import React from "react";
import PropTypes from "prop-types";

import { Flex, Image, Text, Box } from "rebass";
import { useHistory } from "react-router";

// --------------------------------------------------------------------------------------------------

import routes from "constants/routes";
import { PORTAL_IMAGE } from "constants/imagePath";
import SummaryCard from "components/Cards/SummaryCard";
import { OutcomeCardBtnSecondary } from "components/CommonComponents/Button";
import { HealthCardRecommendation } from "components/HealthCard/atomicComponents";
import { HeadingLabel as Heading, HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { getCtaText, getTDEEMetricsWithUnitPreference as tdeeWithPref, getTDEERecommendation } from "utilities/premium";

import { BlurContainer, StyledMetricCardContainer } from "../../styled";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { flex } from "styled-system";

// --------------------------------------------------------------------------------------------------

const FlexLabel = styled(HeadingLabel)`
    display: flex;
    ${flex}
`;

const DailyEnergyCard = ({ title, isPremium, value = {}, ...rest }) => {
    const { settings } = useSelector(state => state);
    const { bmr, dailyEnergyUse, dailyEnergyUseRating, unitInString } = tdeeWithPref(value, settings);

    const isTDEEFinite = Number.isFinite(dailyEnergyUse);
    const rating = isPremium && dailyEnergyUseRating;

    const history = useHistory();

    const handleOnClick = () =>
        history.push(isPremium && !isTDEEFinite ? routes.findHealthStations : routes.dailyEnergy);

    return (
        <SummaryCard
            py="24px"
            height="324px"
            cardHeader={
                <Flex mb="16px" justifyContent="space-between">
                    <Heading>{title ?? "Daily Energy Use"}</Heading>
                    {isPremium && (
                        <Heading fontSize="13px" fontWeight="bold" opacity="0.7">
                            Premium
                        </Heading>
                    )}
                </Flex>
            }
            cardFooter={
                <OutcomeCardBtnSecondary onClick={handleOnClick} data-testid="daily-energy-cta" id="deu">
                    {getCtaText(dailyEnergyUse, isPremium)}
                </OutcomeCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" isPremium={isPremium}>
                    <Flex justifyContent="space-between" alignItems="center" width="100%" height="40px">
                        <HeadingLabel fs={12}>
                            Basal Metabolic Rate:
                            <Text as="span" fontSize="22px">
                                {" "}
                                {bmr?.toLocaleString?.() || 0}{" "}
                            </Text>
                            {unitInString}
                        </HeadingLabel>
                        <Image
                            size="25px"
                            alt="tdee-bmr"
                            src={`${PORTAL_IMAGE}/premium/dailyEnergy/basal-activity-icon.svg`}
                        />
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" width="100%" height="40px" mb="16px">
                        <FlexLabel alignItems="center" fs={12}>
                            Daily Energy Use:
                            {isTDEEFinite ? (
                                <Text as="span" fontSize="22px" my="auto">
                                    {" "}
                                    {dailyEnergyUse.toLocaleString()}{" "}
                                </Text>
                            ) : (
                                <Box as="hr" height="3px" width="16px" bg="hpPrimaryPurple" mx="6px" />
                            )}
                            {unitInString}
                        </FlexLabel>

                        <Image
                            size="25px"
                            alt="tdee-bmr"
                            src={`${PORTAL_IMAGE}/premium/dailyEnergy/high-activity-icon.svg`}
                        />
                    </Flex>
                    {!isPremium && (
                        <BlurContainer top="60px" height="180px" alignItems="center" justifyContent="center">
                            <Image size="130px" alt="premium-lock" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} />
                        </BlurContainer>
                    )}
                    <HealthCardRecommendation fontSize="13px" text={getTDEERecommendation(rating)} textAlign="left" />
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

DailyEnergyCard.propTypes = {
    title: PropTypes.string,
    isPremium: PropTypes.bool,
    value: PropTypes.object,
    indicator: PropTypes.shape({ label: PropTypes.string })
};

export default DailyEnergyCard;
