import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";

class HeartAge extends Component {
    render() {
        const { t, theme, date, heartAge, ...rest } = this.props;

        return (
            <Attribute
                {...rest}
                t={t}
                colour={theme.colours.flat.orange.hex}
                gradientCSS={theme.colours.gradientCSS.orange}
                title={t("Heart Age")}
                date={date}
                guidelineRating={t("Not so Good")}
                primaryStatValue={heartAge}
                icon={theme.icons.types.heartAge}
            />
        );
    }
}

HeartAge.displayName = "Heart Age Attribute";
HeartAge.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    age: PropTypes.number,
    heartAge: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(HeartAge);
