import React from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";

const Height = ({ t, theme, date, height, ...rest }) => (
    <Attribute
        {...rest}
        t={t}
        title={t("Height")}
        date={date}
        primaryStatUnit={"cm"}
        primaryStatValue={typeof height == "number" ? height.toFixed(1) : ""}
        icon={theme.icons.types.height}
    />
);

Height.displayName = "Body Mass Index Attribute";
Height.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    height: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(Height);
