import React from "react";
import PropTypes from "prop-types";

import { Form, Formik } from "formik";
import InputField from "../../Components/InputField";
import { Text, Flex } from "rebass";
import { position } from "styled-system";
import styled from "styled-components";
import { FIELD_VALIDATION, validationSchema } from "../../utilities/validationSchemas";
import { isEmpty } from "ramda";
import MediaQuery from "../../../../MediaQuery";
import theme from "../../../../../themes";

const CustomText = styled(Text)`
    transform: translateY(-50%);
    ${position};
`;

const StyledSpan = styled.span`
    color: #877aa4;
`;

const MetricFormForHeight = ({ onSubmit, value, setErrors, isRequired }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            initialValues={{ heightInCm: `${value}`, isRequired }}
            validationSchema={validationSchema(FIELD_VALIDATION.METRIC.HEIGHT)}
        >
            {({ values, handleBlur, handleSubmit, errors, touched, handleChange }) => {
                setErrors(!touched.heightInCm && isEmpty(errors) && !values.heightInCm ? "error" : errors);
                return (
                    <Form onSubmit={handleSubmit} id="field-form" data-testid="form">
                        <InputField
                            width={["346px", "432px"]}
                            inputProps={{
                                maxLength: 7,
                                name: "heightInCm",
                                placeholder: "(cm)",
                                onBlur: handleBlur,
                                onChange: handleChange,
                                value: values.heightInCm
                            }}
                            error={errors.heightInCm}
                        >
                            <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                {values.heightInCm} {values.heightInCm && <StyledSpan>&nbsp;(cm)</StyledSpan>}
                            </CustomText>
                        </InputField>

                        <MediaQuery devices={["desktop"]}>
                            <Text color={theme.colours.blackTints300.hex} fontSize="13px" textAlign="left">
                                press ENTER ↵
                            </Text>
                        </MediaQuery>
                    </Form>
                );
            }}
        </Formik>
    );
};
MetricFormForHeight.propTypes = {
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any,
    isRequired: PropTypes.bool
};

const ImperialFormForHeight = ({ onSubmit, value, setErrors, isRequired }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            initialValues={{ heightInft: `${value[0]}`, heightInInch: `${value[1]}`, isRequired }}
            validationSchema={validationSchema(FIELD_VALIDATION.IMPERIAL.HEIGHT)}
        >
            {({ values, handleBlur, handleSubmit, handleChange, errors, touched }) => {
                setErrors(!touched.heightInft && isEmpty(errors) && !values.heightInft ? "error" : errors);
                return (
                    <Form onSubmit={handleSubmit} id="field-form" data-testid="form">
                        <Flex justifyContent="space-between" width={["346px", "432px"]}>
                            <InputField
                                width={["165px", "208px"]}
                                inputProps={{
                                    maxLength: 7,
                                    name: "heightInft",
                                    placeholder: "(ft)",
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                    value: values.heightInft
                                }}
                                error={errors.heightInft}
                            >
                                <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                    {values.heightInft} {values.heightInft && <StyledSpan>&nbsp;(ft)</StyledSpan>}
                                </CustomText>
                            </InputField>

                            <InputField
                                width={["165px", "208px"]}
                                inputProps={{
                                    maxLength: 7,
                                    name: "heightInInch",
                                    placeholder: "(in)",
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                    value: values.heightInInch
                                }}
                                error={errors.heightInInch}
                            >
                                <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                    {values.heightInInch} {values.heightInInch && <StyledSpan>&nbsp;(in)</StyledSpan>}
                                </CustomText>
                            </InputField>
                        </Flex>

                        <MediaQuery devices={["desktop"]}>
                            <Text color={theme.colours.blackTints300.hex} fontSize="13px" textAlign="right">
                                press ENTER ↵
                            </Text>
                        </MediaQuery>
                    </Form>
                );
            }}
        </Formik>
    );
};
ImperialFormForHeight.propTypes = {
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any,
    isRequired: PropTypes.bool
};

export { MetricFormForHeight, ImperialFormForHeight };
