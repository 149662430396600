import { Button, Field, FieldTitle, LeftChild, LogOutLink, StyledLink, Title, StyledBox } from "./styled-components";
import { Calendar, Check, Home, List, LogOut, Map, MyAccount, TextBook } from "./Icon";
import React from "react";
import auth from "utilities/auth";
import routes from "constants/routes";
import { withTheme } from "styled-components";
import { Box, Flex } from "rebass";
import { useSelector } from "react-redux";
import { pickAttribute } from "utilities/commonUtils";
import { userIsVhiCustomer, userIsBupaCustomer } from "utilities/clientCheck";

const Hamburger = () => {
    const { citizen } = useSelector(state => state.auth);
    const isHraTaken = Number.isFinite(pickAttribute(["analytics", "healthRiskScore"], citizen));
    const hamburgerStyle = {
        position: "absolute",
        visibility: "hidden",
        opacity: 0,
        transition: "visibility 0.4s, opacity 0.2s linear"
    };

    const resolveContent = citizen => ({
        healthScoreText: userIsVhiCustomer(citizen)
            ? "Vhi Health Score"
            : userIsBupaCustomer(citizen)
            ? "Bupa Health Score"
            : "SiSU Health Score",
        titleText: userIsVhiCustomer(citizen)
            ? "VHI WELLNESS"
            : userIsBupaCustomer(citizen)
            ? "BUPA HEALTH"
            : "SiSU HEALTH"
    });

    const { healthScoreText, titleText } = resolveContent(citizen);

    return (
        <Box data-testid="lhs-menu" id="hamburger" css={hamburgerStyle} width="285px" height="100%" pt="44px">
            <Flex height="100%" flexDirection="column">
                <Box pb="48px">
                    <Title margin="30px 0px 4px 24px">{titleText}</Title>
                    <StyledLink exact to={routes.healthHub} data-testid="dashboard">
                        <Field>
                            <LeftChild>
                                <Home />
                            </LeftChild>
                            <FieldTitle>My Dashboard</FieldTitle>
                        </Field>
                    </StyledLink>
                    <StyledLink to={routes.recommendations} data-testid="recommendation">
                        <Field>
                            <LeftChild>
                                <List />
                            </LeftChild>
                            <FieldTitle>Recommendations</FieldTitle>
                        </Field>
                    </StyledLink>
                    <StyledLink
                        isActive={(_, location) =>
                            [routes.hraResult, routes.healthRiskOnboarding, routes.healthRiskAssessment].includes(
                                location.pathname
                            )
                        }
                        to={isHraTaken ? routes.hraResult : routes.healthRiskOnboarding}
                        data-testid="health-risk"
                    >
                        <Field>
                            <LeftChild>
                                <Check />
                            </LeftChild>
                            <FieldTitle>{healthScoreText}</FieldTitle>
                        </Field>
                    </StyledLink>
                    <StyledLink to={routes.improve} data-testid="plans">
                        <Field>
                            <LeftChild>
                                <TextBook />
                            </LeftChild>
                            <FieldTitle>Online Health Plans</FieldTitle>
                        </Field>
                    </StyledLink>
                    <StyledLink to={routes.healthChecks} data-testid="history">
                        <Field>
                            <LeftChild>
                                <Calendar />
                            </LeftChild>
                            <FieldTitle>Past Health Checks</FieldTitle>
                        </Field>
                    </StyledLink>
                    {!userIsVhiCustomer(citizen) && !userIsBupaCustomer(citizen) && (
                        <StyledLink to={routes.findHealthStations} data-testid="find-station">
                            <Field>
                                <LeftChild>
                                    <Map />
                                </LeftChild>
                                <FieldTitle>Find a SiSU</FieldTitle>
                            </Field>
                        </StyledLink>
                    )}
                </Box>
                <StyledBox>
                    <StyledLink to={`${routes.myAccount.home}`} data-testid="my-account">
                        <Field margin="0px 16px 24px 16px">
                            <LeftChild>
                                <MyAccount />
                            </LeftChild>
                            <FieldTitle>My Account </FieldTitle>
                        </Field>
                    </StyledLink>
                    <LogOutLink
                        to={"/"}
                        onClick={() => {
                            auth.unauthenticate();
                            window.location.assign("/");
                        }}
                    >
                        <Button data-testid="log-out">
                            <Field margin="0px 16px 49px 16px">
                                <LeftChild>
                                    <LogOut />
                                </LeftChild>
                                <FieldTitle>Log out</FieldTitle>
                            </Field>
                        </Button>
                    </LogOutLink>
                </StyledBox>
            </Flex>
        </Box>
    );
};

export default withTheme(Hamburger);
