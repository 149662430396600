import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Box, Flex, Image } from "rebass";
import { path, compose } from "ramda";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { typography } from "styled-system";

// -----------------------------------------------------------------------

import LoadingView from "components/LoadingView";
import { HEIGHT, VIEW_ID_CHART_TYPE_MAP } from "constants/trend-charts";
import { getHealthChecksAPI } from "actions/health-checks";
import { useAsyncData } from "@sisuwellness/utilities/hooks";
import PageLayout from "components/PageLayout";
import CollapsableCard from "components/CollapsableCard";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import FACTS from "constants/facts";
import OutcomeOverview from "components/OutcomeOverview";
import BreadCrumbs from "views/BreadCrumbs";
import { consolidateMetricData, pickAttribute } from "utilities/commonUtils";
import { Guidelines } from "@sisuwellness/utilities";
import { PORTAL_IMAGE } from "constants/imagePath";
import { getMetricCardProps } from "utilities/metrics/healthMetrics";
import { MediaQuery } from "@sisuwellness/web-components";

import Charts, { TYPE_PROPS } from "./Charts";
import NoTrend from "./NoTrend";

// -----------------------------------------------------------------------

const CustomLink = styled(Box)`
    user-select: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    ${typography}
`;

// -----------------------------------------------------------------------

const HEALTH_HUB_ORDER = [
    "weight",
    "blood-pressure",
    "body-fat",
    "bmi",
    "heart-rate",
    "diabetes",
    "qrisk",
    "alcohol",
    "height",
    "smoking"
];

// -----------------------------------------------------------------------

const TrendView = ({ match, healthChecks, getHealthChecksAPI, settings }) => {
    const attribute = path(["params", "attribute"], match);
    const { type: chartType, title } = VIEW_ID_CHART_TYPE_MAP[attribute] || {};
    const theme = useContext(ThemeContext);
    const history = useHistory();

    // Get HRA data for outcome pages
    const { citizen } = useSelector(state => state.auth);
    const datum = consolidateMetricData(citizen, healthChecks);
    let lastMeasurementDate = pickAttribute(["updatedAt"], datum);
    const recommendations = pickAttribute(["recommendations"], datum) || [];
    recommendations.push({
        risk: HEIGHT,
        text: Guidelines.Height.SiSU.recommendations()
    });
    datum["recommendations"] = recommendations;

    const [{ isStarted, isLoading }] = useAsyncData(() => getHealthChecksAPI(), []);

    const isHealthsChecksEmpty = Array.isArray(healthChecks) && healthChecks.length === 0;

    if ((!isStarted && isHealthsChecksEmpty) || (isHealthsChecksEmpty && isLoading)) {
        return <LoadingView />;
    }

    const outcomeProps = { type: chartType, datum, lastMeasurementDate, theme };

    const NextButtonComponent = props => (
        <CustomLink ml="auto" data-testid="next-link" color="hpPrimaryPurple" onClick={goToNextOutcomePage} {...props}>
            Next Health Metric
            <Image src={`${PORTAL_IMAGE}/healthChecks/metric-arrow-right.svg`} size="20px" />
        </CustomLink>
    );

    const renderOverview = () => (
        <>
            <HeadingLabel fontSize={["28px", "30px"]} fontWeight="500">{`Your ${title} Overview`}</HeadingLabel>
            <MediaQuery devices={["mobile"]}>
                <Flex mt="18px" mb="-16px">
                    <NextButtonComponent fontSize="12px" />
                </Flex>
            </MediaQuery>
            <OutcomeOverview {...outcomeProps} mt={["32px", "24px"]} mb={["30px", "60px"]} data-testid="overview" />
        </>
    );

    if (!chartType) {
        return (
            <PageLayout withContainer={false}>
                <BreadCrumbs mb="24px" />
                <Box bg="#f9fbff" px={["20px", "40px", "100px"]} py="20px" mt="32px">
                    <NoTrend />
                </Box>
            </PageLayout>
        );
    }

    const goToNextOutcomePage = () => {
        // Get the index for current attribute
        const currentMetricIndex = HEALTH_HUB_ORDER.indexOf(attribute);

        // get next index
        let nextMetricIndex = getNextMetricIndex(currentMetricIndex);

        // get next attribute to go to
        let nextMetricAttribute = HEALTH_HUB_ORDER[nextMetricIndex];
        let type = VIEW_ID_CHART_TYPE_MAP[nextMetricAttribute].type;

        // Check if attribute is available on the dashboard
        let isCardVisibleOnDashboard = getMetricCardProps(datum, type, datum.recommendations);

        // If card not visible skip to next attribute
        while (!isCardVisibleOnDashboard) {
            // if next index traverses through all attributes and card doesnt exist (revolves back to current index)
            if (currentMetricIndex === nextMetricIndex) return;
            nextMetricIndex = getNextMetricIndex(nextMetricIndex);
            nextMetricAttribute = HEALTH_HUB_ORDER[nextMetricIndex];
            type = VIEW_ID_CHART_TYPE_MAP[nextMetricAttribute].type;
            isCardVisibleOnDashboard = getMetricCardProps(datum, type, datum.recommendations);
        }
        // push the next route on the stack for next outcome page
        history.push(VIEW_ID_CHART_TYPE_MAP[nextMetricAttribute].link);
    };

    // cycles through next indexes from HEALTH_HUB_ORDER
    const getNextMetricIndex = index => (index + 1) % HEALTH_HUB_ORDER.length;

    const hideOutcomeOverviewFor = [HEIGHT];
    const hideOutcomeOverview = hideOutcomeOverviewFor.includes(chartType);

    const isFactsAvailable = Boolean(FACTS[chartType]);

    const isTrendsAvailable = Object.keys(TYPE_PROPS).includes(chartType) && !isHealthsChecksEmpty;

    return (
        <PageLayout>
            <Flex flexDirection={["column", "row"]} justifyContent="space-between">
                <BreadCrumbs mb={["16px", "24px"]} />
                <MediaQuery devices={["tablet", "desktop"]}>
                    <NextButtonComponent fontSize="14px" fontWeight="500" />
                </MediaQuery>
            </Flex>
            {!hideOutcomeOverview && renderOverview()}
            {isTrendsAvailable && (
                <Charts
                    title={title}
                    settings={settings}
                    chartType={chartType}
                    healthChecks={healthChecks}
                    latestHealthCheckData={datum}
                    lastMeasurementDate={lastMeasurementDate}
                    nextOutcomeComponent={
                        hideOutcomeOverview && (
                            <MediaQuery devices={["mobile"]}>
                                <Flex mt="18px" mb="2px">
                                    <NextButtonComponent fontSize="12px" />
                                </Flex>
                            </MediaQuery>
                        )
                    }
                />
            )}

            <Box mb="160px">
                {isFactsAvailable && (
                    <>
                        <HeadingLabel
                            mb={["32px", "24px"]}
                            fontSize={["28px", "30px"]}
                            fontWeight="500"
                        >{`Common Questions About ${FACTS[chartType].title}`}</HeadingLabel>
                        {FACTS[chartType].data.map((facts, index) => (
                            <CollapsableCard mb="24px" {...facts} key={index} />
                        ))}
                    </>
                )}
            </Box>
        </PageLayout>
    );
};

// -----------------------------------------------------------------------

TrendView.propTypes = {
    match: PropTypes.object.isRequired,
    healthChecks: PropTypes.array.isRequired,
    settings: PropTypes.object,
    getHealthChecksAPI: PropTypes.func.isRequired
};

const mapStateToProps = ({ healthCheck: { healthChecks = [] } = {}, settings }) => ({
    healthChecks,
    settings
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getHealthChecksAPI
        },
        dispatch
    );

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TrendView);
