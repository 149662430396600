import React from "react";
import PropTypes from "prop-types";
import { OverlayArea } from "@sisuwellness/web-components/components/Chart";

const AreaLayer = ({ dimension: [width], ...rest }) => (
    <OverlayArea label="Optimal Heart Rate" upperBound={80} lowerBound={40} width={width} {...rest} />
);

AreaLayer.propTypes = {
    dimension: PropTypes.array
};

export default AreaLayer;
