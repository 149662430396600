import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { isMobileView } from "components/Templates/TrendChart/Line/utils";
import { find, pathOr, propEq } from "ramda";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import {
    calculateMinMax,
    createLegends,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";

import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { BLOOD_PRESSURE } from "constants/trend-charts";

import AreaLayer from "./AreaLayer";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: true,
    key: ["sys", "dia"],
    additionalKeys: ["rating", "unit", "bpInString"]
};

/**
 * Update data with rating
 * @param {object} data
 * @return {{ rating: { key: string, label: string, ranges: object } }}
 */
const bpRatingCalc = data => {
    if (!(data.sys && data.dia)) return { sys: null, dia: null };
    const { guideline, currentValue } = calculatePointColor(BLOOD_PRESSURE, data);

    return {
        ...data,
        unit: "mmHg",
        bpInString: currentValue,
        rating: { key: guideline?.key, label: guideline?.label, ranges: guideline?.constructor?.ranges }
    };
};

const plot = {
    unit: () => "Blood Pressure (mmHg)",

    keyForValueString: () => "bpInString",

    color: ({ rating } = {}) =>
        theme.guidelines.NationalHeartFoundationAustralia[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = []) =>
        createPlottableDataPoints(bpRatingCalc)(healthChecks, METRIC_FIELDS.key, METRIC_FIELDS.additionalKeys),

    yRange: (points = []) => {
        const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(0, Math.max(maximum, 120), { parts: 5, rounded: METRIC_FIELDS.rounded });

        return {
            min: 0,
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.BLOOD_PRESSURE.themeKey,
            "Blood Pressure"
        ),

    historyProps: (filteredPoints = [], latestHealthCheck = {}) => {
        const { sys, dia } = latestHealthCheck;

        const { rating } = bpRatingCalc({ sys, dia });

        const meanSystolic = calculateMeanValue("sys", find(propEq("id", "sys"))(filteredPoints));
        const meanDiastolic = calculateMeanValue("dia", find(propEq("id", "dia"))(filteredPoints));

        const { rating: meanRating } = bpRatingCalc({ sys: meanSystolic, dia: meanDiastolic });

        return {
            unit: "mmHg",
            value: sys && dia ? `${sys}/${dia}` : "-",
            valueColor: theme.guidelines.NationalHeartFoundationAustralia[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.NationalHeartFoundationAustralia[(meanRating?.key)]?.sisuPortal?.hex,
            meanValue: meanSystolic && meanDiastolic ? `${Math.round(meanSystolic)}/${Math.round(meanDiastolic)}` : "-"
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot, AreaLayer };
