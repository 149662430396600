import { OTHER_COUNTRY_CODE } from "@sisuwellness/utilities/constants";

import { DEFAULT_LOCALE } from "@sisuwellness/utilities/constants/locale";

const LEFT_TOOLTIP = "LEFT";
const RIGHT_TOOLTIP = "RIGHT";

export const RECORDS_LIMIT = { DESKTOP: 5, MOBILE: 4 };

export const SETTINGS = {
    UNIT_MEASUREMENT: { metric: "Metric", imperial: "Imperial" },
    ENERGY_UNIT: { kilojoules: "Kilojoules", kilocalories: "Calories" }
};

const GMAPS_URL =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyCgtlWWFqypNWzqKkNjX5Vm0C6Db2iwjC4&libraries=geometry,places&callback=initialiseMapsCallback";

export { GMAPS_URL, OTHER_COUNTRY_CODE, DEFAULT_LOCALE, LEFT_TOOLTIP, RIGHT_TOOLTIP };

export const HealthChecksFetchStatus = {
    notStarted: "not started",
    inProgress: "in progress",
    completed: "completed",
    error: "error"
};

export const REGISTRATION_TOKEN = "registration_token";
