import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(gender, waistCircumference) {
        this._validateGender(gender);
        this._validateValue(waistCircumference);

        this._isMale = gender.toLowerCase() === "male";
        this._waistCircumference = waistCircumference;
        this._labels = {
            high: "High"
        };
    }

    /**
     * Returns the given Waist Circumference value
     * @returns {Number}
     */
    get() {
        return this._waistCircumference;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "WAIST_CIRCUMFERENCE";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_WC";
    }

    /**
     *
     * @param string
     * @returns {boolean}
     * @private
     */
    _validateGender(string) {
        if (!(typeof string === "string") || (string.toLowerCase() !== "male" && string.toLowerCase() !== "female")) {
            throw new InvalidArgumentError('gender must be "male" or "female"');
        }

        return true;
    }

    /**
     * Validates the given waist circumference value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(value) {
        if (!(typeof value === "number") || Number.isNaN(value) || value < 0) {
            throw new InvalidArgumentError("Waist Circumference must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the given Waist Circumference is in the "high" range
     *
     * @returns {boolean}
     */
    isHigh() {
        let isHigh = false;
        switch (true) {
            // Women
            case !this._isMale && this._waistCircumference > 88:
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._waistCircumference > 102:
                isHigh = true;
                break;
        }

        return isHigh;
    }

    /**
     * Returns an object representing various data for each Waist Circumference range (for given gender)
     *
     * @returns {object}
     */
    get ranges() {
        const originalWaistCircumference = this._waistCircumference;

        let dummyInput = {};
        let ranges = {};

        // generate dummy body fat input for given age/gender
        switch (true) {
            // Females
            case !this._isMale:
                dummyInput = { high: 89 };
                break;

            // Males
            case this._isMale:
                dummyInput = { high: 103 };
                break;
        }

        for (let key in dummyInput) {
            let dummyRange = dummyInput[key];
            this._waistCircumference = dummyRange;

            ranges[key] = {
                key: this.key,
                label: this.getRatingName(),
                range: this.rangeInfo
            };
        }

        // NB: reinstate the original values
        this._waistCircumference = originalWaistCircumference;
        return ranges;
    }

    /**
     * @returns {*}
     */
    getRatingName() {
        return this._labels[this.key];
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = null;
        switch (true) {
            // Women
            case !this._isMale && this.isHigh():
                info = "> 88";
                break;
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this.isHigh():
                info = "> 102";
                break;
        }

        return info;
    }

    get key() {
        let ratingName = null;
        switch (true) {
            case this.isHigh():
                ratingName = "high";
                break;
        }

        return ratingName;
    }
}
