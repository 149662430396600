import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ThemeContext } from "styled-components";
import { Flex, Image, Text } from "rebass";

// ------------------------------------------------------------------------------------------------

import SummaryCard from "components/Cards/SummaryCard";
import { HeadingLabel as Title } from "@sisuwellness/ui/src/components/Labels";
import { SLEEP } from "constants/trend-charts";
import getMetric from "utilities/metrics/metric";
import { MetricCardBtnPrimary } from "components/CommonComponents/Button";
import Pie from "@sisuwellness/ui/src/components/Pie";
import { PORTAL_IMAGE } from "constants/imagePath";
import { StatusLabel as Status } from "@sisuwellness/ui/src/components/Labels";
import { StatusCard } from "@sisuwellness/ui/src/components/Cards";

// ------------------------------------------------------------------------------------------------

const SleepCardUI = ({ ahiScore, isActionDisabled, onClick, processed, recommendationText }) => {
    const isAHIScoreFinite = Number.isFinite(ahiScore);
    const metric = getMetric(SLEEP, { ahiScore });
    const theme = useContext(ThemeContext);

    return (
        <SummaryCard
            data-testid="sleep-card"
            bg="rgb(152, 210, 195, 0.32)"
            cardHeader={
                <Title fontSize="15px" fontWeight="normal" mb="16px">
                    {metric?.title || "Your NightOwl™ Results"}
                </Title>
            }
            cardFooter={
                <MetricCardBtnPrimary
                    width="230px"
                    color="hpDarkCerulean"
                    borderColor="white"
                    onClick={onClick}
                    backgroundColor="white"
                    fontWeight="intermediate"
                    disabled={isActionDisabled}
                    disabledColor={theme.colours.blackTints600.hex}
                    disabledBorderColor={theme.colours.flat.gray.hex}
                    disabledBackgroundColor={theme.colours.flat.gray.hex}
                >
                    Download Clinical Report
                </MetricCardBtnPrimary>
            }
            cardBody={
                <>
                    <Flex justifyContent="center" alignItems="center">
                        {isAHIScoreFinite ? (
                            <Pie progress={100} progressFgColor="white" bg={metric?.color} size={85}>
                                <Text fontSize="20px" fontWeight="500" textAlign="center" color="white">
                                    AHI
                                    <br />
                                    {ahiScore?.toFixed(2)}
                                </Text>
                            </Pie>
                        ) : (
                            <Image src={`${PORTAL_IMAGE}/nightOwl.png`} alt="mouse-logo" width="85px" height="85px" />
                        )}
                        <Flex flexDirection="column" alignItems="flex-end" ml="45px">
                            {!processed && (
                                <Text fontSize="13px" color="hpDarkCerulean" mb="8px" textAlign="right">
                                    Apnea-Hypopnea Index:
                                </Text>
                            )}
                            <StatusCard bg={metric?.color || "hpPurple100"} height="40px" width="85px">
                                <Status color={isAHIScoreFinite ? "white" : "hpDarkCerulean"} fs={16}>
                                    {metric?.rating || "Pending"}
                                </Status>
                            </StatusCard>
                        </Flex>
                    </Flex>
                    <Text
                        mx="auto"
                        mt="16px"
                        fontSize="13px"
                        maxWidth="360px"
                        textAlign="center"
                        color="hpGrayishBlueText"
                        data-testid="recommendation-text"
                    >
                        {recommendationText}
                    </Text>
                </>
            }
        />
    );
};

SleepCardUI.propTypes = {
    ahiScore: PropTypes.number,
    isActionDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    processed: PropTypes.bool,
    recommendationText: PropTypes.string
};

export default SleepCardUI;
