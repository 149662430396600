import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Flex, Box } from "rebass";
import { Icon, theme } from "../../../index";

const Root = styled(Flex)`
    flex-direction: column;
`;

const ProgressContainer = styled(Flex)`
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`;

const Progress = styled(ProgressBar)`
    flex: 1 0 auto;
    margin: 0 1rem;
    .progress-bar {
        background-color: ${props => props.theme.colours.flat.teal.hex};
    }
    height: 15px;
    border-radius: 2px;
`;

const Title = styled.h4`
    font-size: ${props => props.theme.text.paragraph.sizeRem};
    color: ${props => props.theme.colours.flat.darkGray.hex};
    text-align: center;
    margin: 0;
`;

const NavButton = styled(Flex)`
    justify-content: center;
    align-items: center;
    visibility: ${props => (props.visible ? "visible" : "hidden")};
    color: ${props =>
        props.disabled ? props.theme.colours.flat.lightGray.hex : props.theme.colours.flat.darkGray.hex};
    user-select: none;
`;

const ArrowLeft = styled(Icon)`
    fill: ${props => (props.disabled ? props.theme.colours.flat.lightGray.hex : props.theme.colours.flat.darkGray.hex)};
`;

const ArrowRight = styled(Icon)`
    fill: ${props => (props.disabled ? props.theme.colours.flat.lightGray.hex : props.theme.colours.flat.darkGray.hex)};
    transform: rotate(180deg);
`;

export default class OneQuestionAtATimeNavigation extends Component {
    static displayName = "One Question at a time Navigation";

    static defaultProps = {
        showNextButton: true,
        showBackButton: true,
        nextButtonDisabled: true,
        backButtonDisabled: true,
        healthStationMode: false,
        percentComplete: 0,
        displayName: ""
    };

    static propTypes = {
        survey: PropTypes.object,
        onBackClick: PropTypes.func.isRequired,
        onNextClick: PropTypes.func.isRequired,
        showNextButton: PropTypes.bool.isRequired,
        showBackButton: PropTypes.bool.isRequired,
        nextButtonDisabled: PropTypes.bool.isRequired,
        backButtonDisabled: PropTypes.bool.isRequired,
        healthStationMode: PropTypes.bool,
        percentComplete: PropTypes.number,
        displayName: PropTypes.string
    };

    constructor(props) {
        super(props);
    }

    onBack(e) {
        this.props.onBackClick(e);
    }

    onNext(e) {
        if (this.props.nextButtonDisabled) {
            e.preventDefault();
            return;
        }

        this.props.onNextClick(e);
    }

    render() {
        const {
            showBackButton,
            showNextButton,
            nextButtonDisabled,
            backButtonDisabled,
            healthStationMode
        } = this.props;
        const iconSize = theme.icons.sizes.small;
        const navIcon = theme.icons.types.arrow;
        const fontSize = healthStationMode ? "1.6rem" : "1.4rem";
        const nextButton = (
            <NavButton
                onClick={e => this.onNext(e)}
                visible={showNextButton}
                disabled={nextButtonDisabled}
                data-testid={"button-next"}
            >
                <Box fontSize={fontSize}>NEXT</Box>
                <ArrowRight type={navIcon} size={iconSize} disabled={nextButtonDisabled} />
            </NavButton>
        );
        const backButton = (
            <NavButton
                onClick={e => this.onBack(e)}
                visible={showBackButton}
                disabled={backButtonDisabled}
                data-testid={"button-back"}
            >
                <ArrowLeft type={navIcon} size={iconSize} disabled={backButtonDisabled} />
                <Box fontSize={fontSize}>BACK</Box>
            </NavButton>
        );

        const { survey, percentComplete, displayName } = this.props;
        let now = percentComplete;
        let title = displayName;
        if (survey) {
            const surveyCompletionStats = survey.getCompletionStatistics();
            now = ((surveyCompletionStats.completedQuestions + 1) / surveyCompletionStats.totalQuestions) * 100;
            title = survey.getDisplayName();
        }

        const progressBar = <Progress now={now} />;

        return (
            <Root>
                <Title>{title}</Title>
                <ProgressContainer>
                    {backButton}
                    {progressBar}
                    {nextButton}
                </ProgressContainer>
            </Root>
        );
    }
}
