import React from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";

const HDLCholesterol = ({
    t,
    cholesterol,
    date,
    theme: {
        guidelines = {
            SiSUHDLCholesterol: []
        },
        icons = {
            types: {}
        }
    },
    ...rest
}) => {
    let guideline = null;
    let guidelineStyle = null;

    if (cholesterol) {
        guideline = new Guidelines.HDLCholesterol.SiSU(cholesterol);
        guidelineStyle = guidelines.SiSUHDLCholesterol[guideline.key];
    }

    return (
        <Attribute
            {...rest}
            t={t}
            colour={guidelineStyle ? guidelineStyle.flat.hex : null}
            gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
            guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
            guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
            title={t("HDL Cholesterol")}
            date={date}
            primaryStatValue={cholesterol}
            primaryStatUnit={"mmol/L"}
            icon={icons.types.hdlCholesterol}
        />
    );
};

HDLCholesterol.displayName = "HDL Cholesterol Attribute";
HDLCholesterol.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    cholesterol: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(HDLCholesterol);
