import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash-es";
import { Text } from "rebass";

// -----------------------------------------------------------------------

import { setCitizen } from "@sisuwellness/connected-components/actions/auth";
import { MediaQuery } from "@sisuwellness/web-components";
import { MESSAGE_ERROR } from "constants/messages";
import APIClient from "utilities/APIClient";
import { getErrorMessage, poll } from "utilities/commonUtils";
import Flex from "components/Flex";

import animationData from "../../../assets/lottie/chargbee_loader.json";
import { useMessageBanner } from "..";

// -----------------------------------------------------------------------

const StyledConatiner = styled(Flex).attrs({ ai: "center", jc: "center" })`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
    background: rgba(55, 33, 104, 0.8);
`;

// -----------------------------------------------------------------------

const MAX_POLLING_INTERVAL = 1000;

export const PollingPage = ({ citizen = {}, setIsLoading, setIsCongratsModalOpen }) => {
    const dispatch = useDispatch();
    const { handleMessage } = useMessageBanner();

    const fetchCitizen = async () => await APIClient.citizen.get();

    const validateCitizenData = async result => {
        const newCitizen = await result.body();
        return Promise.resolve(!isEqual(citizen.premium, newCitizen.premium));
    };

    useEffect(() => {
        poll({ fn: fetchCitizen, validate: validateCitizenData, interval: MAX_POLLING_INTERVAL })
            .then(res => res.body())
            .then(newCitizen => {
                dispatch(setCitizen(newCitizen));
                setIsCongratsModalOpen(true);
            })
            .catch(err => handleMessage(MESSAGE_ERROR, getErrorMessage(err)))
            .finally(() => setIsLoading(false));
    }, []);

    const spinnerOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <StyledConatiner data-testid="polling-page">
            <Flex fd="column" gap="24px">
                <Text
                    color="white"
                    fontSize={["20px", "22px", "28px"]}
                    width={["350px", "", "100%"]}
                    textAlign="center"
                >
                    Creating a SiSU Plus™ account for{" "}
                    <Text as="span" color="hpYellow">
                        you
                    </Text>
                    ...
                </Text>
                <MediaQuery devices={["desktop"]}>
                    <Lottie options={spinnerOptions} height={200} width={200} isStopped={false} isPaused={false} />
                </MediaQuery>
                <MediaQuery devices={["tablet"]}>
                    <Lottie options={spinnerOptions} height={160} width={160} isStopped={false} isPaused={false} />
                </MediaQuery>
                <MediaQuery devices={["mobile"]}>
                    <Lottie options={spinnerOptions} height={120} width={120} isStopped={false} isPaused={false} />
                </MediaQuery>
            </Flex>
        </StyledConatiner>
    );
};

PollingPage.propTypes = {
    citizen: PropTypes.object,
    setIsLoading: PropTypes.func,
    setIsCongratsModalOpen: PropTypes.func
};
