import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * @param {number} score
     * Constructor
     */
    constructor(score) {
        this._validateValue(score);
        this._score = score;
    }

    /**
     * Returns the given score value
     * @returns {Number}
     */
    get() {
        return this._score;
    }

    /**
     * Validates the given score value. It is intended to be a positive number greater than 0
     * @param {number} score
     * @returns {boolean}
     * @private
     */
    _validateValue(score) {
        if (!(typeof score === "number") || Number.isNaN(score) || score < 0 || score > 100) {
            throw new InvalidArgumentError("Sleep Ahi Score must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the score is in the 'severe' range
     *
     * @returns {boolean}
     */
    isSevere() {
        return this._score >= 30;
    }

    /**
     * Returns if the given score is in the 'moderate' range
     *
     * @returns {boolean}
     */
    isModerate() {
        return this._score >= 15 && this._score < 30;
    }

    /**
     * Returns if the given score is in the 'mild' range
     *
     * @returns {boolean}
     */
    isMild() {
        return this._score >= 5 && this._score < 15;
    }

    /**
     * Returns if the given score is in the 'healthy' range
     *
     * @returns {boolean}
     */
    isHealthy() {
        return this._score >= 0 && this._score < 5;
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isSevere():
                info = "30 +";
                break;
            case this.isModerate():
                info = "15 - 30";
                break;
            case this.isMild():
                info = "5 - 15";
                break;
            case this.isHealthy():
                info = "0 - 5";
                break;
        }

        return info;
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeLabel() {
        let info = "";
        switch (true) {
            case this.isSevere():
                info = "Severe";
                break;
            case this.isModerate():
                info = "Moderate";
                break;
            case this.isMild():
                info = "Mild";
                break;
            case this.isHealthy():
                info = "Healthy";
                break;
        }

        return info;
    }

    /**
     * returns normalised 'key' about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isSevere():
                info = "severe";
                break;
            case this.isModerate():
                info = "moderate";
                break;
            case this.isMild():
                info = "mild";
                break;
            case this.isHealthy():
                info = "healthy";
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        return this.rangeLabel;
    }
}
