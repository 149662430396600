import React from "react";
import { path } from "ramda";
import { Cell, Header } from "./commonElements";

export const diabetesRiskDef = iconTypes => ({
    minWidth: 92,
    accessor: "ausdriskScoreValue",
    Cell: row => <Cell row={row} value={path(["value"], row)} />,
    Header: () => <Header label="Diabetes Risk" iconType={iconTypes.diabetesRisk} />
});
