import React from "react";
import PropTypes from "prop-types";
import { Flex } from "../Flex";
import { QuestionTitle, QuestionHelpText } from "./styled";
import Choice from "./Choice";

const PhysicalActivityLevel = ({
    title = "What is your physical activity level?",
    helpText = "If you exercise strenuously (at least 30min, 4 times / week), change your selection to the next highest category",
    choices = [],
    onSelect,
    onDeselect,
    selectedChoiceText
}) => {
    return (
        <Flex fd="column" jc="center" height="100%" overflow="auto">
            <Flex fd="column" ai="center" jc="center">
                <QuestionTitle mt={"0px"}>{title}</QuestionTitle>
                <QuestionHelpText mt={"5px"}>{helpText}</QuestionHelpText>
            </Flex>
            <Flex fd="column" ai="center" jc="center" mt="16px">
                {choices.map((choice, index) => (
                    <Choice
                        key={index}
                        choice={choice}
                        isSelected={choice.label === selectedChoiceText}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                    />
                ))}
            </Flex>
        </Flex>
    );
};

PhysicalActivityLevel.propTypes = {
    title: PropTypes.string,
    helpText: PropTypes.string,
    choices: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    selectedChoiceText: PropTypes.string
};

export default PhysicalActivityLevel;
