import React from "react";
import styled from "styled-components";

import { DataCell, DifferenceLabel, InformationModal } from "views/HeartAge/commonElements";
import { calculateHeartAgeParams, isHeartAgeIndicative } from "views/HeartAge/utils";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { MediaQuery } from "@sisuwellness/web-components";
import { useUserHealthCheckData } from "views/HeartAge";
import { formatDate } from "utilities/commonUtils";
import { HEART_AGE } from "constants/messages";
import Flex from "components/Flex";
import OverviewCardUI from "../overviewCardUI";
import { METRIC_TEXTSPECS } from "../textspecs";
import CardStack from "components/Cards/CardStack";
import { useSelector } from "react-redux";
import { Image } from "rebass";
import { PORTAL_IMAGE } from "constants/imagePath";

const ItalicizedText = styled.span`
    font-weight: 300;
    font-style: oblique;
`;

export const CurrentTab = ({ displayTab }) => {
    const { isCountryUK } = useSelector(state => state.deviceLocation);
    const { latestHealthCheck } = useUserHealthCheckData();

    const { age, heartAge, heartAgeRating, lastMeasurementDate, tenYearCvdRisk } = calculateHeartAgeParams(
        latestHealthCheck
    );

    return (
        displayTab && (
            <Flex fd="column" gap="24px" mt="16px" width="100%">
                <Flex fd="column">
                    <HeadingLabel fontSize={["13px", "", "15px"]}>
                        *Your Heart Age and Heart and Stroke Risk are based from the results from your last full Health
                        Station check on {formatDate(lastMeasurementDate, "DD/MM/YYYY")}.
                    </HeadingLabel>
                    {isHeartAgeIndicative(age) && (
                        <HeadingLabel fontSize={["13px", "", "15px"]}>
                            Note: For people aged below 30 or over 74 years old, Heart Age results are{" "}
                            <ItalicizedText>indicative only</ItalicizedText>.
                        </HeadingLabel>
                    )}
                </Flex>
                <CardStack gap={["8px", "16px", "32px"]} py="24px">
                    <OverviewCardUI {...METRIC_TEXTSPECS.HEART_AGE}>
                        <Flex width="100%" gap="16px" jc="space-between">
                            <DataCell
                                age={heartAge}
                                title="Heart Age"
                                gap={["16px", "8px", "4px"]}
                                data-testid="cell-heart-age"
                            />
                            <DataCell
                                age={age}
                                title="Actual Age*"
                                gap={["16px", "8px", "4px"]}
                                data-testid="cell-actual-age"
                            />
                            <MediaQuery devices={["desktop", "tablet"]}>
                                <DifferenceLabel
                                    actualAge={age}
                                    heartAge={heartAge}
                                    rating={heartAgeRating}
                                    gap={["16px", "8px", "4px"]}
                                />
                            </MediaQuery>
                        </Flex>
                    </OverviewCardUI>
                    {isCountryUK ? (
                        <>
                            <MediaQuery devices={["desktop"]}>
                                <Flex bg="white" p="16px" justifyContent="center" flex={1}>
                                    <Image
                                        alt="uk-heart-age-overview.svg"
                                        src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                    />
                                </Flex>
                            </MediaQuery>
                            <MediaQuery devices={["tablet"]}>
                                <Flex bg="white" p="16px" justifyContent="center" flexGrow={1}>
                                    <Image
                                        alt="uk-heart-age-overview.svg"
                                        src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                    />
                                </Flex>
                            </MediaQuery>
                        </>
                    ) : (
                        <OverviewCardUI {...METRIC_TEXTSPECS.TEN_YEAR_CVD_RISK}>
                            <DataCell
                                units="%"
                                age={tenYearCvdRisk}
                                gap={["4px", "20px", "0px"]}
                                data-testid="cell-heart-stroke"
                            />
                        </OverviewCardUI>
                    )}
                </CardStack>

                <CardStack gap={["8px", "16px", "32px"]}>
                    <Flex flexGrow={1} flexBasis="210px">
                        <InformationModal heading={HEART_AGE.MODAL.HEART_AGE_INFO.HEADING}>
                            {HEART_AGE.MODAL.HEART_AGE_INFO.CONTENT}
                        </InformationModal>
                    </Flex>
                    {!isCountryUK && (
                        <Flex flexGrow={1} flexBasis="210px">
                            <InformationModal heading={HEART_AGE.MODAL.HEART_STROKE.HEADING}>
                                {HEART_AGE.MODAL.HEART_STROKE.CONTENT}
                            </InformationModal>
                        </Flex>
                    )}
                </CardStack>
            </Flex>
        )
    );
};
