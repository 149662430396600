import React, { useContext } from "react";

import PageLayout from "components/PageLayout";
import { Card, Flex, Heading, Box, Text, Image } from "rebass";
import styled, { ThemeContext } from "styled-components";
import { PORTAL_IMAGE } from "constants/imagePath";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import routes from "constants/routes";
import { useHistory } from "react-router";

const StyledButton = styled(PrimaryButton)`
    font-size: 16px;
`;

const HraCalculationView = () => {
    const theme = useContext(ThemeContext);
    const history = useHistory();

    const navigateToHealthHub = () => {
        history.push(routes.healthHub);
    };

    return (
        <PageLayout>
            <Card my="16px" bg={theme.portalColor.hpCardBackground.hex}>
                <Flex height="100vh" justifyContent="center" alignItems="center">
                    <Box textAlign="center">
                        <Image
                            src={`${PORTAL_IMAGE}/healthRiskAssessment/Illustration_ Settings.svg`}
                            alt="settings"
                            size="170px"
                        />
                        <Heading
                            pb="15px"
                            fontSize={["28px", "28px", "34px"]}
                            fontWeight={theme.fontWeight.intermediate}
                        >
                            Sorry, but your SiSU Health Score will be ready in 5 minutes
                        </Heading>
                        <Text pb="24px">You can continue to use the portal in the meantime</Text>
                        <StyledButton data-testId="to-dashboard" onClick={navigateToHealthHub}>
                            Return to Dashboard
                        </StyledButton>
                    </Box>
                </Flex>
            </Card>
        </PageLayout>
    );
};

export default HraCalculationView;
