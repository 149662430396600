import React, { Component } from "react";
import { Link } from "react-router-dom";
import APIClient from "../../utilities/APIClient";
import { Cards } from "@sisuwellness/web-components";
import BaseMemberViewWithMenu from "../../components/BaseMemberViewWithMenu";
import LoadingView from "components/LoadingView";

class UserJourneysList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        };

        this.handleComplete = this.handleComplete.bind(this);
    }

    componentDidMount() {
        APIClient.userJourneys
            .get()
            .then(response => {
                if (response.succeeded()) {
                    return response.body();
                } else {
                    this.setState({ items: [] });
                }

                return false;
            })
            .then(body => {
                if (body) {
                    this.setState({ items: body });
                }
            });
    }

    handleComplete() {
        this.setState({ complete: true });
    }

    render() {
        let component = null;
        if (this.state.items.length > 0) {
            component = this.state.items.map(userJourney => (
                <Link key={userJourney.userJourneyId} to={"/journeys/" + userJourney.userJourneyId}>
                    <Cards thin={true}>{userJourney.name}</Cards>
                </Link>
            ));
        } else {
            component = <LoadingView />;
        }

        return (
            <BaseMemberViewWithMenu>
                <h3>User Journeys</h3>
                {component}
            </BaseMemberViewWithMenu>
        );
    }
}

export default UserJourneysList;
