import React from "react";
import PropTypes from "prop-types";
import { OverlayArea } from "@sisuwellness/web-components/components/Chart";

const AreaLayer = ({ dimension: [width], ...rest }) => (
    <>
        <OverlayArea label="Optimal systolic range (90-119)" upperBound={119} lowerBound={90} width={width} {...rest} />
        <OverlayArea label="Optimal diastolic range (79-60)" upperBound={79} lowerBound={60} width={width} {...rest} />
    </>
);

AreaLayer.propTypes = {
    dimension: PropTypes.array
};

export default AreaLayer;
