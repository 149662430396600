import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styled from "styled-components";

// ------------------------------------------------------------------------

import InputField from "components/InputField";
import routes from "constants/routes";
import PasswordValidation from "views/PasswordValidation";
import { FooterText, SignupFormContainer } from "./styled";
import { validateSchema } from "./formValidation";
import { PORTAL_IMAGE } from "constants/imagePath";
import RegisterTNC from "./register";
import { CountryHelper, CountryMap } from "@sisuwellness/utilities/User/UserProfile";
import { intersection } from "ramda";
import {
    CountryField,
    DateOfBirthField,
    EmailField,
    GenderField,
    MobileNumberField,
    NameField,
    PostalCodeField
} from "components/Forms";
import Flex from "components/Flex";
import { PrimaryButton, SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { isEmpty } from "lodash-es";
import { isSignUpFlow } from "./utils";

// ------------------------------------------------------------------------

export const FlexFields = styled(Flex)`
    display: grid;
    column-gap: 15px;
    grid-template-columns: auto auto;
`;

const SignupForm = ({
    userDetails: {
        firstName = "",
        lastName = "",
        email = "",
        gender = "",
        dateOfBirth = "",
        mobileNumber = "",
        postalCode = "",
        countryCode = "",
        type = "",
        regionCode = "",
        locale = "",
        consents = []
    },
    onSubmit,
    userPeep
}) => {
    const [part, setPart] = useState(1);
    const [isShowPasswordEnabled, setPass] = useState(false);
    const [validatingEmail, setValidatingEmail] = useState(false);

    let availableCountries;
    if (regionCode) {
        // generates an array of upper-cased country names for consumption in the dropdown list
        availableCountries = CountryHelper.getCountriesByRegionCode(regionCode).map(country =>
            country.name.toUpperCase()
        );
    } else {
        availableCountries = CountryMap.map(entry => entry.name.toUpperCase());
        if (countryCode) {
            regionCode = CountryHelper.getRegionCodeByCountryCode(countryCode);
            let countryInRegion = CountryHelper.getCountriesByRegionCode(regionCode);
            availableCountries = intersection(countryInRegion, CountryMap).map(country => country.name.toUpperCase());
        }
    }

    let form = {
        type,
        password: "",
        countryCode: countryCode,
        email: email ? email : "",
        gender: gender ? gender : "",
        consents: consents.slice(0, 2),
        lastName: lastName ? lastName : "",
        firstName: firstName ? firstName : "",
        postalCode: postalCode ? postalCode : "",
        dateOfBirth: dateOfBirth ? dateOfBirth : "",
        mobileNumber: mobileNumber ? mobileNumber : "",
        termsAndConditions: type === "setup" ? true : false
    };

    const Eye = (
        <img
            src={`${PORTAL_IMAGE}/login/${isShowPasswordEnabled ? "Hide" : "Show"}Eye.svg`}
            onClick={() => setPass(!isShowPasswordEnabled)}
            style={{ cursor: "pointer" }}
        />
    );

    const handleSubmit = (values, { setSubmitting }) => onSubmit({ ...values }, setSubmitting);

    const disableCreateAccount = (errors, values) =>
        errors.firstName ||
        errors.lastName ||
        errors.email ||
        errors.password ||
        errors.termsAndConditions ||
        !(values.firstName && values.lastName && values.email && values.password && values.termsAndConditions);

    const checkIfUserExist = async email => {
        setValidatingEmail(true);
        let userExist = await userPeep(email);

        if (!userExist) setPart(2);

        setValidatingEmail(false);
    };

    return (
        <SignupFormContainer>
            <Formik
                enableReinitialize
                initialValues={form}
                onSubmit={handleSubmit}
                validationSchema={validateSchema(form)}
            >
                {formProps => {
                    const {
                        isSubmitting,
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isValid,
                        setFieldValue
                    } = formProps;
                    return (
                        <Form onSubmit={handleSubmit}>
                            {part === 1 && (
                                <Flex fd="column" width="100%" gap="40px" mt="40px">
                                    <Flex gap={["40px", "15px"]} fd={["column", "row"]}>
                                        <NameField {...formProps} />
                                    </Flex>
                                    <EmailField {...formProps} isLocked={!!email} />
                                    <PasswordValidation input={values.password}>
                                        <InputField
                                            label="Password"
                                            fontFamily="GT Walsheim Pro"
                                            inputProps={{
                                                name: "password",
                                                "data-testid": "password",
                                                onChange: handleChange,
                                                value: values.password,
                                                onBlur: handleBlur,
                                                id: "text-field-id-password",
                                                type: isShowPasswordEnabled ? "text" : "password"
                                            }}
                                            rightChild={Eye}
                                            error={touched.password && errors.password}
                                        />
                                    </PasswordValidation>
                                    {!isEmpty(values.consents) && (
                                        <RegisterTNC
                                            mt="-16px"
                                            locale={locale}
                                            consents={values.consents}
                                            onChange={value => setFieldValue("termsAndConditions", value)}
                                        />
                                    )}
                                </Flex>
                            )}
                            {part === 2 && (
                                <Flex fd="column" width="100%" gap="24px" mt="24px">
                                    <DateOfBirthField {...formProps} />
                                    <FlexFields
                                        style={{
                                            gridTemplateColumns: "calc(70% - 15px) 30%"
                                        }}
                                    >
                                        <CountryField {...formProps} availableCountries={availableCountries} />
                                        <PostalCodeField {...formProps} mt="25px" />
                                    </FlexFields>
                                    <GenderField {...formProps} />
                                    <MobileNumberField
                                        mt="25px"
                                        {...formProps}
                                        countryCode={values.countryCode || "AU"}
                                    />
                                </Flex>
                            )}

                            {part === 1 && (
                                <Flex fd="column" mt="32px" ai="center">
                                    <PrimaryButton
                                        type="button"
                                        width={["100%", "250px"]}
                                        data-testid="signup-next-button"
                                        onClick={() => checkIfUserExist(values.email)}
                                        disabled={disableCreateAccount(errors, values) || validatingEmail}
                                    >
                                        {isSignUpFlow({ type }).setup ? "Next" : "Create Account"}
                                    </PrimaryButton>
                                    <FooterText>
                                        Already have an account? <Link to={routes.root}>Sign in</Link>
                                    </FooterText>
                                </Flex>
                            )}

                            {part === 2 && (
                                <Flex fd={["column", "row"]} mt="32px" ai="center" gap="16px" jc="space-between">
                                    <SecondaryButton
                                        type="button"
                                        color="hpPrimaryPurple"
                                        width={["100%", "200px"]}
                                        onClick={() => setPart(1)}
                                        data-testid="signup-back-button"
                                    >
                                        Back
                                    </SecondaryButton>

                                    <PrimaryButton
                                        type={"submit"}
                                        width={["100%", "200px"]}
                                        data-testid="signup-button"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Sign Up
                                    </PrimaryButton>
                                </Flex>
                            )}
                        </Form>
                    );
                }}
            </Formik>
            <img src={`${PORTAL_IMAGE}/create-account.svg`} />
        </SignupFormContainer>
    );
};

// -------------------------------------------------------------------------

SignupForm.propTypes = {
    onSubmit: PropTypes.func,
    userPeep: PropTypes.func,
    userDetails: PropTypes.object
};

export default SignupForm;
