import React from "react";
import PropTypes from "prop-types";

import { Box } from "rebass";

// ----------------------------------------------------------------------------

import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import LineChart from "components/Templates/TrendChart/Line";
import {
    WEIGHT,
    HEIGHT,
    QRISK3,
    ACTIVITY,
    DIABETES,
    BODY_FAT,
    HEART_RATE,
    ALCOHOL_RISK,
    BLOOD_PRESSURE,
    BODY_MASS_INDEX
} from "constants/trend-charts";
import OutcomeHistory from "components/Templates/OutcomeHistory";

import weightProps from "./weightProps";
import bodyFatProps from "./bodyFatProps";
import bpProps from "./bpProps";
import bmiProps from "./bmiProps";
import alcoholRiskProps from "./alcoholRiskProps";
import heartRateProps from "./heartRateProps";
import qriskProps from "./qriskProps";
import diabetesProps from "./diabetesProps";
import activityProps from "./activityProps";
import heightProps from "./heightProps";

// ----------------------------------------------------------------------------

export const TYPE_PROPS = {
    [WEIGHT]: weightProps,
    [BODY_FAT]: bodyFatProps,
    [BLOOD_PRESSURE]: bpProps,
    [BODY_MASS_INDEX]: bmiProps,
    [ALCOHOL_RISK]: alcoholRiskProps,
    [HEART_RATE]: heartRateProps,
    [QRISK3]: qriskProps,
    [DIABETES]: diabetesProps,
    [ACTIVITY]: activityProps,
    [HEIGHT]: heightProps
};

const Charts = ({
    title,
    settings,
    chartType,
    healthChecks,
    lastMeasurementDate,
    latestHealthCheckData,
    nextOutcomeComponent: NextOutcomeComponent,
    ...rest
}) => {
    return (
        <Box width="100%" textAlign="left" mb={["30px", "60px"]} {...rest}>
            <HeadingLabel fontSize={["28px", "30px"]} fontWeight="500">
                Your {title} History
            </HeadingLabel>
            {NextOutcomeComponent}
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px">
                An analytical overview of your health over time.
            </NormalLabel>

            <LineChart
                settings={settings}
                healthChecks={healthChecks}
                latestHealthCheckData={latestHealthCheckData}
                {...(TYPE_PROPS[chartType] ?? {})}
                data-testid={`history-${chartType.toLowerCase()}`}
            >
                {({ filter, historyProps }) => (
                    <OutcomeHistory
                        py="24px"
                        filter={filter}
                        {...historyProps}
                        lastMeasurementDate={lastMeasurementDate}
                    />
                )}
            </LineChart>
        </Box>
    );
};

Charts.propTypes = {
    title: PropTypes.string,
    settings: PropTypes.object,
    chartType: PropTypes.string,
    healthChecks: PropTypes.array,
    lastMeasurementDate: PropTypes.string,
    nextOutcomeComponent: PropTypes.node,
    latestHealthCheckData: PropTypes.object
};

export default Charts;
