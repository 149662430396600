import React, { useState } from "react";

import { useLocation } from "react-router";
import styled from "styled-components";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";

import PageLayout from "components/PageLayout";
import ImproveLaunch from "./ImproveLaunch";
import BreadCrumbs from "views/BreadCrumbs";
import { Flex, Text } from "rebass";
import ImproveCard from "./ImproveCard";
import { IMPROVE_DATA } from "./ImproveCardData";
import FlexWithGap from "components/Flex";

const StyledText = styled(Text)`
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
`;

const StyledGrid = styled(Flex)`
    display: grid;
    row-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
`;

const ImproveView = () => {
    const [destination, setDestination] = useState(null);

    const { search = "" } = useLocation();
    const searchParams = new URLSearchParams(search);
    const wants = searchParams.get("wants");
    const provider = searchParams.get("provider");

    if (wants) {
        return <ImproveLaunch wants={wants} provider={provider} replace />;
    }

    if (destination) {
        window.scrollTo(0, 0);
        return <ImproveLaunch wants={destination} />;
    }

    return (
        <PageLayout>
            <BreadCrumbs />
            <FlexWithGap fd="column" gap="16px" mb={["24px", "32px"]}>
                <HeadingLabel fontSize={["28px", "34px"]} fontWeight="500">
                    Online Health Plans
                </HeadingLabel>
                <StyledText fontSize={["13px", "15px"]}>
                    Take a look at some of SiSU Health’s plans designed to help you live a healthier life! You can sign
                    up for these plans here and be sent tips, recipes, and guides on how to improve your health.
                </StyledText>
            </FlexWithGap>
            <StyledGrid flexWrap="wrap">
                {IMPROVE_DATA.map(card => (
                    <ImproveCard
                        key={card.title}
                        title={card.title}
                        text={card.text}
                        image={card.image}
                        handleClick={setDestination}
                        link={card.link}
                        isPremOnlyCard={card.isPremOnlyCard}
                        isCoreOnlyCard={card.isCoreOnlyCard}
                    />
                ))}
            </StyledGrid>
        </PageLayout>
    );
};

export default ImproveView;
