import React, { useState } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Flex } from "rebass";
import { useHistory } from "react-router";
import { Form, Formik } from "formik";

// --------------------------------------------------------------------------

import routes from "constants/routes";
import PortalModal from "components/PortalModal";
import { validationSchema } from "./validationSchema";
import InputField from "components/InputField";

// --------------------------------------------------------------------------

import { FieldContainer } from "./styled";
import InputRegexValidation from "views/PasswordValidation";
import { PrimaryButton, SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { OptionHeadingField } from "./securityCard";
import { Margin } from "components/styled-components";
import { PORTAL_IMAGE } from "constants/imagePath";

// --------------------------------------------------------------------------

const SecurityCardForm = ({ onSubmit }) => {
    const history = useHistory();
    const initialValue = useSelector(state => state.auth.citizen);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowCurrentPassword, setCurrentPass] = useState(false);
    const [isShowNewPassword, setNewPass] = useState(false);
    const [isShowConfirmPassword, setConfirmPass] = useState(false);

    function Eye(value, setFunc) {
        return (
            <img
                src={`${PORTAL_IMAGE}/login/${value ? "Hide" : "Show"}Eye.svg`}
                onClick={() => {
                    setFunc(!value);
                }}
                style={{ cursor: "pointer" }}
            />
        );
    }

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={{
                existingPassword: "",
                newPassword: "",
                newPasswordConfirm: "",
                ...initialValue.userProfile
            }}
        >
            {({ isSubmitting, handleSubmit, values, handleChange, errors, handleBlur, touched }) => {
                const isButtonDisabled = () => {
                    if (
                        values.existingPassword === "" ||
                        values.newPassword === "" ||
                        values.newPasswordConfirm === ""
                    ) {
                        if (values.email === initialValue.userProfile.email) {
                            return true;
                        } else return false;
                    }
                    if (values.email === initialValue.userProfile.email) {
                        if (
                            values.existingPassword === "" ||
                            values.newPassword === "" ||
                            values.newPasswordConfirm === ""
                        ) {
                            return true;
                        } else if (values.newPassword !== values.newPasswordConfirm) {
                            return true;
                        } else return false;
                    }
                };
                const backButton = () => {
                    if (isButtonDisabled()) {
                        if (
                            values.existingPassword !== "" ||
                            values.newPassword !== "" ||
                            values.newPasswordConfirm !== ""
                        ) {
                            return setIsModalOpen(true);
                        }
                        return history.replace(routes.myAccount.security);
                    } else {
                        return setIsModalOpen(true);
                    }
                };
                return (
                    <>
                        <Form onSubmit={handleSubmit} style={{ gridColumn: "span 2" }}>
                            {/*TODO unhidden once SU-3970 is done*/}
                            {/*<OptionHeadingField>*/}
                            {/*    <div className="heading-main">Change Email</div>*/}
                            {/*    <div className="heading-sub">*/}
                            {/*        We’ll use this email to notify you of purchase and service updates.*/}
                            {/*    </div>*/}
                            {/*</OptionHeadingField>*/}
                            {/*<Margin mTop={["52px"]}>*/}
                            {/*    <FieldContainer>*/}
                            {/*        <InputField*/}
                            {/*            label="Email"*/}
                            {/*            inputProps={{*/}
                            {/*                name: "email",*/}
                            {/*                placeholder: "Enter Email",*/}
                            {/*                "data-testid": "email",*/}
                            {/*                onChange: handleChange,*/}
                            {/*                value: values.email,*/}
                            {/*                onBlur: handleBlur,*/}
                            {/*                errors: errors.email*/}
                            {/*            }}*/}
                            {/*            error={touched.email ? errors.email : ""}*/}
                            {/*        />*/}
                            {/*    </FieldContainer>*/}
                            {/*</Margin>*/}
                            <Margin mTop={["44px"]}>
                                <OptionHeadingField>
                                    <div className="heading-main">Change Password</div>
                                    <div className="heading-sub">
                                        This is your universal password for both Scripts Now &amp; SiSU Health Portal.
                                    </div>
                                </OptionHeadingField>
                            </Margin>
                            <Margin mTop={["52px"]}>
                                <FieldContainer>
                                    <InputField
                                        label="Current Password"
                                        fontFamily="GT Walsheim Pro"
                                        inputProps={{
                                            name: "existingPassword",
                                            placeholder: "Enter Current Password",
                                            onChange: handleChange,
                                            onBlur: handleBlur,
                                            value: values.existingPassword,
                                            errors: errors.existingPassword,
                                            "data-testid": "current-password",
                                            type: isShowCurrentPassword ? "text" : "password"
                                        }}
                                        rightChild={Eye(isShowCurrentPassword, setCurrentPass)}
                                        error={touched.existingPassword ? errors.existingPassword : ""}
                                    />
                                </FieldContainer>
                            </Margin>
                            <Margin mTop={["52px"]}>
                                <FieldContainer>
                                    <InputRegexValidation input={values.newPassword}>
                                        <InputField
                                            label="New Password"
                                            fontFamily="GT Walsheim Pro"
                                            inputProps={{
                                                name: "newPassword",
                                                onChange: handleChange,
                                                onBlur: handleBlur,
                                                value: values.newPassword,
                                                errors: errors.newPassword,
                                                "data-testid": "new-password",
                                                placeholder: "Enter New Password",
                                                type: isShowNewPassword ? "text" : "password"
                                            }}
                                            rightChild={Eye(isShowNewPassword, setNewPass)}
                                            error={touched.newPassword ? errors.newPassword : ""}
                                        />
                                    </InputRegexValidation>
                                </FieldContainer>
                            </Margin>
                            <Margin mTop={["52px"]}>
                                <FieldContainer>
                                    <InputField
                                        label="Confirm New Password"
                                        fontFamily="GT Walsheim Pro"
                                        inputProps={{
                                            name: "newPasswordConfirm",
                                            onChange: handleChange,
                                            onBlur: handleBlur,
                                            value: values.newPasswordConfirm,
                                            errors: errors.newPasswordConfirm,
                                            "data-testid": "confirm-password",
                                            placeholder: "Confirm New Password",
                                            type: isShowConfirmPassword ? "text" : "password"
                                        }}
                                        rightChild={Eye(isShowConfirmPassword, setConfirmPass)}
                                        error={touched.newPasswordConfirm ? errors.newPasswordConfirm : ""}
                                    />
                                </FieldContainer>
                            </Margin>
                            <Margin mTop={["60px"]}>
                                <Flex justifyContent="space-between" pr={["0px", "20px"]} pl={["0px", "20px"]}>
                                    <SecondaryButton
                                        width={window.innerWidth < 800 ? "138px" : undefined}
                                        onClick={backButton}
                                        type="button"
                                    >
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton
                                        width={window.innerWidth < 800 ? "138px" : ""}
                                        type="submit"
                                        disabled={isButtonDisabled() || isSubmitting}
                                        data-testid="save-button"
                                    >
                                        Save
                                    </PrimaryButton>
                                </Flex>
                            </Margin>
                        </Form>
                        <PortalModal closeModal={() => setIsModalOpen(false)} modalIsOpen={isModalOpen} />
                    </>
                );
            }}
        </Formik>
    );
};

SecurityCardForm.propTypes = {
    onSubmit: PropTypes.func
};

export default SecurityCardForm;
