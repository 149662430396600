import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(glucose) {
        this._validateValue(glucose);
        this._glucose = glucose;
    }

    /**
     * Returns the given Glucose value
     * @returns {Number}
     */
    get() {
        return this._glucose;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "FASTING_GLUCOSE";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_FASTING_GLUCOSE";
    }

    /**
     * Validates the given Glucose value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(glucose) {
        if (!(typeof glucose === "number") || Number.isNaN(glucose) || glucose < 0) {
            throw new InvalidArgumentError("Glucose must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the given Glucose is in the 'low' range
     *
     * @returns {boolean}
     */
    isLow() {
        return this._glucose < 4.0;
    }

    /**
     * Returns if the given Glucose is in the 'healthy' range
     *
     * @returns {boolean}
     */
    isHealthy() {
        return this._glucose >= 4.0 && this._glucose < 6.0;
    }

    /**
     * Returns if the given Glucose is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._glucose >= 6.0 && this._glucose < 7.0;
    }

    /**
     * Returns if the given Glucose is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        return this._glucose >= 7.0;
    }

    /**
     * Returns an object representing various data for each Glucose range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            low: {
                key: "low",
                label: "Low",
                range: "< 4.00 mmol/L"
            },

            healthy: {
                key: "healthy",
                label: "Healthy",
                range: "4.00 - 5.99 mmol/L"
            },

            high: {
                key: "high",
                label: "High",
                range: "6.00 - 6.99 mmol/L"
            },

            veryHigh: {
                key: "veryHigh",
                label: "Very High",
                range: "7.00+ mmol/L"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.range;
                break;
            case this.isHealthy():
                info = SiSU.ranges.healthy.range;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.range;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.range;
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.key;
                break;
            case this.isHealthy():
                info = SiSU.ranges.healthy.key;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.key;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.key;
                break;
        }

        return info;
    }
}
