import React from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------------

import { formatDate, getOptObject, moment } from "utilities/commonUtils";
import { DAY, MONTHS, YEAR } from "./constants";

import Select from "../../Select";
import FlexWithGap from "components/Flex";
import { Box, Text } from "rebass";
import { ErrorNotification } from "../../Error";

// -----------------------------------------------------------------------------------

export const DateOfBirthField = ({ values, errors, touched, setFieldValue, handleBlur, ...rest }) => {
    const getDate = dob => formatDate(dob, "DD");
    const getMonth = dob => moment(dob, "YYYY-MM-DD").month();
    const getYear = dob => moment(dob, "YYYY-MM-DD").year();
    const date = (year, month, day) => formatDate(new Date(year, month, day), "YYYY-MM-DD");

    const isDateValid = moment(values.dateOfBirth).isValid();

    const defaultDay = isDateValid ? getDate(values.dateOfBirth) : "";
    const defaultMonth = isDateValid ? MONTHS[getMonth(values.dateOfBirth)] : "";
    const defaultYear = isDateValid ? getYear(values.dateOfBirth) : "";

    const hasError = (touched.day || touched.month || touched.year) && errors.dateOfBirth;

    return (
        <Box width="100%" textAlign="left" color="blackTints700" {...rest}>
            <Text fontSize="15px" fontWeight="semiBold" textAlign="left" mb="8px">
                Date of Birth
            </Text>
            <FlexWithGap height="50px" width="100%" gap="16px">
                <Select
                    name="day"
                    placeholder="Day"
                    data-testid="select-day"
                    options={DAY.map(day => getOptObject(day))}
                    onChange={option => {
                        const dob = values.dateOfBirth || `2000-01-${option.value}`;
                        const value = date(getYear(dob), getMonth(dob), option.value);
                        setFieldValue("dateOfBirth", value);
                    }}
                    onBlur={() => handleBlur({ target: { name: "day" } })}
                    defaultValue={getOptObject(defaultDay)}
                    isError={!!hasError}
                />

                <Select
                    name="month"
                    placeholder="Month"
                    data-testid="select-month"
                    options={MONTHS.map(day => getOptObject(day))}
                    onChange={option => {
                        const dob = values.dateOfBirth || `2000-${option.value}-01`;
                        const value = date(getYear(dob), MONTHS.indexOf(option.value), getDate(dob));
                        setFieldValue("dateOfBirth", value);
                    }}
                    onBlur={() => handleBlur({ target: { name: "month" } })}
                    defaultValue={getOptObject(defaultMonth)}
                    isError={!!hasError}
                />

                <Select
                    name="year"
                    placeholder="Year"
                    data-testid="select-year"
                    options={YEAR.map(year => getOptObject(year))}
                    onChange={option => {
                        const dob = values.dateOfBirth || `${option.value}-01-01`;
                        const value = date(option.value, getMonth(dob), getDate(dob));
                        setFieldValue("dateOfBirth", value);
                    }}
                    onBlur={() => handleBlur({ target: { name: "year" } })}
                    defaultValue={getOptObject(defaultYear)}
                    isError={!!hasError}
                />
            </FlexWithGap>
            <ErrorNotification boolExp={!!hasError}>{errors.dateOfBirth}</ErrorNotification>
        </Box>
    );
};

DateOfBirthField.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.object,
    initialValues: PropTypes.object
};
