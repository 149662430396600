import {
    ACTIVITY,
    ALCOHOL_RISK,
    BLOOD_PRESSURE,
    BODY_FAT,
    BODY_MASS_INDEX,
    DIABETES,
    HEART_RATE,
    QRISK3,
    TOTAL_CHOLESTEROL,
    HDL_CHOLESTEROL,
    NON_FASTING_GLUCOSE,
    STRESS_PSS4,
    WEIGHT,
    HRA_SOCIAL_SCORE,
    HRA_EMOTIONAL_SCORE,
    HRA_LIFESTYLE_SCORE,
    SLEEP,
    SMOKING,
    FASTING_GLUCOSE,
    WAIST_CIRCUMFERENCE,
    HRA_HEALTH_SCORE,
    HEART_AGE,
    TEN_YEAR_CVD_RISK,
    TDEE,
    MUSCLE_MASS,
    HRA_STRESS,
    SLEEP_TROUBLE,
    SLEEP_MEAL,
    SLEEP_CAFFEINE,
    HRA_ACTIVITY,
    SUDDEN_ANXIETY,
    WORK_ISSUES,
    THREE_MEALS,
    SUBSTANTIAL_BREAKFAST,
    PROCESSED_MEAT,
    FRUIT_VEG,
    FISH_LEGUME,
    FAT_FRIED,
    SWEET_CONSUMPTION,
    HRA_ALCOHOL
} from "constants/trend-charts";

export const METRIC_INDICATOR = {
    [BLOOD_PRESSURE]: {
        title: "Blood Pressure",
        themeKey: "NationalHeartFoundationAustralia"
    },
    [BODY_MASS_INDEX]: {
        title: "Body Mass Index",
        themeKey: "bodyMassIndex"
    },
    [BODY_FAT]: {
        title: "Body Fat",
        themeKey: "bodyFat"
    },
    [HEART_RATE]: {
        title: "Heart Rate",
        themeKey: "SiSUHeartRate"
    },
    [DIABETES]: {
        title: "Diabetes Risk",
        themeKey: "AustralianDiabetesRisk"
    },
    [STRESS_PSS4]: {
        title: "Perceived Stress",
        themeKey: "PSS4"
    },
    [ALCOHOL_RISK]: {
        title: "Alcohol",
        themeKey: "Alcohol"
    },
    [ACTIVITY]: {
        title: "Activity Check",
        themeKey: "Activity"
    },
    [QRISK3]: {
        title: "Heart & Stroke Risk",
        themeKey: "Qrisk"
    },
    [TOTAL_CHOLESTEROL]: {
        title: "Total Cholestrol",
        themeKey: "SiSUTotalCholesterol"
    },
    [HDL_CHOLESTEROL]: {
        title: "HDL Cholestrol",
        themeKey: "SiSUHDLCholesterol"
    },
    [NON_FASTING_GLUCOSE]: {
        title: "Non Fasting Glucose",
        themeKey: "SiSUNonFastingGlucose"
    },
    [WEIGHT]: {
        title: "Weight",
        themeKey: "weight"
    },
    [HRA_SOCIAL_SCORE]: {
        title: "Social Index",
        themeKey: "SiSUHRAIndex"
    },
    [HRA_EMOTIONAL_SCORE]: {
        title: "Emotional Index",
        themeKey: "SiSUHRAIndex"
    },
    [HRA_LIFESTYLE_SCORE]: {
        title: "Lifestyle Index",
        themeKey: "SiSUHRAIndex"
    },
    [SLEEP]: {
        title: "Your NightOwl™ Results",
        themeKey: "Sleep"
    },
    [SMOKING]: {
        title: "Smoking Status",
        themeKey: "Smoking"
    },
    [FASTING_GLUCOSE]: {
        title: "Fasting Glucose",
        themeKey: "FastingGlucose"
    },
    [WAIST_CIRCUMFERENCE]: {
        title: "Waist Circumference",
        themeKey: "WaistCircumference"
    },
    [HRA_HEALTH_SCORE]: {
        title: "SiSU Health Score",
        themeKey: "SiSUHealthRiskAssessment"
    },
    [HEART_AGE]: {
        title: "Heart Age",
        themeKey: "HeartAge"
    },
    [TEN_YEAR_CVD_RISK]: {
        title: "Risk",
        themeKey: "HeartAge"
    },
    [TDEE]: {
        title: "Daily Energy Use",
        themeKey: "DailyEnergyUse"
    },
    [MUSCLE_MASS]: {
        title: "Muscle Mass",
        themeKey: "muscleMass"
    },
    [HRA_STRESS]: {
        title: "Stress",
        themeKey: "HRAStress"
    },
    [SLEEP_TROUBLE]: {
        title: "Healthy Sleep",
        themeKey: "SleepTrouble"
    },
    [SLEEP_MEAL]: {
        title: "Healthy Sleep",
        themeKey: "SleepMeal"
    },
    [SLEEP_CAFFEINE]: {
        title: "Healthy Sleep",
        themeKey: "SleepCaffeine"
    },
    [HRA_ACTIVITY]: {
        title: "Activity Levels",
        themeKey: "HRAActivity"
    },
    [SUDDEN_ANXIETY]: {
        title: "Anxiety",
        themeKey: "SuddenAnxiety"
    },
    [WORK_ISSUES]: {
        title: "Work/Life Balance",
        themeKey: "WorkIssues"
    },
    [THREE_MEALS]: {
        title: "Three Main Meals",
        themeKey: "ThreeMealsPerDay"
    },
    [SUBSTANTIAL_BREAKFAST]: {
        title: "Substantial Breakfast",
        themeKey: "SubstantialBreakfast"
    },
    [PROCESSED_MEAT]: {
        title: "Processed Meat Consumption",
        themeKey: "ProcessedMeatPerWeek"
    },
    [FRUIT_VEG]: {
        title: "Fruit and Veg Consumption",
        themeKey: "FruitAndVegPerDay"
    },
    [FISH_LEGUME]: {
        title: "Fish/Legume Consumption",
        themeKey: "FishLegumePerWeek"
    },
    [FAT_FRIED]: {
        title: "Fat/Fried Food Consumption",
        themeKey: "FatFriedPerWeek"
    },
    [SWEET_CONSUMPTION]: {
        title: "Sweet Consumption",
        themeKey: "SweetConsumption"
    },
    [HRA_ALCOHOL]: {
        title: "Alcohol Consumption",
        themeKey: "HRAAlcohol"
    }
};
