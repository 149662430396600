import React, { useContext } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// --------------------------------------------------------------------

import { NO_NETWORK_ERROR_MESSAGE, REQUEST_FAILED_ERROR_MESSAGE } from "@sisuwellness/utilities/constants/messages";
import { setCitizen } from "@sisuwellness/connected-components/actions/auth";
import routes from "constants/routes";
import { Margin } from "components/styled-components";
import APIClient from "utilities/APIClient";

import ProfileForm from "./profileForm";
import ProfileCard, { EditField, OptionField } from "./profileCard";
import { MessageContext } from "components/Context";
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "constants/messages";

// --------------------------------------------------------------------

const Profile = ({ forms }) => {
    const dispatch = useDispatch();

    const history = useHistory();

    const { handleMessage } = useContext(MessageContext);

    const { citizen } = useSelector(state => state.auth);
    const { firstName, lastName, gender, dateOfBirth } = citizen.userProfile;

    const optFields = [
        {
            main: "Name",
            sub: `${firstName} ${lastName}`
        },
        {
            main: "Gender at Birth",
            sub: gender
        },
        {
            main: "Date of Birth",
            sub: moment(dateOfBirth).format("DD/MM/YYYY")
        }
    ];

    const handleSubmit = async (values, { setSubmitting }) => {
        const updatedUserProfile = {
            ...citizen.userProfile,
            ...values
        };
        try {
            const response = await APIClient.auth.updateUserProfile(updatedUserProfile);
            if (response.succeeded()) {
                dispatch(setCitizen({ ...citizen, userProfile: { ...updatedUserProfile } }));
                history.replace(routes.myAccount.personalDetails);
                handleMessage(MESSAGE_SUCCESS, "Your User Profile was successfully updated.");
            } else if (response.isNetworkFailure()) {
                handleMessage(MESSAGE_ERROR, NO_NETWORK_ERROR_MESSAGE);
            } else if (response.isBadRequest()) {
                const responseBody = await response.body();
                let errorMessage = "Please ensure all fields are filled out correctly.";
                if (responseBody.length && responseBody[0].value) {
                    errorMessage = responseBody[0].value;
                }
                handleMessage(MESSAGE_ERROR, errorMessage);
            } else {
                handleMessage(MESSAGE_ERROR, REQUEST_FAILED_ERROR_MESSAGE);
            }
        } catch (error) {
            handleMessage(MESSAGE_ERROR, error.message);
        } finally {
            setSubmitting(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <Margin mTop={[forms ? "24px" : "16px"]}>
            <ProfileCard heading="Profile">
                {!forms && <EditField to={routes.myAccount.profile}>Edit</EditField>}
                {!forms ? (
                    optFields.map(option => (
                        <OptionField key={option.main}>
                            <div className="field-main">{option.main}</div>
                            <div className="field-sub">{option.sub}</div>
                        </OptionField>
                    ))
                ) : (
                    <Margin mTop={["-8px"]} style={{ gridColumn: "span 2" }}>
                        <Margin mTop={["20px"]}>
                            <ProfileForm onSubmit={handleSubmit} />
                        </Margin>
                    </Margin>
                )}
            </ProfileCard>
        </Margin>
    );
};

Profile.propTypes = {
    forms: PropTypes.bool.isRequired
};

Profile.defaultProps = {
    forms: false
};

export default Profile;
