import Survey from "../Survey/Survey";

export default class Consent {
    /**
     *
     * @param schema
     * @param userJourneyPage
     */
    constructor(schema, userJourneyPage = null) {
        this._reportCallback = null;
        this._userJourneyPage = userJourneyPage;
        this._response = null;
        this._schema = schema;
        this._userAccepted = null;
        this._shownToUser = false;
        this._completed = false;

        if (this._schema && this._schema.survey) {
            // do not pass through user journey page.. unhelpful nested lifecycle stuff inside
            this._survey = new Survey(this._schema.survey);
        }
    }

    static createFromJSON(pojo) {
        const instance = new Consent({});
        instance.fromJSON(pojo);
        return instance;
    }

    getSurvey() {
        return this._survey;
    }

    /**
     * Really need a better way of articulating and doing this very Health Station centric
     * approach
     */
    /* istanbul ignore next */
    setReportCallback(callback) {
        this._reportCallback = callback;
    }

    /**
     * Really need a better way of articulating and doing this very Health Station centric
     * approach
     */
    /* istanbul ignore next */
    report(status) {
        if (this._reportCallback == null) {
            return;
        }

        this._reportCallback(this, status);
    }

    getShowInHealthStationMenu() {
        return false;
    }

    //TODO: refactor to getSurveyCode()
    getCode() {
        /* istanbul ignore next */
        return this.getSurvey() ? this.getSurvey().getCode() : "";
    }

    getSurveyId() {
        return this._schema.surveyId;
    }

    /**
     * Really need a better way of articulating and doing this very Health Station centric
     * need resulting from not using polymorphic User Journey Pages
     *
     * @return {String}
     */
    getDisplayName() {
        /* istanbul ignore next */
        return this.getSurvey() ? this.getSurvey().getDisplayName() : "";
    }

    /**
     * Returns if this consent is using the 'templateOne' design
     *
     * @returns {boolean}
     */
    usesTemplateOne() {
        return this._schema.templateName === "templateOne";
    }

    /**
     * Returns if this consent is using the 'mobileNumber' design
     *
     * @returns {boolean}
     */
    usesTemplateMobileNumber() {
        return this._schema.templateName === "mobileNumber";
    }

    /**
     *
     * @returns {Response}
     */
    getResponse() {
        // TODO: inelegant but hey
        /* istanbul ignore next */
        if (!this._response && this._userJourneyPage) {
            this._response = this._userJourneyPage.getUserJourney().getResponse();
        }

        return this._response;
    }

    /**
     *
     * @param {Response} response
     */
    setResponse(response) {
        this._response = response;

        if (this._survey) {
            this._survey.setResponse(response);
        }
    }

    getPlainText() {
        /* istanbul ignore next */
        return this._schema.configuration.hasOwnProperty("plaintext")
            ? this._schema.configuration.plaintext.text
            : null;
    }

    isTypeRegistration() {
        return this._schema.type === "registration";
    }

    isTypeSubscription() {
        return this._schema.type === "subscription";
    }

    isTypeCustomerRequest() {
        return this._schema.type === "customerRequest";
    }

    /**
     * Returns if the type of this consent is for Client User Data Reports
     *
     * @return {boolean}
     */
    isTypeClientUserDataReport() {
        // dataShare is previous nomenclature that needs to remain for Response that have this value.
        return this._schema.type === "dataShare" || this._schema.type === "clientUserDataReport";
    }

    toJSON() {
        return {
            schema: this._schema,
            userAccepted: this._userAccepted,
            shownToUser: this._shownToUser,
            completed: this._completed,
            survey: this._survey ? this._survey.toJSON() : null
        };
    }

    /**
     *
     * @param {Object} pojo
     * @param {Response} response
     */
    fromJSON(pojo, response) {
        this._schema = pojo.schema;
        this._userAccepted = pojo.userAccepted;
        this._shownToUser = pojo.shownToUser;
        this._completed = pojo.completed;

        // SU-578 FIXME: I hate myself for this.... NEED TO MOVE ANSWERS TO THE RESPONSE UTILITY!!
        if (pojo.survey) {
            // assumes survey has been created already as instance will have schema consumed as part of constructor
            response.hydrateSurvey(this._survey, pojo.survey);
        }

        this.setResponse(response);
    }

    get userAccepted() {
        return this._userAccepted;
    }

    set userAccepted(bool) {
        this._userAccepted = bool;
        if (this.getResponse()) {
            this.getResponse().trackConsent(this);
        }
    }

    get shownToUser() {
        return this._shownToUser;
    }

    set shownToUser(bool) {
        this._shownToUser = bool;
        /* istanbul ignore next */
        if (this.getResponse()) {
            this.getResponse().trackConsent(this);
        }
    }

    get configuration() {
        return this._schema.configuration;
    }

    get templateName() {
        return this._schema.templateName;
    }

    get consentId() {
        return this._schema.consentId;
    }

    get clientId() {
        return this._schema.clientId;
    }

    get code() {
        return this._schema.code;
    }

    get name() {
        return this._schema.name;
    }

    get displayName() {
        return this._schema.displayName;
    }

    get type() {
        return this._schema.type;
    }

    /**
     *
     * @param bool
     */
    set completed(bool) {
        this._completed = bool;
    }

    /**
     *
     * @returns {boolean}
     */
    get completed() {
        return this._completed;
    }

    /**
     *
     * @param bool
     */
    /* istanbul ignore next */
    setHealthStationMode(bool) {
        if (this.getSurvey()) {
            this.getSurvey().setHealthStationMode(bool);
        }
    }

    /**
     *
     * @returns {boolean}
     */
    /* istanbul ignore next */
    getHealthStationMode() {
        return this.getSurvey() ? this.getSurvey().getHealthStationMode() : false;
    }

    /**
     *
     * @return {number|null}
     */
    /* istanbul ignore next */
    get marketoListId() {
        // consents captured in responses will not necessarily have this property
        return this._schema.hasOwnProperty("marketoListId") ? this._schema.marketoListId : null;
    }

    /**
     *
     * @returns {*|{valid: Boolean, schema: Object, validRuleGroups: {name: String, icon: string, validRules: Array}[]}}
     */
    evaluatePreConditionRuleSet() {
        if (this._userJourneyPage == null) {
            return null;
        }

        return this._userJourneyPage.evaluatePreConditionRuleSet();
    }
}
