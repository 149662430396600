import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Keyboard from "./base/Keyboard";
import LatinLayout from "./base/layouts/LatinLayout";
import KeyboardButton from "./base/KeyboardButton";
import "./base/Keyboard.css";

// TODO: change to styled-component
export default class NumericKeyboard extends PureComponent {
    static propTypes = {
        inputNode: PropTypes.any.isRequired,
        onClick: PropTypes.func,
        onSubmit: PropTypes.func,
        onBack: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onBack.bind(this);
        this.onBack = this.onSubmit.bind(this);
    }

    onSubmit(event) {
        if (this.props.onSubmit) {
            this.props.onSubmit(event, this.props.inputNode);
        }
    }

    onBack(event) {
        if (this.props.onBack) {
            this.props.onBack(event, this.props.inputNode);
        }
    }

    render() {
        return (
            <Keyboard
                isNumeric={true}
                inputNode={this.props.inputNode}
                leftButtons={[<KeyboardButton onClick={this.onBack} value="Back" key="BackButton" />]}
                rightButtons={[
                    <KeyboardButton
                        onClick={this.onSubmit}
                        value="Submit"
                        classes="keyboard-submit-button"
                        key="SubmitButton"
                    />
                ]}
                onClick={this.props.onClick}
                layouts={[LatinLayout]}
            />
        );
    }
}
