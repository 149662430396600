import React from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------

import { MediaQuery } from "@sisuwellness/web-components";

// ----------------------------------------------------------------------------

import PageContainer from "../PageContainer";
import ThinHeader from "../ThinHeader";
import routes from "constants/routes";

import { PORTAL_IMAGE } from "constants/imagePath";
import { HeaderContainer, ItemsContainer, Button, MemberLogin, MobileLogo } from "./styled";

// ----------------------------------------------------------------------------

const BaseMemberViewWithoutMenu = ({ children, margin, width, padding, loading }) => {
    const content = (
        <>
            <MediaQuery devices={["desktop"]}>
                <HeaderContainer loading={loading}>
                    <ItemsContainer>
                        <img
                            data-testid="header-logo-without-menu"
                            src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`}
                            onClick={() => window.location.assign(routes.healthHub)}
                        />
                    </ItemsContainer>
                    <ItemsContainer>
                        <Button onClick={() => window.location.assign("/")}>Member Sign In</Button>
                    </ItemsContainer>
                </HeaderContainer>
            </MediaQuery>
            <MediaQuery devices={["mobile", "tablet"]}>
                <MobileLogo>
                    <img
                        data-testid="mobile-header-logo"
                        src={`${PORTAL_IMAGE}/logo-mobile.svg`}
                        onClick={() => window.location.assign("/")}
                    />
                    <MemberLogin onClick={() => window.location.assign("/")}>Member Sign In</MemberLogin>
                </MobileLogo>
            </MediaQuery>
        </>
    );
    return (
        <>
            <ThinHeader data-testid="base-member-without-menu">{content}</ThinHeader>
            <PageContainer id={"page-container"} margin={margin} width={width} padding={padding}>
                {children}
            </PageContainer>
        </>
    );
};

// ----------------------------------------------------------------------------

BaseMemberViewWithoutMenu.displayName = "Page Container Without Member Menu";
BaseMemberViewWithoutMenu.propTypes = {
    children: PropTypes.any,
    margin: PropTypes.string,
    width: PropTypes.string,
    padding: PropTypes.string,
    loading: PropTypes.oneOf([PropTypes.string, PropTypes.bool])
};

export default BaseMemberViewWithoutMenu;
