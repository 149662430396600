import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { withTheme } from "styled-components";
import { useHistory } from "react-router";

// ----------------------------------------------------------------------------

import auth from "utilities/auth";
import MenuItems from "./MenuItems";
import { nameInitials } from "utilities/leftNav";
import { PORTAL_IMAGE } from "constants/imagePath";
import { Card, DropDownButton, DropDownItem, InitialText, ListContainer, NameCircle } from "./styled";

// ----------------------------------------------------------------------------

const DropDownMenu = () => {
    const { firstName, lastName } = useSelector(state => state.auth.citizen.userProfile);
    const initial = nameInitials(firstName, lastName);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);

    const logout = () => {
        auth.unauthenticate();

        toggling();
        window.location.assign(MenuItems.logOut.path);
    };

    const myAccount = () => {
        toggling();
        history.push(MenuItems.myAccount.path);
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    });

    const handleDocumentClick = () => isOpen && setIsOpen(false);

    return (
        <>
            <Card onClick={toggling}>
                <NameCircle>
                    <InitialText data-testid="header-initials">{initial.toUpperCase()}</InitialText>
                </NameCircle>
                {firstName && <InitialText margin="10px">{`${firstName} ${lastName}`}</InitialText>}
                <DropDownButton data-testid="header-dropdown">
                    <img src={`${PORTAL_IMAGE}/authenticatedHeader/Chevrons_chevron-${isOpen ? "up" : "down"}.svg`} />
                </DropDownButton>
                {isOpen && (
                    <ListContainer data-testid="header-dropdown-list">
                        <DropDownItem onClick={myAccount}>
                            <div className="item" data-testid="header-route-myAccount">
                                <img src={`${PORTAL_IMAGE}/authenticatedHeader/settings.svg`} />
                                <div>{MenuItems.myAccount.title}</div>
                            </div>
                        </DropDownItem>
                        <DropDownItem onClick={logout}>
                            <div className="item" data-testid="header-route-logOut">
                                <img src={`${PORTAL_IMAGE}/authenticatedHeader/log-out.svg`} />
                                <div>{MenuItems.logOut.title}</div>
                            </div>
                        </DropDownItem>
                    </ListContainer>
                )}
            </Card>
        </>
    );
};

DropDownMenu.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(DropDownMenu);
