import React from "react";
import { UnitConverter } from "@sisuwellness/utilities";
import { path } from "ramda";
import { Cell, Header } from "./commonElements";

const handleCellConstruct = (row, settings) => {
    const weight = path(["value"], row);

    switch (settings.unitMeasurement) {
        case "imperial": {
            const weightInIm = UnitConverter.kgToStLbs(weight);
            return (
                <Cell
                    unit=""
                    row={row}
                    data-testid="weight"
                    value={weight ? `${weightInIm.stones} st ${weightInIm.pounds.toFixed(1)} lbs` : null}
                />
            );
        }
        default:
            return <Cell data-testid="weight" row={row} value={weight ? weight.toFixed(1) : null} unit="kgs" />;
    }
};

export const weightDef = (iconTypes, settings) => ({
    minWidth: 92,
    accessor: "weight",
    Cell: row => handleCellConstruct(row, settings),
    Header: () => <Header label="Weight" iconType={iconTypes.weight} />
});
