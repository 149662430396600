export default class SiSU {
    /**
     * Constructor
     */
    constructor(answer) {
        this._answer = answer;
    }

    /**
     * Returns the given HRA alcohol value
     * @returns {Array}
     */
    get() {
        return this._answer;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "HRA_ALCOHOL";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_HRA_ALCOHOL";
    }

    /**
     * Returns if the given HRA alcohol answer is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return !this.isHigh();
    }

    /**
     * Returns if the given HRA alcohol answer is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        let value = false;
        if (this._answer[0]) {
            if (this._answer[0].toLowerCase() === "29+ (4+ drinks /day)") {
                return true;
            }
            value = ["15-28 (2-4 drinks /day)"].includes(this._answer[0].toLowerCase());
        } else {
            return false;
        }
        if (this._answer[1]) {
            value = ["more on weekend", "all on weekend"].includes(this._answer[1].toLowerCase()) && value;
        } else {
            return false;
        }
        return value;
    }

    /**
     * Returns an object representing various data for each stress range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            normal: {
                key: "normal",
                label: "Normal",
                info: "Normal alcohol consumption"
            },
            high: {
                key: "high",
                label: "High",
                info: "15+ (2+ drinks /day) and more/all on weekend"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get rangeInfo() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.info;
            case this.isHigh():
                return SiSU.ranges.high.info;
        }
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get key() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.key;
            case this.isHigh():
                return SiSU.ranges.high.key;
        }
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get label() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.label;
            case this.isHigh():
                return SiSU.ranges.high.label;
        }
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    // eslint-disable-next-line
    get riskLevel() {
        const totalRiskLevel = 2;
        switch (true) {
            case this.isNormal():
                return 1 / (totalRiskLevel + 1);
            case this.isHigh():
                return 2 / (totalRiskLevel + 1);
        }
    }
}
