import { PORTAL_IMAGE } from "constants/imagePath";

import TdeeAnimation from "assets/lottie/locked_tdee.json";
import HeartAgeAnimation from "assets/lottie/locked_heart_age.json";

const PREMIUM_UPGRADE = {
    HEADING: "Premium account. Only $4.99 / month",
    SUBHEADING: "Cancel anytime.",
    LOGO: {
        SRC: `${PORTAL_IMAGE}/premium/status_plus_logo.svg`,
        ALT: "sisu_plus_logo.svg"
    }
};

export const LOCKED_HEART_AGE = {
    CTA: "Unlock Heart Age",

    ANIMATION: HeartAgeAnimation,

    LOCKED_IMAGES: {
        imageDesktop: "locked_heart_age_desktop.svg",
        imageMobile: "locked_heart_age_mob.png"
    },

    UNDERSTANDING_IMPACT: {
        IMPACT_SECTION: {
            HEADING: "Understand the impact of your latest Health Check",
            SUBHEADING: "Currently cardiovascular disease (CVD) affects more than 4 million Australians.",
            DESCRIPTION:
                "See whether your risk of cardiovascular disease is higher or lower than expected for your age.*",
            DESCRIPTION_POINTS: [
                "• Get health recommendations on how to reduce your heart risks",
                "• Unlock another 2 data points to assess your health holistically"
            ]
        },
        PREMIUM_UPGRADE,
        FOOTER: "*For people aged below 30 or over 74 years old, Heart Age results are indicative only"
    }
};

export const LOCKED_TDEE = {
    CTA: "Unlock Daily Energy Use",

    ANIMATION: TdeeAnimation,

    LOCKED_IMAGES: {
        imageDesktop: "dailyEnergy/locked-desktop.svg",
        imageMobile: "dailyEnergy/locked-mobile.svg"
    },

    UNDERSTANDING_IMPACT: {
        IMPACT_SECTION: {
            HEADING: "What’s your average Daily Energy Use?",
            SUBHEADING:
                "If you’re serious about weight management, start by understanding how much energy your body burns everyday.",
            DESCRIPTION: "Find out your Basal Metabolic Rate* and your average Daily Energy Use with SiSU Plus™ now.",
            DESCRIPTION_POINTS: [
                "• Get support with weight management",
                "• Learn what contributes to your metabolism",
                "• Unlock another 2 data points to assess your health holistically"
            ]
        },
        PREMIUM_UPGRADE,
        FOOTER: "*SiSU Health uses the Katch-Mcardle formula to calculate Basal Metabolic Rate."
    }
};

export const LOCKED_BODY_COMPOSITION = {
    CTA: "Unlock Body Composition",

    LOCKED_IMAGES: {
        imageDesktop: "bodyComposition/locked-desktop.svg",
        imageMobile: "bodyComposition/locked-mobile.svg"
    },

    UNDERSTANDING_IMPACT: {
        IMPACT_SECTION: {
            HEADING: "What are the benefits of knowing your body composition?",
            SUBHEADING:
                "If you’re serious about weight management or training, start by understanding your body composition baseline.",
            DESCRIPTION: "Track your Muscle Mass with SiSU Plus™ now.",
            DESCRIPTION_POINTS: [
                "• Set realistic goals, accurately monitor progress, and stay motivated",
                "• Measure changes in your body make-up in serious detail",
                "• Make more informed decisions to improve your body composition"
            ]
        },
        PREMIUM_UPGRADE,
        FOOTER: "*SiSU Health uses a 2-point bioelectrical impedance analysis to determine your body composition."
    }
};
