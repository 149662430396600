import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(cholesterol) {
        this._validateValue(cholesterol);
        this._cholesterol = cholesterol;
    }

    /**
     * Returns the given Cholesterol value
     * @returns {Number}
     */
    get() {
        return this._cholesterol;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "HDL_CHOLESTEROL";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_HDL_CHOLESTEROL";
    }

    /**
     * Validates the given Cholesterol value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(cholesterol) {
        if (!(typeof cholesterol === "number") || Number.isNaN(cholesterol) || cholesterol < 0) {
            throw new InvalidArgumentError("Cholesterol must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the given Cholesterol is in the 'low' range
     *
     * @returns {boolean}
     */
    isLow() {
        return this._cholesterol < 1;
    }

    /**
     * Returns if the given Cholesterol is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._cholesterol > 1;
    }

    /**
     * Returns an object representing various data for each Cholesterol range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            low: {
                key: "low",
                label: "Low",
                range: "< 1.00 mmol/L"
            },

            normal: {
                key: "normal",
                label: "Normal",
                range: "1.00+ mmol/L"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.range;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.range;
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.key;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.key;
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.label;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.label;
                break;
        }

        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 2,
            riskLevel = 0;
        switch (true) {
            case this.isLow():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }
}
