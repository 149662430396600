import InvalidArgumentError from "../../Errors/InvalidArgumentError";
import { NumberValidator, Validation } from "../../Validation";

/**
 * @see https://academic.oup.com/ajcn/article/72/3/694/4729363
 */
export default class Oxford {
    /**
     *
     * @param age
     * @param gender
     * @param bodyFatPercent
     */
    constructor(age, gender, bodyFatPercent) {
        this._validateNumber(age, 16, 125); // arbitrary pick of ages. 16 is allowable/supported min age. 125 is human heat death
        this._validateNumber(bodyFatPercent, 0, 100); // can't be more than 100% fat
        this._validateGender(gender);

        this._age = age;
        this._isMale = gender.toLowerCase() === "male";
        this._bodyFatPercentage = bodyFatPercent;
        this._labels = {
            low: "Low",
            normal: "Normal",
            high: "High",
            veryHigh: "Very High"
        };
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "BODY_FAT";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "OXFORD_BF";
    }

    /**
     * Validates the given score value. It is intended to be a positive number between 0-100
     *
     * @returns {boolean}
     * @private
     */
    _validateNumber(number, min, max) {
        const validation = new Validation();
        validation.addValidator(new NumberValidator({ min, max }));

        if (validation.validate(number).length) {
            throw new InvalidArgumentError(`must be a valid number between ${min} - ${max}`);
        }

        return true;
    }

    /**
     *
     * @param string
     * @returns {boolean}
     * @private
     */
    _validateGender(string) {
        if (!(typeof string === "string") || (string.toLowerCase() !== "male" && string.toLowerCase() !== "female")) {
            throw new InvalidArgumentError('gender must be "male" or "female"');
        }

        return true;
    }

    _isAgeYoung() {
        return this._age < 40;
    }

    _isAgeMidLifeCrisis() {
        return this._age >= 40 && this._age < 60;
    }

    _isAgeOld() {
        return this._age >= 60;
    }

    /**
     * Returns if the score is in the 'low' range
     *
     * @returns {boolean}
     */
    isLow() {
        let isLow = false;
        switch (true) {
            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage < 21:
            case !this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage < 23:
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage < 24:
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage < 8:
            case this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage < 11:
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage < 13:
                isLow = true;
                break;
        }

        return isLow;
    }

    /**
     * Returns if the score is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        let isNormal = false;
        switch (true) {
            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 21 && this._bodyFatPercentage < 33:
            case !this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 23 &&
                this._bodyFatPercentage < 34:
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 24 && this._bodyFatPercentage < 36:
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 8 && this._bodyFatPercentage < 20:
            case this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 11 &&
                this._bodyFatPercentage < 22:
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 13 && this._bodyFatPercentage < 25:
                isNormal = true;
                break;
        }

        return isNormal;
    }

    /**
     * Returns an object representing all labels corresponding to this guideline defination
     *
     * @returns {object}
     */
    static get labels() {
        return {
            low: "Low",
            normal: "Normal",
            high: "High",
            veryHigh: "Very High"
        };
    }

    /**
     * Returns if the score is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        let isHigh = false;
        switch (true) {
            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 33 && this._bodyFatPercentage < 39:
            case !this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 34 &&
                this._bodyFatPercentage < 40:
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 36 && this._bodyFatPercentage < 42:
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 20 && this._bodyFatPercentage < 25:
            case this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 22 &&
                this._bodyFatPercentage < 28:
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 25 && this._bodyFatPercentage < 30:
                isHigh = true;
                break;
        }

        return isHigh;
    }

    /**
     * Returns if the score is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        let isVeryHigh = false;
        switch (true) {
            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 39:
            case !this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage >= 40:
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 42:
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 25:
            case this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage >= 28:
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 30:
                isVeryHigh = true;
                break;
        }

        return isVeryHigh;
    }

    /**
     * Returns an object representing various data for each Body Fat range (for given age/gender)
     *
     * @returns {object}
     */
    get ranges() {
        const originalBodyFatPercentage = this._bodyFatPercentage;

        let dummyInput = {};
        let ranges = {};

        // generate dummy body fat input for given age/gender
        switch (true) {
            // Females
            case !this._isMale && this._isAgeYoung():
                dummyInput = { low: 1, normal: 32, high: 38, veryHigh: 39 };
                break;
            case !this._isMale && this._isAgeMidLifeCrisis():
                dummyInput = { low: 1, normal: 33, high: 39, veryHigh: 40 };
                break;
            case !this._isMale && this._isAgeOld():
                dummyInput = { low: 1, normal: 35, high: 41, veryHigh: 42 };
                break;

            // Males
            case this._isMale && this._isAgeYoung():
                dummyInput = { low: 1, normal: 19, high: 24, veryHigh: 25 };
                break;
            case this._isMale && this._isAgeMidLifeCrisis():
                dummyInput = { low: 1, normal: 21, high: 27, veryHigh: 28 };
                break;
            case this._isMale && this._isAgeOld():
                dummyInput = { low: 1, normal: 24, high: 29, veryHigh: 30 };
                break;
        }

        for (let key in dummyInput) {
            let dummyRange = dummyInput[key];
            this._bodyFatPercentage = dummyRange;

            ranges[key] = {
                key: this.key,
                label: this.getRatingName(),
                range: this.rangeInfo
            };
        }

        // NB: reinstate the original body fat
        this._bodyFatPercentage = originalBodyFatPercentage;
        return ranges;
    }

    get key() {
        let ratingName = null;
        switch (true) {
            case this.isLow():
                ratingName = "low";
                break;
            case this.isNormal():
                ratingName = "normal";
                break;
            case this.isHigh():
                ratingName = "high";
                break;
            case this.isVeryHigh():
                ratingName = "veryHigh";
                break;
        }

        /* istanbul ignore if */
        if (!ratingName) {
            throw new Error(
                `Unsupported body fat range: body-fat: ${this._bodyFatPercentage}, age: ${this._age}, gender: ${this._isMale}`
            );
        }

        return ratingName;
    }

    getRatingName() {
        return this._labels[this.key];
    }

    get label() {
        return this.getRatingName();
    }

    get rangeInfo() {
        let info = null;
        switch (true) {
            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage < 21:
                info = "< 21%";
                break;
            case !this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage < 23:
                info = "< 23%";
                break;
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage < 24:
                info = "< 24%";
                break;
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage < 8:
                info = "< 8%";
                break;
            case this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage < 11:
                info = "< 11%";
                break;
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage < 13:
                info = "< 13%";
                break;

            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 21 && this._bodyFatPercentage < 33:
                info = "21% - 33%";
                break;
            case !this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 23 &&
                this._bodyFatPercentage < 34:
                info = "23% - 34%";
                break;
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 24 && this._bodyFatPercentage < 36:
                info = "24% - 36%";
                break;
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 8 && this._bodyFatPercentage < 20:
                info = "8% - 20%";
                break;
            case this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 11 &&
                this._bodyFatPercentage < 22:
                info = "11% - 22%";
                break;
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 13 && this._bodyFatPercentage < 25:
                info = "13% - 25%";
                break;
            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 33 && this._bodyFatPercentage < 39:
                info = "33% - 39%";
                break;
            case !this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 34 &&
                this._bodyFatPercentage < 40:
                info = "34% - 40%";
                break;
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 36 && this._bodyFatPercentage < 42:
                info = "36% - 42%";
                break;
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 20 && this._bodyFatPercentage < 25:
                info = "20% - 25%";
                break;
            case this._isMale &&
                this._isAgeMidLifeCrisis() &&
                this._bodyFatPercentage >= 22 &&
                this._bodyFatPercentage < 28:
                info = "22% - 28%";
                break;
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 25 && this._bodyFatPercentage < 30:
                info = "25% - 30%";
                break;

            // Women
            case !this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 39:
                info = "39%+";
                break;
            case !this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage >= 40:
                info = "40%+";
                break;
            case !this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 42:
                info = "42%+";
                break;
            // Men (eslint required because of this comment line)
            // eslint ignore no-fallthrough
            case this._isMale && this._isAgeYoung() && this._bodyFatPercentage >= 25:
                info = "25%+";
                break;
            case this._isMale && this._isAgeMidLifeCrisis() && this._bodyFatPercentage >= 28:
                info = "28%+";
                break;
            case this._isMale && this._isAgeOld() && this._bodyFatPercentage >= 30:
                info = "30%+";
                break;
        }

        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 4,
            riskLevel = 0;

        switch (true) {
            case this.isLow():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
            case this.isHigh():
                riskLevel = 3 / (totalRiskLevel + 1);
                break;
            case this.isVeryHigh():
                riskLevel = 4 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }

    /**
     * Determines whether the provided age/gender/body fat yields a risk
     *
     * @return {boolean}
     */
    isAtRisk() {
        return this.isHigh() || this.isVeryHigh();
    }
}
