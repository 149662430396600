import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18resources from "@sisuwellness/web-components/components/i18n";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: i18resources,
        lng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
