import { SET_HEALTH_STATIONS, SET_SWLHT_HEALTH_STATIONS } from "constants/actions";

const initialState = {
    stations: []
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_HEALTH_STATIONS:
        case SET_SWLHT_HEALTH_STATIONS:
            return {
                ...state,
                stations: payload
            };

        default: {
            return state;
        }
    }
};
