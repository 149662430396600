import React, { useContext, useState } from "react";

import { useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { Flex, Text, Box, Image } from "rebass";
import { useHistory } from "react-router";

//--------------------------------------------------------------------------------------------------------

import { SquaredCard as Card, BannerCard, StatusCard } from "@sisuwellness/ui/src/components/Cards";
import { MediaQuery } from "@sisuwellness/web-components";
import { HeadingLabel, NormalLabel, StatusLabel as Status } from "@sisuwellness/ui/src/components/Labels";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import Pie from "@sisuwellness/ui/src/components/Pie";

import PageLayout from "components/PageLayout";
import ProgressView from "components/ProgressView";
import { PORTAL_IMAGE } from "constants/imagePath";
import routes from "constants/routes";
import { HRA_HEALTH_SCORE } from "constants/trend-charts";
import getMetric from "utilities/metrics/metric";
import { formatDate, hexToRgba, pickAttribute } from "utilities/commonUtils";
import { getHRARecommendation } from "utilities/healthRiskScore";
import BreadCrumbs from "views/BreadCrumbs";
import HealthInfoPopup from "views/HealthRiskAssessment/HealthInfoPopup";

import ScoreBreakdown from "./breakdown";
import RecommendationList from "./recommendation";
import HraCalculationView from "views/HraResult/HraCalculation";

//--------------------------------------------------------------------------------------------------------

const HraResult = () => {
    const { citizen } = useSelector(state => state.auth);
    const theme = useContext(ThemeContext);
    const history = useHistory();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const healthRiskScore = pickAttribute(["analytics", "healthRiskScore"], citizen);

    if (!healthRiskScore) return <HraCalculationView />;

    const { color: riskColor, risk, rating } = getMetric(HRA_HEALTH_SCORE, { healthRiskScore });

    const recommendation = getHRARecommendation(healthRiskScore);

    // date for last hra taken
    const date = formatDate(pickAttribute(["lifestyleScore", "updatedAt"], citizen.data), "DD/MM/YYYY");

    return (
        <PageLayout>
            <BreadCrumbs mb="16px" />
            <HeadingLabel fs={["22px", "34px"]} fontWeight="500">
                Your SiSU Health Score
            </HeadingLabel>
            <NormalLabel fontSize={["13px", "15px"]} mt="16px" mb="24px" fontWeight="400">
                The higher your score, the less risk you have of developing negative health conditions.
            </NormalLabel>
            <Card width="100%" height="fit-content" p={["24px", "40px"]} mb={["30px", "60px"]} data-testid="hra-score">
                <BannerCard bg={hexToRgba(riskColor, 0.1)} borderRadius="10px" p="24px">
                    <Flex>
                        <Pie
                            progressBgColor={theme.colours.hpWhite.hex}
                            progress={risk}
                            progressFgColor={riskColor}
                            size={80}
                        >
                            <Text fontSize={["20px", "22px"]} fontWeight="500" mt="3px">
                                {healthRiskScore}
                            </Text>
                        </Pie>
                        <Flex flexDirection="column" ml="24px" justifyContent="center">
                            <StatusCard bg={riskColor} width="128px" py="4px" height="fit-content" minHeight="40px">
                                <Status color="white" fs="16px" textAlign="center" mt="3px">
                                    {rating}
                                </Status>
                            </StatusCard>
                            <MediaQuery devices={["tablet", "desktop"]}>
                                <Text mt="16px" fontSize="15px" color={theme.colours.hpGrayishBlueText.hex}>
                                    {recommendation}
                                </Text>
                            </MediaQuery>
                        </Flex>
                    </Flex>

                    <MediaQuery devices={["mobile"]}>
                        <Text mt="16px" fontSize="13px" color={theme.colours.hpGrayishBlueText.hex} textAlign="center">
                            {recommendation}
                        </Text>
                    </MediaQuery>
                </BannerCard>
                <Box mt="32px">
                    <ProgressView
                        type={HRA_HEALTH_SCORE}
                        datum={{ healthRiskScore }}
                        modalHeading="What does the score mean?"
                    />
                </Box>
            </Card>

            <ScoreBreakdown
                citizenData={citizen.data}
                height="fit-content"
                mb={["30px", "60px"]}
                data-testid="hra-breakdown"
            />

            <RecommendationList
                recommendations={citizen.recommendations}
                mb={["30px", "60px"]}
                data-testid="hra-recommendations"
            />

            <HeadingLabel fs={["22px", "34px"]} mb="24px">
                Ready to Refresh Your SiSU Health Score?
            </HeadingLabel>
            <Card
                width="100%"
                height="fit-content"
                p={["24px", "40px"]}
                mb={["130px", "160px"]}
                data-testid="hra-retake"
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                    flexDirection={["column", "row"]}
                >
                    <Box>
                        <Text fontSize={["13px", "15px"]} color={theme.colours.hpGrayishBlueText.hex}>
                            See how your health index results have changed since your last assessment on {date}.
                        </Text>
                        <Flex mt="16px" alignItems="center" justifyContent="flex-start" mb={["24px", "", "0px"]}>
                            <Image
                                mr="5px"
                                src={`${PORTAL_IMAGE}/information.svg`}
                                height={["12.7px", "16px"]}
                                width={["12.7px", "16px"]}
                                alt="information"
                            />
                            <Text
                                textAlign="center"
                                onClick={() => setIsModalOpen(true)}
                                color={theme.colours.hpPrimaryPurple.hex}
                                css={{ cursor: "pointer" }}
                                fontSize={["11px", "14px"]}
                                fontWeight={["400", "500"]}
                            >
                                What specific health information will I need?
                            </Text>
                        </Flex>
                    </Box>
                    <Box width={["235px", "250px"]}>
                        <PrimaryButton width="100%" onClick={() => history.push(routes.healthRiskOnboarding)}>
                            <Text fontSize={["14px", "16px"]}>Retake Assessment</Text>
                        </PrimaryButton>
                    </Box>
                </Flex>
            </Card>
            <HealthInfoPopup modalIsOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
        </PageLayout>
    );
};

export default HraResult;
