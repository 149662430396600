import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ThemeContext } from "styled-components";
import { useLocation } from "react-router";

// --------------------------------------------------------------------

import { Link } from "@sisuwellness/web-components";

// --------------------------------------------------------------------

import routes from "constants/routes";
import { BreadCrumb, BreadCrumbItem, BreadCrumbNav } from "./styled";
import Icons from "components/Icons";

// --------------------------------------------------------------------

const BreadCrumbMap = {
    "my-account": {
        displayName: "MY ACCOUNT DETAILS",
        linkTo: routes.myAccount.home
    },
    preferences: {
        displayName: "PREFERENCES",
        linkTo: routes.myAccount.preferences
    },
    security: {
        displayName: "SECURITY",
        linkTo: routes.myAccount.security
    },
    payments: {
        displayName: "PAYMENTS",
        linkTo: routes.myAccount.payments
    },
    general: {
        displayName: "GENERAL",
        linkTo: routes.myAccount.preferencesGeneral
    },
    "personal-details": {
        displayName: "PERSONAL DETAILS",
        linkTo: routes.myAccount.personalDetails
    },
    profile: {
        displayName: "PROFILE",
        linkTo: routes.myAccount.profile
    },
    address: {
        displayName: "ADDRESS",
        linkTo: routes.myAccount.address
    },
    "account-settings": {
        displayName: "ACCOUNT SETTINGS",
        linkTo: routes.myAccount.accountSettings
    },
    "health-checks": {
        displayName: "PAST HEALTH CHECKS",
        linkTo: routes.healthChecks
    },
    trends: {
        displayName: "DASHBOARD",
        linkTo: routes.healthHub
    },
    bmi: {
        displayName: "BODY MASS INDEX OVERVIEW",
        linkTo: "#"
    },
    "blood-pressure": {
        displayName: "BLOOD PRESSURE OVERVIEW",
        linkTo: "#"
    },
    "heart-rate": {
        displayName: "HEART RATE OVERVIEW",
        linkTo: "#"
    },
    "body-fat": {
        displayName: "BODY FAT OVERVIEW",
        linkTo: "#"
    },
    diabetes: {
        displayName: "DIABETES OVERVIEW",
        linkTo: "#"
    },
    weight: {
        displayName: "WEIGHT OVERVIEW",
        linkTo: "#"
    },
    qrisk: {
        displayName: "HEART & STROKE RISK OVERVIEW",
        linkTo: "#"
    },
    height: {
        displayName: "HEIGHT OVERVIEW",
        linkTo: "#"
    },
    alcohol: {
        displayName: "ALCOHOL OVERVIEW",
        linkTo: "#"
    },
    smoking: {
        displayName: "SMOKING OVERVIEW",
        linkTo: "#"
    },
    activity: {
        displayName: "ACTIVITY OVERVIEW",
        linkTo: "#"
    },
    "perceived-stress": {
        displayName: "PERCEIVED STRESS OVERVIEW",
        linkTo: "#"
    },
    improve: {
        displayName: "Online Health Plans",
        linkTo: "#"
    },
    "health-hub": {
        displayName: "DASHBOARD",
        linkTo: routes.healthHub
    },
    "hra-result": {
        displayName: "SISU HEALTH SCORE",
        linkTo: "#"
    },
    "billing-and-subscription": {
        displayName: "BILLING & SUBSCRIPTIONS",
        linkTo: "#"
    },
    "heart-age": {
        displayName: "HEART AGE",
        linkTo: "#"
    },
    "daily-energy": {
        displayName: "DAILY ENERGY USE",
        linkTo: "#"
    },
    rewards: {
        displayName: "REWARDS",
        linkTo: "#"
    },
    "body-composition": {
        displayName: "BODY COMPOSITION",
        linkTo: "#"
    },
    "muscle-mass": {
        displayName: "MUSCLE MASS",
        linkTo: "#"
    }
};

const BreadCrumbs = ({ extra, ...rest }) => {
    const { pathname } = useLocation();
    let paths = pathname.split("/");
    paths.shift(); // To skip the first empty item.
    const theme = useContext(ThemeContext);

    return (
        <BreadCrumb mb="16px" {...rest}>
            {paths.map((path, index) => {
                return (
                    <React.Fragment key={index}>
                        <Link to={BreadCrumbMap[path] ? BreadCrumbMap[path].linkTo : extra ? "#" : routes.root}>
                            <BreadCrumbItem key={index}>
                                {BreadCrumbMap[path] ? BreadCrumbMap[path].displayName : extra ? extra : "UNKNOWN"}
                            </BreadCrumbItem>
                        </Link>
                        {index != paths.length - 1 && (
                            <BreadCrumbNav>
                                <Icons
                                    chevron
                                    width="14px"
                                    height="14px"
                                    strokeWidth="3px"
                                    transform="rotate(-90deg)"
                                    stroke={theme.colours.hpPrimaryPurple.hex}
                                />
                            </BreadCrumbNav>
                        )}
                    </React.Fragment>
                );
            })}
        </BreadCrumb>
    );
};

BreadCrumbs.propTypes = {
    extra: PropTypes.string
};

export default BreadCrumbs;
