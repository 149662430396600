import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";

class LifestyleSocialIndex extends Component {
    render() {
        const { t, score: scoreProp, theme, date, ...rest } = this.props;
        let guideline = null;
        let guidelineStyle = null;
        const score = scoreProp ? scoreProp.toFixed() : null;

        if (score) {
            guideline = new Guidelines.HealthRiskAssessment.Guideline(scoreProp);
            guidelineStyle = theme.guidelines.SiSUHealthRiskAssessment[guideline.key];
        }

        return (
            <Attribute
                {...rest}
                t={t}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={t("Social Index")}
                date={date}
                primaryStatValue={score}
                primaryStatUnit={"%"}
                icon={theme.icons.types.social}
            />
        );
    }
}

LifestyleSocialIndex.displayName = "LifestyleSocialIndex Attribute";
LifestyleSocialIndex.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    score: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(LifestyleSocialIndex);
