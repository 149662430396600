import React from "react";
import { ThemeContext, withTheme } from "styled-components";
import { useParams } from "react-router";
import { useAsyncData } from "@sisuwellness/utilities/hooks";
import { PublicRecommendations, userJourneyMap as codes, Response } from "@sisuwellness/utilities";
import { handleResponse } from "@sisuwellness/utilities/front-end-utils/errors";
import { Box, Flex } from "rebass";
import LoadingViewWithoutMenu from "components/LoadingViewWithoutMenu";
import Root from "components/BaseMemberViewWithoutMenu";
import APIClient from "utilities/APIClient";
import PageTitle from "components/PageTitle";
import { Recommendation } from "@sisuwellness/web-components";
import parse from "html-react-parser";
import { useContext } from "react";
import InterpolatedRecommendation from "utilities/Recommendation";

const getResponse = async uuid => {
    const response = await APIClient.surveyResponses.retrieve(uuid);
    let result = await handleResponse(response, null);
    if (result && result.responseJson) {
        const responseUtility = new Response();
        responseUtility.fromJSON(result.responseJson);
        return responseUtility;
    }

    return result;
};

const getFeedback = response => {
    if (response == null) {
        return [];
    }

    const feedback = PublicRecommendations.Needs;
    const questionCodes = Object.values(codes.surveys.covid19.questions);
    const answeredQuestions = response.getAnsweredQuestions();
    if (Array.isArray(answeredQuestions) && answeredQuestions.length > 0) {
        const needsQuestions = answeredQuestions.filter(q => questionCodes.some(code => code === q.getCode()));
        const countryQuestion = needsQuestions.find(q => q.getCode() === codes.questionCodes.covid19Country);
        if (countryQuestion == null || countryQuestion.getAnswers().length === 0) {
            // Not able to determine the recommendations without the country
            return [];
        }

        const country = countryQuestion.getAnswers()[0].getRawAnswer();
        return needsQuestions.reduce((result, question) => {
            const answers = question.getAnswers();
            const answerRecommendations = answers
                .map(answer => feedback.get(question.getCode(), answer.getRawAnswer(), country))
                .filter(r => r.text.length > 0); // TODO: empty <div> ?
            return [...result, ...answerRecommendations];
        }, []);
    } else {
        return [];
    }
};

//eslint-disable-next-line
const RecommendationCard = ({ text, risk, title, priority, index }) => {
    const theme = useContext(ThemeContext);
    return (
        <Box mb={theme.cards.distanceBetweenCards} key={`recommendation-${risk}-${index}`} className="page-break">
            <Recommendation risk={risk} title={title} highPriority={priority >= 8} reverse={false}>
                {parse(text)}
            </Recommendation>
        </Box>
    );
};

const NeedsResult = () => {
    const { uuid } = useParams();
    const [{ isError, isLoading, data }] = useAsyncData(() => getResponse(uuid), null);

    const getLayout = () => {
        if (isError) {
            throw new Error(`Failed to load the Needs Survey results for ${uuid}`);
        }

        if (isLoading || data == null) {
            return <LoadingViewWithoutMenu />;
        }

        const recommendations = getFeedback(data).map(f => ({
            text: f.text,
            textPortal: f.text,
            url: f.urls[0],
            risk: "NOT_DEFINED",
            title: f.title || "",
            priority: 1,
            renderAsHTML: true
        }));

        return recommendations.length > 0 ? (
            <Root>
                <PageTitle>{"Based on your answers:"}</PageTitle>
                <Flex alignItems="flex-start" justifyContent="space-between" flexDirection={["column-reverse", "row"]}>
                    <Flex mt="10px" flex={"2 0 65%"} flexWrap="wrap" justifyContent="center" flexDirection="column">
                        {recommendations.map((recommendation, index) => (
                            <InterpolatedRecommendation key={index} index={index} recommendation={recommendation}>
                                <RecommendationCard />
                            </InterpolatedRecommendation>
                        ))}
                    </Flex>
                </Flex>
            </Root>
        ) : (
            <Root>
                <PageTitle>{"You are all good"}</PageTitle>
            </Root>
        );
    };

    return getLayout();
};

export default withTheme(NeedsResult);
export { getFeedback };
