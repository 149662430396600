import themes from "@sisuwellness/web-components/themes";
import routes from "./routes";

export const BLOOD_PRESSURE = "BLOOD_PRESSURE";
export const BODY_MASS_INDEX = "BODY_MASS_INDEX";
export const BODY_FAT = "BODY_FAT";
export const HEART_RATE = "HEART_RATE";
export const DIABETES = "DIABETES";
export const PSS4_STRESS = "PSS4_STRESS";
export const ACTIVITY = "ACTIVITY";
export const ALCOHOL = "ALCOHOL";
export const QRISK3 = "QRISK3";
export const ALCOHOL_RISK = "ALCOHOL_RISK";
export const TOTAL_CHOLESTEROL = "TOTAL_CHOLESTEROL";
export const HDL_CHOLESTEROL = "HDL_CHOLESTEROL";
export const NON_FASTING_GLUCOSE = "NON_FASTING_GLUCOSE";
export const STRESS_PSS4 = "STRESS_PSS4";
export const WEIGHT = "WEIGHT";
export const HRA_LIFESTYLE_SCORE = "HRA_LIFESTYLE_SCORE";
export const HRA_EMOTIONAL_SCORE = "HRA_EMOTIONAL_SCORE";
export const HRA_SOCIAL_SCORE = "HRA_SOCIAL_SCORE";
export const HRA_HEALTH_SCORE = "HRA_HEALTH_SCORE";

export const THREE_MEALS = "THREE_MEALS";
export const SUBSTANTIAL_BREAKFAST = "SUBSTANTIAL_BREAKFAST";
export const PROCESSED_MEAT = "PROCESSED_MEAT";
export const FRUIT_VEG = "FRUIT_VEG";
export const FISH_LEGUME = "FISH_LEGUME";
export const FAT_FRIED = "FAT_FRIED";
export const SWEET_CONSUMPTION = "SWEET_CONSUMPTION";
export const HRA_ALCOHOL = "HRA_ALCOHOL";

export const HRA_STRESS = "STRESS";
export const SLEEP_TROUBLE = "SLEEP_TROUBLE";
export const SLEEP_MEAL = "SLEEP_MEAL";
export const SLEEP_CAFFEINE = "SLEEP_CAFFEINE";
export const HRA_ACTIVITY = "HRA_ACTIVITY";
export const SUDDEN_ANXIETY = "SUDDEN_ANXIETY";
export const WORK_ISSUES = "WORK_ISSUES";

export const SLEEP = "SLEEP";
export const HEIGHT = "HEIGHT";
export const SMOKING = "SMOKING";
export const WAIST_CIRCUMFERENCE = "WAIST_CIRCUMFERENCE";
export const FASTING_GLUCOSE = "FASTING_GLUCOSE";
export const HEART_AGE = "HEART_AGE";
export const TEN_YEAR_CVD_RISK = "TEN_YEAR_CVD_RISK";
export const TDEE = "TDEE";
export const BODY_COMPOSITION = "BODY_COMPOSITION";
export const MUSCLE_MASS = "MUSCLE_MASS";

export const VIEW_ID_CHART_TYPE_MAP = {
    "blood-pressure": {
        type: BLOOD_PRESSURE,
        title: "Blood Pressure",
        link: routes.trend.bloodPressure
    },
    bmi: {
        type: BODY_MASS_INDEX,
        title: "Body Mass Index",
        link: routes.trend.bmi
    },
    "heart-rate": {
        type: HEART_RATE,
        title: "Heart Rate",
        link: routes.trend.heartRate
    },
    diabetes: {
        type: DIABETES,
        title: "Diabetes",
        link: routes.trend.diabetes
    },
    "body-fat": {
        type: BODY_FAT,
        title: "Body Fat",
        link: routes.trend.bodyFat
    },
    weight: {
        type: WEIGHT,
        title: "Weight",
        link: routes.trend.weight
    },
    qrisk: {
        type: QRISK3,
        title: "Heart & Stroke Risk",
        link: routes.trend.qrisk
    },
    height: {
        type: HEIGHT,
        title: "Height",
        link: routes.trend.height
    },
    alcohol: {
        type: ALCOHOL_RISK,
        title: "Alcohol Intake",
        link: routes.trend.alcohol
    },
    smoking: {
        type: SMOKING,
        title: "Smoking",
        link: routes.trend.smoking
    },
    activity: {
        type: ACTIVITY,
        title: "Physical Activity"
    },
    "perceived-stress": {
        type: STRESS_PSS4,
        title: "Perceived Stress"
    }
};

export const FILTER_ONE_MONTH = "FILTER_ONE_MONTH";
export const FILTER_ONE_YEAR = "FILTER_ONE_YEAR";
export const FILTER_ALL_TIME = "FILTER_ALL_TIME";

export const CHART_FILTERS = {
    [FILTER_ONE_MONTH]: "Month",
    [FILTER_ONE_YEAR]: "Year",
    [FILTER_ALL_TIME]: "All Time"
};
export const STRATEGIES = [BLOOD_PRESSURE, BODY_MASS_INDEX];

export const COMPOSITION_COLOR = {
    Muscle: themes.colours.hpPinkLemonade.hex,
    Fat: themes.colours.hpFountainBlue.hex,
    Other: themes.colours.hpDarkCloud.hex
};
