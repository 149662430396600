import React, { Component } from "react";
import PropTypes from "prop-types";
import FlexCardWithIcon, { InnerContainer } from "./FlexCardWithIcon";
import styled, { withTheme } from "styled-components";
import Icon from "../Icons/Icon";
import Heading from "../Heading";
import Link from "../Link";
import { Guidelines } from "@sisuwellness/utilities";

const StyledTitle = styled(Heading)`
    color: ${props => props.theme.colours.flat.teal.hex};
`;

const StyledLink = styled(Link)`
    color: ${props => props.theme.colours.flat.blue.hex};
`;

const RecommendationBody = styled.div`
    max-width: 100%;
    word-break: break-word;
`;

const RecommendationLink = styled.div`
    max-width: 100%;
    margin-top: 1em;
`;

const StyledInnerContainer = styled(InnerContainer)`
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
`;

class Recommendation extends Component {
    static displayName = "Recommendation Card";

    static defaultProps = {
        reverse: true
    };

    static propTypes = {
        theme: PropTypes.any,
        title: PropTypes.string,
        risk: PropTypes.string,
        tall: PropTypes.bool,
        children: PropTypes.any.isRequired,
        listUrl: PropTypes.string,
        highPriority: PropTypes.bool,
        reverse: PropTypes.bool.isRequired
    };

    getIconTypeFromRisk(risk) {
        let iconType = null;

        const { theme } = this.props;

        switch (risk) {
            case "BODY_MASS_INDEX":
                iconType = theme.icons.types.bmi;
                break;

            case "BLOOD_PRESSURE":
                iconType = theme.icons.types.bloodPressure;
                break;

            case "HEART_RATE":
                iconType = theme.icons.types.heartRate;
                break;

            case "BODY_FAT":
                iconType = theme.icons.types.bodyFat;
                break;

            case "DIABETES":
                iconType = theme.icons.types.diabetesRisk;
                break;

            case "WAIST_CIRCUMFERENCE":
                iconType = theme.icons.types.waist;
                break;

            case "TOTAL_CHOLESTEROL":
            case "HDL_CHOLESTEROL":
                iconType = theme.icons.types.cholesterol;
                break;

            case "FASTING_GLUCOSE":
            case "NON_FASTING_GLUCOSE":
                iconType = theme.icons.types.glucose;
                break;

            case "SMOKING":
                iconType = theme.icons.types.smoking;
                break;

            case Guidelines.Alcohol.RiskCode:
                iconType = theme.icons.types.alcohol;
                break;

            case Guidelines.Activity.RiskCode:
                iconType = theme.icons.types.activity;
                break;

            case Guidelines.Qrisk.RiskCode:
                iconType = theme.icons.types.qrisk;
                break;

            case Guidelines.PSS4.RiskCode:
                iconType = theme.icons.types.mentalHealth;
                break;

            default:
                iconType = theme.icons.types.recommendation;
                break;
        }

        return iconType;
    }

    render() {
        const { risk, title: titleProp, highPriority, theme, listUrl, reverse, tall, children, ...rest } = this.props;
        const iconType = this.getIconTypeFromRisk(risk);

        let title = null;
        if (titleProp) {
            let icon = null;
            if (highPriority) {
                icon = (
                    <Icon
                        type={theme.icons.types.error}
                        colour={theme.colours.flat.darkRose.hex}
                        size={theme.icons.sizes.micro}
                    />
                );
            }
            title = (
                <StyledTitle renderAs="h6" mb="0.5em">
                    {titleProp}&nbsp;{icon}
                </StyledTitle>
            );
        }

        let listLink = null;
        if (listUrl) {
            listLink = (
                <StyledLink to={listUrl}>
                    View all&nbsp;
                    <Icon
                        type={theme.icons.types.arrowWithTail}
                        colour={theme.colours.flat.blue.hex}
                        size={theme.icons.sizes.micro}
                    />
                </StyledLink>
            );
        }

        return (
            <FlexCardWithIcon
                {...rest}
                iconType={iconType}
                iconBackground={theme.colours.flat.teal.hex}
                reverse={reverse}
                tall={tall}
                containerFlexAlign="stretch"
            >
                <StyledInnerContainer>
                    <RecommendationBody>
                        {title}
                        {children}
                    </RecommendationBody>
                    <RecommendationLink>{listLink}</RecommendationLink>
                </StyledInnerContainer>
            </FlexCardWithIcon>
        );
    }
}

export default withTheme(Recommendation);
