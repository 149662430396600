import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Box, Text } from "rebass";
import { ThemeContext } from "styled-components";

// ------------------------------------------------------------------------------------------------

import { useUserHealthCheckData } from "views/MuscleMass";
import { MUSCLE_MASS } from "constants/trend-charts";
import OutcomeOverview from "components/OutcomeOverview";
import { pickAttribute } from "utilities/commonUtils";
import { SectionHeaders } from "views/HeartAge/commonElements";

// ------------------------------------------------------------------------------------------------

const MuscleMassOverview = () => {
    const { latestHealthCheck } = useUserHealthCheckData();
    const theme = useContext(ThemeContext);
    let lastMeasurementDate = pickAttribute(["updatedAt"], latestHealthCheck);

    const outcomeProps = { type: MUSCLE_MASS, datum: latestHealthCheck, lastMeasurementDate, theme };

    return (
        <Box>
            <SectionHeaders height="43px" width="100%" px="16px">
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    Skeletal Muscle Overview
                </Text>
            </SectionHeaders>
            <OutcomeOverview {...outcomeProps} mt={["32px", "24px"]} mb={["30px", "60px"]} />
        </Box>
    );
};

MuscleMassOverview.propTypes = { setWeightGoal: PropTypes.func };

export default MuscleMassOverview;
