import { compose, filter, nth, keys, toPairs } from "ramda";

const TotalRiskLevel = 4;

// See Reference: https://sisuwellness.atlassian.net/wiki/spaces/PREM/pages/1866006533/Muscle+Mass+Reference+Ranges
const REFERENCE_RANGE = {
    male: [
        // Age 18 - 39
        {
            low: { upperBound: 33.3 },
            normal: { lowerBound: 33.3, upperBound: 39.4 },
            high: { lowerBound: 39.4, upperBound: 44.1 },
            veryHigh: { lowerBound: 44.1 }
        },
        // Age 40 - 59
        {
            low: { upperBound: 33.1 },
            normal: { lowerBound: 33.1, upperBound: 39.2 },
            high: { lowerBound: 39.2, upperBound: 43.9 },
            veryHigh: { lowerBound: 43.9 }
        },
        // Age 60 - 80
        {
            low: { upperBound: 32.9 },
            normal: { lowerBound: 32.9, upperBound: 39 },
            high: { lowerBound: 39, upperBound: 43.7 },
            veryHigh: { lowerBound: 43.7 }
        }
    ],
    female: [
        // Age 18 - 39
        {
            low: { upperBound: 24.3 },
            normal: { lowerBound: 24.3, upperBound: 30.4 },
            high: { lowerBound: 30.4, upperBound: 35.4 },
            veryHigh: { lowerBound: 35.4 }
        },
        // Age 40 - 59
        {
            low: { upperBound: 24.1 },
            normal: { lowerBound: 24.1, upperBound: 30.2 },
            high: { lowerBound: 30.2, upperBound: 35.2 },
            veryHigh: { lowerBound: 35.2 }
        },
        // Age 60 - 80
        {
            low: { upperBound: 23.9 },
            normal: { lowerBound: 23.9, upperBound: 30 },
            high: { lowerBound: 30, upperBound: 35 },
            veryHigh: { lowerBound: 35 }
        }
    ]
};

const getRangeKey = (muscleMass, gender, age) =>
    compose(
        nth(0),
        keys,
        filter(range => range.condition && range.condition(muscleMass))
    )(getRangeInfo(gender, age));

/**
 * Create Range Object from reference range
 * @param {{ low: { upperBound: number }, normal: { lowerbound: number, upperBound: number }, high: { lowerbound: number, upperBound: number }, veryHigh: { lowerbound: number } }} referenceRange
 * @return {{ low: { key: string, rangeInfo: string, condition: (muscleMass: number) => boolean }, normal: { key: string, rangeInfo: string, condition: (muscleMass: number) => boolean }, high: { key: string, rangeInfo: string, condition: (muscleMass: number) => boolean }, veryHigh: { key: string, rangeInfo: string, condition: (muscleMass: number) => boolean } }}
 */
const createRangeObject = referenceRange => {
    const getRangeInfo = ({ lowerBound, upperBound }) => {
        if (!lowerBound) return `< ${upperBound}%`;
        else if (!upperBound) return `> ${lowerBound}%`;
        else return `${lowerBound}% - ${upperBound}%`;
    };

    const getConditionInfo = ({ lowerBound, upperBound }) => {
        if (!lowerBound) return muscleMass => muscleMass < upperBound;
        else if (!upperBound) return muscleMass => muscleMass >= lowerBound;
        else return muscleMass => muscleMass >= lowerBound && muscleMass < upperBound;
    };

    return toPairs(referenceRange).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: {
                key,
                rangeInfo: getRangeInfo(value),
                condition: getConditionInfo(value)
            }
        }),
        {}
    );
};

/**
 * Get Range Info
 * @param {string} gender
 * @param {number} age
 * @return {{ low: object, normal: object, high: object, veryHigh: object }}
 */
const getRangeInfo = (gender, age) => {
    if (age >= 18 && age < 40) return createRangeObject(REFERENCE_RANGE[gender.toLowerCase()][0]);
    else if (age >= 40 && age < 60) return createRangeObject(REFERENCE_RANGE[gender.toLowerCase()][1]);
    else if (age >= 60 && age < 80) return createRangeObject(REFERENCE_RANGE[gender.toLowerCase()][2]);
    else return { low: {}, normal: {}, high: {}, veryHigh: {} };
};

const buildGuidelineDefinition = (gender, age) => {
    const rangeInfo = getRangeInfo(gender, age);

    return {
        low: {
            key: "low",
            label: "Low",
            riskLevel: 4 / (TotalRiskLevel + 1),
            rangeInfo: rangeInfo.low.rangeInfo
        },
        normal: {
            key: "normal",
            label: "Normal",
            riskLevel: 3 / (TotalRiskLevel + 1),
            rangeInfo: rangeInfo.normal.rangeInfo
        },
        high: {
            key: "high",
            label: "High",
            riskLevel: 2 / (TotalRiskLevel + 1),
            rangeInfo: rangeInfo.high.rangeInfo
        },
        veryHigh: {
            key: "veryHigh",
            label: "Very High",
            riskLevel: 1 / (TotalRiskLevel + 1),
            rangeInfo: rangeInfo.veryHigh.rangeInfo
        }
    };
};

/**
 * SiSU Muscle Mass Guideline
 * @param {number} muscleMass
 * @param {string} gender
 * @param {number} age
 * @return {{ key: string, label: string, riskLevel: number, rangeInfo: string, ranges: { high: { riskLevel, rangeInfo: string, label: string, key: string}, low: {riskLevel, rangeInfo: string, label: string, key: string}, normal: {riskLevel, rangeInfo: string, label: string, key: string}, veryHigh: {riskLevel, rangeInfo: string, label: string, key: string} } }}
 */
const SisuGuideline = (muscleMass, gender, age) => {
    const ranges = buildGuidelineDefinition(gender, age);
    const rangeKey = getRangeKey(muscleMass, gender, age);

    return { ranges, ...ranges[rangeKey] };
};

const RiskName = "MUSCLE_MASS";
const RiskCode = "MUSCLE_MASS";
const GuidelineName = "SiSU Muscle Mass Guideline";
const GuidelineCode = "SISU_MUSCLE_MASS";

export default SisuGuideline;
export { RiskName, RiskCode, GuidelineName, GuidelineCode, buildGuidelineDefinition };
