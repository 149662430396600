import styled from "styled-components";
import { Flex, Box, Text } from "rebass";

export const HeaderContainer = styled(Flex)`
    width: 100%;
    height: 70px;
    display: flex;
    margin: 0 auto;
    align-items: center;
`;

export const NavButton = styled(Box)`
    width: 84px;
    height: 34px;
    display: flex;
    cursor: pointer;
    border-radius: 20px;
    margin-right: 40px;
    align-items: center;
    background-color: white;
    justify-content: center;
    box-shadow: ${({ theme }) => theme.portalShadows.card1};
    border: solid 1px ${({ theme }) => theme.colours.hpPrimaryPurple.hex};

    img {
        height: 14px;
        width: 14px;
        margin-left: 8px;
    }

    span {
        border: none;
        color: ${({ theme }) => theme.colours.hpPrimaryPurple.hex};
        font-size: ${props => props.theme.fontSize[12].px};
        font-weight: ${props => props.theme.fontWeight.intermediate};
    }
`;

export const Card = styled(Box)`
    min-width: 80px;
    height: 34px;
    display: flex;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px 10px 10px 50px;
    box-shadow: ${props => props.theme.portalShadows.card1};
    background-color: ${props => props.theme.colours.hpWhite.hex};
`;

export const NameCircle = styled(Box)`
    width: 26px;
    height: 26px;
    display: flex;
    border-radius: 13px;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    background-color: ${props => props.theme.colours.hpPurple100.hex};
`;

export const InitialText = styled(Text)`
    color: black;
    font-size: ${props => props.theme.fontSize[12].px};
    font-family: ${props => props.theme.fontFamily.openSans};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    line-height: 14px;
    margin: ${props => props.margin};
    overflow: hidden;
    white-space nowrap;
    text-overflow: ellipsis;
`;

export const DropDownButton = styled.button`
    border: 0px;
    width: 24px;
    outline: none;
    display: flex;
    background: none;
    justify-content: center;
    color: ${props => props.theme.colours.primaryBlueTints100.hex};
    margin-left: 10px;
    margin-right: 2px;
`;

export const ListContainer = styled.div`
    right: 0;
    top: 100%;
    cursor: auto;
    width: 210px;
    height: auto;
    display: flex;
    margin-top: 3px;
    padding: 10px 0px;
    border-radius: 5px;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    border: 2px solid ${props => props.theme.colours.hpLightGray.hex};
    box-shadow: ${props => props.theme.portalShadows.card1};
`;

export const DropDownItem = styled.div`
    cursor: pointer;
    color: ${props => props.theme.colours.hpDesaturatedBlue.hex};
    background: white;
    font-size: 12px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    height: 40px;
    margin: 8px 0px;

    padding: 0px 6px;
    &:hover {
        background-color: ${props => props.theme.colours.hpGrayishBlue.hex};
    }
    .item {
        display: flex;
        align-items: center;
        width: 100%;
        img {
            margin: 0px 16px;
        }
    }
`;

export const MobileLogo = styled(Box)`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
