import React from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Loading } from "@sisuwellness/web-components";
import BaseMemberViewWithoutMenu from "components/BaseMemberViewWithoutMenu";

const LoadingViewWithoutMenu = ({ title, message }) => {
    const loadingMessage = message || "Please wait while we crunch your data";
    const loadingTitle = title || "Loading...";

    return (
        <BaseMemberViewWithoutMenu loading="true">
            <Loading title={loadingTitle} message={loadingMessage} height={"80vh"} />
        </BaseMemberViewWithoutMenu>
    );
};

LoadingViewWithoutMenu.displayName = "LoadingViewWithoutMenu";
LoadingViewWithoutMenu.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
};

export default withTheme(LoadingViewWithoutMenu);
