import React from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";
import { withTheme } from "styled-components";
import { Image } from "rebass";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------------

import routes from "constants/routes";
import DropDownMenu from "./DropDownMenu";

import { isRouteStatic } from "utilities/leftNav";
import { userIsVhiCustomer, userIsBupaCustomer } from "utilities/clientCheck";
import { Link, MediaQuery } from "@sisuwellness/web-components";
import { HeaderContainer, NavButton, MobileLogo } from "./styled";
import { PORTAL_IMAGE } from "constants/imagePath";

// ----------------------------------------------------------------------------

const resolveContent = citizen => ({
    headerLogo:
        (userIsVhiCustomer(citizen) && `${PORTAL_IMAGE}/clients/vhi/vhi_logo.svg`) ||
        (userIsBupaCustomer(citizen) && `${PORTAL_IMAGE}/clients/bupa/bupa_logo.png`) ||
        `${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`,
    mobileLogo:
        (userIsVhiCustomer(citizen) && `${PORTAL_IMAGE}/clients/vhi/vhi_logo.svg`) ||
        (userIsBupaCustomer(citizen) && `${PORTAL_IMAGE}/clients/bupa/bupa_logo.png`) ||
        `${PORTAL_IMAGE}/logo-mobile.svg`,
    mobileLogoLoc: userIsVhiCustomer(citizen) ? "98px" : "85px"
});

const AuthenticatedHeader = ({ visible, setVisible, handleTranslate }) => {
    const currentRoute = useLocation().pathname.toLowerCase();
    const citizen = useSelector(state => state.auth.citizen);

    const { headerLogo, mobileLogo, mobileLogoLoc } = resolveContent(citizen);

    const toggleMenu = () => {
        return setVisible(!visible);
    };
    return (
        <>
            <MediaQuery devices={["mobile", "tablet"]}>
                <HeaderContainer>
                    <Image
                        mx="20px"
                        size="28px"
                        src={`${PORTAL_IMAGE}/hamburger.svg`}
                        alt="Ham Menu"
                        onClick={handleTranslate}
                    />
                    <MobileLogo ml={`calc(50vw - ${mobileLogoLoc})`}>
                        <Link to={"/"}>
                            <img style={{ height: "30px" }} data-testid="mobile-header-logo" src={mobileLogo} />
                        </Link>
                    </MobileLogo>
                </HeaderContainer>
            </MediaQuery>
            <MediaQuery devices={["desktop"]}>
                <HeaderContainer>
                    {!isRouteStatic(currentRoute) && (
                        <NavButton data-testid="menu-toggle-button" onClick={toggleMenu}>
                            <span>Menu</span>
                            <Image
                                css={{ transform: `scale(${visible ? "-1" : "1"})` }}
                                src={`${PORTAL_IMAGE}/authenticatedHeader/arrow_right.svg`}
                            />
                        </NavButton>
                    )}
                    <Link to={routes.healthHub} style={{ alignSelf: "center", width: "150px" }}>
                        <img style={{ "max-height": "60px" }} data-testid="header-logo" src={headerLogo} />
                    </Link>
                    <DropDownMenu />
                </HeaderContainer>
            </MediaQuery>
        </>
    );
};

AuthenticatedHeader.propTypes = {
    theme: PropTypes.object.isRequired,
    show: PropTypes.bool,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    handleTranslate: PropTypes.func
};

AuthenticatedHeader.defaultProps = {
    show: true
};

export default withTheme(AuthenticatedHeader);
