import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { createLegends, downBy, isMobileView } from "components/Templates/TrendChart/Line/utils";

import {
    calculateMinMax,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { MUSCLE_MASS } from "constants/trend-charts";
import { fixedNumber } from "utilities/commonUtils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";

import Guideline from "@sisuwellness/utilities/Guidelines";
import { all, complement, isNil } from "ramda";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: true,
    key: ["muscleMass"],
    additionalKeys: ["age", "gender"]
};

const filterEmptyPoints = data => {
    const { age, gender, muscleMass } = data;
    if (all(complement(isNil))([age, gender, muscleMass])) return { ...data, unit: "%" };
    return null;
};

const plot = {
    unit: () => "Skeletal Muscle (%)",

    color: ({ age, gender, muscleMass } = {}) => {
        const { guideline, pointColor } = calculatePointColor(MUSCLE_MASS, { age, gender, muscleMass });
        return guideline ? pointColor : defaultColor;
    },

    dataPoints: (healthChecks = []) =>
        createPlottableDataPoints(filterEmptyPoints)(healthChecks, METRIC_FIELDS.key, METRIC_FIELDS.additionalKeys),

    yRange: (points = [], width) => {
        const { maximum, minimum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(downBy(minimum, 5), maximum, {
            parts: isMobileView(width) ? 5 : 7,
            rounded: METRIC_FIELDS.rounded
        });

        return {
            min: ranges[0],
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: () =>
        createLegends(
            Guideline.MuscleMass.buildGuidelineDefinition(),
            METRIC_INDICATOR.MUSCLE_MASS.themeKey,
            "Skeletal Muscle"
        ),

    historyProps: (filteredPoints = [], latestHealthCheck = {}) => {
        const { muscleMass, age, gender } = latestHealthCheck;

        const meanMuscleMass = calculateMeanValue("muscleMass", filteredPoints[0]);
        const meanAge = calculateMeanValue("age", filteredPoints[0]);

        const ratingKey = calculatePointColor(MUSCLE_MASS, { age, gender, muscleMass })?.guideline?.key;
        const meanRatingKey = calculatePointColor(MUSCLE_MASS, { age: meanAge, gender, muscleMass: meanMuscleMass })
            ?.guideline?.key;

        return {
            unit: "%",
            value: fixedNumber(muscleMass),
            meanValue: fixedNumber(meanMuscleMass),
            valueColor: theme.guidelines.muscleMass[ratingKey]?.sisuPortal?.hex,
            meanColor: theme.guidelines.muscleMass[meanRatingKey]?.sisuPortal?.hex
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
