import React from "react";
import PropTypes from "prop-types";

import { Card, Flex, Text } from "rebass";
import styled from "styled-components";

// ----------------------------------------------------------------------------

import DoughnutChart from "components/Templates/TrendChart/Doughnut";
import { COMPOSITION_COLOR } from "constants/trend-charts";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { MUSCLE_MASS, BODY_FAT } from "constants/trend-charts";
import { fixedNumber } from "utilities/commonUtils";

// ----------------------------------------------------------------------------

const PositionedText = styled(Text)`
    top: 50%;
    left: 52%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 0;
`;

// eslint-disable-next-line
const CompositionGraph = ({ value = {}, ...rest }) => {
    const { age, gender, bodyFatPercent, muscleMass } = value;

    const muscleMassGuideline = calculatePointColor(MUSCLE_MASS, { age, gender, muscleMass });
    const bodyFatGuideline = calculatePointColor(BODY_FAT, { age, gender, bodyFatPercent });

    const fixedMuscleMass = fixedNumber(muscleMass),
        fixedBodyFatPercentage = fixedNumber(bodyFatPercent),
        fixedOthersPercentage = fixedNumber(100 - (muscleMass + bodyFatPercent));

    const compositions = [
        {
            label: "Skeletal Muscle",
            rating: muscleMassGuideline.rating,
            indicator: COMPOSITION_COLOR.Muscle,
            value: `${fixedMuscleMass}%`,
            ratingColor: muscleMassGuideline.pointColor
        },
        {
            label: "Body Fat",
            rating: bodyFatGuideline.rating,
            indicator: COMPOSITION_COLOR.Fat,
            value: `${fixedBodyFatPercentage}%`,
            ratingColor: bodyFatGuideline.pointColor
        },
        {
            label: "Other",
            rating: "N/A",
            indicator: COMPOSITION_COLOR.Other,
            ratingColor: COMPOSITION_COLOR.Other,
            value: `${fixedOthersPercentage}%`
        }
    ];

    return (
        <Flex
            p={["16px", "32px"]}
            alignItems="center"
            bg="hpCardBackground"
            flexDirection={["column", "row"]}
            justifyContent="center"
            data-testid="overview-graph-bc"
            {...rest}
        >
            <DoughnutChart
                width="153px"
                data={{
                    datasets: [
                        {
                            data: [fixedBodyFatPercentage, fixedMuscleMass, fixedOthersPercentage],
                            backgroundColor: [COMPOSITION_COLOR.Fat, COMPOSITION_COLOR.Muscle, COMPOSITION_COLOR.Other]
                        }
                    ]
                }}
                options={{
                    plugins: {
                        legend: false,
                        tooltip: {
                            enabled: true,
                            bodyAlign: "center",
                            usePointStyle: false,
                            displayColors: false,
                            titleAlign: "center",
                            callbacks: { label: data => `${data.parsed}%` }
                        }
                    }
                }}
            >
                <PositionedText whiteSpace="pre-wrap" color="hpPrimaryPurple" textAlign="center">
                    <Text as="span" fontWeight="500" color="blackTints800">
                        Your Body Composition
                    </Text>
                </PositionedText>
            </DoughnutChart>
            <Flex ml={["0", "10%"]} pt="32px" flexDirection="column" jc={["flex-start", "", "center"]}>
                {compositions.map(({ value, label, indicator }, ix) => (
                    <Flex mb={["16px", "32px"]} alignItems="flex-start" key={ix} flexGrow={1}>
                        <Card mt="4px" mr="8px" width="24px" height="6px" borderRadius="10px" bg={indicator} />
                        <Flex flexDirection="column">
                            <Text color="blackTints800" fontSize="14px" fontWeight="500">
                                {label} {value}
                            </Text>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

CompositionGraph.propTypes = {
    value: PropTypes.any
};

export default CompositionGraph;
