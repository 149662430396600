import React from "react";
import PropTypes from "prop-types";

import { Link } from "@sisuwellness/web-components";
import { NavHeading, NavImage, NavItem, NavText } from "./styled";

export const LinkCard = ({ item }) => (
    <Link to={`${item.linkTo}`}>
        <NavItem item={item}>
            <div>
                <NavHeading>{item.heading}</NavHeading>
                <NavText>{item.text}</NavText>
            </div>
            <NavImage src={item.image}></NavImage>
        </NavItem>
    </Link>
);

LinkCard.propTypes = {
    item: PropTypes.object
};
