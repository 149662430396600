import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactDateTime from "react-datetime";
import { ErrorText } from "../../";
import "./index.css";
import { StyledInput } from "../..";

const NonMarginStyledInput = styled(StyledInput)`
    margin: 0em;
`;

const SinglePicker = ({ onChange, date, placeholder, errors, disabled, ...rest }) => {
    return (
        <>
            <ReactDateTime
                {...rest}
                onChange={onChange}
                defaultValue={date}
                renderInput={props => (
                    <NonMarginStyledInput {...props} hasErrors={errors} placeholder={placeholder} disabled={disabled} />
                )}
            />
            {errors && <ErrorText>{errors}</ErrorText>}
        </>
    );
};

SinglePicker.propTypes = {
    // Controlled component props
    onChange: PropTypes.func.isRequired,
    date: PropTypes.object,
    errors: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

SinglePicker.defaultProps = {
    placeholder: "Please pick a date/time",
    dateFormat: "DD/MM/YYYY"
};

export default SinglePicker;
