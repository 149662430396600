import * as yup from "yup";

import {
    postalCodeValidation,
    mobileValidationByCountryCode
} from "@sisuwellness/utilities/Validation/UserProfileValidation";
import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";
import { ERROR_MESSAGES } from "constants/messages";

export const validateSchema = payload => {
    const options = {
        message: {
            [COUNTRIES.AUSTRALIA]: ERROR_MESSAGES.VALIDATION.MOBILE_NUMBER.AUSTRALIA,
            [COUNTRIES.UNITED_KINGDOM]: ERROR_MESSAGES.VALIDATION.MOBILE_NUMBER.UNITED_KINGDOM
        }
    };

    return yup.object().shape({
        postalCode: postalCodeValidation(payload.postalCode, payload.countryCode),
        countryCode: yup.string().required(),
        mobileNumber: mobileValidationByCountryCode(payload.countryCode, options)
    });
};
