import React, { useState } from "react";
import PropTypes from "prop-types";

import { useHistory, useLocation } from "react-router-dom";

// -------------------------------------------------------------------

import { MessageBox, MessageText, SignupCard, CardContainer, ErrorWrapper } from "components/CommonComponents";
import { getErrorMessage, modifyResposePromise, scrollTo } from "utilities/commonUtils";
import { REQUEST_FAILED_ERROR_MESSAGE } from "constants/messages";
import routes from "constants/routes";
import Auth from "utilities/auth";
import APIClient from "utilities/APIClient";

import SignupForm from "./signupForm";
import { accpetConsents, isSignUpFlow } from "./utils";
import { FlowState } from "@sisuwellness/utilities/User";
import { Title } from "./title";
import useRudderStack from "hooks/useRudderStack";

// -------------------------------------------------------------------

const Signup = ({ state, consents, locale }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const history = useHistory();
    const { search, state: locationState } = useLocation();
    const rudderStack = useRudderStack();

    const handleOnSignupSuccess = uuid => {
        const payload = { person: { id: uuid } };
        if (uuid) window.Rollbar.configure({ payload }); // Logging UUID
        history.push({
            search: locationState?.from?.search ?? search,
            pathname: locationState?.from?.pathname ?? routes.healthHub,
            state: { viaSignUp: true }
        });

        rudderStack.identifyEvent(uuid);

        if (isSignUpFlow(state).setup) rudderStack.trackEvent("Account Completed", { type: "setup" });
        if (isSignUpFlow(state).registration) {
            rudderStack.groupEvent(state.token);
            rudderStack.trackEvent("Account Completed", { type: "campaign" });
        }

        rudderStack.trackEvent("Account Authentication Success", { manualSignIn: false });
    };

    const handleOnApiPromiseRejection = err => {
        if (err.message) {
            window.Rollbar.error("Failed in signup call", err);
            setErrorMessage(REQUEST_FAILED_ERROR_MESSAGE);
        } else setErrorMessage(getErrorMessage(err));
    };

    const createUser = async (user, setSubmitting) => {
        user.token = state.token;
        !isSignUpFlow(state).setup && (user.consents = accpetConsents(user));

        setErrorMessage("");
        modifyResposePromise(APIClient.auth.signup("portal", user))
            .then(({ token }) => Auth.authenticate(token))
            .then(() => Auth.getUserUUID())
            .then(handleOnSignupSuccess)
            .catch(handleOnApiPromiseRejection)
            .finally(() => setSubmitting(false));
    };

    const handleMessageForCompleteUser = async (result = {}) => {
        if (result.isNotFound()) return false;

        if (result.succeeded()) {
            const { userState = "" } = await result.body();
            if (userState === FlowState.USER_STATE_COMPLETE) {
                setErrorMessage("Email is already registered.");
                return true;
            }

            return false;
        }

        return Promise.reject(result);
    };

    const checkIfUserExist = email => {
        const setupToken = state.token;

        setErrorMessage("");
        return APIClient.auth
            .peep(email, null, setupToken)
            .then(result => handleMessageForCompleteUser(result))
            .catch(err => {
                handleOnApiPromiseRejection(err);
                return true;
            })
            .finally(() => scrollTo(0, 0));
    };

    return (
        <CardContainer>
            <ErrorWrapper
                width={"75%"}
                padding={"0 64px"}
                maxWidth={"1110px"}
                mobileWidth={"100%"}
                error={errorMessage}
            >
                {errorMessage && (
                    <MessageBox error={true} data-testid="error-message">
                        <MessageText>{errorMessage}</MessageText>
                    </MessageBox>
                )}
            </ErrorWrapper>
            <SignupCard>
                <Title state={state} />
                <SignupForm
                    userDetails={{ ...state, consents, locale }}
                    onSubmit={createUser}
                    userPeep={checkIfUserExist}
                    class="signup-form"
                />
            </SignupCard>
        </CardContainer>
    );
};
// -------------------------------------------------------------------

Signup.propTypes = {
    state: PropTypes.object,
    consents: PropTypes.array,
    locale: PropTypes.string
};
export default Signup;
