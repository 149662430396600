import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { isMobileView } from "components/Templates/TrendChart/Line/utils";
import { find, mergeRight, pathOr, propEq } from "ramda";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import {
    calculateMinMax,
    createLegends,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import { SisuHeartAgeGuideline } from "@sisuwellness/utilities/Guidelines/HeartAge";
import { calculateHeartAgeParams } from "views/HeartAge/utils";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: true,
    key: ["heartAge"],
    additionalKeys: ["age", "heartAgeOptimal", "rating", "unit"]
};

const plot = {
    unit: () => "Heart Age",

    color: ({ rating } = {}) => theme.guidelines.HeartAge[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = []) =>
        createPlottableDataPoints(
            h => (h.heartAge > 0 && h.tenYearCvdRisk > 0 ? mergeRight(h, { unit: "years old" }) : {}),
            calculateHeartAgeParams
        )(healthChecks, METRIC_FIELDS.key, METRIC_FIELDS.additionalKeys),

    yRange: (points = []) => {
        const { maximum } = calculateMinMax(flattenPointValues(points, "y"));

        const ranges = createYGridValues(0, maximum, { parts: 5, rounded: METRIC_FIELDS.rounded });

        return {
            min: 0,
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.HEART_AGE.themeKey,
            "Heart Age"
        ),

    historyProps: (filteredPoints = [], latestHealthCheck = {}) => {
        const { age, heartAge, heartAgeOptimal } = latestHealthCheck;

        const rating = SisuHeartAgeGuideline(heartAge, heartAgeOptimal, age);

        const meanAge = calculateMeanValue("age", find(propEq("id", "heartAge"))(filteredPoints));
        const meanHeartAge = calculateMeanValue("heartAge", find(propEq("id", "heartAge"))(filteredPoints));

        const { key: meanRatingKey } = SisuHeartAgeGuideline(meanHeartAge, heartAgeOptimal, meanAge);

        return {
            value: heartAge,
            unit: "years old",
            meanValue: Math.round(meanHeartAge),
            valueColor: theme.guidelines.HeartAge[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.HeartAge[meanRatingKey]?.sisuPortal?.hex
        };
    },

    markers: (data, width) => [
        {
            axis: "y",
            textStyle: {
                fill: "#736495",
                fontSize: "11px",
                transform: isMobileView(width) && "translate(250px, -14px) rotate(0deg)"
            },
            value: data.heartAgeOptimal,
            legendOrientation: "horizontal",
            lineStyle: { stroke: "#736495", strokeWidth: 1, strokeDasharray: 8 },
            legend: `Average Heart Age for ${data.gender.toLowerCase()}s your age`
        }
    ],

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
