import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Flex, Text } from "rebass";
import { useHistory } from "react-router";

// ------------------------------------------------------------------------------

import Pie from "@sisuwellness/ui/src/components/Pie";
import { HeadingLabel as Title } from "@sisuwellness/ui/src/components/Labels";
import { getHRARecommendation, getHRARiskColor } from "utilities/healthRiskScore";
import { userIsVhiCustomer, userIsBupaCustomer } from "utilities/clientCheck";
import routes from "constants/routes";

import SummaryCard from "components/Cards/SummaryCard";
import { MetricCardBtnPrimary, MetricCardBtnSecondary } from "components/CommonComponents/Button";

// ------------------------------------------------------------------------------

const resolveContent = citizen => ({
    titleText: userIsVhiCustomer(citizen)
        ? "Vhi Health Score"
        : userIsBupaCustomer(citizen)
        ? "Bupa Health Score"
        : "SiSU Health Score"
});

const HRACard = ({ lifestyle: { healthRiskScore } = {}, ...rest }) => {
    const history = useHistory();

    const isHealthRiskScoreFinite = Number.isFinite(healthRiskScore);

    const handleOnClick = () => {
        history.push(isHealthRiskScoreFinite ? routes.hraResult : routes.healthRiskOnboarding);
    };

    const citizen = useSelector(state => state.auth.citizen);
    const { titleText } = resolveContent(citizen);

    return (
        <SummaryCard
            data-testid="health-risk-assessment-metric"
            cardHeader={
                <Title fontSize="15px" fontWeight="normal" mb="12px">
                    {titleText}
                </Title>
            }
            cardFooter={
                isHealthRiskScoreFinite ? (
                    <MetricCardBtnSecondary onClick={handleOnClick}>Learn More</MetricCardBtnSecondary>
                ) : (
                    <MetricCardBtnPrimary onClick={handleOnClick}>Take Health Assessment</MetricCardBtnPrimary>
                )
            }
            cardBody={
                <Flex flexDirection="column" justifyContent="space-between" alignItems="center">
                    <Pie
                        size={85}
                        bg={isHealthRiskScoreFinite || "hpLightBlue"}
                        progress={isHealthRiskScoreFinite ? healthRiskScore : 100}
                        progressFgColor={getHRARiskColor(healthRiskScore)}
                    >
                        <Text fontSize="20px" textAlign="center" color="hpPrimaryPurple">
                            {isHealthRiskScoreFinite ? healthRiskScore : "?"}
                        </Text>
                    </Pie>
                    <Text
                        mt="16px"
                        maxWidth="360px"
                        fontSize="13px"
                        textAlign="center"
                        fontWeight="light"
                        color="hpGrayishBlueText"
                    >
                        {getHRARecommendation(healthRiskScore)}
                    </Text>
                </Flex>
            }
            {...rest}
        />
    );
};

HRACard.propTypes = {
    lifestyle: PropTypes.object
};

export default HRACard;
