/* eslint-disable camelcase */
import { useMessageBanner } from "components/ContextProviders";
import { MESSAGE_ERROR } from "constants/messages";
import useProgramSubscriptionTracking from "hooks/useProgramSubscriptionTracking";
import useRudderStack from "hooks/useRudderStack";
import { useLocation } from "react-router";
import APIClient from "utilities/APIClient";
import { getErrorMessage, modifyResposePromise } from "utilities/commonUtils";

/**
 * Chargebee hook for checkout and manage subscription flows
 * @return {{ openChargebeeCheckout: Function, openChargebeePortal: Function  }}
 */
export const useChargebee = () => {
    const chargebee = window.Chargebee.getInstance();

    const { search } = useLocation();
    const rudderStack = useRudderStack();
    const { handleMessage = () => {} } = useMessageBanner();
    const { trackSubscriptionCompleted, trackSubscriptionCancelled } = useProgramSubscriptionTracking(
        search,
        rudderStack
    );

    /**
     * Open Chargebee checkout
     * @param {object} param
     * @param {object} param.callbacks
     * @param {Function} param.callbacks.openPollingScreen
     */
    const openChargebeeCheckout = ({ callbacks: { openPollingScreen } }) => {
        if (!chargebee) return;

        chargebee.openCheckout({
            hostedPage: () =>
                modifyResposePromise(APIClient.userSubscriptions.subscriptionCheckout())
                    .then(({ hosted_page }) => hosted_page)
                    .catch(err => handleMessage(MESSAGE_ERROR, getErrorMessage(err))),

            success: () => {
                trackSubscriptionCompleted();
                openPollingScreen(true);
                chargebee.closeAll();
            },

            error: () => trackSubscriptionCancelled(),

            close: () => trackSubscriptionCancelled()
        });
    };

    /**
     * Open Chargebee Portal
     */
    const openChargebeePortal = () => {
        if (!chargebee) return;

        chargebee.setPortalSession(() =>
            modifyResposePromise(APIClient.userSubscriptions.subscriptionPortal())
                .then(({ portal_session }) => portal_session)
                .catch(err => handleMessage(MESSAGE_ERROR, getErrorMessage(err)))
        );

        const chargebeePortalInstance = chargebee.createChargebeePortal();

        chargebeePortalInstance.open();
    };

    return { openChargebeeCheckout, openChargebeePortal };
};
