let isInitiatedFromUpgradeRequest = false;
// NOTE: Use only for unit testing
export const setFlagForTesting = (flag = false) => (isInitiatedFromUpgradeRequest = flag);

/**
 * Hook to provide subscription tracking on premium upgrade request
 *
 * @returns {{ trackSubscriptionInitiated: Function, trackSubscriptionCompleted: Function, trackSubscriptionCancelled: Function }} An object containing methods to track subscription events:
 * - trackSubscriptionInitiated: Function to track when a subscription initiation process starts.
 * - trackSubscriptionCompleted: Function to track when a subscription process is completed.
 * - trackSubscriptionCancelled: Function to track when a subscription process is cancelled.
 */
const useProgramSubscriptionTracking = (search = "", tracker = { trackEvent: () => {} }) => {
    //* NOTE: Assuming this link is uniquely used for Sustainable Weight Loss Program Tracking. (PREM-2081)
    const hasPremiumUpgradeInURL = () => new URLSearchParams(search).get("modal") === "premium-upgrade";

    var trackSubscriptionInitiated = () => {
        if (!hasPremiumUpgradeInURL()) return;
        isInitiatedFromUpgradeRequest = true;
        tracker.trackEvent("Sustainable WLP Subscription Initiated");
    };

    var trackSubscriptionCompleted = () => {
        if (!isInitiatedFromUpgradeRequest) return;
        isInitiatedFromUpgradeRequest = false;
        tracker.trackEvent("Sustainable WLP Subscription Completed");
    };
    var trackSubscriptionCancelled = () => {
        if (!isInitiatedFromUpgradeRequest) return;
        isInitiatedFromUpgradeRequest = false;
        tracker.trackEvent("Sustainable WLP Subscription Failed");
    };

    return {
        trackSubscriptionInitiated,
        trackSubscriptionCompleted,
        trackSubscriptionCancelled
    };
};

export default useProgramSubscriptionTracking;
