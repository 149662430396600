import BaseEndPoint from "../BaseEndPoint";

export default class UserJourney extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/user-journeys/" + path);
    }

    getHealthRiskAssessment() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("health-risk-assessment"), settings);
    }

    getPublic(slug) {
        const settings = { ...this._getDefaultFetchSettings(), method: "GET" };
        return this._send(this.getUrlPath(slug) + "/public", settings);
    }
}
