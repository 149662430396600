import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { isMobileView } from "components/Templates/TrendChart/Line/utils";
import { find, mergeRight, pathOr, propEq } from "ramda";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import {
    calculateMinMax,
    createLegends,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import { SisuHeartAgeGuideline } from "@sisuwellness/utilities/Guidelines/HeartAge";
import { calculateHeartAgeParams } from "views/HeartAge/utils";
import { fixedNumber } from "utilities/commonUtils";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: false,
    key: ["tenYearCvdRisk"],
    additionalKeys: ["age", "heartAge", "heartAgeOptimal", "rating", "unit"]
};

const plot = {
    unit: () => "Heart and Stroke Risk (%)",

    color: ({ rating } = {}) => theme.guidelines.HeartAge[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = []) =>
        createPlottableDataPoints(
            h => (h.heartAge > 0 && h.tenYearCvdRisk > 0 ? mergeRight(h, { unit: "%" }) : {}),
            calculateHeartAgeParams
        )(healthChecks, METRIC_FIELDS.key, METRIC_FIELDS.additionalKeys),

    yRange: (points = []) => {
        const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(0, maximum, { parts: 5, rounded: METRIC_FIELDS.rounded }).map(num =>
            fixedNumber(num)
        );

        return {
            min: 0,
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.TEN_YEAR_CVD_RISK.themeKey,
            "Risk"
        ),

    historyProps: (filteredPoints = [], latestHealthCheck = {}) => {
        const { age, heartAge, heartAgeOptimal, tenYearCvdRisk } = latestHealthCheck;

        const rating = SisuHeartAgeGuideline(heartAge, heartAgeOptimal, age);

        const meanAge = calculateMeanValue("age", find(propEq("id", "tenYearCvdRisk"))(filteredPoints));
        const meanHeartAge = calculateMeanValue("heartAge", find(propEq("id", "tenYearCvdRisk"))(filteredPoints));
        const meanTenYearCvdRisk = calculateMeanValue(
            "tenYearCvdRisk",
            find(propEq("id", "tenYearCvdRisk"))(filteredPoints)
        );

        const { key: meanRatingKey } = SisuHeartAgeGuideline(meanHeartAge, heartAgeOptimal, meanAge);

        return {
            unit: "%",
            value: tenYearCvdRisk,
            meanValue: fixedNumber(meanTenYearCvdRisk),
            valueColor: theme.guidelines.HeartAge[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.HeartAge[meanRatingKey]?.sisuPortal?.hex
        };
    },

    markers: (data, width) => [
        {
            axis: "y",
            textStyle: {
                fill: "#736495",
                fontSize: "11px",
                transform: isMobileView(width) && "translate(250px, -14px) rotate(0deg)"
            },
            value: data.tenYearCvdRiskNormal,
            legendOrientation: "horizontal",
            lineStyle: { stroke: "#736495", strokeWidth: 1, strokeDasharray: 8 },
            legend: `Average Heart and Stroke Risk for ${data.gender.toLowerCase()}s your age`
        }
    ],

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
