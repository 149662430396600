import React, { useState } from "react";
import PropTypes from "prop-types";

import { Box, Flex } from "rebass";
import { Collapse } from "react-bootstrap";

//--------------------------------------------------------------------------

import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import { SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";

import {
    BLOOD_PRESSURE,
    BODY_MASS_INDEX,
    FASTING_GLUCOSE,
    SMOKING,
    TOTAL_CHOLESTEROL,
    WAIST_CIRCUMFERENCE,
    HRA_STRESS,
    SLEEP_TROUBLE,
    SLEEP_MEAL,
    SLEEP_CAFFEINE,
    HRA_ACTIVITY,
    SUDDEN_ANXIETY,
    WORK_ISSUES,
    THREE_MEALS,
    SUBSTANTIAL_BREAKFAST,
    PROCESSED_MEAT,
    FRUIT_VEG,
    FISH_LEGUME,
    FAT_FRIED,
    SWEET_CONSUMPTION,
    HRA_ALCOHOL
} from "constants/trend-charts";
import InterpolatedRecommendation from "utilities/Recommendation";
import RecommendationCard from "views/Recommendations/recommendationCard";
import FlexWithGap from "components/Flex";

//--------------------------------------------------------------------------

const RECOMMENDATIONS_LIST = [
    SMOKING,
    BLOOD_PRESSURE,
    BODY_MASS_INDEX,
    FASTING_GLUCOSE,
    TOTAL_CHOLESTEROL,
    WAIST_CIRCUMFERENCE
];

const REMOVE_NORMAL_RECOMMENDATIONS_LIST = [
    HRA_STRESS,
    SLEEP_TROUBLE,
    SLEEP_MEAL,
    SLEEP_CAFFEINE,
    HRA_ACTIVITY,
    SUDDEN_ANXIETY,
    WORK_ISSUES,
    THREE_MEALS,
    SUBSTANTIAL_BREAKFAST,
    PROCESSED_MEAT,
    FRUIT_VEG,
    FISH_LEGUME,
    FAT_FRIED,
    SWEET_CONSUMPTION,
    HRA_ALCOHOL
];

const RecommendationList = ({ recommendations = [], ...rest }) => {
    const [isShown, setIsShown] = useState(false);

    const handleOnClick = () => setIsShown(!isShown);

    const nonNormalFilteredRecommendations = recommendations.filter(
        recommendation =>
            REMOVE_NORMAL_RECOMMENDATIONS_LIST.includes(recommendation.risk) && recommendation.rating !== "normal"
    );

    const filteredRecommendations = recommendations
        .filter(
            recommendation => RECOMMENDATIONS_LIST.includes(recommendation.risk) && recommendation.rating !== "notSure"
        )
        .map(recommendation => {
            if (recommendation.risk === SMOKING)
                recommendation.rating = recommendation.priority > 1 ? "high" : "normal";
            return recommendation;
        })
        .concat(nonNormalFilteredRecommendations);

    const list1 = filteredRecommendations.slice(0, 3),
        list2 = filteredRecommendations.slice(3, filteredRecommendations.length);

    return (
        <Box {...rest}>
            <HeadingLabel fs={["22px", "34px"]} mb="24px">
                Your Recommendations
            </HeadingLabel>
            <Card width="100%" height="fit-content" p={["24px", "40px"]}>
                <FlexWithGap flex="2 0 65%" flexWrap="wrap" jc="center" fd="column" gap="24px">
                    {list1.map((recommendation, index) => (
                        <InterpolatedRecommendation key={index} index={index} recommendation={recommendation}>
                            <RecommendationCard />
                        </InterpolatedRecommendation>
                    ))}
                </FlexWithGap>
                <Collapse in={isShown} unmountOnExit>
                    <Box>
                        <FlexWithGap mt="24px" flex="2 0 65%" flexWrap="wrap" jc="center" fd="column" gap="24px">
                            {list2.map((recommendation, index) => (
                                <InterpolatedRecommendation
                                    key={index}
                                    index={index + 3}
                                    recommendation={recommendation}
                                >
                                    <RecommendationCard />
                                </InterpolatedRecommendation>
                            ))}
                        </FlexWithGap>
                    </Box>
                </Collapse>
                {Boolean(list2.length) && (
                    <Flex justifyContent="center" alignItems="center" mt={["24px", "40px"]}>
                        <SecondaryButton onClick={handleOnClick} mx="auto">
                            <HeadingLabel fs="16px" fontWeight="500">
                                {isShown ? "Hide" : "Show All"}
                            </HeadingLabel>
                        </SecondaryButton>
                    </Flex>
                )}
            </Card>
        </Box>
    );
};

RecommendationList.propTypes = {
    recommendations: PropTypes.array
};

export default RecommendationList;
