import { Header, TextField } from "../../Forms";

import { Flex } from "rebass";
import PrimaryButton from "../../Buttons/primary";
import PropTypes from "prop-types";
import React from "react";
import { media } from "../../../themes";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledFormAndErrorsContainer = styled.div`
    min-height: 25vh;
    padding: 0.5em;

    ${media.mobile`
        min-height: 40vh;
    `}
`;

const StyledFormBox = styled.div`
    margin: 2em;
`;

const StyledPrimaryButton = styled.div`
    margin-top: 1.8em;

    button {
        width: 100%;
    }
`;

const StyledHeader = styled(Header)`
    font-family: "Proxima Nova Semibold", Arial, sans-serif;
    text-align: center;
`;

const StyledTextField = styled(TextField)`
    font-size: ${props => props.theme.text.heading6.sizeRem};
    box-sizing: border-box;
`;

const IdentifyForm = ({ handleSubmit, handleChangeUsername, isLoading }) => {
    const { t } = useTranslation();
    return (
        <Flex flexDirection="column">
            <StyledFormAndErrorsContainer>
                <StyledFormBox>
                    <StyledHeader>{t("Enter your email")}</StyledHeader>
                    <form onSubmit={handleSubmit} data-testid="identify-form">
                        <StyledTextField
                            name={"username"}
                            value={""}
                            label={null}
                            fieldId={"username"}
                            className={"login-username"}
                            autoFocus={true}
                            onChange={handleChangeUsername}
                            required={true}
                        />
                        <StyledPrimaryButton>
                            <PrimaryButton
                                id={"identify-next-button"}
                                label={"Next"}
                                type={"submit"}
                                disabled={isLoading}
                                borderRadius={"5px"}
                            />
                        </StyledPrimaryButton>
                    </form>
                </StyledFormBox>
            </StyledFormAndErrorsContainer>
        </Flex>
    );
};

IdentifyForm.propTypes = {
    isLoading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleChangeUsername: PropTypes.func.isRequired
};

export default IdentifyForm;
