import React, { useState } from "react";
import PropTypes from "prop-types";

import { Text, Box, Image } from "rebass";

// ------------------------------------------------------------------------------------------------

import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { InformationModal, SectionHeaders } from "views/HeartAge/commonElements";
import { useUserHealthCheckData } from "views/MuscleMass";
import { MUSCLE_MASS } from "constants/trend-charts";
import CardStack from "components/Cards/CardStack";
import { theme } from "@sisuwellness/web-components";

import Simulation from "./simulation";
import { StyledBox, StyledCard, StyledInfoBox } from "./styled";
import { PORTAL_IMAGE } from "constants/imagePath";

// ------------------------------------------------------------------------------------------------

const MuscleMassSimulator = props => {
    const [{ width } = {}, setMeasurementWidth] = useState();
    const { latestHealthCheck } = useUserHealthCheckData();
    const { age = 30, muscleMass = 30, gender } = latestHealthCheck;

    const { key } = calculatePointColor(MUSCLE_MASS, latestHealthCheck);

    const mapCards = [
        {
            value: `${age} years old`,
            title: "Your Age:"
        },
        {
            value: gender,
            title: "Your Sex:"
        },
        {
            value: `${muscleMass}%`,
            showBar: true,
            color: theme.guidelines.muscleMass[key].sisuPortal.hex,
            title: `Your Last Measurement:`
        }
    ];

    return (
        <Box>
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    Simulation
                </Text>
            </SectionHeaders>

            <HeadingLabel fontSize="22px" mt="24px">
                Predicting Skeletal Muscle Change Over Time
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px">
                Modelling the affect of ageing on your muscle mass over the next 15 years, and what impact resistance
                resistnace training could make.
            </NormalLabel>

            <CardStack gap={["8px", "16px"]} py="16px">
                {mapCards.map(({ title, value, showBar, color }, ix) => (
                    <StyledCard
                        px="16px"
                        key={ix}
                        flexGrow={1}
                        flexBasis={["346px", "240px"]}
                        minHeight={["56px", "94px", "77px"]}
                        justifyContent={["flex-start", "center"]}
                    >
                        <StyledCard justifyContent="center" flexWrap="wrap">
                            <Text mr="16px" color="blackTints700" fontSize={["13px", "16px"]} fontWeight="light">
                                {title}
                            </Text>
                            <div>
                                <Text fontSize={["17px", "20px"]} ref={setMeasurementWidth}>
                                    {value}
                                </Text>
                                {showBar && <StyledBox as="hr" height="2px" width={width} bg={color ?? "black"} />}
                            </div>
                        </StyledCard>
                    </StyledCard>
                ))}
            </CardStack>
            <Simulation age={age} muscleMass={muscleMass} />

            <InformationModal heading="How is this calculated?" pt="16px" pb="32px">
                <Text fontSize="18px">
                    An approximate average rate of muscle loss for different age groups has been used to predict muscle
                    mass decline, based on available scientific research. This has been combined with an approximate
                    average rate of muscle growth or maintenance associated with various levels of resistance training.
                    The levels of training referenced are 1-2 sessions (20 - 60 minutes each, ~ 2-3 sets, 10 - 15 reps
                    per muscle group) and 2-4 sessions per week (20 - 60 minutes each, ~ 2-3 sets, 10 - 15 reps per
                    muscle group).
                </Text>
                <Text my="1em" fontStyle="italic">
                    This modelling is intended for educational purposes only and may not reflect results of an intense
                    weight-lifting program.
                </Text>
                <Text my="1em">References:</Text>
                <Text as="ol" fontSize="13px" px="1em">
                    {[
                        "https://pubmed.ncbi.nlm.nih.gov/23748185/",
                        "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6303131/",
                        "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2804956/",
                        "https://pubmed.ncbi.nlm.nih.gov/10855597/"
                    ].map((link, ix) => (
                        <li key={ix}>
                            <Text
                                as="a"
                                href={link}
                                target="_blank"
                                color="blackTints700"
                                rel="noreferrer noopener"
                                style={{ textDecoration: "none" }}
                            >
                                {link}
                            </Text>
                        </li>
                    ))}
                </Text>
            </InformationModal>

            <StyledInfoBox p={["20px", "18.5px 30px"]} flexDirection={["column", "row"]} alignItems="center">
                <Image width={["183px", "285px"]} src={`${PORTAL_IMAGE}/premium/muscleMass/scene-healthy-ageing.svg`} />
                <Text
                    fontWeight="light"
                    ml={["0px", "24px"]}
                    mt={["24px", "0px"]}
                    color="hpPrimaryPurple"
                    fontSize={["13px", "16px"]}
                >
                    <Text my="1em">
                        Skeletal muscle mass naturally declines as part of the ageing process, starting from age 30 and
                        declining faster after age 65.
                    </Text>
                    <Text my="1em">
                        It’s important to include resistance-based exercise into your routine, to maintain or improve
                        your muscle mass over time.
                    </Text>
                </Text>
            </StyledInfoBox>
        </Box>
    );
};

MuscleMassSimulator.propTypes = { setWeightGoal: PropTypes.func };

export default MuscleMassSimulator;
