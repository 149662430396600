import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Text, Box, Flex } from "rebass";
import styled from "styled-components";
import { grid } from "styled-system";

const Grid = styled(Flex)`
    display: grid;
    grid-auto-flow: column;

    ${grid}
`;

const ChoiceBox = styled(Box)`
    cursor: pointer
    padding: 6px 8px;
    border-radius: 4px;
    border: solid 1px #372168;
    user-select: none;
    color: ${({ isSelected }) => (isSelected ? "white" : "")};
    background-color: ${({ isSelected }) => (isSelected ? "#372168" : "#e9eff3")};
`;

const ChoiceIndicator = styled(Box)`
    display: flex;
    font-size: 20px;
    font-weight: 500px;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    text-transform: uppercase;
    border: solid 1px #9b90b3;
    background-color: #ebe9f0;
    color: #372168;
}
`;

const StyledSpan = styled.span`
    font-weight: 400;
    font-size: 18px;
`;

const HRAChoice = ({ model, setErrors, onSubmit, value }) => {
    const [selectedValue, setSelectedValue] = useState(value[0]);
    const [error, setError] = useState("");
    let choices = model.getChoices();

    useEffect(() => {
        if (!selectedValue && model.isRequired()) setError("One choice has to be selected.");
        else setError("");
    }, [selectedValue]);

    useEffect(() => {
        if (error) setErrors({ error: error });
        else setErrors({});
    }, [error]);

    useEffect(() => {
        document.addEventListener("keypress", onKeyPressed);
        return () => document.removeEventListener("keypress", onKeyPressed);
    }, []);

    const handleOnSubmitKeyPress = (newValue = "") => {
        setSelectedValue(newValue || selectedValue);
        onSubmit(newValue || selectedValue);
    };

    const onSubmitForm = event => {
        event.preventDefault();
        onSubmit(selectedValue);
    };

    const onKeyPressed = e => {
        var enterCode = e.keyCode || e.which;
        if (enterCode === 13 && selectedValue) {
            handleOnSubmitKeyPress(selectedValue);
        } else {
            var key = e.key;
            const mapLettersCapital = choices.map((_, ix) => String.fromCharCode(65 + ix));
            const mapLettersSmall = choices.map((_, ix) => String.fromCharCode(97 + ix));
            if (mapLettersCapital.includes(key) || mapLettersSmall.includes(key)) {
                let index = mapLettersCapital.indexOf(key);
                if (index < 0) index = mapLettersSmall.indexOf(key);
                if (selectedValue !== choices[index].label) {
                    const newValue = choices[index].label;
                    setSelectedValue(newValue);
                    setTimeout(() => handleOnSubmitKeyPress(newValue), 100);
                } else if (selectedValue) {
                    setTimeout(() => handleOnSubmitKeyPress(selectedValue), 100);
                }
            }
        }
    };

    return (
        <Formik enableReinitialize={true} onSubmit={onSubmit} initialValues={{ answer: selectedValue }}>
            {({ values, setFieldValue }) => {
                const handleOnClick = index => {
                    if (values.answer !== choices[index].label) {
                        const newValue = choices[index].label;
                        setFieldValue("answer", newValue);
                        setSelectedValue(newValue);
                        // render selected state before submitting
                        setTimeout(() => handleOnSubmitKeyPress(newValue), 100);
                    }
                };
                return (
                    <Flex justifyContent="center" flexDirection="column" alignItems="flex-start">
                        <Text fontSize={["22px", "34px"]} textAlign="left" fontWeight="500">
                            {model.getDisplayText()} <br />
                            <StyledSpan>{model.getHelpText()}</StyledSpan>
                        </Text>
                        <Box mt="32px" />
                        <Form onSubmit={onSubmitForm} id="field-form" data-testid="form">
                            <Grid gridRowGap="16px" gridColumnGap="32px" gridTemplateRows="repeat(5, 1fr)">
                                {choices.map(({ label, helpText }, index) => {
                                    let selectedIndex = values.answer === label ? index : -1;
                                    return (
                                        <ChoiceBox
                                            key={index}
                                            data-testid="choice"
                                            width={["346px", "349px"]}
                                            isSelected={selectedIndex >= 0}
                                            onClick={() => handleOnClick(index)}
                                        >
                                            <Flex alignItems="center">
                                                <ChoiceIndicator mr="16px" size="38px">
                                                    {String.fromCharCode(65 + index)}
                                                </ChoiceIndicator>
                                                <Text fontSize="20px">
                                                    {label} {helpText}
                                                </Text>
                                            </Flex>
                                        </ChoiceBox>
                                    );
                                })}
                            </Grid>
                        </Form>
                    </Flex>
                );
            }}
        </Formik>
    );
};

HRAChoice.propTypes = {
    model: PropTypes.shape({
        getHelpText: PropTypes.func,
        isRequired: PropTypes.func,
        getDisplayText: PropTypes.func,
        getChoices: PropTypes.func
    }),
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any
};

export default HRAChoice;
