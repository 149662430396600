import React from "react";

import { NormalLabel as Label, HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { Box, Card, Flex, Image } from "rebass";
import { PORTAL_IMAGE } from "constants/imagePath";
import { SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import PropTypes from "prop-types";

const EmptyState = ({ gender, history, theme }) => {
    return (
        <>
            <Label fs={15} mt="16px" fontWeight="normal">
                Your health recommendations are currently unavailable. Please complete the health risk assessment or
                visit a SiSU Health Station so we can give you health recommendations based on your health data.
            </Label>
            <Card
                px={["16px", "32px"]}
                pt="24px"
                pb={["80px", "40px", "32px"]}
                bg={theme.portalColor.hpCardBackground.hex}
                width="unset"
                mt={["24px", "48px"]}
                mb={["0", "223px"]}
            >
                <Flex alignItems={["start", "center"]}>
                    <Image
                        src={`${PORTAL_IMAGE}/healthHub/` + gender.toLowerCase() + `.svg`}
                        width={["75px", "120px"]}
                        height={["75px !important", "150px !important"]}
                        alt="avatar-logo"
                        mr={["30px", "50px"]}
                    />
                    <Box width="70%">
                        <Heading mb="10px" fontSize={["20px", "22px"]} lineHeight={["1.14", "1.15"]}>
                            Looks like you don’t have any health recommendations yet. Let’s change that now.
                        </Heading>
                        <Flex flexDirection={["column", "column", "row"]} height={["39px", "49px"]} mt="20px">
                            <SecondaryButton
                                width={["208px", "auto", "272px"]}
                                height="100%"
                                backgroundColor="transparent"
                                borderColor={theme.colours.hpPrimaryPurple.hex}
                                color={theme.colours.hpPrimaryPurple.hex}
                                mr="20px"
                                mb={["24px", "5px", 0]}
                                onClick={() => history.push("/health-risk-onboarding")}
                                p={["8px 11px 11px 11px", "4px 11px 7px 11px"]}
                                fontSize={["15px"]}
                            >
                                Take Health Assessment
                            </SecondaryButton>
                            <SecondaryButton
                                width={["142px", "auto", "272px"]}
                                height="100%"
                                backgroundColor="transparent"
                                borderColor={theme.colours.hpPrimaryPurple.hex}
                                color={theme.colours.hpPrimaryPurple.hex}
                                onClick={() => history.push("/find-health-stations")}
                                p={["8px 11px 11px 11px", "4px 11px 7px 11px"]}
                                fontSize={["15px"]}
                            >
                                Find a Station
                            </SecondaryButton>
                        </Flex>
                    </Box>
                </Flex>
            </Card>
        </>
    );
};

EmptyState.propTypes = {
    history: PropTypes.object,
    theme: PropTypes.object,
    gender: PropTypes.string
};

export default EmptyState;
