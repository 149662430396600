import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Flex, Text } from "rebass";
import { Form } from "formik";
import styled, { withTheme } from "styled-components";

import { MediaQuery } from "@sisuwellness/web-components";
import { media } from "@sisuwellness/web-components/themes";
import { useAsyncData } from "@sisuwellness/utilities/hooks";

import { getSwlhtHealthStationsAPI } from "actions/health-station";
import { StyledGrid } from "../FindAStation/styled";
import InputField from "components/InputField";

import directionIcon from "assets/images/svg/maps-direction.svg";
import postcodeFile from "../../data/postcodes-australia.js";

//-----------------------------------------------------------------------------

function parseCSV(csv) {
    const lines = csv.split("\n");

    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const line = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j].trim()] = line[j].trim();
        }

        result.push(obj);
    }

    return result;
}

const locationsData = parseCSV(postcodeFile);

function getCoordsFromPostcode(postcode) {
    const location = locationsData.find(loc => loc.postcode === postcode);
    return location ? { latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude) } : null;
}

function getDistance(lat1, long1, lat2, long2) {
    const R = 6371; // radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLong = ((long2 - long1) * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // distance in km
}

/**
 * Returns the closest [0,count] locations within 100km of a given postcode
 */
function findClosestLocations(postcode, locations, count) {
    const postcodeLocation = getCoordsFromPostcode(postcode);
    if (!postcodeLocation) {
        return [];
    }

    const closestLocations = [];

    for (const location of locations) {
        const distanceKm = getDistance(
            postcodeLocation.latitude,
            postcodeLocation.longitude,
            location.address.latitude,
            location.address.longitude
        );

        location.distanceKm = distanceKm;
        location.distanceString = `${parseFloat(location.distanceKm).toPrecision(2)}km away`;
        closestLocations.push(location);
    }

    return (
        closestLocations
            // sort by nearest first
            .sort((a, b) => a.distanceKm - b.distanceKm)
            // filter out locations 100km+ away
            .filter(location => location.distanceKm < 100)
            .reduce((acc, val) => {
                if (!acc.some(location => location.name === val.name)) acc.push(val);
                return acc;
            }, [])
            .slice(0, count)
    );
}

//-----------------------------------------------------------------------------

export const Container = styled(Card)`
    width: 100%;

    @media screen and (min-width: 769px) {
        min-width: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const StyledCard = styled(Card)`
    margin-bottom: 16px;
    padding: 16px 24px 24px 16px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid
        ${props => (props.active ? props.theme.colours.hpPrimaryPurple.hex : props.theme.colours.flat.white.hex)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const CardDetails = styled.div``;

const ColumnWrapper = styled(Flex)`
    min-height: 100px;
    background: ${props => props.theme.colours.flat.white.hex};

    @media screen and (min-width: 769px) {
        min-width: 700px;
    }

    ${media.mobile`
        height: auto;
        border-radius: 10px;
        background: ${props => props.theme.colours.hpPurple100.hex}
    `}
`;

const CardLink = styled.a`
    text-decoration: none;
    user-select: none;
`;

const DirectionIcon = styled.img`
    height: 40px;
    width: auto;
`;

//-----------------------------------------------------------------------------

const SWLHTEmbed = function({ theme }) {
    const dispatch = useDispatch();
    const inputRef = useRef();
    const { stations = [] } = useSelector(state => state.healthStation);
    const [{ isLoading }] = useAsyncData(() => dispatch(getSwlhtHealthStationsAPI()), []);

    const [postcode, setPostcode] = useState("");
    const [closestLocations, setClosestLocations] = useState([]);

    const searchPostcode = () => {
        setClosestLocations(findClosestLocations(postcode, stations, 5));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        searchPostcode();
    }, [postcode]);

    if (stations.length === 0 && !isLoading) return null;

    if (isLoading) {
        return null;
    }

    const renderLocations = () => {
        const locationComponents = [];
        for (let location of closestLocations) {
            const directionsUrl =
                "https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(location.address.formattedAddress);

            const locationComponent = (
                <CardLink
                    href={directionsUrl}
                    target={"_blank"}
                    title={`Navigate to ${location.address.formattedAddress}`}
                >
                    <StyledCard
                        className={""}
                        key={`installation-${location.healthStationInstallationId}`}
                        bg={["#ebe9f0", "white", "white"]}
                        active={false}
                        onClick={() => {}}
                    >
                        <CardDetails>
                            <Text fontSize="15px" fontWeight="500" color={theme.colours.blackTints700.hex}>
                                {location.name}
                            </Text>
                            <Text
                                fontSize="11px"
                                fontWeight="normal"
                                color={theme.colours.blackTints700.hex}
                                opacity="0.5"
                                my="10px"
                            >
                                {location.address.formattedAddress}
                            </Text>
                            <Text
                                fontSize="11px"
                                fontWeight="normal"
                                color={theme.colours.blackTints700.hex}
                                opacity="0.5"
                            >
                                {location.distanceString}
                            </Text>
                        </CardDetails>

                        <DirectionIcon src={directionIcon} alt={`Navigate to ${location.address.formattedAddress}`} />
                    </StyledCard>
                </CardLink>
            );

            locationComponents.push(locationComponent);
        }

        if (locationComponents.length) return <Card mt={["24px", "0px"]}>{locationComponents}</Card>;
    };

    return (
        <Container bg="white">
            <MediaQuery devices={["mobile"]}>
                <Box py={["0", "24px"]}>&nbsp;</Box>
            </MediaQuery>
            <ColumnWrapper flexDirection={["column", "row"]}>
                <Flex
                    flexDirection="column"
                    mr={["0px", "0px"]}
                    width={["100%", "auto"]}
                    bg={theme.colours.hpPurple100.hex}
                    flex={["normal", "2 0", "1 0"]}
                    style={{ borderRadius: "10px" }}
                >
                    <Text
                        fontSize="17px"
                        fontWeight="500"
                        color={theme.colours.blackTints700.hex}
                        my="16px"
                        px={["13px", "24px"]}
                    >
                        Find a SiSU Health Station
                    </Text>
                    <Box
                        as="hr"
                        bg="#4d4d4d"
                        mb="16px"
                        css={{
                            border: 0,
                            height: "1px",
                            opacity: "0.1"
                        }}
                    />
                    <Box width={"100%"} px={["13px", "24px"]} mt={["16px", "0"]}>
                        <StyledGrid flexDirection="column">
                            <Form onSubmit={event => event.preventDefault()}>
                                <Box mb="16px">
                                    <InputField
                                        className={"postcode"}
                                        leftChild={Map}
                                        customStyles={`
                                                        background: ${theme.colours.hpGrey200.hex};
                                                        border: none;
                                                    `}
                                        inputProps={{
                                            name: "postCode",
                                            placeholder: "Enter your postcode",
                                            "data-testid": "postCode",
                                            onChange: event => setPostcode(event.target.value),
                                            value: postcode,
                                            ref: inputRef,
                                            id: "text-field-id-postcode"
                                        }}
                                        fontFamily="GT Walsheim Pro"
                                        autoSuggest={true}
                                        handleOnSuggestionsFetch={() => {}}
                                        handleGetSuggestionValue={() => {}}
                                        handleRenderSuggestion={() => {}}
                                    />
                                </Box>
                                <MediaQuery devices={["tablet", "desktop"]}>{renderLocations()}</MediaQuery>
                            </Form>
                        </StyledGrid>
                    </Box>
                </Flex>
            </ColumnWrapper>
            <MediaQuery devices={["mobile"]}>
                <Box mb="30px">{renderLocations()}</Box>
            </MediaQuery>
        </Container>
    );
};

SWLHTEmbed.propTypes = {
    theme: PropTypes.object
};

export default withTheme(SWLHTEmbed);
