import * as Yup from "yup";

import Password from "@sisuwellness/utilities/Auth/Password";

// --------------------------------------------------------------------------

const PasswordUtil = new Password();
export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Please provide a valid email address.")
        .required("Please input your email"),
    existingPassword: Yup.string(),
    newPassword: Yup.string()
        .test("compare-old", "New and old password should not be the same", function(val) {
            if (!val) {
                return true;
            }
            return this.parent.existingPassword !== val ? true : false;
        })
        .test("password-valid", Password.errors.requirement, function(val) {
            if (!val) {
                return true;
            }
            return val ? Promise.resolve(PasswordUtil.isPasswordValid(val)) : false;
        }),
    newPasswordConfirm: Yup.string().test("compare-old", "Password do not match", function(val) {
        if (!val) {
            return true;
        }
        return this.parent.newPassword == val ? true : false;
    })
});
