import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { media } from "@sisuwellness/web-components/themes";

const Card = styled.div`
    vertical-align: top;
`;

/**
 * A cross-browser compatible workaround where applying overflow: hidden to an element that has a drop-shadow
 * renders a rectangle overlaying the shadow itself. nesting a div solves the problem across the board
 */
const ContentWrapper = styled.div`
    overflow: hidden;
    height: 100%;
    height: ${props => {
        let height = "auto";

        if (props.thin) {
            height = props.theme.cards.thin.height;
        } else if (props.large) {
            height = props.theme.cards.large.height;
        }
        return height;
    }};

    display: flex;
    flex: 1;
    flex-direction: ${props => props.flexDirection || "initial"};

    ${media.mobile`display: block`};
`;

class CardBase extends Component {
    render() {
        return (
            <Card className={`card-base-${this.props.className}`}>
                <ContentWrapper
                    thin={this.props.thin}
                    large={this.props.large}
                    flexDirection={this.props.flexDirection}
                    className={"content"}
                >
                    {this.props.children}
                </ContentWrapper>
            </Card>
        );
    }
}

CardBase.displayName = "CardBase";
CardBase.propTypes = {
    blue: PropTypes.any,
    thin: PropTypes.any,
    large: PropTypes.any,
    noPadding: PropTypes.any,
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    flexDirection: PropTypes.string
};

const StyledCardBase = styled(CardBase)`
    color: ${props => props.theme.colours.flat.teal.hex};
    border: 0;
    ${props => props.theme.shadowCss.rest()};
    margin-bottom: 10px;
    background: ${props => (props.blue ? props.theme.colours.flat.lightBlue.hex : props.theme.colours.flat.white.hex)};
    color: ${props => (props.blue ? props.theme.colours.flat.white.hex : props.theme.colours.flat.darkGray.hex)};
    border-radius: ${props => props.theme.defaults.borderRadius};
    padding: ${props => (props.noPadding ? 0 : props.theme.cards.padding)};
`;

export default StyledCardBase;
