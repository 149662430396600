import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Image } from "rebass";
import { flex } from "styled-system";

// --------------------------------------------------------------------

import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import Flex from "components/Flex";
import { OutcomeCardBtnSecondary } from "components/CommonComponents/Button";
import ExtraBreakpointWrapper from "components/ExtraBreakpointWrapper";

// --------------------------------------------------------------------

const StyledCard = styled(Card)`
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(50, 50, 71, 0.06), 0 4px 4px 0 rgba(50, 50, 71, 0.08);

    ${flex}
`;

const StyledBackground = styled(Flex).attrs({ ai: "center", jc: "center" })`
    background: ${({ background }) => background};
`;

const PlansCard = ({ link, title, text, image, onClick, background }) => (
    <ExtraBreakpointWrapper maxHeight="269px">
        <StyledCard
            p="0px"
            pb="0px"
            flexGrow={1}
            flexBasis="400px"
            maxWidth={["100%", "", "664px"]}
            maxHeight={["auto", "208px"]}
        >
            <Flex size="100%" fd={["column", "row"]}>
                <StyledBackground background={background} width={["100%", "50%"]} height={["112px", "auto"]}>
                    <Image alt="plan.svg" width={["50%", "100%"]} src={image} />
                </StyledBackground>

                <Flex width={["100%", "50%"]} height="100%" fd="column" jc="space-between" p="24px" gap="16px">
                    <Flex fd="column" gap="8px">
                        <Heading fontSize={["22px", "28px"]}>{title}</Heading>
                        <Heading fontSize="16px" fontWeight="light">
                            {text}
                        </Heading>
                    </Flex>
                    <OutcomeCardBtnSecondary onClick={() => onClick(link)}>Access Now</OutcomeCardBtnSecondary>
                </Flex>
            </Flex>
        </StyledCard>
    </ExtraBreakpointWrapper>
);

PlansCard.defaultProps = {
    background: "linear-gradient(to right, #fff3f3, #ebd1ff)"
};

PlansCard.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    onClick: PropTypes.func,
    background: PropTypes.string
};

export default PlansCard;
