import BaseEndPoint from "../BaseEndPoint";

export default class HealthStation extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/health-stations/" + path);
    }

    getConfiguration() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("config"), settings);
    }

    updateConfiguration(configuration) {
        return this.put(configuration.healthStationId, configuration);
    }

    updateLastCommunicatedTime(healthStationId, data) {
        return this.post(`${healthStationId}/update-last-communicated-time`, data);
    }

    updateAdScreenStatus(healthStationId) {
        return this.post(`${healthStationId}/update-ad-screen-status`, {});
    }

    updateDiagnosticsData(healthStationId, data) {
        return this.post(`${healthStationId}/diagnostics-data`, data);
    }

    /**
     * Used by Health Station users to get their own Health Station resource
     * @return {Promise<Response>}
     */
    getMe() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("me"), settings);
    }

    public() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("installations/public"), settings, true);
    }

    swlht() {
        const settings = { ...this._getDefaultFetchSettings(), method: "GET" };
        return this._send(this.getUrlPath("installations/swlht"), settings, true);
    }
}
