import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Image, Text } from "rebass";

// ---------------------------------------------------------------------------------------------------------

import Flex from "components/Flex";
import { MediaQuery } from "@sisuwellness/web-components";
import { BodyMassIndex } from "@sisuwellness/utilities/HealthCalculators";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { useUserHealthCheckData } from "views/HeartAge";
import { pickAttribute } from "utilities/commonUtils";
import { DataCell, DifferenceLabel } from "views/HeartAge/commonElements";

import { BloodPressureSlider, SmokingSlider, WeightSlider } from "./metricSliders";
import { calculateHeartAgeParams } from "views/HeartAge/utils";
import OverviewCardUI from "../overviewCardUI";
import { METRIC_TEXTSPECS } from "../textspecs";
import CardStack from "components/Cards/CardStack";
import { PORTAL_IMAGE } from "constants/imagePath";
import { useSelector } from "react-redux";

// ---------------------------------------------------------------------------------------------------------

const ResetText = styled(Text)`
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    text-decoration: underline;
`;

// ---------------------------------------------------------------------------------------------------------

export const SimulatedTab = ({ displayTab }) => {
    const { isCountryUK } = useSelector(state => state.deviceLocation);
    const { latestHealthCheck } = useUserHealthCheckData();

    const defaults = {
        weight: pickAttribute(["weight"], latestHealthCheck),
        systolic: pickAttribute(["sys"], latestHealthCheck),
        isSmoker: pickAttribute(["isSmoker"], latestHealthCheck)
    };

    const [weight, setWeight] = useState(defaults.weight);
    const [systolic, setSystolic] = useState(defaults.systolic);
    const [isSmoker, setIsSmoker] = useState(defaults.isSmoker);

    const handleOnReset = () => {
        setWeight(defaults.weight);
        setSystolic(defaults.systolic);
        setIsSmoker(defaults.isSmoker);
    };

    const { age, heartAge, heartAgeRating, tenYearCvdRisk } = useMemo(() => {
        const height = pickAttribute(["height"], latestHealthCheck);
        const bmi = BodyMassIndex.getBMI(height, weight);

        return calculateHeartAgeParams({ ...latestHealthCheck, bmi, sys: systolic, isSmoker });
    }, [weight, systolic, isSmoker, latestHealthCheck]);

    return (
        displayTab && (
            <Flex fd="column" gap="24px" mt="16px" width="100%">
                <HeadingLabel fontSize={["13px", "", "15px"]}>
                    See how your metrics can be improved, through biometric and lifestyle changes.
                </HeadingLabel>
                <Flex fd={["column", "row", "column"]} gap="32px">
                    <CardStack gap={["8px", "16px", "32px"]} py="24px">
                        <OverviewCardUI {...METRIC_TEXTSPECS.HEART_AGE}>
                            <Flex width="100%" gap="16px" jc="space-between">
                                <DataCell
                                    age={heartAge}
                                    title="Simulated Heart Age"
                                    gap={["16px", "8px", "4px"]}
                                    width={["70px", "", "140px"]}
                                    data-testid="cell-heart-age"
                                />
                                <DataCell
                                    age={age}
                                    title="Actual Age"
                                    gap={["16px", "8px", "4px"]}
                                    data-testid="cell-actual-age"
                                />
                                <MediaQuery devices={["desktop", "tablet"]}>
                                    <DifferenceLabel heartAge={heartAge} actualAge={age} rating={heartAgeRating} />
                                </MediaQuery>
                            </Flex>
                        </OverviewCardUI>

                        {isCountryUK ? (
                            <>
                                <MediaQuery devices={["desktop"]}>
                                    <Flex bg="white" p="16px" justifyContent="center" flex={1}>
                                        <Image
                                            alt="uk-heart-age-overview.svg"
                                            src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                        />
                                    </Flex>
                                </MediaQuery>
                                <MediaQuery devices={["tablet"]}>
                                    <Flex bg="white" p="16px" justifyContent="center" flexGrow={1}>
                                        <Image
                                            alt="uk-heart-age-overview.svg"
                                            src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                        />
                                    </Flex>
                                </MediaQuery>
                            </>
                        ) : (
                            <OverviewCardUI
                                {...METRIC_TEXTSPECS.TEN_YEAR_CVD_RISK}
                                text="Your simulated risk of developing cardiovascular disease the next 10 years is."
                            >
                                <DataCell
                                    units="%"
                                    age={tenYearCvdRisk}
                                    gap={["4px", "20px", "0px"]}
                                    data-testid="cell-heart-stroke"
                                />
                            </OverviewCardUI>
                        )}
                    </CardStack>
                    <Flex fd="column" gap="24px" px={["16px", "0px"]}>
                        <Flex fd={["column", "", "row"]} jc="space-between" gap="24px">
                            <WeightSlider weight={weight} onChange={setWeight} defaultValue={defaults.weight} />
                            <BloodPressureSlider
                                systolic={systolic}
                                onChange={setSystolic}
                                defaultValue={defaults.systolic}
                            />
                            <SmokingSlider
                                isSmoker={isSmoker}
                                onChange={setIsSmoker}
                                defaultValue={defaults.isSmoker}
                            />
                        </Flex>
                        <ResetText
                            onClick={handleOnReset}
                            data-testid="reset-btn"
                            color="hpGrayishBlueText"
                            fontSize={["14px", "", "18px"]}
                        >
                            Reset
                        </ResetText>
                    </Flex>
                </Flex>
            </Flex>
        )
    );
};

SimulatedTab.propTypes = {
    displayTab: PropTypes.bool
};
