import InvalidArgumentError from "../../Errors/InvalidArgumentError";

/**
 * source: https://www.heartfoundation.org.au/images/uploads/publications/PRO-167_Hypertension-guideline-2016_WEB.pdf
 * */
export default class NationalHeartFoundationAustralia {
    /**
     * Constructor
     */
    constructor(systolic, diastolic) {
        this._validateValue(systolic);
        this._validateValue(diastolic);
        this._systolic = systolic;
        this._diastolic = diastolic;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "BLOOD_PRESSURE";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "NHFA_BP";
    }

    /**
     * Validates the given systolic or diastolic value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(reading) {
        if (!(typeof reading === "number") || Number.isNaN(reading) || reading < 0) {
            throw new InvalidArgumentError("Systolic and Diastolic must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the diastolic and systolic values are in the 'Optimal' range
     *
     * @returns {boolean}
     */
    isOptimal() {
        return this._systolic < 120 && this._diastolic < 80;
    }

    /**
     * Returns if the diastolic and systolic values are in the 'Normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return (this._systolic >= 120 && this._systolic <= 129) || (this._diastolic >= 80 && this._diastolic <= 84);
    }

    /**
     * Returns if the diastolic and systolic values are in the 'High Normal' range
     *
     * @returns {boolean}
     */
    isHighNormal() {
        return (this._systolic >= 130 && this._systolic <= 139) || (this._diastolic >= 85 && this._diastolic <= 89);
    }

    /**
     * Returns if the diastolic and systolic values are in the 'Grade 1 (mild) hypertension' range
     *
     * @returns {boolean}
     */
    isMildHypertension() {
        return (this._systolic >= 140 && this._systolic <= 159) || (this._diastolic >= 90 && this._diastolic <= 99);
    }

    /**
     * Returns if the diastolic and systolic values are in the 'Grade 2 (moderate) hypertension' range
     *
     * @returns {boolean}
     */
    isModerateHypertension() {
        return (this._systolic >= 160 && this._systolic <= 179) || (this._diastolic >= 100 && this._diastolic <= 109);
    }

    /**
     * Returns if the diastolic and systolic values are in the 'Grade 3 (severe) hypertension' range
     *
     * @returns {boolean}
     */
    isSevereHypertension() {
        return this._systolic >= 180 || this._diastolic >= 110;
    }

    /**
     * Returns if the diastolic and systolic values are in the 'Isolated systolic hypertension' range
     *
     * @returns {boolean}
     */
    isIsolatedSystolicHypertension() {
        return this._systolic > 140 && this._diastolic < 90;
    }

    /**
     * Returns an object representing various data for each BP range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            optimal: {
                key: "optimal",
                label: "Optimal",
                range: "< 120/80",
                riskLevel: 1 / 7
            },

            normal: {
                key: "normal",
                label: "Normal",
                range: "120/80+",
                riskLevel: 2 / 7
            },

            highNormal: {
                key: "highNormal",
                label: "High-Normal",
                range: "130/85+",
                riskLevel: 3 / 7
            },

            mildHypertension: {
                key: "mildHypertension",
                label: "High",
                range: "140/90+",
                riskLevel: 4 / 7
            },

            moderateHypertension: {
                key: "moderateHypertension",
                label: "Very High",
                range: "160/100+",
                riskLevel: 5 / 7
            },

            severeHypertension: {
                key: "severeHypertension",
                label: "Severe",
                range: "180/110+",
                riskLevel: 6 / 7
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            //TODO: Here, insert the case for this.isIsolatedSystolicHypertension() once downstream can handle it: https://sisuwellness.atlassian.net/browse/SU-1388
            case this.isSevereHypertension():
                info = NationalHeartFoundationAustralia.ranges.severeHypertension.range;
                break;
            case this.isModerateHypertension():
                info = NationalHeartFoundationAustralia.ranges.moderateHypertension.range;
                break;
            case this.isMildHypertension():
                info = NationalHeartFoundationAustralia.ranges.mildHypertension.range;
                break;
            case this.isHighNormal():
                info = NationalHeartFoundationAustralia.ranges.highNormal.range;
                break;
            case this.isNormal():
                info = NationalHeartFoundationAustralia.ranges.normal.range;
                break;
            case this.isOptimal():
                info = NationalHeartFoundationAustralia.ranges.optimal.range;
                break;
        }

        return info;
    }

    /**
     * returns normalised key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let key = "";
        switch (true) {
            //TODO: Here, insert the case for this.isIsolatedSystolicHypertension() once downstream can handle it: https://sisuwellness.atlassian.net/browse/SU-1388
            case this.isSevereHypertension():
                key = NationalHeartFoundationAustralia.ranges.severeHypertension.key;
                break;
            case this.isModerateHypertension():
                key = NationalHeartFoundationAustralia.ranges.moderateHypertension.key;
                break;
            case this.isMildHypertension():
                key = NationalHeartFoundationAustralia.ranges.mildHypertension.key;
                break;
            case this.isHighNormal():
                key = NationalHeartFoundationAustralia.ranges.highNormal.key;
                break;
            case this.isNormal():
                key = NationalHeartFoundationAustralia.ranges.normal.key;
                break;
            case this.isOptimal():
                key = NationalHeartFoundationAustralia.ranges.optimal.key;
                break;
        }

        return key;
    }

    /**
     * returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let label = "";
        switch (true) {
            //TODO: Here, insert the case for this.isIsolatedSystolicHypertension() once downstream can handle it: https://sisuwellness.atlassian.net/browse/SU-1388
            case this.isSevereHypertension():
                label = NationalHeartFoundationAustralia.ranges.severeHypertension.label;
                break;
            case this.isModerateHypertension():
                label = NationalHeartFoundationAustralia.ranges.moderateHypertension.label;
                break;
            case this.isMildHypertension():
                label = NationalHeartFoundationAustralia.ranges.mildHypertension.label;
                break;
            case this.isHighNormal():
                label = NationalHeartFoundationAustralia.ranges.highNormal.label;
                break;
            case this.isNormal():
                label = NationalHeartFoundationAustralia.ranges.normal.label;
                break;
            case this.isOptimal():
                label = NationalHeartFoundationAustralia.ranges.optimal.label;
                break;
        }

        return label;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 6,
            riskLevel = 0;
        switch (true) {
            case this.isSevereHypertension():
                riskLevel = 6 / (totalRiskLevel + 1);
                break;
            case this.isModerateHypertension():
                riskLevel = 5 / (totalRiskLevel + 1);
                break;
            case this.isMildHypertension():
                riskLevel = 4 / (totalRiskLevel + 1);
                break;
            case this.isHighNormal():
                riskLevel = 3 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
            case this.isOptimal():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }
}
