import BaseConfiguration from "./BaseConfiguration";
import Consent from "../../Consent/Consent";

export default class HealthStationConfiguration extends BaseConfiguration {
    get campaignId() {
        let campaignId = null;

        if (
            this._configuration.hasOwnProperty("campaign") &&
            this._configuration.campaign &&
            this._configuration.campaign.hasOwnProperty("campaignId") &&
            this._configuration.campaign.campaignId
        ) {
            campaignId = this._configuration.campaign.campaignId;
        }

        return campaignId;
    }

    get countryId() {
        let countryId = null;

        if (
            this._configuration.hasOwnProperty("healthStationInstallation") &&
            this._configuration.healthStationInstallation &&
            this._configuration.healthStationInstallation.hasOwnProperty("address") &&
            this._configuration.healthStationInstallation.address
        ) {
            countryId = this._configuration.healthStationInstallation.address.countryId;
        }

        return countryId;
    }

    getRegistrationConsents(response) {
        /* istanbul ignore next */ // FIXME: coverage requires schema to contain consents
        return this._configuration.registration.consents.map(consent => {
            let c = new Consent(consent);
            c.setResponse(response);
            return c;
        });
    }

    getRegistrationDisclaimer() {
        return this._configuration.registration.disclaimer;
    }

    get campaign() {
        let campaign = null;

        if (this._configuration.hasOwnProperty("campaign")) {
            campaign = this._configuration.campaign;
        }

        return campaign;
    }

    get clientId() {
        return this.campaign ? this.campaign.clientId : null;
    }

    get healthStationInstallation() {
        let healthStationInstallation = null;

        if (this._configuration.hasOwnProperty("healthStationInstallation")) {
            healthStationInstallation = this._configuration.healthStationInstallation;
        }

        return healthStationInstallation;
    }

    get scriptsNow() {
        return this._configuration.hasOwnProperty("scriptsNow") ? this._configuration.scriptsNow : null;
    }
}
