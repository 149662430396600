import React from "react";
import { path } from "ramda";
import { fixedNumber } from "utilities/commonUtils";
import { Cell, Header } from "./commonElements";

const handleCellConstruct = row => {
    const qriskScore = path(["value"], row);
    const value = qriskScore && fixedNumber(qriskScore);
    return <Cell value={value} row={row} unit="%" />;
};

export const qriskDef = iconTypes => ({
    minWidth: 92,
    accessor: "qriskScore",
    Cell: handleCellConstruct,
    Header: () => <Header label="Heart & Stroke" iconType={iconTypes.qrisk} />
});
