import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(score) {
        this._validateValue(score);
        this._score = score;
    }

    /**
     * Returns the given stress value
     * @returns {Number}
     */
    get() {
        return this._score;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "STRESS";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_STRESS";
    }

    /**
     * Validates the given stress value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(score) {
        if (!(typeof score === "number") || Number.isNaN(score) || score < 0) {
            throw new InvalidArgumentError("Stress must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the given stress score is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._score < 9;
    }

    /**
     * Returns if the given stress score is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._score >= 9 && this._score < 12;
    }

    /**
     * Returns if the given stress score is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        return this._score >= 12;
    }

    /**
     * Returns an object representing various data for each stress range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            normal: {
                key: "normal",
                label: "Normal",
                range: "<9"
            },
            high: {
                key: "high",
                label: "High",
                range: "9 - 12"
            },
            veryHigh: {
                key: "veryHigh",
                label: "Very High",
                range: "12+"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get rangeInfo() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.range;
            case this.isHigh():
                return SiSU.ranges.high.range;
            case this.isVeryHigh():
                return SiSU.ranges.veryHigh.range;
        }
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get key() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.key;
            case this.isHigh():
                return SiSU.ranges.high.key;
            case this.isVeryHigh():
                return SiSU.ranges.veryHigh.key;
        }
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get label() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.label;
            case this.isHigh():
                return SiSU.ranges.high.label;
            case this.isVeryHigh():
                return SiSU.ranges.veryHigh.label;
        }
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    // eslint-disable-next-line
    get riskLevel() {
        const totalRiskLevel = 3;
        switch (true) {
            case this.isNormal():
                return 1 / (totalRiskLevel + 1);
            case this.isHigh():
                return 2 / (totalRiskLevel + 1);
            case this.isVeryHigh():
                return 3 / (totalRiskLevel + 1);
        }
    }
}
