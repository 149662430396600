import React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";

import { LineChart } from "components/Templates/TrendChart";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import OutcomeHistory from "components/Templates/OutcomeHistory";

import cvdRiskProps from "./cvdRiskProps";

const TenYearCvdRiskTrend = ({ healthChecks, lastMeasurementDate, latestHealthCheckData, ...rest }) => (
    <Box width="100%" textAlign="left" mb={["30px", "60px"]} {...rest}>
        <HeadingLabel fontSize="22px" fontWeight="500">
            Your Heart and Stroke Risk History
        </HeadingLabel>
        <NormalLabel fontSize="15px" fontWeight="300" mt="8px">
            An analytical overview of your health over time.
        </NormalLabel>
        <LineChart healthChecks={healthChecks} latestHealthCheckData={latestHealthCheckData} {...cvdRiskProps}>
            {({ filter, historyProps }) => (
                <OutcomeHistory py="24px" filter={filter} {...historyProps} lastMeasurementDate={lastMeasurementDate} />
            )}
        </LineChart>
    </Box>
);

TenYearCvdRiskTrend.propTypes = {
    ...Box.propTypes,
    healthChecks: PropTypes.array,
    lastMeasurementDate: PropTypes.string,
    latestHealthCheckData: PropTypes.object
};

export default TenYearCvdRiskTrend;
