import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";

class HeartRate extends Component {
    render() {
        const { t, heartRate, theme, date, ...rest } = this.props;

        let guideline = null;
        let guidelineStyle = null;

        if (heartRate) {
            guideline = new Guidelines.HeartRate.SiSU(heartRate);
            guidelineStyle = theme.guidelines.SiSUHeartRate[guideline.key];
        }

        return (
            <Attribute
                {...rest}
                t={t}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={t("Heart Rate")}
                date={date}
                primaryStatUnit={"bpm"}
                primaryStatValue={heartRate}
                icon={theme.icons.types.heartRate}
            />
        );
    }
}

HeartRate.displayName = "Heart Rate Attribute";
HeartRate.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    heartRate: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(HeartRate);
