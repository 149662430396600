import { pathOr, toPairs } from "ramda";

import { theme } from "@sisuwellness/web-components";
import {
    isMobileView,
    createLegends,
    calculateMinMax,
    createYGridValues,
    flattenPointValues,
    createPlottableDataPoints,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { QRISK3 } from "constants/trend-charts";
import { fixedNumber } from "utilities/commonUtils";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: false,
    key: ["qriskScore"],
    additionalKeys: ["rating", "unit"]
};

/**
 * Update data with rating
 * @param {object} data
 * @return {{ rating: { key: string, label: string, ranges: object } }}
 */
const qriskRatingCalc = data => {
    const { guideline, currentValue } = calculatePointColor(QRISK3, data);

    return {
        ...data,
        unit: "%",
        qriskScore: fixedNumber(currentValue),
        rating: { key: guideline?.key, label: guideline?.label, ranges: guideline?.ranges }
    };
};

const plot = {
    unit: () => "Heart & Stroke Risk (%)",

    color: ({ rating } = {}) => theme.guidelines.Qrisk[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = [], settings) => {
        const points = createPlottableDataPoints(data => qriskRatingCalc(data, settings))(
            healthChecks,
            METRIC_FIELDS.key,
            METRIC_FIELDS.additionalKeys
        );

        return points;
    },

    yRange: (points = []) => {
        const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(0, maximum, { parts: 5, rounded: METRIC_FIELDS.rounded }).map(num =>
            fixedNumber(num)
        );

        return {
            min: 0,
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: (points = []) => {
        const ranges = toPairs(pathOr({}, [0, "data", 0, "rating", "ranges"], points))
            .map(([key, value]) =>
                value.key == "low" ? { [key]: { ...value, label: "Low" } } : { [key]: { ...value, label: "Elevated" } }
            )
            .reduce((acc, cur) => ({ ...acc, ...cur }), {});
        return createLegends(ranges, METRIC_INDICATOR.QRISK3.themeKey, "Heart & Stroke Risk");
    },

    historyProps: (filteredPoints = [], latestHealthCheckData = {}) => {
        const { qriskScore } = latestHealthCheckData;
        const { rating } = qriskRatingCalc({ qriskScore });

        const meanQrisk = calculateMeanValue("qriskScore", filteredPoints[0]);

        const { rating: meanRating } = qriskRatingCalc({ qriskScore: meanQrisk });

        return {
            unit: "%",
            value: qriskScore,
            meanValue: fixedNumber(meanQrisk),
            valueColor: theme.guidelines.Qrisk[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.Qrisk[(meanRating?.key)]?.sisuPortal?.hex
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
