import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { Guidelines } from "@sisuwellness/utilities";
import { PORTAL_IMAGE } from "constants/imagePath";
import { find, propEq } from "ramda";
import { METRIC_INDICATOR } from "./mapIndicators";
import { BLOOD_PRESSURE } from "constants/trend-charts";
import { theme } from "@sisuwellness/web-components";

/**
 * Get metric data for given type
 * @param {string} type - type of metric
 * @param {object} data - citizen/healthcheck data
 *
 */
const getMetric = (type, data) => {
    const metricObject = calculatePointColor(type, data);

    const { key, rating, riskLevel, guideline } = metricObject;
    if (!guideline) return null;

    const { title, themeKey = "" } = METRIC_INDICATOR[type] || {};

    const totalRiskLevel = Object.keys(theme.guidelines[themeKey] || {}).length;

    const isRiskHigh = totalRiskLevel / (totalRiskLevel + 1) === riskLevel;

    return {
        title,
        rating,
        isRiskHigh,
        risk: riskLevel * 100,
        recommendation: guideline.recommendation || "",
        color: theme.guidelines[themeKey]?.[key]?.sisuPortal?.hex
    };
};

/**
 * Get metric data by metric recommendation (BP Metric data)
 * @param {string} type - type of metric
 * @param {object} recommendation - metric recommendation object
 * @param {string} recommendation.rating - rating key
 */
export const getBPMetricBasedOnBPRecommendation = (type, recommendation) => {
    if (type !== BLOOD_PRESSURE || !recommendation) return null;

    const { rating } = recommendation;

    const ranges = Guidelines.BloodPressure.NationalHeartFoundationAustralia.ranges;

    const { key, label, riskLevel } = find(propEq("key", rating), Object.values(ranges)) || {};

    if (!key) return null; // Return null (Hide card) if key is not present ( or key is 'notSure' )

    const { title, themeKey } = METRIC_INDICATOR[type];

    const totalRiskLevel = Object.keys(theme.guidelines[themeKey]).length;

    const isRiskHigh = totalRiskLevel / (totalRiskLevel + 1) === riskLevel;

    return {
        title,
        isRiskHigh,
        rating: label,
        icon: `${PORTAL_IMAGE}/healthHub/bloodpressure-unfaded.svg`,
        color: theme.guidelines[themeKey][key].sisuPortal.hex
    };
};

export default getMetric;
