import React from "react";
import PropTypes from "prop-types";

import { Card, Flex, Text } from "rebass";
import { toPairs } from "ramda";
import styled from "styled-components";

// ----------------------------------------------------------------------------

import DoughnutChart from "components/Templates/TrendChart/Doughnut";
import { fixedNumber } from "utilities/commonUtils";
import { COMPOSITION_COLOR } from "constants/trend-charts";

// ----------------------------------------------------------------------------

const PositionedText = styled(Text)`
    top: 54%;
    left: 52%;
    position: absolute;
    transform: translate(-50%, -50%);
`;

const CompositionGraph = ({ value: muscleMass, bodyFatPercent }) => (
    <>
        <DoughnutChart
            data={{
                datasets: [
                    {
                        data: muscleMass ? [bodyFatPercent, muscleMass, 100 - (muscleMass + bodyFatPercent)] : [100],
                        backgroundColor: muscleMass
                            ? [COMPOSITION_COLOR.Fat, COMPOSITION_COLOR.Muscle, COMPOSITION_COLOR.Other]
                            : ["white"]
                    }
                ]
            }}
        >
            <PositionedText width="50px" whiteSpace="pre-wrap" color="hpPrimaryPurple" textAlign="center">
                {muscleMass ? (
                    <>
                        <Text as="span" fontSize="20px">
                            {fixedNumber(muscleMass)}
                        </Text>
                        <Text as="span" fontSize="12px">
                            %
                        </Text>
                        <Text fontSize="12px" mt="-8px">
                            Muscle
                        </Text>
                    </>
                ) : (
                    <Text as="span" fontSize="20px">
                        ?
                    </Text>
                )}
            </PositionedText>
        </DoughnutChart>
        <Flex flexDirection="column" justifyContent="space-between" mt="8px">
            {toPairs(COMPOSITION_COLOR).map(([key, color], ix) => (
                <Flex alignItems="center" key={ix}>
                    <Card mr="8px" width="12px" height="6px" borderRadius="10px" bg={muscleMass ? color : "white"} />
                    <Text color="hpGrayishBlueText" fontSize="13px" fontWeight="light">
                        {key}
                    </Text>
                </Flex>
            ))}
        </Flex>
    </>
);

CompositionGraph.propTypes = {
    value: PropTypes.any,
    bodyFatPercent: PropTypes.any
};

export default CompositionGraph;
