import React from "react";
// import { path } from "ramda";
import { Cell, Header, LinkCell } from "./commonElements";
import routes from "constants/routes";
import { fixedNumber } from "utilities/commonUtils";

const handleCellConstruct = (row, { isInterestedInPremium, isPremium }) => {
    return isPremium ? (
        <Cell data-testid="table-cell-muscleMass" row={row} value={fixedNumber(row.value)} unit="%" />
    ) : (
        isInterestedInPremium && (
            <LinkCell row={row} data-testid="table-cell-muscleMass" href={routes.bodyComposition} text="Calculate" />
        )
    );
};

export const muscleMassDef = (iconTypes, premiumProps) => ({
    minWidth: 92,
    accessor: "muscleMass",
    Cell: row => handleCellConstruct(row, premiumProps),
    Header: () => <Header label="Skeletal Muscle" iconType={iconTypes.muscleMass} />
});
