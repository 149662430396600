import { Guidelines, UnitConverter } from "@sisuwellness/utilities";
import React from "react";
import { fixedNumber } from "utilities/commonUtils";

import {
    BLOOD_PRESSURE,
    BODY_MASS_INDEX,
    HEART_RATE,
    DIABETES,
    BODY_FAT,
    WEIGHT,
    QRISK3,
    ALCOHOL_RISK,
    ACTIVITY,
    HRA_HEALTH_SCORE,
    MUSCLE_MASS
} from "constants/trend-charts";
import { baseTheme as theme } from "@sisuwellness/web-components/theme";

//------------------------------------------------------------------------------
/**
 *
 * @param {String} riskType Type of Risk
 * @param {Object} guidelineRange Object of range of Guidelines
 * @return {array} Array of Object of color, ranges and labels for Guidelines
 */
export const progress = (riskType, guidelineRange, isMetric = true) => {
    const weightUnit = isMetric ? "kgs" : "lbs";

    switch (riskType) {
        case BODY_MASS_INDEX:
            return [
                {
                    color: theme.guidelines.bodyMassIndex.low.sisuPortal.hex,
                    label: guidelineRange.low.label,
                    range: guidelineRange.low.range,
                    recommendation: (
                        <p>
                            Your BMI is below 18.5 and this suggests that your body weight may be too low. Being
                            underweight is not good for your health as it can contribute to a weakened immune system,
                            fragile bones and feeling tired. You should consider talking to a health professional about
                            increasing your BMI.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyMassIndex.normal.sisuPortal.hex,
                    label: guidelineRange.normal.label,
                    range: guidelineRange.normal.range,
                    recommendation: (
                        <p>
                            Congratulations your BMI is healthy. Maintaining a healthy BMI brings with it many health
                            benefits.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyMassIndex.elevated.sisuPortal.hex,
                    label: guidelineRange.elevated.label,
                    range: guidelineRange.elevated.range,
                    recommendation: (
                        <p>
                            Your BMI is elevated and you may want to consider losing weight to bring your BMI down to
                            below 25.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyMassIndex.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range,
                    recommendation: (
                        <p>
                            Your BMI is high and this can impact on your heath in a number of ways. Losing weight will
                            improve your overall health.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyMassIndex.veryHigh.sisuPortal.hex,
                    label: guidelineRange.veryHigh.label,
                    range: guidelineRange.veryHigh.range,
                    recommendation: (
                        <p>
                            A BMI of 30+ is classified as obese, a condition that is a serious threat to your
                            well-being. You should consult your doctor and consider lifestyle changes to reduce your
                            weight.
                        </p>
                    )
                }
            ];

        case HEART_RATE:
            return [
                {
                    color: theme.guidelines.SiSUHeartRate.low.sisuPortal.hex,
                    label: guidelineRange.low.label,
                    range: guidelineRange.low.range,
                    recommendation: (
                        <p>
                            A slow heart rate is considered anything slower than 60 beats per minute at rest. A slow
                            heart rate can indicate a very healthy heart, but it might also indicate a medical problem
                            if you experience dizziness or shortness of breath. If you are experiencing these symptoms,
                            consult your doctor.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.SiSUHeartRate.normal.sisuPortal.hex,
                    label: guidelineRange.normal.label,
                    range: guidelineRange.normal.range,
                    recommendation: (
                        <p>
                            Your heart rate is excellent. Keeping your heart healthy can help to boost your overall
                            well-being in a number of powerful ways, from strengthening your brain to reducing your risk
                            of heart disease.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.SiSUHeartRate.elevated.sisuPortal.hex,
                    label: guidelineRange.elevated.label,
                    range: guidelineRange.elevated.range,
                    recommendation: (
                        <p>
                            Your heart rate is higher than it should be. There may be a number of reason for this,
                            exercise, smoking or recent coffee intake. Check it again in a couple days of days and if it
                            is still high and you are concerned, talk to your doctor.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.SiSUHeartRate.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range,
                    recommendation: (
                        <p>
                            If your heart rate is regularly over 100 beats per minute you should consult you doctor,
                            particularly if you experience, dizziness, light headedness or chest pains.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.SiSUHeartRate.veryHigh.sisuPortal.hex,
                    label: guidelineRange.veryHigh.label,
                    range: guidelineRange.veryHigh.range,
                    recommendation: (
                        <p>
                            Your heart rate is very high. You should rest and check it again within 15 minutes. If it is
                            still high and you feel short of breath or have chest pain, you need to consult your doctor
                            immediately.
                        </p>
                    )
                }
            ];

        case BODY_FAT:
            return [
                {
                    color: theme.guidelines.bodyFat.low.sisuPortal.hex,
                    label: guidelineRange.low.label,
                    range: guidelineRange.low.range,
                    recommendation: (
                        <p>
                            Your body fat is low and you need fat to protect your joints and organs, as well as keep you
                            warm. Over time, low body fat can lead to health complications.{" "}
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyFat.normal.sisuPortal.hex,
                    label: guidelineRange.normal.label,
                    range: guidelineRange.normal.range,
                    recommendation: (
                        <p>
                            Congratulations, you're within the healthy range for body fat. Keep this up by maintaining a
                            balanced diet and exercising.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyFat.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range,
                    recommendation: (
                        <p>
                            Your body fat is elevated for your height and weight and it would be worth you making some
                            small changes.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.bodyFat.veryHigh.sisuPortal.hex,
                    label: guidelineRange.veryHigh.label,
                    range: guidelineRange.veryHigh.range,
                    recommendation: (
                        <p>
                            Your body fat is high for your height, weight and age. This can impact your health and risk
                            for a range of diseases. You should consider making some lifestyle changes to lose weight
                            and may want to consult your doctor.
                        </p>
                    )
                }
            ];

        case DIABETES:
            return [
                {
                    color: theme.guidelines.AustralianDiabetesRisk.low.sisuPortal.hex,
                    label: guidelineRange.low.label,
                    range: guidelineRange.low.range,
                    recommendation: (
                        <p>
                            Congratulations on your low diabetes risk! Keep monitoring the lifestyle factors to minimise
                            your risk.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.AustralianDiabetesRisk.intermediate.sisuPortal.hex,
                    label: guidelineRange.intermediate.label,
                    range: guidelineRange.intermediate.range,
                    recommendation: (
                        <p>
                            Keep an eye on your diabetes risk factors and consider discussing with your doctor or
                            pharmacist during your next visit.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.AustralianDiabetesRisk.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range,
                    recommendation: (
                        <p>
                            See your doctor or pharmacist for a more detailed diabetes risk assessment including fasting
                            blood glucose test unless you have done so recently.
                        </p>
                    )
                }
            ];

        case Guidelines.PSS4.RiskCode:
            return [
                {
                    color: theme.guidelines.PSS4.low.sisuPortal.hex,
                    label: guidelineRange.low.label,
                    range: guidelineRange.low.range,
                    recommendation: (
                        <p>
                            Your score indicates you are managing your stress well. You should however regularly monitor
                            your stress levels as a change in circumstances or your health can impact on your coping
                            ability.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.PSS4.moderate.sisuPortal.hex,
                    label: guidelineRange.intermediate.label,
                    range: guidelineRange.intermediate.range,
                    recommendation: (
                        <p>
                            Your score indicates that you are regularly feeling stressed and that this is an issue for
                            you. You should consider taking some positive action to combat your stress.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.PSS4.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range,
                    recommendation: (
                        <p>
                            Your score indicates that you regularly feel highly stressed. Stress can be detrimental to
                            you health and you should consider taking positive action to combat it. If you need urgent
                            help, contact your doctor, Beyond Blue or in the UK the Samaritans.
                        </p>
                    )
                }
            ];

        case WEIGHT:
            return [
                {
                    color: theme.guidelines.weight.low.sisuPortal.hex,
                    label: guidelineRange.low.label,
                    range: guidelineRange.low.range + ` (${weightUnit})`,
                    recommendation: (
                        <p>
                            Your BMI is below 18.5 and this suggests that your body weight may be too low. Being
                            underweight is not good for your health as it can contribute to a weakened immune system,
                            fragile bones and feeling tired. You should consider talking to a health professional about
                            increasing your BMI.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.weight.normal.sisuPortal.hex,
                    label: guidelineRange.normal.label,
                    range: guidelineRange.normal.range + ` (${weightUnit})`,
                    recommendation: (
                        <p>
                            Congratulations your BMI is healthy. Maintaining a healthy BMI brings with it many health
                            benefits.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.weight.elevated.sisuPortal.hex,
                    label: guidelineRange.elevated.label,
                    range: guidelineRange.elevated.range + ` (${weightUnit})`,
                    recommendation: (
                        <p>
                            Your BMI is elevated and you may want to consider losing weight to bring your BMI down to
                            below 25.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.weight.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range + ` (${weightUnit})`,
                    recommendation: (
                        <p>
                            Your BMI is high and this can impact on your heath in a number of ways. Losing weight will
                            improve your overall health.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.weight.veryHigh.sisuPortal.hex,
                    label: guidelineRange.veryHigh.label,
                    range: guidelineRange.veryHigh.range + ` (${weightUnit})`,
                    recommendation: (
                        <p>
                            A BMI of 30+ is classified as obese, a condition that is a serious threat to your
                            well-being. You should consult your doctor and consider lifestyle changes to reduce your
                            weight.
                        </p>
                    )
                }
            ];

        case BLOOD_PRESSURE:
            return [
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.optimal.sisuPortal.hex,
                    range: guidelineRange.optimal.range,
                    label: guidelineRange.optimal.label,
                    recommendation: (
                        <p>
                            Congratulations. Your blood pressure reading is healthy. At this level you have a much lower
                            risk of heart disease and stroke. Checking your blood pressure periodically is a good habit
                            to develop.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.normal.sisuPortal.hex,
                    label: guidelineRange.normal.label,
                    range: guidelineRange.normal.range,
                    recommendation: (
                        <p>
                            Your blood pressure reading is healthy. Ideally aim for under 120/80 and having a focus on
                            your diet, weight and physical activity will help maintain a healthy blood pressure.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.highNormal.sisuPortal.hex,
                    range: guidelineRange.highNormal.range,
                    label: guidelineRange.highNormal.label,
                    recommendation: (
                        <p>
                            Your blood pressure is a little higher than it should be and is worth monitoring regularly.
                            It is also important to consider how your lifestyle is impacting on your blood pressure as
                            making even small changes will improve your heart health.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.mildHypertension.sisuPortal.hex,
                    label: guidelineRange.mildHypertension.label,
                    range: guidelineRange.mildHypertension.range,
                    recommendation: (
                        <p>
                            Your blood pressure is high. Don’t be alarmed as results can fluctuate for a number of
                            reasons. Wait a few minutes and then check your blood pressure a couple of times over the
                            next 15 minutes. If it is still high, consult your doctor.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.moderateHypertension.sisuPortal.hex,
                    label: guidelineRange.moderateHypertension.label,
                    range: guidelineRange.moderateHypertension.range,
                    recommendation: (
                        <p>
                            Your blood pressure is very high but don’t be alarmed as results can fluctuate for a number
                            of reasons. Wait a few minutes and then check your blood pressure a couple of times over the
                            next 15 minutes. If it is still very high, consult a doctor or pharmacist as soon as
                            possible.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.severeHypertension.sisuPortal.hex,
                    range: guidelineRange.severeHypertension.range,
                    label: guidelineRange.severeHypertension.label,
                    recommendation: (
                        <p>
                            Your blood pressure is severely high. Consider the following steps: Wait five minutes and
                            try again. If the second reading is as high, consult your doctor as soon as possible. If you
                            are experiencing any other symptoms such as chest pain, call an ambulance or go to the
                            nearest hospital.
                        </p>
                    )
                }
            ];

        case QRISK3:
            return [
                {
                    color: theme.guidelines.Qrisk.low.sisuPortal.hex,
                    range: guidelineRange.low.range,
                    label: guidelineRange.low.label,
                    recommendation: (
                        <p>
                            Your QRisk Score indicates that you have a relatively low risk of stroke or heart attack in
                            the next ten years. It is important however to maintain a healthy lifestyle to keep your
                            risk low.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.Qrisk.elevated.sisuPortal.hex,
                    range: guidelineRange.elevated.range,
                    label: guidelineRange.elevated.label,
                    recommendation: (
                        <p>
                            Your risk of heart attack or stroke in the next 10 years is moderate to high. Please review
                            your risk with your doctor.
                        </p>
                    )
                }
            ];

        case ALCOHOL_RISK:
            return [
                {
                    color: theme.guidelines.Alcohol.noRisk.sisuPortal.hex,
                    range: guidelineRange.noRisk.range,
                    label: guidelineRange.noRisk.label,
                    recommendation: (
                        <p>Not drinking alcohol has a significant benefit to your health. Great work, keep it up.</p>
                    )
                },
                {
                    color: theme.guidelines.Alcohol.low.sisuPortal.hex,
                    range: guidelineRange.low.range,
                    label: guidelineRange.low.label,
                    recommendation: (
                        <p>
                            Your alcohol intake is within the recommended govt guidelines and represents a low risk to
                            your health.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.Alcohol.elevated.sisuPortal.hex,
                    label: guidelineRange.elevated.label,
                    range: guidelineRange.elevated.range,
                    recommendation: (
                        <p>
                            Your alcohol intake is above the recommended govt guidelines and as a result, we recommend
                            you consider reducing your alcohol intake to reduce the impact on your health
                        </p>
                    )
                },
                {
                    color: theme.guidelines.Alcohol.high.sisuPortal.hex,
                    label: guidelineRange.high.label,
                    range: guidelineRange.high.range,
                    recommendation: (
                        <p>
                            Your alcohol intake is significantly above the recommended govt guidelines and is a serious
                            risk to your health. You should consider reducing your alcohol intake.
                        </p>
                    )
                },
                {
                    color: theme.guidelines.Alcohol.veryHigh.sisuPortal.hex,
                    label: guidelineRange.veryHigh.label,
                    range: guidelineRange.veryHigh.range,
                    recommendation: (
                        <p>
                            Your score indicates you are at a high risk and this can have a serious impact on your
                            health. You may have an alcohol problem and should discuss with your doctor sources of help.
                        </p>
                    )
                }
            ];

        case ACTIVITY:
            return [
                {
                    color: theme.guidelines.Activity.active.sisuPortal.hex,
                    label: guidelineRange.active.label
                },
                {
                    color: theme.guidelines.Activity.moderatelyActive.sisuPortal.hex,
                    label: guidelineRange.moderatelyActive.label
                },
                {
                    color: theme.guidelines.Activity.moderatelyInactive.sisuPortal.hex,
                    label: guidelineRange.moderatelyInactive.label
                },
                {
                    color: theme.guidelines.Activity.inactive.sisuPortal.hex,
                    label: guidelineRange.inactive.label
                }
            ];

        case HRA_HEALTH_SCORE:
            return [
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["increasedRisk"].sisuPortal.hex,
                    label: guidelineRange.increasedRisk.label,
                    range: guidelineRange.increasedRisk.range,
                    recommendation: (
                        <p>
                            You are at Increased Risk of chronic disease and poor health if you score below 50. Our
                            report will help identify steps to take to improve this.
                        </p>
                    )
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["atRisk"].sisuPortal.hex,
                    label: guidelineRange.atRisk.label,
                    range: guidelineRange.atRisk.range,
                    recommendation: (
                        <p>
                            Scoring an ‘At Risk’ rating means your results are below the average person and need to be
                            improved to reduce risk of chronic disease.
                        </p>
                    )
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["moderate"].sisuPortal.hex,
                    label: guidelineRange.moderate.label,
                    range: guidelineRange.moderate.range,
                    recommendation: (
                        <p>
                            The average rating people receive, which ideally should be improved to ensure all national
                            guidelines are met.
                        </p>
                    )
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["good"].sisuPortal.hex,
                    range: guidelineRange.good.range,
                    label: guidelineRange.good.label,
                    recommendation: (
                        <p>
                            Achieving a ‘Good’ rating will likely mean your lifestyle and health is in line with
                            national guidelines.
                        </p>
                    )
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["excellent"].sisuPortal.hex,
                    range: guidelineRange.excellent.range,
                    label: guidelineRange.excellent.label,
                    recommendation: (
                        <p>
                            An excellent rating means you’re results are well above average and exceed national
                            guidelines, such as physical activity and nutrition.
                        </p>
                    )
                }
            ];

        case MUSCLE_MASS:
            return [
                {
                    color: theme.guidelines.muscleMass.low.sisuPortal.hex,
                    range: guidelineRange.low.rangeInfo,
                    label: guidelineRange.low.label
                },
                {
                    color: theme.guidelines.muscleMass.normal.sisuPortal.hex,
                    range: guidelineRange.normal.rangeInfo,
                    label: guidelineRange.normal.label
                },
                {
                    color: theme.guidelines.muscleMass.high.sisuPortal.hex,
                    range: guidelineRange.high.rangeInfo,
                    label: guidelineRange.high.label
                },
                {
                    color: theme.guidelines.muscleMass.veryHigh.sisuPortal.hex,
                    range: guidelineRange.veryHigh.rangeInfo,
                    label: guidelineRange.veryHigh.label
                }
            ];

        default:
            return null;
    }
};

// Regex to match "< Number1" or "Number1 +" or "Number1 - Number2"
const NUMERIC_REGEXP = /<([\s\d.]+)|([\s\d.]+)[%]*[+]+|([\s\d.]+)[%\s]*-\s*([\s\d.]+)/;

/**
 *
 * @param {number} index index of range bucket
 * @param {number} currentValue Current value of Metric
 * @param {number} size total number of range buckets
 * @param {String} range Range of Guideline
 * @param {String} type Type of Guideline
 * @return {String} String to calculate left position of Tooltip
 */
export const tooltipPosition = (index, currentValue, range, type, width) => {
    const group = range ? range.match(NUMERIC_REGEXP) : [];

    const totalBarWidth = index * width;

    const defaultLeftPosition = totalBarWidth + width / 2;

    let lowerRange, higherRange;
    try {
        if (group[1]) {
            lowerRange = 0;
            higherRange = Number.parseFloat(group[1].trim());
        } else if (group[2]) {
            lowerRange = Number.parseFloat(group[2].trim());
            higherRange = 2 * currentValue;
        } else if (group[3] && group[4]) {
            lowerRange = Number.parseFloat(group[3].trim());
            higherRange = Number.parseFloat(group[4].trim());
        } else {
            return defaultLeftPosition;
        }

        if ([BLOOD_PRESSURE, ACTIVITY].includes(type)) return defaultLeftPosition;
    } catch (e) {
        return defaultLeftPosition;
    }

    const bucketRange = higherRange - lowerRange;
    const bucketValue = currentValue - lowerRange;
    const isCurrentValueGTHalfOfBucketSize = bucketValue > bucketRange / 2;

    const calcLeftPosition = totalBarWidth + (width / bucketRange) * bucketValue;
    const endPointsDiff = higherRange === currentValue ? -8 : lowerRange === currentValue ? 4 : 0;
    return calcLeftPosition + (isCurrentValueGTHalfOfBucketSize ? -4 : 1) + endPointsDiff;
};

/**
 * Returns a parsed range string for weight (in st-lbs)
 * @param {string} range
 * @returns {string}
 */
export const parseRangeForWeightInStLbs = range => {
    const group = range ? range.match(NUMERIC_REGEXP) : [];
    let lowerRange, higherRange, rangeStr;

    try {
        if (group[1]) {
            lowerRange = 0;
            higherRange = Number.parseFloat(group[1].trim());
            rangeStr = group[0].replace(group[1], getStLbsString(higherRange));
        } else if (group[2]) {
            lowerRange = Number.parseFloat(group[2].trim());
            higherRange = 2 * Number.parseFloat(group[2].trim());
            rangeStr = group[0].replace(group[2], getStLbsString(lowerRange));
        } else if (group[3] && group[4]) {
            lowerRange = Number.parseFloat(group[3].trim());
            higherRange = Number.parseFloat(group[4].trim());
            rangeStr = group[0].replace(group[3], getStLbsString(lowerRange));
            rangeStr = rangeStr.replace(group[4], getStLbsString(higherRange));
        } else return range;
    } catch (e) {
        return range;
    }
    return rangeStr;
};

/**
 * Returs converted value to st-lbs
 * @param {Number} value
 * @returns {string}
 */
const getStLbsString = value => {
    const { stones, pounds } = UnitConverter.poundsToStLbs(value);
    return `${stones} st ${fixedNumber(pounds)} lbs `;
};
