import { compose, equals, filter, nth, keys } from "ramda";

const TotalRiskLevel = 3;

const getRangeKey = (heartAge, heartAgeOptimal, age) =>
    compose(
        nth(0),
        keys,
        filter(equals(true))
    )({
        optimal: heartAge < heartAgeOptimal,
        normal: heartAge >= heartAgeOptimal && heartAge <= age,
        elevated: heartAge > age
    });

const buildGuidelineDefinition = (heartAgeOptimal, age) => ({
    optimal: {
        key: "optimal",
        label: "Optimal",
        riskLevel: 1 / (TotalRiskLevel + 1),
        rangeInfo: `< ${heartAgeOptimal}`
    },
    normal: {
        key: "normal",
        label: "Normal",
        riskLevel: 2 / (TotalRiskLevel + 1),
        rangeInfo: `${heartAgeOptimal}-${age}`
    },
    elevated: {
        key: "elevated",
        label: "Elevated",
        riskLevel: 3 / (TotalRiskLevel + 1),
        rangeInfo: `${age}+`
    }
});

const SisuGuideline = (heartAge, heartAgeOptimal, age) => {
    const ranges = buildGuidelineDefinition(heartAgeOptimal, age);
    const rangeKey = getRangeKey(heartAge, heartAgeOptimal, age);

    return { ranges, ...ranges[rangeKey] };
};

const RiskName = "HEART_AGE";
const RiskCode = "HEART_AGE";
const GuidelineName = "SiSU Heart Age Guideline";
const GuidelineCode = "SISU_HEART_AGE";

export default SisuGuideline;
export { RiskName, RiskCode, GuidelineName, GuidelineCode };
