import React, { useEffect, useRef } from "react";

import styled from "styled-components";
import { theme } from "@sisuwellness/web-components";

// ----------------------------------------------------------------------------

import { useSelector } from "react-redux";
import { pathOr } from "ramda";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";

// ----------------------------------------------------------------------------

import * as Yup from "yup";
import { Form, Formik } from "formik";

// ----------------------------------------------------------------------------

import Auth from "utilities/auth";
import routes from "constants/routes";
import APIClient from "utilities/APIClient";
import InputField from "components/InputField";

// ----------------------------------------------------------------------------

import { FlowState } from "@sisuwellness/utilities/User";
import { useStateObject } from "@sisuwellness/utilities/hooks";
import { AnchorLink, AuthClientFooter } from "@sisuwellness/web-components";

import {
    INCORRECT_PASSWORD_ERROR_MESSAGE,
    NO_NETWORK_ERROR_MESSAGE,
    PASSWORD_RESET_SUCCESS_MESSAGE,
    REQUEST_FAILED_ERROR_MESSAGE
} from "constants/messages";
import {
    ButtonContainer,
    CardHeader,
    CardSubHeader,
    MessageBox,
    MessageText,
    LoginCard,
    LogoContainer,
    PortalButton,
    SisuLogo,
    ErrorWrapper,
    LoginWrapper
} from "components/CommonComponents";
import PageLayout from "components/PageLayout";
import { PORTAL_IMAGE } from "constants/imagePath";
import useRudderStack from "hooks/useRudderStack";

// ----------------------------------------------------------------------------

const TOKEN_EXPIRY = 3600;
const MEMBER_ROLE = "member";

const StyledSubInputText = styled.div`
    position: relative;
    margin-top: 8px;
    text-align: right;
    font-size: ${props => props.theme.fontSize[14].px};
    a {
        color: #736495;
        color: ${props => props.theme.portalColor.hpPurple700.hex};
        text-decoration: none;
    }
`;

const LoginView = () => {
    const location = useLocation();
    const passwordRef = useRef();
    const passRef = useRef();
    const rudderStack = useRudderStack();

    const { authenticated } = useSelector(state => state.auth);

    const [state, setState] = useStateObject({
        loading: false,
        submitting: false,
        flowState: authenticated ? FlowState.FLOW_STATE_USER_AUTHENTICATED : null,
        errorMessage: null,
        infoMessage: null,
        registrationToken: pathOr(null, ["state", "registrationToken"], location),
        username: pathOr(null, ["state", "username"], location),
        campaign: pathOr(null, ["state", "campaign"], location),
        passwordReset: false,
        isShowPasswordEnabled: false
    });

    let isMounted = true;
    const hanldeSetState = state => isMounted && setState(state);

    useEffect(() => {
        isMounted = true;
        return () => (isMounted = false);
    }, []);

    function clearMessages() {
        hanldeSetState({
            errorMessage: null,
            infoMessage: null,
            submitting: null
        });
    }

    async function handleSubmit(event) {
        clearMessages();
        hanldeSetState({ submitting: true });

        try {
            const response = await APIClient.auth.login(state.username, event.password, TOKEN_EXPIRY, MEMBER_ROLE);
            if (response.succeeded()) {
                let responseBody = await response.body();

                if (responseBody) {
                    await Auth.authenticate(responseBody.token);
                    const uuid = await Auth.getUserUUID();

                    // Logging UUID
                    if (uuid) {
                        window.Rollbar.configure({
                            payload: {
                                person: {
                                    id: uuid
                                }
                            }
                        });
                    }

                    hanldeSetState({ flowState: FlowState.FLOW_STATE_USER_AUTHENTICATED });
                    rudderStack.identifyEvent(uuid);
                    rudderStack.trackEvent("Account Authentication Success", { manualSignIn: true });
                }
            } else if (response.isNetworkFailure()) {
                hanldeSetState({
                    errorMessage: NO_NETWORK_ERROR_MESSAGE
                });
            } else if (response.isNotAuthorised()) {
                passRef.current.focus();
                hanldeSetState({ errorMessage: INCORRECT_PASSWORD_ERROR_MESSAGE });
                rudderStack.trackEvent("Account Authentication Failure", { type: "incorrectPassword" });
            } else {
                hanldeSetState({ errorMessage: REQUEST_FAILED_ERROR_MESSAGE });
            }
        } catch (_) {
            hanldeSetState({
                errorMessage: REQUEST_FAILED_ERROR_MESSAGE
            });
        } finally {
            hanldeSetState({
                submitting: false
            });
            window.scrollTo(0, 0);
        }
    }

    async function handleForgotPassword(event) {
        event.preventDefault();

        clearMessages();
        hanldeSetState({
            submitting: true
        });

        try {
            const response = await APIClient.auth.forgotPassword(state.username, MEMBER_ROLE);
            if (response.succeeded()) {
                hanldeSetState({
                    infoMessage: PASSWORD_RESET_SUCCESS_MESSAGE,
                    passwordReset: true
                });
                rudderStack.trackEvent("Account Password Update", { type: "forgotPassword" });
            } else if (response.isNetworkFailure()) {
                hanldeSetState({
                    errorMessage: NO_NETWORK_ERROR_MESSAGE
                });
            } else {
                hanldeSetState({
                    errorMessage: REQUEST_FAILED_ERROR_MESSAGE
                });
            }
        } catch (_) {
            hanldeSetState({
                errorMessage: REQUEST_FAILED_ERROR_MESSAGE
            });
        } finally {
            hanldeSetState({
                submitting: false
            });
            window.scrollTo(0, 0);
        }
    }

    const { username, errorMessage, infoMessage, submitting, flowState, passwordReset } = state;

    if (!username) {
        return <Redirect to={{ pathname: routes.root }} />;
    }

    if (FlowState.userIsAuthenticated(flowState)) {
        const redirectionPathObject = pathOr(null, ["state", "from"], location) || {
            pathname: routes.healthHub,
            state: {},
            search: ""
        };

        return (
            <Redirect
                to={{
                    pathname: redirectionPathObject.pathname,
                    state: {
                        ...redirectionPathObject.state,
                        registrationToken: state.registrationToken,
                        viaLogin: true
                    },
                    search: redirectionPathObject.search
                }}
            />
        );
    }

    const clientCode = pathOr(null, ["campaign", "client", "code"], state);

    const PasswordSchema = Yup.object().shape({
        password: Yup.string().required("You must enter a password.")
    });

    const { isShowPasswordEnabled } = state;
    const Eye = (
        <img
            src={`${PORTAL_IMAGE}/login/${isShowPasswordEnabled ? "Hide" : "Show"}Eye.svg`}
            onClick={() => {
                hanldeSetState({ isShowPasswordEnabled: !isShowPasswordEnabled });
            }}
            style={{ cursor: "pointer" }}
        />
    );

    // User exists and has completed their account - remainder of login screen
    return (
        <PageLayout containerProps={{ bg: theme.colours.hpPrimaryPurple.hex }}>
            <LoginWrapper>
                <ErrorWrapper width={"50%"} maxWidth={"720px"} mobileWidth={"90%"}>
                    {errorMessage && (
                        <MessageBox error={true} data-testid="error-message">
                            <MessageText>{errorMessage}</MessageText>
                        </MessageBox>
                    )}
                    {infoMessage && (
                        <MessageBox data-testid="info-container">
                            <MessageText>{infoMessage}</MessageText>
                        </MessageBox>
                    )}
                </ErrorWrapper>
                <LoginCard>
                    <div style={{ width: "100%" }}>
                        <LogoContainer>
                            <SisuLogo src={`${PORTAL_IMAGE}/sisu-health-logo.png`} alt="SiSU Logo" />
                        </LogoContainer>
                        <CardHeader>Sign In</CardHeader>
                        <CardSubHeader>Enter your Password</CardSubHeader>
                        <Formik
                            initialValues={{ password: "" }}
                            validationSchema={PasswordSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                                if (!passwordRef.current) passwordRef.current = { setFieldValue: setFieldValue };
                                let isDisabled = Object.keys(touched).length ? false : true;
                                if (isDisabled && values.password) isDisabled = false;
                                if (!isDisabled) isDisabled = Boolean(Object.values(errors).length);
                                return (
                                    <Form data-testid="password-form">
                                        <InputField
                                            className="login-password"
                                            fontFamily="GT Walsheim Pro"
                                            inputProps={{
                                                name: "password",
                                                placeholder: "Password",
                                                "data-testid": "password",
                                                onChange: handleChange,
                                                value: values.password,
                                                onBlur: handleBlur,
                                                type: isShowPasswordEnabled ? "text" : "password",
                                                ref: passRef,
                                                id: "text-field-id-password"
                                            }}
                                            rightChild={Eye}
                                            error={touched.password ? errors.password : ""}
                                        />
                                        <StyledSubInputText>
                                            {!passwordReset && (
                                                <AnchorLink
                                                    href="/"
                                                    onClick={handleForgotPassword}
                                                    disabled={submitting}
                                                >
                                                    Forgot password?
                                                </AnchorLink>
                                            )}
                                        </StyledSubInputText>
                                        <ButtonContainer mTop="16px">
                                            <PortalButton
                                                disabled={isDisabled || submitting}
                                                type={"submit"}
                                                onClick={handleSubmit}
                                                data-testid={"login-button"}
                                            >
                                                Sign In
                                            </PortalButton>
                                        </ButtonContainer>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <AuthClientFooter clientCode={clientCode} />
                    </div>
                </LoginCard>
            </LoginWrapper>
        </PageLayout>
    );
};

export default LoginView;
