import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import { Flex, Box } from "rebass";
import { ThemeContext } from "styled-components";
import { keys } from "ramda";
import { startCase, toLower } from "lodash-es";

// --------------------------------------------------------------------

import { iconPath, baseColor } from "utilities/recommendationIcon";
import { default as Icon } from "@sisuwellness/ui/src/components/Logo";
import { BannerCard as Recommendation } from "@sisuwellness/ui/src/components/Cards";
import { HeadingLabel as Heading, NormalLabel as Label } from "@sisuwellness/ui/src/components/Labels";
import { BODY_MASS_INDEX, HDL_CHOLESTEROL, HEART_AGE, QRISK3 } from "constants/trend-charts";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import { usePremiumFeature } from "components/PremiumFeatures";

// --------------------------------------------------------------------

const RecommendationCard = ({ text, risk, title, index, rating, ...rest }) => {
    const theme = useContext(ThemeContext);
    const { isPremium } = usePremiumFeature();

    const recommendationText = useCallback(
        title => {
            if (!keys(METRIC_INDICATOR).includes(risk)) return "";
            if (risk === BODY_MASS_INDEX) return "BMI Recommendation";
            if (risk === HDL_CHOLESTEROL) return "HDL Cholesterol Recommendation";
            if (risk === QRISK3) return "Heart and Stroke Risk Recommendation";
            return startCase(toLower(title || METRIC_INDICATOR[risk].title), rating);
        },
        [risk]
    );

    // Do not render Heart Age tile if not premium member
    if (risk === HEART_AGE && !isPremium) return <></>;

    let colorCode = baseColor(theme, risk, rating);
    let image = iconPath(risk);

    const { color, bgColor } = colorCode;
    if (!color && !bgColor) return <></>;

    return (
        <Recommendation
            bg={colorCode.bgColor}
            borderWidth="1px"
            borderStyle="solid"
            color={colorCode.color}
            borderColor={colorCode.color}
            key={`recommendation-${risk}-${index}`}
            data-testid={`recommendation-${risk}-${index}`}
            {...rest}
        >
            <Flex flexDirection={["column", "row"]} alignItems={["left", "center"]}>
                <Icon src={image} height="44px !important" width="44px !important" />
                <Box ml={["0", "32px"]}>
                    <Heading fs={20} color={colorCode.color} fontWeight="normal">
                        {recommendationText(title)}
                    </Heading>
                    <Label fs={15} mt={["8px", "16px"]} color={theme.colours.blackTints700.hex} fontWeight="normal">
                        {text}
                    </Label>
                </Box>
            </Flex>
        </Recommendation>
    );
};

RecommendationCard.propTypes = {
    risk: PropTypes.string,
    title: PropTypes.string,
    index: PropTypes.number,
    rating: PropTypes.string,
    priority: PropTypes.number,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default RecommendationCard;
