import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { Flex, Box } from "rebass";
import QuestionIcon from "../QuestionIcon";
import theme from "../../../../themes";
import QuestionTitle from "../Title";
import Help from "../Help";
import { Errors } from "../../../Forms";
import GooglePlaces from "../../../GooglePlaces";

const Address = ({
    id,
    title,
    iconType,
    helpText,
    onFocusChange = () => {},
    onValueChange = () => {},
    errors = []
}) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef) {
            onFocusChange(inputRef.current);
        }

        return () => {};
    }, []);

    const onChange = value => {
        onValueChange(value);
    };

    const onFocus = () => {
        if (inputRef && inputRef.current) {
            onFocusChange(inputRef.current);
        }
    };

    const buildValidationErrors = () => {
        const hasError = errors && errors.length;
        return hasError ? <Errors rounded={true}>{errors}</Errors> : null;
    };

    return (
        <Flex
            flexDirection={"column"}
            alignItems="center"
            justifyContent={"flex-start"}
            overflowY={"auto"}
            overflowX={"hidden"}
        >
            {buildValidationErrors()}
            {iconType && <QuestionIcon type={theme.icons.types[iconType]} size={theme.icons.sizes.normal} />}
            {title && <QuestionTitle htmlFor={id}>{title}</QuestionTitle>}
            {helpText && <Help>{helpText}</Help>}
            <Box width={"100%"} mt={"30px"} mb={"20px"}>
                <GooglePlaces
                    ref={inputRef}
                    onInputFocus={e => onFocus(e)}
                    onInputChange={s => onChange(s)}
                    autofocus={true}
                />
            </Box>
        </Flex>
    );
};

Address.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    iconType: PropTypes.string,
    helpText: PropTypes.string,
    onFocusChange: PropTypes.func,
    onValueChange: PropTypes.func,
    errors: PropTypes.array
};

export default withTheme(Address);
