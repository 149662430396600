import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";
import { isSignUpFlow } from "./utils";

export const Title = ({ state = {} }) => {
    let title = "",
        subTitle = "",
        subText = "";

    switch (true) {
        case isSignUpFlow(state).setup:
            title = "Complete Your Account";
            subTitle = null;
            subText =
                "Sign in with these details next time you visit a Station to keep all your results in the one place.";
            break;

        case isSignUpFlow(state).basic:
        case isSignUpFlow(state).registration:
            title = "Create Account";
            subTitle =
                "Create an account to access free health assessments, track health trends and view meal and exercise plans!";
            subText = "Use these details to sign in next time you visit a SiSU Health Station.";
            break;
    }

    return (
        <>
            <Text fontSize={["22px", "34px"]} fontWeight={["500", "normal"]} color="hpPrimaryPurple">
                {title}
            </Text>
            {subTitle && (
                <Text fontSize={["13px", "17px"]} color="blackTints700" mb={["10px", "0"]}>
                    {subTitle}
                </Text>
            )}
            <Text fontSize={["13px", "17px"]} color="blackTints700">
                {subText}
            </Text>
        </>
    );
};

Title.propTypes = {
    state: PropTypes.object
};
