import { isEmpty, cloneDeep } from "lodash";
import { pathOr, pickBy, propEq, values as getValues } from "ramda";
import UserProfile from "../UserProfile";

export default class AddressDetails {
    /**
     * @param {AddressDetails | {postalAddress: {}, permanentAddress: {}, isPostalAddressSameAsAddress: bool} addressDetails
     *
     */
    constructor(userProfile) {
        this._postalAddress = {};
        this._permanentAddress = {};
        this._isPostalAddressSameAsAddress = false;

        this.initialize(userProfile);
    }

    /**
     * Intializer
     * @param {AddressDetails | {postalAddress: {}, permanentAddress: {}, isPostalAddressSameAsAddress: bool} addressDetails
     *
     */
    initialize(userProfile = {}) {
        let profile;
        if (userProfile instanceof UserProfile) profile = userProfile;
        else {
            profile = new UserProfile();
            profile.initialise(userProfile, !isEmpty(userProfile));
        }

        const { address = {} } = profile.getExtraData();

        this._setPostalAddress(address.postalAddress || {});
        this._setPermanentAddress(address.permanentAddress || {});

        const isPostalAddressSameAsAddress =
            typeof address.isPostalAddressSameAsAddress === "undefined" || address.isPostalAddressSameAsAddress;
        this._setIsPostalAddressSameAsAddress(isPostalAddressSameAsAddress);
    }

    /**
     * Setter for Postal Address
     * @param {object} postalAddress
     * @param {string} postalAddress.state
     * @param {string} postalAddress.suburb
     * @param {string} postalAddress.postcode
     * @param {string} postalAddress.subpremise
     * @param {string} postalAddress.streetAddress
     * @private
     */
    _setPostalAddress(postalAddress) {
        this._postalAddress = { ...postalAddress };
    }

    /**
     * Setter for Permanent Address
     * @param {object} permanentAddress
     * @param {string} permanentAddress.state
     * @param {string} permanentAddress.suburb
     * @param {string} permanentAddress.postcode
     * @param {string} permanentAddress.subpremise
     * @param {string} permanentAddress.streetAddress
     * @private
     */
    _setPermanentAddress(permanentAddress) {
        this._permanentAddress = { ...permanentAddress };
    }

    /**
     * Setter for setting postal address flag
     * @param {object} flag
     * @private
     */
    _setIsPostalAddressSameAsAddress(flag) {
        this._isPostalAddressSameAsAddress = flag;
    }

    /**
     * Getter for Permanent Address
     * @return {{ state: string, suburb: string, postcode: string, streetAddress: string, subpremise: string }}
     */
    get permanentAddress() {
        return this._permanentAddress;
    }

    /**
     * Getter for Postal Address
     * @return {{ state: string, suburb: string, postcode: string, streetAddress: string, subpremise: string }}
     */
    get postalAddress() {
        return this._postalAddress;
    }

    /**
     * Getter for  postal address flag
     * @return {boolean}
     */
    get isPostalAddressSameAsAddress() {
        return this._isPostalAddressSameAsAddress;
    }

    /**
     * Getter for all Address object fields
     * @return {{ permanentAddress: { state: string, suburb: string, postcode: string, streetAddress: string, subpremise: string }, postalAddress: { state: string, suburb: string, postcode: string, streetAddress: string, subpremise: string }, isPostalAddressSameAsAddress: boolean }}
     *
     */
    get fields() {
        return {
            permanentAddress: this.permanentAddress,
            isPostalAddressSameAsAddress: this.isPostalAddressSameAsAddress,
            postalAddress: this.isPostalAddressSameAsAddress ? {} : this.postalAddress
        };
    }

    /**
     * Setter for all address fields
     * @param {{ state: string, suburb: string, postCode: string, streetAddress: string, subpremise: string, pSubpremise: string, pState: string, pSuburb: string, pPostCode: string, pStreetAddress: string }} values
     * @param {boolean} isPostalAdddressSameAsPermanentAddress
     */
    setAddressFields(values, isPostalAdddressSameAsPermanentAddress) {
        let postalAddress = AddressDetails.isAddressComplete(values, true)
            ? AddressDetails.extractPostalAddress(values)
            : this.postalAddress;

        let permanentAddress = AddressDetails.isAddressComplete(values)
            ? AddressDetails.extractPermanentAddress(values)
            : this.permanentAddress;

        this._setPostalAddress(postalAddress);
        this._setPermanentAddress(permanentAddress);
        this._setIsPostalAddressSameAsAddress(isPostalAdddressSameAsPermanentAddress);
    }

    /**
     * Returns a serialised address object
     * @return {{ address: { permanentAddress: { subpremise: string, state: string, suburb: string, postcode: string, streetAddress: string}, postalAddress: { subpremise: string, state: string, suburb: string, postcode: string, streetAddress: string}, isPostalAddressSameAsAddress: boolean} }}
     */
    toJSON() {
        return { address: this.fields };
    }

    /**
     * Return field value or empty string
     * @param {"subpremise" | "state" | "suburb" | "postcode" | "streetAddress"} field
     * @param {boolean} isPostalAdddress
     * @return {string}
     * @private
     */
    _path(field, isPostalAdddress = false) {
        return pathOr("", [isPostalAdddress ? "postalAddress" : "permanentAddress", field], this.fields);
    }

    /**
     * Returns destructured address fields
     * @return {{ subpremise: string, state: string, suburb: string, postCode: string, streetAddress: string, pSubpremise: string, pState: string, pSuburb: string, pPostCode: string, pStreetAddress: string }}
     */
    destructureAddressFields() {
        return {
            state: this._path("state"),
            pState: this._path("state", true),

            suburb: this._path("suburb"),
            pSuburb: this._path("suburb", true),

            subpremise: this._path("subpremise"),
            pSubpremise: this._path("subpremise", true),

            postCode: this._path("postcode"),
            pPostCode: this._path("postcode", true),

            streetAddress: this._path("streetAddress"),
            pStreetAddress: this._path("streetAddress", true)
        };
    }

    /**
     * Extract keys for permanent address
     * @param {{ postcode: string, state: string, suburb: string, postCode: string, streetAddress: string, subpremise: string, pSubpremise: string, pState: string, pSuburb: string, pPostCode: string, pStreetAddress: string }} values
     * @return {{ subpremise: string, state: string, suburb: string, postcode: string, streetAddress: string}}
     */
    static extractPermanentAddress(values = {}) {
        const { state, suburb, postCode, streetAddress, subpremise } = values;
        const postcode = postCode || values.postcode;
        return { state, suburb, postcode, streetAddress, subpremise };
    }

    /**
     *  Extract keys for postal address
     * @param {{ state: string, suburb: string, postCode: string, streetAddress: string, subpremise: string, pSubpremise: string, pState: string, pSuburb: string, pPostCode: string, pStreetAddress: string }} values
     * @return {{ subpremise: string, state: string, suburb: string, postcode: string, streetAddress: string}}
     */
    static extractPostalAddress(values = {}) {
        const {
            pState: state,
            pSuburb: suburb,
            pPostCode: postcode,
            pStreetAddress: streetAddress,
            pSubpremise: subpremise
        } = values;
        return { state, suburb, postcode, streetAddress, subpremise };
    }

    /**
     * Checks if all address fields are filled/complete
     * @param {{ state: string, suburb: string, postCode: string, streetAddress: string, subpremise: string, pSubpremise: string, pState: string, pSuburb: string, pPostCode: string, pStreetAddress: string }} values
     * @param {boolean} isPostalAdddress
     * @return {boolean}
     */
    static isAddressComplete(values = {}, isPostalAdddress = false) {
        let address = AddressDetails.extractPermanentAddress(values);
        if (isPostalAdddress) address = AddressDetails.extractPostalAddress(values);

        // Subpremise is an optional field. It can be empty therefore ignore in address complete check
        const filteredAddressKeys = pickBy((val, key) => !["subpremise", "pSubpremise"].includes(key), address);
        return getValues(filteredAddressKeys).reduce((acc, field) => !!(acc && field && field.length), true);
    }

    /**
     * Convert address to a string representation
     * @param {object} address
     * @param {string} address.state
     * @param {string} address.suburb
     * @param {string} address.postcode
     * @param {string} address.subpremise
     * @param {string} address.streetAddress
     * @return {string}
     */
    static toAddressString(address) {
        return AddressDetails.isAddressComplete(cloneDeep(address))
            ? `${address.subpremise ? address.subpremise + "/" : ""}${address.streetAddress}, ${address.suburb}, ${
                  address.state
              } ${address.postcode}`
            : "";
    }

    /**
     * check if address profile is equal to userProfile
     * @param {{address: object, previousEmails: ([]), firstName: string, lastName: string, gender: string, countryCode: string, mobileNumber: (string|null), postalCode: string, dateOfBirth: string, email: string, extraData: object}} addressProfile
     * @param {{previousEmails: ([]), firstName: string, lastName: string, gender: string, countryCode: string, mobileNumber: (string|null), postalCode: string, dateOfBirth: string, email: string, extraData: object}} userProfile
     * @return {boolean}
     */
    static checkIfEqual(addressProfile, userProfile) {
        const address = addressProfile.address;
        return propEq("address", address)(pathOr({}, ["extraData"], userProfile));
    }
}
