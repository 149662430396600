const QuestionCodes = {
    age: "AGE", //NB: this is a derived question code
    activityModeratePerWeek: "MODERATE_ACTIVITY_PER_WEEK",
    activityPedometerPerDay: "ACTIVITY_PEDOMETER_PER_DAY",
    activityVigorousPerWeek: "VIGOROUS_ACTIVITY_PER_WEEK",
    alcoholDistribution: "ALCOHOL_DISTRIBUTION",
    alcoholServersPerDay: "ALCOHOL_SERVES_PER_DAY",
    australianDiabetesRiskScore: "AUSTRALIAN_DIABETES_RISK_SCORE",
    australianDiabetesRiskGuideline: "AUSTRALIAN_DIABETES_RISK_GUIDELINE",
    bloodCholesterolRange: "BLOOD_CHOLESTEROL_RANGE",
    bloodGlucoseRange: "BLOOD_GLUCOSE_RANGE",
    bloodPressureDiastolic: "BP_DIASTOLIC",
    bloodPressureDiastolicRange: "BLOOD_PRESSURE_DIASTOLIC_RANGE",
    bloodPressureMedicationCompliance: "BLOOD_PRESSURE_MEDICATION_COMPLIANCE",
    bloodPressureMedicationFrequency: "BLOOD_PRESSURE_MEDICATION_FREQUENCY",
    bloodPressureSystolic: "BP_SYSTOLIC",
    bloodPressureSystolicRange: "BLOOD_PRESSURE_SYSTOLIC_RANGE",
    bloodPressureRating: "BLOOD_PRESSURE_RATING",
    bmi: "BMI",
    bmiRating: "BMI_RATING",
    bodyFat: "BODY_FAT",
    bodyFatRating: "BODY_FAT_RATING",
    bodyMassIndex: "BMI",
    bodyMassIndexRating: "BMI_RATING",
    bpLitQ1: "BP_LIT_NEW_Q1",
    bpLitQ2: "BP_LIT_NEW_Q2",
    breakfastPerWeek: "BREAKFAST_PER_WEEK",
    countryOfBirth: "COUNTRY_OF_BIRTH",
    countryOfResidence: "COUNTRY_OF_RESIDENCE",
    dailyFruitVegetablesDiet: "DAILY_FRUIT_VEGETABLES_PER_DAY",
    dateOfBirth: "DATE_OF_BIRTH",
    daysOffWork: "DAYS_OFF_WORK",
    department: "DEPARTMENT",
    descent: "DESCENT",
    diabetesFamilyHistory: "DIABETES_FAMILY_HISTORY",
    diastolic: "BP_DIASTOLIC",
    dizzinessSymptoms: "DIZZINESS_SYMPTOMS",
    eatsFruitsVeggiesDaily: "EATS_FRUIT_VEGETABLES_DAILY",
    email: "EMAIL",
    employmentStatus: "EMPLOYMENT_STATUS",
    fattyFoodsPerWeek: "FATTY_FOODS_PER_WEEK",
    firstName: "FIRST_NAME",
    fishLegumesPerWeek: "FISH_LEGUMES_PER_WEEK",
    followSpecialDiet: "FOLLOW_SPECIAL_DIET",
    gender: "GENDER",
    genderIdentity: "GENDER_IDENTITY",
    hasDiabetesFamilyHistory: "DIABETES_FAMILY_HISTORY",
    hasDiagnosedDiabetes: "HAS_DIABETES_DIAGNOSIS",
    hasDisability: "HAS_DISABILITY",
    hasHighBloodGlucose: "HAS_HIGH_BLOOD_GLUCOSE",
    hasMedicalImplant: "HAS_PACEMAKER",
    hasPacemaker: "HAS_PACEMAKER",
    hayfeverFrequency: "HAYFEVER_FREQUENCY",
    hayfeverRecentSymptoms: "HAYFEVER_RECENT_SYMPTOMS",
    heartRate: "BP_HEART_RATE",
    heartRateRating: "HEART_RATE_RATING",
    height: "HEIGHT_CM",
    historyCancer: "HAS_CANCER_HISTORY",
    historyHeartDisease: "HAS_HEART_DISEASE_HISTORY",
    isAthlete: "IS_ATHLETE",
    isPregnant: "IS_PREGNANT",
    isSmoker: "IS_SMOKER",
    isTakingHighBloodPressureMed: "BLOOD_PRESSURE_MEDICATION",
    lastName: "LAST_NAME",
    mealsPerWeek: "MEALS_PER_WEEK",
    meatDaysPerWeek: "MEAT_DAYS_PER_WEEK",
    mobileNumber: "MOBILE_NUMBER",
    netPromoterScore: "NPS",
    postalCode: "POSTAL_CODE",
    recentSurgeryHospital: "RECENT_HOSPITAL_SURGERY",
    sleepAverageHoursPerDay: "SLEEP_HOURS_PER_DAY",
    sleepHoursSinceLastCaffeine: "SLEEP_HOURS_SINCE_LAST_CAFFEINE",
    sleepHoursSinceLastMeal: "SLEEP_HOURS_SINCE_LAST_MEAL",
    sleepTroubleSleeping: "TROUBLE_SLEEPING",
    sleepingTroubleStayingAsleepPerDay: "TROUBLE_STAYING_ASLEEP",
    socialRelationshipEffort: "SOCIAL_RELATIONSHIP_EFFORT",
    socialWorkNeedsIntrude: "SOCIAL_WORK_NEEDS_INTRUDE",
    stressBrightSideOfLife: "STRESS_BRIGHT_SIDE",
    stressColleagueSupport: "STRESS_COLLEAGUE_SUPPORT",
    stressFinancialPressure: "STRESS_FINANCIAL_PRESSURE",
    stressFriendSupport: "STRESS_FRIEND_SUPPORT",
    stressHomePressure: "STRESS_HOME_PRESSURE",
    stressMentalPhysicalHealth: "STRESS_MENTAL_PHYSICAL_HEALTH",
    stressSuddenAnxiety: "STRESS_SUDDEN_ANXIETY",
    stressWorkPressure: "STRESS_WORK_PRESSURE",
    sweets: "SWEETS_CONSUMPTION",
    systolic: "BP_SYSTOLIC",
    takesCholesterolMedication: "TAKES_CHOLESTEROL_MEDICATION",
    takingPrescriptionMedication: "TAKE_PRESCRIPTION_MEDICATION",
    waistCircumference: "WAIST_CIRCUMFERENCE_CM", // TODO: deprecate
    waistMeasurement: "WAIST_MEASUREMENT",
    waistMeasurementAboriginalMale: "WAIST_MEASUREMENT_ABORIGINAL_MALE",
    waistMeasurementAboriginalFemale: "WAIST_MEASUREMENT_ABORIGINAL_FEMALE",
    waistMeasurementNotAboriginalMale: "WAIST_MEASUREMENT_NOT_ABORIGINAL_MALE",
    waistMeasurementNotAboriginalFemale: "WAIST_MEASUREMENT_NOT_ABORIGINAL_FEMALE",
    weeklyPhysicalActivity: "WEEKLY_PHYSICAL_ACTIVITY",
    weight: "WEIGHT_KG",
    gad2FeelingNervous: "GAD2_FEELING_NERVOUS",
    gad2NotAbleToStopWorrying: "GAD2_NOT_ABLE_TO_STOP_WORRYING",
    phq2NoPleasureDoingThings: "PHQ2_NO_PLEASURE_DOING_THINGS",
    phq2FeelingDepressedAndHopeless: "PHQ2_FEELING_DEPRESSED_AND_HOPELESS",
    stressTestGad2Phq2Score: "STRESS_TEST_GAD2_PHQ2_SCORE",
    stressTestGad2Phq2Guideline: "STRESS_TEST_GAD2_PHQ2_GUIDELINE",

    pss4UnableToControl: "PSS4_UNABLE_TO_CONTROL",
    pss4CanHandleYourPersonalProblem: "PSS4_CAN_HANDLE_YOUR_PERSONAL_PROBLEM",
    pss4ThingsGoingYourWay: "PSS4_THINGS_GOING_YOUR_WAY",
    pss4CouldNotOverComeDifficulties: "PSS4_COULD_NOT_OVERCOME_DIFFICULTIES",
    pss4Score: "PSS4_SCORE",
    pss4Guideline: "PSS4_GUIDELINE",

    smokingDoYouSmoke: "SMOKING_DO_YOU_SMOKE",
    smokingCountPerDay: "SMOKING_COUNT_PER_DAY",
    smokingWhenQuit: "SMOKING_WHEN_QUIT",
    smokingStatus: "SMOKING_STATUS",
    smokingQrisk: "SMOKING_QRISK",
    smokingTriedQuitting: "TRIED_QUITTING",

    ethnicityUkBasic: "ETHNICITY_UK_BASIC",
    ethnicityUkAsian: "ETHNICITY_UK_ASIAN",
    ethnicityUkBlack: "ETHNICITY_UK_BLACK",
    ethnicityUkMixed: "ETHNICITY_UK_MIXED",
    ethnicityUkWhite: "ETHNICITY_UK_WHITE",
    ethnicityUkOther: "ETHNICITY_UK_OTHER",
    ethnicityQrisk: "ETHNICITY_QRISK",
    anginaFamily: "ANGINA_FAMILY",
    kidneyDiseaseDiagnosis: "KIDNEY_DISEASE_DIAGNOSIS",
    atrialFibDiagnosis: "ATRIAL_FIB_DIAGNOSIS",
    migraines: "MIGRAINES",
    rheumatoidArthritisDiagnosis: "RHEUMATOID_ARTHRITIS_DIAGNOSIS",
    lupusDiagnosis: "LUPUS_DIAGNOSIS",
    steroidTabletsRegularly: "STEROID_TABLETS_REGULARLY",
    cholhdlRatio: "CHOLHDL_RATIO",
    diabetesStatus: "DIABETES_STATUS",
    severeMentalIllness: "SEVERE_MENTAL_ILLNESS",
    onAtypicalAntipsychoticsMedication: "ON_ATYPICAL_ANTIPSYCHOTICS_MEDICATION",
    diagnosisOrTreatmentOfErectileDysfunction: "DIAGNOSIS_OR_TREATMENT_OF_ERECTILE_DYSFUNCTION",
    systolicStandardDeviation: "SYSTOLIC_STANDARD_DEVIATION",
    townsendScore: "TOWNSEND_SCORE",
    qriskScore: "QRISK_SCORE",
    qriskRating: "QRISK_RATING",
    nhsEthnicity: "NHS_ETHNICITY",

    alcoholFrequency: "ALCOHOL_FREQUENCY",
    alcoholTypical: "ALCOHOL_TYPICAL",
    alcoholBinge: "ALCOHOL_BINGE",
    alcoholScore: "ALCOHOL_SCORE",
    alcoholRating: "ALCOHOL_RATING",

    paiVigorousDays: "PAI_VIGOROUS_DAYS",
    paiVigorousMinutes: "PAI_VIGOROUS_MINUTES",
    paiModerateDays: "PAI_MODERATE_DAYS",
    paiModerateMinutes: "PAI_MODERATE_MINUTES",
    paiWorkActivity: "PAI_WORK_ACTIVITY",
    paiMinutes: "PAI_MINUTES",
    paiScore: "PAI_SCORE",

    covid19Situation: "COVID19_SITUATION",
    covid19MainConcerns: "COVID19_MAIN_CONCERNS",
    covid19GeneralHealth: "COVID19_GENERAL_HEALTH",
    covid19Conditions: "COVID19_CONDITIONS",
    covid19Sleep: "COVID19_SLEEP",
    covid19Exercise: "COVID19_EXERCISE",
    covid19Meals: "COVID19_MEALS",
    covid19Hygiene: "COVID19_HYGIENE",
    covid19SocialDistance: "COVID19_SOCIAL_DISTANCE",
    covid19Gender: "COVID19_GENDER",
    covid19Age: "COVID19_AGE",
    covid19Country: "COVID19_COUNTRY",

    scriptsNowPharmacyName: "SCRIPTS_NOW_PHARMACY_NAME",
    scriptsNowPharmacyFax: "SCRIPTS_NOW_PHARMACY_FAX",
    scriptsNowPharmacyAddress: "SCRIPTS_NOW_PHARMACY_ADDRESS",
    scriptsNowPharmacyEmailAddress: "SCRIPTS_NOW_PHARMACY_EMAIL_ADDRESS",
    scriptsNowEmergency: "SCRIPTS_NOW_EMERGENCY",
    scriptsNowAreCurrentlyTakingThisMed: "SCRIPTS_NOW_ARE_YOU_CURRENTLY_TAKING",
    scriptsNowHowLong: "SCRIPTS_NOW_HOW_LONG",
    scriptsNowLastTimeSpokeToGP: "SCRIPTS_NOW_LAST_TIME_SPOKE_TO_GP",
    scriptsNowHaveRegularGP: "SCRIPTS_NOW_HAVE_REGULAR_GP",
    scriptsNowNameOfGPOrClinic: "SCRIPTS_NOW_NAME_OF_GP_OR_CLINIC",
    scriptsNowHavingAnyAllergies: "SCRIPTS_NOW_HAVING_ANY_ALLERGIES",
    scriptsNowAllergies: "SCRIPTS_NOW_ALLERGIES",
    scriptsNowTakingAnyOtherMedications: "SCRIPTS_NOW_TAKING_ANY_OTHER_MEDICATIONS",
    scriptsNowCurrentMedications: "SCRIPTS_NOW_CURRENT_MEDICATIONS",
    scriptsNowKidneyProblems: "SCRIPTS_NOW_KIDNEY_PROBLEMS",
    scriptsNowAcneHappy: "SCRIPTS_NOW_ACNE_HAPPY",
    scriptsNowAcneSensitiveAreas: "SCRIPTS_NOW_ACNE_SENSITIVE_AREAS",
    scriptsNowAcneSunburn: "SCRIPTS_NOW_ACNE_SUNBURN",
    scriptsNowAsthmaEmphysemaCondition: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_CONDITION",
    scriptsNowAsthmaEmphysemaFrequency: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_FREQUENCY",
    scriptsNowAsthmaEmphysemaLimitActivities: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_LIMIT_ACTIVITIES",
    scriptsNowAsthmaEmphysemaLimitWhatWay: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_LIMIT_WHAT_WAY",
    scriptsNowAsthmaEmphysemaNight: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_NIGHT",
    scriptsNowAsthmaEmphysemaHospital: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_HOSPITAL",
    scriptsNowAsthmaEmphysemaIcu: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_ICU",
    scriptsNowAsthmaEmphysemaSymptomsAcknowledgement: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_SYMPTOMS_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaFluvaxAcknowledgement: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_FLUVAX_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaAsthmaActionPlanAcknowledgement:
        "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_ASTHMA_ACTION_PLAN_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaAsthmaFrequencyAcknowledgement:
        "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_ASTHMA_FREQUENCY_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaEmphysemaActionPlanAcknowledgement:
        "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_EMPHYSEMA_ACTION_PLAN_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaEmphysemaFrequencyAcknowledgement:
        "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_EMPHYSEMA_FREQUENCY_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaCopdActionPlanAcknowledgement:
        "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_COPD_ACTION_PLAN_ACKNOWLEDGEMENT",
    scriptsNowAsthmaEmphysemaCopdFrequencyAcknowledgement:
        "SCRIPTS_NOW_ASTHMA_EMPHYSEMA_COPD_FREQUENCY_ACKNOWLEDGEMENT",
    scriptsNowBladderUse: "SCRIPTS_NOW_BLADDER_USE",
    scriptsNowBladderTraining: "SCRIPTS_NOW_BLADDER_TRAINING",
    scriptsNowBladderGlaucoma: "SCRIPTS_NOW_BLADDER_GLAUCOMA",
    scriptsNowBladderIntestinalObstruction: "SCRIPTS_NOW_BLADDER_INTESTINAL_OBSTRUCTION",
    scriptsNowBladderDiarrhreoa: "SCRIPTS_NOW_BLADDER_DIARRHREOA",
    scriptsNowBladderMg: "SCRIPTS_NOW_BLADDER_MG",
    scriptsNowBladderDementia: "SCRIPTS_NOW_BLADDER_DEMENTIA",
    scriptsNowBladderVoiding: "SCRIPTS_NOW_BLADDER_VOIDING",
    scriptsNowBladderPainPassingUrine: "SCRIPTS_NOW_BLADDER_PAIN_PASSING_URINE",
    scriptsNowBladderAcknowledgement: "SCRIPTS_NOW_BLADDER_ACKNOWLEDGEMENT",

    scriptsNowBpHeartAttack: "SCRIPTS_NOW_BP_HEART_ATTACK",
    scriptsNowBpAngina: "SCRIPTS_NOW_BP_ANGINA",
    scriptsNowBpAsthma: "SCRIPTS_NOW_BP_ASTHMA",
    scriptsNowBpEmphysema: "SCRIPTS_NOW_BP_EMPHYSEMA",
    scriptsNowBpGout: "SCRIPTS_NOW_BP_GOUT",
    scriptsNowBpHeartFailure: "SCRIPTS_NOW_BP_HEART_FAILURE",
    scriptsNowBpDiabetes: "SCRIPTS_NOW_BP_DIABETES",
    scriptsNowBpRenalNormal: "SCRIPTS_NOW_BP_RENAL_NORMAL",
    scriptsNowBpSmokerAcknowledgement: "SCRIPTS_NOW_BP_SMOKER_ACKNOWLEDGEMENT",
    scriptsNowBpDizzyAcknowledgement: "SCRIPTS_NOW_BP_DIZZY_ACKNOWLEDGEMENT",

    scriptsNowCholesterolChecked: "SCRIPTS_NOW_CHOLESTEROL_CHECKED",
    scriptsNowCholesterolResult: "SCRIPTS_NOW_CHOLESTEROL_RESULT",
    scriptsNowCholesterolStroke: "SCRIPTS_NOW_CHOLESTEROL_STROKE",
    scriptsNowCholesterolHeartAttack: "SCRIPTS_NOW_CHOLESTEROL_HEART_ATTACK",
    scriptsNowCholesterolAngina: "SCRIPTS_NOW_CHOLESTEROL_ANGINA",
    scriptsNowCholesterolPainAcknowledgement: "SCRIPTS_NOW_CHOLESTEROL_PAIN_ACKNOWLEDGEMENT",
    scriptsNowCholesterolCheckAck: "SCRIPTS_NOW_CHOLESTEROL_CHECK_ACK",
    scriptsNowCholesterolSmokerAck: "SCRIPTS_NOW_CHOLESTEROL_SMOKER_ACK",
    scriptsNowCholesterolDizzyAck: "SCRIPTS_NOW_CHOLESTEROL_DIZZY_ACK",
    scriptsNowContraceptionDvt: "SCRIPTS_NOW_CONTRACEPTION_DVT",
    scriptsNowContraceptionClotting: "SCRIPTS_NOW_CONTRACEPTION_CLOTTING",
    scriptsNowContraceptionSurgery: "SCRIPTS_NOW_CONTRACEPTION_SURGERY",
    scriptsNowContraceptionHeartAttackOrAngina: "SCRIPTS_NOW_CONTRACEPTION_HEART_ATTACK_OR_ANGINA",
    scriptsNowContraceptionMigraine: "SCRIPTS_NOW_CONTRACEPTION_MIGRAINE",
    scriptsNowContraceptionDiabetes: "SCRIPTS_NOW_CONTRACEPTION_DIABETES",
    scriptsNowContraceptionPancreatitis: "SCRIPTS_NOW_CONTRACEPTION_PANCREATITIS",
    scriptsNowContraceptionLiverFunction: "SCRIPTS_NOW_CONTRACEPTION_LIVER_FUNCTION",
    scriptsNowContraceptionCancer: "SCRIPTS_NOW_CONTRACEPTION_CANCER",
    scriptsNowContraceptionUndiagnosedBleeding: "SCRIPTS_NOW_CONTRACEPTION_UNDIAGNOSED_BLEEDING",
    scriptsNowContraceptionPillAcknowledgement: "SCRIPTS_NOW_CONTRACEPTION_PILL_ACKNOWLEDGEMENT",
    scriptsNowContraceptionSideEffectAcknowledgement: "SCRIPTS_NOW_CONTRACEPTION_SIDE_EFFECT_ACKNOWLEDGEMENT",
    scriptsNowContraceptionVomitingAcknowledgement: "SCRIPTS_NOW_CONTRACEPTION_VOMITING_ACKNOWLEDGEMENT",
    scriptsNowContraceptionContradictionAcknowledgement: "SCRIPTS_NOW_CONTRACEPTION_CONTRADICTION_ACKNOWLEDGEMENT",

    scriptsNowDiabetesWhen: "SCRIPTS_NOW_DIABETES_WHEN",
    scriptsNowDiabetesBloodTest: "SCRIPTS_NOW_DIABETES_BLOOD_TEST",
    scriptsNowDiabetesBloodTestResult: "SCRIPTS_NOW_DIABETES_BLOOD_TEST_RESULT",
    scriptsNowDiabetesAppointment: "SCRIPTS_NOW_DIABETES_APPOINTMENT",
    scriptsNowDiabetesOsteoporosis: "SCRIPTS_NOW_DIABETES_OSTEOPOROSIS",
    scriptsNowDiabetesHeartFailure: "SCRIPTS_NOW_DIABETES_HEART_FAILURE",
    scriptsNowDiabetesLowSugarFrequency: "SCRIPTS_NOW_DIABETES_LOW_SUGAR_FREQUENCY",
    scriptsNowDiabetesHypoglycemiaFrequency: "SCRIPTS_NOW_DIABETES_HYPOGLYCEMIA_FREQUENCY",
    scriptsNowDiabetesBloodSugarAcknowledgement: "SCRIPTS_NOW_DIABETES_BLOOD_SUGAR_ACKNOWLEDGEMENT",
    scriptsNowDiabetesReviewAcknowledgement: "SCRIPTS_NOW_DIABETES_REVIEW_ACKNOWLEDGEMENT",
    scriptsNowDiabetesSmokerAcknowledgement: "SCRIPTS_NOW_DIABETES_SMOKER_ACKNOWLEDGEMENT",
    scriptsNowDiabetesDizzyAcknowledgement: "SCRIPTS_NOW_DIABETES_DIZZY_ACKNOWLEDGEMENT",
    scriptsNowDigestionWeightLoss: "SCRIPTS_NOW_DIGESTION_WEIGHT_LOSS",
    scriptsNowDigestionDifficultySwallowing: "SCRIPTS_NOW_DIGESTION_DIFFICULTY_SWALLOWING",
    scriptsNowDigestionVomiting: "SCRIPTS_NOW_DIGESTION_VOMITING",
    scriptsNowDigestionBowelHabits: "SCRIPTS_NOW_DIGESTION_BOWEL_HABITS",
    scriptsNowDigestionRequestedInLast12Months: "SCRIPTS_NOW_DIGESTION_REQUESTED_IN_LAST12_MONTHS",
    scriptsNowDigestionBowelAcknowledgement: "SCRIPTS_NOW_DIGESTION_BOWEL_ACKNOWLEDGEMENT",
    scriptsNowDigestionStopTreatmentAcknowledgement: "SCRIPTS_NOW_DIGESTION_STOP_TREATMENT_ACKNOWLEDGEMENT",
    scriptsNowDigestionFollowUpAcknowledgement: "SCRIPTS_NOW_DIGESTION_FOLLOW_UP_ACKNOWLEDGEMENT",

    scriptsNowEdNitrate: "SCRIPTS_NOW_ED_NITRATE",
    scriptsNowEdMigranes: "SCRIPTS_NOW_ED_MIGRANES",
    scriptsNowEdUlcer: "SCRIPTS_NOW_ED_ULCER",
    scriptsNowEdOpticNeuropathy: "SCRIPTS_NOW_ED_OPTIC_NEUROPATHY",
    scriptsNowEdVisionLoss: "SCRIPTS_NOW_ED_VISION_LOSS",
    scriptsNowEdAngina: "SCRIPTS_NOW_ED_ANGINA",
    scriptsNowEdBloodPressure: "SCRIPTS_NOW_ED_BLOOD_PRESSURE",
    scriptsNowEdHeartIssues: "SCRIPTS_NOW_ED_HEART_ISSUES",
    scriptsNowEdContradictionTreatments: "SCRIPTS_NOW_ED_CONTRADICTION_TREATMENTS",
    scriptsNowEdBpCheckAcknowledgement: "SCRIPTS_NOW_ED_BP_CHECK_ACKNOWLEDGEMENT",
    scriptsNowEdSideEffectAcknowledgement: "SCRIPTS_NOW_ED_SIDE_EFFECT_ACKNOWLEDGEMENT",
    scriptsNowEdHearingAcknowledgement: "SCRIPTS_NOW_ED_HEARING_ACKNOWLEDGEMENT",
    scriptsNowEdErectionAcknowledgement: "SCRIPTS_NOW_ED_ERECTION_ACKNOWLEDGEMENT",
    scriptsNowEdCombinedAcknowledgement: "SCRIPTS_NOW_ED_COMBINED_ACKNOWLEDGEMENT",
    scriptsNowEdSmokerAcknowledgement: "SCRIPTS_NOW_ED_SMOKER_ACKNOWLEDGEMENT",
    scriptsNowEdDizzyAcknowledgement: "SCRIPTS_NOW_ED_DIZZY_ACKNOWLEDGEMENT",
    scriptsNowGoutAdequate: "SCRIPTS_NOW_GOUT_ADEQUATE",
    scriptsNowGoutMeds: "SCRIPTS_NOW_GOUT_MEDS",
    scriptsNowGoutMedsOther: "SCRIPTS_NOW_GOUT_MEDS_OTHER",
    scriptsNowGoutFluidsAcknowledgement: "SCRIPTS_NOW_GOUT_FLUIDS_ACKNOWLEDGEMENT",
    scriptsNowHayfeverControl: "SCRIPTS_NOW_HAYFEVER_CONTROL",
    scriptsNowHayfeverOtherMeds: "SCRIPTS_NOW_HAYFEVER_OTHER_MEDS",
    scriptsNowHayfeverNoseBleeds: "SCRIPTS_NOW_HAYFEVER_NOSE_BLEEDS",
    scriptsNowHayfeverNodeInfection: "SCRIPTS_NOW_HAYFEVER_NODE_INFECTION",
    scriptsNowHayfeverGpReviewAcknowledgement: "SCRIPTS_NOW_HAYFEVER_GP_REVIEW_ACKNOWLEDGEMENT",
    scriptsNowPainUnderControl: "SCRIPTS_NOW_PAIN_UNDER_CONTROL",
    scriptsNowPainOtherMeds: "SCRIPTS_NOW_PAIN_OTHER_MEDS",
    scriptsNowPainUlcer: "SCRIPTS_NOW_PAIN_ULCER",
    scriptsNowPainStroke: "SCRIPTS_NOW_PAIN_STROKE",
    scriptsNowPainHeartAttack: "SCRIPTS_NOW_PAIN_HEART_ATTACK",
    scriptsNowPainHeartBypass: "SCRIPTS_NOW_PAIN_HEART_BYPASS",
    scriptsNowPainHighBp: "SCRIPTS_NOW_PAIN_HIGH_BP",
    scriptsNowPainArterialDisease: "SCRIPTS_NOW_PAIN_ARTERIAL_DISEASE",
    scriptsNowPainBloodClotting: "SCRIPTS_NOW_PAIN_BLOOD_CLOTTING",
    scriptsNowPainAllergy: "SCRIPTS_NOW_PAIN_ALLERGY",
    scriptsNowPainSideEffectAcknowledgement: "SCRIPTS_NOW_PAIN_SIDE_EFFECT_ACKNOWLEDGEMENT",
    scriptsNowPainReviewAcknowledgement: "SCRIPTS_NOW_PAIN_REVIEW_ACKNOWLEDGEMENT",
    scriptsNowBaldnessAdequate: "SCRIPTS_NOW_BALDNESS_ADEQUATE",
    scriptsNowBaldnessBaby: "SCRIPTS_NOW_BALDNESS_BABY",
    scriptsNowBaldnessPheochromocytoma: "SCRIPTS_NOW_BALDNESS_PHEOCHROMOCYTOMA",
    scriptsNowBaldnessHypertension: "SCRIPTS_NOW_BALDNESS_HYPERTENSION",
    scriptsNowBaldnessHeartFailure: "SCRIPTS_NOW_BALDNESS_HEART_FAILURE",
    scriptsNowBaldnessAngina: "SCRIPTS_NOW_BALDNESS_ANGINA",
    scriptsNowBaldnessSideEffectAcknowledgement: "SCRIPTS_NOW_BALDNESS_SIDE_EFFECT_ACKNOWLEDGEMENT",
    scriptsNowBaldnessDangerAcknowledgement: "SCRIPTS_NOW_BALDNESS_DANGER_ACKNOWLEDGEMENT",
    scriptsNowBaldnessReviewAcknowledgement: "SCRIPTS_NOW_BALDNESS_REVIEW_ACKNOWLEDGEMENT",
    scriptsNowMenopauseLastPeriod: "SCRIPTS_NOW_MENOPAUSE_LAST_PERIOD",
    scriptsNowMenopausePregnant: "SCRIPTS_NOW_MENOPAUSE_PREGNANT",
    scriptsNowMenopauseGallstones: "SCRIPTS_NOW_MENOPAUSE_GALLSTONES",
    scriptsNowMenopauseHysterectomy: "SCRIPTS_NOW_MENOPAUSE_HYSTERECTOMY",
    scriptsNowMenopauseHysterectomyWhen: "SCRIPTS_NOW_MENOPAUSE_HYSTERECTOMY_WHEN",
    scriptsNowMenopauseCheckUp: "SCRIPTS_NOW_MENOPAUSE_CHECK_UP",
    scriptsNowMenopauseBreastScreen: "SCRIPTS_NOW_MENOPAUSE_BREAST_SCREEN",
    scriptsNowMenopauseDvt: "SCRIPTS_NOW_MENOPAUSE_DVT",
    scriptsNowMenopauseCancer: "SCRIPTS_NOW_MENOPAUSE_CANCER",
    scriptsNowMenopauseStroke: "SCRIPTS_NOW_MENOPAUSE_STROKE",
    scriptsNowMenopauseUnexplainedBleeding: "SCRIPTS_NOW_MENOPAUSE_UNEXPLAINED_BLEEDING",
    scriptsNowMenopauseMigraines: "SCRIPTS_NOW_MENOPAUSE_MIGRAINES",
    scriptsNowMenopauseHighBp: "SCRIPTS_NOW_MENOPAUSE_HIGH_BP",
    scriptsNowMenopauseHighCholesterol: "SCRIPTS_NOW_MENOPAUSE_HIGH_CHOLESTEROL",
    scriptsNowMenopauseElectiveSurgery: "SCRIPTS_NOW_MENOPAUSE_ELECTIVE_SURGERY",
    scriptsNowMigraineFrequency: "SCRIPTS_NOW_MIGRAINE_FREQUENCY",
    scriptsNowMigraineAdequate: "SCRIPTS_NOW_MIGRAINE_ADEQUATE",
    scriptsNowMigraineHighBp: "SCRIPTS_NOW_MIGRAINE_HIGH_BP",
    scriptsNowMigrainePeripheralVascularDisease: "SCRIPTS_NOW_MIGRAINE_PERIPHERAL_VASCULAR_DISEASE",
    scriptsNowMigraineHeartDisease: "SCRIPTS_NOW_MIGRAINE_HEART_DISEASE",
    scriptsNowMigraineStroke: "SCRIPTS_NOW_MIGRAINE_STROKE",
    scriptsNowMigraineAngina: "SCRIPTS_NOW_MIGRAINE_ANGINA",
    scriptsNowMigraineGlaucoma: "SCRIPTS_NOW_MIGRAINE_GLAUCOMA",
    scriptsNowMigraineProstate: "SCRIPTS_NOW_MIGRAINE_PROSTATE",
    scriptsNowMigraineEpilepsy: "SCRIPTS_NOW_MIGRAINE_EPILEPSY",
    scriptsNowMigraineOverFifty: "SCRIPTS_NOW_MIGRAINE_OVER_FIFTY",
    scriptsNowMigraineChange: "SCRIPTS_NOW_MIGRAINE_CHANGE",
    scriptsNowMigraineDrowsyAcknowledgement: "SCRIPTS_NOW_MIGRAINE_DROWSY_ACKNOWLEDGEMENT",
    scriptsNowOsteoporosisWhen: "SCRIPTS_NOW_OSTEOPOROSIS_WHEN",
    scriptsNowOsteoporosisLastTest: "SCRIPTS_NOW_OSTEOPOROSIS_LAST_TEST",
    scriptsNowOsteoporosisVitaminD: "SCRIPTS_NOW_OSTEOPOROSIS_VITAMIN_D",
    scriptsNowOsteoporosisStroke: "SCRIPTS_NOW_OSTEOPOROSIS_STROKE",
    scriptsNowOsteoporosisDvt: "SCRIPTS_NOW_OSTEOPOROSIS_DVT",
    scriptsNowOsteoporosisDentalTreatmentAcknowledgement: "SCRIPTS_NOW_OSTEOPOROSIS_DENTAL_TREATMENT_ACKNOWLEDGEMENT",
    scriptsNowOsteoporosisReviewAcknowledgement: "SCRIPTS_NOW_OSTEOPOROSIS_REVIEW_ACKNOWLEDGEMENT",
    scriptsNowProstateAdequate: "SCRIPTS_NOW_PROSTATE_ADEQUATE",
    scriptsNowProstateLastProstateExam: "SCRIPTS_NOW_PROSTATE_LAST_PROSTATE_EXAM",
    scriptsNowProstateBloodInUrine: "SCRIPTS_NOW_PROSTATE_BLOOD_IN_URINE",
    scriptsNowProstatePain: "SCRIPTS_NOW_PROSTATE_PAIN",
    scriptsNowProstateDizzy: "SCRIPTS_NOW_PROSTATE_DIZZY",
    scriptsNowProstateCataractSurgery: "SCRIPTS_NOW_PROSTATE_CATARACT_SURGERY",
    scriptsNowProstateDangerAcknowledgement: "SCRIPTS_NOW_PROSTATE_DANGER_ACKNOWLEDGEMENT",
    scriptsNowProstateExamAcknowledgement: "SCRIPTS_NOW_PROSTATE_EXAM_ACKNOWLEDGEMENT",
    scriptsNowProfileFirstName: "FIRST_NAME", // to match the profile survey
    scriptsNowProfileLastName: "SCRIPTS_NOW_PROFILE_LAST_NAME",
    scriptsNowProfileDateOfBirth: "DATE_OF_BIRTH", // to match the profile survey
    scriptsNowProfilePostcode: "POSTAL_CODE", // to match the profile survey
    scriptsNowProfileSex: "GENDER", // to match the profile survey
    scriptsNowProfilePregnant: "IS_PREGNANT", // to match the profile survey
    scriptsNowProfileCouldBePregnant: "SCRIPTS_NOW_PROFILE_COULD_BE_PREGNANT",
    scriptsNowProfileMobileNumber: "SCRIPTS_NOW_PROFILE_MOBILE_NUMBER",
    scriptsNowProfileMedicareNumber: "SCRIPTS_NOW_PROFILE_MEDICARE_NUMBER",
    scriptsNowProfileMedicareExpiry: "SCRIPTS_NOW_PROFILE_MEDICARE_EXPIRY",
    scriptsNowProfileMedicareAcknowledgement: "SCRIPTS_NOW_PROFILE_MEDICARE_ACKNOWLEDGEMENT",
    scriptsNowProfileSuburb: "SCRIPTS_NOW_PROFILE_SUBURB",
    scriptsNowProfileAddress: "SCRIPTS_NOW_PROFILE_ADDRESS",
    scriptsNowProfileBreastFeeding: "SCRIPTS_NOW_PREGNANT_OR_BREAST_FEEDING",
    scriptsNowMedicationFrequency: "SCRIPTS_NOW_MEDICATION_FREQUENCY",
    scriptsNowGeneralMeals: "SCRIPTS_NOW_GENERAL_MEALS",
    scriptsNowGeneralCondition: "SCRIPTS_NOW_GENERAL_CONDITION",
    scriptsNowGeneralPrescriber: "SCRIPTS_NOW_GENERAL_PRESCRIBER",
    scriptsNowSmokingDoYouSmoke: "IS_SMOKER", // to match the one we have,
    scriptsNowSmokingHowManyPerDay: "SCRIPTS_NOW_SMOKING_HOW_MANY_PER_DAY",
    scriptsNowSmokingHowLong: "SCRIPTS_NOW_SMOKING_HOW_LONG",
    scriptsNowSymbicortSeretidePbsAcknoledgement: "SCRIPTS_NOW_SYMBICORT_SERETIDE_PBS_ACKNOLEDGEMENT",
    scriptsNowSymbicortSeretideUsedCorticosteroids: "SCRIPTS_NOW_SYMBICORT_SERETIDE_USED_CORTICOSTEROIDS",
    scriptsNowConfirmPayment: "SCRIPTS_NOW_COMFIRM_PAYMENT",
    scriptsNowExitFeedback: "SCRIPTS_NOW_EXIT_FEEDBACK",
    scriptsWhatTimeTakingMeds: "SCRIPTS_WHAT_TIME_TAKING_MEDS",
    scriptsWhatTimeTakingMedsDescribe: "SCRIPTS_WHAT_TIME_TAKING_MEDS_DESCRIBE",
    scriptsNowDosesPerTake: "SCRIPTS_NOW_DOSES_PER_TAKE",
    scriptsNowDosesPerTakeDescribe: "SCRIPTS_NOW_DOSES_PER_TAKE_DESCRIBE",

    //scriptsNowAntiConvBePregnant: "IS_PREGNANT", // not used as it's asked in profile based on gender
    scriptsNowAntiConvRequestedInLast12Months: "SCRIPTS_NOW_ANTI_CONV_REQUESTED_IN_LAST12_MONTHS",
    scriptsNowAntiConvSeizureSinceLastDocVisit: "SCRIPTS_NOW_ANTI_CONV_SEIZURE_SINCE_LAST_DOC_VISIT",
    scriptsNowAntiConvBruisingBleeding: "SCRIPTS_NOW_ANTI_CONV_BRUISING_BLEEDING",
    scriptsNowAntiConvLossOfAppetite: "SCRIPTS_NOW_ANTI_CONV_LOSS_OF_APPETITE",
    scriptsNowAntiConvVomiting: "SCRIPTS_NOW_ANTI_CONV_VOMITING",
    scriptsNowAntiConvWalkingDifficulty: "SCRIPTS_NOW_ANTI_CONV_WALKING_DIFFICULTY",
    scriptsNowAntiConvImpairedConsciousness: "SCRIPTS_NOW_ANTI_CONV_IMPAIRED_CONSCIOUSNESS",
    scriptsNowAntiConvYellowSkin: "SCRIPTS_NOW_ANTI_CONV_YELLOW_SKIN",
    scriptsNowAntiConvYellowEyes: "SCRIPTS_NOW_ANTI_CONV_YELLOW_EYES",
    scriptsNowAntiConvSwelling: "SCRIPTS_NOW_ANTI_CONV_SWELLING",
    scriptsNowAntiConvDizziness: "SCRIPTS_NOW_ANTI_CONV_DIZZINESS",
    scriptsNowAntiConvDryMouth: "SCRIPTS_NOW_ANTI_CONV_DRY_MOUTH",
    scriptsNowAntiConvConstipated: "SCRIPTS_NOW_ANTI_CONV_CONSTIPATED",
    scriptsNowAntiConvAbnormalMovement: "SCRIPTS_NOW_ANTI_CONV_ABNORMAL_MOVEMENT",
    scriptsNowAntiConvBehaviouralChange: "SCRIPTS_NOW_ANTI_CONV_BEHAVIOURAL_CHANGE",
    scriptsNowAntiConvSameTimeSameWayAcknowledgement: "SCRIPTS_NOW_ANTI_CONV_SAME_TIME_SAME_WAY_ACKNOWLEDGEMENT",
    scriptsNowAntiConvNotDriveAcknowledgement: "SCRIPTS_NOW_ANTI_CONV_NOT_DRIVE_ACKNOWLEDGEMENT",
    scriptsNowAntiConvMakeFeelDizzyAcknowledgement: "SCRIPTS_NOW_ANTI_CONV_MAKE_FEEL_DIZZY_ACKNOWLEDGEMENT",
    scriptsNowAntiConvNotStopTakingAcknowledgement: "SCRIPTS_NOW_ANTI_CONV_NOT_STOP_TAKING_ACKNOWLEDGEMENT",
    scriptsNowAntiConvIncreaseAlcoholEffectAcknowledgement:
        "SCRIPTS_NOW_ANTI_CONV_INCREASE_ALCOHOL_EFFECT_ACKNOWLEDGEMENT",
    scriptsNowAntiConvRegularBloodCheckAcknowledgement: "SCRIPTS_NOW_ANTI_CONV_REGULAR_BLOOD_CHECK_ACKNOWLEDGEMENT",

    scriptsNowAnticoagulantUnexplainedBruising: "SCRIPTS_NOW_ANTICOAGULANT_UNEXPLAINED_BRUISING",
    scriptsNowAnticoagulantUnexplainedBleeding: "SCRIPTS_NOW_ANTICOAGULANT_UNEXPLAINED_BLEEDING",
    scriptsNowAnticoagulantUnexplainedColoredUrine: "SCRIPTS_NOW_ANTICOAGULANT_UNEXPLAINED_COLORED_URINE",
    scriptsNowAnticoagulantUnexplainedBlackStool: "SCRIPTS_NOW_ANTICOAGULANT_UNEXPLAINED_BLACK_STOOL",
    scriptsNowAnticoagulantRegularRenalTestAcknowledgment:
        "SCRIPTS_NOW_ANTICOAGULANT_REGULAR_RENAL_TEST_ACKNOWLEDGMENT",
    scriptsNowAnticoagulantMedicAlertAcknowledgment: "SCRIPTS_NOW_ANTICOAGULANT_MEDIC_ALERT_ACKNOWLEDGMENT",

    // extra scripts now information
    scriptsNowSingleMedicationOrder: "SCRIPTS_NOW_SINGLE_MEDICATION_ORDER",

    // my health
    hasDiabetesDiagnosis: "HAS_DIABETES_DIAGNOSIS",
    cholesterolMedication: "CHOLESTEROL_MEDICATION",
    bloodPressureMedication: "BLOOD_PRESSURE_MEDICATION",
    prescriptionMed: "PRESCRIPTION_MED",

    // Ostelin D Test
    ostelinDTestWorkDay: "OSTELIN_DTEST_WORKDAY",
    ostelinDTestSupplements: "OSTELIN_DTEST_SUPPLEMENTS",

    // heart age
    heartAge: "HEART_AGE",
    heartAgeOptimal: "HEART_AGE_OPTIMAL",
    tenYearCvdRisk: "TEN_YEAR_CVD_RISK",
    tenYearCvdRiskNormal: "TEN_YEAR_CVD_RISK_NORMAL",
    tenYearCvdRiskOptimal: "TEN_YEAR_CVD_RISK_OPTIMAL",
    heartAgeRating: "HEART_AGE_RATING",

    // daily energy use AKA total energy expenditure (some are included in BC and Profile surveys)
    isLactating: "IS_LACTATING",
    physicalActivityLevel: "PHYSICAL_ACTIVITY_LEVEL",
    basalMetabolicRate: "BASAL_METABOLIC_RATE",
    dailyEnergyUse: "DAILY_ENERGY_USE",
    dailyEnergyUseRating: "DAILY_ENERGY_USE_RATING",

    // muscle mass
    muscleMass: "MUSCLE_MASS",
    muscleMassRating: "MUSCLE_MASS_RATING",
    impedanceVector: "IMPEDANCE_VECTOR"
};

const SurveyCodes = {
    profile: "PROFILE",
    pregnancy: "PREGNANCY",
    bodyMassIndex: "BMI",
    bloodPressure: "BLOOD_PRESSURE",
    bodyComposition: "BODY_COMPOSITION",
    bpLitNew: "BP_LIT_NEW",
    ausDiabetesRisk: "AUSTRALIAN_DIABETES_RISK",
    mentalHealth: "MENTAL_HEALTH",
    heartAge: "HEART_AGE",
    stressTestGad2Phq2: "STRESS_TEST_GAD2_PHQ2",
    stressTestPss4: "STRESS_TEST_PSS4",
    healthRiskAssessmentStats: "HealthRiskAssessmentStats",
    healthRiskAssessmentLifestyle: "HealthRiskAssessmentLifestyle",
    healthRiskAssessmentEmotional: "HealthRiskAssessmentEmotional",
    healthRiskAssessmentSocial: "HealthRiskAssessmentSocial",
    netPromoterScore: "HEALTH_STATION_NPS",
    qrisk3: "QRISK3",
    smoking: "SMOKING",
    alcohol: "ALCOHOL",
    activity: "ACTIVITY",
    covid19: "COVID_19",
    scriptsNowPharmacySpecs: "SCRIPTS_NOW_PHARMACY_SPECS",
    scriptsNowGeneral: "SCRIPTS_NOW_GENERAL",
    scriptsNowAcne: "SCRIPTS_NOW_ACNE",
    scriptsNowAsthmaEmphysema: "SCRIPTS_NOW_ASTHMA_EMPHYSEMA",
    scriptsNowBladder: "SCRIPTS_NOW_BLADDER",
    scriptsNowBp: "SCRIPTS_NOW_BP",
    scriptsNowCholesterol: "SCRIPTS_NOW_CHOLESTEROL",
    scriptsNowContraception: "SCRIPTS_NOW_CONTRACEPTION",
    scriptsNowDiabetes: "SCRIPTS_NOW_DIABETES",
    scriptsNowDigestion: "SCRIPTS_NOW_DIGESTION",
    scriptsNowEd: "SCRIPTS_NOW_ED",
    scriptsNowGout: "SCRIPTS_NOW_GOUT",
    scriptsNowHayfever: "SCRIPTS_NOW_HAYFEVER",
    scriptsNowPain: "SCRIPTS_NOW_PAIN",
    scriptsNowBaldness: "SCRIPTS_NOW_BALDNESS",
    scriptsNowMenopause: "SCRIPTS_NOW_MENOPAUSE",
    scriptsNowMigraine: "SCRIPTS_NOW_MIGRAINE",
    scriptsNowOsteoporosis: "SCRIPTS_NOW_OSTEOPOROSIS",
    scriptsNowProstate: "SCRIPTS_NOW_PROSTATE",
    scriptsNowProfile: "SCRIPTS_NOW_PROFILE",
    scriptsNowSevereMedicalCondition: "SCRIPTS_NOW_SEVERE_MEDICAL_CONDITION",
    scriptsNowSymbicortSeretide: "SCRIPTS_NOW_SYMBICORT_SERETIDE",
    scriptsNowMedicare: "SCRIPTS_NOW_MEDICARE",
    scriptsNowConfirmPayment: "SCRIPTS_NOW_CONFIRM_PAYMENT",
    scriptsNowExitFeedback: "SCRIPTS_NOW_EXIT_FEEDBACK",
    scriptsNowAnticonvulsants: "SCRIPTS_NOW_ANTICONVULSANTS",
    scriptsNowAnticoagulant: "SCRIPTS_NOW_ANTICOAGULANT",
    myHealth: "MY_HEALTH",
    premiumIntentWaitListThanks: "PREMIUM_INTENT_WAITLIST_THANKS",
    premiumOptInGen2AcceptedInformation: "PREMIUM_OPT_IN_GEN2_ACCEPTED_INFORMATION",
    premiumIntro: "PREMIUM_INTRO",
    dailyEnergyUse: "DAILY_ENERGY_USE",
    muscleMass: "MUSCLE_MASS"
};

const { firstName, gender, genderIdentity, dateOfBirth, postalCode } = QuestionCodes;

const DefaultSurveys = {
    profile: {
        code: SurveyCodes.profile,
        showInHealthStationMenu: false,
        isRequired: true,
        questions: {
            firstName,
            gender,
            genderIdentity,
            dateOfBirth,
            postalCode
        }
    },
    pregnancy: {
        code: SurveyCodes.pregnancy,
        showInHealthStationMenu: false,
        isRequired: true,
        questions: {
            gender: QuestionCodes.gender,
            dateOfBirth: QuestionCodes.dateOfBirth,
            isPregnant: QuestionCodes.isPregnant
        }
    },
    bodyMassIndex: {
        code: SurveyCodes.bodyMassIndex,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {
            weight: QuestionCodes.weight,
            height: QuestionCodes.height,
            bmi: QuestionCodes.bmi,
            bmiRating: QuestionCodes.bodyMassIndexRating
        }
    },
    bloodPressure: {
        code: SurveyCodes.bloodPressure,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {
            gender: QuestionCodes.gender,
            dateOfBirth: QuestionCodes.dateOfBirth,
            medicationCompliance: QuestionCodes.isTakingHighBloodPressureMed,
            systolic: QuestionCodes.systolic,
            diastolic: QuestionCodes.diastolic,
            heartRate: QuestionCodes.heartRate,
            bloodPressureRating: QuestionCodes.bloodPressureRating,
            heartRateRating: QuestionCodes.heartRateRating
        }
    },
    bodyComposition: {
        code: SurveyCodes.bodyComposition,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {
            gender: QuestionCodes.gender,
            dateOfBirth: QuestionCodes.dateOfBirth,
            isPregnant: QuestionCodes.isPregnant,
            hasMedicalImplant: QuestionCodes.hasMedicalImplant,
            isAthlete: QuestionCodes.isAthlete,
            weight: QuestionCodes.weight,
            height: QuestionCodes.height,
            bodyFat: QuestionCodes.bodyFat,
            bodyFatRating: QuestionCodes.bodyFatRating
        }
    },
    ausDiabetesRisk: {
        code: SurveyCodes.ausDiabetesRisk,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {
            gender: QuestionCodes.gender,
            dateOfBirth: QuestionCodes.dateOfBirth,
            isPregnant: QuestionCodes.isPregnant,
            hasDiagnosedDiabetes: QuestionCodes.hasDiagnosedDiabetes,

            descent: QuestionCodes.descent,
            countryOfBirth: QuestionCodes.countryOfBirth,
            hasDiabetesFamilyHistory: QuestionCodes.hasDiabetesFamilyHistory,
            hasHighBloodGlucose: QuestionCodes.hasHighBloodGlucose,
            isTakingHighBloodPressureMed: QuestionCodes.isTakingHighBloodPressureMed,
            isSmoker: QuestionCodes.isSmoker,
            eatsFruitsVeggiesDaily: QuestionCodes.eatsFruitsVeggiesDaily,
            weeklyPhysicalActivity: QuestionCodes.weeklyPhysicalActivity,

            // waistMeasurement: QuestionCodes.waistMeasurement, // TODO: deprecate
            waistMeasurementAboriginalMale: QuestionCodes.waistMeasurementAboriginalMale,
            waistMeasurementAboriginalFemale: QuestionCodes.waistMeasurementAboriginalFemale,
            waistMeasurementNotAboriginalMale: QuestionCodes.waistMeasurementNotAboriginalMale,
            waistMeasurementNotAboriginalFemale: QuestionCodes.waistMeasurementNotAboriginalFemale,

            australianDiabetesRiskScore: QuestionCodes.australianDiabetesRiskScore,
            australianDiabetesRiskGuideline: QuestionCodes.australianDiabetesRiskGuideline
        }
    },
    mentalHealth: {
        code: SurveyCodes.mentalHealth,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {}
    },

    heartAge: {
        code: SurveyCodes.heartAge,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {}
    },

    stressTestGad2Phq2: {
        code: SurveyCodes.stressTestGad2Phq2,
        showInHealthStationMenu: false,
        isRequired: false,
        questions: {
            gad2FeelingNervous: QuestionCodes.gad2FeelingNervous,
            gad2NotAbleToStopWorrying: QuestionCodes.gad2NotAbleToStopWorrying,
            phq2NoPleasureDoingThings: QuestionCodes.phq2NoPleasureDoingThings,
            phq2FeelingDepressedAndHopeless: QuestionCodes.phq2FeelingDepressedAndHopeless
        }
    },

    stressTestPss4: {
        code: SurveyCodes.stressTestPss4,
        showInHealthStationMenu: false,
        isRequired: false,
        questions: {
            pss4UnableToControl: QuestionCodes.pss4UnableToControl,
            pss4CanHandleYourPersonalProblem: QuestionCodes.pss4CanHandleYourPersonalProblem,
            pss4ThingsGoingYourWay: QuestionCodes.pss4ThingsGoingYourWay,
            pss4CouldNotOverComeDifficulties: QuestionCodes.pss4CouldNotOverComeDifficulties,
            pss4Score: QuestionCodes.pss4Score,
            pss4Guideline: QuestionCodes.pss4Guideline
        }
    },

    qrisk3: {
        code: SurveyCodes.qrisk3,
        showInHealthStationMenu: true,
        isRequired: false,

        questions: {
            gender: QuestionCodes.gender,
            dateOfBirth: QuestionCodes.dateOfBirth,
            postalCode: QuestionCodes.postalCode,
            ethnicityUkBasic: QuestionCodes.ethnicityUkBasic,
            ethnicityUkAsian: QuestionCodes.ethnicityUkAsian,
            ethnicityUkBlack: QuestionCodes.ethnicityUkBlack,
            ethnicityUkMixed: QuestionCodes.ethnicityUkMixed,
            ethnicityUkWhite: QuestionCodes.ethnicityUkWhite,
            ethnicityUkOther: QuestionCodes.ethnicityUkOther,
            ethnicityQrisk: QuestionCodes.ethnicityQrisk,
            smokingQrisk: QuestionCodes.smokingQrisk,
            diabetesStatus: QuestionCodes.diabetesStatus,
            anginaFamily: QuestionCodes.anginaFamily,
            kidneyDiseaseDiagnosis: QuestionCodes.kidneyDiseaseDiagnosis,
            atrialFibDiagnosis: QuestionCodes.atrialFibDiagnosis,
            isTakingHighBloodPressureMed: QuestionCodes.isTakingHighBloodPressureMed,
            migraines: QuestionCodes.migraines,
            rheumatoidArthritisDiagnosis: QuestionCodes.rheumatoidArthritisDiagnosis,
            lupusDiagnosis: QuestionCodes.lupusDiagnosis,
            steroidTabletsRegularly: QuestionCodes.steroidTabletsRegularly,
            cholhdlRatio: QuestionCodes.cholhdlRatio,
            bloodPressureSystolic: QuestionCodes.bloodPressureSystolic,
            bodyMassIndex: QuestionCodes.bodyMassIndex,
            severeMentalIllness: QuestionCodes.severeMentalIllness,
            onAtypicalAntipsychoticsMedication: QuestionCodes.onAtypicalAntipsychoticsMedication,
            diagnosisOrTreatmentOfErectileDysfunction: QuestionCodes.diagnosisOrTreatmentOfErectileDysfunction,
            systolicStandardDeviation: QuestionCodes.systolicStandardDeviation,
            townsendScore: QuestionCodes.townsendScore,
            qriskScore: QuestionCodes.qriskScore,
            qriskRating: QuestionCodes.qriskRating,
            nhsEthnicity: QuestionCodes.nhsEthnicity
        }
    },

    smoking: {
        code: SurveyCodes.smoking,
        showInHealthStationMenu: false,
        isRequired: false,
        questions: {
            smokingDoYouSmoke: QuestionCodes.smokingDoYouSmoke,
            smokingCountPerDay: QuestionCodes.smokingCountPerDay,
            smokingWhenQuit: QuestionCodes.smokingWhenQuit,
            smokingStatus: QuestionCodes.smokingStatus,
            smokingQrisk: QuestionCodes.smokingQrisk
        }
    },

    activity: {
        code: SurveyCodes.activity,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {
            paiVigorousDays: QuestionCodes.paiVigorousDays,
            paiVigorousMinutes: QuestionCodes.paiVigorousMinutes,
            paiModerateDays: QuestionCodes.paiModerateDays,
            paiModerateMinutes: QuestionCodes.paiModerateMinutes,
            paiWorkActivity: QuestionCodes.paiWorkActivity,
            paiMinutes: QuestionCodes.paiMinutes,
            paiScore: QuestionCodes.paiScore
        }
    },

    alcohol: {
        code: SurveyCodes.alcohol,
        showInHealthStationMenu: true,
        isRequired: false,
        questions: {
            gender: QuestionCodes.gender,
            alcoholFrequency: QuestionCodes.alcoholFrequency,
            alcoholTypical: QuestionCodes.alcoholTypical,
            alcoholBinge: QuestionCodes.alcoholBinge,
            alcoholScore: QuestionCodes.alcoholScore,
            alcoholRating: QuestionCodes.alcoholRating
        }
    },

    covid19: {
        code: SurveyCodes.covid19,
        showInHealthStationMenu: false,
        isRequired: false,
        questions: {
            covid19Situation: QuestionCodes.covid19Situation,
            covid19MainConcerns: QuestionCodes.covid19MainConcerns,
            covid19GeneralHealth: QuestionCodes.covid19GeneralHealth,
            covid19Conditions: QuestionCodes.covid19Conditions,
            covid19Sleep: QuestionCodes.covid19Sleep,
            covid19Exercise: QuestionCodes.covid19Exercise,
            covid19Meals: QuestionCodes.covid19Meals,
            covid19Hygiene: QuestionCodes.covid19Hygiene,
            covid19SocialDistance: QuestionCodes.covid19SocialDistance,
            covid19Gender: QuestionCodes.covid19Gender,
            covid19Age: QuestionCodes.covid19Age,
            covid19Country: QuestionCodes.covid19Country
        }
    },
    scriptsNowPharmacySpecs: {
        code: SurveyCodes.scriptsNowPharmacySpecs,
        showInHealthStationMenu: false,
        isRequired: false,
        questions: {
            scriptsNowPharmacyName: QuestionCodes.scriptsNowPharmacyName,
            scriptsNowPharmacyEmailAddress: QuestionCodes.scriptsNowPharmacyEmailAddress,
            scriptsNowPharmacyAddress: QuestionCodes.scriptsNowPharmacyAddress
        }
    },
    myHealth: {
        code: SurveyCodes.myHealth,
        questions: {
            isSmoker: QuestionCodes.isSmoker,
            hasDiabetesDiagnosis: QuestionCodes.hasDiabetesDiagnosis,
            cholesterolMedication: QuestionCodes.cholesterolMedication,
            bloodPressureMedication: QuestionCodes.bloodPressureMedication,
            prescriptionMed: QuestionCodes.prescriptionMed
        }
    },
    dailyEnergyUse: {
        code: SurveyCodes.dailyEnergyUse,
        questions: {
            gender: QuestionCodes.gender,
            isLactating: QuestionCodes.isLactating,
            weight: QuestionCodes.weight,
            physicalActivityLevel: QuestionCodes.physicalActivityLevel,
            bodyFat: QuestionCodes.bodyFat,
            basalMetabolicRate: QuestionCodes.basalMetabolicRate,
            dailyEnergyUse: QuestionCodes.dailyEnergyUse,
            dailyEnergyUseRating: QuestionCodes.dailyEnergyUseRating
        }
    }
};

const SurveyStatus = {
    skipped: "SKIPPED",
    notEligible: "NOT_ELIGIBLE",
    error: "ERROR",
    disabled: "DISABLED",
    complete: "COMPLETE"
};

const NumericQuestions = [QuestionCodes.postalCode];

const WaistMeasureQuestionCodes = {
    waistMeasurementAboriginalMale: QuestionCodes.waistMeasurementAboriginalMale,
    waistMeasurementAboriginalFemale: QuestionCodes.waistMeasurementAboriginalFemale,
    waistMeasurementNotAboriginalMale: QuestionCodes.waistMeasurementNotAboriginalMale,
    waistMeasurementNotAboriginalFemale: QuestionCodes.waistMeasurementNotAboriginalFemale
};

const CountryOfBirth = {
    australia: "Australia",
    asia: "Asia",
    middleEast: "Middle East",
    northAfrica: "North Africa",
    southernEurope: "Southern Europe",
    other: "Other"
};

const CountryCodes = {
    australia: "AU"
};

const Descent = {
    aboriginalOrTorres: "Aboriginal or Torres Strait Islander",
    pacificOrMaori: "Pacific Islander or Maori",
    other: "None of the above"
};

const Gender = {
    male: "Male",
    female: "Female"
};

const WaistMeasureAboriginalMale = {
    thin: "Less than 90 cm",
    normal: "90 - 100 cm",
    thick: "More than 100 cm"
};

const WaistMeasureAboriginalFemale = {
    thin: "Less than 80 cm",
    normal: "80 - 90 cm",
    thick: "More than 90 cm"
};

const WaistMeasureNotAboriginalMale = {
    thin: "Less than 102 cm",
    normal: "102 - 110 cm",
    thick: "More than 110 cm"
};

const WaistMeasureNotAboriginalFemale = {
    thin: "Less than 88 cm",
    normal: "88 - 100 cm",
    thick: "More than 100 cm"
};

const YesNo = {
    yes: "Yes",
    no: "No"
};

const OkRequestConsult = {
    ok: "Ok",
    requestConsult: "Request a Telehealth Consult"
};

const StressTestGadPhq = {
    notAtAll: "Not at all",
    severalDays: "Several days",
    moreThanHalfTheDays: "More than half the days",
    nearlyEveryDay: "Nearly every day"
};

const StressTestPSS4 = {
    never: "Never",
    almostNever: "Almost Never",
    sometimes: "Sometimes",
    fairlyOften: "Fairly Often",
    veryOften: "Very Often"
};

const SmokingHra = {
    neverSmoked: "Never smoked",
    yesCurrentSmoker: "Yes - current smoker",
    quitMoreThanFiveYearsAgo: "Quit more than 5yrs ago",
    yesButLessThanTwoADay: "Yes - but less than 2 a day",
    yesButQuitInLastThreeMonths: "Yes - but quit in last 3 months"
};

const SmokingDoYouSmoke = {
    yes: "Yes",
    usedTo: "Used to",
    never: "Never"
};

const SmokingCountPerDay = {
    lessThanOne: "Less than 1",
    oneToNine: "1 - 9",
    tenToNineteen: "10 - 19",
    twentyPlus: "20+"
};

const SmokingWhenQuit = {
    lessThanThreeMonths: "Less than 3 months",
    betweenThreeToTwelveMonths: "Between 3-12 months",
    betweenOneToFiveYears: "Between 1-5 years",
    betweenFiveToFifteenYears: "Between 5-15 years",
    fifteenYearsPlus: "15+ years"
};

const SmokingStatus = {
    ...SmokingCountPerDay,
    ...SmokingWhenQuit
};

const SmokingQrisk = {
    light: "Light",
    moderate: "Moderate",
    heavy: "Heavy",
    exSmoker: "Ex-smoker",
    nonSmoker: "Non-smoker"
};

const EthnicityUkBasic = {
    asian: "Asian or Asian British",
    black: "Black, African, Black British or Caribbean",
    mixed: "Mixed or multiple ethnic groups",
    white: "White",
    another: "Another ethnic group",
    notAnswered: "Prefer not to say"
};

const EthnicityUkAsian = {
    bangladeshi: "Bangladeshi",
    chinese: "Chinese",
    indian: "Indian",
    pakistani: "Pakistani",
    anotherAsian: "Another Asian background",
    notAnswered: "Prefer not to say"
};

const EthnicityUkBlack = {
    african: "African",
    caribbean: "Caribbean",
    anotherBlack: "Another Black background",
    notAnswered: "Prefer not to say"
};

const EthnicityUkMixed = {
    asianWhite: "Asian and White",
    blackWhite: "Black African and White",
    blackCaribbeanWhite: "Black Caribbean and White",
    anotherMixed: "Another Mixed background",
    notAnswered: "Prefer not to say"
};

const EthnicityUkWhite = {
    british: "British, English, Northern Irish, Scottish, or Welsh",
    irish: "Irish",
    irishGypsy: "Irish Traveller or Gypsy",
    anotherWhite: "Another White background",
    notAnswered: "Prefer not to say"
};

const EthnicityUkOther = {
    arab: "Arab",
    anotherOther: "Another ethnic background",
    notAnswered: "Prefer not to say"
};

const EthnicityQrisk = {
    notStated: "Not stated",
    white: "White",
    indian: "Indian",
    pakistani: "Pakistani",
    bangladeshi: "Bangladeshi",
    otherAsian: "Other Asian",
    blackCaribbean: "Black Caribbean",
    blackAfrican: "Black African",
    chinese: "Chinese",
    others: "Other ethnic group"
};

const EthnicityNhs = {
    ...EthnicityUkAsian,
    ...EthnicityUkBlack,
    ...EthnicityUkMixed,
    ...EthnicityUkWhite,
    ...EthnicityUkOther
};

const DiabetesStatus = {
    no: "No",
    type1: "Type1",
    type2: "Type2"
};

const PaiWorkActivity = {
    notEmployed: "Not employed",
    mostlySit: "I mostly sit",
    mostlyStandOrWalk: "I mostly stand or walk",
    definitePhysicalEffort: "Definite physical effort",
    vigorousPhysicalEffort: "Vigorous physical activity"
};

const PaiWorkActivityHelpTexts = {
    notEmployed: "",
    mostlySit: "(e.g. in an office)",
    mostlyStandOrWalk: "(e.g. shop assistant or hairdresser)",
    definitePhysicalEffort: "(e.g. plumber, electrician, cleaner)",
    vigorousPhysicalEffort: "(e.g. construction, scaffolder)"
};

const PaiScores = {
    inactive: "Inactive",
    moderatelyInactive: "Moderately Inactive",
    moderatelyActive: "Moderately Active",
    active: "Active"
};

const AlcoholFrequency = {
    never: "Never",
    monthlyOrLess: "Monthly or less",
    twoToFourPerMonth: "2-4 times a month",
    twoToThreePerWeek: "2-3 times a week",
    fourPlusPerWeek: "4+ times a week"
};

const AlcoholTypical = {
    oneOrTwo: "1 or 2",
    threeOrFour: "3 or 4",
    fiveOrSix: "5 or 6",
    sevenToNine: "7 to 9",
    tenOrMore: "10 or more"
};

const AlcoholBinge = {
    never: "Never",
    lessThanMonthly: "Less than monthly",
    monthly: "Monthly",
    weekly: "Weekly",
    daily: "Daily"
};

const AlcoholRating = {
    veryHighRisk: "Very High Risk",
    highRisk: "High Risk",
    elevatedRisk: "Elevated Risk",
    lowRisk: "Low Risk",
    noRisk: "No Risk"
};

const Covid19Situation = {
    socialDistancingWork: "Social distancing – still attending workplace",
    socialDistancingHome: "Social distancing – working/staying at home",
    governmentMandated: "Government-mandated / advised self-isolation or quarantine",
    selfIsolation: "Self-isolation due to illness or family member illness",
    quarantinedDueToDiagnoses: "Quarantined due to COVID-19 diagnosis",
    usualRoutine: "I am following my usual routine"
};

const Covid19MainConcerns = {
    managingWorkFromHome: "Managing working from home",
    worriedChildrenEducation: "Worried about my children’s education",
    lostMyJob: "I have lost my job",
    worriedLosingMyJob: "Worried about losing my job",
    enoughFood: "Ensuring I have enough food",
    enoughMedication: "Ensuring I have enough medication",
    feelingLonely: "Feeling lonely",
    worriedRelationship: "Worried about my relationship with my partner",
    worriedGettingSick: "Worried about getting sick, or my family getting sick",
    worriedViolence: "Worried about partner or family violence",
    worriedFinance: "Worried about my finances",
    noneOfTheAbove: "None of the above apply"
};

const Covid19GeneralHealth = {
    excellent: "Excellent",
    veryGood: "Very Good",
    average: "Average",
    notGood: "Not good",
    poor: "Poor"
};

const Covid19Conditions = {
    smoke: "I smoke",
    highBloodPressure: "I have high blood pressure",
    pregnant: "I am pregnant",
    asthma: "I have asthma",
    diabetes: "I have diabetes",
    noFluVaccine: "I have not had a flu vaccination this year",
    noneOfTheAbove: "None of the above apply"
};

const Covid19Sleep = {
    muchWorse: "Much worse",
    slightlyWorse: "Slightly worse",
    aboutTheSame: "About the same",
    slightlyBetter: "Slightly better",
    muchBetter: "Much better"
};

const Covid19Exercise = {
    muchLess: "Much less",
    slightlyLess: "Slightly less",
    aboutTheSame: "About the same",
    slightlyMore: "Slightly more",
    muchMore: "Much more"
};

const Covid19Meals = {
    muchWorse: "Much worse",
    slightlyWorse: "Slightly worse",
    aboutTheSame: "About the same",
    slightlyBetter: "Slightly better",
    muchBetter: "Much better"
};

const Covid19Hygiene = {
    notAtAll: "Not at all",
    someDays: "Some days",
    mostDays: "Most days",
    everyDay: "Every day"
};

const Covid19SocialDistance = {
    notAtAll: "Not at all",
    someDays: "Some days",
    mostDays: "Most days",
    everyDay: "Every day"
};

const Covid19Gender = {
    female: "Female",
    male: "Male",
    preferNotToSay: "Prefer not to say"
};

const Covid19Age = {
    under16: "Under 16",
    between16and24: "16-24",
    between25and34: "25-34",
    between35and44: "35-44",
    between45and54: "45-54",
    between55and64: "55-64",
    between65and74: "65-74",
    olderThan75: "75+"
};

const Covid19Country = {
    australia: "Australia",
    unitedKingdom: "United Kingdom",
    other: "Other",
    notSpecified: "Not specified"
};

const Covid19SituationHelpTexts = {
    socialDistancingWork: "(e.g. essential services workers)",
    socialDistancingHome: "(e.g working from home)",
    governmentMandated: "(e.g due to recent travel or government requirements in your country)",
    selfIsolation: "(e.g. unwell or awaiting COVID-19 results)",
    quarantinedDueToDiagnoses: "",
    usualRoutine: ""
};

const Acknowledgement = {
    Acknowledgement: "I acknowledge"
};

const MedicationFrequency = {
    daily: "Daily",
    twiceADay: "Twice a day",
    threePlusTimesADay: "3+ times a day",
    onceAWeek: "Once a week",
    twiceAWeek: "Twice a week",
    threeTimesAWeek: "3 times a week",
    whenRequired: "When required for symptoms"
};

const BpMedicationFrequency = {
    regularly: "Regularly",
    asNeeded: "As needed",
    never: "Never"
};

const PrescriptionMedFrequency = {
    regularly: "Regularly",
    asNeeded: "As needed",
    never: "Never"
};

const LastTimeSpokeToGP = {
    lessThanThreeMonths: "Less than 3 months",
    threeToSixMonths: "3-6 months",
    sixToTwelveMonths: "6-12 months",
    twelvePlusMonths: "12+ months"
};

const Prescriber = {
    doctor: "Doctor",
    specialist: "Specialist"
};

const PhysicalActivityLevel = {
    sedentary: "Sedentary work, no strenuous leisure activity",
    mostlySedentary: "Mostly sedentary work, little strenuous leisure activity",
    moderatelyActive: "Moderately active work, mostly standing or walking",
    heavilyActive: "Heavy activity",
    significantlyActive: "Significantly active"
};

const Answers = {
    CountryOfBirth,
    Gender,
    Descent,
    YesNo,
    OkRequestConsult,
    WaistMeasureAboriginalMale,
    WaistMeasureAboriginalFemale,
    WaistMeasureNotAboriginalMale,
    WaistMeasureNotAboriginalFemale,
    StressTestGadPhq,
    StressTestPSS4,
    SmokingHra,
    SmokingDoYouSmoke,
    SmokingCountPerDay,
    SmokingWhenQuit,
    SmokingStatus,
    SmokingQrisk,
    EthnicityUkBasic,
    EthnicityUkAsian,
    EthnicityUkBlack,
    EthnicityUkMixed,
    EthnicityUkWhite,
    EthnicityUkOther,
    EthnicityQrisk,
    EthnicityNhs,
    DiabetesStatus,
    AlcoholFrequency,
    AlcoholTypical,
    AlcoholBinge,
    AlcoholRating,
    PaiWorkActivity,
    PaiWorkActivityHelpTexts,
    PaiScores,
    Covid19Situation,
    Covid19MainConcerns,
    Covid19GeneralHealth,
    Covid19Conditions,
    Covid19Sleep,
    Covid19Exercise,
    Covid19Meals,
    Covid19Hygiene,
    Covid19SocialDistance,
    Covid19Gender,
    Covid19Age,
    Covid19Country,
    Covid19SituationHelpTexts,
    Acknowledgement,
    MedicationFrequency,
    BpMedicationFrequency,
    LastTimeSpokeToGP,
    Prescriber,
    PhysicalActivityLevel,
    PrescriptionMedFrequency
};

const AdSlots = {
    qriskResultPageAboveRecommendations: {
        code: "QRISK_RESULT_PAGE_ABOVE_RECOMMENDATIONS",
        name: "QRISK-3 result page ad, above the recommendations section",
        description: "QRISK-3 result page ad, above the recommendations section"
    },
    qriskResultPageRecommendations: {
        code: "QRISK_RESULT_PAGE_RECOMMENDATIONS",
        name: "QRISK-3 result page recommendations",
        description: "QRISK-3 result page recommendations"
    },
    alcoholResultPageAboveRecommendations: {
        code: "ALCOHOL_RESULT_PAGE_ABOVE_RECOMMENDATIONS",
        name: "ALCOHOL result page ad, above the recommendations section",
        description: "ALCOHOL result page ad, above the recommendations section"
    },
    alcoholResultPageRecommendations: {
        code: "ALCOHOL_RESULT_PAGE_RECOMMENDATIONS",
        name: "ALCOHOL result page recommendations",
        description: "ALCOHOL result page recommendations"
    },
    activityResultPageAboveRecommendations: {
        code: "ACTIVITY_RESULT_PAGE_ABOVE_RECOMMENDATIONS",
        name: "ACTIVITY result page ad, above the recommendations section",
        description: "ACTIVITY result page ad, above the recommendations section"
    },
    activityResultPageRecommendations: {
        code: "ACTIVITY_RESULT_PAGE_RECOMMENDATIONS",
        name: "ACTIVITY result page recommendations",
        description: "ACTIVITY result page recommendations"
    },
    stressPSS4ResultPageAboveRecommendations: {
        code: "STRESS_PSS4_RESULT_PAGE_ABOVE_RECOMMENDATIONS",
        name: "Stress pss4 result page ad, above the recommendations section",
        description: "Stress pss4 result page ad, above the recommendations section"
    },
    stressPSS4ResultPageRecommendations: {
        code: "STRESS_PSS4_RESULT_PAGE_RECOMMENDATIONS",
        name: "Stress pss4 result page recommendations",
        description: "Stress pss4 result page recommendations"
    },
    heartAgeResultPageRecommendations: {
        code: "HEART_AGE_RESULT_PAGE_RECOMMENDATIONS",
        name: "Heart age result page recommendations",
        description: "Heart age result page recommendations"
    },
    heartAgeResultPagePremiumTeaser: {
        code: "HEART_AGE_RESULT_PAGE_PREMIUM_TEASER",
        name: "Heart age result page premium teaser",
        description: "Heart age result page premium teaser"
    },
    dailyEnergyUseResultPageRecommendations: {
        code: "DAILY_ENERGY_USE_RESULT_PAGE_RECOMMENDATIONS",
        name: "Daily energy use result page recommendations",
        description: "Daily energy use result page recommendations"
    },
    dailyEnergyUseResultPremiumTeaser: {
        code: "DAILY_ENERGY_USE_RESULT_PREMIUM_TEASER",
        name: "Daily energy use result premium teaser",
        description: "Daily energy use result premium teaser"
    },
    muscleMassResultPageRecommendations: {
        code: "MUSCLE_MASS_RESULT_PAGE_RECOMMENDATIONS",
        name: "Muscle mass result page recommendations",
        description: "Muscle mass result page recommendations"
    },
    muscleMassResultPremiumTeaser: {
        code: "muscleMassResultPremiumTeaser",
        name: "Muscle mass result premium teaser",
        description: "Muscle mass result premium teaser"
    },
    bpMeasurementScreenSideContentAsset: {
        code: "BP_MEASUREMENT_SCREEN_SIDE_CONTENT_ASSET",
        name: "Blood Pressure measurement page ad",
        description: "Blood Pressure measurement page ad, side content asset"
    },
    bodyFatMeasurementScreenSideContentAsset: {
        code: "BODY_FAT_MEASUREMENT_SCREEN_SIDE_CONTENT_ASSET",
        name: "Body Fat measurement page ad",
        description: "Body Fat measurement page ad, side content asset"
    }
};

const Clients = {
    priceline: { code: "PRICELINE", name: "Priceline" },
    telstraHealthScriptsNow: { code: "TELSTRA_HEALTH_SN", name: "Telstra Health, Scripts Now service" },
    phenixHealthScriptsNow: { code: "PHENIX_HEALTH_PTY_LT", name: "Phenix Health" },
    ectosense: { code: "ECTOSENSE", name: "Ectosense" },
    sisuGlobal: { code: "SISU_GLOBAL", name: "SiSU Global" }
};

const UserJourneySlugs = {
    scriptsNow: "SCRIPTS_NOW_USER_JOURNEY"
};

const ConsentCodes = {
    sisuHealthRegistration: "REGISTRATION_TERMS_AND_CONDITIONS",
    scriptsNowRegistrationTelstraHealth: "SCRIPTS_NOW_REG_TERMS_AND_CONDITIONS",
    scriptsNowRegistrationPhenixHealth: "SCRIPTS_NOW_PHENIX_REGISTRATION",
    scriptsNowDoctorAssessMedHistory: "SCRIPTS_NOW_DOCTOR_ASSESS_MED_HISTORY",
    scriptsNowRegistration: "SCRIPTS_TERMS_PRIVACY",
    premiumSignupGen1: "PREM_SIGNUP_GEN1",
    premiumSignupGen2: "PREM_SIGNUP_GEN2",
    premiumSignupGen3: "PREM_SIGNUP_GEN3",
    premiumSignupGen4: "PREM_SIGNUP_GEN4",
    premiumSignupGen5: "PREM_SIGNUP_GEN5"
};

const ScriptsNowConsentCodes = [
    ConsentCodes.scriptsNowRegistrationTelstraHealth,
    ConsentCodes.scriptsNowRegistrationPhenixHealth
];

const PremiumIntentConsentCodes = [
    ConsentCodes.premiumSignupGen1,
    ConsentCodes.premiumSignupGen2,
    ConsentCodes.premiumSignupGen3,
    ConsentCodes.premiumSignupGen4,
    ConsentCodes.premiumSignupGen5
];

const UserTypes = {
    newUser: "NEW_USER",
    returnFreeUser: "RETURN_FREE_USER",
    returnPremiumUser: "RETURN_PREMIUM_USER",
    returnPremiumUserOnNonPremiumStation: "RETURN_PREMIUM_USER_ON_NON_PREMIUM_STATION"
};

const PremiumLevels = {
    fullyEnabled: "PREMIUM_FULLY_ENABLED",
    locked: "PREMIUM_LOCKED",
    disabled: "PREMIUM_DISABLED"
};

class UserJourneyMap {
    constructor() {
        this.pojo = JSON.parse(JSON.stringify(DefaultSurveys));
        this.lowerCaseAnswers = {};
        for (let entryKey in Answers) {
            let entry = {};
            for (let answerKey in Answers[entryKey]) {
                let answer = Answers[entryKey][answerKey];
                entry[answerKey] = answer.toLowerCase();
            }

            this.lowerCaseAnswers[entryKey] = entry;
        }
    }

    get surveys() {
        return this.pojo;
    }

    get surveyCodes() {
        return SurveyCodes;
    }

    get questionCodes() {
        return QuestionCodes;
    }

    get surveyStatus() {
        return SurveyStatus;
    }

    get answers() {
        return Answers;
    }

    get answersLowerCase() {
        return this.lowerCaseAnswers;
    }

    get adSlots() {
        return AdSlots;
    }

    get clients() {
        return Clients;
    }

    get countryCodes() {
        return CountryCodes;
    }

    get userJourneySlugs() {
        return UserJourneySlugs;
    }

    get consentCodes() {
        return ConsentCodes;
    }

    get scriptsNowConsentCodes() {
        return ScriptsNowConsentCodes;
    }

    get premiumIntentConsentCodes() {
        return PremiumIntentConsentCodes;
    }

    get userTypes() {
        return UserTypes;
    }

    get premiumLevels() {
        return PremiumLevels;
    }
}

const userJourneyMap = new UserJourneyMap();
export default userJourneyMap;
export {
    QuestionCodes,
    SurveyCodes,
    SurveyStatus,
    Answers,
    WaistMeasureQuestionCodes,
    NumericQuestions,
    AdSlots,
    UserTypes,
    ConsentCodes,
    PremiumLevels
};
