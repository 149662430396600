import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { find, propEq } from "ramda";

// ------------------------------------------------------------------------

import {
    ACTIVITY,
    ALCOHOL_RISK,
    BLOOD_PRESSURE,
    BODY_COMPOSITION,
    BODY_FAT,
    BODY_MASS_INDEX,
    DIABETES,
    HDL_CHOLESTEROL,
    HEART_AGE,
    HEART_RATE,
    HEIGHT,
    HRA_EMOTIONAL_SCORE,
    HRA_LIFESTYLE_SCORE,
    HRA_SOCIAL_SCORE,
    MUSCLE_MASS,
    NON_FASTING_GLUCOSE,
    QRISK3,
    SMOKING,
    STRESS_PSS4,
    TDEE,
    TEN_YEAR_CVD_RISK,
    TOTAL_CHOLESTEROL,
    WEIGHT
} from "constants/trend-charts";
import { PORTAL_IMAGE } from "constants/imagePath";
import { UnitConverter } from "@sisuwellness/utilities";
import getStaticRecommendations from "constants/recommendations";
import routes from "constants/routes";

import getMetric, { getBPMetricBasedOnBPRecommendation } from "./metric";
import { fixedNumber } from "utilities/commonUtils";
import { calculateHeartAgeParams } from "views/HeartAge/utils";
import { calculateTotalDailyEnergyExpenditure, getTDEEMetricsWithUnitPreference } from "utilities/premium";

// ------------------------------------------------------------------------

export const getMetricCardProps = (healthCheck, type, recommendations = []) => {
    // Get Static Recommendation
    const metricRecommendation = find(propEq("risk", type === WEIGHT ? BODY_MASS_INDEX : type), recommendations) ||
        getStaticRecommendations(type) || { text: "" };

    const metric = getMetric(type, healthCheck) || getBPMetricBasedOnBPRecommendation(type, metricRecommendation);

    if (!metric && type !== HEIGHT) return null;

    return { metricRecommendation, metric };
};

// ------------------------------------------------------------------------

const HealthMetrics = ({ children, healthCheck = {}, type }) => {
    let {
        hr,
        bmi,
        dia,
        sys,
        isSmoker,
        weight,
        height,
        bodyFatPercent,
        ausdriskScoreValue,
        qriskScore,
        alcoholScore,
        totalCholesterol,
        hdlCholesterol,
        nonFastingGlucose,
        pss4Score,
        recommendations,
        activityRating,
        HRASocialScore,
        HRAEmotionalScore,
        HRALifestyleScore,
        muscleMass
    } = healthCheck;

    const { heartAge = null, tenYearCvdRisk } = calculateHeartAgeParams(healthCheck);

    const {
        settings: { unitMeasurement, energyUnit }
    } = useSelector(state => state);
    const metricProps = getMetricCardProps(healthCheck, type, recommendations);

    if (!metricProps) return null;

    const { metricRecommendation, metric } = metricProps;

    const activityIcon = `${PORTAL_IMAGE}/healthHub/activity.svg`;

    const isMetric = unitMeasurement === "metric";
    switch (type) {
        case BLOOD_PRESSURE: {
            return children({
                showIcon: true,
                unit: sys && dia ? "mmHg" : "",
                text: metricRecommendation.text,
                title: metric.title,
                value: sys && dia ? `${sys}/${dia}` : "",
                isRiskHigh: metric.isRiskHigh,
                link: routes.trend.bloodPressure,
                icon: metric.icon || `${PORTAL_IMAGE}/healthHub/bloodpressure.svg`,
                indicator: { bg: metric.color, label: metric.rating }
            });
        }
        case BODY_MASS_INDEX: {
            return children({
                showIcon: true,
                title: metric.title,
                value: fixedNumber(bmi),
                progress: metric.risk,
                link: routes.trend.bmi,
                icon: `${PORTAL_IMAGE}/healthHub/bmi.svg`,
                unit: "bmi",
                isRiskHigh: metric.isRiskHigh,
                text: metricRecommendation.text,
                indicator: { bg: metric.color, label: metric.rating }
            });
        }
        case BODY_FAT:
            return children({
                unit: "%",
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                link: routes.trend.bodyFat,
                isRiskHigh: metric.isRiskHigh,
                value: fixedNumber(bodyFatPercent),
                indicator: { bg: metric.color, label: metric.rating }
            });
        case HEART_RATE:
            return children({
                showIcon: true,
                value: hr,
                unit: "bpm",
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                link: routes.trend.heartRate,
                icon: `${PORTAL_IMAGE}/healthHub/heartrate.svg`,
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case DIABETES:
            return children({
                unit: "pts",
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                link: routes.trend.diabetes,
                value: ausdriskScoreValue,
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case QRISK3:
            return children({
                unit: "%",
                title: metric.title,
                progress: metric.risk,
                link: routes.trend.qrisk,
                value: fixedNumber(qriskScore),
                isRiskHigh: metric.isRiskHigh,
                text: metricRecommendation.text,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case ALCOHOL_RISK:
            return children({
                showIcon: true,
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: alcoholScore,
                link: routes.trend.alcohol,
                icon: `${PORTAL_IMAGE}/healthHub/alcohol.svg`,
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });

        case TOTAL_CHOLESTEROL:
            return children({
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: `${totalCholesterol.value}`,
                unit: "mmol/L",
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });

        case HDL_CHOLESTEROL:
            return children({
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: `${hdlCholesterol.value}`,
                unit: "mmol/L",
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });

        case NON_FASTING_GLUCOSE:
            return children({
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: `${nonFastingGlucose.value}`,
                unit: "mmol/L",
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });

        case ACTIVITY:
            return children({
                showIcon: true,
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                link: routes.trend.activity,
                icon: activityIcon,
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: activityRating }
            });
        case STRESS_PSS4:
            return children({
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: pss4Score,
                link: routes.trend.perceivedStress,
                isRiskHigh: metric.isRiskHigh,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case WEIGHT: {
            let value = [fixedNumber(weight), "kgs"];
            if (!isMetric) {
                const { stones, pounds } = UnitConverter.kgToStLbs(weight);
                value = [fixedNumber(stones), "st", fixedNumber(pounds), "lbs"];
            }
            return children({
                showIcon: true,
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: value[0],
                unit: value[1],
                secValue: value[2],
                secUnit: value[3],
                isRiskHigh: metric.isRiskHigh,
                link: routes.trend.weight,
                icon: `${PORTAL_IMAGE}/healthHub/weight.svg`,
                indicator: { bg: metric.color, label: metric.rating }
            });
        }
        case HRA_LIFESTYLE_SCORE:
            return children({
                unit: "%",
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: `${fixedNumber(HRALifestyleScore)}`,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case HRA_EMOTIONAL_SCORE:
            return children({
                unit: "%",
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: `${fixedNumber(HRAEmotionalScore)}`,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case HRA_SOCIAL_SCORE:
            return children({
                unit: "%",
                text: metricRecommendation.text,
                title: metric.title,
                progress: metric.risk,
                value: `${fixedNumber(HRASocialScore)}`,
                indicator: { bg: metric.color, label: metric.rating }
            });
        case HEIGHT: {
            let value = [fixedNumber(height), "cm"];
            if (!isMetric) {
                const { feet, inches } = UnitConverter.cmToFtInch(value[0]);
                value = [fixedNumber(feet), "ft", fixedNumber(inches), "in"];
            }
            return children({
                showIcon: true,
                value: value[0],
                unit: value[1],
                secValue: value[2],
                secUnit: value[3],
                title: "Height",
                link: routes.trend.height,
                text: metricRecommendation.text,
                converter: UnitConverter.cmToFtInch,
                icon: `${PORTAL_IMAGE}/healthHub/height.svg`
            });
        }

        case SMOKING:
            return children({
                showIcon: true,
                title: metric.title,
                text: metric.recommendation,
                link: routes.trend.smoking,
                icon: `${PORTAL_IMAGE}/healthHub/smoking.svg`,
                value: isSmoker ? "Smoker" : "Not Smoking",
                indicator: { bg: metric.color, label: metric.rating }
            });

        case HEART_AGE:
            return children({
                showIcon: true,
                unit: "Years",
                text: (metricRecommendation || {}).text,
                title: metric.title,
                value: heartAge,
                isRiskHigh: metric.isRiskHigh,
                link: routes.heartAge,
                icon: `${PORTAL_IMAGE}/premium/heart-age-icon.svg`,
                indicator: { bg: metric.color, label: metric.rating }
            });

        case TEN_YEAR_CVD_RISK:
            return children({
                unit: "%",
                text: (metricRecommendation || {}).text,
                title: metric.title,
                value: tenYearCvdRisk,
                isRiskHigh: metric.isRiskHigh,
                link: routes.trend.heartAge,
                indicator: { bg: metric.color, label: metric.rating }
            });

        case TDEE: {
            const data = calculateTotalDailyEnergyExpenditure(healthCheck);
            const { unitInString } = getTDEEMetricsWithUnitPreference(data, { energyUnit });

            return children({
                value: data,
                unit: unitInString,
                title: metric.title,
                indicator: { bg: metric.color, label: metric.rating }
            });
        }

        case MUSCLE_MASS: {
            return children({
                unit: "%",
                showIcon: true,
                value: muscleMass,
                title: metric.title,
                text: metricRecommendation.textPortal,
                icon: `${PORTAL_IMAGE}/healthHub/muscleMass.svg`,
                indicator: { bg: metric.color, label: metric.rating }
            });
        }

        case BODY_COMPOSITION: {
            return children({});
        }

        default:
            return <></>;
    }
};

// ------------------------------------------------------------------------

HealthMetrics.propTypes = {
    healthCheck: PropTypes.shape({
        bmi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isSmoker: PropTypes.bool,
        ausdriskScoreValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sys: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        bodyFatPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qriskScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        alcoholScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        activityRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        pss4Score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        HRASocialScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        HRAEmotionalScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        HRALifestyleScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        totalCholesterol: PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        hdlCholesterol: PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        nonFastingGlucose: PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        muscleMass: PropTypes.number,
        recommendations: PropTypes.array
    }).isRequired,
    children: PropTypes.any,
    type: PropTypes.string.isRequired
};

export default HealthMetrics;
