import React from "react";
import PropTypes from "prop-types";

import { Text, Box, Flex } from "rebass";

// ------------------------------------------------------------------------------------------------

import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import { SectionHeaders } from "views/HeartAge/commonElements";
import CardStack from "components/Cards/CardStack";
import CompositionGraph from "./compositionGraph";
import CompositionTabs from "./compositionTabs";
import ProgressView from "components/ProgressView";
import { useUserHealthCheckData } from "views/BodyComposition";
import { BODY_FAT, MUSCLE_MASS } from "constants/trend-charts";
import { ModalPrimaryButton } from "components/CommonComponents/Button";
import { useHistory } from "react-router";
import routes from "constants/routes";

// ------------------------------------------------------------------------------------------------

const BCompBreakdown = props => {
    const { latestHealthCheck } = useUserHealthCheckData();
    const history = useHistory();

    const onViewFullHistoryClick = route => history.push(route);

    return (
        <Box>
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    Body Composition breakdown
                </Text>
            </SectionHeaders>

            <HeadingLabel fontSize="22px" mt="24px">
                Your Body Composition
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px">
                Analyse your Body Composition breakdown to discover what you need to lose, gain, or maintain.
            </NormalLabel>

            <CardStack gap={["16px", "14px"]} pt="16px">
                <CompositionGraph value={latestHealthCheck} flexBasis={["330px", "40%"]} flexGrow={1} />
                <CompositionTabs flexBasis={["330px", "40%"]} flexGrow={1} />
            </CardStack>

            <HeadingLabel fontSize="22px" mt="24px">
                Your relevant skeletal muscle ranges
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px" mb="16px">
                See where you sit compared to health guidelines for your gender and age.
            </NormalLabel>
            <Flex flexDirection="column" bg="hpCardBackground" px={["22px", "16px"]} py={["24px", "28.5px"]}>
                <ProgressView type={MUSCLE_MASS} datum={latestHealthCheck} showPopup={false} />
                <Flex
                    alignItems="center"
                    px={["0px", "24px"]}
                    mt={["40px", "48px"]}
                    justifyContent="space-between"
                    flexDirection={["column", "row"]}
                >
                    <Text fontSize={["13px", "15px"]} color="hpGrayishBlueText">
                        See how your skeletal muscle has changed over time.
                    </Text>
                    <ModalPrimaryButton
                        mt={["24px", "0px"]}
                        height={["40px", "50px"]}
                        width={["235px", "250px"]}
                        fontSize={["14px", "16px"]}
                        onClick={() => onViewFullHistoryClick(routes.muscleMass)}
                    >
                        View Full History
                    </ModalPrimaryButton>
                </Flex>
            </Flex>

            <HeadingLabel fontSize="22px" mt="24px">
                Your relevant body fat ranges
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px" mb="16px">
                See where you sit compared to health guidelines for your gender and age.
            </NormalLabel>
            <Flex flexDirection="column" bg="hpCardBackground" px={["22px", "16px"]} py={["24px", "28.5px"]}>
                <ProgressView type={BODY_FAT} datum={latestHealthCheck} showPopup={false} />
                <Flex
                    alignItems="center"
                    px={["0px", "24px"]}
                    mt={["40px", "48px"]}
                    justifyContent="space-between"
                    flexDirection={["column", "row"]}
                >
                    <Text fontSize={["13px", "15px"]} color="hpGrayishBlueText">
                        See how your body fat has changed over time.
                    </Text>
                    <ModalPrimaryButton
                        mt={["24px", "0px"]}
                        height={["40px", "50px"]}
                        width={["235px", "250px"]}
                        fontSize={["14px", "16px"]}
                        onClick={() => onViewFullHistoryClick(routes.trend.bodyFat)}
                    >
                        View Full History
                    </ModalPrimaryButton>
                </Flex>
            </Flex>
        </Box>
    );
};

BCompBreakdown.propTypes = { setWeightGoal: PropTypes.func };

export default BCompBreakdown;
