import "./utilities/InternetExplorerPolyfills";
import "./i18n";
import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { RefreshRouter } from "@sisuwellness/web-components";
import APIClient from "utilities/APIClient";
import theme from "@sisuwellness/web-components/themes";
import configureStore from "@sisuwellness/connected-components/store";
import { Provider } from "react-redux";
import rootReducer from "reducers";
import thunk from "redux-thunk";

const store = configureStore({ rootReducer, middleware: [thunk] });

if (window.Cypress) {
    window.__store__ = store;
}

export const EntryPoint = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <RefreshRouter checkForUpdate={() => APIClient.appVersion.getMembersPortalAppVersion()}>
                <App />
            </RefreshRouter>
        </ThemeProvider>
    </Provider>
);

// eslint-disable-next-line
ReactDOM.render(
    <ErrorBoundary>
        <EntryPoint />
    </ErrorBoundary>,
    document.getElementById("root")
);

unregisterServiceWorker();
