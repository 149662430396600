import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Keyboard, { Style } from "./base/Keyboard";
import SisuLatinLayout from "./base/layouts/SisuLatinLayout";
import SymbolsNumericReducedLayout from "./base/layouts/SymbolsNumericReducedLayout";
import "./base/Keyboard.css"; // TODO: use styled-component
export default class SisuKeyboard extends PureComponent {
    static propTypes = {
        inputNode: PropTypes.any,
        layoutStyle: PropTypes.string,
        disableSymbols: PropTypes.bool,
        disableAlphabets: PropTypes.bool,
        symbolsLayout: PropTypes.shape({
            symbolsKeyValue: PropTypes.string,
            layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
        }),
        onClick: PropTypes.func,
        onNext: PropTypes.func,
        nextEnabled: PropTypes.bool,
        isFirstLetterUppercase: PropTypes.bool,
        uppercaseOnly: PropTypes.bool,
        enableDecimal: PropTypes.bool,
        defaultEnterText: PropTypes.string
    };

    static defaultProps = {
        layoutStyle: Style.sisu,
        disableSymbols: false,
        disableAlphabets: false,
        symbolsLayout: SymbolsNumericReducedLayout,
        nextEnabled: true,
        isFirstLetterUppercase: false,
        uppercaseOnly: false,
        enableDecimal: false,
        defaultEnterText: "NEXT"
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Keyboard
                inputNode={this.props.inputNode}
                onClick={this.props.onClick}
                onNext={value => this.props.onNext(value)}
                isNumeric={false}
                style={this.props.layoutStyle}
                layouts={[SisuLatinLayout]}
                disableSymbols={this.props.disableSymbols}
                disableAlphabets={this.props.disableAlphabets}
                symbolsLayout={this.props.symbolsLayout}
                nextEnabled={this.props.nextEnabled}
                isFirstLetterUppercase={this.props.isFirstLetterUppercase}
                data-testid={"touch-keyboard"}
                uppercaseOnly={this.props.uppercaseOnly}
                enableDecimal={this.props.enableDecimal}
                defaultEnterText={this.props.defaultEnterText}
            />
        );
    }
}
