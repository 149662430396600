import * as yup from "yup";

// ---------------------------------------------------------------------------

import {
    genderValidation,
    stringNameValidation,
    dateOfBirthValidation,
    mobileValidationByCountryCode,
    emailValidation,
    postalCodeValidation
} from "@sisuwellness/utilities/Validation/UserProfileValidation";
import Password from "@sisuwellness/utilities/Auth/Password";
import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";
import { ERROR_MESSAGES } from "constants/messages";
// ----------------------------------------------------------------

const PasswordUtil = new Password();

export const validateSchema = () =>
    yup.object().shape({
        firstName: stringNameValidation("first name"),
        lastName: stringNameValidation("surname"),
        email: emailValidation(),
        password: yup
            .string()
            .required("You must enter a password.")
            .test("password-valid", Password.errors.requirement, function(val) {
                if (!val) {
                    return true;
                }
                return val ? Promise.resolve(PasswordUtil.isPasswordValid(val)) : false;
            }),
        dateOfBirth: dateOfBirthValidation().required("Date of Birth is required field"),
        gender: genderValidation(["Male", "Female"]).required("Gender is a required field"),
        countryCode: yup.string().required("Please select a country"),
        postalCode: yup.string().when("countryCode", countryCode => {
            if (countryCode) {
                return postalCodeValidation(false, countryCode);
            } else {
                return yup.string().nullable();
            }
        }),
        mobileNumber: yup.string().when("countryCode", countryCode => {
            const options = {
                message: {
                    [COUNTRIES.AUSTRALIA]: ERROR_MESSAGES.VALIDATION.MOBILE_NUMBER.AUSTRALIA,
                    [COUNTRIES.UNITED_KINGDOM]: ERROR_MESSAGES.VALIDATION.MOBILE_NUMBER.UNITED_KINGDOM
                }
            };
            return mobileValidationByCountryCode(countryCode, options);
        }),
        termsAndConditions: yup
            .boolean()
            .required("Please accept the terms and conditions.")
            .oneOf([true], "Please accept the terms and conditions.")
    });
