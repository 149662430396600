import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";

// ------------------------------------------------------------------------

import Select from "components/Forms/Select";
import { getOptObject } from "utilities/commonUtils";
import { CountryHelper } from "@sisuwellness/utilities/User";

// ------------------------------------------------------------------------

export const CountryField = ({ touched, errors, values, handleBlur, setFieldValue, availableCountries, ...rest }) => (
    <Select
        label="Country"
        name="countryCode"
        data-testid="select-countryCode"
        options={availableCountries.map(name => getOptObject(name))}
        onBlur={() => handleBlur({ target: { name: "countryCode" } })}
        isError={touched["countryCode"] ? errors["countryCode"] : ""}
        value={getOptObject(CountryHelper.getCountryNameByCode(values["countryCode"]).toUpperCase())}
        onChange={option => setFieldValue("countryCode", CountryHelper.getCountryCodeByName(option.label))}
        {...rest}
    />
);

CountryField.propTypes = {
    ...Text.propTypes,
    isLocked: PropTypes.bool,
    errors: PropTypes.object,
    values: PropTypes.object,
    touched: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func
};
