import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import PageLayout from "components/PageLayout";
import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import styled, { ThemeContext } from "styled-components";
import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import { Text, Flex, Box, Image } from "rebass";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { PORTAL_IMAGE } from "constants/imagePath";
import Pie from "@sisuwellness/ui/src/components/Pie";
import HealthInfoPopup from "./HealthInfoPopup";
import routes from "constants/routes";

const StyledImage = styled(Image)`
    max-width: 200px;
    padding: 0px 0px 5px 0px;
`;

const StyledCard = styled(Card)`
    background-color: rgba(36, 105, 255, 0.1);
    border-radius: 10px;

    @media (min-width: 768px) and (max-width: 1152px) {
        width: 232px;
        height: 140px;
        padding: 16px;
    }
`;

const StyledHeading = styled(Heading)`
    @media (min-width: 768px) and (max-width: 1152px) {
        font-size: 15px;
        padding-bottom: 8px;
    }
`;

const StyledText = styled(Text)`
    margin-left: 16px;
    @media (min-width: 768px) and (max-width: 1152px) {
        font-size: 11px;
    }
`;

const HealthRiskOnboardingView = ({ history }) => {
    const theme = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigateToHRA = () => {
        history.push(routes.healthRiskAssessment);
    };

    return (
        <PageLayout>
            <Heading pb="24px" fontSize={["28px", "28px", "34px"]} fontWeight={theme.fontWeight.intermediate}>
                SiSU Health Score
            </Heading>
            <Card p={["24px", "32px"]} height="unset" width="100%" borderRadius="10px" mb={["30px", "40px"]}>
                <Flex
                    flexDirection={["column", "column", "row"]}
                    justifyContent={["center", "start", "space-between"]}
                    alignItems="center"
                >
                    <Box width={["100%", "100%", "65%"]}>
                        <Heading fontSize={["22px", "28px"]} mb="16px">
                            Calculate your SiSU Health Score now
                        </Heading>
                        <Text fontSize={["13px", "15px"]} mb="16px">
                            Calculate your SiSU Health Score in under 8-minutes to understand what areas of your health
                            could be at risk and how to improve them. Receive an overall score and scores in each
                            individual health index, all out of 100.
                        </Text>
                        <Flex pb={["24px", "24px", "10px"]}>
                            <Image
                                mr="5px"
                                src={`${PORTAL_IMAGE}/information.svg`}
                                height={["12.7px", "16px"]}
                                width={["12.7px", "16px"]}
                                alt="information"
                            />
                            <Text
                                color={theme.colours.hpPrimaryPurple.hex}
                                css={{ cursor: "pointer", textDecoration: "underline" }}
                                fontSize={["11px", "14px"]}
                                fontWeight="500"
                                onClick={() => setIsModalOpen(true)}
                            >
                                What specific health information will I need?
                            </Text>
                        </Flex>
                    </Box>
                    <PrimaryButton onClick={navigateToHRA}>Start Assessment Now</PrimaryButton>
                </Flex>
            </Card>
            <Card height="fit-content" mb={["130px", "160px"]} padding="24px" width="100%" borderRadius="10px">
                <Heading fontSize={["22px", "28px"]} mb="16px">
                    How is it calculated ?
                </Heading>
                <Text fontSize={["13px", "15px"]} mb="24px">
                    The SiSU Health Score is made up of 3 separate indexes: Lifestyle, Social and Emotional. By
                    accounting for all 3 indexes with some questions about your health metrics, the SiSU Health Score
                    gives a great, holistic indication of your health, any possible risks and how you can improve
                    specific areas.
                </Text>
                <Flex flexDirection={["column", "column", "row"]} alignItems="center" justifyContent="space-between">
                    <StyledCard p="24px" width="299px" height="fit-content">
                        <StyledHeading color={theme.colours.hpBlue.hex} pb="16px" fontSize="17px">
                            Lifestyle Index
                        </StyledHeading>
                        <Flex justifyContent="space-between">
                            <Pie progressBgColor={theme.colours.hpBlue.hex} size={65}>
                                <StyledImage
                                    src={`${PORTAL_IMAGE}/healthRiskAssessment/Lifestyle_Index.svg`}
                                    alt="lifestyle-index"
                                />
                            </Pie>
                            <StyledText ml="auto" fontSize="13px">
                                This takes into account factors like your activity and eating habits.
                            </StyledText>
                        </Flex>
                    </StyledCard>
                    <Text fontSize="20px" my={["16px", "", "unset"]}>
                        +
                    </Text>
                    <StyledCard p="24px" width="299px" height="fit-content">
                        <StyledHeading color={theme.colours.hpBlue.hex} pb="16px" fontSize="17px">
                            Social Index
                        </StyledHeading>
                        <Flex justifyContent="space-between">
                            <Pie progressBgColor={theme.colours.hpBlue.hex} size={65}>
                                <StyledImage
                                    src={`${PORTAL_IMAGE}/healthRiskAssessment/Social_Index.svg`}
                                    alt="lifestyle-index"
                                />
                            </Pie>
                            <StyledText ml="auto" fontSize="13px">
                                This takes into account factors like the quality of your relationships and social
                                interactions.
                            </StyledText>
                        </Flex>
                    </StyledCard>
                    <Text fontSize="20px" my={["16px", "", "unset"]}>
                        +
                    </Text>
                    <StyledCard p="24px" width="299px" height="fit-content">
                        <StyledHeading color={theme.colours.hpBlue.hex} pb="16px" fontSize="17px">
                            Emotional Index
                        </StyledHeading>
                        <Flex justifyContent="space-between">
                            <Pie progressBgColor={theme.colours.hpBlue.hex} size={65}>
                                <StyledImage
                                    src={`${PORTAL_IMAGE}/healthRiskAssessment/Emotional_Index.svg`}
                                    alt="lifestyle-index"
                                />
                            </Pie>

                            <StyledText ml="auto" fontSize="13px">
                                This takes into account factors like the quality of your relationships and how you deal
                                with pressure.
                            </StyledText>
                        </Flex>
                    </StyledCard>
                </Flex>
            </Card>
            <HealthInfoPopup modalIsOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
        </PageLayout>
    );
};

HealthRiskOnboardingView.propTypes = {
    history: PropTypes.object
};

export default HealthRiskOnboardingView;
