import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import routes from "constants/routes";
import { Box, Flex } from "rebass";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import { useHistory } from "react-router";

const TrendContainer = styled(Box)`
    position: relative;
`;

const TrendBody = styled.div`
    background-color: #f9fbff;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    opacity: 0.2;
`;

const AbsoluteBox = styled(Box)`
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
`;

const NoTrend = ({ title, message }) => {
    const history = useHistory();

    const handleOnClick = () => history.push(routes.findHealthStations);

    return (
        <TrendContainer data-testid="no-trend" pb="20px">
            <TrendBody />
            <AbsoluteBox>
                <HeadingLabel color="black" fontSize={["15px", "20px"]} fontWeight="bold" textAlign="center">
                    {title}
                </HeadingLabel>
                <NormalLabel color="black" fontSize={["11px", "15px"]} my={["20px", "40px"]} textAlign="center">
                    {message}
                </NormalLabel>
                <Flex width="100%" justifyContent="center">
                    <PrimaryButton onClick={handleOnClick}>Find A Station</PrimaryButton>
                </Flex>
            </AbsoluteBox>
        </TrendContainer>
    );
};

NoTrend.propTypes = {
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

NoTrend.defaultProps = {
    title: "No health check results to display!",
    message: "Please do a health check to see your trend data."
};

export default withTheme(NoTrend);
