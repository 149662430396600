import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

// -------------------------------------------------------------------

import APIClient from "utilities/APIClient";
import LoadingView from "components/LoadingView";
import { Errors } from "@sisuwellness/web-components/components/Forms";
import { usePremiumFeature, withPremium } from "components/PremiumFeatures";
import { USES_LEARN_WORLD } from "./ImproveCardData";
// -------------------------------------------------------------------

const ImproveLaunchStrings = {
    errors: {
        serverUnreachable: "Sorry, the server could not be reached",
        unexpectedError: "An unexpected error occurred"
    }
};

const Providers = {
    AUTO: "auto",
    LEARNWORLDS: "learnworlds",
    DRUPAL: "drupal"
};

const ImproveLaunch = ({ wants = "", replace = false, provider = Providers.AUTO }) => {
    const { isPremium } = usePremiumFeature();
    const [errorMessage, setErrorMessage] = useState("");
    const learnWorldsProgram = USES_LEARN_WORLD[wants];

    const getRequestPromise = useCallback(() => {
        if (isPremium && !!learnWorldsProgram) return APIClient.programs.getSingleSignOnUrl(learnWorldsProgram);
        if (!!learnWorldsProgram && provider === Providers.LEARNWORLDS)
            return APIClient.programs.getSingleSignOnUrl(learnWorldsProgram);
        return APIClient.auth.requestImproveUrl(wants);
    }, [isPremium, learnWorldsProgram, provider]);

    useEffect(() => {
        getRequestPromise()
            .then(response => {
                if (response.succeeded()) return response.body();

                response.isNetworkFailure()
                    ? setErrorMessage(ImproveLaunchStrings.errors.serverUnreachable)
                    : setErrorMessage(ImproveLaunchStrings.errors.unexpectedError);

                return null;
            })
            .then(responseBody => {
                if (!responseBody) return;

                const shouldUseLearnWorld = isPremium && !!learnWorldsProgram;
                const redirectUrl = shouldUseLearnWorld
                    ? responseBody.singleSignOn && responseBody.singleSignOn.singleSignOnUrl
                    : responseBody.redirectUrl;

                if (redirectUrl && redirectUrl.includes("https://")) {
                    replace ? window.location.replace(redirectUrl) : window.location.assign(redirectUrl);
                } else {
                    setErrorMessage(ImproveLaunchStrings.errors.unexpectedError);
                }
            });
    }, [APIClient]);

    if (errorMessage) {
        return <Errors>{errorMessage}</Errors>;
    }

    return <LoadingView />;
};

ImproveLaunch.propTypes = {
    wants: PropTypes.string,
    replace: PropTypes.bool,
    provider: PropTypes.string
};

export default withPremium(ImproveLaunch);
