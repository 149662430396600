import React, { useEffect } from "react";

import FindAStation from "../FindAStation";
import LoadingView from "components/LoadingView";
import LoadingViewWithoutMenu from "components/LoadingViewWithoutMenu";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getSwlhtHealthStationsAPI } from "actions/health-station";
import { useAsyncData } from "@sisuwellness/utilities/hooks";
import PageLayout from "components/PageLayout";
import withAuthentication from "components/withAuthentication";
import swlLogo from "assets/images/png/swl-logo.png";

const ShaneWarneLegacyHeartTest = ({ authenticated, stations, getSwlhtHealthStationsAPI }) => {
    const [{ isLoading }] = useAsyncData(() => getSwlhtHealthStationsAPI(), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (stations.length === 0 && !isLoading) return null;

    if (isLoading) {
        return authenticated ? <LoadingView /> : <LoadingViewWithoutMenu />;
    }

    const content = {
        logo: swlLogo,
        title: "Find a Shane Warne Legacy Heart Test",
        description:
            "Enter your post code to find your nearest SiSU Health Station with the free Shane Warne Legacy Heart Test",
        mapMarkerUrl: "/images/google-maps-pin-swlht.svg",
        mapClusterUrl: "/images/google-maps-cluster.png"
    };

    return (
        <PageLayout>
            <FindAStation installations={stations} region={"AU"} content={content} />
        </PageLayout>
    );
};

ShaneWarneLegacyHeartTest.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    stations: PropTypes.array.isRequired,
    getSwlhtHealthStationsAPI: PropTypes.func.isRequired
};

const mapStateToProps = ({
    healthStation: { stations = [] } = {},
    auth: { citizen = {}, authenticated = false } = {}
}) => ({
    authenticated,
    stations,
    citizen
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getSwlhtHealthStationsAPI
        },
        dispatch
    );

export default compose(
    withAuthentication,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ShaneWarneLegacyHeartTest);
