export default `postcode,place_name,state_name,state_code,latitude,longitude,accuracy
200,Australian National University,Australian Capital Territory,ACT,-35.2777,149.1189,1
221,Barton,Australian Capital Territory,ACT,-35.3049,149.1412,4
2540,Wreck Bay,Australian Capital Territory,ACT,-35.1627,150.6907,4
2540,Hmas Creswell,Australian Capital Territory,ACT,-35.028,150.5501,3
2540,Jervis Bay,Australian Capital Territory,ACT,-35.1333,150.7,4
2600,Deakin West,Australian Capital Territory,ACT,-35.3126,149.1278,3
2600,Duntroon,Australian Capital Territory,ACT,-35.3,149.1667,4
2600,Parliament House,Australian Capital Territory,ACT,-35.3126,149.1278,3
2600,Yarralumla,Australian Capital Territory,ACT,-35.2998,149.1058,4
2600,Russell,Australian Capital Territory,ACT,-35.2977,149.151,4
2600,Barton,Australian Capital Territory,ACT,-35.3049,149.1412,4
2600,Deakin,Australian Capital Territory,ACT,-35.3193,149.1031,4
2600,Capital Hill,Australian Capital Territory,ACT,-35.3066,149.1246,4
2600,Parkes,Australian Capital Territory,ACT,-35.3018,149.1304,4
2600,Harman,Australian Capital Territory,ACT,-35.35,149.2,4
2600,Hmas Harman,Australian Capital Territory,ACT,-35.31,149.1385,3
2600,Canberra,Australian Capital Territory,ACT,-35.2835,149.1281,4
2601,City,Australian Capital Territory,ACT,-35.2813,149.1293,4
2601,Canberra,Australian Capital Territory,ACT,-35.2835,149.1281,4
2601,Acton,Australian Capital Territory,ACT,-35.2777,149.1183,4
2601,Black Mountain,Australian Capital Territory,ACT,-35.2772,149.1054,3
2602,Downer,Australian Capital Territory,ACT,-35.2446,149.1447,4
2602,Hackett,Australian Capital Territory,ACT,-35.2495,149.1635,4
2602,Watson,Australian Capital Territory,ACT,-35.2381,149.1527,4
2602,Lyneham,Australian Capital Territory,ACT,-35.2398,149.1307,4
2602,O'Connor,Australian Capital Territory,ACT,-35.2564,149.1125,4
2602,Ainslie,Australian Capital Territory,ACT,-35.2625,149.1437,4
2602,Dickson,Australian Capital Territory,ACT,-35.2508,149.1393,4
2603,Red Hill,Australian Capital Territory,ACT,-35.3262,149.1191,4
2603,Manuka,Australian Capital Territory,ACT,-35.3126,149.1278,3
2603,Griffith,Australian Capital Territory,ACT,-35.3253,149.1371,4
2603,Forrest,Australian Capital Territory,ACT,-35.315,149.1286,4
2604,Narrabundah,Australian Capital Territory,ACT,-35.3357,149.1492,4
2604,Causeway,Australian Capital Territory,ACT,-35.3151,149.1512,4
2604,Kingston,Australian Capital Territory,ACT,-35.3152,149.1466,4
2605,Hughes,Australian Capital Territory,ACT,-35.3327,149.0949,4
2605,Garran,Australian Capital Territory,ACT,-35.3421,149.1085,4
2605,Curtin,Australian Capital Territory,ACT,-35.3246,149.0776,4
2606,O'Malley,Australian Capital Territory,ACT,-35.3525,149.1128,4
2606,Lyons,Australian Capital Territory,ACT,-35.3406,149.074,4
2606,Chifley,Australian Capital Territory,ACT,-35.3534,149.0768,4
2606,Woden,Australian Capital Territory,ACT,-35.3481,149.0905,3
2606,Phillip,Australian Capital Territory,ACT,-35.3503,149.0915,4
2606,Swinger Hill,Australian Capital Territory,ACT,-35.3481,149.0905,3
2607,Isaacs,Australian Capital Territory,ACT,-35.3686,149.1156,4
2607,Torrens,Australian Capital Territory,ACT,-35.372,149.0877,4
2607,Mawson,Australian Capital Territory,ACT,-35.3634,149.0986,4
2607,Farrer,Australian Capital Territory,ACT,-35.3767,149.105,4
2607,Pearce,Australian Capital Territory,ACT,-35.3622,149.0834,4
2608,Civic Square,Australian Capital Territory,ACT,-35.31,149.1933,1
2609,Pialligo,Australian Capital Territory,ACT,-35.3201,149.207,4
2609,Canberra Airport,Australian Capital Territory,ACT,-35.3172,149.1753,3
2609,Fyshwick,Australian Capital Territory,ACT,-35.3333,149.1667,4
2609,Symonston,Australian Capital Territory,ACT,-35.3516,149.1592,4
2609,Majura,Australian Capital Territory,ACT,-35.2667,149.2,4
2610,Canberra Bc,Australian Capital Territory,ACT,-35.2778,149.0111,1
2610,Canberra Mc,Australian Capital Territory,ACT,-35.2498,149.0591,1
2611,Stirling,Australian Capital Territory,ACT,-35.3497,149.0493,4
2611,Wright,Australian Capital Territory,ACT,-35.3224,149.0346,4
2611,Coree,Australian Capital Territory,ACT,-35.2794,148.9194,4
2611,Uriarra Village,Australian Capital Territory,ACT,-35.3503,149.0155,3
2611,Holder,Australian Capital Territory,ACT,-35.3344,149.0461,4
2611,Stromlo,Australian Capital Territory,ACT,-35.3255,148.9993,4
2611,Waramanga,Australian Capital Territory,ACT,-35.353,149.0621,4
2611,Chapman,Australian Capital Territory,ACT,-35.3562,149.0374,4
2611,Weston Creek,Australian Capital Territory,ACT,-35.3333,149.05,4
2611,Mount Stromlo,Australian Capital Territory,ACT,-35.4171,148.7769,3
2611,Weston,Australian Capital Territory,ACT,-35.3358,149.0593,4
2611,Coombs,Australian Capital Territory,ACT,-35.3163,149.0394,4
2611,Uriarra,Australian Capital Territory,ACT,-35.4171,148.7769,3
2611,Duffy,Australian Capital Territory,ACT,-35.3346,149.0319,4
2611,Fisher,Australian Capital Territory,ACT,-35.3613,149.057,4
2611,Rivett,Australian Capital Territory,ACT,-35.3471,149.0379,4
2612,Campbell,Australian Capital Territory,ACT,-35.2891,149.1538,4
2612,Turner,Australian Capital Territory,ACT,-35.2688,149.1247,4
2612,Reid,Australian Capital Territory,ACT,-35.2858,149.1391,4
2612,Braddon,Australian Capital Territory,ACT,-35.2708,149.1357,4
2614,Cook,Australian Capital Territory,ACT,-35.2601,149.0657,4
2614,Aranda,Australian Capital Territory,ACT,-35.2582,149.0804,4
2614,Page,Australian Capital Territory,ACT,-35.2386,149.0499,4
2614,Hawker,Australian Capital Territory,ACT,-35.2471,149.0367,4
2614,Scullin,Australian Capital Territory,ACT,-35.2346,149.039,4
2614,Jamison Centre,Australian Capital Territory,ACT,-35.2498,149.0591,3
2614,Weetangera,Australian Capital Territory,ACT,-35.2498,149.0591,3
2614,Macquarie,Australian Capital Territory,ACT,-35.2513,149.0636,4
2615,Fraser,Australian Capital Territory,ACT,-35.1917,149.0453,4
2615,Spence,Australian Capital Territory,ACT,-35.1987,149.0644,4
2615,Florey,Australian Capital Territory,ACT,-35.2259,149.05,4
2615,Holt,Australian Capital Territory,ACT,-35.2244,149.0119,4
2615,Charnwood,Australian Capital Territory,ACT,-35.2002,149.0341,4
2615,Higgins,Australian Capital Territory,ACT,-35.2324,149.0272,4
2615,Dunlop,Australian Capital Territory,ACT,-35.194,149.0198,4
2615,Kippax,Australian Capital Territory,ACT,-35.2101,149.034,3
2615,Melba,Australian Capital Territory,ACT,-35.2102,149.0541,4
2615,Flynn,Australian Capital Territory,ACT,-35.2059,149.0439,4
2615,Latham,Australian Capital Territory,ACT,-35.2165,149.0314,4
2615,Macgregor,Australian Capital Territory,ACT,-35.2098,149.011,4
2615,Kippax Centre,Australian Capital Territory,ACT,-35.2101,149.034,3
2616,Belconnen,Australian Capital Territory,ACT,-35.2167,149.0833,4
2617,Mckellar,Australian Capital Territory,ACT,-35.2175,149.077,4
2617,Belconnen,Australian Capital Territory,ACT,-35.2167,149.0833,4
2617,Evatt,Australian Capital Territory,ACT,-35.2119,149.0689,4
2617,Lawson,Australian Capital Territory,ACT,-35.2241,149.0998,4
2617,Giralang,Australian Capital Territory,ACT,-35.2109,149.096,4
2617,University Of Canberra,Australian Capital Territory,ACT,-35.2498,149.0591,3
2617,Belconnen DC,Australian Capital Territory,ACT,-35.2199,149.0869,3
2617,Kaleen,Australian Capital Territory,ACT,-35.2181,149.1052,4
2617,Bruce,Australian Capital Territory,ACT,-35.2441,149.0908,4
2618,Hall,Australian Capital Territory,ACT,-35.1685,149.0683,4
2620,Hume,Australian Capital Territory,ACT,-35.3855,149.1658,4
2620,Paddys River,Australian Capital Territory,ACT,-35.3914,149.2166,3
2620,Beard,Australian Capital Territory,ACT,-35.3422,149.2101,4
2620,Oaks Estate,Australian Capital Territory,ACT,-35.3397,149.2121,4
2620,Tharwa,Australian Capital Territory,ACT,-35.5167,149.0667,4
2620,Kowen,Australian Capital Territory,ACT,-35.3914,149.2166,3
2900,Greenway,Australian Capital Territory,ACT,-35.4183,149.0666,4
2900,Tuggeranong,Australian Capital Territory,ACT,-35.4158,149.0649,3
2901,Tuggeranong Dc,Australian Capital Territory,ACT,-35.4333,149.15,1
2902,Kambah,Australian Capital Territory,ACT,-35.3862,149.058,4
2902,Kambah Village,Australian Capital Territory,ACT,-35.3862,149.058,3
2903,Erindale Centre,Australian Capital Territory,ACT,-35.3998,149.0888,3
2903,Oxley,Australian Capital Territory,ACT,-35.4095,149.0786,4
2903,Wanniassa,Australian Capital Territory,ACT,-35.3978,149.0909,4
2904,Fadden,Australian Capital Territory,ACT,-35.405,149.1166,4
2904,Monash,Australian Capital Territory,ACT,-35.4158,149.0906,4
2904,Gowrie,Australian Capital Territory,ACT,-35.4119,149.109,4
2904,Macarthur,Australian Capital Territory,ACT,-35.4089,149.127,4
2905,Isabella Plains,Australian Capital Territory,ACT,-35.4283,149.088,4
2905,Theodore,Australian Capital Territory,ACT,-35.4496,149.1197,4
2905,Gilmore,Australian Capital Territory,ACT,-35.4199,149.1348,4
2905,Richardson,Australian Capital Territory,ACT,-35.4279,149.1138,4
2905,Calwell,Australian Capital Territory,ACT,-35.4404,149.1071,4
2905,Chisholm,Australian Capital Territory,ACT,-35.4125,149.1283,4
2905,Bonython,Australian Capital Territory,ACT,-35.4333,149.0782,4
2906,Conder,Australian Capital Territory,ACT,-35.4593,149.1042,4
2906,Gordon,Australian Capital Territory,ACT,-35.4568,149.085,4
2906,Banks,Australian Capital Territory,ACT,-35.4719,149.0997,4
2911,Mitchell,Australian Capital Territory,ACT,-35.2145,149.1293,4
2911,Crace,Australian Capital Territory,ACT,-35.2028,149.1074,4
2912,Gungahlin,Australian Capital Territory,ACT,-35.1867,149.1362,4
2913,Casey,Australian Capital Territory,ACT,-35.167,149.0947,4
2913,Franklin,Australian Capital Territory,ACT,-35.1995,149.1433,4
2913,Taylor,Australian Capital Territory,ACT,-35.1489,149.1092,4
2913,Palmerston,Australian Capital Territory,ACT,-35.1945,149.1194,4
2913,Kinlyside,Australian Capital Territory,ACT,-35.1754,149.0798,4
2913,Nicholls,Australian Capital Territory,ACT,-35.1873,149.0965,4
2913,Ginninderra Village,Australian Capital Territory,ACT,-35.1875,149.1244,3
2913,Ngunnawal,Australian Capital Territory,ACT,-35.1728,149.1115,4
2914,Bonner,Australian Capital Territory,ACT,-35.1623,149.1386,4
2914,Forde,Australian Capital Territory,ACT,-35.1682,149.1461,4
2914,Harrison,Australian Capital Territory,ACT,-35.1991,149.1563,4
2914,Amaroo,Australian Capital Territory,ACT,-35.1696,149.128,4
2914,Moncrieff,Australian Capital Territory,ACT,-35.1651,149.1179,4
2914,Jacka,Australian Capital Territory,ACT,-35.1568,149.1288,4
1001,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1002,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1003,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1004,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1005,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1006,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1007,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1008,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1009,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1010,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1011,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1020,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1021,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1022,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1023,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1025,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1026,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1027,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1028,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1029,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1030,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1031,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1032,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1033,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1034,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1035,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1036,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1037,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1038,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1039,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1040,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1041,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1042,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1043,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1044,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1045,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1046,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1100,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1101,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1105,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1106,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1107,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1108,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1109,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1110,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1112,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1113,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1114,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1115,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1116,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1117,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1118,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1119,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1120,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1121,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1122,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1123,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1124,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1125,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1126,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1127,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1128,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1129,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1130,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1131,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1132,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1133,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1134,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1135,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1136,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1137,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1138,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1139,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1140,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1141,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1142,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1143,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1144,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1145,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1146,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1147,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1148,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1149,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1150,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1151,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1152,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1153,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1154,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1155,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1156,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1157,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1158,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1159,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1160,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1161,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1162,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1163,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1164,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1165,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1166,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1167,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1168,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1169,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1170,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1171,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1172,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1173,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1174,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1175,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1176,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1177,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1178,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1179,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1180,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1181,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1182,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1183,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1184,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1185,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1186,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1187,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1188,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1189,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1190,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1191,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1192,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1193,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1194,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1195,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1196,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1197,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1198,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1199,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1200,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1201,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1202,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1203,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1204,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1205,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1206,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1207,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1208,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
1209,Australia Square,New South Wales,NSW,-33.8691,151.2071,1
1210,Australia Square,New South Wales,NSW,-33.8707,151.2068,1
1211,Australia Square,New South Wales,NSW,-33.8707,151.2068,1
1212,Australia Square,New South Wales,NSW,-33.8707,151.2068,1
1213,Australia Square,New South Wales,NSW,-33.8707,151.2068,1
1214,Australia Square,New South Wales,NSW,-33.8707,151.2068,1
1215,Australia Square,New South Wales,NSW,-33.8707,151.2068,1
1216,Grosvenor Place,New South Wales,NSW,-33.8707,151.2068,1
1217,Grosvenor Place,New South Wales,NSW,-33.8707,151.2068,1
1218,Grosvenor Place,New South Wales,NSW,-33.8707,151.2068,1
1219,Grosvenor Place,New South Wales,NSW,-33.8707,151.2068,1
1220,Grosvenor Place,New South Wales,NSW,-33.8707,151.2068,1
1221,Royal Exchange,New South Wales,NSW,-33.8707,151.2068,1
1222,Royal Exchange,New South Wales,NSW,-33.8707,151.2068,1
1223,Royal Exchange,New South Wales,NSW,-33.8707,151.2068,1
1224,Royal Exchange,New South Wales,NSW,-33.8707,151.2068,1
1225,Royal Exchange,New South Wales,NSW,-33.8707,151.2068,1
1226,Queen Victoria Building,New South Wales,NSW,-33.8707,151.2068,1
1227,Queen Victoria Building,New South Wales,NSW,-33.8707,151.2068,1
1228,Queen Victoria Building,New South Wales,NSW,-33.8707,151.2068,1
1229,Queen Victoria Building,New South Wales,NSW,-33.8707,151.2068,1
1230,Queen Victoria Building,New South Wales,NSW,-33.8787,151.2053,1
1231,Sydney South,New South Wales,NSW,-33.8787,151.2053,1
1232,Sydney South,New South Wales,NSW,-33.8787,151.2053,1
1233,Sydney South,New South Wales,NSW,-33.8787,151.2053,1
1234,Sydney South,New South Wales,NSW,-33.8787,151.2053,1
1235,Sydney South,New South Wales,NSW,-33.8787,151.2053,1
1236,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
1237,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
1238,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
1239,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
1240,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
1291,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1292,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1293,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1294,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1295,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1296,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1297,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1298,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1299,Sydney,New South Wales,NSW,-33.8678,151.2073,4
1300,Darlinghurst,New South Wales,NSW,-33.8794,151.2193,4
1311,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1312,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1313,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1314,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1315,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1316,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1317,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1318,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1319,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1320,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1321,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1322,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1323,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1324,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1325,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1326,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1327,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1328,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1329,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1330,Eastern Suburbs Mc,New South Wales,NSW,-33.8693,151.2248,1
1331,Eastern Suburbs Mc,New South Wales,NSW,-33.8693,151.2248,1
1332,Eastern Suburbs Mc,New South Wales,NSW,-33.8693,151.2248,1
1333,Eastern Suburbs Mc,New South Wales,NSW,-33.8693,151.2248,1
1334,Eastern Suburbs Mc,New South Wales,NSW,-33.8693,151.2248,1
1335,Potts Point,New South Wales,NSW,-33.8713,151.222,4
1340,Kings Cross,New South Wales,NSW,-33.875,151.2223,4
1350,Woollahra,New South Wales,NSW,-33.8878,151.2368,4
1355,Bondi Junction,New South Wales,NSW,-33.8928,151.2472,4
1360,Double Bay,New South Wales,NSW,-33.8778,151.2435,4
1363,Moore Park,New South Wales,NSW,-33.9014,151.2138,4
1391,Eastern Suburbs Mc,New South Wales,NSW,-33.8784,151.2313,1
1401,Broadway,New South Wales,NSW,-33.9475,151.1522,1
1416,Southern Suburbs Mc,New South Wales,NSW,-33.9475,151.1522,1
1419,Southern Suburbs Mc,New South Wales,NSW,-33.9475,151.1522,1
1420,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1421,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1422,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1423,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1424,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1425,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1426,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1427,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1428,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1429,Strawberry Hills,New South Wales,NSW,-33.9475,151.1522,1
1430,Eveleigh,New South Wales,NSW,-33.8956,151.1924,4
1435,Alexandria,New South Wales,NSW,-33.9022,151.2004,4
1440,Waterloo,New South Wales,NSW,-33.9167,151.2,4
1445,Rosebery,New South Wales,NSW,-33.9167,151.2,4
1450,Camperdown,New South Wales,NSW,-33.8897,151.1764,4
1455,Botany,New South Wales,NSW,-33.946,151.1959,4
1460,Mascot,New South Wales,NSW,-33.926,151.1935,4
1465,Kensington,New South Wales,NSW,-33.9202,151.2224,4
1466,Unsw Sydney,New South Wales,NSW,-33.925,151.2083,1
1470,Drummoyne,New South Wales,NSW,-33.8524,151.1549,4
1475,Marrickville,New South Wales,NSW,-33.9032,151.1518,4
1480,Kingsgrove,New South Wales,NSW,-33.9393,151.0993,4
1481,Hurstville Bc,New South Wales,NSW,-33.9687,151.1109,1
1484,Kingsgrove DC,New South Wales,NSW,-33.9687,151.1109,1
1485,Kogarah,New South Wales,NSW,-33.9833,151.1167,4
1487,Kogarah,New South Wales,NSW,-33.9833,151.1167,4
1490,Miranda,New South Wales,NSW,-34.0386,151.1001,4
1493,Hurstville,New South Wales,NSW,-33.9799,151.0903,4
1495,Caringbah,New South Wales,NSW,-34.0473,151.1205,4
1499,Sutherland,New South Wales,NSW,-34.031,151.0553,4
1515,West Chatswood,New South Wales,NSW,-33.794,151.1649,4
1560,Northbridge,New South Wales,NSW,-33.8094,151.2222,4
1565,Milsons Point,New South Wales,NSW,-33.846,151.2119,4
1570,Artarmon,New South Wales,NSW,-33.8167,151.1833,4
1582,Crows Nest,New South Wales,NSW,-33.8265,151.2019,4
1585,Crows Nest,New South Wales,NSW,-33.8265,151.2019,4
1590,St Leonards,New South Wales,NSW,-33.8234,151.1984,4
1595,Lane Cove,New South Wales,NSW,-33.8157,151.1668,4
1597,Lane Cove,New South Wales,NSW,-33.8157,151.1668,4
1602,Lane Cove DC,New South Wales,NSW,-33.7554,151.1998,1
1630,Hornsby,New South Wales,NSW,-33.7024,151.0993,4
1635,Hornsby Westfield,New South Wales,NSW,-33.7,151.1,1
1639,Frenchs Forest,New South Wales,NSW,-33.7482,151.2232,4
1640,Frenchs Forest,New South Wales,NSW,-33.7482,151.2232,4
1655,Manly,New South Wales,NSW,-33.798,151.2883,4
1658,Mona Vale,New South Wales,NSW,-33.6776,151.3031,4
1660,Mona Vale,New South Wales,NSW,-33.6776,151.3031,4
1670,North Ryde Bc,New South Wales,NSW,-33.8151,151.1288,1
1671,North Ryde,New South Wales,NSW,-33.7968,151.1244,4
1675,Gladesville,New South Wales,NSW,-33.8333,151.1333,4
1680,Ryde,New South Wales,NSW,-33.8188,151.1062,4
1685,West Ryde,New South Wales,NSW,-33.8054,151.0739,4
1690,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1691,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1692,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1693,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1694,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1695,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1696,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1697,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1698,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1699,Northern Suburbs Mc,New South Wales,NSW,-33.7554,151.1998,1
1700,Ermington,New South Wales,NSW,-33.8148,151.0547,4
1701,Rydalmere Bc,New South Wales,NSW,-33.7613,151.0294,1
1710,Epping,New South Wales,NSW,-33.7727,151.0818,4
1712,Epping,New South Wales,NSW,-33.7727,151.0818,4
1715,Pennant Hills,New South Wales,NSW,-33.7378,151.0722,4
1730,Seven Hills,New South Wales,NSW,-33.7833,150.9333,4
1740,Parramatta,New South Wales,NSW,-33.7613,151.0294,1
1741,Parramatta,New South Wales,NSW,-33.7613,151.0294,1
1750,North Parramatta,New South Wales,NSW,-33.7935,151.0012,4
1755,Baulkham Hills,New South Wales,NSW,-33.7588,150.9929,4
1765,Castle Hill,New South Wales,NSW,-33.7333,151,4
1771,Pennant Hills,New South Wales,NSW,-33.7378,151.0722,4
1781,Seven Hills Mc,New South Wales,NSW,-33.7613,151.0294,1
1790,St Marys,New South Wales,NSW,-33.7613,151.0294,1
1797,Penrith South DC,New South Wales,NSW,-33.7613,151.0294,1
1800,Ashfield,New South Wales,NSW,-33.8883,151.1227,4
1805,Burwood,New South Wales,NSW,-33.8833,151.1,4
1811,Silverwater,New South Wales,NSW,-33.8334,151.0473,4
1825,Lidcombe,New South Wales,NSW,-33.8644,151.0397,4
1826,Lidcombe,New South Wales,NSW,-33.8644,151.0397,4
1830,Granville,New South Wales,NSW,-33.8333,151.0167,4
1831,Granville,New South Wales,NSW,-33.8333,151.0167,4
1835,Auburn,New South Wales,NSW,-33.85,151.0333,4
1848,Guildford,New South Wales,NSW,-33.8643,150.9839,1
1851,Wetherill Park Dc,New South Wales,NSW,-33.8872,151.0086,1
1860,Fairfield,New South Wales,NSW,-33.8667,150.95,4
1871,Liverpool,New South Wales,NSW,-33.9,150.9333,4
1875,Moorebank,New South Wales,NSW,-33.9425,150.9377,4
1885,Bankstown,New South Wales,NSW,-33.9167,151.0333,4
1888,Bankstown,New South Wales,NSW,-33.9167,151.0333,4
1890,Ingleburn,New South Wales,NSW,-34,150.8667,4
1891,Milperra,New South Wales,NSW,-33.9393,150.9815,4
1900,Leightonfield Mc,New South Wales,NSW,-33.8816,150.9844,
1902,Leightonfield Mc,New South Wales,NSW,-33.8816,150.9844,
2000,Sydney,New South Wales,NSW,-33.8678,151.2073,4
2000,Dawes Point,New South Wales,NSW,-33.8561,151.2077,4
2000,The Rocks,New South Wales,NSW,-33.8592,151.2081,4
2000,Parliament House,New South Wales,NSW,-33.8651,151.2053,3
2000,Sydney South,New South Wales,NSW,-33.8699,151.2025,3
2000,Barangaroo,New South Wales,NSW,-33.8603,151.2016,4
2000,Millers Point,New South Wales,NSW,-33.8596,151.2041,4
2000,Haymarket,New South Wales,NSW,-33.8787,151.2053,4
2001,Sydney,New South Wales,NSW,-33.8678,151.2073,4
2002,World Square,New South Wales,NSW,-33.8699,151.2025,1
2004,Alexandria Mc,New South Wales,NSW,-33.8699,151.2025,1
2004,Eastern Suburbs Mc,New South Wales,NSW,-33.8699,151.2025,1
2006,The University Of Sydney,New South Wales,NSW,-33.8699,151.2025,1
2007,Broadway,New South Wales,NSW,-33.8699,151.2025,3
2007,Ultimo,New South Wales,NSW,-33.8772,151.1972,4
2008,Darlington,New South Wales,NSW,-33.891,151.1955,4
2008,Chippendale,New South Wales,NSW,-33.8867,151.1975,4
2009,Pyrmont,New South Wales,NSW,-33.8698,151.194,4
2010,Darlinghurst,New South Wales,NSW,-33.8794,151.2193,4
2010,Surry Hills,New South Wales,NSW,-33.8837,151.2128,4
2011,Kings Cross,New South Wales,NSW,-33.875,151.2223,4
2011,Potts Point,New South Wales,NSW,-33.8713,151.222,4
2011,Rushcutters Bay,New South Wales,NSW,-33.8748,151.228,4
2011,Elizabeth Bay,New South Wales,NSW,-33.8718,151.2271,4
2011,Woolloomooloo,New South Wales,NSW,-33.8704,151.2197,4
2012,Strawberry Hills,New South Wales,NSW,-33.9032,150.9677,1
2015,Eveleigh,New South Wales,NSW,-33.8956,151.1924,4
2015,Alexandria,New South Wales,NSW,-33.9022,151.2004,4
2015,Beaconsfield,New South Wales,NSW,-33.9115,151.2004,4
2016,Redfern,New South Wales,NSW,-33.8928,151.2041,4
2017,Waterloo,New South Wales,NSW,-33.9167,151.2,4
2017,Zetland,New South Wales,NSW,-33.9075,151.2086,4
2018,Rosebery,New South Wales,NSW,-33.9167,151.2,4
2018,Eastlakes,New South Wales,NSW,-33.932,151.2121,4
2019,Botany,New South Wales,NSW,-33.946,151.1959,4
2019,Banksmeadow,New South Wales,NSW,-33.9566,151.2096,4
2020,Sydney International Airport,New South Wales,NSW,-33.939,151.1862,3
2020,Mascot,New South Wales,NSW,-33.926,151.1935,4
2020,Sydney Domestic Airport,New South Wales,NSW,-33.939,151.1862,3
2021,Paddington,New South Wales,NSW,-33.8842,151.2315,4
2021,Moore Park,New South Wales,NSW,-33.9014,151.2138,4
2021,Centennial Park,New South Wales,NSW,-33.8979,151.2336,4
2022,Queens Park,New South Wales,NSW,-33.8995,151.2472,4
2022,Bondi Junction,New South Wales,NSW,-33.8928,151.2472,4
2023,Bellevue Hill,New South Wales,NSW,-33.879,151.251,4
2024,Bronte,New South Wales,NSW,-33.902,151.2656,4
2024,Waverley,New South Wales,NSW,-33.8977,151.2501,4
2025,Woollahra,New South Wales,NSW,-33.8878,151.2368,4
2026,Tamarama,New South Wales,NSW,-33.8984,151.2706,4
2026,Bondi Beach,New South Wales,NSW,-33.891,151.2716,4
2026,Bondi,New South Wales,NSW,-33.8943,151.2644,4
2026,North Bondi,New South Wales,NSW,-33.8853,151.2752,4
2026,Ben Buckler,New South Wales,NSW,-33.8904,151.2475,3
2027,Darling Point,New South Wales,NSW,-33.8706,151.2389,4
2027,Hmas Rushcutters,New South Wales,NSW,-33.8688,151.2444,3
2027,Point Piper,New South Wales,NSW,-33.8667,151.25,4
2027,Edgecliff,New South Wales,NSW,-33.8833,151.25,4
2028,Double Bay,New South Wales,NSW,-33.8778,151.2435,4
2029,Rose Bay,New South Wales,NSW,-33.8688,151.2706,4
2030,Vaucluse,New South Wales,NSW,-33.8555,151.2775,4
2030,Dover Heights,New South Wales,NSW,-33.8708,151.2792,4
2030,Watsons Bay,New South Wales,NSW,-33.8439,151.2829,4
2030,Hmas Watson,New South Wales,NSW,-33.8625,151.2819,3
2030,Rose Bay North,New South Wales,NSW,-33.8572,151.2784,3
2031,Clovelly,New South Wales,NSW,-33.9121,151.2588,4
2031,Randwick,New South Wales,NSW,-33.9144,151.2489,4
2031,St Pauls,New South Wales,NSW,-33.9251,151.231,3
2031,Clovelly West,New South Wales,NSW,-33.9251,151.231,3
2032,Kingsford,New South Wales,NSW,-33.924,151.2275,4
2032,Daceyville,New South Wales,NSW,-33.9333,151.2333,4
2033,Kensington,New South Wales,NSW,-33.9202,151.2224,4
2034,South Coogee,New South Wales,NSW,-33.9205,151.2552,4
2034,Coogee,New South Wales,NSW,-33.9205,151.2552,4
2035,Maroubra,New South Wales,NSW,-33.95,151.2333,4
2035,Pagewood,New South Wales,NSW,-33.9414,151.2109,4
2035,Maroubra South,New South Wales,NSW,-33.9445,151.2265,3
2036,Phillip Bay,New South Wales,NSW,-33.9816,151.234,4
2036,Hillsdale,New South Wales,NSW,-33.9515,151.2278,4
2036,Port Botany,New South Wales,NSW,-33.9745,151.2228,3
2036,Eastgardens,New South Wales,NSW,-33.9451,151.2258,4
2036,Little Bay,New South Wales,NSW,-33.979,151.243,4
2036,Malabar,New South Wales,NSW,-33.9622,151.248,4
2036,Chifley,New South Wales,NSW,-33.967,151.2422,4
2036,La Perouse,New South Wales,NSW,-33.9872,151.2317,4
2036,Matraville,New South Wales,NSW,-33.95,151.2333,4
2037,Glebe,New South Wales,NSW,-33.8788,151.1843,4
2037,Forest Lodge,New South Wales,NSW,-33.8814,151.18,4
2038,Annandale,New South Wales,NSW,-33.8833,151.1667,4
2039,Rozelle,New South Wales,NSW,-33.8614,151.1705,4
2040,Lilyfield,New South Wales,NSW,-33.875,151.1653,4
2040,Leichhardt,New South Wales,NSW,-33.8834,151.1562,4
2041,Balmain,New South Wales,NSW,-33.8548,151.1833,4
2041,Balmain East,New South Wales,NSW,-33.8571,151.1928,4
2041,Birchgrove,New South Wales,NSW,-33.8516,151.1824,4
2042,Newtown,New South Wales,NSW,-33.8983,151.1775,4
2042,Enmore,New South Wales,NSW,-30.7333,151.8,4
2043,Erskineville,New South Wales,NSW,-33.9025,151.1858,4
2044,St Peters,New South Wales,NSW,-33.9167,151.1833,4
2044,Sydenham,New South Wales,NSW,-33.9167,151.168,4
2044,Tempe,New South Wales,NSW,-33.9233,151.1602,4
2045,Haberfield,New South Wales,NSW,-33.8833,151.2,4
2046,Russell Lea,New South Wales,NSW,-33.8589,151.1411,4
2046,Wareemba,New South Wales,NSW,-33.8582,151.1309,4
2046,Five Dock,New South Wales,NSW,-33.8674,151.1291,4
2046,Chiswick,New South Wales,NSW,-33.85,151.1385,4
2046,Abbotsford,New South Wales,NSW,-33.8489,151.128,4
2046,Canada Bay,New South Wales,NSW,-33.85,151.15,4
2046,Rodd Point,New South Wales,NSW,-33.8666,151.1399,4
2047,Drummoyne,New South Wales,NSW,-33.8524,151.1549,4
2048,Westgate,New South Wales,NSW,-33.8798,151.1623,3
2048,Stanmore,New South Wales,NSW,-33.8941,151.1642,4
2049,Petersham North,New South Wales,NSW,-33.8946,151.1549,3
2049,Petersham,New South Wales,NSW,-33.8946,151.1549,4
2049,Lewisham,New South Wales,NSW,-33.8972,151.1488,4
2050,Missenden Road,New South Wales,NSW,-33.8892,151.1771,3
2050,Camperdown,New South Wales,NSW,-33.8897,151.1764,4
2052,Unsw Sydney,New South Wales,NSW,-33.8427,151.1936,1
2055,North Sydney,New South Wales,NSW,-33.839,151.2072,4
2057,Chatswood,New South Wales,NSW,-33.8,151.1833,4
2058,Northern Suburbs Mc,New South Wales,NSW,-33.8427,151.1936,1
2059,North Sydney,New South Wales,NSW,-33.839,151.2072,4
2060,Hmas Waterhen,New South Wales,NSW,-33.842,151.2046,3
2060,Waverton,New South Wales,NSW,-33.8386,151.2005,4
2060,Hmas Platypus,New South Wales,NSW,-33.842,151.2046,3
2060,Mcmahons Point,New South Wales,NSW,-33.8449,151.2031,4
2060,North Sydney Shoppingworld,New South Wales,NSW,-33.822,151.1962,3
2060,North Sydney,New South Wales,NSW,-33.839,151.2072,4
2060,Lavender Bay,New South Wales,NSW,-33.8437,151.2075,4
2061,Milsons Point,New South Wales,NSW,-33.846,151.2119,4
2061,Kirribilli,New South Wales,NSW,-33.8487,151.2162,4
2062,Cammeray,New South Wales,NSW,-33.8213,151.2161,4
2063,Northbridge,New South Wales,NSW,-33.8094,151.2222,4
2064,Artarmon,New South Wales,NSW,-33.8167,151.1833,4
2065,Greenwich,New South Wales,NSW,-33.8395,151.183,4
2065,Crows Nest,New South Wales,NSW,-33.8265,151.2019,4
2065,Gore Hill,New South Wales,NSW,-33.8167,151.1825,4
2065,Wollstonecraft,New South Wales,NSW,-33.8328,151.1898,4
2065,St Leonards,New South Wales,NSW,-33.8234,151.1984,4
2065,Naremburn,New South Wales,NSW,-33.817,151.2008,4
2065,Royal North Shore Hospital,New South Wales,NSW,-33.822,151.1962,3
2066,Northwood,New South Wales,NSW,-33.8289,151.1803,4
2066,Riverview,New South Wales,NSW,-33.824,151.1602,4
2066,Longueville,New South Wales,NSW,-33.8312,151.1662,4
2066,Lane Cove North,New South Wales,NSW,-33.8054,151.1664,4
2066,Linley Point,New South Wales,NSW,-33.8266,151.1502,4
2066,Lane Cove West,New South Wales,NSW,-33.8161,151.1515,4
2066,Lane Cove,New South Wales,NSW,-33.8157,151.1668,4
2067,Chatswood West,New South Wales,NSW,-33.794,151.1649,4
2067,Chatswood,New South Wales,NSW,-33.8,151.1833,4
2068,Willoughby East,New South Wales,NSW,-33.7984,151.2052,4
2068,Middle Cove,New South Wales,NSW,-33.7928,151.2125,4
2068,Willoughby North,New South Wales,NSW,-33.8094,151.2222,3
2068,Castlecrag,New South Wales,NSW,-33.7999,151.2231,4
2068,Willoughby,New South Wales,NSW,-33.8049,151.1993,4
2068,North Willoughby,New South Wales,NSW,-33.7956,151.2007,4
2069,Castle Cove,New South Wales,NSW,-33.7865,151.2092,4
2069,Roseville,New South Wales,NSW,-33.7833,151.1833,4
2069,Roseville Chase,New South Wales,NSW,-33.7785,151.1968,4
2070,East Lindfield,New South Wales,NSW,-33.7666,151.1868,4
2070,Lindfield West,New South Wales,NSW,-33.7766,151.17,3
2070,Lindfield,New South Wales,NSW,-33.7833,151.1667,4
2071,Killara,New South Wales,NSW,-33.766,151.1621,4
2071,East Killara,New South Wales,NSW,-33.7554,151.1815,4
2072,Gordon,New South Wales,NSW,-33.7553,151.1512,4
2073,West Pymble,New South Wales,NSW,-33.7667,151.1333,4
2073,Pymble,New South Wales,NSW,-33.7439,151.1419,4
2074,South Turramurra,New South Wales,NSW,-33.7494,151.1126,4
2074,Warrawee,New South Wales,NSW,-33.7288,151.1205,4
2074,North Turramurra,New South Wales,NSW,-33.7131,151.1464,4
2074,Turramurra,New South Wales,NSW,-33.7334,151.1285,4
2075,St Ives Chase,New South Wales,NSW,-33.7038,151.1646,4
2075,St Ives,New South Wales,NSW,-33.7209,151.1708,3
2076,Wahroonga,New South Wales,NSW,-33.7182,151.1156,4
2076,North Wahroonga,New South Wales,NSW,-33.7053,151.1228,4
2076,Normanhurst,New South Wales,NSW,-33.7333,151.1,4
2077,Waitara,New South Wales,NSW,-33.7109,151.1033,4
2077,Hornsby,New South Wales,NSW,-33.7024,151.0993,4
2077,Asquith,New South Wales,NSW,-33.6833,151.1,4
2077,Hornsby Heights,New South Wales,NSW,-33.6713,151.0943,4
2079,Mount Colah,New South Wales,NSW,-33.6833,151.1167,4
2080,Mount Kuring-Gai,New South Wales,NSW,-33.642,151.1287,4
2081,Berowra,New South Wales,NSW,-33.6208,151.1509,4
2081,Cowan,New South Wales,NSW,-33.5956,151.1686,4
2082,Berowra Creek,New South Wales,NSW,-33.5827,151.1221,4
2082,Berowra Waters,New South Wales,NSW,-33.6009,151.1264,4
2082,Berowra Heights,New South Wales,NSW,-33.6121,151.1375,4
2083,Cogra Bay,New South Wales,NSW,-33.5198,151.222,4
2083,Dangar Island,New South Wales,NSW,-33.5386,151.2415,4
2083,Cheero Point,New South Wales,NSW,-33.5096,151.1932,4
2083,Bar Point,New South Wales,NSW,-33.5122,151.1622,4
2083,Brooklyn,New South Wales,NSW,-33.549,151.2249,4
2083,Milsons Passage,New South Wales,NSW,-33.5169,151.1774,4
2083,Mooney Mooney,New South Wales,NSW,-33.5243,151.201,4
2084,Duffys Forest,New South Wales,NSW,-33.6705,151.196,4
2084,Terrey Hills,New South Wales,NSW,-33.6833,151.2333,4
2084,Cottage Point,New South Wales,NSW,-33.6197,151.2036,4
2085,Belrose West,New South Wales,NSW,-33.7173,151.232,3
2085,Belrose,New South Wales,NSW,-33.7395,151.2103,4
2085,Davidson,New South Wales,NSW,-33.743,151.2008,4
2086,Frenchs Forest East,New South Wales,NSW,-33.7482,151.2232,3
2086,Frenchs Forest,New South Wales,NSW,-33.7482,151.2232,4
2087,Killarney Heights,New South Wales,NSW,-33.7745,151.2203,4
2087,Forestville,New South Wales,NSW,-33.7667,151.2083,4
2088,Mosman,New South Wales,NSW,-33.839,151.2396,4
2088,Spit Junction,New South Wales,NSW,-33.8291,151.2448,3
2089,Kurraba Point,New South Wales,NSW,-33.8424,151.2226,4
2089,Neutral Bay Junction,New South Wales,NSW,-33.8358,151.2183,3
2089,Neutral Bay,New South Wales,NSW,-33.8378,151.2175,4
2090,Cremorne Junction,New South Wales,NSW,-33.83,151.2267,3
2090,Cremorne Point,New South Wales,NSW,-33.8401,151.227,4
2090,Cremorne,New South Wales,NSW,-33.8344,151.2268,4
2091,Hmas Penguin,New South Wales,NSW,-33.7787,151.2745,1
2092,Seaforth,New South Wales,NSW,-33.8014,151.2398,4
2093,Balgowlah Heights,New South Wales,NSW,-33.8066,151.2624,4
2093,North Balgowlah,New South Wales,NSW,-33.7863,151.248,4
2093,Balgowlah,New South Wales,NSW,-33.7949,151.2572,4
2093,Manly Vale,New South Wales,NSW,-33.7787,151.2745,3
2093,Clontarf,New South Wales,NSW,-33.8055,151.2537,4
2094,Fairlight,New South Wales,NSW,-33.7957,151.2735,4
2095,Manly,New South Wales,NSW,-33.798,151.2883,4
2095,Manly East,New South Wales,NSW,-33.798,151.2883,3
2096,Curl Curl,New South Wales,NSW,-33.7689,151.2889,4
2096,Harbord,New South Wales,NSW,-33.7787,151.2745,3
2096,Queenscliff,New South Wales,NSW,-33.7828,151.285,4
2096,Freshwater,New South Wales,NSW,-33.7787,151.2857,4
2097,Collaroy Beach,New South Wales,NSW,-33.732,151.3012,4
2097,Wheeler Heights,New South Wales,NSW,-33.7274,151.2791,4
2097,Collaroy,New South Wales,NSW,-33.732,151.3012,4
2097,Collaroy Plateau,New South Wales,NSW,-33.7293,151.2865,4
2099,Wingala,New South Wales,NSW,-33.7416,151.2762,3
2099,Narraweena,New South Wales,NSW,-33.7552,151.2766,4
2099,Cromer,New South Wales,NSW,-33.7312,151.2679,4
2099,Dee Why,New South Wales,NSW,-33.75,151.3,4
2099,North Curl Curl,New South Wales,NSW,-33.7623,151.2898,4
2100,Brookvale,New South Wales,NSW,-33.7611,151.2745,4
2100,Warringah Mall,New South Wales,NSW,-33.7646,151.2561,3
2100,Beacon Hill,New South Wales,NSW,-33.7528,151.2586,4
2100,Oxford Falls,New South Wales,NSW,-33.739,151.2444,4
2100,Allambie,New South Wales,NSW,-33.7666,151.2498,4
2100,North Manly,New South Wales,NSW,-33.7756,151.2692,4
2100,Allambie Heights,New South Wales,NSW,-33.7666,151.2498,4
2101,Elanora Heights,New South Wales,NSW,-33.7016,151.2797,4
2101,Narrabeen,New South Wales,NSW,-33.7128,151.2974,4
2101,North Narrabeen,New South Wales,NSW,-33.7093,151.2961,4
2101,Ingleside,New South Wales,NSW,-33.6846,151.2635,4
2102,Warriewood,New South Wales,NSW,-33.6886,151.2953,4
2102,Warriewood Shopping Square,New South Wales,NSW,-33.6793,151.2936,3
2103,Mona Vale,New South Wales,NSW,-33.6776,151.3031,4
2104,Bayview,New South Wales,NSW,-33.6598,151.2988,4
2105,Church Point,New South Wales,NSW,-33.6451,151.2837,4
2105,Morning Bay,New South Wales,NSW,-33.629,151.2867,4
2105,Elvina Bay,New South Wales,NSW,-33.6397,151.2777,4
2105,Lovett Bay,New South Wales,NSW,-33.6499,151.283,3
2105,Scotland Island,New South Wales,NSW,-33.6407,151.2875,4
2105,Mccarrs Creek,New South Wales,NSW,-33.6344,151.2889,3
2106,Newport,New South Wales,NSW,-33.6552,151.3213,4
2106,Newport Beach,New South Wales,NSW,-33.6569,151.3155,3
2107,Clareville,New South Wales,NSW,-33.6297,151.3168,4
2107,Bilgola Beach,New South Wales,NSW,-33.6449,151.3243,4
2107,Whale Beach,New South Wales,NSW,-33.6112,151.3289,4
2107,Bilgola Plateau,New South Wales,NSW,-33.6478,151.3121,4
2107,Careel Bay,New South Wales,NSW,-33.6321,151.3243,3
2107,Avalon Beach,New South Wales,NSW,-33.6359,151.329,4
2107,Bilgola,New South Wales,NSW,-33.6321,151.3243,3
2108,Great Mackerel Beach,New South Wales,NSW,-33.5961,151.297,4
2108,Palm Beach,New South Wales,NSW,-33.5966,151.3238,4
2108,Currawong Beach,New South Wales,NSW,-33.5968,151.2972,4
2108,Coasters Retreat,New South Wales,NSW,-33.6061,151.298,4
2108,The Basin,New South Wales,NSW,-33.6089,151.2646,3
2109,Macquarie University,New South Wales,NSW,-33.7716,151.1138,1
2110,Hunters Hill,New South Wales,NSW,-33.8343,151.1459,4
2110,Woolwich,New South Wales,NSW,-33.8402,151.1694,4
2111,Henley,New South Wales,NSW,-33.8434,151.1356,4
2111,Gladesville,New South Wales,NSW,-33.8333,151.1333,4
2111,Huntleys Point,New South Wales,NSW,-33.8405,151.1402,4
2111,Huntleys Cove,New South Wales,NSW,-33.838,151.1379,4
2111,Tennyson Point,New South Wales,NSW,-33.8317,151.1168,4
2111,Monash Park,New South Wales,NSW,-33.8295,151.1275,3
2111,Boronia Park,New South Wales,NSW,-33.8344,151.1293,3
2112,Ryde,New South Wales,NSW,-33.8188,151.1062,4
2112,Putney,New South Wales,NSW,-33.8261,151.1063,4
2112,Denistone East,New South Wales,NSW,-33.796,151.0974,4
2113,Macquarie Centre,New South Wales,NSW,-33.7912,151.1298,3
2113,East Ryde,New South Wales,NSW,-33.8103,151.1315,4
2113,Macquarie Park,New South Wales,NSW,-33.775,151.1125,4
2113,North Ryde,New South Wales,NSW,-33.7968,151.1244,4
2113,Blenheim Road,New South Wales,NSW,-33.7912,151.1298,3
2114,Meadowbank,New South Wales,NSW,-33.8167,151.0886,4
2114,West Ryde,New South Wales,NSW,-33.8054,151.0739,4
2114,Denistone,New South Wales,NSW,-33.7983,151.0905,4
2114,Denistone West,New South Wales,NSW,-33.8005,151.0762,4
2114,Melrose Park,New South Wales,NSW,-33.8149,151.0721,4
2115,Ermington,New South Wales,NSW,-33.8148,151.0547,4
2116,Rydalmere,New South Wales,NSW,-33.8145,151.0375,4
2117,Dundas,New South Wales,NSW,-33.8058,151.0339,4
2117,Dundas Valley,New South Wales,NSW,-33.7883,151.0526,4
2117,Telopea,New South Wales,NSW,-33.7928,151.0386,4
2117,Oatlands,New South Wales,NSW,-33.796,151.0271,4
2118,Carlingford,New South Wales,NSW,-33.7827,151.0489,4
2118,Carlingford Court,New South Wales,NSW,-33.7776,151.0418,3
2118,Carlingford North,New South Wales,NSW,-33.7722,151.0172,4
2118,Kingsdene,New South Wales,NSW,-33.7776,151.0418,3
2119,Beecroft,New South Wales,NSW,-33.7495,151.0648,4
2119,Cheltenham,New South Wales,NSW,-33.7543,151.065,3
2120,Pennant Hills,New South Wales,NSW,-33.7378,151.0722,4
2120,Thornleigh,New South Wales,NSW,-33.7323,151.079,4
2120,Westleigh,New South Wales,NSW,-33.7118,151.0714,4
2121,Epping,New South Wales,NSW,-33.7727,151.0818,4
2121,North Epping,New South Wales,NSW,-33.7594,151.0925,4
2122,Marsfield,New South Wales,NSW,-33.7778,151.1057,4
2122,Eastwood,New South Wales,NSW,-33.7918,151.0806,4
2123,Parramatta,New South Wales,NSW,-33.8167,151,1
2124,Parramatta,New South Wales,NSW,-33.8167,151,1
2125,West Pennant Hills,New South Wales,NSW,-33.7457,151.0476,4
2126,Cherrybrook,New South Wales,NSW,-33.722,151.0461,4
2127,Sydney Olympic Park,New South Wales,NSW,-33.8498,151.0683,4
2127,Wentworth Point,New South Wales,NSW,-33.8308,151.0744,4
2127,Homebush Bay,New South Wales,NSW,-33.8626,151.0913,4
2127,Newington,New South Wales,NSW,-33.8349,151.057,4
2128,Silverwater,New South Wales,NSW,-33.8334,151.0473,4
2129,Sydney Markets,New South Wales,NSW,-33.7838,151.0564,1
2130,Summer Hill,New South Wales,NSW,-33.8915,151.1382,4
2131,Ashfield,New South Wales,NSW,-33.8883,151.1227,4
2132,Croydon,New South Wales,NSW,-33.8833,151.1167,4
2133,Enfield South,New South Wales,NSW,-33.8972,151.1032,1
2133,Croydon Park,New South Wales,NSW,-33.8972,151.1032,1
2134,Burwood North,New South Wales,NSW,-33.8833,151.1,3
2134,Burwood,New South Wales,NSW,-33.8833,151.1,4
2135,Strathfield,New South Wales,NSW,-33.881,151.0799,4
2136,Strathfield South,New South Wales,NSW,-33.8925,151.0833,4
2136,Burwood Heights,New South Wales,NSW,-33.8905,151.1007,4
2136,Enfield,New South Wales,NSW,-33.8657,151.1042,3
2137,Mortlake,New South Wales,NSW,-33.8422,151.1072,4
2137,Breakfast Point,New South Wales,NSW,-33.8429,151.1107,4
2137,Concord,New South Wales,NSW,-33.8472,151.1038,4
2137,Cabarita,New South Wales,NSW,-33.8472,151.1164,4
2137,North Strathfield,New South Wales,NSW,-33.8569,151.0912,4
2138,Concord West,New South Wales,NSW,-33.8481,151.0861,4
2138,Rhodes,New South Wales,NSW,-33.8266,151.0881,4
2138,Liberty Grove,New South Wales,NSW,-33.8409,151.084,4
2139,Concord Repatriation Hospital,New South Wales,NSW,-33.8657,151.1042,1
2140,Homebush,New South Wales,NSW,-33.8667,151.0833,4
2140,Homebush South,New South Wales,NSW,-33.8667,151.0833,3
2140,Homebush West,New South Wales,NSW,-33.8626,151.0913,4
2141,Rookwood,New South Wales,NSW,-33.8742,151.0557,4
2141,Lidcombe North,New South Wales,NSW,-33.8693,151.0477,3
2141,Berala,New South Wales,NSW,-33.872,151.0339,4
2141,Lidcombe,New South Wales,NSW,-33.8644,151.0397,4
2142,Camellia,New South Wales,NSW,-33.8197,151.0264,4
2142,Blaxcell,New South Wales,NSW,-33.8332,151.0198,3
2142,Granville,New South Wales,NSW,-33.8333,151.0167,4
2142,South Granville,New South Wales,NSW,-33.8333,151.0167,4
2142,Rosehill,New South Wales,NSW,-33.8201,151.0245,4
2142,Clyde,New South Wales,NSW,-33.8333,151.0167,4
2142,Holroyd,New South Wales,NSW,-33.8333,150.95,4
2143,Birrong,New South Wales,NSW,-33.8925,151.0207,4
2143,Potts Hill,New South Wales,NSW,-33.8931,151.0337,4
2143,Regents Park,New South Wales,NSW,-33.8833,151.0167,4
2144,Auburn,New South Wales,NSW,-33.85,151.0333,4
2145,Wentworthville,New South Wales,NSW,-33.8167,150.9667,4
2145,Girraween,New South Wales,NSW,-33.799,150.943,4
2145,Pemulwuy,New South Wales,NSW,-33.8227,150.9239,4
2145,Westmead,New South Wales,NSW,-33.8038,150.9877,4
2145,Greystanes,New South Wales,NSW,-33.8235,150.9461,4
2145,Mays Hill,New South Wales,NSW,-33.8203,150.9943,4
2145,South Wentworthville,New South Wales,NSW,-33.8185,150.9634,4
2145,Pendle Hill,New South Wales,NSW,-33.8,150.95,4
2145,Constitution Hill,New South Wales,NSW,-33.7926,150.9763,4
2146,Toongabbie East,New South Wales,NSW,-33.7898,150.9562,3
2146,Toongabbie,New South Wales,NSW,-33.7833,150.95,4
2146,Old Toongabbie,New South Wales,NSW,-33.7876,150.969,4
2147,Lalor Park,New South Wales,NSW,-33.7609,150.9312,4
2147,Seven Hills West,New South Wales,NSW,-33.7684,150.9365,3
2147,Seven Hills,New South Wales,NSW,-33.7833,150.9333,4
2147,Kings Langley,New South Wales,NSW,-33.7501,150.9354,4
2148,Prospect,New South Wales,NSW,-33.8015,150.9136,4
2148,Kings Park,New South Wales,NSW,-33.7432,150.9076,4
2148,Marayong,New South Wales,NSW,-33.7482,150.8927,4
2148,Huntingwood,New South Wales,NSW,-33.7975,150.8799,4
2148,Blacktown,New South Wales,NSW,-33.7667,150.9167,4
2148,Blacktown Westpoint,New South Wales,NSW,-33.7896,150.9,3
2148,Arndell Park,New South Wales,NSW,-33.7893,150.8816,4
2150,Parramatta,New South Wales,NSW,-33.8197,151.0067,3
2150,Harris Park,New South Wales,NSW,-33.8229,151.0078,4
2150,Parramatta Westfield,New South Wales,NSW,-33.8197,151.0067,3
2151,North Parramatta,New South Wales,NSW,-33.7935,151.0012,4
2151,North Rocks,New South Wales,NSW,-33.7722,151.0172,4
2152,Northmead,New South Wales,NSW,-33.7833,150.9833,4
2153,Winston Hills,New South Wales,NSW,-33.7764,150.9805,4
2153,Bella Vista,New South Wales,NSW,-33.7413,150.9546,4
2153,Baulkham Hills,New South Wales,NSW,-33.7588,150.9929,4
2154,Castle Hill,New South Wales,NSW,-33.7333,151,4
2155,Rouse Hill,New South Wales,NSW,-33.6821,150.9154,4
2155,Beaumont Hills,New South Wales,NSW,-33.6999,150.9411,4
2155,Kellyville Ridge,New South Wales,NSW,-33.7013,150.9231,4
2155,Kellyville,New South Wales,NSW,-33.6994,150.9536,4
2156,Annangrove,New South Wales,NSW,-33.6576,150.9476,4
2156,Glenhaven,New South Wales,NSW,-33.7,151,4
2156,Kenthurst,New South Wales,NSW,-33.6553,151.005,4
2157,Glenorie,New South Wales,NSW,-33.6018,151.0089,4
2157,Forest Glen,New South Wales,NSW,-33.5502,151.0138,4
2157,Canoelands,New South Wales,NSW,-33.5,151.0667,4
2158,Round Corner,New South Wales,NSW,-33.6771,151.0339,3
2158,Dural,New South Wales,NSW,-33.6833,151.0167,4
2158,Middle Dural,New South Wales,NSW,-33.646,151.0205,4
2159,Galston,New South Wales,NSW,-33.653,151.0471,4
2159,Arcadia,New South Wales,NSW,-33.6167,151.0333,4
2159,Berrilee,New South Wales,NSW,-33.6155,151.0914,4
2159,Fiddletown,New South Wales,NSW,-33.6,151.05,4
2160,Merrylands,New South Wales,NSW,-33.8333,150.9833,4
2160,Merrylands West,New South Wales,NSW,-33.8327,150.9691,4
2161,Guildford,New South Wales,NSW,-33.8643,150.9839,3
2161,Old Guildford,New South Wales,NSW,-33.8643,150.9839,4
2161,Yennora,New South Wales,NSW,-33.8617,150.9686,4
2161,Guildford West,New South Wales,NSW,-33.8524,150.974,4
2162,Chester Hill,New South Wales,NSW,-33.879,150.9995,4
2162,Sefton,New South Wales,NSW,-33.8877,151.0105,4
2163,Lansdowne,New South Wales,NSW,-33.896,150.9756,4
2163,Carramar,New South Wales,NSW,-33.8838,150.9615,4
2163,Villawood,New South Wales,NSW,-33.8833,150.9667,4
2164,Smithfield West,New South Wales,NSW,-33.8667,150.917,4
2164,Smithfield,New South Wales,NSW,-33.85,150.9333,4
2164,Woodpark,New South Wales,NSW,-33.8414,150.9605,4
2164,Wetherill Park,New South Wales,NSW,-33.8428,150.9006,4
2165,Fairfield West,New South Wales,NSW,-33.8667,150.917,4
2165,Fairfield East,New South Wales,NSW,-33.8694,150.9713,4
2165,Fairfield,New South Wales,NSW,-33.8667,150.95,4
2165,Fairfield Heights,New South Wales,NSW,-33.8644,150.9388,4
2166,Canley Vale,New South Wales,NSW,-33.8866,150.948,4
2166,Cabramatta,New South Wales,NSW,-33.8974,150.9345,4
2166,Cabramatta West,New South Wales,NSW,-33.8967,150.9106,4
2166,Lansvale,New South Wales,NSW,-33.9014,150.9637,4
2166,Canley Heights,New South Wales,NSW,-33.8826,150.9241,4
2167,Glenfield,New South Wales,NSW,-33.9667,150.9,4
2168,Green Valley,New South Wales,NSW,-33.9029,150.8671,4
2168,Ashcroft,New South Wales,NSW,-33.916,150.8999,4
2168,Heckenberg,New South Wales,NSW,-33.9083,150.8898,4
2168,Busby,New South Wales,NSW,-33.9112,150.8807,4
2168,Cartwright,New South Wales,NSW,-33.925,150.8895,4
2168,Miller,New South Wales,NSW,-33.9209,150.8847,4
2168,Hinchinbrook,New South Wales,NSW,-33.9188,150.8631,4
2168,Sadleir,New South Wales,NSW,-33.9171,150.8909,4
2170,Casula,New South Wales,NSW,-33.9522,150.8995,4
2170,Lurnea,New South Wales,NSW,-33.9354,150.8967,4
2170,Hammondville,New South Wales,NSW,-33.9483,150.9521,4
2170,Liverpool South,New South Wales,NSW,-33.9,150.9333,3
2170,Chipping Norton,New South Wales,NSW,-33.9,150.95,4
2170,Casula Mall,New South Wales,NSW,-33.934,150.9122,3
2170,Mount Pritchard,New South Wales,NSW,-33.903,150.9046,4
2170,Liverpool,New South Wales,NSW,-33.9,150.9333,4
2170,Moorebank,New South Wales,NSW,-33.9425,150.9377,4
2170,Liverpool Westfield,New South Wales,NSW,-33.9239,150.9224,3
2170,Prestons,New South Wales,NSW,-33.9419,150.8717,4
2170,Warwick Farm,New South Wales,NSW,-33.9129,150.937,4
2171,Hoxton Park,New South Wales,NSW,-33.9313,150.8541,4
2171,Carnes Hill,New South Wales,NSW,-33.9385,150.8488,4
2171,Cecil Hills,New South Wales,NSW,-33.8892,150.8514,4
2171,West Hoxton,New South Wales,NSW,-33.9167,150.85,4
2171,Len Waters Estate,New South Wales,NSW,-33.9201,150.8502,3
2171,Horningsea Park,New South Wales,NSW,-33.9434,150.8453,4
2171,Elizabeth Hills,New South Wales,NSW,-33.9201,150.8502,3
2171,Middleton Grange,New South Wales,NSW,-33.917,150.8419,4
2172,Sandy Point,New South Wales,NSW,-35.0628,149.8635,4
2172,Voyager Point,New South Wales,NSW,-33.9587,150.9742,4
2172,Pleasure Point,New South Wales,NSW,-33.9699,150.9839,4
2173,Holsworthy,New South Wales,NSW,-33.9833,150.9667,4
2173,Wattle Grove,New South Wales,NSW,-33.9544,150.9445,4
2174,Edmondson Park,New South Wales,NSW,-33.9569,150.8613,4
2175,Horsley Park,New South Wales,NSW,-33.8432,150.8489,4
2176,Bossley Park,New South Wales,NSW,-33.8618,150.8841,4
2176,Abbotsbury,New South Wales,NSW,-33.8701,150.8612,4
2176,St Johns Park,New South Wales,NSW,-33.883,150.9018,4
2176,Greenfield Park,New South Wales,NSW,-33.8746,150.8919,4
2176,Prairiewood,New South Wales,NSW,-33.8632,150.9052,4
2176,Wakeley,New South Wales,NSW,-33.8743,150.9098,4
2176,Edensor Park,New South Wales,NSW,-33.8797,150.8778,4
2177,Bonnyrigg Heights,New South Wales,NSW,-33.8912,150.8699,4
2177,Bonnyrigg,New South Wales,NSW,-33.8936,150.8891,4
2178,Kemps Creek,New South Wales,NSW,-33.8806,150.7876,4
2178,Mount Vernon,New South Wales,NSW,-33.8604,150.8101,4
2178,Cecil Park,New South Wales,NSW,-33.8743,150.8418,4
2179,Austral,New South Wales,NSW,-33.9274,150.8081,4
2179,Leppington,New South Wales,NSW,-33.9698,150.7967,4
2190,Mount Lewis,New South Wales,NSW,-33.9175,151.0483,4
2190,Greenacre,New South Wales,NSW,-33.9003,151.0556,4
2190,Chullora,New South Wales,NSW,-33.9,151.05,4
2191,Belfield,New South Wales,NSW,-33.9035,151.0847,4
2192,Belmore,New South Wales,NSW,-33.919,151.0894,4
2193,Hurlstone Park,New South Wales,NSW,-33.9099,151.1287,4
2193,Canterbury,New South Wales,NSW,-33.9119,151.1186,4
2193,Ashbury,New South Wales,NSW,-33.8978,151.1196,4
2194,Campsie,New South Wales,NSW,-33.9125,151.1028,4
2195,Wiley Park,New South Wales,NSW,-33.9242,151.0674,4
2195,Lakemba,New South Wales,NSW,-33.9197,151.0759,4
2196,Roselands,New South Wales,NSW,-33.9332,151.0732,4
2196,Punchbowl,New South Wales,NSW,-33.9289,151.0511,4
2197,Bass Hill,New South Wales,NSW,-33.8986,150.9954,4
2198,Georges Hall,New South Wales,NSW,-33.9088,150.9885,4
2199,Yagoona,New South Wales,NSW,-33.9049,151.02,4
2199,Yagoona West,New South Wales,NSW,-33.9021,151.0223,3
2200,Condell Park,New South Wales,NSW,-33.9246,151.0109,4
2200,Mount Lewis,New South Wales,NSW,-33.9175,151.0483,4
2200,Bankstown Square,New South Wales,NSW,-33.9224,151.0231,3
2200,Bankstown Aerodrome,New South Wales,NSW,-33.9206,151.0221,3
2200,Bankstown North,New South Wales,NSW,-33.9224,151.0231,3
2200,Manahan,New South Wales,NSW,-33.9224,151.0231,3
2200,Bankstown,New South Wales,NSW,-33.9167,151.0333,4
2203,Dulwich Hill,New South Wales,NSW,-33.9041,151.1394,4
2204,Marrickville Metro,New South Wales,NSW,-33.9112,151.1557,3
2204,Marrickville,New South Wales,NSW,-33.9032,151.1518,4
2204,Marrickville South,New South Wales,NSW,-33.9032,151.1518,3
2205,Turrella,New South Wales,NSW,-33.9303,151.1421,4
2205,Wolli Creek,New South Wales,NSW,-33.9313,151.1522,4
2205,Arncliffe,New South Wales,NSW,-33.9363,151.1482,4
2206,Earlwood,New South Wales,NSW,-33.95,151.1,4
2206,Undercliffe,New South Wales,NSW,-33.9331,151.1111,3
2206,Clemton Park,New South Wales,NSW,-33.9348,151.0905,4
2207,Bexley South,New South Wales,NSW,-33.95,151.1167,3
2207,Bexley North,New South Wales,NSW,-33.9385,151.1139,4
2207,Bexley,New South Wales,NSW,-33.95,151.1167,4
2207,Bardwell Park,New South Wales,NSW,-33.9353,151.1257,4
2207,Bardwell Valley,New South Wales,NSW,-33.9334,151.1367,4
2208,Kingsway West,New South Wales,NSW,-33.9331,151.1111,3
2208,Kingsgrove,New South Wales,NSW,-33.9393,151.0993,4
2209,Beverly Hills,New South Wales,NSW,-33.948,151.0798,4
2209,Narwee,New South Wales,NSW,-33.9465,151.0692,4
2210,Peakhurst,New South Wales,NSW,-33.9629,151.0516,4
2210,Peakhurst Heights,New South Wales,NSW,-33.9774,151.0556,4
2210,Lugarno,New South Wales,NSW,-33.9827,151.0418,4
2210,Riverwood,New South Wales,NSW,-33.9473,151.0497,4
2211,Padstow Heights,New South Wales,NSW,-33.9674,151.0399,4
2211,Padstow,New South Wales,NSW,-33.9569,151.0319,4
2212,Revesby Heights,New South Wales,NSW,-33.9671,151.0184,4
2212,Revesby,New South Wales,NSW,-33.95,151.0167,4
2212,Revesby North,New South Wales,NSW,-33.9498,151.0148,3
2213,Panania,New South Wales,NSW,-33.9537,150.9973,4
2213,East Hills,New South Wales,NSW,-33.9667,150.9833,4
2213,Picnic Point,New South Wales,NSW,-33.9806,150.9966,4
2214,Milperra,New South Wales,NSW,-33.9393,150.9815,4
2216,Rockdale,New South Wales,NSW,-33.95,151.1333,4
2216,Kyeemagh,New South Wales,NSW,-33.9513,151.162,4
2216,Brighton-Le-Sands,New South Wales,NSW,-33.9601,151.1511,4
2216,Banksia,New South Wales,NSW,-33.946,151.143,4
2217,Beverley Park,New South Wales,NSW,-33.9736,151.1373,3
2217,Ramsgate Beach,New South Wales,NSW,-33.9852,151.1463,4
2217,Kogarah,New South Wales,NSW,-33.9833,151.1167,4
2217,Kogarah Bay,New South Wales,NSW,-33.9795,151.1212,4
2217,Monterey,New South Wales,NSW,-33.9725,151.1481,4
2217,Ramsgate,New South Wales,NSW,-33.9822,151.14,4
2218,Allawah,New South Wales,NSW,-33.9726,151.1144,4
2218,Carlton,New South Wales,NSW,-33.9711,151.1214,4
2219,Dolls Point,New South Wales,NSW,-33.995,151.1455,4
2219,Sans Souci,New South Wales,NSW,-33.9979,151.1293,4
2219,Sandringham,New South Wales,NSW,-33.9956,151.14,4
2220,Hurstville Grove,New South Wales,NSW,-33.9799,151.0903,4
2220,Hurstville Westfield,New South Wales,NSW,-33.9642,151.0991,3
2220,Hurstville,New South Wales,NSW,-33.9799,151.0903,4
2221,Blakehurst,New South Wales,NSW,-33.9915,151.1088,4
2221,Kyle Bay,New South Wales,NSW,-33.9879,151.0994,4
2221,Carss Park,New South Wales,NSW,-33.9867,151.1173,4
2221,South Hurstville,New South Wales,NSW,-33.9776,151.1055,4
2221,Connells Point,New South Wales,NSW,-33.9883,151.0909,4
2222,Penshurst,New South Wales,NSW,-33.9667,151.0833,4
2223,Mortdale,New South Wales,NSW,-33.9667,151.0833,4
2223,Oatley,New South Wales,NSW,-33.98,151.072,4
2224,Kangaroo Point,New South Wales,NSW,-34.0026,151.0959,4
2224,Sylvania,New South Wales,NSW,-34.0124,151.0972,4
2224,Sylvania Southgate,New South Wales,NSW,-34.01,151.0947,3
2224,Sylvania Waters,New South Wales,NSW,-34.0184,151.1056,4
2225,Oyster Bay,New South Wales,NSW,-34.0041,151.0759,4
2226,Jannali,New South Wales,NSW,-34.0164,151.0607,4
2226,Como,New South Wales,NSW,-33.9994,151.0639,4
2226,Bonnet Bay,New South Wales,NSW,-34.0083,151.0519,4
2227,Gymea Bay,New South Wales,NSW,-34.0521,151.088,4
2227,Gymea,New South Wales,NSW,-34.0364,151.0853,4
2228,Miranda,New South Wales,NSW,-34.0386,151.1001,4
2228,Yowie Bay,New South Wales,NSW,-34.0512,151.1023,4
2229,Taren Point,New South Wales,NSW,-34.0194,151.1183,4
2229,Caringbah,New South Wales,NSW,-34.0473,151.1205,4
2229,Caringbah South,New South Wales,NSW,-34.0566,151.1215,4
2229,Port Hacking,New South Wales,NSW,-34.0679,151.1256,4
2229,Dolans Bay,New South Wales,NSW,-34.0665,151.1281,4
2229,Lilli Pilli,New South Wales,NSW,-34.0683,151.1157,4
2230,Greenhills Beach,New South Wales,NSW,-34.0328,151.1762,4
2230,Burraneer,New South Wales,NSW,-34.0562,151.1381,4
2230,Maianbar,New South Wales,NSW,-34.0837,151.1241,4
2230,Woolooware,New South Wales,NSW,-34.0458,151.1431,4
2230,Bundeena,New South Wales,NSW,-34.0838,151.1502,4
2230,Cronulla,New South Wales,NSW,-34.0625,151.1496,4
2231,Kurnell,New South Wales,NSW,-34.0109,151.2051,4
2232,Audley,New South Wales,NSW,-34.106,151.0832,3
2232,Sutherland,New South Wales,NSW,-34.031,151.0553,4
2232,Loftus,New South Wales,NSW,-34.0447,151.0465,4
2232,Kirrawee,New South Wales,NSW,-34.0383,151.069,4
2232,Woronora,New South Wales,NSW,-34.0244,151.0386,4
2232,Kareela,New South Wales,NSW,-34.0134,151.0835,4
2232,Grays Point,New South Wales,NSW,-34.0587,151.086,4
2233,Waterfall,New South Wales,NSW,-34.1357,150.9923,4
2233,Woronora Heights,New South Wales,NSW,-34.0356,151.0273,4
2233,Heathcote,New South Wales,NSW,-34.0841,151.013,4
2233,Yarrawarrah,New South Wales,NSW,-34.0535,151.0356,4
2233,Engadine,New South Wales,NSW,-34.0656,151.0127,4
2234,Lucas Heights,New South Wales,NSW,-34.06,150.9807,3
2234,Illawong,New South Wales,NSW,-34,151.0333,4
2234,Menai Central,New South Wales,NSW,-34.06,150.9807,3
2234,Menai,New South Wales,NSW,-34.0167,151.0167,4
2234,Bangor,New South Wales,NSW,-34.0167,151.0333,4
2234,Alfords Point,New South Wales,NSW,-33.9874,151.0253,4
2234,Barden Ridge,New South Wales,NSW,-34.0341,151.0119,4
2250,Wyoming,New South Wales,NSW,-33.4039,151.3625,4
2250,Somersby,New South Wales,NSW,-33.3667,151.2833,4
2250,West Gosford,New South Wales,NSW,-33.4258,151.317,4
2250,Mount Elliot,New South Wales,NSW,-33.4009,151.3879,4
2250,Erina,New South Wales,NSW,-33.4322,151.3897,4
2250,Niagara Park,New South Wales,NSW,-33.3764,151.3486,4
2250,Central Mangrove,New South Wales,NSW,-33.293,151.2387,4
2250,Mangrove Mountain,New South Wales,NSW,-33.3,151.2,4
2250,Glenworth Valley,New South Wales,NSW,-33.4333,151.1833,4
2250,Kariong,New South Wales,NSW,-33.4397,151.2945,4
2250,Narara,New South Wales,NSW,-33.4,151.35,4
2250,Tascott,New South Wales,NSW,-33.4502,151.3137,4
2250,Peats Ridge,New South Wales,NSW,-33.3167,151.2333,4
2250,Mount White,New South Wales,NSW,-33.4532,151.2004,4
2250,Kulnura,New South Wales,NSW,-33.205,151.1759,4
2250,Springfield,New South Wales,NSW,-33.4294,151.3574,4
2250,Point Clare,New South Wales,NSW,-33.4438,151.3273,4
2250,Mooney Mooney Creek,New South Wales,NSW,-33.4826,151.2275,4
2250,Lisarow,New South Wales,NSW,-33.3833,151.3667,4
2250,Gosford,New South Wales,NSW,-33.4244,151.344,4
2250,East Gosford,New South Wales,NSW,-33.4387,151.3534,4
2250,Mangrove Creek,New South Wales,NSW,-33.35,151.15,4
2250,Upper Mangrove,New South Wales,NSW,-33.2833,151.1167,4
2250,North Gosford,New South Wales,NSW,-33.389,151.2859,3
2250,Matcham,New South Wales,NSW,-33.4167,151.4167,4
2250,Ten Mile Hollow,New South Wales,NSW,-33.389,151.2859,3
2250,Erina Fair,New South Wales,NSW,-33.3232,151.2282,3
2250,Point Frederick,New South Wales,NSW,-33.4404,151.3432,4
2250,Holgate,New South Wales,NSW,-33.4,151.4167,4
2250,Bucketty,New South Wales,NSW,-33.389,151.2859,3
2250,Lower Mangrove,New South Wales,NSW,-33.4,151.15,4
2250,Wendoree Park,New South Wales,NSW,-33.4566,151.1675,4
2250,Greengrove,New South Wales,NSW,-33.3833,151.15,4
2250,Calga,New South Wales,NSW,-33.4312,151.2275,4
2251,Picketts Valley,New South Wales,NSW,-33.4553,151.4109,4
2251,Bouddi,New South Wales,NSW,-33.518,151.3858,4
2251,Davistown,New South Wales,NSW,-33.4856,151.3615,4
2251,Copacabana,New South Wales,NSW,-33.4869,151.4359,4
2251,Green Point,New South Wales,NSW,-32.2493,152.5176,4
2251,Yattalunga,New South Wales,NSW,-33.4809,151.3966,3
2251,Kincumber,New South Wales,NSW,-33.4674,151.381,4
2251,Macmasters Beach,New South Wales,NSW,-33.4966,151.4221,4
2251,Avoca Beach,New South Wales,NSW,-33.4682,151.4339,4
2251,Saratoga,New South Wales,NSW,-33.4756,151.3521,4
2251,Bensville,New South Wales,NSW,-33.4976,151.3808,4
2251,Kincumber South,New South Wales,NSW,-33.4866,151.377,4
2252,Central Coast Mc,New South Wales,NSW,-33.344,151.382,1
2256,Horsfield Bay,New South Wales,NSW,-33.4924,151.301,4
2256,Phegans Bay,New South Wales,NSW,-33.4878,151.3082,4
2256,Woy Woy Bay,New South Wales,NSW,-33.4834,151.312,4
2256,Little Wobby,New South Wales,NSW,-33.5454,151.2564,4
2256,Patonga,New South Wales,NSW,-33.5486,151.2719,4
2256,Koolewong,New South Wales,NSW,-33.465,151.3157,4
2256,Pearl Beach,New South Wales,NSW,-33.5444,151.3034,4
2256,Blackwall,New South Wales,NSW,-33.5019,151.3237,4
2256,Wondabyne,New South Wales,NSW,-33.4876,151.262,4
2256,Woy Woy,New South Wales,NSW,-33.4843,151.3247,4
2257,Killcare Heights,New South Wales,NSW,-33.5239,151.3682,4
2257,Booker Bay,New South Wales,NSW,-33.5167,151.35,4
2257,Daleys Point,New South Wales,NSW,-33.4969,151.355,4
2257,Empire Bay,New South Wales,NSW,-33.4939,151.3629,4
2257,Hardys Bay,New South Wales,NSW,-33.5247,151.3617,4
2257,Pretty Beach,New South Wales,NSW,-33.5167,151.35,4
2257,Box Head,New South Wales,NSW,-33.5308,151.355,4
2257,Killcare,New South Wales,NSW,-33.5167,151.3667,4
2257,St Huberts Island,New South Wales,NSW,-33.4956,151.3462,4
2257,Umina Beach,New South Wales,NSW,-33.5231,151.3132,4
2257,Ettalong Beach,New South Wales,NSW,-33.5106,151.3304,4
2257,Wagstaffe,New South Wales,NSW,-33.5236,151.3409,4
2258,Palmdale,New South Wales,NSW,-33.3333,151.3667,4
2258,Palm Grove,New South Wales,NSW,-33.3333,151.3167,4
2258,Fountaindale,New South Wales,NSW,-33.3477,151.4011,4
2258,Kangy Angy,New South Wales,NSW,-33.3188,151.3964,4
2258,Ourimbah,New South Wales,NSW,-33.3667,151.4333,4
2259,Wybung,New South Wales,NSW,-33.2192,151.4569,3
2259,Yarramalong,New South Wales,NSW,-33.2333,151.2833,4
2259,Cedar Brush Creek,New South Wales,NSW,-33.1667,151.2833,4
2259,Kiar,New South Wales,NSW,-33.2012,151.4376,4
2259,Frazer Park,New South Wales,NSW,-33.2192,151.4569,3
2259,Lake Munmorah,New South Wales,NSW,-33.1972,151.5824,4
2259,Dooralong,New South Wales,NSW,-33.1833,151.35,4
2259,Wadalba,New South Wales,NSW,-33.2723,151.4649,4
2259,Bushells Ridge,New South Wales,NSW,-33.21,151.4747,4
2259,Mardi,New South Wales,NSW,-33.2937,151.4015,4
2259,Little Jilliby,New South Wales,NSW,-33.2407,151.3701,4
2259,Warnervale,New South Wales,NSW,-33.2466,151.4517,4
2259,Gwandalan,New South Wales,NSW,-33.1354,151.5829,4
2259,Alison,New South Wales,NSW,-33.2768,151.4021,4
2259,Mannering Park,New South Wales,NSW,-33.1581,151.5348,4
2259,Freemans,New South Wales,NSW,-33.2063,151.6012,4
2259,Tacoma South,New South Wales,NSW,-33.293,151.4534,4
2259,Halloran,New South Wales,NSW,-33.2323,151.4367,4
2259,Watanobbi,New South Wales,NSW,-33.2677,151.4225,4
2259,Jilliby,New South Wales,NSW,-33.2261,151.4168,4
2259,Crangan Bay,New South Wales,NSW,-33.1735,151.5882,4
2259,Wyong,New South Wales,NSW,-33.2825,151.4233,4
2259,Durren Durren,New South Wales,NSW,-33.2,151.4,4
2259,Point Wolstoncroft,New South Wales,NSW,-33.1203,151.5844,4
2259,Moonee,New South Wales,NSW,-30.2057,153.1529,4
2259,Tacoma,New South Wales,NSW,-33.2855,151.4537,4
2259,Kingfisher Shores,New South Wales,NSW,-33.1683,151.5531,4
2259,Wyong Creek,New South Wales,NSW,-33.2667,151.3667,4
2259,Chain Valley Bay,New South Wales,NSW,-33.1724,151.5714,4
2259,Ravensdale,New South Wales,NSW,-33.1744,151.3007,4
2259,Rocky Point,New South Wales,NSW,-33.2934,151.4699,4
2259,Wyee Point,New South Wales,NSW,-33.1278,151.5131,4
2259,Lemon Tree,New South Wales,NSW,-33.1492,151.3635,4
2259,Wyee,New South Wales,NSW,-33.1825,151.488,4
2259,Kanwal,New South Wales,NSW,-33.253,151.4911,4
2259,Wallarah,New South Wales,NSW,-33.2291,151.4547,4
2259,Tuggerah,New South Wales,NSW,-33.3167,151.4167,4
2259,Tuggerawong,New South Wales,NSW,-33.2804,151.4804,4
2259,Woongarrah,New South Wales,NSW,-33.2413,151.4756,4
2259,Summerland Point,New South Wales,NSW,-33.1409,151.5656,4
2259,Wyongah,New South Wales,NSW,-33.2747,151.489,4
2259,Hamlyn Terrace,New South Wales,NSW,-33.2512,151.4763,4
2260,Terrigal,New South Wales,NSW,-33.4482,151.4467,4
2260,Wamberal,New South Wales,NSW,-33.4155,151.4456,4
2260,Erina Heights,New South Wales,NSW,-33.4252,151.3995,4
2260,Forresters Beach,New South Wales,NSW,-33.407,151.476,4
2260,North Avoca,New South Wales,NSW,-33.4561,151.4351,4
2261,Blue Bay,New South Wales,NSW,-33.3541,151.5002,4
2261,Killarney Vale,New South Wales,NSW,-33.3549,151.4722,3
2261,Bateau Bay,New South Wales,NSW,-33.3833,151.4667,4
2261,Bay Village,New South Wales,NSW,-33.3549,151.4722,3
2261,Tumbi Umbi,New South Wales,NSW,-33.3667,151.45,4
2261,Chittaway Point,New South Wales,NSW,-33.3236,151.4419,4
2261,Long Jetty,New South Wales,NSW,-33.358,151.4814,4
2261,The Entrance North,New South Wales,NSW,-33.3357,151.5034,4
2261,Magenta,New South Wales,NSW,-33.292,151.5469,4
2261,The Entrance,New South Wales,NSW,-33.3388,151.4978,4
2261,Toowoon Bay,New South Wales,NSW,-33.3581,151.4971,4
2261,Berkeley Vale,New South Wales,NSW,-33.35,151.4333,4
2261,Glenning Valley,New South Wales,NSW,-33.3537,151.4262,4
2261,Shelly Beach,New South Wales,NSW,-33.37,151.4852,4
2261,Chittaway Bay,New South Wales,NSW,-33.3277,151.4297,4
2262,Colongra,New South Wales,NSW,-33.2069,151.5436,4
2262,Budgewoi,New South Wales,NSW,-33.2339,151.5541,4
2262,Halekulani,New South Wales,NSW,-33.2191,151.5525,4
2262,Doyalson North,New South Wales,NSW,-33.1873,151.5488,4
2262,San Remo,New South Wales,NSW,-33.2147,151.521,4
2262,Budgewoi Peninsula,New South Wales,NSW,-33.2106,151.5855,4
2262,Buff Point,New South Wales,NSW,-33.2229,151.5312,4
2262,Doyalson,New South Wales,NSW,-33.1963,151.5128,4
2262,Blue Haven,New South Wales,NSW,-33.2111,151.5035,4
2263,Charmhaven,New South Wales,NSW,-33.2267,151.5028,4
2263,Gorokan,New South Wales,NSW,-33.2576,151.5097,4
2263,Norah Head,New South Wales,NSW,-33.2833,151.5667,4
2263,Canton Beach,New South Wales,NSW,-33.2744,151.5461,4
2263,Toukley,New South Wales,NSW,-33.2637,151.5384,4
2263,Noraville,New South Wales,NSW,-33.2679,151.5535,4
2263,Lake Haven,New South Wales,NSW,-33.243,151.5043,4
2264,Yarrawonga Park,New South Wales,NSW,-33.0946,151.5471,4
2264,Myuna Bay,New South Wales,NSW,-33.045,151.5281,4
2264,Dora Creek,New South Wales,NSW,-33.0814,151.4968,4
2264,Silverwater,New South Wales,NSW,-33.104,151.5636,4
2264,Mirrabooka,New South Wales,NSW,-33.111,151.5543,4
2264,Mandalong,New South Wales,NSW,-33.1333,151.4333,4
2264,Balcolyn,New South Wales,NSW,-33.0957,151.552,4
2264,Morisset,New South Wales,NSW,-33.108,151.4871,4
2264,Eraring,New South Wales,NSW,-33.0833,151.5333,4
2264,Sunshine,New South Wales,NSW,-35.7454,150.2079,4
2264,Windermere Park,New South Wales,NSW,-33.121,151.5307,4
2264,Morisset Park,New South Wales,NSW,-33.1198,151.537,4
2264,Bonnells Bay,New South Wales,NSW,-33.1097,151.5323,4
2264,Brightwaters,New South Wales,NSW,-33.1136,151.5447,4
2265,Cooranbong,New South Wales,NSW,-33.0762,151.4541,4
2265,Martinsville,New South Wales,NSW,-33.0667,151.4167,4
2267,Wangi Wangi,New South Wales,NSW,-33.0718,151.5984,4
2278,Barnsley,New South Wales,NSW,-32.9333,151.5833,4
2278,Killingworth,New South Wales,NSW,-32.9341,151.556,4
2278,Wakefield,New South Wales,NSW,-32.9682,151.5609,4
2280,Croudace Bay,New South Wales,NSW,-33.0048,151.6482,4
2280,Marks Point,New South Wales,NSW,-33.0612,151.6472,4
2280,Belmont South,New South Wales,NSW,-33.0493,151.6569,4
2280,Jewells,New South Wales,NSW,-33.0139,151.6831,4
2280,Belmont,New South Wales,NSW,-33.0274,151.6601,4
2280,Valentine,New South Wales,NSW,-33.015,151.6429,4
2280,Floraville,New South Wales,NSW,-33.0112,151.6648,4
2280,Belmont North,New South Wales,NSW,-33.0208,151.6685,4
2281,Blacksmiths,New South Wales,NSW,-33.0717,151.6553,4
2281,Little Pelican,New South Wales,NSW,-33.0823,151.6455,4
2281,Swansea,New South Wales,NSW,-33.0833,151.6167,4
2281,Middle Camp,New South Wales,NSW,-33.15,151.6333,4
2281,Murrays Beach,New South Wales,NSW,-33.1201,151.6297,3
2281,Pinny Beach,New South Wales,NSW,-33.1217,151.639,4
2281,Pelican,New South Wales,NSW,-33.0684,151.6446,4
2281,Swansea Heads,New South Wales,NSW,-33.0965,151.655,4
2281,Caves Beach,New South Wales,NSW,-33.1064,151.644,4
2281,Cams Wharf,New South Wales,NSW,-33.1261,151.6209,4
2281,Nords Wharf,New South Wales,NSW,-33.1313,151.6073,4
2281,Catherine Hill Bay,New South Wales,NSW,-33.1667,151.6333,4
2282,Warners Bay,New South Wales,NSW,-32.9725,151.6527,4
2282,Lakelands,New South Wales,NSW,-32.9619,151.6497,4
2282,Eleebana,New South Wales,NSW,-32.9896,151.6364,4
2283,Buttaba,New South Wales,NSW,-33.0533,151.5787,4
2283,Balmoral,New South Wales,NSW,-33.0667,151.5833,4
2283,Carey Bay,New South Wales,NSW,-33.0266,151.6057,4
2283,Coal Point,New South Wales,NSW,-33.0423,151.6116,4
2283,Fishing Point,New South Wales,NSW,-33.0538,151.5948,4
2283,Rathmines,New South Wales,NSW,-33.0377,151.5844,4
2283,Fassifern,New South Wales,NSW,-32.9833,151.5833,4
2283,Arcadia Vale,New South Wales,NSW,-33.0605,151.5841,4
2283,Kilaben Bay,New South Wales,NSW,-33.024,151.592,4
2283,Ryhope,New South Wales,NSW,-32.9913,151.522,4
2283,Bolton Point,New South Wales,NSW,-33.0018,151.6101,4
2283,Toronto,New South Wales,NSW,-33,151.6,4
2283,Blackalls Park,New South Wales,NSW,-32.9989,151.5802,4
2283,Fennell Bay,New South Wales,NSW,-32.9922,151.6001,4
2283,Awaba,New South Wales,NSW,-33.0082,151.551,4
2284,Teralba,New South Wales,NSW,-32.9667,151.6,4
2284,Argenton,New South Wales,NSW,-32.935,151.6306,4
2284,Marmong Point,New South Wales,NSW,-32.9806,151.6184,4
2284,Boolaroo,New South Wales,NSW,-32.95,151.6167,4
2284,Woodrising,New South Wales,NSW,-32.986,151.6066,4
2284,Speers Point,New South Wales,NSW,-32.9638,151.6262,4
2284,Booragul,New South Wales,NSW,-32.9749,151.6098,4
2285,Macquarie Hills,New South Wales,NSW,-32.9511,151.6446,4
2285,Cardiff South,New South Wales,NSW,-32.9547,151.6645,4
2285,Cameron Park,New South Wales,NSW,-32.907,151.6053,4
2285,Edgeworth,New South Wales,NSW,-32.9251,151.6161,4
2285,Cardiff,New South Wales,NSW,-32.95,151.6667,4
2285,Cardiff Heights,New South Wales,NSW,-32.9363,151.6723,4
2285,Glendale,New South Wales,NSW,-32.9319,151.641,4
2286,West Wallsend,New South Wales,NSW,-32.9,151.5833,4
2286,Seahampton,New South Wales,NSW,-32.8895,151.5832,4
2286,Holmesville,New South Wales,NSW,-32.9167,151.5833,4
2287,Birmingham Gardens,New South Wales,NSW,-32.8937,151.6901,4
2287,Rankin Park,New South Wales,NSW,-32.9252,151.6802,4
2287,Elermore Vale,New South Wales,NSW,-32.9162,151.6766,4
2287,Minmi,New South Wales,NSW,-32.8783,151.6173,4
2287,Maryland,New South Wales,NSW,-32.8789,151.6614,4
2287,Fletcher,New South Wales,NSW,-32.8723,151.6401,4
2287,Wallsend South,New South Wales,NSW,-32.897,151.6562,3
2287,Wallsend,New South Wales,NSW,-32.9167,151.6667,4
2287,Summer Hill,New South Wales,NSW,-33.8915,151.1382,4
2289,Highfields,New South Wales,NSW,-32.9533,151.7123,4
2289,Kotara Fair,New South Wales,NSW,-32.9445,151.7073,3
2289,Kotara,New South Wales,NSW,-32.95,151.6833,4
2289,Kotara South,New South Wales,NSW,-32.95,151.6833,4
2289,Adamstown Heights,New South Wales,NSW,-32.9491,151.7101,4
2289,Garden Suburb,New South Wales,NSW,-32.9437,151.6808,4
2289,Adamstown,New South Wales,NSW,-32.9382,151.7254,4
2290,Charlestown,New South Wales,NSW,-32.95,151.6667,4
2290,Dudley,New South Wales,NSW,-32.9937,151.722,4
2290,Whitebridge,New South Wales,NSW,-32.9833,151.7167,4
2290,Bennetts Green,New South Wales,NSW,-32.9955,151.6879,4
2290,Redhead,New South Wales,NSW,-33.0118,151.7114,4
2290,Mount Hutton,New South Wales,NSW,-32.9833,151.6701,4
2290,Gateshead,New South Wales,NSW,-32.9821,151.6919,4
2290,Kahibah,New South Wales,NSW,-32.9667,151.7167,4
2290,Hillsborough,New South Wales,NSW,-32.9655,151.6724,4
2290,Tingira Heights,New South Wales,NSW,-32.9973,151.6702,4
2291,The Junction,New South Wales,NSW,-32.9382,151.7591,4
2291,Merewether,New South Wales,NSW,-32.948,151.7433,4
2291,Merewether Heights,New South Wales,NSW,-32.9473,151.7356,4
2292,Hamilton North,New South Wales,NSW,-32.9119,151.7368,4
2292,Broadmeadow,New South Wales,NSW,-32.9237,151.7285,4
2293,Maryville,New South Wales,NSW,-32.9112,151.7554,4
2293,Wickham,New South Wales,NSW,-32.9192,151.7569,4
2294,Carrington,New South Wales,NSW,-32.915,151.7644,4
2295,Fern Bay,New South Wales,NSW,-32.8643,151.8131,4
2295,Stockton,New South Wales,NSW,-32.9091,151.7836,4
2296,Islington,New South Wales,NSW,-32.9139,151.7474,4
2297,Tighes Hill,New South Wales,NSW,-32.9073,151.7509,4
2298,Waratah,New South Wales,NSW,-32.9067,151.7265,4
2298,Georgetown,New South Wales,NSW,-32.9081,151.7312,4
2298,Waratah West,New South Wales,NSW,-32.9,151.7117,4
2299,Lambton,New South Wales,NSW,-32.9167,151.7,4
2299,Jesmond,New South Wales,NSW,-32.9028,151.6907,4
2299,North Lambton,New South Wales,NSW,-32.9061,151.7057,4
2300,Newcastle,New South Wales,NSW,-32.9271,151.7765,4
2300,The Hill,New South Wales,NSW,-32.931,151.7754,3
2300,Cooks Hill,New South Wales,NSW,-32.9324,151.7711,4
2300,Newcastle East,New South Wales,NSW,-32.9277,151.7884,4
2300,Bar Beach,New South Wales,NSW,-32.9413,151.7654,4
2302,Newcastle West,New South Wales,NSW,-32.9245,151.7571,4
2303,Hamilton South,New South Wales,NSW,-32.9279,151.7465,3
2303,Hamilton East,New South Wales,NSW,-32.9258,151.7535,4
2303,Hamilton,New South Wales,NSW,-32.9221,151.7471,4
2304,Kooragang,New South Wales,NSW,-32.8843,151.771,4
2304,Warabrook,New South Wales,NSW,-32.8888,151.7149,4
2304,Mayfield,New South Wales,NSW,-32.8979,151.7361,4
2304,Mayfield West,New South Wales,NSW,-32.892,151.7271,4
2304,Sandgate,New South Wales,NSW,-32.8667,151.7,4
2304,Mayfield East,New South Wales,NSW,-32.9003,151.7497,4
2304,Mayfield North,New South Wales,NSW,-32.8928,151.7482,4
2305,New Lambton,New South Wales,NSW,-32.95,151.6833,4
2305,Kotara East,New South Wales,NSW,-32.928,151.7031,3
2305,New Lambton Heights,New South Wales,NSW,-32.9247,151.6936,4
2306,Windale,New South Wales,NSW,-32.9928,151.6817,4
2307,Shortland,New South Wales,NSW,-32.8788,151.691,4
2308,Newcastle University,New South Wales,NSW,-32.892,151.7053,3
2308,Callaghan,New South Wales,NSW,-32.892,151.7053,4
2309,Dangar,New South Wales,NSW,-33.5386,151.2415,4
2310,Hunter Region Mc,New South Wales,NSW,-32.5879,151.8955,1
2311,East Gresford,New South Wales,NSW,-32.4138,151.5565,4
2311,Halton,New South Wales,NSW,-32.3167,151.5167,4
2311,Gresford,New South Wales,NSW,-32.4273,151.5375,4
2311,Bingleburra,New South Wales,NSW,-32.3529,151.5156,3
2311,Mount Rivers,New South Wales,NSW,-32.3652,151.4846,4
2311,Upper Allyn,New South Wales,NSW,-32.3529,151.5156,3
2311,Lostock,New South Wales,NSW,-32.3333,151.45,4
2311,Allynbrook,New South Wales,NSW,-32.3614,151.5364,4
2311,Eccleston,New South Wales,NSW,-32.25,151.5,4
2311,Carrabolla,New South Wales,NSW,-32.3529,151.5156,3
2311,Lewinsbrook,New South Wales,NSW,-32.3529,151.5156,3
2312,Minimbah,New South Wales,NSW,-32.1577,152.3748,4
2312,Nabiac,New South Wales,NSW,-32.0984,152.3763,4
2314,Williamtown Raaf,New South Wales,NSW,-32.5879,151.8955,1
2315,Fingal Bay,New South Wales,NSW,-32.7497,152.171,4
2315,Corlette,New South Wales,NSW,-32.7201,152.1085,4
2315,Shoal Bay,New South Wales,NSW,-32.7223,152.175,4
2315,Nelson Bay,New South Wales,NSW,-32.7204,152.144,4
2316,Taylors Beach,New South Wales,NSW,-32.7488,152.067,4
2316,Fishermans Bay,New South Wales,NSW,-32.7858,152.0896,4
2316,Boat Harbour,New South Wales,NSW,-32.7896,152.1091,4
2316,Anna Bay,New South Wales,NSW,-32.7813,152.0859,4
2316,Bobs Farm,New South Wales,NSW,-32.7667,152.0167,4
2316,One Mile,New South Wales,NSW,-32.77,152.07,3
2317,Soldiers Point,New South Wales,NSW,-32.7105,152.0698,4
2317,Salamander Bay,New South Wales,NSW,-32.7225,152.0794,4
2318,Medowie,New South Wales,NSW,-32.7415,151.8676,4
2318,Salt Ash,New South Wales,NSW,-32.7833,151.9167,4
2318,Ferodale,New South Wales,NSW,-32.7333,151.85,4
2318,Campvale,New South Wales,NSW,-32.7726,151.856,4
2318,Williamtown,New South Wales,NSW,-32.8064,151.8436,4
2318,Oyster Cove,New South Wales,NSW,-32.7387,151.9564,4
2318,Fullerton Cove,New South Wales,NSW,-32.8405,151.8379,4
2319,Tilligerry Creek,New South Wales,NSW,-32.7393,152.0172,3
2319,Tanilba Bay,New South Wales,NSW,-32.7471,151.9971,4
2319,Mallabula,New South Wales,NSW,-32.7282,152.0126,4
2319,Lemon Tree Passage,New South Wales,NSW,-32.7305,152.0386,4
2320,Mindaribba,New South Wales,NSW,-32.7331,151.5279,3
2320,Bolwarra Heights,New South Wales,NSW,-32.701,151.5854,4
2320,Keinbah,New South Wales,NSW,-32.7331,151.5279,3
2320,Allandale,New South Wales,NSW,-32.7196,151.4028,4
2320,Mount Dee,New South Wales,NSW,-32.7331,151.5279,3
2320,South Maitland,New South Wales,NSW,-32.7453,151.5634,4
2320,Anambah,New South Wales,NSW,-32.7331,151.5279,3
2320,Louth Park,New South Wales,NSW,-32.7709,151.5483,4
2320,Farley,New South Wales,NSW,-32.7333,151.5167,4
2320,Lorn,New South Wales,NSW,-32.7333,151.5667,4
2320,Rutherford,New South Wales,NSW,-32.7167,151.5333,4
2320,Largs,New South Wales,NSW,-32.7,151.6,4
2320,Wallalong,New South Wales,NSW,-32.6956,151.6496,4
2320,Horseshoe Bend,New South Wales,NSW,-32.7353,151.5683,4
2320,Maitland Vale,New South Wales,NSW,-32.7331,151.5279,3
2320,Windella,New South Wales,NSW,-32.7033,151.4802,4
2320,Maitland,New South Wales,NSW,-32.7331,151.5574,4
2320,Oakhampton,New South Wales,NSW,-32.7,151.5667,4
2320,Gosforth,New South Wales,NSW,-32.6667,151.4833,4
2320,Telarah,New South Wales,NSW,-32.7333,151.5333,4
2320,Maitland North,New South Wales,NSW,-32.7164,151.5373,3
2320,Bolwarra,New South Wales,NSW,-32.7167,151.5667,4
2320,Pokolbin,New South Wales,NSW,-32.8,151.2833,4
2320,Rosebrook,New South Wales,NSW,-32.65,151.5167,4
2320,Rothbury,New South Wales,NSW,-32.7485,151.3429,4
2320,Aberglasslyn,New South Wales,NSW,-32.7,151.5333,4
2320,Oakhampton Heights,New South Wales,NSW,-32.7331,151.5279,3
2320,Hillsborough,New South Wales,NSW,-32.9655,151.6724,4
2320,Glen Oak,New South Wales,NSW,-32.6167,151.7167,4
2320,Melville,New South Wales,NSW,-32.7331,151.5279,3
2321,Butterwick,New South Wales,NSW,-32.6564,151.6369,4
2321,Duns Creek,New South Wales,NSW,-32.7353,151.622,3
2321,Morpeth,New South Wales,NSW,-32.7333,151.6333,4
2321,Woodville,New South Wales,NSW,-32.6833,151.6167,4
2321,Lochinvar,New South Wales,NSW,-32.6987,151.4552,4
2321,Duckenfield,New South Wales,NSW,-32.7333,151.6833,4
2321,Luskintyre,New South Wales,NSW,-32.6834,151.427,4
2321,Raworth,New South Wales,NSW,-32.7333,151.6167,4
2321,Cliftleigh,New South Wales,NSW,-32.7353,151.622,3
2321,Berry Park,New South Wales,NSW,-32.7333,151.6667,4
2321,Clarence Town,New South Wales,NSW,-32.584,151.7776,4
2321,Glen William,New South Wales,NSW,-32.5333,151.8,4
2321,Oswald,New South Wales,NSW,-32.7062,151.4215,4
2321,Heddon Greta,New South Wales,NSW,-32.802,151.5133,4
2321,Glen Martin,New South Wales,NSW,-32.7353,151.622,3
2321,Gillieston Heights,New South Wales,NSW,-32.7616,151.5286,4
2321,Harpers Hill,New South Wales,NSW,-32.7353,151.622,3
2321,Windermere,New South Wales,NSW,-32.6897,151.4474,4
2321,Phoenix Park,New South Wales,NSW,-32.7353,151.622,3
2321,Hinton,New South Wales,NSW,-32.7147,151.649,4
2322,Hexham,New South Wales,NSW,-32.8293,151.6844,4
2322,Stockrington,New South Wales,NSW,-32.8072,151.6739,3
2322,Chisholm,New South Wales,NSW,-32.8072,151.6739,3
2322,Lenaghan,New South Wales,NSW,-32.8417,151.6359,4
2322,Black Hill,New South Wales,NSW,-32.8377,151.6187,4
2322,Woodberry,New South Wales,NSW,-32.7931,151.6769,4
2322,Tarro,New South Wales,NSW,-32.8,151.6667,4
2322,Beresfield,New South Wales,NSW,-32.8,151.65,4
2322,Thornton,New South Wales,NSW,-32.7833,151.6333,4
2322,Tomago,New South Wales,NSW,-32.827,151.7131,4
2323,East Maitland,New South Wales,NSW,-32.75,151.5833,4
2323,Mulbring,New South Wales,NSW,-32.9048,151.4851,4
2323,Metford,New South Wales,NSW,-32.765,151.6094,4
2323,Ashtonfield,New South Wales,NSW,-32.7733,151.6058,4
2323,Four Mile Creek,New South Wales,NSW,-33.4318,148.9512,4
2323,Buttai,New South Wales,NSW,-32.8219,151.5609,4
2323,Brunkerville,New South Wales,NSW,-32.9425,151.4795,4
2323,Green Hills,New South Wales,NSW,-32.9176,149.5836,4
2323,Metford Dc,New South Wales,NSW,-32.8708,151.5188,3
2323,Pitnacree,New South Wales,NSW,-32.8048,151.5627,3
2323,Freemans Waterhole,New South Wales,NSW,-32.9847,151.4846,4
2323,Buchanan,New South Wales,NSW,-32.8305,151.5312,4
2323,Mount Vincent,New South Wales,NSW,-32.9174,151.4774,3
2323,Tenambit,New South Wales,NSW,-32.7333,151.6167,4
2323,Richmond Vale,New South Wales,NSW,-32.8048,151.5627,3
2324,Limeburners Creek,New South Wales,NSW,-32.6167,151.9167,4
2324,Twelve Mile Creek,New South Wales,NSW,-32.6447,151.865,4
2324,East Seaham,New South Wales,NSW,-32.6478,151.7616,4
2324,Millers Forest,New South Wales,NSW,-32.75,151.7,4
2324,Seaham,New South Wales,NSW,-32.6622,151.7266,4
2324,Tea Gardens,New South Wales,NSW,-32.6635,152.154,4
2324,Eagleton,New South Wales,NSW,-32.707,151.7553,4
2324,Cells River,New South Wales,NSW,-32.6462,151.9955,3
2324,Tahlee,New South Wales,NSW,-32.6667,152,4
2324,Osterley,New South Wales,NSW,-32.7245,151.6998,4
2324,Brandy Hill,New South Wales,NSW,-32.6893,151.696,4
2324,Hawks Nest,New South Wales,NSW,-32.6675,152.1783,4
2324,North Arm Cove,New South Wales,NSW,-32.6706,152.0448,4
2324,Raymond Terrace East,New South Wales,NSW,-32.6462,151.9955,3
2324,Pindimar,New South Wales,NSW,-32.6828,152.0981,4
2324,Carrington,New South Wales,NSW,-32.915,151.7644,4
2324,Heatherbrae,New South Wales,NSW,-32.7889,151.7354,4
2324,Bundabah,New South Wales,NSW,-32.6462,151.9955,3
2324,Nelsons Plains,New South Wales,NSW,-32.7205,151.7173,4
2324,Swan Bay,New South Wales,NSW,-32.6918,151.9289,4
2324,Balickera,New South Wales,NSW,-32.6589,151.8119,4
2324,Raymond Terrace,New South Wales,NSW,-32.75,151.75,4
2324,Karuah,New South Wales,NSW,-32.6539,151.9604,4
2325,Paxton,New South Wales,NSW,-32.9061,151.2849,4
2325,Quorrobolong,New South Wales,NSW,-32.9225,151.364,3
2325,Wollombi,New South Wales,NSW,-32.9388,151.1415,4
2325,Cessnock West,New South Wales,NSW,-32.9386,151.2758,3
2325,Kearsley,New South Wales,NSW,-32.8586,151.3957,3
2325,Cessnock,New South Wales,NSW,-32.8321,151.3562,4
2325,Kitchener,New South Wales,NSW,-32.8813,151.3671,4
2325,Ellalong,New South Wales,NSW,-32.9151,151.3116,4
2325,Bellbird,New South Wales,NSW,-32.8599,151.3187,4
2325,Mount View,New South Wales,NSW,-32.85,151.2833,4
2325,Elrington,New South Wales,NSW,-35.531,149.6453,4
2325,Pelton,New South Wales,NSW,-32.8783,151.3109,4
2325,Nulkaba,New South Wales,NSW,-32.8103,151.3483,4
2325,Lovedale,New South Wales,NSW,-32.7705,151.3648,4
2325,Laguna,New South Wales,NSW,-32.9902,151.1346,4
2325,Abernethy,New South Wales,NSW,-32.9,151.4,4
2325,Millfield,New South Wales,NSW,-32.8886,151.2613,4
2325,Greta Main,New South Wales,NSW,-32.88,151.3082,3
2325,Sweetmans Creek,New South Wales,NSW,-32.88,151.3082,3
2325,Olney,New South Wales,NSW,-32.88,151.3082,3
2325,Paynes Crossing,New South Wales,NSW,-32.9386,151.2758,3
2325,Cedar Creek,New South Wales,NSW,-32.8552,151.2186,4
2325,Congewai,New South Wales,NSW,-32.9386,151.2758,3
2325,Moruben,New South Wales,NSW,-32.88,151.3082,3
2325,Corrabare,New South Wales,NSW,-32.88,151.3082,3
2325,Aberdare,New South Wales,NSW,-32.8333,151.3667,4
2325,Bellbird Heights,New South Wales,NSW,-32.8504,151.329,4
2326,Neath,New South Wales,NSW,-32.8276,151.4082,4
2326,Abermain,New South Wales,NSW,-32.8074,151.4275,4
2326,Bishops Bridge,New South Wales,NSW,-32.7455,151.4689,4
2326,Weston,New South Wales,NSW,-32.811,151.4366,4
2326,Loxford,New South Wales,NSW,-32.791,151.4554,3
2326,Sawyers Gully,New South Wales,NSW,-32.8002,151.4368,3
2327,Stanford Merthyr,New South Wales,NSW,-32.8247,151.4936,4
2327,Pelaw Main,New South Wales,NSW,-32.8378,151.4815,4
2327,Kurri Kurri,New South Wales,NSW,-32.8193,151.4791,4
2328,Denman,New South Wales,NSW,-32.3879,150.6893,4
2328,Widden,New South Wales,NSW,-32.5167,150.3667,4
2328,Kerrabee,New South Wales,NSW,-32.4167,150.3,4
2328,Martindale,New South Wales,NSW,-32.4404,150.452,3
2328,Bureen,New South Wales,NSW,-32.4404,150.452,3
2328,Hollydeen,New South Wales,NSW,-32.5658,150.5176,3
2328,Giants Creek,New South Wales,NSW,-32.4404,150.452,3
2328,Yarrawa,New South Wales,NSW,-32.4404,150.452,3
2328,Dalswinton,New South Wales,NSW,-32.4404,150.452,3
2328,Mangoola,New South Wales,NSW,-32.4404,150.452,3
2329,Cassilis,New South Wales,NSW,-32,149.9833,4
2329,Borambil,New South Wales,NSW,-32.0333,150,4
2329,Uarbry,New South Wales,NSW,-32.0485,149.7679,4
2329,Merriwa,New South Wales,NSW,-32.1392,150.3556,4
2330,Ravensworth,New South Wales,NSW,-32.45,151.0667,4
2330,Gowrie,New South Wales,NSW,-31.35,150.85,4
2330,Glenridding,New South Wales,NSW,-32.6686,151.0454,3
2330,Howick,New South Wales,NSW,-32.6686,151.0454,3
2330,Warkworth,New South Wales,NSW,-32.5833,151.0333,4
2330,Glendon Brook,New South Wales,NSW,-32.5207,151.3346,4
2330,Scotts Flat,New South Wales,NSW,-32.6686,151.0454,3
2330,Hunterview,New South Wales,NSW,-32.5434,151.1772,4
2330,Putty,New South Wales,NSW,-32.9652,150.6679,4
2330,Dunolly,New South Wales,NSW,-32.6686,151.0454,3
2330,Gouldsville,New South Wales,NSW,-32.6686,151.0454,3
2330,Milbrodale,New South Wales,NSW,-32.6686,151.0454,3
2330,Long Point,New South Wales,NSW,-34.0167,150.9,4
2330,Goorangoola,New South Wales,NSW,-32.6686,151.0454,3
2330,Mitchells Flat,New South Wales,NSW,-32.5485,151.2846,4
2330,Redbournberry,New South Wales,NSW,-32.6686,151.0454,3
2330,Whittingham,New South Wales,NSW,-32.6167,151.2,4
2330,Big Ridge,New South Wales,NSW,-32.6686,151.0454,3
2330,Doyles Creek,New South Wales,NSW,-32.5485,150.7846,4
2330,Mirannie,New South Wales,NSW,-32.6993,150.95,3
2330,Glendon,New South Wales,NSW,-29.5794,150.4082,4
2330,Hebden,New South Wales,NSW,-32.3833,151.0667,4
2330,Wollemi,New South Wales,NSW,-33.044,150.6799,4
2330,Darlington,New South Wales,NSW,-33.891,151.1955,4
2330,Sedgefield,New South Wales,NSW,-32.5402,151.2512,4
2330,Mount Thorley,New South Wales,NSW,-32.6686,151.0454,3
2330,Bowmans Creek,New South Wales,NSW,-32.2652,151.1346,4
2330,Mount Olive,New South Wales,NSW,-32.6993,150.95,3
2330,Maison Dieu,New South Wales,NSW,-32.5378,151.0902,4
2330,Appletree Flat,New South Wales,NSW,-32.6686,151.0454,3
2330,Camberwell,New South Wales,NSW,-32.4833,151.0833,4
2330,Bridgman,New South Wales,NSW,-32.4543,151.1882,4
2330,Singleton,New South Wales,NSW,-32.5674,151.166,4
2330,Combo,New South Wales,NSW,-32.6686,151.0454,3
2330,Middle Falbrook,New South Wales,NSW,-32.6686,151.0454,3
2330,Westbrook,New South Wales,NSW,-32.6686,151.0454,3
2330,Mount Royal,New South Wales,NSW,-32.2075,151.2993,4
2330,Obanvale,New South Wales,NSW,-32.6686,151.0454,3
2330,Reedy Creek,New South Wales,NSW,-32.6993,150.95,3
2330,Jerrys Plains,New South Wales,NSW,-32.5,150.9167,4
2330,Big Yengo,New South Wales,NSW,-32.8931,150.8457,4
2330,Lemington,New South Wales,NSW,-32.6686,151.0454,3
2330,Fern Gully,New South Wales,NSW,-32.6686,151.0454,3
2330,Garland Valley,New South Wales,NSW,-32.6686,151.0454,3
2330,Fordwich,New South Wales,NSW,-32.6686,151.0454,3
2330,Rixs Creek,New South Wales,NSW,-32.6686,151.0454,3
2330,Hambledon Hill,New South Wales,NSW,-32.6686,151.0454,3
2330,Howes Valley,New South Wales,NSW,-32.8485,150.8512,4
2330,St Clair,New South Wales,NSW,-33.7973,150.7847,4
2330,Dyrring,New South Wales,NSW,-32.6686,151.0454,3
2330,Clydesdale,New South Wales,NSW,-32.6686,151.0454,3
2330,Wattle Ponds,New South Wales,NSW,-32.6686,151.0454,3
2330,Dural,New South Wales,NSW,-33.6833,151.0167,4
2330,Roughit,New South Wales,NSW,-32.6686,151.0454,3
2330,Falbrook,New South Wales,NSW,-32.6686,151.0454,3
2330,Wylies Flat,New South Wales,NSW,-32.6686,151.0454,3
2330,Bulga,New South Wales,NSW,-32.6667,151.0167,4
2330,Mcdougalls Hill,New South Wales,NSW,-32.6686,151.0454,3
2330,Broke,New South Wales,NSW,-32.7512,151.1045,4
2330,Greenlands,New South Wales,NSW,-32.6993,150.95,3
2330,Glennies Creek,New South Wales,NSW,-32.45,151.1167,4
2330,Singleton Heights,New South Wales,NSW,-32.6686,151.0454,3
2330,Carrowbrook,New South Wales,NSW,-32.6993,150.95,3
2331,Singleton Milpo,New South Wales,NSW,-32.6895,151.1897,1
2331,Singleton Military Area,New South Wales,NSW,-32.3281,150.986,1
2333,Baerami Creek,New South Wales,NSW,-32.5,150.4667,4
2333,Mccullys Gap,New South Wales,NSW,-32.2966,150.7584,3
2333,Liddell,New South Wales,NSW,-32.4167,151.0333,4
2333,Edderton,New South Wales,NSW,-32.2966,150.7584,3
2333,Sandy Hollow,New South Wales,NSW,-32.3333,150.5667,4
2333,Castle Rock,New South Wales,NSW,-32.3306,150.68,3
2333,Muscle Creek,New South Wales,NSW,-32.2704,150.9978,3
2333,Gungal,New South Wales,NSW,-32.2652,150.4846,4
2333,Kayuga,New South Wales,NSW,-32.2,150.8667,4
2333,Bengalla,New South Wales,NSW,-32.3306,150.68,3
2333,Baerami,New South Wales,NSW,-32.3818,150.4679,4
2333,Manobalai,New South Wales,NSW,-32.1896,150.676,4
2333,Muswellbrook,New South Wales,NSW,-32.2612,150.8901,4
2333,Wybong,New South Wales,NSW,-32.2667,150.6333,4
2334,Greta,New South Wales,NSW,-32.6778,151.3892,4
2335,Leconfield,New South Wales,NSW,-33.0131,151.1701,3
2335,Elderslie,New South Wales,NSW,-34.05,150.7,4
2335,Stanhope,New South Wales,NSW,-33.7164,150.9261,4
2335,North Rothbury,New South Wales,NSW,-32.6934,151.3438,4
2335,Lower Belford,New South Wales,NSW,-33.0131,151.1701,3
2335,East Branxton,New South Wales,NSW,-33.0131,151.1701,3
2335,Dalwood,New South Wales,NSW,-32.6484,151.3046,3
2335,Lambs Valley,New South Wales,NSW,-33.0131,151.1701,3
2335,Belford,New South Wales,NSW,-32.6667,151.2833,4
2335,Branxton,New South Wales,NSW,-32.6555,151.3511,4
2336,Upper Rouchel,New South Wales,NSW,-32.1167,151.0833,4
2336,Rouchel,New South Wales,NSW,-32.1167,151.0833,4
2336,Dartbrook,New South Wales,NSW,-32.1568,150.8063,4
2336,Upper Dartbrook,New South Wales,NSW,-32.1568,150.8063,4
2336,Aberdeen,New South Wales,NSW,-32.1659,150.89,4
2336,Rouchel Brook,New South Wales,NSW,-32.1254,151.0481,3
2336,Davis Creek,New South Wales,NSW,-32.1023,151.1358,3
2336,Rossgole,New South Wales,NSW,-32.1254,151.0481,3
2337,Stewarts Brook,New South Wales,NSW,-31.9985,151.3179,4
2337,Owens Gap,New South Wales,NSW,-32.05,150.7,4
2337,Moonan Flat,New South Wales,NSW,-31.9167,151.25,4
2337,Ellerston,New South Wales,NSW,-31.8167,151.3167,4
2337,Satur,New South Wales,NSW,-32.041,150.8416,4
2337,Wingen,New South Wales,NSW,-31.8833,150.8833,4
2337,Moonan Brook,New South Wales,NSW,-31.9415,151.2792,4
2337,Moobi,New South Wales,NSW,-31.9573,151.0389,3
2337,Glenrock,New South Wales,NSW,-31.6652,151.4179,4
2337,Segenhoe,New South Wales,NSW,-32.1167,150.9167,4
2337,Scone,New South Wales,NSW,-32.0501,150.8689,4
2337,Glenbawn,New South Wales,NSW,-31.9573,151.0389,3
2337,Tomalla,New South Wales,NSW,-31.85,151.4667,4
2337,Gundy,New South Wales,NSW,-32.0167,151,4
2337,Belltrees,New South Wales,NSW,-31.9906,151.1232,4
2337,Murulla,New South Wales,NSW,-31.9573,151.0389,3
2337,Bunnan,New South Wales,NSW,-32.05,150.5833,4
2337,Kars Springs,New South Wales,NSW,-31.9,150.5667,4
2337,Parkville,New South Wales,NSW,-31.9809,150.8628,4
2337,Middle Brook,New South Wales,NSW,-31.9573,151.0389,3
2337,Pages Creek,New South Wales,NSW,-31.9573,151.0389,3
2337,Woolooma,New South Wales,NSW,-32,151.25,4
2337,Omadale,New South Wales,NSW,-31.9573,151.0389,3
2337,Waverly,New South Wales,NSW,-31.9573,151.0389,3
2337,Brawboy,New South Wales,NSW,-31.9573,151.0389,3
2337,Dry Creek,New South Wales,NSW,-31.9573,151.0389,3
2338,Timor,New South Wales,NSW,-31.7833,151.0833,4
2338,Ardglen,New South Wales,NSW,-31.7358,150.7484,4
2338,Pages River,New South Wales,NSW,-31.7744,150.9006,3
2338,Murrurundi,New South Wales,NSW,-31.7642,150.8358,4
2338,Green Creek,New South Wales,NSW,-31.7744,150.9006,3
2338,Blandford,New South Wales,NSW,-31.7849,150.8935,4
2338,Crawney,New South Wales,NSW,-31.7744,150.9006,3
2338,Sandy Creek,New South Wales,NSW,-31.7744,150.9006,3
2338,Scotts Creek,New South Wales,NSW,-31.7744,150.9006,3
2339,Warrah Creek,New South Wales,NSW,-31.7152,150.6512,4
2339,Cattle Creek,New South Wales,NSW,-31.6778,150.6778,3
2339,Braefield,New South Wales,NSW,-31.5707,150.7018,4
2339,Little Jacks Creek,New South Wales,NSW,-31.6778,150.6778,3
2339,Willow Tree,New South Wales,NSW,-31.6495,150.7264,4
2339,Macdonalds Creek,New South Wales,NSW,-31.6778,150.6778,3
2339,Warrah,New South Wales,NSW,-31.65,150.65,4
2339,Parraweena,New South Wales,NSW,-31.6778,150.6778,3
2339,Big Jacks Creek,New South Wales,NSW,-31.6778,150.6778,3
2339,Chilcotts Creek,New South Wales,NSW,-31.6778,150.6778,3
2340,Loomberah,New South Wales,NSW,-31.1833,151,4
2340,Daruka,New South Wales,NSW,-31.0373,150.9707,4
2340,Somerton,New South Wales,NSW,-30.9411,150.6383,4
2340,Hillvue,New South Wales,NSW,-31.1276,150.9071,4
2340,Moore Creek,New South Wales,NSW,-31.1512,150.9383,3
2340,Garoo,New South Wales,NSW,-31.1512,150.9383,3
2340,Hanging Rock,New South Wales,NSW,-31.4822,151.1921,4
2340,Appleby,New South Wales,NSW,-30.9667,150.85,4
2340,Westdale,New South Wales,NSW,-31.0905,150.8568,4
2340,Gowrie,New South Wales,NSW,-31.35,150.85,4
2340,Goonoo Goonoo,New South Wales,NSW,-31.3079,150.9027,4
2340,South Tamworth,New South Wales,NSW,-31.11,150.9225,4
2340,Nundle,New South Wales,NSW,-31.465,151.1264,4
2340,Calala,New South Wales,NSW,-31.1736,150.9806,4
2340,Carroll,New South Wales,NSW,-30.9894,150.4442,4
2340,Bithramere,New South Wales,NSW,-31.1357,150.7811,4
2340,Woolomin,New South Wales,NSW,-31.305,151.1502,4
2340,Tamworth South,New South Wales,NSW,-31.2744,151.0275,3
2340,Gidley,New South Wales,NSW,-31.0119,150.8387,4
2340,Keepit,New South Wales,NSW,-30.8333,150.5167,4
2340,Barry,New South Wales,NSW,-31.5817,151.3183,4
2340,Oxley Vale,New South Wales,NSW,-31.0632,150.9006,4
2340,Duncans Creek,New South Wales,NSW,-31.3667,151.2,4
2340,Weabonga,New South Wales,NSW,-31.2167,151.3167,4
2340,Warral,New South Wales,NSW,-31.1637,150.8567,4
2340,Ogunbil,New South Wales,NSW,-31.1512,150.9383,3
2340,North Tamworth,New South Wales,NSW,-31.0786,150.9222,4
2340,Kingswood,New South Wales,NSW,-33.75,150.75,4
2340,Dungowan,New South Wales,NSW,-31.2152,151.1179,4
2340,Wallamore,New South Wales,NSW,-31.1512,150.9383,3
2340,Bective,New South Wales,NSW,-31.0042,150.7269,4
2340,Hallsville,New South Wales,NSW,-31.0112,150.874,4
2340,Piallamore,New South Wales,NSW,-31.1659,151.0576,4
2340,Taminda,New South Wales,NSW,-31.0941,150.8962,4
2340,West Tamworth,New South Wales,NSW,-31.1024,150.9145,4
2340,Nemingha,New South Wales,NSW,-31.1237,150.9892,4
2340,Timbumburi,New South Wales,NSW,-31.1512,150.9383,3
2340,Bowling Alley Point,New South Wales,NSW,-31.3956,151.1397,4
2340,East Tamworth,New South Wales,NSW,-31.0855,150.9372,4
2340,Tamworth,New South Wales,NSW,-31.0905,150.929,4
2341,Werris Creek,New South Wales,NSW,-31.3491,150.6487,4
2342,Piallaway,New South Wales,NSW,-31.1667,150.5833,4
2342,Currabubula,New South Wales,NSW,-31.2667,150.7333,4
2343,Coomoo Coomoo,New South Wales,NSW,-31.5553,150.3414,3
2343,Warrah Ridge,New South Wales,NSW,-31.5667,150.55,4
2343,Spring Ridge,New South Wales,NSW,-31.4,150.25,4
2343,Quirindi,New South Wales,NSW,-31.5076,150.679,4
2343,Pine Ridge,New South Wales,NSW,-31.499,150.4539,4
2343,Blackville,New South Wales,NSW,-31.6438,150.2361,4
2343,Colly Blue,New South Wales,NSW,-31.4592,150.1458,4
2343,Yarraman,New South Wales,NSW,-31.6167,150.2,4
2343,Wallabadah,New South Wales,NSW,-31.5425,150.8282,4
2343,Caroona,New South Wales,NSW,-31.4066,150.4264,4
2343,Borambil,New South Wales,NSW,-31.5071,150.6419,4
2343,Bundella,New South Wales,NSW,-31.5664,149.9929,4
2343,Windy,New South Wales,NSW,-31.5976,150.3836,4
2343,Yannergee,New South Wales,NSW,-31.4437,150.0303,4
2343,Quipolly,New South Wales,NSW,-31.4284,150.6564,4
2344,Duri,New South Wales,NSW,-31.2141,150.8219,4
2344,Winton,New South Wales,NSW,-31.0818,150.7179,4
2345,Garthowen,New South Wales,NSW,-30.8951,150.7835,3
2345,Attunga,New South Wales,NSW,-30.9303,150.8468,4
2346,Wongo Creek,New South Wales,NSW,-30.7,150.5,4
2346,Borah Creek,New South Wales,NSW,-30.7295,150.6539,3
2346,Manilla,New South Wales,NSW,-30.7475,150.7197,4
2346,Halls Creek,New South Wales,NSW,-30.7295,150.6539,3
2346,Klori,New South Wales,NSW,-30.8318,150.7679,4
2346,Namoi River,New South Wales,NSW,-30.7295,150.6539,3
2346,Upper Manilla,New South Wales,NSW,-30.6385,150.6635,4
2346,Wimborne,New South Wales,NSW,-30.7295,150.6539,3
2346,New Mexico,New South Wales,NSW,-30.7424,150.6185,4
2346,Rushes Creek,New South Wales,NSW,-30.7295,150.6539,3
2346,Warrabah,New South Wales,NSW,-30.7295,150.6539,3
2347,Thirldene,New South Wales,NSW,-30.2297,150.5773,3
2347,Woodsreef,New South Wales,NSW,-30.3818,150.7346,4
2347,Cobbadah,New South Wales,NSW,-30.2286,150.5823,4
2347,Banoon,New South Wales,NSW,-30.2297,150.5773,3
2347,Red Hill,New South Wales,NSW,-35.0523,149.1643,3
2347,Barraba,New South Wales,NSW,-30.3855,150.609,4
2347,Gundamulda,New South Wales,NSW,-30.2297,150.5773,3
2347,Gulf Creek,New South Wales,NSW,-30.1985,150.7012,4
2347,Mayvale,New South Wales,NSW,-30.2297,150.5773,3
2347,Upper Horton,New South Wales,NSW,-30.1333,150.4333,4
2347,Ironbark,New South Wales,NSW,-30.2297,150.5773,3
2347,Lindesay,New South Wales,NSW,-30.2297,150.5773,3
2347,Longarm,New South Wales,NSW,-30.2297,150.5773,3
2348,New England Mc,New South Wales,NSW,-31.191,150.7174,1
2350,Hillgrove,New South Wales,NSW,-30.5712,151.9045,4
2350,Tilbuster,New South Wales,NSW,-30.4333,151.6667,4
2350,Dangarsleigh,New South Wales,NSW,-30.6055,151.6898,4
2350,Lyndhurst,New South Wales,NSW,-30.3167,152.05,4
2350,Castle Doyle,New South Wales,NSW,-30.6152,151.7679,4
2350,Duval,New South Wales,NSW,-30.461,151.7789,3
2350,Wongwibinda,New South Wales,NSW,-30.2928,152.1661,4
2350,Abington,New South Wales,NSW,-30.2833,151.1833,4
2350,Dumaresq,New South Wales,NSW,-30.4667,151.5833,4
2350,Aberfoyle,New South Wales,NSW,-30.2667,152.0167,4
2350,Saumarez,New South Wales,NSW,-30.5546,151.5837,4
2350,East Armidale,New South Wales,NSW,-30.506,151.9832,3
2350,Boorolong,New South Wales,NSW,-30.3235,151.5346,4
2350,Wollomombi,New South Wales,NSW,-30.5143,152.0435,4
2350,Kellys Plains,New South Wales,NSW,-30.5712,151.6436,4
2350,Jeogla,New South Wales,NSW,-30.5765,152.1184,4
2350,Enmore,New South Wales,NSW,-30.7333,151.8,4
2350,Wards Mistake,New South Wales,NSW,-30.1318,152.0096,4
2350,Armidale,New South Wales,NSW,-30.5083,151.6712,4
2350,Saumarez Ponds,New South Wales,NSW,-30.461,151.7789,3
2350,Invergowrie,New South Wales,NSW,-30.512,151.507,4
2350,Thalgarrah,New South Wales,NSW,-30.461,151.7789,3
2350,West Armidale,New South Wales,NSW,-30.506,151.9832,3
2350,Puddledock,New South Wales,NSW,-30.3667,151.7667,4
2350,Donald Creek,New South Wales,NSW,-30.461,151.7789,3
2350,Argyle,New South Wales,NSW,-30.461,151.7789,3
2350,Metz,New South Wales,NSW,-30.5785,151.8735,4
2351,University Of New England,New South Wales,NSW,-30.85,151.0635,1
2352,Limbri,New South Wales,NSW,-31.0388,151.1553,4
2352,Mulla Creek,New South Wales,NSW,-31.0695,151.0734,3
2352,Kootingal,New South Wales,NSW,-31.0586,151.0535,4
2352,Tintinhull,New South Wales,NSW,-31.0925,151.0202,4
2353,Moonbi,New South Wales,NSW,-31.0177,151.0706,4
2354,Woolbrook,New South Wales,NSW,-30.9652,151.3512,4
2354,Niangala,New South Wales,NSW,-31.3001,151.4049,4
2354,Kentucky,New South Wales,NSW,-30.7614,151.4489,4
2354,Walcha,New South Wales,NSW,-30.9849,151.5934,4
2354,Nowendoc,New South Wales,NSW,-31.5175,151.7148,4
2354,Wollun,New South Wales,NSW,-30.8464,151.4263,4
2354,Yarrowitch,New South Wales,NSW,-31.2701,151.971,4
2354,Walcha Road,New South Wales,NSW,-30.9441,151.4,4
2354,Kentucky South,New South Wales,NSW,-30.7973,151.4401,4
2355,Watsons Creek,New South Wales,NSW,-30.7384,151.0513,4
2355,Retreat,New South Wales,NSW,-30.6068,151.1068,4
2355,Bendemeer,New South Wales,NSW,-30.8865,151.1535,4
2356,Gwabegar,New South Wales,NSW,-30.6101,148.9675,4
2357,Coonabarabran,New South Wales,NSW,-31.2773,149.279,4
2357,Ulamambri,New South Wales,NSW,-31.328,149.4007,4
2357,Gowang,New South Wales,NSW,-31.4235,149.0846,4
2357,Wattle Springs,New South Wales,NSW,-31.2332,149.2463,3
2357,Purlewaugh,New South Wales,NSW,-31.346,149.5092,4
2357,Box Ridge,New South Wales,NSW,-31.25,148.8167,4
2357,Dandry,New South Wales,NSW,-31.0277,149.4043,4
2357,Rocky Glen,New South Wales,NSW,-31.1167,149.5667,4
2357,Tannabar,New South Wales,NSW,-31.2332,149.2463,3
2357,Bugaldie,New South Wales,NSW,-31.1252,149.1116,4
2357,Bomera,New South Wales,NSW,-31.2332,149.2463,3
2358,Gostwyck,New South Wales,NSW,-30.6941,151.5834,4
2358,Arding,New South Wales,NSW,-30.5677,151.5254,4
2358,Yarrowyck,New South Wales,NSW,-30.4639,151.3222,4
2358,Enmore,New South Wales,NSW,-30.7333,151.8,4
2358,Rocky River,New South Wales,NSW,-30.6167,151.5,4
2358,Torryburn,New South Wales,NSW,-30.45,151.2167,4
2358,Balala,New South Wales,NSW,-30.6046,151.4612,3
2358,Salisbury Plains,New South Wales,NSW,-30.7667,151.5333,4
2358,Mihi,New South Wales,NSW,-30.6046,151.4612,3
2358,Kingstown,New South Wales,NSW,-30.504,151.1139,4
2358,Uralla,New South Wales,NSW,-30.6411,151.5007,4
2359,Bakers Creek,New South Wales,NSW,-30.2667,151.0167,4
2359,Bundarra,New South Wales,NSW,-30.1721,151.0762,4
2359,Aberdeen,New South Wales,NSW,-32.1659,150.89,4
2359,Camerons Creek,New South Wales,NSW,-30.2194,151.0464,3
2360,Wandera,New South Wales,NSW,-29.6833,151.1667,4
2360,Copeton,New South Wales,NSW,-29.9167,151.0167,4
2360,Mount Russell,New South Wales,NSW,-29.6772,150.9286,4
2360,Little Plain,New South Wales,NSW,-29.7303,150.955,4
2360,Auburn Vale,New South Wales,NSW,-29.821,151.0454,4
2360,Rob Roy,New South Wales,NSW,-29.75,151.0167,4
2360,Cherry Tree Hill,New South Wales,NSW,-29.5368,150.9946,4
2360,Stanborough,New South Wales,NSW,-29.95,151.15,4
2360,Sapphire,New South Wales,NSW,-29.6846,151.3375,4
2360,Swanbrook,New South Wales,NSW,-29.718,151.0965,3
2360,Inverell,New South Wales,NSW,-29.774,151.1117,4
2360,Bukkulla,New South Wales,NSW,-29.5045,151.1293,4
2360,Howell,New South Wales,NSW,-29.95,151.0333,4
2360,Spring Mountain,New South Wales,NSW,-29.718,151.0965,3
2360,Gilgai,New South Wales,NSW,-29.8527,151.1181,4
2360,Nullamanna,New South Wales,NSW,-29.65,151.2167,4
2360,Brodies Plains,New South Wales,NSW,-29.8094,151.1956,4
2360,Long Plain,New South Wales,NSW,-29.718,151.0965,3
2360,Woodstock,New South Wales,NSW,-29.718,151.0965,3
2360,Wallangra,New South Wales,NSW,-29.2468,150.8931,4
2360,Oakwood,New South Wales,NSW,-29.6458,151.0442,4
2360,Kings Plains,New South Wales,NSW,-29.718,151.0965,3
2360,Newstead,New South Wales,NSW,-29.8333,151.35,4
2360,Paradise,New South Wales,NSW,-29.718,151.0965,3
2360,Elsmore,New South Wales,NSW,-29.8065,151.2689,4
2360,Gum Flat,New South Wales,NSW,-29.7943,150.9296,3
2360,Graman,New South Wales,NSW,-29.4694,150.9267,4
2361,Ashford,New South Wales,NSW,-29.3219,151.0931,4
2361,Atholwood,New South Wales,NSW,-28.9985,151.0512,4
2361,Limestone,New South Wales,NSW,-29.1295,151.1366,3
2361,Bonshaw,New South Wales,NSW,-29.0479,151.2757,4
2361,Pindaroi,New South Wales,NSW,-29.1295,151.1366,3
2365,Oban,New South Wales,NSW,-30.1333,151.8667,4
2365,Brushy Creek,New South Wales,NSW,-30.1052,151.7015,3
2365,Tubbamurra,New South Wales,NSW,-30.1152,151.7679,4
2365,Glencoe,New South Wales,NSW,-29.9249,151.7223,4
2365,Brockley,New South Wales,NSW,-30.2833,151.8167,4
2365,Ben Lomond,New South Wales,NSW,-30.0167,151.6667,4
2365,Georges Creek,New South Wales,NSW,-30.7461,152.1945,4
2365,Guyra,New South Wales,NSW,-30.2167,151.6744,4
2365,The Gulf,New South Wales,NSW,-29.3167,151.4833,4
2365,Falconer,New South Wales,NSW,-30.1052,151.7015,3
2365,Llangothlin,New South Wales,NSW,-30.1372,151.6843,4
2365,Black Mountain,New South Wales,NSW,-30.3107,151.6582,4
2365,Baldersleigh,New South Wales,NSW,-30.2555,151.426,4
2365,Bald Blair,New South Wales,NSW,-30.1052,151.7015,3
2365,Mount Mitchell,New South Wales,NSW,-30.0167,151.85,4
2365,Wandsworth,New South Wales,NSW,-30.0671,151.52,4
2365,Maybole,New South Wales,NSW,-29.8985,151.5846,4
2365,South Guyra,New South Wales,NSW,-30.1052,151.7015,3
2365,Backwater,New South Wales,NSW,-30.0652,151.8846,4
2365,Tenterden,New South Wales,NSW,-30.1318,151.4346,4
2365,Green Hills,New South Wales,NSW,-32.9176,149.5836,4
2365,Briarbrook,New South Wales,NSW,-30.1052,151.7015,3
2365,Bassendean,New South Wales,NSW,-30.1052,151.7015,3
2365,Glen Nevis,New South Wales,NSW,-30.1052,151.7015,3
2365,The Basin,New South Wales,NSW,-30.1052,151.7015,3
2365,New Valley,New South Wales,NSW,-30.1052,151.7015,3
2369,Tingha,New South Wales,NSW,-29.9559,151.2125,4
2369,Old Mill,New South Wales,NSW,-29.9196,151.2146,3
2369,Stannifer,New South Wales,NSW,-29.8833,151.2167,4
2370,Dundee,New South Wales,NSW,-29.5724,151.8663,4
2370,Moggs Swamp,New South Wales,NSW,-29.7311,151.8242,3
2370,Shannon Vale,New South Wales,NSW,-29.6985,151.8679,4
2370,Glen Innes,New South Wales,NSW,-29.7349,151.7385,4
2370,Wellingrove,New South Wales,NSW,-29.6334,151.5692,4
2370,Morven,New South Wales,NSW,-29.7311,151.8242,3
2370,Glen Elgin,New South Wales,NSW,-29.5833,152.1167,4
2370,Kingsland,New South Wales,NSW,-29.7311,151.8242,3
2370,Stonehenge,New South Wales,NSW,-29.8164,151.7351,4
2370,Diehard,New South Wales,NSW,-29.7311,151.8242,3
2370,Reddestone,New South Wales,NSW,-29.7311,151.8242,3
2370,Pinkett,New South Wales,NSW,-29.7311,151.8242,3
2370,Matheson,New South Wales,NSW,-29.7383,151.574,4
2370,Bald Nob,New South Wales,NSW,-29.7311,151.8242,3
2370,Swan Vale,New South Wales,NSW,-29.7833,151.4333,4
2370,Gibraltar Range,New South Wales,NSW,-29.7311,151.8242,3
2370,Newton Boyd,New South Wales,NSW,-29.7976,152.2659,4
2370,Lambs Valley,New South Wales,NSW,-29.7311,151.8242,3
2370,Kookabookra,New South Wales,NSW,-30.0333,152.0667,4
2370,Yarrowford,New South Wales,NSW,-29.7311,151.8242,3
2370,Moogem,New South Wales,NSW,-29.7311,151.8242,3
2370,Furracabad,New South Wales,NSW,-29.7818,151.6679,4
2370,Kingsgate,New South Wales,NSW,-29.8,151.9833,4
2370,Red Range,New South Wales,NSW,-29.7757,151.8966,4
2370,Rangers Valley,New South Wales,NSW,-29.5167,151.75,4
2371,Rocky Creek,New South Wales,NSW,-29.3,151.3667,4
2371,Yellow Dam,New South Wales,NSW,-29.3724,151.6768,3
2371,Capoompeta,New South Wales,NSW,-29.3724,151.6768,3
2371,Emmaville,New South Wales,NSW,-29.4441,151.5979,4
2371,Stannum,New South Wales,NSW,-29.3279,151.7869,4
2371,Deepwater,New South Wales,NSW,-29.4404,151.8461,4
2371,Wellington Vale,New South Wales,NSW,-29.4152,151.7512,4
2371,Torrington,New South Wales,NSW,-29.3152,151.691,4
2372,Silent Grove,New South Wales,NSW,-29.1167,151.6667,4
2372,Woodside,New South Wales,NSW,-29.1538,151.9669,3
2372,Liston,New South Wales,NSW,-28.6492,152.087,4
2372,Bolivia,New South Wales,NSW,-29.3015,151.9539,4
2372,Sandy Hill,New South Wales,NSW,-28.9167,152.25,4
2372,Back Creek,New South Wales,NSW,-29.1538,151.9669,3
2372,Rocky River,New South Wales,NSW,-30.6167,151.5,4
2372,Tarban,New South Wales,NSW,-28.9818,151.9012,4
2372,Black Swamp,New South Wales,NSW,-29,152.15,4
2372,Boorook,New South Wales,NSW,-29.1538,151.9669,3
2372,Sandy Flat,New South Wales,NSW,-29.2307,152.0048,4
2372,Rivertree,New South Wales,NSW,-29.1538,151.9669,3
2372,Boonoo Boonoo,New South Wales,NSW,-28.8777,152.1012,4
2372,Dumaresq Valley,New South Wales,NSW,-29.1538,151.9669,3
2372,Mole River,New South Wales,NSW,-29.1167,151.7667,4
2372,Wylie Creek,New South Wales,NSW,-28.5485,152.1512,4
2372,Forest Land,New South Wales,NSW,-29.1538,151.9669,3
2372,Timbarra,New South Wales,NSW,-29.0167,152.1833,4
2372,Bookookoorara,New South Wales,NSW,-29.1538,151.9669,3
2372,Tenterfield,New South Wales,NSW,-29.0495,152.0195,4
2372,Bungulla,New South Wales,NSW,-29.1333,151.9833,4
2372,Carrolls Creek,New South Wales,NSW,-28.8227,152.103,4
2372,Willsons Downfall,New South Wales,NSW,-29.1538,151.9669,3
2372,Cullendore,New South Wales,NSW,-29.1538,151.9669,3
2379,Goolhi,New South Wales,NSW,-31.1,149.9,3
2379,Mullaley,New South Wales,NSW,-31.1011,149.9058,4
2379,Napier Lane,New South Wales,NSW,-31.1,149.9,3
2379,Nombi,New South Wales,NSW,-31.1,149.9,3
2380,Coocooboonah,New South Wales,NSW,-31,150.1333,4
2380,Ghoolendaadi,New South Wales,NSW,-30.9702,150.1854,3
2380,Kelvin,New South Wales,NSW,-30.8152,150.3512,4
2380,Rangari,New South Wales,NSW,-30.9702,150.1854,3
2380,Orange Grove,New South Wales,NSW,-32.0473,146.6726,4
2380,Milroy,New South Wales,NSW,-31.15,150.05,4
2380,Emerald Hill,New South Wales,NSW,-30.8833,150.0833,4
2380,Marys Mount,New South Wales,NSW,-33.7549,150.7844,4
2380,Gunnedah,New South Wales,NSW,-30.9807,150.2584,4
2380,Burburgate,New South Wales,NSW,-30.9908,150.2538,3
2380,Blue Vale,New South Wales,NSW,-30.9702,150.1854,3
2381,Curlewis,New South Wales,NSW,-31.1167,150.2675,4
2381,Pullaming,New South Wales,NSW,-31.2415,150.4857,3
2381,Breeza,New South Wales,NSW,-31.2497,150.4629,4
2381,Tambar Springs,New South Wales,NSW,-31.3495,149.83,4
2381,Premer,New South Wales,NSW,-31.4567,149.909,4
2382,Maules Creek,New South Wales,NSW,-30.7365,150.0637,3
2382,Willala,New South Wales,NSW,-30.8152,149.8679,4
2382,Wean,New South Wales,NSW,-30.7,150.2833,4
2382,Boggabri,New South Wales,NSW,-30.7095,150.0412,4
2386,Drildool,New South Wales,NSW,-30.1167,148.9667,3
2386,Burren Junction,New South Wales,NSW,-30.0985,148.9679,4
2386,Nowley,New South Wales,NSW,-30.1167,148.9667,3
2387,Rowena,New South Wales,NSW,-29.8169,148.909,4
2387,Bulyeroi,New South Wales,NSW,-29.8,148.9167,3
2388,Merah North,New South Wales,NSW,-30.1831,149.2951,4
2388,Cuttabri,New South Wales,NSW,-30.2785,149.2051,3
2388,Yarrie Lake,New South Wales,NSW,-30.35,149.5167,4
2388,Spring Plains,New South Wales,NSW,-31.4,150.25,4
2388,Boolcarroll,New South Wales,NSW,-30.502,149.4814,3
2388,Pilliga,New South Wales,NSW,-30.3558,148.8879,4
2388,The Pilliga,New South Wales,NSW,-30.502,149.4814,3
2388,Wee Waa,New South Wales,NSW,-30.2266,149.4404,4
2390,Jacks Creek,New South Wales,NSW,-30.4977,149.7394,4
2390,Kaputar,New South Wales,NSW,-30.2941,150.1447,4
2390,Turrawan,New South Wales,NSW,-30.4642,149.8849,4
2390,Eulah Creek,New South Wales,NSW,-30.364,149.8906,3
2390,Edgeroi,New South Wales,NSW,-30.1221,149.8012,4
2390,Harparary,New South Wales,NSW,-30.5333,150.0167,4
2390,Narrabri West,New South Wales,NSW,-30.3333,149.75,4
2390,Berrigal,New South Wales,NSW,-30.364,149.8906,3
2390,Rocky Creek,New South Wales,NSW,-30.0333,150.2667,4
2390,Baan Baa,New South Wales,NSW,-30.5978,149.9496,4
2390,Bullawa Creek,New South Wales,NSW,-30.3068,150.0012,4
2390,Couradda,New South Wales,NSW,-30.364,149.8906,3
2390,Back Creek,New South Wales,NSW,-30.364,149.8906,3
2390,Narrabri,New South Wales,NSW,-30.3261,149.7837,4
2390,Tarriaro,New South Wales,NSW,-30.364,149.8906,3
2390,Bohena Creek,New South Wales,NSW,-30.4167,149.7,4
2395,Ropers Road,New South Wales,NSW,-31.5928,149.481,3
2395,Binnaway,New South Wales,NSW,-31.5523,149.3787,4
2395,Weetaliba,New South Wales,NSW,-31.644,149.5845,4
2396,Barwon,New South Wales,NSW,-30.9329,149.0119,3
2396,Goorianawa,New South Wales,NSW,-31.0833,148.9333,4
2396,Kenebri,New South Wales,NSW,-30.7751,149.0236,4
2396,Baradine,New South Wales,NSW,-30.9487,149.0692,4
2397,Millie,New South Wales,NSW,-29.8152,149.5679,4
2397,Bellata,New South Wales,NSW,-29.9191,149.7889,4
2397,Jews Lagoon,New South Wales,NSW,-29.8667,149.675,3
2398,Gurley,New South Wales,NSW,-29.7333,149.8,4
2399,Biniguy,New South Wales,NSW,-29.5435,150.2033,4
2399,Pallamallawa,New South Wales,NSW,-29.4741,150.134,4
2400,Bullarah,New South Wales,NSW,-29.4657,150.0146,3
2400,Terry Hie Hie,New South Wales,NSW,-29.7968,150.148,4
2400,Moree East,New South Wales,NSW,-29.4657,150.0146,3
2400,Moree,New South Wales,NSW,-29.4628,149.8416,4
2400,Crooble,New South Wales,NSW,-29.2659,150.2555,4
2400,Tulloona,New South Wales,NSW,-29.4657,150.0146,3
2400,Mallowa,New South Wales,NSW,-29.4657,150.0146,3
2400,Ashley,New South Wales,NSW,-29.3136,149.8081,4
2401,Gravesend,New South Wales,NSW,-29.5833,150.3331,4
2402,Coolatai,New South Wales,NSW,-29.2571,150.7473,4
2402,Warialda,New South Wales,NSW,-29.5435,150.5754,4
2402,Warialda Rail,New South Wales,NSW,-29.5766,150.5319,4
2403,Delungra,New South Wales,NSW,-29.654,150.83,4
2403,Myall Creek,New South Wales,NSW,-29.75,150.7667,4
2403,Koloona,New South Wales,NSW,-29.626,150.7333,4
2403,Gragin,New South Wales,NSW,-29.6791,150.7711,3
2403,Balfours Peak,New South Wales,NSW,-29.6791,150.7711,3
2404,Bingara,New South Wales,NSW,-29.8693,150.572,4
2404,Elcombe,New South Wales,NSW,-29.7667,150.3833,4
2404,Keera,New South Wales,NSW,-29.9818,150.7679,4
2404,Gineroi,New South Wales,NSW,-29.7667,150.5,4
2404,Bangheet,New South Wales,NSW,-29.8782,150.537,3
2404,Pallal,New South Wales,NSW,-29.9167,150.3833,4
2404,Riverview,New South Wales,NSW,-33.824,151.1602,4
2404,Dinoga,New South Wales,NSW,-29.9667,150.6,4
2404,Upper Bingara,New South Wales,NSW,-30.05,150.65,4
2405,Boomi,New South Wales,NSW,-28.7233,149.5794,4
2405,Garah,New South Wales,NSW,-29.0759,149.6377,4
2406,Weemelah,New South Wales,NSW,-29.0185,149.2548,4
2406,Mungindi,New South Wales,NSW,-28.9767,148.9881,4
2408,North Star,New South Wales,NSW,-28.9348,150.3896,4
2408,Blue Nobby,New South Wales,NSW,-29.0246,150.4374,3
2408,Yallaroi,New South Wales,NSW,-29.1167,150.4833,4
2409,Boggabilla,New South Wales,NSW,-28.6048,150.3593,4
2409,Boonal,New South Wales,NSW,-28.6048,150.3593,3
2410,Yetman,New South Wales,NSW,-28.9043,150.7769,4
2410,Twin Rivers,New South Wales,NSW,-28.9,150.7833,3
2411,Croppa Creek,New South Wales,NSW,-29.1331,150.3014,4
2415,Monkerai,New South Wales,NSW,-32.2915,151.8602,4
2415,Nooroo,New South Wales,NSW,-32.3207,151.8968,3
2415,Stroud Road,New South Wales,NSW,-32.35,151.9333,4
2415,Upper Karuah River,New South Wales,NSW,-32.3207,151.8968,3
2415,Weismantels,New South Wales,NSW,-32.2608,151.9427,4
2420,Fosterton,New South Wales,NSW,-32.3474,151.7612,4
2420,Wallarobba,New South Wales,NSW,-32.5046,151.7007,4
2420,Underbank,New South Wales,NSW,-32.3982,151.6842,3
2420,Dungog,New South Wales,NSW,-32.4003,151.7576,4
2420,Stroud Hill,New South Wales,NSW,-32.3982,151.6842,3
2420,Bendolba,New South Wales,NSW,-32.3518,151.7301,4
2420,Main Creek,New South Wales,NSW,-32.3982,151.6842,3
2420,Wirragulla,New South Wales,NSW,-32.4596,151.746,4
2420,Sugarloaf,New South Wales,NSW,-32.3982,151.6842,3
2420,Brookfield,New South Wales,NSW,-32.4985,151.7679,4
2420,Bandon Grove,New South Wales,NSW,-32.2999,151.7159,3
2420,Salisbury,New South Wales,NSW,-32.25,151.5667,4
2420,Hanleys Creek,New South Wales,NSW,-32.3982,151.6842,3
2420,Marshdale,New South Wales,NSW,-32.3707,151.7022,3
2420,Munni,New South Wales,NSW,-32.3982,151.6842,3
2420,Flat Tops,New South Wales,NSW,-32.3982,151.6842,3
2420,Wallaringa,New South Wales,NSW,-32.3982,151.6842,3
2420,Cambra,New South Wales,NSW,-32.3982,151.6842,3
2420,Tabbil Creek,New South Wales,NSW,-32.3982,151.6842,3
2420,Hilldale,New South Wales,NSW,-32.5,151.65,4
2420,Alison,New South Wales,NSW,-33.2768,151.4021,4
2420,Chichester,New South Wales,NSW,-32.2,151.6167,4
2420,Martins Creek,New South Wales,NSW,-32.55,151.6167,4
2421,Webbers Creek,New South Wales,NSW,-32.6061,151.6159,3
2421,Tocal,New South Wales,NSW,-32.633,151.5913,4
2421,Fishers Hill,New South Wales,NSW,-32.6061,151.6159,3
2421,Trevallyn,New South Wales,NSW,-32.5432,151.5372,3
2421,Vacy,New South Wales,NSW,-32.5316,151.569,4
2421,Paterson,New South Wales,NSW,-32.6061,151.6159,4
2421,Torryburn,New South Wales,NSW,-30.45,151.2167,4
2421,Summer Hill,New South Wales,NSW,-33.8915,151.1382,4
2422,Gloucester Tops,New South Wales,NSW,-31.9223,151.5346,4
2422,Belbora,New South Wales,NSW,-32.0333,152.1833,4
2422,Barrington,New South Wales,NSW,-31.9726,151.9031,4
2422,Bulliac,New South Wales,NSW,-31.9167,152.0333,4
2422,Stratford,New South Wales,NSW,-32.1167,151.9333,4
2422,Cobark,New South Wales,NSW,-31.9485,151.7096,4
2422,Rawdon Vale,New South Wales,NSW,-31.9833,151.7,4
2422,Gangat,New South Wales,NSW,-32.0235,152.0846,4
2422,Gloucester,New South Wales,NSW,-32.0077,151.9633,4
2422,Craven,New South Wales,NSW,-32.1485,151.9512,4
2422,Copeland,New South Wales,NSW,-32,151.8333,4
2422,Giro,New South Wales,NSW,-31.7152,151.8096,4
2422,Bundook,New South Wales,NSW,-31.8906,152.1219,4
2422,Wards River,New South Wales,NSW,-32.2166,151.9423,4
2422,Forbesdale,New South Wales,NSW,-31.9758,151.9086,3
2422,Rookhurst,New South Wales,NSW,-31.8833,151.8833,4
2422,Dewitt,New South Wales,NSW,-31.9758,151.9086,3
2422,Curricabark,New South Wales,NSW,-31.7447,151.6389,4
2422,Callaghans Creek,New South Wales,NSW,-31.9758,151.9086,3
2422,Bowman,New South Wales,NSW,-31.9223,151.7831,4
2422,Faulkland,New South Wales,NSW,-31.9758,151.9086,3
2422,Mograni,New South Wales,NSW,-32.0333,152.0333,4
2422,Bindera,New South Wales,NSW,-31.9758,151.9086,3
2422,Upper Bowman,New South Wales,NSW,-31.9223,151.7831,4
2422,Titaatee Creek,New South Wales,NSW,-31.9758,151.9086,3
2422,Baxters Ridge,New South Wales,NSW,-31.9758,151.9086,3
2422,Barrington Tops,New South Wales,NSW,-31.9223,151.5346,4
2422,Coneac,New South Wales,NSW,-31.9758,151.9086,3
2422,Terreel,New South Wales,NSW,-31.9758,151.9086,3
2422,Bretti,New South Wales,NSW,-31.9362,151.7966,3
2422,Craven Plateau,New South Wales,NSW,-31.9758,151.9086,3
2422,Tugrabakh,New South Wales,NSW,-31.9758,151.9086,3
2422,Invergordon,New South Wales,NSW,-31.9758,151.9086,3
2422,Glen Ward,New South Wales,NSW,-31.9758,151.9086,3
2422,Bowman Farm,New South Wales,NSW,-31.9758,151.9086,3
2422,Kia Ora,New South Wales,NSW,-31.9758,151.9086,3
2422,Back Creek,New South Wales,NSW,-31.9758,151.9086,3
2422,Waukivory,New South Wales,NSW,-32.1167,152.05,4
2422,Tibbuc,New South Wales,NSW,-31.9758,151.9086,3
2422,Woko,New South Wales,NSW,-31.9758,151.9086,3
2422,Berrico,New South Wales,NSW,-31.9758,151.9086,3
2422,Mernot,New South Wales,NSW,-31.9758,151.9086,3
2422,Wallanbah,New South Wales,NSW,-31.9758,151.9086,3
2422,Moppy,New South Wales,NSW,-31.9758,151.9086,3
2422,Mares Run,New South Wales,NSW,-31.9758,151.9086,3
2422,Bakers Creek,New South Wales,NSW,-30.2667,151.0167,4
2423,Upper Myall,New South Wales,NSW,-32.3,152.1667,4
2423,Bungwahl,New South Wales,NSW,-32.3931,152.4487,4
2423,Crawford River,New South Wales,NSW,-32.45,152.1333,4
2423,Violet Hill,New South Wales,NSW,-32.3182,152.2701,3
2423,Wang Wauk,New South Wales,NSW,-32.1667,152.3333,4
2423,Mayers Flat,New South Wales,NSW,-32.388,152.3318,4
2423,Wootton,New South Wales,NSW,-32.3021,152.3171,4
2423,Boolambayte,New South Wales,NSW,-32.3982,152.2696,4
2423,Topi Topi,New South Wales,NSW,-32.3667,152.3833,4
2423,Bulahdelah,New South Wales,NSW,-32.407,152.2119,4
2423,Markwell,New South Wales,NSW,-32.3333,152.1833,4
2423,Mungo Brush,New South Wales,NSW,-32.3182,152.2701,3
2423,Warranulla,New South Wales,NSW,-32.1963,152.1354,4
2423,Myall Lake,New South Wales,NSW,-32.3182,152.2701,3
2423,Nerong,New South Wales,NSW,-32.5235,152.1982,3
2423,Bombah Point,New South Wales,NSW,-32.3182,152.2701,3
2423,Coolongolook,New South Wales,NSW,-32.2174,152.3221,4
2423,Yagon,New South Wales,NSW,-32.3182,152.2701,3
2423,Willina,New South Wales,NSW,-32.1766,152.2712,4
2423,Seal Rocks,New South Wales,NSW,-32.4399,152.5317,4
2424,Knorrit Flat,New South Wales,NSW,-31.8414,152.112,4
2424,Mount George,New South Wales,NSW,-31.8884,152.1854,4
2424,Cells River,New South Wales,NSW,-31.8167,152.0792,3
2424,Cundle Flat,New South Wales,NSW,-31.8167,151.9833,4
2424,Caffreys Flat,New South Wales,NSW,-31.8167,152.0792,3
2424,Number One,New South Wales,NSW,-31.738,152.0665,4
2424,Tiri,New South Wales,NSW,-31.8167,152.0792,3
2424,Cooplacurripa,New South Wales,NSW,-31.8167,152.0792,3
2424,Knorrit Forest,New South Wales,NSW,-31.8167,152.0792,3
2425,Allworth,New South Wales,NSW,-32.5368,151.9418,4
2425,Washpool,New South Wales,NSW,-32.395,152.0141,3
2425,Stroud,New South Wales,NSW,-32.4037,151.967,4
2425,The Branch,New South Wales,NSW,-32.5333,152.0167,4
2425,Girvan,New South Wales,NSW,-32.4777,152.0651,4
2425,Booral,New South Wales,NSW,-32.4685,151.986,4
2426,Moto,New South Wales,NSW,-31.8127,152.589,3
2426,Coopernook,New South Wales,NSW,-31.8254,152.6114,4
2426,Langley Vale,New South Wales,NSW,-31.8,152.5667,4
2427,Harrington,New South Wales,NSW,-31.8723,152.6917,4
2427,Crowdy Head,New South Wales,NSW,-31.8448,152.7389,3
2428,Whoota,New South Wales,NSW,-32.3157,152.4818,4
2428,Coomba Bay,New South Wales,NSW,-32.28,152.5039,3
2428,Coomba Park,New South Wales,NSW,-32.2377,152.4571,4
2428,Elizabeth Beach,New South Wales,NSW,-32.3195,152.5275,4
2428,Blueys Beach,New South Wales,NSW,-32.3472,152.5357,4
2428,Smiths Lake,New South Wales,NSW,-32.3824,152.5018,4
2428,Booti Booti,New South Wales,NSW,-32.3168,152.5221,4
2428,Tiona,New South Wales,NSW,-32.28,152.5039,3
2428,Forster,New South Wales,NSW,-32.1814,152.5172,4
2428,Shallow Bay,New South Wales,NSW,-32.28,152.5039,3
2428,Green Point,New South Wales,NSW,-32.2493,152.5176,4
2428,Forster Shopping Village,New South Wales,NSW,-32.2335,152.4559,3
2428,Tuncurry,New South Wales,NSW,-32.1744,152.4988,4
2428,Wallingat,New South Wales,NSW,-32.28,152.5039,3
2428,Charlotte Bay,New South Wales,NSW,-32.351,152.5079,4
2428,Tarbuck Bay,New South Wales,NSW,-32.3645,152.4779,4
2428,Darawank,New South Wales,NSW,-32.1333,152.4833,4
2428,Sandbar,New South Wales,NSW,-32.28,152.5039,3
2428,Pacific Palms,New South Wales,NSW,-32.2335,152.4559,3
2428,Wallis Lake,New South Wales,NSW,-32.28,152.5039,3
2428,Boomerang Beach,New South Wales,NSW,-32.28,152.5039,3
2429,Bobin,New South Wales,NSW,-31.7167,152.2833,4
2429,Kimbriki,New South Wales,NSW,-31.9252,152.2107,4
2429,Cedar Party,New South Wales,NSW,-31.8,152.3833,4
2429,Bucca Wauka,New South Wales,NSW,-32.1,152.1667,4
2429,Burrell Creek,New South Wales,NSW,-31.9333,152.3,4
2429,Krambach,New South Wales,NSW,-32.05,152.2667,4
2429,Dyers Crossing,New South Wales,NSW,-32.1,152.3,4
2429,Yarratt Forest,New South Wales,NSW,-31.8738,152.2973,3
2429,Killawarra,New South Wales,NSW,-31.9049,152.2872,4
2429,Killabakh,New South Wales,NSW,-31.7333,152.4,4
2429,Wingham,New South Wales,NSW,-31.8668,152.3699,4
2429,Innes View,New South Wales,NSW,-31.8738,152.2973,3
2429,Comboyne,New South Wales,NSW,-31.6092,152.4742,4
2429,Kundibakh,New South Wales,NSW,-31.9833,152.25,4
2429,Elands,New South Wales,NSW,-31.6385,152.2981,4
2429,Bulga Forest,New South Wales,NSW,-31.8738,152.2973,3
2429,Wherrol Flat,New South Wales,NSW,-31.7816,152.2269,4
2429,Khatambuhl,New South Wales,NSW,-31.8738,152.2973,3
2429,Marlee,New South Wales,NSW,-31.8014,152.3157,4
2429,Dollys Flat,New South Wales,NSW,-31.8738,152.2973,3
2429,Mooral Creek,New South Wales,NSW,-31.7248,152.355,4
2429,Strathcedar,New South Wales,NSW,-31.8738,152.2973,3
2429,Bunyah,New South Wales,NSW,-32.1667,152.2167,4
2429,The Bight,New South Wales,NSW,-31.8738,152.2973,3
2429,Warriwillah,New South Wales,NSW,-31.7783,152.2596,3
2429,Tipperary,New South Wales,NSW,-31.8738,152.2973,3
2429,Caparra,New South Wales,NSW,-31.7783,152.2596,3
2429,Boorganna,New South Wales,NSW,-31.8738,152.2973,3
2429,Firefly,New South Wales,NSW,-32.0826,152.2449,3
2429,Kippaxs,New South Wales,NSW,-31.8738,152.2973,3
2429,Dingo Forest,New South Wales,NSW,-31.8738,152.2973,3
2429,Karaak Flat,New South Wales,NSW,-31.8738,152.2973,3
2430,Purfleet,New South Wales,NSW,-31.9428,152.4673,4
2430,Cundletown,New South Wales,NSW,-31.8941,152.5214,4
2430,Bohnock,New South Wales,NSW,-31.95,152.5667,4
2430,Rainbow Flat,New South Wales,NSW,-32.0333,152.4833,4
2430,Taree,New South Wales,NSW,-31.911,152.4539,4
2430,Brimbin,New South Wales,NSW,-31.9412,152.5161,3
2430,Cabbage Tree Island,New South Wales,NSW,-28.9804,153.4567,4
2430,Kundle Kundle,New South Wales,NSW,-31.9412,152.5161,3
2430,Glenthorne,New South Wales,NSW,-31.9279,152.4788,4
2430,Black Head,New South Wales,NSW,-31.9412,152.5161,3
2430,Oxley Island,New South Wales,NSW,-31.9167,152.5667,4
2430,Dumaresq Island,New South Wales,NSW,-31.9412,152.5161,3
2430,Chatham,New South Wales,NSW,-31.9,152.4833,4
2430,Ghinni Ghinni,New South Wales,NSW,-31.8833,152.5667,4
2430,Old Bar,New South Wales,NSW,-31.9694,152.5881,4
2430,Lansdowne,New South Wales,NSW,-31.7849,152.5192,4
2430,Mitchells Island,New South Wales,NSW,-31.9,152.6167,4
2430,Hallidays Point,New South Wales,NSW,-32.0713,152.5445,4
2430,Possum Brush,New South Wales,NSW,-32.0651,152.4382,4
2430,Lansdowne Forest,New South Wales,NSW,-31.7469,152.5757,4
2430,Koorainghat,New South Wales,NSW,-31.986,152.4799,4
2430,Diamond Beach,New South Wales,NSW,-32.0435,152.5346,4
2430,Kolodong,New South Wales,NSW,-31.8833,152.4333,4
2430,Tallwoods Village,New South Wales,NSW,-32.0554,152.5104,4
2430,Failford,New South Wales,NSW,-32.0918,152.446,4
2430,Bootawa,New South Wales,NSW,-31.9412,152.5161,3
2430,Upper Lansdowne,New South Wales,NSW,-31.7108,152.4658,4
2430,Hillville,New South Wales,NSW,-31.9833,152.3667,4
2430,Red Head,New South Wales,NSW,-32.057,152.5357,4
2430,Kiwarrak,New South Wales,NSW,-31.9412,152.5161,3
2430,Croki,New South Wales,NSW,-31.8733,152.5926,4
2430,Tinonee,New South Wales,NSW,-31.9363,152.4142,4
2430,Jones Island,New South Wales,NSW,-31.9412,152.5161,3
2430,Wallabi Point,New South Wales,NSW,-31.9951,152.5685,4
2430,Saltwater,New South Wales,NSW,-31.9412,152.5161,3
2430,Manning Point,New South Wales,NSW,-31.8963,152.6617,4
2430,Pampoolah,New South Wales,NSW,-31.9333,152.5333,4
2430,Mondrook,New South Wales,NSW,-31.9412,152.5161,3
2430,Taree South,New South Wales,NSW,-31.9412,152.5161,3
2430,Melinga,New South Wales,NSW,-31.9412,152.5161,3
2431,South West Rocks,New South Wales,NSW,-30.8855,153.0413,4
2431,Arakoon,New South Wales,NSW,-30.8875,153.0721,4
2431,Jerseyville,New South Wales,NSW,-30.9167,153.0333,4
2439,Kerewong,New South Wales,NSW,-31.6466,152.6875,3
2439,Lorne,New South Wales,NSW,-31.6612,152.6117,4
2439,Black Creek,New South Wales,NSW,-31.6466,152.6875,3
2439,Rossglen,New South Wales,NSW,-31.6658,152.7251,4
2439,Batar Creek,New South Wales,NSW,-31.6466,152.6875,3
2439,Upsalls Creek,New South Wales,NSW,-31.6333,152.6667,4
2439,Kew,New South Wales,NSW,-31.636,152.7266,4
2439,Logans Crossing,New South Wales,NSW,-31.6466,152.6875,3
2439,Kendall,New South Wales,NSW,-31.633,152.7039,4
2439,Swans Crossing,New South Wales,NSW,-31.6466,152.6875,3
2440,West Kempsey,New South Wales,NSW,-30.8855,153.0413,4
2440,Yarravel,New South Wales,NSW,-31.043,152.7619,4
2440,Dondingalong,New South Wales,NSW,-31.1332,152.7514,4
2440,East Kempsey,New South Wales,NSW,-31.0123,152.7651,3
2440,Lower Creek,New South Wales,NSW,-30.7342,152.2575,4
2440,Old Station,New South Wales,NSW,-31.0123,152.7651,3
2440,Frederickton,New South Wales,NSW,-31.0375,152.8753,4
2440,Temagog,New South Wales,NSW,-31.0123,152.7651,3
2440,Comara,New South Wales,NSW,-30.78,152.3772,4
2440,Rainbow Reach,New South Wales,NSW,-31.0123,152.7651,3
2440,Kinchela,New South Wales,NSW,-30.9939,152.9914,4
2440,Yessabah,New South Wales,NSW,-31.0123,152.7651,3
2440,South Kempsey,New South Wales,NSW,-31.079,152.8309,3
2440,Wittitrin,New South Wales,NSW,-31.1333,152.6667,4
2440,Skillion Flat,New South Wales,NSW,-31.0099,152.7329,4
2440,Toorooka,New South Wales,NSW,-30.9307,152.6368,3
2440,Sherwood,New South Wales,NSW,-31.0649,152.7288,4
2440,Hampden Hall,New South Wales,NSW,-31.0123,152.7651,3
2440,Willi Willi,New South Wales,NSW,-30.9333,152.45,4
2440,Bellimbopinni,New South Wales,NSW,-31.0167,152.9167,4
2440,Willawarrin,New South Wales,NSW,-30.9288,152.6253,4
2440,Belmore River,New South Wales,NSW,-31.1167,152.9833,4
2440,Kempsey,New South Wales,NSW,-31.079,152.8309,4
2440,Greenhill,New South Wales,NSW,-31.059,152.8017,4
2440,Aldavilla,New South Wales,NSW,-31.0818,152.7679,4
2440,Carrai,New South Wales,NSW,-30.9307,152.6368,3
2440,Hickeys Creek,New South Wales,NSW,-30.8985,152.6179,4
2440,Gladstone,New South Wales,NSW,-31.0288,152.9476,4
2440,Millbank,New South Wales,NSW,-30.8612,152.6357,4
2440,Pola Creek,New South Wales,NSW,-31.0123,152.7651,3
2440,Austral Eden,New South Wales,NSW,-31.0333,152.9167,4
2440,Hat Head,New South Wales,NSW,-31.0555,153.0472,4
2440,Euroka,New South Wales,NSW,-31.077,152.8005,4
2440,Deep Creek,New South Wales,NSW,-30.3667,149.8333,4
2440,Clybucca,New South Wales,NSW,-30.9377,152.9413,4
2440,Seven Oaks,New South Wales,NSW,-31.0123,152.7651,3
2440,Mooneba,New South Wales,NSW,-31.0402,152.6846,4
2440,Turners Flat,New South Wales,NSW,-31.0152,152.7012,4
2440,Crescent Head,New South Wales,NSW,-31.1887,152.973,4
2440,Moparrabah,New South Wales,NSW,-31.0123,152.7651,3
2440,Smithtown,New South Wales,NSW,-31.0186,152.9503,4
2440,Collombatti,New South Wales,NSW,-30.9812,152.8251,4
2440,Burnt Bridge,New South Wales,NSW,-31.108,152.8062,4
2440,Summer Island,New South Wales,NSW,-31.0123,152.7651,3
2440,Bellbrook,New South Wales,NSW,-30.8199,152.5102,4
2440,Mungay Creek,New South Wales,NSW,-31.0123,152.7651,3
2440,Corangula,New South Wales,NSW,-31.0123,152.7651,3
2440,Verges Creek,New South Wales,NSW,-31.0878,152.8997,3
2441,Eungai Rail,New South Wales,NSW,-30.8463,152.9006,3
2441,Yarrahapinni,New South Wales,NSW,-30.8281,152.9538,4
2441,Kundabung,New South Wales,NSW,-31.2094,152.8316,4
2441,Bril Bril,New South Wales,NSW,-31.0274,152.8364,3
2441,Upper Rollands Plains,New South Wales,NSW,-31.2434,152.6205,4
2441,Cooperabung,New South Wales,NSW,-31.3,152.8,4
2441,Eungai Creek,New South Wales,NSW,-30.8333,152.8833,4
2441,Stuarts Point,New South Wales,NSW,-30.8208,152.9933,4
2441,Tamban,New South Wales,NSW,-30.8827,152.826,4
2441,Crossmaglen,New South Wales,NSW,-31.1374,152.7419,3
2441,Telegraph Point,New South Wales,NSW,-31.3247,152.8005,4
2441,Fishermans Reach,New South Wales,NSW,-31.0274,152.8364,3
2441,Allgomera,New South Wales,NSW,-30.8182,152.7955,4
2441,Grassy Head,New South Wales,NSW,-31.1374,152.7419,3
2441,Ballengarra,New South Wales,NSW,-31.3167,152.75,4
2441,Gum Scrub,New South Wales,NSW,-31.3,152.7333,4
2441,Barraganyatti,New South Wales,NSW,-30.8568,152.9346,4
2441,Marlo Merrican,New South Wales,NSW,-31.0274,152.8364,3
2441,Kippara,New South Wales,NSW,-31.2209,152.5225,4
2441,Bonville,New South Wales,NSW,-30.3828,153.0596,4
2441,Rollands Plains,New South Wales,NSW,-31.2818,152.6846,4
2441,Hacks Ferry,New South Wales,NSW,-31.0274,152.8364,3
2442,Mid North Coast Msc,New South Wales,NSW,-31.1374,152.7419,1
2442,Mid North Coast Mc,New South Wales,NSW,-31.1374,152.7419,1
2443,Diamond Head,New South Wales,NSW,-32.057,152.5357,4
2443,Laurieton,New South Wales,NSW,-31.6448,152.7946,4
2443,Coralville,New South Wales,NSW,-31.6915,152.7267,3
2443,Waitui,New South Wales,NSW,-31.6915,152.7267,3
2443,Bobs Creek,New South Wales,NSW,-31.6915,152.7267,3
2443,North Haven,New South Wales,NSW,-31.6368,152.8136,4
2443,Deauville,New South Wales,NSW,-31.6915,152.7267,3
2443,Middle Brother,New South Wales,NSW,-31.6915,152.7267,3
2443,Stewarts River,New South Wales,NSW,-31.7333,152.65,4
2443,Dunbogan,New South Wales,NSW,-31.65,152.8167,4
2443,Lakewood,New South Wales,NSW,-31.6321,152.7582,4
2443,North Brother,New South Wales,NSW,-31.6915,152.7267,3
2443,Camden Head,New South Wales,NSW,-31.6469,152.8346,3
2443,Moorland,New South Wales,NSW,-31.7929,152.6518,4
2443,Hannam Vale,New South Wales,NSW,-31.7167,152.6,4
2443,Crowdy Bay National Park,New South Wales,NSW,-31.788,152.7311,4
2443,West Haven,New South Wales,NSW,-31.6344,152.7825,3
2443,Herons Creek,New South Wales,NSW,-31.5918,152.7274,4
2443,Johns River,New South Wales,NSW,-31.7339,152.6965,4
2444,Riverside,New South Wales,NSW,-31.7083,152.6444,3
2444,Limeburners Creek,New South Wales,NSW,-32.6167,151.9167,4
2444,Blackmans Point,New South Wales,NSW,-31.3906,152.8314,4
2444,Fernbank Creek,New South Wales,NSW,-31.4212,152.8404,4
2444,Port Macquarie Bc,New South Wales,NSW,-31.3892,152.8614,3
2444,North Shore,New South Wales,NSW,-31.4024,152.9019,4
2444,The Hatch,New South Wales,NSW,-31.7083,152.6444,3
2444,Flynns Beach,New South Wales,NSW,-31.3892,152.8614,3
2444,Port Macquarie,New South Wales,NSW,-31.4308,152.9089,4
2444,Thrumster,New South Wales,NSW,-31.7083,152.6444,3
2444,Settlement City,New South Wales,NSW,-31.7083,152.6444,3
2444,Lighthouse Beach,New South Wales,NSW,-31.7083,152.6444,3
2445,Bonny Hills,New South Wales,NSW,-31.5906,152.8391,4
2445,Lake Cathie,New South Wales,NSW,-31.5518,152.8546,4
2445,Grants Beach,New South Wales,NSW,-31.5712,152.8469,3
2445,Jolly Nose,New South Wales,NSW,-31.5712,152.8469,3
2446,Wauchope,New South Wales,NSW,-31.4579,152.7262,4
2446,Hartys Plains,New South Wales,NSW,-31.4343,152.6221,3
2446,Banda Banda,New South Wales,NSW,-31.2157,152.4566,4
2446,Yippin Creek,New South Wales,NSW,-31.4343,152.6221,3
2446,Redbank,New South Wales,NSW,-31.4242,152.7393,4
2446,Long Flat,New South Wales,NSW,-31.4395,152.4925,4
2446,Pembrooke,New South Wales,NSW,-31.3935,152.7508,4
2446,Bago,New South Wales,NSW,-31.4343,152.6221,3
2446,Ellenborough,New South Wales,NSW,-31.45,152.4667,4
2446,Birdwood,New South Wales,NSW,-31.3485,152.3346,4
2446,Bellangry,New South Wales,NSW,-31.3284,152.6077,4
2446,Lower Pappinbarra,New South Wales,NSW,-31.4343,152.6221,3
2446,Forbes River,New South Wales,NSW,-31.3667,152.3167,4
2446,Hollisdale,New South Wales,NSW,-31.4,152.5333,4
2446,Sancrox,New South Wales,NSW,-31.4318,152.8012,4
2446,Toms Creek,New South Wales,NSW,-31.3861,152.4402,3
2446,Rawdon Island,New South Wales,NSW,-31.4333,152.7667,4
2446,King Creek,New South Wales,NSW,-31.4924,152.7566,4
2446,Brombin,New South Wales,NSW,-31.4578,152.6315,4
2446,Rosewood,New South Wales,NSW,-31.4343,152.6221,3
2446,Beechwood,New South Wales,NSW,-31.4365,152.6768,4
2446,Pappinbarra,New South Wales,NSW,-31.3666,152.4943,4
2446,Yarras,New South Wales,NSW,-31.4189,152.3198,4
2446,Cairncross,New South Wales,NSW,-31.4343,152.6221,3
2446,Byabarra,New South Wales,NSW,-31.523,152.546,4
2446,Huntingdon,New South Wales,NSW,-31.4639,152.6521,4
2446,Mount Seaview,New South Wales,NSW,-31.3966,152.0798,4
2446,Kindee,New South Wales,NSW,-31.4343,152.6221,3
2446,Mortons Creek,New South Wales,NSW,-31.3829,152.6427,4
2446,Doyles River,New South Wales,NSW,-31.4343,152.6221,3
2446,Gearys Flat,New South Wales,NSW,-31.4343,152.6221,3
2446,Upper Pappinbarra,New South Wales,NSW,-31.4343,152.6221,3
2446,Werrikimbe,New South Wales,NSW,-31.4343,152.6221,3
2446,Frazers Creek,New South Wales,NSW,-31.4343,152.6221,3
2446,Bagnoo,New South Wales,NSW,-31.4652,152.5346,4
2446,Debenham,New South Wales,NSW,-31.4343,152.6221,3
2446,Lake Innes,New South Wales,NSW,-31.5038,152.8165,4
2446,Kings Creek,New South Wales,NSW,-31.3861,152.4402,3
2446,Crosslands,New South Wales,NSW,-31.4393,152.7154,4
2446,Hyndmans Creek,New South Wales,NSW,-31.4343,152.6221,3
2446,Pipeclay,New South Wales,NSW,-31.4343,152.6221,3
2447,Gumma,New South Wales,NSW,-30.7172,152.9694,4
2447,Taylors Arm,New South Wales,NSW,-30.7833,152.7,4
2447,Wirrimbi,New South Wales,NSW,-30.6667,152.9333,4
2447,Congarinni,New South Wales,NSW,-30.6867,152.7061,3
2447,Scotts Head,New South Wales,NSW,-30.7477,152.9961,4
2447,Newee Creek,New South Wales,NSW,-30.6316,152.9275,4
2447,Warrell Creek,New South Wales,NSW,-30.7718,152.8924,4
2447,Upper Taylors Arm,New South Wales,NSW,-30.6867,152.7061,3
2447,Thumb Creek,New South Wales,NSW,-30.683,152.6154,4
2447,Talarm,New South Wales,NSW,-30.7016,152.8501,4
2447,Macksville,New South Wales,NSW,-30.7078,152.9203,4
2447,Congarinni North,New South Wales,NSW,-30.6867,152.7061,3
2447,Burrapine,New South Wales,NSW,-30.7176,152.6371,4
2447,Donnellyville,New South Wales,NSW,-30.7554,152.9155,4
2447,Yarranbella,New South Wales,NSW,-30.6867,152.7061,3
2447,Bakers Creek,New South Wales,NSW,-30.2667,151.0167,4
2447,Way Way,New South Wales,NSW,-30.6867,152.7061,3
2447,Utungun,New South Wales,NSW,-30.7427,152.8276,4
2447,North Macksville,New South Wales,NSW,-30.6867,152.7061,3
2448,Hyland Park,New South Wales,NSW,-30.6124,153.0001,4
2448,Valla,New South Wales,NSW,-30.5926,153.0113,4
2448,Valla Beach,New South Wales,NSW,-30.5926,153.0113,4
2448,Nambucca Heads,New South Wales,NSW,-30.6432,153.0088,4
2449,South Arm,New South Wales,NSW,-30.6888,152.7396,4
2449,Missabotti,New South Wales,NSW,-30.5747,152.8158,4
2449,Bowraville,New South Wales,NSW,-30.65,152.8515,4
2449,Buckra Bendinni,New South Wales,NSW,-30.6389,152.7853,3
2449,Killiekrankie,New South Wales,NSW,-30.6389,152.7853,3
2449,Tewinga,New South Wales,NSW,-30.6389,152.7853,3
2449,Girralong,New South Wales,NSW,-30.5696,152.6554,4
2449,Argents Hill,New South Wales,NSW,-30.6415,152.7491,4
2449,Kennaicle Creek,New South Wales,NSW,-30.6389,152.7853,3
2450,Upper Orara,New South Wales,NSW,-30.2833,153,4
2450,Coffs Harbour,New South Wales,NSW,-30.2963,153.1135,4
2450,Coffs Harbour Plaza,New South Wales,NSW,-30.0947,152.6671,3
2450,Moonee Beach,New South Wales,NSW,-30.2057,153.1529,4
2450,Nana Glen,New South Wales,NSW,-30.1333,153.0167,4
2450,Bonville,New South Wales,NSW,-30.3828,153.0596,4
2450,Sapphire Beach,New South Wales,NSW,-30.2325,153.1483,4
2450,Brooklana,New South Wales,NSW,-30.2488,152.8432,4
2450,Ulong,New South Wales,NSW,-30.2384,152.8799,4
2450,Korora,New South Wales,NSW,-30.259,153.1306,4
2450,Lower Bucca,New South Wales,NSW,-30.15,153.0833,4
2450,Glenreagh,New South Wales,NSW,-30.0534,152.9786,4
2450,Coffs Harbour Jetty,New South Wales,NSW,-30.0947,152.6671,3
2450,Sherwood,New South Wales,NSW,-31.0649,152.7288,4
2450,Lowanna,New South Wales,NSW,-30.2167,152.9,4
2450,North Boambee Valley,New South Wales,NSW,-30.2807,153.0196,3
2450,Bucca,New South Wales,NSW,-30.1574,153.0958,4
2450,Coramba,New South Wales,NSW,-30.2202,153.0134,4
2450,Boambee,New South Wales,NSW,-30.3398,153.0678,4
2450,Karangi,New South Wales,NSW,-30.2667,153.05,4
2452,Sawtell,New South Wales,NSW,-30.3646,153.1014,4
2452,Boambee East,New South Wales,NSW,-30.3466,153.0732,4
2452,Toormina,New South Wales,NSW,-30.3538,153.0884,4
2453,Never Never,New South Wales,NSW,-30.282,152.61,3
2453,Deer Vale,New South Wales,NSW,-30.3485,152.5512,4
2453,Billys Creek,New South Wales,NSW,-30.1674,152.5747,4
2453,North Dorrigo,New South Wales,NSW,-30.2955,152.6862,4
2453,Ebor,New South Wales,NSW,-30.4018,152.349,4
2453,Marengo,New South Wales,NSW,-30.1725,152.3615,4
2453,Dundurrabin,New South Wales,NSW,-30.1919,152.5513,4
2453,Clouds Creek,New South Wales,NSW,-30.1019,152.6498,4
2453,Tallowwood Ridge,New South Wales,NSW,-30.282,152.61,3
2453,Cascade,New South Wales,NSW,-30.2317,152.7898,4
2453,Bielsdown Hills,New South Wales,NSW,-30.282,152.61,3
2453,Tyringham,New South Wales,NSW,-30.2149,152.5434,4
2453,Bostobrick,New South Wales,NSW,-30.2771,152.6293,4
2453,Megan,New South Wales,NSW,-30.2857,152.7691,4
2453,Hernani,New South Wales,NSW,-30.2969,152.4208,4
2453,Dorrigo,New South Wales,NSW,-30.3411,152.7139,4
2453,Fernbrook,New South Wales,NSW,-30.282,152.61,3
2453,Briggsvale,New South Wales,NSW,-30.2497,152.7784,4
2453,Dorrigo Mountain,New South Wales,NSW,-30.282,152.61,3
2453,Wild Cattle Creek,New South Wales,NSW,-30.282,152.61,3
2453,Moonpar,New South Wales,NSW,-30.282,152.61,3
2454,Mylestom,New South Wales,NSW,-30.4649,153.0432,4
2454,Fernmount,New South Wales,NSW,-30.4667,152.9333,4
2454,Valery,New South Wales,NSW,-30.4055,152.9515,4
2454,Raleigh,New South Wales,NSW,-30.4396,152.9712,4
2454,Thora,New South Wales,NSW,-30.4397,152.7868,4
2454,Spicketts Creek,New South Wales,NSW,-30.441,152.9121,3
2454,Brinerville,New South Wales,NSW,-30.441,152.9121,3
2454,Kalang,New South Wales,NSW,-30.5083,152.7742,3
2454,Sunny Corner,New South Wales,NSW,-30.4511,152.743,3
2454,Repton,New South Wales,NSW,-30.45,153.0167,4
2454,Brierfield,New South Wales,NSW,-30.5,152.9,4
2454,Bundagen,New South Wales,NSW,-30.4132,153.0584,4
2454,Darkwood,New South Wales,NSW,-30.4535,152.5487,4
2454,Gleniffer,New South Wales,NSW,-30.3885,152.8871,4
2454,Bellingen,New South Wales,NSW,-30.4529,152.8991,4
2455,Urunga,New South Wales,NSW,-30.497,153.0142,4
2456,Mullaway,New South Wales,NSW,-30.0804,153.1959,4
2456,Emerald Beach,New South Wales,NSW,-30.1639,153.1819,4
2456,Upper Corindi,New South Wales,NSW,-30.0388,153.1131,4
2456,Corindi Beach,New South Wales,NSW,-30.0295,153.1595,3
2456,Red Rock,New South Wales,NSW,-29.9845,153.2262,4
2456,Arrawarra,New South Wales,NSW,-30.0606,153.1907,4
2456,Arrawarra Headland,New South Wales,NSW,-30.0598,153.2027,3
2456,Safety Beach,New South Wales,NSW,-30.0968,153.1877,4
2456,Dirty Creek,New South Wales,NSW,-30.0867,153.1898,3
2456,Woolgoolga,New South Wales,NSW,-30.1106,153.2007,4
2456,Sandy Beach,New South Wales,NSW,-30.1468,153.1924,4
2460,Smiths Creek,New South Wales,NSW,-28.4277,153.378,4
2460,Halfway Creek,New South Wales,NSW,-29.9318,153.0679,4
2460,Gurranang,New South Wales,NSW,-29.4676,152.9929,4
2460,Eatonsville,New South Wales,NSW,-29.6333,152.8333,4
2460,Braunstone,New South Wales,NSW,-29.8027,152.9581,4
2460,Buccarumbi,New South Wales,NSW,-29.8318,152.5846,4
2460,Kungala,New South Wales,NSW,-29.9535,153.0086,4
2460,Clifden,New South Wales,NSW,-29.5667,152.9167,4
2460,Lanitza,New South Wales,NSW,-29.8868,153.0017,4
2460,Whiteman Creek,New South Wales,NSW,-29.5833,152.8833,4
2460,Banyabba,New South Wales,NSW,-29.342,153.0165,4
2460,Fine Flower,New South Wales,NSW,-29.3833,152.6667,4
2460,Carrs Creek,New South Wales,NSW,-29.6488,152.9284,4
2460,Coutts Crossing,New South Wales,NSW,-29.8262,152.8916,4
2460,Tyndale,New South Wales,NSW,-29.5167,153.2,4
2460,Baryulgil,New South Wales,NSW,-29.2216,152.605,4
2460,Collum Collum,New South Wales,NSW,-29.6305,152.875,3
2460,Bookram,New South Wales,NSW,-29.6305,152.875,3
2460,Waterview,New South Wales,NSW,-29.6652,152.8679,4
2460,Eighteen Mile,New South Wales,NSW,-29.6305,152.875,3
2460,Brushgrove,New South Wales,NSW,-29.5663,153.0785,4
2460,Jackadgery,New South Wales,NSW,-29.5863,152.5635,4
2460,Mountain View,New South Wales,NSW,-32.41,149.1625,3
2460,Fortis Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Alumy Creek,New South Wales,NSW,-29.6485,152.9512,4
2460,Grafton,New South Wales,NSW,-29.6901,152.9334,4
2460,Lionsville,New South Wales,NSW,-29.1919,152.4907,4
2460,Lilydale,New South Wales,NSW,-29.6305,152.875,3
2460,Dalmorton,New South Wales,NSW,-29.8631,152.4629,4
2460,Kangaroo Creek,New South Wales,NSW,-29.8667,152.9333,4
2460,Lower Southgate,New South Wales,NSW,-29.55,153.0833,4
2460,Great Marlow,New South Wales,NSW,-29.6305,152.875,3
2460,Seelands,New South Wales,NSW,-29.6152,152.9179,4
2460,Chambigne,New South Wales,NSW,-29.7333,152.7833,4
2460,South Grafton,New South Wales,NSW,-29.7076,152.9263,4
2460,Grafton West,New South Wales,NSW,-29.624,152.7585,3
2460,Junction Hill,New South Wales,NSW,-29.6411,152.9249,4
2460,Copmanhurst,New South Wales,NSW,-29.5852,152.779,4
2460,Dilkoon,New South Wales,NSW,-29.4985,153.0012,4
2460,Levenstrath,New South Wales,NSW,-29.6305,152.875,3
2460,South Arm,New South Wales,NSW,-30.6888,152.7396,4
2460,Bom Bom,New South Wales,NSW,-29.6305,152.875,3
2460,Ramornie,New South Wales,NSW,-29.65,152.7833,4
2460,Upper Copmanhurst,New South Wales,NSW,-29.6305,152.875,3
2460,Stockyard Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Coaldale,New South Wales,NSW,-29.3943,152.8089,4
2460,Cowper,New South Wales,NSW,-29.5796,153.0761,4
2460,Heifer Station,New South Wales,NSW,-29.6305,152.875,3
2460,The Pinnacles,New South Wales,NSW,-29.6305,152.875,3
2460,Cangai,New South Wales,NSW,-29.4985,152.4679,4
2460,Southgate,New South Wales,NSW,-29.5833,153.0333,4
2460,Wells Crossing,New South Wales,NSW,-29.6305,152.875,3
2460,Elland,New South Wales,NSW,-29.6305,152.875,3
2460,Clarenza,New South Wales,NSW,-29.7037,153.0006,4
2460,Waterview Heights,New South Wales,NSW,-29.6652,152.8679,4
2460,Newbold,New South Wales,NSW,-29.6305,152.875,3
2460,Deep Creek,New South Wales,NSW,-30.3667,149.8333,4
2460,Punchbowl,New South Wales,NSW,-29.6305,152.875,3
2460,Koolkhan,New South Wales,NSW,-29.6305,152.875,3
2460,Washpool,New South Wales,NSW,-29.6305,152.875,3
2460,Carnham,New South Wales,NSW,-29.6305,152.875,3
2460,Trenayr,New South Wales,NSW,-29.6305,152.875,3
2460,The Whiteman,New South Wales,NSW,-29.6305,152.875,3
2460,Wombat Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Moleville Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Mylneford,New South Wales,NSW,-29.6305,152.875,3
2460,Upper Fine Flower,New South Wales,NSW,-29.6305,152.875,3
2460,Glenugie,New South Wales,NSW,-29.6305,152.875,3
2460,Dumbudgery,New South Wales,NSW,-29.6305,152.875,3
2460,Chaelundi,New South Wales,NSW,-29.6305,152.875,3
2460,Kyarran,New South Wales,NSW,-29.5808,152.9743,4
2460,Pulganbar,New South Wales,NSW,-29.6305,152.875,3
2460,Winegrove,New South Wales,NSW,-29.624,152.7585,3
2460,Towallum,New South Wales,NSW,-29.6305,152.875,3
2460,Crowther Island,New South Wales,NSW,-29.6305,152.875,3
2460,Nymboida,New South Wales,NSW,-29.926,152.7479,3
2460,Southampton,New South Wales,NSW,-29.6305,152.875,3
2460,Sandy Crossing,New South Wales,NSW,-29.6305,152.875,3
2460,Calamia,New South Wales,NSW,-29.6305,152.875,3
2460,Blaxlands Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Barcoongere,New South Wales,NSW,-29.6305,152.875,3
2460,Warragai Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Coombadjha,New South Wales,NSW,-29.6305,152.875,3
2460,Rushforth,New South Wales,NSW,-29.6305,152.875,3
2460,Malabugilmah,New South Wales,NSW,-29.6305,152.875,3
2460,Barretts Creek,New South Wales,NSW,-29.6305,152.875,3
2460,Lawrence,New South Wales,NSW,-29.4921,153.0969,4
2460,Kremnos,New South Wales,NSW,-29.6305,152.875,3
2460,Shannondale,New South Wales,NSW,-29.6305,152.875,3
2460,Carrs Island,New South Wales,NSW,-29.6305,152.875,3
2460,Carrs Peninsular,New South Wales,NSW,-29.6305,152.875,3
2462,Wooli,New South Wales,NSW,-29.8616,153.2677,4
2462,Lavadia,New South Wales,NSW,-29.7667,153.1,4
2462,Ulmarra,New South Wales,NSW,-29.6313,153.0299,4
2462,Swan Creek,New South Wales,NSW,-29.7122,153.1253,3
2462,Pillar Valley,New South Wales,NSW,-29.7558,153.1112,4
2462,Gilletts Ridge,New South Wales,NSW,-29.7122,153.1253,3
2462,Minnie Water,New South Wales,NSW,-29.7759,153.2973,4
2462,Lake Hiawatha,New South Wales,NSW,-29.7122,153.1253,3
2462,Diggers Camp,New South Wales,NSW,-29.8141,153.2853,4
2462,Calliope,New South Wales,NSW,-29.7122,153.1253,3
2462,Tucabia,New South Wales,NSW,-29.6636,153.1043,4
2462,Coldstream,New South Wales,NSW,-29.5833,153.1333,4
2463,Maclean,New South Wales,NSW,-29.4581,153.1975,4
2463,Palmers Island,New South Wales,NSW,-29.4167,153.2833,4
2463,Townsend,New South Wales,NSW,-29.4662,153.2207,4
2463,The Sandon,New South Wales,NSW,-29.4726,153.2191,3
2463,Shark Creek,New South Wales,NSW,-29.5167,153.2,4
2463,Palmers Channel,New South Wales,NSW,-29.4726,153.2191,3
2463,Ashby,New South Wales,NSW,-29.4318,153.1929,4
2463,Ashby Island,New South Wales,NSW,-29.4726,153.2191,3
2463,Gulmarrad,New South Wales,NSW,-29.487,153.2334,4
2463,Woodford Island,New South Wales,NSW,-29.4726,153.2191,3
2463,Ilarwill,New South Wales,NSW,-29.4818,153.1846,4
2463,James Creek,New South Wales,NSW,-29.4726,153.2191,3
2463,Tullymorgan,New South Wales,NSW,-29.3735,153.1179,4
2463,Jacky Bulbin Flat,New South Wales,NSW,-29.4726,153.2191,3
2463,Brooms Head,New South Wales,NSW,-29.6089,153.3365,4
2463,Sandon,New South Wales,NSW,-29.4726,153.2191,3
2463,Taloumbi,New South Wales,NSW,-29.4726,153.2191,3
2463,Woodford,New South Wales,NSW,-29.5433,153.2368,3
2463,Ashby Heights,New South Wales,NSW,-29.4726,153.2191,3
2464,Freeburn Island,New South Wales,NSW,-29.4042,153.3322,4
2464,Angourie,New South Wales,NSW,-29.4763,153.3588,4
2464,Micalo Island,New South Wales,NSW,-29.4927,153.3425,3
2464,Yuraygir,New South Wales,NSW,-29.5765,153.307,4
2464,Yamba,New South Wales,NSW,-29.4375,153.3591,4
2464,Wooloweyah,New South Wales,NSW,-29.4809,153.3428,4
2465,Harwood,New South Wales,NSW,-29.4258,153.2418,4
2466,Woody Head,New South Wales,NSW,-29.4067,153.3524,3
2466,Iluka,New South Wales,NSW,-29.4076,153.3495,4
2466,The Freshwater,New South Wales,NSW,-29.4067,153.3524,3
2469,Haystack,New South Wales,NSW,-29.0454,152.8384,3
2469,Lower Duck Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Paddys Flat,New South Wales,NSW,-28.7152,152.4179,4
2469,Bonalbo,New South Wales,NSW,-28.7371,152.6239,4
2469,Ewingar,New South Wales,NSW,-29.0454,152.8384,3
2469,Rappville,New South Wales,NSW,-29.0867,152.9528,4
2469,Theresa Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Gorge Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Goodwood Island,New South Wales,NSW,-29.3792,153.3012,4
2469,Pikapene,New South Wales,NSW,-29.0454,152.8384,3
2469,Camira,New South Wales,NSW,-29.2538,152.9636,4
2469,Tabulam,New South Wales,NSW,-28.8928,152.5652,4
2469,Mallanganee,New South Wales,NSW,-28.9076,152.7196,4
2469,Chatsworth,New South Wales,NSW,-29.3921,153.2341,4
2469,Drake,New South Wales,NSW,-28.9283,152.3723,4
2469,Kippenduff,New South Wales,NSW,-29.0454,152.8384,3
2469,Old Bonalbo,New South Wales,NSW,-28.6569,152.5974,4
2469,Pagans Flat,New South Wales,NSW,-29.0454,152.8384,3
2469,Bottle Creek,New South Wales,NSW,-28.7888,152.6521,4
2469,Duck Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Mummulgum,New South Wales,NSW,-28.8496,152.8048,4
2469,Mount Marsh,New South Wales,NSW,-29.0454,152.8384,3
2469,Alice,New South Wales,NSW,-29.0555,152.5879,4
2469,Capeen Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Myrtle Creek,New South Wales,NSW,-29.1122,152.9448,4
2469,Drake Village,New South Wales,NSW,-29.0454,152.8384,3
2469,Mororo,New South Wales,NSW,-29.3333,153.25,4
2469,Lower Bottle Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Whiporie,New South Wales,NSW,-29.2879,152.9926,4
2469,Deep Creek,New South Wales,NSW,-30.3667,149.8333,4
2469,Gibberagee,New South Wales,NSW,-29.2318,153.1012,4
2469,Jacksons Flat,New South Wales,NSW,-29.0454,152.8384,3
2469,Clearfield,New South Wales,NSW,-29.1445,152.936,4
2469,Joes Box,New South Wales,NSW,-29.0454,152.8384,3
2469,Six Mile Swamp,New South Wales,NSW,-29.0454,152.8384,3
2469,Louisa Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Bean Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Keybarbin,New South Wales,NSW,-29.15,152.6,4
2469,Tunglebung,New South Wales,NSW,-29.0454,152.8384,3
2469,Wyan,New South Wales,NSW,-29.0667,152.8667,4
2469,Peacock Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Woombah,New South Wales,NSW,-29.3667,153.2833,4
2469,Lower Peacock,New South Wales,NSW,-29.0454,152.8384,3
2469,Bungawalbin,New South Wales,NSW,-29.0333,153.2833,4
2469,Hogarth Range,New South Wales,NSW,-29.0454,152.8384,3
2469,Busbys Flat,New South Wales,NSW,-29.0485,152.8012,4
2469,Warregah Island,New South Wales,NSW,-29.0454,152.8384,3
2469,Mookima Wybra,New South Wales,NSW,-29.0454,152.8384,3
2469,Bingeebeebra Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Cambridge Plateau,New South Wales,NSW,-29.0454,152.8384,3
2469,Coongbar,New South Wales,NSW,-29.0454,152.8384,3
2469,Yabbra,New South Wales,NSW,-29.0454,152.8384,3
2469,Culmaran Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Sandilands,New South Wales,NSW,-29.0454,152.8384,3
2469,Simpkins Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Upper Duck Creek,New South Wales,NSW,-29.0454,152.8384,3
2469,Bulldog,New South Wales,NSW,-29.0454,152.8384,3
2470,Ellangowan,New South Wales,NSW,-29.0333,153.0667,4
2470,Sextonville,New South Wales,NSW,-28.8492,152.9372,3
2470,Yorklea,New South Wales,NSW,-28.9481,153.0584,4
2470,Irvington,New South Wales,NSW,-28.8492,152.9372,3
2470,Fairy Hill,New South Wales,NSW,-28.7667,153.0167,4
2470,Babyl Creek,New South Wales,NSW,-28.8732,152.9852,3
2470,Dobies Bight,New South Wales,NSW,-28.8016,152.9368,4
2470,Dyraaba Creek,New South Wales,NSW,-28.8492,152.9372,3
2470,Shannon Brook,New South Wales,NSW,-28.9167,152.9667,4
2470,Stratheden,New South Wales,NSW,-28.7505,152.9468,4
2470,Dyraaba Central,New South Wales,NSW,-28.7161,152.8628,4
2470,Baraimal,New South Wales,NSW,-28.8492,152.9372,3
2470,Spring Grove,New South Wales,NSW,-28.8331,153.1557,3
2470,Leeville,New South Wales,NSW,-28.95,153,4
2470,Dyraaba,New South Wales,NSW,-28.7161,152.8628,4
2470,Coombell,New South Wales,NSW,-29.0157,152.9732,4
2470,Doubtful Creek,New South Wales,NSW,-28.7538,152.9205,4
2470,Naughtons Gap,New South Wales,NSW,-28.8047,153.089,4
2470,Casino,New South Wales,NSW,-28.8582,153.0475,4
2470,Backmede,New South Wales,NSW,-28.75,153.0167,4
2470,Tomki,New South Wales,NSW,-28.8618,153.1323,4
2470,Woodview,New South Wales,NSW,-28.8667,152.9667,4
2470,Upper Mongogarie,New South Wales,NSW,-28.9833,152.85,4
2470,Lower Dyraaba,New South Wales,NSW,-28.8732,152.9852,3
2470,Woolners Arm,New South Wales,NSW,-28.6849,152.8415,4
2470,North Casino,New South Wales,NSW,-28.8732,152.9852,3
2470,Piora,New South Wales,NSW,-28.8549,152.8879,4
2470,Mongogarie,New South Wales,NSW,-28.95,152.9333,4
2471,Codrington,New South Wales,NSW,-28.9494,153.2418,4
2471,Bora Ridge,New South Wales,NSW,-28.9775,153.2497,3
2471,Tatham,New South Wales,NSW,-28.9282,153.1552,4
2471,Coraki,New South Wales,NSW,-28.9933,153.2821,4
2471,North Woodburn,New South Wales,NSW,-28.9775,153.2497,3
2471,Swan Bay,New South Wales,NSW,-29.05,153.3167,4
2471,Greenridge,New South Wales,NSW,-28.9775,153.2497,3
2471,East Coraki,New South Wales,NSW,-28.9775,153.2497,3
2471,West Coraki,New South Wales,NSW,-28.9775,153.2497,3
2471,West Bungawalbin,New South Wales,NSW,-28.9775,153.2497,3
2471,Green Forest,New South Wales,NSW,-28.9775,153.2497,3
2472,Rileys Hill,New South Wales,NSW,-29.0167,153.4,4
2472,Broadwater,New South Wales,NSW,-29.0111,153.4356,4
2472,Buckendoon,New South Wales,NSW,-29.0485,153.3512,4
2472,Esk,New South Wales,NSW,-29.0832,153.3442,3
2472,Woodburn,New South Wales,NSW,-29.0716,153.3461,4
2472,Tabbimoble,New South Wales,NSW,-29.1985,153.2679,4
2472,New Italy,New South Wales,NSW,-29.1318,153.2679,4
2472,Trustums Hill,New South Wales,NSW,-29.0832,153.3442,3
2472,The Gap,New South Wales,NSW,-29.0832,153.3442,3
2472,Kilgin,New South Wales,NSW,-29.0832,153.3442,3
2472,Moonem,New South Wales,NSW,-29.0832,153.3442,3
2473,Evans Head,New South Wales,NSW,-29.1178,153.4307,4
2473,South Evans Head,New South Wales,NSW,-29.1178,153.4307,4
2473,Bundjalung,New South Wales,NSW,-29.1178,153.4307,3
2473,Iron Gates,New South Wales,NSW,-29.1178,153.4307,3
2473,Doonbah,New South Wales,NSW,-29.1178,153.4307,3
2474,Cawongla,New South Wales,NSW,-28.6013,153.0974,4
2474,Findon Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Loadstone,New South Wales,NSW,-28.3907,152.9904,4
2474,Rukenvale,New South Wales,NSW,-28.4787,152.9119,3
2474,Green Pigeon,New South Wales,NSW,-28.4806,153.0856,4
2474,Roseberry Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Cedar Point,New South Wales,NSW,-28.6843,153.0021,4
2474,Sawpit Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Horseshoe Creek,New South Wales,NSW,-28.5352,153.0376,4
2474,Border Ranges,New South Wales,NSW,-28.536,152.9961,3
2474,Barkers Vale,New South Wales,NSW,-28.5366,153.1207,4
2474,Eden Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Roseberry,New South Wales,NSW,-28.4888,152.909,4
2474,Upper Eden Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Wadeville,New South Wales,NSW,-28.5607,153.1354,4
2474,Kilgra,New South Wales,NSW,-28.536,152.9961,3
2474,Homeleigh,New South Wales,NSW,-28.5685,153.0643,4
2474,Kyogle,New South Wales,NSW,-28.6208,153.0048,4
2474,The Risk,New South Wales,NSW,-28.4825,152.9398,4
2474,Edenville,New South Wales,NSW,-28.536,152.9961,3
2474,Upper Horseshoe Creek,New South Wales,NSW,-28.5387,153.0651,4
2474,Wiangaree,New South Wales,NSW,-28.5148,152.961,4
2474,Afterlee,New South Wales,NSW,-28.5741,152.8287,4
2474,Toonumbar,New South Wales,NSW,-28.5807,152.7629,4
2474,Sherwood,New South Wales,NSW,-31.0649,152.7288,4
2474,Gradys Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Cougal,New South Wales,NSW,-28.3667,152.9667,4
2474,Wyneden,New South Wales,NSW,-28.536,152.9961,3
2474,Geneva,New South Wales,NSW,-28.6219,152.989,4
2474,Ettrick,New South Wales,NSW,-28.6637,152.9125,4
2474,Dairy Flat,New South Wales,NSW,-28.536,152.9961,3
2474,New Park,New South Wales,NSW,-28.536,152.9961,3
2474,Smiths Creek,New South Wales,NSW,-28.4277,153.378,4
2474,Little Back Creek,New South Wales,NSW,-28.536,152.9961,3
2474,West Wiangaree,New South Wales,NSW,-28.536,152.9961,3
2474,Grevillia,New South Wales,NSW,-28.4431,152.8332,4
2474,Warrazambil Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Fawcetts Plain,New South Wales,NSW,-28.536,152.9961,3
2474,Unumgar,New South Wales,NSW,-28.4096,152.7429,4
2474,Iron Pot Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Ghinni Ghi,New South Wales,NSW,-28.536,152.9961,3
2474,Horse Station Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Terrace Creek,New South Wales,NSW,-28.536,152.9961,3
2474,Lynchs Creek,New South Wales,NSW,-28.45,153,4
2474,Old Grevillia,New South Wales,NSW,-28.536,152.9961,3
2474,Collins Creek,New South Wales,NSW,-28.536,152.9961,3
2475,Upper Tooloom,New South Wales,NSW,-28.545,152.483,3
2475,Tooloom,New South Wales,NSW,-28.6167,152.4167,4
2475,Urbenville,New South Wales,NSW,-28.4732,152.5494,4
2476,Koreelah,New South Wales,NSW,-28.4799,152.3275,4
2476,Old Koreelah,New South Wales,NSW,-28.4,152.4167,4
2476,Legume,New South Wales,NSW,-28.4081,152.3095,4
2476,Muli Muli,New South Wales,NSW,-28.4182,152.5834,4
2476,The Glen,New South Wales,NSW,-35.1145,148.2097,3
2476,Lindesay Creek,New South Wales,NSW,-28.4177,152.3993,3
2476,Acacia Creek,New South Wales,NSW,-28.3652,152.3179,4
2476,Acacia Plateau,New South Wales,NSW,-28.4516,152.3925,3
2476,Lower Acacia Creek,New South Wales,NSW,-28.4318,152.2346,4
2476,Brumby Plains,New South Wales,NSW,-28.4177,152.3993,3
2476,Woodenbong,New South Wales,NSW,-28.3892,152.6115,4
2476,Boomi Creek,New South Wales,NSW,-28.4177,152.3993,3
2477,Lynwood,New South Wales,NSW,-28.872,153.4425,4
2477,Wardell,New South Wales,NSW,-28.9489,153.4651,4
2477,Rous,New South Wales,NSW,-28.8764,153.3918,4
2477,Alstonvale,New South Wales,NSW,-28.8949,153.4304,3
2477,Rous Mill,New South Wales,NSW,-28.8764,153.3918,4
2477,Tuckombil,New South Wales,NSW,-28.8143,153.468,4
2477,Bagotville,New South Wales,NSW,-28.9828,153.4212,4
2477,Pearces Creek,New South Wales,NSW,-28.8949,153.4304,3
2477,Meerschaum Vale,New South Wales,NSW,-28.9167,153.4333,4
2477,Cabbage Tree Island,New South Wales,NSW,-28.9804,153.4567,4
2477,East Wardell,New South Wales,NSW,-28.8949,153.4304,3
2477,Wollongbar,New South Wales,NSW,-28.8251,153.4148,4
2477,Goat Island,New South Wales,NSW,-28.8949,153.4304,3
2477,Alstonville,New South Wales,NSW,-28.8419,153.4402,4
2477,Dalwood,New South Wales,NSW,-28.8923,153.4314,3
2477,Uralba,New South Wales,NSW,-28.8736,153.4713,3
2478,Empire Vale,New South Wales,NSW,-28.9163,153.5135,4
2478,East Ballina,New South Wales,NSW,-28.8667,153.5833,4
2478,Ballina,New South Wales,NSW,-28.8641,153.5654,4
2478,South Ballina,New South Wales,NSW,-28.8555,153.5391,3
2478,Cumbalum,New South Wales,NSW,-28.8167,153.5333,4
2478,Patchs Beach,New South Wales,NSW,-28.8555,153.5391,3
2478,Lennox Head,New South Wales,NSW,-28.7931,153.5939,4
2478,Pimlico Island,New South Wales,NSW,-28.8555,153.5391,3
2478,Pimlico,New South Wales,NSW,-28.9152,153.4846,4
2478,Keith Hall,New South Wales,NSW,-28.8763,153.5346,4
2478,Skennars Head,New South Wales,NSW,-28.8555,153.5391,3
2478,Tintenbar,New South Wales,NSW,-28.8,153.5167,4
2478,Coolgardie,New South Wales,NSW,-28.8555,153.5391,3
2478,West Ballina,New South Wales,NSW,-28.8641,153.5654,4
2478,Teven,New South Wales,NSW,-28.8167,153.5,4
2479,Bangalow,New South Wales,NSW,-28.6867,153.5239,4
2479,Brooklet,New South Wales,NSW,-28.7465,153.5087,4
2479,Binna Burra,New South Wales,NSW,-28.7,153.5,4
2479,Knockrow,New South Wales,NSW,-28.7667,153.5333,4
2479,Coopers Shoot,New South Wales,NSW,-28.6833,153.5833,4
2479,Coorabell,New South Wales,NSW,-28.6333,153.4833,4
2479,Newrybar,New South Wales,NSW,-28.7167,153.5333,4
2479,Mcleods Shoot,New South Wales,NSW,-28.7133,153.5156,3
2479,Nashua,New South Wales,NSW,-28.7286,153.4646,4
2479,Possum Creek,New South Wales,NSW,-28.7133,153.5156,3
2479,Fernleigh,New South Wales,NSW,-28.7667,153.5,4
2480,Tucki Tucki,New South Wales,NSW,-28.9333,153.3167,4
2480,Marom Creek,New South Wales,NSW,-28.9041,153.3699,4
2480,East Lismore,New South Wales,NSW,-28.8193,153.2874,4
2480,Mcleans Ridges,New South Wales,NSW,-28.8,153.3833,4
2480,Lismore,New South Wales,NSW,-28.8135,153.2773,4
2480,Bentley,New South Wales,NSW,-28.7667,153.1167,4
2480,South Gundurimba,New South Wales,NSW,-28.85,153.2667,3
2480,Blue Knob,New South Wales,NSW,-28.5,153.1833,4
2480,Blakebrook,New South Wales,NSW,-28.7667,153.2333,4
2480,Eltham,New South Wales,NSW,-28.7596,153.4084,4
2480,Rosebank,New South Wales,NSW,-28.6667,153.4,4
2480,Goonellabah,New South Wales,NSW,-28.8167,153.3167,4
2480,Bexhill,New South Wales,NSW,-28.7667,153.35,4
2480,Clovass,New South Wales,NSW,-28.8667,153.15,4
2480,South Lismore,New South Wales,NSW,-28.7522,153.2836,3
2480,The Channon,New South Wales,NSW,-28.6667,153.2833,4
2480,Tregeagle,New South Wales,NSW,-28.85,153.3667,4
2480,Jiggi,New South Wales,NSW,-28.677,153.1843,4
2480,Chilcotts Grass,New South Wales,NSW,-28.7522,153.2836,3
2480,Nimbin,New South Wales,NSW,-28.5973,153.2225,4
2480,Modanville,New South Wales,NSW,-28.7219,153.2956,4
2480,Fernside,New South Wales,NSW,-28.7818,153.1679,4
2480,Keerrong,New South Wales,NSW,-28.7522,153.2836,3
2480,Bungabbee,New South Wales,NSW,-28.7828,153.1472,4
2480,Wyrallah,New South Wales,NSW,-28.9,153.3,4
2480,Koonorigan,New South Wales,NSW,-28.6833,153.2333,4
2480,Lismore Heights,New South Wales,NSW,-28.7522,153.2836,3
2480,Booyong,New South Wales,NSW,-28.7484,153.4494,4
2480,Eureka,New South Wales,NSW,-28.6818,153.4346,4
2480,Lillian Rock,New South Wales,NSW,-28.5349,153.1585,4
2480,Repentance Creek,New South Wales,NSW,-28.7522,153.2836,3
2480,Goolmangar,New South Wales,NSW,-28.75,153.2167,4
2480,Tuncester,New South Wales,NSW,-28.8,153.2167,4
2480,Whian Whian,New South Wales,NSW,-28.6396,153.3145,4
2480,Dorroughby,New South Wales,NSW,-28.6667,153.35,4
2480,Rock Valley,New South Wales,NSW,-28.7485,153.1679,4
2480,Corndale,New South Wales,NSW,-28.6985,153.3679,4
2480,Tullera,New South Wales,NSW,-28.7652,153.3012,4
2480,Clunes,New South Wales,NSW,-28.7281,153.4075,4
2480,North Lismore,New South Wales,NSW,-28.788,153.2772,4
2480,Caniaba,New South Wales,NSW,-28.8302,153.2036,4
2480,Numulgi,New South Wales,NSW,-28.7485,153.3346,4
2480,Richmond Hill,New South Wales,NSW,-28.7221,153.2565,3
2480,Larnook,New South Wales,NSW,-28.6516,153.1108,4
2480,Coffee Camp,New South Wales,NSW,-28.6569,153.2281,4
2480,Leycester,New South Wales,NSW,-28.7833,153.2,4
2480,Stony Chute,New South Wales,NSW,-28.7522,153.2836,3
2480,Monaltrie,New South Wales,NSW,-28.8521,153.2906,4
2480,Nightcap,New South Wales,NSW,-28.7522,153.2836,3
2480,Girards Hill,New South Wales,NSW,-28.8177,153.2787,4
2480,Mountain Top,New South Wales,NSW,-28.7522,153.2836,3
2480,Federal,New South Wales,NSW,-28.6485,153.4512,4
2480,Tuntable Creek,New South Wales,NSW,-28.7522,153.2836,3
2480,Georgica,New South Wales,NSW,-28.6606,153.1724,4
2480,Booerie Creek,New South Wales,NSW,-28.7522,153.2836,3
2480,Dungarubba,New South Wales,NSW,-28.9833,153.3833,4
2480,Boorabee Park,New South Wales,NSW,-28.7522,153.2836,3
2480,Mckees Hill,New South Wales,NSW,-28.8833,153.2,4
2480,Terania Creek,New South Wales,NSW,-28.7522,153.2836,3
2480,Ruthven,New South Wales,NSW,-28.9333,153.2833,4
2480,Lindendale,New South Wales,NSW,-28.7522,153.2836,3
2480,Dunoon,New South Wales,NSW,-28.6823,153.3175,4
2480,Loftville,New South Wales,NSW,-28.7522,153.2836,3
2480,Tuckurimba,New South Wales,NSW,-28.9667,153.3167,4
2480,Howards Grass,New South Wales,NSW,-28.7522,153.2836,3
2480,Boat Harbour,New South Wales,NSW,-28.3167,153.35,4
2480,Lagoon Grass,New South Wales,NSW,-28.7522,153.2836,3
2480,Woodlawn,New South Wales,NSW,-28.7522,153.2836,3
2481,Hayters Hill,New South Wales,NSW,-28.6309,153.5669,3
2481,Broken Head,New South Wales,NSW,-28.7172,153.5923,3
2481,Suffolk Park,New South Wales,NSW,-28.6881,153.6098,4
2481,Talofa,New South Wales,NSW,-28.6309,153.5669,3
2481,Ewingsdale,New South Wales,NSW,-28.6333,153.55,4
2481,Tyagarah,New South Wales,NSW,-28.6,153.55,4
2481,Byron Bay,New South Wales,NSW,-28.642,153.6119,4
2481,Skinners Shoot,New South Wales,NSW,-28.6309,153.5669,3
2481,Myocum,New South Wales,NSW,-28.596,153.5061,4
2482,Upper Wilsons Creek,New South Wales,NSW,-28.5657,153.4396,3
2482,Main Arm,New South Wales,NSW,-28.5657,153.4396,3
2482,Upper Coopers Creek,New South Wales,NSW,-28.5657,153.4396,3
2482,Goonengerry,New South Wales,NSW,-28.6107,153.44,4
2482,Montecollum,New South Wales,NSW,-28.5657,153.4396,3
2482,Huonbrook,New South Wales,NSW,-28.5485,153.3846,4
2482,Mullumbimby,New South Wales,NSW,-28.5524,153.4996,4
2482,Mullumbimby Creek,New South Wales,NSW,-28.5657,153.4396,3
2482,Upper Main Arm,New South Wales,NSW,-28.5657,153.4396,3
2482,Koonyum Range,New South Wales,NSW,-28.5657,153.4396,3
2482,Wanganui,New South Wales,NSW,-28.5657,153.4396,3
2482,Palmwoods,New South Wales,NSW,-28.5657,153.4396,3
2482,Wilsons Creek,New South Wales,NSW,-28.5606,153.4255,4
2483,Sleepy Hollow,New South Wales,NSW,-28.4177,153.5198,4
2483,Middle Pocket,New South Wales,NSW,-28.4833,153.5111,3
2483,Crabbes Creek,New South Wales,NSW,-28.461,153.4823,4
2483,Burringbar,New South Wales,NSW,-28.4358,153.4732,4
2483,Upper Burringbar,New South Wales,NSW,-28.45,153.45,4
2483,Wooyung,New South Wales,NSW,-28.4833,153.5111,3
2483,Ocean Shores,New South Wales,NSW,-28.5093,153.5376,4
2483,Mooball,New South Wales,NSW,-28.45,153.4833,4
2483,Yelgun,New South Wales,NSW,-28.48,153.4949,4
2483,The Pocket,New South Wales,NSW,-28.5167,153.4833,4
2483,Brunswick Heads,New South Wales,NSW,-28.5415,153.5481,4
2483,New Brighton,New South Wales,NSW,-28.5123,153.5491,4
2483,Billinudgel,New South Wales,NSW,-28.5,153.5333,4
2483,South Golden Beach,New South Wales,NSW,-28.498,153.547,4
2484,Dum Dum,New South Wales,NSW,-28.3833,153.3333,4
2484,Condong,New South Wales,NSW,-28.3188,153.4378,4
2484,Pumpenbil,New South Wales,NSW,-28.3784,153.1461,4
2484,Kielvale,New South Wales,NSW,-28.3333,153.4338,4
2484,Chillingham,New South Wales,NSW,-28.3135,153.2752,4
2484,Crystal Creek,New South Wales,NSW,-28.3143,153.3151,4
2484,Reserve Creek,New South Wales,NSW,-28.3503,153.4924,4
2484,Murwillumbah South,New South Wales,NSW,-28.3273,153.3934,4
2484,Murwillumbah,New South Wales,NSW,-28.3273,153.3934,4
2484,Kunghur,New South Wales,NSW,-28.4714,153.252,4
2484,Terragon,New South Wales,NSW,-28.4355,153.263,4
2484,Mebbin,New South Wales,NSW,-28.3665,153.3497,3
2484,Round Mountain,New South Wales,NSW,-28.3549,153.5443,4
2484,Fernvale,New South Wales,NSW,-28.3652,153.419,4
2484,Dunbible,New South Wales,NSW,-28.3884,153.4205,4
2484,Uki,New South Wales,NSW,-28.4141,153.3366,4
2484,Dungay,New South Wales,NSW,-28.2724,153.3585,4
2484,Mount Burrell,New South Wales,NSW,-28.5,153.2167,4
2484,Clothiers Creek,New South Wales,NSW,-28.3302,153.496,4
2484,Rowlands Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Zara,New South Wales,NSW,-28.3163,153.2585,4
2484,Dulguigan,New South Wales,NSW,-28.3665,153.3497,3
2484,Cudgera Creek,New South Wales,NSW,-28.3833,153.5167,4
2484,Tyalgum Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Limpinwood,New South Wales,NSW,-28.3076,153.2173,4
2484,Doon Doon,New South Wales,NSW,-28.5167,153.3,4
2484,Stokers Siding,New South Wales,NSW,-28.4085,153.4143,4
2484,Farrants Hill,New South Wales,NSW,-28.3665,153.3497,3
2484,Eungella,New South Wales,NSW,-28.3534,153.3161,4
2484,Urliup,New South Wales,NSW,-28.3665,153.3497,3
2484,Tomewin,New South Wales,NSW,-28.2446,153.3779,4
2484,Chowan Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Nobbys Creek,New South Wales,NSW,-28.2943,153.3376,4
2484,Smiths Creek,New South Wales,NSW,-28.4277,153.378,4
2484,Commissioners Creek,New South Wales,NSW,-28.4985,153.3321,4
2484,Hopkins Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Byangum,New South Wales,NSW,-28.35,153.3667,4
2484,North Arm,New South Wales,NSW,-28.3665,153.3497,3
2484,South Murwillumbah,New South Wales,NSW,-28.3273,153.3934,4
2484,Back Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Palmvale,New South Wales,NSW,-28.3667,153.4833,4
2484,Kunghur Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Numinbah,New South Wales,NSW,-28.2833,153.25,4
2484,Cedar Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Eviron,New South Wales,NSW,-28.3665,153.3497,3
2484,Byrrill Creek,New South Wales,NSW,-28.3665,153.3497,3
2484,Upper Crystal Creek,New South Wales,NSW,-28.2997,153.3014,4
2484,Mount Warning,New South Wales,NSW,-28.3665,153.3497,3
2484,Bray Park,New South Wales,NSW,-28.3414,153.3761,4
2484,Kynnumboon,New South Wales,NSW,-28.3665,153.3497,3
2484,Nunderi,New South Wales,NSW,-28.3221,153.4597,4
2484,Tygalgah,New South Wales,NSW,-28.3665,153.3497,3
2484,Brays Creek,New South Wales,NSW,-28.4,153.2,4
2484,Wardrop Valley,New South Wales,NSW,-28.3665,153.3497,3
2484,Midginbil,New South Wales,NSW,-28.4971,153.2596,4
2484,Tyalgum,New South Wales,NSW,-28.3567,153.2058,4
2485,Tweed Heads West,New South Wales,NSW,-28.1874,153.5228,4
2485,Tweed Heads,New South Wales,NSW,-28.1756,153.542,4
2486,Piggabeen,New South Wales,NSW,-28.1909,153.4504,4
2486,Carool,New South Wales,NSW,-28.2333,153.4167,4
2486,Bilambil Heights,New South Wales,NSW,-28.2161,153.4841,4
2486,Tweed Heads South,New South Wales,NSW,-28.1756,153.542,3
2486,Cobaki,New South Wales,NSW,-28.1985,153.4644,4
2486,Banora Point,New South Wales,NSW,-28.213,153.5363,4
2486,Upper Duroby,New South Wales,NSW,-28.2621,153.4718,4
2486,Glengarrie,New South Wales,NSW,-28.2173,153.4848,3
2486,Duroby,New South Wales,NSW,-28.2621,153.4718,4
2486,Terranora,New South Wales,NSW,-28.2383,153.5008,4
2486,Bilambil,New South Wales,NSW,-28.2266,153.4676,4
2486,Cobaki Lakes,New South Wales,NSW,-28.2173,153.4848,3
2486,Bungalora,New South Wales,NSW,-28.2173,153.4848,3
2487,Duranbah,New South Wales,NSW,-28.3167,153.5333,4
2487,Cudgen,New South Wales,NSW,-28.2667,153.55,4
2487,Kingscliff,New South Wales,NSW,-28.2598,153.5782,4
2487,Kings Forest,New South Wales,NSW,-28.2646,153.5474,3
2487,Fingal Head,New South Wales,NSW,-28.2018,153.5652,4
2487,Casuarina,New South Wales,NSW,-28.3004,153.5706,4
2487,Stotts Creek,New South Wales,NSW,-28.2821,153.509,4
2487,Chinderah,New South Wales,NSW,-28.2333,153.55,4
2488,Bogangar,New South Wales,NSW,-28.3294,153.5686,4
2488,Tanglewood,New South Wales,NSW,-28.3376,153.5335,4
2488,Cabarita Beach,New South Wales,NSW,-28.3336,153.5666,4
2489,Pottsville,New South Wales,NSW,-28.3885,153.5656,4
2489,Pottsville Beach,New South Wales,NSW,-28.3885,153.5656,4
2489,Hastings Point,New South Wales,NSW,-28.3641,153.5748,4
2490,North Tumbulgum,New South Wales,NSW,-28.2621,153.4718,4
2490,Tumbulgum,New South Wales,NSW,-28.2752,153.4628,4
2500,Wollongong,New South Wales,NSW,-34.424,150.8934,4
2500,Wollongong DC,New South Wales,NSW,-34.4295,150.8756,3
2500,Keiraville,New South Wales,NSW,-34.4167,150.8667,4
2500,Mount Keira,New South Wales,NSW,-34.4189,150.8542,4
2500,Gwynneville,New South Wales,NSW,-34.4167,150.8875,4
2500,Wollongong West,New South Wales,NSW,-34.4248,150.8642,4
2500,Mangerton,New South Wales,NSW,-34.4367,150.8717,4
2500,Spring Hill,New South Wales,NSW,-33.3988,149.1525,4
2500,North Wollongong,New South Wales,NSW,-34.4151,150.8986,4
2500,Coniston,New South Wales,NSW,-34.45,150.8833,4
2500,Mount Saint Thomas,New South Wales,NSW,-34.4433,150.8722,4
2500,West Wollongong,New South Wales,NSW,-34.4248,150.8642,4
2502,Primbee,New South Wales,NSW,-34.5036,150.8795,4
2502,Cringila,New South Wales,NSW,-34.4667,150.8833,4
2502,Warrawong,New South Wales,NSW,-34.485,150.8883,4
2502,Lake Heights,New South Wales,NSW,-34.4867,150.875,4
2505,Kemblawarra,New South Wales,NSW,-34.4944,150.89,4
2505,Port Kembla,New South Wales,NSW,-34.4818,150.9012,4
2506,Berkeley,New South Wales,NSW,-34.4833,150.85,4
2508,Maddens Plains,New South Wales,NSW,-34.2005,150.9802,3
2508,Coalcliff,New South Wales,NSW,-34.2457,150.9682,4
2508,Helensburgh,New South Wales,NSW,-34.1784,150.9952,4
2508,Otford,New South Wales,NSW,-34.2129,150.9982,4
2508,Woronora Dam,New South Wales,NSW,-34.1167,150.9333,4
2508,Darkes Forest,New South Wales,NSW,-34.2377,150.9195,4
2508,Stanwell Tops,New South Wales,NSW,-34.2193,150.9764,4
2508,Stanwell Park,New South Wales,NSW,-34.2261,150.9858,4
2508,Lilyvale,New South Wales,NSW,-34.1861,151.0165,4
2515,Coledale,New South Wales,NSW,-34.291,150.9447,4
2515,Thirroul,New South Wales,NSW,-34.316,150.9214,4
2515,Clifton,New South Wales,NSW,-34.25,150.9667,4
2515,Wombarra,New South Wales,NSW,-34.2833,150.95,4
2515,Scarborough,New South Wales,NSW,-34.2732,150.9541,4
2515,Austinmer,New South Wales,NSW,-34.3052,150.9334,4
2516,Bulli,New South Wales,NSW,-34.3383,150.9135,4
2517,Woonona,New South Wales,NSW,-34.3493,150.9144,4
2517,Woonona East,New South Wales,NSW,-34.3524,150.9044,3
2517,Russell Vale,New South Wales,NSW,-34.3554,150.8943,4
2518,Bellambi,New South Wales,NSW,-34.3667,150.9167,4
2518,Corrimal East,New South Wales,NSW,-34.3761,150.9108,4
2518,East Corrimal,New South Wales,NSW,-34.3761,150.9108,4
2518,Tarrawanna,New South Wales,NSW,-34.3815,150.888,4
2518,Corrimal,New South Wales,NSW,-34.3786,150.9036,4
2518,Towradgi,New South Wales,NSW,-34.3867,150.9028,4
2519,Balgownie,New South Wales,NSW,-34.3951,150.8805,4
2519,Fernhill,New South Wales,NSW,-34.17,151.0052,3
2519,Mount Ousley,New South Wales,NSW,-34.4021,150.8879,4
2519,Fairy Meadow,New South Wales,NSW,-34.393,150.8928,4
2519,Mount Pleasant,New South Wales,NSW,-34.3953,150.8666,4
2520,Wollongong,New South Wales,NSW,-34.424,150.8934,4
2521,South Coast Mc,New South Wales,NSW,-34.5262,150.8534,1
2522,University Of Wollongong,New South Wales,NSW,-34.5262,150.8534,1
2525,Figtree,New South Wales,NSW,-34.4356,150.8583,4
2526,Cordeaux,New South Wales,NSW,-34.4404,150.8375,4
2526,Cordeaux Heights,New South Wales,NSW,-34.4404,150.8375,4
2526,Kembla Heights,New South Wales,NSW,-34.4305,150.8069,4
2526,Kembla Grange,New South Wales,NSW,-34.4663,150.8067,4
2526,Mount Kembla,New South Wales,NSW,-34.4328,150.8205,3
2526,Farmborough Heights,New South Wales,NSW,-34.455,150.8131,4
2526,Unanderra,New South Wales,NSW,-34.4531,150.8475,4
2527,Tullimbar,New South Wales,NSW,-34.5833,150.75,4
2527,Tongarra,New South Wales,NSW,-34.5833,150.6833,4
2527,Calderwood,New South Wales,NSW,-34.5505,150.7113,4
2527,Yellow Rock,New South Wales,NSW,-34.6,150.7333,4
2527,Albion Park Rail,New South Wales,NSW,-34.5612,150.7968,4
2527,North Macquarie,New South Wales,NSW,-34.5845,150.7356,3
2527,Albion Park,New South Wales,NSW,-34.5713,150.7757,4
2527,Croom,New South Wales,NSW,-34.5943,150.8413,4
2528,Warilla,New South Wales,NSW,-34.5519,150.8583,4
2528,Lake Illawarra,New South Wales,NSW,-34.5466,150.8564,4
2528,Windang,New South Wales,NSW,-34.5333,150.8667,4
2528,Barrack Heights,New South Wales,NSW,-34.566,150.8552,4
2528,Mount Warrigal,New South Wales,NSW,-34.548,150.8375,4
2528,Barrack Point,New South Wales,NSW,-34.5628,150.8668,4
2529,Shell Cove,New South Wales,NSW,-34.5887,150.8725,4
2529,Shellharbour City Centre,New South Wales,NSW,-34.5636,150.8383,4
2529,Blackbutt,New South Wales,NSW,-34.5706,150.8396,4
2529,Oak Flats,New South Wales,NSW,-34.5623,150.8219,4
2529,Flinders,New South Wales,NSW,-34.5833,150.8552,4
2529,Shellharbour Square,New South Wales,NSW,-34.5753,150.8442,3
2529,Dunmore,New South Wales,NSW,-34.6,150.85,4
2529,Shellharbour,New South Wales,NSW,-34.5833,150.8667,4
2529,Oak Flats Dc,New South Wales,NSW,-34.5753,150.8442,3
2530,Penrose,New South Wales,NSW,-34.6707,150.2137,4
2530,Yallah,New South Wales,NSW,-34.5369,150.7852,4
2530,Koonawarra,New South Wales,NSW,-34.5021,150.8085,4
2530,Dombarton,New South Wales,NSW,-34.4586,150.7718,4
2530,Huntley,New South Wales,NSW,-34.4976,150.7421,4
2530,Brownsville,New South Wales,NSW,-34.4833,150.8,4
2530,Haywards Bay,New South Wales,NSW,-34.5339,150.7916,4
2530,Cleveland,New South Wales,NSW,-34.5492,150.4827,3
2530,Kanahooka,New South Wales,NSW,-34.4933,150.8188,4
2530,Dapto,New South Wales,NSW,-34.5,150.7833,4
2530,Marshall Mount,New South Wales,NSW,-34.55,150.75,4
2530,Wongawilli,New South Wales,NSW,-34.4775,150.7568,4
2530,Avondale,New South Wales,NSW,-34.5175,150.7501,4
2530,Horsley,New South Wales,NSW,-34.4876,150.778,4
2533,Jerrara,New South Wales,NSW,-34.6701,150.8231,4
2533,Kiama,New South Wales,NSW,-34.6683,150.8522,4
2533,Jamberoo,New South Wales,NSW,-34.6474,150.7746,4
2533,Curramore,New South Wales,NSW,-34.6234,150.7607,4
2533,Bombo,New South Wales,NSW,-34.6521,150.8596,4
2533,Kiama Heights,New South Wales,NSW,-34.6497,150.8256,3
2533,Minnamurra,New South Wales,NSW,-34.6333,150.85,4
2533,Kiama Downs,New South Wales,NSW,-34.6334,150.8546,4
2533,Saddleback Mountain,New South Wales,NSW,-34.6961,150.8169,4
2534,Broughton Village,New South Wales,NSW,-34.7667,150.75,4
2534,Willow Vale,New South Wales,NSW,-34.7381,150.7998,4
2534,Werri Beach,New South Wales,NSW,-34.7368,150.8309,4
2534,Toolijooa,New South Wales,NSW,-34.7649,150.7831,4
2534,Gerringong,New South Wales,NSW,-34.747,150.8281,4
2534,Rose Valley,New South Wales,NSW,-34.7235,150.8096,4
2534,Gerroa,New South Wales,NSW,-34.7691,150.8101,4
2534,Foxground,New South Wales,NSW,-34.7152,150.7679,4
2535,Coolangatta,New South Wales,NSW,-34.8528,150.7262,4
2535,Broughton,New South Wales,NSW,-34.75,150.7667,4
2535,Jaspers Brush,New South Wales,NSW,-34.8167,150.6667,4
2535,Far Meadow,New South Wales,NSW,-34.781,150.705,3
2535,Wattamolla,New South Wales,NSW,-34.7333,150.65,4
2535,Bellawongarah,New South Wales,NSW,-34.7667,150.6333,4
2535,Woodhill,New South Wales,NSW,-34.7333,150.7,4
2535,Budderoo,New South Wales,NSW,-34.7819,150.68,3
2535,Shoalhaven Heads,New South Wales,NSW,-34.8509,150.7451,4
2535,Berry Mountain,New South Wales,NSW,-34.781,150.705,3
2535,Broughton Vale,New South Wales,NSW,-34.75,150.7667,4
2535,Brogers Creek,New South Wales,NSW,-34.781,150.705,3
2535,Berry,New South Wales,NSW,-34.7751,150.6944,4
2535,Bundewallah,New South Wales,NSW,-34.781,150.705,3
2535,Back Forest,New South Wales,NSW,-34.781,150.705,3
2536,South Durras,New South Wales,NSW,-35.6513,150.2952,4
2536,Guerilla Bay,New South Wales,NSW,-35.6319,150.1637,3
2536,Catalina,New South Wales,NSW,-35.73,150.1849,4
2536,Denhams Beach,New South Wales,NSW,-35.7509,150.2114,4
2536,Maloneys Beach,New South Wales,NSW,-35.7059,150.248,3
2536,North Batemans Bay,New South Wales,NSW,-35.7003,150.1832,3
2536,Jeremadra,New South Wales,NSW,-35.8091,150.1366,3
2536,Pebbly Beach,New South Wales,NSW,-35.64,150.1533,3
2536,Surfside,New South Wales,NSW,-35.6953,150.1995,4
2536,Bimbimbie,New South Wales,NSW,-35.6319,150.1637,3
2536,Benandarah,New South Wales,NSW,-35.6333,150.25,4
2536,Buckenbowra,New South Wales,NSW,-35.7333,150.0667,4
2536,Long Beach,New South Wales,NSW,-35.7083,150.2448,4
2536,Surf Beach,New South Wales,NSW,-35.6319,150.1637,3
2536,Mogo,New South Wales,NSW,-35.7848,150.1417,4
2536,Depot Beach,New South Wales,NSW,-35.64,150.1533,3
2536,Lilli Pilli,New South Wales,NSW,-35.7759,150.228,4
2536,Runnyford,New South Wales,NSW,-35.6319,150.1637,3
2536,Batemans Bay,New South Wales,NSW,-35.7066,150.1754,4
2536,Sunshine Bay,New South Wales,NSW,-35.7454,150.2079,4
2536,Malua Bay,New South Wales,NSW,-35.7938,150.2283,4
2536,Batehaven,New South Wales,NSW,-35.7376,150.1984,4
2536,Nelligen,New South Wales,NSW,-35.6467,150.1362,4
2536,Woodlands,New South Wales,NSW,-35.3166,149.8863,4
2536,Rosedale,New South Wales,NSW,-35.816,150.2225,4
2536,East Lynne,New South Wales,NSW,-35.59,150.2927,4
2536,Currowan,New South Wales,NSW,-35.5833,150.1667,4
2536,Durras North,New South Wales,NSW,-35.6319,150.1637,3
2537,Congo,New South Wales,NSW,-35.9552,150.1575,4
2537,Meringo,New South Wales,NSW,-35.8613,149.9495,3
2537,Mossy Point,New South Wales,NSW,-35.8398,150.1804,4
2537,Moruya Heads,New South Wales,NSW,-35.9177,150.1537,4
2537,Bergalia,New South Wales,NSW,-35.9818,150.1012,4
2537,Deua,New South Wales,NSW,-35.8613,149.9495,3
2537,Bingie,New South Wales,NSW,-36.0105,150.1483,4
2537,Moruya,New South Wales,NSW,-35.9125,150.0814,4
2537,Mogendoura,New South Wales,NSW,-35.8652,150.0512,4
2537,Wamban,New South Wales,NSW,-35.9334,150.1203,3
2537,Broulee,New South Wales,NSW,-35.8547,150.1739,4
2537,Coila,New South Wales,NSW,-36.0068,150.0965,4
2537,Tuross Head,New South Wales,NSW,-36.0533,150.1332,4
2537,Deua River Valley,New South Wales,NSW,-35.8613,149.9495,3
2537,Tomakin,New South Wales,NSW,-35.8233,150.1879,4
2537,Kiora,New South Wales,NSW,-35.9333,150.0333,4
2537,Turlinjah,New South Wales,NSW,-36.0333,150.1,4
2538,Mogood,New South Wales,NSW,-35.4918,150.1905,4
2538,Little Forest,New South Wales,NSW,-35.4271,150.3108,3
2538,Brooman,New South Wales,NSW,-35.5,150.25,4
2538,Woodstock,New South Wales,NSW,-33.7446,148.8489,4
2538,Milton,New South Wales,NSW,-35.3164,150.4361,4
2538,Porters Creek,New South Wales,NSW,-35.4271,150.3108,3
2538,Morton,New South Wales,NSW,-35.4,150.3667,4
2538,Woodburn,New South Wales,NSW,-35.4271,150.3108,3
2539,Conjola,New South Wales,NSW,-35.2167,150.45,4
2539,Croobyar,New South Wales,NSW,-35.3549,150.447,3
2539,Bawley Point,New South Wales,NSW,-35.5078,150.3892,4
2539,Yatte Yattah,New South Wales,NSW,-35.2588,150.4258,4
2539,Burrill Lake,New South Wales,NSW,-35.3872,150.4495,4
2539,Kioloa,New South Wales,NSW,-35.5542,150.3789,4
2539,Fishermans Paradise,New South Wales,NSW,-35.2296,150.4449,4
2539,Berringer Lake,New South Wales,NSW,-35.2533,150.498,4
2539,Cunjurong Point,New South Wales,NSW,-35.2598,150.5049,3
2539,Pretty Beach,New South Wales,NSW,-33.5167,151.35,4
2539,Mollymook,New South Wales,NSW,-35.3297,150.4717,4
2539,Lake Tabourie,New South Wales,NSW,-35.4392,150.3983,4
2539,Bendalong,New South Wales,NSW,-35.2467,150.5284,4
2539,Cockwhy,New South Wales,NSW,-35.3549,150.447,3
2539,Conjola Park,New South Wales,NSW,-35.2631,150.4398,4
2539,Yadboro,New South Wales,NSW,-35.3549,150.447,3
2539,Ulladulla,New South Wales,NSW,-35.3591,150.4725,4
2539,Mollymook Beach,New South Wales,NSW,-35.3297,150.4717,4
2539,Termeil,New South Wales,NSW,-35.4667,150.35,4
2539,Pointer Mountain,New South Wales,NSW,-35.3549,150.447,3
2539,Manyana,New South Wales,NSW,-35.2551,150.5137,4
2539,Narrawallee,New South Wales,NSW,-35.3124,150.4635,4
2539,Lake Conjola,New South Wales,NSW,-35.2681,150.4908,4
2539,Kings Point,New South Wales,NSW,-35.3702,150.4341,4
2539,Dolphin Point,New South Wales,NSW,-35.4018,150.4436,4
2539,Mount Kingiman,New South Wales,NSW,-35.3549,150.447,3
2540,Culburra Beach,New South Wales,NSW,-34.9303,150.7587,3
2540,Tomerong,New South Wales,NSW,-35.0523,150.5865,4
2540,Sussex Inlet,New South Wales,NSW,-35.1567,150.5853,4
2540,Currarong,New South Wales,NSW,-35.0151,150.8269,4
2540,Worrigee,New South Wales,NSW,-34.8904,150.6261,3
2540,Cambewarra Village,New South Wales,NSW,-35.0169,150.6319,3
2540,Cudmirrah,New South Wales,NSW,-35.198,150.5562,4
2540,Terara,New South Wales,NSW,-34.8833,150.6333,4
2540,Meroo Meadow,New South Wales,NSW,-34.8099,150.6184,3
2540,Browns Mountain,New South Wales,NSW,-35.0169,150.6319,3
2540,Illaroo,New South Wales,NSW,-35.0169,150.6319,3
2540,Old Erowal Bay,New South Wales,NSW,-35.0846,150.6457,4
2540,Worrowing Heights,New South Wales,NSW,-35.077,150.6406,4
2540,Twelve Mile Peg,New South Wales,NSW,-35.0169,150.6319,3
2540,Hyams Beach,New South Wales,NSW,-35.1014,150.6924,4
2540,Brundee,New South Wales,NSW,-34.9,150.6833,4
2540,Nowra Naval Po,New South Wales,NSW,-35.0169,150.6319,3
2540,Nowra Hill,New South Wales,NSW,-35.0169,150.6319,3
2540,Sanctuary Point,New South Wales,NSW,-35.1036,150.6267,4
2540,St Georges Basin,New South Wales,NSW,-35.0908,150.5978,3
2540,Pyree,New South Wales,NSW,-34.9167,150.7,4
2540,Moollattoo,New South Wales,NSW,-35.0169,150.6319,3
2540,Callala Beach,New South Wales,NSW,-35.0056,150.6958,4
2540,Myola,New South Wales,NSW,-35.0231,150.6758,4
2540,Orient Point,New South Wales,NSW,-34.9091,150.7497,4
2540,Wrights Beach,New South Wales,NSW,-35.028,150.5501,3
2540,Mundamia,New South Wales,NSW,-35.0169,150.6319,3
2540,Tullarwalla,New South Wales,NSW,-35.0169,150.6319,3
2540,Tallowal,New South Wales,NSW,-35.0169,150.6319,3
2540,Bream Beach,New South Wales,NSW,-35.0169,150.6319,3
2540,Callala Bay,New South Wales,NSW,-34.9967,150.7228,4
2540,Yalwal,New South Wales,NSW,-34.9333,150.4,4
2540,Boolijah,New South Wales,NSW,-35.0169,150.6319,3
2540,Yerriyong,New South Wales,NSW,-35.0169,150.6319,3
2540,Berrara,New South Wales,NSW,-35.205,150.5525,4
2540,Vincentia,New South Wales,NSW,-35.0682,150.6748,4
2540,Greenwell Point,New South Wales,NSW,-34.9075,150.7311,4
2540,Mondayong,New South Wales,NSW,-35.0169,150.6319,3
2540,Kinghorne,New South Wales,NSW,-34.9896,150.7837,4
2540,Wandandian,New South Wales,NSW,-35.1,150.5,4
2540,Falls Creek,New South Wales,NSW,-34.9667,150.6,4
2540,Ettrema,New South Wales,NSW,-35.0169,150.6319,3
2540,Woollamia,New South Wales,NSW,-35.0167,150.65,4
2540,Beecroft Peninsula,New South Wales,NSW,-35.0431,150.8071,4
2540,Wollumboola,New South Wales,NSW,-34.9884,150.7436,4
2540,Bewong,New South Wales,NSW,-35.0169,150.6319,3
2540,Erowal Bay,New South Wales,NSW,-35.0999,150.6505,4
2540,Burrier,New South Wales,NSW,-34.8833,150.4667,4
2540,Comerong Island,New South Wales,NSW,-34.8654,150.7365,4
2540,Swanhaven,New South Wales,NSW,-35.028,150.5501,3
2540,Basin View,New South Wales,NSW,-35.0883,150.5553,4
2540,Bamarang,New South Wales,NSW,-35.028,150.5501,3
2540,Bolong,New South Wales,NSW,-34.85,150.6667,4
2540,Numbaa,New South Wales,NSW,-35.028,150.5501,3
2540,Hmas Albatross,New South Wales,NSW,-35.028,150.5501,3
2540,Buangla,New South Wales,NSW,-35.0169,150.6319,3
2540,Barringella,New South Wales,NSW,-35.0169,150.6319,3
2540,Comberton,New South Wales,NSW,-35.0169,150.6319,3
2540,Longreach,New South Wales,NSW,-35.0169,150.6319,3
2540,Cambewarra,New South Wales,NSW,-34.8233,150.5594,4
2540,Jerrawangala,New South Wales,NSW,-35.1167,150.4833,4
2540,Watersleigh,New South Wales,NSW,-35.0169,150.6319,3
2540,Huskisson,New South Wales,NSW,-35.0401,150.668,4
2540,Parma,New South Wales,NSW,-35.0169,150.6319,3
2540,Tapitallee,New South Wales,NSW,-34.8298,150.5397,4
2540,Mayfield,New South Wales,NSW,-32.8979,151.7361,4
2541,Nowra Dc,New South Wales,NSW,-34.8545,150.6167,3
2541,Bomaderry,New South Wales,NSW,-34.8497,150.6109,4
2541,Nowra,New South Wales,NSW,-34.8842,150.6004,4
2541,West Nowra,New South Wales,NSW,-34.8727,150.6039,3
2541,South Nowra,New South Wales,NSW,-34.8842,150.6004,4
2541,Nowra East,New South Wales,NSW,-34.8727,150.6039,3
2541,North Nowra,New South Wales,NSW,-34.8727,150.6039,3
2541,Nowra North,New South Wales,NSW,-34.8727,150.6039,3
2541,Bangalee,New South Wales,NSW,-34.8545,150.6167,3
2545,Belowra,New South Wales,NSW,-36.199,149.7088,4
2545,Bodalla,New South Wales,NSW,-36.0912,150.0506,4
2545,Eurobodalla,New South Wales,NSW,-36.1485,149.9679,4
2545,Potato Point,New South Wales,NSW,-36.138,149.9085,3
2545,Cadgee,New South Wales,NSW,-36.1582,149.8996,4
2545,Nerrigundah,New South Wales,NSW,-36.1167,149.9,4
2546,Wallaga Lake Heights,New South Wales,NSW,-36.3684,150.0718,4
2546,Murrah,New South Wales,NSW,-36.5,150.0167,4
2546,Narooma,New South Wales,NSW,-36.2178,150.1325,4
2546,Tinpot,New South Wales,NSW,-36.2167,149.8667,4
2546,Akolele,New South Wales,NSW,-36.3555,150.0772,4
2546,Bermagui,New South Wales,NSW,-36.419,150.0636,4
2546,Corunna,New South Wales,NSW,-36.2818,150.1012,4
2546,Kianga,New South Wales,NSW,-36.1945,150.1268,4
2546,Central Tilba,New South Wales,NSW,-36.3131,150.0731,4
2546,Tilba Tilba,New South Wales,NSW,-36.3247,150.0599,4
2546,Dalmeny,New South Wales,NSW,-36.1661,150.1291,4
2546,Beauty Point,New South Wales,NSW,-36.2614,150.0075,3
2546,Wallaga Lake,New South Wales,NSW,-36.3684,150.0718,4
2546,Cuttagee,New South Wales,NSW,-36.2614,150.0075,3
2546,Dignams Creek,New South Wales,NSW,-36.3485,149.9846,4
2546,Barragga Bay,New South Wales,NSW,-36.2614,150.0075,3
2546,Wadbilliga,New South Wales,NSW,-36.2555,149.6512,4
2546,Mystery Bay,New South Wales,NSW,-36.3073,150.0259,3
2546,North Narooma,New South Wales,NSW,-36.2027,150.1191,3
2548,Bournda,New South Wales,NSW,-36.8763,149.9199,3
2548,Tura Beach,New South Wales,NSW,-36.8636,149.9303,4
2548,Merimbula,New South Wales,NSW,-36.889,149.9096,4
2548,Berrambool,New South Wales,NSW,-36.8911,149.9075,3
2548,Mirador,New South Wales,NSW,-36.8763,149.9199,3
2548,Yellow Pinch,New South Wales,NSW,-36.8763,149.9199,3
2549,Nethercote,New South Wales,NSW,-37.0152,149.8346,4
2549,Broadwater,New South Wales,NSW,-36.9354,149.8338,3
2549,Pambula Beach,New South Wales,NSW,-36.9417,149.9034,4
2549,Pambula,New South Wales,NSW,-36.9301,149.8745,4
2549,Greigs Flat,New South Wales,NSW,-36.9652,149.8679,4
2549,Millingandi,New South Wales,NSW,-36.8667,149.8333,4
2549,Lochiel,New South Wales,NSW,-36.9485,149.8179,4
2549,Bald Hills,New South Wales,NSW,-36.9117,149.8428,4
2549,South Pambula,New South Wales,NSW,-36.9301,149.8745,3
2550,Wog Wog,New South Wales,NSW,-36.714,149.7654,3
2550,Jellat Jellat,New South Wales,NSW,-36.7167,149.8833,4
2550,Nelson,New South Wales,NSW,-33.6462,150.9214,4
2550,Bournda,New South Wales,NSW,-36.8368,149.913,3
2550,Mumbulla Mountain,New South Wales,NSW,-36.7267,149.6829,3
2550,Burragate,New South Wales,NSW,-37,149.6167,4
2550,Coolangubra,New South Wales,NSW,-36.714,149.7654,3
2550,Wandella,New South Wales,NSW,-33.9137,146.0441,3
2550,Doctor George Mountain,New South Wales,NSW,-36.7267,149.6829,3
2550,Quaama,New South Wales,NSW,-36.4667,149.8667,4
2550,Greendale,New South Wales,NSW,-36.4255,149.9534,4
2550,Mogilla,New South Wales,NSW,-36.6985,149.5846,4
2550,Devils Hole,New South Wales,NSW,-36.714,149.7654,3
2550,Mogareeka,New South Wales,NSW,-36.6984,149.976,3
2550,Verona,New South Wales,NSW,-36.4667,149.8167,4
2550,Towamba,New South Wales,NSW,-37.0833,149.6833,4
2550,Reedy Swamp,New South Wales,NSW,-36.714,149.7654,3
2550,Cobargo,New South Wales,NSW,-36.3879,149.8863,4
2550,Stony Creek,New South Wales,NSW,-36.714,149.7654,3
2550,Kalaru,New South Wales,NSW,-36.7312,149.9294,4
2550,Myrtle Mountain,New South Wales,NSW,-36.714,149.7654,3
2550,Numbugga,New South Wales,NSW,-36.6333,149.75,4
2550,Yambulla,New South Wales,NSW,-37.2167,149.55,4
2550,Kameruka,New South Wales,NSW,-36.7318,149.7012,4
2550,Candelo,New South Wales,NSW,-36.7677,149.6947,4
2550,Bemboka,New South Wales,NSW,-36.6289,149.5718,4
2550,Tarraganda,New South Wales,NSW,-36.6609,149.8662,4
2550,Angledale,New South Wales,NSW,-36.6318,149.8846,4
2550,Rocky Hall,New South Wales,NSW,-36.9318,149.5096,4
2550,South Wolumla,New South Wales,NSW,-36.8152,149.7512,4
2550,Wapengo,New South Wales,NSW,-36.5985,149.9846,4
2550,Bega,New South Wales,NSW,-36.6739,149.8418,4
2550,Black Range,New South Wales,NSW,-36.714,149.7654,3
2550,Tanja,New South Wales,NSW,-36.6,149.9833,4
2550,Pericoe,New South Wales,NSW,-37.1167,149.6167,4
2550,Morans Crossing,New South Wales,NSW,-36.6667,149.65,4
2550,Tathra,New South Wales,NSW,-36.7313,149.9833,4
2550,Wolumla,New South Wales,NSW,-36.8308,149.8131,4
2550,Wallagoot,New South Wales,NSW,-36.714,149.7654,3
2550,Brogo,New South Wales,NSW,-36.5333,149.8333,4
2550,Tantawangalo,New South Wales,NSW,-36.714,149.7654,3
2550,Chinnock,New South Wales,NSW,-36.714,149.7654,3
2550,Kanoona,New South Wales,NSW,-36.714,149.7654,3
2550,New Buildings,New South Wales,NSW,-36.714,149.7654,3
2550,Frogs Hollow,New South Wales,NSW,-36.714,149.7654,3
2550,Buckajo,New South Wales,NSW,-36.7167,149.7167,4
2550,Wyndham,New South Wales,NSW,-36.9318,149.6512,4
2550,Yowrie,New South Wales,NSW,-36.3,149.7167,4
2550,Coolagolite,New South Wales,NSW,-36.714,149.7654,3
2550,Toothdale,New South Wales,NSW,-36.7818,149.7512,4
2550,Yankees Creek,New South Wales,NSW,-36.714,149.7654,3
2550,Kingswood,New South Wales,NSW,-33.75,150.75,4
2550,Coopers Gully,New South Wales,NSW,-36.714,149.7654,3
2551,Boydtown,New South Wales,NSW,-37.0833,149.9167,4
2551,Green Cape,New South Wales,NSW,-37.2032,149.7397,3
2551,Wonboyn,New South Wales,NSW,-37.2505,149.9382,4
2551,Kiah,New South Wales,NSW,-37.15,149.8667,4
2551,Eden,New South Wales,NSW,-37.0632,149.904,4
2551,Wonboyn North,New South Wales,NSW,-37.2032,149.7397,3
2551,Timbillica,New South Wales,NSW,-37.3652,149.7012,4
2551,Wonboyn Lake,New South Wales,NSW,-37.219,149.83,3
2551,Nungatta South,New South Wales,NSW,-37.2152,149.4012,4
2551,Edrom,New South Wales,NSW,-37.2032,149.7397,3
2551,Nungatta,New South Wales,NSW,-37.2152,149.4012,4
2551,Nadgee,New South Wales,NSW,-37.2032,149.7397,3
2551,Narrabarba,New South Wales,NSW,-37.2568,149.8179,4
2551,Nullica,New South Wales,NSW,-37.2032,149.7397,3
2555,Badgerys Creek,New South Wales,NSW,-33.8907,150.7427,4
2556,Bringelly,New South Wales,NSW,-33.9333,150.7333,4
2557,Rossmore,New South Wales,NSW,-33.9748,150.7634,3
2557,Catherine Field,New South Wales,NSW,-34.0044,150.7725,4
2557,Gregory Hills,New South Wales,NSW,-34.0267,150.7707,4
2557,Gledswood Hills,New South Wales,NSW,-34.0158,150.7651,4
2558,Eschol Park,New South Wales,NSW,-34.0298,150.8096,4
2558,Kearns,New South Wales,NSW,-34.0219,150.8008,4
2558,Eagle Vale,New South Wales,NSW,-34.0375,150.8136,4
2559,Claymore,New South Wales,NSW,-34.0465,150.8111,4
2559,Blairmount,New South Wales,NSW,-34.0508,150.7924,4
2560,Leumeah,New South Wales,NSW,-34.05,150.8333,4
2560,Englorie Park,New South Wales,NSW,-34.0804,150.7961,4
2560,Woodbine,New South Wales,NSW,-34.0459,150.8215,4
2560,Glen Alpine,New South Wales,NSW,-34.086,150.7851,4
2560,Kentlyn,New South Wales,NSW,-34.0604,150.8654,4
2560,Wedderburn,New South Wales,NSW,-34.1333,150.8167,4
2560,Macarthur Square,New South Wales,NSW,-34.0887,150.8165,3
2560,Blair Athol,New South Wales,NSW,-34.0621,150.8014,4
2560,Appin,New South Wales,NSW,-34.2035,150.7864,4
2560,Gilead,New South Wales,NSW,-34.1057,150.764,4
2560,Rosemeadow,New South Wales,NSW,-34.1041,150.7928,4
2560,Airds,New South Wales,NSW,-34.086,150.8332,4
2560,Campbelltown North,New South Wales,NSW,-34.0887,150.8165,3
2560,Ruse,New South Wales,NSW,-34.0698,150.8417,4
2560,Cataract,New South Wales,NSW,-34.0887,150.8165,3
2560,Bradbury,New South Wales,NSW,-34.0854,150.8154,4
2560,St Helens Park,New South Wales,NSW,-34.1047,150.8137,4
2560,Campbelltown,New South Wales,NSW,-34.0667,150.8167,4
2560,Ambarvale,New South Wales,NSW,-34.0894,150.7966,4
2563,Menangle Park,New South Wales,NSW,-34.1044,150.7507,4
2564,Long Point,New South Wales,NSW,-34.0167,150.9,4
2564,Macquarie Fields,New South Wales,NSW,-33.9921,150.8931,4
2564,Glenquarie,New South Wales,NSW,-34.0044,150.8965,3
2565,Ingleburn,New South Wales,NSW,-34,150.8667,4
2565,Macquarie Links,New South Wales,NSW,-33.9845,150.8703,4
2565,Denham Court,New South Wales,NSW,-33.9831,150.8461,4
2565,Bardia,New South Wales,NSW,-34,150.8667,3
2566,Minto Heights,New South Wales,NSW,-34.0532,150.844,4
2566,Minto DC,New South Wales,NSW,-34.0188,150.8297,3
2566,St Andrews,New South Wales,NSW,-34.0224,150.8296,4
2566,Bow Bowing,New South Wales,NSW,-34.0165,150.8403,4
2566,Raby,New South Wales,NSW,-34.0185,150.8176,4
2566,Varroville,New South Wales,NSW,-34.0032,150.8111,4
2566,Minto,New South Wales,NSW,-34.0333,150.85,4
2567,Narellan Dc,New South Wales,NSW,-34.0474,150.7534,3
2567,Smeaton Grange,New South Wales,NSW,-34.0371,150.7577,4
2567,Harrington Park,New South Wales,NSW,-34.024,150.735,4
2567,Narellan,New South Wales,NSW,-34.0333,150.7333,4
2567,Currans Hill,New South Wales,NSW,-34.0431,150.773,4
2567,Mount Annan,New South Wales,NSW,-34.0529,150.7598,4
2567,Narellan Vale,New South Wales,NSW,-34.0497,150.7439,4
2568,Menangle,New South Wales,NSW,-34.1268,150.7387,4
2569,Douglas Park,New South Wales,NSW,-34.185,150.7145,4
2570,Glenmore,New South Wales,NSW,-33.7907,150.6693,3
2570,Werombi,New South Wales,NSW,-33.9833,150.5833,4
2570,Orangeville,New South Wales,NSW,-34.0311,150.6018,4
2570,Grasmere,New South Wales,NSW,-34.0539,150.666,4
2570,Camden Park,New South Wales,NSW,-34.1241,150.4295,3
2570,Brownlow Hill,New South Wales,NSW,-34.0333,150.6667,4
2570,Mount Hunter,New South Wales,NSW,-34.0717,150.6378,4
2570,Oakdale,New South Wales,NSW,-34.0793,150.5132,4
2570,Camden South,New South Wales,NSW,-34.0831,150.6949,4
2570,Spring Farm,New South Wales,NSW,-34.0685,150.7118,4
2570,Theresa Park,New South Wales,NSW,-34.0068,150.6346,4
2570,Bickley Vale,New South Wales,NSW,-34.0733,150.6644,4
2570,Nattai,New South Wales,NSW,-34.1241,150.4295,3
2570,Oran Park,New South Wales,NSW,-34.0056,150.7403,4
2570,Cawdor,New South Wales,NSW,-34.1,150.6667,4
2570,Cobbitty,New South Wales,NSW,-34.0159,150.6785,4
2570,Belimbla Park,New South Wales,NSW,-34.0841,150.5398,4
2570,Ellis Lane,New South Wales,NSW,-34.0329,150.6782,4
2570,Elderslie,New South Wales,NSW,-34.05,150.7,4
2570,Camden,New South Wales,NSW,-34.054,150.6962,4
2570,The Oaks,New South Wales,NSW,-34.0799,150.57,4
2570,Kirkham,New South Wales,NSW,-34.0388,150.7111,4
2571,Balmoral Village,New South Wales,NSW,-34.3149,150.5165,4
2571,Balmoral,New South Wales,NSW,-34.3077,150.521,4
2571,Maldon,New South Wales,NSW,-34.2,150.6333,4
2571,Wilton,New South Wales,NSW,-34.2405,150.6977,4
2571,Couridjah,New South Wales,NSW,-34.2336,150.5513,4
2571,Razorback,New South Wales,NSW,-34.1524,150.6531,4
2571,Picton,New South Wales,NSW,-34.1699,150.6117,4
2571,Buxton,New South Wales,NSW,-34.2602,150.5329,4
2571,Mowbray Park,New South Wales,NSW,-34.15,150.55,4
2572,Thirlmere,New South Wales,NSW,-34.2046,150.5677,4
2572,Lakesland,New South Wales,NSW,-34.1652,150.5346,4
2573,Tahmoor,New South Wales,NSW,-34.2225,150.5947,4
2574,Pheasants Nest,New South Wales,NSW,-34.2558,150.634,4
2574,Avon,New South Wales,NSW,-34.4271,150.7012,4
2574,Bargo,New South Wales,NSW,-34.293,150.5781,4
2574,Yanderra,New South Wales,NSW,-34.3196,150.5753,4
2575,Yerrinbool,New South Wales,NSW,-34.3688,150.5385,4
2575,Mount Lindsey,New South Wales,NSW,-34.4137,150.4398,3
2575,High Range,New South Wales,NSW,-34.3652,150.3012,4
2575,Mittagong,New South Wales,NSW,-34.45,150.4457,4
2575,Alpine,New South Wales,NSW,-34.4093,150.5296,4
2575,Willow Vale,New South Wales,NSW,-34.4351,150.47,4
2575,Colo Vale,New South Wales,NSW,-34.401,150.4857,4
2575,Mandemar,New South Wales,NSW,-34.4332,150.3026,4
2575,Aylmerton,New South Wales,NSW,-34.421,150.4973,4
2575,Wattle Ridge,New South Wales,NSW,-34.4137,150.4398,3
2575,Woodlands,New South Wales,NSW,-34.4057,150.3943,4
2575,Goodmans Ford,New South Wales,NSW,-34.4137,150.4398,3
2575,Renwick,New South Wales,NSW,-34.4137,150.4398,3
2575,Bullio,New South Wales,NSW,-34.4137,150.4398,3
2575,Braemar,New South Wales,NSW,-34.4274,150.484,4
2575,Welby,New South Wales,NSW,-34.4393,150.4306,4
2575,Balaclava,New South Wales,NSW,-34.4137,150.4398,3
2575,Joadja,New South Wales,NSW,-34.4167,150.2167,4
2575,Hill Top,New South Wales,NSW,-34.3479,150.4955,4
2576,East Bowral,New South Wales,NSW,-34.5255,150.4874,3
2576,Burradoo,New South Wales,NSW,-34.5032,150.409,4
2576,Bowral,New South Wales,NSW,-34.4775,150.4204,4
2576,East Kangaloon,New South Wales,NSW,-34.5667,150.5833,4
2576,Glenquarry,New South Wales,NSW,-34.5263,150.4846,4
2576,Kangaloon,New South Wales,NSW,-34.546,150.544,4
2577,Paddys River,New South Wales,NSW,-35.764,148.1853,4
2577,Kangaroo Valley,New South Wales,NSW,-34.7381,150.5366,4
2577,Burrawang,New South Wales,NSW,-34.5853,150.5115,4
2577,Medway,New South Wales,NSW,-34.4916,150.2823,4
2577,Budgong,New South Wales,NSW,-34.6192,150.3835,3
2577,Berrima,New South Wales,NSW,-34.4877,150.339,4
2577,New Berrima,New South Wales,NSW,-34.4877,150.339,4
2577,Upper Kangaroo River,New South Wales,NSW,-34.694,150.5986,4
2577,Yarrunga,New South Wales,NSW,-34.7227,150.3099,3
2577,Sutton Forest,New South Wales,NSW,-34.5833,150.3167,4
2577,Carrington Falls,New South Wales,NSW,-34.6192,150.3835,3
2577,Barrengarry,New South Wales,NSW,-34.6833,150.5167,4
2577,Macquarie Pass,New South Wales,NSW,-34.6192,150.3835,3
2577,Belanglo,New South Wales,NSW,-34.6192,150.3835,3
2577,Meryla,New South Wales,NSW,-34.6192,150.3835,3
2577,Beaumont,New South Wales,NSW,-34.7833,150.5667,4
2577,Werai,New South Wales,NSW,-34.6192,150.3835,3
2577,Canyonleigh,New South Wales,NSW,-34.6192,150.3835,3
2577,Knights Hill,New South Wales,NSW,-34.6192,150.3835,3
2577,Robertson,New South Wales,NSW,-34.5874,150.5912,4
2577,Wildes Meadow,New South Wales,NSW,-34.623,150.5262,4
2577,Avoca,New South Wales,NSW,-34.5953,149.2917,4
2577,Moss Vale,New South Wales,NSW,-34.5537,150.3712,4
2577,Barren Grounds,New South Wales,NSW,-34.6192,150.3835,3
2577,Mount Murray,New South Wales,NSW,-34.6192,150.3835,3
2577,Red Rocks,New South Wales,NSW,-34.6192,150.3835,3
2577,Fitzroy Falls,New South Wales,NSW,-34.65,150.4833,4
2577,Manchester Square,New South Wales,NSW,-34.6192,150.3835,3
2577,Upper Kangaroo Valley,New South Wales,NSW,-34.694,150.5986,4
2578,Bundanoon,New South Wales,NSW,-34.6566,150.2962,4
2579,Brayton,New South Wales,NSW,-34.6487,150.2702,3
2579,Exeter,New South Wales,NSW,-34.6128,150.3208,4
2579,Marulan,New South Wales,NSW,-34.7084,150.0097,4
2579,Tallong,New South Wales,NSW,-34.7167,150.0833,4
2579,Wingello,New South Wales,NSW,-34.6948,150.1582,4
2579,Penrose,New South Wales,NSW,-34.5107,150.7791,4
2579,Big Hill,New South Wales,NSW,-34.6487,150.2702,3
2580,Carrick,New South Wales,NSW,-34.7,149.8833,4
2580,Bannaby,New South Wales,NSW,-34.45,149.9833,4
2580,Richlands,New South Wales,NSW,-34.3167,149.8167,4
2580,Mayfield,New South Wales,NSW,-32.8979,151.7361,4
2580,Chatsbury,New South Wales,NSW,-34.5485,149.8179,4
2580,Middle Arm,New South Wales,NSW,-34.7108,149.7312,3
2580,Wayo,New South Wales,NSW,-34.599,149.6377,4
2580,Towrang,New South Wales,NSW,-34.7,149.85,4
2580,Gundary,New South Wales,NSW,-34.8318,149.7512,4
2580,Curraweela,New South Wales,NSW,-34.7108,149.7312,3
2580,Myrtleville,New South Wales,NSW,-34.4818,149.8179,4
2580,Taralga,New South Wales,NSW,-34.404,149.8196,4
2580,Currawang,New South Wales,NSW,-34.9833,149.5167,4
2580,Goulburn North,New South Wales,NSW,-34.7108,149.7312,3
2580,Yarra,New South Wales,NSW,-34.7833,149.6333,4
2580,Quialigo,New South Wales,NSW,-34.9281,149.7431,4
2580,Windellama,New South Wales,NSW,-35.022,149.8728,4
2580,Kingsdale,New South Wales,NSW,-34.6535,149.7676,3
2580,Yalbraith,New South Wales,NSW,-34.3,149.75,4
2580,Baw Baw,New South Wales,NSW,-34.75,149.6667,4
2580,Tarlo,New South Wales,NSW,-34.6,149.8,4
2580,Stonequarry,New South Wales,NSW,-34.7108,149.7312,3
2580,Bungonia,New South Wales,NSW,-34.8485,149.9512,4
2580,Mount Fairy,New South Wales,NSW,-35.1833,149.6,4
2580,Goulburn,New South Wales,NSW,-34.7515,149.7209,4
2580,Wombeyan Caves,New South Wales,NSW,-34.3296,150.0227,3
2580,Pomeroy,New South Wales,NSW,-34.6568,149.5012,4
2580,Run-O-Waters,New South Wales,NSW,-34.7665,149.6702,4
2580,Goulburn DC,New South Wales,NSW,-34.7108,149.7312,3
2580,Tarago,New South Wales,NSW,-35.0978,149.526,4
2580,Golspie,New South Wales,NSW,-34.2985,149.6679,4
2580,Lower Boro,New South Wales,NSW,-35.1667,149.7667,4
2580,Woodhouselee,New South Wales,NSW,-34.55,149.6333,4
2580,Tirrannaville,New South Wales,NSW,-34.8167,149.6833,4
2580,Bannister,New South Wales,NSW,-34.5985,149.4929,4
2580,Roslyn,New South Wales,NSW,-36.6776,149.0173,4
2580,Paling Yards,New South Wales,NSW,-34.7108,149.7312,3
2580,Greenwich Park,New South Wales,NSW,-34.5818,149.9346,4
2580,Jerrong,New South Wales,NSW,-34.7108,149.7312,3
2580,Mummel,New South Wales,NSW,-34.7108,149.7312,3
2580,Lake Bathurst,New South Wales,NSW,-35.0167,149.65,4
2580,Parkesbourne,New South Wales,NSW,-34.7108,149.7312,3
2580,Brisbane Grove,New South Wales,NSW,-34.7108,149.7312,3
2580,Boxers Creek,New South Wales,NSW,-34.7108,149.7312,3
2580,Wiarborough,New South Wales,NSW,-34.7108,149.7312,3
2581,Dalton,New South Wales,NSW,-34.718,149.1932,4
2581,Broadway,New South Wales,NSW,-34.7466,149.305,3
2581,Gurrundah,New South Wales,NSW,-34.661,149.4068,4
2581,Lake George,New South Wales,NSW,-35.1452,149.4752,4
2581,Blakney Creek,New South Wales,NSW,-34.633,149.0212,4
2581,Merrill,New South Wales,NSW,-34.7466,149.305,3
2581,Biala,New South Wales,NSW,-34.5946,149.2626,4
2581,Bellmount Forest,New South Wales,NSW,-34.7466,149.305,3
2581,Oolong,New South Wales,NSW,-34.7907,149.179,4
2581,Wollogorang,New South Wales,NSW,-34.7466,149.305,3
2581,Breadalbane,New South Wales,NSW,-34.8,149.4833,4
2581,Bevendale,New South Wales,NSW,-34.5333,149.1167,4
2581,Collector,New South Wales,NSW,-34.9167,149.4333,4
2581,Lade Vale,New South Wales,NSW,-34.7466,149.305,3
2581,Gunning,New South Wales,NSW,-34.7825,149.2662,4
2581,Lerida,New South Wales,NSW,-34.8833,149.3333,4
2581,Cullerin,New South Wales,NSW,-34.7833,149.4167,4
2582,Mullion,New South Wales,NSW,-33.1252,149.1276,4
2582,Jeir,New South Wales,NSW,-35.0563,148.9719,4
2582,Yass,New South Wales,NSW,-34.8404,148.9099,4
2582,Jerrawa,New South Wales,NSW,-34.7788,149.0748,4
2582,Cavan,New South Wales,NSW,-35.0129,148.7983,4
2582,Good Hope,New South Wales,NSW,-34.9167,148.8167,4
2582,Wee Jasper,New South Wales,NSW,-35.15,148.7,4
2582,Bango,New South Wales,NSW,-34.7363,148.8274,3
2582,Bowning,New South Wales,NSW,-34.7449,148.809,4
2582,Manton,New South Wales,NSW,-34.7363,148.8274,3
2582,Bookham,New South Wales,NSW,-34.8126,148.6396,4
2582,Boambolo,New South Wales,NSW,-34.7363,148.8274,3
2582,Kangiara,New South Wales,NSW,-34.5985,148.7512,4
2582,Yass River,New South Wales,NSW,-34.7363,148.8274,3
2582,Laverstock,New South Wales,NSW,-34.7363,148.8274,3
2582,Marchmont,New South Wales,NSW,-34.7363,148.8274,3
2582,Murrumbateman,New South Wales,NSW,-34.972,149.0298,4
2582,Burrinjuck,New South Wales,NSW,-35,148.6,4
2582,Berremangra,New South Wales,NSW,-34.8,148.4833,4
2582,Woolgarlo,New South Wales,NSW,-34.7363,148.8274,3
2582,Narrangullen,New South Wales,NSW,-34.7363,148.8274,3
2583,Binda,New South Wales,NSW,-34.3333,149.3667,4
2583,Laggan,New South Wales,NSW,-34.4167,149.5333,4
2583,Narrawa,New South Wales,NSW,-34.4152,149.0846,4
2583,Limerick,New South Wales,NSW,-34.1929,149.2818,3
2583,Grabben Gullen,New South Wales,NSW,-34.5402,149.4012,4
2583,Crookwell,New South Wales,NSW,-34.4593,149.4714,4
2583,Pejar,New South Wales,NSW,-34.5833,149.5833,4
2583,Rugby,New South Wales,NSW,-34.3833,149.0167,4
2583,Tuena,New South Wales,NSW,-34.0235,149.3262,4
2583,Lost River,New South Wales,NSW,-34.3411,149.348,3
2583,Bigga,New South Wales,NSW,-34.0833,149.15,4
2583,Crooked Corner,New South Wales,NSW,-34.1929,149.2818,3
2583,Wheeo,New South Wales,NSW,-34.5,149.2833,4
2583,Blanket Flat,New South Wales,NSW,-34.15,149.2167,4
2583,Kialla,New South Wales,NSW,-34.5333,149.45,4
2583,Peelwood,New South Wales,NSW,-34.1068,149.4346,4
2583,Fullerton,New South Wales,NSW,-34.2333,149.55,4
2584,Binalong,New South Wales,NSW,-34.6717,148.6348,4
2585,Galong,New South Wales,NSW,-34.6,148.55,4
2586,Boorowa,New South Wales,NSW,-34.4366,148.7163,4
2586,Frogmore,New South Wales,NSW,-34.2667,148.85,4
2586,Murringo,New South Wales,NSW,-34.3,148.5167,4
2586,Godfreys Creek,New South Wales,NSW,-34.1485,148.7012,4
2586,Taylors Flat,New South Wales,NSW,-34.2735,149.0179,4
2586,Rye Park,New South Wales,NSW,-34.5152,148.9179,4
2586,Reids Flat,New South Wales,NSW,-34.1223,149.0785,4
2587,Wombat,New South Wales,NSW,-34.4167,148.25,4
2587,Cunningar,New South Wales,NSW,-34.5607,148.4393,4
2587,Murrumburrah,New South Wales,NSW,-34.5541,148.3676,4
2587,Nubba,New South Wales,NSW,-34.5329,148.2352,4
2587,Beggan Beggan,New South Wales,NSW,-34.6818,148.2679,4
2587,Kingsvale,New South Wales,NSW,-34.4333,148.3167,4
2587,Harden,New South Wales,NSW,-34.5541,148.3676,4
2587,Mcmahons Reef,New South Wales,NSW,-34.6667,148.45,4
2588,Wallendbeen,New South Wales,NSW,-34.5333,148.15,4
2590,Bethungra,New South Wales,NSW,-34.761,147.8554,4
2590,Cootamundra,New South Wales,NSW,-34.6409,148.0284,4
2590,Illabo,New South Wales,NSW,-34.8318,147.7537,4
2594,Burrangong,New South Wales,NSW,-34.2916,148.2481,4
2594,Young,New South Wales,NSW,-34.3135,148.3011,4
2594,Bulla Creek,New South Wales,NSW,-34.2339,148.1092,3
2594,Milvale,New South Wales,NSW,-34.3167,147.9167,4
2594,Monteagle,New South Wales,NSW,-34.1833,148.35,4
2594,Maimuru,New South Wales,NSW,-34.2667,148.2667,4
2594,Barwang,New South Wales,NSW,-34.2339,148.1092,3
2594,Tubbul,New South Wales,NSW,-34.2652,148.0012,4
2594,Kikiamah,New South Wales,NSW,-34.2339,148.1092,3
2594,Weedallion,New South Wales,NSW,-34.1985,147.9512,4
2594,Memagong,New South Wales,NSW,-34.2339,148.1092,3
2594,Bribbaree,New South Wales,NSW,-34.1199,147.8792,4
2594,Berthong,New South Wales,NSW,-34.2339,148.1092,3
2594,Thuddungra,New South Wales,NSW,-34.15,148.1167,4
2611,Bimberi,New South Wales,NSW,-35.3503,149.0155,3
2611,Brindabella,New South Wales,NSW,-35.4,148.7333,4
2611,Cooleman,New South Wales,NSW,-35.3503,149.0155,3
2618,Springrange,New South Wales,NSW,-35.076,149.0889,4
2618,Nanima,New South Wales,NSW,-35.0129,149.1277,4
2618,Wallaroo,New South Wales,NSW,-35.1468,149.0128,4
2619,Jerrabomberra,New South Wales,NSW,-35.3844,149.2025,4
2620,Queanbeyan,New South Wales,NSW,-35.3549,149.232,4
2620,Yarrow,New South Wales,NSW,-35.3914,149.2166,3
2620,Tinderry,New South Wales,NSW,-35.7348,149.34,4
2620,Queanbeyan East,New South Wales,NSW,-35.3428,149.2444,3
2620,The Ridgeway,New South Wales,NSW,-35.3389,149.2609,4
2620,Sutton,New South Wales,NSW,-35.1667,149.25,4
2620,Williamsdale,New South Wales,NSW,-35.5667,149.1333,4
2620,Queanbeyan West,New South Wales,NSW,-35.3914,149.2166,3
2620,Karabar,New South Wales,NSW,-35.376,149.2328,4
2620,Burra,New South Wales,NSW,-35.5594,149.2235,4
2620,Michelago,New South Wales,NSW,-35.7167,149.1667,4
2620,Tralee,New South Wales,NSW,-35.3914,149.2166,3
2620,Royalla,New South Wales,NSW,-35.5167,149.15,4
2620,Crestwood,New South Wales,NSW,-35.3484,149.2176,4
2620,Urila,New South Wales,NSW,-35.3914,149.2166,3
2620,Gundaroo,New South Wales,NSW,-35.0333,149.25,4
2620,Clear Range,New South Wales,NSW,-35.3914,149.2166,3
2620,Carwoola,New South Wales,NSW,-35.3784,149.3215,4
2620,The Angle,New South Wales,NSW,-35.3914,149.2166,3
2620,Wamboin,New South Wales,NSW,-35.2507,149.3328,4
2620,Environa,New South Wales,NSW,-35.3914,149.2166,3
2620,Greenleigh,New South Wales,NSW,-35.3667,149.2528,4
2620,Queanbeyan DC,New South Wales,NSW,-35.3914,149.2166,3
2620,Googong,New South Wales,NSW,-35.4387,149.2131,4
2621,Bungendore,New South Wales,NSW,-35.2538,149.4401,4
2621,Anembo,New South Wales,NSW,-35.3262,149.4297,3
2621,Bywong,New South Wales,NSW,-35.1648,149.3289,4
2621,Forbes Creek,New South Wales,NSW,-35.3262,149.4297,3
2621,Hoskinstown,New South Wales,NSW,-35.4167,149.45,4
2621,Primrose Valley,New South Wales,NSW,-35.3262,149.4297,3
2621,Rossi,New South Wales,NSW,-35.4667,149.5,4
2622,Bendoura,New South Wales,NSW,-35.5167,149.7,4
2622,Corang,New South Wales,NSW,-35.2,150.0167,4
2622,Braidwood,New South Wales,NSW,-35.4415,149.7998,4
2622,Ballalaba,New South Wales,NSW,-35.4771,149.7702,3
2622,Nerriga,New South Wales,NSW,-35.1167,150.0833,4
2622,Warri,New South Wales,NSW,-35.5617,149.2356,4
2622,Araluen,New South Wales,NSW,-35.6459,149.8174,4
2622,Jinden,New South Wales,NSW,-35.4068,149.8505,3
2622,Reidsdale,New South Wales,NSW,-35.5818,149.8512,4
2622,Mulloon,New South Wales,NSW,-35.2982,149.6088,4
2622,Boro,New South Wales,NSW,-35.15,149.6667,4
2622,Charleys Forest,New South Wales,NSW,-35.4068,149.8505,3
2622,Endrick,New South Wales,NSW,-35.4068,149.8505,3
2622,Back Creek,New South Wales,NSW,-35.4068,149.8505,3
2622,Oallen,New South Wales,NSW,-35.1665,149.9622,4
2622,Oranmeir,New South Wales,NSW,-35.4771,149.7702,3
2622,Krawarree,New South Wales,NSW,-35.8152,149.6346,4
2622,Snowball,New South Wales,NSW,-35.9333,149.5833,4
2622,Larbert,New South Wales,NSW,-35.2985,149.7679,4
2622,Palerang,New South Wales,NSW,-35.4068,149.8505,3
2622,Merricumbene,New South Wales,NSW,-35.7335,149.896,4
2622,Wog Wog,New South Wales,NSW,-35.4068,149.8505,3
2622,Touga,New South Wales,NSW,-34.9546,150.0911,4
2622,St George,New South Wales,NSW,-35.4068,149.8505,3
2622,Coolumburra,New South Wales,NSW,-35.4068,149.8505,3
2622,Gundillion,New South Wales,NSW,-35.4771,149.7702,3
2622,Manar,New South Wales,NSW,-35.3,149.7,4
2622,Mayfield,New South Wales,NSW,-32.8979,151.7361,4
2622,Northangera,New South Wales,NSW,-35.4068,149.8505,3
2622,Jembaicumbene,New South Wales,NSW,-35.4771,149.7702,3
2622,Jerrabattgulla,New South Wales,NSW,-35.4068,149.8505,3
2622,Farringdon,New South Wales,NSW,-35.4068,149.8505,3
2622,Tomboye,New South Wales,NSW,-35.4068,149.8505,3
2622,Wyanbene,New South Wales,NSW,-35.4068,149.8505,3
2622,Durran Durra,New South Wales,NSW,-35.4068,149.8505,3
2622,Quiera,New South Wales,NSW,-35.4068,149.8505,3
2622,Monga,New South Wales,NSW,-35.5755,149.9204,4
2622,Tolwong,New South Wales,NSW,-35.4068,149.8505,3
2622,Hereford Hall,New South Wales,NSW,-35.4068,149.8505,3
2622,Budawang,New South Wales,NSW,-35.4068,149.8505,3
2622,Jingera,New South Wales,NSW,-35.4068,149.8505,3
2622,Kindervale,New South Wales,NSW,-35.4068,149.8505,3
2622,Murrengenburg,New South Wales,NSW,-35.4068,149.8505,3
2622,Majors Creek,New South Wales,NSW,-35.5689,149.7455,4
2622,Sassafras,New South Wales,NSW,-35.1,150.25,4
2622,Marlowe,New South Wales,NSW,-35.3,149.8833,4
2622,Harolds Cross,New South Wales,NSW,-35.4068,149.8505,3
2622,Neringla,New South Wales,NSW,-35.4068,149.8505,3
2622,Bulee,New South Wales,NSW,-35.4068,149.8505,3
2622,Mongarlowe,New South Wales,NSW,-35.4333,149.9333,4
2622,Berlang,New South Wales,NSW,-35.4068,149.8505,3
2622,Tianjara,New South Wales,NSW,-35.1167,150.3333,4
2622,Bombay,New South Wales,NSW,-35.4068,149.8505,3
2623,Captains Flat,New South Wales,NSW,-35.5911,149.4452,4
2624,Charlottes Pass,New South Wales,NSW,-36.6077,148.3881,3
2624,Smiggin Holes,New South Wales,NSW,-36.3932,148.4337,4
2624,Perisher Valley,New South Wales,NSW,-36.406,148.4117,4
2625,Thredbo Village,New South Wales,NSW,-36.5052,148.3076,4
2625,Thredbo,New South Wales,NSW,-36.5052,148.3076,4
2626,Bredbo,New South Wales,NSW,-35.95,149.15,4
2626,Bumbalong,New South Wales,NSW,-35.9083,149.1583,3
2626,Colinton,New South Wales,NSW,-35.8667,149.1667,4
2627,Kosciuszko National Park,New South Wales,NSW,-36.2644,148.4818,4
2627,Ingebirah,New South Wales,NSW,-36.65,148.5,4
2627,Jindabyne,New South Wales,NSW,-36.4162,148.625,4
2627,Pilot Wilderness,New South Wales,NSW,-36.4102,148.5365,3
2627,Grosses Plain,New South Wales,NSW,-36.4102,148.5365,3
2627,Crackenback,New South Wales,NSW,-36.4429,148.5001,4
2627,East Jindabyne,New South Wales,NSW,-36.4102,148.5365,3
2627,Kalkite,New South Wales,NSW,-36.3318,148.6429,4
2627,Kosciuszko,New South Wales,NSW,-36.2644,148.4818,4
2627,Moonbah,New South Wales,NSW,-36.4818,148.5512,4
2628,Beloka,New South Wales,NSW,-36.5333,148.7333,4
2628,Eucumbene,New South Wales,NSW,-36.1383,148.6349,4
2628,Hill Top,New South Wales,NSW,-34.3479,150.4955,4
2628,Snowy Plain,New South Wales,NSW,-36.1167,148.8667,4
2628,Nimmo,New South Wales,NSW,-36.3943,148.7809,3
2628,Dalgety,New South Wales,NSW,-36.5,148.8333,4
2628,Berridale,New South Wales,NSW,-36.3657,148.8268,4
2628,Paupong,New South Wales,NSW,-36.5833,148.6833,4
2628,Byadbo Wilderness,New South Wales,NSW,-36.3943,148.7809,3
2628,Braemar Bay,New South Wales,NSW,-36.3943,148.7809,3
2628,Avonside,New South Wales,NSW,-36.3943,148.7809,3
2628,Rocky Plain,New South Wales,NSW,-36.3943,148.7809,3
2628,Cootralantra,New South Wales,NSW,-36.2652,148.8679,4
2628,Numbla Vale,New South Wales,NSW,-36.65,148.8,4
2629,Tantangara,New South Wales,NSW,-35.8167,148.5667,4
2629,Cabramurra,New South Wales,NSW,-35.9357,148.3839,4
2629,Anglers Reach,New South Wales,NSW,-36.0167,148.6667,4
2629,Adaminaby,New South Wales,NSW,-35.9969,148.7697,4
2629,Old Adaminaby,New South Wales,NSW,-36.0349,148.7016,4
2629,Long Plain,New South Wales,NSW,-35.9287,148.6269,3
2629,Kiandra,New South Wales,NSW,-35.8833,148.5,4
2629,Bolaro,New South Wales,NSW,-35.9287,148.6269,3
2629,Yaouk,New South Wales,NSW,-35.8152,148.8012,4
2629,Providence Portal,New South Wales,NSW,-35.9287,148.6269,3
2630,Rhine Falls,New South Wales,NSW,-36.1667,148.8667,4
2630,Bungarby,New South Wales,NSW,-36.2254,148.9852,3
2630,Bunyan,New South Wales,NSW,-36.1696,149.1538,3
2630,Cooma,New South Wales,NSW,-36.2352,149.1241,4
2630,Jerangle,New South Wales,NSW,-35.8667,149.3667,4
2630,Billilingra,New South Wales,NSW,-36.0398,149.249,3
2630,Maffra,New South Wales,NSW,-36.5318,148.9679,4
2630,Bobundara,New South Wales,NSW,-36.5167,148.9333,4
2630,Coolringdon,New South Wales,NSW,-36.2833,148.9833,4
2630,Frying Pan,New South Wales,NSW,-36.0398,149.249,3
2630,Badja,New South Wales,NSW,-36.0527,149.5498,4
2630,Middle Flat,New South Wales,NSW,-36.0398,149.249,3
2630,Numeralla,New South Wales,NSW,-36.1667,149.3333,4
2630,Peak View,New South Wales,NSW,-36.2254,148.9852,3
2630,Rock Flat,New South Wales,NSW,-36.35,149.2,4
2630,Binjura,New South Wales,NSW,-36.0398,149.249,3
2630,Dairymans Plains,New South Wales,NSW,-36.0398,149.249,3
2630,The Brothers,New South Wales,NSW,-36.0398,149.249,3
2630,Glen Fergus,New South Wales,NSW,-36.0398,149.249,3
2630,Dry Plain,New South Wales,NSW,-36.1167,148.8667,4
2630,Dangelong,New South Wales,NSW,-36.0398,149.249,3
2630,Springfield,New South Wales,NSW,-33.3433,149.2635,4
2630,Cooma North,New South Wales,NSW,-36.0398,149.249,3
2630,Countegany,New South Wales,NSW,-36.1833,149.45,4
2630,Middlingbank,New South Wales,NSW,-36.2167,148.8333,4
2630,Wambrook,New South Wales,NSW,-36.0398,149.249,3
2630,Buckenderra,New South Wales,NSW,-36.0398,149.249,3
2630,Murrumbucca,New South Wales,NSW,-36.0985,149.0512,4
2630,Polo Flat,New South Wales,NSW,-36.2315,149.151,4
2630,Pine Valley,New South Wales,NSW,-36.0398,149.249,3
2630,Rose Valley,New South Wales,NSW,-34.7235,150.8096,4
2630,Myalla,New South Wales,NSW,-36.4167,149.1167,4
2630,Tuross,New South Wales,NSW,-36.0533,150.1332,4
2630,Shannons Flat,New South Wales,NSW,-36.0398,149.249,3
2630,Carlaminda,New South Wales,NSW,-36.2601,149.3051,4
2630,Chakola,New South Wales,NSW,-36.0985,149.1679,4
2630,Ironmungy,New South Wales,NSW,-36.5833,148.9333,4
2630,Jimenbuen,New South Wales,NSW,-36.0398,149.249,3
2630,Arable,New South Wales,NSW,-36.35,149.0333,4
2631,Holts Flat,New South Wales,NSW,-36.6667,149.2833,4
2631,Winifred,New South Wales,NSW,-36.6223,149.3001,3
2631,Jincumbilly,New South Wales,NSW,-36.7167,149.2167,4
2631,Glen Allen,New South Wales,NSW,-36.6985,149.3679,4
2631,Mount Cooper,New South Wales,NSW,-36.6833,149.2167,4
2631,Greenlands,New South Wales,NSW,-36.6223,149.3001,3
2631,Steeple Flat,New South Wales,NSW,-36.5485,149.3846,4
2631,Nimmitabel,New South Wales,NSW,-36.5119,149.2838,4
2631,Boco,New South Wales,NSW,-36.6223,149.3001,3
2631,Ando,New South Wales,NSW,-36.7167,149.25,4
2631,Creewah,New South Wales,NSW,-36.75,149.3667,4
2631,Kybeyan,New South Wales,NSW,-36.3485,149.4179,4
2632,Cathcart,New South Wales,NSW,-36.8333,149.3667,4
2632,Paddys Flat,New South Wales,NSW,-36.9232,149.2451,3
2632,Rockton,New South Wales,NSW,-37.1318,149.3179,4
2632,Palarang,New South Wales,NSW,-36.9232,149.2451,3
2632,Bukalong,New South Wales,NSW,-36.7985,149.2012,4
2632,Lords Hill,New South Wales,NSW,-36.9232,149.2451,3
2632,Mount Darragh,New South Wales,NSW,-36.8485,149.5179,4
2632,Gunningrah,New South Wales,NSW,-36.9232,149.2451,3
2632,Mila,New South Wales,NSW,-37.0485,149.1679,4
2632,Merriangaah,New South Wales,NSW,-36.9232,149.2451,3
2632,Cambalong,New South Wales,NSW,-36.8167,149.0833,4
2632,Rosemeath,New South Wales,NSW,-36.9232,149.2451,3
2632,Bombala,New South Wales,NSW,-36.9121,149.2367,4
2632,Coolumbooka,New South Wales,NSW,-36.9232,149.2451,3
2632,Bondi Forest,New South Wales,NSW,-36.9232,149.2451,3
2632,Bibbenluke,New South Wales,NSW,-36.8134,149.2863,4
2632,Craigie,New South Wales,NSW,-37.0833,149.05,4
2632,Quidong,New South Wales,NSW,-36.9232,149.2451,3
2633,Corrowong,New South Wales,NSW,-36.9802,148.9291,3
2633,Tombong,New South Wales,NSW,-36.9,148.9319,4
2633,Delegate,New South Wales,NSW,-37.0438,148.9415,4
2640,Talmalmo,New South Wales,NSW,-35.9318,147.5012,4
2640,Table Top,New South Wales,NSW,-35.9667,147,4
2640,North Albury,New South Wales,NSW,-36.0575,146.9299,4
2640,Ournie,New South Wales,NSW,-35.9318,147.8512,4
2640,Moorwatha,New South Wales,NSW,-35.9833,146.7,4
2640,Thurgoona,New South Wales,NSW,-36.0363,146.9961,4
2640,Lavington Dc,New South Wales,NSW,-36.0351,147.0025,3
2640,Bungowannah,New South Wales,NSW,-36.0056,146.7702,4
2640,East Albury,New South Wales,NSW,-36.0814,146.9299,4
2640,Wymah,New South Wales,NSW,-36.0139,147.0421,3
2640,South Albury,New South Wales,NSW,-36.0865,146.9091,4
2640,West Albury,New South Wales,NSW,-36.0798,146.8923,4
2640,Wirlinga,New South Wales,NSW,-36.0139,147.0421,3
2640,Glenroy,New South Wales,NSW,-36.0502,146.9106,4
2640,Ettamogah,New South Wales,NSW,-36.0139,147.0421,3
2640,Splitters Creek,New South Wales,NSW,-36.053,146.85,4
2640,Albury,New South Wales,NSW,-36.0748,146.924,4
2641,Lavington,New South Wales,NSW,-36.0398,146.9396,4
2641,Hamilton Valley,New South Wales,NSW,-36.0379,146.9177,4
2641,Springdale Heights,New South Wales,NSW,-36.031,146.9478,4
2642,Jagumba,New South Wales,NSW,-35.8945,147.3359,3
2642,Tooma,New South Wales,NSW,-35.9667,148.0667,4
2642,Burrumbuttock,New South Wales,NSW,-35.8375,146.8023,4
2642,Welaregang,New South Wales,NSW,-35.998,147.9637,4
2642,Bidgeemia,New South Wales,NSW,-35.8945,147.3359,3
2642,Rand,New South Wales,NSW,-35.5929,146.5811,4
2642,Brocklesby,New South Wales,NSW,-35.8249,146.6751,4
2642,Walbundrie,New South Wales,NSW,-35.6985,146.7179,4
2642,Jagungal Wilderness,New South Wales,NSW,-35.8945,147.3359,3
2642,Greg Greg,New South Wales,NSW,-36.0485,148.0512,4
2642,Glenellen,New South Wales,NSW,-35.9152,146.9179,4
2642,Jingellic,New South Wales,NSW,-35.9152,147.6846,4
2642,Wrathall,New South Wales,NSW,-35.8945,147.3359,3
2642,Yerong Creek,New South Wales,NSW,-35.3868,147.0608,4
2642,Murray Gorge,New South Wales,NSW,-35.8945,147.3359,3
2642,Gerogery,New South Wales,NSW,-35.8344,146.9928,4
2642,Geehi,New South Wales,NSW,-36.3667,148.1667,4
2642,Khancoban,New South Wales,NSW,-36.2181,148.1305,4
2642,Indi,New South Wales,NSW,-33.0333,149.4167,4
2642,Jindera,New South Wales,NSW,-35.9547,146.8885,4
2643,Howlong,New South Wales,NSW,-35.982,146.6325,4
2644,Little Billabong,New South Wales,NSW,-35.5818,147.5346,4
2644,Bowna,New South Wales,NSW,-35.7649,147.4557,3
2644,Mountain Creek,New South Wales,NSW,-35.7652,147.1346,4
2644,Woomargama,New South Wales,NSW,-35.8333,147.25,4
2644,Lankeys Creek,New South Wales,NSW,-35.8152,147.6512,4
2644,Holbrook,New South Wales,NSW,-35.7219,147.3139,4
2644,Yarara,New South Wales,NSW,-35.7648,147.3468,3
2644,Mullengandra,New South Wales,NSW,-35.8833,147.1667,4
2644,Wantagong,New South Wales,NSW,-35.7648,147.3468,3
2644,Coppabella,New South Wales,NSW,-35.7648,147.3468,3
2645,Urana,New South Wales,NSW,-35.3293,146.2666,4
2645,Cullivel,New South Wales,NSW,-35.2627,146.382,4
2646,Daysdale,New South Wales,NSW,-35.6436,146.302,4
2646,Rennie,New South Wales,NSW,-35.809,146.1362,4
2646,Oaklands,New South Wales,NSW,-35.5576,146.163,4
2646,Balldale,New South Wales,NSW,-35.8466,146.5197,4
2646,Coreen,New South Wales,NSW,-35.7667,146.35,4
2646,Savernake,New South Wales,NSW,-35.7345,146.051,4
2646,Corowa,New South Wales,NSW,-35.997,146.3855,4
2646,Goombargana,New South Wales,NSW,-35.7924,146.2899,3
2646,Lowesdale,New South Wales,NSW,-35.8318,146.3512,4
2646,Sanger,New South Wales,NSW,-35.7924,146.2899,3
2646,Ringwood,New South Wales,NSW,-35.8,146.2,3
2646,Hopefield,New South Wales,NSW,-35.8824,146.4377,4
2646,Redlands,New South Wales,NSW,-35.7924,146.2899,3
2646,Nyora,New South Wales,NSW,-35.7924,146.2899,3
2646,Collendina,New South Wales,NSW,-35.7924,146.2899,3
2647,Mulwala,New South Wales,NSW,-35.9854,146.0053,4
2648,Mourquong,New South Wales,NSW,-33.4923,142.2143,3
2648,Ellerslie,New South Wales,NSW,-33.3103,147.2462,3
2648,Anabranch North,New South Wales,NSW,-35.8604,146.9648,3
2648,Palinyewah,New South Wales,NSW,-33.4923,142.2143,3
2648,Rufus,New South Wales,NSW,-35.8604,146.9648,3
2648,Pomona,New South Wales,NSW,-34.023,141.8896,4
2648,Wentworth,New South Wales,NSW,-34.1073,141.9131,4
2648,Rufus River,New South Wales,NSW,-33.4923,142.2143,3
2648,Pooncarie,New South Wales,NSW,-33.3853,142.5699,4
2648,Moorara,New South Wales,NSW,-35.8604,146.9648,3
2648,Cal Lal,New South Wales,NSW,-34.0452,141.0884,4
2648,Para,New South Wales,NSW,-35.8604,146.9648,3
2648,Scotia,New South Wales,NSW,-35.8604,146.9648,3
2648,Pan Ban,New South Wales,NSW,-35.8604,146.9648,3
2648,Anabranch South,New South Wales,NSW,-35.8604,146.9648,3
2648,Pine Camp,New South Wales,NSW,-35.8604,146.9648,3
2648,Curlwaa,New South Wales,NSW,-34.1077,141.9669,4
2649,Laurel Hill,New South Wales,NSW,-35.6167,148.0833,4
2649,Nurenmerenmong,New South Wales,NSW,-35.6167,148.0833,3
2650,Tolland,New South Wales,NSW,-35.1452,147.3516,4
2650,Burrandana,New South Wales,NSW,-35.153,147.3705,3
2650,Bomen,New South Wales,NSW,-35.0667,147.4167,4
2650,Pulletop,New South Wales,NSW,-35.153,147.3705,3
2650,East Wagga Wagga,New South Wales,NSW,-35.1276,147.407,4
2650,Estella,New South Wales,NSW,-35.0725,147.3575,4
2650,Glenfield Park,New South Wales,NSW,-35.1373,147.3328,4
2650,Yathella,New South Wales,NSW,-35.153,147.3705,3
2650,Wagga Wagga,New South Wales,NSW,-35.1258,147.3537,4
2650,Wantabadgery,New South Wales,NSW,-35.05,147.7333,4
2650,Mount Austin,New South Wales,NSW,-35.1368,147.353,4
2650,Eunanoreenya,New South Wales,NSW,-35.153,147.3705,3
2650,Oura,New South Wales,NSW,-35.1167,147.5333,4
2650,Oberne Creek,New South Wales,NSW,-35.4,147.8333,4
2650,Galore,New South Wales,NSW,-34.998,146.8128,4
2650,Maxwell,New South Wales,NSW,-35.153,147.3705,3
2650,Lake Albert,New South Wales,NSW,-35.1667,147.3833,4
2650,Ashmont,New South Wales,NSW,-35.1249,147.3317,4
2650,Gregadoo,New South Wales,NSW,-35.2333,147.4333,4
2650,Wallacetown,New South Wales,NSW,-35.153,147.3705,3
2650,Boorooma,New South Wales,NSW,-35.0744,147.3646,4
2650,Kyeamba,New South Wales,NSW,-35.4333,147.6167,4
2650,Currawarna,New South Wales,NSW,-35.0167,147.0833,4
2650,Belfrayden,New South Wales,NSW,-35.153,147.3705,3
2650,Cookardinia,New South Wales,NSW,-35.5667,147.2167,4
2650,Tatton,New South Wales,NSW,-35.1599,147.3587,4
2650,Cartwrights Hill,New South Wales,NSW,-35.0748,147.392,4
2650,Big Springs,New South Wales,NSW,-35.153,147.3705,3
2650,Brucedale,New South Wales,NSW,-35.0333,147.4167,4
2650,Moorong,New South Wales,NSW,-35.1119,147.3125,4
2650,San Isidore,New South Wales,NSW,-35.1248,147.2894,4
2650,Wagga Wagga South,New South Wales,NSW,-35.1127,147.4103,3
2650,Bourkelands,New South Wales,NSW,-35.1559,147.347,4
2650,Gobbagombalin,New South Wales,NSW,-35.0592,147.3436,4
2650,Carabost,New South Wales,NSW,-35.5985,147.7179,4
2650,Downside,New South Wales,NSW,-35,147.35,4
2650,Turvey Park,New South Wales,NSW,-35.1285,147.3626,4
2650,Springvale,New South Wales,NSW,-35.1669,147.3306,4
2650,Yarragundry,New South Wales,NSW,-35.1167,147.2333,4
2650,Book Book,New South Wales,NSW,-35.3693,147.5624,4
2650,Harefield,New South Wales,NSW,-34.9667,147.5333,4
2650,Borambola,New South Wales,NSW,-35.2,147.6667,4
2650,Alfredtown,New South Wales,NSW,-35.1667,147.5167,4
2650,Hillgrove,New South Wales,NSW,-35.153,147.3705,3
2650,Bulgary,New South Wales,NSW,-35.0772,146.9725,4
2650,Wagga Wagga BC,New South Wales,NSW,-35.153,147.3705,3
2650,Rowan,New South Wales,NSW,-35.2,147.3333,4
2650,Lloyd,New South Wales,NSW,-35.1507,147.3371,4
2650,Kooringal,New South Wales,NSW,-35.1407,147.3768,4
2650,The Gap,New South Wales,NSW,-35.153,147.3705,3
2650,North Wagga Wagga,New South Wales,NSW,-35.1,147.3833,4
2650,Collingullie,New South Wales,NSW,-35.0833,147.1167,4
2650,Euberta,New South Wales,NSW,-35.0667,147.2167,4
2650,Gelston Park,New South Wales,NSW,-35.153,147.3705,3
2651,Forest Hill,New South Wales,NSW,-35.1481,147.4671,4
2651,Wagga Wagga Raaf,New South Wales,NSW,-35.1588,147.4662,3
2652,Boree Creek,New South Wales,NSW,-35.1066,146.6085,4
2652,Boorga,New South Wales,NSW,-34.0326,146.021,4
2652,Humula,New South Wales,NSW,-35.4833,147.75,4
2652,Galore,New South Wales,NSW,-34.998,146.8128,4
2652,Marrar,New South Wales,NSW,-34.8333,147.3667,4
2652,Gumly Gumly,New South Wales,NSW,-35.1273,147.4335,4
2652,Tarcutta,New South Wales,NSW,-35.2774,147.7359,4
2652,Tabbita,New South Wales,NSW,-34.1071,145.8463,4
2652,Uranquinty,New South Wales,NSW,-35.1935,147.246,4
2652,Landervale,New South Wales,NSW,-34.5454,146.8317,4
2652,Ladysmith,New South Wales,NSW,-35.2095,147.5118,4
2652,Grong Grong,New South Wales,NSW,-34.7368,146.7822,4
2652,Rosewood,New South Wales,NSW,-35.7,147.8667,4
2652,Matong,New South Wales,NSW,-34.7671,146.9239,4
2652,Cowabbie,New South Wales,NSW,-34.5815,146.9698,4
2652,Old Junee,New South Wales,NSW,-34.8333,147.5333,4
2652,Merriwagga,New South Wales,NSW,-33.8333,145.6167,4
2652,Mangoplah,New South Wales,NSW,-35.3833,147.25,4
2652,Murrulebale,New South Wales,NSW,-34.8864,146.9996,3
2652,Goolgowi,New South Wales,NSW,-33.9781,145.7087,4
2653,Burra,New South Wales,NSW,-35.5594,149.2235,4
2653,Courabyra,New South Wales,NSW,-35.7,148.0333,4
2653,Glenroy,New South Wales,NSW,-36.0502,146.9106,4
2653,Paddys River,New South Wales,NSW,-35.764,148.1853,4
2653,Mannus,New South Wales,NSW,-35.8,147.95,4
2653,Taradale,New South Wales,NSW,-35.7893,147.9991,3
2653,Willigobung,New South Wales,NSW,-35.7893,147.9991,3
2653,Munderoo,New South Wales,NSW,-35.8318,147.8679,4
2653,Westdale,New South Wales,NSW,-35.7893,147.9991,3
2653,Tumbarumba,New South Wales,NSW,-35.7762,148.0105,4
2653,Maragle,New South Wales,NSW,-35.7893,147.9991,3
2655,The Rock,New South Wales,NSW,-35.268,147.1142,4
2655,Tootool,New South Wales,NSW,-35.263,146.9936,4
2655,French Park,New South Wales,NSW,-35.2623,146.927,4
2656,Osborne,New South Wales,NSW,-35.3545,146.7228,4
2656,Urangeline East,New South Wales,NSW,-35.4874,146.7,4
2656,Brookong,New South Wales,NSW,-35.1844,146.6364,4
2656,Fargunyah,New South Wales,NSW,-35.3398,146.7235,3
2656,Urangeline,New South Wales,NSW,-35.4874,146.7,4
2656,Lockhart,New South Wales,NSW,-35.2233,146.7182,4
2656,Brookdale,New South Wales,NSW,-35.3398,146.7235,3
2656,Milbrulong,New South Wales,NSW,-35.2532,146.8428,4
2658,Pleasant Hills,New South Wales,NSW,-35.4622,146.7978,4
2658,Henty,New South Wales,NSW,-35.5207,147.0349,4
2658,Ryan,New South Wales,NSW,-35.5564,146.8684,4
2658,Munyabla,New South Wales,NSW,-35.4963,146.8718,4
2659,Alma Park,New South Wales,NSW,-35.7568,146.9108,3
2659,Walla Walla,New South Wales,NSW,-35.7622,146.9011,4
2660,Morven,New South Wales,NSW,-35.65,147.1,4
2660,Culcairn,New South Wales,NSW,-35.6667,147.0385,4
2661,Kapooka,New South Wales,NSW,-35.1569,147.2844,4
2663,Erin Vale,New South Wales,NSW,-34.9061,147.672,3
2663,Eurongilly,New South Wales,NSW,-34.95,147.7667,4
2663,Wantiool,New South Wales,NSW,-34.9,147.6667,4
2663,Marinna,New South Wales,NSW,-34.9061,147.672,3
2663,Junee,New South Wales,NSW,-34.8682,147.5827,4
2665,Mirrool,New South Wales,NSW,-34.3167,147.1,4
2665,Barellan,New South Wales,NSW,-34.2849,146.5722,4
2665,Tara,New South Wales,NSW,-34.5,147.1667,4
2665,Bectric,New South Wales,NSW,-34.3391,146.9046,3
2665,Kamarah,New South Wales,NSW,-34.3255,146.7831,4
2665,Moombooldool,New South Wales,NSW,-34.3,146.6833,4
2665,Beckom,New South Wales,NSW,-34.3152,146.9679,4
2665,Binya,New South Wales,NSW,-34.2333,146.35,4
2665,Walleroobie,New South Wales,NSW,-34.3391,146.9046,3
2665,Ariah Park,New South Wales,NSW,-34.3493,147.2218,4
2665,Quandary,New South Wales,NSW,-34.3833,147.3167,4
2665,Ardlethan,New South Wales,NSW,-34.3569,146.9018,4
2666,Grogan,New South Wales,NSW,-34.3167,147.8,4
2666,Reefton,New South Wales,NSW,-34.25,147.4333,4
2666,Dirnaseer,New South Wales,NSW,-34.414,147.5797,3
2666,Junee Reefs,New South Wales,NSW,-34.7318,147.6179,4
2666,Morangarell,New South Wales,NSW,-34.15,147.7,4
2666,Mimosa,New South Wales,NSW,-34.6,147.45,4
2666,Combaning,New South Wales,NSW,-34.45,147.6833,4
2666,Pucawan,New South Wales,NSW,-34.4152,147.3512,4
2666,Sebastopol,New South Wales,NSW,-34.5833,147.5333,4
2666,Temora,New South Wales,NSW,-34.4483,147.5356,4
2666,Gidginbung,New South Wales,NSW,-34.3333,147.4667,4
2666,Trungley Hall,New South Wales,NSW,-34.2985,147.5679,4
2666,Narraburra,New South Wales,NSW,-34.3318,147.6512,4
2666,Springdale,New South Wales,NSW,-34.4667,147.7333,4
2668,Barmedman,New South Wales,NSW,-34.1449,147.3873,4
2669,Rankins Springs,New South Wales,NSW,-33.8318,146.2512,4
2669,Melbergen,New South Wales,NSW,-33.7,146.05,4
2669,Tallimba,New South Wales,NSW,-33.9935,146.8796,4
2669,Erigolia,New South Wales,NSW,-33.8546,146.3733,4
2669,Gubbata,New South Wales,NSW,-33.778,146.5263,3
2669,Tullibigeal,New South Wales,NSW,-33.4167,146.7333,4
2669,Naradhan,New South Wales,NSW,-33.6152,146.3179,4
2669,Girral,New South Wales,NSW,-33.7058,147.0758,4
2669,Weethalle,New South Wales,NSW,-33.8818,146.6346,4
2669,Bygalorie,New South Wales,NSW,-33.4667,146.8,4
2669,Ungarie,New South Wales,NSW,-33.6318,146.9679,4
2669,Kikoira,New South Wales,NSW,-33.6496,146.6615,4
2671,West Wyalong,New South Wales,NSW,-33.9237,147.2047,4
2671,North Yalgogrin,New South Wales,NSW,-33.85,146.8333,3
2671,Wyalong,New South Wales,NSW,-33.9333,147.25,4
2671,Alleena,New South Wales,NSW,-34.0688,147.1392,4
2671,Lake Cowal,New South Wales,NSW,-33.6877,147.3586,4
2671,Back Creek,New South Wales,NSW,-33.8345,147.173,3
2671,Burcher,New South Wales,NSW,-33.5141,147.2532,4
2672,Lake Cargelligo,New South Wales,NSW,-33.2987,146.3739,4
2672,Curlew Waters,New South Wales,NSW,-33.2963,146.4428,3
2672,Murrin Bridge,New South Wales,NSW,-33.2068,146.3711,4
2672,Burgooney,New South Wales,NSW,-33.3861,146.5752,4
2675,Hillston,New South Wales,NSW,-33.4877,145.5403,4
2675,Wallanthery,New South Wales,NSW,-33.2655,145.5005,3
2675,Lake Brewster,New South Wales,NSW,-33.2655,145.5005,3
2675,Monia Gap,New South Wales,NSW,-33.2655,145.5005,3
2675,Roto,New South Wales,NSW,-33.05,145.4667,4
2678,Riverina Mc,New South Wales,NSW,-33.5843,146.6698,1
2678,Charles Sturt University,New South Wales,NSW,-35.0593,147.352,1
2680,Beelbangera,New South Wales,NSW,-34.2553,146.0985,4
2680,Nericon,New South Wales,NSW,-34.1781,146.0355,4
2680,Bilbul,New South Wales,NSW,-34.2718,146.1405,4
2680,Griffith East,New South Wales,NSW,-34.2768,146.0447,3
2680,Yoogali,New South Wales,NSW,-34.3,146.0833,4
2680,Willbriggie,New South Wales,NSW,-34.466,146.0149,4
2680,Warburn,New South Wales,NSW,-34.1858,145.9442,4
2680,Warrawidgee,New South Wales,NSW,-34.2768,146.0447,3
2680,Hanwood,New South Wales,NSW,-34.331,146.0414,4
2680,Kooba,New South Wales,NSW,-34.2768,146.0447,3
2680,Griffith,New South Wales,NSW,-34.2885,146.0509,4
2680,Griffith DC,New South Wales,NSW,-34.2768,146.0447,3
2680,Lake Wyangan,New South Wales,NSW,-34.232,146.0208,4
2680,Tharbogang,New South Wales,NSW,-34.299,145.826,3
2680,Widgelli,New South Wales,NSW,-34.299,145.826,3
2680,Benerembah,New South Wales,NSW,-34.299,145.826,3
2681,Yenda,New South Wales,NSW,-34.2501,146.199,4
2681,Myall Park,New South Wales,NSW,-34.2501,146.199,3
2700,Sandigo,New South Wales,NSW,-34.9214,146.6613,4
2700,Morundah,New South Wales,NSW,-34.9333,146.3,4
2700,Narrandera,New South Wales,NSW,-34.7475,146.551,4
2700,Gillenbah,New South Wales,NSW,-34.8246,146.4029,3
2700,Colinroobie,New South Wales,NSW,-34.454,146.6034,4
2700,Bundure,New South Wales,NSW,-35.1708,146.0078,4
2700,Cudgel,New South Wales,NSW,-34.8246,146.4029,3
2700,Euroley,New South Wales,NSW,-34.7167,146.35,4
2700,Corobimilla,New South Wales,NSW,-34.9139,146.1765,3
2701,Methul,New South Wales,NSW,-34.5985,147.1512,4
2701,Berry Jerry,New South Wales,NSW,-34.6893,147.2003,3
2701,Coolamon,New South Wales,NSW,-34.8164,147.1958,4
2701,Rannock,New South Wales,NSW,-34.6485,147.2512,4
2702,Ganmain,New South Wales,NSW,-34.7932,147.0391,4
2703,Yanco,New South Wales,NSW,-34.5985,146.4095,4
2705,Corbie Hill,New South Wales,NSW,-34.563,146.4833,4
2705,Whitton,New South Wales,NSW,-34.5187,146.1851,4
2705,Wamoon,New South Wales,NSW,-34.5358,146.3366,4
2705,Merungle Hill,New South Wales,NSW,-34.615,146.4652,4
2705,Murrami,New South Wales,NSW,-34.4231,146.3057,4
2705,Leeton,New South Wales,NSW,-34.551,146.4048,4
2705,Stanbridge,New South Wales,NSW,-34.5152,146.2346,4
2705,Brobenah,New South Wales,NSW,-34.531,146.3361,3
2705,Gogeldrie,New South Wales,NSW,-34.5485,146.3012,4
2706,Darlington Point,New South Wales,NSW,-34.5694,145.9987,4
2707,Coleambally,New South Wales,NSW,-34.8039,145.8804,4
2707,Argoon,New South Wales,NSW,-34.9477,145.7211,4
2708,Murray Region Mc,New South Wales,NSW,-34.6742,146.3985,1
2710,Pretty Pine,New South Wales,NSW,-35.4152,144.8846,4
2710,Mathoura,New South Wales,NSW,-35.8152,144.9012,4
2710,Wakool,New South Wales,NSW,-35.4706,144.3957,3
2710,Wandook,New South Wales,NSW,-35.4621,144.9959,4
2710,Birganbigil,New South Wales,NSW,-35.3667,145.4667,3
2710,Caldwell,New South Wales,NSW,-35.6318,144.5096,4
2710,Moonbria,New South Wales,NSW,-35.3667,145.4667,3
2710,Deniliquin,New South Wales,NSW,-35.5324,144.9536,4
2710,Stud Park,New South Wales,NSW,-35.3667,145.4667,3
2710,Conargo,New South Wales,NSW,-35.3035,145.1801,4
2710,Steam Plains,New South Wales,NSW,-35.3667,145.4667,3
2710,Calimo,New South Wales,NSW,-35.4152,144.5846,4
2710,Gulpa,New South Wales,NSW,-35.7,144.8833,4
2710,Wanganella,New South Wales,NSW,-35.2121,144.8194,4
2710,Booroorban,New South Wales,NSW,-34.9325,144.7619,4
2710,Lindifferon,New South Wales,NSW,-35.3667,145.4667,3
2710,Barratta,New South Wales,NSW,-35.2955,144.5737,4
2710,Coree,New South Wales,NSW,-35.3454,145.4289,4
2710,Moira,New South Wales,NSW,-35.9318,144.8346,4
2710,Hartwood,New South Wales,NSW,-35.3667,145.4667,3
2710,Morago,New South Wales,NSW,-35.3718,144.6606,4
2710,Bullatale,New South Wales,NSW,-35.3667,145.4667,3
2710,Mayrung,New South Wales,NSW,-35.3251,144.8201,3
2710,Willurah,New South Wales,NSW,-35.3667,145.4667,3
2710,Warragoon,New South Wales,NSW,-35.3667,145.4667,3
2711,Oxley,New South Wales,NSW,-34.1936,144.1024,4
2711,Carrathool,New South Wales,NSW,-34.4075,145.4358,4
2711,Maude,New South Wales,NSW,-34.4739,144.3003,4
2711,Yanga,New South Wales,NSW,-34.2167,145.425,3
2711,Gunbar,New South Wales,NSW,-34.0444,145.4043,4
2711,Keri Keri,New South Wales,NSW,-34.2167,145.425,3
2711,Corrong,New South Wales,NSW,-34.2176,144.4683,4
2711,Waugorah,New South Wales,NSW,-34.2167,145.425,3
2711,Booligal,New South Wales,NSW,-34.1481,144.6732,3
2711,Hay South,New South Wales,NSW,-34.2167,145.425,3
2711,One Tree,New South Wales,NSW,-34.1889,144.7172,4
2711,Clare,New South Wales,NSW,-33.4,143.9303,4
2711,Hay,New South Wales,NSW,-34.509,144.8431,4
2712,Berrigan,New South Wales,NSW,-35.6588,145.8114,4
2712,Boomanoomana,New South Wales,NSW,-35.8831,145.7929,4
2713,Myrtle Park,New South Wales,NSW,-35.649,145.577,3
2713,Blighty,New South Wales,NSW,-35.5883,145.2859,4
2713,Logie Brae,New South Wales,NSW,-35.649,145.577,3
2713,Finley,New South Wales,NSW,-35.655,145.5711,4
2714,Tuppal,New South Wales,NSW,-35.6818,145.2512,4
2714,Tocumwal,New South Wales,NSW,-35.8115,145.5692,4
2714,Aratula,New South Wales,NSW,-35.231,145.6188,3
2714,Pine Lodge,New South Wales,NSW,-35.231,145.6188,3
2715,Hatfield,New South Wales,NSW,-33.8665,143.738,4
2715,Mungo,New South Wales,NSW,-35.231,145.6188,3
2715,Balranald,New South Wales,NSW,-34.6365,143.5614,4
2715,Kyalite,New South Wales,NSW,-34.9532,143.4775,4
2715,Arumpo,New South Wales,NSW,-35.231,145.6188,3
2716,Four Corners,New South Wales,NSW,-32.136,146.3019,4
2716,Mairjimmy,New South Wales,NSW,-35.4932,145.7475,4
2716,Gala Vale,New South Wales,NSW,-35.4283,145.7311,3
2716,Mabins Well,New South Wales,NSW,-35.247,145.5718,3
2716,Jerilderie,New South Wales,NSW,-35.3576,145.7296,4
2717,Dareton,New South Wales,NSW,-34.0947,142.0397,4
2717,Coomealla,New South Wales,NSW,-34.0981,142.0925,4
2720,Bombowlee,New South Wales,NSW,-35.2628,148.2449,4
2720,Pinbeyan,New South Wales,NSW,-35.3366,148.2926,3
2720,Lacmalac,New South Wales,NSW,-35.3167,148.3167,4
2720,Blowering,New South Wales,NSW,-35.5117,148.2658,4
2720,Talbingo,New South Wales,NSW,-35.581,148.2948,4
2720,Gadara,New South Wales,NSW,-35.3366,148.2926,3
2720,Red Hill,New South Wales,NSW,-35.0523,149.1643,4
2720,Tumut,New South Wales,NSW,-35.3002,148.2251,4
2720,Minjary,New South Wales,NSW,-35.1999,148.1346,4
2720,Mundongo,New South Wales,NSW,-35.2907,148.2805,4
2720,Tumorrama,New South Wales,NSW,-35.1667,148.4333,4
2720,Argalong,New South Wales,NSW,-35.3,148.4333,4
2720,Yarrangobilly,New South Wales,NSW,-35.65,148.4833,4
2720,Couragago,New South Wales,NSW,-35.3366,148.2926,3
2720,Gocup,New South Wales,NSW,-35.2333,148.2,4
2720,Gilmore,New South Wales,NSW,-35.3333,148.1833,4
2720,Killimicat,New South Wales,NSW,-35.3366,148.2926,3
2720,Little River,New South Wales,NSW,-35.3366,148.2926,3
2720,Goobarragandra,New South Wales,NSW,-35.3366,148.2926,3
2720,Jones Bridge,New South Wales,NSW,-35.3366,148.2926,3
2720,Bombowlee Creek,New South Wales,NSW,-35.3366,148.2926,3
2720,Wyangle,New South Wales,NSW,-35.2333,148.3,4
2720,Buddong,New South Wales,NSW,-35.3366,148.2926,3
2720,Windowie,New South Wales,NSW,-35.3366,148.2926,3
2720,Tumut Plains,New South Wales,NSW,-35.3366,148.2926,3
2720,Bogong Peaks Wilderness,New South Wales,NSW,-35.3366,148.2926,3
2720,Wereboldera,New South Wales,NSW,-35.3366,148.2926,3
2720,Wermatong,New South Wales,NSW,-35.3366,148.2926,3
2721,Quandialla,New South Wales,NSW,-34.0124,147.7876,4
2721,Bland,New South Wales,NSW,-33.9833,147.6833,4
2722,Gundagai,New South Wales,NSW,-35.0652,148.108,4
2722,Darbalara,New South Wales,NSW,-35.0167,148.2,4
2722,South Gundagai,New South Wales,NSW,-35.0833,148.1,4
2722,Brungle Creek,New South Wales,NSW,-35.0275,148.1097,3
2722,Brungle,New South Wales,NSW,-35.15,148.2333,4
2722,Jones Creek,New South Wales,NSW,-35.0275,148.1097,3
2722,Muttama,New South Wales,NSW,-34.7985,148.1179,4
2722,Burra Creek,New South Wales,NSW,-35.0275,148.1097,3
2722,Nangus,New South Wales,NSW,-35.05,147.9,4
2725,Stockinbingal,New South Wales,NSW,-34.4989,147.882,4
2726,Cooneys Creek,New South Wales,NSW,-34.8333,148.3333,3
2726,Jugiong,New South Wales,NSW,-34.8333,148.3333,4
2727,Coolac,New South Wales,NSW,-34.9285,148.1651,4
2727,Gobarralong,New South Wales,NSW,-34.9902,148.2512,4
2727,Adjungbilly,New South Wales,NSW,-35.0873,148.4185,4
2729,Westwood,New South Wales,NSW,-35.2452,147.9929,3
2729,Mount Horeb,New South Wales,NSW,-35.2167,148.0333,4
2729,Sandy Gully,New South Wales,NSW,-35.2452,147.9929,3
2729,Cooleys Creek,New South Wales,NSW,-35.2633,148.0041,3
2729,Califat,New South Wales,NSW,-35.2452,147.9929,3
2729,Black Creek,New South Wales,NSW,-35.2452,147.9929,3
2729,Adelong,New South Wales,NSW,-35.3049,148.069,4
2729,Tumblong,New South Wales,NSW,-35.15,148,4
2729,Grahamstown,New South Wales,NSW,-35.2667,148.0333,4
2729,Darlow,New South Wales,NSW,-35.2452,147.9929,3
2729,Sharps Creek,New South Wales,NSW,-35.2452,147.9929,3
2729,Mundarlo,New South Wales,NSW,-35.1333,147.8167,4
2729,Yaven Creek,New South Wales,NSW,-35.2452,147.9929,3
2729,Bangadang,New South Wales,NSW,-35.2452,147.9929,3
2729,Ellerslie,New South Wales,NSW,-33.3103,147.2462,4
2729,Mount Adrah,New South Wales,NSW,-35.2167,147.9,4
2729,Wondalga,New South Wales,NSW,-35.4167,148.1167,4
2730,Green Hills,New South Wales,NSW,-32.9176,149.5836,4
2730,Kunama,New South Wales,NSW,-35.5494,148.0982,4
2730,Lower Bago,New South Wales,NSW,-35.524,148.0994,3
2730,Batlow,New South Wales,NSW,-35.5191,148.146,4
2731,Bunnaloo,New South Wales,NSW,-35.7869,144.5977,4
2731,Tantonan,New South Wales,NSW,-35.1588,146.0447,3
2731,Womboota,New South Wales,NSW,-35.9073,144.6,4
2731,Thyra,New South Wales,NSW,-35.8401,144.6356,4
2731,Moama,New South Wales,NSW,-36.1041,144.7608,4
2732,Burraboi,New South Wales,NSW,-35.393,144.3159,4
2732,Gonn,New South Wales,NSW,-35.1588,146.0447,3
2732,Cobramunga,New South Wales,NSW,-35.1588,146.0447,3
2732,Tullakool,New South Wales,NSW,-35.1588,146.0447,3
2732,Thule,New South Wales,NSW,-35.1588,146.0447,3
2732,Barham,New South Wales,NSW,-35.6265,144.129,4
2732,Noorong,New South Wales,NSW,-35.1588,146.0447,3
2733,Dhuragoon,New South Wales,NSW,-35.1588,146.0447,3
2733,Niemur,New South Wales,NSW,-35.1588,146.0447,3
2733,Moulamein,New South Wales,NSW,-35.0906,144.0363,4
2734,Dilpurra,New South Wales,NSW,-35.1652,143.6846,4
2734,Stony Crossing,New South Wales,NSW,-35.0813,143.5498,4
2734,Cunninyeuk,New South Wales,NSW,-35.1588,146.0447,3
2734,Kyalite,New South Wales,NSW,-34.9532,143.4775,4
2734,Mallan,New South Wales,NSW,-35.1588,146.0447,3
2734,Wetuppa,New South Wales,NSW,-35.1588,146.0447,3
2734,Tooranie,New South Wales,NSW,-35.1588,146.0447,3
2734,Murray Downs,New South Wales,NSW,-35.1588,146.0447,3
2734,Mellool,New South Wales,NSW,-35.1588,146.0447,3
2734,Moolpa,New South Wales,NSW,-35.1588,146.0447,3
2735,Koraleigh,New South Wales,NSW,-35.1286,143.4404,4
2735,Speewa,New South Wales,NSW,-35.1588,146.0447,3
2736,Goodnight,New South Wales,NSW,-34.959,143.3395,4
2736,Tooleybuc,New South Wales,NSW,-35.0318,143.3346,4
2737,Euston,New South Wales,NSW,-34.5744,142.7448,4
2738,Paringi,New South Wales,NSW,-34.2366,142.5301,3
2738,Gol Gol,New South Wales,NSW,-34.1739,142.2219,4
2738,Trentham Cliffs,New South Wales,NSW,-34.2366,142.5301,3
2738,Mallee,New South Wales,NSW,-34.2366,142.5301,3
2738,Monak,New South Wales,NSW,-34.2818,142.2679,4
2739,Mourquong,New South Wales,NSW,-35.1588,146.0447,3
2739,Boeill Creek,New South Wales,NSW,-34.1432,142.1201,4
2739,Buronga,New South Wales,NSW,-34.1707,142.1735,4
2745,Mulgoa,New South Wales,NSW,-33.838,150.6496,4
2745,Glenmore Park,New South Wales,NSW,-33.7907,150.6693,4
2745,Greendale,New South Wales,NSW,-33.9274,150.663,4
2745,Luddenham,New South Wales,NSW,-33.8785,150.6886,4
2745,Regentville,New South Wales,NSW,-33.7752,150.6655,4
2745,Wallacia,New South Wales,NSW,-33.8658,150.6402,4
2747,Claremont Meadows,New South Wales,NSW,-33.7759,150.7519,4
2747,Kingswood,New South Wales,NSW,-33.75,150.75,4
2747,Shanes Park,New South Wales,NSW,-33.7033,150.783,4
2747,Cambridge Gardens,New South Wales,NSW,-33.738,150.7207,4
2747,Caddens,New South Wales,NSW,-33.7472,150.7444,3
2747,Werrington,New South Wales,NSW,-33.7592,150.7527,4
2747,Cambridge Park,New South Wales,NSW,-33.7478,150.7221,4
2747,Werrington County,New South Wales,NSW,-33.7465,150.7393,4
2747,Llandilo,New South Wales,NSW,-33.7129,150.7465,4
2747,Werrington Downs,New South Wales,NSW,-33.7421,150.7278,4
2747,Jordan Springs,New South Wales,NSW,-33.7472,150.7444,3
2748,Orchard Hills,New South Wales,NSW,-33.7823,150.7109,4
2749,Castlereagh,New South Wales,NSW,-33.6898,150.6789,4
2749,Cranebrook,New South Wales,NSW,-33.7061,150.7094,4
2750,Penrith,New South Wales,NSW,-33.75,150.7,4
2750,Leonay,New South Wales,NSW,-33.7654,150.6481,4
2750,Jamisontown,New South Wales,NSW,-33.768,150.6768,4
2750,Emu Heights,New South Wales,NSW,-33.7345,150.6487,4
2750,South Penrith,New South Wales,NSW,-33.7735,150.6947,4
2750,Emu Plains,New South Wales,NSW,-33.75,150.6667,4
2750,Penrith Plaza,New South Wales,NSW,-33.7583,150.6729,3
2750,Penrith South,New South Wales,NSW,-33.7583,150.6729,3
2751,Penrith,New South Wales,NSW,-33.75,150.7,4
2752,Warragamba,New South Wales,NSW,-33.8919,150.6046,4
2752,Silverdale,New South Wales,NSW,-33.9152,150.6096,4
2753,Londonderry,New South Wales,NSW,-33.6466,150.7352,4
2753,Agnes Banks,New South Wales,NSW,-33.6094,150.7167,4
2753,Grose Wold,New South Wales,NSW,-33.6075,150.6945,3
2753,Hobartville,New South Wales,NSW,-33.6044,150.743,4
2753,Bowen Mountain,New South Wales,NSW,-33.5719,150.6256,4
2753,Yarramundi,New South Wales,NSW,-33.6194,150.6748,4
2753,Grose Vale,New South Wales,NSW,-33.5871,150.6556,4
2753,Richmond,New South Wales,NSW,-33.5996,150.7514,4
2753,Richmond Lowlands,New South Wales,NSW,-33.608,150.6988,3
2754,The Slopes,New South Wales,NSW,-33.5324,150.7056,4
2754,North Richmond,New South Wales,NSW,-33.5812,150.7194,4
2754,Tennyson,New South Wales,NSW,-33.533,150.7375,4
2755,Richmond Raaf,New South Wales,NSW,-33.5971,150.7584,3
2755,Richmond,New South Wales,NSW,-33.5996,150.7514,4
2756,Bligh Park,New South Wales,NSW,-33.6398,150.8025,4
2756,Cornwallis,New South Wales,NSW,-33.5006,150.8816,3
2756,Colo,New South Wales,NSW,-33.4333,150.8333,4
2756,Mellong,New South Wales,NSW,-33.146,150.6847,4
2756,Ebenezer,New South Wales,NSW,-33.5198,150.8863,4
2756,Freemans Reach,New South Wales,NSW,-33.5579,150.7955,4
2756,Cumberland Reach,New South Wales,NSW,-33.4648,150.895,4
2756,Colo Heights,New South Wales,NSW,-33.3685,150.7208,4
2756,Glossodia,New South Wales,NSW,-33.5362,150.7739,4
2756,Sackville North,New South Wales,NSW,-33.4841,150.888,4
2756,Cattai,New South Wales,NSW,-33.5457,150.9143,4
2756,Upper Colo,New South Wales,NSW,-33.4215,150.7264,4
2756,Pitt Town,New South Wales,NSW,-33.5872,150.8586,4
2756,Mulgrave,New South Wales,NSW,-33.5006,150.8816,3
2756,Mcgraths Hill,New South Wales,NSW,-33.6155,150.8337,4
2756,Pitt Town Bottoms,New South Wales,NSW,-33.5274,150.8499,3
2756,South Maroota,New South Wales,NSW,-33.504,150.9568,4
2756,Sackville,New South Wales,NSW,-33.4833,150.9,4
2756,Maroota,New South Wales,NSW,-33.4833,150.95,4
2756,Windsor Downs,New South Wales,NSW,-33.6677,150.8026,4
2756,Central Colo,New South Wales,NSW,-33.4333,150.8333,4
2756,Windsor,New South Wales,NSW,-33.6131,150.8142,4
2756,Womerah,New South Wales,NSW,-33.5274,150.8499,3
2756,Scheyville,New South Wales,NSW,-33.6045,150.8831,4
2756,South Windsor,New South Wales,NSW,-33.6181,150.8044,4
2756,Wilberforce,New South Wales,NSW,-33.5626,150.8378,4
2756,Lower Portland,New South Wales,NSW,-33.45,150.8833,4
2756,Clarendon,New South Wales,NSW,-33.6096,150.7867,4
2757,Kurmond,New South Wales,NSW,-33.5533,150.6947,4
2758,Blaxlands Ridge,New South Wales,NSW,-33.4733,150.7981,4
2758,Wheeny Creek,New South Wales,NSW,-33.5068,150.6768,3
2758,Mountain Lagoon,New South Wales,NSW,-33.5068,150.6768,3
2758,Bilpin,New South Wales,NSW,-33.5,150.5333,4
2758,Mount Tomah,New South Wales,NSW,-33.5466,150.4223,4
2758,Kurrajong Hills,New South Wales,NSW,-33.5068,150.6768,3
2758,East Kurrajong,New South Wales,NSW,-33.5055,150.7943,4
2758,Berambing,New South Wales,NSW,-33.5326,150.4439,4
2758,Kurrajong,New South Wales,NSW,-33.5277,150.6291,4
2758,The Devils Wilderness,New South Wales,NSW,-33.5068,150.6768,3
2758,Kurrajong Heights,New South Wales,NSW,-33.5277,150.6291,4
2759,St Clair,New South Wales,NSW,-33.7973,150.7847,4
2759,Erskine Park,New South Wales,NSW,-33.813,150.7977,4
2760,Colyton,New South Wales,NSW,-33.7828,150.7968,4
2760,St Marys East,New South Wales,NSW,-33.7593,150.7883,3
2760,North St Marys,New South Wales,NSW,-33.7549,150.7844,4
2760,Ropes Crossing,New South Wales,NSW,-33.7288,150.777,4
2760,Oxley Park,New South Wales,NSW,-33.7707,150.795,4
2760,St Marys,New South Wales,NSW,-33.744,150.783,3
2760,St Marys South,New South Wales,NSW,-33.7593,150.7883,3
2761,Plumpton,New South Wales,NSW,-33.7518,150.8369,4
2761,Hassall Grove,New South Wales,NSW,-33.7335,150.8354,4
2761,Dean Park,New South Wales,NSW,-33.7354,150.8596,4
2761,Oakhurst,New South Wales,NSW,-33.7388,150.8384,4
2761,Colebee,New South Wales,NSW,-33.7277,150.8474,4
2761,Glendenning,New South Wales,NSW,-33.7483,150.8541,4
2762,Schofields,New South Wales,NSW,-33.7167,150.8667,4
2763,Quakers Hill,New South Wales,NSW,-33.7333,150.8833,4
2763,Acacia Gardens,New South Wales,NSW,-33.7334,150.9139,4
2765,Box Hill,New South Wales,NSW,-33.6441,150.8684,3
2765,Vineyard,New South Wales,NSW,-33.6499,150.8526,4
2765,Nelson,New South Wales,NSW,-33.6462,150.9214,4
2765,Oakville,New South Wales,NSW,-33.6156,150.8801,4
2765,Riverstone,New South Wales,NSW,-33.6833,150.8667,4
2765,Marsden Park,New South Wales,NSW,-33.692,150.8373,4
2765,Maraylya,New South Wales,NSW,-33.5922,150.9198,4
2765,Berkshire Park,New South Wales,NSW,-33.6728,150.7752,4
2766,Eastern Creek,New South Wales,NSW,-33.8063,150.8612,4
2766,Rooty Hill,New South Wales,NSW,-33.7667,150.8333,4
2767,Bungarribee,New South Wales,NSW,-33.7615,150.8737,3
2767,Woodcroft,New South Wales,NSW,-33.7564,150.8807,4
2767,Doonside,New South Wales,NSW,-33.7667,150.8667,4
2768,Parklea,New South Wales,NSW,-33.7244,150.9182,4
2768,Stanhope Gardens,New South Wales,NSW,-33.7164,150.9261,4
2768,Glenwood,New South Wales,NSW,-33.7357,150.9348,4
2769,The Ponds,New South Wales,NSW,-33.7061,150.9088,4
2770,Lethbridge Park,New South Wales,NSW,-33.7372,150.8004,4
2770,Tregear,New South Wales,NSW,-33.7456,150.7929,4
2770,Bidwill,New South Wales,NSW,-33.7301,150.8222,4
2770,Dharruk,New South Wales,NSW,-33.7481,150.8156,4
2770,Hebersham,New South Wales,NSW,-33.7458,150.8239,4
2770,Whalan,New South Wales,NSW,-33.757,150.804,4
2770,Willmot,New South Wales,NSW,-33.7241,150.7928,4
2770,Minchinbury,New South Wales,NSW,-33.7872,150.8296,4
2770,Emerton,New South Wales,NSW,-33.7441,150.8064,4
2770,Mount Druitt Village,New South Wales,NSW,-33.7467,150.8101,3
2770,Mount Druitt,New South Wales,NSW,-33.7667,150.8167,4
2770,Shalvey,New South Wales,NSW,-33.7276,150.8066,4
2770,Blackett,New South Wales,NSW,-33.7379,150.8181,4
2773,Lapstone,New South Wales,NSW,-33.7742,150.6357,4
2773,Glenbrook,New South Wales,NSW,-33.7667,150.6167,4
2774,Mount Riverview,New South Wales,NSW,-33.7307,150.6353,4
2774,Blaxland East,New South Wales,NSW,-33.7325,150.6118,3
2774,Blaxland,New South Wales,NSW,-33.75,150.6,4
2774,Warrimoo,New South Wales,NSW,-33.7167,150.6,4
2775,Wisemans Ferry,New South Wales,NSW,-33.3833,150.9833,4
2775,Spencer,New South Wales,NSW,-33.4593,151.1447,4
2775,Marlow,New South Wales,NSW,-33.4639,151.166,4
2775,St Albans,New South Wales,NSW,-33.2833,150.9833,4
2775,Higher Macdonald,New South Wales,NSW,-33.3998,151.0311,3
2775,Singletons Mill,New South Wales,NSW,-33.3998,151.0311,3
2775,Perrys Crossing,New South Wales,NSW,-33.3998,151.0311,3
2775,Lower Macdonald,New South Wales,NSW,-33.3653,150.9511,4
2775,Central Macdonald,New South Wales,NSW,-33.2453,150.8513,3
2775,Webbs Creek,New South Wales,NSW,-33.3998,151.0311,3
2775,Leets Vale,New South Wales,NSW,-33.3998,151.0311,3
2775,Fernances,New South Wales,NSW,-33.3998,151.0311,3
2775,Wrights Creek,New South Wales,NSW,-33.3998,151.0311,3
2775,Upper Macdonald,New South Wales,NSW,-33.3998,151.0311,3
2775,Laughtondale,New South Wales,NSW,-33.4167,151.0333,4
2775,Gunderman,New South Wales,NSW,-33.45,151.0667,4
2775,Mogo Creek,New South Wales,NSW,-33.3998,151.0311,3
2775,Lower Hawkesbury,New South Wales,NSW,-33.45,151.05,4
2776,Faulconbridge,New South Wales,NSW,-33.7,150.5333,4
2777,Hawkesbury Heights,New South Wales,NSW,-33.6657,150.6426,4
2777,Sun Valley,New South Wales,NSW,-33.7088,150.5937,4
2777,Winmalee,New South Wales,NSW,-33.6785,150.6121,4
2777,Yellow Rock,New South Wales,NSW,-33.6985,150.6346,4
2777,Springwood,New South Wales,NSW,-33.7,150.55,4
2777,Valley Heights,New South Wales,NSW,-33.7,150.5833,4
2778,Linden,New South Wales,NSW,-33.7167,150.5,4
2778,Woodford,New South Wales,NSW,-33.7333,150.4833,4
2779,Hazelbrook,New South Wales,NSW,-33.7245,150.4584,4
2780,Katoomba,New South Wales,NSW,-33.7198,150.3074,4
2780,Katoomba DC,New South Wales,NSW,-33.7053,150.3076,3
2780,Medlow Bath,New South Wales,NSW,-33.6746,150.2788,4
2780,Leura,New South Wales,NSW,-33.7167,150.3333,4
2782,Wentworth Falls,New South Wales,NSW,-33.7103,150.3753,4
2783,Lawson,New South Wales,NSW,-33.7206,150.4297,4
2784,Bullaburra,New South Wales,NSW,-33.7292,150.4177,4
2785,Megalong Valley,New South Wales,NSW,-33.6835,150.2679,3
2785,Blackheath,New South Wales,NSW,-33.6357,150.2832,4
2785,Megalong,New South Wales,NSW,-33.7333,150.25,4
2786,Mount Irvine,New South Wales,NSW,-33.4849,150.4612,4
2786,Mount Victoria,New South Wales,NSW,-33.591,150.2554,4
2786,Bell,New South Wales,NSW,-33.5167,150.2,4
2786,Mount Wilson,New South Wales,NSW,-33.5035,150.3749,4
2786,Dargan,New South Wales,NSW,-33.4896,150.2504,4
2787,Duckmaloi,New South Wales,NSW,-33.6799,149.9642,3
2787,Essington,New South Wales,NSW,-33.7,149.7167,4
2787,Oberon,New South Wales,NSW,-33.704,149.8591,4
2787,Edith,New South Wales,NSW,-33.8,149.9167,4
2787,Shooters Hill,New South Wales,NSW,-33.9167,149.8667,4
2787,Black Springs,New South Wales,NSW,-33.8667,149.7167,4
2787,Mount Werong,New South Wales,NSW,-33.7787,149.8476,3
2787,Mayfield,New South Wales,NSW,-32.8979,151.7361,4
2787,Gurnang,New South Wales,NSW,-33.7787,149.8476,3
2787,Tarana,New South Wales,NSW,-33.5167,149.9167,4
2787,Hazelgrove,New South Wales,NSW,-33.65,149.8667,4
2787,Porters Retreat,New South Wales,NSW,-34,149.8167,4
2787,Gingkin,New South Wales,NSW,-33.8667,149.9333,4
2787,Chatham Valley,New South Wales,NSW,-33.7787,149.8476,3
2787,Mozart,New South Wales,NSW,-33.7787,149.8476,3
2787,Norway,New South Wales,NSW,-33.7667,149.8667,4
2787,The Meadows,New South Wales,NSW,-33.7787,149.8476,3
2787,Jaunter,New South Wales,NSW,-33.7787,149.8476,3
2787,Kanangra,New South Wales,NSW,-33.7787,149.8476,3
2787,Mount Olive,New South Wales,NSW,-33.7787,149.8476,3
2790,Springvale,New South Wales,NSW,-31.8595,149.8401,4
2790,Littleton,New South Wales,NSW,-33.5006,150.1373,4
2790,Little Hartley,New South Wales,NSW,-33.5717,150.2086,3
2790,Oaky Park,New South Wales,NSW,-33.4643,150.1829,4
2790,Hartley,New South Wales,NSW,-33.5465,150.1757,4
2790,Hermitage Flat,New South Wales,NSW,-33.4768,150.1432,4
2790,Hartley Vale,New South Wales,NSW,-33.5333,150.2333,4
2790,Good Forest,New South Wales,NSW,-33.4245,150.1024,3
2790,Bowenfels,New South Wales,NSW,-33.5167,150.1167,4
2790,Clarence,New South Wales,NSW,-33.4723,150.222,4
2790,Lidsdale,New South Wales,NSW,-33.4,150.0833,4
2790,Kanimbla,New South Wales,NSW,-33.4245,150.1024,3
2790,Jenolan,New South Wales,NSW,-33.8167,150.0333,4
2790,South Littleton,New South Wales,NSW,-33.5006,150.1373,4
2790,Blackmans Flat,New South Wales,NSW,-33.3656,150.0651,4
2790,Hassans Walls,New South Wales,NSW,-33.4245,150.1024,3
2790,Rydal,New South Wales,NSW,-33.4833,150.0333,4
2790,Hampton,New South Wales,NSW,-33.65,150.05,4
2790,Pottery Estate,New South Wales,NSW,-33.4245,150.1024,3
2790,Wollangambe,New South Wales,NSW,-33.4245,150.1024,3
2790,Cullen Bullen,New South Wales,NSW,-33.2989,150.0328,4
2790,South Bowenfels,New South Wales,NSW,-33.5167,150.1167,4
2790,Morts Estate,New South Wales,NSW,-33.4583,150.1731,4
2790,Newnes Plateau,New South Wales,NSW,-33.4245,150.1024,3
2790,Lowther,New South Wales,NSW,-33.6167,150.1,4
2790,Lithgow,New South Wales,NSW,-33.4825,150.1363,4
2790,Sodwalls,New South Wales,NSW,-33.5167,149.9833,4
2790,State Mine Gully,New South Wales,NSW,-33.4245,150.1024,3
2790,Newnes,New South Wales,NSW,-33.1833,150.25,4
2790,Mount Lambie,New South Wales,NSW,-33.4245,150.1024,3
2790,Ben Bullen,New South Wales,NSW,-33.2167,150.0333,4
2790,Doctors Gap,New South Wales,NSW,-33.4245,150.1024,3
2790,Ganbenang,New South Wales,NSW,-33.4245,150.1024,3
2790,Wolgan Valley,New South Wales,NSW,-33.4245,150.1024,3
2790,Vale Of Clwydd,New South Wales,NSW,-33.4245,150.1024,3
2790,Corney Town,New South Wales,NSW,-33.4245,150.1024,3
2790,Mckellars Park,New South Wales,NSW,-33.4245,150.1024,3
2790,Sheedys Gully,New South Wales,NSW,-33.4245,150.1024,3
2790,Jenolan Caves,New South Wales,NSW,-33.8167,150.0333,4
2790,Marrangaroo,New South Wales,NSW,-33.4333,150.1167,4
2790,Cobar Park,New South Wales,NSW,-33.4228,150.258,3
2791,Carcoar,New South Wales,NSW,-33.6,149.1333,4
2791,Errowanbang,New South Wales,NSW,-33.6,149.1333,3
2792,Mandurama,New South Wales,NSW,-33.65,149.0833,4
2792,Burnt Yards,New South Wales,NSW,-33.5833,149.0167,4
2793,Darbys Falls,New South Wales,NSW,-33.9024,148.856,4
2793,Roseberg,New South Wales,NSW,-33.8378,148.854,3
2793,Woodstock,New South Wales,NSW,-33.7446,148.8489,4
2794,Noonbinna,New South Wales,NSW,-33.8834,148.6441,4
2794,Westville,New South Wales,NSW,-33.7667,148.8,4
2794,Wattamondara,New South Wales,NSW,-33.9406,148.6067,4
2794,Cowra,New South Wales,NSW,-33.8355,148.6966,4
2794,Hovells Creek,New South Wales,NSW,-34.0485,148.8846,4
2794,Mount Collins,New South Wales,NSW,-33.8976,148.6717,3
2794,Bumbaldry,New South Wales,NSW,-33.9,148.4667,4
2795,Locksley,New South Wales,NSW,-33.5142,149.7982,4
2795,Fosters Valley,New South Wales,NSW,-33.4549,149.6841,3
2795,Georges Plains,New South Wales,NSW,-33.5,149.5333,4
2795,Triangle Flat,New South Wales,NSW,-33.75,149.5,4
2795,Wimbledon,New South Wales,NSW,-33.5333,149.4333,4
2795,Laffing Waters,New South Wales,NSW,-33.4549,149.6841,3
2795,Windradyne,New South Wales,NSW,-33.4054,149.5451,4
2795,Mitchell,New South Wales,NSW,-33.4273,149.5567,4
2795,Sunny Corner,New South Wales,NSW,-33.3833,149.9,4
2795,Billywillinga,New South Wales,NSW,-33.4549,149.6841,3
2795,Meadow Flat,New South Wales,NSW,-33.4333,149.9333,4
2795,Copperhannia,New South Wales,NSW,-33.4549,149.6841,3
2795,Robin Hill,New South Wales,NSW,-33.4229,149.5328,4
2795,Kirkconnell,New South Wales,NSW,-33.4549,149.6841,3
2795,Peel,New South Wales,NSW,-33.3167,149.6333,4
2795,Dunkeld,New South Wales,NSW,-33.4106,149.4808,4
2795,West Bathurst,New South Wales,NSW,-33.4127,149.5648,3
2795,Jeremy,New South Wales,NSW,-33.4549,149.6841,3
2795,Glanmire,New South Wales,NSW,-33.4167,149.7167,4
2795,Mount David,New South Wales,NSW,-33.8167,149.5833,4
2795,Crudine,New South Wales,NSW,-32.9667,149.7,4
2795,Fitzgeralds Valley,New South Wales,NSW,-33.4549,149.6841,3
2795,Yetholme,New South Wales,NSW,-33.45,149.8333,4
2795,Raglan,New South Wales,NSW,-33.4167,149.6667,4
2795,Gowan,New South Wales,NSW,-33.1833,149.3667,4
2795,Cow Flat,New South Wales,NSW,-33.4549,149.6841,3
2795,Burraga,New South Wales,NSW,-33.95,149.5333,4
2795,Wisemans Creek,New South Wales,NSW,-33.4708,149.6033,3
2795,Arkell,New South Wales,NSW,-33.7284,149.3909,4
2795,Clear Creek,New South Wales,NSW,-33.4549,149.6841,3
2795,O'Connell,New South Wales,NSW,-33.5333,149.7333,4
2795,Colo,New South Wales,NSW,-33.4333,150.8333,4
2795,Hobbys Yards,New South Wales,NSW,-33.6941,149.3274,4
2795,Forest Grove,New South Wales,NSW,-33.4549,149.6841,3
2795,Trunkey Creek,New South Wales,NSW,-33.8167,149.3333,4
2795,Wiagdon,New South Wales,NSW,-33.4549,149.6841,3
2795,Llanarth,New South Wales,NSW,-33.3969,149.551,4
2795,Gemalla,New South Wales,NSW,-33.5333,149.8333,4
2795,Caloola,New South Wales,NSW,-33.6,149.4333,4
2795,Abercrombie,New South Wales,NSW,-33.3887,149.5458,4
2795,Palmers Oaky,New South Wales,NSW,-33.2,149.8667,4
2795,Yarras,New South Wales,NSW,-31.4189,152.3198,4
2795,Dog Rocks,New South Wales,NSW,-33.4549,149.6841,3
2795,Wattle Flat,New South Wales,NSW,-33.15,149.7,4
2795,Stewarts Mount,New South Wales,NSW,-33.4549,149.6841,3
2795,Perthville,New South Wales,NSW,-33.4874,149.5464,4
2795,Walang,New South Wales,NSW,-33.4549,149.6841,3
2795,The Lagoon,New South Wales,NSW,-33.55,149.6167,4
2795,Arkstone,New South Wales,NSW,-33.4549,149.6841,3
2795,Isabella,New South Wales,NSW,-33.95,149.6667,4
2795,Bathurst West,New South Wales,NSW,-33.4549,149.6841,3
2795,Kelso,New South Wales,NSW,-33.4167,149.6,4
2795,Twenty Forests,New South Wales,NSW,-33.4549,149.6841,3
2795,Paling Yards,New South Wales,NSW,-33.4549,149.6841,3
2795,Ballyroe,New South Wales,NSW,-33.4708,149.6033,3
2795,Mount Rankin,New South Wales,NSW,-33.2956,149.4837,4
2795,Moorilda,New South Wales,NSW,-33.4549,149.6841,3
2795,Gilmandyke,New South Wales,NSW,-33.4549,149.6841,3
2795,Milkers Flat,New South Wales,NSW,-33.4549,149.6841,3
2795,Eglinton,New South Wales,NSW,-33.3773,149.5465,4
2795,Millah Murrah,New South Wales,NSW,-33.4549,149.6841,3
2795,Mount Panorama,New South Wales,NSW,-33.4549,149.6841,3
2795,Killongbutta,New South Wales,NSW,-33.4549,149.6841,3
2795,Bathurst,New South Wales,NSW,-33.4166,149.5806,4
2795,Watton,New South Wales,NSW,-33.4549,149.6841,3
2795,Charles Sturt University,New South Wales,NSW,-33.4549,149.6841,3
2795,Abercrombie River,New South Wales,NSW,-33.4549,149.6841,3
2795,Dark Corner,New South Wales,NSW,-33.3333,149.8667,4
2795,Evans Plains,New South Wales,NSW,-33.4549,149.6841,3
2795,Charlton,New South Wales,NSW,-33.4549,149.6841,3
2795,Limekilns,New South Wales,NSW,-33.4549,149.6841,3
2795,Turondale,New South Wales,NSW,-33.0833,149.6167,4
2795,South Bathurst,New South Wales,NSW,-33.4549,149.6841,3
2795,Rockley,New South Wales,NSW,-33.7,149.5667,4
2795,Gormans Hill,New South Wales,NSW,-33.4549,149.6841,3
2795,Brewongle,New South Wales,NSW,-33.4833,149.7167,4
2795,White Rock,New South Wales,NSW,-33.4549,149.6841,3
2795,Winburndale,New South Wales,NSW,-33.4549,149.6841,3
2795,Tannas Mount,New South Wales,NSW,-33.4549,149.6841,3
2795,Sofala,New South Wales,NSW,-33.0833,149.7,4
2795,Freemantle,New South Wales,NSW,-33.4708,149.6033,3
2795,Orton Park,New South Wales,NSW,-33.4667,149.5667,4
2795,The Rocks,New South Wales,NSW,-33.8592,151.2081,4
2795,Upper Turon,New South Wales,NSW,-33.1,149.7667,4
2795,Judds Creek,New South Wales,NSW,-33.4708,149.6033,3
2795,Bruinbun,New South Wales,NSW,-33.4549,149.6841,3
2795,Bald Ridge,New South Wales,NSW,-33.4708,149.6033,3
2795,Napoleon Reef,New South Wales,NSW,-33.4549,149.6841,3
2795,Bathampton,New South Wales,NSW,-33.4549,149.6841,3
2795,Newbridge,New South Wales,NSW,-33.5833,149.3833,4
2795,Rock Forest,New South Wales,NSW,-33.4549,149.6841,3
2795,Wambool,New South Wales,NSW,-33.4708,149.6033,3
2795,Duramana,New South Wales,NSW,-33.4708,149.6033,3
2795,Curragh,New South Wales,NSW,-33.4549,149.6841,3
2795,Rockley Mount,New South Wales,NSW,-33.4549,149.6841,3
2797,Garland,New South Wales,NSW,-33.7167,149.0333,4
2797,Lyndhurst,New South Wales,NSW,-33.6733,149.0477,4
2798,Forest Reefs,New South Wales,NSW,-33.4645,149.079,4
2798,Tallwood,New South Wales,NSW,-33.4894,149.084,3
2798,Spring Terrace,New South Wales,NSW,-33.4,149.1167,4
2798,Guyong,New South Wales,NSW,-33.4368,149.127,3
2798,Millthorpe,New South Wales,NSW,-33.446,149.1854,4
2799,Neville,New South Wales,NSW,-33.7118,149.2182,4
2799,Kings Plains,New South Wales,NSW,-33.5,149.3333,4
2799,Barry,New South Wales,NSW,-33.6484,149.2743,4
2799,Blayney,New South Wales,NSW,-33.5323,149.2537,4
2799,Vittoria,New South Wales,NSW,-33.5868,149.2635,3
2799,Fitzgeralds Mount,New South Wales,NSW,-33.5997,149.2467,3
2799,Browns Creek,New South Wales,NSW,-33.5868,149.2635,3
2800,Lucknow,New South Wales,NSW,-33.346,149.1621,4
2800,Nashdale,New South Wales,NSW,-33.2833,149.0333,4
2800,Summer Hill Creek,New South Wales,NSW,-33.2968,149.174,3
2800,Huntley,New South Wales,NSW,-33.3695,149.1343,4
2800,Canobolas,New South Wales,NSW,-33.3,149.0667,4
2800,Borenore,New South Wales,NSW,-33.2346,148.9815,4
2800,Ophir,New South Wales,NSW,-33.1667,149.2333,4
2800,March,New South Wales,NSW,-33.2789,149.0298,3
2800,Spring Hill,New South Wales,NSW,-33.3988,149.1525,4
2800,Clergate,New South Wales,NSW,-33.1833,149.1333,4
2800,Clifton Grove,New South Wales,NSW,-33.2547,149.156,4
2800,Spring Creek,New South Wales,NSW,-33.2968,149.174,3
2800,Lidster,New South Wales,NSW,-33.2833,149.0167,4
2800,Bowen,New South Wales,NSW,-33.2789,149.0298,3
2800,Lewis Ponds,New South Wales,NSW,-33.2667,149.2667,4
2800,Windera,New South Wales,NSW,-33.2968,149.174,3
2800,Byng,New South Wales,NSW,-33.3476,149.2551,4
2800,Lower Lewis Ponds,New South Wales,NSW,-33.2,149.25,4
2800,Cadia,New South Wales,NSW,-33.4485,148.9846,4
2800,Waldegrave,New South Wales,NSW,-33.2968,149.174,3
2800,Mullion Creek,New South Wales,NSW,-33.1252,149.1276,4
2800,Orange,New South Wales,NSW,-33.284,149.1002,4
2800,Shadforth,New South Wales,NSW,-33.4,149.2333,4
2800,Orange East,New South Wales,NSW,-33.2968,149.174,3
2800,Four Mile Creek,New South Wales,NSW,-33.4318,148.9512,4
2800,Kerrs Creek,New South Wales,NSW,-33.0505,149.1004,4
2800,Belgravia,New South Wales,NSW,-33.1152,149.0346,4
2800,Narrambla,New South Wales,NSW,-33.2789,149.0298,3
2800,Emu Swamp,New South Wales,NSW,-33.2968,149.174,3
2800,Kangaroobie,New South Wales,NSW,-33.2968,149.174,3
2800,Springside,New South Wales,NSW,-33.2968,149.174,3
2800,Panuara,New South Wales,NSW,-33.2968,149.174,3
2800,Cargo,New South Wales,NSW,-33.4241,148.809,4
2800,Orange DC,New South Wales,NSW,-33.2968,149.174,3
2800,Boree,New South Wales,NSW,-35.1066,146.6085,4
2803,Crowther,New South Wales,NSW,-34.1167,148.4833,4
2803,Wirrimah,New South Wales,NSW,-34.1471,148.5206,3
2803,Bendick Murrell,New South Wales,NSW,-34.1667,148.4333,4
2804,Nyrang Creek,New South Wales,NSW,-33.5333,148.55,4
2804,Moorbel,New South Wales,NSW,-33.593,148.6103,3
2804,Canowindra,New South Wales,NSW,-33.5625,148.6643,4
2804,Billimari,New South Wales,NSW,-33.6741,148.6196,4
2805,Gooloogong,New South Wales,NSW,-33.6241,148.409,4
2806,Eugowra,New South Wales,NSW,-33.4273,148.3714,4
2807,Koorawatha,New South Wales,NSW,-34.0378,148.5561,4
2808,Wyangala,New South Wales,NSW,-33.968,148.9482,4
2809,Greenethorpe,New South Wales,NSW,-34,148.4,4
2810,Pinnacle,New South Wales,NSW,-33.8576,147.9607,3
2810,Piney Range,New South Wales,NSW,-33.85,147.9167,4
2810,Warraderry,New South Wales,NSW,-33.7667,148.2167,4
2810,Pullabooka,New South Wales,NSW,-33.7452,147.7893,4
2810,Glenelg,New South Wales,NSW,-33.8576,147.9607,3
2810,Caragabal,New South Wales,NSW,-33.8333,147.75,4
2810,Bimbi,New South Wales,NSW,-34.05,147.95,4
2810,Grenfell,New South Wales,NSW,-33.8955,148.1644,4
2818,Terrabella,New South Wales,NSW,-32.4318,148.7429,4
2818,Benolong,New South Wales,NSW,-32.4006,148.8303,3
2818,Ponto,New South Wales,NSW,-32.4006,148.8303,3
2818,Geurie,New South Wales,NSW,-32.4006,148.8303,4
2820,Medway,New South Wales,NSW,-34.4916,150.2823,4
2820,Apsley,New South Wales,NSW,-32.5985,148.9679,4
2820,Walmer,New South Wales,NSW,-32.6667,148.75,4
2820,Gollan,New South Wales,NSW,-32.2667,149.0833,4
2820,Bakers Swamp,New South Wales,NSW,-32.781,148.9153,4
2820,Stuart Town,New South Wales,NSW,-32.8,149.0833,4
2820,Maryvale,New South Wales,NSW,-32.4735,148.9012,4
2820,Curra Creek,New South Wales,NSW,-32.6985,148.8179,4
2820,Arthurville,New South Wales,NSW,-32.5667,148.75,4
2820,Montefiores,New South Wales,NSW,-32.5333,148.9333,4
2820,Bodangora,New South Wales,NSW,-32.45,149,4
2820,Neurea,New South Wales,NSW,-32.69,148.9489,4
2820,Dripstone,New South Wales,NSW,-32.65,149,4
2820,Mount Arthur,New South Wales,NSW,-32.6003,148.9534,3
2820,Mumbil,New South Wales,NSW,-32.7167,149.05,4
2820,Wuuluman,New South Wales,NSW,-32.55,149.0833,4
2820,Wellington,New South Wales,NSW,-32.5559,148.9451,4
2820,Spicers Creek,New South Wales,NSW,-32.3958,149.143,3
2820,Farnham,New South Wales,NSW,-32.85,149.0833,4
2820,Suntop,New South Wales,NSW,-32.5652,148.8346,4
2820,Comobella,New South Wales,NSW,-32.4,148.9667,4
2820,Lake Burrendong,New South Wales,NSW,-32.6003,148.9534,3
2820,Mount Aquila,New South Wales,NSW,-32.6003,148.9534,3
2820,Mookerawa,New South Wales,NSW,-32.6003,148.9534,3
2820,Yarragal,New South Wales,NSW,-32.6003,148.9534,3
2821,Narromine,New South Wales,NSW,-32.2312,148.2405,4
2821,Burroway,New South Wales,NSW,-32.2312,148.2405,3
2823,Trangie,New South Wales,NSW,-32.0315,147.9834,4
2823,Cathundral,New South Wales,NSW,-31.9167,147.8333,4
2823,Dandaloo,New South Wales,NSW,-32.2833,147.6333,4
2823,Gin Gin,New South Wales,NSW,-31.9116,148.0898,4
2823,Bundemar,New South Wales,NSW,-32.0772,147.8167,3
2824,Mount Foster,New South Wales,NSW,-31.6887,147.8413,3
2824,Warren,New South Wales,NSW,-31.7022,147.8339,4
2824,Mount Harris,New South Wales,NSW,-31.6887,147.8413,3
2824,Snakes Plain,New South Wales,NSW,-31.6887,147.8413,3
2824,Eenaweena,New South Wales,NSW,-31.6887,147.8413,3
2824,Tenandra,New South Wales,NSW,-31.6887,147.8413,3
2824,Marthaguy,New South Wales,NSW,-31.6887,147.8413,3
2824,Ravenswood,New South Wales,NSW,-31.6971,147.84,4
2824,Red Hill,New South Wales,NSW,-35.0523,149.1643,4
2824,Beemunnel,New South Wales,NSW,-31.6624,147.8596,4
2824,Bullagreen,New South Wales,NSW,-31.6887,147.8413,3
2824,Pine Clump,New South Wales,NSW,-31.6887,147.8413,3
2824,Pigeonbah,New South Wales,NSW,-31.6887,147.8413,3
2824,Mumblebone Plain,New South Wales,NSW,-31.6887,147.8413,3
2824,Oxley,New South Wales,NSW,-31.6887,147.8413,3
2825,Nyngan,New South Wales,NSW,-31.5609,147.1882,4
2825,Mulla,New South Wales,NSW,-31.608,147.1822,3
2825,Miandetta,New South Wales,NSW,-31.5833,146.9667,4
2825,Bogan,New South Wales,NSW,-31.6046,147.1405,3
2825,Mullengudgery,New South Wales,NSW,-31.6985,147.4179,4
2825,Honeybugle,New South Wales,NSW,-31.608,147.1822,3
2825,Canonba,New South Wales,NSW,-31.3447,147.3473,4
2825,Pangee,New South Wales,NSW,-31.608,147.1822,3
2825,Murrawombie,New South Wales,NSW,-31.608,147.1822,3
2825,Babinda,New South Wales,NSW,-31.608,147.1822,3
2825,Buddabadah,New South Wales,NSW,-31.608,147.1822,3
2826,Nevertire,New South Wales,NSW,-31.8478,147.7215,4
2826,Bogan,New South Wales,NSW,-31.85,147.7167,3
2827,Biddon,New South Wales,NSW,-31.5602,148.8023,4
2827,Collie,New South Wales,NSW,-31.6667,148.3,4
2827,Gilgandra,New South Wales,NSW,-31.7117,148.6625,4
2827,Curban,New South Wales,NSW,-31.5418,148.5887,4
2827,Bearbong,New South Wales,NSW,-31.659,148.6258,3
2827,Breelong,New South Wales,NSW,-31.8152,148.8012,4
2827,Merrigal,New South Wales,NSW,-31.659,148.6258,3
2828,Armatree,New South Wales,NSW,-31.446,148.4748,4
2828,Tonderburine,New South Wales,NSW,-31.3843,148.4696,3
2828,Black Hollow,New South Wales,NSW,-31.3843,148.4696,3
2828,Bourbah,New South Wales,NSW,-31.3843,148.4696,3
2828,Warrumbungle,New South Wales,NSW,-31.3178,148.6229,3
2828,Gulargambone,New South Wales,NSW,-31.3353,148.4725,4
2828,Quanda,New South Wales,NSW,-31.3843,148.4696,3
2828,Mount Tenandra,New South Wales,NSW,-31.3843,148.4696,3
2829,Gilgooma,New South Wales,NSW,-30.7557,148.5391,4
2829,Gungalman,New South Wales,NSW,-30.8774,148.5111,3
2829,Pine Grove,New South Wales,NSW,-30.8774,148.5111,3
2829,Combara,New South Wales,NSW,-31.1168,148.3705,4
2829,Nebea,New South Wales,NSW,-30.8774,148.5111,3
2829,Teridgerie,New South Wales,NSW,-30.8667,148.8333,4
2829,Billeroy,New South Wales,NSW,-30.7,148.45,4
2829,Tooloon,New South Wales,NSW,-30.8774,148.5111,3
2829,Magometon,New South Wales,NSW,-30.8774,148.5111,3
2829,Conimbia,New South Wales,NSW,-30.8774,148.5111,3
2829,Coonamble,New South Wales,NSW,-30.9539,148.3888,4
2829,Urawilkie,New South Wales,NSW,-30.8774,148.5111,3
2829,Wingadee,New South Wales,NSW,-30.8774,148.5111,3
2830,Dubbo West,New South Wales,NSW,-32.1582,148.6012,3
2830,Minore,New South Wales,NSW,-32.25,148.4667,4
2830,Mogriguy,New South Wales,NSW,-32.0667,148.6833,4
2830,Brocklehurst,New South Wales,NSW,-32.1806,148.6229,4
2830,Ballimore,New South Wales,NSW,-32.2,148.9,4
2830,Muronbung,New South Wales,NSW,-32.1639,148.642,3
2830,Terramungamine,New South Wales,NSW,-32.0667,148.55,4
2830,Goonoo Forest,New South Wales,NSW,-32.1582,148.6012,3
2830,Rawsonville,New South Wales,NSW,-32.1667,148.5,4
2830,Dubbo Grove,New South Wales,NSW,-32.1582,148.6012,3
2830,Dubbo,New South Wales,NSW,-32.243,148.6048,4
2830,Dubbo East,New South Wales,NSW,-32.1582,148.6012,3
2830,Kickabil,New South Wales,NSW,-31.8,148.4833,4
2830,Dubbo DC,New South Wales,NSW,-32.1582,148.6012,3
2830,Toongi,New South Wales,NSW,-32.45,148.6,4
2830,Wambangalang,New South Wales,NSW,-32.1582,148.6012,3
2831,Wongarbon,New South Wales,NSW,-32.3328,148.7593,4
2831,Goodooga,New South Wales,NSW,-29.1131,147.4525,4
2831,Neilrex,New South Wales,NSW,-31.7226,149.3043,4
2831,Girilambone,New South Wales,NSW,-31.4472,146.3418,3
2831,Quambone,New South Wales,NSW,-30.9331,147.8722,4
2831,The Marra,New South Wales,NSW,-31.5488,148.1209,3
2831,Merrygoen,New South Wales,NSW,-31.8279,149.2326,4
2831,Hermidale,New South Wales,NSW,-31.4472,146.3418,3
2831,Armatree,New South Wales,NSW,-31.446,148.4748,4
2831,Nevertire,New South Wales,NSW,-31.8478,147.7215,4
2831,Elong Elong,New South Wales,NSW,-32.1235,149.0262,4
2831,Macquarie Marshes,New South Wales,NSW,-31.5488,148.1209,3
2831,Balladoran,New South Wales,NSW,-31.865,148.6521,4
2831,Tooraweenah,New South Wales,NSW,-31.4333,148.9,4
2831,Nymagee,New South Wales,NSW,-32.0667,146.3333,4
2831,Carinda,New South Wales,NSW,-30.4667,147.6833,4
2831,Eumungerie,New South Wales,NSW,-31.95,148.6215,4
2831,Byrock,New South Wales,NSW,-30.6606,146.4012,4
2831,Coolabah,New South Wales,NSW,-31.0295,146.7149,4
2832,Come By Chance,New South Wales,NSW,-30.3726,148.4874,4
2832,Cryon,New South Wales,NSW,-30.0086,148.6135,4
2832,Cumborah,New South Wales,NSW,-29.7469,147.7664,4
2832,Walgett,New South Wales,NSW,-30.0246,148.1157,4
2833,Collarenebri,New South Wales,NSW,-29.5438,148.5759,4
2834,Angledool,New South Wales,NSW,-29.1102,147.9097,4
2834,Lightning Ridge,New South Wales,NSW,-29.4274,147.9787,4
2835,Cobar,New South Wales,NSW,-31.4987,145.8418,4
2835,Bulla,New South Wales,NSW,-31.8274,146.0639,3
2835,Irymple,New South Wales,NSW,-32.4905,145.6074,4
2835,Gilgunnia,New South Wales,NSW,-32.4134,146.0379,4
2835,Canbelego,New South Wales,NSW,-31.5577,146.3216,4
2835,Kerrigundi,New South Wales,NSW,-31.8274,146.0639,3
2835,Tindarey,New South Wales,NSW,-31.8274,146.0639,3
2835,Noona,New South Wales,NSW,-31.8274,146.0639,3
2835,Cubba,New South Wales,NSW,-31.8274,146.0639,3
2835,Kulwin,New South Wales,NSW,-31.8274,146.0639,3
2835,Sandy Creek,New South Wales,NSW,-31.8274,146.0639,3
2836,White Cliffs,New South Wales,NSW,-30.8521,143.0886,4
2836,Wilcannia,New South Wales,NSW,-31.5588,143.3769,4
2838,Goodooga,New South Wales,NSW,-29.1131,147.4525,4
2839,Narran Lake,New South Wales,NSW,-29.998,146.8033,3
2839,Collerina,New South Wales,NSW,-29.6915,146.6479,4
2839,Talawanta,New South Wales,NSW,-29.998,146.8033,3
2839,Gongolgon,New South Wales,NSW,-30.3488,146.8979,4
2839,Weilmoringle,New South Wales,NSW,-29.2428,146.9219,4
2839,Brewarrina,New South Wales,NSW,-29.9607,146.8599,4
2840,Enngonia,New South Wales,NSW,-29.3223,145.8471,4
2840,Hungerford,New South Wales,NSW,-32.482,150.4871,3
2840,Louth,New South Wales,NSW,-30.5388,145.116,4
2840,Bourke,New South Wales,NSW,-30.0901,145.9365,4
2840,Yantabulla,New South Wales,NSW,-29.3457,145.0012,4
2840,Barringun,New South Wales,NSW,-29.0141,145.7159,4
2840,Fords Bridge,New South Wales,NSW,-29.7515,145.4273,4
2840,North Bourke,New South Wales,NSW,-30.0523,145.9489,4
2840,Wanaaring,New South Wales,NSW,-29.7027,144.1235,4
2840,Urisino,New South Wales,NSW,-29.7252,143.7619,4
2840,Gunderbooka,New South Wales,NSW,-30.07,145.9477,3
2840,Tilpa,New South Wales,NSW,-30.9356,144.4165,4
2840,Gumbalie,New South Wales,NSW,-30.07,145.9477,3
2842,Mendooran,New South Wales,NSW,-31.8226,149.1164,4
2842,Yarragrin,New South Wales,NSW,-31.8226,149.1164,3
2842,New Mollyan,New South Wales,NSW,-31.8262,149.1256,3
2842,Mollyan,New South Wales,NSW,-31.8226,149.1164,3
2843,Coolah,New South Wales,NSW,-31.8279,149.7156,4
2844,Leadville,New South Wales,NSW,-32,149.55,4
2844,Birriwa,New South Wales,NSW,-32.1167,149.4667,4
2844,Cobbora,New South Wales,NSW,-32.0667,149.25,4
2844,Dunedoo,New South Wales,NSW,-32.0163,149.3871,4
2845,Wallerawang,New South Wales,NSW,-33.411,150.0646,4
2846,Glen Davis,New South Wales,NSW,-33.1167,150.2833,4
2846,Round Swamp,New South Wales,NSW,-33.0735,149.9429,4
2846,Capertee,New South Wales,NSW,-33.15,149.9833,4
2847,Pipers Flat,New South Wales,NSW,-33.4436,149.9763,3
2847,Portland,New South Wales,NSW,-33.3571,149.9815,4
2848,Clandulla,New South Wales,NSW,-32.8833,149.9667,4
2848,Brogans Creek,New South Wales,NSW,-33,149.9333,4
2848,Kandos,New South Wales,NSW,-32.8577,149.9683,4
2848,Charbon,New South Wales,NSW,-32.8839,149.9701,4
2849,Upper Bylong,New South Wales,NSW,-32.4119,150.1201,4
2849,Bylong,New South Wales,NSW,-32.4119,150.1201,4
2849,Budden,New South Wales,NSW,-32.5152,150.0679,4
2849,Lee Creek,New South Wales,NSW,-32.6562,150.0774,3
2849,Ginghi,New South Wales,NSW,-32.5818,150.0846,4
2849,Coggan,New South Wales,NSW,-32.6562,150.0774,3
2849,Bogee,New South Wales,NSW,-32.9488,150.0938,4
2849,Dungeree,New South Wales,NSW,-32.6562,150.0774,3
2849,Camboon,New South Wales,NSW,-32.6985,149.9512,4
2849,Wirraba,New South Wales,NSW,-32.6562,150.0774,3
2849,Breakfast Creek,New South Wales,NSW,-32.6818,150.0012,4
2849,Mount Marsden,New South Wales,NSW,-32.6562,150.0774,3
2849,Rylstone,New South Wales,NSW,-32.7995,149.9694,4
2849,Upper Nile,New South Wales,NSW,-32.6562,150.0774,3
2849,Olinda,New South Wales,NSW,-32.8333,150.1333,4
2849,Kelgoola,New South Wales,NSW,-32.6562,150.0774,3
2849,Pinnacle Swamp,New South Wales,NSW,-32.6562,150.0774,3
2849,Coxs Creek,New South Wales,NSW,-32.6562,150.0774,3
2849,Dunville Loop,New South Wales,NSW,-32.6562,150.0774,3
2849,Coxs Crown,New South Wales,NSW,-32.6562,150.0774,3
2849,Murrumbo,New South Wales,NSW,-32.6562,150.0774,3
2849,Dabee,New South Wales,NSW,-32.6562,150.0774,3
2849,Growee,New South Wales,NSW,-32.6562,150.0774,3
2849,Pyangle,New South Wales,NSW,-32.6562,150.0774,3
2849,Glen Alice,New South Wales,NSW,-33.05,150.2167,4
2849,Reedy Creek,New South Wales,NSW,-32.6562,150.0774,3
2849,Nullo Mountain,New South Wales,NSW,-32.6562,150.0774,3
2849,Upper Growee,New South Wales,NSW,-32.6562,150.0774,3
2849,Carwell,New South Wales,NSW,-32.6562,150.0774,3
2850,Mogo,New South Wales,NSW,-35.7848,150.1417,4
2850,Turill,New South Wales,NSW,-32.1152,149.8429,4
2850,Lue,New South Wales,NSW,-32.6667,149.85,4
2850,Wilbetree,New South Wales,NSW,-32.6849,149.8056,3
2850,Bombira,New South Wales,NSW,-32.5719,149.6058,4
2850,Stony Creek,New South Wales,NSW,-32.4735,149.7346,4
2850,Apple Tree Flat,New South Wales,NSW,-32.6818,149.7012,4
2850,Ulan,New South Wales,NSW,-32.2833,149.7333,4
2850,Wollar,New South Wales,NSW,-32.35,149.95,4
2850,Twelve Mile,New South Wales,NSW,-32.5,149.2667,4
2850,Bara,New South Wales,NSW,-32.6278,149.652,3
2850,Hayes Gap,New South Wales,NSW,-32.6278,149.652,3
2850,Mudgee,New South Wales,NSW,-32.5943,149.5871,4
2850,Windeyer,New South Wales,NSW,-32.7667,149.55,4
2850,Pyramul,New South Wales,NSW,-32.8833,149.6167,4
2850,Cross Roads,New South Wales,NSW,-32.6278,149.652,3
2850,Cooyal,New South Wales,NSW,-32.4485,149.7596,4
2850,Running Stream,New South Wales,NSW,-33.0318,149.9096,4
2850,Caerleon,New South Wales,NSW,-32.6278,149.652,3
2850,Home Rule,New South Wales,NSW,-32.4167,149.6333,4
2850,Collingwood,New South Wales,NSW,-32.6278,149.652,3
2850,Sallys Flat,New South Wales,NSW,-33,149.5833,4
2850,Aarons Pass,New South Wales,NSW,-32.6278,149.652,3
2850,Ullamalla,New South Wales,NSW,-32.6278,149.652,3
2850,Avisford,New South Wales,NSW,-32.7318,149.4846,4
2850,Ilford,New South Wales,NSW,-32.9667,149.8667,4
2850,Putta Bucca,New South Wales,NSW,-32.5753,149.5848,4
2850,Mullamuddy,New South Wales,NSW,-32.6278,149.652,3
2850,Budgee Budgee,New South Wales,NSW,-32.5318,149.6679,4
2850,Erudgere,New South Wales,NSW,-32.6278,149.652,3
2850,Hargraves,New South Wales,NSW,-32.7866,149.4579,4
2850,Maitland Bar,New South Wales,NSW,-32.6278,149.652,3
2850,Barigan,New South Wales,NSW,-32.6278,149.652,3
2850,Piambong,New South Wales,NSW,-32.4985,149.3846,4
2850,Menah,New South Wales,NSW,-32.5494,149.5432,4
2850,Milroy,New South Wales,NSW,-31.15,150.05,4
2850,Mount Frome,New South Wales,NSW,-32.6,149.6333,4
2850,Munghorn,New South Wales,NSW,-32.45,149.8,4
2850,Yarrawonga,New South Wales,NSW,-32.4514,150.5858,4
2850,Canadian Lead,New South Wales,NSW,-32.6278,149.652,3
2850,Tambaroora,New South Wales,NSW,-33,149.4333,4
2850,Meroo,New South Wales,NSW,-34.8333,150.6167,4
2850,Cullenbone,New South Wales,NSW,-32.5,149.5167,4
2850,Eurunderee,New South Wales,NSW,-32.6278,149.652,3
2850,Buckaroo,New South Wales,NSW,-32.55,149.6333,4
2850,Triamble,New South Wales,NSW,-32.8833,149.3,4
2850,Grattai,New South Wales,NSW,-32.6833,149.4833,4
2850,Monivae,New South Wales,NSW,-32.6278,149.652,3
2850,Bocoble,New South Wales,NSW,-32.6278,149.652,3
2850,Spring Flat,New South Wales,NSW,-32.6278,149.652,3
2850,Linburn,New South Wales,NSW,-32.4235,149.7179,4
2850,Worlds End,New South Wales,NSW,-32.6278,149.652,3
2850,Kains Flat,New South Wales,NSW,-32.6278,149.652,3
2850,Tichular,New South Wales,NSW,-32.6278,149.652,3
2850,Yarrabin,New South Wales,NSW,-32.6278,149.652,3
2850,Galambine,New South Wales,NSW,-32.6278,149.652,3
2850,Cudgegong,New South Wales,NSW,-32.8,149.8167,4
2850,St Fillans,New South Wales,NSW,-32.6278,149.652,3
2850,Mount Knowles,New South Wales,NSW,-32.6152,149.7179,4
2850,Carcalgong,New South Wales,NSW,-32.6278,149.652,3
2850,Hill End,New South Wales,NSW,-33.0333,149.4167,4
2850,Cumbo,New South Wales,NSW,-32.6278,149.652,3
2850,Moolarben,New South Wales,NSW,-32.3485,149.7762,4
2850,Green Gully,New South Wales,NSW,-32.6278,149.652,3
2850,Havilah,New South Wales,NSW,-32.6187,149.765,4
2850,Queens Pinch,New South Wales,NSW,-32.6278,149.652,3
2850,Riverlea,New South Wales,NSW,-32.6278,149.652,3
2850,Frog Rock,New South Wales,NSW,-32.6278,149.652,3
2850,Wilpinjong,New South Wales,NSW,-32.3318,149.8846,4
2850,Botobolar,New South Wales,NSW,-32.5167,149.7833,4
2850,Burrundulla,New South Wales,NSW,-32.6278,149.652,3
2850,Totnes Valley,New South Wales,NSW,-32.6278,149.652,3
2850,Cooks Gap,New South Wales,NSW,-32.3557,149.7117,4
2852,Stubbo,New South Wales,NSW,-32.3,149.5333,4
2852,Bungaba,New South Wales,NSW,-32.3453,149.4269,3
2852,Guntawang,New South Wales,NSW,-32.3985,149.4846,4
2852,Two Mile Flat,New South Wales,NSW,-32.4068,149.3596,4
2852,Beryl,New South Wales,NSW,-32.3485,149.4429,4
2852,Barneys Reef,New South Wales,NSW,-32.3453,149.4269,3
2852,Mebul,New South Wales,NSW,-32.3485,149.3346,4
2852,Gulgong,New South Wales,NSW,-32.3625,149.532,4
2852,Tallawang,New South Wales,NSW,-32.2,149.45,4
2852,Cope,New South Wales,NSW,-32.3453,149.4269,3
2852,Goolma,New South Wales,NSW,-32.3818,149.2762,4
2852,Cumbandry,New South Wales,NSW,-32.3453,149.4269,3
2852,Merotherie,New South Wales,NSW,-32.3453,149.4269,3
2864,Murga,New South Wales,NSW,-33.3688,148.5511,4
2864,Cudal,New South Wales,NSW,-33.2856,148.7407,4
2864,Bowan Park,New South Wales,NSW,-33.3,148.8167,4
2864,Toogong,New South Wales,NSW,-33.3333,148.6333,4
2865,Gumble,New South Wales,NSW,-33.0818,148.6512,4
2865,Bocobra,New South Wales,NSW,-33.1329,148.6695,3
2865,Manildra,New South Wales,NSW,-33.1825,148.6891,4
2866,Amaroo,New South Wales,NSW,-33.1833,148.9333,4
2866,Boomey,New South Wales,NSW,-33.0618,148.9073,3
2866,Molong,New South Wales,NSW,-33.0923,148.87,4
2866,Euchareena,New South Wales,NSW,-32.9167,149.1167,4
2866,Garra,New South Wales,NSW,-33.1167,148.7667,4
2866,Larras Lee,New South Wales,NSW,-33,148.85,4
2866,Cundumbul,New South Wales,NSW,-33.0618,148.9073,3
2867,Baldry,New South Wales,NSW,-32.8667,148.5,4
2867,Loombah,New South Wales,NSW,-32.888,148.7016,3
2867,Cumnock,New South Wales,NSW,-32.9306,148.7549,4
2867,Yullundry,New South Wales,NSW,-32.888,148.7016,3
2867,Eurimbla,New South Wales,NSW,-32.8463,148.8435,4
2868,Obley,New South Wales,NSW,-32.7,148.5667,4
2868,North Yeoval,New South Wales,NSW,-32.7271,148.6074,3
2868,Yeoval,New South Wales,NSW,-32.7542,148.6481,4
2868,Bournewood,New South Wales,NSW,-32.7271,148.6074,3
2869,Trewilga,New South Wales,NSW,-32.7866,148.2298,4
2869,Tomingley,New South Wales,NSW,-32.5667,148.2333,4
2869,Peak Hill,New South Wales,NSW,-32.7239,148.1905,4
2870,Goonumbla,New South Wales,NSW,-33,148.1333,4
2870,Cooks Myalls,New South Wales,NSW,-33.1546,148.2428,3
2870,Cookamidgera,New South Wales,NSW,-33.2,148.3,4
2870,Alectown,New South Wales,NSW,-32.9333,148.25,4
2870,Mandagery,New South Wales,NSW,-33.2167,148.4167,4
2870,Daroobalgie,New South Wales,NSW,-33.3333,148.0667,4
2870,Parkes,New South Wales,NSW,-33.1372,148.1759,4
2870,Bumberry,New South Wales,NSW,-33.2,148.4833,4
2870,Tichborne,New South Wales,NSW,-33.2167,148.1167,4
2871,Bedgerebong,New South Wales,NSW,-33.35,147.7,4
2871,Forbes,New South Wales,NSW,-33.3844,148.0076,4
2871,Ooma,New South Wales,NSW,-33.7233,148.0891,4
2871,Bedgerabong,New South Wales,NSW,-33.4692,147.7868,3
2871,Fairholme,New South Wales,NSW,-33.2652,147.3846,4
2871,Warroo,New South Wales,NSW,-33.3333,147.5833,4
2871,Paytens Bridge,New South Wales,NSW,-33.4692,147.7868,3
2871,Gunning Gap,New South Wales,NSW,-33.4692,147.7868,3
2871,Wirrinya,New South Wales,NSW,-33.6667,147.8167,4
2871,Corinella,New South Wales,NSW,-33.4692,147.7868,3
2871,Jemalong,New South Wales,NSW,-33.4692,147.7868,3
2871,Mulyandry,New South Wales,NSW,-33.4692,147.7868,3
2871,Garema,New South Wales,NSW,-33.55,147.9333,4
2873,Albert,New South Wales,NSW,-32.35,147.5,4
2873,Tottenham,New South Wales,NSW,-32.2431,147.3559,4
2873,Miamley,New South Wales,NSW,-32.2966,147.428,3
2873,Five Ways,New South Wales,NSW,-32.2966,147.428,3
2874,Tullamore,New South Wales,NSW,-32.6311,147.5661,4
2875,Bruie Plains,New South Wales,NSW,-32.7833,147.85,4
2875,Trundle,New South Wales,NSW,-32.9248,147.7105,4
2875,Yarrabandai,New South Wales,NSW,-33.128,147.579,4
2875,Ootha,New South Wales,NSW,-33.1167,147.45,4
2875,Fifield,New South Wales,NSW,-32.7954,147.4656,4
2876,Bogan Gate,New South Wales,NSW,-33.1167,147.8167,4
2876,Nelungaloo,New South Wales,NSW,-33.1333,148,4
2876,Gunningbland,New South Wales,NSW,-33.1193,147.9146,4
2877,Bobadah,New South Wales,NSW,-32.519,146.7503,4
2877,Euabalong,New South Wales,NSW,-33.0574,146.3957,4
2877,Eremerang,New South Wales,NSW,-32.8447,146.7229,3
2877,Condobolin,New South Wales,NSW,-33.0881,147.1451,4
2877,Mulguthrie,New South Wales,NSW,-32.8447,146.7229,3
2877,Boona Mount,New South Wales,NSW,-32.6333,147.1667,4
2877,Mount Hope,New South Wales,NSW,-32.839,145.8806,4
2877,Euabalong West,New South Wales,NSW,-33.0574,146.3957,4
2877,Derriwong,New South Wales,NSW,-33.0985,147.3346,4
2877,Kiacatoo,New South Wales,NSW,-33.0652,146.8179,4
2878,Ivanhoe,New South Wales,NSW,-32.9004,144.3015,4
2878,Trida,New South Wales,NSW,-33.0185,145.0086,4
2878,Mossgiel,New South Wales,NSW,-33.2495,144.5657,4
2878,Conoble,New South Wales,NSW,-32.6986,144.1619,3
2879,Menindee,New South Wales,NSW,-32.3929,142.4182,4
2879,Sunset Strip,New South Wales,NSW,-32.2652,142.3012,4
2880,Broken Hill,New South Wales,NSW,-31.9652,141.4512,4
2880,Broken Hill West,New South Wales,NSW,-32.5166,148.6724,3
2880,Milparinka,New South Wales,NSW,-29.7345,141.8849,4
2880,Broken Hill North,New South Wales,NSW,-32.5166,148.6724,3
2880,South Broken Hill,New South Wales,NSW,-31.9652,141.4512,4
2880,Silverton,New South Wales,NSW,-31.884,141.2233,4
2880,Packsaddle,New South Wales,NSW,-32.5166,148.6724,3
2880,Broughams Gate,New South Wales,NSW,-32.5166,148.6724,3
2880,Mutawintji,New South Wales,NSW,-32.5166,148.6724,3
2880,Tibooburra,New South Wales,NSW,-29.4339,142.0102,4
2880,Little Topar,New South Wales,NSW,-32.5166,148.6724,3
2880,Fowlers Gap,New South Wales,NSW,-32.5166,148.6724,3
2890,Australian Defence Forces,New South Wales,NSW,-32.309,148.084,1
2891,International Mc,New South Wales,NSW,-32.5166,148.6724,1
2898,Lord Howe Island,New South Wales,NSW,-31.5526,159.0768,1
2899,Norfolk Island,New South Wales,NSW,-32.309,148.084,1
3500,Paringi,New South Wales,NSW,-34.1979,142.1515,3
3644,Barooga,New South Wales,NSW,-35.906,145.6958,4
3644,Lalalty,New South Wales,NSW,-35.9503,145.6546,3
3691,Lake Hume Village,New South Wales,NSW,-36.2843,147.0692,3
3707,Bringenbrong,New South Wales,NSW,-36.4104,147.9589,3
4377,Maryland,New South Wales,NSW,-28.55,152,4
4380,Mingoola,New South Wales,NSW,-28.9833,151.5167,4
4380,Amosfield,New South Wales,NSW,-28.6667,152.073,4
4380,Ruby Creek,New South Wales,NSW,-28.7241,151.825,3
4380,Undercliffe,New South Wales,NSW,-28.7241,151.825,3
4383,Jennings,New South Wales,NSW,-28.9307,151.9382,4
4385,Camp Creek,New South Wales,NSW,-28.85,151.0333,4
800,Darwin,Northern Territory,NT,-12.4611,130.8418,4
801,Darwin,Northern Territory,NT,-12.4611,130.8418,4
803,Wagait Beach,Northern Territory,NT,-12.4348,130.7443,4
804,Parap,Northern Territory,NT,-12.4305,130.8414,4
810,Casuarina,Northern Territory,NT,-12.374,130.8822,4
810,Tiwi,Northern Territory,NT,-12.3588,130.878,4
810,Lyons,Northern Territory,NT,-12.3591,130.8885,4
810,Nightcliff,Northern Territory,NT,-12.383,130.8517,4
810,Brinkin,Northern Territory,NT,-12.3706,130.868,4
810,Moil,Northern Territory,NT,-12.3885,130.8809,4
810,Millner,Northern Territory,NT,-12.3919,130.8625,4
810,Jingili,Northern Territory,NT,-12.3889,130.8728,4
810,Rapid Creek,Northern Territory,NT,-12.3804,130.8592,4
810,Alawa,Northern Territory,NT,-12.3795,130.8732,4
810,Muirhead,Northern Territory,NT,-12.3586,130.8935,4
810,Nakara,Northern Territory,NT,-12.3705,130.8773,4
810,Wagaman,Northern Territory,NT,-12.3807,130.8854,4
810,Coconut Grove,Northern Territory,NT,-12.396,130.8519,4
810,Wanguri,Northern Territory,NT,-12.3713,130.8881,4
810,Lee Point,Northern Territory,NT,-12.3415,130.8895,4
811,Casuarina,Northern Territory,NT,-12.374,130.8822,4
812,Sanderson,Northern Territory,NT,-12.3792,130.8784,3
812,Marrara,Northern Territory,NT,-12.4004,130.8929,4
812,Leanyer,Northern Territory,NT,-12.3667,130.9,4
812,Anula,Northern Territory,NT,-12.3912,130.8905,4
812,Woodleigh Gardens,Northern Territory,NT,-12.3792,130.8784,3
812,Malak,Northern Territory,NT,-12.3929,130.904,4
812,Buffalo Creek,Northern Territory,NT,-12.3368,130.9054,4
812,Holmes,Northern Territory,NT,-37.814,144.9633,3
812,Karama,Northern Territory,NT,-12.4022,130.916,4
812,Wulagi,Northern Territory,NT,-12.3834,130.8954,4
813,Sanderson,Northern Territory,NT,-12.3792,130.8784,3
813,Karama,Northern Territory,NT,-12.4022,130.916,4
814,Nightcliff,Northern Territory,NT,-12.383,130.8517,4
815,Charles Darwin University,Northern Territory,NT,-12.3792,130.8784,1
820,Winnellie,Northern Territory,NT,-12.4292,130.8859,4
820,East Point,Northern Territory,NT,-12.4078,130.8194,4
820,Bagot,Northern Territory,NT,-12.4153,130.8568,4
820,Charles Darwin,Northern Territory,NT,-12.4338,130.8737,4
820,Darwin DC,Northern Territory,NT,-12.4252,130.8697,3
820,Coonawarra,Northern Territory,NT,-12.4341,130.9098,4
820,Stuart Park,Northern Territory,NT,-12.445,130.843,4
820,Ludmilla,Northern Territory,NT,-12.4203,130.8551,4
820,Darwin International Airport,Northern Territory,NT,-12.4252,130.8697,3
820,Fannie Bay,Northern Territory,NT,-12.4227,130.8363,4
820,The Gardens,Northern Territory,NT,-12.449,130.8316,4
820,Larrakeyah,Northern Territory,NT,-12.4553,130.8317,4
820,Eaton,Northern Territory,NT,-12.4124,130.8807,4
820,Bayview,Northern Territory,NT,-12.4336,130.857,4
820,Woolner,Northern Territory,NT,-12.4354,130.8479,4
820,Parap,Northern Territory,NT,-12.4305,130.8414,4
820,The Narrows,Northern Territory,NT,-12.4264,130.8595,4
821,Winnellie,Northern Territory,NT,-12.4292,130.8859,4
822,Milyakburra,Northern Territory,NT,-13.7794,136.2019,4
822,Blackmore,Northern Territory,NT,-12.7593,130.9382,4
822,Murrumujuk,Northern Territory,NT,-12.2609,131.0717,4
822,Maningrida,Northern Territory,NT,-12.0674,134.0866,4
822,Nganmarriyanga,Northern Territory,NT,-14.335,129.8835,4
822,Gunbalanya,Northern Territory,NT,-12.3247,133.0509,4
822,Tiwi Islands,Northern Territory,NT,-11.5602,130.8174,4
822,Coomalie Creek,Northern Territory,NT,-12.6978,130.8459,3
822,Darwin River Dam,Northern Territory,NT,-12.6978,130.8459,3
822,Acacia Hills,Northern Territory,NT,-12.7941,131.1254,4
822,Charles Darwin,Northern Territory,NT,-12.4338,130.8737,4
822,Anindilyakwa,Northern Territory,NT,-12.6978,130.8459,3
822,Daly River,Northern Territory,NT,-13.7654,130.7123,4
822,Lloyd Creek,Northern Territory,NT,-12.6767,131.1338,4
822,Mandorah,Northern Territory,NT,-12.4457,130.7574,4
822,Thamarrurr,Northern Territory,NT,-14.2032,129.7052,4
822,East Arnhem,Northern Territory,NT,-12.6978,130.8459,3
822,Wickham,Northern Territory,NT,-12.5539,130.9223,4
822,Numbulwar,Northern Territory,NT,-14.2761,135.7372,4
822,Darwin Mc,Northern Territory,NT,-13.0616,132.8034,3
822,Nauiyu,Northern Territory,NT,-13.7425,130.6956,4
822,Tumbling Waters,Northern Territory,NT,-12.7685,130.95,4
822,Kakadu,Northern Territory,NT,-12.8685,132.474,4
822,Bathurst Island,Northern Territory,NT,-12.6978,130.8459,3
822,Wadeye,Northern Territory,NT,-14.2383,129.5218,4
822,Hughes,Northern Territory,NT,-12.7007,131.0981,4
822,Marrakai,Northern Territory,NT,-12.7505,131.4806,4
822,Delissaville,Northern Territory,NT,-13.0616,132.8034,3
822,Pularumpi,Northern Territory,NT,-11.4049,130.4156,4
822,Pirlangimpi,Northern Territory,NT,-11.403,130.429,4
822,Croker Island,Northern Territory,NT,-13.0616,132.8034,3
822,Peppimenarti,Northern Territory,NT,-14.1555,130.0815,4
822,Douglas-Daly,Northern Territory,NT,-12.6978,130.8459,3
822,Numburindi,Northern Territory,NT,-13.9293,135.704,4
822,Litchfield Park,Northern Territory,NT,-12.6978,130.8459,3
822,Wurrumiyanga,Northern Territory,NT,-11.7608,130.6256,4
822,Nemarluk,Northern Territory,NT,-14.2768,130.3759,4
822,West Arnhem,Northern Territory,NT,-12.2352,134.0603,4
822,Umbakumba,Northern Territory,NT,-13.0616,132.8034,3
822,Charlotte,Northern Territory,NT,-12.6978,130.8459,3
822,Hidden Valley,Northern Territory,NT,-12.4424,130.9094,4
822,Southport,Northern Territory,NT,-12.7218,130.9514,4
822,Bynoe Harbour,Northern Territory,NT,-12.6978,130.8459,3
822,Warruwi,Northern Territory,NT,-11.6457,133.3884,4
822,Angurugu,Northern Territory,NT,-13.0616,132.8034,3
822,Cox Peninsula,Northern Territory,NT,-12.4268,130.6408,4
822,Bynoe,Northern Territory,NT,-12.6978,130.8459,3
822,Black Jungle,Northern Territory,NT,-12.5138,131.2193,4
822,Weddell,Northern Territory,NT,-12.6978,130.8459,3
822,Fly Creek,Northern Territory,NT,-12.776,131.0445,4
822,Claravale,Northern Territory,NT,-12.6978,130.8459,3
822,Goulburn Island,Northern Territory,NT,-11.65,133.3883,4
822,Mcminns Lagoon,Northern Territory,NT,-12.554,131.1116,4
822,Lambells Lagoon,Northern Territory,NT,-12.5845,131.2503,4
822,East Arm,Northern Territory,NT,-12.6978,130.8459,3
822,Bees Creek,Northern Territory,NT,-12.5795,131.0593,4
822,Finniss Valley,Northern Territory,NT,-12.6978,130.8459,3
822,Livingstone,Northern Territory,NT,-12.6814,131.0741,4
822,Tipperary,Northern Territory,NT,-12.6978,130.8459,3
822,Annie River,Northern Territory,NT,-12.6978,130.8459,3
822,Tortilla Flats,Northern Territory,NT,-12.6978,130.8459,3
822,Burrundie,Northern Territory,NT,-12.6978,130.8459,3
822,Tivendale,Northern Territory,NT,-12.6978,130.8459,3
822,Nguiu,Northern Territory,NT,-11.7604,130.636,4
822,Vernon Islands,Northern Territory,NT,-12.6978,130.8459,3
822,Minjilang,Northern Territory,NT,-11.1584,132.5453,4
822,Stapleton,Northern Territory,NT,-12.6978,130.8459,3
822,Oenpelli,Northern Territory,NT,-12.3237,133.0576,4
822,Rakula,Northern Territory,NT,-12.6978,130.8459,3
822,Galiwinku,Northern Territory,NT,-12.0281,135.5649,4
822,Robin Falls,Northern Territory,NT,-12.6978,130.8459,3
822,Channel Island,Northern Territory,NT,-12.5533,130.8685,4
822,Wak Wak,Northern Territory,NT,-12.6978,130.8459,3
822,Milingimbi,Northern Territory,NT,-12.1019,134.919,4
822,Glyde Point,Northern Territory,NT,-12.6978,130.8459,3
822,Camp Creek,Northern Territory,NT,-12.6978,130.8459,3
822,Eva Valley,Northern Territory,NT,-12.6978,130.8459,3
822,Winnellie,Northern Territory,NT,-12.4292,130.8859,4
822,Daly,Northern Territory,NT,-12.6978,130.8459,3
822,Hotham,Northern Territory,NT,-12.6978,130.8459,3
822,Hayes Creek,Northern Territory,NT,-12.6978,130.8459,3
822,Ramingining,Northern Territory,NT,-13.0616,132.8034,3
822,Mapuru,Northern Territory,NT,-12.6978,130.8459,3
822,Freds Pass,Northern Territory,NT,-12.5394,131.0569,4
822,Middle Point,Northern Territory,NT,-12.6978,130.8459,3
822,Koolpinyah,Northern Territory,NT,-12.6978,130.8459,3
822,Wagait Beach,Northern Territory,NT,-12.4348,130.7443,4
822,Mickett Creek,Northern Territory,NT,-12.6978,130.8459,3
822,Point Stephens,Northern Territory,NT,-12.6978,130.8459,3
822,Belyuen,Northern Territory,NT,-12.5381,130.6982,4
822,Margaret River,Northern Territory,NT,-12.6978,130.8459,3
822,Woolaning,Northern Territory,NT,-12.6978,130.8459,3
822,Wishart,Northern Territory,NT,-12.6978,130.8459,3
822,Collett Creek,Northern Territory,NT,-12.6978,130.8459,3
822,Maranunga,Northern Territory,NT,-12.6978,130.8459,3
822,Milikapiti,Northern Territory,NT,-11.4255,130.6748,3
822,Lake Bennett,Northern Territory,NT,-12.6978,130.8459,3
822,Cobourg,Northern Territory,NT,-12.6978,130.8459,3
822,Fleming,Northern Territory,NT,-12.6978,130.8459,3
822,Sandpalms Roadhouse,Northern Territory,NT,-12.6978,130.8459,3
822,Gunn Point,Northern Territory,NT,-12.6978,130.8459,3
822,Point Stuart,Northern Territory,NT,-12.6978,130.8459,3
822,Mount Bundey,Northern Territory,NT,-12.6978,130.8459,3
822,Rum Jungle,Northern Territory,NT,-12.6978,130.8459,3
822,Elrundie,Northern Territory,NT,-12.4789,130.9599,4
828,Berrimah,Northern Territory,NT,-12.4351,130.9261,4
828,Knuckey Lagoon,Northern Territory,NT,-12.4266,130.9457,4
829,Pinelands,Northern Territory,NT,-12.4576,130.9598,4
829,Holtze,Northern Territory,NT,-12.4485,131.0056,4
830,Driver,Northern Territory,NT,-12.486,130.9754,4
830,Archer,Northern Territory,NT,-12.5144,130.9688,4
830,Shoal Bay,Northern Territory,NT,-12.4731,130.9721,3
830,Marlow Lagoon,Northern Territory,NT,-12.4913,130.9641,4
830,Farrar,Northern Territory,NT,-12.4803,130.9982,4
830,Durack,Northern Territory,NT,-12.4731,130.9721,4
830,Palmerston,Northern Territory,NT,-12.486,130.9833,4
830,Gray,Northern Territory,NT,-12.4904,130.9823,4
830,Woodroffe,Northern Territory,NT,-12.5017,130.9802,4
830,Moulden,Northern Territory,NT,-12.5056,130.9731,4
830,Yarrawonga,Northern Territory,NT,-12.4725,130.9884,4
831,Palmerston,Northern Territory,NT,-12.486,130.9833,4
832,Zuccoli,Northern Territory,NT,-12.4876,131.0117,3
832,Bakewell,Northern Territory,NT,-12.4968,130.9934,4
832,Johnston,Northern Territory,NT,-12.4876,131.0117,4
832,Gunn,Northern Territory,NT,-12.4876,130.9932,4
832,Rosebery,Northern Territory,NT,-12.5105,130.9878,4
832,Mitchell,Northern Territory,NT,-12.4986,130.9984,3
832,Rosebery Heights,Northern Territory,NT,-12.4876,131.0117,3
832,Bellamack,Northern Territory,NT,-12.5171,130.9836,4
834,Virginia,Northern Territory,NT,-12.519,131.0284,4
835,Coolalinga,Northern Territory,NT,-12.5231,131.0415,4
835,Howard Springs,Northern Territory,NT,-12.4958,131.0445,4
835,Virginia,Northern Territory,NT,-12.519,131.0284,4
836,Girraween,Northern Territory,NT,-12.5253,131.0957,4
836,Humpty Doo,Northern Territory,NT,-12.5841,131.1367,4
836,Herbert,Northern Territory,NT,-12.5341,131.162,4
837,Manton,Northern Territory,NT,-12.8937,131.1132,4
837,Noonamah,Northern Territory,NT,-12.6312,131.0799,4
838,Berry Springs,Northern Territory,NT,-12.6968,131.01,4
839,Coolalinga,Northern Territory,NT,-12.5231,131.0415,4
840,Dundee Beach,Northern Territory,NT,-12.7176,130.3513,1
840,Dundee Downs,Northern Territory,NT,-12.7522,130.5154,1
840,Dundee Forest,Northern Territory,NT,-12.7168,130.4475,1
841,Darwin River,Northern Territory,NT,-12.8193,130.9697,4
845,Batchelor,Northern Territory,NT,-13.0505,131.0307,4
846,Adelaide River,Northern Territory,NT,-13.2379,131.1056,4
847,Pine Creek,Northern Territory,NT,-13.8208,131.8329,4
850,Cossack,Northern Territory,NT,-14.4555,132.1748,4
850,Katherine,Northern Territory,NT,-14.4652,132.2635,4
850,Katherine South,Northern Territory,NT,-14.4435,132.2814,3
850,Lansdowne,Northern Territory,NT,-14.4314,132.3879,4
850,Emungalan,Northern Territory,NT,-14.4435,132.2814,3
850,Katherine East,Northern Territory,NT,-14.4435,132.2814,3
851,Katherine,Northern Territory,NT,-14.4652,132.2635,4
852,Yarralin,Northern Territory,NT,-16.4526,130.8419,4
852,Beswick,Northern Territory,NT,-14.558,133.1151,4
852,Barunga,Northern Territory,NT,-14.5196,132.8644,4
852,Wave Hill,Northern Territory,NT,-17.4476,130.8344,4
852,Jilkminggan,Northern Territory,NT,-14.9615,133.2928,4
852,Maranboy,Northern Territory,NT,-14.5333,132.7833,4
852,Daguragu,Northern Territory,NT,-17.4193,130.8021,4
852,Wilton,Northern Territory,NT,-15.0045,133.3768,3
852,Numbulwar,Northern Territory,NT,-14.2761,135.7372,4
852,Ngukurr,Northern Territory,NT,-14.7308,134.731,4
852,Baines,Northern Territory,NT,-15.9828,129.4996,4
852,Uralla,Northern Territory,NT,-14.4911,132.2983,4
852,Gregory,Northern Territory,NT,-15.0045,133.3768,3
852,Victoria River Downs,Northern Territory,NT,-16.0432,132.4219,3
852,Venn,Northern Territory,NT,-15.0045,133.3768,3
852,Timber Creek,Northern Territory,NT,-15.6625,130.4804,4
852,Edith,Northern Territory,NT,-15.0045,133.3768,3
852,Binjari,Northern Territory,NT,-14.5501,132.1842,4
852,Manbulloo,Northern Territory,NT,-15.0045,133.3768,3
852,Top Springs,Northern Territory,NT,-16.5438,131.7977,4
852,Gurindji,Northern Territory,NT,-15.0045,133.3768,3
852,Bulman Weemol,Northern Territory,NT,-15.0045,133.3768,3
852,Kalkarindji,Northern Territory,NT,-17.4476,130.8344,4
852,Limmen,Northern Territory,NT,-15.5241,134.8004,4
852,Birdum,Northern Territory,NT,-15.65,133.2167,4
852,Arnold,Northern Territory,NT,-15.0045,133.3768,3
852,Elsey,Northern Territory,NT,-15.0045,133.3768,3
852,Bradshaw,Northern Territory,NT,-15.0045,133.3768,3
852,Creswell,Northern Territory,NT,-15.0045,133.3768,3
852,Tanami East,Northern Territory,NT,-15.0045,133.3768,3
852,Delamere,Northern Territory,NT,-15.0045,133.3768,3
852,Lajamanu,Northern Territory,NT,-16.0432,132.4219,3
852,Flying Fox,Northern Territory,NT,-15.0045,133.3768,3
852,Beswick Creek,Northern Territory,NT,-15.0045,133.3768,3
852,Victoria River,Northern Territory,NT,-15.0045,133.3768,3
852,Pellew Islands,Northern Territory,NT,-15.0045,133.3768,3
852,Florina,Northern Territory,NT,-15.0045,133.3768,3
852,Robinson River,Northern Territory,NT,-15.0045,133.3768,3
852,Daly Waters,Northern Territory,NT,-16.2533,133.3693,4
852,Larrimah,Northern Territory,NT,-16.0432,132.4219,3
852,Gulung Mardrulk,Northern Territory,NT,-15.0045,133.3768,3
852,Pigeon Hole,Northern Territory,NT,-15.0045,133.3768,3
852,Miniyeri,Northern Territory,NT,-15.2255,134.0798,4
852,Katherine,Northern Territory,NT,-14.4652,132.2635,4
852,Mataranka,Northern Territory,NT,-14.9213,133.065,4
852,Helen Springs Station,Northern Territory,NT,-15.0045,133.3768,3
852,Mcarthur,Northern Territory,NT,-15.0045,133.3768,3
852,Buchanan,Northern Territory,NT,-15.0045,133.3768,3
852,Sturt Plateau,Northern Territory,NT,-15.0045,133.3768,3
852,Warumungu,Northern Territory,NT,-15.0045,133.3768,3
852,Nitmiluk,Northern Territory,NT,-15.0045,133.3768,3
853,Tindal,Northern Territory,NT,-14.5312,132.3793,4
853,Tindal Raaf,Northern Territory,NT,-14.5733,132.54,3
854,King Ash Bay,Northern Territory,NT,-14.8923,133.1577,1
854,Borroloola,Northern Territory,NT,-16.0703,136.3087,1
860,Tennant Creek,Northern Territory,NT,-19.6548,134.1871,1
861,Tennant Creek,Northern Territory,NT,-23.7,133.8833,1
862,Warrego,Northern Territory,NT,-19.4362,133.8208,4
862,Tennant Creek,Northern Territory,NT,-19.6497,134.1915,4
862,Newcastle Waters,Northern Territory,NT,-18.9889,135.0004,3
862,Renner Springs,Northern Territory,NT,-37.8421,145.0694,3
862,Calvert,Northern Territory,NT,-16.9206,137.2034,4
862,Tablelands,Northern Territory,NT,-37.8421,145.0694,3
862,Nicholson,Northern Territory,NT,-37.8421,145.0694,3
862,Pamayu,Northern Territory,NT,-37.8421,145.0694,3
862,Elliott,Northern Territory,NT,-17.5507,133.5407,4
870,Stuart,Northern Territory,NT,-23.6975,133.8836,1
870,Ross,Northern Territory,NT,-23.7442,133.9036,1
870,Larapinta,Northern Territory,NT,-23.6803,133.8311,1
870,Araluen,Northern Territory,NT,-23.696,133.854,1
870,Mount Johns,Northern Territory,NT,-23.7232,133.913,1
870,Flynn,Northern Territory,NT,-23.7135,133.8356,1
870,The Gap,Northern Territory,NT,-23.7133,133.8739,1
870,Sadadeen,Northern Territory,NT,-23.704,133.9004,1
870,East Side,Northern Territory,NT,-23.692,133.8932,1
870,Arumbera,Northern Territory,NT,-23.7,133.874,1
870,Connellan,Northern Territory,NT,-23.7,133.874,1
870,Alice Springs,Northern Territory,NT,-23.735,133.8548,1
870,Irlpme,Northern Territory,NT,-23.6469,133.8648,1
870,Ilparpa,Northern Territory,NT,-23.7,133.874,1
870,Undoolya,Northern Territory,NT,-23.7,133.874,1
870,Gillen,Northern Territory,NT,-23.7092,133.866,1
870,Ciccone,Northern Territory,NT,-23.7,133.874,1
870,White Gums,Northern Territory,NT,-23.7,133.874,1
870,Braitling,Northern Territory,NT,-23.68,133.8688,1
870,Desert Springs,Northern Territory,NT,-23.7,133.874,1
871,Alice Springs,Northern Territory,NT,-23.7,133.8833,1
872,Ampilatwatja,Northern Territory,NT,-21.657,135.2259,4
872,Wilora,Northern Territory,NT,-21.7443,133.7265,4
872,Barrow Creek,Northern Territory,NT,-21.55,133.8833,4
872,Hale,Northern Territory,NT,-23.3256,133.0557,3
872,Laramba,Northern Territory,NT,-22.5434,132.7624,4
872,Ali Curung,Northern Territory,NT,-21.0199,134.3249,4
872,Wallace Rockhole,Northern Territory,NT,-24.1242,133.091,3
872,Yuendumu,Northern Territory,NT,-22.2667,131.8167,4
872,Papunya,Northern Territory,NT,-23.2045,131.9122,4
872,Tara,Northern Territory,NT,-21.4833,133.9992,4
872,Davenport,Northern Territory,NT,-23.3256,133.0557,3
872,Kulgera,Northern Territory,NT,-23.3256,133.0557,3
872,Anmatjere,Northern Territory,NT,-23.3256,133.0557,3
872,Wutunugurra,Northern Territory,NT,-20.4623,135.2505,4
872,Namatjira,Northern Territory,NT,-23.3256,133.0557,3
872,Canteen Creek,Northern Territory,NT,-20.6403,135.5865,4
872,Hart,Northern Territory,NT,-23.3256,133.0557,3
872,Burt Plain,Northern Territory,NT,-23.3256,133.0557,3
872,Napperby Station,Northern Territory,NT,-23.3256,133.0557,3
872,Engawala,Northern Territory,NT,-22.8031,134.4564,4
872,Atitjere,Northern Territory,NT,-23.3256,133.0557,3
872,Nyirripi,Northern Territory,NT,-22.6492,130.5639,4
872,Imanpa,Northern Territory,NT,-25.1176,132.5688,3
872,Sandover,Northern Territory,NT,-21.9596,135.5128,4
872,Finke,Northern Territory,NT,-25.698,134.3397,3
872,Tanami,Northern Territory,NT,-23.3256,133.0557,3
872,Santa Teresa,Northern Territory,NT,-24.7306,135.3322,3
872,Mutitjulu,Northern Territory,NT,-23.3256,133.0557,3
872,Anatye,Northern Territory,NT,-23.3256,133.0557,3
872,Costello,Northern Territory,NT,-14.5655,134.8794,3
872,Erldunda,Northern Territory,NT,-23.3256,133.0557,3
872,Chilla Well,Northern Territory,NT,-23.3256,133.0557,3
872,Alice Springs,Northern Territory,NT,-23.7917,132.6818,3
872,Titjikala,Northern Territory,NT,-24.6802,134.0751,3
872,Areyonga,Northern Territory,NT,-24.1669,132.2868,3
872,Willowra,Northern Territory,NT,-21.2632,132.6191,4
872,Hart Range,Northern Territory,NT,-23.3256,133.0557,3
872,Yuelamu,Northern Territory,NT,-22.2617,132.1994,4
872,Ti Tree,Northern Territory,NT,-22.1338,133.4139,4
872,Yulara,Northern Territory,NT,-25.242,130.9856,3
872,Haasts Bluff,Northern Territory,NT,-23.5093,132.1852,3
872,Mereenie,Northern Territory,NT,-23.3256,133.0557,3
872,Mount Zeil,Northern Territory,NT,-23.3256,133.0557,3
872,Kaltukatjara,Northern Territory,NT,-23.7917,132.6818,3
872,Hermannsburg,Northern Territory,NT,-23.7917,132.6818,3
872,Kings Creek Station,Northern Territory,NT,-23.3256,133.0557,3
872,Kintore,Northern Territory,NT,-23.7917,132.6818,3
872,Hugh,Northern Territory,NT,-23.3256,133.0557,3
872,Telegraph Station,Northern Territory,NT,-23.3256,133.0557,3
872,Lake Mackay,Northern Territory,NT,-23.3256,133.0557,3
872,Ininti Store,Northern Territory,NT,-23.3256,133.0557,3
872,Macdonnell Range,Northern Territory,NT,-23.3256,133.0557,3
872,Simpson,Northern Territory,NT,-23.3256,133.0557,3
872,Mount Liebig,Northern Territory,NT,-23.3256,133.0557,3
872,Ghan,Northern Territory,NT,-23.3256,133.0557,3
872,Kunparrka,Northern Territory,NT,-23.3611,131.6665,3
872,Amoonguna,Northern Territory,NT,-23.7709,133.9365,3
872,Petermann,Northern Territory,NT,-23.3256,133.0557,3
873,Heavitree Gap Cpa,Northern Territory,NT,-23.5298,133.5021,1
880,Nhulunbuy,Northern Territory,NT,-12.1816,136.7784,4
880,Yirrkala,Northern Territory,NT,-12.2549,136.8899,4
880,Gapuwiyak,Northern Territory,NT,-12.5031,135.8105,4
880,Gunyangara,Northern Territory,NT,-12.2165,136.7054,4
881,Nhulunbuy,Northern Territory,NT,-12.1816,136.7784,4
885,Alyangula,Northern Territory,NT,-13.8541,136.4213,4
886,Jabiru,Northern Territory,NT,-12.6705,132.836,4
906,Winnellie,Northern Territory,NT,-12.4292,130.8859,4
907,Winnellie,Northern Territory,NT,-12.4292,130.8859,4
909,Charles Darwin University,Northern Territory,NT,-12.3731,130.8681,1
4825,Alpurrurulam,Northern Territory,NT,-20.1386,145.8436,3
4000,Brisbane City,Queensland,QLD,-27.4679,153.0281,4
4000,Brisbane Adelaide Street,Queensland,QLD,-27.454,153.0426,3
4000,Brisbane,Queensland,QLD,-27.4679,153.0281,4
4000,Petrie Terrace,Queensland,QLD,-27.463,153.0131,4
4000,Spring Hill,Queensland,QLD,-27.4614,153.0231,4
4000,Brisbane GPO,Queensland,QLD,-27.4651,153.0231,3
4001,Brisbane,Queensland,QLD,-27.4679,153.0281,4
4001,Central Plaza,Queensland,QLD,-27.5,153.0167,3
4001,Riverside Centre,Queensland,QLD,-27.5,153.0167,3
4001,Waterfront Place,Queensland,QLD,-27.5,153.0167,3
4002,City East,Queensland,QLD,-27.454,153.0426,1
4002,Wintergarden,Queensland,QLD,-27.454,153.0426,1
4003,George Street,Queensland,QLD,-27.454,153.0426,1
4004,Spring Hill,Queensland,QLD,-27.4614,153.0231,4
4005,New Farm,Queensland,QLD,-27.4667,153.05,4
4005,Teneriffe,Queensland,QLD,-27.4559,153.047,4
4006,Newstead,Queensland,QLD,-27.4436,153.0443,4
4006,Fortitude Valley BC,Queensland,QLD,-27.4473,153.032,3
4006,Bowen Hills,Queensland,QLD,-27.4432,153.0302,4
4006,Herston,Queensland,QLD,-27.4445,153.0185,4
4006,Fortitude Valley,Queensland,QLD,-27.4571,153.0318,4
4007,Hamilton,Queensland,QLD,-27.439,153.0629,4
4007,Ascot,Queensland,QLD,-27.4315,153.058,4
4007,Hamilton Central,Queensland,QLD,-27.4417,153.0667,3
4008,Pinkenba,Queensland,QLD,-27.428,153.1167,4
4008,Brisbane Airport,Queensland,QLD,-27.3947,153.114,4
4009,Eagle Farm BC,Queensland,QLD,-27.4306,153.0906,3
4009,Eagle Farm,Queensland,QLD,-27.4333,153.0833,4
4010,Albion DC,Queensland,QLD,-27.4301,153.0379,3
4010,Albion,Queensland,QLD,-27.4301,153.0379,4
4010,Albion BC,Queensland,QLD,-27.4301,153.0379,3
4011,Clayfield,Queensland,QLD,-27.4189,153.0582,4
4011,Hendra,Queensland,QLD,-27.4177,153.0703,4
4012,Nundah,Queensland,QLD,-27.4025,153.0617,4
4012,Wavell Heights,Queensland,QLD,-27.3938,153.047,4
4012,Toombul,Queensland,QLD,-27.4096,153.0584,4
4012,Wavell Heights North,Queensland,QLD,-27.3974,153.0546,3
4013,Northgate,Queensland,QLD,-27.4,153.0667,4
4014,Nudgee Beach,Queensland,QLD,-27.3462,153.1037,4
4014,Banyo,Queensland,QLD,-27.3824,153.079,4
4014,Virginia,Queensland,QLD,-27.3833,153.0667,4
4014,Virginia BC,Queensland,QLD,-27.3783,153.0768,3
4014,Nudgee,Queensland,QLD,-27.3691,153.0847,4
4014,Virginia DC,Queensland,QLD,-27.3783,153.0768,3
4017,Brighton,Queensland,QLD,-27.2958,153.0572,4
4017,Brighton Nathan Street,Queensland,QLD,-27.3192,153.0612,3
4017,Deagon,Queensland,QLD,-27.3333,153.0667,4
4017,Sandgate,Queensland,QLD,-27.322,153.0695,4
4017,Shorncliffe,Queensland,QLD,-27.3276,153.0816,4
4017,Brighton Eventide,Queensland,QLD,-27.3192,153.0612,3
4017,Bracken Ridge,Queensland,QLD,-27.3171,153.031,4
4017,Sandgate DC,Queensland,QLD,-27.3192,153.0612,3
4018,Taigum,Queensland,QLD,-27.3426,153.0444,4
4018,Fitzgibbon,Queensland,QLD,-27.3397,153.0292,4
4019,Woody Point,Queensland,QLD,-27.2561,153.1043,4
4019,Margate,Queensland,QLD,-27.2476,153.0981,4
4019,Margate Beach,Queensland,QLD,-27.2473,153.0905,3
4019,Clontarf,Queensland,QLD,-27.2534,153.0783,4
4019,Clontarf DC,Queensland,QLD,-27.2524,153.0936,3
4019,Clontarf Beach,Queensland,QLD,-27.2524,153.0936,3
4020,Newport,Queensland,QLD,-27.2073,153.1013,4
4020,Redcliffe North,Queensland,QLD,-27.213,153.103,3
4020,Scarborough,Queensland,QLD,-27.2012,153.1094,4
4020,Redcliffe,Queensland,QLD,-27.2307,153.0973,4
4021,Kippa-Ring,Queensland,QLD,-27.2259,153.0835,4
4022,Rothwell,Queensland,QLD,-27.2143,153.0468,4
4025,Tangalooma,Queensland,QLD,-27.1794,153.3743,4
4025,Cape Moreton,Queensland,QLD,-27.0282,153.4674,4
4025,Cowan Cowan,Queensland,QLD,-27.1259,153.3661,4
4025,Bulwer,Queensland,QLD,-27.0787,153.3697,4
4025,Kooringal,Queensland,QLD,-27.3536,153.4247,4
4029,Royal Brisbane Hospital,Queensland,QLD,-27.1962,153.2931,1
4030,Wooloowin,Queensland,QLD,-27.4224,153.042,4
4030,Lutwyche,Queensland,QLD,-27.4227,153.0335,4
4030,Windsor,Queensland,QLD,-27.4363,153.0288,4
4031,Kedron,Queensland,QLD,-27.4029,153.0297,4
4031,Gordon Park,Queensland,QLD,-27.419,153.0255,4
4032,Chermside West,Queensland,QLD,-27.3838,153.0159,4
4032,Chermside,Queensland,QLD,-27.3847,153.0306,4
4032,Chermside Centre,Queensland,QLD,-27.3847,153.0306,3
4032,Chermside South,Queensland,QLD,-27.3847,153.0306,3
4034,Carseldine,Queensland,QLD,-27.3475,153.0231,4
4034,Boondall,Queensland,QLD,-27.3532,153.0609,4
4034,Aspley,Queensland,QLD,-27.3667,153.0167,4
4034,Zillmere,Queensland,QLD,-27.3559,153.0445,4
4034,Geebung,Queensland,QLD,-27.3725,153.0471,4
4035,Albany Creek,Queensland,QLD,-27.35,152.9833,4
4035,Bridgeman Downs,Queensland,QLD,-27.353,152.9817,3
4036,Bald Hills,Queensland,QLD,-27.3211,153.0086,4
4037,Eatons Hill,Queensland,QLD,-27.3392,152.9599,4
4051,Enoggera,Queensland,QLD,-27.4283,152.9747,4
4051,Grange,Queensland,QLD,-27.4225,153.0153,4
4051,Newmarket,Queensland,QLD,-27.4333,153.0167,4
4051,Gaythorne,Queensland,QLD,-27.4167,152.9833,4
4051,Alderley,Queensland,QLD,-27.4255,153.001,4
4051,Wilston,Queensland,QLD,-27.4322,153.019,4
4053,Everton Hills,Queensland,QLD,-27.3891,152.9713,4
4053,Brookside Centre,Queensland,QLD,-27.4155,152.9766,3
4053,Mitchelton,Queensland,QLD,-27.4167,152.9667,4
4053,Stafford DC,Queensland,QLD,-27.3961,152.9766,3
4053,Mcdowall,Queensland,QLD,-27.3827,152.9919,4
4053,Stafford Heights,Queensland,QLD,-27.3935,153.0105,4
4053,Stafford,Queensland,QLD,-27.4104,153.0111,4
4053,Everton Park,Queensland,QLD,-27.4073,152.9884,4
4054,Arana Hills,Queensland,QLD,-27.3981,152.958,4
4054,Keperra,Queensland,QLD,-27.4138,152.947,4
4055,Ferny Grove,Queensland,QLD,-27.4008,152.9348,4
4055,Bunya,Queensland,QLD,-27.3694,152.9433,4
4055,Ferny Hills,Queensland,QLD,-27.3959,152.9374,4
4055,Ferny Hills DC,Queensland,QLD,-27.3963,152.9319,3
4055,Upper Kedron,Queensland,QLD,-27.4188,152.9178,4
4059,Red Hill,Queensland,QLD,-27.4525,153.0021,4
4059,Kelvin Grove Bc,Queensland,QLD,-27.4507,153.007,3
4059,Kelvin Grove,Queensland,QLD,-27.4482,153.0134,4
4059,Kelvin Grove Dc,Queensland,QLD,-27.4507,153.007,3
4060,Ashgrove,Queensland,QLD,-27.4455,152.992,4
4060,Ashgrove East,Queensland,QLD,-27.4455,152.992,3
4060,Dorrington,Queensland,QLD,-27.4425,152.9864,3
4061,The Gap,Queensland,QLD,-27.4419,152.9386,4
4064,Milton,Queensland,QLD,-27.4704,153.0031,4
4064,Milton BC,Queensland,QLD,-27.4649,152.9991,3
4064,Paddington,Queensland,QLD,-27.4594,152.9951,4
4065,Bardon,Queensland,QLD,-27.461,152.9792,4
4066,Toowong,Queensland,QLD,-27.4833,152.9833,4
4066,Toowong DC,Queensland,QLD,-27.48,152.993,3
4066,Toowong BC,Queensland,QLD,-27.48,152.993,3
4066,Mount Coot-Tha,Queensland,QLD,-27.4868,152.9592,3
4066,Auchenflower,Queensland,QLD,-27.4765,152.9966,4
4067,St Lucia,Queensland,QLD,-27.4982,153.0004,1
4067,St Lucia South,Queensland,QLD,-27.4868,152.9592,1
4068,Chelmer,Queensland,QLD,-27.5133,152.9752,4
4068,Indooroopilly,Queensland,QLD,-27.503,152.9752,4
4068,Indooroopilly Centre,Queensland,QLD,-27.5023,152.9763,3
4068,Taringa,Queensland,QLD,-27.4906,152.9786,4
4069,Upper Brookfield,Queensland,QLD,-27.4667,152.8667,4
4069,Pullenvale,Queensland,QLD,-27.5228,152.8865,4
4069,Brookfield,Queensland,QLD,-27.5,152.9,4
4069,Kenmore DC,Queensland,QLD,-27.5072,152.9208,3
4069,Chapel Hill,Queensland,QLD,-27.5026,152.9501,4
4069,Fig Tree Pocket,Queensland,QLD,-27.5278,152.9619,4
4069,Kenmore,Queensland,QLD,-27.5076,152.9388,4
4069,Pinjarra Hills,Queensland,QLD,-27.5383,152.9074,4
4069,Kenmore East,Queensland,QLD,-27.5072,152.9208,3
4069,Kenmore Hills,Queensland,QLD,-27.5042,152.9318,4
4070,Anstead,Queensland,QLD,-27.5378,152.8619,4
4070,Moggill,Queensland,QLD,-27.5833,152.8667,4
4070,Bellbowrie,Queensland,QLD,-27.5594,152.8828,4
4072,University Of Queensland,Queensland,QLD,-27.4978,153.0135,1
4073,Seventeen Mile Rocks,Queensland,QLD,-27.5507,152.959,4
4073,Sinnamon Park,Queensland,QLD,-27.5444,152.9489,4
4074,Sumner,Queensland,QLD,-27.5655,152.9334,4
4074,Riverhills,Queensland,QLD,-27.5593,152.9111,4
4074,Mount Ommaney,Queensland,QLD,-27.5354,152.9382,4
4074,Jindalee,Queensland,QLD,-27.5345,152.9384,4
4074,Westlake,Queensland,QLD,-27.5486,152.9128,4
4074,Jamboree Heights,Queensland,QLD,-27.5563,152.934,4
4074,Sumner Park Bc,Queensland,QLD,-27.5608,152.9429,3
4074,Middle Park,Queensland,QLD,-27.556,152.9222,4
4075,Sherwood,Queensland,QLD,-27.5247,152.9807,4
4075,Graceville East,Queensland,QLD,-27.5473,152.9791,3
4075,Corinda,Queensland,QLD,-27.5333,152.9833,4
4075,Graceville,Queensland,QLD,-27.522,152.9822,4
4075,Oxley,Queensland,QLD,-27.55,152.9833,4
4076,Darra,Queensland,QLD,-27.5667,152.9667,4
4076,Wacol,Queensland,QLD,-27.5833,152.9333,4
4077,Inala Heights,Queensland,QLD,-27.6,152.9819,3
4077,Richlands,Queensland,QLD,-27.5962,152.9532,4
4077,Durack,Queensland,QLD,-27.5895,152.9858,4
4077,Inala East,Queensland,QLD,-27.6,152.9819,3
4077,Doolandella,Queensland,QLD,-27.6133,152.9855,4
4077,Inala,Queensland,QLD,-27.5972,152.9743,4
4078,Ellen Grove,Queensland,QLD,-27.6153,152.9439,4
4078,Forest Lake,Queensland,QLD,-27.6256,152.9688,4
4101,South Brisbane,Queensland,QLD,-27.4803,153.0205,4
4101,West End,Queensland,QLD,-27.4833,153,4
4101,Highgate Hill,Queensland,QLD,-27.4891,153.0188,4
4101,South Brisbane BC,Queensland,QLD,-27.4839,153.015,3
4102,Woolloongabba,Queensland,QLD,-27.4886,153.0365,4
4102,Dutton Park,Queensland,QLD,-27.4966,153.028,4
4102,Buranda,Queensland,QLD,-27.5202,153.0278,3
4103,Fairfield Gardens,Queensland,QLD,-27.5094,153.0286,3
4103,Fairfield,Queensland,QLD,-27.5064,153.0248,4
4103,Annerley,Queensland,QLD,-27.5123,153.0325,4
4104,Yeronga,Queensland,QLD,-27.5157,153.0164,4
4105,Tennyson,Queensland,QLD,-27.5333,153.0167,4
4105,Moorooka,Queensland,QLD,-27.5344,153.0246,4
4105,Yeerongpilly,Queensland,QLD,-27.5215,153.0123,4
4106,Brisbane Market,Queensland,QLD,-27.547,153.0022,3
4106,Rocklea,Queensland,QLD,-27.5392,153.004,4
4106,Rocklea Dc,Queensland,QLD,-27.547,153.0022,3
4107,Salisbury,Queensland,QLD,-27.5514,153.0325,4
4107,Salisbury East,Queensland,QLD,-27.5514,153.0325,3
4108,Archerfield BC,Queensland,QLD,-27.5202,153.0278,3
4108,Archerfield,Queensland,QLD,-27.5671,153.0145,4
4108,Coopers Plains,Queensland,QLD,-27.5646,153.0407,4
4109,Macgregor,Queensland,QLD,-27.5649,153.0665,4
4109,Robertson,Queensland,QLD,-27.5658,153.0574,4
4109,Sunnybank,Queensland,QLD,-27.5833,153.05,4
4109,Sunnybank Hills,Queensland,QLD,-27.6103,153.0539,4
4109,Sunnybank South,Queensland,QLD,-27.5741,153.0583,3
4110,Willawong,Queensland,QLD,-27.5972,153.0074,4
4110,Larapinta,Queensland,QLD,-27.6426,153.0052,4
4110,Heathwood,Queensland,QLD,-27.6362,152.9843,4
4110,Acacia Ridge Dc,Queensland,QLD,-27.6116,153.0092,3
4110,Acacia Ridge,Queensland,QLD,-27.5833,153.0333,4
4110,Heathwood Df,Queensland,QLD,-27.616,153.0072,3
4110,Pallara,Queensland,QLD,-27.6208,153.0058,4
4111,Nathan,Queensland,QLD,-27.5589,153.0623,4
4112,Kuraby,Queensland,QLD,-27.6068,153.0937,4
4113,Eight Mile Plains,Queensland,QLD,-27.5833,153.1,4
4113,Runcorn,Queensland,QLD,-27.5976,153.077,4
4114,Logan City Dc,Queensland,QLD,-27.6463,153.1111,3
4114,Kingston,Queensland,QLD,-27.6667,153.1167,4
4114,Woodridge,Queensland,QLD,-27.6333,153.1,4
4114,Logan Central,Queensland,QLD,-27.6438,153.1073,4
4115,Algester,Queensland,QLD,-27.6128,153.0324,4
4115,Parkinson,Queensland,QLD,-27.6434,153.0298,4
4116,Stretton,Queensland,QLD,-27.6221,153.0661,4
4116,Calamvale,Queensland,QLD,-27.6233,153.0479,4
4116,Drewvale,Queensland,QLD,-27.6477,153.0546,4
4117,Berrinba,Queensland,QLD,-27.6585,153.0789,4
4117,Karawatha,Queensland,QLD,-27.6199,153.091,4
4118,Forestdale,Queensland,QLD,-27.6633,153.0038,4
4118,Browns Plains,Queensland,QLD,-27.65,153.0333,4
4118,Regents Park,Queensland,QLD,-27.6766,153.0417,4
4118,Heritage Park,Queensland,QLD,-27.6827,153.0608,4
4118,Hillcrest,Queensland,QLD,-27.6686,153.0261,4
4119,Underwood,Queensland,QLD,-27.6089,153.1113,4
4120,Greenslopes,Queensland,QLD,-27.5082,153.0495,4
4120,Stones Corner,Queensland,QLD,-27.5071,153.0477,3
4121,Holland Park West,Queensland,QLD,-27.5262,153.0605,4
4121,Holland Park,Queensland,QLD,-27.5262,153.0605,4
4121,Tarragindi,Queensland,QLD,-27.5333,153.0333,4
4121,Holland Park East,Queensland,QLD,-27.5298,153.0511,3
4121,Wellers Hill,Queensland,QLD,-27.5333,153.05,4
4122,Upper Mount Gravatt,Queensland,QLD,-27.5613,153.0845,4
4122,Mansfield DC,Queensland,QLD,-27.5439,153.0922,3
4122,Mansfield,Queensland,QLD,-27.5393,153.099,4
4122,Mount Gravatt,Queensland,QLD,-27.5333,153.0833,4
4122,Wishart,Queensland,QLD,-27.5545,153.1011,4
4122,Upper Mount Gravatt BC,Queensland,QLD,-27.5439,153.0922,3
4122,Mount Gravatt East,Queensland,QLD,-27.5405,153.0822,4
4122,Mansfield BC,Queensland,QLD,-27.5439,153.0922,3
4123,Rochedale,Queensland,QLD,-27.5667,153.1333,4
4123,Rochedale South,Queensland,QLD,-27.5952,153.1233,4
4124,New Beith,Queensland,QLD,-27.7936,152.8844,4
4124,Lyons,Queensland,QLD,-27.7335,152.8523,4
4124,Greenbank,Queensland,QLD,-27.7167,152.9833,4
4124,Boronia Heights,Queensland,QLD,-27.6885,153.0196,4
4124,Spring Mountain,Queensland,QLD,-27.6892,152.8954,4
4125,Park Ridge South,Queensland,QLD,-27.7271,153.0332,4
4125,Munruben,Queensland,QLD,-27.7463,153.0312,4
4125,Park Ridge,Queensland,QLD,-27.7167,153.0333,4
4127,Springwood,Queensland,QLD,-27.6118,153.129,4
4127,Daisy Hill,Queensland,QLD,-27.6303,153.1578,4
4127,Slacks Creek,Queensland,QLD,-27.65,153.15,4
4127,Priestdale,Queensland,QLD,-27.6,153.1333,4
4128,Shailer Park,Queensland,QLD,-27.6498,153.1775,4
4128,Tanah Merah,Queensland,QLD,-27.6715,153.1702,4
4129,Loganholme,Queensland,QLD,-27.7,153.2,4
4129,Loganholme DC,Queensland,QLD,-27.7,153.2,3
4129,Loganholme BC,Queensland,QLD,-27.7,153.2,3
4130,Cornubia,Queensland,QLD,-27.6667,153.2167,4
4130,Carbrook,Queensland,QLD,-27.6833,153.25,4
4131,Loganlea,Queensland,QLD,-27.6667,153.1333,4
4131,Meadowbrook,Queensland,QLD,-27.664,153.1447,4
4132,Marsden,Queensland,QLD,-27.6734,153.0973,4
4132,Crestmead,Queensland,QLD,-27.6876,153.0844,4
4133,Waterford West,Queensland,QLD,-27.6906,153.1332,4
4133,Waterford,Queensland,QLD,-27.7,153.15,4
4133,Chambers Flat,Queensland,QLD,-27.7481,153.0776,4
4133,Logan Reserve,Queensland,QLD,-27.7241,153.09,4
4151,Coorparoo DC,Queensland,QLD,-27.4932,153.0583,3
4151,Coorparoo,Queensland,QLD,-27.4932,153.0583,4
4152,Carindale,Queensland,QLD,-27.5058,153.1024,4
4152,Carina,Queensland,QLD,-27.5072,153.0913,4
4152,Carina Heights,Queensland,QLD,-27.5072,153.0913,4
4152,Camp Hill,Queensland,QLD,-27.4935,153.0763,4
4153,Belmont,Queensland,QLD,-27.5,153.1167,4
4154,Ransome,Queensland,QLD,-27.4881,153.1793,4
4154,Wakerley,Queensland,QLD,-27.483,153.1559,4
4154,Gumdale,Queensland,QLD,-27.4919,153.1532,4
4155,Chandler,Queensland,QLD,-27.514,153.1491,4
4156,Mackenzie,Queensland,QLD,-27.5435,153.1206,4
4156,Burbank,Queensland,QLD,-27.5558,153.147,4
4157,Sheldon,Queensland,QLD,-27.5844,153.2004,4
4157,Capalaba West,Queensland,QLD,-27.56,153.1993,3
4157,Capalaba,Queensland,QLD,-27.5167,153.1833,4
4157,Capalaba DC,Queensland,QLD,-27.5505,153.1919,3
4157,Capalaba BC,Queensland,QLD,-27.5505,153.1919,3
4158,Thorneside,Queensland,QLD,-27.4878,153.1982,4
4159,Birkdale,Queensland,QLD,-27.4833,153.2167,4
4160,Wellington Point,Queensland,QLD,-27.4833,153.25,4
4160,Ormiston,Queensland,QLD,-27.5167,153.25,4
4161,Alexandra Hills,Queensland,QLD,-27.5322,153.2289,4
4163,Cleveland,Queensland,QLD,-27.5268,153.2652,4
4163,Cleveland DC,Queensland,QLD,-27.5333,153.2667,3
4164,Thornlands,Queensland,QLD,-27.5575,153.2648,4
4165,Mount Cotton,Queensland,QLD,-27.6333,153.2333,4
4165,Redland Bay,Queensland,QLD,-27.6113,153.3002,4
4165,Victoria Point West,Queensland,QLD,-27.61,153.2825,3
4165,Victoria Point,Queensland,QLD,-27.5833,153.3117,4
4169,East Brisbane,Queensland,QLD,-27.481,153.044,4
4169,Kangaroo Point,Queensland,QLD,-27.4768,153.0367,4
4170,Norman Park,Queensland,QLD,-27.4792,153.0625,4
4170,Seven Hills,Queensland,QLD,-27.4834,153.0747,4
4170,Cannon Hill,Queensland,QLD,-27.4724,153.0947,4
4170,Morningside,Queensland,QLD,-27.4673,153.0732,4
4171,Balmoral,Queensland,QLD,-27.4595,153.0638,4
4171,Bulimba,Queensland,QLD,-27.45,153.0667,4
4171,Hawthorne,Queensland,QLD,-27.4633,153.0595,4
4172,Murarrie,Queensland,QLD,-27.4629,153.0981,4
4173,Tingalpa,Queensland,QLD,-27.4736,153.127,4
4173,Tingalpa Bc,Queensland,QLD,-27.4594,153.1211,3
4173,Tingalpa Dc,Queensland,QLD,-27.4594,153.1211,3
4174,Hemmant,Queensland,QLD,-27.4478,153.1317,4
4178,Wynnum North,Queensland,QLD,-27.4191,153.1677,3
4178,Wynnum Plaza,Queensland,QLD,-27.4333,153.1667,3
4178,Wynnum,Queensland,QLD,-27.4453,153.1581,4
4178,Port Of Brisbane,Queensland,QLD,-27.3903,153.1772,4
4178,Lytton,Queensland,QLD,-27.423,153.1511,4
4178,Wynnum West,Queensland,QLD,-27.4582,153.1532,4
4179,Lota,Queensland,QLD,-27.4692,153.1858,4
4179,Manly,Queensland,QLD,-27.4667,153.1833,4
4179,Manly West,Queensland,QLD,-27.4738,153.1664,4
4183,North Stradbroke Island,Queensland,QLD,-27.4332,153.4627,4
4183,Point Lookout,Queensland,QLD,-27.4273,153.5366,4
4183,Amity Point,Queensland,QLD,-27.3982,153.438,4
4183,Amity,Queensland,QLD,-27.3982,153.438,4
4183,Dunwich,Queensland,QLD,-27.4984,153.402,4
4184,Peel Island,Queensland,QLD,-27.651,153.363,3
4184,Lamb Island,Queensland,QLD,-27.6248,153.3802,4
4184,Perulpa Island,Queensland,QLD,-27.6019,153.3569,3
4184,Macleay Island,Queensland,QLD,-27.6109,153.36,4
4184,Karragarra Island,Queensland,QLD,-27.6368,153.3681,4
4184,Coochiemudlo Island,Queensland,QLD,-27.5736,153.3302,4
4184,Russell Island,Queensland,QLD,-27.6487,153.3812,4
4205,Bethania,Queensland,QLD,-27.6833,153.1667,4
4207,Cedar Creek,Queensland,QLD,-27.8618,153.1999,4
4207,Yarrabilba,Queensland,QLD,-27.8234,153.0965,4
4207,Eagleby,Queensland,QLD,-27.6833,153.2167,4
4207,Alberton,Queensland,QLD,-27.7,153.25,4
4207,Bahrs Scrub,Queensland,QLD,-27.7333,153.1667,4
4207,Buccan,Queensland,QLD,-27.75,153.1333,4
4207,Mount Warren Park,Queensland,QLD,-27.7311,153.2055,4
4207,Bannockburn,Queensland,QLD,-27.7599,153.1922,4
4207,Wolffdene,Queensland,QLD,-27.7833,153.1833,4
4207,Belivah,Queensland,QLD,-27.7582,153.175,4
4207,Holmview,Queensland,QLD,-27.7163,153.1705,4
4207,Steiglitz,Queensland,QLD,-27.75,153.35,4
4207,Logan Village,Queensland,QLD,-27.7683,153.1076,4
4207,Windaroo,Queensland,QLD,-27.7333,153.2,4
4207,Yatala,Queensland,QLD,-27.7167,153.2167,4
4207,Woongoolba,Queensland,QLD,-27.75,153.3333,4
4207,Edens Landing,Queensland,QLD,-27.7027,153.1694,4
4207,Stapylton,Queensland,QLD,-27.7333,153.2333,4
4207,Luscombe,Queensland,QLD,-27.7755,153.2064,4
4207,Yatala DC,Queensland,QLD,-27.7079,153.1842,3
4207,Beenleigh,Queensland,QLD,-27.7114,153.2029,4
4208,Ormeau Hills,Queensland,QLD,-27.7965,153.2567,4
4208,Norwell,Queensland,QLD,-27.7833,153.3167,4
4208,Gilberton,Queensland,QLD,-27.7333,153.2833,4
4208,Ormeau,Queensland,QLD,-27.7679,153.2428,4
4208,Jacobs Well,Queensland,QLD,-27.7799,153.3615,4
4208,Kingsholme,Queensland,QLD,-27.8254,153.2335,4
4209,Willow Vale,Queensland,QLD,-27.845,153.2631,4
4209,Pimpama,Queensland,QLD,-27.8167,153.3,4
4209,Coomera,Queensland,QLD,-27.8833,153.3,4
4209,Upper Coomera,Queensland,QLD,-27.9,153.3,4
4210,Wongawallan,Queensland,QLD,-27.8755,153.2287,4
4210,Studio Village,Queensland,QLD,-27.9079,153.2566,3
4210,Guanaba,Queensland,QLD,-27.9381,153.2393,4
4210,Oxenford,Queensland,QLD,-27.8903,153.3131,4
4210,Maudsland,Queensland,QLD,-27.95,153.2667,4
4211,Beechmont,Queensland,QLD,-28.1214,153.192,4
4211,Gaven,Queensland,QLD,-27.9565,153.3345,4
4211,Southern Lamington,Queensland,QLD,-28.0195,153.2946,3
4211,Highland Park,Queensland,QLD,-28.0139,153.3331,4
4211,Nerang,Queensland,QLD,-27.9894,153.3363,4
4211,Gilston,Queensland,QLD,-28.0333,153.3,4
4211,Clagiraba,Queensland,QLD,-27.9939,153.2424,4
4211,Numinbah Valley,Queensland,QLD,-28.1417,153.2231,4
4211,Nerang BC,Queensland,QLD,-28.0195,153.2946,3
4211,Mount Nathan,Queensland,QLD,-27.9643,153.272,4
4211,Binna Burra,Queensland,QLD,-28.0195,153.2946,3
4211,Advancetown,Queensland,QLD,-28.0667,153.2667,4
4211,Natural Bridge,Queensland,QLD,-28.2212,153.236,4
4211,Pacific Pines,Queensland,QLD,-27.9399,153.3144,4
4211,Lower Beechmont,Queensland,QLD,-28.0475,153.2457,4
4211,Nerang DC,Queensland,QLD,-28.0195,153.2946,3
4211,Carrara,Queensland,QLD,-28.0215,153.3664,4
4212,Helensvale,Queensland,QLD,-27.9183,153.3327,4
4212,Sanctuary Cove,Queensland,QLD,-27.86,153.3719,4
4212,Helensvale Town Centre,Queensland,QLD,-27.8875,153.3463,3
4212,Hope Island,Queensland,QLD,-27.8704,153.3516,4
4213,Worongary,Queensland,QLD,-28.05,153.35,4
4213,Austinville,Queensland,QLD,-28.1545,153.3092,4
4213,Neranwood,Queensland,QLD,-28.1248,153.2983,4
4213,Tallai,Queensland,QLD,-28.0641,153.3259,4
4213,Springbrook,Queensland,QLD,-28.1917,153.263,4
4213,Mudgeeraba,Queensland,QLD,-28.0833,153.3667,4
4213,Bonogin,Queensland,QLD,-28.1393,153.353,4
4214,Molendinar,Queensland,QLD,-27.9741,153.3607,4
4214,Ashmore City,Queensland,QLD,-27.9638,153.3658,3
4214,Parkwood,Queensland,QLD,-27.9546,153.363,4
4214,Ashmore,Queensland,QLD,-27.9888,153.3765,4
4214,Arundel,Queensland,QLD,-27.9377,153.363,4
4214,Arundel Bc,Queensland,QLD,-27.9631,153.3656,3
4214,Arundel Dc,Queensland,QLD,-27.9631,153.3656,3
4215,Southport,Queensland,QLD,-27.9672,153.398,4
4215,Labrador,Queensland,QLD,-27.944,153.3982,4
4215,Australia Fair,Queensland,QLD,-27.9553,153.3991,3
4215,Southport BC,Queensland,QLD,-27.9553,153.3991,3
4215,Southport Park,Queensland,QLD,-27.9553,153.3991,3
4215,Chirn Park,Queensland,QLD,-27.9553,153.3991,3
4216,South Stradbroke,Queensland,QLD,-27.8329,153.4207,4
4216,Runaway Bay,Queensland,QLD,-27.9139,153.3977,4
4216,Paradise Point,Queensland,QLD,-27.8866,153.3933,4
4216,Biggera Waters,Queensland,QLD,-27.9324,153.4002,4
4216,Coombabah,Queensland,QLD,-27.9107,153.3709,4
4216,Hollywell,Queensland,QLD,-27.9008,153.3961,4
4217,Main Beach,Queensland,QLD,-27.9788,153.4266,4
4217,Surfers Paradise,Queensland,QLD,-28.0027,153.43,4
4217,Bundall,Queensland,QLD,-28.0111,153.4048,4
4217,Benowa,Queensland,QLD,-28.0167,153.4,4
4217,Isle Of Capri,Queensland,QLD,-27.9996,153.4093,3
4217,Bundall BC,Queensland,QLD,-28.0023,153.4153,3
4217,Bundall DC,Queensland,QLD,-28.0023,153.4153,3
4217,Gold Coast Mc,Queensland,QLD,-28.0023,153.4153,3
4217,Chevron Island,Queensland,QLD,-27.9996,153.4093,3
4218,Broadbeach,Queensland,QLD,-28.0278,153.4334,4
4218,Mermaid Beach,Queensland,QLD,-28.0441,153.4347,4
4218,Mermaid Waters,Queensland,QLD,-28.0491,153.4308,4
4218,Broadbeach Waters,Queensland,QLD,-28.035,153.425,4
4218,Nobby Beach,Queensland,QLD,-28.0396,153.4217,3
4218,Q Supercentre,Queensland,QLD,-28.0396,153.4217,3
4218,Pacific Fair,Queensland,QLD,-28.0396,153.4217,3
4219,West Burleigh,Queensland,QLD,-28.1167,153.4333,4
4220,Miami,Queensland,QLD,-28.0717,153.4416,4
4220,Burleigh Town,Queensland,QLD,-28.0895,153.4341,3
4220,Burleigh Heads,Queensland,QLD,-28.1,153.45,4
4220,Burleigh Waters,Queensland,QLD,-28.0998,153.4246,4
4220,Burleigh DC,Queensland,QLD,-28.0859,153.4458,3
4221,Elanora,Queensland,QLD,-28.1358,153.4493,4
4221,Palm Beach,Queensland,QLD,-28.1169,153.4658,4
4222,Griffith University,Queensland,QLD,-28.1235,153.4442,1
4223,Currumbin,Queensland,QLD,-28.1407,153.45,4
4223,Currumbin Waters,Queensland,QLD,-28.1544,153.4728,4
4223,Currumbin Dc,Queensland,QLD,-28.1947,153.4129,3
4223,Currumbin Valley,Queensland,QLD,-28.2081,153.3942,4
4224,Tugun,Queensland,QLD,-28.15,153.5,4
4225,Coolangatta,Queensland,QLD,-28.1694,153.5347,4
4225,Bilinga,Queensland,QLD,-28.1667,153.5167,4
4226,Robina,Queensland,QLD,-28.0707,153.3933,4
4226,Clear Island Waters,Queensland,QLD,-28.0531,153.3981,4
4226,Robina DC,Queensland,QLD,-28.0642,153.3794,3
4226,Merrimac,Queensland,QLD,-28.0509,153.3731,4
4227,Reedy Creek,Queensland,QLD,-28.1124,153.3964,4
4227,Varsity Lakes,Queensland,QLD,-28.0894,153.4122,4
4228,Tallebudgera,Queensland,QLD,-28.15,153.4333,4
4228,Tallebudgera Valley,Queensland,QLD,-28.1927,153.3549,4
4229,Bond University,Queensland,QLD,-28.0779,153.4123,1
4230,Robina Town Centre,Queensland,QLD,-28.0757,153.3842,1
4270,Tamborine,Queensland,QLD,-27.8833,153.1333,4
4271,Eagle Heights,Queensland,QLD,-27.9193,153.1994,4
4272,Mount Tamborine,Queensland,QLD,-27.9695,153.1994,4
4272,Tamborine Mountain,Queensland,QLD,-27.9695,153.1994,4
4272,North Tamborine,Queensland,QLD,-27.9276,153.1848,4
4275,Sarabah,Queensland,QLD,-27.9973,153.1642,3
4275,Witheren,Queensland,QLD,-28.0392,153.1916,4
4275,Boyland,Queensland,QLD,-27.95,153.1333,4
4275,Benobble,Queensland,QLD,-27.9833,153.1667,4
4275,Wonglepong,Queensland,QLD,-27.9618,153.1749,3
4275,Canungra,Queensland,QLD,-28.0169,153.1653,4
4275,Lamington National Park,Queensland,QLD,-28.1139,153.134,3
4275,Ferny Glen,Queensland,QLD,-27.9973,153.1642,3
4275,Flying Fox,Queensland,QLD,-27.9973,153.1642,3
4275,Illinbah,Queensland,QLD,-27.9973,153.1642,3
4275,Biddaddaba,Queensland,QLD,-27.9973,153.1642,3
4280,Jimboomba,Queensland,QLD,-27.8312,153.0274,4
4280,South Maclean,Queensland,QLD,-27.7924,153.016,4
4280,North Maclean,Queensland,QLD,-27.7676,153.0165,4
4280,Stockleigh,Queensland,QLD,-27.7667,153.0667,4
4285,Veresdale Scrub,Queensland,QLD,-27.8916,152.9977,4
4285,Darlington,Queensland,QLD,-28.239,153.067,4
4285,Beaudesert,Queensland,QLD,-27.9869,152.9966,4
4285,Veresdale,Queensland,QLD,-27.9167,152.9833,4
4285,Birnam,Queensland,QLD,-27.4833,151.9167,4
4285,Kerry,Queensland,QLD,-28.1,153.0167,4
4285,Lamington,Queensland,QLD,-28.245,152.9933,4
4285,Laravale,Queensland,QLD,-28.0833,152.9333,4
4285,Cedar Vale,Queensland,QLD,-27.8892,153.0289,4
4285,Oaky Creek,Queensland,QLD,-28.0067,152.9125,3
4285,Tabooba,Queensland,QLD,-28.1333,152.95,4
4285,Mount Gipps,Queensland,QLD,-28.0067,152.9125,3
4285,Kagaru,Queensland,QLD,-27.8657,152.9238,4
4285,Bromelton,Queensland,QLD,-27.9833,152.9333,4
4285,Josephville,Queensland,QLD,-28.0305,152.9438,4
4285,Kooralbyn,Queensland,QLD,-28.0796,152.8397,4
4285,Tamrookum,Queensland,QLD,-28.1242,152.9159,4
4285,Cryna,Queensland,QLD,-28.0067,152.9125,3
4285,Hillview,Queensland,QLD,-28.2137,153.0123,4
4285,Chinghee Creek,Queensland,QLD,-28.0067,152.9125,3
4285,Cedar Grove,Queensland,QLD,-27.8667,152.9833,4
4285,Allenview,Queensland,QLD,-28.0067,152.9125,3
4285,Mundoolun,Queensland,QLD,-27.8994,153.0715,4
4285,Christmas Creek,Queensland,QLD,-28.0067,152.9125,3
4285,Cainbable,Queensland,QLD,-28.0067,152.9125,3
4285,Nindooinbah,Queensland,QLD,-28.0067,152.9125,3
4285,Woodhill,Queensland,QLD,-27.9,152.9667,4
4285,Knapp Creek,Queensland,QLD,-28.0067,152.9125,3
4285,Tamrookum Creek,Queensland,QLD,-28.0067,152.9125,3
4285,Tabragalba,Queensland,QLD,-28.0067,152.9125,3
4285,Innisplain,Queensland,QLD,-28.1667,152.9,4
4285,Gleneagle,Queensland,QLD,-27.9333,152.9833,4
4285,Undullah,Queensland,QLD,-27.8088,152.9491,4
4287,Rathdowney,Queensland,QLD,-28.2108,152.8641,4
4287,Barney View,Queensland,QLD,-28.2333,152.7833,4
4287,Running Creek,Queensland,QLD,-14.3097,143.7279,3
4287,Mount Barney,Queensland,QLD,-28.2369,152.8158,3
4287,Palen Creek,Queensland,QLD,-28.2667,152.8,4
4287,Mount Lindesay,Queensland,QLD,-28.2369,152.8158,3
4300,Gailes,Queensland,QLD,-27.6113,152.9132,4
4300,Springfield Lakes,Queensland,QLD,-27.6676,152.9249,4
4300,Goodna,Queensland,QLD,-27.6105,152.899,4
4300,Springfield Central,Queensland,QLD,-27.6805,152.9042,4
4300,Camira,Queensland,QLD,-27.6346,152.9209,4
4300,Bellbird Park,Queensland,QLD,-27.6367,152.8866,4
4300,Springfield,Queensland,QLD,-27.6536,152.9172,4
4300,Augustine Heights,Queensland,QLD,-27.6605,152.879,4
4300,Carole Park,Queensland,QLD,-27.6172,152.9316,4
4300,Brookwater,Queensland,QLD,-27.6613,152.8967,4
4301,Redbank Plains,Queensland,QLD,-27.6461,152.8596,4
4301,Collingwood Park,Queensland,QLD,-27.6128,152.8601,4
4301,Redbank,Queensland,QLD,-27.6,152.8667,4
4303,Dinmore,Queensland,QLD,-27.6,152.8333,4
4303,Riverview,Queensland,QLD,-27.6,152.85,4
4303,New Chum,Queensland,QLD,-27.618,152.8326,4
4304,North Booval,Queensland,QLD,-27.6039,152.7948,4
4304,Booval Fair,Queensland,QLD,-27.6122,152.8014,3
4304,Silkstone,Queensland,QLD,-27.6213,152.7877,4
4304,Blackstone,Queensland,QLD,-27.6251,152.8084,4
4304,Booval,Queensland,QLD,-27.6133,152.7894,4
4304,Bundamba,Queensland,QLD,-27.6095,152.8113,4
4304,Ebbw Vale,Queensland,QLD,-27.6,152.8167,4
4305,Limestone Ridges,Queensland,QLD,-27.8365,152.726,4
4305,North Ipswich,Queensland,QLD,-27.6029,152.7615,4
4305,Brassall,Queensland,QLD,-27.5975,152.7476,4
4305,Coalfalls,Queensland,QLD,-27.6084,152.7468,4
4305,Basin Pocket,Queensland,QLD,-27.5999,152.7713,4
4305,Yamanto,Queensland,QLD,-27.6603,152.7386,4
4305,Bremer,Queensland,QLD,-27.6204,152.7567,3
4305,East Ipswich,Queensland,QLD,-27.6064,152.7723,4
4305,West Ipswich,Queensland,QLD,-27.6213,152.7482,4
4305,Wulkuraka,Queensland,QLD,-27.6181,152.7144,4
4305,Moores Pocket,Queensland,QLD,-27.5978,152.7773,4
4305,Leichhardt,Queensland,QLD,-27.6228,152.7337,4
4305,Raceview,Queensland,QLD,-27.6347,152.7752,4
4305,Newtown,Queensland,QLD,-27.6163,152.7768,4
4305,Woodend,Queensland,QLD,-27.6055,152.7531,4
4305,Ipswich,Queensland,QLD,-27.6118,152.7682,4
4305,North Tivoli,Queensland,QLD,-27.5761,152.7877,4
4305,One Mile,Queensland,QLD,-27.629,152.7377,4
4305,Sadliers Crossing,Queensland,QLD,-27.6125,152.7458,4
4305,Eastern Heights,Queensland,QLD,-27.6285,152.7783,4
4305,Flinders View,Queensland,QLD,-27.6505,152.7745,4
4305,Churchill,Queensland,QLD,-27.6423,152.7502,4
4305,Tivoli,Queensland,QLD,-27.5928,152.768,4
4306,England Creek,Queensland,QLD,-27.3362,152.7616,4
4306,Fernvale,Queensland,QLD,-27.4548,152.6532,4
4306,South Ripley,Queensland,QLD,-27.7174,152.8164,4
4306,Blacksoil,Queensland,QLD,-27.577,152.7052,4
4306,Karrabin,Queensland,QLD,-27.6,152.7167,4
4306,Goolman,Queensland,QLD,-27.7333,152.75,4
4306,Wanora,Queensland,QLD,-27.5167,152.6667,4
4306,Muirlea,Queensland,QLD,-27.5667,152.7167,4
4306,Harlin,Queensland,QLD,-26.9803,152.358,4
4306,Blackbutt,Queensland,QLD,-26.8863,152.1017,4
4306,Moore,Queensland,QLD,-26.9005,152.2934,4
4306,Nukku,Queensland,QLD,-26.8833,152.0667,4
4306,Purga,Queensland,QLD,-27.6592,152.7158,4
4306,Pine Mountain,Queensland,QLD,-27.55,152.7,4
4306,Peak Crossing,Queensland,QLD,-27.7837,152.726,4
4306,Ironbark,Queensland,QLD,-27.5564,152.6716,4
4306,Dundas,Queensland,QLD,-27.3,152.6167,4
4306,Thagoona,Queensland,QLD,-27.6333,152.6333,4
4306,Mount Binga,Queensland,QLD,-27.1074,152.4405,3
4306,Fairney View,Queensland,QLD,-27.4833,152.6667,4
4306,Mount Crosby,Queensland,QLD,-27.5395,152.8058,4
4306,Swanbank,Queensland,QLD,-27.6569,152.8072,4
4306,Split Yard Creek,Queensland,QLD,-27.4218,152.5998,3
4306,Borallon,Queensland,QLD,-27.5333,152.6833,4
4306,Deebing Heights,Queensland,QLD,-27.6672,152.7487,4
4306,Linville,Queensland,QLD,-26.8443,152.276,4
4306,Benarkin North,Queensland,QLD,-26.8572,152.1501,3
4306,Banks Creek,Queensland,QLD,-27.4131,152.7237,4
4306,Colinton,Queensland,QLD,-26.9333,152.3167,4
4306,Teelah,Queensland,QLD,-27.1074,152.4405,3
4306,Ripley,Queensland,QLD,-27.7167,152.8333,4
4306,Blackbutt North,Queensland,QLD,-26.8643,152.1129,3
4306,Blackbutt South,Queensland,QLD,-26.8863,152.1017,4
4306,Avoca Vale,Queensland,QLD,-27.1074,152.4405,3
4306,Walloon,Queensland,QLD,-27.6055,152.6643,4
4306,Lake Manchester,Queensland,QLD,-27.1074,152.4405,3
4306,Karana Downs,Queensland,QLD,-27.5486,152.8073,4
4306,Washpool,Queensland,QLD,-27.4218,152.5998,3
4306,Willowbank,Queensland,QLD,-27.6821,152.6741,4
4306,Cherry Creek,Queensland,QLD,-27.1074,152.4405,3
4306,Chuwar,Queensland,QLD,-27.5662,152.7784,4
4306,Glamorgan Vale,Queensland,QLD,-27.5167,152.6333,4
4306,Vernor,Queensland,QLD,-27.4667,152.6167,4
4306,Googa Creek,Queensland,QLD,-27.1074,152.4405,3
4306,Amberley,Queensland,QLD,-27.6333,152.7,4
4306,Mount Stanley,Queensland,QLD,-27.1074,152.4405,3
4306,Lark Hill,Queensland,QLD,-27.5265,152.6031,4
4306,White Rock,Queensland,QLD,-27.4218,152.5998,3
4306,Barellan Point,Queensland,QLD,-27.572,152.8438,4
4306,Wivenhoe Pocket,Queensland,QLD,-27.4219,152.6217,4
4306,Haigslea,Queensland,QLD,-27.5725,152.6294,4
4306,Taromeo,Queensland,QLD,-27.4218,152.5998,3
4306,Benarkin,Queensland,QLD,-26.8918,152.1394,4
4306,Mount Marrow,Queensland,QLD,-27.6039,152.6209,4
4306,Kholo,Queensland,QLD,-27.4958,152.7608,4
4306,Karalee,Queensland,QLD,-27.5648,152.8245,4
4307,Mutdapilly,Queensland,QLD,-27.7667,152.65,4
4307,Warrill View,Queensland,QLD,-27.8241,152.6137,4
4307,Silverdale,Queensland,QLD,-27.8993,152.6048,4
4307,Coleyville,Queensland,QLD,-27.809,152.5668,4
4307,Radford,Queensland,QLD,-27.8522,152.6295,4
4307,Wilsons Plains,Queensland,QLD,-27.8409,152.6528,4
4307,Harrisville,Queensland,QLD,-27.811,152.6646,4
4309,Charlwood,Queensland,QLD,-28.0167,152.5667,4
4309,Mount Edwards,Queensland,QLD,-28.0167,152.5167,4
4309,Frazerview,Queensland,QLD,-28.023,152.5304,3
4309,Teviotville,Queensland,QLD,-27.95,152.6833,4
4309,Fassifern,Queensland,QLD,-27.9667,152.6,4
4309,Kulgun,Queensland,QLD,-27.9333,152.6833,4
4309,Aratula,Queensland,QLD,-27.9833,152.5333,4
4309,Obum Obum,Queensland,QLD,-27.9158,152.652,4
4309,Kalbar,Queensland,QLD,-27.9417,152.6237,4
4309,Kents Lagoon,Queensland,QLD,-27.8987,152.6186,4
4309,Washpool,Queensland,QLD,-28.023,152.5304,3
4309,Morwincha,Queensland,QLD,-27.9833,152.5833,4
4309,Fassifern Valley,Queensland,QLD,-27.9667,152.6,4
4309,Milora,Queensland,QLD,-27.8319,152.693,4
4309,Clumber,Queensland,QLD,-28.0667,152.4667,4
4309,Munbilla,Queensland,QLD,-27.8751,152.6592,4
4309,Moogerah,Queensland,QLD,-28.0886,152.5223,4
4309,Tarome,Queensland,QLD,-27.9764,152.591,3
4310,Mount Alford,Queensland,QLD,-28.0707,152.5944,4
4310,Boonah,Queensland,QLD,-27.9972,152.6811,4
4310,Maroon,Queensland,QLD,-28.1773,152.7178,4
4310,Burnett Creek,Queensland,QLD,-28.0242,152.668,3
4310,Bunburra,Queensland,QLD,-28.0667,152.6833,4
4310,Anthony,Queensland,QLD,-27.8779,152.6828,4
4310,Hoya,Queensland,QLD,-27.9667,152.6833,4
4310,Milford,Queensland,QLD,-28.0333,152.7167,4
4310,Frenches Creek,Queensland,QLD,-28.019,152.6327,4
4310,Coulson,Queensland,QLD,-28.0242,152.668,3
4310,Cannon Creek,Queensland,QLD,-28.0627,152.7241,4
4310,Wallaces Creek,Queensland,QLD,-28.0705,152.6535,4
4310,Blantyre,Queensland,QLD,-27.9039,152.678,4
4310,Dugandan,Queensland,QLD,-28.0211,152.6714,4
4310,Allandale,Queensland,QLD,-28.0121,152.7482,4
4310,Templin,Queensland,QLD,-27.9714,152.6478,4
4310,Roadvale,Queensland,QLD,-27.9177,152.6882,4
4310,Mount French,Queensland,QLD,-28.0242,152.668,3
4310,Kents Pocket,Queensland,QLD,-27.9891,152.6561,4
4310,Croftby,Queensland,QLD,-28.1333,152.5667,4
4310,Wyaralong,Queensland,QLD,-28.0242,152.668,3
4310,Coochin,Queensland,QLD,-28.0989,152.643,4
4310,Milbong,Queensland,QLD,-28.0242,152.668,3
4310,Carneys Creek,Queensland,QLD,-28.0242,152.668,3
4310,Woolooman,Queensland,QLD,-28.0242,152.668,3
4310,Bunjurgen,Queensland,QLD,-28.0412,152.6236,4
4311,Lowood,Queensland,QLD,-27.4655,152.5762,4
4311,Coolana,Queensland,QLD,-27.5333,152.55,4
4311,Atkinsons Dam,Queensland,QLD,-27.4214,152.4339,4
4311,Mount Tarampa,Queensland,QLD,-27.4609,152.4899,3
4311,Tarampa,Queensland,QLD,-27.4833,152.55,4
4311,Minden,Queensland,QLD,-27.5596,152.5449,4
4311,Buaraba,Queensland,QLD,-27.3667,152.3167,4
4311,Buaraba South,Queensland,QLD,-27.4291,152.4881,3
4311,Rifle Range,Queensland,QLD,-27.4595,152.5312,4
4311,Coominya,Queensland,QLD,-27.3901,152.5006,4
4311,Churchable,Queensland,QLD,-27.4259,152.3698,4
4311,Wivenhoe Hill,Queensland,QLD,-27.4291,152.4881,3
4311,Patrick Estate,Queensland,QLD,-27.4185,152.5819,4
4311,Lockyer Waters,Queensland,QLD,-27.4539,152.4058,4
4311,Brightview,Queensland,QLD,-27.5,152.4833,4
4311,Prenzlau,Queensland,QLD,-27.5333,152.5167,4
4311,Clarendon,Queensland,QLD,-27.4167,152.5333,4
4312,Somerset Dam,Queensland,QLD,-27.1214,152.5497,4
4312,Esk,Queensland,QLD,-27.239,152.4222,4
4312,Redbank Creek,Queensland,QLD,-27.2862,152.3039,3
4312,Bryden,Queensland,QLD,-27.2692,152.5813,4
4312,Mount Hallen,Queensland,QLD,-27.3167,152.4167,4
4312,Caboonbah,Queensland,QLD,-27.15,152.4833,4
4312,Murrumba,Queensland,QLD,-27.2,152.5,4
4312,Moombra,Queensland,QLD,-27.229,152.4719,3
4312,Mount Byron,Queensland,QLD,-27.229,152.4719,3
4312,Glen Esk,Queensland,QLD,-27.229,152.4719,3
4312,Crossdale,Queensland,QLD,-27.229,152.4719,3
4312,Coal Creek,Queensland,QLD,-27.2148,152.4932,3
4312,Eskdale,Queensland,QLD,-27.2148,152.4932,3
4313,Cressbrook,Queensland,QLD,-27.0833,152.4833,3
4313,Yimbun,Queensland,QLD,-27.0333,152.3667,4
4313,Fulham,Queensland,QLD,-27.0667,152.45,4
4313,Cooeeimbardi,Queensland,QLD,-27.1009,152.3312,3
4313,Toogoolawah,Queensland,QLD,-27.0866,152.3764,4
4313,Braemore,Queensland,QLD,-27.1009,152.3312,3
4313,Lower Cressbrook,Queensland,QLD,-27.0833,152.4833,4
4313,Scrub Creek,Queensland,QLD,-27.1009,152.3312,3
4313,Mount Beppo,Queensland,QLD,-27.0839,152.4238,3
4313,Ottaba,Queensland,QLD,-27.15,152.3833,4
4313,Biarra,Queensland,QLD,-27.0839,152.4238,3
4313,Gregors Creek,Queensland,QLD,-27.0839,152.4238,3
4313,Ivory Creek,Queensland,QLD,-27.0839,152.4238,3
4340,Rosewood,Queensland,QLD,-27.6335,152.5895,4
4340,Jeebropilly,Queensland,QLD,-27.6461,152.6586,4
4340,Tallegalla,Queensland,QLD,-27.6,152.5667,4
4340,Lanefield,Queensland,QLD,-27.65,152.55,4
4340,The Bluff,Queensland,QLD,-27.6235,152.5355,4
4340,Mount Walker,Queensland,QLD,-27.7833,152.5333,4
4340,Grandchester,Queensland,QLD,-27.664,152.462,4
4340,Woolshed,Queensland,QLD,-27.7448,152.5241,3
4340,Calvert,Queensland,QLD,-27.6667,152.5167,4
4340,Lower Mount Walker,Queensland,QLD,-27.7609,152.5176,3
4340,Rosevale,Queensland,QLD,-27.8701,152.4852,4
4340,Ebenezer,Queensland,QLD,-27.6833,152.6333,4
4340,Mount Mort,Queensland,QLD,-27.8167,152.4167,4
4340,Mount Walker West,Queensland,QLD,-27.7609,152.5176,3
4340,Ashwell,Queensland,QLD,-27.6299,152.5586,4
4340,Merryvale,Queensland,QLD,-27.7925,152.4928,4
4340,Mount Forbes,Queensland,QLD,-27.7333,152.6167,4
4340,Moorang,Queensland,QLD,-27.9167,152.4667,4
4341,Kentville,Queensland,QLD,-27.4833,152.4333,4
4341,Kensington Grove,Queensland,QLD,-27.5328,152.4721,4
4341,Mulgowie,Queensland,QLD,-27.7333,152.3667,4
4341,Thornton,Queensland,QLD,-27.8167,152.3833,4
4341,Regency Downs,Queensland,QLD,-27.5336,152.4405,4
4341,Blenheim,Queensland,QLD,-27.6667,152.3167,4
4341,Plainland,Queensland,QLD,-27.5667,152.4167,4
4341,Mount Berryman,Queensland,QLD,-27.7167,152.3333,4
4341,Laidley South,Queensland,QLD,-27.6458,152.4032,3
4341,Hatton Vale,Queensland,QLD,-27.5667,152.4667,4
4341,Laidley Creek West,Queensland,QLD,-27.7125,152.3775,3
4341,Laidley,Queensland,QLD,-27.6328,152.3929,4
4341,Laidley North,Queensland,QLD,-27.7125,152.3775,3
4341,Townson,Queensland,QLD,-27.8833,152.3667,4
4341,Laidley Heights,Queensland,QLD,-27.6246,152.3776,3
4341,Summerholm,Queensland,QLD,-27.6167,152.45,4
4342,Glenore Grove,Queensland,QLD,-27.5333,152.4,4
4342,Lynford,Queensland,QLD,-27.5371,152.4037,3
4342,Forest Hill,Queensland,QLD,-27.5926,152.3557,4
4342,Glen Cairn,Queensland,QLD,-27.5371,152.4037,3
4342,Crowley Vale,Queensland,QLD,-27.5383,152.4052,3
4342,Lockrose,Queensland,QLD,-27.489,152.46,4
4343,Morton Vale,Queensland,QLD,-27.4869,152.3892,3
4343,Spring Creek,Queensland,QLD,-27.4532,152.332,4
4343,Placid Hills,Queensland,QLD,-27.5613,152.2325,4
4343,College View,Queensland,QLD,-27.6758,152.2491,3
4343,Caffey,Queensland,QLD,-27.6997,152.2199,4
4343,Fordsdale,Queensland,QLD,-27.7094,152.1277,4
4343,Lower Tenthill,Queensland,QLD,-27.612,152.2315,3
4343,East Haldon,Queensland,QLD,-27.6758,152.2491,3
4343,Black Duck Creek,Queensland,QLD,-27.8333,152.1833,4
4343,Woodlands,Queensland,QLD,-27.6758,152.2491,3
4343,Adare,Queensland,QLD,-27.5127,152.2954,4
4343,Ingoldsby,Queensland,QLD,-27.6758,152.2491,3
4343,Woodbine,Queensland,QLD,-27.7833,152.1833,4
4343,Ropeley,Queensland,QLD,-27.6667,152.2667,4
4343,Junction View,Queensland,QLD,-27.8,152.1833,4
4343,Gatton,Queensland,QLD,-27.5587,152.2762,4
4343,Vinegar Hill,Queensland,QLD,-27.6758,152.2491,3
4343,Mount Sylvia,Queensland,QLD,-27.7253,152.2222,4
4343,Upper Tenthill,Queensland,QLD,-27.6758,152.2491,3
4343,Lawes,Queensland,QLD,-27.6758,152.2491,3
4343,Rockside,Queensland,QLD,-27.6758,152.2491,3
4343,Ringwood,Queensland,QLD,-27.6758,152.2491,3
4343,Lefthand Branch,Queensland,QLD,-27.6758,152.2491,3
4343,Lake Clarendon,Queensland,QLD,-27.5167,152.3667,4
4344,Seventeen Mile,Queensland,QLD,-27.5507,152.959,4
4344,Egypt,Queensland,QLD,-27.5987,152.0996,3
4344,Helidon,Queensland,QLD,-27.5532,152.124,4
4344,Lockyer,Queensland,QLD,-27.5135,152.0822,4
4344,Carpendale,Queensland,QLD,-27.5891,152.1533,3
4344,Helidon Spa,Queensland,QLD,-27.5987,152.0996,3
4344,Rockmount,Queensland,QLD,-27.6833,152.0333,4
4344,Lilydale,Queensland,QLD,-27.6074,152.1308,4
4344,Iredale,Queensland,QLD,-27.6003,152.0873,3
4344,Stockyard,Queensland,QLD,-27.6333,152.0667,4
4344,Upper Flagstone,Queensland,QLD,-27.5987,152.0996,3
4344,Flagstone Creek,Queensland,QLD,-27.6167,152.1167,4
4344,Silver Ridge,Queensland,QLD,-27.5987,152.0996,3
4345,Gatton College,Queensland,QLD,-27.5546,152.337,1
4346,Marburg,Queensland,QLD,-27.5661,152.597,4
4347,Veradilla,Queensland,QLD,-27.5934,152.2167,3
4347,Winwill,Queensland,QLD,-27.6,152.2167,4
4347,Ma Ma Creek,Queensland,QLD,-27.6323,152.187,4
4347,Mount Whitestone,Queensland,QLD,-27.6667,152.1667,4
4347,Grantham,Queensland,QLD,-27.5792,152.2017,4
4350,Athol,Queensland,QLD,-27.6081,151.7545,4
4350,Southtown,Queensland,QLD,-27.5717,151.8559,3
4350,Westbrook,Queensland,QLD,-27.6167,151.8667,4
4350,Toowoomba Village Fair,Queensland,QLD,-27.6284,151.8604,3
4350,Harristown,Queensland,QLD,-27.5833,151.9333,4
4350,Toowoomba City,Queensland,QLD,-27.5802,151.9439,3
4350,Mount Rascal,Queensland,QLD,-27.635,151.9143,4
4350,Toowoomba West,Queensland,QLD,-27.7858,151.9008,4
4350,Prince Henry Heights,Queensland,QLD,-27.5511,151.991,4
4350,Rangeville,Queensland,QLD,-27.5832,151.98,4
4350,Rockville,Queensland,QLD,-27.5361,151.938,4
4350,Clifford Gardens,Queensland,QLD,-27.6284,151.8604,3
4350,Cranley,Queensland,QLD,-27.5167,151.95,4
4350,East Toowoomba,Queensland,QLD,-27.5709,151.9742,4
4350,Harlaxton,Queensland,QLD,-27.5333,151.9667,4
4350,Wellcamp,Queensland,QLD,-27.55,151.8667,4
4350,Drayton,Queensland,QLD,-27.5833,151.9167,4
4350,Drayton North,Queensland,QLD,-27.6284,151.8604,3
4350,North Toowoomba,Queensland,QLD,-27.5441,151.9612,4
4350,Toowoomba DC,Queensland,QLD,-27.6284,151.8604,3
4350,Cotswold Hills,Queensland,QLD,-27.5224,151.8952,4
4350,Northlands,Queensland,QLD,-27.5717,151.8559,3
4350,Blue Mountain Heights,Queensland,QLD,-27.5049,151.9559,4
4350,Centenary Heights,Queensland,QLD,-27.5868,151.9625,4
4350,Glenvale,Queensland,QLD,-27.5682,151.8934,4
4350,Toowoomba South,Queensland,QLD,-27.5717,151.8559,3
4350,Wilsonton Heights,Queensland,QLD,-27.5323,151.9244,4
4350,Toowoomba BC,Queensland,QLD,-27.6284,151.8604,3
4350,Toowoomba East,Queensland,QLD,-27.5717,151.8559,3
4350,Finnie,Queensland,QLD,-27.6167,151.9,4
4350,Gowrie Mountain,Queensland,QLD,-27.6284,151.8604,3
4350,Top Camp,Queensland,QLD,-27.6358,151.9392,4
4350,Darling Heights,Queensland,QLD,-27.5966,151.9305,4
4350,Mount Lofty,Queensland,QLD,-27.5463,151.9722,4
4350,Newtown,Queensland,QLD,-27.556,151.9325,4
4350,Charlton,Queensland,QLD,-27.5333,151.8667,4
4350,Wilsonton,Queensland,QLD,-27.5431,151.9168,4
4350,Middle Ridge,Queensland,QLD,-27.6071,151.9669,4
4350,Torrington,Queensland,QLD,-27.5424,151.8879,4
4350,Mount Kynoch,Queensland,QLD,-27.5107,151.9481,4
4350,Wyalla Plaza,Queensland,QLD,-27.5717,151.8559,3
4350,Kearneys Spring,Queensland,QLD,-27.6049,151.941,4
4350,South Toowoomba,Queensland,QLD,-27.571,151.9465,4
4350,Toowoomba,Queensland,QLD,-27.5606,151.9539,4
4350,Redwood,Queensland,QLD,-27.5648,151.9791,4
4352,Umbiram,Queensland,QLD,-27.6692,151.7663,4
4352,Cement Mills,Queensland,QLD,-28.3333,151.5,4
4352,Meringandan West,Queensland,QLD,-27.4258,151.9007,3
4352,Spring Bluff,Queensland,QLD,-27.4625,151.9698,4
4352,Kulpi,Queensland,QLD,-27.1901,151.6984,4
4352,Ballard,Queensland,QLD,-27.7753,151.6342,3
4352,Cabarlah,Queensland,QLD,-27.4333,152,4
4352,Highgrove,Queensland,QLD,-27.1333,151.7667,4
4352,Wyreema,Queensland,QLD,-27.6558,151.8553,4
4352,Fifteen Mile,Queensland,QLD,-27.7753,151.6342,3
4352,Groomsville,Queensland,QLD,-27.3136,151.9654,3
4352,Highfields,Queensland,QLD,-27.4634,151.9539,4
4352,Yalangur,Queensland,QLD,-27.4167,151.8333,4
4352,Hodgson Vale,Queensland,QLD,-27.6652,151.9325,4
4352,Karara,Queensland,QLD,-28.2085,151.5647,4
4352,Hampton,Queensland,QLD,-27.35,152.0833,4
4352,Pampas,Queensland,QLD,-27.7929,151.4161,4
4352,Coalbank,Queensland,QLD,-27.1167,151.85,4
4352,Maclagan,Queensland,QLD,-27.0867,151.6318,4
4352,Perseverance,Queensland,QLD,-27.3667,152.1333,4
4352,Kleinton,Queensland,QLD,-27.4333,151.95,4
4352,Ravensbourne,Queensland,QLD,-27.3667,152.1667,4
4352,Meringandan,Queensland,QLD,-27.4301,151.9136,4
4352,Glencoe,Queensland,QLD,-27.5997,151.838,3
4352,Peranga,Queensland,QLD,-27.1469,151.6939,4
4352,Djuan,Queensland,QLD,-27.1167,151.9833,4
4352,Muniganeen,Queensland,QLD,-27.7753,151.6342,3
4352,Pechey,Queensland,QLD,-27.3,152.05,4
4352,Tummaville,Queensland,QLD,-27.8754,151.5056,4
4352,Branchview,Queensland,QLD,-27.7753,151.6342,3
4352,Geham,Queensland,QLD,-27.4,152.0167,4
4352,Evergreen,Queensland,QLD,-27.15,151.7333,4
4352,Upper Lockyer,Queensland,QLD,-27.6667,152.2333,4
4352,Blanchview,Queensland,QLD,-27.5667,152.0333,4
4352,Cutella,Queensland,QLD,-27.45,151.8167,4
4352,Nutgrove,Queensland,QLD,-27.05,151.7667,4
4352,Gore,Queensland,QLD,-28.2833,151.4833,4
4352,Merritts Creek,Queensland,QLD,-27.7753,151.6342,3
4352,Severnlea,Queensland,QLD,-28.7023,151.9027,4
4352,Narko,Queensland,QLD,-27.7753,151.6342,3
4352,Oman Ama,Queensland,QLD,-28.4,151.3,4
4352,Derrymore,Queensland,QLD,-27.7753,151.6342,3
4352,Birnam,Queensland,QLD,-27.4833,151.9167,4
4352,Mount Luke,Queensland,QLD,-27.4,150.0333,4
4352,Silver Ridge,Queensland,QLD,-27.5997,151.838,3
4352,Condamine Plains,Queensland,QLD,-27.7753,151.6342,3
4352,Wutul,Queensland,QLD,-27.0333,151.8,4
4352,Malling,Queensland,QLD,-27.0709,151.5901,4
4352,Woolmer,Queensland,QLD,-27.45,151.9167,4
4352,Doctor Creek,Queensland,QLD,-27.7753,151.6342,3
4352,Murphys Creek,Queensland,QLD,-27.4637,152.0549,4
4352,Lyra,Queensland,QLD,-28.829,151.8552,4
4352,Vale View,Queensland,QLD,-27.6619,151.899,4
4352,Thornville,Queensland,QLD,-27.7753,151.6342,3
4352,Ramsay,Queensland,QLD,-27.7232,151.9886,4
4352,Yandilla,Queensland,QLD,-27.85,151.3667,4
4352,Preston,Queensland,QLD,-27.6604,151.9866,4
4352,Toowoomba Mc,Queensland,QLD,-27.7753,151.6342,3
4352,Amiens,Queensland,QLD,-28.588,151.8074,4
4352,St Aubyn,Queensland,QLD,-27.0833,151.9167,4
4352,Bapaume,Queensland,QLD,-28.5699,151.8438,4
4352,Palmtree,Queensland,QLD,-27.7753,151.6342,3
4352,Gowrie Junction,Queensland,QLD,-27.4833,151.8833,4
4352,Lilyvale,Queensland,QLD,-27.7753,151.6342,3
4352,Woodleigh,Queensland,QLD,-27.7753,151.6342,3
4352,Gowrie Little Plain,Queensland,QLD,-27.7753,151.6342,3
4352,Grapetree,Queensland,QLD,-27.7753,151.6342,3
4352,Withcott,Queensland,QLD,-27.5541,152.0243,4
4352,White Mountain,Queensland,QLD,-27.7753,151.6342,3
4352,Kurrowah,Queensland,QLD,-27.7753,151.6342,3
4352,Whichello,Queensland,QLD,-27.7753,151.6342,3
4352,Iredale,Queensland,QLD,-27.7753,151.6342,3
4352,Rangemore,Queensland,QLD,-27.7753,151.6342,3
4352,Postmans Ridge,Queensland,QLD,-27.5309,152.0528,4
4352,North Maclagan,Queensland,QLD,-27.7753,151.6342,3
4352,Pozieres,Queensland,QLD,-28.5269,151.8829,4
4352,Cawdor,Queensland,QLD,-27.4667,151.9,4
4353,Bergen,Queensland,QLD,-27.2167,151.8833,3
4353,East Cooyar,Queensland,QLD,-27.2167,151.8833,3
4353,Haden,Queensland,QLD,-27.2266,151.8858,4
4354,Kilbirnie,Queensland,QLD,-27.2167,151.85,4
4354,Goombungee,Queensland,QLD,-27.3167,151.7,4
4354,Douglas,Queensland,QLD,-27.3175,151.8788,3
4355,The Bluff,Queensland,QLD,-27.6235,152.5355,4
4355,Mountain Camp,Queensland,QLD,-27.4,152.8833,4
4355,Pinelands,Queensland,QLD,-27.27,152.345,3
4355,Crows Nest,Queensland,QLD,-27.2599,152.0516,4
4355,Anduramba,Queensland,QLD,-27.15,152.1,4
4355,Pierces Creek,Queensland,QLD,-27.2077,152.0273,3
4355,Cressbrook Creek,Queensland,QLD,-27.27,152.345,3
4355,Jones Gully,Queensland,QLD,-27.27,152.345,3
4355,Glenaven,Queensland,QLD,-27.2077,152.0273,3
4355,Upper Pinelands,Queensland,QLD,-27.27,152.345,3
4355,Plainby,Queensland,QLD,-27.27,152.345,3
4355,Emu Creek,Queensland,QLD,-27.27,152.345,3
4356,Mount Tyson,Queensland,QLD,-27.5833,151.5667,4
4356,Linthorpe,Queensland,QLD,-27.5976,151.6262,3
4356,Yarranlea,Queensland,QLD,-27.7283,151.5257,4
4356,Purrawunda,Queensland,QLD,-27.7002,151.5413,3
4356,Bongeen,Queensland,QLD,-27.5667,151.45,4
4356,North Branch,Queensland,QLD,-28.0368,152.3298,4
4356,Motley,Queensland,QLD,-27.55,151.6167,4
4356,Irongate,Queensland,QLD,-27.7002,151.5413,3
4356,Stoneleigh,Queensland,QLD,-26.5766,151.3706,4
4356,Broxburn,Queensland,QLD,-27.7,151.6667,4
4356,Norwin,Queensland,QLD,-27.5667,151.3833,4
4356,Pittsworth,Queensland,QLD,-27.7164,151.634,4
4356,Springside,Queensland,QLD,-27.6744,151.5921,4
4356,Evanslea,Queensland,QLD,-27.7002,151.5413,3
4356,Kincora,Queensland,QLD,-27.7002,151.5413,3
4356,Scrubby Mountain,Queensland,QLD,-27.7692,151.585,3
4356,Rossvale,Queensland,QLD,-27.7002,151.5413,3
4356,St Helens,Queensland,QLD,-27.7002,151.5413,3
4357,Captains Mountain,Queensland,QLD,-27.9222,151.1706,3
4357,Kooroongarra,Queensland,QLD,-28.1032,151.2586,4
4357,Cypress Gardens,Queensland,QLD,-27.8684,151.0835,3
4357,Turallin,Queensland,QLD,-27.8333,151.2167,4
4357,Bringalily,Queensland,QLD,-27.8684,151.0835,3
4357,Millmerran Woods,Queensland,QLD,-27.8684,151.0835,3
4357,Bulli Creek,Queensland,QLD,-27.8684,151.0835,3
4357,Clontarf,Queensland,QLD,-27.9667,151.2167,4
4357,Condamine Farms,Queensland,QLD,-27.8684,151.0835,3
4357,Lavelle,Queensland,QLD,-27.8684,151.0835,3
4357,Western Creek,Queensland,QLD,-26.5167,149.9333,4
4357,Rocky Creek,Queensland,QLD,-28.0167,151.35,4
4357,The Pines,Queensland,QLD,-27.8684,151.0835,3
4357,Mount Emlyn,Queensland,QLD,-28.0397,151.3342,4
4357,Forest Ridge,Queensland,QLD,-27.8684,151.0835,3
4357,Millmerran,Queensland,QLD,-27.8791,151.2706,4
4357,Domville,Queensland,QLD,-27.8684,151.0835,3
4357,Stonehenge,Queensland,QLD,-27.8684,151.0835,3
4357,Canning Creek,Queensland,QLD,-27.8684,151.0835,3
4357,Millmerran Downs,Queensland,QLD,-27.8684,151.0835,3
4357,Millwood,Queensland,QLD,-27.8684,151.0835,3
4357,Wattle Ridge,Queensland,QLD,-27.8684,151.0835,3
4357,Lemontree,Queensland,QLD,-27.8684,151.0835,3
4357,Grays Gate,Queensland,QLD,-27.8684,151.0835,3
4357,Woondul,Queensland,QLD,-27.8684,151.0835,3
4357,Punchs Creek,Queensland,QLD,-27.8684,151.0835,3
4358,Cambooya,Queensland,QLD,-27.7145,151.8603,4
4358,Vale View,Queensland,QLD,-27.6619,151.899,4
4358,Ramsay,Queensland,QLD,-27.7232,151.9886,4
4358,Felton South,Queensland,QLD,-27.7484,151.8971,3
4358,Felton,Queensland,QLD,-27.8022,151.7693,4
4359,Hirstglen,Queensland,QLD,-27.8333,152.1,4
4359,Greenmount East,Queensland,QLD,-27.7976,152.0087,3
4359,Ascot,Queensland,QLD,-27.4315,153.058,4
4359,East Greenmount,Queensland,QLD,-27.8104,152.0021,3
4359,West Haldon,Queensland,QLD,-27.7976,152.0087,3
4359,Greenmount,Queensland,QLD,-27.7858,151.9008,4
4359,Budgee,Queensland,QLD,-27.7976,152.0087,3
4360,Nobby,Queensland,QLD,-27.8616,151.9011,4
4361,Pilton,Queensland,QLD,-27.8701,152.0458,4
4361,Manapouri,Queensland,QLD,-27.9373,151.9367,3
4361,Upper Pilton,Queensland,QLD,-27.8701,152.0458,4
4361,Ryeford,Queensland,QLD,-27.9373,151.9367,3
4361,Elphinstone,Queensland,QLD,-27.973,151.9023,4
4361,Missen Flat,Queensland,QLD,-27.9373,151.9367,3
4361,Back Plains,Queensland,QLD,-27.9,151.7833,4
4361,Mount Molar,Queensland,QLD,-27.8816,151.8517,4
4361,Kings Creek,Queensland,QLD,-27.9,151.9167,4
4361,Victoria Hill,Queensland,QLD,-27.9373,151.9367,3
4361,Spring Creek,Queensland,QLD,-27.9333,151.9833,4
4361,Nevilton,Queensland,QLD,-27.9373,151.9367,3
4361,Ellangowan,Queensland,QLD,-27.9415,151.7273,4
4361,Sandy Camp,Queensland,QLD,-27.9373,151.9367,3
4361,Clifton,Queensland,QLD,-27.9337,151.9066,4
4361,Headington Hill,Queensland,QLD,-27.9373,151.9367,3
4362,Berat,Queensland,QLD,-28.05,152.05,4
4362,Mount Marshall,Queensland,QLD,-28.0833,152.0333,4
4362,Allora,Queensland,QLD,-28.0348,151.9806,4
4362,Ellinthorp,Queensland,QLD,-28.0512,151.9221,4
4362,Deuchar,Queensland,QLD,-28.1176,151.9575,4
4362,Talgai,Queensland,QLD,-27.9864,151.7704,4
4362,Goomburra,Queensland,QLD,-28.05,152.1333,4
4362,Hendon,Queensland,QLD,-28.0772,151.9492,4
4363,Southbrook,Queensland,QLD,-27.6833,151.7167,4
4364,Brookstead,Queensland,QLD,-27.7607,151.4456,4
4365,Leyburn,Queensland,QLD,-28.011,151.5821,4
4370,Morgan Park,Queensland,QLD,-28.2562,152.0454,4
4370,Wildash,Queensland,QLD,-28.1853,152.02,3
4370,Thanes Creek,Queensland,QLD,-28.1167,151.6833,4
4370,Mount Tabor,Queensland,QLD,-28.1853,152.02,3
4370,Massie,Queensland,QLD,-28.1478,151.9556,4
4370,Clintonvale,Queensland,QLD,-28.1,152.1167,4
4370,Cunningham,Queensland,QLD,-28.1613,151.8421,4
4370,Danderoo,Queensland,QLD,-28.25,152.2333,4
4370,Pratten,Queensland,QLD,-28.0892,151.7827,4
4370,Elbow Valley,Queensland,QLD,-28.3833,152.1333,4
4370,Allan,Queensland,QLD,-28.2017,151.9512,4
4370,Leslie Dam,Queensland,QLD,-28.1853,152.02,3
4370,Leslie,Queensland,QLD,-28.1787,151.9217,4
4370,Swan Creek,Queensland,QLD,-28.2,152.1333,4
4370,Thane,Queensland,QLD,-28.1621,151.7012,4
4370,Wiyarra,Queensland,QLD,-28.2667,152.2333,4
4370,North Branch,Queensland,QLD,-28.0368,152.3298,4
4370,Sladevale,Queensland,QLD,-28.1667,152.0667,4
4370,Montrose,Queensland,QLD,-28.1817,151.7933,4
4370,Murrays Bridge,Queensland,QLD,-28.1853,152.02,3
4370,Cherry Gully,Queensland,QLD,-28.4331,152.0321,4
4370,Canningvale,Queensland,QLD,-28.2441,152.0564,4
4370,Loch Lomond,Queensland,QLD,-28.3167,152.2,4
4370,Wheatvale,Queensland,QLD,-28.1667,151.8833,4
4370,Bony Mountain,Queensland,QLD,-28.1333,151.8333,4
4370,Rodgers Creek,Queensland,QLD,-28.1853,152.02,3
4370,Warwick,Queensland,QLD,-28.219,152.0344,4
4370,Rosenthal Heights,Queensland,QLD,-28.2401,152.0133,4
4370,Mount Sturt,Queensland,QLD,-28.2,152.1833,4
4370,Rosehill,Queensland,QLD,-28.1833,152,4
4370,Womina,Queensland,QLD,-28.1833,152.05,4
4370,The Hermitage,Queensland,QLD,-28.1853,152.02,3
4370,Junabee,Queensland,QLD,-28.25,152.1333,4
4370,Glengallan,Queensland,QLD,-28.1853,152.02,3
4370,Greymare,Queensland,QLD,-28.1667,151.7667,4
4370,Warwick DC,Queensland,QLD,-28.1853,152.02,3
4370,Upper Freestone,Queensland,QLD,-28.1333,152.2,4
4370,Tregony,Queensland,QLD,-28.1853,152.02,3
4370,Mount Colliery,Queensland,QLD,-28.2503,152.3738,4
4370,Willowvale,Queensland,QLD,-28.1853,152.02,3
4370,Freestone,Queensland,QLD,-28.1333,152.1333,4
4370,Silverwood,Queensland,QLD,-28.3239,152.0051,4
4370,Upper Wheatvale,Queensland,QLD,-28.1853,152.02,3
4370,The Glen,Queensland,QLD,-28.3574,151.9244,4
4370,Toolburra,Queensland,QLD,-28.1667,151.9667,4
4370,Maryvale,Queensland,QLD,-28.0687,152.2436,4
4370,Gladfield,Queensland,QLD,-28.1853,152.02,3
4371,Emu Vale,Queensland,QLD,-28.2241,152.2467,4
4371,Swanfels,Queensland,QLD,-28.2141,152.2288,3
4371,Yangan,Queensland,QLD,-28.1949,152.2075,4
4372,Tannymorel,Queensland,QLD,-28.2927,152.2472,4
4373,The Head,Queensland,QLD,-28.2833,152.4167,4
4373,Killarney,Queensland,QLD,-28.3327,152.2942,4
4373,The Falls,Queensland,QLD,-28.311,152.3559,3
4374,Dalveen,Queensland,QLD,-28.4871,151.9674,4
4375,Fleurbaix,Queensland,QLD,-28.5333,151.9167,4
4375,Cottonvale,Queensland,QLD,-27.7167,152.4667,4
4376,Thulimbah,Queensland,QLD,-28.5506,151.9486,4
4377,Glen Niven,Queensland,QLD,-28.6,151.9667,4
4377,The Summit,Queensland,QLD,-28.5805,151.9533,4
4378,Applethorpe,Queensland,QLD,-28.6193,151.9601,4
4380,Severnlea,Queensland,QLD,-28.7023,151.9027,4
4380,Springdale,Queensland,QLD,-28.7241,151.825,3
4380,Stanthorpe,Queensland,QLD,-28.6543,151.9339,4
4380,Glenlyon,Queensland,QLD,-28.7241,151.825,3
4380,Thorndale,Queensland,QLD,-28.7241,151.825,3
4380,Pikedale,Queensland,QLD,-28.65,151.6333,4
4380,Amiens,Queensland,QLD,-28.588,151.8074,4
4380,Sugarloaf,Queensland,QLD,-28.7241,151.825,3
4380,Pikes Creek,Queensland,QLD,-28.7241,151.825,3
4380,Broadwater,Queensland,QLD,-28.7241,151.825,3
4380,Eukey,Queensland,QLD,-28.7667,151.9833,4
4380,Nundubbermere,Queensland,QLD,-28.7241,151.825,3
4380,Greenlands,Queensland,QLD,-28.7241,151.825,3
4380,Mingoola,Queensland,QLD,-28.9833,151.5167,4
4380,Kyoomba,Queensland,QLD,-28.6833,152,4
4380,Dalcouth,Queensland,QLD,-28.7241,151.825,3
4380,Storm King,Queensland,QLD,-28.7241,151.825,3
4380,Mount Tully,Queensland,QLD,-28.7646,151.9481,4
4380,Cannon Creek,Queensland,QLD,-28.6036,151.8722,4
4381,Fletcher,Queensland,QLD,-28.774,151.8637,4
4381,Glen Aplin,Queensland,QLD,-28.7338,151.8797,4
4382,Lyra,Queensland,QLD,-28.829,151.8552,4
4382,Girraween,Queensland,QLD,-28.8278,151.8556,3
4382,Wyberba,Queensland,QLD,-28.8619,151.8646,4
4382,Ballandean,Queensland,QLD,-28.798,151.8401,4
4382,Somme,Queensland,QLD,-28.8278,151.8556,3
4383,Wallangarra,Queensland,QLD,-28.9239,151.9282,4
4384,Limevale,Queensland,QLD,-28.721,151.1824,4
4385,Texas,Queensland,QLD,-28.8542,151.165,4
4385,Smithlea,Queensland,QLD,-28.7884,151.01,3
4385,Glenarbon,Queensland,QLD,-28.6835,150.8928,4
4385,Riverton,Queensland,QLD,-28.7884,151.01,3
4385,Beebo,Queensland,QLD,-28.7239,150.966,4
4385,Watsons Crossing,Queensland,QLD,-28.7884,151.01,3
4385,Bonshaw,Queensland,QLD,-28.7884,151.01,3
4385,Maidenhead,Queensland,QLD,-28.7884,151.01,3
4385,Silver Spur,Queensland,QLD,-28.7884,151.01,3
4387,Whetstone,Queensland,QLD,-28.5,150.9333,4
4387,Inglewood,Queensland,QLD,-28.4159,151.0807,4
4387,Coolmunda,Queensland,QLD,-28.4171,151.1449,4
4387,Warroo,Queensland,QLD,-28.4789,151.1134,3
4387,Terrica,Queensland,QLD,-28.5219,151.4786,4
4387,Bybera,Queensland,QLD,-28.4789,151.1134,3
4387,Greenup,Queensland,QLD,-28.4789,151.1134,3
4387,Brush Creek,Queensland,QLD,-28.4789,151.1134,3
4387,Mosquito Creek,Queensland,QLD,-28.4789,151.1134,3
4388,Yelarbon,Queensland,QLD,-28.5725,150.7513,4
4388,Kurumbul,Queensland,QLD,-28.6167,150.55,4
4390,Callandoon,Queensland,QLD,-28.4958,150.1831,4
4390,Calingunee,Queensland,QLD,-28.194,150.3565,3
4390,Goondiwindi,Queensland,QLD,-28.5471,150.3055,4
4390,Kindon,Queensland,QLD,-28.194,150.3565,3
4390,Wondalli,Queensland,QLD,-28.194,150.3565,3
4390,Lundavra,Queensland,QLD,-28.194,150.3565,3
4390,Yagaburne,Queensland,QLD,-28.194,150.3565,3
4390,Wyaga,Queensland,QLD,-28.194,150.3565,3
4390,Goodar,Queensland,QLD,-28.194,150.3565,3
4390,Billa Billa,Queensland,QLD,-28.194,150.3565,3
4400,Kingsthorpe,Queensland,QLD,-27.4756,151.8141,4
4401,Sabine,Queensland,QLD,-27.35,151.7167,4
4401,Highland Plains,Queensland,QLD,-27.4617,151.6957,3
4401,Muldu,Queensland,QLD,-27.2667,151.6833,4
4401,Aubigny,Queensland,QLD,-27.5234,151.6348,4
4401,Boodua,Queensland,QLD,-27.3833,151.8333,4
4401,Devon Park,Queensland,QLD,-27.4617,151.6957,3
4401,Rosalie Plains,Queensland,QLD,-27.2167,151.7,4
4401,Acland,Queensland,QLD,-27.3086,151.6898,4
4401,Biddeston,Queensland,QLD,-27.5592,151.718,4
4401,Greenwood,Queensland,QLD,-27.4617,151.6957,3
4401,Oakey,Queensland,QLD,-27.4331,151.7206,4
4401,Silverleigh,Queensland,QLD,-27.4617,151.6957,3
4401,Balgowan,Queensland,QLD,-27.4617,151.6957,3
4401,Kelvinhaugh,Queensland,QLD,-27.4617,151.6957,3
4401,Mount Irving,Queensland,QLD,-27.4617,151.6957,3
4401,Yargullen,Queensland,QLD,-27.5,151.65,4
4402,Kooralgin,Queensland,QLD,-26.9333,151.95,4
4402,Upper Cooyar Creek,Queensland,QLD,-26.9583,151.8917,3
4402,Cooyar,Queensland,QLD,-26.9844,151.8297,4
4403,Brymaroo,Queensland,QLD,-27.2835,151.6046,3
4403,Quinalow,Queensland,QLD,-27.1123,151.6209,4
4403,West Prairie,Queensland,QLD,-27.2835,151.6046,3
4403,Malu,Queensland,QLD,-27.35,151.5667,4
4403,Jondaryan,Queensland,QLD,-27.3703,151.5904,4
4403,Mount Moriah,Queensland,QLD,-27.2835,151.6046,3
4404,Formartin,Queensland,QLD,-27.3959,151.4118,4
4404,Irvingdale,Queensland,QLD,-27.3495,151.4651,3
4404,Bowenville,Queensland,QLD,-27.3055,151.4899,4
4404,Wainui,Queensland,QLD,-27.3495,151.4651,3
4405,Ducklo,Queensland,QLD,-27.2552,151.011,4
4405,Dalby,Queensland,QLD,-27.1817,151.2621,4
4405,Blaxland,Queensland,QLD,-27.1987,151.3211,4
4405,Bunya Mountains,Queensland,QLD,-27.236,151.1628,3
4405,Weranga,Queensland,QLD,-27.3051,150.7262,4
4405,Grassdale,Queensland,QLD,-27.236,151.1628,3
4405,Tipton,Queensland,QLD,-27.3892,151.2704,4
4405,St Ruth,Queensland,QLD,-27.2763,151.1157,3
4405,Ranges Bridge,Queensland,QLD,-27.2763,151.1157,3
4405,Marmadua,Queensland,QLD,-27.2763,151.1157,3
4405,Pirrinuan,Queensland,QLD,-27.2763,151.1157,3
4406,Kaimkillenbun,Queensland,QLD,-27.0627,151.4362,4
4406,Weir River,Queensland,QLD,-27.1306,150.7917,3
4406,The Gums,Queensland,QLD,-27.3333,150.1833,4
4406,Boondandilla,Queensland,QLD,-27.1306,150.7917,3
4406,Moonie,Queensland,QLD,-27.7184,150.3697,4
4406,Macalister,Queensland,QLD,-27.0457,151.081,4
4406,Hannaford,Queensland,QLD,-27.3296,150.0631,4
4406,Jimbour,Queensland,QLD,-26.9627,151.2145,4
4406,Southwood,Queensland,QLD,-27.7683,150.0713,4
4406,Kogan,Queensland,QLD,-27.0333,150.7667,4
4407,Cattle Creek,Queensland,QLD,-13.1617,143.0534,3
4407,Cecil Plains,Queensland,QLD,-27.5332,151.1962,4
4407,Dunmore,Queensland,QLD,-27.6386,151.2709,3
4407,Nangwee,Queensland,QLD,-27.55,151.3,4
4408,Bell,Queensland,QLD,-26.9354,151.451,4
4410,Jandowae,Queensland,QLD,-26.7841,151.1124,4
4411,Warra,Queensland,QLD,-26.9323,150.9229,4
4412,Brigalow,Queensland,QLD,-26.8452,150.7916,4
4413,Durah,Queensland,QLD,-26.7809,150.6226,3
4413,Boonarga,Queensland,QLD,-26.7957,150.7172,4
4413,Hopeland,Queensland,QLD,-26.3471,150.6717,3
4413,Montrose,Queensland,QLD,-28.1817,151.7933,4
4413,Canaga,Queensland,QLD,-26.7809,150.6226,3
4413,Baking Board,Queensland,QLD,-26.7078,150.5455,4
4413,Burncluith,Queensland,QLD,-26.6333,150.7167,4
4413,Wieambilla,Queensland,QLD,-27.0167,150.5,4
4413,Chances Plain,Queensland,QLD,-26.7809,150.6226,3
4413,Chinchilla,Queensland,QLD,-26.7379,150.6297,4
4415,Dalwogon,Queensland,QLD,-26.5727,150.1489,4
4415,Hookswood,Queensland,QLD,-26.5532,150.2053,3
4415,Columboola,Queensland,QLD,-26.6727,150.3432,4
4415,Myall Park,Queensland,QLD,-26.5532,150.2053,3
4415,Miles,Queensland,QLD,-26.6584,150.1882,4
4415,Pelham,Queensland,QLD,-26.5532,150.2053,3
4415,Gurulmundi,Queensland,QLD,-26.4172,150.0574,4
4415,Kowguran,Queensland,QLD,-26.4953,150.1036,4
4416,Yulabilla,Queensland,QLD,-26.9843,150.0054,3
4416,Condamine,Queensland,QLD,-26.9286,150.1404,4
4416,Pine Hills,Queensland,QLD,-26.9843,150.0054,3
4416,Moraby,Queensland,QLD,-26.9843,150.0054,3
4416,Nangram,Queensland,QLD,-26.9843,150.0054,3
4416,Barramornie,Queensland,QLD,-26.9843,150.0054,3
4416,Sunnyside,Queensland,QLD,-26.9843,150.0054,3
4417,Wellesley,Queensland,QLD,-26.631,150.4009,3
4417,Parknook,Queensland,QLD,-26.631,150.4009,3
4417,Surat,Queensland,QLD,-27.1529,149.0681,4
4417,Warkon,Queensland,QLD,-26.631,150.4009,3
4417,Oberina,Queensland,QLD,-26.631,150.4009,3
4417,Noorindoo,Queensland,QLD,-26.631,150.4009,3
4417,Weribone,Queensland,QLD,-26.631,150.4009,3
4418,Guluguba,Queensland,QLD,-26.2593,150.0493,4
4419,Cockatoo,Queensland,QLD,-14.9895,142.77,3
4419,Wandoan,Queensland,QLD,-26.1228,149.9603,4
4419,Grosmont,Queensland,QLD,-25.9419,149.9011,3
4420,Spring Creek,Queensland,QLD,-27.9333,151.9833,4
4420,Coorada,Queensland,QLD,-25.4868,149.6225,3
4420,Ghinghinda,Queensland,QLD,-25.4868,149.6225,3
4420,Peek-A-Doo,Queensland,QLD,-25.4868,149.6225,3
4420,Taroom,Queensland,QLD,-25.6393,149.7998,4
4420,Gwambegwine,Queensland,QLD,-25.4868,149.6225,3
4420,Hornet Bank,Queensland,QLD,-25.4868,149.6225,3
4420,Glenhaughton,Queensland,QLD,-25.4868,149.6225,3
4420,Broadmere,Queensland,QLD,-25.4868,149.6225,3
4421,Tara,Queensland,QLD,-27.2761,150.4568,4
4421,Goranba,Queensland,QLD,-27.2879,150.6003,4
4422,Flinton,Queensland,QLD,-27.8833,149.5667,4
4422,Inglestone,Queensland,QLD,-27.6083,149.725,3
4422,Meandarra,Queensland,QLD,-27.3228,149.8825,4
4422,Coomrith,Queensland,QLD,-27.6083,149.725,3
4422,Westmar,Queensland,QLD,-27.6083,149.725,3
4423,Glenmorgan,Queensland,QLD,-27.254,149.681,4
4423,Teelba,Queensland,QLD,-27.25,149.6833,3
4424,Drillham,Queensland,QLD,-26.6431,149.9762,4
4424,Drillham South,Queensland,QLD,-26.7755,149.8266,3
4424,Glenaubyn,Queensland,QLD,-26.7755,149.8266,3
4425,Dulacca,Queensland,QLD,-26.6452,149.7612,4
4425,Bogandilla,Queensland,QLD,-26.5,149.8,4
4426,Jackson,Queensland,QLD,-26.6452,149.626,4
4426,Jackson North,Queensland,QLD,-26.5332,149.5778,3
4426,Jackson South,Queensland,QLD,-26.5332,149.5778,3
4427,Yuleba,Queensland,QLD,-26.6115,149.3821,4
4427,Clifford,Queensland,QLD,-26.6115,149.3821,3
4427,Yuleba South,Queensland,QLD,-26.6171,149.3784,3
4427,Yuleba North,Queensland,QLD,-26.6171,149.3784,3
4428,Wallumbilla South,Queensland,QLD,-26.6506,149.2272,3
4428,Wallumbilla North,Queensland,QLD,-26.3092,149.2359,3
4428,Wallumbilla,Queensland,QLD,-26.5827,149.1871,4
4428,Pickanjinnie,Queensland,QLD,-26.5831,149.1133,4
4454,Durham Downs,Queensland,QLD,-25.5572,148.1783,3
4454,Injune,Queensland,QLD,-25.8411,148.5665,4
4454,Baffle West,Queensland,QLD,-25.5572,148.1783,3
4454,Highland Plains,Queensland,QLD,-25.5572,148.1783,3
4454,Hutton Creek,Queensland,QLD,-25.5572,148.1783,3
4454,Beilba,Queensland,QLD,-25.5572,148.1783,3
4454,Simmie,Queensland,QLD,-25.5572,148.1783,3
4454,Westgrove,Queensland,QLD,-25.5572,148.1783,3
4454,Upper Dawson,Queensland,QLD,-25.5572,148.1783,3
4454,Mount Hutton,Queensland,QLD,-25.5572,148.1783,3
4454,Pony Hills,Queensland,QLD,-25.5572,148.1783,3
4455,Orallo,Queensland,QLD,-26.276,148.5434,4
4455,Roma,Queensland,QLD,-26.5674,148.7875,4
4455,Dargal Road,Queensland,QLD,-26.5495,148.7172,3
4455,Wycombe,Queensland,QLD,-26.3605,148.7068,3
4455,Euthulla,Queensland,QLD,-26.4913,148.6656,3
4455,Mooga,Queensland,QLD,-26.2667,148.9333,4
4455,Tingun,Queensland,QLD,-26.744,148.9144,3
4455,Cornwall,Queensland,QLD,-26.7779,148.6938,3
4455,Ballaroo,Queensland,QLD,-26.3605,148.7068,3
4455,Mount Abundance,Queensland,QLD,-26.7779,148.6938,3
4455,Bymount,Queensland,QLD,-26.0667,148.5333,4
4455,Eumamurrin,Queensland,QLD,-26.1565,148.6575,3
4455,Blythdale,Queensland,QLD,-26.581,148.9559,4
4455,Orange Hill,Queensland,QLD,-26.7779,148.6938,3
4455,Hodgson,Queensland,QLD,-26.5667,148.6167,4
4455,Mount Bindango,Queensland,QLD,-26.7779,148.6938,3
4455,Gunnewin,Queensland,QLD,-25.9884,148.5586,4
4455,Bungil,Queensland,QLD,-26.7779,148.6938,3
4455,Bungeworgorai,Queensland,QLD,-26.5798,148.7197,4
4461,Muckadilla,Queensland,QLD,-26.5896,148.3919,4
4462,Amby,Queensland,QLD,-26.55,148.1833,4
4465,Forestvale,Queensland,QLD,-26.4924,147.9038,3
4465,Womalilla,Queensland,QLD,-26.5,147.8333,4
4465,Dunkeld,Queensland,QLD,-26.4924,147.9038,3
4465,V Gate,Queensland,QLD,-26.4924,147.9038,3
4465,Mitchell,Queensland,QLD,-26.4849,147.9742,4
4467,Mungallala,Queensland,QLD,-26.4491,147.5418,4
4467,Tyrconnel,Queensland,QLD,-26.45,147.5333,3
4467,Redford,Queensland,QLD,-26.45,147.5333,3
4468,Clara Creek,Queensland,QLD,-26.4167,147.1167,3
4468,Morven,Queensland,QLD,-26.4177,147.1139,4
4470,Riversleigh,Queensland,QLD,-26.2694,145.9547,3
4470,Charleville,Queensland,QLD,-26.4054,146.2427,4
4470,Bakers Bend,Queensland,QLD,-26.2694,145.9547,3
4470,Langlo,Queensland,QLD,-26.1333,145.6667,4
4470,Murweh,Queensland,QLD,-26.2694,145.9547,3
4470,Gowrie Station,Queensland,QLD,-26.2694,145.9547,3
4471,Claverton,Queensland,QLD,-27.3726,145.9577,4
4472,Blackall,Queensland,QLD,-24.4233,145.463,4
4472,Mount Enniskillen,Queensland,QLD,-24.587,145.403,3
4474,Adavale,Queensland,QLD,-25.9073,144.6008,4
4475,Cheepie,Queensland,QLD,-26.6333,145.0167,4
4477,Augathella,Queensland,QLD,-25.7962,146.5845,4
4477,Upper Warrego,Queensland,QLD,-26.6351,145.9587,3
4478,Tambo,Queensland,QLD,-24.8819,146.2575,4
4478,Lumeah,Queensland,QLD,-25.0182,146.2489,3
4478,Caldervale,Queensland,QLD,-25.0182,146.2489,3
4478,Macfarlane,Queensland,QLD,-25.0182,146.2489,3
4478,Minnie Downs,Queensland,QLD,-25.0182,146.2489,3
4478,Bayrick,Queensland,QLD,-25.0182,146.2489,3
4478,Windeyer,Queensland,QLD,-25.0182,146.2489,3
4478,Yandarlo,Queensland,QLD,-25.0182,146.2489,3
4478,Lansdowne,Queensland,QLD,-25.0182,146.2489,3
4478,Scrubby Creek,Queensland,QLD,-25.0182,146.2489,3
4479,Cooladdi,Queensland,QLD,-26.6426,145.4644,4
4480,Quilpie,Queensland,QLD,-26.6135,144.2695,4
4480,Eromanga,Queensland,QLD,-26.6706,143.2664,4
4481,Tanbar,Queensland,QLD,-25.4211,141.8981,3
4481,Windorah,Queensland,QLD,-25.4222,142.6566,4
4481,Farrars Creek,Queensland,QLD,-28.1566,147.6171,3
4482,Birdsville,Queensland,QLD,-25.592,140.1157,1
4486,Hebel,Queensland,QLD,-28.9733,147.7948,4
4486,Dirranbandi,Queensland,QLD,-28.5854,148.2255,4
4487,St George,Queensland,QLD,-27.9476,148.6099,4
4487,Begonia,Queensland,QLD,-27.9476,148.6099,3
4488,Nebine,Queensland,QLD,-28.2776,147.4572,3
4488,Bollon,Queensland,QLD,-28.0333,147.4833,4
4489,Wyandra,Queensland,QLD,-27.2478,145.9805,4
4490,Cunnamulla,Queensland,QLD,-28.0677,145.6844,4
4490,Tuen,Queensland,QLD,-28.5667,145.7167,4
4490,Widgeegoara,Queensland,QLD,-28.2377,145.9073,3
4490,Barringun,Queensland,QLD,-29.0141,145.7159,4
4490,Coongoola,Queensland,QLD,-27.6536,145.9022,4
4490,Yowah,Queensland,QLD,-27.9683,144.6362,3
4490,Noorama,Queensland,QLD,-28.2377,145.9073,3
4490,Cuttaburra,Queensland,QLD,-28.2377,145.9073,3
4490,Humeburn,Queensland,QLD,-28.2377,145.9073,3
4490,Linden,Queensland,QLD,-28.2377,145.9073,3
4490,Jobs Gate,Queensland,QLD,-28.2377,145.9073,3
4491,Eulo,Queensland,QLD,-28.1599,145.0471,4
4492,Dynevor,Queensland,QLD,-28.375,148.7972,3
4492,Nockatunga,Queensland,QLD,-28.375,148.7972,3
4492,Bullawarra,Queensland,QLD,-28.375,148.7972,3
4492,Norley,Queensland,QLD,-28.375,148.7972,3
4492,Bulloo Downs,Queensland,QLD,-28.375,148.7972,3
4492,Thargomindah,Queensland,QLD,-27.995,143.8239,4
4493,Hungerford,Queensland,QLD,-28.9963,144.407,4
4494,Bungunya,Queensland,QLD,-28.4294,149.6555,4
4494,North Bungunya,Queensland,QLD,-28.2718,149.8403,3
4494,Tarawera,Queensland,QLD,-28.2718,149.8403,3
4496,Talwood,Queensland,QLD,-28.4858,149.4681,4
4496,South Talwood,Queensland,QLD,-28.3773,149.1424,3
4496,North Talwood,Queensland,QLD,-28.3773,149.1424,3
4497,Thallon,Queensland,QLD,-28.6379,148.8683,4
4497,Weengallon,Queensland,QLD,-28.5021,148.8399,3
4497,Daymar,Queensland,QLD,-28.6043,148.9792,4
4498,Kioma,Queensland,QLD,-28.332,149.5801,3
4498,Toobeah,Queensland,QLD,-28.4206,149.8662,4
4500,Brendale,Queensland,QLD,-27.3211,152.9839,4
4500,Warner,Queensland,QLD,-27.3122,152.9498,4
4500,Brendale BC,Queensland,QLD,-27.3006,152.9571,3
4500,Clear Mountain,Queensland,QLD,-27.3052,152.883,4
4500,Strathpine Centre,Queensland,QLD,-27.3006,152.9571,3
4500,Joyner,Queensland,QLD,-27.2761,152.9447,4
4500,Brendale DC,Queensland,QLD,-27.3006,152.9571,3
4500,Strathpine,Queensland,QLD,-27.3041,152.9898,4
4500,Cashmere,Queensland,QLD,-27.2977,152.9066,4
4500,Bray Park,Queensland,QLD,-27.2925,152.9678,4
4501,Lawnton,Queensland,QLD,-27.2833,152.9833,4
4502,Petrie,Queensland,QLD,-27.2667,152.9833,4
4503,Murrumba Downs,Queensland,QLD,-27.2673,153.0105,4
4503,Kallangur,Queensland,QLD,-27.252,152.9923,4
4503,Griffin,Queensland,QLD,-27.2715,153.0394,4
4503,Kurwongbah,Queensland,QLD,-27.2325,152.9762,4
4503,Dakabin,Queensland,QLD,-27.2259,152.991,4
4503,Whiteside,Queensland,QLD,-27.2571,152.9116,4
4504,Narangba,Queensland,QLD,-27.2034,152.9592,4
4505,Burpengary,Queensland,QLD,-27.1575,152.9576,4
4505,Burpengary DC,Queensland,QLD,-27.1575,152.9576,3
4505,Burpengary East,Queensland,QLD,-27.1575,152.9576,3
4506,Moorina,Queensland,QLD,-27.1491,152.873,4
4506,Morayfield,Queensland,QLD,-27.1088,152.9491,4
4507,Bongaree,Queensland,QLD,-27.0837,153.1594,4
4507,Bellara,Queensland,QLD,-27.0639,153.1496,4
4507,Banksia Beach,Queensland,QLD,-27.0403,153.1439,4
4507,Woorim,Queensland,QLD,-27.0693,153.2043,4
4507,Bribie Island North,Queensland,QLD,-27.0149,153.1446,3
4507,White Patch,Queensland,QLD,-27.0307,153.1282,4
4507,Bribie Island,Queensland,QLD,-27.0149,153.1446,3
4507,Welsby,Queensland,QLD,-26.9714,153.1292,4
4508,Deception Bay,Queensland,QLD,-27.1935,153.0263,4
4509,North Lakes,Queensland,QLD,-27.2243,153.0208,4
4509,Mango Hill,Queensland,QLD,-27.2435,153.0238,4
4510,Caboolture,Queensland,QLD,-27.0846,152.9511,4
4510,Rocksberg,Queensland,QLD,-27.1,152.8333,4
4510,Upper Caboolture,Queensland,QLD,-27.1167,152.8833,4
4510,Caboolture BC,Queensland,QLD,-27.0772,152.9595,3
4510,Caboolture South,Queensland,QLD,-27.0939,152.9487,4
4510,Donnybrook,Queensland,QLD,-27.005,153.0673,4
4510,Moodlu,Queensland,QLD,-27.0667,152.9,4
4510,Toorbul,Queensland,QLD,-27.0322,153.0983,4
4510,Bellmere,Queensland,QLD,-27.0844,152.89,4
4510,Meldale,Queensland,QLD,-27.0323,153.0724,4
4510,Beachmere,Queensland,QLD,-27.1281,153.0524,4
4511,Godwin Beach,Queensland,QLD,-27.0861,153.0986,4
4511,Sandstone Point,Queensland,QLD,-27.0837,153.1324,4
4511,Ningi,Queensland,QLD,-27.0611,153.0788,4
4512,Wamuran,Queensland,QLD,-27.0391,152.8654,4
4512,Wamuran Basin,Queensland,QLD,-27.0475,152.8262,4
4512,Bracalba,Queensland,QLD,-27.0167,152.85,4
4514,D'Aguilar,Queensland,QLD,-26.9833,152.8,4
4514,Woodford,Queensland,QLD,-26.9529,152.7775,4
4514,Mount Delaney,Queensland,QLD,-27.0147,152.7148,4
4514,Villeneuve,Queensland,QLD,-26.9667,152.6333,4
4514,Neurum,Queensland,QLD,-26.9667,152.7,4
4514,Bellthorpe,Queensland,QLD,-26.8554,152.7609,4
4514,Stony Creek,Queensland,QLD,-26.9272,152.7124,4
4514,Stanmore,Queensland,QLD,-26.8814,152.788,4
4514,Cedarton,Queensland,QLD,-26.85,152.8167,4
4514,Delaneys Creek,Queensland,QLD,-27.0002,152.7955,4
4514,Mount Archer,Queensland,QLD,-26.9307,152.7552,3
4514,Commissioners Flat,Queensland,QLD,-26.9307,152.7552,3
4515,Hazeldean,Queensland,QLD,-27.0321,152.5404,4
4515,Jimna,Queensland,QLD,-26.6676,152.4633,4
4515,Royston,Queensland,QLD,-26.95,152.65,4
4515,Kilcoy,Queensland,QLD,-26.943,152.5654,4
4515,Sandy Creek,Queensland,QLD,-26.8506,152.6377,4
4515,Woolmar,Queensland,QLD,-26.8907,152.577,3
4515,Winya,Queensland,QLD,-26.8907,152.577,3
4515,Sheep Station Creek,Queensland,QLD,-26.8907,152.577,3
4515,Mount Kilcoy,Queensland,QLD,-26.8426,152.5787,4
4515,Hazledean,Queensland,QLD,-26.7416,152.4735,3
4515,Glenfern,Queensland,QLD,-26.95,152.6,4
4515,Monsildale,Queensland,QLD,-26.7416,152.4735,3
4515,Kingaham,Queensland,QLD,-26.8907,152.577,3
4516,Elimbah,Queensland,QLD,-27.0167,152.95,4
4517,Beerburrum,Queensland,QLD,-26.957,152.9554,4
4518,Glass House Mountains,Queensland,QLD,-26.8976,152.9532,4
4519,Beerwah,Queensland,QLD,-26.8588,152.9614,4
4519,Crohamhurst,Queensland,QLD,-26.807,152.8617,4
4519,Peachester,Queensland,QLD,-26.8455,152.884,4
4519,Coochin Creek,Queensland,QLD,-26.9051,153.0749,4
4520,Mount Samson,Queensland,QLD,-27.3,152.85,4
4520,Samford Valley,Queensland,QLD,-27.3727,152.867,4
4520,Mount Nebo,Queensland,QLD,-27.3974,152.7845,4
4520,Kobble Creek,Queensland,QLD,-27.25,152.8333,4
4520,Cedar Creek,Queensland,QLD,-27.3335,152.8187,4
4520,Yugar,Queensland,QLD,-27.3457,152.8718,4
4520,Highvale,Queensland,QLD,-27.3723,152.802,4
4520,Mount Glorious,Queensland,QLD,-27.3339,152.7668,4
4520,Samford Village,Queensland,QLD,-27.3727,152.8866,4
4520,Wights Mountain,Queensland,QLD,-27.3933,152.8554,4
4520,Closeburn,Queensland,QLD,-27.3333,152.8333,4
4520,Samford,Queensland,QLD,-27.3727,152.8866,4
4520,Draper,Queensland,QLD,-27.3526,152.9065,4
4520,Jollys Lookout,Queensland,QLD,-27.4001,152.8073,4
4520,Armstrong Creek,Queensland,QLD,-27.2167,152.8333,4
4520,Enoggera Reservoir,Queensland,QLD,-27.4335,152.8705,4
4520,Samsonvale,Queensland,QLD,-27.2667,152.85,4
4520,Camp Mountain,Queensland,QLD,-27.4,152.8833,4
4521,Campbells Pocket,Queensland,QLD,-27.0767,152.805,4
4521,Dayboro,Queensland,QLD,-27.1963,152.8225,4
4521,Ocean View,Queensland,QLD,-27.1271,152.8053,4
4521,Mount Pleasant,Queensland,QLD,-27.1489,152.7636,4
4521,Mount Mee,Queensland,QLD,-27.0806,152.7702,4
4521,Rush Creek,Queensland,QLD,-27.2086,152.8642,4
4521,Laceys Creek,Queensland,QLD,-27.2319,152.7313,4
4521,King Scrub,Queensland,QLD,-27.1732,152.8215,4
4550,Landsborough,Queensland,QLD,-26.8067,152.961,4
4550,Mount Mellum,Queensland,QLD,-26.816,152.9335,4
4551,Kings Beach,Queensland,QLD,-26.8017,153.1426,4
4551,Shelly Beach,Queensland,QLD,-26.7965,153.1458,4
4551,Aroona,Queensland,QLD,-26.7817,153.1165,4
4551,Little Mountain,Queensland,QLD,-26.7844,153.0922,4
4551,Caloundra BC,Queensland,QLD,-26.7961,153.1229,3
4551,Bells Creek,Queensland,QLD,-26.7836,153.0605,4
4551,Golden Beach,Queensland,QLD,-26.8218,153.1197,4
4551,Currimundi,Queensland,QLD,-26.7693,153.1224,4
4551,Battery Hill,Queensland,QLD,-26.7788,153.1289,4
4551,Pelican Waters,Queensland,QLD,-26.8335,153.1001,4
4551,Caloundra,Queensland,QLD,-26.8035,153.1219,4
4551,Moffat Beach,Queensland,QLD,-26.7905,153.14,4
4551,Caloundra DC,Queensland,QLD,-26.7961,153.1229,3
4551,Caloundra West,Queensland,QLD,-26.7985,153.114,4
4551,Meridan Plains,Queensland,QLD,-26.7735,153.0619,4
4551,Dicky Beach,Queensland,QLD,-26.7836,153.1377,4
4552,Booroobin,Queensland,QLD,-26.8224,152.7666,4
4552,Maleny,Queensland,QLD,-26.76,152.8493,4
4552,North Maleny,Queensland,QLD,-26.7521,152.8583,4
4552,Curramore,Queensland,QLD,-26.6937,152.803,4
4552,Cambroon,Queensland,QLD,-26.6394,152.6853,4
4552,Reesville,Queensland,QLD,-26.7595,152.7879,4
4552,Bald Knob,Queensland,QLD,-26.779,152.9118,4
4552,Elaman Creek,Queensland,QLD,-26.7374,152.7524,4
4552,Conondale,Queensland,QLD,-26.7364,152.7199,4
4552,Balmoral Ridge,Queensland,QLD,-26.7445,152.8922,4
4552,Crystal Waters,Queensland,QLD,-26.7395,152.8249,3
4552,Wootha,Queensland,QLD,-26.7882,152.8009,4
4552,Witta,Queensland,QLD,-26.7,152.8167,4
4552,Harper Creek,Queensland,QLD,-26.7623,152.7117,4
4553,Glenview,Queensland,QLD,-26.7667,153.0167,4
4553,Mooloolah Valley,Queensland,QLD,-26.7655,152.9587,4
4553,Mooloolah,Queensland,QLD,-26.7655,152.9587,4
4553,Diamond Valley,Queensland,QLD,-26.7552,152.9293,4
4553,Palmview,Queensland,QLD,-26.746,153.0599,4
4554,Ilkley,Queensland,QLD,-26.731,152.9977,4
4554,Eudlo,Queensland,QLD,-26.7296,152.954,4
4555,Chevallum,Queensland,QLD,-26.7007,152.9935,4
4555,Palmwoods,Queensland,QLD,-26.6858,152.9613,4
4555,Hunchy,Queensland,QLD,-26.6799,152.9109,4
4555,Landers Shoot,Queensland,QLD,-26.7134,152.9267,4
4556,Mons,Queensland,QLD,-26.6916,153.025,4
4556,Forest Glen,Queensland,QLD,-26.6886,153.0045,4
4556,Sippy Downs,Queensland,QLD,-26.7179,153.0547,4
4556,Buderim,Queensland,QLD,-26.6844,153.0571,4
4556,Kunda Park,Queensland,QLD,-26.6643,153.0354,4
4556,Tanawha,Queensland,QLD,-26.7198,153.0302,4
4557,Mountain Creek,Queensland,QLD,-26.7028,153.1013,4
4557,Mooloolaba,Queensland,QLD,-26.6816,153.1192,4
4558,Maroochydore DC,Queensland,QLD,-26.6589,153.0778,3
4558,Sunshine Plaza,Queensland,QLD,-26.6546,153.0777,3
4558,Maroochydore Bc,Queensland,QLD,-26.6546,153.0777,3
4558,Maroochydore,Queensland,QLD,-26.6601,153.0995,4
4558,Kuluin,Queensland,QLD,-26.6578,153.0561,4
4558,Cotton Tree,Queensland,QLD,-26.6535,153.1012,4
4559,Woombye,Queensland,QLD,-26.6605,152.9695,4
4559,Diddillibah,Queensland,QLD,-26.6417,153.0496,4
4559,Kiels Mountain,Queensland,QLD,-26.6565,153.0081,4
4559,West Woombye,Queensland,QLD,-26.6664,152.9256,4
4560,Parklands,Queensland,QLD,-26.5955,152.9789,4
4560,Nambour BC,Queensland,QLD,-26.6218,152.9337,3
4560,Burnside,Queensland,QLD,-26.6325,152.9406,4
4560,Towen Mountain,Queensland,QLD,-26.6468,152.9284,4
4560,Rosemount,Queensland,QLD,-26.6303,152.9997,4
4560,Kiamba,Queensland,QLD,-26.5727,152.9241,4
4560,Kulangoor,Queensland,QLD,-26.5833,152.9667,4
4560,Nambour,Queensland,QLD,-26.6261,152.9594,4
4560,Dulong,Queensland,QLD,-26.6339,152.9062,4
4560,Image Flat,Queensland,QLD,-26.601,152.933,4
4560,Mapleton,Queensland,QLD,-26.6243,152.8678,4
4560,Highworth,Queensland,QLD,-26.6174,152.9344,4
4560,Perwillowen,Queensland,QLD,-26.6333,152.9167,4
4560,Coes Creek,Queensland,QLD,-26.6472,152.9494,4
4560,Montville,Queensland,QLD,-26.6833,152.8833,4
4560,Nambour DC,Queensland,QLD,-26.6218,152.9337,3
4560,Cooloolabin,Queensland,QLD,-26.55,152.8833,4
4560,Sunshine Coast Mc,Queensland,QLD,-26.6218,152.9337,3
4560,Nambour West,Queensland,QLD,-26.6218,152.9337,3
4560,Kureelpa,Queensland,QLD,-26.6067,152.891,4
4560,Flaxton,Queensland,QLD,-26.6571,152.8641,4
4560,Bli Bli,Queensland,QLD,-26.6172,153.0367,4
4561,Yandina,Queensland,QLD,-26.5616,152.9559,4
4561,Valdora,Queensland,QLD,-26.5459,153.0068,4
4561,Yandina Creek,Queensland,QLD,-26.5387,153.0389,4
4561,Maroochy River,Queensland,QLD,-26.5777,153.0188,4
4561,Ninderry,Queensland,QLD,-26.5409,152.9752,4
4561,Bridges,Queensland,QLD,-26.5333,152.95,4
4561,North Arm,Queensland,QLD,-26.5248,152.9552,4
4562,Belli Park,Queensland,QLD,-26.5085,152.8212,4
4562,Doonan,Queensland,QLD,-26.4396,152.992,4
4562,Verrierdale,Queensland,QLD,-26.4821,152.9986,4
4562,Weyba Downs,Queensland,QLD,-26.4495,153.0472,4
4562,Eumundi,Queensland,QLD,-26.4774,152.9512,4
4562,Eerwah Vale,Queensland,QLD,-26.4794,152.9112,4
4563,Carters Ridge,Queensland,QLD,-26.4525,152.772,4
4563,Black Mountain,Queensland,QLD,-26.3989,152.8756,4
4563,Cooroy Mountain,Queensland,QLD,-26.4252,152.9541,4
4563,Tinbeerwah,Queensland,QLD,-26.4044,152.9826,4
4563,Cooroy,Queensland,QLD,-26.4178,152.9115,4
4563,Ridgewood,Queensland,QLD,-26.4605,152.7991,4
4563,Lake Macdonald,Queensland,QLD,-26.3848,152.9321,4
4564,Pacific Paradise,Queensland,QLD,-26.6181,153.0778,4
4564,Marcoola,Queensland,QLD,-26.5845,153.0947,4
4564,Mudjimba,Queensland,QLD,-26.615,153.0989,4
4564,Twin Waters,Queensland,QLD,-26.61,153,4
4565,Ringtail Creek,Queensland,QLD,-26.3439,152.9668,4
4565,Cootharaba,Queensland,QLD,-26.2667,152.9667,4
4565,Boreen Point,Queensland,QLD,-26.2861,152.9882,4
4565,Noosa North Shore,Queensland,QLD,-26.3704,153.0404,4
4565,Cooroibah,Queensland,QLD,-26.3632,152.986,4
4565,Tewantin,Queensland,QLD,-26.391,153.0343,4
4565,North Shore,Queensland,QLD,-26.3704,153.0404,4
4566,Noosaville DC,Queensland,QLD,-26.4,153.0667,3
4566,Noosaville,Queensland,QLD,-26.4,153.0667,4
4567,Castaways Beach,Queensland,QLD,-26.4299,153.0979,4
4567,Sunshine Beach,Queensland,QLD,-26.403,153.0963,3
4567,Sunrise Beach,Queensland,QLD,-26.4165,153.103,4
4567,Noosa Heads,Queensland,QLD,-26.3943,153.0901,4
4568,Pinbarren,Queensland,QLD,-26.3333,152.85,4
4568,Federal,Queensland,QLD,-26.3942,152.8076,4
4568,Pomona,Queensland,QLD,-26.3668,152.8557,4
4569,Cooran,Queensland,QLD,-26.3369,152.8225,4
4570,Bollier,Queensland,QLD,-26.4368,152.7333,3
4570,Chatsworth,Queensland,QLD,-26.144,152.615,4
4570,Widgee,Queensland,QLD,-26.2203,152.4079,4
4570,Gympie,Queensland,QLD,-26.1898,152.665,4
4570,Theebine,Queensland,QLD,-25.9505,152.5409,4
4570,Calgoa,Queensland,QLD,-26.1931,152.5564,3
4570,Nahrunda,Queensland,QLD,-26.2026,152.6246,3
4570,Traveston,Queensland,QLD,-26.3167,152.7833,4
4570,Lagoon Pocket,Queensland,QLD,-26.2667,152.6667,4
4570,Veteran,Queensland,QLD,-26.159,152.6898,4
4570,Gunalda,Queensland,QLD,-25.9987,152.562,4
4570,Southside,Queensland,QLD,-26.21,152.6472,4
4570,Tamaree,Queensland,QLD,-26.1346,152.6636,4
4570,Kia Ora,Queensland,QLD,-26.1931,152.5564,3
4570,Langshaw,Queensland,QLD,-26.3,152.5667,4
4570,Imbil,Queensland,QLD,-26.4596,152.6781,4
4570,Dagun,Queensland,QLD,-26.3167,152.6833,4
4570,Banks Pocket,Queensland,QLD,-26.1931,152.5564,3
4570,Kandanga,Queensland,QLD,-26.3927,152.6767,4
4570,East Deep Creek,Queensland,QLD,-26.1976,152.7175,4
4570,Amamoor,Queensland,QLD,-26.3492,152.6793,4
4570,Mooloo,Queensland,QLD,-26.1931,152.5564,3
4570,Wallu,Queensland,QLD,-26.2008,152.6451,3
4570,Victory Heights,Queensland,QLD,-26.1723,152.6895,4
4570,Bells Bridge,Queensland,QLD,-26.1258,152.5387,4
4570,Canina,Queensland,QLD,-26.144,152.7405,3
4570,Pie Creek,Queensland,QLD,-26.2008,152.6451,3
4570,Upper Kandanga,Queensland,QLD,-26.4167,152.5833,4
4570,Woondum,Queensland,QLD,-26.25,152.7333,4
4570,Kanigan,Queensland,QLD,-25.9309,152.5983,3
4570,Gympie DC,Queensland,QLD,-26.2008,152.6451,3
4570,Gilldora,Queensland,QLD,-26.3,152.6667,4
4570,Glanmire,Queensland,QLD,-26.2294,152.6964,4
4570,Glenwood,Queensland,QLD,-26.1931,152.5564,3
4570,Cedar Pocket,Queensland,QLD,-26.2008,152.6451,3
4570,Curra,Queensland,QLD,-26.0667,152.5833,4
4570,Kybong,Queensland,QLD,-26.3167,152.7167,4
4570,Coles Creek,Queensland,QLD,-26.1931,152.5564,3
4570,Toolara Forest,Queensland,QLD,-25.9966,152.8371,4
4570,Paterson,Queensland,QLD,-25.8908,152.5438,4
4570,Monkland,Queensland,QLD,-26.2119,152.6824,4
4570,Fishermans Pocket,Queensland,QLD,-26.1931,152.5564,3
4570,Mothar Mountain,Queensland,QLD,-26.2499,152.7737,4
4570,Widgee Crossing South,Queensland,QLD,-26.1931,152.5564,3
4570,Tandur,Queensland,QLD,-26.2833,152.75,4
4570,Glen Echo,Queensland,QLD,-26.1931,152.5564,3
4570,Goomboorian,Queensland,QLD,-26.081,152.7912,4
4570,Greens Creek,Queensland,QLD,-26.1705,152.7536,4
4570,Wilsons Pocket,Queensland,QLD,-26.1231,152.7999,4
4570,Lake Borumba,Queensland,QLD,-26.1931,152.5564,3
4570,Araluen,Queensland,QLD,-26.1571,152.6522,4
4570,Corella,Queensland,QLD,-26.1,152.6333,4
4570,Jones Hill,Queensland,QLD,-26.2292,152.6688,4
4570,Neusa Vale,Queensland,QLD,-26.1931,152.5564,3
4570,Lower Wonga,Queensland,QLD,-26.2008,152.6451,3
4570,Calico Creek,Queensland,QLD,-26.2008,152.6451,3
4570,Two Mile,Queensland,QLD,-26.1689,152.641,4
4570,Neerdie,Queensland,QLD,-26.2008,152.6451,3
4570,Miva,Queensland,QLD,-25.9606,152.4913,4
4570,Beenaam Valley,Queensland,QLD,-26.1931,152.5564,3
4570,Downsfield,Queensland,QLD,-26.0667,152.7333,4
4570,Bella Creek,Queensland,QLD,-26.1931,152.5564,3
4570,Woolooga,Queensland,QLD,-26.055,152.3951,4
4570,Long Flat,Queensland,QLD,-26.1931,152.5564,3
4570,The Palms,Queensland,QLD,-26.205,152.5865,4
4570,Scrubby Creek,Queensland,QLD,-26.1931,152.5564,3
4570,Wolvi,Queensland,QLD,-26.1667,152.8333,4
4570,Marodian,Queensland,QLD,-26.1931,152.5564,3
4570,Melawondi,Queensland,QLD,-26.4167,152.6667,4
4570,Munna Creek,Queensland,QLD,-26.1931,152.5564,3
4570,Brooloo,Queensland,QLD,-26.5,152.7,4
4570,Tuchekoi,Queensland,QLD,-26.2008,152.6451,3
4570,Sexton,Queensland,QLD,-26,152.4333,4
4570,Kandanga Creek,Queensland,QLD,-26.3878,152.6474,3
4570,Glastonbury,Queensland,QLD,-26.2167,152.5167,4
4570,Anderleigh,Queensland,QLD,-26.1931,152.5564,3
4570,Upper Glastonbury,Queensland,QLD,-26.25,152.5,4
4570,Marys Creek,Queensland,QLD,-26.1931,152.5564,3
4570,The Dawn,Queensland,QLD,-26.2418,152.6792,4
4570,Mcintosh Creek,Queensland,QLD,-26.1931,152.5564,3
4570,Coondoo,Queensland,QLD,-26.1667,152.8833,4
4570,North Deep Creek,Queensland,QLD,-26.1365,152.6811,3
4570,Ross Creek,Queensland,QLD,-26.1931,152.5564,3
4570,Scotchy Pocket,Queensland,QLD,-26.1931,152.5564,3
4570,Widgee Crossing North,Queensland,QLD,-26.1931,152.5564,3
4570,Amamoor Creek,Queensland,QLD,-26.1931,152.5564,3
4570,St Mary,Queensland,QLD,-26.1931,152.5564,3
4571,Kin Kin,Queensland,QLD,-26.2709,152.8773,4
4571,Como,Queensland,QLD,-26.2045,152.9656,4
4572,Alexandra Headland,Queensland,QLD,-26.672,153.1079,4
4573,Mount Coolum,Queensland,QLD,-26.5658,153.0914,4
4573,Peregian Springs,Queensland,QLD,-26.488,153.0758,4
4573,Yaroomba,Queensland,QLD,-26.5513,153.097,4
4573,Coolum Beach,Queensland,QLD,-26.5283,153.0881,4
4573,Point Arkwright,Queensland,QLD,-26.5461,153.1006,4
4573,Marcus Beach,Queensland,QLD,-26.4531,153.1006,4
4573,Peregian Beach,Queensland,QLD,-26.481,153.096,4
4574,Kenilworth,Queensland,QLD,-26.5966,152.7281,4
4574,Moy Pocket,Queensland,QLD,-26.6155,152.7203,3
4574,Kidaman Creek,Queensland,QLD,-26.6312,152.7675,4
4574,Obi Obi,Queensland,QLD,-26.65,152.8,4
4574,Coolabine,Queensland,QLD,-26.5933,152.764,4
4574,Gheerulla,Queensland,QLD,-26.5667,152.7667,4
4575,Bokarina,Queensland,QLD,-26.7384,153.1305,4
4575,Minyama,Queensland,QLD,-26.6957,153.1235,4
4575,Birtinya,Queensland,QLD,-26.7432,153.1191,4
4575,Parrearra,Queensland,QLD,-26.7115,153.122,4
4575,Wurtulla,Queensland,QLD,-26.7565,153.128,4
4575,Warana,Queensland,QLD,-26.7227,153.1269,4
4575,Buddina,Queensland,QLD,-26.6956,153.133,4
4580,Tin Can Bay,Queensland,QLD,-25.9133,153.0058,4
4580,Cooloola Cove,Queensland,QLD,-25.9864,152.9935,4
4580,Cooloola,Queensland,QLD,-25.9864,152.9935,4
4581,Fraser Island,Queensland,QLD,-24.9673,153.3144,4
4581,Inskip,Queensland,QLD,-25.8283,153.0675,4
4581,Eurong,Queensland,QLD,-25.5092,153.125,4
4581,Orchid Beach,Queensland,QLD,-24.9673,153.3144,4
4581,Rainbow Beach,Queensland,QLD,-25.9043,153.0917,4
4600,Oakview,Queensland,QLD,-26.0833,152.3333,4
4600,Kilkivan,Queensland,QLD,-26.0869,152.234,4
4600,Mudlo,Queensland,QLD,-26.1644,152.2093,3
4600,Cinnabar,Queensland,QLD,-26.1224,152.1854,4
4600,Black Snake,Queensland,QLD,-26.1644,152.2093,3
4601,Boonara,Queensland,QLD,-26.0802,152.0546,4
4601,Goomeribong,Queensland,QLD,-26.1146,152.0795,3
4601,Tansey,Queensland,QLD,-26.0467,152.0514,4
4601,Wrattens Forest,Queensland,QLD,-26.1146,152.0795,3
4601,Manyung,Queensland,QLD,-26.2,152.0333,4
4601,Barambah,Queensland,QLD,-26.1146,152.0795,3
4601,Goomeri,Queensland,QLD,-26.1838,152.0687,4
4601,Kinbombi,Queensland,QLD,-26.1981,152.1221,4
4601,Manumbar,Queensland,QLD,-26.4,152.2833,4
4601,Booubyjan,Queensland,QLD,-26.1146,152.0795,3
4605,Manyung,Queensland,QLD,-26.2,152.0333,4
4605,Kitoba,Queensland,QLD,-26.1525,151.893,3
4605,Windera,Queensland,QLD,-26.05,151.8333,4
4605,Warnung,Queensland,QLD,-26.1525,151.893,3
4605,Byee,Queensland,QLD,-26.1833,151.85,4
4605,Redgate,Queensland,QLD,-26.1525,151.893,3
4605,Moffatdale,Queensland,QLD,-26.3079,152.0125,4
4605,Oakdale,Queensland,QLD,-26.1525,151.893,3
4605,Barlil,Queensland,QLD,-26.2,151.8833,4
4605,Cherbourg,Queensland,QLD,-26.2919,151.9521,4
4605,Moondooner,Queensland,QLD,-26.2333,152,4
4605,Crownthorpe,Queensland,QLD,-26.1896,151.9157,3
4605,Cloyna,Queensland,QLD,-26.1112,151.8459,4
4605,Wooroonden,Queensland,QLD,-26.1525,151.893,3
4605,Silverleaf,Queensland,QLD,-26.1525,151.893,3
4605,Murgon,Queensland,QLD,-26.2417,151.9405,4
4605,Cobbs Hill,Queensland,QLD,-26.1525,151.893,3
4605,Glenrock,Queensland,QLD,-26.1525,151.893,3
4605,Merlwood,Queensland,QLD,-26.1525,151.893,3
4605,Sunny Nook,Queensland,QLD,-26.1525,151.893,3
4605,Tablelands,Queensland,QLD,-26.1525,151.893,3
4606,Wondai,Queensland,QLD,-26.3177,151.8724,4
4606,Fairdale,Queensland,QLD,-26.2833,151.7833,4
4606,Wheatlands,Queensland,QLD,-26.2762,151.8292,3
4606,Leafdale,Queensland,QLD,-26.2762,151.8292,3
4606,Mp Creek,Queensland,QLD,-26.2762,151.8292,3
4606,Mount Mceuen,Queensland,QLD,-26.2762,151.8292,3
4606,Chelmsford,Queensland,QLD,-26.2762,151.8292,3
4606,Greenview,Queensland,QLD,-26.3005,151.8279,3
4606,Ficks Crossing,Queensland,QLD,-26.3005,151.8279,3
4608,Tingoora,Queensland,QLD,-26.3667,151.8167,4
4608,Cushnie,Queensland,QLD,-26.3833,151.7611,3
4608,Wooroolin,Queensland,QLD,-26.4167,151.8167,4
4608,Charlestown,Queensland,QLD,-26.3093,151.7816,3
4608,Wilkesdale,Queensland,QLD,-26.3667,151.65,4
4610,Haly Creek,Queensland,QLD,-26.6833,151.75,4
4610,Ballogie,Queensland,QLD,-26.3565,151.5347,3
4610,Mannuem,Queensland,QLD,-26.5796,151.5673,3
4610,Boyneside,Queensland,QLD,-26.7167,151.55,4
4610,Dangore,Queensland,QLD,-26.45,151.5833,4
4610,Booie,Queensland,QLD,-26.5368,151.9418,4
4610,Wattle Grove,Queensland,QLD,-26.5796,151.5673,3
4610,Kumbia,Queensland,QLD,-26.6947,151.6551,4
4610,Coolabunia,Queensland,QLD,-26.6,151.9,4
4610,Memerambi,Queensland,QLD,-26.4623,151.8219,4
4610,Inverlaw,Queensland,QLD,-26.5948,151.7663,3
4610,Durong,Queensland,QLD,-26.3333,151.25,4
4610,Hodgleigh,Queensland,QLD,-26.6333,151.9333,4
4610,Ellesmere,Queensland,QLD,-26.5519,151.7104,3
4610,Durong South,Queensland,QLD,-26.3333,151.25,4
4610,Ironpot,Queensland,QLD,-26.5796,151.5673,3
4610,Goodger,Queensland,QLD,-26.6667,151.8167,4
4610,Benair,Queensland,QLD,-26.5796,151.5673,3
4610,Taabinga,Queensland,QLD,-26.5833,151.8333,4
4610,Corndale,Queensland,QLD,-26.5796,151.5673,3
4610,Crawford,Queensland,QLD,-26.5,151.8167,4
4610,Kingaroy,Queensland,QLD,-26.5399,151.8373,4
4610,Chahpingah,Queensland,QLD,-26.5796,151.5673,3
4610,Gordonbrook,Queensland,QLD,-26.4263,151.6484,3
4610,Alice Creek,Queensland,QLD,-26.5796,151.5673,3
4610,Kingaroy DC,Queensland,QLD,-26.5519,151.7104,3
4611,Marshlands,Queensland,QLD,-26.1833,151.7667,3
4611,Mondure,Queensland,QLD,-26.1837,151.7724,4
4612,Kawl Kawl,Queensland,QLD,-26.1833,151.7333,4
4612,Hivesville,Queensland,QLD,-26.1807,151.6907,4
4612,Wigton,Queensland,QLD,-26.0475,151.624,3
4612,Stonelands,Queensland,QLD,-26.0475,151.624,3
4612,Keysland,Queensland,QLD,-26.0475,151.624,3
4613,Abbeywood,Queensland,QLD,-26.1139,151.6294,4
4613,Stalworth,Queensland,QLD,-26.1951,151.4567,3
4613,Brigooda,Queensland,QLD,-26.2534,151.4158,4
4613,Okeden,Queensland,QLD,-26.2365,151.2929,3
4613,Boondooma,Queensland,QLD,-26.3036,151.262,4
4613,Melrose,Queensland,QLD,-26.2365,151.2929,3
4613,Kinleymore,Queensland,QLD,-26.1833,151.65,4
4613,Coverty,Queensland,QLD,-26.2365,151.2929,3
4613,Proston,Queensland,QLD,-26.1607,151.6004,4
4613,Speedwell,Queensland,QLD,-26.1951,151.4567,3
4614,Yarraman,Queensland,QLD,-26.8417,151.9814,4
4614,Upper Yarraman,Queensland,QLD,-26.8431,151.9858,3
4614,Neumgna,Queensland,QLD,-26.8431,151.9858,3
4615,Wengenville,Queensland,QLD,-26.8333,151.7,4
4615,Glan Devon,Queensland,QLD,-26.6,152.0167,4
4615,South Nanango,Queensland,QLD,-26.75,151.9667,4
4615,Nanango,Queensland,QLD,-26.6716,152.0021,4
4615,Wyalla,Queensland,QLD,-26.6526,151.9716,3
4615,Tarong,Queensland,QLD,-26.7667,151.85,4
4615,Maidenwell,Queensland,QLD,-26.85,151.8,4
4615,Brooklands,Queensland,QLD,-26.7041,151.8997,3
4615,Elgin Vale,Queensland,QLD,-26.7041,151.8997,3
4615,Wattle Camp,Queensland,QLD,-26.4572,151.9623,4
4615,Johnstown,Queensland,QLD,-26.6526,151.9716,3
4615,Bullcamp,Queensland,QLD,-26.6526,151.9716,3
4615,Barker Creek Flat,Queensland,QLD,-26.6526,151.9716,3
4615,East Nanango,Queensland,QLD,-26.6943,152.0413,3
4615,Kunioon,Queensland,QLD,-26.6526,151.9716,3
4615,Pimpimbudgee,Queensland,QLD,-26.6526,151.9716,3
4615,Runnymede,Queensland,QLD,-26.6526,151.9716,3
4615,Sandy Ridges,Queensland,QLD,-26.6526,151.9716,3
4615,South East Nanango,Queensland,QLD,-26.695,152.0264,3
4620,Aramara,Queensland,QLD,-25.6095,152.322,4
4620,Brooweena,Queensland,QLD,-25.6059,152.2611,4
4620,Gungaloon,Queensland,QLD,-25.5188,152.3157,3
4620,Teebar,Queensland,QLD,-25.5188,152.3157,3
4620,Glenbar,Queensland,QLD,-25.5188,152.3157,3
4620,Gigoomgan,Queensland,QLD,-25.5188,152.3157,3
4620,Woocoo,Queensland,QLD,-25.5188,152.3157,3
4620,Malarga,Queensland,QLD,-25.5188,152.3157,3
4620,North Aramara,Queensland,QLD,-25.5188,152.3157,3
4620,Doongul,Queensland,QLD,-25.5188,152.3157,3
4621,Lakeside,Queensland,QLD,-25.5783,152.1251,4
4621,Degilbo,Queensland,QLD,-25.4833,152,4
4621,Coalstoun Lakes,Queensland,QLD,-25.6181,151.8937,4
4621,Woowoonga,Queensland,QLD,-25.632,152.0448,3
4621,Biggenden,Queensland,QLD,-25.5086,152.0448,4
4621,Dallarnil,Queensland,QLD,-25.4,152.05,4
4621,Coringa,Queensland,QLD,-25.632,152.0448,3
4621,Boompa,Queensland,QLD,-25.5911,152.1861,4
4621,Wateranga,Queensland,QLD,-25.632,152.0448,3
4621,Didcot,Queensland,QLD,-25.4756,151.868,4
4621,Golden Fleece,Queensland,QLD,-25.632,152.0448,3
4625,Wetheron,Queensland,QLD,-25.5457,151.7187,4
4625,Toondahra,Queensland,QLD,-25.5553,151.5923,3
4625,Dirnbir,Queensland,QLD,-25.5553,151.5923,3
4625,Bon Accord,Queensland,QLD,-25.5553,151.5923,3
4625,Ideraway,Queensland,QLD,-25.5778,151.6178,4
4625,Mount Steadman,Queensland,QLD,-25.6269,151.7478,3
4625,Yenda,Queensland,QLD,-25.4531,151.659,4
4625,Dundarrah,Queensland,QLD,-25.65,151.8833,4
4625,Byrnestown,Queensland,QLD,-25.5254,151.761,4
4625,Mount Lawless,Queensland,QLD,-25.5395,151.6533,4
4625,Gooroolba,Queensland,QLD,-25.5139,151.8083,4
4625,Stockhaven,Queensland,QLD,-25.5553,151.5923,3
4625,Aranbanga,Queensland,QLD,-25.5553,151.5923,3
4625,Gayndah,Queensland,QLD,-25.6252,151.6114,4
4625,Deep Creek,Queensland,QLD,-26.1976,152.7175,4
4625,Reids Creek,Queensland,QLD,-25.5553,151.5923,3
4625,Wahoon,Queensland,QLD,-25.5553,151.5923,3
4625,Humphery,Queensland,QLD,-25.6033,151.4803,4
4625,Binjour,Queensland,QLD,-25.5151,151.4562,4
4625,Wilson Valley,Queensland,QLD,-25.5553,151.5923,3
4625,Mount Debateable,Queensland,QLD,-25.5553,151.5923,3
4625,Penwhaupell,Queensland,QLD,-25.5553,151.5923,3
4625,Ban Ban,Queensland,QLD,-25.5553,151.5923,3
4625,Blairmore,Queensland,QLD,-25.5553,151.5923,3
4625,Mingo,Queensland,QLD,-25.5553,151.5923,3
4625,Barlyne,Queensland,QLD,-25.5553,151.5923,3
4625,Ginoondan,Queensland,QLD,-25.5553,151.5923,3
4625,Campbell Creek,Queensland,QLD,-25.5553,151.5923,3
4625,Pile Gully,Queensland,QLD,-25.5553,151.5923,3
4625,The Limits,Queensland,QLD,-25.5553,151.5923,3
4625,Woodmillar,Queensland,QLD,-25.5553,151.5923,3
4625,Branch Creek,Queensland,QLD,-25.5553,151.5923,3
4625,Harriet,Queensland,QLD,-25.5553,151.5923,3
4625,Ban Ban Springs,Queensland,QLD,-25.5553,151.5923,3
4626,Riverleigh,Queensland,QLD,-25.5766,151.2001,4
4626,Obil Bil,Queensland,QLD,-25.5193,151.2187,4
4626,Mundubbera,Queensland,QLD,-25.5927,151.3022,4
4626,Brovinia,Queensland,QLD,-25.7889,151.1136,3
4626,Mundowran,Queensland,QLD,-25.5206,151.3438,3
4626,Hawkwood,Queensland,QLD,-25.7761,150.8109,4
4626,Monogorilby,Queensland,QLD,-26.0585,151.0135,4
4626,Glenrae,Queensland,QLD,-25.7889,151.1136,3
4626,O'Bil Bil,Queensland,QLD,-25.5193,151.2187,4
4626,Old Cooranga,Queensland,QLD,-25.7889,151.1136,3
4626,Derri Derra,Queensland,QLD,-25.7889,151.1136,3
4626,Philpott,Queensland,QLD,-25.6,151.3667,4
4626,Coonambula,Queensland,QLD,-25.7889,151.1136,3
4626,Dykehead,Queensland,QLD,-25.7889,151.1136,3
4626,Cattle Creek,Queensland,QLD,-13.1617,143.0534,3
4626,Gurgeena,Queensland,QLD,-25.6463,151.1704,3
4626,Beeron,Queensland,QLD,-25.7889,151.1136,3
4626,Boynewood,Queensland,QLD,-25.633,151.2793,3
4627,Eidsvold,Queensland,QLD,-25.3717,151.1215,4
4627,Ceratodus,Queensland,QLD,-25.2916,151.1395,4
4627,Malmoe,Queensland,QLD,-25.4774,151.2019,4
4627,Wuruma Dam,Queensland,QLD,-25.3644,150.8345,3
4627,Grosvenor,Queensland,QLD,-25.4292,151.1738,4
4627,Abercorn,Queensland,QLD,-25.1347,151.1294,4
4627,Cynthia,Queensland,QLD,-25.2123,151.1287,4
4627,Eidsvold West,Queensland,QLD,-25.3644,150.8345,3
4627,Cheltenham,Queensland,QLD,-25.3644,150.8345,3
4627,Eidsvold East,Queensland,QLD,-25.3644,150.8345,3
4630,Tellebang,Queensland,QLD,-25.0667,151.2333,4
4630,Kalpowar,Queensland,QLD,-24.7,151.3,4
4630,Bancroft,Queensland,QLD,-24.7833,151.2333,4
4630,Harrami,Queensland,QLD,-24.8332,150.9872,3
4630,Monto,Queensland,QLD,-24.8648,151.122,4
4630,Selene,Queensland,QLD,-25.0167,151.15,4
4630,Yarrol,Queensland,QLD,-25.1301,151.3521,3
4630,Cannindah,Queensland,QLD,-24.8332,150.9872,3
4630,Kapaldo,Queensland,QLD,-25.0425,151.1353,4
4630,Cania,Queensland,QLD,-24.6333,150.9667,4
4630,Three Moon,Queensland,QLD,-24.9111,151.1265,4
4630,Ventnor,Queensland,QLD,-24.8332,150.9872,3
4630,Mungungo,Queensland,QLD,-24.7658,151.1633,4
4630,Mulgildie,Queensland,QLD,-24.9605,151.1319,4
4630,Rawbelle,Queensland,QLD,-25.0232,150.8271,4
4630,Bukali,Queensland,QLD,-24.8332,150.9872,3
4630,Coominglah,Queensland,QLD,-24.7532,150.8356,4
4630,Splinter Creek,Queensland,QLD,-24.8332,150.9872,3
4630,Moonford,Queensland,QLD,-24.7667,151.0333,4
4630,Glenleigh,Queensland,QLD,-24.8332,150.9872,3
4630,Langley,Queensland,QLD,-23.45,150.4333,4
4630,Monal,Queensland,QLD,-24.8332,150.9872,3
4630,Dalga,Queensland,QLD,-24.7691,151.0402,3
4630,Coominglah Forest,Queensland,QLD,-24.8332,150.9872,3
4650,Maryborough,Queensland,QLD,-25.5407,152.7049,4
4650,Thinoomba,Queensland,QLD,-25.6025,152.4537,4
4650,Dundathu,Queensland,QLD,-25.4632,152.7174,3
4650,The Dimonds,Queensland,QLD,-25.6657,152.6858,3
4650,Maryborough DC,Queensland,QLD,-25.6401,152.6462,3
4650,Gootchie,Queensland,QLD,-25.8548,152.5544,4
4650,Boonooroo,Queensland,QLD,-25.6729,152.9026,4
4650,Tinana South,Queensland,QLD,-25.5822,152.6513,3
4650,Antigua,Queensland,QLD,-25.6381,152.5989,4
4650,Grahams Creek,Queensland,QLD,-25.5667,152.6,4
4650,Yengarie,Queensland,QLD,-25.5562,152.6082,4
4650,Mount Urah,Queensland,QLD,-25.6657,152.6858,3
4650,Owanyilla,Queensland,QLD,-25.6674,152.6028,4
4650,Maaroom,Queensland,QLD,-25.6112,152.8718,4
4650,Yerra,Queensland,QLD,-25.61,152.5356,4
4650,Tandora,Queensland,QLD,-25.6657,152.6858,3
4650,Bauple,Queensland,QLD,-25.8121,152.6213,4
4650,Pilerwa,Queensland,QLD,-25.6069,152.5653,4
4650,Mungar,Queensland,QLD,-25.6053,152.5923,4
4650,Tinnanbar,Queensland,QLD,-25.6401,152.6462,3
4650,Tuan,Queensland,QLD,-25.6811,152.8735,4
4650,Oakhurst,Queensland,QLD,-25.5125,152.6358,4
4650,Granville,Queensland,QLD,-25.5449,152.7335,4
4650,Mount Steadman,Queensland,QLD,-25.6657,152.6858,3
4650,Tiaro,Queensland,QLD,-25.7261,152.5827,4
4650,Pallas Street Maryborough,Queensland,QLD,-25.6401,152.6462,3
4650,Poona,Queensland,QLD,-25.7154,152.9096,4
4650,Tuan Forest,Queensland,QLD,-25.6657,152.6858,3
4650,Aldershot,Queensland,QLD,-25.4631,152.6635,4
4650,Bidwill,Queensland,QLD,-25.6077,152.7144,4
4650,Netherby,Queensland,QLD,-25.7752,152.5434,4
4650,Talegalla Weir,Queensland,QLD,-25.6657,152.6858,3
4650,Tinana,Queensland,QLD,-25.552,152.6659,4
4650,Prawle,Queensland,QLD,-25.6401,152.6462,3
4650,Maryborough West,Queensland,QLD,-25.6401,152.6462,3
4650,Boonooroo Plains,Queensland,QLD,-25.6657,152.6858,3
4650,Bauple Forest,Queensland,QLD,-25.878,152.6551,3
4650,Pioneers Rest,Queensland,QLD,-25.6657,152.6858,3
4650,St Helens,Queensland,QLD,-25.6657,152.6858,3
4650,Magnolia,Queensland,QLD,-25.6401,152.6462,3
4650,Dunmora,Queensland,QLD,-25.6657,152.6858,3
4650,Ferney,Queensland,QLD,-25.6657,152.6858,3
4650,Gundiah,Queensland,QLD,-25.8335,152.5405,4
4650,St Mary,Queensland,QLD,-25.6401,152.6462,3
4650,Duckinwilla,Queensland,QLD,-25.4126,152.4375,3
4650,Walkers Point,Queensland,QLD,-25.6657,152.6858,3
4650,Glenorchy,Queensland,QLD,-25.6657,152.6858,3
4650,Teddington,Queensland,QLD,-25.6657,152.6858,3
4650,Beaver Rock,Queensland,QLD,-25.6657,152.6858,3
4650,Island Plantation,Queensland,QLD,-25.6401,152.6462,3
4655,River Heads,Queensland,QLD,-25.4155,152.9131,4
4655,Hervey Bay,Queensland,QLD,-25.2894,152.8288,4
4655,Kingfisher Bay,Queensland,QLD,-25.3914,153.0294,4
4655,Walliebum,Queensland,QLD,-25.3305,152.8062,3
4655,Wondunna,Queensland,QLD,-25.3176,152.8591,4
4655,Sunshine Acres,Queensland,QLD,-25.3581,152.7877,4
4655,Scarness,Queensland,QLD,-25.2841,152.856,4
4655,Susan River,Queensland,QLD,-25.3305,152.8062,3
4655,Booral,Queensland,QLD,-25.3605,152.9081,4
4655,Toogoom,Queensland,QLD,-25.2564,152.6902,4
4655,Nikenbah,Queensland,QLD,-25.3194,152.8041,4
4655,Hervey Bay Dc,Queensland,QLD,-25.3305,152.8062,3
4655,Kawungan,Queensland,QLD,-25.3,152.8333,4
4655,Kingfisher Bay Resort,Queensland,QLD,-25.3074,152.8123,3
4655,Pialba,Queensland,QLD,-25.287,152.8437,4
4655,Great Sandy Strait,Queensland,QLD,-25.3305,152.8062,3
4655,Craignish,Queensland,QLD,-25.2826,152.7243,4
4655,Happy Valley,Queensland,QLD,-25.3376,153.2005,4
4655,Urangan,Queensland,QLD,-25.2921,152.9053,4
4655,Bunya Creek,Queensland,QLD,-25.3305,152.8062,3
4655,Torquay,Queensland,QLD,-25.285,152.8789,4
4655,Walligan,Queensland,QLD,-25.3471,152.7599,4
4655,Eli Waters,Queensland,QLD,-25.2865,152.815,4
4655,Point Vernon,Queensland,QLD,-25.2663,152.8257,4
4655,Dundowran,Queensland,QLD,-25.2891,152.7646,4
4655,Takura,Queensland,QLD,-25.3571,152.7482,4
4655,Urraween,Queensland,QLD,-25.2879,152.8345,4
4655,Dundowran Beach,Queensland,QLD,-25.2756,152.7676,4
4659,Beelbi Creek,Queensland,QLD,-25.3432,152.5402,3
4659,Pacific Haven,Queensland,QLD,-25.2658,152.5521,4
4659,Burrum Heads,Queensland,QLD,-25.1846,152.6126,4
4659,Burgowan,Queensland,QLD,-25.3432,152.5402,3
4659,Howard,Queensland,QLD,-25.3193,152.5599,4
4659,Burrum Town,Queensland,QLD,-25.3432,152.5402,3
4659,Burrum River,Queensland,QLD,-25.2734,152.5807,3
4660,Woodgate,Queensland,QLD,-25.1083,152.5625,4
4660,Apple Tree Creek,Queensland,QLD,-25.2167,152.2333,4
4660,Cordalba,Queensland,QLD,-25.1606,152.2119,4
4660,Abington,Queensland,QLD,-25.2112,152.3091,3
4660,North Isis,Queensland,QLD,-25.177,152.2643,4
4660,Doolbi,Queensland,QLD,-25.2333,152.3,4
4660,Redridge,Queensland,QLD,-25.1699,152.3613,4
4660,Goodwood,Queensland,QLD,-25.1457,152.4103,4
4660,Buxton,Queensland,QLD,-25.1989,152.5422,4
4660,Horton,Queensland,QLD,-25.2333,152.3167,4
4660,Childers,Queensland,QLD,-25.2371,152.2788,4
4660,Cherwell,Queensland,QLD,-25.2112,152.3091,3
4660,South Isis,Queensland,QLD,-25.1878,152.3466,3
4660,Gregory River,Queensland,QLD,-25.2112,152.3091,3
4660,Eureka,Queensland,QLD,-25.2112,152.3091,3
4660,Isis Central,Queensland,QLD,-25.1946,152.1876,3
4660,Kullogum,Queensland,QLD,-25.328,152.2665,3
4660,Farnsfield,Queensland,QLD,-25.1878,152.3466,3
4660,Promisedland,Queensland,QLD,-25.2112,152.3091,3
4660,Isis River,Queensland,QLD,-25.2112,152.3091,3
4660,North Gregory,Queensland,QLD,-25.2112,152.3091,3
4662,Torbanlea,Queensland,QLD,-25.3453,152.5938,4
4670,Bundaberg North,Queensland,QLD,-25.177,152.2643,4
4670,Kepnock,Queensland,QLD,-24.8761,152.3752,4
4670,Moore Park Beach,Queensland,QLD,-24.7204,152.2638,3
4670,Bundaberg,Queensland,QLD,-24.8662,152.3479,4
4670,Gooburrum,Queensland,QLD,-24.85,152.3167,4
4670,Bucca,Queensland,QLD,-24.8667,152.1,4
4670,Bundaberg Dc,Queensland,QLD,-24.8542,152.2352,3
4670,Moorland,Queensland,QLD,-24.7513,152.1886,4
4670,Elliott,Queensland,QLD,-25.0063,152.2758,4
4670,Rubyanna,Queensland,QLD,-24.8158,152.3916,4
4670,Svensson Heights,Queensland,QLD,-24.8863,152.3357,3
4670,Kalkie,Queensland,QLD,-24.8443,152.3853,4
4670,Bargara,Queensland,QLD,-24.8148,152.4626,4
4670,Watalgan,Queensland,QLD,-24.65,152.0333,4
4670,Mullett Creek,Queensland,QLD,-24.8542,152.2352,3
4670,Fairymead,Queensland,QLD,-24.7833,152.3667,4
4670,Avenell Heights,Queensland,QLD,-24.8896,152.3679,4
4670,Walkervale,Queensland,QLD,-24.8827,152.3589,4
4670,Bundaberg South,Queensland,QLD,-24.8739,152.3626,3
4670,Avoca,Queensland,QLD,-24.8782,152.3079,4
4670,Calavos,Queensland,QLD,-24.9364,152.4347,4
4670,Oakwood,Queensland,QLD,-24.8667,152.3,4
4670,Burnett Heads,Queensland,QLD,-24.7647,152.4127,4
4670,Millbank,Queensland,QLD,-24.8754,152.3222,4
4670,Meadowvale,Queensland,QLD,-24.8161,152.2771,4
4670,Elliott Heads,Queensland,QLD,-24.9162,152.4899,4
4670,Welcome Creek,Queensland,QLD,-24.7645,152.276,3
4670,Sharon,Queensland,QLD,-24.8759,152.2699,4
4670,Avondale,Queensland,QLD,-24.7383,152.1582,4
4670,Thabeban,Queensland,QLD,-24.9,152.35,4
4670,Winfield,Queensland,QLD,-24.8801,152.3305,3
4670,South Kolan,Queensland,QLD,-24.932,152.1679,4
4670,Norville,Queensland,QLD,-24.8931,152.3409,4
4670,Kensington,Queensland,QLD,-24.9171,152.3182,4
4670,Abbotsford,Queensland,QLD,-24.8542,152.2352,3
4670,Kinkuna,Queensland,QLD,-25.0303,152.3933,4
4670,Alloway,Queensland,QLD,-24.9568,152.3598,4
4670,Qunaba,Queensland,QLD,-24.831,152.4279,3
4670,Branyan,Queensland,QLD,-24.9135,152.2725,4
4670,Woongarra,Queensland,QLD,-24.8801,152.3305,3
4670,Innes Park,Queensland,QLD,-24.8683,152.4799,4
4670,Bundaberg West,Queensland,QLD,-24.8711,152.3355,3
4670,Coonarr,Queensland,QLD,-24.9699,152.4723,4
4670,Bundaberg Central,Queensland,QLD,-24.9125,152.3672,3
4670,South Bingera,Queensland,QLD,-24.9673,152.223,4
4670,Bundaberg East,Queensland,QLD,-24.8534,152.3801,3
4670,Ashfield,Queensland,QLD,-24.8726,152.3954,4
4670,Electra,Queensland,QLD,-24.9753,152.1163,4
4670,Mon Repos,Queensland,QLD,-24.8801,152.3305,3
4670,Givelda,Queensland,QLD,-24.9908,152.1516,4
4670,Windermere,Queensland,QLD,-24.85,152.45,4
4670,Pine Creek,Queensland,QLD,-25.011,152.1697,4
4670,Coral Cove,Queensland,QLD,-24.8831,152.4827,4
4671,Mount Perry,Queensland,QLD,-25.1792,151.6471,4
4671,Redhill Farms,Queensland,QLD,-25.0574,151.6613,3
4671,Bungadoo,Queensland,QLD,-24.9833,152.0709,4
4671,Monduran,Queensland,QLD,-25.0574,151.6613,3
4671,Wallaville,Queensland,QLD,-25.0765,151.9952,4
4671,Skyring Reserve,Queensland,QLD,-25.0574,151.6613,3
4671,Booyal,Queensland,QLD,-25.2164,152.0399,4
4671,Molangul,Queensland,QLD,-24.75,151.55,4
4671,Boolboonda,Queensland,QLD,-25.0833,151.6833,4
4671,Doughboy,Queensland,QLD,-25.0574,151.6613,3
4671,Gin Gin,Queensland,QLD,-24.9936,151.9581,4
4671,Drinan,Queensland,QLD,-25.05,152.0167,4
4671,Tirroan,Queensland,QLD,-25.0167,151.9333,4
4671,Duingal,Queensland,QLD,-25.0574,151.6613,3
4671,Dalysford,Queensland,QLD,-25.0167,151.8833,4
4671,Maroondan,Queensland,QLD,-24.9667,152.0167,4
4671,Delan,Queensland,QLD,-25.0171,152.0505,4
4671,Good Night,Queensland,QLD,-25.0574,151.6613,3
4671,Wonbah,Queensland,QLD,-25.1,151.65,4
4671,Lake Monduran,Queensland,QLD,-25.0574,151.6613,3
4671,Kolonga,Queensland,QLD,-25.0574,151.6613,3
4671,Mungy,Queensland,QLD,-25.0574,151.6613,3
4671,St Agnes,Queensland,QLD,-25.0574,151.6613,3
4671,Gaeta,Queensland,QLD,-24.8333,151.6,4
4671,Moolboolaman,Queensland,QLD,-25.0167,151.8167,4
4671,Bullyard,Queensland,QLD,-24.95,152.0667,4
4671,Damascus,Queensland,QLD,-25.0574,151.6613,3
4671,Mcilwraith,Queensland,QLD,-24.9833,152,4
4671,Morganville,Queensland,QLD,-25.1731,151.9704,3
4671,Horse Camp,Queensland,QLD,-25.066,151.8924,3
4671,Wonbah Forest,Queensland,QLD,-25.0574,151.6613,3
4671,New Moonta,Queensland,QLD,-25.0574,151.6613,3
4671,Nearum,Queensland,QLD,-25.0574,151.6613,3
4671,Takilberan,Queensland,QLD,-25.0574,151.6613,3
4671,St Kilda,Queensland,QLD,-25.0574,151.6613,3
4673,Yandaran,Queensland,QLD,-24.7217,152.114,4
4673,Waterloo,Queensland,QLD,-24.7241,152.0437,3
4673,Littabella,Queensland,QLD,-24.6857,152.0781,4
4673,Miara,Queensland,QLD,-24.7241,152.0437,3
4674,Berajondo,Queensland,QLD,-24.6171,151.8441,4
4674,Euleilah,Queensland,QLD,-24.45,151.8667,4
4674,Oyster Creek,Queensland,QLD,-24.6184,151.8492,3
4674,Baffle Creek,Queensland,QLD,-24.5,151.9333,4
4674,Deepwater,Queensland,QLD,-24.6184,151.8492,3
4674,Taunton,Queensland,QLD,-24.4205,151.8218,4
4674,Rules Beach,Queensland,QLD,-24.6184,151.8492,3
4674,Mount Maria,Queensland,QLD,-24.6184,151.8492,3
4674,Rosedale,Queensland,QLD,-24.629,151.9189,4
4676,Gindoran,Queensland,QLD,-24.6263,151.5919,3
4676,Lowmead,Queensland,QLD,-24.5295,151.7532,4
4677,Miriam Vale,Queensland,QLD,-24.3321,151.5629,4
4677,Eurimbula,Queensland,QLD,-24.1047,151.7138,3
4677,Colosseum,Queensland,QLD,-24.3902,151.6121,4
4677,Agnes Water,Queensland,QLD,-24.2119,151.9035,4
4677,Seventeen Seventy,Queensland,QLD,-24.1687,151.8839,4
4677,Mount Tom,Queensland,QLD,-24.364,151.6458,3
4677,Round Hill,Queensland,QLD,-24.364,151.6458,3
4677,Captain Creek,Queensland,QLD,-24.364,151.6458,3
4678,Rodds Bay,Queensland,QLD,-24.0633,151.3874,4
4678,Foreshores,Queensland,QLD,-24.1383,151.5912,3
4678,Turkey Beach,Queensland,QLD,-24.1942,151.5456,3
4678,Bororen,Queensland,QLD,-24.2406,151.496,4
4680,Glen Eden,Queensland,QLD,-23.8974,151.2713,4
4680,Gladstone,Queensland,QLD,-23.8485,151.2577,4
4680,Beecher,Queensland,QLD,-23.9,151.2,4
4680,Callemondah,Queensland,QLD,-23.8493,151.2312,3
4680,Byellee,Queensland,QLD,-23.874,151.1946,4
4680,Tannum Sands,Queensland,QLD,-23.9475,151.3675,4
4680,Heron Island,Queensland,QLD,-23.4418,151.9125,4
4680,Burua,Queensland,QLD,-23.95,151.2167,4
4680,Benaraby,Queensland,QLD,-24.0042,151.3341,4
4680,Boyne Island,Queensland,QLD,-23.9483,151.3534,4
4680,Kirkwood,Queensland,QLD,-23.9033,151.2354,4
4680,South End,Queensland,QLD,-23.9766,151.2768,3
4680,Kin Kora,Queensland,QLD,-23.877,151.2472,4
4680,Gladstone BC,Queensland,QLD,-23.9766,151.2768,3
4680,West Stowe,Queensland,QLD,-23.9123,151.1302,4
4680,Telina,Queensland,QLD,-23.8883,151.2538,4
4680,Wooderson,Queensland,QLD,-24.1333,151.1365,3
4680,Builyan,Queensland,QLD,-24.525,151.3848,4
4680,Gladstone Central,Queensland,QLD,-23.9766,151.2768,3
4680,Boynedale,Queensland,QLD,-24.2144,151.245,4
4680,Barney Point,Queensland,QLD,-23.8568,151.2713,3
4680,Iveragh,Queensland,QLD,-24.1333,151.45,4
4680,River Ranch,Queensland,QLD,-23.982,151.1583,4
4680,South Gladstone,Queensland,QLD,-23.869,151.2783,4
4680,Boyne Valley,Queensland,QLD,-24.3873,151.1936,3
4680,Wurdong Heights,Queensland,QLD,-23.9766,151.2768,3
4680,Tablelands,Queensland,QLD,-24.1333,151.1365,3
4680,Clinton,Queensland,QLD,-23.8752,151.2179,4
4680,O'Connell,Queensland,QLD,-24.1333,151.1365,3
4680,West Gladstone,Queensland,QLD,-23.9123,151.1302,4
4680,Taragoola,Queensland,QLD,-24.1003,151.2196,4
4680,Gladstone DC,Queensland,QLD,-23.9766,151.2768,3
4680,South Trees,Queensland,QLD,-23.869,151.2783,4
4680,New Auckland,Queensland,QLD,-23.8836,151.2356,4
4680,Toolooa,Queensland,QLD,-23.9333,151.2833,4
4680,Diglum,Queensland,QLD,-24.1333,151.1365,3
4680,Calliope,Queensland,QLD,-24.007,151.2003,4
4680,Sun Valley,Queensland,QLD,-23.8752,151.2575,4
4680,Curtis Island,Queensland,QLD,-23.9766,151.2768,3
4680,Gladstone Harbour,Queensland,QLD,-23.9766,151.2768,3
4680,Ubobo,Queensland,QLD,-24.4046,151.3234,4
4680,Mount Alma,Queensland,QLD,-24.0312,150.8779,4
4694,Targinnie,Queensland,QLD,-23.8,151.1,4
4694,Aldoga,Queensland,QLD,-23.7602,151.0899,3
4694,Targinie,Queensland,QLD,-23.8,151.1,4
4694,Yarwun,Queensland,QLD,-23.85,151.1167,4
4695,Bracewell,Queensland,QLD,-23.9,150.9,4
4695,The Narrows,Queensland,QLD,-23.7923,150.8651,3
4695,Ambrose,Queensland,QLD,-23.8,150.9333,4
4695,Darts Creek,Queensland,QLD,-23.7923,150.8651,3
4695,Mount Larcom,Queensland,QLD,-23.8121,150.9789,4
4695,Machine Creek,Queensland,QLD,-23.7923,150.8651,3
4695,East End,Queensland,QLD,-23.7923,150.8651,3
4697,Raglan,Queensland,QLD,-23.7167,150.8167,4
4699,Port Alma,Queensland,QLD,-23.5831,150.8608,4
4699,Bajool,Queensland,QLD,-23.6667,150.65,4
4700,Fairy Bower,Queensland,QLD,-23.4053,150.4654,4
4700,Rockhampton City,Queensland,QLD,-23.3772,150.5099,3
4700,Wandal,Queensland,QLD,-23.3688,150.4938,4
4700,West Rockhampton,Queensland,QLD,-23.3794,150.4818,4
4700,Great Keppel Island,Queensland,QLD,-23.2698,150.718,3
4700,The Range,Queensland,QLD,-23.392,150.4962,4
4700,Rockhampton Hospital,Queensland,QLD,-23.3636,150.559,3
4700,Rockhampton,Queensland,QLD,-23.3803,150.506,4
4700,The Keppels,Queensland,QLD,-23.0695,150.8913,4
4700,Allenstown,Queensland,QLD,-23.394,150.5039,4
4700,Depot Hill,Queensland,QLD,-23.3902,150.521,4
4700,Port Curtis,Queensland,QLD,-23.4117,150.5098,4
4701,Nerimbera,Queensland,QLD,-23.4,150.5833,4
4701,Central Queensland University,Queensland,QLD,-23.3815,150.6125,3
4701,Koongal,Queensland,QLD,-23.3689,150.5492,4
4701,Nankin,Queensland,QLD,-23.3833,150.6333,4
4701,Red Hill Rockhampton,Queensland,QLD,-23.3656,150.5483,3
4701,The Common,Queensland,QLD,-23.3815,150.5334,4
4701,Rockyview,Queensland,QLD,-23.2364,150.5196,3
4701,Kawana,Queensland,QLD,-23.3393,150.5049,4
4701,North Rockhampton,Queensland,QLD,-23.3815,150.6125,3
4701,Berserker,Queensland,QLD,-23.3582,150.5204,4
4701,Sandringham,Queensland,QLD,-23.3815,150.6125,3
4701,Lakes Creek,Queensland,QLD,-23.3833,150.5667,4
4701,Ironpot,Queensland,QLD,-23.3656,150.5483,3
4701,Frenchville,Queensland,QLD,-23.3476,150.5445,4
4701,Mount Archer,Queensland,QLD,-23.3815,150.6125,3
4701,Park Avenue,Queensland,QLD,-23.3533,150.5189,4
4701,Greenlake,Queensland,QLD,-23.3815,150.6125,3
4701,Norman Gardens,Queensland,QLD,-23.3304,150.5275,4
4701,Limestone Creek,Queensland,QLD,-23.2868,150.5677,3
4701,Rockhampton DC,Queensland,QLD,-23.3656,150.5483,3
4702,Rolleston,Queensland,QLD,-24.4645,148.6226,4
4702,Westwood,Queensland,QLD,-23.5969,150.1675,4
4702,Smoky Creek,Queensland,QLD,-24.1376,150.3786,3
4702,Yaraka,Queensland,QLD,-24.8842,144.08,4
4702,Cawarral,Queensland,QLD,-23.2667,150.6667,4
4702,South Yaamba,Queensland,QLD,-23.8022,148.7101,3
4702,Milman,Queensland,QLD,-23.1333,150.4,4
4702,Garnant,Queensland,QLD,-23.8022,148.7101,3
4702,Stanwell,Queensland,QLD,-23.4833,150.3167,4
4702,Anakie,Queensland,QLD,-23.55,147.75,4
4702,Morinish,Queensland,QLD,-23.25,150.15,4
4702,Goomally,Queensland,QLD,-23.8022,148.7101,3
4702,Alton Downs,Queensland,QLD,-23.3,150.35,4
4702,Plum Tree,Queensland,QLD,-23.5089,150.1078,3
4702,Banana,Queensland,QLD,-24.4688,150.1349,3
4702,Mimosa,Queensland,QLD,-23.8022,148.7101,3
4702,Willows,Queensland,QLD,-23.7436,147.5425,4
4702,Willows Gemfields,Queensland,QLD,-23.7436,147.5425,4
4702,Kunwarara,Queensland,QLD,-22.9167,150.1333,4
4702,Joskeleigh,Queensland,QLD,-23.3801,150.7786,4
4702,Bluff,Queensland,QLD,-23.5805,149.0685,4
4702,Rossmoya,Queensland,QLD,-23.0333,150.4833,4
4702,Gracemere,Queensland,QLD,-23.4379,150.4575,4
4702,Canal Creek,Queensland,QLD,-23.8022,148.7101,3
4702,Jambin,Queensland,QLD,-24.1948,150.3706,4
4702,Canoona,Queensland,QLD,-23.0833,150.2667,4
4702,Goovigen,Queensland,QLD,-24.1459,150.2853,4
4702,Dumpy Creek,Queensland,QLD,-23.8022,148.7101,3
4702,Coowonga,Queensland,QLD,-23.2833,150.7167,4
4702,Comet,Queensland,QLD,-23.6,148.55,4
4702,Mount Chalmers,Queensland,QLD,-23.3,150.65,4
4702,Gainsford,Queensland,QLD,-23.8022,148.7101,3
4702,Gindie,Queensland,QLD,-23.7333,148.1333,4
4702,Dalma,Queensland,QLD,-23.35,150.25,4
4702,Pink Lily,Queensland,QLD,-23.35,150.4667,4
4702,Stanage,Queensland,QLD,-22.1613,150.0676,4
4702,The Caves,Queensland,QLD,-23.1833,150.4667,4
4702,Dululu,Queensland,QLD,-23.85,150.25,4
4702,Bushley,Queensland,QLD,-23.5333,150.25,4
4702,Jardine,Queensland,QLD,-23.8022,148.7101,3
4702,Keppel Sands,Queensland,QLD,-23.3259,150.7873,4
4702,Kalapa,Queensland,QLD,-23.5167,150.2667,4
4702,Argoon,Queensland,QLD,-24.2527,150.4359,4
4702,Kokotungo,Queensland,QLD,-23.8022,148.7101,3
4702,Wowan,Queensland,QLD,-23.9167,150.1833,4
4702,Shoalwater,Queensland,QLD,-23.8022,148.7101,3
4702,Tungamull,Queensland,QLD,-23.3167,150.6833,4
4702,Woolein,Queensland,QLD,-23.8022,148.7101,3
4702,Dingo,Queensland,QLD,-23.65,149.3333,4
4702,Rubyvale,Queensland,QLD,-23.4182,147.6991,4
4702,Etna Creek,Queensland,QLD,-23.2167,150.4667,4
4702,Bingegang,Queensland,QLD,-23.8022,148.7101,3
4702,Baralaba,Queensland,QLD,-24.1745,149.8156,4
4702,Alsace,Queensland,QLD,-23.2806,149.3524,4
4702,Ulogie,Queensland,QLD,-23.8022,148.7101,3
4702,Alberta,Queensland,QLD,-23.8022,148.7101,3
4702,Coorumbene,Queensland,QLD,-23.5089,150.1078,3
4702,Marmor,Queensland,QLD,-23.6833,150.7167,4
4702,Blackdown,Queensland,QLD,-23.8022,148.7101,3
4702,Coorooman,Queensland,QLD,-23.2667,150.7333,4
4702,Wooroona,Queensland,QLD,-23.8022,148.7101,3
4702,Wycarbah,Queensland,QLD,-23.55,150.2167,4
4702,Mackenzie,Queensland,QLD,-23.8022,148.7101,3
4702,Wallaroo,Queensland,QLD,-23.7,149.5667,4
4702,Kabra,Queensland,QLD,-23.4667,150.4,4
4702,Boolburra,Queensland,QLD,-23.7333,149.7667,4
4702,Yalleroi,Queensland,QLD,-24.0682,145.7578,4
4702,Bouldercombe,Queensland,QLD,-23.5705,150.4695,4
4702,Thompson Point,Queensland,QLD,-23.8022,148.7101,3
4702,Dixalea,Queensland,QLD,-23.9333,150.2833,4
4702,Jellinbah,Queensland,QLD,-23.8022,148.7101,3
4702,Goowarra,Queensland,QLD,-23.6667,149.4167,4
4702,Stewarton,Queensland,QLD,-23.8022,148.7101,3
4702,Midgee,Queensland,QLD,-23.5167,150.55,4
4702,Lowesby,Queensland,QLD,-23.5089,150.1078,3
4702,Sapphire,Queensland,QLD,-23.4573,147.7244,4
4702,Central Queensland Mc,Queensland,QLD,-23.8022,148.7101,3
4702,Gogango,Queensland,QLD,-23.6667,150.0333,4
4702,Balcomba,Queensland,QLD,-23.8022,148.7101,3
4702,Parkhurst,Queensland,QLD,-23.3,150.5167,4
4702,Barnard,Queensland,QLD,-23.8022,148.7101,3
4702,Nine Mile,Queensland,QLD,-23.8022,148.7101,3
4702,Ridgelands,Queensland,QLD,-23.25,150.2833,4
4702,Consuelo,Queensland,QLD,-23.5089,150.1078,3
4702,Glenroy,Queensland,QLD,-28.7953,151.9335,3
4702,Coomoo,Queensland,QLD,-23.8022,148.7101,3
4702,Morinish South,Queensland,QLD,-23.8022,148.7101,3
4702,Pheasant Creek,Queensland,QLD,-23.8022,148.7101,3
4703,Byfield,Queensland,QLD,-22.8476,150.6502,4
4703,Bungundarra,Queensland,QLD,-23.049,150.6384,4
4703,Adelaide Park,Queensland,QLD,-23.0968,150.6957,4
4703,Hidden Valley,Queensland,QLD,-22.9308,150.6842,3
4703,Yeppoon,Queensland,QLD,-23.1268,150.7441,4
4703,Maryvale,Queensland,QLD,-28.0687,152.2436,4
4703,Bangalee,Queensland,QLD,-23.075,150.7622,4
4703,Lake Mary,Queensland,QLD,-23.1144,150.585,3
4703,Mulambin,Queensland,QLD,-23.1876,150.7893,4
4703,Causeway Lake,Queensland,QLD,-23.1978,150.7876,4
4703,Inverness,Queensland,QLD,-23.1158,150.7231,4
4703,Mulara,Queensland,QLD,-22.9308,150.6842,3
4703,Cooee Bay,Queensland,QLD,-23.1443,150.7611,4
4703,Stockyard,Queensland,QLD,-22.8801,150.7321,4
4703,Tanby,Queensland,QLD,-23.2333,150.75,4
4703,Cobraball,Queensland,QLD,-22.9308,150.6842,3
4703,Barmoya,Queensland,QLD,-23.1465,150.5467,4
4703,Farnborough,Queensland,QLD,-23.0912,150.7415,4
4703,Taranganba,Queensland,QLD,-23.1443,150.7514,4
4703,Taroomball,Queensland,QLD,-23.1727,150.7422,3
4703,Bondoola,Queensland,QLD,-23.1667,150.6833,4
4703,Kinka Beach,Queensland,QLD,-23.2247,150.7968,4
4703,Weerriba,Queensland,QLD,-22.9308,150.6842,3
4703,Rosslyn,Queensland,QLD,-23.1661,150.7783,4
4703,Meikleville Hill,Queensland,QLD,-23.1131,150.7447,4
4703,Pacific Heights,Queensland,QLD,-23.0981,150.7386,4
4703,Barlows Hill,Queensland,QLD,-23.1076,150.7451,4
4703,Lammermoor,Queensland,QLD,-23.1608,150.7608,4
4703,Barmaryee,Queensland,QLD,-23.1333,150.7167,4
4703,Woodbury,Queensland,QLD,-22.9788,150.7052,4
4704,Wattlebank,Queensland,QLD,-23.1333,150.3667,3
4704,Yaamba,Queensland,QLD,-23.1333,150.3667,4
4705,Mackenzie River,Queensland,QLD,-22.9527,148.9351,3
4705,Lotus Creek,Queensland,QLD,-22.5566,149.6096,3
4705,Marlborough,Queensland,QLD,-22.8167,149.8833,4
4705,Mount Gardiner,Queensland,QLD,-22.5566,149.6096,3
4705,Clarke Creek,Queensland,QLD,-22.5566,149.6096,3
4706,Ogmore,Queensland,QLD,-22.6167,149.65,4
4707,St Lawrence,Queensland,QLD,-22.3456,149.5353,4
4707,The Percy Group,Queensland,QLD,-22.6123,149.2367,3
4707,Collaroy,Queensland,QLD,-22.6123,149.2367,3
4709,Tieri,Queensland,QLD,-23.0383,148.3446,4
4710,Emu Park,Queensland,QLD,-23.2568,150.8268,4
4710,Zilzie,Queensland,QLD,-23.273,150.8183,4
4711,Glendale,Queensland,QLD,-23.2569,150.4849,1
4711,Glenlee,Queensland,QLD,-23.2569,150.4849,1
4712,Duaringa,Queensland,QLD,-23.7158,149.6701,4
4713,Woorabinda,Queensland,QLD,-24.1306,149.4566,4
4714,Hamilton Creek,Queensland,QLD,-23.6989,150.3337,3
4714,Baree,Queensland,QLD,-23.6167,150.4,4
4714,Wura,Queensland,QLD,-23.8167,150.3333,4
4714,Walterhall,Queensland,QLD,-23.6333,150.4,4
4714,Struck Oil,Queensland,QLD,-23.6714,150.3843,3
4714,Mount Morgan,Queensland,QLD,-23.6453,150.389,4
4714,Trotter Creek,Queensland,QLD,-23.6989,150.3337,3
4714,Moongan,Queensland,QLD,-23.6,150.4,4
4714,Nine Mile Creek,Queensland,QLD,-23.6989,150.3337,3
4714,Horse Creek,Queensland,QLD,-23.6989,150.3337,3
4714,Leydens Hill,Queensland,QLD,-23.6989,150.3337,3
4714,Fletcher Creek,Queensland,QLD,-23.6989,150.3337,3
4714,Walmul,Queensland,QLD,-23.7167,150.3833,4
4714,Boulder Creek,Queensland,QLD,-23.6989,150.3337,3
4714,The Mine,Queensland,QLD,-23.6296,150.3747,3
4714,Limestone,Queensland,QLD,-23.6989,150.3337,3
4714,Oakey Creek,Queensland,QLD,-23.6989,150.3337,3
4714,Johnsons Hill,Queensland,QLD,-23.6989,150.3337,3
4715,Biloela,Queensland,QLD,-24.3959,150.515,4
4715,Tarramba,Queensland,QLD,-24.3982,150.5467,3
4715,Prospect,Queensland,QLD,-24.5117,150.4189,4
4715,Greycliffe,Queensland,QLD,-24.3982,150.5467,3
4715,Valentine Plains,Queensland,QLD,-24.4167,150.5833,4
4715,Mount Murchison,Queensland,QLD,-24.412,150.5031,3
4715,Callide,Queensland,QLD,-24.3004,150.4656,4
4715,Dumgree,Queensland,QLD,-24.3982,150.5467,3
4715,Dakenba,Queensland,QLD,-24.3485,150.4976,4
4715,Castle Creek,Queensland,QLD,-24.3982,150.5467,3
4715,Orange Creek,Queensland,QLD,-24.3582,150.3213,3
4716,Thangool,Queensland,QLD,-24.4863,150.5736,4
4716,Lawgi Dawes,Queensland,QLD,-24.6038,150.781,4
4717,Blackwater,Queensland,QLD,-23.5835,148.8791,4
4718,Roundstone,Queensland,QLD,-24.4746,149.4083,3
4718,Kianga,Queensland,QLD,-24.6936,150.0128,4
4718,Bauhinia,Queensland,QLD,-24.4746,149.4083,3
4718,Moura,Queensland,QLD,-24.5722,149.9673,4
4718,Mungabunda,Queensland,QLD,-24.4746,149.4083,3
4718,Oombabeer,Queensland,QLD,-24.4746,149.4083,3
4718,Dromedary,Queensland,QLD,-24.4746,149.4083,3
4718,Rhydding,Queensland,QLD,-24.4746,149.4083,3
4718,Warnoah,Queensland,QLD,-24.4746,149.4083,3
4719,Theodore,Queensland,QLD,-24.946,150.0742,4
4719,Glenmoral,Queensland,QLD,-24.9541,150.1128,3
4719,Camboon,Queensland,QLD,-25.0306,150.4376,4
4719,Lonesome Creek,Queensland,QLD,-24.9541,150.1128,3
4719,Isla,Queensland,QLD,-25.1307,150.0515,3
4719,Cracow,Queensland,QLD,-25.2989,150.309,4
4720,Emerald,Queensland,QLD,-23.523,148.1578,4
4720,Yamala,Queensland,QLD,-23.5833,148.3667,4
4721,Winchester,Queensland,QLD,-22.1309,148.1868,4
4721,Theresa Creek,Queensland,QLD,-22.2273,147.794,3
4721,Clermont,Queensland,QLD,-22.8241,147.6364,4
4721,Elgin,Queensland,QLD,-22.2273,147.794,3
4721,Laglan,Queensland,QLD,-22.2273,147.794,3
4721,Gemini Mountains,Queensland,QLD,-22.2273,147.794,3
4721,Pasha,Queensland,QLD,-21.727,147.5588,4
4721,Kilcummin,Queensland,QLD,-22.2273,147.794,3
4721,Wolfang,Queensland,QLD,-22.2273,147.794,3
4721,Frankfield,Queensland,QLD,-22.2273,147.794,3
4721,Argyll,Queensland,QLD,-22.2977,147.0586,3
4721,Mistake Creek,Queensland,QLD,-22.2273,147.794,3
4722,Springsure,Queensland,QLD,-24.1148,148.0885,4
4722,Orion,Queensland,QLD,-24.1148,148.0885,3
4722,Cona Creek,Queensland,QLD,-24.1148,148.0885,3
4722,Buckland,Queensland,QLD,-24.1148,148.0885,3
4722,Cairdbeign,Queensland,QLD,-24.1148,148.0885,3
4722,Nandowrie,Queensland,QLD,-24.1148,148.0885,3
4722,Wealwandangie,Queensland,QLD,-24.1148,148.0885,3
4723,Capella,Queensland,QLD,-23.0859,148.0221,4
4723,Retro,Queensland,QLD,-23.0167,147.9,4
4723,Belcong,Queensland,QLD,-23.086,147.9895,3
4723,Lowestoff,Queensland,QLD,-23.086,147.9895,3
4723,Crinum,Queensland,QLD,-23.086,147.9895,3
4723,Chirnside,Queensland,QLD,-23.1381,148.0463,4
4723,Khosh Bulduk,Queensland,QLD,-23.086,147.9895,3
4723,Carbine Creek,Queensland,QLD,-23.086,147.9895,3
4723,Hibernia,Queensland,QLD,-23.086,147.9895,3
4723,Lilyvale,Queensland,QLD,-23.086,147.9895,3
4723,Mount Macarthur,Queensland,QLD,-23.086,147.9895,3
4724,Alpha,Queensland,QLD,-23.6492,146.6398,4
4724,Pine Hill,Queensland,QLD,-23.65,146.9667,4
4724,Sedgeford,Queensland,QLD,-23.6501,146.8326,3
4724,Hobartville,Queensland,QLD,-23.6501,146.8326,3
4724,Port Wine,Queensland,QLD,-23.6501,146.8326,3
4724,Beaufort,Queensland,QLD,-23.6511,146.8914,4
4724,Surbiton,Queensland,QLD,-23.6501,146.8326,3
4724,Drummondslope,Queensland,QLD,-23.6501,146.8326,3
4725,Barcaldine Downs,Queensland,QLD,-23.2086,147.5842,3
4725,Barcaldine,Queensland,QLD,-23.5535,145.2854,4
4725,Tara Station,Queensland,QLD,-23.2086,147.5842,3
4725,Patrick,Queensland,QLD,-23.2086,147.5842,3
4726,Galilee,Queensland,QLD,-23.2086,147.5842,3
4726,Pelican Creek,Queensland,QLD,-23.2086,147.5842,3
4726,Aramac,Queensland,QLD,-22.9683,145.245,4
4727,Ilfracombe,Queensland,QLD,-23.5,144.5,4
4728,Jericho,Queensland,QLD,-23.6,146.1333,4
4728,Mexico,Queensland,QLD,-23.6,146.1333,3
4728,Dunrobin,Queensland,QLD,-23.6,146.1333,3
4728,Garfield,Queensland,QLD,-23.6,146.1333,3
4730,Chorregon,Queensland,QLD,-22.6785,143.5619,4
4730,Morella,Queensland,QLD,-22.9667,143.8667,4
4730,Stonehenge,Queensland,QLD,-24.3546,143.2873,4
4730,Ernestina,Queensland,QLD,-23.6006,143.3629,3
4730,Longreach,Queensland,QLD,-22.9833,144.4833,4
4730,Maneroo,Queensland,QLD,-23.6006,143.3629,3
4730,Camoola,Queensland,QLD,-22.9833,144.4833,4
4730,Tocal,Queensland,QLD,-23.6006,143.3629,3
4730,Vergemont,Queensland,QLD,-23.6006,143.3629,3
4731,Isisford,Queensland,QLD,-24.2597,144.4393,4
4732,Muttaburra,Queensland,QLD,-22.6,144.55,4
4732,Tablederry,Queensland,QLD,-21.5243,149.2101,3
4733,Corfield,Queensland,QLD,-21.7167,143.3667,4
4735,Middleton,Queensland,QLD,-22.3514,141.5517,4
4735,Diamantina Lakes,Queensland,QLD,-23.0459,141.9408,3
4735,Winton,Queensland,QLD,-22.3818,143.0424,4
4735,Opalton,Queensland,QLD,-23.25,142.7667,4
4736,Jundah,Queensland,QLD,-24.8331,143.0616,4
4737,Blue Mountain,Queensland,QLD,-21.5833,149.0333,4
4737,Campwin Beach,Queensland,QLD,-21.3742,149.3145,4
4737,Sarina,Queensland,QLD,-21.4191,149.2168,4
4737,Sunnyside,Queensland,QLD,-21.1667,148.8833,4
4737,Freshwater Point,Queensland,QLD,-21.4172,149.3265,3
4737,Sarina Range,Queensland,QLD,-21.5533,149.1175,4
4737,Armstrong Beach,Queensland,QLD,-21.4549,149.2896,3
4737,Sarina Beach,Queensland,QLD,-21.3884,149.3086,4
4738,Ilbilbie,Queensland,QLD,-21.7,149.35,4
4738,Koumala,Queensland,QLD,-21.6167,149.25,4
4739,Carmila,Queensland,QLD,-21.9167,149.4167,4
4740,The Leap,Queensland,QLD,-21.0833,149.0167,4
4740,Mackay DC,Queensland,QLD,-21.1774,149.1268,3
4740,Mount Pleasant,Queensland,QLD,-21.1181,149.1596,4
4740,Bakers Creek,Queensland,QLD,-21.2199,149.1468,4
4740,Rural View,Queensland,QLD,-21.0645,149.1646,4
4740,Blacks Beach,Queensland,QLD,-21.0547,149.1897,4
4740,Alligator Creek,Queensland,QLD,-21.3333,149.2,4
4740,West Mackay,Queensland,QLD,-21.1547,149.1618,4
4740,Cremorne,Queensland,QLD,-21.1333,149.2,4
4740,Beaconsfield,Queensland,QLD,-21.0912,149.1654,4
4740,Dumbleton,Queensland,QLD,-21.1333,149.1,4
4740,Munbura,Queensland,QLD,-21.3333,149.15,4
4740,Mackay West,Queensland,QLD,-21.15,148.85,3
4740,Erakala,Queensland,QLD,-21.1282,149.1171,4
4740,Mackay Caneland,Queensland,QLD,-21.1774,149.1268,3
4740,Dolphin Heads,Queensland,QLD,-21.0406,149.1849,4
4740,Belmunda,Queensland,QLD,-21.2395,149.0527,3
4740,Eimeo,Queensland,QLD,-21.0377,149.1763,4
4740,Balnagowan,Queensland,QLD,-21.1175,149.0085,4
4740,Glenella,Queensland,QLD,-21.1177,149.1445,4
4740,East Mackay,Queensland,QLD,-21.1498,149.1964,4
4740,Homebush,Queensland,QLD,-21.2833,149.05,4
4740,Racecourse,Queensland,QLD,-21.1665,149.1345,4
4740,North Mackay,Queensland,QLD,-21.1201,149.1794,4
4740,Paget,Queensland,QLD,-21.1868,149.1722,4
4740,Grasstree Beach,Queensland,QLD,-21.364,149.3061,4
4740,Slade Point,Queensland,QLD,-21.075,149.225,4
4740,Mackay South,Queensland,QLD,-21.65,149.2333,3
4740,Planland,Queensland,QLD,-21.2395,149.0527,3
4740,Andergrove,Queensland,QLD,-21.0833,149.1833,4
4740,Dunnrock,Queensland,QLD,-21.2618,149.1686,4
4740,Habana,Queensland,QLD,-21.0333,149.0833,4
4740,Mackay North,Queensland,QLD,-21.1774,149.1268,3
4740,Balberra,Queensland,QLD,-21.3,149.1167,4
4740,Alexandra,Queensland,QLD,-21.1667,149.1,4
4740,Mcewens Beach,Queensland,QLD,-21.2395,149.0527,3
4740,Coningsby,Queensland,QLD,-21.1,149.0667,4
4740,Cape Hillsborough,Queensland,QLD,-21.2395,149.0527,3
4740,Rosella,Queensland,QLD,-21.2365,149.1459,4
4740,Ooralea,Queensland,QLD,-21.1739,149.148,4
4740,Nindaroo,Queensland,QLD,-21.0833,149.15,4
4740,Dundula,Queensland,QLD,-21.2395,149.0527,3
4740,Mount Jukes,Queensland,QLD,-21.0167,148.95,4
4740,Haliday Bay,Queensland,QLD,-21.2395,149.0527,3
4740,Te Kowai,Queensland,QLD,-21.1667,149.1167,4
4740,Richmond,Queensland,QLD,-21.2395,149.0527,3
4740,South Mackay,Queensland,QLD,-21.1629,149.1775,4
4740,Foulden,Queensland,QLD,-21.1346,149.1369,4
4740,Sandiford,Queensland,QLD,-21.25,149.1,4
4740,Hay Point,Queensland,QLD,-21.2833,149.3,4
4740,Mackay Harbour,Queensland,QLD,-21.1088,149.2113,4
4740,Mackay,Queensland,QLD,-21.1534,149.1655,4
4740,Chelona,Queensland,QLD,-21.2595,149.1424,4
4741,Kuttabul,Queensland,QLD,-21.0333,148.9,4
4741,Ball Bay,Queensland,QLD,-21.2351,148.9113,3
4741,Oakenden,Queensland,QLD,-21.3,149.0167,4
4741,Pinnacle,Queensland,QLD,-21.15,148.7167,4
4741,Eton North,Queensland,QLD,-21.2333,148.9667,4
4741,South Molle,Queensland,QLD,-21.3252,149.0031,3
4741,Coppabella,Queensland,QLD,-21.9471,148.3005,4
4741,Eungella Hinterland,Queensland,QLD,-21.3252,149.0031,3
4741,Yalboroo,Queensland,QLD,-20.8333,148.65,4
4741,Epsom,Queensland,QLD,-21.3252,149.0031,3
4741,Farleigh,Queensland,QLD,-21.1057,149.1024,4
4741,Pleystowe,Queensland,QLD,-21.15,149.0333,4
4741,Mackay Mc,Queensland,QLD,-21.3252,149.0031,3
4741,Daydream Island,Queensland,QLD,-21.3252,149.0031,3
4741,Lindeman Island,Queensland,QLD,-20.4483,149.039,4
4741,Owens Creek,Queensland,QLD,-21.0833,148.7167,4
4741,Gargett,Queensland,QLD,-21.1667,148.75,4
4741,Brightly,Queensland,QLD,-21.2351,148.9113,3
4741,Clairview,Queensland,QLD,-22.1175,149.5347,4
4741,Brampton Island,Queensland,QLD,-21.3252,149.0031,3
4741,Long Island,Queensland,QLD,-21.3252,149.0031,3
4741,Mount Pelion,Queensland,QLD,-20.9333,148.8167,4
4741,Kinchant Dam,Queensland,QLD,-21.3252,149.0031,3
4741,Seaforth,Queensland,QLD,-20.9004,148.9679,4
4741,North Eton,Queensland,QLD,-21.2333,148.9667,4
4741,Hazledean,Queensland,QLD,-21.3833,148.95,4
4741,Eton,Queensland,QLD,-21.2647,148.9724,4
4741,Orkabie,Queensland,QLD,-21.8167,149.3667,4
4741,Mount Ossa,Queensland,QLD,-20.9667,148.8333,4
4741,Hampden,Queensland,QLD,-21.0667,148.9333,4
4741,Mount Charlton,Queensland,QLD,-21.0167,148.7333,4
4742,Strathfield,Queensland,QLD,-21.746,148.4967,4
4742,Oxford,Queensland,QLD,-21.7372,148.4658,3
4742,Elphinstone,Queensland,QLD,-21.5,148.2333,4
4742,Eaglefield,Queensland,QLD,-21.7372,148.4658,3
4742,Turrawulla,Queensland,QLD,-21.7372,148.4658,3
4742,Mount Britton,Queensland,QLD,-21.6431,148.4711,3
4742,Mt Britton,Queensland,QLD,-21.7372,148.4658,3
4742,Valkyrie,Queensland,QLD,-22.0745,148.6408,3
4742,Kemmis,Queensland,QLD,-21.7372,148.4658,3
4742,Burton,Queensland,QLD,-21.7372,148.4658,3
4742,Hail Creek,Queensland,QLD,-21.7372,148.4658,3
4742,Nebo,Queensland,QLD,-21.6833,148.6833,4
4743,Glenden,Queensland,QLD,-21.3544,148.1149,4
4743,Suttor,Queensland,QLD,-21.3476,148.1158,3
4744,Moranbah,Queensland,QLD,-22.0016,148.0466,4
4745,Dysart,Queensland,QLD,-22.5882,148.3492,4
4746,Middlemount,Queensland,QLD,-22.8123,148.6993,4
4746,May Downs,Queensland,QLD,-22.7863,148.5777,3
4750,Bucasia,Queensland,QLD,-21.0349,149.1573,4
4750,Shoal Point,Queensland,QLD,-21.0048,149.1527,4
4751,Victoria Plains,Queensland,QLD,-21.2174,149.0122,3
4751,Walkerston,Queensland,QLD,-21.161,149.0581,4
4751,Palmyra,Queensland,QLD,-21.1724,149.0462,3
4751,Greenmount,Queensland,QLD,-21.1839,149.0342,4
4753,Devereux Creek,Queensland,QLD,-21.1167,148.9167,4
4753,Marian,Queensland,QLD,-21.1439,148.9421,4
4754,Mount Martin,Queensland,QLD,-21.1167,148.8167,4
4754,Mia Mia,Queensland,QLD,-21.1881,148.8079,3
4754,Benholme,Queensland,QLD,-21.1667,148.8167,4
4754,Pinevale,Queensland,QLD,-21.1881,148.8079,3
4754,Mirani,Queensland,QLD,-21.1573,148.8622,4
4754,Dows Creek,Queensland,QLD,-21.1,148.7833,4
4754,Septimus,Queensland,QLD,-21.2333,148.7667,4
4756,Netherdale,Queensland,QLD,-21.15,148.5333,4
4756,Finch Hatton,Queensland,QLD,-21.1404,148.6288,4
4757,Crediton,Queensland,QLD,-21.203,148.5171,4
4757,Broken River,Queensland,QLD,-21.0003,148.4112,3
4757,Dalrymple Heights,Queensland,QLD,-21.1,148.5167,4
4757,Eungella Dam,Queensland,QLD,-21.0003,148.4112,3
4757,Eungella,Queensland,QLD,-21.1333,148.4833,4
4798,Pindi Pindi,Queensland,QLD,-20.8667,148.7333,4
4798,Mentmore,Queensland,QLD,-20.8742,148.728,3
4798,Calen,Queensland,QLD,-20.8975,148.7711,4
4798,St Helens Beach,Queensland,QLD,-20.8821,148.7522,3
4799,Bloomsbury,Queensland,QLD,-20.7167,148.6,4
4799,Midge Point,Queensland,QLD,-20.7167,148.6,3
4800,Palm Grove,Queensland,QLD,-20.3927,148.7007,4
4800,Dittmer,Queensland,QLD,-20.45,148.4,4
4800,Mount Julian,Queensland,QLD,-20.3833,148.6167,4
4800,Breadalbane,Queensland,QLD,-20.4057,148.4881,3
4800,Cannon Valley,Queensland,QLD,-20.2833,148.6833,4
4800,Lethebrook,Queensland,QLD,-20.4057,148.4881,3
4800,Strathdickie,Queensland,QLD,-20.3333,148.6167,4
4800,Hamilton Plains,Queensland,QLD,-20.3823,148.5875,3
4800,Mount Marlow,Queensland,QLD,-20.3667,148.6167,4
4800,Gunyarra,Queensland,QLD,-20.5167,148.5667,4
4800,Thoopara,Queensland,QLD,-20.55,148.5667,4
4800,Preston,Queensland,QLD,-20.4057,148.4881,3
4800,Riordanvale,Queensland,QLD,-19.828,147.5322,3
4800,Foxdale,Queensland,QLD,-20.3667,148.55,4
4800,Crystal Brook,Queensland,QLD,-20.3484,148.485,4
4800,Laguna Quays,Queensland,QLD,-20.6543,148.6667,3
4800,Kelsey Creek,Queensland,QLD,-20.4333,148.45,4
4800,Dingo Beach,Queensland,QLD,-20.0904,148.4959,4
4800,Cape Gloucester,Queensland,QLD,-20.1101,148.5024,4
4800,Glen Isla,Queensland,QLD,-20.4089,148.62,3
4800,Proserpine,Queensland,QLD,-20.4011,148.5802,4
4800,Conway,Queensland,QLD,-20.4167,148.7,4
4800,Sugarloaf,Queensland,QLD,-20.4057,148.4881,3
4800,Gregory River,Queensland,QLD,-20.4057,148.4881,3
4800,Wilson Beach,Queensland,QLD,-20.4057,148.4881,3
4800,Andromache,Queensland,QLD,-20.4057,148.4881,3
4800,Silver Creek,Queensland,QLD,-20.4057,148.4881,3
4800,Goorganga Plains,Queensland,QLD,-20.4057,148.4881,3
4800,Lake Proserpine,Queensland,QLD,-20.4057,148.4881,3
4800,Conway Beach,Queensland,QLD,-20.4057,148.4881,3
4800,Mount Pluto,Queensland,QLD,-20.4057,148.4881,3
4800,Goorganga Creek,Queensland,QLD,-20.4057,148.4881,3
4800,Brandy Creek,Queensland,QLD,-20.4057,148.4881,3
4800,Cape Conway,Queensland,QLD,-20.2981,148.747,3
4800,Myrtlevale,Queensland,QLD,-20.4057,148.4881,3
4800,Hideaway Bay,Queensland,QLD,-20.074,148.4752,4
4800,Pauls Pocket,Queensland,QLD,-20.4057,148.4881,3
4801,Hayman Island,Queensland,QLD,-20.0511,148.8849,4
4802,Cannonvale,Queensland,QLD,-20.2768,148.6976,4
4802,Flametree,Queensland,QLD,-20.27,148.7497,4
4802,Airlie Beach,Queensland,QLD,-20.2675,148.7147,4
4802,Mount Rooper,Queensland,QLD,-20.2873,148.7783,3
4802,Woodwark,Queensland,QLD,-20.2358,148.6533,4
4802,Whitsundays,Queensland,QLD,-20.2318,148.952,4
4802,Jubilee Pocket,Queensland,QLD,-20.2881,148.7294,4
4802,Shute Harbour,Queensland,QLD,-20.2913,148.7858,4
4802,Mandalay,Queensland,QLD,-20.27,148.7389,4
4803,Hamilton Island,Queensland,QLD,-20.346,148.9519,4
4804,Mount Coolon,Queensland,QLD,-21.3833,147.3333,4
4804,Collinsville,Queensland,QLD,-20.5522,147.844,4
4804,Springlands,Queensland,QLD,-20.9844,147.5398,3
4804,Scottville,Queensland,QLD,-20.5667,147.8167,4
4804,Newlands,Queensland,QLD,-20.9844,147.5398,3
4804,Mount Wyatt,Queensland,QLD,-20.9844,147.5398,3
4805,Gumlu,Queensland,QLD,-19.8833,147.6833,4
4805,Bogie,Queensland,QLD,-20.1665,147.8081,3
4805,Bowen,Queensland,QLD,-20.0137,148.2475,4
4805,Merinda,Queensland,QLD,-20.0169,148.1634,4
4805,Guthalungra,Queensland,QLD,-19.9167,147.8333,4
4805,Queens Beach,Queensland,QLD,-19.9667,148.25,4
4806,Carstairs,Queensland,QLD,-19.65,147.4,4
4806,Home Hill,Queensland,QLD,-19.6592,147.4123,4
4806,Wangaratta,Queensland,QLD,-19.8433,147.4016,3
4806,Inkerman,Queensland,QLD,-19.8433,147.4016,3
4806,Rangemore,Queensland,QLD,-19.8433,147.4016,3
4806,Wunjunga,Queensland,QLD,-19.8433,147.4016,3
4806,Kirknie,Queensland,QLD,-19.8433,147.4016,3
4806,Osborne,Queensland,QLD,-19.8433,147.4016,3
4806,Fredericksfield,Queensland,QLD,-19.8433,147.4016,3
4807,Jarvisfield,Queensland,QLD,-19.6167,147.4667,4
4807,Mcdesme,Queensland,QLD,-19.8733,147.2013,3
4807,Mount Kelly,Queensland,QLD,-19.6685,147.3153,3
4807,Ayr,Queensland,QLD,-19.5739,147.4067,4
4807,Dalbeg,Queensland,QLD,-20.274,147.3036,4
4807,Swans Lagoon,Queensland,QLD,-19.8733,147.2013,3
4807,Alva,Queensland,QLD,-19.45,147.4833,4
4807,Airville,Queensland,QLD,-19.6309,147.3455,4
4807,Clare,Queensland,QLD,-19.7833,147.2333,4
4807,Mona Park,Queensland,QLD,-19.8733,147.2013,3
4807,Rita Island,Queensland,QLD,-19.6167,147.5333,4
4807,Mulgrave,Queensland,QLD,-19.8733,147.2013,3
4807,Millaroo,Queensland,QLD,-20.0601,147.28,4
4807,Airdmillan,Queensland,QLD,-19.8733,147.2013,3
4807,Eight Mile Creek,Queensland,QLD,-19.8733,147.2013,3
4807,Rite Island,Queensland,QLD,-19.8733,147.2013,3
4807,Claredale,Queensland,QLD,-19.7507,147.3816,3
4807,Parkside,Queensland,QLD,-19.8733,147.2013,3
4808,Brandon,Queensland,QLD,-19.5539,147.353,4
4808,Colevale,Queensland,QLD,-19.5557,147.3568,3
4809,Cromarty,Queensland,QLD,-19.4667,147.05,4
4809,Horseshoe Lagoon,Queensland,QLD,-19.5649,147.1252,3
4809,Upper Haughton,Queensland,QLD,-19.5649,147.1252,3
4809,Giru,Queensland,QLD,-19.517,147.1026,4
4809,Mount Surround,Queensland,QLD,-19.5649,147.1252,3
4809,Jerona,Queensland,QLD,-19.5649,147.1252,3
4809,Shirbourne,Queensland,QLD,-19.5649,147.1252,3
4809,Barratta,Queensland,QLD,-19.5311,147.2132,4
4810,Pallarenda,Queensland,QLD,-19.201,146.7734,4
4810,Townsville Mc,Queensland,QLD,-19.2599,146.8266,3
4810,North Ward,Queensland,QLD,-19.2474,146.8055,4
4810,Town Common,Queensland,QLD,-19.2183,146.7552,4
4810,Rowes Bay,Queensland,QLD,-19.2401,146.7892,4
4810,South Townsville,Queensland,QLD,-19.2603,146.8277,4
4810,Townsville DC,Queensland,QLD,-19.2599,146.8266,3
4810,Railway Estate,Queensland,QLD,-19.2757,146.8196,4
4810,Castle Hill,Queensland,QLD,-19.2283,146.7706,3
4810,Shelly Beach,Queensland,QLD,-19.2283,146.7706,3
4810,Townsville City,Queensland,QLD,-19.2283,146.7706,3
4810,Townsville,Queensland,QLD,-19.2664,146.8057,4
4810,Belgian Gardens,Queensland,QLD,-19.2451,146.7945,4
4810,West End,Queensland,QLD,-19.2653,146.794,4
4810,Cape Cleveland,Queensland,QLD,-19.3328,147.0248,4
4811,Cluden,Queensland,QLD,-19.3173,146.8249,4
4811,James Cook University,Queensland,QLD,-19.3511,146.8368,3
4811,Idalia,Queensland,QLD,-19.3068,146.8139,4
4811,Stuart,Queensland,QLD,-19.347,146.8438,4
4811,Oonoonba,Queensland,QLD,-19.3,146.8167,4
4811,Oak Valley,Queensland,QLD,-19.4083,146.8213,4
4811,Roseneath,Queensland,QLD,-19.3555,146.8351,4
4811,Mount Stuart,Queensland,QLD,-19.3722,146.743,4
4811,Wulguru,Queensland,QLD,-19.3285,146.8184,4
4812,Pimlico,Queensland,QLD,-19.2817,146.788,4
4812,Hyde Park Castletown,Queensland,QLD,-19.2855,146.7904,3
4812,Mundingburra,Queensland,QLD,-19.2965,146.7851,4
4812,Currajong,Queensland,QLD,-19.2757,146.7792,4
4812,Hermit Park,Queensland,QLD,-19.2831,146.8036,4
4812,Mysterton,Queensland,QLD,-19.2872,146.7936,4
4812,Hyde Park,Queensland,QLD,-19.2769,146.7965,4
4812,Gulliver,Queensland,QLD,-19.2851,146.7745,4
4812,Rosslea,Queensland,QLD,-19.298,146.8027,4
4813,Townsville Milpo,Queensland,QLD,-19.3366,146.7836,1
4814,Garbutt,Queensland,QLD,-19.2566,146.7658,4
4814,Garbutt East,Queensland,QLD,-19.2983,146.7711,3
4814,Murray,Queensland,QLD,-19.3323,146.7917,4
4814,Mount Louisa,Queensland,QLD,-19.274,146.7509,4
4814,Douglas,Queensland,QLD,-19.3239,146.7523,4
4814,Heatley,Queensland,QLD,-19.2904,146.7536,4
4814,Aitkenvale,Queensland,QLD,-19.2833,146.8,4
4814,Vincent,Queensland,QLD,-19.2834,146.7646,4
4814,Annandale,Queensland,QLD,-19.3111,146.7876,4
4814,Cranbrook,Queensland,QLD,-19.3055,146.7529,4
4814,Thuringowa DC,Queensland,QLD,-19.2983,146.7711,3
4815,Condon,Queensland,QLD,-19.3297,146.7166,4
4815,Gumlow,Queensland,QLD,-19.3528,146.6849,4
4815,Rasmussen,Queensland,QLD,-19.3524,146.7228,4
4815,Pinnacles,Queensland,QLD,-19.4415,146.6751,4
4815,Kelso,Queensland,QLD,-19.3893,146.7191,4
4815,Granite Vale,Queensland,QLD,-19.5988,146.5943,4
4816,Nome,Queensland,QLD,-19.3833,146.9167,4
4816,Hidden Valley,Queensland,QLD,-20.5619,144.8924,3
4816,Alligator Creek,Queensland,QLD,-19.3918,146.9378,4
4816,Mutarnee,Queensland,QLD,-18.95,146.3,4
4816,Julago,Queensland,QLD,-19.3695,146.8872,4
4816,Mount Elliot,Queensland,QLD,-19.5652,146.3947,3
4816,Torrens Creek,Queensland,QLD,-20.7667,145.0167,4
4816,The Cape,Queensland,QLD,-20.4592,145.1686,4
4816,Homestead,Queensland,QLD,-20.3667,145.6667,4
4816,Greenvale,Queensland,QLD,-20.5619,144.8924,3
4816,Crystal Creek,Queensland,QLD,-19.0029,146.2689,4
4816,Rollingstone,Queensland,QLD,-19.05,146.3833,4
4816,Crimea,Queensland,QLD,-19.7307,146.8048,4
4816,Buchanan,Queensland,QLD,-19.5652,146.3947,3
4816,Ross River,Queensland,QLD,-19.3167,146.7333,4
4816,Savannah,Queensland,QLD,-19.5652,146.3947,3
4816,Barringha,Queensland,QLD,-19.55,146.8333,4
4816,Malpas-Trenton,Queensland,QLD,-19.5652,146.3947,3
4816,Balgal Beach,Queensland,QLD,-19.0163,146.4083,4
4816,Carruchan,Queensland,QLD,-18.2167,145.9167,4
4816,Toonpan,Queensland,QLD,-19.5,146.8333,4
4816,Kennedy,Queensland,QLD,-18.2048,145.9546,4
4816,Brookhill,Queensland,QLD,-19.3833,146.8333,4
4816,Majors Creek,Queensland,QLD,-19.6338,147.0081,4
4816,Paluma,Queensland,QLD,-19.0098,146.2086,4
4816,Ellerbeck,Queensland,QLD,-19.5652,146.3947,3
4816,Woodstock,Queensland,QLD,-19.6,146.8333,4
4816,Prairie,Queensland,QLD,-20.8833,144.6,4
4816,Reid River,Queensland,QLD,-19.7667,146.8333,4
4816,Clemant,Queensland,QLD,-19.092,146.4733,4
4816,Macrossan,Queensland,QLD,-20,146.4667,4
4816,Nelia,Queensland,QLD,-20.5619,144.8924,3
4816,Sellheim,Queensland,QLD,-20.0167,146.4167,4
4816,Calcium,Queensland,QLD,-19.6395,146.8198,4
4816,Cungulla,Queensland,QLD,-19.3921,147.1083,4
4816,Toomulla,Queensland,QLD,-19.0828,146.4749,4
4816,Mingela,Queensland,QLD,-19.8833,146.6333,4
4816,Palm Island,Queensland,QLD,-18.7356,146.5779,4
4816,Pentland,Queensland,QLD,-20.5236,145.3969,4
4816,Ravenswood,Queensland,QLD,-20.1,146.8833,4
4817,Kirwan,Queensland,QLD,-19.3032,146.7253,4
4817,Thuringowa Central,Queensland,QLD,-19.3167,146.7289,4
4817,Bohle Plains,Queensland,QLD,-19.3137,146.6919,4
4817,Alice River,Queensland,QLD,-19.3344,146.6139,4
4817,Rangewood,Queensland,QLD,-19.3114,146.6388,4
4817,Hervey Range,Queensland,QLD,-19.4857,146.6606,4
4818,Lynam,Queensland,QLD,-19.2751,146.5469,4
4818,Beach Holm,Queensland,QLD,-19.2107,146.6499,4
4818,Yabulu,Queensland,QLD,-19.201,146.5888,4
4818,Mount St John,Queensland,QLD,-19.2568,146.7448,4
4818,Burdell,Queensland,QLD,-19.2506,146.6988,4
4818,Blue Hills,Queensland,QLD,-24.5329,150.9193,3
4818,Bushland Beach,Queensland,QLD,-19.1913,146.6768,4
4818,Mount Low,Queensland,QLD,-19.2321,146.6695,4
4818,Jensen,Queensland,QLD,-19.2457,146.6529,4
4818,Bohle,Queensland,QLD,-19.2625,146.7156,4
4818,Saunders Beach,Queensland,QLD,-19.1579,146.6032,4
4818,Bluewater Park,Queensland,QLD,-19.205,146.5134,4
4818,Bluewater,Queensland,QLD,-19.1816,146.5582,4
4818,Deeragun,Queensland,QLD,-19.2474,146.6757,4
4818,Toolakea,Queensland,QLD,-19.1476,146.5863,4
4818,Shaw,Queensland,QLD,-19.2626,146.7033,4
4818,Cosgrove,Queensland,QLD,-19.2154,146.6304,3
4818,Black River,Queensland,QLD,-19.2252,146.6179,4
4819,West Point,Queensland,QLD,-19.1409,146.8244,4
4819,Florence Bay,Queensland,QLD,-19.1167,146.8705,4
4819,Horseshoe Bay,Queensland,QLD,-19.1176,146.8605,4
4819,Picnic Bay,Queensland,QLD,-19.1746,146.8376,4
4819,Nelly Bay,Queensland,QLD,-19.1578,146.8498,4
4819,Magnetic Island,Queensland,QLD,-19.1551,146.8485,4
4819,Arcadia,Queensland,QLD,-19.1494,146.8667,4
4820,Broughton,Queensland,QLD,-20.1167,146.4333,4
4820,Columbia,Queensland,QLD,-20.0041,145.9004,3
4820,Breddan,Queensland,QLD,-19.9804,146.2904,4
4820,Black Jack,Queensland,QLD,-20.108,146.1939,3
4820,Lissner,Queensland,QLD,-20.0776,146.2582,4
4820,Richmond Hill,Queensland,QLD,-20.0041,145.9004,3
4820,Towers Hill,Queensland,QLD,-20.0906,146.2503,4
4820,Balfes Creek,Queensland,QLD,-20.2167,145.9167,4
4820,Alabama Hill,Queensland,QLD,-20.0823,146.2484,4
4820,Millchester,Queensland,QLD,-20.0953,146.276,3
4820,Charters Towers,Queensland,QLD,-20.0767,146.2635,4
4820,Mosman Park,Queensland,QLD,-20.0987,146.2603,4
4820,Grand Secret,Queensland,QLD,-20.0761,146.2449,3
4820,Campaspe,Queensland,QLD,-20.108,146.1939,3
4820,Llanarth,Queensland,QLD,-20.108,146.1939,3
4820,Southern Cross,Queensland,QLD,-20.1333,146.1167,4
4820,Toll,Queensland,QLD,-20.0506,146.2556,3
4820,Seventy Mile,Queensland,QLD,-20.597,146.6175,4
4820,Queenton,Queensland,QLD,-20.0745,146.2817,4
4820,Dotswood,Queensland,QLD,-20.108,146.1939,3
4820,Basalt,Queensland,QLD,-19.7516,145.3892,4
4820,Charters Towers City,Queensland,QLD,-20.108,146.1939,3
4821,Tangorin,Queensland,QLD,-21.75,144.2333,4
4821,Stamford,Queensland,QLD,-21.2667,143.8167,4
4821,Porcupine,Queensland,QLD,-20.844,144.2003,3
4821,Dutton River,Queensland,QLD,-20.844,144.2003,3
4821,Hughenden,Queensland,QLD,-20.844,144.2003,4
4822,Albion,Queensland,QLD,-19.8,143.2833,3
4822,Cambridge,Queensland,QLD,-19.8,143.2833,3
4822,Bellfield,Queensland,QLD,-19.8,143.2833,3
4822,Richmond,Queensland,QLD,-20.7324,143.1436,4
4822,Maxwelton,Queensland,QLD,-20.7167,142.6833,4
4822,Victoria Vale,Queensland,QLD,-19.8,143.2833,3
4822,Saxby,Queensland,QLD,-19.8,143.2833,3
4822,Burleigh,Queensland,QLD,-19.8,143.2833,3
4822,Woolgar,Queensland,QLD,-19.8,143.2833,4
4823,Julia Creek,Queensland,QLD,-20.6558,141.7413,4
4823,Mckinlay,Queensland,QLD,-21.5833,141.9,4
4823,Carpentaria,Queensland,QLD,-20.1386,145.8436,3
4823,Kynuna,Queensland,QLD,-21.5833,141.9,4
4823,Taldora,Queensland,QLD,-20.1386,145.8436,3
4823,Warburton,Queensland,QLD,-20.5351,141.5871,3
4823,Stokes,Queensland,QLD,-20.1386,145.8436,3
4824,Kuridala,Queensland,QLD,-21.2833,140.5,4
4824,Four Ways,Queensland,QLD,-20.1386,145.8436,3
4824,Oorindi,Queensland,QLD,-20.6833,141.0667,4
4824,Gidya,Queensland,QLD,-20.1386,145.8436,3
4824,Cloncurry,Queensland,QLD,-20.7072,140.513,4
4824,Three Rivers,Queensland,QLD,-20.1386,145.8436,3
4825,Mount Isa DC,Queensland,QLD,-20.1386,145.8436,3
4825,Mount Isa,Queensland,QLD,-20.7252,139.4973,4
4825,Winston,Queensland,QLD,-20.7037,139.4991,4
4825,Menzies,Queensland,QLD,-20.7181,139.4972,4
4825,Sunset,Queensland,QLD,-20.7103,139.5082,4
4825,Happy Valley,Queensland,QLD,-20.7479,139.4906,4
4825,Soldiers Hill,Queensland,QLD,-20.7053,139.4899,4
4825,Waverley,Queensland,QLD,-21.2234,138.7211,3
4825,Townview,Queensland,QLD,-20.7319,139.5077,3
4825,Spreadborough,Queensland,QLD,-20.1386,145.8436,3
4825,Duchess,Queensland,QLD,-21.3667,139.85,4
4825,Parkside,Queensland,QLD,-20.7377,139.4886,4
4825,Miles End,Queensland,QLD,-20.7241,139.4867,3
4825,Mount Isa East,Queensland,QLD,-20.1386,145.8436,3
4825,Healy,Queensland,QLD,-20.7418,139.5014,3
4825,The Monument,Queensland,QLD,-21.2234,138.7211,3
4825,Gunpowder,Queensland,QLD,-19.7103,139.3839,4
4825,The Gap,Queensland,QLD,-21.2234,138.7211,3
4825,Pioneer,Queensland,QLD,-20.7203,139.5081,4
4825,Ryan,Queensland,QLD,-21.2234,138.7211,3
4825,Dajarra,Queensland,QLD,-21.6959,139.5163,4
4825,Breakaway,Queensland,QLD,-21.2234,138.7211,3
4825,Buckingham,Queensland,QLD,-21.2234,138.7211,3
4825,Georgina,Queensland,QLD,-21.2234,138.7211,3
4825,Mount Isa City,Queensland,QLD,-20.1386,145.8436,3
4825,Piturie,Queensland,QLD,-21.2234,138.7211,3
4825,Mica Creek,Queensland,QLD,-20.7668,139.4913,3
4825,Carrandotta,Queensland,QLD,-21.2234,138.7211,3
4825,Fisher,Queensland,QLD,-21.2234,138.7211,3
4825,Kalkadoon,Queensland,QLD,-20.1386,145.8436,3
4825,Lanskey,Queensland,QLD,-20.1386,145.8436,3
4825,Lawn Hill,Queensland,QLD,-20.1386,145.8436,3
4825,Mornington,Queensland,QLD,-20.7342,139.4959,4
4825,Fielding,Queensland,QLD,-20.1386,145.8436,3
4825,Barkly,Queensland,QLD,-20.1386,145.8436,3
4828,Camooweal,Queensland,QLD,-19.9215,138.1201,4
4829,Boulia,Queensland,QLD,-22.9114,139.9093,4
4829,Toko,Queensland,QLD,-24.6177,139.1149,3
4829,Bedourie,Queensland,QLD,-24.6177,139.1149,3
4829,Wills,Queensland,QLD,-24.6177,139.1149,3
4829,Amaroo,Queensland,QLD,-24.6177,139.1149,3
4829,Warenda,Queensland,QLD,-24.6177,139.1149,3
4829,Sturt,Queensland,QLD,-24.6177,139.1149,3
4829,Min Min,Queensland,QLD,-24.6177,139.1149,3
4830,Nicholson,Queensland,QLD,-18.1224,146.0477,3
4830,Burketown,Queensland,QLD,-17.7411,139.5477,4
4830,Gregory,Queensland,QLD,-18.1224,146.0477,3
4830,Doomadgee,Queensland,QLD,-17.9435,138.8272,4
4849,Cardwell,Queensland,QLD,-18.2669,146.028,4
4849,Rungoo,Queensland,QLD,-18.3072,146.1291,3
4849,Lumholtz,Queensland,QLD,-18.3072,146.1291,3
4849,Hinchinbrook,Queensland,QLD,-18.3511,146.2352,4
4849,Damper Creek,Queensland,QLD,-18.3072,146.1291,3
4850,Ingham,Queensland,QLD,-18.6455,146.1625,4
4850,Yuruga,Queensland,QLD,-18.8333,146.1667,4
4850,Toobanna,Queensland,QLD,-18.7,146.15,4
4850,Peacock Siding,Queensland,QLD,-18.6833,146,4
4850,Bemerside,Queensland,QLD,-18.5667,146.2333,4
4850,Macknade,Queensland,QLD,-18.5873,146.2559,4
4850,Lucinda,Queensland,QLD,-18.5278,146.3313,4
4850,Abergowrie,Queensland,QLD,-18.4382,145.9313,4
4850,Halifax,Queensland,QLD,-18.5827,146.2855,4
4850,Valley Of Lagoons,Queensland,QLD,-18.661,146.1506,3
4850,Blackrock,Queensland,QLD,-18.7085,146.2121,4
4850,Trebonne,Queensland,QLD,-18.6254,146.0792,4
4850,Victoria Estate,Queensland,QLD,-18.65,146.2,4
4850,Victoria Plantation,Queensland,QLD,-18.6466,146.2019,3
4850,Hawkins Creek,Queensland,QLD,-18.6,146.1,4
4850,Coolbie,Queensland,QLD,-18.9167,146.2667,4
4850,Long Pocket,Queensland,QLD,-18.5167,146.0333,4
4850,Allingham,Queensland,QLD,-18.6667,146.25,4
4850,Braemeadows,Queensland,QLD,-18.661,146.1506,3
4850,Dalrymple Creek,Queensland,QLD,-18.661,146.1506,3
4850,Bambaroo,Queensland,QLD,-18.8667,146.2,4
4850,Orient,Queensland,QLD,-18.661,146.1506,3
4850,Lannercost,Queensland,QLD,-18.5833,146.05,4
4850,Wharps,Queensland,QLD,-18.661,146.1506,3
4850,Helens Hill,Queensland,QLD,-18.7667,146.1667,4
4850,Garrawalt,Queensland,QLD,-18.661,146.1506,3
4850,Cordelia,Queensland,QLD,-18.5833,146.25,4
4850,Wallaman,Queensland,QLD,-18.661,146.1506,3
4850,Taylors Beach,Queensland,QLD,-18.6327,146.3245,4
4850,Foresthome,Queensland,QLD,-18.661,146.1506,3
4850,Mount Fox,Queensland,QLD,-18.8167,145.8333,4
4850,Forrest Beach,Queensland,QLD,-18.661,146.1506,3
4850,Upper Stone,Queensland,QLD,-18.7333,145.9667,4
4850,Gairloch,Queensland,QLD,-18.6333,146.1667,4
4852,Tam O'Shanter,Queensland,QLD,-17.941,146.0235,4
4852,Djiru,Queensland,QLD,-17.8466,146.0534,3
4852,Dunk,Queensland,QLD,-17.9518,146.1557,4
4852,Garners Beach,Queensland,QLD,-17.8163,146.0977,4
4852,Bingil Bay,Queensland,QLD,-17.8295,146.0965,4
4852,Carmoo,Queensland,QLD,-17.9473,146.0457,4
4852,Wongaling Beach,Queensland,QLD,-17.9133,146.0961,4
4852,Midgeree Bar,Queensland,QLD,-17.8008,146.0801,4
4852,South Mission Beach,Queensland,QLD,-17.95,146.0833,4
4852,Mission Beach,Queensland,QLD,-17.8688,146.1039,4
4854,Birkalla,Queensland,QLD,-17.921,145.9401,4
4854,Midgenoo,Queensland,QLD,-17.9,145.9667,4
4854,Murray Upper,Queensland,QLD,-18.1,145.85,4
4854,East Feluga,Queensland,QLD,-17.9573,145.9256,3
4854,Tully Heads,Queensland,QLD,-18.0232,146.0558,4
4854,Bilyana,Queensland,QLD,-18.1092,145.9267,4
4854,Silky Oak,Queensland,QLD,-17.9673,145.9392,4
4854,Lower Tully,Queensland,QLD,-17.9846,146.0144,4
4854,Bulgun,Queensland,QLD,-17.8996,145.9282,4
4854,Hull Heads,Queensland,QLD,-17.9573,145.9256,3
4854,Cardstone,Queensland,QLD,-17.7509,145.5993,4
4854,Rockingham,Queensland,QLD,-18.0275,145.9746,4
4854,Djarawong,Queensland,QLD,-17.8667,145.9833,4
4854,Dingo Pocket,Queensland,QLD,-17.9573,145.9256,3
4854,Murrigal,Queensland,QLD,-18.0478,145.9116,4
4854,Tully,Queensland,QLD,-17.9326,145.9228,4
4854,Feluga,Queensland,QLD,-17.8795,145.9516,4
4854,Kooroomool,Queensland,QLD,-17.9573,145.9256,3
4854,Warrami,Queensland,QLD,-17.9573,145.9256,3
4854,Mount Mackay,Queensland,QLD,-17.9573,145.9256,3
4854,Euramo,Queensland,QLD,-18,145.9333,4
4854,Munro Plains,Queensland,QLD,-17.9573,145.9256,3
4854,Jarra Creek,Queensland,QLD,-17.9573,145.9256,3
4854,Merryburn,Queensland,QLD,-17.9573,145.9256,3
4854,Walter Hill,Queensland,QLD,-17.9573,145.9256,3
4855,El Arish,Queensland,QLD,-17.8075,146.0072,4
4855,Jaffa,Queensland,QLD,-17.7833,146.0167,4
4855,Maadi,Queensland,QLD,-17.8333,146,4
4855,Granadilla,Queensland,QLD,-17.8057,146.0047,3
4855,Shell Pocket,Queensland,QLD,-17.8057,146.0047,3
4855,Maria Creeks,Queensland,QLD,-17.8057,146.0047,3
4855,Daveson,Queensland,QLD,-17.8057,146.0047,3
4855,Friday Pocket,Queensland,QLD,-17.8057,146.0047,3
4856,Silkwood,Queensland,QLD,-17.7446,146.0171,4
4856,Goolboo,Queensland,QLD,-17.7104,146.0345,4
4856,Japoonvale,Queensland,QLD,-17.7167,145.9333,4
4856,Walter Lever Estate,Queensland,QLD,-17.7786,145.9193,3
4856,No. 4 Branch,Queensland,QLD,-17.7786,145.9193,3
4856,No. 5 Branch,Queensland,QLD,-17.7786,145.9193,3
4856,Mccutcheon,Queensland,QLD,-17.7786,145.9193,3
4857,Silkwood East,Queensland,QLD,-17.7435,146.0097,1
4858,Comoon Loop,Queensland,QLD,-17.5607,146.0337,4
4858,Etty Bay,Queensland,QLD,-17.6009,146.0708,3
4858,Mourilyan Harbour,Queensland,QLD,-17.6002,146.1251,4
4858,Mourilyan,Queensland,QLD,-17.5826,146.0435,4
4858,New Harbourline,Queensland,QLD,-17.614,146.0637,4
4858,Martyville,Queensland,QLD,-17.6175,146.036,4
4859,No. 6 Branch,Queensland,QLD,-17.5983,145.9944,3
4859,South Johnstone,Queensland,QLD,-17.5972,145.9945,4
4860,Coorumba,Queensland,QLD,-17.5563,145.9143,3
4860,Innisfail Estate,Queensland,QLD,-17.519,146.0393,3
4860,Hudson,Queensland,QLD,-17.5256,146.0031,4
4860,East Palmerston,Queensland,QLD,-17.6062,145.8486,4
4860,Eubenangee,Queensland,QLD,-17.455,145.9768,4
4860,Cooroo Lands,Queensland,QLD,-17.5305,145.8932,3
4860,Cullinane,Queensland,QLD,-17.5152,146.026,3
4860,Garradunga,Queensland,QLD,-17.4667,146,4
4860,Belvedere,Queensland,QLD,-17.5208,145.9892,4
4860,Nerada,Queensland,QLD,-17.5305,145.8932,3
4860,South Innisfail,Queensland,QLD,-17.5449,146.0296,4
4860,Sundown,Queensland,QLD,-27.4654,151.3113,3
4860,Goondi,Queensland,QLD,-17.5167,146,4
4860,Palmerston,Queensland,QLD,-17.5305,145.8932,3
4860,Daradgee,Queensland,QLD,-17.4833,146,4
4860,Innisfail,Queensland,QLD,-17.5221,146.031,4
4860,East Innisfail,Queensland,QLD,-17.5342,146.0328,4
4860,Stoters Hill,Queensland,QLD,-17.5305,145.8932,3
4860,Mighell,Queensland,QLD,-17.5329,146.024,4
4860,Goondi Bend,Queensland,QLD,-17.5192,146.0107,3
4860,Wooroonooran,Queensland,QLD,-17.3807,145.8007,4
4860,Coquette Point,Queensland,QLD,-17.5305,145.8932,3
4860,Flying Fish Point,Queensland,QLD,-17.4967,146.076,4
4860,Fitzgerald Creek,Queensland,QLD,-17.5305,145.8932,3
4860,Eaton,Queensland,QLD,-17.5305,145.8932,3
4860,Coconuts,Queensland,QLD,-17.5059,146.0645,3
4860,Njatjan,Queensland,QLD,-17.5305,145.8932,3
4860,Ngatjan,Queensland,QLD,-17.5156,145.9646,3
4860,Bamboo Creek,Queensland,QLD,-17.5305,145.8932,3
4860,O'Briens Hill,Queensland,QLD,-17.5305,145.8932,3
4860,Pin Gin Hill,Queensland,QLD,-17.5305,145.8932,3
4860,Vasa Views,Queensland,QLD,-17.4685,145.9656,3
4860,Jubilee Heights,Queensland,QLD,-17.5305,145.8932,3
4860,Webb,Queensland,QLD,-17.5298,146.0435,3
4860,Upper Daradgee,Queensland,QLD,-17.5305,145.8932,3
4860,Wanjuru,Queensland,QLD,-17.5305,145.8932,3
4860,Goondi Hill,Queensland,QLD,-17.5246,146.0171,3
4860,Mundoo,Queensland,QLD,-17.5626,146.0166,4
4861,Bartle Frere,Queensland,QLD,-17.45,145.8833,4
4861,East Russell,Queensland,QLD,-17.3577,145.9755,3
4861,Babinda,Queensland,QLD,-17.3439,145.9227,4
4865,Gordonvale,Queensland,QLD,-17.0996,145.7804,4
4865,Packers Camp,Queensland,QLD,-17.1114,145.754,3
4865,Green Hill,Queensland,QLD,-17.1114,145.754,3
4865,Kamma,Queensland,QLD,-17.05,145.7667,4
4865,Little Mulgrave,Queensland,QLD,-17.15,145.7167,4
4865,Goldsborough,Queensland,QLD,-17.146,145.7524,4
4868,Bayview Heights,Queensland,QLD,-16.9623,145.7258,4
4868,White Rock,Queensland,QLD,-16.9833,145.75,4
4868,Woree,Queensland,QLD,-16.95,145.75,4
4868,Mount Sheridan,Queensland,QLD,-16.9889,145.7322,4
4869,Bentley Park,Queensland,QLD,-17.0083,145.7221,4
4869,Mount Peter,Queensland,QLD,-17.0177,145.7305,3
4869,Edmonton,Queensland,QLD,-17.019,145.7444,4
4869,Wrights Creek,Queensland,QLD,-17.0177,145.7305,3
4870,Cairns,Queensland,QLD,-16.923,145.7663,4
4870,Westcourt,Queensland,QLD,-16.9304,145.753,4
4870,Edge Hill,Queensland,QLD,-16.9,145.75,4
4870,Cairns North,Queensland,QLD,-16.9066,145.7597,3
4870,Bungalow,Queensland,QLD,-16.9373,145.757,4
4870,Cairns Central,Queensland,QLD,-16.9071,145.7325,3
4870,Manunda,Queensland,QLD,-16.9172,145.7492,4
4870,Cairns City,Queensland,QLD,-16.9078,145.7329,3
4870,Parramatta Park,Queensland,QLD,-16.9245,145.7626,4
4870,Mooroobool,Queensland,QLD,-16.9356,145.7296,4
4870,Whitfield,Queensland,QLD,-16.8951,145.7333,4
4870,Cairns Orchid Plaza,Queensland,QLD,-16.9078,145.7329,3
4870,Kanimbla,Queensland,QLD,-16.9217,145.7222,4
4870,Cairns DC,Queensland,QLD,-16.9071,145.7325,3
4870,Aeroglen,Queensland,QLD,-16.8806,145.745,4
4870,Martynvale,Queensland,QLD,-16.9078,145.7329,3
4870,Redlynch,Queensland,QLD,-16.8833,145.7,4
4870,Portsmith,Queensland,QLD,-16.9071,145.7325,3
4870,Brinsmead,Queensland,QLD,-16.8992,145.7155,4
4870,North Cairns,Queensland,QLD,-16.9071,145.7325,3
4870,Manoora,Queensland,QLD,-16.9171,145.7367,4
4870,Barron Gorge,Queensland,QLD,-16.8802,145.6692,4
4870,Earlville,Queensland,QLD,-16.9471,145.7315,4
4870,Lamb Range,Queensland,QLD,-16.9071,145.7325,3
4870,Kamerunga,Queensland,QLD,-16.8732,145.7013,4
4870,Stratford,Queensland,QLD,-16.8833,145.7333,4
4870,Freshwater,Queensland,QLD,-16.8833,145.7167,4
4870,Earlville BC,Queensland,QLD,-16.9071,145.7325,3
4871,Coen,Queensland,QLD,-13.9456,143.199,4
4871,Stockton,Queensland,QLD,-17.5811,146.0141,4
4871,Cowley Beach,Queensland,QLD,-17.6953,146.1073,4
4871,Einasleigh,Queensland,QLD,-18.5167,144.1,4
4871,Gilbert River,Queensland,QLD,-18.15,142.8667,4
4871,Cowley,Queensland,QLD,-17.6833,146.05,4
4871,Bellenden Ker,Queensland,QLD,-17.2667,145.9167,4
4871,Georgetown,Queensland,QLD,-18.292,143.5458,4
4871,Basilisk,Queensland,QLD,-17.6602,145.992,3
4871,Moresby,Queensland,QLD,-17.6333,146.0333,4
4871,East Creek,Queensland,QLD,-17.2018,145.1662,3
4871,Mena Creek,Queensland,QLD,-17.65,145.9667,4
4871,Mount Surprise,Queensland,QLD,-18.1466,144.319,4
4871,East Trinity,Queensland,QLD,-16.8932,145.8267,4
4871,Karron,Queensland,QLD,-17.2018,145.1662,3
4871,Strathmore,Queensland,QLD,-17.2018,145.1662,3
4871,Mount Molloy,Queensland,QLD,-16.6734,145.3272,4
4871,Lower Cowley,Queensland,QLD,-16.1048,143.1125,3
4871,Lyndhurst,Queensland,QLD,-17.2018,145.1662,3
4871,Desailly,Queensland,QLD,-17.2018,145.1662,3
4871,Mount Mulligan,Queensland,QLD,-16.85,144.8667,4
4871,Julatten,Queensland,QLD,-16.6072,145.3412,4
4871,Springfield,Queensland,QLD,-17.2018,145.1662,3
4871,Yarrabah,Queensland,QLD,-16.9203,145.8715,4
4871,Lakeland Downs,Queensland,QLD,-15.8585,144.8546,4
4871,Lockhart,Queensland,QLD,-16.1048,143.1125,3
4871,Aloomba,Queensland,QLD,-17.1099,145.8334,4
4871,Chillagoe,Queensland,QLD,-17.1539,144.5227,4
4871,Boogan,Queensland,QLD,-17.6111,146.0133,4
4871,Utchee Creek,Queensland,QLD,-16.1048,143.1125,3
4871,Miriwinni,Queensland,QLD,-17.4,145.9167,4
4871,Talaroo,Queensland,QLD,-17.2018,145.1662,3
4871,Laura,Queensland,QLD,-15.5691,144.4702,4
4871,Currajah,Queensland,QLD,-16.1048,143.1125,3
4871,Kurrimine Beach,Queensland,QLD,-17.7765,146.1066,4
4871,Forsayth,Queensland,QLD,-18.5833,143.6167,4
4871,Aurukun,Queensland,QLD,-13.6029,141.8162,3
4871,Camp Creek,Queensland,QLD,-16.1048,143.1125,3
4871,Kowanyama,Queensland,QLD,-15.4736,141.7457,4
4871,Petford,Queensland,QLD,-17.35,144.9333,4
4871,Hurricane,Queensland,QLD,-17.2018,145.1662,3
4871,Green Island,Queensland,QLD,-16.1048,143.1125,3
4871,Gununa,Queensland,QLD,-16.6657,139.1801,4
4871,Croydon,Queensland,QLD,-18.2043,142.2446,4
4871,Crystalbrook,Queensland,QLD,-17.2018,145.1662,3
4871,Portland Roads,Queensland,QLD,-17.2018,145.1662,3
4871,Claraville,Queensland,QLD,-17.2018,145.1662,3
4871,Esmeralda,Queensland,QLD,-17.2018,145.1662,3
4871,Coralie,Queensland,QLD,-17.2018,145.1662,3
4871,Bombeeta,Queensland,QLD,-17.6833,145.9667,4
4871,Woopen Creek,Queensland,QLD,-17.2018,145.1662,3
4871,Deeral,Queensland,QLD,-17.2167,145.9167,4
4871,Glen Boughton,Queensland,QLD,-16.1048,143.1125,3
4871,Mount Carbine,Queensland,QLD,-16.5333,145.1333,4
4871,Amber,Queensland,QLD,-17.2018,145.1662,3
4871,Macalister Range,Queensland,QLD,-16.7755,145.6551,4
4871,Bolwarra,Queensland,QLD,-17.2018,145.1662,3
4871,Mirriwinni,Queensland,QLD,-17.2018,145.1662,3
4871,Blackbull,Queensland,QLD,-17.2018,145.1662,3
4871,Fishery Falls,Queensland,QLD,-17.1833,145.8833,4
4871,Sandy Pocket,Queensland,QLD,-16.1048,143.1125,3
4871,Fitzroy Island,Queensland,QLD,-17.2018,145.1662,3
4871,Cowley Creek,Queensland,QLD,-16.1048,143.1125,3
4871,Wangan,Queensland,QLD,-17.5775,146.0067,4
4871,Fossilbrook,Queensland,QLD,-17.2018,145.1662,3
4871,Northhead,Queensland,QLD,-17.2018,145.1662,3
4871,Germantown,Queensland,QLD,-16.1048,143.1125,3
4871,Lakeland,Queensland,QLD,-15.9707,144.8036,4
4871,Edward River,Queensland,QLD,-16.1048,143.1125,3
4871,Thornborough,Queensland,QLD,-17.2018,145.1662,3
4871,Conjuboy,Queensland,QLD,-17.2018,145.1662,3
4871,Southedge,Queensland,QLD,-17.2018,145.1662,3
4871,Bulleringa,Queensland,QLD,-17.2018,145.1662,3
4871,Waugh Pocket,Queensland,QLD,-17.2018,145.1662,3
4871,Pormpuraaw,Queensland,QLD,-14.6611,141.835,3
4871,Bramston Beach,Queensland,QLD,-17.3518,146.0231,4
4871,Almaden,Queensland,QLD,-17.3382,144.6771,4
4871,Rookwood,Queensland,QLD,-17.2018,145.1662,3
4871,Warrubullen,Queensland,QLD,-17.6913,146.0167,4
4871,Nychum,Queensland,QLD,-17.2018,145.1662,3
4872,Kairi,Queensland,QLD,-17.2144,145.544,4
4872,Silver Valley,Queensland,QLD,-17.9971,145.26,3
4872,Mutchilba,Queensland,QLD,-17.1333,145.2167,4
4872,Barrine,Queensland,QLD,-17.2,145.6333,4
4872,Innot Hot Springs,Queensland,QLD,-17.6667,145.25,4
4872,Wairuna,Queensland,QLD,-17.9971,145.26,3
4872,Mount Garnet,Queensland,QLD,-17.6766,145.1143,4
4872,Kowrowa,Queensland,QLD,-16.8167,145.5833,4
4872,Danbulla,Queensland,QLD,-17.15,145.6167,4
4872,Munderra,Queensland,QLD,-17.2313,145.4035,3
4872,Dimbulah,Queensland,QLD,-17.1475,145.1097,4
4872,Barwidgi,Queensland,QLD,-17.2313,145.4035,3
4872,Tinaroo,Queensland,QLD,-17.1712,145.5494,4
4872,Cairns Mc,Queensland,QLD,-17.2313,145.4035,3
4872,Walkamin,Queensland,QLD,-17.1368,145.4172,4
4872,Koombooloomba,Queensland,QLD,-17.9971,145.26,3
4872,Forty Mile,Queensland,QLD,-17.2313,145.4035,3
4872,Lake Tinaroo,Queensland,QLD,-17.9971,145.26,3
4872,Minnamoolka,Queensland,QLD,-17.9971,145.26,3
4872,Glen Ruth,Queensland,QLD,-17.9971,145.26,3
4872,Gunnawarra,Queensland,QLD,-17.9971,145.26,3
4872,Kirrama,Queensland,QLD,-17.9971,145.26,3
4873,Cow Bay,Queensland,QLD,-16.2254,145.4286,4
4873,Forest Creek,Queensland,QLD,-16.2374,145.3658,4
4873,Syndicate,Queensland,QLD,-16.3447,145.3956,3
4873,Dagmar,Queensland,QLD,-16.3447,145.3956,3
4873,Bamboo,Queensland,QLD,-14.6417,143.4742,4
4873,Kimberley,Queensland,QLD,-16.2562,145.4387,4
4873,Daintree,Queensland,QLD,-16.25,145.3193,4
4873,Dedin,Queensland,QLD,-16.3447,145.3956,3
4873,Whyanbeel,Queensland,QLD,-16.3642,145.3597,4
4873,Shannonvale,Queensland,QLD,-16.4901,145.3775,4
4873,Diwan,Queensland,QLD,-16.1902,145.415,4
4873,Low Isles,Queensland,QLD,-16.3447,145.3956,3
4873,Cassowary,Queensland,QLD,-16.516,145.4123,4
4873,Rocky Point,Queensland,QLD,-16.3907,145.4056,4
4873,Miallo,Queensland,QLD,-16.3997,145.3803,4
4873,Noah,Queensland,QLD,-16.3447,145.3956,3
4873,Finlayvale,Queensland,QLD,-16.4666,145.3451,4
4873,Bonnie Doon,Queensland,QLD,-16.4491,145.3806,4
4873,Mossman,Queensland,QLD,-16.4605,145.3719,4
4873,Upper Daintree,Queensland,QLD,-16.239,145.3059,4
4873,Newell,Queensland,QLD,-16.4264,145.406,4
4873,Spurgeon,Queensland,QLD,-16.3447,145.3956,3
4873,Stewart Creek Valley,Queensland,QLD,-16.298,145.3151,4
4873,Cape Tribulation,Queensland,QLD,-16.0867,145.4618,4
4873,Mossman Gorge,Queensland,QLD,-16.4663,145.3633,4
4873,Cooya Beach,Queensland,QLD,-16.4481,145.4057,4
4873,Wonga,Queensland,QLD,-16.346,145.412,4
4873,Thornton Beach,Queensland,QLD,-16.3447,145.3956,3
4873,Lower Daintree,Queensland,QLD,-16.276,145.3821,4
4874,Nanum,Queensland,QLD,-12.6401,141.8636,3
4874,Weipa,Queensland,QLD,-12.6235,141.8788,4
4874,Mapoon,Queensland,QLD,-12.019,141.9006,4
4874,Napranum,Queensland,QLD,-12.6832,141.89,4
4874,Evans Landing,Queensland,QLD,-12.6623,141.8526,4
4874,Rocky Point,Queensland,QLD,-12.5776,141.9603,3
4874,Wenlock,Queensland,QLD,-13.0876,142.9476,4
4874,Trunding,Queensland,QLD,-12.6341,141.871,3
4874,Weipa Airport,Queensland,QLD,-12.5776,141.9603,3
4874,Jardine River,Queensland,QLD,-16.913,145.4479,3
4874,Mission River,Queensland,QLD,-12.5951,141.9576,4
4874,Shelburne,Queensland,QLD,-16.913,145.4479,3
4875,Prince Of Wales,Queensland,QLD,-16.913,145.4479,1
4875,Murray Island,Queensland,QLD,-16.913,145.4479,1
4875,Boigu Island,Queensland,QLD,-16.913,145.4479,1
4875,Badu Island,Queensland,QLD,-10.1201,142.1394,1
4875,Dauan Island,Queensland,QLD,-16.913,145.4479,1
4875,Thursday Island,Queensland,QLD,-10.5838,142.2206,1
4875,Erub,Queensland,QLD,-16.913,145.4479,1
4875,Coconut Island,Queensland,QLD,-16.913,145.4479,1
4875,Yorke Island,Queensland,QLD,-16.913,145.4479,1
4875,Stephens Island,Queensland,QLD,-16.913,145.4479,1
4875,Kubin Village,Queensland,QLD,-16.913,145.4479,1
4875,Warraber Island,Queensland,QLD,-16.913,145.4479,1
4875,Horn Island,Queensland,QLD,-16.913,145.4479,1
4875,Saibai Island,Queensland,QLD,-16.913,145.4479,1
4875,Mabuiag Island,Queensland,QLD,-16.913,145.4479,1
4875,Moa Island,Queensland,QLD,-16.913,145.4479,1
4875,Yam Island,Queensland,QLD,-16.913,145.4479,1
4876,Umagico,Queensland,QLD,-16.913,145.4479,1
4876,New Mapoon,Queensland,QLD,-16.913,145.4479,1
4876,Seisia,Queensland,QLD,-16.913,145.4479,1
4876,Injinoo,Queensland,QLD,-16.913,145.4479,1
4876,Bamaga,Queensland,QLD,-10.8517,142.427,1
4877,Oak Beach,Queensland,QLD,-16.5976,145.515,4
4877,Mowbray,Queensland,QLD,-16.5602,145.4794,4
4877,Craiglie,Queensland,QLD,-16.5379,145.4696,4
4877,Killaloe,Queensland,QLD,-16.4918,145.4206,4
4877,Wangetti,Queensland,QLD,-16.6593,145.5664,4
4877,Port Douglas,Queensland,QLD,-16.4838,145.4673,4
4878,Machans Beach,Queensland,QLD,-16.8585,145.7447,4
4878,Caravonica,Queensland,QLD,-16.8666,145.6784,4
4878,Yorkeys Knob,Queensland,QLD,-16.8028,145.7208,4
4878,Holloways Beach,Queensland,QLD,-16.8421,145.7392,4
4878,Smithfield,Queensland,QLD,-16.825,145.6917,4
4878,Barron,Queensland,QLD,-16.8367,145.7022,3
4879,Kewarra Beach,Queensland,QLD,-16.7822,145.6845,4
4879,Clifton Beach,Queensland,QLD,-16.7631,145.6717,4
4879,Ellis Beach,Queensland,QLD,-16.7284,145.6642,4
4879,Trinity Beach,Queensland,QLD,-16.7888,145.6968,4
4879,Palm Cove,Queensland,QLD,-16.75,145.6667,4
4879,Trinity Park,Queensland,QLD,-16.8057,145.7008,3
4880,Chewko,Queensland,QLD,-17.0667,145.3833,4
4880,Mareeba,Queensland,QLD,-16.995,145.4232,4
4880,Biboohra,Queensland,QLD,-16.9333,145.4167,4
4880,Glen Russell,Queensland,QLD,-16.9781,145.3764,3
4880,Paddys Green,Queensland,QLD,-16.9176,145.2825,4
4880,Arriga,Queensland,QLD,-16.9781,145.3764,3
4881,Speewah,Queensland,QLD,-16.8908,145.6257,4
4881,Koah,Queensland,QLD,-16.8333,145.5167,4
4881,Kuranda,Queensland,QLD,-16.8198,145.6382,4
4881,Mona Mona,Queensland,QLD,-16.7126,145.5368,4
4882,Tolga,Queensland,QLD,-17.2333,145.4833,4
4883,Carrington,Queensland,QLD,-17.3435,145.496,3
4883,Atherton,Queensland,QLD,-17.2686,145.4752,4
4883,Upper Barron,Queensland,QLD,-17.3833,145.0167,4
4883,East Barron,Queensland,QLD,-17.3167,145.5333,4
4883,Wongabel,Queensland,QLD,-17.3167,145.4833,4
4884,Lake Eacham,Queensland,QLD,-17.2998,145.6284,4
4884,Yungaburra,Queensland,QLD,-17.2717,145.5855,4
4884,Lake Barrine,Queensland,QLD,-17.2836,145.6165,3
4884,Gadgarra,Queensland,QLD,-17.2836,145.6165,3
4885,Peeramon,Queensland,QLD,-17.3167,145.6167,4
4885,Topaz,Queensland,QLD,-17.4167,145.7167,4
4885,North Johnstone,Queensland,QLD,-17.3262,145.6611,4
4885,Malanda,Queensland,QLD,-17.3523,145.5953,4
4885,Kureen,Queensland,QLD,-17.3333,145.6,4
4885,Glen Allyn,Queensland,QLD,-17.3646,145.631,3
4885,Butchers Creek,Queensland,QLD,-17.3241,145.6895,4
4885,Tarzali,Queensland,QLD,-17.4333,145.6,4
4885,Jaggan,Queensland,QLD,-17.4,145.6,4
4886,Beatrice,Queensland,QLD,-17.4991,145.5987,3
4886,Mungalli,Queensland,QLD,-17.4991,145.5987,3
4886,Millaa Millaa,Queensland,QLD,-17.5139,145.6129,4
4886,Minbun,Queensland,QLD,-17.4833,145.5833,4
4886,Moregatta,Queensland,QLD,-17.5,145.6,4
4886,Maalan,Queensland,QLD,-17.4991,145.5987,3
4886,Ellinjaa,Queensland,QLD,-17.4991,145.5987,3
4886,Middlebrook,Queensland,QLD,-17.4991,145.5987,3
4887,Wondecla,Queensland,QLD,-17.4167,145.4,4
4887,Irvinebank,Queensland,QLD,-17.4333,145.2167,4
4887,Herberton,Queensland,QLD,-17.3784,145.3837,4
4887,Watsonville,Queensland,QLD,-17.3833,145.3167,4
4887,Kalunga,Queensland,QLD,-17.45,145.3833,4
4887,Moomin,Queensland,QLD,-17.3818,145.2063,3
4888,Tumoulin,Queensland,QLD,-17.5667,145.4667,4
4888,Millstream,Queensland,QLD,-17.6267,145.3756,4
4888,Ravenshoe,Queensland,QLD,-17.6076,145.4809,4
4888,Kaban,Queensland,QLD,-17.5167,145.4167,4
4888,Evelyn,Queensland,QLD,-17.5167,145.5167,4
4890,Normanton,Queensland,QLD,-17.6718,141.0772,4
4890,Howitt,Queensland,QLD,-17.461,141.1801,4
4891,Karumba,Queensland,QLD,-17.4869,140.8426,4
4892,Archer River,Queensland,QLD,-13.4384,142.9403,4
4892,Lyndside,Queensland,QLD,-15.7071,145.2665,3
4892,Aurukun,Queensland,QLD,-13.6057,141.8279,4
4892,Coen,Queensland,QLD,-13.9456,143.199,4
4892,Wrotham,Queensland,QLD,-15.7071,145.2665,3
4892,Lakefield,Queensland,QLD,-15.7071,145.2665,3
4892,West Wellesley Islands,Queensland,QLD,-15.7071,145.2665,3
4892,Bellevue,Queensland,QLD,-15.7071,145.2665,3
4892,Staaten,Queensland,QLD,-15.7071,145.2665,3
4892,Highbury,Queensland,QLD,-15.7071,145.2665,3
4892,Yagoonya,Queensland,QLD,-15.7071,145.2665,3
4892,Lizard,Queensland,QLD,-14.6641,145.464,4
4892,Ravensworth,Queensland,QLD,-15.7071,145.2665,3
4892,Yarraden,Queensland,QLD,-14.3019,143.3133,4
4892,Lockhart River,Queensland,QLD,-12.7872,143.3435,4
4892,South Wellesley Islands,Queensland,QLD,-17.0909,139.5656,4
4892,Groganville,Queensland,QLD,-15.7071,145.2665,3
4892,Mount Mulgrave,Queensland,QLD,-15.7071,145.2665,3
4892,Laura,Queensland,QLD,-15.5691,144.4702,4
4892,Dixie,Queensland,QLD,-15.1139,143.3136,4
4892,Kowanyama,Queensland,QLD,-15.4736,141.7457,4
4892,Palmer,Queensland,QLD,-15.7071,145.2665,3
4892,Wellesley Islands,Queensland,QLD,-16.6318,139.4014,4
4892,Gununa,Queensland,QLD,-16.6657,139.1801,4
4892,Pormpuraaw,Queensland,QLD,-14.8988,141.6212,4
4892,Lockhart,Queensland,QLD,-15.7071,145.2665,3
4892,Holroyd River,Queensland,QLD,-15.7071,145.2665,3
4892,Arbouin,Queensland,QLD,-15.7071,145.2665,3
4892,Red River,Queensland,QLD,-15.7071,145.2665,3
4892,Maramie,Queensland,QLD,-15.7071,145.2665,3
4892,Edward River,Queensland,QLD,-15.7071,145.2665,3
4892,Gamboola,Queensland,QLD,-15.7071,145.2665,3
4892,Abingdon Downs,Queensland,QLD,-15.7071,145.2665,3
4895,Degarra,Queensland,QLD,-15.9522,145.3473,4
4895,Hope Vale,Queensland,QLD,-15.2956,145.1113,4
4895,Wujal Wujal,Queensland,QLD,-15.9456,145.3185,4
4895,Cooktown,Queensland,QLD,-15.4678,145.2487,4
4895,Ayton,Queensland,QLD,-15.9216,145.3525,4
4895,Starcke,Queensland,QLD,-15.7071,145.2665,3
4895,Rossville,Queensland,QLD,-15.6667,145.2667,4
4895,Bloomfield,Queensland,QLD,-15.9333,145.3333,4
4895,Helenvale,Queensland,QLD,-15.7167,145.2333,4
9000,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9001,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9002,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9005,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9007,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9008,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9009,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9010,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9013,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9015,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9016,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9017,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9018,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9019,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9020,Brisbane,Queensland,QLD,-27.4679,153.0281,4
9021,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9022,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9023,Brisbane GPO Boxes,Queensland,QLD,-27.4679,153.0281,1
9464,Northgate Mc,Queensland,QLD,-33.8816,150.9844,
9726,Gold Coast Mc,Queensland,QLD,-33.8816,150.9844,
9943,Mackay,Queensland,QLD,-21.1534,149.1655,4
9944,Cannonvale,Queensland,QLD,-20.2768,148.6976,4
872,Nyapari,South Australia,SA,-23.3256,133.0557,3
872,Fregon,South Australia,SA,-26.7639,132.0277,3
872,Mimili,South Australia,SA,-23.3256,133.0557,3
872,Indulkana,South Australia,SA,-26.96,133.3058,3
872,Ernabella,South Australia,SA,-23.7917,132.6818,3
5000,Adelaide,South Australia,SA,-34.9287,138.5986,4
5000,Rundle Mall,South Australia,SA,-34.9286,138.6007,3
5000,Hutt Street,South Australia,SA,-34.9286,138.6007,3
5000,Halifax Street,South Australia,SA,-34.9286,138.6007,3
5000,Station Arcade,South Australia,SA,-34.9286,138.6007,3
5000,Adelaide Bc,South Australia,SA,-34.9286,138.6007,3
5000,City West Campus,South Australia,SA,-34.9287,138.5986,3
5000,Sturt Street,South Australia,SA,-34.9287,138.5986,3
5001,Adelaide,South Australia,SA,-34.9287,138.5986,4
5005,University Of Adelaide,South Australia,SA,-34.9006,138.5702,1
5005,The University Of Adelaide,South Australia,SA,-34.9006,138.5702,1
5005,Adelaide University,South Australia,SA,-34.9006,138.5702,1
5006,North Adelaide,South Australia,SA,-34.9073,138.5914,4
5006,North Adelaide Melbourne St,South Australia,SA,-34.9006,138.5702,3
5007,Welland,South Australia,SA,-34.9,138.5333,4
5007,West Hindmarsh,South Australia,SA,-34.9079,138.5641,4
5007,Hindmarsh,South Australia,SA,-34.9014,138.575,4
5007,Brompton,South Australia,SA,-34.8944,138.5782,4
5007,Bowden,South Australia,SA,-34.9026,138.5791,4
5008,Dudley Park,South Australia,SA,-34.8756,138.5773,4
5008,West Croydon,South Australia,SA,-34.8945,138.5593,4
5008,Croydon,South Australia,SA,-34.9006,138.5702,3
5008,Devon Park,South Australia,SA,-34.8865,138.5823,4
5008,Renown Park,South Australia,SA,-34.8917,138.5773,4
5008,Ridleyton,South Australia,SA,-34.8928,138.5716,4
5008,Croydon Park,South Australia,SA,-34.8803,138.5678,4
5009,Kilkenny,South Australia,SA,-34.8791,138.5529,4
5009,Beverley,South Australia,SA,-34.8951,138.5463,4
5009,Allenby Gardens,South Australia,SA,-34.8977,138.5542,4
5010,Regency Park,South Australia,SA,-34.8626,138.5724,4
5010,Regency Park Bc,South Australia,SA,-34.8626,138.5678,3
5010,Ferryden Park,South Australia,SA,-34.8662,138.5618,4
5010,Angle Park,South Australia,SA,-34.8599,138.558,4
5011,Woodville Park,South Australia,SA,-34.887,138.5465,4
5011,St Clair,South Australia,SA,-34.8833,138.55,3
5011,Woodville South,South Australia,SA,-34.8819,138.5348,4
5011,Woodville West,South Australia,SA,-34.8864,138.5309,4
5011,Woodville,South Australia,SA,-34.8833,138.55,4
5012,Woodville Gardens,South Australia,SA,-34.8679,138.548,4
5012,Mansfield Park,South Australia,SA,-34.8566,138.5519,4
5012,Woodville North,South Australia,SA,-34.872,138.5431,4
5012,Athol Park,South Australia,SA,-34.8561,138.5428,4
5013,Rosewater East,South Australia,SA,-34.8391,138.5496,3
5013,Gillman,South Australia,SA,-34.8409,138.5282,4
5013,Ottoway,South Australia,SA,-34.8476,138.5357,4
5013,Pennington,South Australia,SA,-34.8576,138.5316,4
5013,Rosewater,South Australia,SA,-34.852,138.5216,4
5013,Wingfield,South Australia,SA,-34.8498,138.5674,4
5014,Cheltenham,South Australia,SA,-34.8667,138.5167,4
5014,Queenstown,South Australia,SA,-34.8609,138.5105,4
5014,Hendon,South Australia,SA,-34.9,138.5167,4
5014,Royal Park,South Australia,SA,-34.8742,138.5119,4
5014,Albert Park,South Australia,SA,-34.872,138.5197,4
5014,Alberton,South Australia,SA,-34.8593,138.5214,4
5015,Port Adelaide,South Australia,SA,-34.85,138.4667,4
5015,Ethelton,South Australia,SA,-34.8487,138.491,4
5015,Glanville,South Australia,SA,-34.8462,138.4898,4
5015,New Port,South Australia,SA,-34.8417,138.4833,3
5015,Birkenhead,South Australia,SA,-34.8368,138.4979,4
5015,Port Adelaide DC,South Australia,SA,-34.8417,138.4833,3
5016,Peterhead,South Australia,SA,-34.8336,138.491,4
5016,Largs North,South Australia,SA,-34.8205,138.4924,4
5016,Largs Bay,South Australia,SA,-34.8258,138.4862,4
5017,Osborne,South Australia,SA,-34.798,138.498,4
5017,Taperoo,South Australia,SA,-34.8043,138.4959,4
5018,Outer Harbor,South Australia,SA,-34.7766,138.4874,4
5018,North Haven,South Australia,SA,-34.7883,138.4916,4
5019,Semaphore,South Australia,SA,-34.8393,138.4823,4
5019,Semaphore Park,South Australia,SA,-34.8507,138.4789,4
5019,Semaphore South,South Australia,SA,-34.8498,138.4795,4
5019,Exeter,South Australia,SA,-34.8399,138.489,4
5020,West Lakes Shore,South Australia,SA,-34.8601,138.4897,4
5021,West Lakes,South Australia,SA,-34.873,138.4946,4
5022,Henley Beach South,South Australia,SA,-34.9259,138.4955,4
5022,Henley Beach,South Australia,SA,-34.9203,138.4944,4
5022,Tennyson,South Australia,SA,-34.886,138.4859,4
5022,Grange,South Australia,SA,-34.9022,138.4902,4
5023,Seaton,South Australia,SA,-34.9,138.5167,4
5023,Findon,South Australia,SA,-34.9,138.5333,4
5024,West Beach,South Australia,SA,-34.946,138.5093,4
5024,Fulham,South Australia,SA,-34.9274,138.5137,4
5024,Fulham Gardens,South Australia,SA,-34.9148,138.5132,4
5025,Flinders Park,South Australia,SA,-34.9103,138.5432,4
5025,Kidman Park,South Australia,SA,-34.9122,138.5269,4
5031,Torrensville Plaza,South Australia,SA,-34.9441,138.5648,3
5031,Mile End,South Australia,SA,-34.9265,138.5703,4
5031,Torrensville,South Australia,SA,-34.9106,138.5605,4
5031,Mile End South,South Australia,SA,-34.9378,138.5704,4
5031,Thebarton,South Australia,SA,-34.9167,138.5667,4
5032,Lockleys,South Australia,SA,-34.9259,138.528,4
5032,Brooklyn Park,South Australia,SA,-34.9285,138.5363,4
5032,Underdale,South Australia,SA,-34.918,138.547,4
5033,Marleston,South Australia,SA,-34.9476,138.5596,4
5033,Hilton,South Australia,SA,-34.932,138.5643,4
5033,Marleston DC,South Australia,SA,-34.9355,138.5617,3
5033,Cowandilla,South Australia,SA,-34.9322,138.5593,4
5033,West Richmond,South Australia,SA,-34.9387,138.5521,4
5033,Hilton Plaza,South Australia,SA,-34.9441,138.5648,3
5033,Richmond,South Australia,SA,-34.942,138.5632,4
5034,Wayville,South Australia,SA,-34.95,138.5833,4
5034,Millswood,South Australia,SA,-34.955,138.5889,4
5034,Goodwood,South Australia,SA,-34.9509,138.5923,4
5034,Kings Park,South Australia,SA,-34.9638,138.5909,4
5034,Clarence Park,South Australia,SA,-34.9642,138.5806,4
5035,Black Forest,South Australia,SA,-34.9603,138.5767,4
5035,Keswick,South Australia,SA,-34.9418,138.5746,4
5035,Forestville,South Australia,SA,-34.9495,138.5785,4
5035,Ashford,South Australia,SA,-34.9486,138.5746,4
5035,Everard Park,South Australia,SA,-34.952,138.5748,4
5035,Keswick Terminal,South Australia,SA,-34.9411,138.5804,4
5037,Kurralta Park,South Australia,SA,-34.9514,138.567,4
5037,North Plympton,South Australia,SA,-34.9577,138.5534,4
5037,Netley,South Australia,SA,-34.9489,138.5498,4
5037,Glandore,South Australia,SA,-34.9615,138.5683,4
5038,Camden Park,South Australia,SA,-34.9686,138.5396,4
5038,Plympton,South Australia,SA,-34.95,138.55,4
5038,Plympton Park,South Australia,SA,-34.9809,138.547,4
5038,South Plympton,South Australia,SA,-34.9768,138.5559,4
5039,Melrose Park,South Australia,SA,-34.9833,138.5754,4
5039,Clarence Gardens,South Australia,SA,-34.9754,138.5798,4
5039,Edwardstown,South Australia,SA,-34.9802,138.5694,4
5039,Melrose Park DC,South Australia,SA,-34.9827,138.5741,3
5040,Novar Gardens,South Australia,SA,-34.9733,138.5383,4
5041,Cumberland Park,South Australia,SA,-34.972,138.5867,4
5041,Westbourne Park,South Australia,SA,-34.9716,138.5952,4
5041,Daw Park,South Australia,SA,-34.9898,138.5841,4
5041,Panorama,South Australia,SA,-34.9908,138.5995,4
5041,Colonel Light Gardens,South Australia,SA,-34.9863,138.5975,4
5042,St Marys,South Australia,SA,-35.0042,138.5798,4
5042,Bedford Park,South Australia,SA,-35.0333,138.5667,4
5042,Pasadena,South Australia,SA,-35.0027,138.5886,4
5042,Clovelly Park,South Australia,SA,-34.9995,138.5708,4
5042,Flinders University,South Australia,SA,-35.0069,138.5513,3
5043,Morphettville,South Australia,SA,-34.9871,138.544,4
5043,Marion,South Australia,SA,-35.0167,138.5667,4
5043,Mitchell Park,South Australia,SA,-35.0051,138.5623,4
5043,Park Holme,South Australia,SA,-34.9888,138.552,4
5043,Ascot Park,South Australia,SA,-34.9881,138.561,4
5044,Somerton Park,South Australia,SA,-34.9971,138.5215,4
5044,Glengowrie,South Australia,SA,-34.9871,138.5367,4
5045,Glenelg Jetty Road,South Australia,SA,-34.9833,138.5167,3
5045,Glenelg East,South Australia,SA,-34.9764,138.5296,4
5045,Glenelg North,South Australia,SA,-34.9679,138.5175,4
5045,Glenelg South,South Australia,SA,-34.9808,138.515,3
5045,Glenelg,South Australia,SA,-34.9808,138.515,4
5046,Oaklands Park,South Australia,SA,-35.0141,138.5384,4
5046,Warradale North,South Australia,SA,-35.0069,138.5513,3
5046,Warradale,South Australia,SA,-35.0074,138.5356,4
5047,Seacombe Heights,South Australia,SA,-35.0326,138.5458,4
5047,Seacombe Gardens,South Australia,SA,-35.0234,138.5458,4
5047,Darlington,South Australia,SA,-35.0333,138.5667,4
5047,Sturt,South Australia,SA,-35.0167,138.55,4
5048,South Brighton,South Australia,SA,-35.0277,138.528,4
5048,North Brighton,South Australia,SA,-35.0037,138.5218,4
5048,Brighton,South Australia,SA,-35.0182,138.5235,4
5048,Hove,South Australia,SA,-35.0112,138.523,4
5048,Dover Gardens,South Australia,SA,-35.024,138.537,4
5049,Seacliff Park,South Australia,SA,-35.0298,138.5313,4
5049,Seaview Downs,South Australia,SA,-35.037,138.538,4
5049,Kingston Park,South Australia,SA,-35.0382,138.5163,4
5049,Marino,South Australia,SA,-35.0447,138.5124,4
5049,Seacliff,South Australia,SA,-35.0347,138.5229,4
5050,Eden Hills,South Australia,SA,-35.0211,138.5957,4
5050,Bellevue Heights,South Australia,SA,-35.0302,138.5831,4
5051,Blackwood,South Australia,SA,-35.0214,138.6143,4
5051,Coromandel Valley,South Australia,SA,-35.05,138.6167,4
5051,Hawthorndene,South Australia,SA,-35.0223,138.6304,4
5051,Craigburn Farm,South Australia,SA,-35.05,138.6167,4
5052,Belair,South Australia,SA,-34.9977,138.6208,4
5052,Glenalta,South Australia,SA,-35.0079,138.6259,4
5061,Malvern,South Australia,SA,-34.9592,138.6125,4
5061,Unley DC,South Australia,SA,-34.9546,138.6063,3
5061,Unley Park,South Australia,SA,-34.9639,138.6078,4
5061,Unley BC,South Australia,SA,-34.9546,138.6063,3
5061,Unley,South Australia,SA,-34.95,138.6,4
5061,Hyde Park,South Australia,SA,-34.9547,138.6011,4
5062,Kingswood,South Australia,SA,-34.9671,138.6077,4
5062,Hawthorn,South Australia,SA,-34.9738,138.6074,4
5062,Netherby,South Australia,SA,-34.9705,138.625,4
5062,Brown Hill Creek,South Australia,SA,-34.9868,138.6421,4
5062,Springfield,South Australia,SA,-34.9782,138.6325,4
5062,Lower Mitcham,South Australia,SA,-34.9773,138.6049,4
5062,Mitcham,South Australia,SA,-34.9789,138.6218,4
5062,Torrens Park,South Australia,SA,-34.9761,138.6103,4
5062,Clapham,South Australia,SA,-34.9899,138.6016,4
5062,Mitcham Shopping Centre,South Australia,SA,-34.9788,138.6166,3
5062,Lynton,South Australia,SA,-34.9973,138.6074,4
5063,Parkside,South Australia,SA,-34.9451,138.613,4
5063,Frewville,South Australia,SA,-34.9476,138.6306,4
5063,Eastwood,South Australia,SA,-34.9423,138.6218,4
5063,Fullarton,South Australia,SA,-34.951,138.629,4
5063,Highgate,South Australia,SA,-34.9618,138.6218,4
5064,Mount Osmond,South Australia,SA,-34.9629,138.6566,4
5064,Urrbrae,South Australia,SA,-34.9663,138.6422,4
5064,Glen Osmond,South Australia,SA,-34.9596,138.6487,4
5064,Myrtle Bank,South Australia,SA,-34.9606,138.6338,4
5064,St Georges,South Australia,SA,-34.952,138.6479,4
5064,Glenunga,South Australia,SA,-34.9501,138.6384,4
5065,Linden Park,South Australia,SA,-34.9473,138.6529,4
5065,Tusmore,South Australia,SA,-34.9389,138.6506,4
5065,Dulwich,South Australia,SA,-34.9365,138.6272,4
5065,Glenside,South Australia,SA,-34.9427,138.6338,4
5065,Toorak Gardens,South Australia,SA,-34.9348,138.6364,4
5066,Stonyfell,South Australia,SA,-34.9342,138.6767,4
5066,Burnside,South Australia,SA,-34.9383,138.6673,3
5066,Erindale,South Australia,SA,-34.9278,138.6631,4
5066,Beaumont,South Australia,SA,-34.949,138.6619,4
5066,Waterfall Gully,South Australia,SA,-34.9667,138.679,4
5066,Wattle Park,South Australia,SA,-34.9252,138.675,4
5066,Hazelwood Park,South Australia,SA,-34.9395,138.6588,4
5067,Beulah Park,South Australia,SA,-34.9177,138.6452,4
5067,Rose Park,South Australia,SA,-34.9276,138.626,4
5067,Norwood,South Australia,SA,-34.9273,138.6285,4
5067,Kent Town,South Australia,SA,-34.9216,138.6201,4
5067,Norwood South,South Australia,SA,-34.9167,138.65,3
5068,Kensington Gardens,South Australia,SA,-34.9258,138.6615,4
5068,Heathpool,South Australia,SA,-34.9314,138.6463,4
5068,Marryatville,South Australia,SA,-34.9284,138.646,4
5068,Leabrook,South Australia,SA,-34.9298,138.6586,4
5068,Kensington Park,South Australia,SA,-34.9202,138.6543,4
5068,Trinity Gardens,South Australia,SA,-34.9144,138.6414,4
5068,St Morris,South Australia,SA,-34.9111,138.6536,4
5068,Kensington,South Australia,SA,-34.9167,138.65,4
5069,Stepney,South Australia,SA,-34.9123,138.6292,4
5069,College Park,South Australia,SA,-34.9117,138.6198,4
5069,St Peters,South Australia,SA,-34.905,138.6225,4
5069,Hackney,South Australia,SA,-34.9139,138.6164,4
5069,Evandale,South Australia,SA,-34.9055,138.6354,4
5069,Maylands,South Australia,SA,-34.9113,138.6367,4
5070,Payneham,South Australia,SA,-34.8972,138.6406,4
5070,Glynde DC,South Australia,SA,-34.8988,138.6447,3
5070,Royston Park,South Australia,SA,-34.9004,138.6361,4
5070,Glynde Plaza,South Australia,SA,-34.8994,138.6696,3
5070,Felixstow,South Australia,SA,-34.889,138.647,4
5070,Joslin,South Australia,SA,-34.8997,138.6265,4
5070,Glynde,South Australia,SA,-34.896,138.6525,4
5070,Firle,South Australia,SA,-34.9046,138.6532,4
5070,Marden,South Australia,SA,-34.8957,138.6378,4
5070,Payneham South,South Australia,SA,-34.9048,138.6445,4
5071,Kent Town DC,South Australia,SA,-34.9216,138.6201,3
5071,Kent Town,South Australia,SA,-34.9216,138.6201,4
5072,Magill South,South Australia,SA,-34.9091,138.675,4
5072,Rosslyn Park,South Australia,SA,-34.9219,138.6755,4
5072,Skye,South Australia,SA,-34.9245,138.6928,4
5072,Magill,South Australia,SA,-34.9091,138.675,4
5072,Auldana,South Australia,SA,-34.918,138.6869,4
5072,Woodforde,South Australia,SA,-34.9051,138.6896,4
5072,Magill North,South Australia,SA,-34.8994,138.6696,3
5072,Teringie,South Australia,SA,-34.9143,138.7023,4
5073,Tranmere,South Australia,SA,-34.9055,138.6621,4
5073,Tranmere North,South Australia,SA,-34.8994,138.6696,3
5073,Rostrevor,South Australia,SA,-34.8939,138.6918,4
5073,Hectorville,South Australia,SA,-34.8916,138.6589,4
5074,Newton,South Australia,SA,-34.8821,138.6825,4
5074,Campbelltown,South Australia,SA,-34.8833,138.6667,4
5075,Paradise,South Australia,SA,-34.8736,138.669,4
5075,Dernancourt,South Australia,SA,-34.862,138.6766,4
5076,Athelstone,South Australia,SA,-34.8702,138.6999,4
5076,Castambul,South Australia,SA,-34.8715,138.7055,3
5081,Gilberton,South Australia,SA,-34.9005,138.6126,4
5081,Vale Park,South Australia,SA,-34.886,138.6271,4
5081,Medindie,South Australia,SA,-34.896,138.605,4
5081,Walkerville,South Australia,SA,-34.8711,138.6232,3
5081,Collinswood,South Australia,SA,-34.8873,138.6122,4
5081,Medindie Gardens,South Australia,SA,-34.8909,138.6039,4
5082,Fitzroy,South Australia,SA,-34.8968,138.5914,4
5082,Prospect East,South Australia,SA,-34.8845,138.5925,3
5082,Ovingham,South Australia,SA,-34.8986,138.5852,4
5082,Thorngate,South Australia,SA,-34.8978,138.5983,4
5082,Prospect,South Australia,SA,-34.8824,138.5933,4
5083,Broadview,South Australia,SA,-34.8759,138.615,4
5083,Sefton Park,South Australia,SA,-34.874,138.6034,4
5083,Nailsworth,South Australia,SA,-34.8844,138.6062,4
5084,Kilburn North,South Australia,SA,-34.8595,138.59,3
5084,Kilburn,South Australia,SA,-34.8597,138.5856,4
5084,Blair Athol,South Australia,SA,-34.8589,138.5966,4
5085,Northgate,South Australia,SA,-34.8578,138.6291,4
5085,Northfield,South Australia,SA,-34.85,138.6333,4
5085,Clearview,South Australia,SA,-34.8573,138.6133,4
5085,Enfield,South Australia,SA,-34.8526,138.6026,4
5085,Enfield Plaza,South Australia,SA,-34.8711,138.6232,3
5086,Oakden,South Australia,SA,-34.8528,138.6433,4
5086,Manningham,South Australia,SA,-34.8711,138.6232,3
5086,Gilles Plains,South Australia,SA,-34.85,138.65,4
5086,Greenacres,South Australia,SA,-34.8685,138.628,4
5086,Hillcrest,South Australia,SA,-34.8625,138.6431,4
5086,Hampstead Gardens,South Australia,SA,-34.8745,138.6288,4
5087,Windsor Gardens,South Australia,SA,-34.8654,138.647,4
5087,Klemzig,South Australia,SA,-34.8793,138.6356,4
5088,Holden Hill,South Australia,SA,-34.8516,138.6723,4
5089,Highbury,South Australia,SA,-34.8529,138.6975,4
5090,Hope Valley,South Australia,SA,-34.8333,138.7,4
5091,Tea Tree Gully,South Australia,SA,-34.8216,138.7353,3
5091,Vista,South Australia,SA,-34.8355,138.7232,4
5091,Banksia Park,South Australia,SA,-34.8102,138.7357,4
5092,Modbury North DC,South Australia,SA,-34.8333,138.6833,3
5092,Modbury,South Australia,SA,-34.8333,138.6833,4
5092,Modbury North,South Australia,SA,-34.8285,138.6739,4
5092,Modbury Heights,South Australia,SA,-34.8097,138.6854,4
5093,Valley View,South Australia,SA,-34.8399,138.6607,4
5093,Para Vista,South Australia,SA,-34.8209,138.6611,4
5094,Gepps Cross,South Australia,SA,-34.8415,138.5956,4
5094,Dry Creek,South Australia,SA,-34.8281,138.5547,4
5094,Cavan,South Australia,SA,-34.8291,138.5985,4
5095,Pooraka,South Australia,SA,-34.8247,138.6264,4
5095,The Levels,South Australia,SA,-34.8149,138.6162,3
5095,Mawson Lakes,South Australia,SA,-34.8159,138.6081,4
5096,Para Hills West,South Australia,SA,-34.8047,138.6428,4
5096,Para Hills,South Australia,SA,-34.8121,138.658,4
5096,Gulfview Heights,South Australia,SA,-34.7955,138.6694,4
5097,Redwood Park,South Australia,SA,-34.8116,138.7069,4
5097,St Agnes,South Australia,SA,-34.8308,138.7107,4
5097,Ridgehaven,South Australia,SA,-34.8213,138.7047,4
5098,Ingle Farm,South Australia,SA,-34.8333,138.6333,4
5098,Walkley Heights,South Australia,SA,-34.8367,138.6437,4
5106,Parafield,South Australia,SA,-34.789,138.6374,4
5106,Salisbury South DC,South Australia,SA,-34.7746,138.6221,3
5106,Salisbury South,South Australia,SA,-34.7805,138.648,4
5106,Salisbury South BC,South Australia,SA,-34.7746,138.6221,3
5107,Parafield Gardens,South Australia,SA,-34.7825,138.6108,4
5107,Green Fields,South Australia,SA,-34.8006,138.6067,4
5108,Salisbury,South Australia,SA,-34.7667,138.6333,4
5108,Salisbury North Whites Road,South Australia,SA,-34.7619,138.6208,3
5108,Paralowie,South Australia,SA,-34.7571,138.6082,4
5108,Salisbury Downs,South Australia,SA,-34.7677,138.6221,4
5108,Salisbury North,South Australia,SA,-34.7496,138.6206,4
5109,Salisbury East Northbri Ave,South Australia,SA,-34.7874,138.6417,3
5109,Salisbury Plain,South Australia,SA,-34.7726,138.6633,4
5109,Salisbury East,South Australia,SA,-34.7905,138.6545,4
5109,Salisbury Park,South Australia,SA,-34.7615,138.6703,4
5109,Salisbury Heights,South Australia,SA,-34.7631,138.6759,4
5109,Brahma Lodge,South Australia,SA,-34.776,138.6531,4
5110,Direk,South Australia,SA,-34.7216,138.6015,4
5110,Globe Derby Park,South Australia,SA,-34.7876,138.5898,4
5110,Waterloo Corner,South Australia,SA,-34.7167,138.5833,4
5110,St Kilda,South Australia,SA,-34.7556,138.5696,3
5110,Bolivar,South Australia,SA,-34.7667,138.6,4
5110,Burton,South Australia,SA,-34.7334,138.6025,4
5111,Edinburgh,South Australia,SA,-34.7462,138.6375,4
5111,Edinburgh Raaf,South Australia,SA,-34.6697,138.6878,3
5112,Elizabeth Vale,South Australia,SA,-34.7486,138.6682,4
5112,Hillbank,South Australia,SA,-34.7359,138.6872,4
5112,Elizabeth South,South Australia,SA,-34.7318,138.6619,4
5112,Elizabeth Grove,South Australia,SA,-34.7353,138.6689,4
5112,Elizabeth,South Australia,SA,-34.7194,138.6771,4
5112,Elizabeth East,South Australia,SA,-34.7251,138.6821,4
5113,Elizabeth Downs,South Australia,SA,-34.6998,138.6913,4
5113,Davoren Park,South Australia,SA,-34.6906,138.6686,4
5113,Elizabeth West,South Australia,SA,-34.7009,138.678,3
5113,Davoren Park South,South Australia,SA,-34.6906,138.6686,4
5113,Elizabeth Park,South Australia,SA,-34.7097,138.686,4
5113,Elizabeth North,South Australia,SA,-34.7068,138.6761,4
5113,Elizabeth West DC,South Australia,SA,-34.6906,138.6686,3
5113,Edinburgh North,South Australia,SA,-34.6906,138.6686,3
5114,One Tree Hill,South Australia,SA,-34.7,138.7667,4
5114,Smithfield,South Australia,SA,-34.6833,138.6833,4
5114,Smithfield West,South Australia,SA,-34.6646,138.6807,4
5114,Sampson Flat,South Australia,SA,-34.7287,138.7801,4
5114,Uleybury,South Australia,SA,-34.6763,138.7459,4
5114,Gould Creek,South Australia,SA,-34.7424,138.7087,4
5114,Andrews Farm,South Australia,SA,-34.6763,138.662,4
5114,Humbug Scrub,South Australia,SA,-34.7409,138.8116,4
5114,Smithfield Plains,South Australia,SA,-34.6764,138.6779,4
5114,Yattalunga,South Australia,SA,-34.6847,138.8037,4
5114,Blakeview,South Australia,SA,-34.6757,138.7101,4
5114,Craigmore,South Australia,SA,-34.7006,138.7096,4
5115,Munno Para,South Australia,SA,-34.6679,138.7015,4
5115,Kudla,South Australia,SA,-34.6423,138.7018,4
5115,Munno Para West,South Australia,SA,-34.6646,138.6807,4
5115,Munno Para Downs,South Australia,SA,-34.6557,138.6812,4
5116,Evanston,South Australia,SA,-34.6166,138.7337,4
5116,Evanston Gardens,South Australia,SA,-34.6232,138.7217,4
5116,Hillier,South Australia,SA,-34.6309,138.6682,4
5116,Evanston Park,South Australia,SA,-34.6228,138.7412,4
5116,Evanston South,South Australia,SA,-34.6483,138.7178,4
5117,Angle Vale,South Australia,SA,-34.641,138.6461,4
5118,Kingsford,South Australia,SA,-34.5862,138.7137,3
5118,Gawler,South Australia,SA,-34.6025,138.749,4
5118,Kalbeeba,South Australia,SA,-34.625,138.7928,4
5118,Concordia,South Australia,SA,-34.5748,138.8051,4
5118,Gawler East,South Australia,SA,-34.5989,138.7632,4
5118,Willaston,South Australia,SA,-34.5833,138.75,4
5118,Hewett,South Australia,SA,-34.578,138.7524,4
5118,Gawler River,South Australia,SA,-34.6144,138.6564,4
5118,Reid,South Australia,SA,-34.5913,138.7397,3
5118,Gawler West,South Australia,SA,-34.6044,138.7325,4
5118,Gawler South,South Australia,SA,-34.6071,138.7445,4
5118,Kangaroo Flat,South Australia,SA,-34.55,138.7,4
5118,Bibaringa,South Australia,SA,-34.6337,138.766,4
5118,Ward Belt,South Australia,SA,-34.5789,138.6683,4
5118,Gawler Belt,South Australia,SA,-34.5862,138.7137,3
5118,Buchfelde,South Australia,SA,-34.5862,138.7137,3
5120,Virginia,South Australia,SA,-34.6663,138.5603,4
5120,Buckland Park,South Australia,SA,-34.6853,138.5417,4
5121,Penfield,South Australia,SA,-34.7,138.6333,4
5121,Penfield Gardens,South Australia,SA,-34.6522,138.6329,4
5121,Macdonald Park,South Australia,SA,-34.6584,138.6439,4
5125,Golden Grove,South Australia,SA,-34.7833,138.7333,4
5125,Golden Grove Village,South Australia,SA,-34.7689,138.735,3
5125,Greenwith,South Australia,SA,-34.7657,138.7138,4
5126,Fairview Park,South Australia,SA,-34.8,138.7286,4
5126,Yatala Vale,South Australia,SA,-34.7945,138.7303,4
5126,Surrey Downs,South Australia,SA,-34.7995,138.7115,4
5127,Wynn Vale,South Australia,SA,-34.7994,138.6871,4
5131,Houghton,South Australia,SA,-34.8287,138.7615,4
5131,Upper Hermitage,South Australia,SA,-34.7964,138.7589,4
5131,Lower Hermitage,South Australia,SA,-34.7942,138.7798,3
5132,Paracombe,South Australia,SA,-34.8488,138.78,4
5133,Inglewood,South Australia,SA,-34.824,138.7739,4
5134,Montacute,South Australia,SA,-34.888,138.7491,4
5134,Cherryville,South Australia,SA,-34.912,138.7657,4
5136,Norton Summit,South Australia,SA,-34.9229,138.7249,4
5137,Ashton,South Australia,SA,-34.9395,138.7371,4
5137,Marble Hill,South Australia,SA,-34.9395,138.7371,3
5138,Basket Range,South Australia,SA,-34.9398,138.765,4
5139,Forest Range,South Australia,SA,-34.928,138.807,4
5140,Greenhill,South Australia,SA,-34.9499,138.6917,4
5141,Summertown,South Australia,SA,-34.9595,138.7313,4
5141,Horsnell Gully,South Australia,SA,-34.9351,138.7044,4
5142,Uraidla,South Australia,SA,-34.956,138.7444,4
5144,Carey Gully,South Australia,SA,-34.9647,138.7618,4
5150,Leawood Gardens,South Australia,SA,-34.9741,138.6734,4
5150,Eagle On The Hill,South Australia,SA,-35.0887,138.6997,3
5151,Piccadilly,South Australia,SA,-34.9778,138.7302,4
5152,Stirling,South Australia,SA,-35.0152,138.7364,4
5152,Crafers,South Australia,SA,-35,138.7,4
5152,Cleland,South Australia,SA,-35.0076,138.7182,3
5152,Crafers West,South Australia,SA,-34.9914,138.6821,4
5153,Longwood,South Australia,SA,-35.05,138.7167,4
5153,Scott Creek,South Australia,SA,-35.0575,138.7052,4
5153,Jupiter Creek,South Australia,SA,-35.1379,138.758,4
5153,Bradbury,South Australia,SA,-35.0608,138.7251,4
5153,Macclesfield,South Australia,SA,-35.1718,138.8359,4
5153,Echunga,South Australia,SA,-35.1035,138.7959,4
5153,Mylor,South Australia,SA,-35.043,138.759,4
5153,Biggs Flat,South Australia,SA,-35.0667,138.7833,4
5153,Chapel Hill,South Australia,SA,-35.0842,138.7582,3
5153,Ironbank,South Australia,SA,-35.05,138.6833,4
5153,Heathfield,South Australia,SA,-35.0167,138.7167,4
5153,Flaxley,South Australia,SA,-35.1333,138.8167,4
5153,Green Hills Range,South Australia,SA,-35.0842,138.7582,3
5154,Aldgate,South Australia,SA,-35.0167,138.7333,4
5155,Mount George,South Australia,SA,-35.0037,138.7637,3
5155,Bridgewater,South Australia,SA,-35,138.75,4
5156,Upper Sturt,South Australia,SA,-35.0167,138.6833,4
5157,Dorset Vale,South Australia,SA,-35.0873,138.6764,4
5157,Clarendon,South Australia,SA,-35.1167,138.6333,4
5157,Cherry Gardens,South Australia,SA,-35.0634,138.6629,4
5157,Kangarilla,South Australia,SA,-35.1488,138.6604,4
5157,Bull Creek,South Australia,SA,-35.2333,138.75,4
5157,Ashbourne,South Australia,SA,-35.2873,138.7668,4
5157,Coromandel East,South Australia,SA,-35.0477,138.6419,4
5157,Mcharg Creek,South Australia,SA,-35.273,138.7361,4
5158,Sheidow Park,South Australia,SA,-35.0793,138.5274,4
5158,O'Halloran Hill DC,South Australia,SA,-35.073,138.5387,3
5158,Hallett Cove,South Australia,SA,-35.0792,138.5146,4
5158,O'Halloran Hill,South Australia,SA,-35.0667,138.55,4
5158,Trott Park,South Australia,SA,-35.0758,138.5419,4
5159,Happy Valley,South Australia,SA,-35.0833,138.5333,4
5159,Chandlers Hill,South Australia,SA,-35.0865,138.6169,4
5159,Flagstaff Hill,South Australia,SA,-35.0486,138.5828,4
5159,Aberfoyle Park,South Australia,SA,-35.0768,138.5916,4
5160,Lonsdale DC,South Australia,SA,-35.1068,138.4929,3
5160,Lonsdale,South Australia,SA,-35.1068,138.4929,4
5161,Reynella,South Australia,SA,-35.0937,138.5308,4
5161,Reynella East,South Australia,SA,-35.092,138.5574,4
5161,Old Reynella,South Australia,SA,-35.1035,138.5336,4
5162,Morphett Vale,South Australia,SA,-35.1333,138.5167,4
5162,Woodcroft,South Australia,SA,-35.1024,138.5629,4
5163,Huntfield Heights,South Australia,SA,-35.1545,138.5142,4
5163,Onkaparinga Hills,South Australia,SA,-35.1351,138.567,4
5163,Hackham West,South Australia,SA,-35.1431,138.5144,4
5163,Hackham,South Australia,SA,-35.15,138.5167,4
5164,Christie Downs,South Australia,SA,-35.13,138.4963,4
5165,Christies Beach,South Australia,SA,-35.1388,138.4723,4
5165,Christies Beach North,South Australia,SA,-35.1451,138.5038,3
5166,O'Sullivan Beach,South Australia,SA,-35.1188,138.4734,4
5167,Port Noarlunga,South Australia,SA,-35.148,138.471,4
5167,Port Noarlunga South,South Australia,SA,-35.174,138.4686,4
5168,Old Noarlunga,South Australia,SA,-35.1821,138.5014,4
5168,Noarlunga Centre,South Australia,SA,-35.1821,138.5014,4
5168,Noarlunga Downs,South Australia,SA,-35.1504,138.504,4
5169,Seaford Heights,South Australia,SA,-35.1988,138.4909,4
5169,Moana,South Australia,SA,-35.1939,138.4761,4
5169,Seaford Meadows,South Australia,SA,-35.1728,138.4879,4
5169,Seaford,South Australia,SA,-35.1898,138.4759,4
5169,Seaford Rise,South Australia,SA,-35.193,138.4817,4
5170,Maslin Beach,South Australia,SA,-35.2259,138.4798,4
5171,Blewitt Springs,South Australia,SA,-35.2178,138.5633,3
5171,Mclaren Vale,South Australia,SA,-35.2189,138.5433,4
5171,Tatachilla,South Australia,SA,-35.2369,138.5071,4
5171,Mclaren Flat,South Australia,SA,-35.2167,138.5833,4
5171,Pedler Creek,South Australia,SA,-35.202,138.566,3
5172,Hope Forest,South Australia,SA,-35.2833,138.6167,4
5172,Willunga,South Australia,SA,-35.2713,138.5542,4
5172,Yundi,South Australia,SA,-35.3167,138.6333,4
5172,Willunga Hill,South Australia,SA,-35.2751,138.6196,3
5172,Dingabledinga,South Australia,SA,-35.2672,138.6291,4
5172,Willunga South,South Australia,SA,-35.2838,138.5548,4
5172,The Range,South Australia,SA,-35.2463,138.6228,4
5172,Pages Flat,South Australia,SA,-35.2751,138.6196,3
5172,Kuitpo Colony,South Australia,SA,-35.2876,138.6963,4
5172,Montarra,South Australia,SA,-35.2667,138.6167,4
5172,Kyeema,South Australia,SA,-35.2763,138.6731,4
5172,Whites Valley,South Australia,SA,-35.2711,138.5221,4
5173,Port Willunga,South Australia,SA,-35.2779,138.4523,4
5173,Aldinga,South Australia,SA,-35.2833,138.4833,4
5173,Aldinga Beach,South Australia,SA,-35.2783,138.458,4
5174,Sellicks Hill,South Australia,SA,-35.3296,138.4742,4
5174,Sellicks Beach,South Australia,SA,-35.3387,138.4473,4
5201,Kuitpo,South Australia,SA,-35.2266,138.6861,4
5201,Meadows,South Australia,SA,-35.1802,138.7625,4
5201,Prospect Hill,South Australia,SA,-35.2333,138.7167,4
5201,Blackfellows Creek,South Australia,SA,-35.2159,138.7406,3
5201,Paris Creek,South Australia,SA,-35.2094,138.7955,4
5202,Hindmarsh Tiers,South Australia,SA,-35.4,138.45,3
5202,Myponga,South Australia,SA,-35.404,138.4712,4
5202,Myponga Beach,South Australia,SA,-35.3738,138.387,4
5203,Bald Hills,South Australia,SA,-35.5167,138.4167,4
5203,Yankalilla,South Australia,SA,-35.4571,138.3498,4
5203,Wattle Flat,South Australia,SA,-35.428,138.3897,4
5203,Parawa,South Australia,SA,-35.4851,138.3791,3
5203,Tunkalilla,South Australia,SA,-35.4851,138.3791,3
5203,Torrens Vale,South Australia,SA,-35.5167,138.3667,4
5204,Normanville,South Australia,SA,-35.4477,138.317,4
5204,Rapid Bay,South Australia,SA,-35.5251,138.1893,4
5204,Second Valley,South Australia,SA,-35.5311,138.2269,4
5204,Wirrina Cove,South Australia,SA,-35.5225,138.232,3
5204,Delamere,South Australia,SA,-35.5833,138.1833,4
5204,Hay Flat,South Australia,SA,-35.5225,138.232,3
5204,Carrickalinga,South Australia,SA,-35.4275,138.3244,4
5204,Silverton,South Australia,SA,-35.5225,138.232,3
5204,Cape Jervis,South Australia,SA,-35.6034,138.1032,4
5204,Deep Creek,South Australia,SA,-35.5225,138.232,3
5210,Mount Compass,South Australia,SA,-35.3469,138.6203,4
5210,Mount Magnificent,South Australia,SA,-35.3484,138.6518,3
5210,Nangkita,South Australia,SA,-35.3455,138.6865,4
5211,Lower Inman Valley,South Australia,SA,-35.516,138.5679,4
5211,Inman Valley,South Australia,SA,-35.4938,138.4563,4
5211,Waitpinga,South Australia,SA,-35.5744,138.5826,4
5211,Encounter Bay,South Australia,SA,-35.5742,138.6005,4
5211,Hayborough,South Australia,SA,-35.5313,138.6504,4
5211,Back Valley,South Australia,SA,-35.5453,138.5896,4
5211,Willow Creek,South Australia,SA,-35.5417,138.5839,3
5211,Hindmarsh Valley,South Australia,SA,-35.5167,138.6167,4
5211,Mount Jagged,South Australia,SA,-35.5417,138.5839,3
5211,Mccracken,South Australia,SA,-35.5358,138.6312,4
5211,Victor Harbor,South Australia,SA,-35.5504,138.6215,4
5212,Port Elliot,South Australia,SA,-35.5302,138.6792,4
5213,Middleton,South Australia,SA,-35.5105,138.7038,4
5214,Hindmarsh Island,South Australia,SA,-35.5101,138.8676,4
5214,Mosquito Hill,South Australia,SA,-35.502,138.7849,3
5214,Goolwa Beach,South Australia,SA,-35.5047,138.7732,4
5214,Currency Creek,South Australia,SA,-35.45,138.7667,4
5214,Goolwa,South Australia,SA,-35.5016,138.7819,4
5214,Mundoo Island,South Australia,SA,-35.5509,138.9148,4
5214,Goolwa South,South Australia,SA,-35.5148,138.7813,4
5214,Goolwa North,South Australia,SA,-35.4912,138.8126,4
5220,Parndana,South Australia,SA,-35.7853,137.2571,4
5221,American River,South Australia,SA,-35.7725,137.7783,4
5221,Ballast Head,South Australia,SA,-35.7717,137.7804,4
5221,Muston,South Australia,SA,-35.7897,137.757,4
5222,Kangaroo Head,South Australia,SA,-35.7717,137.7804,4
5222,Island Beach,South Australia,SA,-35.7975,137.7987,4
5222,Penneshaw,South Australia,SA,-35.7333,137.9333,4
5222,Pelican Lagoon,South Australia,SA,-35.807,137.8039,4
5222,Brown Beach,South Australia,SA,-35.7984,137.8378,4
5222,Sapphiretown,South Australia,SA,-35.7907,137.781,4
5222,Baudin Beach,South Australia,SA,-35.7765,137.8729,4
5222,Dudley West,South Australia,SA,-35.7661,137.8681,3
5222,Ironstone,South Australia,SA,-35.7661,137.8681,3
5222,Antechamber Bay,South Australia,SA,-35.7661,137.8681,3
5222,Cuttlefish Bay,South Australia,SA,-35.7661,137.8681,3
5222,Dudley East,South Australia,SA,-35.7661,137.8681,3
5222,Willson River,South Australia,SA,-35.7661,137.8681,3
5222,American Beach,South Australia,SA,-35.7661,137.8681,3
5222,Porky Flat,South Australia,SA,-35.7661,137.8681,3
5222,Willoughby,South Australia,SA,-35.7661,137.8681,3
5223,North Cape,South Australia,SA,-35.5808,137.595,4
5223,D'Estrees Bay,South Australia,SA,-35.928,137.5742,4
5223,Kingscote,South Australia,SA,-35.6559,137.6393,4
5223,Newland,South Australia,SA,-35.8717,137.1212,4
5223,Seddon,South Australia,SA,-35.8359,137.2923,4
5223,Emu Bay,South Australia,SA,-35.5984,137.5059,4
5223,Vivonne Bay,South Australia,SA,-35.9826,137.1771,4
5223,Kohinoor,South Australia,SA,-35.6801,137.5345,3
5223,Brownlow Ki,South Australia,SA,-35.6714,137.6071,4
5223,Bay Of Shoals,South Australia,SA,-35.6801,137.5345,3
5223,Wisanger,South Australia,SA,-35.6333,137.4667,4
5223,Karatta,South Australia,SA,-35.9284,136.8972,4
5223,Cygnet River,South Australia,SA,-35.7,137.5167,4
5223,Cape Borda,South Australia,SA,-35.6801,137.5345,3
5223,Stokes Bay,South Australia,SA,-35.6294,137.2034,4
5223,Haines,South Australia,SA,-35.6801,137.5345,3
5223,De Mole River,South Australia,SA,-35.6801,137.5345,3
5223,Gosse,South Australia,SA,-35.6801,137.5345,3
5223,Birchmore,South Australia,SA,-35.6801,137.5345,3
5223,Nepean Bay,South Australia,SA,-35.6801,137.5345,3
5223,Macgillivray,South Australia,SA,-35.6801,137.5345,3
5223,Menzies,South Australia,SA,-35.6801,137.5345,3
5223,Flinders Chase,South Australia,SA,-35.6801,137.5345,3
5223,Cassini,South Australia,SA,-35.6801,137.5345,3
5223,Stun'Sail Boom,South Australia,SA,-35.6801,137.5345,3
5223,Seal Bay,South Australia,SA,-35.6801,137.5345,3
5223,Middle River,South Australia,SA,-35.6801,137.5345,3
5223,Western River,South Australia,SA,-35.6801,137.5345,3
5223,Duncan,South Australia,SA,-35.6801,137.5345,3
5231,Chain Of Ponds,South Australia,SA,-34.8225,138.8335,4
5231,Kersbrook,South Australia,SA,-34.7831,138.8509,4
5231,Millbrook,South Australia,SA,-34.7999,138.8421,3
5232,Cudlee Creek,South Australia,SA,-34.8409,138.8284,4
5233,Gumeracha,South Australia,SA,-34.8239,138.8847,4
5233,Forreston,South Australia,SA,-34.7814,138.9181,4
5234,Birdwood,South Australia,SA,-34.8183,138.9648,4
5235,Flaxman Valley,South Australia,SA,-34.7247,139.0596,3
5235,Springton,South Australia,SA,-34.7074,139.0893,4
5235,Taunton,South Australia,SA,-34.7247,139.0596,3
5235,Mount Pleasant,South Australia,SA,-34.7749,139.0491,4
5235,Cromer,South Australia,SA,-34.7627,138.9901,4
5235,Eden Valley,South Australia,SA,-34.65,139.1,4
5236,Tungkillo,South Australia,SA,-34.8167,139.0667,4
5237,Sanderston,South Australia,SA,-34.7554,139.2219,4
5237,Milendella,South Australia,SA,-34.8167,139.2,4
5237,Palmer,South Australia,SA,-34.85,139.1667,4
5237,Apamurra,South Australia,SA,-34.8056,139.1944,3
5238,Bowhill,South Australia,SA,-34.9,139.6167,4
5238,Ponde,South Australia,SA,-34.95,139.3167,4
5238,Purnong,South Australia,SA,-34.8667,139.6167,4
5238,Frayville,South Australia,SA,-34.8411,139.4865,3
5238,Caurnamont,South Australia,SA,-34.8431,139.5874,4
5238,Nildottie,South Australia,SA,-34.6764,139.6517,4
5238,Purnong Landing,South Australia,SA,-34.8667,139.6167,4
5238,Five Miles,South Australia,SA,-34.8411,139.4865,3
5238,Younghusband Holdings,South Australia,SA,-34.8411,139.4865,3
5238,Angas Valley,South Australia,SA,-34.7667,139.3,4
5238,Teal Flat,South Australia,SA,-34.8936,139.5564,4
5238,Julanka Holdings,South Australia,SA,-34.8411,139.4865,3
5238,Walker Flat,South Australia,SA,-34.7526,139.5565,4
5238,Pompoota,South Australia,SA,-35,139.3333,4
5238,Wongulla,South Australia,SA,-34.7049,139.5659,4
5238,Frahns,South Australia,SA,-34.8411,139.4865,3
5238,Rocky Point,South Australia,SA,-34.8411,139.4865,3
5238,Younghusband,South Australia,SA,-34.8411,139.4865,3
5238,Forster,South Australia,SA,-34.7334,139.5548,4
5238,Port Mannum,South Australia,SA,-34.8411,139.4865,3
5238,Mannum,South Australia,SA,-34.9153,139.3033,4
5238,Bolto,South Australia,SA,-34.8411,139.4865,3
5238,Old Teal Flat,South Australia,SA,-34.8411,139.4865,3
5238,Big Bend,South Australia,SA,-34.8411,139.4865,3
5238,Punthari,South Australia,SA,-34.8167,139.3167,4
5238,Cowirra,South Australia,SA,-34.9,139.3333,4
5238,Pellaring Flat,South Australia,SA,-34.862,139.3899,4
5238,Lake Carlet,South Australia,SA,-34.8411,139.4865,3
5238,Claypans,South Australia,SA,-34.8167,139.6667,4
5240,Lenswood,South Australia,SA,-34.9182,138.8326,4
5241,Lobethal,South Australia,SA,-34.9045,138.8748,4
5242,Balhannah,South Australia,SA,-34.9909,138.8257,4
5243,Oakbank,South Australia,SA,-34.9834,138.8453,4
5244,Inverbrackie,South Australia,SA,-34.9734,138.8917,4
5244,Charleston,South Australia,SA,-34.9167,138.9,4
5244,Mount Torrens,South Australia,SA,-34.8755,138.9586,4
5244,Woodside,South Australia,SA,-34.9543,138.879,4
5244,Harrogate,South Australia,SA,-34.95,139.0167,4
5245,Paechtown,South Australia,SA,-35.049,138.8089,4
5245,Hahndorf,South Australia,SA,-35.0286,138.8078,4
5245,Verdun,South Australia,SA,-35,138.7833,4
5246,Woodhouse,South Australia,SA,-34.9614,138.8661,1
5250,Littlehampton,South Australia,SA,-35.05,138.85,4
5250,Blakiston,South Australia,SA,-35.0421,138.8815,4
5250,Totness,South Australia,SA,-35.05,138.8667,3
5251,Mount Barker Junction,South Australia,SA,-35.0253,138.8683,4
5251,Mount Barker Springs,South Australia,SA,-35.079,138.8637,3
5251,Bugle Ranges,South Australia,SA,-35.1327,138.8716,4
5251,Mount Barker Summit,South Australia,SA,-35.079,138.8637,3
5251,Mount Barker,South Australia,SA,-35.0667,138.8667,4
5251,Wistow,South Australia,SA,-35.1,138.8667,4
5252,Nairne,South Australia,SA,-35.0345,138.9115,4
5252,Brukunga,South Australia,SA,-35.0029,138.9425,4
5252,Dawesley,South Australia,SA,-35.0419,138.9535,4
5252,Kanmantoo,South Australia,SA,-35.0701,139.0127,4
5252,St Ives,South Australia,SA,-35.0367,138.9559,3
5252,Hay Valley,South Australia,SA,-35.0367,138.9559,3
5253,Brinkley,South Australia,SA,-35.23,139.2221,4
5253,Riverglen,South Australia,SA,-35.17,139.3085,4
5253,Ettrick,South Australia,SA,-35,139.5667,4
5253,Murray Bridge East,South Australia,SA,-35.1339,139.3678,3
5253,White Sands,South Australia,SA,-35.1339,139.3678,3
5253,Northern Heights,South Australia,SA,-35.1339,139.3678,3
5253,Swanport,South Australia,SA,-35.15,139.3,4
5253,Murray Bridge,South Australia,SA,-35.1199,139.2735,4
5253,Chapman Bore,South Australia,SA,-35.1275,139.5517,4
5253,Murrawong,South Australia,SA,-35.1339,139.3678,3
5253,Monteith,South Australia,SA,-35.1667,139.3,4
5253,Mobilong,South Australia,SA,-35.1339,139.3678,3
5253,Sunnyside,South Australia,SA,-35.1339,139.3678,3
5253,Willow Banks,South Australia,SA,-35.1339,139.3678,3
5253,Riverglades,South Australia,SA,-35.1339,139.3678,3
5253,Murray Bridge North,South Australia,SA,-35.1339,139.3678,3
5253,Toora,South Australia,SA,-35.1339,139.3678,3
5253,Murray Bridge South,South Australia,SA,-35.1339,139.3678,3
5253,Long Flat,South Australia,SA,-35.1339,139.3678,3
5253,Gifford Hill,South Australia,SA,-35.1339,139.3678,3
5253,Greenbanks,South Australia,SA,-35.1339,139.3678,3
5253,Avoca Dell,South Australia,SA,-35.1339,139.3678,3
5253,Burdett,South Australia,SA,-35.1339,139.3678,3
5253,Woods Point,South Australia,SA,-35.1339,139.3678,3
5254,Murray Bridge,South Australia,SA,-35.1199,139.2735,4
5254,Wall Flat,South Australia,SA,-34.9667,139.3167,4
5254,Caloote,South Australia,SA,-34.9642,139.2622,4
5254,White Hill,South Australia,SA,-35.0406,139.1998,3
5254,Monarto South,South Australia,SA,-35.122,139.129,4
5254,Mypolonga,South Australia,SA,-35.05,139.35,4
5254,Pallamana,South Australia,SA,-35.0344,139.2059,4
5254,Woodlane,South Australia,SA,-35.0406,139.1998,3
5254,Monarto,South Australia,SA,-35.05,139.1333,4
5254,Rocky Gully,South Australia,SA,-35.0406,139.1998,3
5254,Tepko,South Australia,SA,-34.969,139.1878,4
5254,Petwood,South Australia,SA,-35.0406,139.1998,3
5254,Callington,South Australia,SA,-35.1198,139.0414,4
5254,Zadows Landing,South Australia,SA,-35.0406,139.1998,3
5254,Rockleigh,South Australia,SA,-35,139.1167,4
5255,Woodchester,South Australia,SA,-35.2167,138.95,4
5255,Tooperang,South Australia,SA,-35.3833,138.7333,4
5255,Mount Observation,South Australia,SA,-35.2918,138.9315,3
5255,Finniss,South Australia,SA,-35.4,138.8167,4
5255,Mulgundawa,South Australia,SA,-35.2918,138.9315,3
5255,Belvidere,South Australia,SA,-35.2914,138.9474,4
5255,Gemmells,South Australia,SA,-35.2918,138.9315,3
5255,Lake Plains,South Australia,SA,-35.3704,139.0417,4
5255,Langhorne Creek,South Australia,SA,-35.296,139.0359,4
5255,Red Creek,South Australia,SA,-35.1587,138.9757,4
5255,Salem,South Australia,SA,-35.2918,138.9315,3
5255,Bletchley,South Australia,SA,-35.2408,138.9855,4
5255,Strathalbyn,South Australia,SA,-35.2598,138.8925,4
5255,Sandergrove,South Australia,SA,-35.3333,138.8333,4
5255,Nalpa,South Australia,SA,-35.2918,138.9315,3
5255,Angas Plains,South Australia,SA,-35.35,139,4
5255,Highland Valley,South Australia,SA,-35.2918,138.9315,3
5255,Hartley,South Australia,SA,-35.2,139,4
5255,Willyaroo,South Australia,SA,-35.2667,138.9167,4
5256,Clayton Bay,South Australia,SA,-35.4905,138.9207,4
5256,Tolderol,South Australia,SA,-35.4768,138.9608,3
5256,Point Sturt,South Australia,SA,-35.5167,139.0333,4
5256,Clayton,South Australia,SA,-35.4905,138.9207,4
5256,Milang,South Australia,SA,-35.4078,138.9712,4
5256,Nurragi,South Australia,SA,-35.4768,138.9608,3
5259,Tailem Bend,South Australia,SA,-35.2523,139.4555,4
5259,Wellington,South Australia,SA,-35.35,139.3833,4
5259,Narrung,South Australia,SA,-35.5122,139.1685,4
5259,Wellington East,South Australia,SA,-35.3333,139.4,4
5259,Ashville,South Australia,SA,-35.5106,139.3656,4
5259,Kepa,South Australia,SA,-35.3711,139.3611,3
5259,Jervois,South Australia,SA,-35.2667,139.4333,4
5259,Point Mcleay,South Australia,SA,-35.5333,139.1,4
5259,Naturi,South Australia,SA,-35.1973,139.5846,4
5259,Raukkan,South Australia,SA,-35.4358,139.2417,3
5259,Poltalloch,South Australia,SA,-35.3711,139.3611,3
5259,Malinong,South Australia,SA,-35.3711,139.3611,3
5260,Tailem Bend,South Australia,SA,-35.2523,139.4555,4
5260,Elwomple,South Australia,SA,-35.2523,139.4555,3
5261,Yumali,South Australia,SA,-35.5084,139.7578,4
5261,Ki Ki,South Australia,SA,-35.5833,139.8,4
5261,Culburra,South Australia,SA,-35.8134,139.9649,4
5261,Cooke Plains,South Australia,SA,-35.3797,139.5617,4
5261,Coomandook,South Australia,SA,-35.4735,139.6963,4
5262,Binnum,South Australia,SA,-36.7951,140.9375,4
5262,Frances,South Australia,SA,-36.7167,140.9667,4
5262,Kybybolite,South Australia,SA,-36.8833,140.9167,4
5262,Hynam,South Australia,SA,-36.95,140.85,4
5263,Coonawarra,South Australia,SA,-37.2906,140.8344,4
5264,Meningie East,South Australia,SA,-35.8184,139.4684,3
5264,Meningie,South Australia,SA,-35.6864,139.3401,4
5264,Coorong,South Australia,SA,-35.8184,139.4684,3
5264,Waltowa,South Australia,SA,-35.624,139.4327,4
5264,Meningie West,South Australia,SA,-35.8184,139.4684,3
5264,Salt Creek,South Australia,SA,-36.15,139.6333,4
5265,Field,South Australia,SA,-35.8224,139.6652,4
5265,Coonalpyn,South Australia,SA,-35.6942,139.8578,4
5266,Deepwater,South Australia,SA,-35.8871,140.0565,3
5266,Tintinara,South Australia,SA,-35.8871,140.0565,4
5266,Bunbury,South Australia,SA,-35.8871,140.0565,3
5266,Colebatch,South Australia,SA,-35.8871,140.0565,3
5267,Coombe,South Australia,SA,-35.962,140.2192,4
5267,Mount Charles,South Australia,SA,-36.1495,140.4682,3
5267,Petherick,South Australia,SA,-36.1495,140.4682,3
5267,Wirrega,South Australia,SA,-36.2064,140.5831,4
5267,Sherwood,South Australia,SA,-36.1495,140.4682,3
5267,Keith,South Australia,SA,-36.0991,140.3531,4
5267,Shaugh,South Australia,SA,-36.1495,140.4682,3
5267,Mccallum,South Australia,SA,-36.1495,140.4682,3
5267,Brimbago,South Australia,SA,-36.1495,140.4682,3
5267,Laffer,South Australia,SA,-36.1495,140.4682,3
5267,Willalooka,South Australia,SA,-36.3935,140.3081,4
5267,Makin,South Australia,SA,-36.1495,140.4682,3
5268,Lowan Vale,South Australia,SA,-36.1788,140.6703,4
5268,Western Flat,South Australia,SA,-36.2959,140.7423,3
5268,Cannawigara,South Australia,SA,-36.25,140.6667,4
5268,Bordertown South,South Australia,SA,-36.3077,140.7717,4
5268,Senior,South Australia,SA,-36.2959,140.7423,3
5268,Bordertown,South Australia,SA,-36.3077,140.7717,4
5268,Pooginagoric,South Australia,SA,-36.4333,140.8333,4
5268,Bangham,South Australia,SA,-36.2959,140.7423,3
5269,Pine Hill,South Australia,SA,-32.1717,133.7971,3
5269,Wolseley,South Australia,SA,-36.3667,140.9,4
5269,Custon,South Australia,SA,-36.45,140.9167,4
5270,Buckingham,South Australia,SA,-36.3444,140.5818,4
5270,Kongal,South Australia,SA,-36.3167,140.5167,4
5270,Carew,South Australia,SA,-36.3444,140.5944,3
5270,Mundulla,South Australia,SA,-36.3667,140.6833,4
5270,Mundulla West,South Australia,SA,-36.3444,140.5944,3
5270,Swede Flat,South Australia,SA,-36.3444,140.5944,3
5271,Bool Lagoon,South Australia,SA,-37.1392,140.7218,4
5271,Laurie Park,South Australia,SA,-36.9763,140.6925,3
5271,Keppoch,South Australia,SA,-36.7,140.5667,4
5271,Moyhall,South Australia,SA,-36.9763,140.6925,3
5271,Naracoorte,South Australia,SA,-36.9578,140.7383,4
5271,Struan,South Australia,SA,-37.0945,140.791,4
5271,Padthaway,South Australia,SA,-36.5963,140.4996,4
5271,Koppamurra,South Australia,SA,-36.9763,140.6925,3
5271,Joanna,South Australia,SA,-37.1,140.8333,4
5271,Lochaber,South Australia,SA,-36.8333,140.5167,4
5271,Cadgee,South Australia,SA,-36.9763,140.6925,3
5271,Wrattonbully,South Australia,SA,-36.9763,140.6925,3
5271,Spence,South Australia,SA,-36.9763,140.6925,3
5271,Mount Light,South Australia,SA,-36.9763,140.6925,3
5271,Wild Dog Valley,South Australia,SA,-36.9763,140.6925,3
5271,Marcollat,South Australia,SA,-36.9763,140.6925,3
5271,The Gap,South Australia,SA,-36.9763,140.6925,3
5271,Stewart Range,South Australia,SA,-36.9763,140.6925,3
5272,Lucindale,South Australia,SA,-36.9727,140.3701,4
5272,Woolumbool,South Australia,SA,-37.0906,140.3179,3
5272,Conmurra,South Australia,SA,-37.15,140.2833,4
5272,Coles,South Australia,SA,-37.0906,140.3179,3
5272,Fox,South Australia,SA,-33.5964,135.7086,4
5272,Greenways,South Australia,SA,-34.6586,139.6446,4
5273,Avenue Range,South Australia,SA,-36.9667,140.2333,4
5275,Rosetown,South Australia,SA,-36.8333,139.85,4
5275,Pinks Beach,South Australia,SA,-37.0028,140.0667,3
5275,Boatswain Point,South Australia,SA,-37.0028,140.0667,3
5275,Keilira,South Australia,SA,-36.7167,140.15,4
5275,West Range,South Australia,SA,-37.6667,140.5833,4
5275,Kingston Se,South Australia,SA,-37.0028,140.0667,3
5275,Blackford,South Australia,SA,-36.7667,140.0167,4
5275,Taratap,South Australia,SA,-37.0028,140.0667,3
5275,Tilley Swamp,South Australia,SA,-37.0028,140.0667,3
5275,Kingston S.E.,South Australia,SA,-36.5102,139.9281,3
5275,Wangolina,South Australia,SA,-37.0028,140.0667,3
5275,Mount Benson,South Australia,SA,-37.0833,139.7833,4
5275,Reedy Creek,South Australia,SA,-36.95,140.0167,4
5275,Sandy Grove,South Australia,SA,-37.0028,140.0667,3
5275,Cape Jaffa,South Australia,SA,-36.9455,139.6982,4
5275,Wyomi,South Australia,SA,-37.0028,140.0667,3
5276,Nora Creina,South Australia,SA,-37.3256,139.8523,4
5276,Robe,South Australia,SA,-37.1639,139.7603,4
5276,Bray,South Australia,SA,-37.2461,139.8082,3
5277,Pleasant Park,South Australia,SA,-37.4131,140.8494,3
5277,Glenroy,South Australia,SA,-37.25,140.85,4
5277,Penola,South Australia,SA,-37.3752,140.8368,4
5277,Comaum,South Australia,SA,-37.2667,140.95,4
5277,Maaoupe,South Australia,SA,-37.4131,140.8494,3
5277,Tarpeena,South Australia,SA,-37.6277,140.7959,4
5277,Monbulla,South Australia,SA,-37.4131,140.8494,3
5277,Nangwarry,South Australia,SA,-37.546,140.8145,4
5278,Kalangadoo,South Australia,SA,-37.5648,140.6992,4
5278,Wepar,South Australia,SA,-37.6083,140.7077,4
5278,Krongart,South Australia,SA,-37.5908,140.6996,3
5278,Moerlong,South Australia,SA,-37.5908,140.6996,3
5279,Mount Burr,South Australia,SA,-37.542,140.4555,4
5279,Trihi,South Australia,SA,-37.5436,140.4452,3
5279,Wattle Range East,South Australia,SA,-37.546,140.5028,3
5279,Short,South Australia,SA,-37.546,140.5028,3
5279,Mount Mcintyre,South Australia,SA,-37.5608,140.5373,4
5279,Koorine,South Australia,SA,-37.546,140.5028,3
5280,Beachport,South Australia,SA,-37.4802,140.0107,4
5280,Millicent,South Australia,SA,-37.5939,140.3495,4
5280,Tantanoola,South Australia,SA,-37.6966,140.4575,4
5280,Clay Wells,South Australia,SA,-37.5441,140.251,3
5280,Furner,South Australia,SA,-37.4167,140.3333,4
5280,Sebastopol,South Australia,SA,-37.5441,140.251,3
5280,Rendelsham,South Australia,SA,-37.55,140.2167,4
5280,German Flat,South Australia,SA,-37.5441,140.251,3
5280,Southend,South Australia,SA,-37.5678,140.1287,4
5280,Thornlea,South Australia,SA,-37.5441,140.251,3
5280,Hatherleigh,South Australia,SA,-37.5,140.2667,4
5280,Canunda,South Australia,SA,-37.5441,140.251,3
5280,Wattle Range,South Australia,SA,-37.5441,140.251,3
5280,Kangaroo Inn,South Australia,SA,-37.5441,140.251,3
5280,Magarey,South Australia,SA,-37.5441,140.251,3
5280,Rocky Camp,South Australia,SA,-37.5441,140.251,3
5290,Mount Gambier,South Australia,SA,-37.8318,140.7792,4
5291,Glencoe West,South Australia,SA,-37.6667,140.5833,4
5291,German Creek,South Australia,SA,-37.8571,140.6831,3
5291,Caveton,South Australia,SA,-37.8571,140.6831,3
5291,Allendale East,South Australia,SA,-38.0037,140.7092,4
5291,Worrolong,South Australia,SA,-37.8062,140.848,4
5291,Compton,South Australia,SA,-37.7667,140.6833,4
5291,Eight Mile Creek,South Australia,SA,-37.8571,140.6831,3
5291,Yahl,South Australia,SA,-37.8667,140.8,4
5291,Mount Gambier,South Australia,SA,-37.8318,140.7792,4
5291,Nene Valley,South Australia,SA,-37.9895,140.5204,4
5291,Pelican Point,South Australia,SA,-37.8571,140.6831,3
5291,Mount Schank,South Australia,SA,-37.9399,140.7241,4
5291,Glencoe,South Australia,SA,-37.7,140.6167,4
5291,Kongorong,South Australia,SA,-37.9,140.55,4
5291,Square Mile,South Australia,SA,-37.8571,140.6831,3
5291,Wandilo,South Australia,SA,-37.7259,140.7257,4
5291,Mil-Lel,South Australia,SA,-37.7833,140.8167,4
5291,Carpenter Rocks,South Australia,SA,-37.9102,140.3956,4
5291,Suttontown,South Australia,SA,-37.8571,140.6831,3
5291,Caroline,South Australia,SA,-37.8969,140.9214,4
5291,O.B. Flat,South Australia,SA,-37.8265,140.7066,3
5291,Burrungule,South Australia,SA,-37.7809,140.574,4
5291,Mingbool,South Australia,SA,-37.8571,140.6831,3
5291,Port Macdonnell,South Australia,SA,-38.0531,140.6996,4
5291,Donovans,South Australia,SA,-38.0135,140.9621,3
5291,Glenburnie,South Australia,SA,-37.8394,140.8588,4
5291,Moorak,South Australia,SA,-37.8641,140.7375,3
5291,Blackfellows Caves,South Australia,SA,-37.9467,140.4685,4
5291,Dismal Swamp,South Australia,SA,-37.8571,140.6831,3
5291,Mount Gambier West,South Australia,SA,-37.8265,140.7066,3
5291,Mount Gambier East,South Australia,SA,-37.8265,140.7066,3
5291,Cape Douglas,South Australia,SA,-37.8571,140.6831,3
5291,Racecourse Bay,South Australia,SA,-37.8571,140.6831,3
5291,Ob Flat,South Australia,SA,-37.8571,140.6831,3
5291,Wye,South Australia,SA,-37.8571,140.6831,3
5301,Moorlands,South Australia,SA,-35.2899,139.6455,4
5301,Sherlock,South Australia,SA,-35.3167,139.8167,4
5301,Wilkawatt,South Australia,SA,-35.3673,140.3601,4
5301,Carcuma,South Australia,SA,-35.3771,140.0271,3
5301,Parrakie,South Australia,SA,-35.3833,140.25,4
5301,Jabuk,South Australia,SA,-35.4,140.0667,4
5301,Peake,South Australia,SA,-35.3667,139.95,4
5301,Geranium,South Australia,SA,-35.3833,140.1667,4
5301,Netherton,South Australia,SA,-35.4751,139.9474,4
5302,Ngarkat,South Australia,SA,-35.329,140.5167,3
5302,Lameroo,South Australia,SA,-35.329,140.5167,4
5303,Parilla,South Australia,SA,-35.3,140.6667,4
5304,Pinnaroo,South Australia,SA,-35.2616,140.912,4
5304,Karte,South Australia,SA,-35.0667,140.7,4
5304,Kringin,South Australia,SA,-34.9833,140.7833,4
5304,Peebinga,South Australia,SA,-34.9333,140.9167,4
5306,Wynarka,South Australia,SA,-35.1333,139.7333,4
5307,Karoonda,South Australia,SA,-35.0945,139.8934,4
5307,Marama,South Australia,SA,-35.1833,140.1833,4
5308,Galga,South Australia,SA,-34.6994,139.9646,4
5308,Copeville,South Australia,SA,-34.7973,139.8431,4
5308,Perponda,South Australia,SA,-34.9875,139.8122,4
5308,Mantung,South Australia,SA,-34.6,140.0667,4
5308,Mercunda,South Australia,SA,-34.65,140.0167,4
5309,Mindarie,South Australia,SA,-34.8167,140.2167,4
5309,Halidon,South Australia,SA,-34.8833,140.1667,4
5309,Sandalwood,South Australia,SA,-34.95,140.1333,4
5309,Borrika,South Australia,SA,-35.0167,140.05,4
5310,Caliph,South Australia,SA,-34.6667,140.2833,4
5310,Wanbi,South Australia,SA,-34.7667,140.3167,4
5311,Taplan,South Australia,SA,-34.5444,140.8801,4
5311,Malpas,South Australia,SA,-34.7167,140.6167,4
5311,Bugle Hut,South Australia,SA,-34.3774,140.783,4
5311,Maggea,South Australia,SA,-34.4833,140.0667,4
5311,Veitch,South Australia,SA,-34.6667,140.5167,4
5311,Woodleigh,South Australia,SA,-34.5682,140.7151,4
5311,Meribah,South Australia,SA,-34.7034,140.8542,4
5311,Taldra,South Australia,SA,-34.4228,140.8887,4
5311,Alawoona,South Australia,SA,-34.7333,140.5167,4
5311,Wunkar,South Australia,SA,-34.5,140.3,4
5311,Schell Well,South Australia,SA,-34.6058,140.6087,3
5311,Paruna,South Australia,SA,-34.7167,140.7333,4
5311,Billiatt,South Australia,SA,-34.6058,140.6087,3
5312,Veitch,South Australia,SA,-34.6667,140.5167,4
5320,Stuart,South Australia,SA,-33.0249,137.5247,4
5320,Eba,South Australia,SA,-34.0669,139.579,4
5320,Beatty,South Australia,SA,-34.0991,139.659,3
5320,Murbko,South Australia,SA,-34.1631,139.6629,4
5320,Morgan,South Australia,SA,-34.0315,139.668,4
5320,Beaumonts,South Australia,SA,-34.0991,139.659,3
5320,Wombats Rest,South Australia,SA,-34.0991,139.659,3
5320,North West Bend,South Australia,SA,-34.0991,139.659,3
5320,Morphetts Flat,South Australia,SA,-34.0991,139.659,3
5320,Brenda Park,South Australia,SA,-34.0991,139.659,3
5320,Bundey,South Australia,SA,-34.0991,139.659,3
5320,Maude,South Australia,SA,-34.0991,139.659,3
5320,Lindley,South Australia,SA,-34.0991,139.659,3
5321,Cadell,South Australia,SA,-34.0388,139.7639,4
5321,Cadell Lagoon,South Australia,SA,-34.0333,139.75,3
5322,Golden Heights,South Australia,SA,-34.1417,139.9,3
5322,Sunlands,South Australia,SA,-34.1417,139.9,3
5322,Ramco Heights,South Australia,SA,-34.1417,139.9,3
5322,Ramco,South Australia,SA,-34.1667,139.9333,4
5322,Qualco,South Australia,SA,-34.1103,139.8933,4
5330,Wigley Flat,South Australia,SA,-34.1621,140.0839,3
5330,Boolgun,South Australia,SA,-34.4173,140.04,4
5330,Kanni,South Australia,SA,-34.1621,140.0839,3
5330,Waikerie,South Australia,SA,-34.1818,139.9855,4
5330,Devlins Pound,South Australia,SA,-34.1621,140.0839,3
5330,Taylorville,South Australia,SA,-34.0989,139.9731,4
5330,Pooginook,South Australia,SA,-34.1621,140.0839,3
5330,Overland Corner,South Australia,SA,-34.1458,140.3343,4
5330,Stockyard Plain,South Australia,SA,-34.1621,140.0839,3
5330,Holder,South Australia,SA,-34.3267,140.0174,4
5330,Markaranka,South Australia,SA,-34.1621,140.0839,3
5330,Good Hope Landing,South Australia,SA,-34.1621,140.0839,3
5330,Holder Siding,South Australia,SA,-34.1621,140.0839,3
5330,Woolpunda,South Australia,SA,-34.1621,140.0839,3
5330,Lowbank,South Australia,SA,-34.1943,140.0852,4
5331,Kingston On Murray,South Australia,SA,-34.325,140.3517,1
5331,Kingston-On-Murray,South Australia,SA,-34.2207,140.34,1
5332,Moorook South,South Australia,SA,-34.2663,140.3566,4
5332,Yinkanie,South Australia,SA,-34.3333,140.3167,4
5332,Wappilka,South Australia,SA,-34.2886,140.3433,3
5332,Moorook,South Australia,SA,-34.2663,140.3566,4
5333,Pyap,South Australia,SA,-34.4353,140.4932,4
5333,Bookpurnong,South Australia,SA,-34.4771,140.571,3
5333,New Residence,South Australia,SA,-34.3787,140.4026,4
5333,Pyap West,South Australia,SA,-34.4771,140.571,3
5333,Taplan,South Australia,SA,-34.5444,140.8801,4
5333,Loxton,South Australia,SA,-34.4502,140.5691,4
5333,Pata,South Australia,SA,-34.5667,140.5167,4
5333,Loxton North,South Australia,SA,-34.4183,140.6266,3
5340,Wonuarra,South Australia,SA,-34.2156,140.8435,4
5340,Paringa,South Australia,SA,-34.1764,140.7858,4
5340,Yamba,South Australia,SA,-34.2623,140.8589,4
5340,Murtho,South Australia,SA,-34.1764,140.7858,3
5340,Pike River,South Australia,SA,-34.1764,140.7858,3
5340,Mundic Creek,South Australia,SA,-34.1764,140.7858,3
5341,Cooltong,South Australia,SA,-34.1006,140.6737,4
5341,Renmark South,South Australia,SA,-34.2016,140.7106,3
5341,Renmark West,South Australia,SA,-34.1653,140.7097,4
5341,Renmark,South Australia,SA,-34.177,140.747,4
5341,Crescent,South Australia,SA,-34.1481,140.704,3
5341,Chaffey,South Australia,SA,-34.0934,140.7065,4
5341,Renmark North,South Australia,SA,-34.1255,140.7127,4
5341,Old Calperum,South Australia,SA,-34.2178,140.6803,4
5342,Monash,South Australia,SA,-34.2384,140.5613,4
5343,Lyrup,South Australia,SA,-34.2579,140.6482,4
5343,Winkie,South Australia,SA,-34.3,140.5333,4
5343,Berri,South Australia,SA,-34.2811,140.5996,4
5343,Katarapko,South Australia,SA,-34.4424,140.559,4
5343,Gerard,South Australia,SA,-34.2826,140.5943,3
5343,Gurra Gurra,South Australia,SA,-34.2826,140.5943,3
5344,Glossop,South Australia,SA,-34.2769,140.541,4
5345,Spectacle Lake,South Australia,SA,-34.2695,140.4474,3
5345,Barmera,South Australia,SA,-34.2556,140.4615,4
5345,Loveday,South Australia,SA,-34.2833,140.4333,4
5346,Cobdogla,South Australia,SA,-34.2438,140.4043,4
5350,Sandy Creek,South Australia,SA,-34.5848,138.8205,3
5350,Rosedale,South Australia,SA,-34.55,138.85,4
5351,Cockatoo Valley,South Australia,SA,-34.6248,138.8365,4
5351,Pewsey Vale,South Australia,SA,-34.6313,138.8964,3
5351,Williamstown,South Australia,SA,-34.6721,138.8911,4
5351,Lyndoch,South Australia,SA,-34.6012,138.8909,4
5351,Altona,South Australia,SA,-34.5962,138.914,4
5351,Barossa Goldfields,South Australia,SA,-34.6516,138.8354,4
5351,Mount Crawford,South Australia,SA,-34.6667,138.95,4
5352,Vine Vale,South Australia,SA,-34.5145,139.0054,4
5352,Krondorf,South Australia,SA,-34.5413,138.9486,3
5352,Rowland Flat,South Australia,SA,-34.5833,138.9333,4
5352,Stone Well,South Australia,SA,-34.5358,138.8762,3
5352,Tanunda,South Australia,SA,-34.5234,138.9598,4
5352,Bethany,South Australia,SA,-34.5333,138.9667,4
5352,Gomersal,South Australia,SA,-34.55,138.8833,4
5353,Angaston,South Australia,SA,-34.5013,139.0462,4
5353,Langs Landing,South Australia,SA,-34.5798,139.2255,3
5353,Kongolia,South Australia,SA,-34.65,139.3667,4
5353,Black Hill,South Australia,SA,-34.7027,139.4575,4
5353,Towitta,South Australia,SA,-34.5333,139.25,4
5353,Sunnydale,South Australia,SA,-34.5798,139.2255,3
5353,Moculta,South Australia,SA,-34.4687,139.1186,4
5353,Cambrai,South Australia,SA,-34.65,139.2833,4
5353,Keyneton,South Australia,SA,-34.5542,139.1349,4
5353,Marks Landing,South Australia,SA,-34.5798,139.2255,3
5353,Mount Mckenzie,South Australia,SA,-34.565,139.0884,4
5353,Sedan,South Australia,SA,-34.5833,139.3,4
5353,Punyelroo,South Australia,SA,-34.5798,139.2255,3
5353,Penrice,South Australia,SA,-34.5798,139.2255,3
5354,Bakara,South Australia,SA,-34.6635,139.7823,4
5354,Swan Reach,South Australia,SA,-34.5666,139.5975,4
5354,Naidia,South Australia,SA,-34.5167,139.8167,4
5354,Fisher,South Australia,SA,-34.5833,139.7269,3
5354,Bakara Well,South Australia,SA,-34.5833,139.7269,3
5355,Light Pass,South Australia,SA,-34.4716,139.0229,4
5355,Moppa,South Australia,SA,-34.4167,139.95,4
5355,Daveyston,South Australia,SA,-34.4692,138.8815,4
5355,Ebenezer,South Australia,SA,-34.4537,139.1629,3
5355,Marananga,South Australia,SA,-34.4856,138.9398,4
5355,Nuriootpa,South Australia,SA,-34.4682,138.9977,4
5355,Stockwell,South Australia,SA,-34.4346,139.0536,4
5355,Seppeltsfield,South Australia,SA,-34.4617,138.9575,3
5356,Annadale,South Australia,SA,-34.3914,139.3489,4
5356,Truro,South Australia,SA,-34.4087,139.1274,4
5356,Dutton,South Australia,SA,-34.3667,139.1333,4
5356,Dutton East,South Australia,SA,-34.4033,139.24,3
5356,Steinfeld,South Australia,SA,-34.4033,139.24,3
5356,St Kitts,South Australia,SA,-34.4033,139.24,3
5356,Stonefield,South Australia,SA,-34.3667,139.2833,4
5356,Sandleton,South Australia,SA,-34.4667,139.3333,4
5357,Notts Well,South Australia,SA,-34.4159,139.7875,4
5357,Paisley,South Australia,SA,-34.3844,139.7604,3
5357,Blanchetown,South Australia,SA,-34.3531,139.6145,4
5357,Mcbean Pound,South Australia,SA,-34.3844,139.7604,3
5357,New Well,South Australia,SA,-34.3874,139.9239,4
5360,Greenock,South Australia,SA,-34.457,138.9272,4
5360,Nain,South Australia,SA,-34.457,138.9272,3
5371,Roseworthy,South Australia,SA,-34.534,138.7502,4
5371,Shea-Oak Log,South Australia,SA,-34.5167,138.8167,4
5371,Morn Hill,South Australia,SA,-34.5058,138.7723,3
5371,Templers,South Australia,SA,-34.4684,138.7468,4
5372,Freeling,South Australia,SA,-34.4541,138.8087,4
5373,Fords,South Australia,SA,-34.3853,138.8749,4
5373,Bethel,South Australia,SA,-34.3278,138.8376,4
5373,Hamilton,South Australia,SA,-34.2167,138.8667,4
5373,Allendale North,South Australia,SA,-34.3011,138.9147,4
5373,St Johns,South Australia,SA,-34.3259,138.9,3
5373,Koonunga,South Australia,SA,-34.3804,138.9902,4
5373,Kapunda,South Australia,SA,-34.3387,138.9164,4
5373,Bagot Well,South Australia,SA,-34.3259,138.9,3
5374,Hampden,South Australia,SA,-34.15,139.05,4
5374,Mount Mary,South Australia,SA,-34.1,139.4333,4
5374,Frankton,South Australia,SA,-34.29,139.2054,4
5374,Buchanan,South Australia,SA,-34.1453,139.1626,3
5374,Julia,South Australia,SA,-34.1077,139.0204,4
5374,Bower,South Australia,SA,-34.1241,139.3512,4
5374,Brownlow,South Australia,SA,-34.2454,139.2693,4
5374,Sutherlands,South Australia,SA,-34.1544,139.2223,4
5374,Point Pass,South Australia,SA,-34.0757,139.0506,4
5374,Eudunda,South Australia,SA,-34.177,139.0841,4
5374,Australia Plains,South Australia,SA,-34.097,139.1562,4
5374,Neales Flat,South Australia,SA,-34.1453,139.1626,3
5374,Ngapala,South Australia,SA,-34.0714,138.9917,4
5374,Peep Hill,South Australia,SA,-34.1459,139.1544,4
5374,Hansborough,South Australia,SA,-34.1453,139.1626,3
5381,Brady Creek,South Australia,SA,-33.9739,138.9816,4
5381,Robertstown,South Australia,SA,-34,139.0667,4
5381,Rocky Plain,South Australia,SA,-33.9277,139.0539,3
5381,Worlds End,South Australia,SA,-33.8327,139.0567,4
5381,Hallelujah Hills,South Australia,SA,-33.9277,139.0539,3
5381,Geranium Plains,South Australia,SA,-33.9667,139.1667,4
5381,Emu Downs,South Australia,SA,-33.9,138.9667,4
5381,Bright,South Australia,SA,-33.9,139.0667,4
5400,Magdala,South Australia,SA,-34.4699,138.6856,3
5400,Pinkerton Plains,South Australia,SA,-34.4485,138.6731,3
5400,Woolsheds,South Australia,SA,-34.4699,138.6856,3
5400,Wasleys,South Australia,SA,-34.4699,138.6856,4
5401,Alma,South Australia,SA,-34.2668,138.6304,4
5401,Hamley Bridge,South Australia,SA,-34.3576,138.6807,4
5401,Salter Springs,South Australia,SA,-34.1843,138.6347,4
5410,Linwood,South Australia,SA,-34.3603,138.7679,4
5410,Stockport,South Australia,SA,-34.334,138.732,4
5411,Tarlee,South Australia,SA,-34.2716,138.7713,4
5411,Giles Corner,South Australia,SA,-34.223,138.729,4
5412,Woolshed Flat,South Australia,SA,-34.1442,138.7241,3
5412,Riverton,South Australia,SA,-34.1551,138.7482,4
5412,Navan,South Australia,SA,-34.1442,138.7241,3
5412,Rhynie,South Australia,SA,-34.1483,138.6895,4
5413,Marrabel,South Australia,SA,-34.15,138.8667,4
5413,Waterloo,South Australia,SA,-33.9833,138.8667,4
5413,Tothill Belt,South Australia,SA,-34.0457,138.8872,3
5413,Apoinga,South Australia,SA,-33.9279,138.939,4
5413,Black Springs,South Australia,SA,-33.9,138.9,4
5413,Tarnma,South Australia,SA,-34.15,138.95,4
5413,Saddleworth,South Australia,SA,-34.0826,138.7806,4
5413,Steelton,South Australia,SA,-34.05,138.8833,4
5413,Tothill Creek,South Australia,SA,-34.1,138.9167,4
5414,Manoora,South Australia,SA,-34,138.8167,4
5415,Stanley,South Australia,SA,-33.9167,138.7167,3
5415,Mintaro,South Australia,SA,-33.9173,138.7215,4
5416,Farrell Flat,South Australia,SA,-33.8295,138.7927,1
5416,Porter Lagoon,South Australia,SA,-33.8857,138.8563,1
5417,Mongolata,South Australia,SA,-33.5667,139.1,4
5417,Burra,South Australia,SA,-33.6717,138.9283,4
5417,Leighton,South Australia,SA,-33.65,138.75,4
5417,Koonoona,South Australia,SA,-33.6138,138.8821,3
5417,Booborowie,South Australia,SA,-33.5667,138.75,4
5417,Gum Creek,South Australia,SA,-33.6138,138.8821,3
5417,Baldina,South Australia,SA,-33.6138,138.8821,3
5417,Hanson,South Australia,SA,-33.6138,138.8821,3
5417,North Booborowie,South Australia,SA,-33.6138,138.8821,3
5417,Burra Eastern Districts,South Australia,SA,-33.6138,138.8821,3
5418,Mount Bryan,South Australia,SA,-33.4047,139.0184,4
5418,Collinsville,South Australia,SA,-33.3333,139.15,4
5419,Hallett,South Australia,SA,-33.4,138.8833,4
5419,Canowie,South Australia,SA,-33.4,138.75,4
5419,Mount Bryan East,South Australia,SA,-33.4047,139.0184,4
5419,Wonna,South Australia,SA,-33.4,138.8833,3
5419,Willalo,South Australia,SA,-33.4,138.8833,3
5419,Pine Creek,South Australia,SA,-33.4,138.8833,3
5419,Ulooloo,South Australia,SA,-33.4,138.8833,3
5420,Canowie Belt,South Australia,SA,-33.1826,138.7625,4
5420,Whyte Yarcowie,South Australia,SA,-33.2047,139.0367,3
5421,Franklyn,South Australia,SA,-33.1352,139.0737,4
5421,Terowie,South Australia,SA,-33.1667,138.9,4
5422,Ucolta,South Australia,SA,-32.95,138.9667,4
5422,Yatina,South Australia,SA,-32.9333,138.6667,4
5422,Peterborough,South Australia,SA,-32.9721,138.8407,4
5422,Erskine,South Australia,SA,-32.7333,138.85,4
5422,Mannanarie,South Australia,SA,-33.0443,138.6185,4
5422,Hardy,South Australia,SA,-32.8905,138.9224,3
5422,Paratoo,South Australia,SA,-32.7326,139.3366,4
5422,Minvalara,South Australia,SA,-32.9,138.75,4
5422,Parnaroo,South Australia,SA,-32.9786,139.1553,4
5422,Oodla Wirra,South Australia,SA,-32.8833,139.0667,4
5422,Dawson,South Australia,SA,-32.8,138.9667,4
5422,Cavenagh,South Australia,SA,-32.8905,138.9224,3
5422,Sunnybrae,South Australia,SA,-32.8905,138.9224,3
5431,Orroroo,South Australia,SA,-32.7344,138.6139,4
5431,Black Rock,South Australia,SA,-32.8333,138.7,4
5431,Amyton,South Australia,SA,-32.6,138.3333,4
5431,Minburra,South Australia,SA,-32.6695,138.5539,3
5431,Walloway,South Australia,SA,-32.6333,138.5833,4
5431,Willowie,South Australia,SA,-32.6833,138.3333,4
5431,Johnburgh,South Australia,SA,-32.45,138.7,4
5431,Coomooroo,South Australia,SA,-32.6695,138.5539,3
5431,Hammond,South Australia,SA,-32.5167,138.3167,4
5431,Eurelia,South Australia,SA,-32.55,138.5667,4
5431,Pekina,South Australia,SA,-32.8333,138.55,4
5431,Yalpara,South Australia,SA,-32.5333,138.9333,4
5431,Tarcowie,South Australia,SA,-32.95,138.5167,4
5431,Morchard,South Australia,SA,-32.7167,138.5,4
5432,Yanyarrie,South Australia,SA,-32.3042,138.5417,3
5432,Carrieton,South Australia,SA,-32.4333,138.5333,4
5432,Belton,South Australia,SA,-32.2333,138.7,4
5432,Cradock,South Australia,SA,-32.0833,138.5,4
5432,Moockra,South Australia,SA,-32.4667,138.4333,4
5433,Quorn,South Australia,SA,-32.3468,138.0418,4
5433,Stephenston,South Australia,SA,-32.3489,138.125,3
5433,Willochra,South Australia,SA,-32.2333,138.1333,4
5433,Yarrah,South Australia,SA,-32.3489,138.125,3
5433,Bruce,South Australia,SA,-32.4667,138.2,4
5433,Saltia,South Australia,SA,-32.3489,138.125,3
5434,Hawker,South Australia,SA,-31.8892,138.42,4
5434,Barndioota,South Australia,SA,-31.8892,138.42,3
5434,Kanyaka,South Australia,SA,-31.8892,138.42,3
5440,Waukaringa,South Australia,SA,-32.3,139.4333,4
5440,Yunta,South Australia,SA,-32.5833,139.55,4
5440,Nackara,South Australia,SA,-32.8,139.2333,4
5440,Mingary,South Australia,SA,-32.1355,140.7367,4
5440,Olary,South Australia,SA,-32.2829,140.3311,4
5440,Cockburn,South Australia,SA,-32.0762,140.9902,4
5440,Manna Hill,South Australia,SA,-32.433,139.9903,4
5451,Auburn,South Australia,SA,-34.0269,138.6852,4
5451,Undalya,South Australia,SA,-34.0667,138.6833,4
5452,Watervale,South Australia,SA,-33.9942,138.6248,1
5452,Leasingham,South Australia,SA,-33.9942,138.6248,1
5453,Armagh,South Australia,SA,-33.825,138.5748,4
5453,Hill River,South Australia,SA,-33.8972,138.5785,3
5453,Clare,South Australia,SA,-33.8332,138.6106,4
5453,Spring Farm,South Australia,SA,-33.8972,138.5785,3
5453,Hoyleton,South Australia,SA,-34.0333,138.55,4
5453,Stanley Flat,South Australia,SA,-33.8972,138.5785,3
5453,Barinia,South Australia,SA,-33.8972,138.5785,3
5453,Sevenhill,South Australia,SA,-33.8972,138.5785,3
5453,Boconnoc Park,South Australia,SA,-33.8972,138.5785,3
5453,Kybunga,South Australia,SA,-33.8972,138.5785,3
5453,Penwortham,South Australia,SA,-33.8972,138.5785,3
5453,Emu Flat,South Australia,SA,-33.8972,138.5785,3
5453,Spring Gully,South Australia,SA,-33.8972,138.5785,3
5453,Benbournie,South Australia,SA,-33.8972,138.5785,3
5453,Gillentown,South Australia,SA,-33.8972,138.5785,3
5453,Polish Hill River,South Australia,SA,-33.8972,138.5785,3
5454,Spalding,South Australia,SA,-33.4979,138.6075,4
5454,Mayfield,South Australia,SA,-34.0871,138.6347,3
5454,Andrews,South Australia,SA,-34.6763,138.662,4
5454,Hacklins Corner,South Australia,SA,-34.0871,138.6347,3
5454,Washpool,South Australia,SA,-34.0871,138.6347,3
5454,Euromina,South Australia,SA,-34.0871,138.6347,3
5454,Broughton River Valley,South Australia,SA,-34.0871,138.6347,3
5455,Hilltown,South Australia,SA,-33.8458,138.6125,1
5460,Pinery,South Australia,SA,-34.3,138.45,4
5460,Owen,South Australia,SA,-34.272,138.5444,4
5460,Barabba,South Australia,SA,-34.345,138.5899,4
5460,Stockyard Creek,South Australia,SA,-34.3003,138.5852,4
5461,Goyder,South Australia,SA,-34.0851,138.3993,3
5461,Stow,South Australia,SA,-33.9833,138.3667,4
5461,Whitwarta,South Australia,SA,-34.1,138.3333,4
5461,Saints,South Australia,SA,-34.15,138.3167,4
5461,Hoskin Corner,South Australia,SA,-34.0851,138.3993,3
5461,Bowillia,South Australia,SA,-33.95,138.4,4
5461,Halbury,South Australia,SA,-34.0833,138.5167,4
5461,Erith,South Australia,SA,-34.0851,138.3993,3
5461,Dalkey,South Australia,SA,-34.1868,138.4384,4
5461,Mount Templeton,South Australia,SA,-34.0851,138.3993,3
5461,Balaklava,South Australia,SA,-34.1454,138.4115,4
5461,Watchman,South Australia,SA,-34.0851,138.3993,3
5461,Everard Central,South Australia,SA,-34.0851,138.3993,3
5462,Blyth,South Australia,SA,-33.8486,138.4893,4
5464,Anama,South Australia,SA,-33.7,138.4167,3
5464,Condowie,South Australia,SA,-33.7667,138.3,4
5464,Hart,South Australia,SA,-33.7,138.4167,3
5464,Rochester,South Australia,SA,-33.6952,138.4853,4
5464,Koolunga,South Australia,SA,-33.5833,138.3333,4
5464,Bungaree,South Australia,SA,-33.7443,138.5609,4
5464,Marola,South Australia,SA,-33.7,138.4167,3
5464,Brinkworth,South Australia,SA,-33.6954,138.4035,4
5470,Yacka,South Australia,SA,-33.5514,138.4805,1
5471,Gulnare,South Australia,SA,-33.4672,138.443,4
5472,Georgetown,South Australia,SA,-33.3667,138.4,4
5473,Gladstone,South Australia,SA,-33.2669,138.3566,4
5480,Stone Hut,South Australia,SA,-33.1176,138.3595,3
5480,Appila,South Australia,SA,-33.05,138.4167,4
5480,Laura,South Australia,SA,-33.1852,138.3023,4
5481,Wirrabara,South Australia,SA,-33.0338,138.2692,4
5481,Wongyarra,South Australia,SA,-32.9779,138.2175,3
5481,Murray Town,South Australia,SA,-32.9333,138.2333,4
5481,Bangor,South Australia,SA,-32.9667,138.15,4
5482,Wepowie,South Australia,SA,-32.8333,138.4833,4
5482,Booleroo Centre,South Australia,SA,-32.8799,138.3507,4
5483,Melrose,South Australia,SA,-32.8253,138.1863,4
5485,Wilmington,South Australia,SA,-32.6499,138.0981,4
5490,Caltowie North,South Australia,SA,-33.1833,138.4833,3
5490,Caltowie,South Australia,SA,-33.1833,138.4833,4
5490,Caltowie West,South Australia,SA,-33.1833,138.4833,3
5491,Hornsdale,South Australia,SA,-33.0681,138.5217,4
5491,Bundaleer North,South Australia,SA,-33.136,138.5608,3
5491,Jamestown,South Australia,SA,-33.2053,138.605,4
5491,Bundaleer Gardens,South Australia,SA,-33.136,138.5608,3
5491,Belalie North,South Australia,SA,-33.136,138.5608,3
5491,West Bundaleer,South Australia,SA,-33.136,138.5608,3
5491,Belalie East,South Australia,SA,-33.136,138.5608,3
5493,Yongala,South Australia,SA,-34.9167,137.55,4
5495,Baroota,South Australia,SA,-32.85,138.0333,4
5495,Port Germein,South Australia,SA,-33.0216,138.0013,4
5495,Mambray Creek,South Australia,SA,-32.8,137.9833,4
5495,Nectar Brook,South Australia,SA,-32.7333,137.9333,4
5495,Port Flinders,South Australia,SA,-33.1014,138.0357,3
5495,Germein Bay,South Australia,SA,-32.8512,137.9878,3
5501,Parham,South Australia,SA,-34.4333,138.2667,4
5501,Webb Beach,South Australia,SA,-34.4493,138.3886,3
5501,Dublin,South Australia,SA,-34.45,138.35,4
5501,Calomba,South Australia,SA,-34.4493,138.3886,3
5501,Wild Horse Plains,South Australia,SA,-34.3601,138.2933,4
5501,Port Gawler,South Australia,SA,-34.504,138.4302,3
5501,Lower Light,South Australia,SA,-34.5326,138.437,4
5501,Middle Beach,South Australia,SA,-34.504,138.4302,3
5501,Lewiston,South Australia,SA,-34.6045,138.5913,4
5501,Thompson Beach,South Australia,SA,-34.4493,138.3886,3
5501,Long Plains,South Australia,SA,-34.359,138.3783,4
5501,Windsor,South Australia,SA,-34.4219,138.3308,4
5501,Avon,South Australia,SA,-34.2839,138.336,4
5501,Two Wells,South Australia,SA,-34.5933,138.5137,4
5502,Mallala,South Australia,SA,-34.4374,138.5099,4
5502,Korunye,South Australia,SA,-34.5124,138.5102,4
5502,Grace Plains,South Australia,SA,-34.4902,138.5422,3
5502,Reeves Plains,South Australia,SA,-34.5167,138.6,4
5502,Redbanks,South Australia,SA,-34.4902,138.5422,3
5502,Fischer,South Australia,SA,-34.4902,138.5422,3
5510,Lochiel,South Australia,SA,-33.9333,138.1667,4
5520,Bumbunga,South Australia,SA,-33.9167,138.2333,4
5520,Burnsfield,South Australia,SA,-33.7327,138.1419,3
5520,Wokurna,South Australia,SA,-33.7167,138.05,4
5520,Barunga Gap,South Australia,SA,-33.5241,138.0404,4
5520,Snowtown,South Australia,SA,-33.7808,138.2175,4
5521,Redhill,South Australia,SA,-33.5333,138.2333,4
5522,Fisherman Bay,South Australia,SA,-33.5594,137.9429,4
5522,Ward Hill,South Australia,SA,-33.5801,137.9394,3
5522,Port Broughton,South Australia,SA,-33.6007,137.936,4
5523,Wandearah West,South Australia,SA,-33.3927,137.9731,4
5523,Merriton,South Australia,SA,-33.45,138.15,4
5523,Crystal Brook,South Australia,SA,-33.3526,138.2095,4
5523,Clements Gap,South Australia,SA,-33.5241,138.0404,4
5523,Beetaloo,South Australia,SA,-33.2333,138.2333,4
5523,Narridy,South Australia,SA,-33.4333,138.3,4
5523,Huddleston,South Australia,SA,-33.3949,138.171,3
5523,Wandearah East,South Australia,SA,-33.3833,138.0667,4
5523,Nurom,South Australia,SA,-33.3949,138.171,3
5523,Wandearah,South Australia,SA,-33.3949,138.171,3
5540,Bungama,South Australia,SA,-33.189,138.0451,3
5540,Pirie East,South Australia,SA,-33.3833,138.0667,4
5540,Risdon Park,South Australia,SA,-33.1966,137.994,4
5540,Port Davis,South Australia,SA,-33.2667,137.8541,4
5540,Port Pirie South,South Australia,SA,-33.1918,138.0175,3
5540,Coonamia,South Australia,SA,-33.189,138.0451,3
5540,Nelshaby,South Australia,SA,-33.1261,138.1105,4
5540,Port Pirie West,South Australia,SA,-33.3927,137.9731,4
5540,Port Pirie,South Australia,SA,-33.1918,138.0175,4
5540,Risdon Park South,South Australia,SA,-33.1966,137.994,4
5540,Solomontown,South Australia,SA,-33.2,138.0333,4
5540,Warnertown,South Australia,SA,-33.189,138.0451,3
5540,Telowie,South Australia,SA,-33.0549,138.0684,4
5540,Lower Broughton,South Australia,SA,-33.189,138.0451,3
5540,Napperby,South Australia,SA,-33.1574,138.1164,4
5550,Inkerman,South Australia,SA,-34.2799,138.2378,4
5550,Beaufort,South Australia,SA,-34.0833,138.2,4
5550,Bowmans,South Australia,SA,-34.1589,138.2594,4
5550,Nantawarra,South Australia,SA,-34.0167,138.2333,4
5550,Port Wakefield,South Australia,SA,-34.1848,138.1453,4
5550,Kallora,South Australia,SA,-34.1505,138.2203,3
5550,Proof Range,South Australia,SA,-34.1505,138.2203,3
5550,South Hummocks,South Australia,SA,-34.1505,138.2203,3
5552,Paskeville,South Australia,SA,-34.0333,137.9,4
5552,Melton,South Australia,SA,-34.0722,137.9444,3
5552,Thrington,South Australia,SA,-34.0131,137.8294,4
5552,Sunnyvale,South Australia,SA,-34.0722,137.9444,3
5552,Kainton,South Australia,SA,-34.1233,137.9028,4
5552,Port Arthur,South Australia,SA,-34.0722,137.9444,3
5552,Kulpara,South Australia,SA,-34.0667,138.0333,4
5554,Matta Flat,South Australia,SA,-33.9199,137.6638,3
5554,Wallaroo Mines,South Australia,SA,-33.9725,137.6939,4
5554,Jerusalem,South Australia,SA,-33.9735,137.711,3
5554,Kadina,South Australia,SA,-33.965,137.7163,4
5554,Willamulka,South Australia,SA,-34.005,137.711,3
5554,Thomas Plain,South Australia,SA,-33.9637,137.9278,4
5554,Boors Plain,South Australia,SA,-34.005,137.711,3
5554,Cunliffe,South Australia,SA,-34.0833,137.7333,4
5554,New Town,South Australia,SA,-33.9199,137.6638,3
5554,Jericho,South Australia,SA,-33.9199,137.6638,3
5555,Tickera,South Australia,SA,-33.7833,137.7,4
5555,Mundoora,South Australia,SA,-33.6,138.0833,4
5555,Alford,South Australia,SA,-33.8167,137.8167,4
5555,Collinsfield,South Australia,SA,-33.7333,137.8667,3
5555,Lake View,South Australia,SA,-33.8287,138.0507,3
5555,Hope Gap,South Australia,SA,-33.7333,137.8667,3
5556,North Beach,South Australia,SA,-33.9029,137.6329,4
5556,Warburto,South Australia,SA,-34,137.6167,4
5556,Wallaroo Plain,South Australia,SA,-33.9472,137.6277,3
5556,Wallaroo,South Australia,SA,-33.9388,137.6337,4
5558,Moonta Bay,South Australia,SA,-34.05,137.5667,4
5558,Moonta Mines,South Australia,SA,-34.0779,137.6086,3
5558,Moonta,South Australia,SA,-34.0684,137.5908,4
5558,Hamley,South Australia,SA,-34.3576,138.6807,4
5558,Paramatta,South Australia,SA,-34.1174,137.769,3
5558,East Moonta,South Australia,SA,-34.1127,137.6609,3
5558,Agery,South Australia,SA,-34.1568,137.7429,4
5558,Kooroona,South Australia,SA,-34.1127,137.6609,3
5558,Port Hughes,South Australia,SA,-34.0833,137.55,4
5558,Nalyappa,South Australia,SA,-34.1127,137.6609,3
5558,North Yelta,South Australia,SA,-34.0616,137.6321,4
5558,North Moonta,South Australia,SA,-34.0528,137.5931,3
5558,Yelta,South Australia,SA,-34.0616,137.6321,4
5558,Cross Roads,South Australia,SA,-34.1127,137.6609,3
5560,Bute,South Australia,SA,-33.8656,138.0066,4
5560,Ninnes,South Australia,SA,-33.8656,138.0066,3
5570,Winulta,South Australia,SA,-34.2763,137.8728,4
5570,Port Clinton,South Australia,SA,-34.2243,138.0206,4
5570,Price,South Australia,SA,-34.2864,137.9943,4
5570,Clinton,South Australia,SA,-34.183,137.9648,3
5570,Clinton Centre,South Australia,SA,-34.2333,137.9167,4
5571,Ardrossan,South Australia,SA,-34.4222,137.9191,4
5571,Pine Point,South Australia,SA,-34.5667,137.8667,4
5571,Tiddy Widdy Beach,South Australia,SA,-34.4014,137.9402,4
5571,Black Point,South Australia,SA,-34.4481,137.8819,3
5571,Sandilands,South Australia,SA,-34.5138,137.7932,4
5571,Petersville,South Australia,SA,-34.4481,137.8819,3
5571,Dowlingville,South Australia,SA,-34.327,137.9193,4
5571,Rogues Point,South Australia,SA,-34.4481,137.8819,3
5571,Cunningham,South Australia,SA,-34.4148,137.7995,4
5571,James Well,South Australia,SA,-34.4481,137.8819,3
5572,Arthurton,South Australia,SA,-34.2667,137.75,4
5573,Yorke Valley,South Australia,SA,-35.1494,137.1512,4
5573,Point Pearce,South Australia,SA,-34.4174,137.5005,3
5573,South Kilkerran,South Australia,SA,-34.4333,137.6,4
5573,Chinaman Wells,South Australia,SA,-34.3811,137.4805,4
5573,Weetulta,South Australia,SA,-34.25,137.6333,4
5573,Balgowan,South Australia,SA,-34.3333,137.4833,4
5573,Port Victoria,South Australia,SA,-34.4972,137.4853,4
5573,Maitland,South Australia,SA,-34.3739,137.6733,4
5573,Urania,South Australia,SA,-34.511,137.6064,4
5573,Wauraltee,South Australia,SA,-34.5833,137.55,4
5575,Hardwicke Bay,South Australia,SA,-34.8888,137.458,4
5575,Bluff Beach,South Australia,SA,-34.7325,137.4807,4
5575,Point Turton,South Australia,SA,-34.9523,137.3565,4
5575,Stenhouse Bay,South Australia,SA,-35.2833,136.9333,4
5575,Port Rickaby,South Australia,SA,-34.6833,137.5,4
5575,Wool Bay,South Australia,SA,-34.9911,137.7567,4
5575,Brentwood,South Australia,SA,-34.8667,137.5,4
5575,White Hut,South Australia,SA,-34.9243,137.3787,3
5575,Koolywurtie,South Australia,SA,-34.65,137.6333,4
5575,Minlaton,South Australia,SA,-34.771,137.5958,4
5575,Corny Point,South Australia,SA,-34.9249,137.0812,4
5575,Ramsay,South Australia,SA,-34.9243,137.3787,3
5575,Marion Bay,South Australia,SA,-35.2333,137.0167,4
5576,Honiton,South Australia,SA,-35.1167,137.65,4
5576,Port Moorowie,South Australia,SA,-35.1167,137.5167,4
5576,Yorketown,South Australia,SA,-35.0185,137.6049,4
5577,Inneston,South Australia,SA,-35.275,136.8917,4
5577,Couch Beach,South Australia,SA,-34.9095,137.1769,4
5577,The Pines,South Australia,SA,-34.9165,137.1605,4
5577,Point Souttar,South Australia,SA,-35.1327,137.1464,3
5577,Warooka,South Australia,SA,-34.9903,137.4012,4
5577,Foul Bay,South Australia,SA,-35.1327,137.1464,3
5580,Port Julia,South Australia,SA,-34.6667,137.8667,4
5580,Curramulka,South Australia,SA,-34.7,137.7,4
5581,Sheaoak Flat,South Australia,SA,-34.778,137.8608,3
5581,Port Vincent,South Australia,SA,-34.7798,137.8593,4
5582,Stansbury,South Australia,SA,-34.9111,137.799,4
5582,Port Giles,South Australia,SA,-34.9111,137.799,3
5583,Coobowie,South Australia,SA,-35.044,137.7311,4
5583,Sultana Point,South Australia,SA,-35.0671,137.7309,3
5583,Edithburgh,South Australia,SA,-35.0846,137.744,4
5600,Whyalla,South Australia,SA,-33.0327,137.5648,4
5600,Whyalla Playford,South Australia,SA,-33.0279,137.562,4
5600,Whyalla DC,South Australia,SA,-33.0303,137.5634,3
5601,Iron Baron,South Australia,SA,-32.9951,137.1686,4
5601,Point Lowly North,South Australia,SA,-32.864,137.1598,3
5601,Backy Point,South Australia,SA,-32.864,137.1598,3
5601,Murninnie Beach,South Australia,SA,-32.864,137.1598,3
5601,Port Bonython,South Australia,SA,-32.9907,137.7754,4
5601,Iron Knob,South Australia,SA,-32.733,137.151,4
5601,Point Lowly,South Australia,SA,-32.864,137.1598,3
5601,Douglas Point South,South Australia,SA,-32.864,137.1598,3
5601,Whyalla Barson,South Australia,SA,-32.864,137.1598,3
5601,Cultana,South Australia,SA,-32.864,137.1598,3
5601,Cowleds Landing,South Australia,SA,-32.864,137.1598,3
5601,Douglas Point,South Australia,SA,-32.864,137.1598,3
5601,Mullaquana,South Australia,SA,-33.2118,137.3627,4
5601,False Bay,South Australia,SA,-32.864,137.1598,3
5601,Fitzgerald Bay,South Australia,SA,-32.864,137.1598,3
5602,Miltalie,South Australia,SA,-33.5833,136.8333,4
5602,Lucky Bay,South Australia,SA,-33.6165,136.8785,3
5602,Cowell,South Australia,SA,-33.6823,136.9212,4
5602,Port Gibbon,South Australia,SA,-33.7972,136.8028,4
5602,Minbrie,South Australia,SA,-33.5,136.9667,4
5602,Midgee,South Australia,SA,-33.4209,137.1546,4
5602,Mitchellville,South Australia,SA,-33.6167,137.15,4
5602,Mangalo,South Australia,SA,-33.5341,136.6208,4
5603,Verran,South Australia,SA,-33.925,136.4,3
5603,Arno Bay,South Australia,SA,-33.9,136.5667,4
5603,Wharminda,South Australia,SA,-33.9538,136.2458,4
5603,Hincks,South Australia,SA,-33.925,136.4,3
5604,Port Neill,South Australia,SA,-34.1188,136.3483,4
5605,Butler,South Australia,SA,-34.1167,136.1,4
5605,Tumby Bay,South Australia,SA,-34.3751,136.1033,4
5606,Port Lincoln,South Australia,SA,-34.7263,135.8744,4
5607,Koppio,South Australia,SA,-34.4167,135.85,4
5607,Little Douglas,South Australia,SA,-34.3251,135.6717,3
5607,Fountain,South Australia,SA,-34.6,135.6333,4
5607,Coomunga,South Australia,SA,-34.6437,135.7074,4
5607,Louth Bay,South Australia,SA,-34.55,135.9,4
5607,Point Boston,South Australia,SA,-34.3251,135.6717,3
5607,Boston,South Australia,SA,-34.6906,135.9165,4
5607,Wanilla,South Australia,SA,-34.5,135.6833,4
5607,Poonindie,South Australia,SA,-34.5813,135.881,4
5607,Green Patch,South Australia,SA,-34.3251,135.6717,3
5607,Yallunda Flat,South Australia,SA,-34.3475,135.8787,4
5607,Coulta,South Australia,SA,-34.3833,135.4667,4
5607,Wangary,South Australia,SA,-34.55,135.4833,4
5607,Whites River,South Australia,SA,-34.3251,135.6717,3
5607,Mount Hope,South Australia,SA,-34.1167,135.3833,4
5607,Ungarra,South Australia,SA,-34.1801,136.0481,4
5607,Sleaford,South Australia,SA,-34.8144,135.7438,4
5607,Duck Ponds,South Australia,SA,-34.7333,135.7833,4
5607,Murdinga,South Australia,SA,-33.7167,135.7167,4
5607,Port Lincoln,South Australia,SA,-34.7263,135.8744,4
5607,Mount Drummond,South Australia,SA,-34.218,135.4092,4
5607,Tootenilla,South Australia,SA,-34.3251,135.6717,3
5607,Charlton Gully,South Australia,SA,-34.55,135.8035,4
5607,Warrachie,South Australia,SA,-33.65,135.7167,4
5607,Pearlah,South Australia,SA,-34.5934,135.6761,4
5607,Lincoln National Park,South Australia,SA,-34.8341,135.856,4
5607,Lipson,South Australia,SA,-34.3,136.1333,4
5607,Venus Bay,South Australia,SA,-33.2333,134.6833,4
5607,Coffin Bay,South Australia,SA,-34.6167,135.4667,4
5607,Hawson,South Australia,SA,-34.3251,135.6717,3
5607,Tiatukia,South Australia,SA,-34.3251,135.6717,3
5607,Kiana,South Australia,SA,-34.3251,135.6717,3
5607,Sheringa,South Australia,SA,-33.8443,135.2321,4
5607,Uley,South Australia,SA,-34.3251,135.6717,3
5607,Brooker,South Australia,SA,-34.1,135.9,4
5607,North Shields,South Australia,SA,-34.6286,135.8622,4
5607,Tulka,South Australia,SA,-34.7942,135.7999,4
5607,Kellidie Bay,South Australia,SA,-34.3251,135.6717,3
5607,Tooligie,South Australia,SA,-33.85,135.7,4
5607,Moody,South Australia,SA,-34.3251,135.6717,3
5607,Warrow,South Australia,SA,-34.3333,135.4667,4
5607,Whites Flat,South Australia,SA,-34.5122,135.8532,4
5607,Farm Beach,South Australia,SA,-34.3251,135.6717,3
5607,Mount Dutton Bay,South Australia,SA,-34.3251,135.6717,3
5608,Whyalla Norrie,South Australia,SA,-33.029,137.5392,4
5608,Whyalla Norrie East,South Australia,SA,-33.027,137.532,3
5608,Whyalla Stuart,South Australia,SA,-33.0249,137.5247,4
5608,Whyalla Norrie North,South Australia,SA,-33.027,137.532,3
5609,Whyalla Jenkins,South Australia,SA,-33.0221,137.5127,4
5630,Edillilie,South Australia,SA,-34.4118,135.7063,4
5631,Cockaleechie,South Australia,SA,-34.2601,135.7273,3
5631,Cummins,South Australia,SA,-34.264,135.726,4
5632,Karkoo,South Australia,SA,-34.0328,135.7293,4
5632,Mitchell,South Australia,SA,-35.0051,138.5623,4
5632,Yeelanna,South Australia,SA,-34.1408,135.7294,4
5632,Kapinnie,South Australia,SA,-34.138,135.4976,4
5633,Boonerdo,South Australia,SA,-33.5667,135.7667,3
5633,Lock,South Australia,SA,-33.568,135.7565,4
5640,Waddikee,South Australia,SA,-33.3131,136.2551,4
5640,Jamieson,South Australia,SA,-33.5083,136.3583,3
5640,Cleve,South Australia,SA,-33.7,136.5,4
5640,Campoona,South Australia,SA,-33.5083,136.3583,3
5641,Moseley,South Australia,SA,-33.0833,136.5167,4
5641,Pinkawillinie,South Australia,SA,-33.105,136.3337,3
5641,Buckleboo,South Australia,SA,-32.9167,136.2,4
5641,Wilcherry,South Australia,SA,-33.105,136.3337,3
5641,Cortlinye,South Australia,SA,-33.0167,136.3333,4
5641,Yalanda,South Australia,SA,-33.105,136.3337,3
5641,Caralue,South Australia,SA,-33.3667,136.2,4
5641,Panitya,South Australia,SA,-33.105,136.3337,3
5641,Kimba,South Australia,SA,-33.1416,136.4187,4
5641,Kelly,South Australia,SA,-33.105,136.3337,3
5641,Cunyarie,South Australia,SA,-33.105,136.3337,3
5641,Solomon,South Australia,SA,-33.105,136.3337,3
5641,Barna,South Australia,SA,-33.105,136.3337,3
5642,Rudall,South Australia,SA,-33.6833,136.2667,4
5642,Darke Peak,South Australia,SA,-33.4667,136.2,4
5642,Murlong,South Australia,SA,-33.5778,136.2278,3
5642,Kielpa,South Australia,SA,-33.5833,136.2167,4
5642,Hambidge,South Australia,SA,-33.5778,136.2278,3
5650,Koongawa,South Australia,SA,-33.1667,135.8833,4
5650,Warramboo,South Australia,SA,-33.2333,135.6,4
5650,Cootra,South Australia,SA,-33.25,135.8667,4
5651,Kyancutta,South Australia,SA,-33.1333,135.5667,4
5652,Paney,South Australia,SA,-33.0334,135.4402,3
5652,Pygery,South Australia,SA,-33.0167,135.4167,4
5652,Wudinna,South Australia,SA,-33.0501,135.4638,4
5653,Yaninee,South Australia,SA,-32.9462,135.2737,4
5654,Mount Damper,South Australia,SA,-33.1167,135.1,4
5654,Karcultaby,South Australia,SA,-32.7667,134.9667,4
5654,Minnipa,South Australia,SA,-32.85,135.15,4
5654,Cocata,South Australia,SA,-32.9111,135.0722,3
5655,Poochera,South Australia,SA,-32.7167,134.85,4
5655,Kaldoonera,South Australia,SA,-32.7167,134.85,3
5655,Bockelberg,South Australia,SA,-32.7167,134.85,3
5660,Cungena,South Australia,SA,-32.5833,134.7167,4
5660,Chilpenunda,South Australia,SA,-32.5833,134.7167,3
5661,Wirrulla,South Australia,SA,-32.4,134.5167,4
5661,Koolgera,South Australia,SA,-32.4,134.5278,3
5661,Yantanabie,South Australia,SA,-32.4833,134.6363,4
5661,Wallala,South Australia,SA,-32.4,134.5278,3
5661,Pimbaacla,South Australia,SA,-32.3241,134.4495,4
5670,Kappawanta,South Australia,SA,-33.5096,134.9778,3
5670,Colton,South Australia,SA,-33.4833,134.9333,4
5670,Ulyerra,South Australia,SA,-33.5096,134.9778,3
5670,Bramfield,South Australia,SA,-33.6167,135.0167,4
5670,Coolillie,South Australia,SA,-33.5096,134.9778,3
5670,Mount Wedge,South Australia,SA,-33.4665,135.1566,4
5670,Elliston,South Australia,SA,-33.6479,134.8889,4
5670,Palkagee,South Australia,SA,-33.4141,135.5029,4
5670,Talia,South Australia,SA,-33.3167,134.8833,4
5670,Mount Joy,South Australia,SA,-33.5291,135.1062,4
5670,Polda,South Australia,SA,-33.5281,135.4578,4
5671,Mortana,South Australia,SA,-33.0279,134.4531,4
5671,Port Kenny,South Australia,SA,-33.1667,134.7,4
5671,Calca,South Australia,SA,-33.0833,134.35,4
5671,Tyringa,South Australia,SA,-33.0917,134.55,3
5671,Baird Bay,South Australia,SA,-33.0917,134.55,3
5671,Mount Cooper,South Australia,SA,-33.0833,134.7,4
5671,Witera,South Australia,SA,-33.0917,134.55,3
5671,Colley,South Australia,SA,-33.0917,134.55,3
5680,Chinbingina,South Australia,SA,-32.2167,134.2,4
5680,Sceale Bay,South Australia,SA,-33.0167,134.2,4
5680,Streaky Bay,South Australia,SA,-32.7955,134.2093,4
5680,Piednippie,South Australia,SA,-32.473,134.2278,3
5680,Petina,South Australia,SA,-32.4765,134.3969,4
5680,Perlubie,South Australia,SA,-32.473,134.2278,3
5680,Nunjikompita,South Australia,SA,-32.2691,134.3383,4
5680,Westall,South Australia,SA,-32.473,134.2278,3
5680,Haslam,South Australia,SA,-32.5,134.2,4
5680,Pureba,South Australia,SA,-32.473,134.2278,3
5680,Carawa,South Australia,SA,-32.3833,134.2,4
5680,Maryvale,South Australia,SA,-32.473,134.2278,3
5680,Chandada,South Australia,SA,-32.7548,134.6731,4
5680,Yanerbie,South Australia,SA,-32.473,134.2278,3
5680,Puntabie,South Australia,SA,-32.2036,134.1281,4
5680,Laura Bay,South Australia,SA,-32.2167,133.8333,4
5680,Mudamuckla,South Australia,SA,-32.1584,134.0438,4
5680,Eba Anchorage,South Australia,SA,-32.473,134.2278,3
5680,Smoky Bay,South Australia,SA,-32.3784,133.934,4
5680,Inkster,South Australia,SA,-32.8167,134.6833,4
5690,Nadia,South Australia,SA,-32.077,133.4841,4
5690,Denial Bay,South Australia,SA,-32.1006,133.5768,4
5690,Ceduna,South Australia,SA,-32.1266,133.6727,4
5690,Koonibba,South Australia,SA,-31.9044,133.431,4
5690,Thevenard,South Australia,SA,-32.1458,133.6485,4
5690,Charra,South Australia,SA,-32.0833,133.3333,3
5690,Kalanbi,South Australia,SA,-31.9513,133.6311,4
5690,Wandana,South Australia,SA,-32.0129,133.2552,3
5690,Bookabie,South Australia,SA,-31.8384,132.6899,4
5690,Maltee,South Australia,SA,-32.0833,133.9,4
5690,Uworra,South Australia,SA,-31.965,133.327,4
5690,Coorabie,South Australia,SA,-31.8998,132.2953,4
5690,Penong,South Australia,SA,-31.9303,133.0107,4
5690,Merghiny,South Australia,SA,-32.1167,133.8333,4
5690,Watraba,South Australia,SA,-31.9734,133.2119,4
5690,White Well Corner,South Australia,SA,-32.0129,133.2552,3
5690,Fowlers Bay,South Australia,SA,-31.9865,132.4349,4
5690,Yalata,South Australia,SA,-31.4802,131.8424,4
5700,Winninowie,South Australia,SA,-32.5369,137.8122,3
5700,Port Augusta North,South Australia,SA,-32.6049,137.6894,3
5700,Port Paterson,South Australia,SA,-32.5543,137.8309,4
5700,Port Augusta,South Australia,SA,-32.496,137.7728,4
5700,Commissariat Point,South Australia,SA,-32.5369,137.8122,3
5700,Miranda,South Australia,SA,-32.6139,137.8949,4
5700,Blanche Harbor,South Australia,SA,-32.5369,137.8122,3
5700,Port Augusta West,South Australia,SA,-32.4861,137.7583,4
5700,Wami Kata,South Australia,SA,-32.5369,137.8122,3
5700,Mundallio,South Australia,SA,-32.4678,137.8842,4
5701,Tarcoola,South Australia,SA,-30.7101,134.5707,4
5701,Arkaroola Village,South Australia,SA,-30.3116,139.3356,4
5701,Glendambo,South Australia,SA,-30.9683,135.7502,4
5701,Woolundunga,South Australia,SA,-32.5374,137.9454,4
5701,Cook,South Australia,SA,-32.5369,137.8122,3
5701,Kingoonya,South Australia,SA,-30.9107,135.3105,4
5701,Port Augusta,South Australia,SA,-32.496,137.7728,4
5710,Kingoonya,South Australia,SA,-30.9107,135.3105,4
5710,Cook,South Australia,SA,-29.3441,134.786,3
5710,Nonning,South Australia,SA,-32.5,136.5,4
5710,Stirling North,South Australia,SA,-32.4799,137.838,4
5710,Tarcoola,South Australia,SA,-30.7101,134.5707,4
5710,Port Augusta,South Australia,SA,-32.496,137.7728,4
5720,Pimba,South Australia,SA,-31.2521,136.8048,4
5720,Woomera,South Australia,SA,-31.1998,136.8326,4
5722,Andamooka,South Australia,SA,-30.4481,137.1656,4
5723,Coober Pedy,South Australia,SA,-29.0141,134.755,4
5724,Mintabie,South Australia,SA,-27.3103,133.2966,4
5724,Marla,South Australia,SA,-27.2997,133.6225,4
5725,Olympic Dam,South Australia,SA,-30.4468,136.8668,4
5725,Roxby Downs,South Australia,SA,-30.5631,136.8955,4
5730,Blinman,South Australia,SA,-31.1174,138.6896,4
5730,Beltana,South Australia,SA,-30.8143,138.4152,4
5730,Parachilna,South Australia,SA,-31.1322,138.395,4
5731,Leigh Creek,South Australia,SA,-30.5931,138.4003,4
5731,Innamincka,South Australia,SA,-27.7073,140.737,4
5731,Lyndhurst,South Australia,SA,-30.2879,138.3493,4
5731,Cordillo Downs,South Australia,SA,-30.4405,138.3748,3
5731,Coopers Creek,South Australia,SA,-30.4405,138.3748,3
5732,Copley,South Australia,SA,-30.5599,138.4213,4
5732,Nepabunna,South Australia,SA,-30.5635,138.967,4
5733,Farina,South Australia,SA,-30.0665,138.2847,4
5733,Marree,South Australia,SA,-29.6512,138.071,4
5734,Oodnadatta,South Australia,SA,-27.5514,135.4491,4
5800,Adelaide,South Australia,SA,-34.9287,138.5986,4
5810,Adelaide,South Australia,SA,-34.9287,138.5986,4
5839,Adelaide,South Australia,SA,-34.9287,138.5986,4
5860,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5861,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5862,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5863,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5864,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5865,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5866,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5867,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5868,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5869,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5870,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5871,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5872,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5873,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5874,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5875,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5876,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5877,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5878,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5879,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5880,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5881,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5882,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5883,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5884,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5885,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5886,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5887,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5888,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5889,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5890,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5891,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5892,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5893,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5894,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5895,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5896,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5897,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5898,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5899,Adelaide Mail Centre,South Australia,SA,-34.9287,138.5986,1
5900,Adelaide Mail Centre,South Australia,SA,-34.8956,138.5855,1
5901,Adelaide Mail Centre,South Australia,SA,-34.8956,138.5855,1
5902,Adelaide Mail Centre,South Australia,SA,-34.8956,138.5855,1
5903,Adelaide Mail Centre,South Australia,SA,-34.8956,138.5855,1
5904,Adelaide Mail Centre,South Australia,SA,-34.8956,138.5855,1
5920,Adelaide,South Australia,SA,-34.9287,138.5986,4
5942,Regency Park,South Australia,SA,-34.8626,138.5724,4
5950,Export Park,South Australia,SA,-34.9461,138.5302,3
5950,Adelaide Airport,South Australia,SA,-34.9391,138.5341,4
5960,Torrens Island,South Australia,SA,-34.8956,138.5855,1
5960,Garden Island,South Australia,SA,-34.8956,138.5855,1
7000,Mount Stuart,Tasmania,TAS,-42.8716,147.3016,4
7000,North Hobart,Tasmania,TAS,-42.8741,147.316,4
7000,Queens Domain,Tasmania,TAS,-42.877,147.3288,3
7000,West Hobart,Tasmania,TAS,-42.8766,147.3069,4
7000,Glebe,Tasmania,TAS,-42.8747,147.3281,4
7000,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7001,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7002,North Hobart,Tasmania,TAS,-42.8741,147.316,4
7004,South Hobart,Tasmania,TAS,-42.8946,147.3092,4
7004,Battery Point,Tasmania,TAS,-42.8899,147.3332,4
7005,Sandy Bay,Tasmania,TAS,-42.8945,147.3244,4
7005,Dynnyrne,Tasmania,TAS,-42.9054,147.3158,4
7005,Lower Sandy Bay,Tasmania,TAS,-42.913,147.3532,3
7005,University Of Tasmania,Tasmania,TAS,-42.9107,147.3357,3
7006,Sandy Bay,Tasmania,TAS,-42.8945,147.3244,4
7007,Tolmans Hill,Tasmania,TAS,-42.914,147.3071,4
7007,Mount Nelson,Tasmania,TAS,-42.9207,147.3207,4
7008,New Town,Tasmania,TAS,-42.8576,147.3087,4
7008,Lenah Valley,Tasmania,TAS,-42.8715,147.2751,4
7009,West Moonah,Tasmania,TAS,-42.85,147.2818,4
7009,Moonah,Tasmania,TAS,-42.8462,147.3023,4
7009,Derwent Park,Tasmania,TAS,-42.8351,147.2949,4
7009,Lutana,Tasmania,TAS,-42.8347,147.3115,4
7010,Rosetta,Tasmania,TAS,-42.821,147.2526,4
7010,Montrose,Tasmania,TAS,-42.829,147.2473,4
7010,Dowsing Point,Tasmania,TAS,-42.8208,147.3022,4
7010,Goodwood,Tasmania,TAS,-42.8282,147.293,4
7010,Elwick,Tasmania,TAS,-42.8355,147.2646,3
7010,Glenorchy,Tasmania,TAS,-42.8334,147.2759,4
7011,Berriedale,Tasmania,TAS,-42.8167,147.25,4
7011,Chigwell,Tasmania,TAS,-42.8091,147.2448,4
7011,Claremont,Tasmania,TAS,-42.7833,147.25,4
7011,Austins Ferry,Tasmania,TAS,-42.7667,147.25,4
7012,Collinsvale,Tasmania,TAS,-42.8417,147.1961,4
7012,Glenlusk,Tasmania,TAS,-42.8167,147.2,4
7015,Geilston Bay,Tasmania,TAS,-42.8296,147.3463,4
7015,Lindisfarne,Tasmania,TAS,-42.8484,147.3619,4
7015,Rose Bay,Tasmania,TAS,-42.832,147.3227,3
7016,Risdon Vale,Tasmania,TAS,-42.8124,147.3565,4
7017,Honeywood,Tasmania,TAS,-42.7261,147.2802,4
7017,Grasstree Hill,Tasmania,TAS,-42.782,147.3613,3
7017,Risdon,Tasmania,TAS,-42.8,147.3333,4
7017,Old Beach,Tasmania,TAS,-42.7776,147.2666,4
7017,Otago,Tasmania,TAS,-42.8088,147.2984,4
7017,Tea Tree,Tasmania,TAS,-42.6833,147.3167,4
7018,Rosny,Tasmania,TAS,-42.8707,147.357,4
7018,Bellerive,Tasmania,TAS,-42.8755,147.3703,4
7018,Montagu Bay,Tasmania,TAS,-42.8626,147.3533,4
7018,Rosny Park,Tasmania,TAS,-42.8661,147.3677,4
7018,Tranmere,Tasmania,TAS,-42.9218,147.417,4
7018,Warrane,Tasmania,TAS,-42.8555,147.383,4
7018,Mornington,Tasmania,TAS,-42.8627,147.3982,4
7018,Howrah,Tasmania,TAS,-42.8873,147.4055,4
7019,Oakdowns,Tasmania,TAS,-42.9018,147.4559,4
7019,Clarendon Vale,Tasmania,TAS,-42.8899,147.4437,4
7019,Rokeby,Tasmania,TAS,-42.8987,147.4492,4
7020,Sandford,Tasmania,TAS,-42.9333,147.5,4
7020,Clifton Beach,Tasmania,TAS,-42.9853,147.5211,4
7021,Lauderdale,Tasmania,TAS,-42.9136,147.4875,4
7022,South Arm,Tasmania,TAS,-43.0247,147.4149,4
7023,Opossum Bay,Tasmania,TAS,-42.9954,147.4038,4
7024,Cremorne,Tasmania,TAS,-42.9578,147.5315,4
7025,Richmond,Tasmania,TAS,-42.7355,147.4383,4
7025,Dulcot,Tasmania,TAS,-42.7883,147.4014,4
7026,Campania,Tasmania,TAS,-42.6648,147.4232,4
7027,Colebrook,Tasmania,TAS,-42.5333,147.3667,4
7030,Steppes,Tasmania,TAS,-42.1104,146.8924,4
7030,Flintstone,Tasmania,TAS,-42.5042,147.1203,3
7030,Bothwell,Tasmania,TAS,-42.3848,147.0083,4
7030,Pontville,Tasmania,TAS,-42.687,147.2686,4
7030,Hermitage,Tasmania,TAS,-42.25,146.8833,4
7030,Tods Corner,Tasmania,TAS,-42.5042,147.1203,3
7030,Apsley,Tasmania,TAS,-42.4365,147.1466,4
7030,Broadmarsh,Tasmania,TAS,-42.65,147.1167,4
7030,Miena,Tasmania,TAS,-41.9817,146.7232,4
7030,Morass Bay,Tasmania,TAS,-42.5042,147.1203,3
7030,Kempton,Tasmania,TAS,-42.5359,147.2052,4
7030,Pelham,Tasmania,TAS,-42.5833,147,4
7030,Gagebrook,Tasmania,TAS,-42.7477,147.2698,4
7030,Lower Marshes,Tasmania,TAS,-42.238,147.01,3
7030,Waddamana,Tasmania,TAS,-42.1271,146.7476,4
7030,Elderslie,Tasmania,TAS,-42.6,147.0667,4
7030,Shannon,Tasmania,TAS,-42.238,147.01,3
7030,Bagdad,Tasmania,TAS,-42.6297,147.2234,4
7030,Jericho,Tasmania,TAS,-42.3833,147.2833,4
7030,Interlaken,Tasmania,TAS,-42.15,147.1833,4
7030,Melton Mowbray,Tasmania,TAS,-42.4667,147.1833,4
7030,Bagdad North,Tasmania,TAS,-42.5042,147.1203,3
7030,Herdsmans Cove,Tasmania,TAS,-42.7507,147.2648,4
7030,Dysart,Tasmania,TAS,-42.5667,147.2,4
7030,Granton,Tasmania,TAS,-42.7515,147.228,4
7030,Arthurs Lake,Tasmania,TAS,-42.5042,147.1203,3
7030,Brighton,Tasmania,TAS,-42.6981,147.2449,4
7030,Mangalore,Tasmania,TAS,-42.65,147.2333,4
7030,Millers Bluff,Tasmania,TAS,-42.5042,147.1203,3
7030,Bridgewater,Tasmania,TAS,-42.7372,147.2278,4
7030,Lake Sorell,Tasmania,TAS,-42.5042,147.1203,3
7030,Dromedary,Tasmania,TAS,-42.7333,147.1667,4
7030,Cramps Bay,Tasmania,TAS,-42.5042,147.1203,3
7030,Wilburville,Tasmania,TAS,-42.5042,147.1203,3
7030,Liawenee,Tasmania,TAS,-42.5042,147.1203,3
7050,Kingston Beach,Tasmania,TAS,-42.9802,147.3194,4
7050,Kingston,Tasmania,TAS,-42.9764,147.3035,4
7051,Kingston,Tasmania,TAS,-42.9764,147.3035,4
7052,Blackmans Bay,Tasmania,TAS,-43.0031,147.3169,4
7053,Bonnet Hill,Tasmania,TAS,-42.968,147.3319,4
7053,Taroona,Tasmania,TAS,-42.9452,147.3484,4
7054,Coningham,Tasmania,TAS,-43.0767,147.2756,4
7054,Wellington Park,Tasmania,TAS,-43.0081,147.2655,3
7054,Margate,Tasmania,TAS,-43.0281,147.2626,4
7054,Tinderbox,Tasmania,TAS,-43.0362,147.3203,4
7054,Neika,Tasmania,TAS,-42.95,147.2333,4
7054,Lower Snug,Tasmania,TAS,-43.0081,147.2655,3
7054,Electrona,Tasmania,TAS,-43.0577,147.2607,4
7054,Barretta,Tasmania,TAS,-43.0339,147.2463,3
7054,Snug,Tasmania,TAS,-43.0667,147.2547,4
7054,Fern Tree,Tasmania,TAS,-42.921,147.2633,4
7054,Ridgeway,Tasmania,TAS,-42.928,147.289,4
7054,Leslie Vale,Tasmania,TAS,-42.9743,147.2295,4
7054,Howden,Tasmania,TAS,-43.0254,147.2937,4
7055,Huntingfield,Tasmania,TAS,-43.0016,147.2957,4
7109,Wattle Grove,Tasmania,TAS,-43.1673,146.9986,4
7109,Huonville,Tasmania,TAS,-43.0312,147.0481,4
7109,Raminea,Tasmania,TAS,-43.2109,146.8676,3
7109,Glen Huon,Tasmania,TAS,-43.0333,146.9667,4
7109,Glendevie,Tasmania,TAS,-43.2392,146.9953,4
7109,Hastings,Tasmania,TAS,-43.4089,146.9195,4
7109,Southport,Tasmania,TAS,-43.2109,146.8676,3
7109,Cradoc,Tasmania,TAS,-43.0991,147.0285,4
7109,Grove,Tasmania,TAS,-42.9833,147.1,4
7109,Lymington,Tasmania,TAS,-43.1984,147.0727,4
7109,Petcheys Bay,Tasmania,TAS,-43.2109,146.8676,3
7109,Woodstock,Tasmania,TAS,-43.0736,147.0376,4
7109,Glaziers Bay,Tasmania,TAS,-43.1333,147.05,4
7109,Judbury,Tasmania,TAS,-42.9924,146.9326,4
7109,Lune River,Tasmania,TAS,-43.4295,146.9009,4
7109,Ranelagh,Tasmania,TAS,-43.0098,147.0376,4
7109,Crabtree,Tasmania,TAS,-42.9329,147.0489,4
7109,Lower Longley,Tasmania,TAS,-42.9791,147.1444,4
7109,Waterloo,Tasmania,TAS,-43.2109,146.8676,3
7109,Lucaston,Tasmania,TAS,-42.9833,147.05,4
7109,Strathblane,Tasmania,TAS,-43.3341,146.9705,4
7109,Lonnavale,Tasmania,TAS,-42.9754,146.8397,3
7109,Ida Bay,Tasmania,TAS,-43.1269,147.0275,3
7109,Mountain River,Tasmania,TAS,-42.945,147.1257,3
7109,Recherche,Tasmania,TAS,-43.1269,147.0275,3
7109,Southport Lagoon,Tasmania,TAS,-43.1269,147.0275,3
7109,Lower Wattle Grove,Tasmania,TAS,-43.1269,147.0275,3
7109,Catamaran,Tasmania,TAS,-43.5583,146.8911,4
7112,Nicholls Rivulet,Tasmania,TAS,-43.1585,147.1447,4
7112,Randalls Bay,Tasmania,TAS,-43.2434,147.128,4
7112,Deep Bay,Tasmania,TAS,-43.2082,147.134,4
7112,Verona Sands,Tasmania,TAS,-43.1696,147.1296,3
7112,Garden Island Creek,Tasmania,TAS,-43.25,147.1667,4
7112,Eggs And Bacon Bay,Tasmania,TAS,-43.2455,147.1047,3
7112,Cygnet,Tasmania,TAS,-43.1533,147.0725,4
7112,Charlotte Cove,Tasmania,TAS,-43.189,147.148,3
7112,Abels Bay,Tasmania,TAS,-43.1696,147.1296,3
7112,Gardners Bay,Tasmania,TAS,-43.1757,147.1323,4
7113,Franklin,Tasmania,TAS,-43.0888,147.0091,4
7116,Castle Forbes Bay,Tasmania,TAS,-43.1323,146.9763,4
7116,Brooks Bay,Tasmania,TAS,-43.1708,146.9784,3
7116,Surges Bay,Tasmania,TAS,-43.2188,146.9926,4
7116,Cairns Bay,Tasmania,TAS,-43.2775,146.4256,3
7116,Police Point,Tasmania,TAS,-43.2406,147.0419,4
7116,Surveyors Bay,Tasmania,TAS,-43.1708,146.9784,3
7116,Port Huon,Tasmania,TAS,-43.1564,146.9638,4
7116,Geeveston,Tasmania,TAS,-43.1634,146.9255,4
7117,Dover,Tasmania,TAS,-43.3142,147.0149,4
7119,Stonor,Tasmania,TAS,-42.4,147.3833,4
7120,Swanston,Tasmania,TAS,-42.3482,147.4726,3
7120,Baden,Tasmania,TAS,-42.4333,147.4667,4
7120,Levendale,Tasmania,TAS,-42.5333,147.5667,4
7120,Lemont,Tasmania,TAS,-42.2974,147.6081,4
7120,Tiberias,Tasmania,TAS,-42.3482,147.4726,3
7120,Andover,Tasmania,TAS,-42.3333,147.45,4
7120,Mount Seymour,Tasmania,TAS,-42.4,147.45,4
7120,Parattah,Tasmania,TAS,-42.35,147.4,4
7120,Oatlands,Tasmania,TAS,-42.303,147.3672,4
7120,Antill Ponds,Tasmania,TAS,-42.2089,147.4018,4
7120,Whitefoord,Tasmania,TAS,-42.4243,147.5097,4
7120,Woodbury,Tasmania,TAS,-42.1794,147.3889,4
7120,York Plains,Tasmania,TAS,-42.2792,147.4456,4
7120,Woodsdale,Tasmania,TAS,-42.4849,147.5631,4
7120,Tunbridge,Tasmania,TAS,-42.1327,147.4292,4
7120,Pawtella,Tasmania,TAS,-42.2905,147.504,4
7120,Rhyndaston,Tasmania,TAS,-42.4789,147.3837,4
7120,Tunnack,Tasmania,TAS,-42.4519,147.4618,4
7120,Stonehenge,Tasmania,TAS,-42.4,147.6333,4
7139,Strathgordon,Tasmania,TAS,-42.7665,146.0521,4
7140,Macquarie Plains,Tasmania,TAS,-42.7119,146.9131,4
7140,Plenty,Tasmania,TAS,-42.7419,146.9554,4
7140,Victoria Valley,Tasmania,TAS,-42.3167,146.7333,4
7140,Sorell Creek,Tasmania,TAS,-42.8133,147.8342,4
7140,Fitzgerald,Tasmania,TAS,-42.7496,146.6424,4
7140,Magra,Tasmania,TAS,-42.75,147.0667,4
7140,Fentonbury,Tasmania,TAS,-42.65,146.7667,4
7140,Karanja,Tasmania,TAS,-42.5434,146.4477,3
7140,Hollow Tree,Tasmania,TAS,-42.5333,146.9333,4
7140,Maydena,Tasmania,TAS,-42.7548,146.6256,4
7140,Bronte Park,Tasmania,TAS,-42.136,146.4932,4
7140,Lachlan,Tasmania,TAS,-42.8333,147.05,4
7140,Malbina,Tasmania,TAS,-42.8,147.1333,4
7140,Ouse,Tasmania,TAS,-42.4862,146.7126,4
7140,Gretna,Tasmania,TAS,-42.6809,146.9365,4
7140,Glenora,Tasmania,TAS,-42.6833,146.8667,4
7140,Bushy Park,Tasmania,TAS,-42.7,146.9,4
7140,Hayes,Tasmania,TAS,-42.76,147.0124,4
7140,Black Hills,Tasmania,TAS,-42.7167,147.0333,4
7140,Hamilton,Tasmania,TAS,-42.5536,146.8453,4
7140,Mount Field,Tasmania,TAS,-42.5434,146.4477,3
7140,Mount Lloyd,Tasmania,TAS,-42.8167,146.95,4
7140,Lawitta,Tasmania,TAS,-42.7682,147.0359,4
7140,Osterley,Tasmania,TAS,-42.35,146.75,4
7140,Bradys Lake,Tasmania,TAS,-42.5434,146.4477,3
7140,Moogara,Tasmania,TAS,-42.7833,146.9167,4
7140,Wayatinah,Tasmania,TAS,-42.3769,146.5085,4
7140,Butlers Gorge,Tasmania,TAS,-42.279,146.2777,4
7140,Lake St Clair,Tasmania,TAS,-42.5434,146.4477,3
7140,Westerway,Tasmania,TAS,-42.6833,146.7833,4
7140,Ellendale,Tasmania,TAS,-42.6167,146.7167,4
7140,National Park,Tasmania,TAS,-42.6805,146.7277,4
7140,Molesworth,Tasmania,TAS,-42.5434,146.4477,3
7140,Tarraleah,Tasmania,TAS,-42.3015,146.4492,4
7140,Strickland,Tasmania,TAS,-42.3667,146.6667,4
7140,New Norfolk,Tasmania,TAS,-42.7826,147.0587,4
7140,Styx,Tasmania,TAS,-42.618,146.8279,3
7140,Uxbridge,Tasmania,TAS,-42.773,146.8686,4
7140,Derwent Bridge,Tasmania,TAS,-42.1394,146.2314,4
7140,Little Pine Lagoon,Tasmania,TAS,-42.618,146.8279,3
7140,Tyenna,Tasmania,TAS,-42.7287,146.6665,4
7140,Boyer,Tasmania,TAS,-42.7823,147.1062,4
7140,Dee,Tasmania,TAS,-42.2833,146.6167,4
7140,Glenfern,Tasmania,TAS,-42.8167,147,4
7140,Rosegarland,Tasmania,TAS,-42.7,146.9489,4
7140,Meadowbank,Tasmania,TAS,-42.618,146.8279,3
7140,Florentine,Tasmania,TAS,-42.618,146.8279,3
7140,London Lakes,Tasmania,TAS,-42.618,146.8279,3
7150,Allens Rivulet,Tasmania,TAS,-43.0151,147.2014,4
7150,Upper Woodstock,Tasmania,TAS,-43.2345,147.2537,3
7150,South Bruny,Tasmania,TAS,-43.4386,147.2462,4
7150,Barnes Bay,Tasmania,TAS,-43.1216,147.368,4
7150,Pelverata,Tasmania,TAS,-43.0462,147.1127,4
7150,Killora,Tasmania,TAS,-43.2345,147.2537,3
7150,Oyster Cove,Tasmania,TAS,-43.1052,147.2436,4
7150,Lunawanna,Tasmania,TAS,-43.3666,147.2432,4
7150,Simpsons Bay,Tasmania,TAS,-43.278,147.2997,4
7150,North Bruny,Tasmania,TAS,-43.1287,147.2464,3
7150,Gordon,Tasmania,TAS,-43.262,147.2395,4
7150,Apollo Bay,Tasmania,TAS,-43.1287,147.2464,3
7150,Dennes Point,Tasmania,TAS,-43.0628,147.3528,4
7150,Great Bay,Tasmania,TAS,-43.2345,147.2537,3
7150,Adventure Bay,Tasmania,TAS,-43.3602,147.3214,4
7150,Alonnah,Tasmania,TAS,-43.315,147.2443,4
7150,Longley,Tasmania,TAS,-42.9708,147.1947,4
7150,Sandfly,Tasmania,TAS,-42.9833,147.1962,4
7150,Kaoota,Tasmania,TAS,-43.0167,147.1833,4
7151,Macquarie Island,Tasmania,TAS,-42.7167,146.9167,3
7151,Macquarie,Tasmania,TAS,-42.7119,146.9131,4
7151,Casey,Tasmania,TAS,-42.7167,146.9167,3
7151,Mawson,Tasmania,TAS,-42.7167,146.9167,3
7151,Davis,Tasmania,TAS,-42.7167,146.9167,3
7151,Heard Island,Tasmania,TAS,-42.7167,146.9167,3
7151,Mcdonald Islands,Tasmania,TAS,-42.7167,146.9167,3
7155,Kettering,Tasmania,TAS,-43.1262,147.2464,4
7162,Birchs Bay,Tasmania,TAS,-43.1755,147.242,4
7162,Woodbridge,Tasmania,TAS,-43.1557,147.2389,4
7163,Flowerpot,Tasmania,TAS,-43.2336,147.2198,3
7163,Middleton,Tasmania,TAS,-43.2316,147.2529,4
7170,Seven Mile Beach,Tasmania,TAS,-42.8586,147.5053,4
7170,Roches Beach,Tasmania,TAS,-42.8902,147.4953,4
7170,Cambridge,Tasmania,TAS,-42.8333,147.45,4
7170,Mount Rumney,Tasmania,TAS,-42.8606,147.4476,4
7170,Acton Park,Tasmania,TAS,-42.8793,147.4846,4
7171,Penna,Tasmania,TAS,-42.7776,147.5228,4
7171,Midway Point,Tasmania,TAS,-42.7816,147.5627,4
7172,Orielton,Tasmania,TAS,-42.7333,147.5333,4
7172,Sorell,Tasmania,TAS,-42.7816,147.5627,4
7172,Nugent,Tasmania,TAS,-42.7172,147.7522,4
7172,Pawleena,Tasmania,TAS,-42.7333,147.6,4
7172,Wattle Hill,Tasmania,TAS,-42.7667,147.65,4
7173,Forcett,Tasmania,TAS,-42.8,147.6333,4
7173,Carlton River,Tasmania,TAS,-42.8474,147.6451,3
7173,Primrose Sands,Tasmania,TAS,-42.8858,147.6695,4
7173,Lewisham,Tasmania,TAS,-42.8333,147.6167,4
7173,Dodges Ferry,Tasmania,TAS,-42.8531,147.6226,4
7173,Connellys Marsh,Tasmania,TAS,-42.8222,147.6703,3
7173,Carlton,Tasmania,TAS,-42.874,147.6534,4
7174,Copping,Tasmania,TAS,-42.8167,147.8,4
7175,Bream Creek,Tasmania,TAS,-42.8133,147.8342,4
7175,Marion Bay,Tasmania,TAS,-42.8133,147.8342,3
7176,Kellevie,Tasmania,TAS,-42.7785,147.8153,4
7177,Boomer Bay,Tasmania,TAS,-42.8865,147.8108,3
7177,Dunalley,Tasmania,TAS,-42.8865,147.8108,4
7178,Murdunna,Tasmania,TAS,-42.9473,147.8705,4
7179,Eaglehawk Neck,Tasmania,TAS,-43.0179,147.9222,4
7180,Taranna,Tasmania,TAS,-43.0629,147.863,4
7182,Port Arthur,Tasmania,TAS,-43.1458,147.8437,4
7182,Cape Pillar,Tasmania,TAS,-43.15,147.85,3
7182,Fortescue,Tasmania,TAS,-43.15,147.85,3
7183,Highcroft,Tasmania,TAS,-43.1333,147.8,4
7184,White Beach,Tasmania,TAS,-43.1249,147.7212,4
7184,Nubeena,Tasmania,TAS,-43.0971,147.7433,4
7184,Cape Raoul,Tasmania,TAS,-43.111,147.7322,3
7184,Stormlea,Tasmania,TAS,-43.1703,147.7718,4
7185,Premaydena,Tasmania,TAS,-43.0546,147.7708,4
7186,Saltwater River,Tasmania,TAS,-43.0234,147.7183,4
7186,Sloping Main,Tasmania,TAS,-43.0167,147.75,3
7187,Koonya,Tasmania,TAS,-43.05,147.85,4
7190,Swansea,Tasmania,TAS,-42.1228,148.0743,4
7190,Little Swanport,Tasmania,TAS,-42.3375,147.9334,4
7190,Dolphin Sands,Tasmania,TAS,-42.4221,147.8876,3
7190,Runnymede,Tasmania,TAS,-42.6419,147.5605,4
7190,Rheban,Tasmania,TAS,-42.63,147.928,4
7190,Orford,Tasmania,TAS,-42.5602,147.8718,4
7190,Cranbrook,Tasmania,TAS,-42.0062,148.0689,4
7190,Triabunna,Tasmania,TAS,-42.508,147.9113,4
7190,Spring Beach,Tasmania,TAS,-42.5841,147.9041,4
7190,Buckland,Tasmania,TAS,-42.6,147.7167,4
7190,Pontypool,Tasmania,TAS,-42.4221,147.8876,3
7190,Apslawn,Tasmania,TAS,-41.9738,148.1865,4
7190,Maria Island,Tasmania,TAS,-42.3064,147.9158,3
7190,Rocky Hills,Tasmania,TAS,-42.4221,147.8876,3
7209,Ross,Tasmania,TAS,-42.0308,147.4927,4
7209,Tooms Lake,Tasmania,TAS,-42.0308,147.4927,3
7210,Campbell Town,Tasmania,TAS,-41.9256,147.4939,4
7210,Lake Leake,Tasmania,TAS,-42.0136,147.7954,4
7211,Cleveland,Tasmania,TAS,-41.8,147.4,4
7211,Conara,Tasmania,TAS,-41.8333,147.4333,4
7211,Epping Forest,Tasmania,TAS,-41.7599,147.3498,4
7212,Evandale,Tasmania,TAS,-41.5686,147.2527,4
7212,Burns Creek,Tasmania,TAS,-41.4147,147.5265,4
7212,Blessington,Tasmania,TAS,-41.519,147.4761,4
7212,Upper Blessington,Tasmania,TAS,-41.4591,147.5922,4
7212,Nile,Tasmania,TAS,-41.6454,147.3301,4
7212,Deddington,Tasmania,TAS,-41.6024,147.4272,4
7212,Western Junction,Tasmania,TAS,-41.5543,147.2282,4
7212,Ben Lomond,Tasmania,TAS,-41.5535,147.3829,3
7213,Royal George,Tasmania,TAS,-41.8307,147.8968,4
7213,Rossarden,Tasmania,TAS,-41.667,147.745,4
7213,Avoca,Tasmania,TAS,-41.7864,147.7209,4
7214,Mangana,Tasmania,TAS,-41.6081,147.8854,4
7214,Fingal,Tasmania,TAS,-41.6396,147.9695,4
7214,Upper Esk,Tasmania,TAS,-41.4308,147.7256,4
7214,Mathinna,Tasmania,TAS,-41.4751,147.89,4
7215,Seymour,Tasmania,TAS,-41.7438,148.2762,4
7215,Scamander,Tasmania,TAS,-41.456,148.261,4
7215,Douglas River,Tasmania,TAS,-41.7856,148.2503,4
7215,Douglas-Apsley,Tasmania,TAS,-41.6675,148.2438,3
7215,Falmouth,Tasmania,TAS,-41.5058,148.2709,4
7215,St Marys,Tasmania,TAS,-41.5834,148.1875,4
7215,Coles Bay,Tasmania,TAS,-42.1264,148.2887,4
7215,Friendly Beaches,Tasmania,TAS,-41.6675,148.2438,3
7215,Bicheno,Tasmania,TAS,-41.8743,148.3024,4
7215,Cornwall,Tasmania,TAS,-41.559,148.1453,4
7215,Four Mile Creek,Tasmania,TAS,-41.723,148.1894,3
7215,Beaumaris,Tasmania,TAS,-41.4159,148.2614,4
7215,Chain Of Lagoons,Tasmania,TAS,-41.723,148.1894,3
7215,Gray,Tasmania,TAS,-41.6287,148.2284,4
7215,Upper Scamander,Tasmania,TAS,-41.6675,148.2438,3
7215,Freycinet,Tasmania,TAS,-41.6675,148.2438,3
7216,The Gardens,Tasmania,TAS,-41.2651,148.1443,3
7216,Pyengana,Tasmania,TAS,-41.2918,148.0127,4
7216,Goshen,Tasmania,TAS,-41.2721,148.1054,4
7216,Ansons Bay,Tasmania,TAS,-41.2687,148.0987,3
7216,St Helens,Tasmania,TAS,-41.3203,148.2389,4
7216,Lottah,Tasmania,TAS,-41.2258,148.0324,4
7216,Stieglitz,Tasmania,TAS,-41.3217,148.3058,4
7216,Goulds Country,Tasmania,TAS,-41.2422,148.056,4
7216,Akaroa,Tasmania,TAS,-41.3045,148.3167,3
7216,Binalong Bay,Tasmania,TAS,-41.2511,148.3117,4
7248,Newnham,Tasmania,TAS,-41.3971,147.1313,4
7248,Rocherlea,Tasmania,TAS,-41.3808,147.1574,4
7248,Mowbray,Tasmania,TAS,-41.4024,147.1506,4
7248,Mayfield,Tasmania,TAS,-41.3876,147.1305,4
7248,Invermay,Tasmania,TAS,-41.4204,147.1336,4
7249,Youngtown,Tasmania,TAS,-41.4833,147.1707,4
7249,Kings Meadows,Tasmania,TAS,-41.4704,147.162,4
7249,Glen Dhu,Tasmania,TAS,-41.476,147.1577,3
7249,Punchbowl,Tasmania,TAS,-41.4576,147.1647,4
7249,South Launceston,Tasmania,TAS,-41.4551,147.1456,4
7250,Norwood,Tasmania,TAS,-41.4592,147.1808,4
7250,Prospect,Tasmania,TAS,-41.481,147.1041,4
7250,Prospect Vale,Tasmania,TAS,-41.481,147.1041,4
7250,Waverley,Tasmania,TAS,-41.436,147.1859,4
7250,Trevallyn,Tasmania,TAS,-41.4425,147.1048,4
7250,East Launceston,Tasmania,TAS,-41.4384,147.151,4
7250,Newstead,Tasmania,TAS,-41.4333,147.1667,4
7250,Travellers Rest,Tasmania,TAS,-41.4971,147.0929,4
7250,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7250,Blackstone Heights,Tasmania,TAS,-41.4598,147.0841,4
7250,Summerhill,Tasmania,TAS,-41.4675,147.128,4
7250,West Launceston,Tasmania,TAS,-41.4509,147.1311,4
7250,Riverside,Tasmania,TAS,-41.4211,147.0509,4
7250,Ravenswood,Tasmania,TAS,-41.4159,147.1825,4
7250,St Leonards,Tasmania,TAS,-41.4625,147.2146,4
7252,Swan Bay,Tasmania,TAS,-41.1315,147.01,3
7252,Dilston,Tasmania,TAS,-41.3296,147.0652,4
7252,Pipers River,Tasmania,TAS,-41.0973,147.0809,4
7252,Weymouth,Tasmania,TAS,-41.0145,147.1517,4
7252,Mount Direction,Tasmania,TAS,-41.2464,147.0132,4
7252,Lefroy,Tasmania,TAS,-41.1,146.9833,4
7252,Hillwood,Tasmania,TAS,-41.2442,146.9842,4
7252,Beechford,Tasmania,TAS,-41.1315,147.01,3
7252,Windermere,Tasmania,TAS,-41.3134,147.0058,4
7252,Stony Head,Tasmania,TAS,-41.1659,147.0475,3
7252,Lulworth,Tasmania,TAS,-41,147.07,4
7253,George Town,Tasmania,TAS,-41.1056,146.8261,4
7253,Long Reach,Tasmania,TAS,-41.0971,146.8325,3
7253,Low Head,Tasmania,TAS,-41.08,146.8075,4
7253,Bell Bay,Tasmania,TAS,-41.13,146.87,4
7254,Golconda,Tasmania,TAS,-41.167,147.3094,4
7254,Lebrina,Tasmania,TAS,-41.179,147.2283,4
7254,Tunnel,Tasmania,TAS,-41.1952,147.193,4
7254,Pipers Brook,Tasmania,TAS,-41.0831,147.1743,4
7254,Bellingham,Tasmania,TAS,-41.0164,147.1688,4
7254,Retreat,Tasmania,TAS,-41.1505,147.173,4
7254,Wyena,Tasmania,TAS,-41.176,147.2668,4
7255,Ranga,Tasmania,TAS,-40.1522,148.0673,4
7255,Emita,Tasmania,TAS,-40.0008,147.9165,4
7255,Blue Rocks,Tasmania,TAS,-40.0571,147.9555,4
7255,Loccota,Tasmania,TAS,-40.2181,148.0423,4
7255,Leeka,Tasmania,TAS,-39.9005,147.7989,4
7255,Lackrana,Tasmania,TAS,-40.1194,148.0978,3
7255,Lughrata,Tasmania,TAS,-39.9741,147.9297,4
7255,Strzelecki,Tasmania,TAS,-40.0066,147.9897,3
7255,Lady Barron,Tasmania,TAS,-40.21,148.24,4
7255,Wingaroo,Tasmania,TAS,-40.0066,147.9897,3
7255,Palana,Tasmania,TAS,-39.7667,147.8833,4
7255,Whitemark,Tasmania,TAS,-40.1222,148.0164,4
7255,Killiecrankie,Tasmania,TAS,-39.8391,147.834,4
7255,Memana,Tasmania,TAS,-39.9789,148.0914,4
7256,Naracoopa,Tasmania,TAS,-39.9208,144.1194,4
7256,Yarra Creek,Tasmania,TAS,-39.8478,143.9795,3
7256,Yambacoona,Tasmania,TAS,-39.7042,143.9306,4
7256,Currie,Tasmania,TAS,-39.9298,143.8538,4
7256,Grassy,Tasmania,TAS,-40.0492,144.0561,4
7256,Pearshape,Tasmania,TAS,-39.8478,143.9795,3
7256,Pegarah,Tasmania,TAS,-39.9417,143.9958,4
7256,Loorana,Tasmania,TAS,-39.8389,143.8958,4
7256,Sea Elephant,Tasmania,TAS,-39.8286,144.1111,4
7256,Surprise Bay,Tasmania,TAS,-39.8478,143.9795,3
7256,Reekara,Tasmania,TAS,-39.7553,143.9175,4
7256,Nugara,Tasmania,TAS,-39.8478,143.9795,3
7256,Bungaree,Tasmania,TAS,-39.8478,143.9795,3
7256,Lymwood,Tasmania,TAS,-39.8478,143.9795,3
7256,Wickham,Tasmania,TAS,-39.8478,143.9795,3
7256,Egg Lagoon,Tasmania,TAS,-39.6611,143.9764,4
7257,Cape Barren Island,Tasmania,TAS,-40.3734,148.027,4
7258,Relbia,Tasmania,TAS,-41.5058,147.2021,3
7258,White Hills,Tasmania,TAS,-41.5055,147.2543,4
7258,Breadalbane,Tasmania,TAS,-41.5268,147.1965,4
7259,Patersonia,Tasmania,TAS,-41.3403,147.303,4
7259,Myrtle Bank,Tasmania,TAS,-41.2941,147.3584,4
7259,Targa,Tasmania,TAS,-41.3138,147.367,4
7259,Nunamara,Tasmania,TAS,-41.3896,147.3067,4
7259,Tayene,Tasmania,TAS,-41.3457,147.4494,4
7260,North Scottsdale,Tasmania,TAS,-41.1215,147.5595,4
7260,Blumont,Tasmania,TAS,-41.1681,147.4846,3
7260,Nabowla,Tasmania,TAS,-41.1712,147.3662,4
7260,Jetsonville,Tasmania,TAS,-41.1167,147.4667,4
7260,Scottsdale,Tasmania,TAS,-41.1578,147.5173,4
7260,Cuckoo,Tasmania,TAS,-41.1674,147.5017,3
7260,South Springfield,Tasmania,TAS,-41.2515,147.5081,4
7260,Tonganah,Tasmania,TAS,-41.1862,147.5881,4
7260,Springfield,Tasmania,TAS,-41.2,147.4833,4
7260,Tulendeena,Tasmania,TAS,-41.1681,147.4846,3
7260,Kamona,Tasmania,TAS,-41.1542,147.6768,4
7260,Lietinna,Tasmania,TAS,-41.15,147.4667,4
7260,Forester,Tasmania,TAS,-41.0848,147.6633,4
7260,West Scottsdale,Tasmania,TAS,-41.1674,147.5017,3
7260,Lisle,Tasmania,TAS,-41.2307,147.3345,4
7261,Branxholm,Tasmania,TAS,-41.1662,147.7431,4
7261,Warrentinna,Tasmania,TAS,-41.1004,147.7559,4
7262,Waterhouse,Tasmania,TAS,-40.9607,147.6967,3
7262,Tomahawk,Tasmania,TAS,-40.867,147.756,4
7262,Bridport,Tasmania,TAS,-41.0005,147.3918,4
7263,Trenah,Tasmania,TAS,-41.3106,147.69,4
7263,Talawa,Tasmania,TAS,-41.2521,147.7302,3
7263,Legerwood,Tasmania,TAS,-41.213,147.6994,4
7263,Ringarooma,Tasmania,TAS,-41.2418,147.7376,4
7263,Alberton,Tasmania,TAS,-41.2679,147.8003,4
7264,Gladstone,Tasmania,TAS,-40.9593,148.0094,4
7264,South Mount Cameron,Tasmania,TAS,-41.0246,147.9639,4
7264,Boobyalla,Tasmania,TAS,-41.0696,147.9241,3
7264,Mount William,Tasmania,TAS,-40.9221,148.2129,4
7264,Cape Portland,Tasmania,TAS,-41.0754,148.0082,3
7264,Telita,Tasmania,TAS,-41.1657,147.7408,4
7264,Eddystone,Tasmania,TAS,-41.0696,147.9241,3
7264,Moorina,Tasmania,TAS,-41.1275,147.8695,4
7264,Pioneer,Tasmania,TAS,-41.0754,148.0082,3
7264,Ansons Bay,Tasmania,TAS,-41.0696,147.9241,3
7264,Derby,Tasmania,TAS,-41.1489,147.8025,4
7264,Musselroe Bay,Tasmania,TAS,-40.8803,148.1374,4
7264,Weldborough,Tasmania,TAS,-41.1938,147.9067,4
7264,Rushy Lagoon,Tasmania,TAS,-41.0696,147.9241,3
7264,Herrick,Tasmania,TAS,-41.1002,147.8826,4
7265,Winnaleah,Tasmania,TAS,-41.0975,147.8238,4
7265,Banca,Tasmania,TAS,-41.0833,147.8167,3
7267,Karoola,Tasmania,TAS,-41.2443,147.1534,4
7267,Bangor,Tasmania,TAS,-41.2184,147.1411,4
7267,Turners Marsh,Tasmania,TAS,-41.2666,147.1311,4
7267,Lalla,Tasmania,TAS,-41.2565,147.1783,4
7267,Lower Turners Marsh,Tasmania,TAS,-41.2085,147.1126,4
7268,Underwood,Tasmania,TAS,-41.288,147.2136,4
7268,Lilydale,Tasmania,TAS,-41.2503,147.2176,4
7268,North Lilydale,Tasmania,TAS,-41.2257,147.2404,4
7270,Rowella,Tasmania,TAS,-41.2,146.9,4
7270,York Town,Tasmania,TAS,-41.1725,146.8325,3
7270,Sidmouth,Tasmania,TAS,-41.2085,146.8949,4
7270,Kelso,Tasmania,TAS,-41.1049,146.7929,4
7270,Greens Beach,Tasmania,TAS,-41.0848,146.7504,4
7270,Badger Head,Tasmania,TAS,-41.1725,146.8325,3
7270,Kayena,Tasmania,TAS,-41.1889,146.895,4
7270,Flowery Gully,Tasmania,TAS,-41.2582,146.8159,4
7270,Beauty Point,Tasmania,TAS,-41.1622,146.8215,4
7270,Beaconsfield,Tasmania,TAS,-41.2023,146.8172,4
7270,Clarence Point,Tasmania,TAS,-41.1293,146.7892,4
7275,Blackwall,Tasmania,TAS,-41.3021,146.969,4
7275,Swan Point,Tasmania,TAS,-41.2613,146.9644,4
7275,Notley Hills,Tasmania,TAS,-41.3485,146.9286,4
7275,Lanena,Tasmania,TAS,-41.3021,146.969,4
7275,Glengarry,Tasmania,TAS,-41.3488,146.8655,4
7275,Robigana,Tasmania,TAS,-41.2618,146.9465,4
7275,Loira,Tasmania,TAS,-41.276,146.923,4
7275,Winkleigh,Tasmania,TAS,-41.2933,146.8358,4
7275,Holwell,Tasmania,TAS,-41.2831,146.7813,4
7275,Deviot,Tasmania,TAS,-41.2353,146.9298,4
7275,Exeter,Tasmania,TAS,-41.2975,146.9505,4
7275,Frankford,Tasmania,TAS,-41.3333,146.7758,4
7276,Gravelly Beach,Tasmania,TAS,-41.2863,146.9752,4
7277,Grindelwald,Tasmania,TAS,-41.3515,147.0052,4
7277,Legana,Tasmania,TAS,-41.3657,147.0458,4
7277,Rosevears,Tasmania,TAS,-41.3243,147.0043,4
7277,Bridgenorth,Tasmania,TAS,-41.3839,146.9757,4
7290,Hadspen,Tasmania,TAS,-41.5006,147.0736,4
7291,Carrick,Tasmania,TAS,-41.5324,147.0103,4
7292,Quamby Bend,Tasmania,TAS,-41.4667,146.9111,3
7292,Selbourne,Tasmania,TAS,-41.4496,146.8896,4
7292,Westwood,Tasmania,TAS,-41.4859,146.9817,4
7292,Hagley,Tasmania,TAS,-41.526,146.9026,4
7292,Rosevale,Tasmania,TAS,-41.4167,146.9333,4
7300,Perth,Tasmania,TAS,-41.5723,147.171,4
7300,Devon Hills,Tasmania,TAS,-41.5591,147.1951,3
7300,Powranna,Tasmania,TAS,-41.687,147.2892,4
7301,Blackwood Creek,Tasmania,TAS,-41.7366,146.8979,4
7301,Longford,Tasmania,TAS,-41.6051,147.1189,4
7301,Bishopsbourne,Tasmania,TAS,-41.617,146.9979,4
7301,Liffey,Tasmania,TAS,-41.6842,146.8542,4
7301,Toiberry,Tasmania,TAS,-41.6096,147.0412,4
7302,Cressy,Tasmania,TAS,-41.6856,147.0779,4
7302,Poatina,Tasmania,TAS,-41.7957,146.9608,4
7302,Bracknell,Tasmania,TAS,-41.65,146.9384,4
7303,Osmaston,Tasmania,TAS,-41.5318,146.8687,3
7303,Cluan,Tasmania,TAS,-41.605,146.8726,4
7303,Birralee,Tasmania,TAS,-41.4096,146.8172,4
7303,Westbury,Tasmania,TAS,-41.5291,146.8391,4
7303,Exton,Tasmania,TAS,-41.5209,146.7421,4
7303,Oaks,Tasmania,TAS,-41.5898,146.9819,4
7303,Whitemore,Tasmania,TAS,-41.5833,146.9333,4
7304,Weegena,Tasmania,TAS,-41.4881,146.4819,4
7304,Dunorlan,Tasmania,TAS,-41.4834,146.5449,4
7304,Western Creek,Tasmania,TAS,-41.6889,146.4032,3
7304,Quamby Brook,Tasmania,TAS,-41.6014,146.7271,4
7304,Dairy Plains,Tasmania,TAS,-41.6889,146.4032,3
7304,Montana,Tasmania,TAS,-41.5955,146.5871,4
7304,Reedy Marsh,Tasmania,TAS,-41.4477,146.7128,4
7304,Liena,Tasmania,TAS,-41.5515,146.233,4
7304,Mersey Forest,Tasmania,TAS,-41.6889,146.4032,3
7304,Meander,Tasmania,TAS,-41.653,146.6296,4
7304,Moltema,Tasmania,TAS,-41.4598,146.5395,4
7304,Mayberry,Tasmania,TAS,-41.5836,146.3013,4
7304,Brandum,Tasmania,TAS,-41.5465,146.5541,3
7304,Walls Of Jerusalem,Tasmania,TAS,-41.5465,146.5541,3
7304,Elizabeth Town,Tasmania,TAS,-41.468,146.5637,4
7304,Red Hills,Tasmania,TAS,-41.6889,146.4032,3
7304,Jackeys Marsh,Tasmania,TAS,-41.6865,146.6787,4
7304,Central Plateau,Tasmania,TAS,-41.5465,146.5541,3
7304,Reynolds Neck,Tasmania,TAS,-41.5465,146.5541,3
7304,Doctors Point,Tasmania,TAS,-41.5465,146.5541,3
7304,Golden Valley,Tasmania,TAS,-41.6308,146.7212,4
7304,Needles,Tasmania,TAS,-41.5307,146.5944,4
7304,Kimberley,Tasmania,TAS,-41.417,146.4923,4
7304,Weetah,Tasmania,TAS,-41.4735,146.6446,4
7304,Mole Creek,Tasmania,TAS,-41.5782,146.4042,4
7304,Deloraine,Tasmania,TAS,-41.5248,146.657,4
7304,Parkham,Tasmania,TAS,-41.4072,146.6129,4
7304,Chudleigh,Tasmania,TAS,-41.5759,146.4728,4
7304,Caveside,Tasmania,TAS,-41.6164,146.4238,4
7304,Breona,Tasmania,TAS,-41.7822,146.7043,4
7305,Railton,Tasmania,TAS,-41.3457,146.4236,4
7305,Merseylea,Tasmania,TAS,-41.3441,146.4206,3
7305,Sunnyside,Tasmania,TAS,-41.4001,146.4364,4
7306,Staverton,Tasmania,TAS,-41.4594,146.1829,4
7306,Middlesex,Tasmania,TAS,-41.4267,146.1847,3
7306,West Kentish,Tasmania,TAS,-41.4113,146.2561,4
7306,Paradise,Tasmania,TAS,-41.4516,146.3156,4
7306,Claude Road,Tasmania,TAS,-41.451,146.2719,4
7306,Nowhere Else,Tasmania,TAS,-41.4267,146.1847,3
7306,Sheffield,Tasmania,TAS,-41.3995,146.339,4
7306,Stoodley,Tasmania,TAS,-41.4139,146.4033,4
7306,Lower Beulah,Tasmania,TAS,-41.4808,146.3917,4
7306,Acacia Hills,Tasmania,TAS,-41.4185,146.3153,3
7306,Promised Land,Tasmania,TAS,-41.4322,146.2088,4
7306,Lorinna,Tasmania,TAS,-41.5577,146.1308,4
7306,Cradle Mountain,Tasmania,TAS,-41.6416,145.9432,4
7306,Lower Barrington,Tasmania,TAS,-41.3084,146.2872,4
7306,Roland,Tasmania,TAS,-41.4236,146.2274,4
7306,Barrington,Tasmania,TAS,-41.3456,146.2766,4
7306,Cethana,Tasmania,TAS,-41.4918,146.1539,4
7306,Mount Roland,Tasmania,TAS,-41.4267,146.1847,3
7306,Gowrie Park,Tasmania,TAS,-41.4697,146.2128,4
7306,Beulah,Tasmania,TAS,-41.4562,146.3781,4
7306,Nook,Tasmania,TAS,-41.328,146.3396,4
7307,Sassafras,Tasmania,TAS,-41.2983,146.4915,4
7307,Bakers Beach,Tasmania,TAS,-41.2006,146.5087,3
7307,Harford,Tasmania,TAS,-41.236,146.5481,4
7307,Shearwater,Tasmania,TAS,-41.1567,146.5318,4
7307,Latrobe,Tasmania,TAS,-41.2464,146.4246,4
7307,Hawley Beach,Tasmania,TAS,-41.142,146.5377,4
7307,Moriarty,Tasmania,TAS,-41.2351,146.4566,4
7307,Wesley Vale,Tasmania,TAS,-41.2086,146.4555,4
7307,Northdown,Tasmania,TAS,-41.1829,146.4924,4
7307,Thirlstane,Tasmania,TAS,-41.2037,146.5382,4
7307,Port Sorell,Tasmania,TAS,-41.1665,146.5528,4
7307,Squeaking Point,Tasmania,TAS,-41.1887,146.5567,4
7310,Forthside,Tasmania,TAS,-41.2531,146.2766,3
7310,West Devonport,Tasmania,TAS,-41.4311,146.0939,3
7310,Spreyton,Tasmania,TAS,-41.2352,146.3511,4
7310,Stony Rise,Tasmania,TAS,-41.2531,146.2766,3
7310,Devonport,Tasmania,TAS,-41.1769,146.3515,4
7310,Ambleside,Tasmania,TAS,-41.2032,146.3722,4
7310,Erriba,Tasmania,TAS,-41.4643,146.1053,4
7310,Eugenana,Tasmania,TAS,-41.2555,146.3476,4
7310,Lower Wilmot,Tasmania,TAS,-41.3507,146.1921,4
7310,Lillico,Tasmania,TAS,-41.4311,146.0939,3
7310,Quoiba,Tasmania,TAS,-41.2085,146.3356,4
7310,Paloona,Tasmania,TAS,-41.2726,146.276,4
7310,Don,Tasmania,TAS,-41.175,146.3188,4
7310,East Devonport,Tasmania,TAS,-41.2531,146.2766,3
7310,Aberdeen,Tasmania,TAS,-41.2636,146.324,4
7310,Miandetta,Tasmania,TAS,-41.1955,146.3532,4
7310,Kindred,Tasmania,TAS,-41.2525,146.2119,4
7310,Tugrah,Tasmania,TAS,-41.2531,146.2766,3
7310,Melrose,Tasmania,TAS,-41.2546,146.2858,4
7310,Wilmot,Tasmania,TAS,-41.3976,146.1754,4
7310,Moina,Tasmania,TAS,-41.4979,146.066,4
7310,Tarleton,Tasmania,TAS,-41.2531,146.2766,3
7310,Forth,Tasmania,TAS,-41.2032,146.2728,4
7310,South Spreyton,Tasmania,TAS,-41.2531,146.2766,3
7315,Ulverstone,Tasmania,TAS,-41.1603,146.1824,4
7315,West Ulverstone,Tasmania,TAS,-41.2427,146.1546,3
7315,Nietta,Tasmania,TAS,-41.3811,146.0634,4
7315,Loongana,Tasmania,TAS,-41.2427,146.1546,3
7315,South Preston,Tasmania,TAS,-41.3384,146.0474,4
7315,Castra,Tasmania,TAS,-41.3444,146.1276,4
7315,Gunns Plains,Tasmania,TAS,-41.2887,146.0292,4
7315,North Motton,Tasmania,TAS,-41.2193,146.1019,4
7315,Upper Castra,Tasmania,TAS,-41.3444,146.1276,4
7315,South Nietta,Tasmania,TAS,-41.4183,146.0736,4
7315,Abbotsham,Tasmania,TAS,-41.2265,146.1732,4
7315,Turners Beach,Tasmania,TAS,-41.1606,146.2319,4
7315,Leith,Tasmania,TAS,-41.1576,146.2589,4
7315,Spalford,Tasmania,TAS,-41.2564,146.1739,4
7315,Preston,Tasmania,TAS,-41.3384,146.0474,4
7315,Sprent,Tasmania,TAS,-41.2766,146.1591,4
7315,Gawler,Tasmania,TAS,-41.1938,146.1569,4
7316,West Pine,Tasmania,TAS,-41.1532,145.9994,4
7316,Howth,Tasmania,TAS,-41.0968,146.0123,4
7316,Sulphur Creek,Tasmania,TAS,-41.0947,146.0235,4
7316,Loyetea,Tasmania,TAS,-41.3064,145.9485,4
7316,Camena,Tasmania,TAS,-41.1814,145.9571,4
7316,Penguin,Tasmania,TAS,-41.1226,146.0732,4
7316,Heybridge,Tasmania,TAS,-41.0806,145.9859,4
7316,South Riana,Tasmania,TAS,-41.244,145.9823,4
7316,Preservation Bay,Tasmania,TAS,-41.2565,145.9734,3
7316,Cuprona,Tasmania,TAS,-41.1398,145.97,4
7316,Riana,Tasmania,TAS,-41.2159,145.9974,4
7320,Camdale,Tasmania,TAS,-41.0499,145.8516,4
7320,Acton,Tasmania,TAS,-42.8793,147.4846,4
7320,Burnie,Tasmania,TAS,-41.0558,145.9037,4
7320,Downlands,Tasmania,TAS,-41.0827,145.8902,4
7320,South Burnie,Tasmania,TAS,-41.0598,145.9085,4
7320,Shorewell Park,Tasmania,TAS,-41.0699,145.8769,4
7320,Upper Burnie,Tasmania,TAS,-41.2444,145.8987,3
7320,Brooklyn,Tasmania,TAS,-41.0722,145.9073,4
7320,Cooee,Tasmania,TAS,-41.0496,145.8772,4
7320,Park Grove,Tasmania,TAS,-41.0548,145.882,4
7320,Wivenhoe,Tasmania,TAS,-41.0745,145.9472,4
7320,Havenview,Tasmania,TAS,-41.0839,145.9118,4
7320,Hillcrest,Tasmania,TAS,-41.0632,145.8944,4
7320,Round Hill,Tasmania,TAS,-41.0827,145.9006,3
7320,Emu Heights,Tasmania,TAS,-41.076,145.9204,4
7320,Ocean Vista,Tasmania,TAS,-41.0827,145.9006,3
7320,Romaine,Tasmania,TAS,-41.0901,145.8941,3
7320,Parklands,Tasmania,TAS,-41.0827,145.9006,3
7320,Montello,Tasmania,TAS,-41.0576,145.8963,4
7321,Stowport,Tasmania,TAS,-41.12,145.938,4
7321,Edgcumbe Beach,Tasmania,TAS,-41.1418,145.6144,3
7321,Rocky Cape,Tasmania,TAS,-40.9252,145.4786,4
7321,East Ridgley,Tasmania,TAS,-41.1418,145.6144,3
7321,Ridgley,Tasmania,TAS,-41.1556,145.8351,4
7321,Crayfish Creek,Tasmania,TAS,-41.1418,145.6144,3
7321,Wiltshire,Tasmania,TAS,-40.8347,145.2771,4
7321,Parrawe,Tasmania,TAS,-41.3044,145.5872,4
7321,Tullah,Tasmania,TAS,-41.7321,145.6225,4
7321,Savage River,Tasmania,TAS,-41.3598,145.4991,3
7321,Guildford,Tasmania,TAS,-41.4329,145.6887,4
7321,Waratah,Tasmania,TAS,-41.4437,145.5329,4
7321,Tewkesbury,Tasmania,TAS,-41.2101,145.7291,4
7321,Upper Stowport,Tasmania,TAS,-41.1624,145.8969,4
7321,Mooreville,Tasmania,TAS,-41.0985,145.8613,4
7321,Port Latta,Tasmania,TAS,-40.8333,145.3333,4
7321,Luina,Tasmania,TAS,-41.1418,145.6144,3
7321,West Mooreville,Tasmania,TAS,-41.1418,145.6144,3
7321,Natone,Tasmania,TAS,-41.1712,145.9217,4
7321,Boat Harbour Beach,Tasmania,TAS,-41.3598,145.4991,3
7321,Corinna,Tasmania,TAS,-41.656,145.0762,4
7321,West Ridgley,Tasmania,TAS,-41.1418,145.6144,3
7321,Upper Natone,Tasmania,TAS,-41.2444,145.8987,4
7321,Hellyer,Tasmania,TAS,-41.1418,145.6144,3
7321,Boat Harbour,Tasmania,TAS,-40.9484,145.6303,4
7321,Chasm Creek,Tasmania,TAS,-41.1418,145.6144,3
7321,Montumana,Tasmania,TAS,-40.9523,145.5131,4
7321,Mawbanna,Tasmania,TAS,-40.9569,145.3758,4
7321,Detention,Tasmania,TAS,-40.9028,145.486,4
7321,Cowrie Point,Tasmania,TAS,-41.1418,145.6144,3
7321,Black River,Tasmania,TAS,-40.8536,145.311,4
7321,Sisters Beach,Tasmania,TAS,-40.9197,145.5597,4
7321,Hampshire,Tasmania,TAS,-41.2709,145.7821,4
7321,East Cam,Tasmania,TAS,-41.0594,145.8514,4
7321,Highclere,Tasmania,TAS,-41.2006,145.8059,4
7322,Somerset,Tasmania,TAS,-41.0406,145.8305,4
7325,Takone,Tasmania,TAS,-41.1737,145.6318,4
7325,Sisters Creek,Tasmania,TAS,-40.9617,145.569,4
7325,Yolla,Tasmania,TAS,-41.1362,145.7088,4
7325,West Takone,Tasmania,TAS,-41.1847,145.5273,4
7325,Lapoinya,Tasmania,TAS,-41.0011,145.5753,4
7325,Henrietta,Tasmania,TAS,-41.1803,145.6649,4
7325,Milabena,Tasmania,TAS,-41.0333,145.5156,4
7325,Preolenna,Tasmania,TAS,-41.0868,145.5515,4
7325,Mount Hicks,Tasmania,TAS,-41.0949,145.6115,3
7325,Wynyard,Tasmania,TAS,-40.9897,145.7262,4
7325,Oldina,Tasmania,TAS,-41.0833,145.6833,4
7325,Oonah,Tasmania,TAS,-41.2373,145.6219,4
7325,Seabrook,Tasmania,TAS,-41.0949,145.6115,3
7325,Elliott,Tasmania,TAS,-41.1001,145.7828,4
7325,Meunna,Tasmania,TAS,-41.1021,145.4883,4
7325,Doctors Rocks,Tasmania,TAS,-41.0949,145.6115,3
7325,Moorleah,Tasmania,TAS,-40.9846,145.6213,4
7325,Table Cape,Tasmania,TAS,-41.0949,145.6115,3
7325,Myalla,Tasmania,TAS,-40.9812,145.576,4
7325,Calder,Tasmania,TAS,-41.0693,145.63,4
7325,Flowerdale,Tasmania,TAS,-40.9643,145.6578,4
7330,Trowutta,Tasmania,TAS,-41.0332,145.0834,4
7330,South Forest,Tasmania,TAS,-40.9351,145.0327,3
7330,Mengha,Tasmania,TAS,-40.8972,145.2387,4
7330,Edith Creek,Tasmania,TAS,-40.9653,145.0981,4
7330,Temma,Tasmania,TAS,-41.2285,144.6961,4
7330,Lileah,Tasmania,TAS,-40.9631,145.156,4
7330,Roger River,Tasmania,TAS,-41.0042,145.0613,4
7330,Togari,Tasmania,TAS,-40.9258,144.8635,4
7330,Alcomie,Tasmania,TAS,-40.9303,145.1896,4
7330,Scopus,Tasmania,TAS,-40.9351,145.0327,3
7330,Marrawah,Tasmania,TAS,-40.9128,144.705,4
7330,Christmas Hills,Tasmania,TAS,-40.901,145.0165,4
7330,Mella,Tasmania,TAS,-40.8393,145.0685,4
7330,Irishtown,Tasmania,TAS,-41.0924,144.9667,3
7330,Redpa,Tasmania,TAS,-40.9198,144.7692,4
7330,Forest,Tasmania,TAS,-40.8582,145.2432,4
7330,Smithton,Tasmania,TAS,-40.8459,145.125,4
7330,Arthur River,Tasmania,TAS,-41.0924,144.9667,3
7330,Brittons Swamp,Tasmania,TAS,-40.9319,144.9694,4
7330,Nabageena,Tasmania,TAS,-40.9907,145.1252,4
7330,Broadmeadows,Tasmania,TAS,-40.9351,145.0327,3
7330,Three Hummock Island,Tasmania,TAS,-41.0924,144.9667,3
7330,Scotchtown,Tasmania,TAS,-40.9351,145.0327,3
7330,Woolnorth,Tasmania,TAS,-41.0924,144.9667,3
7330,West Montagu,Tasmania,TAS,-40.9351,145.0327,3
7330,Couta Rocks,Tasmania,TAS,-40.9351,145.0327,3
7330,Nelson Bay,Tasmania,TAS,-40.9351,145.0327,3
7330,Montagu,Tasmania,TAS,-40.7739,144.9605,4
7331,Stanley,Tasmania,TAS,-40.7606,145.2955,4
7466,Gormanston,Tasmania,TAS,-42.0749,145.5968,4
7467,Lake Margaret,Tasmania,TAS,-42.0805,145.5565,3
7467,Queenstown,Tasmania,TAS,-42.0805,145.5565,4
7468,Macquarie Heads,Tasmania,TAS,-42.1519,145.3273,3
7468,Strahan,Tasmania,TAS,-42.1519,145.3273,4
7469,Granville Harbour,Tasmania,TAS,-41.8417,145.3764,3
7469,Renison Bell,Tasmania,TAS,-41.7943,145.4337,4
7469,Trial Harbour,Tasmania,TAS,-41.8417,145.3764,3
7469,Zeehan,Tasmania,TAS,-41.8834,145.3362,4
7470,Rosebery,Tasmania,TAS,-41.7794,145.5383,4
7800,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7802,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7803,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7804,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7805,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7806,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7807,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7808,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7809,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7810,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7811,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7812,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7813,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7814,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7823,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7824,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7827,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7828,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7829,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7845,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7850,Hobart,Tasmania,TAS,-42.8794,147.3294,4
7901,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7902,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7903,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7904,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7905,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7906,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7907,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7908,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7915,Burnie,Tasmania,TAS,-41.0558,145.9037,4
7916,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7917,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7918,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7919,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7920,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7921,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7922,Launceston,Tasmania,TAS,-41.4388,147.1347,4
7923,Launceston,Tasmania,TAS,-41.4388,147.1347,4
3000,Melbourne,Victoria,VIC,-37.814,144.9633,4
3001,Melbourne,Victoria,VIC,-37.814,144.9633,4
3002,East Melbourne,Victoria,VIC,-37.8167,144.9879,4
3003,West Melbourne,Victoria,VIC,-37.8101,144.95,4
3004,Melbourne,Victoria,VIC,-37.814,144.9633,4
3004,St Kilda Road Central,Victoria,VIC,-37.814,144.9633,3
3004,St Kilda Road Melbourne,Victoria,VIC,-37.814,144.9633,3
3005,World Trade Centre,Victoria,VIC,-37.8152,144.9632,1
3006,South Wharf,Victoria,VIC,-37.8253,144.9522,4
3006,Southbank,Victoria,VIC,-37.8228,144.9643,4
3008,Docklands,Victoria,VIC,-37.8149,144.9505,4
3010,University Of Melbourne,Victoria,VIC,-37.7958,144.9612,1
3011,Seddon,Victoria,VIC,-37.8061,144.8907,4
3011,Seddon West,Victoria,VIC,-37.7995,144.8963,3
3011,Footscray,Victoria,VIC,-37.8,144.9,4
3012,Maidstone,Victoria,VIC,-37.7803,144.8735,4
3012,Tottenham,Victoria,VIC,-37.7985,144.8626,4
3012,West Footscray,Victoria,VIC,-37.7975,144.8773,4
3012,Brooklyn,Victoria,VIC,-37.8161,144.8415,4
3012,Kingsville,Victoria,VIC,-37.8082,144.8791,4
3013,Yarraville,Victoria,VIC,-37.8167,144.9,4
3013,Yarraville West,Victoria,VIC,-37.8249,144.8708,3
3015,Newport,Victoria,VIC,-37.8443,144.8848,4
3015,Spotswood,Victoria,VIC,-37.8298,144.8852,4
3015,South Kingsville,Victoria,VIC,-37.8302,144.8709,4
3016,Williamstown North,Victoria,VIC,-37.8552,144.8826,4
3016,Williamstown,Victoria,VIC,-37.857,144.8977,4
3018,Seaholme,Victoria,VIC,-37.864,144.845,4
3018,Altona,Victoria,VIC,-37.8696,144.8304,4
3019,Braybrook,Victoria,VIC,-37.7867,144.8548,4
3019,Robinson,Victoria,VIC,-37.7867,144.8548,3
3020,Sunshine West,Victoria,VIC,-37.7912,144.8164,4
3020,Albion,Victoria,VIC,-37.7667,144.8333,4
3020,Glengala,Victoria,VIC,-37.775,144.8333,3
3020,Sunshine North,Victoria,VIC,-37.7699,144.8279,4
3020,Sunshine,Victoria,VIC,-37.7833,144.8333,4
3021,Albanvale,Victoria,VIC,-37.7461,144.7686,4
3021,St Albans,Victoria,VIC,-37.745,144.8005,4
3021,Kealba,Victoria,VIC,-37.7371,144.8283,4
3021,Kings Park,Victoria,VIC,-37.734,144.7777,4
3022,Deer Park East,Victoria,VIC,-37.7764,144.8016,3
3022,Ardeer,Victoria,VIC,-37.7759,144.8014,4
3023,Deer Park,Victoria,VIC,-37.7667,144.7833,4
3023,Ravenhall,Victoria,VIC,-37.7655,144.751,4
3023,Deer Park North,Victoria,VIC,-37.7541,144.7651,3
3023,Burnside,Victoria,VIC,-37.7494,144.753,4
3023,Cairnlea,Victoria,VIC,-37.7593,144.7878,4
3023,Burnside Heights,Victoria,VIC,-37.7269,144.7523,4
3023,Caroline Springs,Victoria,VIC,-37.7412,144.7363,4
3024,Wyndham Vale,Victoria,VIC,-37.8415,144.541,4
3024,Mount Cottrell,Victoria,VIC,-37.8167,144.5833,4
3024,Mambourin,Victoria,VIC,-37.8755,144.5032,4
3025,Altona North,Victoria,VIC,-37.8349,144.8474,4
3025,Altona Gate,Victoria,VIC,-37.8349,144.8474,3
3025,Altona East,Victoria,VIC,-37.8349,144.8474,3
3026,Laverton North,Victoria,VIC,-37.8205,144.8159,4
3027,Williams Landing,Victoria,VIC,-37.8619,144.7437,4
3028,Altona Meadows,Victoria,VIC,-37.8841,144.7837,4
3028,Laverton,Victoria,VIC,-37.862,144.7698,4
3028,Seabrook,Victoria,VIC,-37.8809,144.7587,4
3029,Truganina,Victoria,VIC,-37.8167,144.75,4
3029,Hoppers Crossing,Victoria,VIC,-37.8826,144.7003,4
3029,Tarneit,Victoria,VIC,-37.8667,144.6667,4
3030,Point Cook,Victoria,VIC,-37.9148,144.7509,4
3030,Quandong,Victoria,VIC,-37.8139,144.4951,4
3030,Werribee,Victoria,VIC,-37.9,144.6667,4
3030,Derrimut,Victoria,VIC,-37.7924,144.7699,4
3030,Cocoroc,Victoria,VIC,-37.9728,144.5643,4
3030,Werribee South,Victoria,VIC,-37.9475,144.7167,4
3031,Flemington,Victoria,VIC,-37.7882,144.93,4
3031,Kensington,Victoria,VIC,-37.7833,144.9333,4
3032,Maribyrnong,Victoria,VIC,-37.7833,144.8833,4
3032,Highpoint City,Victoria,VIC,-37.7747,144.8999,3
3032,Travancore,Victoria,VIC,-37.7808,144.9354,4
3032,Ascot Vale,Victoria,VIC,-37.7833,144.9167,4
3033,Keilor East,Victoria,VIC,-37.7326,144.865,4
3034,Avondale Heights,Victoria,VIC,-37.7616,144.8626,4
3036,Keilor North,Victoria,VIC,-37.6963,144.8047,4
3036,Keilor,Victoria,VIC,-37.7167,144.8333,4
3037,Hillside,Victoria,VIC,-37.6905,144.7417,4
3037,Calder Park,Victoria,VIC,-37.6798,144.7611,4
3037,Sydenham,Victoria,VIC,-37.7,144.7667,4
3037,Taylors Hill,Victoria,VIC,-37.7099,144.7548,4
3037,Delahey,Victoria,VIC,-37.7198,144.7773,4
3038,Keilor Downs,Victoria,VIC,-37.7234,144.8084,4
3038,Watergardens,Victoria,VIC,-37.6986,144.7863,3
3038,Taylors Lakes,Victoria,VIC,-37.6986,144.7863,4
3038,Keilor Lodge,Victoria,VIC,-37.7,144.8033,4
3039,Moonee Ponds,Victoria,VIC,-37.7667,144.9167,4
3040,Essendon,Victoria,VIC,-37.7667,144.9167,4
3040,Aberfeldie,Victoria,VIC,-37.7596,144.8974,4
3040,Essendon West,Victoria,VIC,-37.7576,144.879,4
3041,Strathmore,Victoria,VIC,-37.6333,142.4833,4
3041,Strathmore Heights,Victoria,VIC,-37.7133,144.8969,4
3041,Essendon North,Victoria,VIC,-37.7422,144.9055,4
3041,Essendon Fields,Victoria,VIC,-37.7245,144.8989,4
3041,Cross Keys,Victoria,VIC,-37.7133,144.8969,3
3042,Keilor Park,Victoria,VIC,-37.7203,144.8542,4
3042,Niddrie North,Victoria,VIC,-37.7311,144.8867,3
3042,Airport West,Victoria,VIC,-37.7247,144.8813,4
3042,Niddrie,Victoria,VIC,-37.7375,144.8921,4
3043,Gowanbrae,Victoria,VIC,-37.7082,144.8908,4
3043,Tullamarine,Victoria,VIC,-37.7013,144.881,4
3043,Gladstone Park,Victoria,VIC,-37.6874,144.8868,4
3044,Pascoe Vale,Victoria,VIC,-37.7333,144.9333,4
3044,Pascoe Vale South,Victoria,VIC,-37.7397,144.9461,4
3045,Melbourne Airport,Victoria,VIC,-37.6743,144.8518,4
3046,Oak Park,Victoria,VIC,-37.7184,144.9195,4
3046,Glenroy,Victoria,VIC,-37.7,144.9333,4
3046,Hadfield,Victoria,VIC,-37.7073,144.9416,4
3047,Dallas,Victoria,VIC,-37.6708,144.9354,4
3047,Broadmeadows,Victoria,VIC,-37.6802,144.9188,4
3047,Jacana,Victoria,VIC,-37.6878,144.9111,4
3048,Coolaroo,Victoria,VIC,-37.6568,144.9346,4
3048,Meadow Heights,Victoria,VIC,-37.6512,144.9186,4
3049,Westmeadows,Victoria,VIC,-37.676,144.887,4
3049,Attwood,Victoria,VIC,-37.6696,144.8868,4
3050,Royal Melbourne Hospital,Victoria,VIC,-37.7981,144.9555,1
3051,North Melbourne,Victoria,VIC,-37.8,144.9667,4
3051,Hotham Hill,Victoria,VIC,-37.7984,144.9449,3
3052,Parkville,Victoria,VIC,-37.7833,144.95,4
3052,Melbourne University,Victoria,VIC,-37.7603,144.9502,3
3053,Carlton,Victoria,VIC,-37.8,144.9667,4
3053,Carlton South,Victoria,VIC,-37.8004,144.9681,3
3054,Princes Hill,Victoria,VIC,-37.7818,144.9666,4
3054,Carlton North,Victoria,VIC,-37.7833,144.9667,4
3055,Brunswick South,Victoria,VIC,-37.7603,144.9502,3
3055,Brunswick West,Victoria,VIC,-37.7646,144.9438,4
3055,Moreland West,Victoria,VIC,-37.7603,144.9502,3
3055,Moonee Vale,Victoria,VIC,-37.7603,144.9502,3
3056,Brunswick,Victoria,VIC,-37.7667,144.9667,4
3056,Brunswick North,Victoria,VIC,-37.7667,144.9667,3
3056,Brunswick Lower,Victoria,VIC,-37.7667,144.9667,3
3057,Sumner,Victoria,VIC,-37.7603,144.9502,3
3057,Brunswick East,Victoria,VIC,-37.7725,144.9724,4
3058,Coburg North,Victoria,VIC,-37.7287,144.9613,4
3058,Moreland,Victoria,VIC,-37.7603,144.9502,3
3058,Batman,Victoria,VIC,-37.75,144.9667,3
3058,Merlynston,Victoria,VIC,-37.7603,144.9502,3
3058,Coburg,Victoria,VIC,-37.75,144.9667,4
3059,Greenvale,Victoria,VIC,-37.6333,144.8667,4
3060,Fawkner,Victoria,VIC,-37.7167,144.9667,4
3061,Campbellfield,Victoria,VIC,-37.6833,144.95,4
3062,Somerton,Victoria,VIC,-37.65,144.9333,4
3063,Yuroke,Victoria,VIC,-37.5911,144.8782,4
3063,Oaklands Junction,Victoria,VIC,-37.615,144.8434,4
3064,Kalkallo,Victoria,VIC,-37.5333,144.95,4
3064,Mickleham,Victoria,VIC,-37.5366,144.902,4
3064,Donnybrook,Victoria,VIC,-37.5333,144.9833,4
3064,Roxburgh Park,Victoria,VIC,-37.6258,144.9255,4
3064,Craigieburn,Victoria,VIC,-37.6,144.95,4
3065,Fitzroy,Victoria,VIC,-37.7984,144.9783,4
3066,Collingwood,Victoria,VIC,-37.8,144.9833,4
3066,Collingwood North,Victoria,VIC,-37.8,144.9833,3
3067,Abbotsford,Victoria,VIC,-37.8,145,4
3068,Fitzroy North,Victoria,VIC,-37.7886,144.9788,4
3068,Clifton Hill,Victoria,VIC,-37.7904,144.9975,4
3070,Northcote South,Victoria,VIC,-37.7667,145,3
3070,Northcote,Victoria,VIC,-37.7667,145,4
3071,Thornbury,Victoria,VIC,-37.75,145,4
3072,Preston,Victoria,VIC,-37.75,145.0167,4
3072,Regent West,Victoria,VIC,-37.7241,145.0179,3
3072,Gilberton,Victoria,VIC,-37.75,145.0167,3
3072,Preston South,Victoria,VIC,-37.7241,145.0179,3
3072,Preston West,Victoria,VIC,-37.75,145.0167,3
3072,Northland Centre,Victoria,VIC,-37.7241,145.0179,3
3072,Preston Lower,Victoria,VIC,-37.75,145.0167,3
3073,Reservoir,Victoria,VIC,-37.7167,145,4
3073,Keon Park,Victoria,VIC,-37.7,145.0167,4
3074,Thomastown,Victoria,VIC,-37.6833,145.0167,4
3075,Lalor,Victoria,VIC,-37.6667,145.0167,4
3076,Epping Dc,Victoria,VIC,-37.6307,145.006,3
3076,Epping,Victoria,VIC,-37.65,145.0333,4
3078,Alphington,Victoria,VIC,-37.7804,145.0309,4
3078,Fairfield,Victoria,VIC,-37.7798,145.0176,4
3079,Ivanhoe,Victoria,VIC,-37.769,145.0431,4
3079,Ivanhoe East,Victoria,VIC,-37.7832,145.0525,4
3079,Ivanhoe North,Victoria,VIC,-37.769,145.0431,3
3081,Heidelberg West,Victoria,VIC,-37.75,145.0333,4
3081,Heidelberg Rgh,Victoria,VIC,-37.743,145.0464,3
3081,Bellfield,Victoria,VIC,-37.7531,145.0385,4
3081,Heidelberg Heights,Victoria,VIC,-37.7431,145.057,4
3082,Mill Park,Victoria,VIC,-37.6667,145.0667,4
3083,Kingsbury,Victoria,VIC,-37.7141,145.0351,4
3083,Bundoora,Victoria,VIC,-37.6982,145.0597,4
3083,La Trobe University,Victoria,VIC,-37.7062,145.0474,3
3084,Viewbank,Victoria,VIC,-37.7399,145.0932,4
3084,Banyule,Victoria,VIC,-37.7464,145.0771,3
3084,Rosanna,Victoria,VIC,-37.7396,145.0697,4
3084,Heidelberg,Victoria,VIC,-37.75,145.0667,4
3084,Eaglemont,Victoria,VIC,-37.7638,145.0638,4
3085,Yallambie,Victoria,VIC,-37.7251,145.0935,4
3085,Macleod West,Victoria,VIC,-37.7228,145.0817,3
3085,Macleod,Victoria,VIC,-37.7204,145.0698,4
3086,La Trobe University,Victoria,VIC,-37.7173,145.0826,1
3087,Watsonia North,Victoria,VIC,-37.6998,145.0847,4
3087,Watsonia,Victoria,VIC,-37.7167,145.0833,4
3088,Saint Helena,Victoria,VIC,-37.7,145.1333,4
3088,Briar Hill,Victoria,VIC,-37.7167,145.1167,4
3088,Greensborough,Victoria,VIC,-37.7046,145.103,4
3088,St Helena,Victoria,VIC,-37.6895,145.1296,4
3089,Diamond Creek,Victoria,VIC,-37.6667,145.15,4
3090,Plenty,Victoria,VIC,-37.6667,145.1167,4
3091,Yarrambat,Victoria,VIC,-37.6375,145.1349,4
3093,Lower Plenty,Victoria,VIC,-37.7333,145.1167,4
3094,Montmorency,Victoria,VIC,-37.7167,145.1167,4
3095,Research,Victoria,VIC,-37.7,145.1833,4
3095,Eltham,Victoria,VIC,-37.7333,145.15,4
3095,Eltham North,Victoria,VIC,-37.7,145.15,4
3096,Wattle Glen,Victoria,VIC,-37.6667,145.1833,4
3097,Bend Of Islands,Victoria,VIC,-37.7008,145.2848,4
3097,Kangaroo Ground,Victoria,VIC,-37.6892,145.2317,4
3097,Watsons Creek,Victoria,VIC,-37.6833,145.2333,4
3099,Strathewen,Victoria,VIC,-37.55,145.2667,4
3099,Hurstbridge,Victoria,VIC,-37.6416,145.1941,4
3099,Arthurs Creek,Victoria,VIC,-37.5833,145.2,4
3099,Cottles Bridge,Victoria,VIC,-37.6333,145.2167,4
3099,Nutfield,Victoria,VIC,-37.6167,145.1833,4
3101,Kew,Victoria,VIC,-37.8064,145.0309,4
3101,Cotham,Victoria,VIC,-37.8064,145.0309,3
3102,Kew East,Victoria,VIC,-37.7976,145.0538,4
3103,Balwyn,Victoria,VIC,-37.8117,145.081,4
3103,Stradbroke Park,Victoria,VIC,-37.8117,145.081,3
3103,Deepdene,Victoria,VIC,-37.8167,145.0667,4
3103,Balwyn East,Victoria,VIC,-37.8117,145.081,3
3104,Balwyn North,Victoria,VIC,-37.7909,145.0939,4
3104,Greythorn,Victoria,VIC,-37.7909,145.0939,3
3105,Bulleen,Victoria,VIC,-37.7667,145.0833,4
3106,Templestowe,Victoria,VIC,-37.75,145.1167,4
3107,Templestowe Lower,Victoria,VIC,-37.7667,145.1167,4
3108,Doncaster,Victoria,VIC,-37.7883,145.1237,4
3109,Tunstall Square Po,Victoria,VIC,-37.7795,145.1638,3
3109,Doncaster East,Victoria,VIC,-37.7876,145.1489,4
3109,Doncaster Heights,Victoria,VIC,-37.7795,145.1638,3
3110,Nunawading Bc,Victoria,VIC,-37.7576,145.2408,1
3111,Donvale,Victoria,VIC,-37.7891,145.1749,4
3113,Warrandyte,Victoria,VIC,-37.75,145.2333,4
3113,North Warrandyte,Victoria,VIC,-37.7292,145.2157,4
3114,Park Orchards,Victoria,VIC,-37.7769,145.2146,4
3115,Wonga Park,Victoria,VIC,-37.7333,145.2667,4
3116,Chirnside Park,Victoria,VIC,-37.7386,145.3143,4
3121,Burnley,Victoria,VIC,-37.8286,145.0077,4
3121,Cremorne,Victoria,VIC,-37.8333,145,4
3121,Richmond,Victoria,VIC,-37.8182,145.0018,4
3121,Richmond East,Victoria,VIC,-37.8271,145.0064,3
3121,Burnley North,Victoria,VIC,-37.8271,145.0064,3
3121,Richmond South,Victoria,VIC,-37.8271,145.0064,3
3121,Richmond North,Victoria,VIC,-37.8271,145.0064,3
3122,Glenferrie South,Victoria,VIC,-37.8344,145.0397,3
3122,Auburn South,Victoria,VIC,-37.8333,145.05,4
3122,Hawthorn,Victoria,VIC,-37.8199,145.0358,4
3122,Hawthorn North,Victoria,VIC,-37.85,145.0333,3
3122,Hawthorn West,Victoria,VIC,-37.8344,145.0397,3
3123,Auburn,Victoria,VIC,-37.8167,145.05,4
3123,Hawthorn East,Victoria,VIC,-37.8248,145.0464,4
3124,Camberwell North,Victoria,VIC,-37.8248,145.0705,3
3124,Camberwell,Victoria,VIC,-37.8421,145.0694,4
3124,Camberwell West,Victoria,VIC,-37.8248,145.0705,3
3124,Camberwell South,Victoria,VIC,-37.8248,145.0705,3
3124,Hartwell,Victoria,VIC,-37.8421,145.0694,3
3124,Middle Camberwell,Victoria,VIC,-37.8248,145.0705,3
3125,Burwood,Victoria,VIC,-37.8505,145.0949,4
3125,Surrey Hills South,Victoria,VIC,-37.8498,145.119,3
3125,Bennettswood,Victoria,VIC,-37.8495,145.1114,3
3126,Canterbury,Victoria,VIC,-37.8247,145.0848,4
3126,Camberwell East,Victoria,VIC,-37.8248,145.0705,3
3127,Surrey Hills,Victoria,VIC,-37.8252,145.1004,4
3127,Surrey Hills North,Victoria,VIC,-37.8128,145.1043,3
3127,Mont Albert,Victoria,VIC,-37.8167,145.1,4
3128,Box Hill South,Victoria,VIC,-37.8324,145.121,4
3128,Box Hill Central,Victoria,VIC,-37.8256,145.1232,3
3128,Box Hill,Victoria,VIC,-37.8189,145.1254,4
3128,Wattle Park,Victoria,VIC,-37.829,145.1248,3
3128,Houston,Victoria,VIC,-37.829,145.1248,3
3129,Box Hill North,Victoria,VIC,-37.8027,145.1266,4
3129,Kerrimuir,Victoria,VIC,-37.8048,145.1229,3
3129,Mont Albert North,Victoria,VIC,-37.8004,145.1083,4
3130,Blackburn North,Victoria,VIC,-37.8093,145.1518,4
3130,Blackburn South,Victoria,VIC,-37.8398,145.1555,4
3130,Blackburn,Victoria,VIC,-37.819,145.1533,4
3130,Laburnum,Victoria,VIC,-37.8232,145.1511,3
3131,Forest Hill,Victoria,VIC,-37.8333,145.1833,4
3131,Brentford Square,Victoria,VIC,-37.8275,145.1739,3
3131,Nunawading,Victoria,VIC,-37.8204,145.1731,4
3132,Mitcham North,Victoria,VIC,-37.8182,145.199,3
3132,Mitcham,Victoria,VIC,-37.8167,145.2,4
3132,Rangeview,Victoria,VIC,-37.8186,145.1987,3
3133,Vermont,Victoria,VIC,-37.8362,145.1943,4
3133,Vermont South,Victoria,VIC,-37.8575,145.1827,4
3134,Warrandyte South,Victoria,VIC,-37.7667,145.25,4
3134,Heathwood,Victoria,VIC,-37.7944,145.2389,3
3134,Warranwood,Victoria,VIC,-37.7756,145.2473,4
3134,Ringwood North,Victoria,VIC,-37.8,145.2333,4
3134,Ringwood,Victoria,VIC,-37.8167,145.2333,4
3135,Bedford Road,Victoria,VIC,-37.8213,145.2489,3
3135,Ringwood East,Victoria,VIC,-37.8167,145.25,4
3135,Heathmont,Victoria,VIC,-37.8333,145.25,4
3136,Croydon North,Victoria,VIC,-37.7833,145.3,4
3136,Croydon South,Victoria,VIC,-37.8123,145.2681,4
3136,Croydon Hills,Victoria,VIC,-37.7765,145.2636,4
3136,Croydon,Victoria,VIC,-37.8,145.2833,4
3137,Kilsyth,Victoria,VIC,-37.8,145.3167,4
3137,Kilsyth South,Victoria,VIC,-37.8203,145.316,4
3138,Mooroolbark,Victoria,VIC,-37.7833,145.3,4
3139,Hoddles Creek,Victoria,VIC,-37.8333,145.5833,4
3139,Beenak,Victoria,VIC,-37.9,145.6333,4
3139,Don Valley,Victoria,VIC,-37.7364,145.5923,4
3139,Seville,Victoria,VIC,-37.7667,145.4667,4
3139,Woori Yallock,Victoria,VIC,-37.7806,145.5382,4
3139,Yellingbo,Victoria,VIC,-37.846,145.5291,4
3139,Wandin North,Victoria,VIC,-37.7833,145.4333,4
3139,Launching Place,Victoria,VIC,-37.7806,145.5683,4
3139,Seville East,Victoria,VIC,-37.775,145.4973,4
3139,Wandin East,Victoria,VIC,-37.8151,145.458,4
3140,Lilydale,Victoria,VIC,-37.75,145.35,4
3141,South Yarra,Victoria,VIC,-37.8333,144.9833,4
3142,Toorak,Victoria,VIC,-37.8416,145.0144,4
3142,Hawksburn,Victoria,VIC,-37.8416,145.0177,3
3143,Armadale,Victoria,VIC,-37.8554,145.0205,4
3143,Armadale North,Victoria,VIC,-37.85,145.0333,4
3144,Kooyong,Victoria,VIC,-37.8399,145.0352,4
3144,Malvern,Victoria,VIC,-37.8626,145.0281,4
3144,Malvern North,Victoria,VIC,-37.8512,145.0317,3
3145,Malvern East,Victoria,VIC,-37.874,145.0425,4
3145,Darling,Victoria,VIC,-37.8775,145.0624,3
3145,Caulfield East,Victoria,VIC,-37.8833,145.05,4
3145,Central Park,Victoria,VIC,-37.8775,145.0624,3
3145,Darling South,Victoria,VIC,-37.874,145.0425,3
3145,Wattletree Road Po,Victoria,VIC,-37.874,145.0425,3
3146,Glen Iris,Victoria,VIC,-37.8667,145.0667,4
3146,Tooronga,Victoria,VIC,-37.8599,145.0549,3
3147,Ashwood,Victoria,VIC,-37.8666,145.1055,4
3147,Ashburton,Victoria,VIC,-37.8626,145.0794,4
3148,Jordanville,Victoria,VIC,-37.8833,145.1167,3
3148,Holmesglen,Victoria,VIC,-37.8821,145.1002,3
3148,Chadstone,Victoria,VIC,-37.8877,145.0952,4
3148,Chadstone Centre,Victoria,VIC,-37.8855,145.1059,3
3149,Syndal,Victoria,VIC,-37.8742,145.1479,4
3149,Pinewood,Victoria,VIC,-37.8756,145.1387,3
3149,Mount Waverley,Victoria,VIC,-37.8771,145.1294,4
3150,Wheelers Hill,Victoria,VIC,-37.9,145.1833,4
3150,Brandon Park,Victoria,VIC,-37.8891,145.174,3
3150,Glen Waverley,Victoria,VIC,-37.8781,145.1648,4
3151,Burwood Heights,Victoria,VIC,-37.8535,145.1503,3
3151,Burwood East,Victoria,VIC,-37.85,145.15,4
3152,Wantirna South,Victoria,VIC,-37.8833,145.2167,4
3152,Knox City Centre,Victoria,VIC,-37.8656,145.2228,3
3152,Wantirna,Victoria,VIC,-37.85,145.2167,4
3152,Studfield,Victoria,VIC,-37.8656,145.2228,3
3153,Bayswater North,Victoria,VIC,-37.8261,145.2983,4
3153,Bayswater,Victoria,VIC,-37.85,145.2667,4
3154,The Basin,Victoria,VIC,-37.85,145.3167,4
3155,Boronia,Victoria,VIC,-37.8667,145.2833,4
3156,Lysterfield South,Victoria,VIC,-37.9581,145.2727,4
3156,Lysterfield,Victoria,VIC,-37.9333,145.3,4
3156,Mountain Gate,Victoria,VIC,-37.9136,145.2823,3
3156,Upper Ferntree Gully,Victoria,VIC,-37.8942,145.3124,4
3156,Ferntree Gully,Victoria,VIC,-37.8846,145.2954,4
3158,Upwey,Victoria,VIC,-37.9,145.3333,4
3159,Menzies Creek,Victoria,VIC,-37.9167,145.4,4
3159,Selby,Victoria,VIC,-37.9167,145.3833,4
3160,Tecoma,Victoria,VIC,-37.903,145.3447,4
3160,Belgrave South,Victoria,VIC,-37.9333,145.3333,4
3160,Belgrave Heights,Victoria,VIC,-37.9167,145.35,4
3160,Belgrave,Victoria,VIC,-37.9,145.35,4
3161,Caulfield North,Victoria,VIC,-37.8739,145.0249,4
3161,Caulfield Junction,Victoria,VIC,-37.872,145.0216,3
3162,Caulfield South,Victoria,VIC,-37.8956,145.026,4
3162,Hopetoun Gardens,Victoria,VIC,-37.8914,145.0254,3
3162,Caulfield,Victoria,VIC,-37.8825,145.0229,4
3163,Glen Huntly,Victoria,VIC,-37.8833,145.05,4
3163,Carnegie,Victoria,VIC,-37.8833,145.0667,4
3163,Booran Road Po,Victoria,VIC,-37.8895,145.0581,3
3163,Murrumbeena,Victoria,VIC,-37.8902,145.0711,4
3164,Dandenong South,Victoria,VIC,-37.9833,145.2,4
3165,Coatesville,Victoria,VIC,-37.9221,145.0667,3
3165,Bentleigh East,Victoria,VIC,-37.9193,145.053,4
3166,Oakleigh East,Victoria,VIC,-37.9,145.1167,4
3166,Oakleigh,Victoria,VIC,-37.8981,145.0884,4
3166,Huntingdale,Victoria,VIC,-37.9077,145.1085,4
3166,Hughesdale,Victoria,VIC,-37.9,145.0833,4
3167,Oakleigh South,Victoria,VIC,-37.9,145.1,4
3168,Notting Hill,Victoria,VIC,-37.905,145.1427,4
3168,Clayton,Victoria,VIC,-37.9173,145.1304,4
3169,Clarinda,Victoria,VIC,-37.9333,145.1167,4
3169,Clayton South,Victoria,VIC,-37.9333,145.1167,4
3170,Waverley Gardens,Victoria,VIC,-37.9167,145.2,3
3170,Mulgrave,Victoria,VIC,-37.9167,145.2,4
3171,Sandown Village,Victoria,VIC,-37.9457,145.1581,3
3171,Springvale,Victoria,VIC,-37.9485,145.1527,4
3172,Springvale South,Victoria,VIC,-37.9667,145.15,4
3172,Dingley Village,Victoria,VIC,-37.9827,145.1342,4
3173,Keysborough,Victoria,VIC,-37.9912,145.1739,4
3174,Noble Park,Victoria,VIC,-37.9667,145.1667,4
3174,Noble Park North,Victoria,VIC,-37.9498,145.1926,4
3175,Dandenong East,Victoria,VIC,-37.9833,145.2,3
3175,Dunearn,Victoria,VIC,-37.9833,145.2,3
3175,Dandenong South,Victoria,VIC,-37.9833,145.2,4
3175,Dandenong North,Victoria,VIC,-37.9665,145.2081,4
3175,Dandenong,Victoria,VIC,-37.9833,145.2,4
3175,Bangholme,Victoria,VIC,-38.0384,145.1869,4
3176,Scoresby Bc,Victoria,VIC,-37.9636,145.1943,1
3177,Doveton,Victoria,VIC,-37.9935,145.2389,4
3177,Eumemmerring,Victoria,VIC,-37.9978,145.2482,4
3178,Rowville,Victoria,VIC,-37.9333,145.2333,4
3179,Scoresby,Victoria,VIC,-37.9,145.2333,4
3180,Knoxfield,Victoria,VIC,-37.8898,145.2496,4
3181,Prahran,Victoria,VIC,-37.8511,144.9932,4
3181,Windsor,Victoria,VIC,-37.8548,144.9927,4
3181,Prahran East,Victoria,VIC,-37.853,144.9929,3
3182,St Kilda West,Victoria,VIC,-37.8599,144.9711,4
3182,St Kilda South,Victoria,VIC,-37.8633,144.9802,3
3182,St Kilda,Victoria,VIC,-37.8633,144.9802,3
3183,St Kilda East,Victoria,VIC,-37.8659,145.0002,4
3183,Balaclava,Victoria,VIC,-37.8692,144.9977,4
3184,Elwood,Victoria,VIC,-37.8821,144.9821,4
3184,Brighton Road,Victoria,VIC,-37.8809,144.9855,3
3185,Elsternwick,Victoria,VIC,-37.8864,145.0025,4
3185,Gardenvale,Victoria,VIC,-37.8979,145.0074,4
3185,Ripponlea,Victoria,VIC,-37.8833,145,4
3186,Dendy,Victoria,VIC,-37.895,145.0191,3
3186,Brighton,Victoria,VIC,-37.9056,145.0028,4
3186,Brighton North,Victoria,VIC,-37.9069,145.0053,4
3186,Were Street Po,Victoria,VIC,-37.9057,145.0067,3
3187,North Road,Victoria,VIC,-37.9667,145.0167,4
3187,Brighton East,Victoria,VIC,-37.9023,145.0173,4
3188,Hampton,Victoria,VIC,-37.9372,145.0046,4
3188,Hampton East,Victoria,VIC,-37.937,145.0286,4
3188,Hampton North,Victoria,VIC,-37.9374,145.0151,3
3189,Moorabbin,Victoria,VIC,-37.9367,145.0342,4
3189,Moorabbin East,Victoria,VIC,-37.9367,145.0342,4
3189,Wishart,Victoria,VIC,-37.9415,145.0578,3
3190,Highett,Victoria,VIC,-37.95,145.05,4
3191,Sandringham,Victoria,VIC,-37.95,145,4
3192,Cheltenham,Victoria,VIC,-37.9694,145.0481,4
3192,Cheltenham East,Victoria,VIC,-37.9634,145.0615,3
3192,Southland Centre,Victoria,VIC,-37.9634,145.0615,3
3193,Beaumaris,Victoria,VIC,-37.9822,145.0389,4
3193,Black Rock North,Victoria,VIC,-37.9667,145.0167,4
3193,Black Rock,Victoria,VIC,-37.9736,145.0164,4
3193,Cromer,Victoria,VIC,-37.9773,145.0323,3
3194,Mentone,Victoria,VIC,-37.9833,145.0667,4
3194,Mentone East,Victoria,VIC,-37.9833,145.0667,3
3194,Moorabbin Airport,Victoria,VIC,-37.9799,145.0815,3
3195,Braeside,Victoria,VIC,-37.9995,145.1163,4
3195,Mordialloc,Victoria,VIC,-38,145.0833,4
3195,Waterways,Victoria,VIC,-38.0148,145.1305,4
3195,Aspendale Gardens,Victoria,VIC,-38.0228,145.118,4
3195,Parkdale,Victoria,VIC,-37.9942,145.0734,4
3195,Aspendale,Victoria,VIC,-38.0291,145.1027,4
3196,Chelsea Heights,Victoria,VIC,-38.0333,145.1333,4
3196,Edithvale,Victoria,VIC,-38.0372,145.1097,4
3196,Bonbeach,Victoria,VIC,-38.0653,145.1228,4
3196,Chelsea,Victoria,VIC,-38.05,145.1167,4
3197,Carrum,Victoria,VIC,-38.0833,145.1333,4
3197,Patterson Lakes,Victoria,VIC,-38.0693,145.1433,4
3198,Belvedere Park,Victoria,VIC,-38.1049,145.1382,3
3198,Seaford,Victoria,VIC,-38.1,145.1333,4
3199,Karingal Centre,Victoria,VIC,-38.1278,145.15,3
3199,Frankston South,Victoria,VIC,-38.166,145.1364,4
3199,Frankston East,Victoria,VIC,-38.1333,145.1333,4
3199,Frankston,Victoria,VIC,-38.1446,145.1229,4
3199,Frankston Heights,Victoria,VIC,-38.1278,145.15,3
3199,Karingal,Victoria,VIC,-38.1604,145.1399,3
3200,Frankston North,Victoria,VIC,-38.1235,145.1484,4
3200,Pines Forest,Victoria,VIC,-38.1213,145.1552,3
3201,Carrum Downs,Victoria,VIC,-38.0997,145.1725,4
3202,Heatherton,Victoria,VIC,-37.956,145.0984,4
3204,Ormond,Victoria,VIC,-37.9039,145.0415,4
3204,Patterson,Victoria,VIC,-38.0693,145.1433,4
3204,Bentleigh,Victoria,VIC,-37.9181,145.0354,4
3204,Mckinnon,Victoria,VIC,-37.9167,145.05,4
3205,South Melbourne Dc,Victoria,VIC,-37.8335,144.9571,3
3205,South Melbourne,Victoria,VIC,-37.8333,144.9667,4
3206,Albert Park,Victoria,VIC,-37.8333,145,4
3206,Middle Park,Victoria,VIC,-37.8512,144.962,4
3207,Garden City,Victoria,VIC,-37.836,144.9183,4
3207,Port Melbourne,Victoria,VIC,-37.8396,144.9423,4
3211,Little River,Victoria,VIC,-37.9667,144.5,4
3212,Avalon,Victoria,VIC,-38.0239,144.4062,3
3212,Lara,Victoria,VIC,-38.0239,144.4062,4
3212,Point Wilson,Victoria,VIC,-38.0239,144.4062,3
3213,Anakie,Victoria,VIC,-37.9167,144.25,4
3213,Moorabool,Victoria,VIC,-38.0833,144.3,4
3213,Lovely Banks,Victoria,VIC,-38.0667,144.3333,4
3213,Batesford,Victoria,VIC,-38.1,144.2833,4
3214,Norlane,Victoria,VIC,-38.1014,144.3542,4
3214,North Shore,Victoria,VIC,-38.1,144.3667,4
3214,Corio,Victoria,VIC,-38.0833,144.3833,4
3215,Geelong North,Victoria,VIC,-38.1,144.3667,3
3215,Bell Post Hill,Victoria,VIC,-38.0956,144.3197,4
3215,North Geelong,Victoria,VIC,-38.1095,144.3513,4
3215,Rippleside,Victoria,VIC,-38.1257,144.355,4
3215,Hamlyn Heights,Victoria,VIC,-38.1211,144.328,4
3215,Drumcondra,Victoria,VIC,-38.1302,144.3538,4
3215,Bell Park,Victoria,VIC,-38.1079,144.3384,4
3216,Highton,Victoria,VIC,-38.1706,144.3114,4
3216,Belmont,Victoria,VIC,-38.1748,144.3428,4
3216,Freshwater Creek,Victoria,VIC,-38.2667,144.2833,4
3216,Grovedale East,Victoria,VIC,-38.2113,144.3244,3
3216,Mount Duneed,Victoria,VIC,-38.2409,144.3375,4
3216,Marshall,Victoria,VIC,-38.2,144.3667,4
3216,Wandana Heights,Victoria,VIC,-38.1758,144.3039,4
3216,Grovedale,Victoria,VIC,-38.2,144.35,4
3216,Waurn Ponds,Victoria,VIC,-38.2167,144.2833,4
3217,Deakin University,Victoria,VIC,-38.1974,144.3023,3
3217,Mount Duneed,Victoria,VIC,-38.2409,144.3375,4
3217,Freshwater Creek,Victoria,VIC,-38.2667,144.2833,4
3217,Charlemont,Victoria,VIC,-38.2187,144.3677,4
3217,Armstrong Creek,Victoria,VIC,-38.2312,144.3737,4
3218,Fyansford,Victoria,VIC,-38.1333,144.3167,4
3218,Stonehaven,Victoria,VIC,-38.1236,144.2291,4
3218,Geelong West,Victoria,VIC,-38.1389,144.3484,4
3218,Manifold Heights,Victoria,VIC,-38.1376,144.3337,4
3218,Herne Hill,Victoria,VIC,-38.1343,144.3241,4
3218,Murgheboluc,Victoria,VIC,-38.0833,144.1333,4
3219,Thomson,Victoria,VIC,-38.1698,144.3789,4
3219,St Albans Park,Victoria,VIC,-38.188,144.3921,4
3219,Newcomb,Victoria,VIC,-38.1662,144.3943,4
3219,East Geelong,Victoria,VIC,-38.1557,144.372,4
3219,Whittington,Victoria,VIC,-38.1808,144.3932,4
3219,Breakwater,Victoria,VIC,-38.1799,144.3676,4
3220,South Geelong,Victoria,VIC,-38.1619,144.3556,4
3220,Bareena,Victoria,VIC,-38.1505,144.3479,3
3220,Newtown,Victoria,VIC,-38.1539,144.3351,4
3220,Geelong,Victoria,VIC,-38.1471,144.3607,4
3221,Murgheboluc,Victoria,VIC,-38.0833,144.1333,4
3221,Fyansford,Victoria,VIC,-38.1333,144.3167,4
3221,Stonehaven,Victoria,VIC,-38.1236,144.2291,4
3221,Batesford,Victoria,VIC,-38.1,144.2833,4
3221,Gnarwarre,Victoria,VIC,-38.1667,144.15,4
3221,Moolap,Victoria,VIC,-38.1833,144.4333,4
3221,Bellarine,Victoria,VIC,-38.1333,144.6167,4
3221,Geelong Mc,Victoria,VIC,-38.2043,144.223,3
3221,Ceres,Victoria,VIC,-38.1667,144.2667,4
3221,Staughton Vale,Victoria,VIC,-37.85,144.2833,4
3221,Wongarra,Victoria,VIC,-38.7167,143.75,4
3221,Moorabool,Victoria,VIC,-38.0833,144.3,4
3221,Anakie,Victoria,VIC,-37.9167,144.25,4
3221,Barrabool,Victoria,VIC,-38.1667,144.2263,4
3221,Kennett River,Victoria,VIC,-38.6685,143.8584,4
3221,Wye River,Victoria,VIC,-38.6333,143.8833,4
3221,Wallington,Victoria,VIC,-38.2,144.4833,4
3221,Lovely Banks,Victoria,VIC,-38.0667,144.3333,4
3222,Mannerim,Victoria,VIC,-38.2167,144.5833,4
3222,Drysdale,Victoria,VIC,-38.1724,144.5699,4
3222,Marcus Hill,Victoria,VIC,-38.25,144.5667,4
3222,Clifton Springs,Victoria,VIC,-38.1567,144.5551,4
3222,Curlewis,Victoria,VIC,-38.1833,144.4833,4
3222,Wallington,Victoria,VIC,-38.2,144.4833,4
3223,St Leonards,Victoria,VIC,-38.1705,144.718,4
3223,Indented Head,Victoria,VIC,-38.1431,144.7134,4
3223,Bellarine,Victoria,VIC,-38.1333,144.6167,4
3223,Portarlington,Victoria,VIC,-38.1154,144.6556,4
3224,Leopold,Victoria,VIC,-38.19,144.4682,4
3224,Moolap,Victoria,VIC,-38.1833,144.4333,4
3225,Point Lonsdale,Victoria,VIC,-38.2817,144.6105,4
3225,Queenscliff,Victoria,VIC,-38.2679,144.6619,4
3225,Swan Island,Victoria,VIC,-35.4034,143.6912,4
3225,Swan Bay,Victoria,VIC,-38.2756,144.631,3
3226,Ocean Grove,Victoria,VIC,-38.2653,144.5147,4
3227,Connewarre,Victoria,VIC,-38.2667,144.4,4
3227,Breamlea,Victoria,VIC,-38.2597,144.4509,3
3227,Barwon Heads,Victoria,VIC,-38.2653,144.5147,4
3228,Bellbrae,Victoria,VIC,-38.3333,144.2667,4
3228,Torquay,Victoria,VIC,-38.3308,144.3264,4
3228,Bells Beach,Victoria,VIC,-38.3751,144.2564,4
3228,Jan Juc,Victoria,VIC,-38.3445,144.2952,4
3230,Anglesea,Victoria,VIC,-38.4073,144.1859,4
3231,Aireys Inlet,Victoria,VIC,-38.464,144.1039,4
3231,Eastern View,Victoria,VIC,-38.4595,144.0909,3
3231,Fairhaven,Victoria,VIC,-38.35,145.2833,4
3231,Big Hill,Victoria,VIC,-38.407,144.6936,3
3231,Moggs Creek,Victoria,VIC,-38.4595,144.0909,3
3232,Lorne,Victoria,VIC,-38.5404,143.9764,4
3233,Apollo Bay,Victoria,VIC,-38.7594,143.6722,4
3233,Cape Otway,Victoria,VIC,-38.85,143.5167,4
3233,Skenes Creek North,Victoria,VIC,-38.7803,143.6403,3
3233,Marengo,Victoria,VIC,-38.7783,143.6555,4
3233,Petticoat Creek,Victoria,VIC,-38.7803,143.6403,3
3233,Skenes Creek,Victoria,VIC,-38.7333,143.7167,4
3234,Grey River,Victoria,VIC,-38.6622,143.8466,3
3234,Wye River,Victoria,VIC,-38.6333,143.8833,4
3234,Wongarra,Victoria,VIC,-38.7167,143.75,4
3234,Separation Creek,Victoria,VIC,-38.6304,143.8945,4
3234,Sugarloaf,Victoria,VIC,-38.6622,143.8466,3
3234,Kennett River,Victoria,VIC,-38.6685,143.8584,4
3235,Deans Marsh,Victoria,VIC,-38.4,143.8667,4
3235,Boonah,Victoria,VIC,-38.4167,143.9333,4
3235,Pennyroyal,Victoria,VIC,-38.4167,143.85,4
3235,Benwerrin,Victoria,VIC,-38.4111,143.8833,3
3236,Mount Sabine,Victoria,VIC,-38.6333,143.7167,4
3236,Forrest,Victoria,VIC,-38.5167,143.7167,4
3237,Beech Forest,Victoria,VIC,-38.6366,143.5678,4
3237,Wattle Hill,Victoria,VIC,-38.7833,143.25,4
3237,Aire Valley,Victoria,VIC,-38.703,143.5728,4
3237,Wyelangta,Victoria,VIC,-38.6333,143.4667,4
3237,Ferguson,Victoria,VIC,-38.631,143.5258,4
3237,Gellibrand Lower,Victoria,VIC,-38.7167,143.25,4
3237,Yuulong,Victoria,VIC,-38.6722,143.4278,3
3237,Weeaproinah,Victoria,VIC,-38.6333,143.5,4
3238,Johanna,Victoria,VIC,-38.756,143.3893,4
3238,Lavers Hill,Victoria,VIC,-38.6667,143.4,4
3238,Hordern Vale,Victoria,VIC,-38.7782,143.5004,4
3238,Glenaire,Victoria,VIC,-38.7333,143.4278,3
3239,Chapple Vale,Victoria,VIC,-38.6333,143.3167,4
3239,Carlisle River,Victoria,VIC,-38.5667,143.3833,4
3239,Kennedys Creek,Victoria,VIC,-38.6,143.25,4
3239,Gellibrand,Victoria,VIC,-38.5167,143.5333,4
3240,Moriac,Victoria,VIC,-38.2409,144.1748,4
3240,Modewarre,Victoria,VIC,-38.25,144.1333,4
3240,Buckley,Victoria,VIC,-38.2333,144.1,4
3240,Mount Moriac,Victoria,VIC,-38.2167,144.1833,4
3240,Paraparap,Victoria,VIC,-38.2504,144.139,3
3240,Gherang,Victoria,VIC,-38.3113,144.1034,4
3241,Wensleydale,Victoria,VIC,-38.35,144.0333,4
3241,Winchelsea,Victoria,VIC,-38.2448,143.9832,4
3241,Bambra,Victoria,VIC,-38.3667,143.9333,4
3241,Ombersley,Victoria,VIC,-38.3154,144,3
3241,Winchelsea South,Victoria,VIC,-38.3154,144,3
3241,Wurdiboluc,Victoria,VIC,-38.3,144.05,4
3242,Birregurra,Victoria,VIC,-38.3368,143.7847,4
3243,Whoorel,Victoria,VIC,-38.3667,143.8167,4
3243,Murroon,Victoria,VIC,-38.4333,143.8167,4
3243,Gerangamete,Victoria,VIC,-38.45,143.7333,4
3243,Barwon Downs,Victoria,VIC,-38.4667,143.75,4
3243,Warncoort,Victoria,VIC,-38.3167,143.7167,4
3249,Barongarook,Victoria,VIC,-38.4167,143.6,4
3249,Swan Marsh,Victoria,VIC,-38.3667,143.3833,4
3249,Dreeite,Victoria,VIC,-38.1833,143.5167,4
3249,Barongarook West,Victoria,VIC,-38.364,143.5632,3
3249,Yeo,Victoria,VIC,-38.4167,143.7,4
3249,Dreeite South,Victoria,VIC,-38.364,143.5632,3
3249,Larpent,Victoria,VIC,-38.35,143.5,4
3249,Coragulac,Victoria,VIC,-38.2667,143.5333,4
3249,Irrewarra,Victoria,VIC,-38.3167,143.6333,4
3249,Pirron Yallock,Victoria,VIC,-38.35,143.4333,4
3249,Tanybryn,Victoria,VIC,-38.65,143.7167,4
3249,Pomborneit East,Victoria,VIC,-38.364,143.5632,3
3249,Yeodene,Victoria,VIC,-38.4167,143.7,4
3249,Corunnun,Victoria,VIC,-38.3019,143.5942,3
3249,Warrion,Victoria,VIC,-38.2333,143.5667,4
3249,Wool Wool,Victoria,VIC,-38.25,143.4167,4
3249,Ondit,Victoria,VIC,-38.25,143.6333,4
3249,Irrewillipe East,Victoria,VIC,-38.364,143.5632,3
3249,Gerangamete,Victoria,VIC,-38.45,143.7333,4
3249,Balintore,Victoria,VIC,-38.364,143.5632,3
3249,Nalangil,Victoria,VIC,-38.3,143.45,4
3249,Kawarren,Victoria,VIC,-38.4833,143.5833,4
3249,Irrewillipe,Victoria,VIC,-38.4,143.4167,4
3249,Barramunga,Victoria,VIC,-38.5833,143.6833,4
3249,Alvie,Victoria,VIC,-38.2333,143.5,4
3250,Colac West,Victoria,VIC,-38.3445,143.5841,3
3250,Colac East,Victoria,VIC,-38.3445,143.5841,3
3250,Colac,Victoria,VIC,-38.339,143.5849,4
3250,Elliminyt,Victoria,VIC,-38.35,143.5833,4
3251,Cundare North,Victoria,VIC,-38.1167,143.5667,4
3251,Cundare,Victoria,VIC,-38.1167,143.5667,4
3251,Weering,Victoria,VIC,-38.1,143.6333,4
3251,Beeac,Victoria,VIC,-38.1938,143.6413,4
3251,Eurack,Victoria,VIC,-38.15,143.7,4
3254,Cororooke,Victoria,VIC,-38.2833,143.5167,4
3260,Kariah,Victoria,VIC,-38.1833,143.2167,4
3260,Gnotuk,Victoria,VIC,-38.2167,143.1167,4
3260,Carpendeit,Victoria,VIC,-38.3833,143.2667,4
3260,Pomborneit North,Victoria,VIC,-38.2612,143.2241,3
3260,Bostocks Creek,Victoria,VIC,-38.3,143.1167,4
3260,Pomborneit,Victoria,VIC,-38.3,143.3,4
3260,Weerite,Victoria,VIC,-38.25,143.25,4
3260,Koallah,Victoria,VIC,-38.2612,143.2241,3
3260,Leslie Manor,Victoria,VIC,-38.1333,143.3833,4
3260,Camperdown,Victoria,VIC,-38.2339,143.1498,4
3260,Tandarook,Victoria,VIC,-38.2612,143.2241,3
3260,South Purrumbete,Victoria,VIC,-38.35,143.2167,4
3260,Tesbury,Victoria,VIC,-38.2612,143.2241,3
3260,Skibo,Victoria,VIC,-38.2612,143.2241,3
3260,Chocolyn,Victoria,VIC,-38.2612,143.2241,3
3260,Bookaar,Victoria,VIC,-38.2612,143.2241,3
3260,Bungador,Victoria,VIC,-38.2512,143.2045,3
3260,Stonyford,Victoria,VIC,-38.2512,143.2045,3
3264,Terang,Victoria,VIC,-38.2408,142.9212,4
3265,Boorcan,Victoria,VIC,-38.2167,143.0167,4
3265,Noorat East,Victoria,VIC,-38.2472,142.8397,3
3265,Kolora,Victoria,VIC,-38.1333,142.9167,4
3265,The Sisters,Victoria,VIC,-38.2,142.7833,4
3265,Ellerslie,Victoria,VIC,-38.15,142.7,4
3265,Taroon,Victoria,VIC,-38.2472,142.8397,3
3265,Dixie,Victoria,VIC,-38.3,142.9333,4
3265,Garvoc,Victoria,VIC,-38.3,142.8333,4
3265,Glenormiston South,Victoria,VIC,-38.1833,142.9667,4
3265,Framlingham East,Victoria,VIC,-38.2,142.7,4
3265,Laang,Victoria,VIC,-38.3667,142.8167,4
3265,Panmure,Victoria,VIC,-38.3333,142.7333,4
3265,Cudgee,Victoria,VIC,-38.35,142.65,4
3265,Noorat,Victoria,VIC,-38.1916,142.9287,4
3265,Glenormiston North,Victoria,VIC,-38.15,142.9833,4
3265,Ecklin South,Victoria,VIC,-38.4,142.9167,4
3265,Framlingham,Victoria,VIC,-38.2333,142.7167,4
3266,Jancourt East,Victoria,VIC,-38.4143,143.1039,3
3266,Elingamite North,Victoria,VIC,-38.35,143.0167,4
3266,Elingamite,Victoria,VIC,-38.3833,143.0167,4
3266,Simpson,Victoria,VIC,-38.5022,143.2501,4
3266,Bullaharre,Victoria,VIC,-38.3753,143.0622,3
3266,Jancourt,Victoria,VIC,-38.3667,143.1,4
3266,Cobrico,Victoria,VIC,-38.3,143.0333,4
3266,Naroghid,Victoria,VIC,-38.3753,143.0622,3
3266,Glenfyne,Victoria,VIC,-38.4,142.9833,4
3266,Cobden,Victoria,VIC,-38.3286,143.0748,4
3267,Scotts Creek,Victoria,VIC,-38.4333,143.05,4
3268,Nirranda,Victoria,VIC,-38.5,142.7833,4
3268,Nullawarre,Victoria,VIC,-38.4667,142.7667,4
3268,Timboon,Victoria,VIC,-38.4843,142.9803,4
3268,Newfield,Victoria,VIC,-38.5667,143.0167,4
3268,Ayrford,Victoria,VIC,-38.4249,142.8596,4
3268,Brucknell,Victoria,VIC,-38.45,142.9333,4
3268,Nirranda South,Victoria,VIC,-38.4987,142.9281,3
3268,Heytesbury Lower,Victoria,VIC,-38.5667,142.9333,4
3268,Curdie Vale,Victoria,VIC,-38.5167,142.8333,4
3268,The Cove,Victoria,VIC,-38.4987,142.9281,3
3268,Curdievale,Victoria,VIC,-38.4987,142.9281,3
3268,Cowleys Creek,Victoria,VIC,-38.5,143,4
3268,Curdies River,Victoria,VIC,-38.45,142.9667,4
3268,Cooriemungle,Victoria,VIC,-38.5597,143.1363,4
3268,Nirranda East,Victoria,VIC,-38.4987,142.9281,3
3268,Nullawarre North,Victoria,VIC,-38.4987,142.9281,3
3268,Timboon West,Victoria,VIC,-38.4987,142.9281,3
3268,Paaratte,Victoria,VIC,-38.4987,142.9281,3
3269,Princetown,Victoria,VIC,-38.6833,143.15,4
3269,Port Campbell,Victoria,VIC,-38.6192,142.9958,4
3269,Waarre,Victoria,VIC,-38.6513,143.0729,3
3270,Peterborough,Victoria,VIC,-38.6035,142.8733,4
3271,Dundonnell,Victoria,VIC,-37.85,142.9833,4
3271,Darlington,Victoria,VIC,-38,143.05,4
3271,Pura Pura,Victoria,VIC,-37.8167,143.0833,4
3272,Mortlake,Victoria,VIC,-38.081,142.8083,4
3272,Woorndoo,Victoria,VIC,-37.9,142.8167,4
3273,Hexham,Victoria,VIC,-38,142.7,4
3274,Caramut,Victoria,VIC,-37.95,142.5167,4
3275,Mailer Flat,Victoria,VIC,-38.2876,142.4615,3
3275,Mailors Flat,Victoria,VIC,-38.3,142.4667,4
3276,Minjah,Victoria,VIC,-38.2,142.4333,3
3276,Woolsthorpe,Victoria,VIC,-38.2,142.4333,4
3277,Mepunga East,Victoria,VIC,-38.45,142.7333,4
3277,Allansford,Victoria,VIC,-38.3864,142.5943,4
3277,Naringal East,Victoria,VIC,-38.4216,142.6902,3
3277,Naringal,Victoria,VIC,-38.4,142.7,4
3277,Mepunga,Victoria,VIC,-38.45,142.7333,4
3277,Mepunga West,Victoria,VIC,-38.4216,142.6902,3
3278,Purnim,Victoria,VIC,-38.2833,142.6333,4
3278,Purnim West,Victoria,VIC,-38.2833,142.6333,3
3279,Wangoom,Victoria,VIC,-38.3333,142.5833,4
3279,Ballangeich,Victoria,VIC,-38.2167,142.6667,4
3280,Warrnambool,Victoria,VIC,-38.3818,142.488,4
3280,Dennington,Victoria,VIC,-38.3667,142.45,4
3281,Grassmere,Victoria,VIC,-38.2833,142.5333,4
3281,Woodford,Victoria,VIC,-38.3167,142.4667,4
3281,Bushfield,Victoria,VIC,-38.3333,142.5167,4
3281,Winslow,Victoria,VIC,-38.2333,142.45,4
3282,Koroit,Victoria,VIC,-38.2918,142.3673,4
3282,Illowa,Victoria,VIC,-38.3333,142.4167,4
3283,Warrong,Victoria,VIC,-38.2333,142.3667,4
3283,Crossley,Victoria,VIC,-38.3,142.3333,4
3283,Tower Hill,Victoria,VIC,-38.3333,142.35,4
3283,Yangery,Victoria,VIC,-38.3167,142.4167,4
3283,Southern Cross,Victoria,VIC,-38.3,142.4,4
3283,Yarpturk,Victoria,VIC,-38.3,142.4333,4
3283,Killarney,Victoria,VIC,-38.35,142.3333,4
3283,Tarrone,Victoria,VIC,-38.2852,142.3574,3
3283,Kirkstall,Victoria,VIC,-38.2667,142.3167,4
3283,Willatook,Victoria,VIC,-38.1667,142.2667,4
3284,Port Fairy,Victoria,VIC,-38.3853,142.2371,4
3284,Orford,Victoria,VIC,-38.2035,142.1009,4
3285,Yambuk,Victoria,VIC,-38.3156,142.0651,4
3285,Codrington,Victoria,VIC,-38.2667,141.9667,4
3285,Rosebrook,Victoria,VIC,-38.3536,142.2545,4
3285,St Helens,Victoria,VIC,-38.2395,141.9049,3
3285,Narrawong,Victoria,VIC,-38.25,141.7167,4
3285,Toolong,Victoria,VIC,-38.2395,141.9049,3
3285,Tyrendarra,Victoria,VIC,-38.2167,141.7833,4
3285,Tyrendarra East,Victoria,VIC,-38.25,141.9,4
3286,Warrabkook,Victoria,VIC,-38,142.0167,4
3286,Macarthur,Victoria,VIC,-38.0339,142.0018,4
3286,Condah Swamp,Victoria,VIC,-38.0113,141.9617,3
3286,Knebsworth,Victoria,VIC,-38,141.8667,4
3287,Minhamite,Victoria,VIC,-37.9833,142.35,4
3287,Hawkesdale,Victoria,VIC,-38.1167,142.3167,4
3289,Gazette,Victoria,VIC,-37.8751,142.2913,3
3289,Tabor,Victoria,VIC,-37.8333,142.2,4
3289,Gerrigerrup,Victoria,VIC,-37.8751,142.2913,3
3289,Penshurst,Victoria,VIC,-37.8753,142.2907,4
3289,Purdeet,Victoria,VIC,-37.9167,142.3833,4
3292,Nelson,Victoria,VIC,-38.05,141.0167,4
3293,Glenthompson,Victoria,VIC,-37.7187,142.5402,1
3293,Narrapumelap South,Victoria,VIC,-37.7187,142.5402,1
3293,Nareeb,Victoria,VIC,-37.6555,142.0879,1
3294,Moutajup,Victoria,VIC,-37.6667,142.2333,4
3294,Victoria Point,Victoria,VIC,-37.5766,142.3021,3
3294,Mirranatwa,Victoria,VIC,-37.4167,142.4167,4
3294,Woodhouse,Victoria,VIC,-37.5766,142.3021,3
3294,Dunkeld,Victoria,VIC,-37.6499,142.3439,4
3294,Karabeal,Victoria,VIC,-37.6,142.1833,4
3294,Victoria Valley,Victoria,VIC,-37.55,142.3333,4
3300,Hamilton,Victoria,VIC,-37.7442,142.022,4
3300,Byaduk North,Victoria,VIC,-37.8928,141.9553,4
3301,Yatchaw,Victoria,VIC,-37.8167,142.1,4
3301,Wannon,Victoria,VIC,-37.6667,141.8333,4
3301,Croxton East,Victoria,VIC,-37.8,142.2167,4
3301,Mount Napier,Victoria,VIC,-37.7904,142.0065,3
3301,Bochara,Victoria,VIC,-37.7,141.9333,4
3301,Tahara,Victoria,VIC,-37.7333,141.7,4
3301,Tarrington,Victoria,VIC,-37.7667,142.1,4
3301,Hensley Park,Victoria,VIC,-37.7904,142.0065,3
3301,Byaduk,Victoria,VIC,-37.9523,141.9552,4
3301,Warrayure,Victoria,VIC,-37.6833,142.15,4
3301,Broadwater,Victoria,VIC,-38.1253,142.0497,4
3301,Morgiana,Victoria,VIC,-37.7904,142.0065,3
3301,Strathkellar,Victoria,VIC,-37.7,142.1,4
3301,Yulecart,Victoria,VIC,-37.7475,141.9389,4
3301,Buckley Swamp,Victoria,VIC,-37.7904,142.0065,3
3302,Grassdale,Victoria,VIC,-37.8,141.65,4
3302,Branxholme,Victoria,VIC,-37.8667,141.8,4
3303,Condah,Victoria,VIC,-37.95,141.75,4
3303,Wallacedale,Victoria,VIC,-37.9333,141.8,4
3303,Breakaway Creek,Victoria,VIC,-38.0167,141.8,4
3303,Lake Condah,Victoria,VIC,-38.1167,141.8,4
3303,Hotspur,Victoria,VIC,-37.9333,141.5667,4
3304,Heywood,Victoria,VIC,-38.1329,141.6295,4
3304,Drik Drik,Victoria,VIC,-38,141.3,4
3304,Drumborg,Victoria,VIC,-38.0833,141.6,4
3304,Mumbannar,Victoria,VIC,-37.9269,141.1875,4
3304,Myamyn,Victoria,VIC,-38,141.7333,4
3304,Lyons,Victoria,VIC,-38.0167,141.4667,4
3304,Dartmoor,Victoria,VIC,-37.9222,141.2749,4
3304,Greenwald,Victoria,VIC,-37.9667,141.3667,4
3304,Winnap,Victoria,VIC,-37.95,141.3167,4
3304,Bessiebelle,Victoria,VIC,-38.15,141.9667,4
3304,Homerton,Victoria,VIC,-38.1333,141.7833,4
3304,Milltown,Victoria,VIC,-38.05,141.7167,4
3305,Mount Richmond,Victoria,VIC,-38.2019,141.3416,4
3305,Cape Bridgewater,Victoria,VIC,-38.3705,141.3995,4
3305,Bolwarra,Victoria,VIC,-38.2833,141.6167,4
3305,Portland,Victoria,VIC,-38.3462,141.6026,4
3305,Gorae,Victoria,VIC,-38.2333,141.55,4
3305,Allestree,Victoria,VIC,-38.2833,141.65,4
3305,Gorae West,Victoria,VIC,-38.25,141.4833,4
3305,Cashmore,Victoria,VIC,-38.3116,141.4774,4
3305,Portland West,Victoria,VIC,-38.354,141.574,4
3305,Portland North,Victoria,VIC,-38.2855,141.5377,3
3305,Dutton Way,Victoria,VIC,-38.2855,141.5377,3
3305,Heathmere,Victoria,VIC,-38.2167,141.6167,4
3309,Digby,Victoria,VIC,-37.8,141.5333,4
3310,Merino,Victoria,VIC,-37.7205,141.5483,4
3310,Tahara West,Victoria,VIC,-37.7205,141.5483,3
3311,Casterton,Victoria,VIC,-37.5849,141.403,4
3311,Corndale,Victoria,VIC,-37.5849,141.403,3
3312,Lindsay,Victoria,VIC,-37.6333,140.9667,4
3312,Bahgallah,Victoria,VIC,-37.6333,141.3833,4
3312,Lake Mundi,Victoria,VIC,-37.55,141,4
3312,Dunrobin,Victoria,VIC,-37.4799,141.2923,3
3312,Powers Creek,Victoria,VIC,-37.2,141.2833,4
3312,Henty,Victoria,VIC,-37.6667,141.5167,4
3312,Wando Vale,Victoria,VIC,-37.5167,141.45,4
3312,Nangeela,Victoria,VIC,-37.4799,141.2923,3
3312,Carapook,Victoria,VIC,-37.55,141.5333,4
3312,Warrock,Victoria,VIC,-37.4799,141.2923,3
3312,Brimboal,Victoria,VIC,-37.3833,141.4,4
3312,Killara,Victoria,VIC,-37.75,145.5,4
3312,Poolaijelo,Victoria,VIC,-37.2324,141.0988,4
3312,Sandford,Victoria,VIC,-37.6167,141.4333,4
3312,Strathdownie,Victoria,VIC,-37.7333,141.1833,4
3312,Chetwynd,Victoria,VIC,-37.2833,141.4167,4
3312,Dorodong,Victoria,VIC,-37.3332,141.0697,4
3312,Dergholm,Victoria,VIC,-37.3833,141.2167,4
3312,Wando Bridge,Victoria,VIC,-37.4833,141.4333,4
3314,Mooralla,Victoria,VIC,-37.4,142.1333,4
3314,Grampians,Victoria,VIC,-37.0297,142.4986,4
3314,Cavendish,Victoria,VIC,-37.5167,142.0333,4
3314,Glenisla,Victoria,VIC,-37.2333,142.1667,4
3314,Bulart,Victoria,VIC,-37.55,141.95,4
3315,Nareen,Victoria,VIC,-37.3833,141.5667,4
3315,Paschendale,Victoria,VIC,-37.6667,141.6,4
3315,Konongwootong,Victoria,VIC,-37.5333,141.6833,4
3315,Tarrenlea,Victoria,VIC,-37.6833,141.7333,4
3315,Muntham,Victoria,VIC,-37.5833,141.5667,4
3315,Tahara Bridge,Victoria,VIC,-37.4943,141.6909,3
3315,Tarrayoukyan,Victoria,VIC,-37.3167,141.5833,4
3315,Clover Flat,Victoria,VIC,-37.4943,141.6909,3
3315,Gritjurk,Victoria,VIC,-37.5833,141.7667,4
3315,Brit Brit,Victoria,VIC,-37.4333,141.7667,4
3315,Coleraine,Victoria,VIC,-37.5983,141.6912,4
3315,Coojar,Victoria,VIC,-37.4943,141.6909,3
3315,Melville Forest,Victoria,VIC,-37.5,141.8667,4
3315,Gringegalgona,Victoria,VIC,-37.4167,141.8167,4
3315,Culla,Victoria,VIC,-37.2333,141.65,4
3315,Hilgay,Victoria,VIC,-37.4943,141.6909,3
3315,Wootong Vale,Victoria,VIC,-37.4943,141.6909,3
3317,Harrow,Victoria,VIC,-37.1667,141.6,4
3318,Edenhope,Victoria,VIC,-37.0365,141.2966,4
3318,Kadnook,Victoria,VIC,-37.0255,141.3217,3
3318,Connewirricoo,Victoria,VIC,-37.2,141.4667,4
3318,Ullswater,Victoria,VIC,-36.9667,141.3833,4
3318,Langkoop,Victoria,VIC,-37.1,141.0333,4
3318,Patyah,Victoria,VIC,-36.9,141.2333,4
3318,Charam,Victoria,VIC,-36.95,141.5167,4
3319,Bringalbert,Victoria,VIC,-36.85,141.1667,4
3319,Apsley,Victoria,VIC,-36.9667,141.0833,4
3319,Benayeo,Victoria,VIC,-36.9,141.05,4
3321,Hesse,Victoria,VIC,-38.1009,144.0575,3
3321,Inverleigh,Victoria,VIC,-38.1018,144.0513,4
3321,Wingeel,Victoria,VIC,-38.1009,144.0575,3
3322,Cressy,Victoria,VIC,-38.0333,143.6333,4
3323,Foxhow,Victoria,VIC,-38.05,143.4667,4
3323,Duverney,Victoria,VIC,-38.0167,143.5667,4
3323,Berrybank,Victoria,VIC,-37.9833,143.4833,4
3324,Lismore,Victoria,VIC,-37.9527,143.3378,4
3324,Mount Bute,Victoria,VIC,-37.8667,143.4167,4
3324,Mingay,Victoria,VIC,-37.8333,143.3167,4
3325,Derrinallum,Victoria,VIC,-37.9479,143.2203,4
3325,Vite Vite North,Victoria,VIC,-37.7833,143.2,4
3325,Vite Vite,Victoria,VIC,-37.8833,143.1667,4
3325,Larralea,Victoria,VIC,-37.8715,143.1957,3
3328,Teesdale,Victoria,VIC,-38.0333,144.05,4
3329,Shelford,Victoria,VIC,-38.0167,143.9667,4
3329,Barunah Park,Victoria,VIC,-38.0167,143.9667,3
3329,Barunah Plains,Victoria,VIC,-38.0167,143.9667,3
3330,Rokewood,Victoria,VIC,-37.9,143.7167,4
3331,She Oaks,Victoria,VIC,-37.9,144.1333,4
3331,Sutherlands Creek,Victoria,VIC,-37.9176,144.1721,3
3331,Maude,Victoria,VIC,-37.95,144.1667,4
3331,Steiglitz,Victoria,VIC,-37.8833,144.1833,4
3331,Russells Bridge,Victoria,VIC,-38.0166,144.1818,3
3331,Gheringhap,Victoria,VIC,-38.0833,144.2333,4
3331,Bannockburn,Victoria,VIC,-38.0489,144.1688,4
3332,Lethbridge,Victoria,VIC,-37.9657,144.1357,4
3333,Bamganie,Victoria,VIC,-37.9333,143.9833,4
3333,Meredith,Victoria,VIC,-37.8454,144.0774,4
3334,Bungal,Victoria,VIC,-37.7889,144.0333,3
3334,Elaine,Victoria,VIC,-37.7833,144.0333,4
3334,Mount Doran,Victoria,VIC,-37.7889,144.0333,3
3334,Morrisons,Victoria,VIC,-37.7833,144.1,4
3334,Cargerie,Victoria,VIC,-37.8,143.9667,4
3335,Plumpton,Victoria,VIC,-37.687,144.6908,4
3335,Rockbank,Victoria,VIC,-37.7335,144.67,4
3337,Melton,Victoria,VIC,-37.6834,144.5854,4
3337,Melton West,Victoria,VIC,-37.6785,144.5688,4
3337,Kurunjang,Victoria,VIC,-37.6759,144.5969,4
3337,Toolern Vale,Victoria,VIC,-37.6,144.6,4
3338,Brookfield,Victoria,VIC,-37.7008,144.5603,4
3338,Eynesbury,Victoria,VIC,-37.8004,144.5746,4
3338,Melton South,Victoria,VIC,-37.7077,144.5749,4
3338,Exford,Victoria,VIC,-37.7259,144.53,4
3340,Staughton Vale,Victoria,VIC,-37.85,144.2833,4
3340,Balliang East,Victoria,VIC,-37.7833,144.45,4
3340,Hopetoun Park,Victoria,VIC,-37.6928,144.501,4
3340,Balliang,Victoria,VIC,-37.8333,144.3667,4
3340,Bacchus Marsh,Victoria,VIC,-37.6727,144.4383,4
3340,Merrimu,Victoria,VIC,-37.6576,144.4535,4
3340,Parwan,Victoria,VIC,-37.705,144.4699,4
3340,Maddingley,Victoria,VIC,-37.6852,144.4324,4
3340,Darley,Victoria,VIC,-37.6581,144.4434,4
3340,Coimadai,Victoria,VIC,-37.6167,144.4667,4
3340,Long Forest,Victoria,VIC,-37.6727,144.514,4
3340,Glenmore,Victoria,VIC,-37.7127,144.3185,4
3340,Rowsley,Victoria,VIC,-37.7199,144.3727,4
3341,Korobeit,Victoria,VIC,-37.5465,144.313,3
3341,Dales Creek,Victoria,VIC,-37.5226,144.298,4
3341,Myrniong,Victoria,VIC,-37.6167,144.35,4
3341,Pentland Hills,Victoria,VIC,-37.6632,144.4085,4
3341,Greendale,Victoria,VIC,-37.5603,144.2968,4
3342,Colbrook,Victoria,VIC,-37.6426,144.2293,3
3342,Beremboke,Victoria,VIC,-37.7833,144.25,4
3342,Ballan,Victoria,VIC,-37.5999,144.2289,4
3342,Fiskville,Victoria,VIC,-37.6819,144.2264,4
3342,Mount Wallace,Victoria,VIC,-37.75,144.2167,4
3342,Bunding,Victoria,VIC,-37.5167,144.1667,4
3342,Durdidwarrah,Victoria,VIC,-37.6426,144.2293,3
3342,Blakeville,Victoria,VIC,-37.5167,144.2167,4
3342,Ingliston,Victoria,VIC,-37.65,144.3,4
3345,Gordon,Victoria,VIC,-37.5823,144.1093,4
3350,Brown Hill,Victoria,VIC,-37.55,143.9,4
3350,Black Hill,Victoria,VIC,-37.55,143.8667,4
3350,Ballarat,Victoria,VIC,-37.5662,143.8496,4
3350,Lake Wendouree,Victoria,VIC,-37.5499,143.8473,4
3350,Bakery Hill,Victoria,VIC,-37.5635,143.8658,4
3350,Mount Pleasant,Victoria,VIC,-37.5833,143.8667,4
3350,Ballarat Central,Victoria,VIC,-37.5621,143.8508,4
3350,Golden Point,Victoria,VIC,-37.5749,143.8687,4
3350,Mount Helen,Victoria,VIC,-37.6246,143.8789,4
3350,Nerrina,Victoria,VIC,-37.55,143.8833,4
3350,Sovereign Hill,Victoria,VIC,-37.5677,143.8786,3
3350,Mount Clear,Victoria,VIC,-37.6,143.8833,4
3350,Canadian,Victoria,VIC,-37.5761,143.8774,4
3350,Alfredton,Victoria,VIC,-37.5667,143.8167,4
3350,Invermay Park,Victoria,VIC,-37.5306,143.858,4
3350,Eureka,Victoria,VIC,-37.5647,143.8826,4
3350,Ballarat North,Victoria,VIC,-37.5369,143.8624,3
3350,Redan,Victoria,VIC,-37.5765,143.8386,4
3350,Ballarat West,Victoria,VIC,-37.5677,143.8786,3
3350,Ballarat East,Victoria,VIC,-37.5667,143.8667,4
3350,Soldiers Hill,Victoria,VIC,-37.5496,143.8587,4
3350,Lucas,Victoria,VIC,-37.5536,143.7717,4
3350,Newington,Victoria,VIC,-37.564,143.8276,4
3351,Chepstowe,Victoria,VIC,-37.5833,143.5,4
3351,Carngham,Victoria,VIC,-37.5833,143.5833,4
3351,Springdallah,Victoria,VIC,-37.7667,143.6333,4
3351,Lake Bolac,Victoria,VIC,-37.7126,142.8396,4
3351,Streatham,Victoria,VIC,-37.6667,143.0667,4
3351,Cape Clear,Victoria,VIC,-37.7833,143.6167,4
3351,Ross Creek,Victoria,VIC,-37.65,143.75,4
3351,Newtown,Victoria,VIC,-37.7,143.65,4
3351,Snake Valley,Victoria,VIC,-37.6116,143.585,4
3351,Bo Peep,Victoria,VIC,-37.6967,143.5151,3
3351,Westmere,Victoria,VIC,-37.6833,142.9667,4
3351,Smythes Creek,Victoria,VIC,-37.6,143.7833,4
3351,Illabarook,Victoria,VIC,-37.8333,143.6667,4
3351,Mount Emu,Victoria,VIC,-37.6967,143.5151,3
3351,Staffordshire Reef,Victoria,VIC,-37.75,143.7,4
3351,Wallinduc,Victoria,VIC,-37.8667,143.5167,4
3351,Hillcrest,Victoria,VIC,-37.6167,143.6333,4
3351,Smythesdale,Victoria,VIC,-37.6431,143.6862,4
3351,Nintingbool,Victoria,VIC,-37.6167,143.7167,4
3351,Scarsdale,Victoria,VIC,-37.65,143.6667,4
3351,Berringa,Victoria,VIC,-37.7667,143.7,4
3351,Pitfield,Victoria,VIC,-37.8,143.6,4
3351,Rokewood Junction,Victoria,VIC,-37.85,143.6833,4
3351,Mininera,Victoria,VIC,-37.6,142.9667,4
3351,Piggoreet,Victoria,VIC,-37.7167,143.6333,4
3351,Haddon,Victoria,VIC,-37.6,143.7167,4
3351,Nerrin Nerrin,Victoria,VIC,-37.7667,143.0167,4
3352,Mount Rowan,Victoria,VIC,-37.5,143.85,4
3352,Mount Bolton,Victoria,VIC,-37.558,143.8518,3
3352,Bunkers Hill,Victoria,VIC,-37.5716,143.746,4
3352,Weatherboard,Victoria,VIC,-37.4333,143.6667,4
3352,Waubra,Victoria,VIC,-37.3667,143.65,4
3352,Chapel Flat,Victoria,VIC,-37.558,143.8518,3
3352,Blowhard,Victoria,VIC,-37.4333,143.8,4
3352,Gong Gong,Victoria,VIC,-37.55,143.9333,4
3352,Dereel,Victoria,VIC,-37.8167,143.7667,4
3352,Ballarat Roadside Delivery,Victoria,VIC,-37.558,143.8518,3
3352,Wattle Flat,Victoria,VIC,-37.4667,143.95,4
3352,Enfield,Victoria,VIC,-37.7281,143.7945,4
3352,Dean,Victoria,VIC,-37.45,143.9833,4
3352,Navigators,Victoria,VIC,-37.6167,143.9333,4
3352,Bolwarrah,Victoria,VIC,-37.5167,144.1,4
3352,Mount Egerton,Victoria,VIC,-37.6333,144.1,4
3352,Lamplough,Victoria,VIC,-37.1333,143.5167,4
3352,Langi Kal Kal,Victoria,VIC,-37.558,143.8518,3
3352,Clarkes Hill,Victoria,VIC,-37.4833,143.9833,4
3352,Claretown,Victoria,VIC,-37.558,143.8518,3
3352,Bullarook,Victoria,VIC,-37.5277,143.9976,4
3352,Bungaree,Victoria,VIC,-37.5667,144,4
3352,Magpie,Victoria,VIC,-37.6167,143.85,4
3352,Lexton,Victoria,VIC,-37.2667,143.5167,4
3352,Bonshaw,Victoria,VIC,-37.604,143.8226,4
3352,Ercildoune,Victoria,VIC,-37.558,143.8518,3
3352,Clarendon,Victoria,VIC,-37.7,143.9667,4
3352,Millbrook,Victoria,VIC,-37.5833,144.05,4
3352,Barkstead,Victoria,VIC,-37.45,144.1,4
3352,Cardigan,Victoria,VIC,-37.5333,143.75,4
3352,Yendon,Victoria,VIC,-37.6333,143.9667,4
3352,Miners Rest,Victoria,VIC,-37.4808,143.8011,4
3352,Garibaldi,Victoria,VIC,-37.7333,143.8833,4
3352,Glenbrae,Victoria,VIC,-37.35,143.5667,4
3352,Addington,Victoria,VIC,-37.3833,143.6833,4
3352,Wallace,Victoria,VIC,-37.55,144.05,4
3352,Lal Lal,Victoria,VIC,-37.6833,144.0333,4
3352,Mount Mercer,Victoria,VIC,-37.8167,143.85,4
3352,Durham Lead,Victoria,VIC,-37.7,143.8833,4
3352,Glen Park,Victoria,VIC,-37.558,143.8518,3
3352,Corindhap,Victoria,VIC,-37.8833,143.7333,4
3352,Grenville,Victoria,VIC,-37.7667,143.8833,4
3352,Napoleons,Victoria,VIC,-37.6667,143.8333,4
3352,Pootilla,Victoria,VIC,-37.5167,143.9667,4
3352,Burrumbeet,Victoria,VIC,-37.4667,143.6667,4
3352,Dunnstown,Victoria,VIC,-37.6,143.9833,4
3352,Sulky,Victoria,VIC,-37.4667,143.8667,4
3352,Scotchmans Lead,Victoria,VIC,-37.558,143.8518,3
3352,Cambrian Hill,Victoria,VIC,-37.6333,143.8333,4
3352,Werneth,Victoria,VIC,-37.95,143.6167,4
3352,Windermere,Victoria,VIC,-37.4833,143.7167,4
3352,Springbank,Victoria,VIC,-37.531,144.0574,4
3352,Brewster,Victoria,VIC,-37.4833,143.5167,4
3352,Mitchell Park,Victoria,VIC,-37.5069,143.7798,4
3352,Warrenheip,Victoria,VIC,-37.5667,143.9333,4
3352,Invermay,Victoria,VIC,-37.5164,143.8818,4
3352,Scotsburn,Victoria,VIC,-37.6667,143.9333,4
3352,Cardigan Village,Victoria,VIC,-37.5109,143.7062,4
3352,Learmonth,Victoria,VIC,-37.4188,143.7122,4
3352,Mollongghip,Victoria,VIC,-37.4833,144.0333,4
3352,Leigh Creek,Victoria,VIC,-37.5667,143.9667,4
3353,Ballarat,Victoria,VIC,-37.5662,143.8496,4
3354,Ballarat Mc,Victoria,VIC,-37.5987,143.7607,3
3354,Bakery Hill,Victoria,VIC,-37.5635,143.8658,4
3355,Wendouree Village,Victoria,VIC,-37.5314,143.8286,3
3355,Wendouree,Victoria,VIC,-37.55,143.8333,4
3355,Mitchell Park,Victoria,VIC,-37.5069,143.7798,4
3355,Lake Gardens,Victoria,VIC,-37.5463,143.8165,4
3356,Delacombe,Victoria,VIC,-37.5891,143.8147,4
3356,Sebastopol,Victoria,VIC,-37.5853,143.8395,4
3357,Buninyong,Victoria,VIC,-37.6502,143.8839,4
3360,Happy Valley,Victoria,VIC,-37.7167,143.6,4
3360,Pittong,Victoria,VIC,-37.6667,143.4833,4
3360,Linton,Victoria,VIC,-37.6863,143.5629,4
3360,Willowvale,Victoria,VIC,-37.8,143.4667,4
3360,Mannibadar,Victoria,VIC,-37.7667,143.5,4
3361,Carranballac,Victoria,VIC,-37.7,143.2167,4
3361,Bradvale,Victoria,VIC,-37.7833,143.4,4
3361,Skipton,Victoria,VIC,-37.686,143.3647,4
3363,Mount Beckworth,Victoria,VIC,-37.3167,143.6833,4
3363,Creswick North,Victoria,VIC,-37.3781,143.8123,3
3363,Dean,Victoria,VIC,-37.45,143.9833,4
3363,Langdons Hill,Victoria,VIC,-37.3781,143.8123,3
3363,Tourello,Victoria,VIC,-37.35,143.7833,4
3363,Creswick,Victoria,VIC,-37.424,143.8947,4
3363,Glendaruel,Victoria,VIC,-37.35,143.7167,4
3364,Kooroocheang,Victoria,VIC,-37.2833,144.0333,4
3364,Werona,Victoria,VIC,-37.25,144.0333,4
3364,Barkstead,Victoria,VIC,-37.45,144.1,4
3364,Mount Prospect,Victoria,VIC,-37.3317,143.9508,3
3364,Ascot,Victoria,VIC,-37.4,143.8,4
3364,Broomfield,Victoria,VIC,-37.3867,143.9119,4
3364,Newlyn,Victoria,VIC,-37.4167,143.9833,4
3364,Glendonald,Victoria,VIC,-37.3333,143.8333,4
3364,Rocklyn,Victoria,VIC,-37.4333,144.05,4
3364,Strathlea,Victoria,VIC,-37.1333,143.9667,4
3364,Allendale,Victoria,VIC,-37.3667,143.9333,4
3364,Cabbage Tree,Victoria,VIC,-37.6967,148.7197,4
3364,Smeaton,Victoria,VIC,-37.3333,143.95,4
3364,Blampied,Victoria,VIC,-37.3667,144.05,4
3364,Ullina,Victoria,VIC,-37.2833,143.9,4
3364,Smokeytown,Victoria,VIC,-37.3317,143.9508,3
3364,Joyces Creek,Victoria,VIC,-37.0833,144.0167,4
3364,Newlyn North,Victoria,VIC,-37.4,144,4
3364,Campbelltown,Victoria,VIC,-37.2,143.95,4
3364,Kingston,Victoria,VIC,-37.35,143.9667,4
3364,Coghills Creek,Victoria,VIC,-37.3833,143.7667,4
3364,Springmount,Victoria,VIC,-37.4167,143.9333,4
3364,Lawrence,Victoria,VIC,-37.3167,143.9,4
3364,Bald Hills,Victoria,VIC,-37.4333,143.85,4
3370,Clunes,Victoria,VIC,-37.2947,143.7864,4
3370,Mount Cameron,Victoria,VIC,-37.2649,143.8566,3
3370,Ullina,Victoria,VIC,-37.2833,143.9,4
3370,Glengower,Victoria,VIC,-37.2167,143.8833,4
3371,Lillicur,Victoria,VIC,-37.2037,143.6586,3
3371,Red Lion,Victoria,VIC,-37.2037,143.6586,3
3371,Evansford,Victoria,VIC,-37.25,143.6333,4
3371,Burnbank,Victoria,VIC,-37.2333,143.6,4
3371,Talbot,Victoria,VIC,-37.1723,143.7017,4
3371,Amherst,Victoria,VIC,-37.15,143.6667,4
3371,Dunach,Victoria,VIC,-37.2167,143.7167,4
3371,Mount Glasgow,Victoria,VIC,-37.2037,143.6586,3
3371,Stony Creek,Victoria,VIC,-38.5833,146.0333,4
3371,Caralulup,Victoria,VIC,-37.2,143.6333,4
3373,Trawalla,Victoria,VIC,-37.4333,143.4667,4
3373,Mena Park,Victoria,VIC,-37.4293,143.3908,3
3373,Lake Goldsmith,Victoria,VIC,-37.55,143.4,4
3373,Nerring,Victoria,VIC,-37.4667,143.4333,4
3373,Raglan,Victoria,VIC,-37.35,143.35,4
3373,Stoneleigh,Victoria,VIC,-37.4293,143.3908,3
3373,Waterloo,Victoria,VIC,-37.3667,143.4167,4
3373,Chute,Victoria,VIC,-37.3333,143.3833,4
3373,Main Lead,Victoria,VIC,-37.3833,143.3667,4
3373,Cross Roads,Victoria,VIC,-37.4293,143.3908,3
3373,Beaufort,Victoria,VIC,-37.43,143.3835,4
3373,Lake Wongan,Victoria,VIC,-37.4293,143.3908,3
3373,Stockyard Hill,Victoria,VIC,-37.55,143.3167,4
3374,Great Western,Victoria,VIC,-37.15,142.85,4
3375,Buangor,Victoria,VIC,-37.3667,143.1667,4
3375,Bayindeen,Victoria,VIC,-37.3167,143.1667,4
3375,Middle Creek,Victoria,VIC,-37.4,143.2333,4
3375,Ballyrogan,Victoria,VIC,-37.4333,143.1333,4
3377,Rocky Point,Victoria,VIC,-37.2626,142.9809,3
3377,Warrak,Victoria,VIC,-37.2667,143.15,4
3377,Rhymney,Victoria,VIC,-37.2667,142.7833,4
3377,Dobie,Victoria,VIC,-37.3167,143.0333,4
3377,Shays Flat,Victoria,VIC,-37.2626,142.9809,3
3377,Mount Cole,Victoria,VIC,-37.25,143.2,4
3377,Crowlands,Victoria,VIC,-37.15,143.1167,4
3377,Mount Cole Creek,Victoria,VIC,-37.2333,143.1333,4
3377,Cathcart,Victoria,VIC,-37.2626,142.9809,3
3377,Langi Logan,Victoria,VIC,-37.35,142.95,4
3377,Denicull Creek,Victoria,VIC,-37.3333,142.9,4
3377,Eversley,Victoria,VIC,-37.2,143.1667,4
3377,Bulgana,Victoria,VIC,-37.2626,142.9809,3
3377,Ararat,Victoria,VIC,-37.2854,142.9273,4
3377,Norval,Victoria,VIC,-37.2667,142.8833,4
3377,Moyston,Victoria,VIC,-37.3,142.7667,4
3377,Rossbridge,Victoria,VIC,-37.2626,142.9809,3
3377,Dunneworthy,Victoria,VIC,-37.2,143.0667,4
3377,Maroona,Victoria,VIC,-37.4167,142.8667,4
3377,Great Western,Victoria,VIC,-37.15,142.85,4
3377,Armstrong,Victoria,VIC,-37.2167,142.9,4
3378,Yalla-Y-Poora,Victoria,VIC,-37.5333,143.0667,4
3378,Tatyoon,Victoria,VIC,-37.5167,142.95,4
3379,Chatsworth,Victoria,VIC,-37.858,142.6511,4
3379,Bornes Hill,Victoria,VIC,-37.6717,142.6812,3
3379,Willaura North,Victoria,VIC,-37.6717,142.6812,3
3379,Mafeking,Victoria,VIC,-37.6717,142.6812,3
3379,Stavely,Victoria,VIC,-37.6,142.6167,4
3379,Wickliffe,Victoria,VIC,-37.6833,142.7167,4
3379,Willaura,Victoria,VIC,-37.5454,142.7404,4
3380,Stawell,Victoria,VIC,-37.0563,142.7809,4
3380,Stawell West,Victoria,VIC,-37.0563,142.7809,3
3381,Bellellen,Victoria,VIC,-37.0132,142.692,3
3381,Bellfield,Victoria,VIC,-37.7531,145.0385,4
3381,Lubeck,Victoria,VIC,-36.7333,142.5667,4
3381,Mount Dryden,Victoria,VIC,-37.0132,142.692,3
3381,Mokepilly,Victoria,VIC,-37.0132,142.692,3
3381,Black Range,Victoria,VIC,-37.0132,142.692,3
3381,Pomonal,Victoria,VIC,-37.1917,142.6076,4
3381,Lake Lonsdale,Victoria,VIC,-37.0333,142.5833,4
3381,Lake Fyans,Victoria,VIC,-37.0132,142.692,3
3381,Halls Gap,Victoria,VIC,-37.1365,142.5185,4
3381,Campbells Bridge,Victoria,VIC,-36.9333,142.7833,4
3381,Illawarra,Victoria,VIC,-37.0667,142.7,4
3381,Barkly,Victoria,VIC,-36.9333,143.2,4
3381,Fyans Creek,Victoria,VIC,-37.0864,142.5558,4
3384,Joel South,Victoria,VIC,-37.1333,143.0667,4
3384,Wattle Creek,Victoria,VIC,-36.9833,143.1167,4
3384,Landsborough,Victoria,VIC,-37.0167,143.15,4
3384,Barkly,Victoria,VIC,-36.9333,143.2,4
3384,Concongella,Victoria,VIC,-36.9972,143.1028,3
3384,Joel Joel,Victoria,VIC,-37.0167,142.9833,4
3384,Navarre,Victoria,VIC,-36.9,143.1,4
3384,Frenchmans,Victoria,VIC,-36.9972,143.1028,3
3384,Tulkara,Victoria,VIC,-36.9972,143.1028,3
3384,Landsborough West,Victoria,VIC,-36.9972,143.1028,3
3385,Lubeck,Victoria,VIC,-36.7333,142.5667,4
3385,Dadswells Bridge,Victoria,VIC,-36.9,142.5167,4
3385,Roses Gap,Victoria,VIC,-36.8733,142.6233,3
3385,Riachella,Victoria,VIC,-36.8733,142.6233,3
3385,Deep Lead,Victoria,VIC,-37.0167,142.7333,4
3385,Wal Wal,Victoria,VIC,-36.8167,142.6167,4
3385,Glenorchy,Victoria,VIC,-36.9,142.6833,4
3385,Ledcourt,Victoria,VIC,-36.8733,142.6233,3
3387,Morrl Morrl,Victoria,VIC,-36.85,142.9333,4
3387,Marnoo,Victoria,VIC,-36.65,142.8833,4
3387,Callawadda,Victoria,VIC,-36.8,142.8,4
3387,Wallaloo,Victoria,VIC,-36.7167,142.8833,4
3387,Kanya,Victoria,VIC,-36.7667,143,4
3387,Wallaloo East,Victoria,VIC,-36.7833,142.9333,4
3387,Bolangum,Victoria,VIC,-36.8042,142.8958,3
3387,Campbells Bridge,Victoria,VIC,-36.9333,142.7833,4
3387,Germania,Victoria,VIC,-36.8042,142.8958,3
3387,Greens Creek,Victoria,VIC,-36.9333,142.95,4
3387,Marnoo West,Victoria,VIC,-36.8042,142.8958,3
3388,Banyena,Victoria,VIC,-36.5667,142.8333,4
3388,Rupanyup,Victoria,VIC,-36.6285,142.6315,4
3390,Kewell,Victoria,VIC,-36.5,142.4167,4
3390,Murtoa,Victoria,VIC,-36.6201,142.4668,4
3391,Brim,Victoria,VIC,-36.0833,142.4167,4
3392,Sheep Hills,Victoria,VIC,-36.3333,142.55,4
3392,Minyip,Victoria,VIC,-36.4596,142.5878,4
3392,Boolite,Victoria,VIC,-36.35,142.6667,4
3393,Crymelon,Victoria,VIC,-36.2043,142.3909,3
3393,Kellalac,Victoria,VIC,-36.3808,142.4012,4
3393,Willenabrina,Victoria,VIC,-36.0667,142.2,4
3393,Wilkur,Victoria,VIC,-36.0667,142.6167,4
3393,Bangerang,Victoria,VIC,-36.2,142.5833,4
3393,Warracknabeal,Victoria,VIC,-36.2533,142.3928,4
3393,Lah,Victoria,VIC,-36.1333,142.45,4
3393,Aubrey,Victoria,VIC,-36.2333,142.2333,4
3393,Cannum,Victoria,VIC,-36.3,142.25,4
3395,Kenmare,Victoria,VIC,-35.9167,142.1667,4
3395,Beulah,Victoria,VIC,-35.9378,142.4222,4
3395,Reedy Dam,Victoria,VIC,-35.9667,142.65,4
3395,Rosebery,Victoria,VIC,-35.8167,142.4167,4
3396,Hopetoun,Victoria,VIC,-35.727,142.3663,4
3400,Horsham,Victoria,VIC,-36.7113,142.1998,4
3401,Kalkee,Victoria,VIC,-36.5333,142.2333,4
3401,Quantong,Victoria,VIC,-36.7333,142.0167,4
3401,Nurcoung,Victoria,VIC,-36.7333,141.6833,4
3401,Wallup,Victoria,VIC,-36.35,142.25,4
3401,Laharum,Victoria,VIC,-36.7821,142.1128,3
3401,St Helens Plains,Victoria,VIC,-36.8,142.3833,4
3401,Dooen,Victoria,VIC,-36.6667,142.2833,4
3401,Mockinya,Victoria,VIC,-36.9667,142.15,4
3401,Zumsteins,Victoria,VIC,-36.7821,142.1128,3
3401,Nurrabiel,Victoria,VIC,-36.9167,142.05,4
3401,Murra Warra,Victoria,VIC,-36.7821,142.1128,3
3401,Telangatuk East,Victoria,VIC,-37.0833,141.9667,4
3401,Drung,Victoria,VIC,-36.7167,142.3333,4
3401,Lower Norton,Victoria,VIC,-36.7667,142.1,4
3401,Haven,Victoria,VIC,-36.7607,142.1943,4
3401,Toolondo,Victoria,VIC,-36.9833,141.9333,4
3401,Kanagulk,Victoria,VIC,-37.1333,141.8667,4
3401,Longerenong,Victoria,VIC,-36.6667,142.4,4
3401,Rocklands,Victoria,VIC,-36.7717,141.9796,3
3401,Gymbowen,Victoria,VIC,-36.7333,141.6,4
3401,Vectis,Victoria,VIC,-36.7821,142.1128,3
3401,Wartook,Victoria,VIC,-37.0239,142.3385,4
3401,Riverside,Victoria,VIC,-36.7717,141.9796,3
3401,Brimpaen,Victoria,VIC,-37.0333,142.2,4
3401,Cherrypool,Victoria,VIC,-37.1,142.1833,4
3401,Blackheath,Victoria,VIC,-36.4667,142.25,4
3401,Wonwondah,Victoria,VIC,-36.7821,142.1128,3
3401,Mckenzie Creek,Victoria,VIC,-36.8,142.1833,4
3401,Bungalally,Victoria,VIC,-36.8,142.25,4
3401,Karnak,Victoria,VIC,-36.8667,141.5,4
3401,Jung,Victoria,VIC,-36.6167,142.3667,4
3401,Wail,Victoria,VIC,-36.5,142.1,4
3401,Pimpinio,Victoria,VIC,-36.5833,142.1167,4
3402,Horsham,Victoria,VIC,-36.7113,142.1998,4
3407,Englefield,Victoria,VIC,-37.3167,141.8333,4
3407,Vasey,Victoria,VIC,-37.3667,141.8833,4
3407,Balmoral,Victoria,VIC,-37.25,141.85,4
3407,Pigeon Ponds,Victoria,VIC,-37.3,141.6833,4
3407,Gatum,Victoria,VIC,-37.4167,141.9333,4
3409,Natimuk,Victoria,VIC,-36.7403,141.9452,4
3409,Grass Flat,Victoria,VIC,-36.86,141.7958,3
3409,Clear Lake,Victoria,VIC,-36.9333,141.8667,4
3409,Arapiles,Victoria,VIC,-36.7333,141.85,4
3409,Mitre,Victoria,VIC,-36.7333,141.7833,4
3409,Duchembegarra,Victoria,VIC,-36.86,141.7958,3
3409,Noradjuha,Victoria,VIC,-36.8333,141.9667,4
3409,Douglas,Victoria,VIC,-37.0333,141.7333,4
3409,Wombelano,Victoria,VIC,-37,141.6,4
3409,Jilpanger,Victoria,VIC,-36.86,141.7958,3
3409,Tooan,Victoria,VIC,-36.8,141.7833,4
3409,Miga Lake,Victoria,VIC,-36.9333,141.6333,4
3412,Goroke,Victoria,VIC,-36.7179,141.4731,4
3413,Neuarpurr,Victoria,VIC,-36.7667,141.0833,4
3413,Ozenkadnook,Victoria,VIC,-36.85,141.3333,4
3413,Minimay,Victoria,VIC,-36.7167,141.1833,4
3413,Peronne,Victoria,VIC,-36.7333,141.3833,4
3414,Antwerp,Victoria,VIC,-36.3,142.05,4
3414,Dimboola,Victoria,VIC,-36.4562,142.0329,4
3414,Tarranyurk,Victoria,VIC,-36.2167,142.05,4
3415,Miram,Victoria,VIC,-36.3667,141.35,4
3418,Gerang Gerung,Victoria,VIC,-36.3594,141.8555,4
3418,Nhill,Victoria,VIC,-36.3333,141.6501,4
3418,Kiata,Victoria,VIC,-36.3667,141.8,4
3418,Lawloit,Victoria,VIC,-36.4167,141.4667,4
3418,Broughton,Victoria,VIC,-36.1667,141.35,4
3418,Lorquon,Victoria,VIC,-36.1667,141.75,4
3418,Little Desert,Victoria,VIC,-36.4956,141.7474,4
3418,Yanac,Victoria,VIC,-36.1333,141.4333,4
3418,Glenlee,Victoria,VIC,-36.2167,141.85,4
3418,Netherby,Victoria,VIC,-36.1167,141.65,4
3419,Kaniva,Victoria,VIC,-36.3786,141.2422,4
3420,Telopea Downs,Victoria,VIC,-36.375,141.0583,3
3420,Serviceton,Victoria,VIC,-36.3833,140.9833,4
3420,Lillimur,Victoria,VIC,-36.3667,141.1333,4
3423,Jeparit,Victoria,VIC,-36.1425,141.9882,4
3424,Yaapeet,Victoria,VIC,-35.7667,142.05,4
3424,Albacutya,Victoria,VIC,-35.8167,142,4
3424,Rainbow,Victoria,VIC,-35.8958,141.9979,4
3427,Diggers Rest,Victoria,VIC,-37.626,144.7188,4
3428,Bulla,Victoria,VIC,-37.6384,144.8074,4
3429,Sunbury,Victoria,VIC,-37.5774,144.7261,4
3429,Wildwood,Victoria,VIC,-37.5735,144.7881,4
3430,Clarkefield,Victoria,VIC,-37.4833,144.75,4
3431,Riddells Creek,Victoria,VIC,-37.4605,144.6796,4
3432,Bolinda,Victoria,VIC,-37.4333,144.75,4
3433,Monegeetta,Victoria,VIC,-37.4167,144.75,4
3434,Romsey,Victoria,VIC,-37.3507,144.743,4
3434,Cherokee,Victoria,VIC,-37.342,144.7798,3
3434,Springfield,Victoria,VIC,-37.3333,144.8167,4
3434,Kerrie,Victoria,VIC,-37.3684,144.754,3
3435,Lancefield,Victoria,VIC,-37.2773,144.736,4
3435,Goldie,Victoria,VIC,-37.247,144.718,3
3435,Benloch,Victoria,VIC,-37.2167,144.7,4
3435,Nulla Vale,Victoria,VIC,-37.247,144.718,3
3437,Bullengarook,Victoria,VIC,-37.5167,144.4833,4
3437,Gisborne South,Victoria,VIC,-37.5026,144.5388,3
3437,Gisborne,Victoria,VIC,-37.4886,144.5942,4
3438,New Gisborne,Victoria,VIC,-37.4428,144.6184,1
3440,Macedon,Victoria,VIC,-37.4196,144.5624,4
3441,Mount Macedon,Victoria,VIC,-37.4005,144.5841,4
3442,Hesket,Victoria,VIC,-37.35,144.65,4
3442,Ashbourne,Victoria,VIC,-37.3355,144.5399,3
3442,Rochford,Victoria,VIC,-37.3167,144.6833,4
3442,Carlsruhe,Victoria,VIC,-37.2833,144.5,4
3442,Woodend,Victoria,VIC,-37.3547,144.529,4
3442,Cadello,Victoria,VIC,-37.3174,144.5993,3
3442,Newham,Victoria,VIC,-37.3333,144.6,4
3442,Cobaw,Victoria,VIC,-37.2667,144.6333,4
3442,Woodend North,Victoria,VIC,-37.3174,144.5993,3
3444,Barfold,Victoria,VIC,-37.0833,144.5167,4
3444,Pastoria,Victoria,VIC,-37.1538,144.4934,3
3444,Lyal,Victoria,VIC,-36.95,144.4833,4
3444,Redesdale,Victoria,VIC,-37.0167,144.5167,4
3444,Langley,Victoria,VIC,-37.15,144.5,4
3444,Tylden South,Victoria,VIC,-37.1538,144.4934,3
3444,Kyneton,Victoria,VIC,-37.2444,144.4515,4
3444,Glenhope,Victoria,VIC,-37.0833,144.6,4
3444,Tylden,Victoria,VIC,-37.3258,144.4062,4
3444,Greenhill,Victoria,VIC,-37.1538,144.4934,3
3444,Baynton,Victoria,VIC,-37.1492,144.6337,4
3444,Pipers Creek,Victoria,VIC,-37.25,144.5333,4
3444,Mia Mia,Victoria,VIC,-37,144.5667,4
3444,Metcalfe East,Victoria,VIC,-37.1538,144.4934,3
3444,Lauriston,Victoria,VIC,-37.25,144.3833,4
3444,Edgecombe,Victoria,VIC,-37.1833,144.4333,4
3444,Sidonia,Victoria,VIC,-37.1667,144.55,4
3444,Baynton East,Victoria,VIC,-37.1538,144.4934,3
3444,Spring Hill,Victoria,VIC,-37.3,144.3333,4
3444,Kyneton South,Victoria,VIC,-37.1538,144.4934,3
3444,Pastoria East,Victoria,VIC,-37.1538,144.4934,3
3446,Malmsbury,Victoria,VIC,-37.1886,144.3847,4
3446,Drummond North,Victoria,VIC,-37.189,144.3822,3
3447,Taradale,Victoria,VIC,-37.15,144.3667,4
3448,Sutton Grange,Victoria,VIC,-36.9833,144.3667,4
3448,Elphinstone,Victoria,VIC,-37.1,144.3333,4
3448,Metcalfe,Victoria,VIC,-37.1,144.4333,4
3450,Moonlight Flat,Victoria,VIC,-37.0671,144.2168,3
3450,Castlemaine,Victoria,VIC,-37.0671,144.2168,4
3451,Gower,Victoria,VIC,-37.1426,144.1861,3
3451,Mckenzie Hill,Victoria,VIC,-37.1426,144.1861,3
3451,Campbells Creek,Victoria,VIC,-37.0867,144.202,4
3451,Golden Point,Victoria,VIC,-37.5749,143.8687,4
3451,Barkers Creek,Victoria,VIC,-37.0167,144.25,4
3451,Muckleford,Victoria,VIC,-37.0167,144.15,4
3451,Guildford,Victoria,VIC,-37.1667,144.1667,4
3451,Yapeen,Victoria,VIC,-37.1333,144.1833,4
3451,Chewton Bushlands,Victoria,VIC,-37.1426,144.1861,3
3451,Faraday,Victoria,VIC,-37.05,144.3,4
3451,Vaughan,Victoria,VIC,-37.1426,144.1861,3
3451,Glenluce,Victoria,VIC,-37.1667,144.2333,4
3451,Chewton,Victoria,VIC,-37.0814,144.2568,4
3451,Fryerstown,Victoria,VIC,-37.1333,144.25,4
3451,Tarilta,Victoria,VIC,-37.1426,144.1861,3
3451,Irishtown,Victoria,VIC,-37.1426,144.1861,3
3453,Ravenswood,Victoria,VIC,-36.9,144.2167,4
3453,Harcourt North,Victoria,VIC,-36.9521,144.2382,3
3453,Harcourt,Victoria,VIC,-36.9998,144.2599,4
3453,Ravenswood South,Victoria,VIC,-36.9566,144.2381,4
3458,Newbury,Victoria,VIC,-37.4167,144.3167,4
3458,Trentham,Victoria,VIC,-37.3888,144.3216,4
3458,Fern Hill,Victoria,VIC,-37.3667,144.4,4
3458,Trentham East,Victoria,VIC,-37.3833,144.3833,4
3458,Blackwood,Victoria,VIC,-37.4722,144.3034,4
3458,Lerderderg,Victoria,VIC,-37.3823,144.3389,3
3458,Little Hampton,Victoria,VIC,-37.4157,144.3378,3
3458,Barrys Reef,Victoria,VIC,-37.454,144.2964,4
3458,North Blackwood,Victoria,VIC,-37.4157,144.3378,3
3460,Daylesford,Victoria,VIC,-37.3411,144.1426,4
3460,Basalt,Victoria,VIC,-37.3411,144.1426,3
3461,Korweinguboora,Victoria,VIC,-37.45,144.1333,4
3461,Strangways,Victoria,VIC,-37.15,144.1167,4
3461,Hepburn Springs,Victoria,VIC,-37.3163,144.1379,4
3461,Porcupine Ridge,Victoria,VIC,-37.3268,144.1641,3
3461,Musk Vale,Victoria,VIC,-37.3833,144.1333,4
3461,Eganstown,Victoria,VIC,-37.3667,144.0833,4
3461,Yandoit,Victoria,VIC,-37.2,144.1,4
3461,Elevated Plains,Victoria,VIC,-37.3268,144.1641,3
3461,Hepburn,Victoria,VIC,-37.3167,144.1333,4
3461,Leonards Hill,Victoria,VIC,-37.4333,144.1167,4
3461,Spargo Creek,Victoria,VIC,-37.4833,144.15,4
3461,Sailors Hill,Victoria,VIC,-37.3268,144.1641,3
3461,Franklinford,Victoria,VIC,-37.2333,144.1,4
3461,Yandoit Hills,Victoria,VIC,-37.3268,144.1641,3
3461,Drummond,Victoria,VIC,-37.2333,144.3333,4
3461,Bullarto South,Victoria,VIC,-37.3268,144.1641,3
3461,Musk,Victoria,VIC,-37.3833,144.1333,4
3461,Denver,Victoria,VIC,-37.2667,144.3,4
3461,Sailors Falls,Victoria,VIC,-37.4,144.1333,4
3461,Mount Franklin,Victoria,VIC,-37.3268,144.1641,3
3461,Lyonville,Victoria,VIC,-37.3833,144.2667,4
3461,Shepherds Flat,Victoria,VIC,-37.3268,144.1641,3
3461,Glenlyon,Victoria,VIC,-37.3,144.25,4
3461,Dry Diggings,Victoria,VIC,-37.3268,144.1641,3
3461,Bullarto,Victoria,VIC,-37.4,144.2333,4
3461,Coomoora,Victoria,VIC,-37.3268,144.1641,3
3461,Wheatsheaf,Victoria,VIC,-37.3268,144.1641,3
3461,Clydesdale,Victoria,VIC,-37.1833,144.1,4
3462,Newstead,Victoria,VIC,-37.106,144.0647,4
3462,Muckleford South,Victoria,VIC,-37.1,144.1333,4
3462,Sandon,Victoria,VIC,-37.0909,144.0827,3
3462,Welshmans Reef,Victoria,VIC,-37.0667,144.05,4
3462,Green Gully,Victoria,VIC,-37.0909,144.0827,3
3463,Maldon,Victoria,VIC,-36.9957,144.0683,4
3463,Laanecoorie,Victoria,VIC,-36.8167,143.9,4
3463,Baringhup,Victoria,VIC,-36.9833,143.9667,4
3463,Bradford,Victoria,VIC,-36.916,144.0058,3
3463,Walmer,Victoria,VIC,-36.9667,144.15,4
3463,Baringhup West,Victoria,VIC,-36.916,144.0058,3
3463,Eastville,Victoria,VIC,-36.85,143.9333,4
3463,Tarrengower,Victoria,VIC,-36.916,144.0058,3
3463,Nuggetty,Victoria,VIC,-36.916,144.0058,3
3463,Woodstock West,Victoria,VIC,-36.9317,144.0176,3
3463,Neereman,Victoria,VIC,-36.916,144.0058,3
3463,Shelbourne,Victoria,VIC,-36.8833,144.0167,4
3464,Carisbrook,Victoria,VIC,-37.0481,143.8167,4
3465,Havelock,Victoria,VIC,-36.9667,143.7833,4
3465,Maryborough,Victoria,VIC,-37.0456,143.7392,4
3465,Timor West,Victoria,VIC,-36.9667,143.7,4
3465,Wareek,Victoria,VIC,-36.9833,143.6167,4
3465,Cotswold,Victoria,VIC,-37.1333,143.9,4
3465,Homebush,Victoria,VIC,-37.05,143.5333,4
3465,Moonlight Flat,Victoria,VIC,-37.0633,143.6975,3
3465,Bung Bong,Victoria,VIC,-37.0667,143.6167,4
3465,Moolort,Victoria,VIC,-37.0833,143.9333,4
3465,Bowenvale,Victoria,VIC,-37,143.7167,4
3465,Rathscar,Victoria,VIC,-36.9667,143.5667,4
3465,Daisy Hill,Victoria,VIC,-37.1167,143.7167,4
3465,Natte Yallock,Victoria,VIC,-36.95,143.5,4
3465,Timor,Victoria,VIC,-36.9859,143.7139,4
3465,Simson,Victoria,VIC,-37.0301,143.7073,3
3465,Alma,Victoria,VIC,-37.0333,143.6833,4
3465,Majorca,Victoria,VIC,-37.1167,143.8,4
3465,Adelaide Lead,Victoria,VIC,-37.0667,143.6833,4
3465,Flagstaff,Victoria,VIC,-37.0633,143.6975,3
3465,Rathscar West,Victoria,VIC,-37.0333,143.4833,4
3465,Craigie,Victoria,VIC,-37.0301,143.7073,3
3465,Golden Point,Victoria,VIC,-37.5749,143.8687,4
3467,Avoca,Victoria,VIC,-37.0904,143.4736,4
3468,Mount Lonarch,Victoria,VIC,-37.25,143.4,4
3468,Amphitheatre,Victoria,VIC,-37.1667,143.4167,4
3469,Nowhere Creek,Victoria,VIC,-37.1389,143.2611,3
3469,Elmhurst,Victoria,VIC,-37.1667,143.25,4
3469,Glenlogie,Victoria,VIC,-37.1389,143.2611,3
3469,Glenpatrick,Victoria,VIC,-37.1333,143.3167,4
3469,Glenlofty,Victoria,VIC,-37.1167,143.2167,4
3472,Bet Bet,Victoria,VIC,-36.9167,143.75,4
3472,Eddington,Victoria,VIC,-36.8833,143.85,4
3472,Bromley,Victoria,VIC,-36.8833,143.7667,4
3472,Betley,Victoria,VIC,-36.9,143.8,4
3472,Moliagul,Victoria,VIC,-36.75,143.6667,4
3472,Goldsborough,Victoria,VIC,-36.8167,143.6833,4
3472,Dunluce,Victoria,VIC,-36.9,143.6,4
3472,Inkerman,Victoria,VIC,-36.9148,143.6487,3
3472,Dunolly,Victoria,VIC,-36.8601,143.7336,4
3472,Mount Hooghly,Victoria,VIC,-36.9,143.6667,4
3472,Mcintyre,Victoria,VIC,-36.8678,143.7241,3
3475,Emu,Victoria,VIC,-36.7333,143.4667,4
3475,Logan,Victoria,VIC,-36.6167,143.4667,4
3475,Cochranes Creek,Victoria,VIC,-36.7267,143.51,3
3475,Archdale,Victoria,VIC,-36.8333,143.5167,4
3475,Archdale Junction,Victoria,VIC,-36.7267,143.51,3
3475,Burkes Flat,Victoria,VIC,-36.65,143.55,4
3475,Bealiba,Victoria,VIC,-36.8,143.55,4
3477,Traynors Lagoon,Victoria,VIC,-36.5833,143,4
3477,Grays Bridge,Victoria,VIC,-36.6653,143.2081,3
3477,Redbank,Victoria,VIC,-36.9333,143.3167,4
3477,Rostron,Victoria,VIC,-36.7667,143.1833,4
3477,Gowar East,Victoria,VIC,-36.55,143.4167,4
3477,Coonooer West,Victoria,VIC,-36.6653,143.2081,3
3477,Paradise,Victoria,VIC,-36.8333,143.1333,4
3477,Stuart Mill,Victoria,VIC,-36.8053,143.2862,4
3477,Gre Gre North,Victoria,VIC,-36.5833,143.0667,4
3477,Gre Gre South,Victoria,VIC,-36.6653,143.2081,3
3477,Avon Plains,Victoria,VIC,-36.55,142.9333,4
3477,Moyreisk,Victoria,VIC,-36.9167,143.3833,4
3477,St Arnaud North,Victoria,VIC,-36.6653,143.2081,3
3477,Marnoo East,Victoria,VIC,-36.6653,143.2081,3
3477,Carapooee West,Victoria,VIC,-36.6653,143.2081,3
3477,Beazleys Bridge,Victoria,VIC,-36.7,143.1667,4
3477,Gre Gre,Victoria,VIC,-36.6667,143.0667,4
3477,York Plains,Victoria,VIC,-36.6653,143.2081,3
3477,Carapooee,Victoria,VIC,-36.7,143.3333,4
3477,Gooroc,Victoria,VIC,-36.4667,143.2,4
3477,Winjallok,Victoria,VIC,-36.8167,143.2,4
3477,St Arnaud East,Victoria,VIC,-36.6653,143.2081,3
3477,Coonooer Bridge,Victoria,VIC,-36.4667,143.3167,4
3477,Moolerr,Victoria,VIC,-36.6333,143.2167,4
3477,Swanwater,Victoria,VIC,-36.4833,143.1333,4
3477,Dalyenong,Victoria,VIC,-36.6653,143.2081,3
3477,Sutherland,Victoria,VIC,-36.55,143.2,4
3477,Tottington,Victoria,VIC,-36.7833,143.1167,4
3477,Slaty Creek,Victoria,VIC,-36.55,143.3,4
3477,Kooreh,Victoria,VIC,-36.6333,143.4,4
3478,Redbank,Victoria,VIC,-36.9333,143.3167,4
3478,Percydale,Victoria,VIC,-37.05,143.4,4
3478,Stuart Mill,Victoria,VIC,-36.8053,143.2862,4
3478,Warrenmang,Victoria,VIC,-37.0333,143.3,4
3478,Medlyn,Victoria,VIC,-36.827,143.2969,3
3478,Moonambel,Victoria,VIC,-36.9833,143.3167,4
3478,St Arnaud,Victoria,VIC,-36.6168,143.2587,4
3478,Yawong Hills,Victoria,VIC,-36.827,143.2969,3
3478,Tanwood,Victoria,VIC,-36.827,143.2969,3
3478,Dooboobetic,Victoria,VIC,-36.3667,143.2,4
3480,Carron,Victoria,VIC,-36.3,142.7167,4
3480,Lawler,Victoria,VIC,-36.3668,142.9058,3
3480,Areegra,Victoria,VIC,-36.2833,142.6333,4
3480,Corack East,Victoria,VIC,-36.1167,143.05,4
3480,Donald,Victoria,VIC,-36.3686,142.9811,4
3480,Jeffcott North,Victoria,VIC,-36.3668,142.9058,3
3480,Laen North,Victoria,VIC,-36.4,142.8167,4
3480,Cope Cope,Victoria,VIC,-36.45,143.05,4
3480,Corack,Victoria,VIC,-36.1667,143.05,4
3480,Jeffcott,Victoria,VIC,-36.35,143.1333,4
3480,Laen East,Victoria,VIC,-36.3833,142.8833,4
3480,Litchfield,Victoria,VIC,-36.3,142.8667,4
3480,Swanwater West,Victoria,VIC,-36.5333,143.0167,4
3480,Laen,Victoria,VIC,-36.45,142.8167,4
3480,Rich Avon,Victoria,VIC,-36.5167,142.8333,4
3480,Rich Avon East,Victoria,VIC,-36.3668,142.9058,3
3480,Rich Avon West,Victoria,VIC,-36.5167,142.8333,4
3480,Gil Gil,Victoria,VIC,-36.3668,142.9058,3
3482,Warmur,Victoria,VIC,-36.1167,142.7833,4
3482,Massey,Victoria,VIC,-36.2167,142.8667,4
3482,Watchem,Victoria,VIC,-36.15,142.85,4
3482,Morton Plains,Victoria,VIC,-36.0833,142.9,4
3482,Watchem West,Victoria,VIC,-36.1417,142.85,3
3483,Narraport,Victoria,VIC,-36,143.0333,4
3483,Birchip,Victoria,VIC,-35.9838,142.9173,4
3483,Kinnabulla,Victoria,VIC,-35.8833,142.8,4
3483,Karyrie,Victoria,VIC,-35.9,142.85,4
3483,Birchip West,Victoria,VIC,-35.9063,142.8876,3
3483,Whirily,Victoria,VIC,-35.8667,142.9667,4
3483,Ballapur,Victoria,VIC,-35.9667,142.7667,4
3483,Curyo,Victoria,VIC,-35.8333,142.8,4
3483,Marlbed,Victoria,VIC,-35.9063,142.8876,3
3483,Jil Jil,Victoria,VIC,-35.8167,142.9667,4
3485,Woomelang,Victoria,VIC,-35.6811,142.6617,4
3485,Willangie,Victoria,VIC,-35.65,142.85,4
3485,Banyan,Victoria,VIC,-35.6333,142.75,4
3485,Watchupga,Victoria,VIC,-35.75,142.7167,4
3487,Lascelles,Victoria,VIC,-35.6167,142.5833,4
3488,Turriff East,Victoria,VIC,-35.4,142.5333,4
3488,Speed,Victoria,VIC,-35.4,142.4333,4
3488,Turriff,Victoria,VIC,-35.45,142.45,4
3489,Tempy,Victoria,VIC,-35.3333,142.4167,4
3490,Mittyack,Victoria,VIC,-35.15,142.65,4
3490,Murray-Sunset,Victoria,VIC,-34.751,141.5034,4
3490,Torrita,Victoria,VIC,-35.15,141.9333,4
3490,Kulwin,Victoria,VIC,-35.0333,142.6167,4
3490,Boinka,Victoria,VIC,-35.2,141.6,4
3490,Big Desert,Victoria,VIC,-35.0817,142.0153,3
3490,Ouyen,Victoria,VIC,-35.0709,142.3203,4
3490,Tutye,Victoria,VIC,-35.2167,141.4833,4
3491,Patchewollock,Victoria,VIC,-35.3833,142.2,4
3494,Nangiloc,Victoria,VIC,-34.5,142.2916,3
3494,Iraak,Victoria,VIC,-34.4304,142.354,4
3494,Carwarp,Victoria,VIC,-34.4602,142.199,4
3494,Colignan,Victoria,VIC,-34.5248,142.3656,4
3496,Sunnycliffs,Victoria,VIC,-34.5668,141.5741,3
3496,Cullulleraine,Victoria,VIC,-34.278,141.5994,4
3496,Werrimull,Victoria,VIC,-34.3887,141.5993,4
3496,Red Cliffs,Victoria,VIC,-34.3094,142.1897,4
3496,Merrinee,Victoria,VIC,-34.3188,141.7502,3
3496,Cardross,Victoria,VIC,-34.3071,142.1253,4
3496,Meringur,Victoria,VIC,-34.3886,141.3343,4
3496,Neds Corner,Victoria,VIC,-34.1425,141.3304,4
3496,Lindsay Point,Victoria,VIC,-34.3188,141.7502,3
3498,Irymple,Victoria,VIC,-34.2354,142.171,4
3500,Mildura West,Victoria,VIC,-34.1631,142.0026,4
3500,Mildura,Victoria,VIC,-34.1855,142.1625,4
3501,Mildura Centre Plaza,Victoria,VIC,-34.6355,142.3253,3
3501,Nichols Point,Victoria,VIC,-34.2121,142.2144,4
3501,Hattah,Victoria,VIC,-34.7625,142.2815,4
3501,Koorlong,Victoria,VIC,-34.2776,142.0874,4
3501,Mildura South,Victoria,VIC,-34.4207,142.1937,3
3502,Mildura,Victoria,VIC,-34.1855,142.1625,4
3505,Wargan,Victoria,VIC,-34.1853,142.0476,3
3505,Birdwoodton,Victoria,VIC,-34.2012,142.0697,4
3505,Merbein,Victoria,VIC,-34.168,142.066,4
3505,Cabarita,Victoria,VIC,-34.1975,142.0845,4
3505,Merbein West,Victoria,VIC,-34.1631,142.0026,4
3505,Yelta,Victoria,VIC,-34.1284,142.0152,4
3505,Merbein South,Victoria,VIC,-34.2333,142.0333,4
3506,Cowangie,Victoria,VIC,-35.2333,141.3667,4
3507,Walpeup,Victoria,VIC,-35.1333,142.0333,4
3509,Linga,Victoria,VIC,-35.1667,141.7,4
3509,Underbool,Victoria,VIC,-35.1695,141.8056,4
3512,Carina,Victoria,VIC,-35.25,141.1,4
3512,Murrayville,Victoria,VIC,-35.2625,141.1835,4
3512,Panitya,Victoria,VIC,-35.2333,141,4
3515,Shelbourne,Victoria,VIC,-36.8833,144.0167,4
3515,Wilsons Hill,Victoria,VIC,-36.8083,144.075,3
3515,Marong,Victoria,VIC,-36.7333,144.1333,4
3516,Bridgewater On Loddon,Victoria,VIC,-36.6027,143.9423,4
3516,Derby,Victoria,VIC,-36.65,144.0167,4
3516,Yarraberb,Victoria,VIC,-36.6167,144.0667,4
3516,Leichardt,Victoria,VIC,-36.6833,144.0667,4
3516,Bridgewater,Victoria,VIC,-36.6027,143.9423,4
3516,Bridgewater North,Victoria,VIC,-36.6311,144.0069,3
3517,Glenalbyn,Victoria,VIC,-36.5167,143.7333,4
3517,Jarklin,Victoria,VIC,-36.2667,143.9667,4
3517,Serpentine,Victoria,VIC,-36.4333,143.9833,4
3517,Kurting,Victoria,VIC,-36.5333,143.85,4
3517,Powlett Plains,Victoria,VIC,-36.45,143.8667,4
3517,Salisbury West,Victoria,VIC,-36.5167,143.9667,4
3517,Inglewood,Victoria,VIC,-36.575,143.8683,4
3517,Bears Lagoon,Victoria,VIC,-36.328,143.9755,4
3517,Rheola,Victoria,VIC,-36.65,143.7,4
3517,Brenanah,Victoria,VIC,-36.4886,143.866,3
3517,Kingower,Victoria,VIC,-36.6167,143.75,4
3518,Richmond Plains,Victoria,VIC,-36.3833,143.45,4
3518,Skinners Flat,Victoria,VIC,-36.4161,143.6325,3
3518,Borung,Victoria,VIC,-36.3,143.75,4
3518,Kurraca West,Victoria,VIC,-36.5,143.5667,4
3518,Fernihurst,Victoria,VIC,-36.25,143.8667,4
3518,Woolshed Flat,Victoria,VIC,-36.4161,143.6325,3
3518,Berrimal,Victoria,VIC,-36.4833,143.4833,4
3518,Wedderburn Junction,Victoria,VIC,-36.4167,143.7,4
3518,Mysia,Victoria,VIC,-36.25,143.7667,4
3518,Wehla,Victoria,VIC,-36.7833,143.6,4
3518,Nine Mile,Victoria,VIC,-36.4333,143.4833,4
3518,Fentons Creek,Victoria,VIC,-36.5667,143.5333,4
3518,Woosang,Victoria,VIC,-36.3333,143.4833,4
3518,Kurraca,Victoria,VIC,-36.5,143.5667,4
3518,Fiery Flat,Victoria,VIC,-36.4,143.8,4
3518,Wedderburn,Victoria,VIC,-36.419,143.6139,4
3520,Korong Vale,Victoria,VIC,-36.3561,143.7034,4
3520,Kinypanial,Victoria,VIC,-36.35,143.8,4
3521,Pyalong,Victoria,VIC,-37.1129,144.8523,4
3522,Tooborac,Victoria,VIC,-37.05,144.8333,4
3522,Glenhope East,Victoria,VIC,-37.05,144.8333,3
3523,Costerfield,Victoria,VIC,-36.8667,144.8,4
3523,Derrinal,Victoria,VIC,-36.8833,144.6333,4
3523,Argyle,Victoria,VIC,-36.95,144.7333,4
3523,Moormbool West,Victoria,VIC,-36.8,144.9,4
3523,Redcastle,Victoria,VIC,-36.7833,144.7833,4
3523,Heathcote,Victoria,VIC,-36.9221,144.7084,4
3523,Ladys Pass,Victoria,VIC,-36.8627,144.7345,3
3523,Knowsley,Victoria,VIC,-36.8333,144.5833,4
3523,Heathcote South,Victoria,VIC,-36.8627,144.7345,3
3523,Mount Camel,Victoria,VIC,-36.8627,144.7345,3
3525,Wychitella North,Victoria,VIC,-36.2726,143.4353,3
3525,Charlton,Victoria,VIC,-36.2644,143.349,4
3525,Buckrabanyule,Victoria,VIC,-36.25,143.5167,4
3525,Granite Flat,Victoria,VIC,-36.2726,143.4353,3
3525,Wooroonook,Victoria,VIC,-36.25,143.2,4
3525,Yeungroon,Victoria,VIC,-36.3833,143.4,4
3525,Barrakee,Victoria,VIC,-36.2667,143.45,4
3525,Terrappee,Victoria,VIC,-36.2726,143.4353,3
3525,Wychitella,Victoria,VIC,-36.2833,143.6167,4
3525,Yeungroon East,Victoria,VIC,-36.35,143.4333,4
3525,Chirrip,Victoria,VIC,-36.2726,143.4353,3
3525,Lake Marmal,Victoria,VIC,-36.132,143.516,4
3525,Nareewillock,Victoria,VIC,-36.2726,143.4353,3
3527,Jeruk,Victoria,VIC,-36.05,143.3833,4
3527,Bunguluke,Victoria,VIC,-36.0561,143.3904,4
3527,Thalia,Victoria,VIC,-36.0667,143.0833,4
3527,Ninyeunook,Victoria,VIC,-35.9871,143.4019,4
3527,Towaninny South,Victoria,VIC,-35.9229,143.3527,4
3527,Glenloth,Victoria,VIC,-36.1451,143.3388,4
3527,Towaninny,Victoria,VIC,-35.9229,143.3527,4
3527,Wycheproof South,Victoria,VIC,-36.0563,143.2848,3
3527,Dumosa,Victoria,VIC,-35.936,143.2245,4
3527,Teddywaddy West,Victoria,VIC,-36.0563,143.2848,3
3527,Wycheproof,Victoria,VIC,-36.0776,143.2271,4
3527,Glenloth East,Victoria,VIC,-36.0563,143.2848,3
3527,Teddywaddy,Victoria,VIC,-36.1937,143.3374,4
3529,Nullawil,Victoria,VIC,-35.85,143.1833,4
3529,Kalpienung,Victoria,VIC,-35.7826,143.2558,4
3530,Warne,Victoria,VIC,-35.7808,143.1242,4
3530,Wangie,Victoria,VIC,-35.725,143.0417,3
3530,Sutton,Victoria,VIC,-35.7333,143,4
3530,Culgoa,Victoria,VIC,-35.7167,143.0833,4
3531,Boigbeat,Victoria,VIC,-35.55,142.9167,4
3531,Berriwillock,Victoria,VIC,-35.6333,143,4
3533,Sea Lake,Victoria,VIC,-35.5039,142.8498,4
3533,Lake Tyrrell,Victoria,VIC,-35.4244,142.9857,3
3533,Ninda,Victoria,VIC,-35.4667,142.75,4
3533,Nyarrin,Victoria,VIC,-35.3833,142.6833,4
3533,Pier Milan,Victoria,VIC,-35.4244,142.9857,3
3533,Straten,Victoria,VIC,-35.4244,142.9857,3
3533,Nandaly,Victoria,VIC,-35.3,142.7,4
3533,Tyenna,Victoria,VIC,-35.4244,142.9857,3
3533,Tyrrell,Victoria,VIC,-35.3667,142.9667,4
3533,Bimbourie,Victoria,VIC,-35.4244,142.9857,3
3533,Tyrrell Downs,Victoria,VIC,-35.3667,142.9667,4
3533,Myall,Victoria,VIC,-35.581,144.0013,4
3537,Canary Island,Victoria,VIC,-35.9917,143.843,4
3537,Barraport West,Victoria,VIC,-36.0308,143.7505,3
3537,Gredgwin,Victoria,VIC,-35.9694,143.6191,4
3537,Leaghur,Victoria,VIC,-35.9637,143.7847,4
3537,Minmindie,Victoria,VIC,-36.0402,143.7511,4
3537,Catumnal,Victoria,VIC,-36.0308,143.7505,3
3537,Barraport,Victoria,VIC,-36.0078,143.6709,4
3537,Yando,Victoria,VIC,-36.0789,143.8058,4
3537,Boort,Victoria,VIC,-36.1157,143.7199,4
3540,Oakvale,Victoria,VIC,-35.9109,143.5635,4
3540,Quambatook,Victoria,VIC,-35.8524,143.5207,4
3540,Cannie,Victoria,VIC,-35.754,143.4485,4
3542,Lalbert,Victoria,VIC,-35.6672,143.3779,4
3542,Tittybong,Victoria,VIC,-35.7466,143.372,4
3542,Cokum,Victoria,VIC,-35.725,143.3667,3
3544,Gowanford,Victoria,VIC,-35.4187,143.1916,4
3544,Ultima East,Victoria,VIC,-35.4222,143.1778,3
3544,Waitchie,Victoria,VIC,-35.3667,143.0833,4
3544,Chinangin,Victoria,VIC,-35.4222,143.1778,3
3544,Springfield,Victoria,VIC,-37.3333,144.8167,4
3544,Murnungin,Victoria,VIC,-35.4222,143.1778,3
3544,Ultima,Victoria,VIC,-35.468,143.2709,4
3546,Chinkapook,Victoria,VIC,-35.1891,142.9431,4
3546,Gerahmin,Victoria,VIC,-35.0977,142.9331,3
3546,Turoar,Victoria,VIC,-35.1667,143.05,4
3546,Manangatang,Victoria,VIC,-35.0551,142.8823,4
3546,Cocamba,Victoria,VIC,-35.116,142.8978,4
3546,Bolton,Victoria,VIC,-34.9666,142.8815,4
3546,Winnambool,Victoria,VIC,-35.0977,142.9331,3
3549,Robinvale,Victoria,VIC,-34.5836,142.7723,4
3549,Robinvale Irrigation District Section B,Victoria,VIC,-34.7137,142.753,3
3549,Annuello,Victoria,VIC,-34.85,142.8333,4
3549,Happy Valley,Victoria,VIC,-37.7167,143.6,4
3549,Bannerton,Victoria,VIC,-34.6992,142.7883,4
3549,Tol Tol,Victoria,VIC,-34.6514,142.8258,4
3549,Wemen,Victoria,VIC,-34.7887,142.6301,4
3549,Robinvale Irrigation District Section E,Victoria,VIC,-34.7137,142.753,3
3549,Wandown,Victoria,VIC,-34.7137,142.753,3
3549,Robinvale Irrigation District Section D,Victoria,VIC,-34.7137,142.753,3
3549,Liparoo,Victoria,VIC,-34.7848,142.5431,4
3549,Robinvale Irrigation District Section C,Victoria,VIC,-34.7137,142.753,3
3550,Flora Hill,Victoria,VIC,-36.7812,144.2956,4
3550,East Bendigo,Victoria,VIC,-36.7366,144.3178,3
3550,North Bendigo,Victoria,VIC,-36.731,144.2997,3
3550,Strathdale,Victoria,VIC,-36.7657,144.3155,4
3550,Kennington,Victoria,VIC,-36.7833,144.3,4
3550,Spring Gully,Victoria,VIC,-36.7575,144.2908,3
3550,Long Gully,Victoria,VIC,-36.7431,144.2579,4
3550,White Hills,Victoria,VIC,-36.7287,144.3053,4
3550,West Bendigo,Victoria,VIC,-36.4167,144.5833,4
3550,Bendigo South,Victoria,VIC,-36.85,144.2833,3
3550,Quarry Hill,Victoria,VIC,-36.7765,144.2802,4
3550,Ironbark,Victoria,VIC,-36.7548,144.2638,4
3550,Tysons Reef,Victoria,VIC,-36.7575,144.2908,3
3550,Bendigo,Victoria,VIC,-36.7582,144.2802,4
3550,Sandhurst East,Victoria,VIC,-36.7575,144.2908,3
3551,Toolleen,Victoria,VIC,-36.7167,144.7,4
3551,Lake Eppalock,Victoria,VIC,-36.7536,144.2402,3
3551,Wellsford,Victoria,VIC,-36.6333,144.4667,4
3551,Myrtle Creek,Victoria,VIC,-36.7536,144.2402,3
3551,Maiden Gully,Victoria,VIC,-36.7539,144.2079,4
3551,Huntly,Victoria,VIC,-36.6645,144.3338,4
3551,Arnold West,Victoria,VIC,-36.6667,143.8,4
3551,Huntly North,Victoria,VIC,-36.7536,144.2402,3
3551,Emu Creek,Victoria,VIC,-36.8333,144.35,4
3551,Tarnagulla,Victoria,VIC,-36.75,143.8333,4
3551,Lockwood,Victoria,VIC,-36.8111,144.1576,4
3551,Painswick,Victoria,VIC,-36.7519,144.2656,3
3551,Bendigo Forward,Victoria,VIC,-36.7536,144.2402,3
3551,Myola,Victoria,VIC,-36.6333,144.65,4
3551,Waanyarra,Victoria,VIC,-36.8,143.8,4
3551,Junortoun,Victoria,VIC,-36.7711,144.3631,4
3551,Llanelly,Victoria,VIC,-36.7333,143.8667,4
3551,Longlea,Victoria,VIC,-36.7833,144.4333,4
3551,Mandurang,Victoria,VIC,-36.8167,144.3,4
3551,Axedale,Victoria,VIC,-36.8,144.5167,4
3551,Woodstock On Loddon,Victoria,VIC,-36.7833,144.05,4
3551,Bagshot,Victoria,VIC,-36.65,144.4167,4
3551,Murphys Creek,Victoria,VIC,-36.75,143.7667,4
3551,Bagshot North,Victoria,VIC,-36.7536,144.2402,3
3551,Newbridge,Victoria,VIC,-36.7333,143.9167,4
3551,Eppalock,Victoria,VIC,-36.8333,144.4167,4
3551,Arnold,Victoria,VIC,-36.6833,143.8667,4
3551,Axe Creek,Victoria,VIC,-36.7536,144.2402,3
3551,Mandurang South,Victoria,VIC,-36.85,144.2833,4
3551,Kimbolton,Victoria,VIC,-36.7536,144.2402,3
3551,Epsom,Victoria,VIC,-36.7167,144.3167,4
3551,Cornella,Victoria,VIC,-36.7,144.75,4
3551,Strathfieldsaye,Victoria,VIC,-36.8067,144.3549,4
3551,Lockwood South,Victoria,VIC,-36.8436,144.1584,4
3551,Ascot,Victoria,VIC,-36.7,144.3333,4
3551,Sedgwick,Victoria,VIC,-36.8833,144.3167,4
3552,Bendigo,Victoria,VIC,-36.7582,144.2802,4
3554,Bendigo Dc,Victoria,VIC,-36.731,144.2997,1
3555,Golden Gully,Victoria,VIC,-37.7,143.6667,4
3555,Golden Square,Victoria,VIC,-36.7726,144.2527,4
3555,Kangaroo Flat,Victoria,VIC,-36.8,144.25,4
3555,Lansell Plaza,Victoria,VIC,-37.0909,144.0565,3
3555,Big Hill,Victoria,VIC,-37.0909,144.0565,3
3556,Campbells Forest,Victoria,VIC,-36.6,144.1333,4
3556,Eaglehawk,Victoria,VIC,-36.7333,144.25,4
3556,Sebastian,Victoria,VIC,-36.6,144.2,4
3556,Eaglehawk North,Victoria,VIC,-36.6731,144.221,3
3556,California Gully,Victoria,VIC,-36.7289,144.2609,4
3556,Woodvale,Victoria,VIC,-36.65,144.2167,4
3556,Comet Hill,Victoria,VIC,-36.6879,144.2346,3
3556,Jackass Flat,Victoria,VIC,-36.7159,144.286,4
3556,Myers Flat,Victoria,VIC,-36.6833,144.2,4
3556,Whipstick,Victoria,VIC,-36.6731,144.221,3
3556,Sailors Gully,Victoria,VIC,-36.6731,144.221,3
3557,Goornong,Victoria,VIC,-36.6165,144.5072,4
3557,Barnadown,Victoria,VIC,-36.65,144.55,4
3557,Muskerry,Victoria,VIC,-36.6833,144.6333,4
3557,Fosterville,Victoria,VIC,-36.7,144.5,4
3558,Elmore,Victoria,VIC,-36.4946,144.6105,4
3558,Hunter,Victoria,VIC,-36.45,144.5167,4
3558,Burnewang,Victoria,VIC,-36.4723,144.5636,3
3558,Runnymede,Victoria,VIC,-36.4723,144.5636,3
3558,Corop West,Victoria,VIC,-36.4723,144.5636,3
3559,Avonmore,Victoria,VIC,-36.5333,144.5667,4
3559,Gobarup,Victoria,VIC,-36.5333,144.7278,3
3559,Colbinabbin,Victoria,VIC,-36.6,144.8167,4
3559,Corop,Victoria,VIC,-36.4667,144.8,4
3559,Burramboot,Victoria,VIC,-36.5333,144.7278,3
3561,Fairy Dell,Victoria,VIC,-36.3333,144.75,4
3561,Rochester,Victoria,VIC,-36.3635,144.7008,4
3561,Ballendella,Victoria,VIC,-36.3,144.65,4
3561,Bonn,Victoria,VIC,-36.3305,144.712,3
3561,Timmering,Victoria,VIC,-36.3667,144.9167,4
3561,Nanneella,Victoria,VIC,-36.3167,144.7833,4
3561,Bamawm,Victoria,VIC,-36.2145,144.6089,4
3561,Diggora,Victoria,VIC,-36.4167,144.5833,4
3562,Torrumbarry,Victoria,VIC,-36.0231,144.5533,4
3563,Lockington,Victoria,VIC,-36.2712,144.5373,4
3564,Roslynmead,Victoria,VIC,-36.1262,144.6892,3
3564,Echuca South,Victoria,VIC,-36.1262,144.6892,3
3564,Wharparilla,Victoria,VIC,-36.129,144.6289,4
3564,Simmie,Victoria,VIC,-36.1292,144.6995,3
3564,Echuca,Victoria,VIC,-36.1406,144.7518,4
3564,Echuca Village,Victoria,VIC,-36.1333,144.8167,4
3564,Bamawm Extension,Victoria,VIC,-36.2145,144.6089,4
3564,Kanyapella,Victoria,VIC,-36.1574,144.7962,4
3564,Echuca West,Victoria,VIC,-36.1262,144.6892,3
3564,Patho,Victoria,VIC,-36.0087,144.4304,4
3565,Kotta,Victoria,VIC,-36.2003,144.5363,4
3566,Gunbower,Victoria,VIC,-35.9561,144.3692,4
3567,Horfield,Victoria,VIC,-35.9039,144.3022,3
3567,Leitchville,Victoria,VIC,-35.9039,144.3022,4
3568,Mead,Victoria,VIC,-35.8147,144.1738,3
3568,Cohuna,Victoria,VIC,-35.807,144.2189,4
3568,Gannawarra,Victoria,VIC,-35.7333,144.15,4
3568,Macorna North,Victoria,VIC,-35.8391,144.0922,4
3568,Cullen,Victoria,VIC,-35.8147,144.1738,3
3568,Keely,Victoria,VIC,-35.85,144.25,4
3568,Mincha West,Victoria,VIC,-35.8147,144.1738,3
3568,Mcmillans,Victoria,VIC,-35.8333,144.15,4
3568,Wee Wee Rup,Victoria,VIC,-35.8147,144.1738,3
3568,Daltons Bridge,Victoria,VIC,-35.8147,144.1738,3
3568,Burkes Bridge,Victoria,VIC,-35.8147,144.1738,3
3570,Kamarooka,Victoria,VIC,-36.4707,144.3336,4
3570,Raywood,Victoria,VIC,-36.5333,144.2167,4
3570,Drummartin,Victoria,VIC,-36.45,144.4333,4
3570,Auchmore,Victoria,VIC,-36.5972,144.2121,3
3570,Neilborough,Victoria,VIC,-36.5667,144.2333,4
3571,Kamarooka North,Victoria,VIC,-36.4064,144.1719,3
3571,Dingee,Victoria,VIC,-36.3667,144.2167,4
3571,Pompapiel,Victoria,VIC,-36.35,144.0833,4
3571,Tandarra,Victoria,VIC,-36.4285,144.2286,4
3572,Tennyson,Victoria,VIC,-36.2833,144.4333,4
3572,Piavella,Victoria,VIC,-36.3,144.3444,3
3572,Milloo,Victoria,VIC,-36.3167,144.3667,4
3572,Prairie,Victoria,VIC,-36.3,144.2333,4
3573,Pine Grove,Victoria,VIC,-36.2333,144.4167,4
3573,Calivil,Victoria,VIC,-36.2667,144.0667,4
3573,Terrick Terrick East,Victoria,VIC,-36.2389,144.2389,3
3573,Mitiamo,Victoria,VIC,-36.2078,144.2306,4
3575,Gladfield,Victoria,VIC,-36.0506,143.9521,4
3575,Mologa,Victoria,VIC,-36.1437,144.1791,4
3575,Sylvaterre,Victoria,VIC,-36.11,144.2248,4
3575,Loddon Vale,Victoria,VIC,-36.3561,143.7034,4
3575,Pyramid Hill,Victoria,VIC,-36.0558,144.1147,4
3575,Yarrawalla,Victoria,VIC,-36.1165,144.0308,4
3575,Terrick Terrick,Victoria,VIC,-36.0843,144.2808,4
3575,Jungaburra,Victoria,VIC,-36.114,144.0939,3
3575,Mincha,Victoria,VIC,-36.0022,144.0757,4
3576,Durham Ox,Victoria,VIC,-36.1259,143.9199,4
3579,Mystic Park,Victoria,VIC,-35.5535,143.7221,4
3579,Murrabit West,Victoria,VIC,-35.5193,143.9049,4
3579,Teal Point,Victoria,VIC,-35.6746,144.0757,4
3579,Kerang East,Victoria,VIC,-35.7167,143.8618,3
3579,Beauchamp,Victoria,VIC,-35.6082,143.5719,4
3579,Westby,Victoria,VIC,-35.7167,143.8618,3
3579,Sandhill Lake,Victoria,VIC,-35.7248,143.685,4
3579,Koroop,Victoria,VIC,-35.7425,144.0736,4
3579,Appin South,Victoria,VIC,-35.9204,143.9123,4
3579,Wandella,Victoria,VIC,-35.7167,143.8618,3
3579,Budgerum East,Victoria,VIC,-35.7792,143.6597,4
3579,Macorna,Victoria,VIC,-35.9177,144.0305,4
3579,Capels Crossing,Victoria,VIC,-35.6333,143.4333,4
3579,Meering West,Victoria,VIC,-35.7249,143.8763,3
3579,Murrabit,Victoria,VIC,-35.5322,143.9524,4
3579,Benjeroop,Victoria,VIC,-35.4913,143.8604,4
3579,Tragowel,Victoria,VIC,-35.8513,143.9777,4
3579,Lake Meran,Victoria,VIC,-35.7167,143.8618,3
3579,Myall,Victoria,VIC,-35.581,144.0013,4
3579,Gonn Crossing,Victoria,VIC,-35.7167,143.8618,3
3579,Normanville,Victoria,VIC,-35.8161,143.7454,4
3579,Fairley,Victoria,VIC,-35.6868,143.8612,4
3579,Milnes Bridge,Victoria,VIC,-35.8,144.0667,4
3579,Pine View,Victoria,VIC,-35.7167,143.8618,3
3579,Reedy Lake,Victoria,VIC,-35.7167,143.8618,3
3579,Kerang,Victoria,VIC,-35.7349,143.9203,4
3579,Bael Bael,Victoria,VIC,-35.7167,143.8618,3
3579,Appin,Victoria,VIC,-35.9065,143.8691,4
3579,Dingwall,Victoria,VIC,-35.811,143.8309,4
3580,Koondrook,Victoria,VIC,-35.6318,144.1248,4
3581,Lake Charm,Victoria,VIC,-35.65,143.7833,4
3583,Tresco,Victoria,VIC,-35.5057,143.6748,4
3584,Tresco West,Victoria,VIC,-35.463,143.6326,3
3584,Lake Boga,Victoria,VIC,-35.463,143.6326,4
3585,Kunat,Victoria,VIC,-35.5412,143.4973,4
3585,Goschen,Victoria,VIC,-35.4833,143.4667,4
3585,Nowie,Victoria,VIC,-35.2435,143.3554,4
3585,Winlaton,Victoria,VIC,-35.3964,143.4089,3
3585,Chillingollah,Victoria,VIC,-35.25,143.05,4
3585,Pira,Victoria,VIC,-35.2558,143.3676,4
3585,Meatian,Victoria,VIC,-35.5715,143.3427,4
3585,Speewa,Victoria,VIC,-35.3964,143.4089,3
3585,Fish Point,Victoria,VIC,-35.4354,143.7268,4
3585,Swan Hill,Victoria,VIC,-35.3378,143.5544,4
3585,Nyrraby,Victoria,VIC,-35.3964,143.4089,3
3585,Castle Donnington,Victoria,VIC,-35.3964,143.4089,3
3585,Polisbet,Victoria,VIC,-35.3964,143.4089,3
3585,Swan Hill West,Victoria,VIC,-35.3964,143.4089,3
3586,Murrawee,Victoria,VIC,-35.3167,143.5333,4
3586,Pental Island,Victoria,VIC,-35.4034,143.6912,4
3586,Tyntynder South,Victoria,VIC,-35.2788,143.5356,4
3586,Bulga,Victoria,VIC,-35.3134,143.5561,3
3586,Tyntynder,Victoria,VIC,-35.2333,143.4667,4
3586,Murraydale,Victoria,VIC,-35.3134,143.5561,3
3588,Woorinen South,Victoria,VIC,-35.2911,143.4551,1
3589,Woorinen North,Victoria,VIC,-35.255,143.4593,4
3589,Woorinen,Victoria,VIC,-35.2925,143.4542,4
3590,Beverford,Victoria,VIC,-35.2238,143.4727,4
3591,Vinifera,Victoria,VIC,-35.2393,143.3195,1
3594,Nyah,Victoria,VIC,-35.1755,143.3774,4
3595,Nyah West,Victoria,VIC,-35.1854,143.3508,4
3596,Miralie,Victoria,VIC,-35.1167,143.2833,3
3596,Wood Wood,Victoria,VIC,-35.1037,143.3383,4
3596,Towan,Victoria,VIC,-35.1167,143.2333,4
3597,Narrung,Victoria,VIC,-34.774,143.2472,4
3597,Kooloonong,Victoria,VIC,-34.8774,143.1459,4
3597,Kenley,Victoria,VIC,-34.9083,143.2167,3
3597,Natya,Victoria,VIC,-34.9567,143.2252,4
3597,Piangil,Victoria,VIC,-35.0585,143.3063,4
3597,Lake Powell,Victoria,VIC,-34.9083,143.2167,3
3599,Boundary Bend,Victoria,VIC,-34.7213,143.1648,4
3607,Tabilk,Victoria,VIC,-36.85,145.1667,4
3608,Kirwans Bridge,Victoria,VIC,-36.7741,145.0958,3
3608,Bailieston,Victoria,VIC,-36.7333,145.05,4
3608,Graytown,Victoria,VIC,-36.8167,144.95,4
3608,Nagambie,Victoria,VIC,-36.7851,145.1537,4
3608,Goulburn Weir,Victoria,VIC,-36.7167,145.1833,4
3608,Mitchellstown,Victoria,VIC,-36.85,145.0667,4
3608,Wahring,Victoria,VIC,-36.7167,145.2167,4
3608,Wirrate,Victoria,VIC,-36.8,145.05,4
3610,Murchison North,Victoria,VIC,-36.596,145.2493,3
3610,Moorilim,Victoria,VIC,-36.6167,145.3167,4
3610,Dhurringile,Victoria,VIC,-36.5319,145.2132,4
3610,Murchison,Victoria,VIC,-36.6189,145.2172,4
3610,Murchison East,Victoria,VIC,-36.6167,145.25,4
3612,Wanalta,Victoria,VIC,-36.6,144.8833,4
3612,Moora,Victoria,VIC,-36.6099,144.9369,3
3612,Rushworth,Victoria,VIC,-36.5867,145.0175,4
3612,Whroo,Victoria,VIC,-36.65,145.0167,4
3612,Waranga Shores,Victoria,VIC,-36.6122,144.9725,3
3614,Toolamba,Victoria,VIC,-36.5,145.35,4
3614,Toolamba West,Victoria,VIC,-36.4333,145.2833,4
3616,Waranga,Victoria,VIC,-36.6,145.1167,4
3616,Girgarre East,Victoria,VIC,-36.4333,145.0667,4
3616,Tatura,Victoria,VIC,-36.4396,145.2299,4
3616,Harston,Victoria,VIC,-36.4667,145.15,4
3616,Tatura East,Victoria,VIC,-36.4455,145.1411,3
3616,Gillieston,Victoria,VIC,-36.3167,145.2167,4
3616,Cooma,Victoria,VIC,-36.4167,145.0667,4
3616,Mooroopna North West,Victoria,VIC,-36.4455,145.1411,3
3617,Byrneside,Victoria,VIC,-36.4167,145.1833,4
3618,Merrigum,Victoria,VIC,-36.3724,145.1322,4
3619,Kyabram,Victoria,VIC,-36.3134,145.0504,4
3620,St Germains,Victoria,VIC,-36.25,145.1667,4
3620,Wyuna,Victoria,VIC,-36.1968,145.0589,4
3620,Kyabram South,Victoria,VIC,-36.3048,145.0719,3
3620,Lancaster,Victoria,VIC,-36.3167,145.1333,4
3620,Wyuna East,Victoria,VIC,-36.1968,145.0589,4
3620,Kyabram,Victoria,VIC,-36.3134,145.0504,4
3621,Kyvalley,Victoria,VIC,-36.2833,145,4
3621,Koyuga South,Victoria,VIC,-36.208,144.9829,3
3621,Tongala,Victoria,VIC,-36.2547,144.9562,4
3621,Yambuna,Victoria,VIC,-36.1343,145.036,4
3622,Koyuga,Victoria,VIC,-36.2207,144.8906,4
3622,Strathallan,Victoria,VIC,-36.25,144.75,4
3623,Stanhope,Victoria,VIC,-36.4472,144.9827,4
3623,Carag Carag,Victoria,VIC,-36.4667,144.9167,4
3623,Stanhope South,Victoria,VIC,-36.457,144.9497,3
3624,Girgarre,Victoria,VIC,-36.3999,144.9496,4
3629,Mooroopna North,Victoria,VIC,-36.3333,145.3,4
3629,Mooroopna,Victoria,VIC,-36.4,145.35,4
3629,Ardmona,Victoria,VIC,-36.3833,145.3,4
3629,Undera,Victoria,VIC,-36.2833,145.2167,4
3629,Coomboona,Victoria,VIC,-36.2833,145.2484,3
3630,Shepparton,Victoria,VIC,-36.3805,145.3987,4
3630,Shepparton South,Victoria,VIC,-36.4283,145.5142,3
3630,Caniambo,Victoria,VIC,-36.4761,145.6297,4
3630,Benarch,Victoria,VIC,-36.4681,145.586,3
3630,Colliver,Victoria,VIC,-36.4681,145.586,3
3630,Branditt,Victoria,VIC,-36.4681,145.586,3
3630,Dunkirk,Victoria,VIC,-36.4681,145.586,3
3631,Kialla East,Victoria,VIC,-36.45,145.5333,4
3631,Kialla,Victoria,VIC,-36.4473,145.4142,4
3631,Orrvale,Victoria,VIC,-36.4167,145.45,4
3631,Arcadia South,Victoria,VIC,-36.4425,145.4837,3
3631,Pine Lodge,Victoria,VIC,-36.4167,145.5,4
3631,Karramomus,Victoria,VIC,-36.5333,145.4833,4
3631,Lemnos,Victoria,VIC,-36.35,145.4667,4
3631,Tamleugh West,Victoria,VIC,-36.5667,145.5333,4
3631,Grahamvale,Victoria,VIC,-36.3484,145.4362,4
3631,Kialla West,Victoria,VIC,-36.4645,145.3878,4
3631,Arcadia,Victoria,VIC,-36.5654,145.3319,4
3631,Shepparton North,Victoria,VIC,-36.4425,145.4837,3
3631,Cosgrove,Victoria,VIC,-36.35,145.6667,4
3631,Cosgrove South,Victoria,VIC,-36.4,145.6167,4
3631,Shepparton East,Victoria,VIC,-36.4293,145.4749,3
3632,Shepparton,Victoria,VIC,-36.3805,145.3987,4
3633,Congupna,Victoria,VIC,-36.3,145.4333,4
3634,Invergordon South,Victoria,VIC,-36.2456,145.4686,3
3634,Tallygaroopna,Victoria,VIC,-36.25,145.4333,4
3634,Bunbartha,Victoria,VIC,-36.2167,145.3167,4
3634,Katandra West,Victoria,VIC,-36.2241,145.5583,4
3634,Zeerust,Victoria,VIC,-36.2248,145.4883,3
3634,Katandra,Victoria,VIC,-36.2333,145.6167,4
3634,Marionvale,Victoria,VIC,-36.2248,145.4883,3
3634,Marungi,Victoria,VIC,-36.1969,145.536,4
3635,Wunghnu,Victoria,VIC,-36.1526,145.431,4
3635,Kaarimba,Victoria,VIC,-36.1333,145.25,4
3635,Mundoona,Victoria,VIC,-36.1545,145.3298,4
3636,Naring,Victoria,VIC,-36.1295,145.5085,3
3636,Numurkah,Victoria,VIC,-36.0885,145.4422,4
3636,Drumanure,Victoria,VIC,-36.1312,145.5057,4
3636,Invergordon,Victoria,VIC,-36.1667,145.5833,4
3637,Yalca,Victoria,VIC,-35.9833,145.3167,4
3637,Waaia,Victoria,VIC,-36.0475,145.3334,4
3638,Nathalia,Victoria,VIC,-36.0577,145.2041,4
3638,Yielima,Victoria,VIC,-35.9333,145.2167,3
3638,Kotupna,Victoria,VIC,-36.1475,145.1337,4
3639,Barmah,Victoria,VIC,-36.0159,144.9628,4
3639,Picola West,Victoria,VIC,-36.0333,145.0278,3
3639,Lower Moira,Victoria,VIC,-36.0855,144.9763,4
3639,Picola,Victoria,VIC,-35.9969,145.1193,4
3640,Katunga,Victoria,VIC,-36.0133,145.4695,4
3641,Bearii,Victoria,VIC,-35.8857,145.3453,4
3641,Strathmerton,Victoria,VIC,-35.9253,145.4787,4
3641,Ulupna,Victoria,VIC,-35.8833,145.4,4
3641,Mywee,Victoria,VIC,-35.8566,145.4976,4
3643,Cobram,Victoria,VIC,-35.9207,145.6407,4
3644,Koonoomoo,Victoria,VIC,-35.9503,145.6546,3
3644,Cobram,Victoria,VIC,-35.9207,145.6407,4
3644,Muckatah,Victoria,VIC,-36.0198,145.6478,4
3644,Yarroweyah,Victoria,VIC,-35.9241,145.5572,4
3644,Cobram East,Victoria,VIC,-35.9746,145.7364,4
3646,Nalinga,Victoria,VIC,-36.4167,145.7167,4
3646,Dookie,Victoria,VIC,-36.3273,145.6897,4
3646,Mount Major,Victoria,VIC,-36.3606,145.6914,4
3646,Yabba South,Victoria,VIC,-36.2755,145.7146,3
3646,Waggarandall,Victoria,VIC,-36.25,145.8,4
3646,Youanmite,Victoria,VIC,-36.1625,145.6899,4
3646,Yabba North,Victoria,VIC,-36.2167,145.6833,4
3647,Dookie College,Victoria,VIC,-36.3957,145.7047,4
3649,Katamatite East,Victoria,VIC,-36.0759,145.6872,3
3649,Katamatite,Victoria,VIC,-36.0759,145.6872,4
3658,Waterford Park,Victoria,VIC,-37.3002,145.0668,4
3658,Clonbinane,Victoria,VIC,-37.292,145.1406,3
3658,Tyaak,Victoria,VIC,-37.2167,145.1333,4
3658,Strath Creek,Victoria,VIC,-37.2333,145.2167,4
3658,Broadford,Victoria,VIC,-37.2028,145.0484,4
3658,Sugarloaf Creek,Victoria,VIC,-37.2602,145.1482,3
3658,Reedy Creek,Victoria,VIC,-37.2667,145.1333,4
3658,Sunday Creek,Victoria,VIC,-37.2602,145.1482,3
3658,Hazeldene,Victoria,VIC,-37.3413,145.2908,4
3659,Tallarook,Victoria,VIC,-37.0937,145.1,4
3660,Northwood,Victoria,VIC,-36.9667,145.1167,4
3660,Dropmore,Victoria,VIC,-37.0605,145.2132,3
3660,Seymour South,Victoria,VIC,-37.0605,145.2132,3
3660,Seymour,Victoria,VIC,-37.0266,145.1392,4
3660,Whiteheads Creek,Victoria,VIC,-37.0468,145.2864,4
3660,Trawool,Victoria,VIC,-37.1,145.2167,4
3660,Caveat,Victoria,VIC,-37.0605,145.2132,3
3660,Kerrisdale,Victoria,VIC,-37.15,145.2667,4
3660,Highlands,Victoria,VIC,-37.1,145.4,4
3660,Hilldene,Victoria,VIC,-37.0333,145.0667,4
3661,Seymour,Victoria,VIC,-37.0266,145.1392,4
3662,Puckapunyal Milpo,Victoria,VIC,-36.9604,145.0527,3
3662,Puckapunyal,Victoria,VIC,-36.9949,145.0401,4
3663,Mangalore,Victoria,VIC,-36.9333,145.1833,4
3664,Upton Hill,Victoria,VIC,-36.9009,145.2337,3
3664,Avenel,Victoria,VIC,-36.9009,145.2337,4
3665,Longwood,Victoria,VIC,-36.8,145.4167,4
3665,Locksley,Victoria,VIC,-36.8333,145.3333,4
3666,Miepoll,Victoria,VIC,-36.6167,145.4667,4
3666,Strathbogie,Victoria,VIC,-36.85,145.75,4
3666,Creightons Creek,Victoria,VIC,-36.7804,145.5403,3
3666,Gooram,Victoria,VIC,-36.9,145.5833,4
3666,Kelvin View,Victoria,VIC,-36.8167,145.6667,4
3666,Ruffy,Victoria,VIC,-36.9833,145.5,4
3666,Kithbrook,Victoria,VIC,-36.7574,145.5632,3
3666,Creighton,Victoria,VIC,-36.7833,145.5,4
3666,Balmattum,Victoria,VIC,-36.7,145.65,4
3666,Pranjip,Victoria,VIC,-36.7333,145.3667,4
3666,Euroa,Victoria,VIC,-36.7555,145.5708,4
3666,Moglonemby,Victoria,VIC,-36.65,145.5333,4
3666,Molka,Victoria,VIC,-36.6833,145.45,4
3666,Tarcombe,Victoria,VIC,-36.9833,145.4,4
3666,Longwood East,Victoria,VIC,-36.8167,145.45,4
3666,Riggs Creek,Victoria,VIC,-36.65,145.5667,4
3666,Sheans Creek,Victoria,VIC,-36.7833,145.65,4
3669,Earlston,Victoria,VIC,-36.55,145.6667,4
3669,Boho,Victoria,VIC,-36.7167,145.8333,4
3669,Tamleugh North,Victoria,VIC,-36.5,145.55,4
3669,Koonda,Victoria,VIC,-36.5,145.7333,4
3669,Marraweeney,Victoria,VIC,-36.75,145.75,4
3669,Violet Town,Victoria,VIC,-36.6361,145.7151,4
3669,Upotipotpon,Victoria,VIC,-36.4833,145.8167,4
3669,Boho South,Victoria,VIC,-36.7833,145.8,4
3669,Gowangardie,Victoria,VIC,-36.4833,145.7,4
3669,Tamleugh,Victoria,VIC,-36.6,145.6333,4
3669,Creek Junction,Victoria,VIC,-37.25,147.7167,4
3670,Tarnook,Victoria,VIC,-36.6333,145.8667,3
3670,Warrenbayne,Victoria,VIC,-36.6833,145.8667,4
3670,Baddaginnie,Victoria,VIC,-36.5833,145.8667,4
3671,Benalla,Victoria,VIC,-36.5511,145.9843,4
3672,Benalla,Victoria,VIC,-36.5511,145.9843,4
3673,Goomalibee,Victoria,VIC,-36.4833,145.9167,4
3673,Upper Ryans Creek,Victoria,VIC,-36.6418,146.0718,3
3673,Tatong,Victoria,VIC,-36.7333,146.1,4
3673,Swanpool,Victoria,VIC,-36.7667,146.0167,4
3673,Samaria,Victoria,VIC,-36.8,146.0833,4
3673,Upper Lurg,Victoria,VIC,-36.5833,146.1833,4
3673,Moorngag,Victoria,VIC,-36.75,146.0667,4
3673,Winton,Victoria,VIC,-36.5167,146.0833,4
3673,Benalla,Victoria,VIC,-36.5511,145.9843,4
3673,Broken Creek,Victoria,VIC,-36.6418,146.0718,3
3673,Molyullah,Victoria,VIC,-36.65,146.15,4
3673,Winton North,Victoria,VIC,-36.6418,146.0718,3
3673,Lurg,Victoria,VIC,-36.5833,146.1333,4
3673,Lima East,Victoria,VIC,-36.6418,146.0718,3
3673,Lima,Victoria,VIC,-36.702,146.0416,3
3673,Lima South,Victoria,VIC,-36.6418,146.0718,3
3675,Boweya,Victoria,VIC,-36.2667,146.1333,4
3675,Greta,Victoria,VIC,-36.5833,146.2833,4
3675,Taminick,Victoria,VIC,-36.4167,146.1667,4
3675,Mount Bruno,Victoria,VIC,-36.46,146.2072,3
3675,Greta West,Victoria,VIC,-36.5333,146.2167,4
3675,Glenrowan,Victoria,VIC,-36.4635,146.2245,4
3675,Glenrowan West,Victoria,VIC,-36.46,146.2072,3
3675,Boweya North,Victoria,VIC,-36.2047,146.0685,4
3675,Greta South,Victoria,VIC,-36.6167,146.25,4
3675,Hansonville,Victoria,VIC,-36.6,146.3,4
3676,Wangaratta,Victoria,VIC,-36.3585,146.3206,4
3677,Yarrunga,Victoria,VIC,-36.3639,146.3103,3
3677,Wangaratta,Victoria,VIC,-36.3585,146.3206,4
3677,Appin Park,Victoria,VIC,-36.3639,146.3103,3
3678,Oxley Flats,Victoria,VIC,-36.3954,146.3852,4
3678,Rose River,Victoria,VIC,-36.4375,146.3894,3
3678,Edi,Victoria,VIC,-36.65,146.4071,4
3678,Meadow Creek,Victoria,VIC,-36.4375,146.3894,3
3678,Waldara,Victoria,VIC,-36.3199,146.2844,4
3678,Everton Upper,Victoria,VIC,-36.4375,146.3894,3
3678,Tarrawingee,Victoria,VIC,-36.4,146.4667,4
3678,Boorhaman,Victoria,VIC,-36.2333,146.3,4
3678,Killawarra,Victoria,VIC,-36.25,146.25,4
3678,Wangaratta Forward,Victoria,VIC,-36.4375,146.3894,3
3678,Peechelba,Victoria,VIC,-36.1947,146.2299,4
3678,Wangandary,Victoria,VIC,-36.4375,146.3894,3
3678,Bobinawarrah,Victoria,VIC,-36.5167,146.5,4
3678,Wangaratta South,Victoria,VIC,-36.4375,146.3894,3
3678,North Wangaratta,Victoria,VIC,-36.4375,146.3894,3
3678,King Valley,Victoria,VIC,-36.7,146.4333,4
3678,Edi Upper,Victoria,VIC,-36.7,146.45,4
3678,Boorhaman East,Victoria,VIC,-36.4375,146.3894,3
3678,Docker,Victoria,VIC,-36.5167,146.3833,4
3678,Peechelba East,Victoria,VIC,-36.1692,146.2544,4
3678,Markwood,Victoria,VIC,-36.473,146.5088,4
3678,Dockers Plains,Victoria,VIC,-36.4375,146.3894,3
3678,Oxley,Victoria,VIC,-36.45,146.3667,4
3678,Wabonga,Victoria,VIC,-36.4375,146.3894,3
3678,Byawatha,Victoria,VIC,-36.4375,146.3894,3
3678,Cheshunt South,Victoria,VIC,-36.4375,146.3894,3
3678,Londrigan,Victoria,VIC,-36.3333,146.4167,4
3678,Bowser,Victoria,VIC,-36.4375,146.3894,3
3678,Everton,Victoria,VIC,-36.4167,146.55,4
3678,Whitlands,Victoria,VIC,-36.4375,146.3894,3
3678,Cheshunt,Victoria,VIC,-36.8,146.4333,4
3678,East Wangaratta,Victoria,VIC,-36.1692,146.2544,4
3678,Laceby,Victoria,VIC,-36.4333,146.3,4
3678,Carboor,Victoria,VIC,-36.6333,146.55,4
3678,Milawa,Victoria,VIC,-36.4333,146.4333,4
3682,Norong,Victoria,VIC,-36.1167,146.4,4
3682,Boralma,Victoria,VIC,-36.2167,146.4167,4
3682,Springhurst,Victoria,VIC,-36.2,146.5,4
3682,Lilliput,Victoria,VIC,-36.1131,146.4751,4
3683,Chiltern Valley,Victoria,VIC,-36.1399,146.5408,4
3683,Cornishtown,Victoria,VIC,-36.1419,146.5709,3
3683,Chiltern,Victoria,VIC,-36.1504,146.6084,4
3685,Prentice North,Victoria,VIC,-36.0333,146.5,4
3685,Boorhaman North,Victoria,VIC,-36.045,146.5117,3
3685,Browns Plains,Victoria,VIC,-36.0472,146.6387,4
3685,Brimin,Victoria,VIC,-36.0513,146.2249,4
3685,Rutherglen,Victoria,VIC,-36.0543,146.4621,4
3685,Gooramadda,Victoria,VIC,-36,146.55,4
3685,Great Southern,Victoria,VIC,-36.0667,146.4833,4
3685,Carlyle,Victoria,VIC,-36.0427,146.4824,3
3687,Wahgunyah,Victoria,VIC,-36.0118,146.3971,4
3688,Indigo Valley,Victoria,VIC,-36.1399,146.5408,4
3688,Barnawartha,Victoria,VIC,-36.1041,146.6761,4
3689,Wodonga,Victoria,VIC,-36.1218,146.8881,4
3690,Wodonga,Victoria,VIC,-36.1218,146.8881,4
3690,Wodonga Plaza,Victoria,VIC,-36.0997,146.802,3
3690,West Wodonga,Victoria,VIC,-36.1218,146.8881,3
3691,Killara,Victoria,VIC,-36.1396,146.9514,4
3691,Castle Creek,Victoria,VIC,-36.2843,147.0692,3
3691,Upper Gundowring,Victoria,VIC,-36.4833,147.0667,4
3691,Bandiana,Victoria,VIC,-36.1479,146.9262,4
3691,Bethanga,Victoria,VIC,-36.1167,147.1,4
3691,Baranduda,Victoria,VIC,-36.1911,146.9538,4
3691,Allans Flat,Victoria,VIC,-36.2667,146.9,4
3691,Mount Alfred,Victoria,VIC,-36.2959,147.0534,3
3691,Bellbridge,Victoria,VIC,-36.0974,147.0617,4
3691,Kergunyah South,Victoria,VIC,-36.2843,147.0692,3
3691,Berringama,Victoria,VIC,-36.2167,147.65,4
3691,Coral Bank,Victoria,VIC,-36.2843,147.0692,3
3691,Talgarno,Victoria,VIC,-36.0833,147.1667,4
3691,Kergunyah,Victoria,VIC,-36.3833,147.05,4
3691,Gundowring,Victoria,VIC,-36.4333,147.05,4
3691,Lone Pine,Victoria,VIC,-36.2959,147.0534,3
3691,Glen Creek,Victoria,VIC,-36.45,146.95,4
3691,Tangambalanga,Victoria,VIC,-36.2522,147.0286,4
3691,Dederang,Victoria,VIC,-36.4833,147.0167,4
3691,Wodonga Forward,Victoria,VIC,-36.2843,147.0692,3
3691,Hume Weir,Victoria,VIC,-36.1022,147.0373,4
3691,Bonegilla,Victoria,VIC,-36.1403,147.0062,4
3691,Kancoona,Victoria,VIC,-36.5833,146.9833,4
3691,Thologolong,Victoria,VIC,-36.2843,147.0692,3
3691,Running Creek,Victoria,VIC,-36.6167,146.9167,4
3691,Kiewa,Victoria,VIC,-36.2667,147.0167,4
3691,Bungil,Victoria,VIC,-36.0667,147.35,4
3691,Gateway Island,Victoria,VIC,-36.2843,147.0692,3
3691,Lucyvale,Victoria,VIC,-36.2833,147.6167,4
3691,Osbornes Flat,Victoria,VIC,-36.3167,146.8833,4
3691,Leneva,Victoria,VIC,-36.2167,146.9,4
3691,Barnawartha North,Victoria,VIC,-36.2843,147.0692,3
3691,Mongans Bridge,Victoria,VIC,-36.6,147.1,4
3691,Huon Creek,Victoria,VIC,-36.2843,147.0692,3
3691,Staghorn Flat,Victoria,VIC,-36.2522,146.9228,3
3691,Ebden,Victoria,VIC,-36.153,147.0252,4
3694,Bandiana Milpo,Victoria,VIC,-36.1454,146.9188,1
3695,Huon,Victoria,VIC,-36.2333,147.0667,4
3695,Charleroi,Victoria,VIC,-36.332,147.1146,3
3695,Sandy Creek,Victoria,VIC,-36.3,147.1,4
3695,Red Bluff,Victoria,VIC,-36.2667,147.1,4
3697,Tawonga,Victoria,VIC,-36.6895,147.1421,4
3698,Tawonga South,Victoria,VIC,-36.7359,147.1521,1
3699,Mount Beauty,Victoria,VIC,-36.7363,147.1753,4
3699,Nelse,Victoria,VIC,-36.8005,147.2233,3
3699,Falls Creek,Victoria,VIC,-36.8653,147.2778,4
3699,Bogong,Victoria,VIC,-36.8047,147.2237,4
3700,Georges Creek,Victoria,VIC,-36.1833,147.2833,4
3700,Tallangatta,Victoria,VIC,-36.2179,147.177,4
3700,Jarvis Creek,Victoria,VIC,-36.2004,147.2646,3
3700,Bullioh,Victoria,VIC,-36.2,147.3333,4
3700,Tallangatta East,Victoria,VIC,-36.2004,147.2646,3
3701,Tallandoon,Victoria,VIC,-36.45,147.2,4
3701,Shelley,Victoria,VIC,-36.4158,147.4436,3
3701,Old Tallangatta,Victoria,VIC,-36.1929,147.2521,4
3701,Tallangatta South,Victoria,VIC,-36.3737,147.3266,3
3701,Mitta Mitta,Victoria,VIC,-36.55,147.3833,4
3701,Eskdale,Victoria,VIC,-36.4667,147.2333,4
3701,Tallangatta Valley,Victoria,VIC,-36.2936,147.389,4
3701,Dartmouth,Victoria,VIC,-36.5462,147.5116,4
3701,Granya,Victoria,VIC,-36.1167,147.3167,4
3704,Koetong,Victoria,VIC,-36.15,147.5,4
3705,Nariel Valley,Victoria,VIC,-36.3112,147.726,3
3705,Cudgewa,Victoria,VIC,-36.2,147.75,4
3707,Biggara,Victoria,VIC,-36.2667,148.0333,4
3707,Tom Groggin,Victoria,VIC,-36.5192,148.127,4
3707,Towong,Victoria,VIC,-36.1333,147.9833,4
3707,Corryong,Victoria,VIC,-36.197,147.9026,4
3707,Thowgla Valley,Victoria,VIC,-36.4104,147.9589,3
3707,Colac Colac,Victoria,VIC,-36.4104,147.9589,3
3707,Towong Upper,Victoria,VIC,-36.2167,148.0333,4
3707,Nariel Valley,Victoria,VIC,-36.2034,147.9881,3
3708,Tintaldra,Victoria,VIC,-36.05,147.9333,4
3709,Walwa,Victoria,VIC,-35.9667,147.7333,4
3709,Mount Alfred,Victoria,VIC,-36.0222,147.6444,3
3709,Burrowye,Victoria,VIC,-36.0333,147.55,4
3709,Guys Forest,Victoria,VIC,-36.0667,147.65,4
3709,Pine Mountain,Victoria,VIC,-36.0222,147.6444,3
3711,Buxton,Victoria,VIC,-37.4167,145.7167,4
3712,Thornton,Victoria,VIC,-37.2558,145.7964,4
3712,Rubicon,Victoria,VIC,-37.256,145.7977,3
3713,Eildon,Victoria,VIC,-37.2324,145.9126,4
3713,Lake Eildon,Victoria,VIC,-37.2324,145.9126,3
3713,Taylor Bay,Victoria,VIC,-37.2324,145.9126,3
3714,Alexandra,Victoria,VIC,-37.1913,145.7112,4
3714,Acheron,Victoria,VIC,-37.25,145.7,4
3714,Cathkin,Victoria,VIC,-37.15,145.5833,4
3714,Maintongoon,Victoria,VIC,-37.2041,145.6741,3
3714,Koriella,Victoria,VIC,-37.1667,145.65,4
3714,Whanregarwen,Victoria,VIC,-37.2041,145.6741,3
3714,Fawcett,Victoria,VIC,-37.1333,145.6833,4
3714,Crystal Creek,Victoria,VIC,-37.2041,145.6741,3
3714,Taggerty,Victoria,VIC,-37.3333,145.7167,4
3714,Devils River,Victoria,VIC,-37.2041,145.6741,3
3715,Merton,Victoria,VIC,-36.9768,145.7108,4
3715,Ancona,Victoria,VIC,-36.9833,145.8167,4
3715,Woodfield,Victoria,VIC,-37.0167,145.8,4
3717,Flowerdale,Victoria,VIC,-37.3333,145.3333,4
3717,Homewood,Victoria,VIC,-37.2,145.3333,4
3717,Killingworth,Victoria,VIC,-37.2962,145.424,3
3717,Yea,Victoria,VIC,-37.2107,145.4276,4
3717,Limestone,Victoria,VIC,-37.25,145.5,4
3717,Murrindindi,Victoria,VIC,-37.3667,145.5333,4
3717,Ghin Ghin,Victoria,VIC,-37.2962,145.424,3
3717,Glenburn,Victoria,VIC,-37.4167,145.4167,4
3718,Molesworth,Victoria,VIC,-37.1667,145.5333,4
3719,Yarck,Victoria,VIC,-37.1,145.6167,4
3719,Gobur,Victoria,VIC,-37.0167,145.6,4
3719,Terip Terip,Victoria,VIC,-37,145.5667,4
3719,Kanumbra,Victoria,VIC,-37.05,145.6667,4
3720,Bonnie Doon,Victoria,VIC,-37.0167,145.8667,4
3722,Barwite,Victoria,VIC,-37,146.1667,4
3722,Mirimbah,Victoria,VIC,-37.1103,146.3985,4
3722,Mansfield,Victoria,VIC,-37.052,146.0885,4
3723,Howes Creek,Victoria,VIC,-37.1667,146,4
3723,Howqua Inlet,Victoria,VIC,-37.251,146.1822,3
3723,Gaffneys Creek,Victoria,VIC,-37.4667,146.1833,4
3723,Enochs Point,Victoria,VIC,-37.4167,146.1,4
3723,Archerton,Victoria,VIC,-36.8833,146.2,4
3723,Sawmill Settlement,Victoria,VIC,-37.251,146.1822,3
3723,Knockwood,Victoria,VIC,-37.45,146.2333,4
3723,Howqua,Victoria,VIC,-37.2333,146.1167,4
3723,Mount Buller,Victoria,VIC,-37.146,146.4369,4
3723,Mountain Bay,Victoria,VIC,-37.251,146.1822,3
3723,Goughs Bay,Victoria,VIC,-37.1767,146.0635,3
3723,Howqua Hills,Victoria,VIC,-37.2598,146.4295,4
3723,Delatite,Victoria,VIC,-37.15,146.1333,4
3723,Kevington,Victoria,VIC,-37.3667,146.15,4
3723,Piries,Victoria,VIC,-37.2,146.1333,4
3723,Matlock,Victoria,VIC,-37.6,146.2167,4
3723,Merrijig,Victoria,VIC,-37.1,146.25,4
3723,Barjarg,Victoria,VIC,-37.251,146.1822,3
3723,Maindample,Victoria,VIC,-37.0333,145.9333,4
3723,Boorolite,Victoria,VIC,-37.251,146.1822,3
3723,Woods Point,Victoria,VIC,-37.5833,146.25,4
3723,Bridge Creek,Victoria,VIC,-37.251,146.1822,3
3723,Macs Cove,Victoria,VIC,-37.2125,146.1464,4
3723,Jamieson,Victoria,VIC,-37.3167,146.1333,4
3723,Tolmie,Victoria,VIC,-36.9333,146.2333,4
3724,Mansfield,Victoria,VIC,-37.052,146.0885,4
3725,Goorambat,Victoria,VIC,-36.4167,145.9167,4
3725,Chesney Vale,Victoria,VIC,-36.3833,145.8292,3
3725,Boxwood,Victoria,VIC,-36.3167,145.8,4
3725,Major Plains,Victoria,VIC,-36.3667,145.8,4
3725,Stewarton,Victoria,VIC,-36.4333,145.8,4
3726,Bungeet,Victoria,VIC,-36.3,146.05,4
3726,Bungeet West,Victoria,VIC,-36.3167,146.0056,3
3726,Thoona,Victoria,VIC,-36.3167,146.0667,4
3726,Devenish,Victoria,VIC,-36.3333,145.9,4
3727,Yundool,Victoria,VIC,-36.2667,145.8333,4
3727,Almonds,Victoria,VIC,-36.2,145.9667,4
3727,Lake Rowan,Victoria,VIC,-36.2667,145.9667,4
3727,Pelluebla,Victoria,VIC,-36.25,145.9167,3
3727,St James,Victoria,VIC,-36.2667,145.9,4
3728,Tungamah,Victoria,VIC,-36.161,145.8768,4
3728,Boomahnoomoonah,Victoria,VIC,-36.1265,146.1321,4
3728,Wilby,Victoria,VIC,-36.1574,146.022,4
3728,Youarang,Victoria,VIC,-36.1767,145.791,4
3730,Burramine,Victoria,VIC,-36.0639,145.854,4
3730,Yarrawonga,Victoria,VIC,-36.0192,145.9997,4
3730,Esmond,Victoria,VIC,-36.0797,146.1833,4
3730,Boosey,Victoria,VIC,-36.05,145.7833,4
3730,Bathumi,Victoria,VIC,-36.0367,146.0793,4
3730,Bundalong,Victoria,VIC,-36.0298,146.1581,4
3730,Yarrawonga South,Victoria,VIC,-36.0521,146.0092,3
3730,Telford,Victoria,VIC,-36.0966,145.9337,4
3730,Burramine South,Victoria,VIC,-36.0639,145.854,4
3730,Bundalong South,Victoria,VIC,-36.0955,146.1876,4
3732,Moyhu,Victoria,VIC,-36.578,146.3784,4
3732,Myrrhee,Victoria,VIC,-36.75,146.3167,4
3733,Whitfield,Victoria,VIC,-36.7833,146.4167,4
3735,Whorouly South,Victoria,VIC,-36.55,146.5833,4
3735,Whorouly,Victoria,VIC,-36.5167,146.6,4
3735,Bowmans Forest,Victoria,VIC,-36.5706,146.5783,3
3735,Whorouly East,Victoria,VIC,-36.5333,146.5917,3
3736,Myrtleford,Victoria,VIC,-36.561,146.7237,4
3737,Buffalo River,Victoria,VIC,-36.6333,146.6833,4
3737,Abbeyard,Victoria,VIC,-36.6267,146.7254,3
3737,Myrtleford,Victoria,VIC,-36.561,146.7237,4
3737,Gapsted,Victoria,VIC,-36.5167,146.6833,4
3737,Mudgegonga,Victoria,VIC,-36.4897,146.8658,3
3737,Nug Nug,Victoria,VIC,-36.6656,146.7178,4
3737,Merriang South,Victoria,VIC,-36.6267,146.7254,3
3737,Havilah,Victoria,VIC,-36.6267,146.7254,3
3737,Wonnangatta,Victoria,VIC,-36.6267,146.7254,3
3737,Rosewhite,Victoria,VIC,-36.5833,146.8773,4
3737,Merriang,Victoria,VIC,-36.6267,146.7254,3
3737,Barwidgee,Victoria,VIC,-36.6267,146.7254,3
3737,Selwyn,Victoria,VIC,-36.6267,146.7254,3
3737,Dandongadale,Victoria,VIC,-36.8,146.6667,4
3738,Ovens,Victoria,VIC,-36.5874,146.7593,4
3739,Eurobin,Victoria,VIC,-36.6352,146.8596,4
3740,Mount Buffalo,Victoria,VIC,-36.7829,146.8699,3
3740,Porepunkah,Victoria,VIC,-36.6961,146.9104,4
3740,Buckland,Victoria,VIC,-36.8833,146.85,4
3741,Smoko,Victoria,VIC,-36.8202,147.0642,4
3741,Mount Hotham,Victoria,VIC,-37.0712,147.0949,3
3741,Bright,Victoria,VIC,-36.73,146.9598,4
3741,Germantown,Victoria,VIC,-36.7427,147.0153,4
3741,Hotham Heights,Victoria,VIC,-36.993,147.1523,4
3741,Harrietville,Victoria,VIC,-36.8915,147.0619,4
3741,Freeburgh,Victoria,VIC,-36.7599,147.0263,4
3744,Wandiligong,Victoria,VIC,-36.7833,146.9833,4
3746,Eldorado,Victoria,VIC,-36.3,146.5167,4
3747,Wooragee,Victoria,VIC,-36.2833,146.7833,4
3747,Baarmutha,Victoria,VIC,-36.4167,146.6833,4
3747,Murmungee,Victoria,VIC,-36.45,146.6833,4
3747,Stanley,Victoria,VIC,-36.4167,146.75,4
3747,Beechworth,Victoria,VIC,-36.3583,146.6858,4
3749,Bruarong,Victoria,VIC,-36.4167,146.85,4
3749,Yackandandah,Victoria,VIC,-36.3137,146.8396,4
3750,Wollert,Victoria,VIC,-37.5833,145.0333,4
3751,Woodstock,Victoria,VIC,-37.55,145.0333,4
3752,South Morang,Victoria,VIC,-37.65,145.1,4
3753,Beveridge,Victoria,VIC,-37.4833,144.9833,4
3754,Mernda,Victoria,VIC,-37.6007,145.0956,4
3754,Doreen,Victoria,VIC,-37.6,145.15,4
3755,Yan Yean,Victoria,VIC,-37.5667,145.1,4
3756,Hidden Valley,Victoria,VIC,-37.4369,144.8946,3
3756,Upper Plenty,Victoria,VIC,-37.4197,145.0547,4
3756,Chintin,Victoria,VIC,-37.418,145.0166,3
3756,Wallan,Victoria,VIC,-37.4162,144.9786,4
3756,Darraweit Guim,Victoria,VIC,-37.4369,144.8946,3
3757,Kinglake Central,Victoria,VIC,-37.5,145.3,4
3757,Pheasant Creek,Victoria,VIC,-37.4833,145.2833,4
3757,Humevale,Victoria,VIC,-37.5,145.1667,4
3757,Kinglake West,Victoria,VIC,-37.4667,145.2333,4
3757,Whittlesea,Victoria,VIC,-37.5115,145.1184,4
3757,Eden Park,Victoria,VIC,-37.4833,145.0833,4
3758,Wandong,Victoria,VIC,-37.3719,145.0321,4
3758,Heathcote Junction,Victoria,VIC,-37.3719,145.0321,4
3759,Panton Hill,Victoria,VIC,-37.6408,145.2399,4
3760,Smiths Gully,Victoria,VIC,-37.6202,145.2603,4
3761,St Andrews,Victoria,VIC,-37.5782,145.3074,1
3762,Bylands,Victoria,VIC,-37.35,144.9667,4
3763,Kinglake,Victoria,VIC,-37.5329,145.3396,4
3764,Tantaraboo,Victoria,VIC,-37.4658,145.0672,3
3764,Kilmore,Victoria,VIC,-37.2958,144.9525,4
3764,High Camp,Victoria,VIC,-37.1833,144.9,4
3764,Kilmore East,Victoria,VIC,-37.3,144.9833,4
3764,Moranding,Victoria,VIC,-37.4658,145.0672,3
3764,Forbes,Victoria,VIC,-38.45,145.5333,4
3764,Glenaroua,Victoria,VIC,-37.1,144.9667,4
3764,Willowmavin,Victoria,VIC,-37.4658,145.0672,3
3765,Montrose,Victoria,VIC,-37.8167,145.35,4
3766,Kalorama,Victoria,VIC,-37.8167,145.3667,4
3767,Mount Dandenong,Victoria,VIC,-37.8333,145.3667,4
3770,Coldstream,Victoria,VIC,-37.7249,145.378,4
3770,Gruyere,Victoria,VIC,-37.7333,145.45,4
3770,Yering,Victoria,VIC,-37.7,145.3667,4
3775,Dixons Creek,Victoria,VIC,-37.6,145.4167,4
3775,Yarra Glen,Victoria,VIC,-37.6579,145.3742,4
3775,Christmas Hills,Victoria,VIC,-37.65,145.3333,4
3775,Steels Creek,Victoria,VIC,-37.5833,145.3833,4
3775,Tarrawarra,Victoria,VIC,-37.65,145.4333,4
3777,Healesville,Victoria,VIC,-37.654,145.5172,4
3777,Chum Creek,Victoria,VIC,-37.6051,145.4874,4
3777,Healesville Main Street,Victoria,VIC,-37.6018,145.4842,3
3777,Badger Creek,Victoria,VIC,-37.7,145.5167,4
3777,Toolangi,Victoria,VIC,-37.5333,145.4667,4
3777,Castella,Victoria,VIC,-37.5167,145.4333,4
3777,Mount Toolebewong,Victoria,VIC,-37.6984,145.5625,4
3778,Narbethong,Victoria,VIC,-37.5639,145.6557,4
3778,Fernshaw,Victoria,VIC,-37.6,145.6333,4
3779,Cambarville,Victoria,VIC,-37.5588,145.8906,4
3779,Marysville,Victoria,VIC,-37.5098,145.7481,4
3781,Mount Burnett,Victoria,VIC,-37.9833,145.5,4
3781,Nangana,Victoria,VIC,-37.8667,145.5333,4
3781,Cockatoo,Victoria,VIC,-37.9368,145.4938,4
3782,Avonsleigh,Victoria,VIC,-37.9333,145.4667,4
3782,Macclesfield,Victoria,VIC,-37.8833,145.4833,4
3782,Clematis,Victoria,VIC,-37.9333,145.4167,4
3782,Emerald,Victoria,VIC,-37.9317,145.4409,4
3783,Gembrook,Victoria,VIC,-37.9532,145.5554,4
3785,Tremont,Victoria,VIC,-37.9,145.3333,4
3786,Ferny Creek,Victoria,VIC,-37.8833,145.3333,4
3787,Sassafras Gully,Victoria,VIC,-37.8667,145.35,3
3787,Sassafras,Victoria,VIC,-37.8667,145.35,4
3788,Olinda,Victoria,VIC,-37.85,145.3667,4
3789,Sherbrooke,Victoria,VIC,-37.8833,145.35,4
3791,Kallista,Victoria,VIC,-37.8833,145.3667,4
3792,The Patch,Victoria,VIC,-37.9,145.3833,4
3793,Monbulk,Victoria,VIC,-37.8833,145.4,4
3795,Silvan,Victoria,VIC,-37.8167,145.4167,4
3796,Mount Evelyn,Victoria,VIC,-37.7833,145.3833,4
3797,Gladysdale,Victoria,VIC,-37.8,145.65,4
3797,Powelltown,Victoria,VIC,-37.8667,145.75,4
3797,Three Bridges,Victoria,VIC,-37.8454,145.6845,4
3797,Gilderoy,Victoria,VIC,-37.8667,145.7167,4
3797,Yarra Junction,Victoria,VIC,-37.7819,145.6143,4
3799,Big Pats Creek,Victoria,VIC,-37.7833,145.75,4
3799,Mcmahons Creek,Victoria,VIC,-37.7167,145.8333,4
3799,Warburton,Victoria,VIC,-37.7537,145.6904,4
3799,Reefton,Victoria,VIC,-37.752,145.7172,3
3799,East Warburton,Victoria,VIC,-37.7364,145.7429,4
3799,Millgrove,Victoria,VIC,-37.7553,145.6535,4
3799,Wesburn,Victoria,VIC,-37.7667,145.6333,4
3800,Monash University,Victoria,VIC,-37.9106,145.1348,1
3802,Endeavour Hills,Victoria,VIC,-37.977,145.2587,4
3803,Hallam,Victoria,VIC,-38.0167,145.2667,4
3804,Narre Warren North,Victoria,VIC,-37.9833,145.3167,4
3804,Narre Warren East,Victoria,VIC,-37.9639,145.3629,4
3805,Narre Warren,Victoria,VIC,-38.0333,145.3,4
3805,Fountain Gate,Victoria,VIC,-38.0263,145.3069,4
3805,Narre Warren South,Victoria,VIC,-38.0437,145.2923,4
3806,Harkaway,Victoria,VIC,-38,145.35,4
3806,Berwick,Victoria,VIC,-38.0333,145.35,4
3807,Guys Hill,Victoria,VIC,-38.0161,145.3871,4
3807,Beaconsfield,Victoria,VIC,-38.05,145.3667,4
3808,Dewhurst,Victoria,VIC,-37.9801,145.4669,4
3808,Beaconsfield Upper,Victoria,VIC,-37.9941,145.4142,4
3809,Officer,Victoria,VIC,-38.0592,145.4095,4
3809,Officer South,Victoria,VIC,-38.0863,145.4021,4
3810,Rythdale,Victoria,VIC,-38.1333,145.4333,4
3810,Pakenham South,Victoria,VIC,-38.1333,145.5167,4
3810,Pakenham,Victoria,VIC,-38.0702,145.4741,4
3810,Pakenham Upper,Victoria,VIC,-38.0167,145.5167,4
3812,Maryknoll,Victoria,VIC,-38.036,145.5948,4
3812,Nar Nar Goon,Victoria,VIC,-38.0825,145.5701,4
3812,Nar Nar Goon North,Victoria,VIC,-38.0167,145.5667,4
3813,Tynong,Victoria,VIC,-38.0834,145.6246,4
3813,Tynong North,Victoria,VIC,-38.0667,145.6167,4
3814,Cora Lynn,Victoria,VIC,-38.15,145.6,4
3814,Garfield North,Victoria,VIC,-38.0333,145.6833,4
3814,Garfield,Victoria,VIC,-38.0899,145.675,4
3814,Vervale,Victoria,VIC,-38.1333,145.6667,4
3815,Tonimbuk,Victoria,VIC,-38.0167,145.7333,4
3815,Bunyip,Victoria,VIC,-38.0979,145.7161,4
3815,Bunyip North,Victoria,VIC,-38.0634,145.7206,4
3815,Iona,Victoria,VIC,-38.1333,145.6833,4
3816,Labertouche,Victoria,VIC,-38.05,145.8,4
3816,Longwarry North,Victoria,VIC,-38.0833,145.7667,4
3816,Longwarry,Victoria,VIC,-38.1128,145.7685,4
3816,Modella,Victoria,VIC,-38.1833,145.7167,4
3818,Drouin West,Victoria,VIC,-38.1,145.8667,4
3818,Ripplebrook,Victoria,VIC,-38.2001,145.7725,3
3818,Drouin South,Victoria,VIC,-38.1667,145.8333,4
3818,Drouin East,Victoria,VIC,-38.1561,145.8431,3
3818,Hallora,Victoria,VIC,-38.2667,145.8333,4
3818,Athlone,Victoria,VIC,-38.2333,145.7667,4
3818,Drouin,Victoria,VIC,-38.1366,145.8584,4
3818,Jindivick,Victoria,VIC,-38.0333,145.9,4
3820,Warragul,Victoria,VIC,-38.1591,145.9312,4
3820,Bona Vista,Victoria,VIC,-38.1462,145.9406,3
3820,Lillico,Victoria,VIC,-38.1333,145.95,4
3821,Brandy Creek,Victoria,VIC,-38.1,145.9,4
3821,Lardner,Victoria,VIC,-38.2,145.8667,4
3821,Seaview,Victoria,VIC,-38.1382,145.9483,3
3821,Crossover,Victoria,VIC,-38.05,145.9667,4
3821,Nilma North,Victoria,VIC,-38.15,145.9833,4
3821,Rokeby,Victoria,VIC,-38.0667,145.9333,4
3821,Neerim North,Victoria,VIC,-38.1952,145.9567,3
3821,Buln Buln East,Victoria,VIC,-38.0833,146,4
3821,Nilma,Victoria,VIC,-38.1833,145.9667,4
3821,Neerim Junction,Victoria,VIC,-37.9296,145.9612,4
3821,Torwood,Victoria,VIC,-38.1382,145.9483,3
3821,Shady Creek,Victoria,VIC,-38.1167,146.05,4
3821,Bravington,Victoria,VIC,-38.1382,145.9483,3
3821,Tetoora Road,Victoria,VIC,-38.2667,145.9,4
3821,Ferndale,Victoria,VIC,-38.3167,145.9333,4
3821,Ellinbank,Victoria,VIC,-38.2333,145.9333,4
3821,Warragul South,Victoria,VIC,-38.1382,145.9483,3
3821,Warragul West,Victoria,VIC,-38.1382,145.9483,3
3821,Buln Buln,Victoria,VIC,-38.1,145.9333,4
3822,Gainsborough,Victoria,VIC,-38.2333,145.9667,4
3822,Cloverlea,Victoria,VIC,-38.2333,146,4
3822,Darnum,Victoria,VIC,-38.1885,146.0053,4
3823,Yarragon,Victoria,VIC,-38.2052,146.0608,4
3823,Yarragon South,Victoria,VIC,-38.236,146.0471,3
3823,Allambee,Victoria,VIC,-38.2667,146.0333,4
3824,Trafalgar South,Victoria,VIC,-38.2667,146.15,4
3824,Trafalgar,Victoria,VIC,-38.2091,146.1535,4
3824,Thorpdale South,Victoria,VIC,-38.3,146.1667,4
3824,Childers,Victoria,VIC,-38.2993,146.1139,4
3824,Trafalgar East,Victoria,VIC,-38.1833,146.1833,4
3824,Narracan,Victoria,VIC,-38.25,146.2333,4
3825,Yallourn,Victoria,VIC,-38.1783,146.261,4
3825,Jacob Creek,Victoria,VIC,-38.0481,146.1801,3
3825,Hernes Oak,Victoria,VIC,-38.1956,146.3258,3
3825,Walhalla East,Victoria,VIC,-38.0481,146.1801,3
3825,Aberfeldy,Victoria,VIC,-37.6833,146.3667,4
3825,Jericho,Victoria,VIC,-38.0481,146.1801,3
3825,Rawson,Victoria,VIC,-37.9546,146.3993,4
3825,Tanjil South,Victoria,VIC,-38.1167,146.25,4
3825,Willow Grove,Victoria,VIC,-38.0833,146.2,4
3825,Amor,Victoria,VIC,-38.0481,146.1801,3
3825,Yallourn North,Victoria,VIC,-38.1605,146.3645,4
3825,Thomson,Victoria,VIC,-38.1698,144.3789,4
3825,Fumina,Victoria,VIC,-37.8833,146.1,4
3825,Coalville,Victoria,VIC,-38.25,146.2667,4
3825,Moe,Victoria,VIC,-38.1783,146.261,4
3825,Moondarra,Victoria,VIC,-38.0167,146.3667,4
3825,Coopers Creek,Victoria,VIC,-37.9833,146.4333,4
3825,Caringal,Victoria,VIC,-38.0481,146.1801,3
3825,Fumina South,Victoria,VIC,-37.95,146.1167,4
3825,Westbury,Victoria,VIC,-38.1363,146.2175,4
3825,Boola,Victoria,VIC,-38.0481,146.1801,3
3825,Hill End,Victoria,VIC,-38.0167,146.15,4
3825,Thalloo,Victoria,VIC,-38.0481,146.1801,3
3825,Erica,Victoria,VIC,-37.9833,146.3667,4
3825,Tanjil,Victoria,VIC,-38.0481,146.1801,3
3825,Walhalla,Victoria,VIC,-37.95,146.45,4
3825,Moe South,Victoria,VIC,-38.0481,146.1801,3
3825,Toombon,Victoria,VIC,-38.0481,146.1801,3
3825,Newborough,Victoria,VIC,-38.1717,146.2926,4
3831,Neerim East,Victoria,VIC,-37.9833,146,4
3831,Neerim,Victoria,VIC,-37.9667,145.95,4
3831,Neerim South,Victoria,VIC,-38.0168,145.9545,4
3832,Neerim Junction,Victoria,VIC,-37.9296,145.9612,4
3832,Neerim North,Victoria,VIC,-37.9315,145.9556,3
3832,Nayook,Victoria,VIC,-37.9333,145.95,4
3833,Toorongo,Victoria,VIC,-37.8728,146.1029,3
3833,Icy Creek,Victoria,VIC,-37.8728,146.1029,3
3833,Vesper,Victoria,VIC,-37.9167,146.0833,4
3833,Gentle Annie,Victoria,VIC,-37.938,145.8359,4
3833,Loch Valley,Victoria,VIC,-37.8333,146,4
3833,Ada,Victoria,VIC,-37.8728,146.1029,3
3833,Baw Baw Village,Victoria,VIC,-37.8378,146.2499,4
3833,Noojee,Victoria,VIC,-37.9167,146,4
3833,Piedmont,Victoria,VIC,-37.8728,146.1029,3
3833,Tanjil Bren,Victoria,VIC,-37.9222,146.2121,4
3833,Baw Baw,Victoria,VIC,-37.7412,146.331,4
3835,Thorpdale,Victoria,VIC,-38.2833,146.1833,4
3840,Hazelwood,Victoria,VIC,-38.2833,146.35,4
3840,Driffield,Victoria,VIC,-38.3137,146.4182,3
3840,Hazelwood South,Victoria,VIC,-38.317,146.4626,4
3840,Morwell,Victoria,VIC,-38.2348,146.395,4
3840,Maryvale,Victoria,VIC,-38.3137,146.4182,3
3840,Mid Valley,Victoria,VIC,-38.2994,146.483,3
3840,Jeeralang,Victoria,VIC,-38.4038,146.4753,4
3840,Hazelwood North,Victoria,VIC,-38.3137,146.4182,3
3840,Jeeralang Junction,Victoria,VIC,-38.3333,146.4167,4
3841,Gippsland Mc,Victoria,VIC,-38.2458,146.6306,1
3842,Churchill,Victoria,VIC,-38.3078,146.4137,4
3844,Mount Tassie,Victoria,VIC,-38.3008,146.6242,3
3844,Callignee,Victoria,VIC,-38.3333,146.5833,4
3844,Tyers,Victoria,VIC,-38.1489,146.4804,4
3844,Flynn,Victoria,VIC,-38.1667,146.6833,4
3844,Carrajung Lower,Victoria,VIC,-38.4,146.7333,4
3844,Traralgon South,Victoria,VIC,-38.2667,146.55,4
3844,Blackwarry,Victoria,VIC,-38.4,146.65,4
3844,Carrajung South,Victoria,VIC,-38.3833,146.7333,4
3844,Koornalla,Victoria,VIC,-38.3167,146.5333,4
3844,Callignee South,Victoria,VIC,-38.3833,146.6167,4
3844,Flynns Creek,Victoria,VIC,-38.3008,146.6242,3
3844,Willung South,Victoria,VIC,-38.35,146.7333,4
3844,Traralgon East,Victoria,VIC,-38.3008,146.6242,3
3844,Carrajung,Victoria,VIC,-38.3667,146.6833,4
3844,Traralgon,Victoria,VIC,-38.1953,146.5415,4
3844,Callignee North,Victoria,VIC,-38.3167,146.6,4
3844,Loy Yang,Victoria,VIC,-38.1833,146.6167,4
3847,Rosedale,Victoria,VIC,-38.1545,146.7886,4
3847,Willung South,Victoria,VIC,-38.35,146.7333,4
3847,Willung,Victoria,VIC,-38.25,146.8167,4
3847,Hiamdale,Victoria,VIC,-38.2011,146.8055,3
3847,Nambrok,Victoria,VIC,-38.05,146.8833,4
3850,Wurruk,Victoria,VIC,-38.1167,147.0333,4
3850,Guthridge,Victoria,VIC,-38.106,147.0608,3
3850,Sale,Victoria,VIC,-38.111,147.068,4
3851,Fulham,Victoria,VIC,-38.1,146.9833,4
3851,Seaspray,Victoria,VIC,-38.3761,147.1906,4
3851,Seacombe,Victoria,VIC,-38.1167,147.4333,4
3851,Darriman,Victoria,VIC,-38.45,146.9333,4
3851,The Heart,Victoria,VIC,-38.1167,147.1667,4
3851,Kilmany,Victoria,VIC,-38.1,146.9,4
3851,Montgomery,Victoria,VIC,-38.0333,147.0667,4
3851,Golden Beach,Victoria,VIC,-38.2109,147.3987,4
3851,Giffard,Victoria,VIC,-38.4167,147.0833,4
3851,Airly,Victoria,VIC,-38.0333,147.1167,4
3851,The Honeysuckles,Victoria,VIC,-38.1661,147.1648,3
3851,Dutson,Victoria,VIC,-38.1833,147.1833,4
3851,Cobains,Victoria,VIC,-38.0833,147.15,4
3851,Paradise Beach,Victoria,VIC,-38.1962,147.4152,4
3851,Glomar Beach,Victoria,VIC,-38.1661,147.1648,3
3851,Loch Sport,Victoria,VIC,-38.0401,147.5918,4
3851,Bundalaguah,Victoria,VIC,-38.0333,147.0167,4
3851,Longford,Victoria,VIC,-38.1624,147.0859,4
3851,Clydebank,Victoria,VIC,-38.05,147.2,4
3851,Myrtlebank,Victoria,VIC,-38.1588,147.1833,3
3851,Stradbroke,Victoria,VIC,-38.3,147.0333,4
3851,Flamingo Beach,Victoria,VIC,-38.1661,147.1648,3
3851,Giffard West,Victoria,VIC,-38.1661,147.1648,3
3851,Dutson Downs,Victoria,VIC,-38.1661,147.1648,3
3851,Pearsondale,Victoria,VIC,-38.1661,147.1648,3
3851,Lake Wellington,Victoria,VIC,-38.1661,147.1648,3
3851,Somerton Park,Victoria,VIC,-38.1661,147.1648,3
3852,East Sale,Victoria,VIC,-38.1035,147.127,4
3852,East Sale Raaf,Victoria,VIC,-38.104,147.1424,3
3852,Sale East Raaf,Victoria,VIC,-38.1035,147.127,3
3853,Sale,Victoria,VIC,-38.111,147.068,4
3854,Glengarry,Victoria,VIC,-38.1167,146.5667,4
3854,Glengarry West,Victoria,VIC,-38.15,146.5167,4
3854,Glengarry North,Victoria,VIC,-38.1333,146.5417,3
3856,Toongabbie,Victoria,VIC,-38.05,146.6167,4
3857,Cowwarr,Victoria,VIC,-38.0167,146.6833,4
3858,Dawson,Victoria,VIC,-37.9833,146.7333,4
3858,Heyfield,Victoria,VIC,-37.9813,146.7856,4
3858,Licola,Victoria,VIC,-37.6333,146.6167,4
3858,Denison,Victoria,VIC,-38.0333,146.8833,4
3858,Arbuckle,Victoria,VIC,-37.9307,146.7432,3
3858,Gillum,Victoria,VIC,-37.9307,146.7432,3
3858,Yangoura,Victoria,VIC,-37.9307,146.7432,3
3858,Billabong,Victoria,VIC,-37.9307,146.7432,3
3858,Tamboritha,Victoria,VIC,-37.9307,146.7432,3
3858,Reynard,Victoria,VIC,-37.9307,146.7432,3
3858,Worrowing,Victoria,VIC,-37.9307,146.7432,3
3858,Seaton,Victoria,VIC,-37.9333,146.6667,4
3858,Crookayan,Victoria,VIC,-37.9307,146.7432,3
3858,Winnindoo,Victoria,VIC,-38.05,146.7667,4
3858,Glenmaggie,Victoria,VIC,-37.9,146.75,4
3858,Sargood,Victoria,VIC,-37.9307,146.7432,3
3858,Buragwonduc,Victoria,VIC,-37.9307,146.7432,3
3858,Glenfalloch,Victoria,VIC,-37.9307,146.7432,3
3858,Howitt Plains,Victoria,VIC,-37.9307,146.7432,3
3858,Licola North,Victoria,VIC,-37.9307,146.7432,3
3859,Newry,Victoria,VIC,-37.9167,146.8833,4
3859,Tinamba West,Victoria,VIC,-37.9264,146.8749,3
3859,Maffra West Upper,Victoria,VIC,-37.9,146.85,4
3859,Tinamba,Victoria,VIC,-37.9625,146.8914,4
3860,Riverslea,Victoria,VIC,-38,146.95,4
3860,Woolenook,Victoria,VIC,-37.8985,146.9703,3
3860,Coongulla,Victoria,VIC,-37.8913,146.786,4
3860,Nap Nap Marra,Victoria,VIC,-37.8985,146.9703,3
3860,Wrathung,Victoria,VIC,-37.8985,146.9703,3
3860,Bushy Park,Victoria,VIC,-37.8667,147.0333,4
3860,Wrixon,Victoria,VIC,-37.8985,146.9703,3
3860,Koorool,Victoria,VIC,-37.8985,146.9703,3
3860,Toolome,Victoria,VIC,-37.8985,146.9703,3
3860,Briagolong,Victoria,VIC,-37.8436,147.0727,4
3860,Maffra,Victoria,VIC,-37.9713,146.9837,4
3860,Boisdale,Victoria,VIC,-37.8833,146.9833,4
3860,Moroka,Victoria,VIC,-37.8985,146.9703,3
3860,Monomak,Victoria,VIC,-37.8985,146.9703,3
3860,Valencia Creek,Victoria,VIC,-37.8333,146.9833,4
3862,Llowalong,Victoria,VIC,-37.9333,147.0667,4
3862,Budgee Budgee,Victoria,VIC,-37.8052,147.2018,3
3862,Dargo,Victoria,VIC,-37.4833,147.2667,4
3862,Meerlieu,Victoria,VIC,-38,147.4,4
3862,Munro,Victoria,VIC,-37.9167,147.1833,4
3862,Hollands Landing,Victoria,VIC,-37.8052,147.2018,3
3862,Stockdale,Victoria,VIC,-37.8,147.1667,4
3862,Cobbannah,Victoria,VIC,-37.6333,147.1833,4
3862,Perry Bridge,Victoria,VIC,-38,147.2667,4
3862,Crooked River,Victoria,VIC,-37.8052,147.2018,3
3862,Stratford,Victoria,VIC,-37.9634,147.0826,4
3862,Hawkhurst,Victoria,VIC,-37.8052,147.2018,3
3862,Waterford,Victoria,VIC,-37.5167,147.2,4
3862,Miowera,Victoria,VIC,-37.8052,147.2018,3
3862,Wongungarra,Victoria,VIC,-37.8052,147.2018,3
3862,Cowa,Victoria,VIC,-37.8052,147.2018,3
3862,Moornapa,Victoria,VIC,-37.8052,147.2018,3
3864,Glenaladale,Victoria,VIC,-37.7833,147.35,4
3864,Fernbank,Victoria,VIC,-37.8667,147.3167,4
3865,Lindenow,Victoria,VIC,-37.8005,147.4598,4
3869,Yinnar,Victoria,VIC,-38.3225,146.3292,4
3869,Yinnar South,Victoria,VIC,-38.3833,146.3833,4
3869,Jumbuk,Victoria,VIC,-38.4,146.4278,4
3870,Budgeree,Victoria,VIC,-38.4167,146.3333,4
3870,Johnstones Hill,Victoria,VIC,-38.4153,146.2973,3
3870,Boolarra,Victoria,VIC,-38.3794,146.2753,4
3870,Grand Ridge,Victoria,VIC,-38.4153,146.2973,3
3870,Boolarra South,Victoria,VIC,-38.45,146.2833,4
3871,Mirboo North,Victoria,VIC,-38.401,146.1613,4
3871,Dollar,Victoria,VIC,-38.5667,146.1667,4
3871,Allambee Reserve,Victoria,VIC,-38.4141,146.1797,3
3871,Darlimurla,Victoria,VIC,-38.3667,146.2167,4
3871,Allambee South,Victoria,VIC,-38.35,146.1,4
3871,Baromi,Victoria,VIC,-38.4141,146.1797,3
3871,Mirboo,Victoria,VIC,-38.4667,146.2,4
3871,Delburn,Victoria,VIC,-38.3333,146.2333,4
3873,Gormandale,Victoria,VIC,-38.2833,146.7,4
3874,Woodside,Victoria,VIC,-38.5238,146.8751,4
3874,Mcloughlins Beach,Victoria,VIC,-38.4536,146.8042,3
3874,Woodside North,Victoria,VIC,-38.4536,146.8042,3
3874,Carrajung South,Victoria,VIC,-38.3833,146.7333,4
3874,Woodside Beach,Victoria,VIC,-38.4536,146.8042,3
3875,Bairnsdale,Victoria,VIC,-37.8229,147.6104,4
3875,Granite Rock,Victoria,VIC,-37.7569,147.672,4
3875,Lucknow,Victoria,VIC,-37.8167,147.65,4
3875,Merrijig,Victoria,VIC,-37.1,146.25,4
3875,Hillside,Victoria,VIC,-37.8333,147.5,4
3875,Goon Nure,Victoria,VIC,-37.95,147.65,4
3875,Walpa,Victoria,VIC,-37.8,147.45,4
3875,Deptford,Victoria,VIC,-37.5833,147.7,4
3875,Eastwood,Victoria,VIC,-37.7479,147.4935,3
3875,Lindenow South,Victoria,VIC,-37.8167,147.4333,4
3875,Woodglen,Victoria,VIC,-37.75,147.4833,4
3875,Wentworth,Victoria,VIC,-37.7479,147.4935,3
3875,Forge Creek,Victoria,VIC,-37.9,147.6333,4
3875,Tabberabbera,Victoria,VIC,-37.5833,147.3833,4
3875,Calulu,Victoria,VIC,-37.8,147.5167,4
3875,Waterholes,Victoria,VIC,-37.7479,147.4935,3
3875,Clifton Creek,Victoria,VIC,-37.7078,147.6658,4
3875,Marthavale,Victoria,VIC,-37.7479,147.4935,3
3875,Bullumwaal,Victoria,VIC,-37.6333,147.5333,4
3875,Broadlands,Victoria,VIC,-37.7479,147.4935,3
3875,Wy Yung,Victoria,VIC,-37.8,147.5833,4
3875,Banksia Peninsula,Victoria,VIC,-37.7479,147.4935,3
3875,Newlands Arm,Victoria,VIC,-37.9241,147.698,3
3875,Ellaswood,Victoria,VIC,-37.7479,147.4935,3
3875,East Bairnsdale,Victoria,VIC,-37.8229,147.6104,4
3875,Iguana Creek,Victoria,VIC,-37.7333,147.3167,4
3875,Wuk Wuk,Victoria,VIC,-37.7667,147.4333,4
3875,Mount Taylor,Victoria,VIC,-37.7437,147.5994,4
3875,Fairy Dell,Victoria,VIC,-36.3333,144.75,4
3875,Melwood,Victoria,VIC,-37.7479,147.4935,3
3875,Ryans,Victoria,VIC,-37.7479,147.4935,3
3875,Flaggy Creek,Victoria,VIC,-37.7479,147.4935,3
3875,Sarsfield,Victoria,VIC,-37.75,147.7167,4
3875,Bengworden,Victoria,VIC,-37.9833,147.4667,4
3878,Eagle Point,Victoria,VIC,-37.9,147.6833,4
3880,Paynesville,Victoria,VIC,-37.9189,147.7194,4
3880,Boole Poole,Victoria,VIC,-37.9178,147.7264,3
3880,Ocean Grange,Victoria,VIC,-37.9178,147.7264,3
3880,Raymond Island,Victoria,VIC,-37.9167,147.7333,4
3882,Nicholson,Victoria,VIC,-37.8167,147.7333,4
3885,Wulgulmerang,Victoria,VIC,-37.0833,148.2833,4
3885,Wulgulmerang East,Victoria,VIC,-37.0833,148.2833,4
3885,Buchan South,Victoria,VIC,-37.5667,148.1167,4
3885,Suggan Buggan,Victoria,VIC,-36.9556,148.3276,4
3885,W Tree,Victoria,VIC,-37.2648,148.1466,3
3885,Butchers Ridge,Victoria,VIC,-37.2583,148.2443,4
3885,Yalmy,Victoria,VIC,-37.4142,148.1025,3
3885,Gelantipy,Victoria,VIC,-37.1833,148.2833,4
3885,Wiseleigh,Victoria,VIC,-37.7117,147.8056,4
3885,Buchan,Victoria,VIC,-37.5,148.1833,4
3885,Brumby,Victoria,VIC,-37.4142,148.1025,3
3885,Bruthen,Victoria,VIC,-37.7067,147.831,4
3885,Wulgulmerang West,Victoria,VIC,-37.4142,148.1025,3
3885,Mossiface,Victoria,VIC,-37.7415,147.8159,4
3885,Murrindal,Victoria,VIC,-37.4167,148.2,4
3885,Tambo Upper,Victoria,VIC,-37.767,147.843,4
3885,Timbarra,Victoria,VIC,-37.4142,148.1025,3
3886,Newmerella,Victoria,VIC,-37.7333,148.45,4
3887,Nowa Nowa,Victoria,VIC,-37.7167,148.1,4
3887,Lake Tyers,Victoria,VIC,-37.85,148.0667,4
3887,Wairewa,Victoria,VIC,-37.6892,148.1693,4
3888,Tostaree,Victoria,VIC,-37.75,148.1667,4
3888,Brodribb River,Victoria,VIC,-37.7,148.5667,4
3888,Bete Bolong North,Victoria,VIC,-37.5266,148.543,3
3888,Goongerah,Victoria,VIC,-37.3321,148.6943,4
3888,Marlo,Victoria,VIC,-37.7962,148.5319,4
3888,Waygara,Victoria,VIC,-37.7333,148.3167,4
3888,Bendoc,Victoria,VIC,-37.1333,148.9,4
3888,Jarrahmond,Victoria,VIC,-37.6984,148.4064,4
3888,Wombat Creek,Victoria,VIC,-37.5266,148.543,3
3888,Bete Bolong,Victoria,VIC,-37.6858,148.3833,4
3888,Haydens Bog,Victoria,VIC,-37.5266,148.543,3
3888,Delegate River,Victoria,VIC,-37.0667,148.8,4
3888,Simpsons Creek,Victoria,VIC,-37.5266,148.543,3
3888,Nurran,Victoria,VIC,-37.5266,148.543,3
3888,Bonang,Victoria,VIC,-37.2,148.75,4
3888,Orbost,Victoria,VIC,-37.6964,148.457,4
3888,Tubbut,Victoria,VIC,-37.3755,148.5993,3
3888,Corringle,Victoria,VIC,-37.5266,148.543,3
3888,Cape Conran,Victoria,VIC,-37.5266,148.543,3
3888,Deddick Valley,Victoria,VIC,-37.5266,148.543,3
3889,Cabbage Tree Creek,Victoria,VIC,-37.6967,148.7197,4
3889,Bemm River,Victoria,VIC,-37.7582,148.9692,4
3889,Errinundra,Victoria,VIC,-37.3831,148.8937,4
3889,Bellbird Creek,Victoria,VIC,-37.5481,148.8764,3
3889,Manorina,Victoria,VIC,-37.6756,148.7948,4
3889,Club Terrace,Victoria,VIC,-37.5333,148.95,4
3889,Combienbar,Victoria,VIC,-37.3833,149.0167,4
3890,Buldah,Victoria,VIC,-37.2655,149.1431,4
3890,Noorinbee North,Victoria,VIC,-37.4986,149.1425,3
3890,Cann River,Victoria,VIC,-37.5658,149.151,4
3890,Chandlers Creek,Victoria,VIC,-37.3385,149.1951,4
3890,Noorinbee,Victoria,VIC,-37.5167,149.1667,4
3890,Tonghi Creek,Victoria,VIC,-37.5638,149.0588,4
3890,Tamboon,Victoria,VIC,-37.7393,149.1419,4
3891,Wangarabell,Victoria,VIC,-37.475,149.6333,3
3891,Genoa,Victoria,VIC,-37.4833,149.5833,4
3891,Gipsy Point,Victoria,VIC,-37.4667,149.6833,4
3891,Wallagaraugh,Victoria,VIC,-37.475,149.6333,3
3891,Wroxham,Victoria,VIC,-37.475,149.6333,3
3891,Wingan River,Victoria,VIC,-37.475,149.6333,3
3891,Maramingo Creek,Victoria,VIC,-37.475,149.6333,3
3892,Mallacoota,Victoria,VIC,-37.559,149.7541,4
3893,Double Bridges,Victoria,VIC,-37.4667,147.7917,3
3893,Stirling,Victoria,VIC,-37.4333,147.75,4
3893,Tambo Crossing,Victoria,VIC,-37.5,147.8333,4
3895,Ensay,Victoria,VIC,-37.3833,147.8333,4
3895,Reedy Flat,Victoria,VIC,-37.4,147.9,4
3895,Doctors Flat,Victoria,VIC,-37.3,147.75,4
3895,Ensay North,Victoria,VIC,-37.3611,147.8278,3
3896,Swifts Creek,Victoria,VIC,-37.25,147.7167,4
3896,Brookville,Victoria,VIC,-37.3333,147.6167,4
3896,Nunniong,Victoria,VIC,-37.2208,147.725,3
3896,Tongio,Victoria,VIC,-37.1833,147.7333,4
3896,Bindi,Victoria,VIC,-37.1167,147.8333,4
3898,Hinnomunjie,Victoria,VIC,-37.0167,147.65,4
3898,Glen Wills,Victoria,VIC,-36.85,147.5167,4
3898,Omeo,Victoria,VIC,-37.0961,147.5949,4
3898,Cobungra,Victoria,VIC,-37.1,147.4167,4
3898,Dinner Plain,Victoria,VIC,-37.0236,147.2412,4
3898,Bingo Munjie,Victoria,VIC,-37.0204,147.4992,3
3898,Cassilis,Victoria,VIC,-37.2167,147.6,4
3898,Omeo Valley,Victoria,VIC,-37.0204,147.4992,3
3898,Anglers Rest,Victoria,VIC,-36.9935,147.4905,4
3898,Bundara,Victoria,VIC,-37.0204,147.4992,3
3898,Shannonvale,Victoria,VIC,-37.0204,147.4992,3
3898,Glen Valley,Victoria,VIC,-36.8667,147.4833,4
3900,Benambra,Victoria,VIC,-36.95,147.7167,4
3900,Cobberas,Victoria,VIC,-36.95,147.7167,3
3902,Johnsonville,Victoria,VIC,-37.8167,147.8,4
3902,Bumberrah,Victoria,VIC,-37.8,147.8333,4
3903,Swan Reach,Victoria,VIC,-37.8333,147.8667,4
3904,Metung,Victoria,VIC,-37.8915,147.853,4
3909,Lakes Entrance,Victoria,VIC,-37.8812,147.981,4
3909,Nungurner,Victoria,VIC,-37.8819,147.8858,4
3909,Lake Tyers Beach,Victoria,VIC,-37.859,148.0868,3
3909,Toorloo Arm,Victoria,VIC,-37.8758,147.9423,3
3909,Kalimna West,Victoria,VIC,-37.8568,147.9522,4
3909,Lake Bunga,Victoria,VIC,-37.8758,147.9423,3
3909,Nyerimilang,Victoria,VIC,-37.8758,147.9423,3
3909,Kalimna,Victoria,VIC,-37.8833,147.95,4
3910,Langwarrin,Victoria,VIC,-38.1667,145.1667,4
3911,Langwarrin South,Victoria,VIC,-38.1987,145.1807,4
3911,Baxter,Victoria,VIC,-38.2,145.15,4
3912,Somerville,Victoria,VIC,-38.2167,145.1667,4
3912,Pearcedale,Victoria,VIC,-38.203,145.2349,4
3913,Tyabb,Victoria,VIC,-38.25,145.1833,4
3915,Tuerong,Victoria,VIC,-38.2987,145.0967,4
3915,Hastings,Victoria,VIC,-38.3,145.1833,4
3916,Shoreham,Victoria,VIC,-38.4263,145.0469,4
3916,Merricks,Victoria,VIC,-38.3833,145.0833,4
3916,Point Leo,Victoria,VIC,-38.415,145.0694,4
3918,Bittern,Victoria,VIC,-38.3333,145.1667,4
3919,Crib Point,Victoria,VIC,-38.35,145.2,4
3920,Hmas Cerberus,Victoria,VIC,-38.3649,145.2004,4
3921,French Island,Victoria,VIC,-38.3406,145.341,4
3921,Elizabeth Island,Victoria,VIC,-38.3667,145.3241,3
3921,Tankerton,Victoria,VIC,-38.3868,145.2896,4
3922,Sunset Strip,Victoria,VIC,-38.4922,145.2627,4
3922,Sunderland Bay,Victoria,VIC,-38.4568,145.0754,3
3922,Ventnor,Victoria,VIC,-38.4627,145.195,4
3922,Cowes,Victoria,VIC,-38.4523,145.2386,4
3922,Summerlands,Victoria,VIC,-38.4568,145.0754,3
3922,Wimbledon Heights,Victoria,VIC,-38.4568,145.0754,3
3922,Silverleaves,Victoria,VIC,-38.4539,145.2727,4
3922,Surf Beach,Victoria,VIC,-38.3751,144.2564,4
3922,Smiths Beach,Victoria,VIC,-38.5007,145.2552,4
3923,Rhyll,Victoria,VIC,-38.4642,145.2995,4
3925,Churchill Island,Victoria,VIC,-38.5262,145.3584,3
3925,San Remo,Victoria,VIC,-38.5204,145.37,4
3925,Newhaven,Victoria,VIC,-38.5121,145.3558,4
3925,Cape Woolamai,Victoria,VIC,-38.5428,145.3441,4
3926,Merricks North,Victoria,VIC,-38.35,145.0833,4
3926,Balnarring Beach,Victoria,VIC,-38.4,145.1167,4
3926,Balnarring,Victoria,VIC,-38.3667,145.1333,4
3926,Merricks Beach,Victoria,VIC,-38.3988,145.106,4
3927,Somers,Victoria,VIC,-38.4,145.1667,4
3928,Main Ridge,Victoria,VIC,-38.3973,144.9724,4
3929,Flinders,Victoria,VIC,-38.4739,145.019,4
3930,Mount Eliza,Victoria,VIC,-38.1833,145.0833,4
3930,Kunyung,Victoria,VIC,-38.1943,145.0926,3
3931,Mornington,Victoria,VIC,-38.2179,145.0388,4
3933,Moorooduc,Victoria,VIC,-38.2167,145.1167,4
3934,Mount Martha,Victoria,VIC,-38.2667,145.0167,4
3936,Dromana,Victoria,VIC,-38.3338,144.9646,4
3936,Safety Beach,Victoria,VIC,-38.3153,145.0003,4
3936,Arthurs Seat,Victoria,VIC,-38.3632,144.9598,4
3937,Red Hill,Victoria,VIC,-38.35,145.0167,4
3937,Red Hill South,Victoria,VIC,-38.3833,145.0333,4
3938,Mccrae,Victoria,VIC,-38.3497,144.9281,4
3939,Boneo,Victoria,VIC,-38.4167,144.8833,4
3939,Rosebud Plaza,Victoria,VIC,-38.4106,144.8837,3
3939,Rosebud,Victoria,VIC,-38.3554,144.9068,4
3939,Fingal,Victoria,VIC,-38.414,144.8499,4
3939,Cape Schanck,Victoria,VIC,-38.4616,144.9014,4
3940,Rosebud West,Victoria,VIC,-38.3644,144.8775,4
3941,Rye,Victoria,VIC,-38.3667,144.8167,4
3941,Tootgarook,Victoria,VIC,-38.3777,144.8502,4
3941,St Andrews Beach,Victoria,VIC,-38.4141,144.8244,3
3942,Blairgowrie,Victoria,VIC,-38.3613,144.7795,4
3943,Sorrento,Victoria,VIC,-38.3396,144.7413,4
3944,Portsea,Victoria,VIC,-38.3167,144.7167,4
3945,Krowera,Victoria,VIC,-38.4333,145.6667,4
3945,Jeetho,Victoria,VIC,-38.3833,145.7167,4
3945,Woodleigh,Victoria,VIC,-38.4,145.6333,4
3945,Loch,Victoria,VIC,-38.3691,145.7081,4
3946,Bena,Victoria,VIC,-38.4167,145.7667,4
3950,Strzelecki,Victoria,VIC,-38.3333,145.9,4
3950,Whitelaw,Victoria,VIC,-38.4167,145.7833,4
3950,Kardella South,Victoria,VIC,-38.45,145.8833,4
3950,Korumburra South,Victoria,VIC,-38.4667,145.85,4
3950,Korumburra,Victoria,VIC,-38.4319,145.8235,4
3951,Arawata,Victoria,VIC,-38.4,145.8667,4
3951,Fairbank,Victoria,VIC,-38.45,145.7929,3
3951,Ranceby,Victoria,VIC,-38.3833,145.85,4
3951,Kardella,Victoria,VIC,-38.4167,145.8667,4
3951,Moyarra,Victoria,VIC,-38.4833,145.7333,4
3951,Outtrim,Victoria,VIC,-38.4833,145.7667,4
3951,Jumbunna,Victoria,VIC,-38.4667,145.7667,4
3951,Kongwak,Victoria,VIC,-38.5167,145.7,4
3953,Ruby,Victoria,VIC,-38.45,145.9167,4
3953,Leongatha North,Victoria,VIC,-38.4236,146.0027,3
3953,Hallston,Victoria,VIC,-38.3667,146.0333,4
3953,Mount Eccles South,Victoria,VIC,-38.3667,145.9833,4
3953,Mount Eccles,Victoria,VIC,-38.3667,145.9833,4
3953,Koorooman,Victoria,VIC,-38.4236,146.0027,3
3953,Berrys Creek,Victoria,VIC,-38.4167,146.0667,4
3953,Mardan,Victoria,VIC,-38.45,146.1333,4
3953,Leongatha,Victoria,VIC,-38.4761,145.9469,4
3953,Boorool,Victoria,VIC,-38.4236,146.0027,3
3953,Leongatha South,Victoria,VIC,-38.5333,145.8833,4
3953,Wild Dog Valley,Victoria,VIC,-38.4236,146.0027,3
3953,Nerrena,Victoria,VIC,-38.5167,146.0833,4
3953,Wooreen,Victoria,VIC,-38.4,146.0333,4
3953,Trida,Victoria,VIC,-38.3167,145.9667,4
3954,Koonwarra,Victoria,VIC,-38.55,145.95,4
3956,Venus Bay,Victoria,VIC,-38.7004,145.8224,4
3956,Tarwin,Victoria,VIC,-38.5833,145.9833,4
3956,Dumbalk,Victoria,VIC,-38.5333,146.0833,4
3956,Walkerville North,Victoria,VIC,-38.6678,145.9843,3
3956,Meeniyan,Victoria,VIC,-38.5769,146.0193,4
3956,Walkerville,Victoria,VIC,-38.6777,146.0009,3
3956,Walkerville South,Victoria,VIC,-38.8599,145.9933,4
3956,Middle Tarwin,Victoria,VIC,-38.6667,145.9333,4
3956,Tarwin Lower,Victoria,VIC,-38.7,145.85,4
3956,Dumbalk North,Victoria,VIC,-38.5167,146.1667,4
3957,Stony Creek,Victoria,VIC,-38.5833,146.0333,4
3958,Buffalo,Victoria,VIC,-38.65,146.0333,4
3959,Sandy Point,Victoria,VIC,-38.8199,146.1174,4
3959,Fish Creek,Victoria,VIC,-38.6833,146.0833,4
3959,Waratah Bay,Victoria,VIC,-38.7697,146.0757,3
3960,Gunyah,Victoria,VIC,-38.5333,146.3167,4
3960,Boolarong,Victoria,VIC,-38.5667,146.25,4
3960,Woorarra West,Victoria,VIC,-38.5638,146.1564,3
3960,Bennison,Victoria,VIC,-38.6667,146.25,4
3960,Shallow Inlet,Victoria,VIC,-38.5638,146.1564,3
3960,Turtons Creek,Victoria,VIC,-38.55,146.25,4
3960,Tidal River,Victoria,VIC,-38.8342,146.2888,3
3960,Yanakie,Victoria,VIC,-38.8114,146.2057,4
3960,Foster North,Victoria,VIC,-38.5638,146.1564,3
3960,Wilsons Promontory,Victoria,VIC,-38.9602,146.3677,4
3960,Wonga,Victoria,VIC,-37.7333,145.2667,4
3960,Mount Best,Victoria,VIC,-38.6,146.3,4
3960,Foster,Victoria,VIC,-38.6527,146.2007,4
3962,Woorarra East,Victoria,VIC,-38.6167,146.3833,4
3962,Toora,Victoria,VIC,-38.6629,146.3279,4
3962,Wonyip,Victoria,VIC,-38.5622,146.3924,4
3962,Toora North,Victoria,VIC,-38.633,146.3711,3
3962,Agnes,Victoria,VIC,-38.6857,146.3898,4
3964,Port Franklin,Victoria,VIC,-38.6833,146.2667,4
3965,Port Welshpool,Victoria,VIC,-38.6992,146.4531,4
3966,Binginwarri,Victoria,VIC,-38.5833,146.45,4
3966,Welshpool,Victoria,VIC,-38.6667,146.4333,4
3966,Hazel Park,Victoria,VIC,-38.6333,146.3833,4
3967,Hedley,Victoria,VIC,-38.6333,146.5,4
3971,Gelliondale,Victoria,VIC,-38.6194,146.6036,4
3971,Yarram,Victoria,VIC,-38.5648,146.6756,4
3971,Balook,Victoria,VIC,-38.4333,146.5667,4
3971,Manns Beach,Victoria,VIC,-38.5398,146.6065,3
3971,Tarraville,Victoria,VIC,-38.6333,146.7167,4
3971,Madalya,Victoria,VIC,-38.5,146.4667,4
3971,Langsborough,Victoria,VIC,-38.6551,146.678,4
3971,Hunterston,Victoria,VIC,-38.5398,146.6065,3
3971,Alberton,Victoria,VIC,-38.6167,146.6667,4
3971,Won Wron,Victoria,VIC,-38.4667,146.7167,4
3971,Hiawatha,Victoria,VIC,-38.5167,146.4667,4
3971,Robertsons Beach,Victoria,VIC,-38.5398,146.6065,3
3971,Devon North,Victoria,VIC,-38.505,146.6497,3
3971,Jack River,Victoria,VIC,-38.55,146.5667,4
3971,Tarra Valley,Victoria,VIC,-38.4333,146.5333,4
3971,Snake Island,Victoria,VIC,-38.5398,146.6065,3
3971,Macks Creek,Victoria,VIC,-38.4667,146.6333,4
3971,Alberton West,Victoria,VIC,-38.6,146.6,4
3971,Staceys Bridge,Victoria,VIC,-38.55,146.5333,4
3971,Calrossie,Victoria,VIC,-38.4833,146.6667,4
3971,Port Albert,Victoria,VIC,-38.6654,146.6885,4
3975,Lyndhurst,Victoria,VIC,-38.05,145.25,4
3975,Lynbrook,Victoria,VIC,-38.0559,145.2562,4
3976,Hampton Park,Victoria,VIC,-38.0333,145.25,4
3977,Botanic Ridge,Victoria,VIC,-38.1392,145.2684,4
3977,Skye,Victoria,VIC,-38.1051,145.2163,4
3977,Cannons Creek,Victoria,VIC,-38.2122,145.3153,4
3977,Devon Meadows,Victoria,VIC,-38.1667,145.3,4
3977,Cranbourne West,Victoria,VIC,-38.0965,145.2671,4
3977,Cranbourne South,Victoria,VIC,-38.135,145.2396,4
3977,Cranbourne,Victoria,VIC,-38.1134,145.2833,4
3977,Junction Village,Victoria,VIC,-38.1364,145.2968,4
3977,Cranbourne East,Victoria,VIC,-38.1153,145.2981,4
3977,Five Ways,Victoria,VIC,-38.1667,145.3167,4
3977,Sandhurst,Victoria,VIC,-38.081,145.2077,4
3977,Cranbourne North,Victoria,VIC,-38.0829,145.2781,4
3978,Clyde North,Victoria,VIC,-38.1167,145.3333,4
3978,Cardinia,Victoria,VIC,-38.15,145.4167,4
3978,Clyde,Victoria,VIC,-38.1333,145.3333,4
3979,Glen Alvie,Victoria,VIC,-38.5167,145.7,4
3979,Kernot,Victoria,VIC,-38.4333,145.6,4
3979,Almurta,Victoria,VIC,-38.4667,145.5833,4
3980,Blind Bight,Victoria,VIC,-38.2143,145.3377,4
3980,Warneet,Victoria,VIC,-38.2253,145.3101,4
3980,Tooradin,Victoria,VIC,-38.2147,145.3833,4
3981,Koo Wee Rup North,Victoria,VIC,-38.1667,145.5333,4
3981,Heath Hill,Victoria,VIC,-38.25,145.7,4
3981,Koo Wee Rup,Victoria,VIC,-38.1994,145.4908,3
3981,Yannathan,Victoria,VIC,-38.2333,145.65,4
3981,Bayles,Victoria,VIC,-38.1833,145.5667,4
3981,Dalmore,Victoria,VIC,-38.1833,145.4167,4
3981,Catani,Victoria,VIC,-38.2,145.6333,4
3984,The Gurdies,Victoria,VIC,-38.3151,145.5606,3
3984,Tenby Point,Victoria,VIC,-38.441,145.5107,3
3984,Pioneer Bay,Victoria,VIC,-38.3807,145.5495,4
3984,Coronet Bay,Victoria,VIC,-38.4391,145.4457,4
3984,Adams Estate,Victoria,VIC,-38.3488,145.5081,3
3984,Lang Lang,Victoria,VIC,-38.266,145.5621,4
3984,Queensferry,Victoria,VIC,-38.4167,145.5,4
3984,Grantville,Victoria,VIC,-38.407,145.5301,4
3984,Monomeith,Victoria,VIC,-38.2167,145.5167,4
3984,Jam Jerrup,Victoria,VIC,-38.3151,145.5606,3
3984,Corinella,Victoria,VIC,-38.4141,145.4276,4
3984,Lang Lang East,Victoria,VIC,-38.3488,145.5081,3
3984,Caldermeade,Victoria,VIC,-38.25,145.5333,4
3987,Nyora,Victoria,VIC,-38.3336,145.6725,4
3988,Poowong,Victoria,VIC,-38.3428,145.7638,4
3988,Poowong East,Victoria,VIC,-38.3,145.8333,4
3988,Mountain View,Victoria,VIC,-38.2667,145.8667,4
3988,Poowong North,Victoria,VIC,-38.3,145.8,4
3990,Glen Forbes,Victoria,VIC,-38.45,145.5333,4
3991,Bass,Victoria,VIC,-38.4833,145.45,4
3992,Ryanston,Victoria,VIC,-38.5333,145.5833,4
3992,West Creek,Victoria,VIC,-38.5333,145.5833,3
3992,Dalyston,Victoria,VIC,-38.5667,145.55,4
3992,Blackwood Forest,Victoria,VIC,-38.5,145.6167,4
3995,Wattle Bank,Victoria,VIC,-38.5667,145.7,4
3995,Lance Creek,Victoria,VIC,-38.5752,145.5665,3
3995,North Wonthaggi,Victoria,VIC,-38.6,145.6,4
3995,Anderson,Victoria,VIC,-38.5333,145.45,4
3995,Kilcunda,Victoria,VIC,-38.55,145.4833,4
3995,Harmers Haven,Victoria,VIC,-38.5752,145.5665,3
3995,Archies Creek,Victoria,VIC,-38.55,145.5667,4
3995,Woolamai,Victoria,VIC,-38.5,145.5,4
3995,South Dudley,Victoria,VIC,-38.6,145.5833,4
3995,St Clair,Victoria,VIC,-38.5752,145.5665,3
3995,Wonthaggi,Victoria,VIC,-38.6059,145.5936,4
3995,Cape Paterson,Victoria,VIC,-38.6709,145.6213,4
3996,Pound Creek,Victoria,VIC,-38.6333,145.8,4
3996,Inverloch,Victoria,VIC,-38.6266,145.7226,4
8001,Melbourne,Victoria,VIC,-37.814,144.9633,4
8002,East Melbourne,Victoria,VIC,-37.8167,144.9879,4
8003,Collins Street East,Victoria,VIC,-37.8153,144.9756,1
8004,St Kilda Road,Victoria,VIC,-37.8153,144.9756,1
8005,World Trade Centre,Victoria,VIC,-37.8153,144.9756,1
8006,Abeckett Street,Victoria,VIC,-37.8153,144.9756,1
8007,Collins Street West,Victoria,VIC,-37.8153,144.9756,1
8008,St Kilda Road Central,Victoria,VIC,-37.8153,144.9756,1
8009,Flinders Lane,Victoria,VIC,-37.8153,144.9756,1
8010,Law Courts,Victoria,VIC,-37.8149,144.9505,1
8011,Little Lonsdale Street,Victoria,VIC,-37.8149,144.9505,1
8012,Docklands,Victoria,VIC,-37.8149,144.9505,4
8045,Melbourne,Victoria,VIC,-37.814,144.9633,4
8051,Melbourne,Victoria,VIC,-37.814,144.9633,4
8060,Melbourne,Victoria,VIC,-37.814,144.9633,4
8061,Melbourne,Victoria,VIC,-37.814,144.9633,4
8066,Melbourne,Victoria,VIC,-37.814,144.9633,4
8069,Melbourne,Victoria,VIC,-37.814,144.9633,4
8070,Melbourne,Victoria,VIC,-37.814,144.9633,4
8071,Melbourne,Victoria,VIC,-37.814,144.9633,4
8102,Melbourne,Victoria,VIC,-37.814,144.9633,4
8103,Melbourne,Victoria,VIC,-37.814,144.9633,4
8107,Melbourne,Victoria,VIC,-37.814,144.9633,4
8108,Melbourne,Victoria,VIC,-37.814,144.9633,4
8111,Melbourne,Victoria,VIC,-37.814,144.9633,4
8120,Melbourne,Victoria,VIC,-37.814,144.9633,4
8205,Melbourne,Victoria,VIC,-37.814,144.9633,4
8383,Melbourne,Victoria,VIC,-37.814,144.9633,4
8386,Melbourne,Victoria,VIC,-37.814,144.9633,4
8388,Melbourne,Victoria,VIC,-37.814,144.9633,4
8390,Melbourne,Victoria,VIC,-37.814,144.9633,4
8393,Melbourne,Victoria,VIC,-37.814,144.9633,4
8394,Melbourne,Victoria,VIC,-37.814,144.9633,4
8396,Melbourne,Victoria,VIC,-37.814,144.9633,4
8399,Melbourne,Victoria,VIC,-37.814,144.9633,4
8627,Camberwell,Victoria,VIC,-37.8421,145.0694,4
872,Ngaanyatjarra-Giles,Western Australia,WA,-23.3256,133.0557,3
872,Gibson Desert North,Western Australia,WA,-23.3256,133.0557,3
872,Tjirrkarli,Western Australia,WA,-23.3256,133.0557,3
872,Wannan,Western Australia,WA,-23.3256,133.0557,3
872,Gibson Desert South,Western Australia,WA,-23.3256,133.0557,3
872,Patjarr,Western Australia,WA,-23.3256,133.0557,3
872,Wingellina,Western Australia,WA,-23.3256,133.0557,3
872,Kiwirrkurra,Western Australia,WA,-23.3256,133.0557,3
872,Tjukurla,Western Australia,WA,-23.3256,133.0557,3
6000,Perth,Western Australia,WA,-31.9522,115.8614,4
6000,Perth GPO,Western Australia,WA,-31.9522,115.8614,3
6000,City Delivery Centre,Western Australia,WA,-31.9522,115.8614,3
6001,Perth,Western Australia,WA,-31.9522,115.8614,4
6003,Northbridge,Western Australia,WA,-31.9478,115.8588,4
6003,Highgate,Western Australia,WA,-31.9397,115.8701,4
6004,East Perth,Western Australia,WA,-31.9587,115.8711,4
6005,West Perth,Western Australia,WA,-31.949,115.842,4
6005,Kings Park,Western Australia,WA,-31.955,115.8359,3
6006,North Perth,Western Australia,WA,-31.9272,115.8528,4
6007,Leederville,Western Australia,WA,-31.9363,115.8419,4
6007,West Leederville,Western Australia,WA,-31.9414,115.8312,4
6008,Shenton Park,Western Australia,WA,-31.9583,115.7972,4
6008,Daglish,Western Australia,WA,-31.9483,115.819,4
6008,Subiaco,Western Australia,WA,-31.9485,115.8268,4
6008,Subiaco East,Western Australia,WA,-31.9542,115.7986,3
6009,Nedlands,Western Australia,WA,-31.9818,115.8073,4
6009,Broadway Nedlands,Western Australia,WA,-31.9853,115.8052,3
6009,Dalkeith,Western Australia,WA,-31.9952,115.7998,4
6009,Nedlands DC,Western Australia,WA,-31.9875,115.8014,3
6009,Crawley,Western Australia,WA,-31.9842,115.816,4
6010,Karrakatta,Western Australia,WA,-31.9676,115.798,4
6010,Claremont,Western Australia,WA,-31.9796,115.7823,4
6010,Mount Claremont,Western Australia,WA,-31.97,115.7775,3
6010,Swanbourne,Western Australia,WA,-31.9688,115.7673,4
6010,Claremont North,Western Australia,WA,-31.97,115.7775,3
6011,Peppermint Grove,Western Australia,WA,-31.9988,115.7682,4
6011,Cottesloe,Western Australia,WA,-32,115.7667,4
6012,Mosman Park,Western Australia,WA,-32.0054,115.7638,4
6014,Wembley,Western Australia,WA,-31.9333,115.8,4
6014,Floreat,Western Australia,WA,-31.9386,115.7921,4
6014,Jolimont,Western Australia,WA,-31.9456,115.8095,4
6015,City Beach,Western Australia,WA,-31.9344,115.7617,4
6016,Mount Hawthorn,Western Australia,WA,-31.92,115.8351,4
6016,Glendalough,Western Australia,WA,-31.9199,115.8194,4
6017,Herdsman,Western Australia,WA,-31.9106,115.8055,4
6017,Osborne Park DC,Western Australia,WA,-31.9007,115.8108,3
6017,Osborne Park,Western Australia,WA,-31.9007,115.8108,4
6018,Churchlands,Western Australia,WA,-31.9214,115.7932,4
6018,Woodlands,Western Australia,WA,-31.8873,115.7865,3
6018,Gwelup,Western Australia,WA,-31.8716,115.7948,4
6018,Innaloo,Western Australia,WA,-31.8927,115.7951,4
6018,Doubleview,Western Australia,WA,-31.8963,115.7816,4
6018,Gwelup DC,Western Australia,WA,-31.8909,115.7883,3
6018,Karrinyup,Western Australia,WA,-31.8723,115.7768,4
6019,Wembley Downs,Western Australia,WA,-31.9117,115.7736,4
6019,Scarborough,Western Australia,WA,-31.8958,115.7643,4
6020,North Beach,Western Australia,WA,-31.863,115.7562,4
6020,Marmion,Western Australia,WA,-31.8416,115.7569,4
6020,Watermans Bay,Western Australia,WA,-31.8492,115.7559,4
6020,Sorrento,Western Australia,WA,-31.8253,115.7525,4
6020,Carine,Western Australia,WA,-31.8523,115.7826,4
6021,Stirling,Western Australia,WA,-31.8834,115.8099,4
6021,Balcatta,Western Australia,WA,-31.875,115.8284,4
6022,Hamersley,Western Australia,WA,-31.849,115.8076,4
6023,Duncraig,Western Australia,WA,-31.8329,115.7759,4
6024,Warwick,Western Australia,WA,-31.8397,115.8086,4
6024,Greenwood,Western Australia,WA,-31.8272,115.8025,4
6025,Hillarys,Western Australia,WA,-31.807,115.7405,4
6025,Craigie,Western Australia,WA,-31.7873,115.7699,4
6025,Kallaroo,Western Australia,WA,-31.791,115.753,4
6025,Padbury,Western Australia,WA,-31.8064,115.7688,4
6026,Kingsley,Western Australia,WA,-31.8104,115.8008,4
6026,Woodvale,Western Australia,WA,-31.7893,115.7968,4
6027,Ocean Reef,Western Australia,WA,-31.7587,115.736,4
6027,Beldon,Western Australia,WA,-31.7747,115.7627,4
6027,Joondalup DC,Western Australia,WA,-31.7619,115.7565,3
6027,Connolly,Western Australia,WA,-31.7486,115.7505,4
6027,Heathridge,Western Australia,WA,-31.7624,115.7589,4
6027,Edgewater,Western Australia,WA,-31.7655,115.7822,4
6027,Mullaloo,Western Australia,WA,-31.779,115.7368,4
6027,Joondalup,Western Australia,WA,-31.7444,115.7683,4
6028,Iluka,Western Australia,WA,-31.7355,115.7306,4
6028,Currambine,Western Australia,WA,-31.7331,115.7477,4
6028,Kinross,Western Australia,WA,-31.7187,115.7384,4
6028,Burns Beach,Western Australia,WA,-31.7206,115.7205,4
6029,Trigg,Western Australia,WA,-31.8694,115.7571,4
6030,Merriwa,Western Australia,WA,-31.6649,115.7127,4
6030,Tamala Park,Western Australia,WA,-31.7061,115.7201,4
6030,Clarkson,Western Australia,WA,-31.6828,115.7247,4
6030,Quinns Rocks,Western Australia,WA,-31.6738,115.7037,4
6030,Ridgewood,Western Australia,WA,-31.6615,115.7226,4
6030,Mindarie,Western Australia,WA,-31.6893,115.707,4
6031,Neerabup,Western Australia,WA,-31.6734,115.7517,4
6031,Banksia Grove,Western Australia,WA,-31.6962,115.805,4
6031,Carramar,Western Australia,WA,-31.6902,115.7716,4
6032,Nowergup,Western Australia,WA,-31.6371,115.7625,4
6033,Carabooda,Western Australia,WA,-31.6058,115.7144,4
6034,Eglinton,Western Australia,WA,-31.5868,115.6888,4
6035,Yanchep,Western Australia,WA,-31.5468,115.6317,4
6036,Butler,Western Australia,WA,-31.643,115.7043,4
6036,Jindalee,Western Australia,WA,-31.6494,115.695,4
6037,Two Rocks,Western Australia,WA,-31.4998,115.5874,4
6038,Alkimos,Western Australia,WA,-31.6304,115.6864,4
6041,Wilbinga,Western Australia,WA,-31.3591,115.5608,3
6041,Gabbadah,Western Australia,WA,-31.292,115.5059,4
6041,Caraban,Western Australia,WA,-31.3591,115.5608,3
6041,Woodridge,Western Australia,WA,-31.3338,115.5959,4
6041,Guilderton,Western Australia,WA,-31.3451,115.499,4
6042,Seabird,Western Australia,WA,-31.2772,115.4439,4
6043,Ledge Point,Western Australia,WA,-31.1002,115.3909,4
6043,Breton Bay,Western Australia,WA,-31.1025,115.3766,3
6044,Lancelin,Western Australia,WA,-31.0225,115.3365,4
6044,Wedge Island,Western Australia,WA,-30.8531,115.3072,3
6044,Karakin,Western Australia,WA,-30.9704,115.3976,3
6044,Nilgen,Western Australia,WA,-30.9704,115.3976,3
6050,Menora,Western Australia,WA,-31.9189,115.8621,4
6050,Coolbinia,Western Australia,WA,-31.9147,115.8489,4
6050,Mount Lawley,Western Australia,WA,-31.9344,115.8716,4
6051,Maylands,Western Australia,WA,-31.931,115.8949,4
6052,Inglewood,Western Australia,WA,-31.9166,115.8798,4
6052,Bedford,Western Australia,WA,-31.9113,115.8892,4
6053,Bayswater,Western Australia,WA,-31.9169,115.9178,4
6054,Ashfield,Western Australia,WA,-31.9172,115.938,4
6054,Bassendean,Western Australia,WA,-31.9091,115.9437,4
6054,Eden Hill,Western Australia,WA,-31.8891,115.9467,4
6054,Lockridge,Western Australia,WA,-31.8817,115.9503,4
6054,Kiara,Western Australia,WA,-31.8816,115.9389,4
6055,Hazelmere,Western Australia,WA,-31.9174,116.0118,4
6055,Henley Brook,Western Australia,WA,-31.8139,115.9881,4
6055,West Swan,Western Australia,WA,-31.8476,115.9799,3
6055,Brabham,Western Australia,WA,-31.8273,115.9742,4
6055,Caversham,Western Australia,WA,-31.8833,115.9667,4
6055,South Guildford,Western Australia,WA,-31.9122,115.9682,4
6055,Guildford,Western Australia,WA,-31.9,115.9667,4
6055,Dayton,Western Australia,WA,-31.8525,115.9749,4
6056,Koongamia,Western Australia,WA,-31.9066,116.0422,4
6056,Viveash,Western Australia,WA,-31.8777,115.9975,4
6056,Jane Brook,Western Australia,WA,-31.8637,116.0504,4
6056,Midvale,Western Australia,WA,-31.8888,116.0329,4
6056,Boya,Western Australia,WA,-31.9333,116.05,4
6056,Woodbridge,Western Australia,WA,-31.8869,115.992,4
6056,Greenmount,Western Australia,WA,-31.9007,116.0495,4
6056,Red Hill,Western Australia,WA,-31.849,116.0584,4
6056,Helena Valley,Western Australia,WA,-31.9194,116.0398,4
6056,Midland,Western Australia,WA,-31.9,116,4
6056,Baskerville,Western Australia,WA,-31.7978,116.0436,4
6056,Bellevue,Western Australia,WA,-31.9,116.0167,4
6056,Middle Swan,Western Australia,WA,-31.85,116.0167,4
6056,Millendon,Western Australia,WA,-31.8167,116.0333,4
6056,Stratton,Western Australia,WA,-31.8683,116.0407,4
6056,Swan View,Western Australia,WA,-31.8833,116.05,4
6056,Herne Hill,Western Australia,WA,-31.8255,116.0227,4
6057,High Wycombe,Western Australia,WA,-31.9444,116.0034,4
6057,Maida Vale,Western Australia,WA,-31.9519,116.0276,4
6058,Forrestfield,Western Australia,WA,-31.9828,116.0067,4
6059,Dianella,Western Australia,WA,-31.8882,115.8719,4
6060,Joondanna,Western Australia,WA,-31.9077,115.8423,4
6060,Dog Swamp,Western Australia,WA,-31.902,115.8455,3
6060,Tuart Hill,Western Australia,WA,-31.8981,115.8349,4
6060,Yokine South,Western Australia,WA,-31.8996,115.8441,3
6060,Yokine,Western Australia,WA,-31.9011,115.8532,4
6061,Westminster,Western Australia,WA,-31.8674,115.8392,4
6061,Balga,Western Australia,WA,-31.855,115.8399,4
6061,Mirrabooka,Western Australia,WA,-31.8596,115.8659,4
6061,Nollamara,Western Australia,WA,-31.8802,115.8454,4
6062,Embleton,Western Australia,WA,-31.9035,115.909,4
6062,Morley,Western Australia,WA,-31.8877,115.9099,4
6062,Noranda,Western Australia,WA,-31.8733,115.899,4
6063,Beechboro,Western Australia,WA,-31.8651,115.9366,4
6063,Bennett Springs,Western Australia,WA,-31.8651,115.9366,3
6064,Girrawheen,Western Australia,WA,-31.841,115.8398,4
6064,Alexander Heights,Western Australia,WA,-31.8276,115.865,4
6064,Marangaroo,Western Australia,WA,-31.8271,115.8384,4
6064,Koondoola,Western Australia,WA,-31.8415,115.8665,4
6065,Madeley,Western Australia,WA,-31.8111,115.8281,4
6065,Gnangara,Western Australia,WA,-31.7761,115.8662,4
6065,Sinagra,Western Australia,WA,-31.7426,115.8063,4
6065,Hocking,Western Australia,WA,-31.7701,115.8183,4
6065,Darch,Western Australia,WA,-31.8125,115.8458,4
6065,Wangara DC,Western Australia,WA,-31.7671,115.8255,3
6065,Pearsall,Western Australia,WA,-31.7812,115.8182,4
6065,Tapping,Western Australia,WA,-31.7195,115.7956,4
6065,Wanneroo,Western Australia,WA,-31.75,115.8,4
6065,Kingsway,Western Australia,WA,-31.6923,115.8437,3
6065,Wangara,Western Australia,WA,-31.7914,115.8203,4
6065,Landsdale,Western Australia,WA,-31.8075,115.866,4
6065,Lexia,Western Australia,WA,-31.7934,115.9215,4
6065,Mariginiup,Western Australia,WA,-31.7111,115.8432,4
6065,Ashby,Western Australia,WA,-31.7325,115.7977,4
6065,Jandabup,Western Australia,WA,-31.755,115.8607,4
6065,Pinjar,Western Australia,WA,-31.6936,115.8431,4
6065,Melaleuca,Western Australia,WA,-31.7745,115.9176,4
6066,Ballajura,Western Australia,WA,-31.8406,115.8955,4
6067,Cullacabardee,Western Australia,WA,-31.8194,115.9055,4
6068,Whiteman,Western Australia,WA,-31.8245,115.9416,4
6069,Aveley,Western Australia,WA,-31.7813,115.9896,4
6069,Upper Swan,Western Australia,WA,-31.7695,116.0273,4
6069,The Vines,Western Australia,WA,-31.7549,116.0024,4
6069,Ellenbrook East,Western Australia,WA,-31.7673,116.0259,3
6069,Belhus,Western Australia,WA,-31.7816,116.0095,4
6069,Ellenbrook,Western Australia,WA,-31.7674,115.9694,4
6069,Brigadoon,Western Australia,WA,-31.7726,116.0727,4
6070,Darlington,Western Australia,WA,-31.9191,116.0812,4
6071,Hovea,Western Australia,WA,-31.8833,116.1,4
6071,Glen Forrest,Western Australia,WA,-31.9193,116.1073,4
6072,Mahogany Creek,Western Australia,WA,-31.9095,116.1043,4
6073,Mundaring DC,Western Australia,WA,-31.9021,116.1676,3
6073,Mundaring,Western Australia,WA,-31.9021,116.1676,4
6074,Sawyers Valley,Western Australia,WA,-31.9023,116.2007,4
6076,Bickley,Western Australia,WA,-32,116.0833,4
6076,Paulls Valley,Western Australia,WA,-31.9692,116.1094,4
6076,Reservoir,Western Australia,WA,-31.9647,116.166,4
6076,Carmel,Western Australia,WA,-32.0167,116.1,4
6076,Lesmurdie,Western Australia,WA,-32.002,116.0482,4
6076,Hacketts Gully,Western Australia,WA,-31.9774,116.0928,4
6076,Kalamunda,Western Australia,WA,-31.9868,116.0704,3
6076,Piesse Brook,Western Australia,WA,-31.972,116.0779,4
6076,Gooseberry Hill,Western Australia,WA,-31.9544,116.0492,4
6076,Pickering Brook,Western Australia,WA,-32.0333,116.1,4
6076,Walliston,Western Australia,WA,-31.9968,116.0743,4
6077,Gnangara,Western Australia,WA,-31.7761,115.8662,4
6077,Jandabup,Western Australia,WA,-31.755,115.8607,4
6078,Pinjar,Western Australia,WA,-31.6936,115.8431,4
6078,Mariginiup,Western Australia,WA,-31.7111,115.8432,4
6079,Melaleuca,Western Australia,WA,-31.7745,115.9176,4
6079,Lexia,Western Australia,WA,-31.7934,115.9215,4
6081,Stoneville,Western Australia,WA,-31.8783,116.1707,4
6081,Parkerville,Western Australia,WA,-31.8747,116.138,4
6082,Mount Helena,Western Australia,WA,-31.8761,116.2103,4
6082,Bailup,Western Australia,WA,-31.7392,116.3102,4
6083,Morangup,Western Australia,WA,-31.599,116.3541,3
6083,Gidgegannup,Western Australia,WA,-31.7544,116.1875,4
6084,Chittering,Western Australia,WA,-31.4677,116.0801,4
6084,Lower Chittering,Western Australia,WA,-31.5833,116.1167,4
6084,Bullsbrook,Western Australia,WA,-31.669,115.9999,4
6084,Walyunga National Park,Western Australia,WA,-31.5924,116.1014,3
6084,Avon Valley National Park,Western Australia,WA,-31.6361,116.1887,4
6090,Malaga,Western Australia,WA,-31.8526,115.881,4
6100,Burswood,Western Australia,WA,-31.9587,115.904,4
6100,Lathlain,Western Australia,WA,-31.9676,115.9063,4
6100,Victoria Park,Western Australia,WA,-31.9762,115.9052,4
6101,Carlisle South,Western Australia,WA,-31.9842,115.911,3
6101,Carlisle,Western Australia,WA,-31.9795,115.9181,4
6101,East Victoria Park,Western Australia,WA,-31.9889,115.9039,4
6101,Carlisle North,Western Australia,WA,-31.9842,115.911,3
6102,Bentley,Western Australia,WA,-32,115.9167,4
6102,St James,Western Australia,WA,-32,115.9098,4
6102,Bentley DC,Western Australia,WA,-32,115.9132,3
6102,Bentley South,Western Australia,WA,-32,115.9132,3
6103,Rivervale,Western Australia,WA,-31.9555,115.9131,4
6104,Redcliffe,Western Australia,WA,-31.9384,115.9464,4
6104,Belmont,Western Australia,WA,-31.9435,115.9255,4
6104,Ascot,Western Australia,WA,-31.9382,115.9226,4
6105,Perth Airport,Western Australia,WA,-31.9321,115.9564,4
6105,Kewdale,Western Australia,WA,-31.9789,115.9516,4
6105,Cloverdale,Western Australia,WA,-31.9628,115.9443,4
6106,Welshpool,Western Australia,WA,-31.9944,115.9222,4
6107,Wattle Grove,Western Australia,WA,-32.0067,116.0005,4
6107,Queens Park,Western Australia,WA,-32.0031,115.9462,4
6107,Kenwick,Western Australia,WA,-32.0333,115.9667,4
6107,Beckenham,Western Australia,WA,-32.0248,115.9599,4
6107,East Cannington,Western Australia,WA,-32.0098,115.955,4
6107,Wilson,Western Australia,WA,-32.0206,115.9118,4
6107,Cannington,Western Australia,WA,-32.0167,115.95,4
6108,Thornlie,Western Australia,WA,-32.06,115.955,4
6109,Maddington,Western Australia,WA,-32.05,115.9833,4
6109,Orange Grove,Western Australia,WA,-32.0293,116.0057,4
6110,Gosnells,Western Australia,WA,-32.081,116.0054,4
6110,Huntingdale,Western Australia,WA,-32.0832,115.9661,4
6110,Southern River,Western Australia,WA,-32.1066,115.959,4
6110,Martin,Western Australia,WA,-32.0755,116.0454,4
6111,Champion Lakes,Western Australia,WA,-32.1191,115.9862,4
6111,Ashendon,Western Australia,WA,-32.1831,116.1341,4
6111,Kelmscott,Western Australia,WA,-32.1243,116.0259,4
6111,Camillo,Western Australia,WA,-32.1129,116.0021,4
6111,Roleystone,Western Australia,WA,-32.1146,116.0706,4
6111,Karragullen,Western Australia,WA,-32.1,116.1333,4
6111,Canning Mills,Western Australia,WA,-32.0804,116.1047,4
6111,Lesley,Western Australia,WA,-32.185,116.2075,4
6111,Kelmscott DC,Western Australia,WA,-32.1286,116.0542,3
6111,Westfield,Western Australia,WA,-32.15,115.9667,4
6112,Mount Nasura,Western Australia,WA,-32.1378,116.0247,4
6112,Piara Waters,Western Australia,WA,-32.1334,115.9163,4
6112,Wungong,Western Australia,WA,-32.1802,116.0095,4
6112,Forrestdale,Western Australia,WA,-32.1552,115.9342,4
6112,Hilbert,Western Australia,WA,-32.1782,115.9849,4
6112,Harrisdale,Western Australia,WA,-32.1108,115.9356,4
6112,Mount Richon,Western Australia,WA,-32.1735,116.0214,4
6112,Brookdale,Western Australia,WA,-32.167,116.0019,4
6112,Haynes,Western Australia,WA,-32.162,115.9815,4
6112,Bedfordale,Western Australia,WA,-32.1741,116.0504,4
6112,Armadale,Western Australia,WA,-32.1461,116.0093,4
6112,Seville Grove,Western Australia,WA,-32.1364,115.989,4
6121,Oldbury,Western Australia,WA,-32.2657,115.9183,4
6121,Oakford,Western Australia,WA,-32.2009,115.9339,4
6122,Byford,Western Australia,WA,-32.221,116.009,4
6122,Karrakup,Western Australia,WA,-32.2233,116.035,4
6122,Cardup,Western Australia,WA,-32.25,116,4
6122,Darling Downs,Western Australia,WA,-32.1944,115.9919,4
6123,Whitby,Western Australia,WA,-32.292,116.0124,4
6123,Mundijong,Western Australia,WA,-32.2922,115.9856,4
6124,Jarrahdale,Western Australia,WA,-32.3358,116.0595,4
6125,Serpentine,Western Australia,WA,-32.3605,115.9772,4
6125,Hopeland,Western Australia,WA,-32.3816,115.8873,4
6125,Mardella,Western Australia,WA,-32.3333,115.9833,4
6126,Keysbrook,Western Australia,WA,-32.4333,115.9667,4
6147,Lynwood,Western Australia,WA,-32.0401,115.9289,4
6147,Langford,Western Australia,WA,-32.0409,115.9416,4
6147,Parkwood,Western Australia,WA,-32.0491,115.9184,4
6148,Shelley,Western Australia,WA,-32.0296,115.8866,4
6148,Rossmoyne,Western Australia,WA,-32.0397,115.8678,4
6148,Ferndale,Western Australia,WA,-32.0349,115.9223,4
6148,Riverton,Western Australia,WA,-32.0347,115.8986,4
6149,Bull Creek,Western Australia,WA,-32.0563,115.8624,4
6149,Leeming,Western Australia,WA,-32.0751,115.8661,4
6150,Murdoch,Western Australia,WA,-32.0699,115.8376,4
6150,Bateman,Western Australia,WA,-32.0555,115.8474,4
6150,Winthrop,Western Australia,WA,-32.0522,115.8297,4
6151,South Perth Angelo St,Western Australia,WA,-31.9833,115.8667,3
6151,South Perth,Western Australia,WA,-31.9833,115.8667,4
6151,Kensington,Western Australia,WA,-31.981,115.8696,3
6152,Waterford,Western Australia,WA,-32.0157,115.8866,4
6152,Como,Western Australia,WA,-31.9912,115.8634,4
6152,Karawara,Western Australia,WA,-32.0086,115.8811,4
6152,Manning,Western Australia,WA,-32.0159,115.8671,4
6152,Salter Point,Western Australia,WA,-32.0238,115.8658,4
6153,Mount Pleasant,Western Australia,WA,-32.0279,115.8489,4
6153,Ardross,Western Australia,WA,-32.027,115.8355,4
6153,Applecross North,Western Australia,WA,-32.0323,115.8367,3
6153,Brentwood,Western Australia,WA,-32.0435,115.8525,4
6153,Canning Bridge Applecross,Western Australia,WA,-32.0323,115.8367,3
6153,Applecross,Western Australia,WA,-32.0167,115.8333,4
6154,Myaree,Western Australia,WA,-32.04,115.8176,4
6154,Booragoon,Western Australia,WA,-32.0395,115.8337,4
6154,Alfred Cove,Western Australia,WA,-32.0332,115.8126,4
6155,Canning Vale East,Western Australia,WA,-32.0552,115.903,3
6155,Willetton,Western Australia,WA,-32.0525,115.8878,4
6155,Canning Vale DC,Western Australia,WA,-32.0552,115.903,3
6155,Canning Vale,Western Australia,WA,-32.058,115.9181,4
6155,Canning Vale South,Western Australia,WA,-32.074,115.9095,3
6156,Melville,Western Australia,WA,-32.0404,115.8012,4
6156,Willagee Central,Western Australia,WA,-32.0373,115.8033,3
6156,Attadale,Western Australia,WA,-32.0249,115.802,4
6156,Willagee,Western Australia,WA,-32.052,115.8053,4
6157,Palmyra,Western Australia,WA,-32.045,115.7859,4
6157,Palmyra DC,Western Australia,WA,-32.0364,115.7846,3
6157,Bicton,Western Australia,WA,-32.0278,115.7833,4
6158,East Fremantle,Western Australia,WA,-32.0382,115.7676,4
6159,North Fremantle,Western Australia,WA,-32.0333,115.75,4
6160,Fremantle,Western Australia,WA,-32.05,115.7667,4
6161,Rottnest Island,Western Australia,WA,-31.9952,115.5404,4
6162,White Gum Valley,Western Australia,WA,-32.0603,115.7706,4
6162,South Fremantle,Western Australia,WA,-32.0691,115.7545,4
6162,Beaconsfield,Western Australia,WA,-32.0675,115.764,4
6163,Bibra Lake,Western Australia,WA,-32.0976,115.8192,4
6163,Hamilton Hill,Western Australia,WA,-32.0845,115.7795,4
6163,Spearwood,Western Australia,WA,-32.1053,115.778,4
6163,North Lake,Western Australia,WA,-32.0819,115.8357,4
6163,O'Connor,Western Australia,WA,-32.0581,115.7973,4
6163,Hilton,Western Australia,WA,-32.0621,115.7805,4
6163,Kardinya,Western Australia,WA,-32.0656,115.8148,4
6163,Bibra Lake DC,Western Australia,WA,-32.0799,115.8042,3
6163,Samson,Western Australia,WA,-32.07,115.7977,4
6163,Coolbellup,Western Australia,WA,-32.0812,115.8061,4
6163,North Coogee,Western Australia,WA,-32.0799,115.8042,3
6164,Atwell,Western Australia,WA,-32.1435,115.8653,4
6164,Banjup,Western Australia,WA,-32.1418,115.8867,4
6164,Success,Western Australia,WA,-32.1428,115.85,4
6164,Beeliar,Western Australia,WA,-32.1334,115.8064,4
6164,Hammond Park,Western Australia,WA,-32.1685,115.8504,4
6164,Yangebup,Western Australia,WA,-32.1206,115.8162,4
6164,Aubin Grove,Western Australia,WA,-32.1671,115.8626,4
6164,South Lake,Western Australia,WA,-32.1112,115.8387,4
6164,Jandakot,Western Australia,WA,-32.1016,115.8708,4
6164,Cockburn Central,Western Australia,WA,-32.1213,115.8478,4
6165,Hope Valley,Western Australia,WA,-32.2045,115.81,4
6165,Naval Base,Western Australia,WA,-32.191,115.7777,4
6166,Wattleup,Western Australia,WA,-32.1696,115.824,4
6166,Munster,Western Australia,WA,-32.1365,115.783,4
6166,Coogee,Western Australia,WA,-32.1193,115.7665,4
6166,Henderson,Western Australia,WA,-32.1764,115.7746,4
6167,Wandi,Western Australia,WA,-32.1943,115.8763,4
6167,Parmelia,Western Australia,WA,-32.2578,115.8221,4
6167,Casuarina,Western Australia,WA,-32.2414,115.8698,4
6167,Medina,Western Australia,WA,-32.2333,115.7833,4
6167,Anketell,Western Australia,WA,-32.2186,115.8656,4
6167,Kwinana Beach,Western Australia,WA,-32.2484,115.7619,4
6167,Bertram,Western Australia,WA,-32.2437,115.8457,4
6167,Postans,Western Australia,WA,-32.2247,115.8158,4
6167,Calista,Western Australia,WA,-32.241,115.7976,4
6167,Kwinana Town Centre,Western Australia,WA,-32.2444,115.8161,4
6167,Mandogalup,Western Australia,WA,-32.1929,115.8435,4
6167,The Spectacles,Western Australia,WA,-32.2202,115.8387,4
6167,Orelia,Western Australia,WA,-32.2333,115.8223,4
6168,Hillman,Western Australia,WA,-32.2833,115.7636,4
6168,Rockingham,Western Australia,WA,-32.2768,115.7298,4
6168,Peron,Western Australia,WA,-32.2766,115.7005,4
6168,Garden Island,Western Australia,WA,-32.2426,115.695,4
6168,Rockingham DC,Western Australia,WA,-32.2879,115.7429,3
6168,Cooloongup,Western Australia,WA,-32.3151,115.7777,4
6168,Rockingham Beach,Western Australia,WA,-32.2879,115.7429,3
6168,East Rockingham,Western Australia,WA,-32.2623,115.7489,4
6169,Safety Bay,Western Australia,WA,-32.3046,115.7421,4
6169,Shoalwater,Western Australia,WA,-32.2909,115.711,4
6169,Waikiki,Western Australia,WA,-32.3161,115.7546,4
6169,Warnbro,Western Australia,WA,-32.3402,115.7473,4
6170,Leda,Western Australia,WA,-32.2677,115.7993,4
6170,Wellard,Western Australia,WA,-32.2667,115.8333,4
6171,Baldivis,Western Australia,WA,-32.3289,115.8305,4
6172,Port Kennedy,Western Australia,WA,-32.3734,115.7515,4
6173,Secret Harbour,Western Australia,WA,-32.4059,115.7585,4
6174,Golden Bay,Western Australia,WA,-32.425,115.761,4
6175,Singleton,Western Australia,WA,-32.444,115.7572,4
6176,Karnup,Western Australia,WA,-32.3667,115.8167,4
6180,Parklands,Western Australia,WA,-32.4956,115.773,4
6180,Lakelands,Western Australia,WA,-32.4775,115.7557,4
6181,Stake Hill,Western Australia,WA,-32.4778,115.7942,4
6182,Keralup,Western Australia,WA,-32.4299,115.8314,4
6207,Solus,Western Australia,WA,-32.5627,116.1411,3
6207,Nambeelup,Western Australia,WA,-32.5318,115.8317,4
6207,Whittaker,Western Australia,WA,-32.5627,116.1411,3
6207,North Dandalup,Western Australia,WA,-32.5168,115.9719,4
6207,Myara,Western Australia,WA,-32.5627,116.1411,3
6208,Ravenswood,Western Australia,WA,-32.5824,115.8315,4
6208,Pinjarra,Western Australia,WA,-32.6298,115.8735,4
6208,Meelon,Western Australia,WA,-32.7,115.9333,4
6208,West Pinjarra,Western Australia,WA,-32.6428,115.8124,3
6208,North Yunderup,Western Australia,WA,-32.578,115.7943,4
6208,Point Grey,Western Australia,WA,-32.6606,115.9613,3
6208,South Yunderup,Western Australia,WA,-32.5851,115.7802,4
6208,Blythewood,Western Australia,WA,-32.6606,115.9613,3
6208,Fairbridge,Western Australia,WA,-32.6606,115.9613,3
6208,Nirimba,Western Australia,WA,-32.6606,115.9613,3
6208,Oakley,Western Australia,WA,-32.6606,115.9613,3
6209,Furnissdale,Western Australia,WA,-32.5585,115.7604,4
6209,Barragup,Western Australia,WA,-32.5498,115.7796,4
6210,Mandurah,Western Australia,WA,-32.5269,115.7217,4
6210,Parklands,Western Australia,WA,-32.4956,115.773,4
6210,Bouvard,Western Australia,WA,-32.7189,115.6389,4
6210,Greenfields,Western Australia,WA,-32.5243,115.7631,4
6210,Wannanup,Western Australia,WA,-32.5995,115.6449,4
6210,Mandurah DC,Western Australia,WA,-32.5582,115.719,3
6210,Dudley Park,Western Australia,WA,-32.5522,115.731,4
6210,Clifton,Western Australia,WA,-32.7699,115.6321,4
6210,Meadow Springs,Western Australia,WA,-32.4972,115.7533,4
6210,Mandurah East,Western Australia,WA,-32.5733,115.7613,3
6210,Silver Sands,Western Australia,WA,-32.5086,115.736,4
6210,Lakelands,Western Australia,WA,-32.4775,115.7557,4
6210,Furnissdale,Western Australia,WA,-32.5585,115.7604,4
6210,Mandurah North,Western Australia,WA,-32.5733,115.7613,3
6210,San Remo,Western Australia,WA,-32.4899,115.7447,4
6210,Coodanup,Western Australia,WA,-32.5519,115.7535,4
6210,Herron,Western Australia,WA,-32.7552,115.674,4
6210,Falcon,Western Australia,WA,-32.5824,115.662,4
6210,Dawesville,Western Australia,WA,-32.6323,115.629,4
6210,Barragup,Western Australia,WA,-32.5498,115.7796,4
6210,Stake Hill,Western Australia,WA,-32.4778,115.7942,4
6210,Halls Head,Western Australia,WA,-32.5431,115.6966,4
6210,Erskine,Western Australia,WA,-32.5572,115.7066,4
6210,Madora Bay,Western Australia,WA,-32.4695,115.7526,4
6211,Dawesville,Western Australia,WA,-32.6323,115.629,4
6211,Bouvard,Western Australia,WA,-32.7189,115.6389,4
6211,Clifton,Western Australia,WA,-32.7699,115.6321,4
6211,Herron,Western Australia,WA,-32.7552,115.674,4
6213,Marrinup,Western Australia,WA,-32.7,116.0167,4
6213,Dwellingup,Western Australia,WA,-32.7144,116.0622,4
6213,Etmilyn,Western Australia,WA,-32.7126,116.061,3
6213,Inglehope,Western Australia,WA,-32.7667,116.1667,4
6213,Banksiadale,Western Australia,WA,-32.65,116.0833,4
6213,Teesdale,Western Australia,WA,-32.7126,116.061,3
6213,Holyoake,Western Australia,WA,-32.7167,116.0833,4
6214,West Coolup,Western Australia,WA,-32.75,115.8667,4
6214,Coolup,Western Australia,WA,-32.75,115.8667,4
6214,Birchmont,Western Australia,WA,-32.7289,115.7752,3
6215,Waroona,Western Australia,WA,-32.8432,115.922,4
6215,Hamel,Western Australia,WA,-32.8741,115.927,4
6215,Lake Clifton,Western Australia,WA,-32.8134,115.6856,4
6215,Nanga Brook,Western Australia,WA,-32.8167,116.0667,4
6215,Wagerup,Western Australia,WA,-32.9102,115.8915,4
6215,Preston Beach,Western Australia,WA,-32.8668,115.6703,4
6218,Yarloop,Western Australia,WA,-32.9537,115.8969,4
6220,Hoffman,Western Australia,WA,-33.0367,115.9527,3
6220,Myalup,Western Australia,WA,-33.1021,115.692,4
6220,Cookernup,Western Australia,WA,-32.9833,115.8833,4
6220,Harvey,Western Australia,WA,-33.0799,115.8945,4
6220,Warawarrup,Western Australia,WA,-33.0367,115.9527,3
6220,Uduc,Western Australia,WA,-33.0493,115.8101,4
6221,Mornington,Western Australia,WA,-33.15,115.9333,4
6221,Wokalup,Western Australia,WA,-33.1167,115.8667,4
6223,Benger,Western Australia,WA,-33.1667,115.85,4
6224,Beela,Western Australia,WA,-33.1965,115.9656,3
6224,Brunswick,Western Australia,WA,-33.2324,115.8628,4
6225,Allanson,Western Australia,WA,-33.3359,116.0994,4
6225,Mumballup,Western Australia,WA,-33.5286,116.1133,4
6225,Palmer,Western Australia,WA,-31.4062,119.6815,4
6225,Mcalinden,Western Australia,WA,-33.578,116.2985,4
6225,Cardiff,Western Australia,WA,-33.4292,116.2078,4
6225,Shotts,Western Australia,WA,-33.3833,116.2667,4
6225,Lyalls Mill,Western Australia,WA,-33.2836,116.2116,3
6225,Bowelling,Western Australia,WA,-33.4167,116.5,4
6225,Noggerup,Western Australia,WA,-33.5833,116.1667,4
6225,Muja,Western Australia,WA,-33.2836,116.2116,3
6225,Buckingham,Western Australia,WA,-33.4,116.3333,4
6225,Collie,Western Australia,WA,-33.3621,116.1562,4
6225,Collie Burn,Western Australia,WA,-33.4,116.1833,4
6225,Mungalup,Western Australia,WA,-33.2836,116.2116,3
6225,Worsley,Western Australia,WA,-33.3069,116.0067,4
6225,Preston Settlement,Western Australia,WA,-33.4129,116.1578,3
6225,Yourdamung Lake,Western Australia,WA,-33.2072,116.2349,3
6225,Harris River,Western Australia,WA,-33.2836,116.2116,3
6226,Roelands,Western Australia,WA,-33.2833,115.8167,4
6227,Burekup,Western Australia,WA,-33.3071,115.8137,4
6228,Waterloo,Western Australia,WA,-33.3186,115.7567,1
6229,Picton East,Western Australia,WA,-33.3471,115.7016,3
6229,Picton,Western Australia,WA,-33.35,115.7,4
6230,Withers,Western Australia,WA,-33.3681,115.6305,4
6230,Dalyellup,Western Australia,WA,-33.4084,115.6151,4
6230,Gelorup,Western Australia,WA,-33.4051,115.6344,4
6230,Vittoria,Western Australia,WA,-33.3305,115.6659,4
6230,Glen Iris,Western Australia,WA,-33.3371,115.6743,4
6230,South Bunbury,Western Australia,WA,-33.3361,115.6403,3
6230,Pelican Point,Western Australia,WA,-33.3183,115.6929,4
6230,Bunbury,Western Australia,WA,-33.3271,115.6414,4
6230,East Bunbury,Western Australia,WA,-33.3363,115.6566,4
6230,College Grove,Western Australia,WA,-33.3759,115.6591,4
6230,Davenport,Western Australia,WA,-33.374,115.6852,4
6230,Carey Park,Western Australia,WA,-33.3488,115.6493,4
6230,Usher,Western Australia,WA,-33.3798,115.6292,4
6231,Bunbury,Western Australia,WA,-33.3271,115.6414,4
6232,Eaton,Western Australia,WA,-33.3166,115.705,4
6232,Millbridge,Western Australia,WA,-33.3062,115.735,4
6233,Parkfield,Western Australia,WA,-33.2139,115.7196,3
6233,Australind,Western Australia,WA,-33.2792,115.715,4
6233,Wellesley,Western Australia,WA,-33.2343,115.7526,4
6233,Leschenault,Western Australia,WA,-33.2452,115.7251,4
6233,Binningup,Western Australia,WA,-33.1483,115.6952,4
6236,Wellington Mill,Western Australia,WA,-33.4667,115.9,4
6236,Paradise,Western Australia,WA,-33.4403,115.8631,3
6236,Dardanup,Western Australia,WA,-33.3983,115.7518,4
6236,Wellington Forest,Western Australia,WA,-33.4403,115.8631,3
6236,Ferguson,Western Australia,WA,-33.4403,115.8631,3
6236,Dardanup West,Western Australia,WA,-33.3819,115.7017,3
6236,Crooked Brook,Western Australia,WA,-33.4403,115.8631,3
6236,Henty,Western Australia,WA,-33.4403,115.8631,3
6237,Stratham,Western Australia,WA,-33.4632,115.5862,4
6237,Elgin,Western Australia,WA,-33.5068,115.68,3
6237,Boyanup,Western Australia,WA,-33.4829,115.7284,4
6237,North Boyanup,Western Australia,WA,-33.4311,115.6889,3
6237,Gwindinup,Western Australia,WA,-33.5068,115.68,3
6237,The Plains,Western Australia,WA,-33.5068,115.68,3
6239,Beelerup,Western Australia,WA,-33.5756,115.8506,4
6239,Donnybrook,Western Australia,WA,-33.5713,115.8245,4
6239,Argyle,Western Australia,WA,-33.5458,115.7707,4
6239,Upper Capel,Western Australia,WA,-33.6486,115.8189,3
6239,Yabberup,Western Australia,WA,-33.5386,116.0125,3
6239,Thomson Brook,Western Australia,WA,-33.6087,115.9784,3
6239,Paynedale,Western Australia,WA,-33.6087,115.9784,3
6239,Queenwood,Western Australia,WA,-33.6087,115.9784,3
6239,Brookhampton,Western Australia,WA,-33.6087,115.9784,3
6239,Charley Creek,Western Australia,WA,-33.6087,115.9784,3
6239,Glen Mervyn,Western Australia,WA,-33.6087,115.9784,3
6240,Lowden,Western Australia,WA,-33.5344,115.9756,4
6243,Wilga West,Western Australia,WA,-33.6833,116.2333,4
6243,Wilga,Western Australia,WA,-33.6833,116.2333,4
6244,Mayanup,Western Australia,WA,-33.9355,116.4529,4
6244,Chowerup,Western Australia,WA,-34.0849,116.6746,4
6244,Boyup Brook,Western Australia,WA,-33.8343,116.3878,4
6244,Tonebridge,Western Australia,WA,-33.8918,116.524,3
6244,Dinninup,Western Australia,WA,-33.8141,116.5368,4
6244,Kulikup,Western Australia,WA,-33.8263,116.6771,4
6244,Trigwell,Western Australia,WA,-33.9035,116.5476,3
6244,Scotts Brook,Western Australia,WA,-33.8918,116.524,3
6251,Newlands,Western Australia,WA,-33.6667,115.8667,4
6251,Kirup,Western Australia,WA,-33.7,115.9,4
6251,Brazier,Western Australia,WA,-33.7636,115.8561,3
6252,Mullalyup,Western Australia,WA,-33.7333,115.9333,4
6253,Balingup,Western Australia,WA,-33.8,115.9667,4
6253,Southampton,Western Australia,WA,-33.7948,116.0503,3
6253,Grimwade,Western Australia,WA,-33.7948,116.0503,3
6254,Greenbushes,Western Australia,WA,-33.8497,116.0582,4
6254,North Greenbushes,Western Australia,WA,-33.8484,116.0569,3
6255,Benjinup,Western Australia,WA,-33.7667,116.3167,4
6255,Hester,Western Australia,WA,-33.9,116.15,4
6255,Bridgetown,Western Australia,WA,-33.9624,116.136,4
6255,Kangaroo Gully,Western Australia,WA,-33.9619,116.1986,3
6255,Catterick,Western Australia,WA,-33.9057,116.1308,3
6255,Winnejup,Western Australia,WA,-33.9057,116.1308,3
6255,Hester Brook,Western Australia,WA,-33.9057,116.1308,3
6256,Yornup,Western Australia,WA,-34.05,116.1667,4
6256,Sunnyside,Western Australia,WA,-34.0777,116.2364,3
6256,Glenlynn,Western Australia,WA,-34.0063,116.1535,4
6256,Maranup,Western Australia,WA,-34.0777,116.2364,3
6256,Wandillup,Western Australia,WA,-34.0777,116.2364,3
6256,Kingston,Western Australia,WA,-34.0777,116.2364,3
6258,Yanmah,Western Australia,WA,-34.1833,116.0167,4
6258,Wilgarrup,Western Australia,WA,-34.352,116.3659,3
6258,Palgarup,Western Australia,WA,-34.1625,116.1845,4
6258,Diamond Tree,Western Australia,WA,-34.352,116.3659,3
6258,Jardee,Western Australia,WA,-34.285,116.12,4
6258,Ringbark,Western Australia,WA,-34.352,116.3659,3
6258,Manjimup,Western Australia,WA,-34.2412,116.1456,4
6258,Linfarne,Western Australia,WA,-34.352,116.3659,3
6258,Balbarrup,Western Australia,WA,-34.2292,116.2017,4
6258,Smith Brook,Western Australia,WA,-34.352,116.3659,3
6258,Lake Muir,Western Australia,WA,-34.352,116.3659,3
6258,Dixvale,Western Australia,WA,-34.352,116.3659,3
6258,Quinninup,Western Australia,WA,-34.4355,116.2498,4
6258,Shannon River Mill,Western Australia,WA,-34.352,116.3659,3
6258,Deanmill,Western Australia,WA,-34.2647,116.0742,4
6258,Crowea,Western Australia,WA,-34.352,116.3659,3
6258,Upper Warren,Western Australia,WA,-34.352,116.3659,3
6258,Glenoran,Western Australia,WA,-34.352,116.3659,3
6258,Donnelly River,Western Australia,WA,-34.352,116.3659,3
6258,Middlesex,Western Australia,WA,-34.352,116.3659,3
6258,Mordalup,Western Australia,WA,-34.352,116.3659,3
6258,Dingup,Western Australia,WA,-34.2584,116.1878,3
6258,Nyamup,Western Australia,WA,-34.352,116.3659,3
6258,Perup,Western Australia,WA,-34.352,116.3659,3
6260,Eastbrook,Western Australia,WA,-34.4,116.1,4
6260,Pemberton,Western Australia,WA,-34.4431,116.0369,4
6260,Yeagarup,Western Australia,WA,-34.4216,116.0684,3
6260,Collins,Western Australia,WA,-34.4216,116.0684,3
6260,Biddelia,Western Australia,WA,-34.4447,115.9282,3
6260,Channybearup,Western Australia,WA,-34.4216,116.0684,3
6260,Callcup,Western Australia,WA,-34.4216,116.0684,3
6260,Beedelup,Western Australia,WA,-34.4216,116.0684,3
6260,Lake Jasper,Western Australia,WA,-34.4447,115.9282,3
6260,Peerabeelup,Western Australia,WA,-34.4447,115.9282,3
6262,Crowea,Western Australia,WA,-34.7331,116.0778,3
6262,Windy Harbour,Western Australia,WA,-34.8333,116.0333,4
6262,Northcliffe,Western Australia,WA,-34.6329,116.1224,4
6262,Boorara Brook,Western Australia,WA,-34.7331,116.0778,3
6262,Meerup,Western Australia,WA,-34.7331,116.0778,3
6262,Shannon,Western Australia,WA,-34.7331,116.0778,3
6271,Peppermint Grove Beach,Western Australia,WA,-33.5271,115.5065,4
6271,Capel,Western Australia,WA,-33.5581,115.5628,4
6271,Forrest Beach,Western Australia,WA,-33.5746,115.5801,3
6271,Capel River,Western Australia,WA,-33.5746,115.5801,3
6271,Stirling Estate,Western Australia,WA,-33.5746,115.5801,3
6275,Nannup,Western Australia,WA,-33.9787,115.7646,4
6275,Jarrahwood,Western Australia,WA,-33.8,115.6667,4
6275,Darradup,Western Australia,WA,-34.1358,115.6706,3
6275,Barrabup,Western Australia,WA,-34.1358,115.6706,3
6275,Carlotta,Western Australia,WA,-34.1358,115.6706,3
6275,Cundinup,Western Australia,WA,-34.1358,115.6706,3
6275,Scott River East,Western Australia,WA,-34.1358,115.6706,3
6275,Yoganup,Western Australia,WA,-34.1358,115.6706,3
6275,East Nannup,Western Australia,WA,-34.1358,115.6706,3
6275,Jalbarragup,Western Australia,WA,-34.1358,115.6706,3
6280,Carbunup River,Western Australia,WA,-33.7,115.1833,4
6280,Bovell,Western Australia,WA,-33.6801,115.361,4
6280,Busselton,Western Australia,WA,-33.6525,115.3455,4
6280,Broadwater,Western Australia,WA,-33.663,115.2842,4
6280,Yelverton,Western Australia,WA,-33.7505,115.1196,4
6280,Yoongarillup,Western Australia,WA,-33.7152,115.4268,4
6280,Ludlow,Western Australia,WA,-33.6,115.4667,4
6280,Kaloorup,Western Australia,WA,-33.7296,115.4072,3
6280,Vasse,Western Australia,WA,-33.6667,115.25,4
6280,Abbey,Western Australia,WA,-33.6636,115.2564,4
6280,Metricup,Western Australia,WA,-33.7755,115.1329,4
6280,Ruabon,Western Australia,WA,-33.7296,115.4072,3
6280,Ambergate,Western Australia,WA,-33.7373,115.3262,4
6280,Reinscourt,Western Australia,WA,-33.6535,115.4079,4
6280,Wonnerup,Western Australia,WA,-33.6333,115.4333,4
6280,West Busselton,Western Australia,WA,-33.6559,115.3227,3
6280,Yalyalup,Western Australia,WA,-33.675,115.4145,4
6280,Kealy,Western Australia,WA,-33.7296,115.4072,3
6280,Siesta Park,Western Australia,WA,-33.7296,115.4072,3
6280,Tutunup,Western Australia,WA,-33.7296,115.4072,3
6280,Anniebrook,Western Australia,WA,-33.6591,115.1726,4
6280,Kalgup,Western Australia,WA,-33.7296,115.4072,3
6280,North Jindong,Western Australia,WA,-33.7296,115.4072,3
6280,Hithergreen,Western Australia,WA,-33.7296,115.4072,3
6280,Walsall,Western Australia,WA,-33.7296,115.4072,3
6280,Jindong,Western Australia,WA,-33.7296,115.4072,3
6280,Sabina River,Western Australia,WA,-33.7296,115.4072,3
6280,Chapman Hill,Western Australia,WA,-33.7783,115.3272,3
6280,Boallia,Western Australia,WA,-33.7296,115.4072,3
6280,Wilyabrup,Western Australia,WA,-33.7956,115.0461,3
6280,Marybrook,Western Australia,WA,-33.6594,115.2,3
6280,Abba River,Western Australia,WA,-33.7296,115.4072,3
6280,Geographe,Western Australia,WA,-33.6397,115.3836,4
6280,Acton Park,Western Australia,WA,-33.7296,115.4072,3
6281,Quindalup,Western Australia,WA,-33.6356,115.149,4
6281,Dunsborough,Western Australia,WA,-33.6151,115.1056,4
6281,Eagle Bay,Western Australia,WA,-33.6164,115.0811,3
6281,Quedjinup,Western Australia,WA,-33.6206,115.0917,4
6281,Naturaliste,Western Australia,WA,-33.6164,115.0811,3
6282,Yallingup Siding,Western Australia,WA,-33.683,115.0977,4
6282,Yallingup,Western Australia,WA,-33.7,115.1,4
6284,Baudin,Western Australia,WA,-33.865,115.4242,3
6284,Cowaramup,Western Australia,WA,-33.8504,115.1038,4
6284,Gracetown,Western Australia,WA,-33.8673,114.9859,3
6284,Treeton,Western Australia,WA,-33.875,115.102,3
6285,Prevelly,Western Australia,WA,-33.9791,114.9943,4
6285,Rosa Glen,Western Australia,WA,-34.0152,115.2033,4
6285,Margaret River,Western Australia,WA,-33.9536,115.0739,4
6285,Osmington,Western Australia,WA,-33.921,115.206,3
6285,Bramley,Western Australia,WA,-33.9194,115.1135,4
6285,Schroeder,Western Australia,WA,-33.9504,115.3202,3
6285,Burnside,Western Australia,WA,-33.9369,115.0181,4
6285,Rosa Brook,Western Australia,WA,-33.95,115.1833,4
6285,Gnarabup,Western Australia,WA,-33.9919,114.9943,4
6286,Forest Grove,Western Australia,WA,-34.0667,115.0833,4
6286,Witchcliffe,Western Australia,WA,-34.0333,115.1,4
6286,Redgate,Western Australia,WA,-34.1242,115.0573,3
6286,Boranup,Western Australia,WA,-34.1242,115.0573,3
6288,Karridale,Western Australia,WA,-34.2167,115.0833,4
6288,Scott River,Western Australia,WA,-34.1742,115.2399,3
6288,Hamelin Bay,Western Australia,WA,-34.2264,115.0571,4
6288,Nillup,Western Australia,WA,-34.1742,115.2399,3
6288,Warner Glen,Western Australia,WA,-34.1742,115.2399,3
6288,Alexandra Bridge,Western Australia,WA,-34.1742,115.2399,3
6288,Courtenay,Western Australia,WA,-34.1742,115.2399,3
6290,Leeuwin,Western Australia,WA,-34.3455,115.1365,4
6290,Kudardup,Western Australia,WA,-34.2833,115.1,4
6290,East Augusta,Western Australia,WA,-34.0202,115.1211,4
6290,Molloy Island,Western Australia,WA,-34.2684,115.2097,3
6290,Augusta,Western Australia,WA,-34.3157,115.1592,4
6290,Deepdene,Western Australia,WA,-34.2528,115.1632,3
6302,Talbot West,Western Australia,WA,-32.0333,116.6833,4
6302,Gwambygine,Western Australia,WA,-31.9757,116.8063,4
6302,Burges,Western Australia,WA,-31.8167,116.7833,4
6302,St Ronans,Western Australia,WA,-31.9264,116.7354,3
6302,York,Western Australia,WA,-31.8881,116.7678,4
6302,Mount Observation,Western Australia,WA,-31.9264,116.7354,3
6302,Mount Hardey,Western Australia,WA,-31.9,116.8667,4
6302,Cold Harbour,Western Australia,WA,-31.9264,116.7354,3
6302,Malebelling,Western Australia,WA,-31.9264,116.7354,3
6302,Narraloggan,Western Australia,WA,-31.9264,116.7354,3
6302,Gilgering,Western Australia,WA,-32.0243,116.7984,3
6302,Wilberforce,Western Australia,WA,-31.9264,116.7354,3
6302,Flint,Western Australia,WA,-31.9264,116.7354,3
6302,Caljie,Western Australia,WA,-31.9264,116.7354,3
6302,Talbot,Western Australia,WA,-32.0333,116.6833,4
6302,Balladong,Western Australia,WA,-31.9264,116.7354,3
6302,Greenhills,Western Australia,WA,-31.9208,116.9643,4
6302,Daliak,Western Australia,WA,-31.9264,116.7354,3
6302,Inkpen,Western Australia,WA,-31.9264,116.7354,3
6302,Quellington,Western Australia,WA,-31.9264,116.7354,3
6302,Flynn,Western Australia,WA,-31.9264,116.7354,3
6302,Badgin,Western Australia,WA,-31.9264,116.7354,3
6302,Kauring,Western Australia,WA,-31.9264,116.7354,3
6304,Westdale,Western Australia,WA,-32.3134,116.6088,4
6304,Kokeby,Western Australia,WA,-32.2102,116.9712,4
6304,Bally Bally,Western Australia,WA,-32.1667,117.1,4
6304,Beverley,Western Australia,WA,-32.1084,116.9274,4
6304,East Beverley,Western Australia,WA,-32.0333,117.0167,4
6304,Morbinning,Western Australia,WA,-32.1,117.15,4
6304,Dale,Western Australia,WA,-32.0796,116.8083,4
6306,Brookton,Western Australia,WA,-32.3723,117.0105,4
6306,Jelcobine,Western Australia,WA,-32.362,117.0031,3
6306,Kweda,Western Australia,WA,-32.3667,117.4333,4
6306,Aldersyde,Western Australia,WA,-32.3747,117.2834,4
6306,Bulyee,Western Australia,WA,-32.3667,117.5167,4
6308,Springs,Western Australia,WA,-32.5363,116.8413,3
6308,Hastings,Western Australia,WA,-32.5363,116.8413,3
6308,Pingelly,Western Australia,WA,-32.5325,117.0824,4
6308,Dwarda,Western Australia,WA,-32.7667,116.6667,4
6308,East Pingelly,Western Australia,WA,-32.5103,117.1464,3
6308,Gillimanning,Western Australia,WA,-32.5363,116.8413,3
6308,West Pingelly,Western Australia,WA,-32.5363,116.8413,3
6308,Wandering,Western Australia,WA,-32.6833,116.6667,4
6308,Codjatotine,Western Australia,WA,-32.5363,116.8413,3
6308,Pumphreys Bridge,Western Australia,WA,-32.5363,116.8413,3
6309,Popanyinning,Western Australia,WA,-32.6667,117.1333,4
6309,Stratherne,Western Australia,WA,-32.6618,117.1292,3
6309,East Popanyinning,Western Australia,WA,-32.6329,117.2604,4
6309,West Popanyinning,Western Australia,WA,-32.6618,117.1292,3
6311,Yornaning,Western Australia,WA,-32.7333,117.15,4
6311,Contine,Western Australia,WA,-32.8667,116.9667,4
6311,Dryandra,Western Australia,WA,-32.7667,116.9667,4
6311,Wardering,Western Australia,WA,-32.7358,117.1593,3
6311,Cuballing,Western Australia,WA,-32.8196,117.1758,4
6311,Commodine,Western Australia,WA,-32.7358,117.1593,3
6311,Lol Gray,Western Australia,WA,-32.7358,117.1593,3
6311,Townsendale,Western Australia,WA,-32.7358,117.1593,3
6312,Yilliminning,Western Australia,WA,-32.8994,117.3744,4
6312,Narrogin Valley,Western Australia,WA,-32.9953,117.2623,3
6312,Nomans Lake,Western Australia,WA,-32.9256,117.5194,4
6312,Hillside,Western Australia,WA,-32.9953,117.2623,3
6312,Dumberning,Western Australia,WA,-33.0042,117.101,4
6312,Minigin,Western Australia,WA,-32.9953,117.2623,3
6312,Narrogin,Western Australia,WA,-32.9328,117.1776,4
6312,Boundain,Western Australia,WA,-32.95,117.3167,4
6312,Toolibin,Western Australia,WA,-32.946,117.6225,4
6313,Highbury,Western Australia,WA,-33.0667,117.25,4
6315,Piesseville,Western Australia,WA,-33.1909,117.2901,4
6315,Gundaring,Western Australia,WA,-33.3398,117.1803,3
6315,Jaloran,Western Australia,WA,-33.15,117.4333,4
6315,Dongolocking,Western Australia,WA,-33.1333,117.8167,4
6315,Lime Lake,Western Australia,WA,-33.3398,117.1803,3
6315,Ballaying,Western Australia,WA,-33.3064,117.5525,4
6315,Minding,Western Australia,WA,-33.3398,117.1803,3
6315,Collanilling,Western Australia,WA,-33.2667,117.5,4
6315,Wedgecarrup,Western Australia,WA,-33.3398,117.1803,3
6315,Wagin,Western Australia,WA,-33.3089,117.3433,4
6315,Arthur River,Western Australia,WA,-33.351,117.0318,4
6315,Cancanning,Western Australia,WA,-33.1333,117.5667,4
6316,Woodanilling,Western Australia,WA,-33.5667,117.4333,4
6316,Boyerine,Western Australia,WA,-33.5336,117.3382,3
6316,Cartmeticup,Western Australia,WA,-33.47,117.5175,4
6316,Glencoe,Western Australia,WA,-33.5336,117.3382,3
6316,Kenmare,Western Australia,WA,-33.5336,117.3382,3
6316,Westwood,Western Australia,WA,-33.5336,117.3382,3
6317,Badgebup,Western Australia,WA,-33.6333,117.9,4
6317,Datatine,Western Australia,WA,-33.4667,117.9167,4
6317,Ewlyamartup,Western Australia,WA,-33.7055,117.7372,4
6317,Moojebing,Western Australia,WA,-33.6483,117.6843,3
6317,Murdong,Western Australia,WA,-33.7577,117.5995,4
6317,Katanning,Western Australia,WA,-33.6879,117.5584,4
6317,Coyrecup,Western Australia,WA,-33.6781,117.83,4
6317,Pinwernying,Western Australia,WA,-33.6483,117.6843,3
6317,Marracoonda,Western Australia,WA,-33.65,117.4167,4
6317,Coblinine,Western Australia,WA,-33.6483,117.6843,3
6317,Carrolup,Western Australia,WA,-33.7435,117.3999,4
6317,South Datatine,Western Australia,WA,-33.6483,117.6843,3
6317,Bullock Hills,Western Australia,WA,-33.6483,117.6843,3
6317,South Glencoe,Western Australia,WA,-33.6483,117.6843,3
6318,Broomehill,Western Australia,WA,-33.843,117.6401,4
6318,Broomehill West,Western Australia,WA,-33.8785,117.6161,3
6318,Broomehill East,Western Australia,WA,-33.8785,117.6161,3
6318,Broomehill Village,Western Australia,WA,-33.8785,117.6161,3
6320,Tambellup,Western Australia,WA,-34.0433,117.6414,4
6320,Moonies Hill,Western Australia,WA,-34.0833,117.5167,4
6320,Wansbrough,Western Australia,WA,-34.1432,117.6715,4
6320,Lake Toolbrunup,Western Australia,WA,-34.1035,117.6981,3
6320,Bobalong,Western Australia,WA,-34.1035,117.6981,3
6320,Borderdale,Western Australia,WA,-34.1035,117.6981,3
6320,Dartnall,Western Australia,WA,-34.1035,117.6981,3
6321,Cranbrook,Western Australia,WA,-34.2976,117.5518,4
6322,Tenterden,Western Australia,WA,-34.362,117.5549,4
6323,Kendenup,Western Australia,WA,-34.4821,117.6253,4
6324,Takalarup,Western Australia,WA,-34.4793,117.8964,3
6324,Porongurup,Western Australia,WA,-34.65,117.9,4
6324,Denbarker,Western Australia,WA,-34.4793,117.8964,3
6324,Woogenellup,Western Australia,WA,-34.5167,117.8333,4
6324,Perillup,Western Australia,WA,-34.4793,117.8964,3
6324,Mount Barker,Western Australia,WA,-34.6308,117.6661,4
6324,Forest Hill,Western Australia,WA,-34.4793,117.8964,3
6324,South Stirling,Western Australia,WA,-34.4793,117.8964,3
6326,Narrikup,Western Australia,WA,-34.7723,117.7042,4
6327,Redmond,Western Australia,WA,-34.8667,117.6833,4
6327,Redmond West,Western Australia,WA,-34.8667,117.6833,4
6328,Mettler,Western Australia,WA,-34.8333,118.1667,3
6328,Manypeaks,Western Australia,WA,-34.8381,118.1698,4
6328,Palmdale,Western Australia,WA,-34.6048,118.4088,3
6328,Cape Riche,Western Australia,WA,-34.6048,118.4088,3
6328,Gnowellen,Western Australia,WA,-34.6048,118.4088,3
6328,Cheynes,Western Australia,WA,-34.6048,118.4088,3
6328,Green Range,Western Australia,WA,-34.6048,118.4088,3
6328,Wellstead,Western Australia,WA,-34.4952,118.6014,3
6328,Kojaneerup South,Western Australia,WA,-34.6048,118.4088,3
6330,Kalgan,Western Australia,WA,-34.8847,118.0131,4
6330,Napier,Western Australia,WA,-34.8333,118,4
6330,Mount Clarence,Western Australia,WA,-35.0253,117.9023,4
6330,Green Valley,Western Australia,WA,-34.9448,117.8359,3
6330,Torbay,Western Australia,WA,-35.0233,117.6606,4
6330,Milpara,Western Australia,WA,-34.9889,117.8707,4
6330,Lower King,Western Australia,WA,-34.9578,117.9383,4
6330,Lowlands,Western Australia,WA,-34.9448,117.8359,3
6330,Willyung,Western Australia,WA,-34.9433,117.8795,4
6330,Mckail,Western Australia,WA,-34.9853,117.8301,4
6330,Mount Elphinstone,Western Australia,WA,-35.0194,117.8518,4
6330,Collingwood Park,Western Australia,WA,-34.9448,117.8359,3
6330,Marbelup,Western Australia,WA,-34.9675,117.7333,4
6330,Orana,Western Australia,WA,-34.996,117.8592,4
6330,Yakamia,Western Australia,WA,-35.0013,117.8751,4
6330,Millbrook,Western Australia,WA,-34.9068,117.8726,4
6330,Frenchman Bay,Western Australia,WA,-35.0935,117.9491,4
6330,Bornholm,Western Australia,WA,-35.0606,117.5769,4
6330,Seppings,Western Australia,WA,-35.0099,117.9087,4
6330,Nanarup,Western Australia,WA,-34.9448,117.8359,3
6330,Mira Mar,Western Australia,WA,-35.0152,117.901,4
6330,Albany,Western Australia,WA,-35.0031,117.866,4
6330,West Cape Howe,Western Australia,WA,-35.0827,117.6255,4
6330,Nullaki,Western Australia,WA,-34.9448,117.8359,3
6330,Centennial Park,Western Australia,WA,-35.0153,117.8805,4
6330,Youngs Siding,Western Australia,WA,-35.0167,117.5167,4
6330,Big Grove,Western Australia,WA,-35.0808,117.879,4
6330,Sandpatch,Western Australia,WA,-34.9448,117.8359,3
6330,Lange,Western Australia,WA,-34.9914,117.8817,4
6330,Cuthbert,Western Australia,WA,-35.0158,117.7983,4
6330,King River,Western Australia,WA,-34.9344,117.9119,4
6330,Vancouver Peninsula,Western Australia,WA,-34.9448,117.8359,3
6330,Gledhow,Western Australia,WA,-35.0063,117.8269,4
6330,Collingwood Heights,Western Australia,WA,-34.9927,117.9207,4
6330,Lockyer,Western Australia,WA,-35.0057,117.8588,4
6330,Port Albany,Western Australia,WA,-34.9448,117.8359,3
6330,Mount Melville,Western Australia,WA,-35.0148,117.8689,4
6330,Bayonet Head,Western Australia,WA,-34.973,117.9345,4
6330,Emu Point,Western Australia,WA,-35,117.9381,4
6330,Little Grove,Western Australia,WA,-35.068,117.872,4
6330,Warrenup,Western Australia,WA,-34.9755,117.8472,4
6330,Spencer Park,Western Australia,WA,-35.0052,117.9001,4
6330,Elleker,Western Australia,WA,-35.0092,117.7303,4
6330,Robinson,Western Australia,WA,-35.0316,117.8408,4
6330,Kronkup,Western Australia,WA,-35.0506,117.6186,4
6330,Middleton Beach,Western Australia,WA,-35.0237,117.911,4
6330,Goode Beach,Western Australia,WA,-35.0887,117.9358,4
6330,Drome,Western Australia,WA,-34.9572,117.8109,4
6330,Walmsley,Western Australia,WA,-34.9739,117.903,4
6330,Torndirrup,Western Australia,WA,-35.0941,117.906,4
6331,Albany Dc,Western Australia,WA,-34.6956,117.882,1
6332,Albany,Western Australia,WA,-35.0031,117.866,4
6332,Albany Po,Western Australia,WA,-35.0031,117.866,3
6333,Kentdale,Western Australia,WA,-34.9,117.05,4
6333,Hay,Western Australia,WA,-34.8905,117.1188,3
6333,Parryville,Western Australia,WA,-34.9833,117.1833,4
6333,Denmark,Western Australia,WA,-34.9607,117.3532,4
6333,Nornalup,Western Australia,WA,-34.9917,116.8202,4
6333,Shadforth,Western Australia,WA,-34.9638,117.2692,4
6333,William Bay,Western Australia,WA,-34.8905,117.1188,3
6333,Mount Romance,Western Australia,WA,-34.8119,117.1347,3
6333,Ocean Beach,Western Australia,WA,-35.0202,117.3263,3
6333,Trent,Western Australia,WA,-34.9131,116.9396,3
6333,Tingledale,Western Australia,WA,-34.8905,117.1188,3
6333,Peaceful Bay,Western Australia,WA,-35.0225,116.9165,4
6333,Hazelvale,Western Australia,WA,-34.8905,117.1188,3
6333,Bow Bridge,Western Australia,WA,-34.8905,117.1188,3
6333,Mount Lindesay,Western Australia,WA,-34.8905,117.1188,3
6333,Kordabup,Western Australia,WA,-34.929,117.1486,4
6333,Scotsdale,Western Australia,WA,-34.9081,117.2825,3
6335,Kebaringup,Western Australia,WA,-34.0333,118.15,4
6335,Gnowangerup,Western Australia,WA,-33.937,118.009,4
6335,Pallinup,Western Australia,WA,-33.8664,118.1111,3
6335,Jackitup,Western Australia,WA,-33.8664,118.1111,3
6336,Needilup,Western Australia,WA,-33.95,118.8167,4
6336,Toompup,Western Australia,WA,-34.0133,118.4278,4
6336,Ongerup,Western Australia,WA,-33.9656,118.4869,4
6336,Mindarabin,Western Australia,WA,-33.8753,118.5061,3
6336,Mills Lake,Western Australia,WA,-33.8753,118.5061,3
6336,Cowalellup,Western Australia,WA,-33.8753,118.5061,3
6337,Jerramungup,Western Australia,WA,-33.9422,118.9173,4
6337,West Fitzgerald,Western Australia,WA,-33.9438,119.2324,3
6337,Jacup,Western Australia,WA,-33.8167,119.2667,4
6337,Gairdner,Western Australia,WA,-33.9438,119.2324,3
6337,Fitzgerald,Western Australia,WA,-33.9438,119.2324,3
6338,Bremer Bay,Western Australia,WA,-34.3925,119.3798,4
6338,Borden,Western Australia,WA,-34.0724,118.2721,4
6338,Nalyerlup,Western Australia,WA,-34.1703,118.7076,3
6338,North Stirlings,Western Australia,WA,-34.1703,118.7076,3
6338,Monjebup,Western Australia,WA,-34.1703,118.7076,3
6338,Boxwood Hill,Western Australia,WA,-34.3617,118.7467,4
6338,Magitup,Western Australia,WA,-34.1703,118.7076,3
6338,Stirling Range National Park,Western Australia,WA,-34.4044,118.0419,3
6338,Amelup,Western Australia,WA,-34.2385,118.2209,3
6341,Nyabing,Western Australia,WA,-33.5413,118.146,4
6343,Pingrup,Western Australia,WA,-33.5378,118.5122,4
6346,Ravensthorpe,Western Australia,WA,-33.583,120.0469,4
6346,Fitzgerald River National Park,Western Australia,WA,-34.0016,119.598,4
6346,Jerdacuttup,Western Australia,WA,-33.8964,120.213,4
6346,West River,Western Australia,WA,-33.4166,120.1755,3
6348,Hopetoun,Western Australia,WA,-33.9506,120.1264,4
6350,Nippering,Western Australia,WA,-33.295,117.638,4
6350,Dumbleyung,Western Australia,WA,-33.3126,117.7393,4
6350,Nairibin,Western Australia,WA,-33.2563,117.7554,3
6351,Moulyinning,Western Australia,WA,-33.2254,117.9269,4
6351,North Moulyinning,Western Australia,WA,-33.25,117.9333,3
6352,Kukerin,Western Australia,WA,-33.1833,118.0833,4
6352,Merilup,Western Australia,WA,-33.2667,118.1667,4
6352,North Kukerin,Western Australia,WA,-33.2087,118.0664,3
6352,South Kukerin,Western Australia,WA,-33.2087,118.0664,3
6353,Beenong,Western Australia,WA,-33.045,118.5853,4
6353,Kuender,Western Australia,WA,-32.95,118.5333,4
6353,North Lake Grace,Western Australia,WA,-32.95,118.4667,4
6353,Mallee Hill,Western Australia,WA,-32.9592,118.4872,3
6353,Lake Grace,Western Australia,WA,-33.1032,118.4646,4
6353,Neendaling,Western Australia,WA,-33.1167,118.3333,4
6353,Tarin Rock,Western Australia,WA,-33.1075,118.2308,4
6353,North Burngup,Western Australia,WA,-32.9592,118.4872,3
6353,Buniche,Western Australia,WA,-33.0039,118.766,4
6353,South Lake Grace,Western Australia,WA,-32.8333,119.5333,4
6355,Lake Biddy,Western Australia,WA,-33.0019,118.9292,4
6355,Lake Camm,Western Australia,WA,-32.9833,119.5833,4
6355,Varley,Western Australia,WA,-32.7667,119.45,4
6355,South Newdegate,Western Australia,WA,-33.0865,118.9698,3
6355,Newdegate,Western Australia,WA,-33.0939,119.0339,4
6355,Holt Rock,Western Australia,WA,-32.6722,119.406,4
6355,Mount Sheridan,Western Australia,WA,-33.0865,118.9698,3
6355,East Newdegate,Western Australia,WA,-33.0865,118.9698,3
6355,Magenta,Western Australia,WA,-33.0865,118.9698,3
6355,Dunn Rock,Western Australia,WA,-33.0865,118.9698,3
6355,Little Italy,Western Australia,WA,-33.0865,118.9698,3
6356,Lake King,Western Australia,WA,-33.0859,119.6755,4
6356,Mount Madden,Western Australia,WA,-33.2667,119.7833,4
6356,Hatter Hill,Western Australia,WA,-33.175,119.725,3
6357,Pingaring,Western Australia,WA,-32.7546,118.6278,4
6358,Karlgarin,Western Australia,WA,-32.4971,118.7098,4
6359,Hyden,Western Australia,WA,-32.45,118.8833,4
6359,Forrestania,Western Australia,WA,-32.4947,119.4469,3
6361,Tincurrin,Western Australia,WA,-32.9768,117.7739,4
6361,Harrismith,Western Australia,WA,-32.9357,117.8609,4
6363,Dudinin,Western Australia,WA,-32.8762,117.9053,4
6363,Walyurin,Western Australia,WA,-32.8483,117.9961,3
6365,Jilakin,Western Australia,WA,-32.6667,118.3333,4
6365,Kulin,Western Australia,WA,-32.6668,118.158,4
6365,Jitarning,Western Australia,WA,-32.7869,118.0047,4
6365,Kulin West,Western Australia,WA,-32.7164,118.2755,3
6367,Kondinin,Western Australia,WA,-32.4986,118.2653,4
6368,South Kumminin,Western Australia,WA,-32.2,118.3333,4
6369,Mount Walker,Western Australia,WA,-32.0823,118.7442,3
6369,Wadderin,Western Australia,WA,-31.994,118.4505,4
6369,West Holleton,Western Australia,WA,-31.95,119.0333,4
6369,Woolocutty,Western Australia,WA,-32.0823,118.7442,3
6369,Narembeen,Western Australia,WA,-32.0637,118.3957,4
6370,Wickepin,Western Australia,WA,-32.7816,117.503,4
6370,Kirk Rock,Western Australia,WA,-32.767,117.7208,3
6370,East Wickepin,Western Australia,WA,-32.7833,117.65,4
6370,Malyalling,Western Australia,WA,-32.7,117.5333,4
6370,Wogolin,Western Australia,WA,-32.767,117.7208,3
6372,Yealering,Western Australia,WA,-32.5921,117.6273,4
6373,Bullaring,Western Australia,WA,-32.4964,117.7453,4
6375,Bilbarin,Western Australia,WA,-32.2061,117.9529,4
6375,Corrigin,Western Australia,WA,-32.3338,117.8718,4
6375,Kurrenkutten,Western Australia,WA,-32.25,118.2167,4
6375,Gorge Rock,Western Australia,WA,-32.4567,117.9978,4
6375,Kunjin,Western Australia,WA,-32.3554,117.7709,4
6375,Adamsvale,Western Australia,WA,-32.3416,117.8368,3
6383,South Quairading,Western Australia,WA,-32.1667,117.4667,4
6383,Mount Stirling,Western Australia,WA,-32.0292,117.3523,3
6383,Balkuling,Western Australia,WA,-31.9812,117.1139,4
6383,Cubbine,Western Australia,WA,-32.0292,117.3523,3
6383,Doodenanning,Western Australia,WA,-31.8667,117.15,4
6383,Mawson,Western Australia,WA,-32.0292,117.3523,3
6383,Quairading,Western Australia,WA,-32.012,117.3955,4
6383,Dulbelling,Western Australia,WA,-32.05,117.2667,4
6383,Wamenusking,Western Australia,WA,-32.1258,117.5997,4
6383,Badjaling,Western Australia,WA,-31.9972,117.4989,4
6383,Yoting,Western Australia,WA,-31.9602,117.5914,4
6383,Dangin,Western Australia,WA,-32.05,117.3167,4
6384,Pantapin,Western Australia,WA,-31.9525,117.6572,4
6385,Kwolyin,Western Australia,WA,-31.9295,117.7501,4
6386,Shackleton,Western Australia,WA,-31.9325,117.8313,4
6390,Ranford,Western Australia,WA,-32.8,116.5,4
6390,Boddington,Western Australia,WA,-32.8007,116.4752,4
6390,Crossman,Western Australia,WA,-32.7833,116.6,4
6390,North Bannister,Western Australia,WA,-32.5833,116.4333,4
6390,Bannister,Western Australia,WA,-32.5833,116.4333,4
6390,Marradong,Western Australia,WA,-32.8631,116.4508,4
6390,Wuraming,Western Australia,WA,-32.8,116.2833,4
6390,Lower Hotham,Western Australia,WA,-32.817,116.4003,3
6390,Mount Wells,Western Australia,WA,-32.817,116.4003,3
6390,Upper Murray,Western Australia,WA,-32.817,116.4003,3
6390,Mount Cooke,Western Australia,WA,-32.817,116.4003,3
6391,Williams,Western Australia,WA,-33.028,116.8792,4
6391,Narrakine,Western Australia,WA,-33.0714,116.7281,3
6391,Congelin,Western Australia,WA,-32.8402,116.8854,4
6391,Tarwonga,Western Australia,WA,-33.0714,116.7281,3
6391,Quindanning,Western Australia,WA,-33.0466,116.5698,4
6391,Culbin,Western Australia,WA,-33.0714,116.7281,3
6391,Hillman River,Western Australia,WA,-32.2833,115.7636,4
6391,Boraning,Western Australia,WA,-33.0714,116.7281,3
6392,Meeking,Western Australia,WA,-33.3563,116.8259,3
6392,Bokal,Western Australia,WA,-33.4814,116.9005,4
6392,Boolading,Western Australia,WA,-33.4836,116.5773,3
6392,Darkan,Western Australia,WA,-33.3357,116.7443,4
6392,Dardadine,Western Australia,WA,-33.2333,116.8333,4
6393,Duranillin,Western Australia,WA,-33.5159,116.8072,4
6393,Moodiarrup,Western Australia,WA,-33.5167,116.725,3
6393,Cordering,Western Australia,WA,-33.4971,116.6572,4
6393,Trigwell,Western Australia,WA,-33.5167,116.725,3
6394,Muradup,Western Australia,WA,-33.8333,116.9833,4
6394,Orchid Valley,Western Australia,WA,-33.7898,116.8998,3
6394,Boscabel,Western Australia,WA,-33.6667,117.05,4
6394,Beaufort River,Western Australia,WA,-33.7898,116.8998,3
6394,Boilup,Western Australia,WA,-33.7898,116.8998,3
6394,Mokup,Western Australia,WA,-33.7778,116.9444,3
6394,Qualeup,Western Australia,WA,-33.8333,116.8,4
6394,Changerup,Western Australia,WA,-33.7898,116.8998,3
6395,Kojonup,Western Australia,WA,-33.8305,117.1595,4
6395,Mobrup,Western Australia,WA,-34.1257,116.9282,4
6395,Ryansbrook,Western Australia,WA,-34.0305,117.0231,3
6395,Jingalup,Western Australia,WA,-33.9667,117.0333,4
6395,Lumeah,Western Australia,WA,-33.9833,117.2333,4
6395,Cherry Tree Pool,Western Australia,WA,-33.75,117.2833,4
6396,Frankland,Western Australia,WA,-34.3667,117.0667,4
6396,Frankland River,Western Australia,WA,-34.3667,117.0667,4
6397,Rocky Gully,Western Australia,WA,-34.5105,117.0106,4
6398,Broke,Western Australia,WA,-34.796,116.5997,3
6398,North Walpole,Western Australia,WA,-34.7234,116.6612,4
6398,Walpole,Western Australia,WA,-34.976,116.7302,4
6401,Southern Brook,Western Australia,WA,-31.5451,116.8231,4
6401,Cunjardine,Western Australia,WA,-31.5588,116.7519,3
6401,Northam,Western Australia,WA,-31.6493,116.6742,4
6401,Spencers Brook,Western Australia,WA,-31.7366,116.6306,4
6401,Jennapullin,Western Australia,WA,-31.5329,116.7201,4
6401,Muluckine,Western Australia,WA,-31.6363,116.7491,3
6401,Rossmore,Western Australia,WA,-31.5588,116.7519,3
6401,Burlong,Western Australia,WA,-31.5588,116.7519,3
6401,Muresk,Western Australia,WA,-31.75,116.6667,4
6401,Irishtown,Western Australia,WA,-31.5588,116.7519,3
6401,Mokine,Western Australia,WA,-31.7441,116.5727,3
6401,Buckland,Western Australia,WA,-31.5588,116.7519,3
6401,Malabaine,Western Australia,WA,-31.5588,116.7519,3
6401,Meenaar,Western Australia,WA,-31.5588,116.7519,3
6401,Throssell,Western Australia,WA,-31.5588,116.7519,3
6401,Jennacubbine,Western Australia,WA,-31.4402,116.7247,4
6401,Wongamine,Western Australia,WA,-31.476,116.617,4
6401,Mumberkine,Western Australia,WA,-31.5588,116.7519,3
6403,Grass Valley,Western Australia,WA,-31.6373,116.7976,4
6405,Warding East,Western Australia,WA,-31.6254,117.0059,3
6405,Meckering,Western Australia,WA,-31.6277,117.0114,4
6405,Quelagetting,Western Australia,WA,-31.6254,117.0059,3
6405,Greenwoods Valley,Western Australia,WA,-31.6254,117.0059,3
6407,Youndegin,Western Australia,WA,-31.7885,117.3287,4
6407,Wyola West,Western Australia,WA,-31.5995,117.1561,3
6407,Cunderdin,Western Australia,WA,-31.654,117.2337,4
6407,Wyola,Western Australia,WA,-31.5995,117.1561,3
6407,Watercarrin,Western Australia,WA,-31.5995,117.1561,3
6407,Waeel,Western Australia,WA,-31.5995,117.1561,3
6409,South Tammin,Western Australia,WA,-31.8099,117.5363,4
6409,North Tammin,Western Australia,WA,-31.6167,117.5389,3
6409,Tammin,Western Australia,WA,-31.6667,117.5,4
6409,Doongin,Western Australia,WA,-31.6067,117.495,3
6409,Yorkrakine,Western Australia,WA,-31.3679,117.5858,4
6410,Mount Caroline,Western Australia,WA,-31.9586,117.5524,3
6410,Daadenning Creek,Western Australia,WA,-31.7667,117.7833,4
6410,North Kellerberrin,Western Australia,WA,-31.5,117.6833,4
6410,Bungulla,Western Australia,WA,-31.6238,117.5898,4
6410,Kellerberrin,Western Australia,WA,-31.6318,117.7198,4
6411,South Doodlakine,Western Australia,WA,-31.5822,117.8148,3
6411,Doodlakine,Western Australia,WA,-31.6092,117.8722,4
6412,Baandee,Western Australia,WA,-31.5832,117.9536,4
6412,North Baandee,Western Australia,WA,-31.5833,117.9667,3
6413,Hines Hill,Western Australia,WA,-31.5342,118.0748,4
6414,Nangeenan,Western Australia,WA,-31.5333,118.1667,4
6415,Merredin,Western Australia,WA,-31.4809,118.2745,4
6415,Goomarin,Western Australia,WA,-31.2444,118.4358,4
6415,Tandegin,Western Australia,WA,-31.4231,118.2344,3
6415,Norpa,Western Australia,WA,-31.584,118.4238,4
6415,Nokaning,Western Australia,WA,-31.3733,118.2183,4
6415,Korbel,Western Australia,WA,-31.6602,118.1489,4
6418,Yarding,Western Australia,WA,-31.9276,117.9851,4
6418,Bruce Rock,Western Australia,WA,-31.8805,118.1475,4
6419,Ardath,Western Australia,WA,-32.0325,118.0952,4
6420,Cramphorne,Western Australia,WA,-31.805,118.5601,4
6420,Muntadgin,Western Australia,WA,-31.7578,118.5619,4
6421,Burracoppin,Western Australia,WA,-31.3975,118.4815,4
6421,South Burracoppin,Western Australia,WA,-31.5667,118.1167,4
6421,Warralakin,Western Australia,WA,-31.0167,118.6051,4
6422,Walgoolan,Western Australia,WA,-31.3833,118.5667,4
6423,Warrachuppin,Western Australia,WA,-30.9683,118.6569,3
6423,Carrabin,Western Australia,WA,-31.3936,118.6719,4
6423,Westonia,Western Australia,WA,-31.3029,118.6962,4
6423,Boodarockin,Western Australia,WA,-31.0127,118.8046,4
6424,Bodallin,Western Australia,WA,-31.3766,118.8761,4
6424,South Bodallin,Western Australia,WA,-31.3667,118.8667,3
6424,North Bodallin,Western Australia,WA,-31.3667,118.8667,3
6425,Moorine Rock,Western Australia,WA,-31.3208,119.1267,4
6425,Dulyalbin,Western Australia,WA,-31.6054,119.034,3
6426,Parker Range,Western Australia,WA,-31.6333,119.5833,4
6426,Ghooli,Western Australia,WA,-31.2618,119.4568,4
6426,Holleton,Western Australia,WA,-31.95,119.0333,4
6426,Turkey Hill,Western Australia,WA,-31.7573,119.6086,3
6426,Marvel Loch,Western Australia,WA,-31.4678,119.4873,4
6426,Mount Hampton,Western Australia,WA,-31.7573,119.6086,3
6426,Southern Cross,Western Australia,WA,-31.2268,119.3238,4
6426,Mount Holland,Western Australia,WA,-31.7573,119.6086,3
6426,Yellowdine,Western Australia,WA,-31.2927,119.6532,4
6426,Corinthia,Western Australia,WA,-31.1171,119.234,4
6426,Mount Palmer,Western Australia,WA,-31.4062,119.6815,4
6426,Mount Jackson,Western Australia,WA,-31.7573,119.6086,3
6426,Skeleton Rock,Western Australia,WA,-31.7573,119.6086,3
6426,South Yilgarn,Western Australia,WA,-31.7573,119.6086,3
6427,Koolyanobbing,Western Australia,WA,-30.8212,119.5214,4
6428,Babakin,Western Australia,WA,-32.1264,118.0241,4
6429,Mount Burges,Western Australia,WA,-30.852,121.117,4
6429,Wallaroo,Western Australia,WA,-30.8763,120.5734,4
6429,Karramindie,Western Australia,WA,-31.4493,120.7804,3
6429,Londonderry,Western Australia,WA,-31.074,121.1228,4
6429,Victoria Rock,Western Australia,WA,-31.4493,120.7804,3
6429,Boorabbin,Western Australia,WA,-31.214,120.3101,4
6429,Coolgardie,Western Australia,WA,-30.9536,121.166,4
6429,Bullabulling,Western Australia,WA,-31.0135,120.871,4
6430,Kalgoorlie,Western Australia,WA,-30.7461,121.4742,4
6430,Karlkurla,Western Australia,WA,-30.8326,121.49,3
6430,West Lamington,Western Australia,WA,-30.7342,121.4616,4
6430,Binduli,Western Australia,WA,-30.8326,121.49,3
6430,Mullingar,Western Australia,WA,-30.7168,121.4746,4
6430,South Kalgoorlie,Western Australia,WA,-30.764,121.48,3
6430,Somerville,Western Australia,WA,-30.7698,121.4544,4
6430,West Kalgoorlie,Western Australia,WA,-30.8326,121.49,3
6430,Lamington,Western Australia,WA,-30.7342,121.4616,4
6430,Broadwood,Western Australia,WA,-30.7903,121.4549,4
6430,Hannans,Western Australia,WA,-30.7191,121.4548,4
6430,Yilkari,Western Australia,WA,-30.8326,121.49,3
6430,Piccadilly,Western Australia,WA,-30.7428,121.4642,4
6430,Williamstown,Western Australia,WA,-30.75,121.4833,4
6431,Kanowna,Western Australia,WA,-30.6117,121.6074,4
6431,Feysville,Western Australia,WA,-30.7126,123.8117,3
6431,Ora Banda,Western Australia,WA,-30.377,121.0619,4
6431,Boorara,Western Australia,WA,-30.8167,121.6333,4
6431,Kookynie,Western Australia,WA,-29.3317,121.4925,4
6431,Emu Flat,Western Australia,WA,-30.7126,123.8117,3
6431,Kurnalpi,Western Australia,WA,-30.5367,122.2306,4
6431,Lakewood,Western Australia,WA,-30.8,121.5333,4
6431,Trafalgar,Western Australia,WA,-30.7769,121.5122,4
6431,Plumridge Lakes,Western Australia,WA,-30.7126,123.8117,3
6431,Bulong,Western Australia,WA,-30.7484,121.7945,4
6431,Brown Hill,Western Australia,WA,-30.7667,121.5,4
6431,Warburton,Western Australia,WA,-30.5278,121.5963,3
6432,Boulder,Western Australia,WA,-30.782,121.4912,4
6432,Victory Heights,Western Australia,WA,-30.7788,121.4726,4
6432,Fimiston,Western Australia,WA,-30.7765,121.5119,3
6432,South Boulder,Western Australia,WA,-30.7917,121.4887,3
6433,Kalgoorlie,Western Australia,WA,-30.7461,121.4742,4
6433,Hannans,Western Australia,WA,-30.7191,121.4548,4
6433,Kalgoorlie Po,Western Australia,WA,-30.7326,121.4645,3
6433,Kalgoorlie DC,Western Australia,WA,-30.7326,121.4645,3
6433,Hannans Po,Western Australia,WA,-30.7326,121.4645,3
6434,Cundeelee,Western Australia,WA,-31.0505,123.2116,4
6434,Rawlinna,Western Australia,WA,-31.0115,125.3302,4
6434,Parkeston,Western Australia,WA,-30.8136,127.0639,3
6434,Forrest,Western Australia,WA,-30.851,128.1,4
6434,Zanthus,Western Australia,WA,-31.0351,123.5775,4
6436,Ularring,Western Australia,WA,-29.5567,124.3118,3
6436,Menzies,Western Australia,WA,-29.6939,121.0285,4
6437,Leinster,Western Australia,WA,-27.9203,120.6967,4
6437,Sir Samuel,Western Australia,WA,-27.9168,120.6938,3
6438,Leonora,Western Australia,WA,-28.8851,121.3307,4
6438,Lake Darlot,Western Australia,WA,-29.1845,121.8832,3
6440,Burtville,Western Australia,WA,-28.7706,122.6428,4
6440,Neale,Western Australia,WA,-26.5861,126.0718,3
6440,Laverton,Western Australia,WA,-28.6253,122.3994,4
6440,Bandya,Western Australia,WA,-26.5861,126.0718,3
6440,Beadell,Western Australia,WA,-26.5861,126.0718,3
6440,Lake Wells,Western Australia,WA,-26.5861,126.0718,3
6440,Cosmo Newbery,Western Australia,WA,-27.9962,122.8953,4
6442,Kambalda West,Western Australia,WA,-31.2017,121.6306,4
6442,Kambalda East,Western Australia,WA,-31.2017,121.6507,3
6442,Kambalda,Western Australia,WA,-31.2017,121.6708,4
6443,Higginsville,Western Australia,WA,-31.75,121.7167,4
6443,Norseman,Western Australia,WA,-32.1977,121.7787,4
6443,Fraser Range,Western Australia,WA,-32.0134,124.3774,3
6443,Widgiemooltha,Western Australia,WA,-31.4959,121.5787,4
6443,Balladonia,Western Australia,WA,-32.3458,123.62,4
6443,Madura,Western Australia,WA,-31.8995,127.0197,4
6443,Dundas,Western Australia,WA,-32.3872,121.7733,4
6443,Caiguna,Western Australia,WA,-32.2734,125.4939,4
6443,Cocklebiddy,Western Australia,WA,-32.037,126.091,4
6443,Mundrabilla,Western Australia,WA,-31.815,128.2273,4
6443,Eucla,Western Australia,WA,-32.0134,124.3774,3
6445,North Cascade,Western Australia,WA,-32.9695,121.2179,3
6445,Salmon Gums,Western Australia,WA,-32.9833,121.6333,4
6446,Grass Patch,Western Australia,WA,-33.2317,121.7222,4
6447,Lort River,Western Australia,WA,-33.45,121.7167,3
6447,Scaddan,Western Australia,WA,-33.4445,121.731,4
6447,Wittenoom Hills,Western Australia,WA,-33.45,121.7167,3
6447,Mount Ney,Western Australia,WA,-33.45,121.7167,3
6448,Gibson,Western Australia,WA,-33.6442,121.8157,4
6450,Chadwick,Western Australia,WA,-33.8394,121.8856,4
6450,Condingup,Western Australia,WA,-33.7564,122.5259,4
6450,Nulsen,Western Australia,WA,-33.857,121.875,4
6450,Coomalbidgup,Western Australia,WA,-33.7194,121.3606,4
6450,Neridup,Western Australia,WA,-33.2828,122.5813,3
6450,Dalyup,Western Australia,WA,-33.7191,121.598,4
6450,Monjingup,Western Australia,WA,-33.7532,121.7408,3
6450,Merivale,Western Australia,WA,-33.2828,122.5813,3
6450,Pink Lake,Western Australia,WA,-33.8516,121.8593,4
6450,Munglinup,Western Australia,WA,-33.2828,122.5813,3
6450,Windabout,Western Australia,WA,-33.8192,121.9204,3
6450,Esperance,Western Australia,WA,-33.8592,121.8916,4
6450,Castletown,Western Australia,WA,-33.8324,121.9156,4
6450,Bandy Creek,Western Australia,WA,-33.8278,121.9281,4
6450,West Beach,Western Australia,WA,-33.8746,121.8901,4
6450,Myrup,Western Australia,WA,-33.7911,121.9242,3
6450,Cascade,Western Australia,WA,-33.2828,122.5813,3
6450,Cape Le Grand,Western Australia,WA,-33.2828,122.5813,3
6450,Boyatup,Western Australia,WA,-33.2828,122.5813,3
6450,Beaumont,Western Australia,WA,-33.2828,122.5813,3
6450,Howick,Western Australia,WA,-33.2828,122.5813,3
6450,Sinclair,Western Australia,WA,-33.8623,121.8723,3
6450,East Munglinup,Western Australia,WA,-33.2828,122.5813,3
6452,Cape Arid,Western Australia,WA,-33.8667,121.9,1
6452,Israelite Bay,Western Australia,WA,-33.8667,121.9,1
6452,Buraminya,Western Australia,WA,-33.8667,121.9,1
6460,Ucarty West,Western Australia,WA,-31.215,116.7035,3
6460,Goomalling,Western Australia,WA,-31.3016,116.8253,4
6460,Karranadgin,Western Australia,WA,-31.2333,116.75,4
6460,Walyormouring,Western Australia,WA,-31.215,116.7035,3
6460,Hulongine,Western Australia,WA,-31.215,116.7035,3
6461,Dowerin,Western Australia,WA,-31.1937,117.0396,4
6461,Koomberkine,Western Australia,WA,-31.1,117.0333,4
6462,Minnivale,Western Australia,WA,-31.1383,117.1809,4
6462,Ucarty,Western Australia,WA,-30.9957,117.1834,3
6462,Hindmarsh,Western Australia,WA,-30.9957,117.1834,3
6463,Benjaberring,Western Australia,WA,-31.1471,117.4098,1
6465,Manmanning,Western Australia,WA,-30.8486,117.0977,4
6466,Cadoux,Western Australia,WA,-30.7694,117.1328,4
6467,Burakin,Western Australia,WA,-30.5246,117.1769,4
6468,Petrudor,Western Australia,WA,-30.0614,117.2011,3
6468,Kalannie,Western Australia,WA,-30.3625,117.1181,4
6468,Goodlands,Western Australia,WA,-30.0614,117.2011,3
6470,Kulja,Western Australia,WA,-30.4854,117.2775,4
6472,Tampu,Western Australia,WA,-30.4333,117.7417,3
6472,Remlap,Western Australia,WA,-30.0362,117.9778,3
6472,Cleary,Western Australia,WA,-30.4364,117.6438,4
6472,Karroun Hill,Western Australia,WA,-30.0362,117.9778,3
6472,Mouroubra,Western Australia,WA,-29.8015,117.7043,4
6472,Bimbijy,Western Australia,WA,-30.0362,117.9778,3
6472,Beacon,Western Australia,WA,-30.4485,117.863,4
6473,North Wialki,Western Australia,WA,-30.5744,118.0118,3
6473,Wialki,Western Australia,WA,-30.478,118.12,4
6475,Koorda,Western Australia,WA,-30.8256,117.4879,4
6475,Badgerin Rock,Western Australia,WA,-30.4589,117.4554,3
6475,Mollerin,Western Australia,WA,-30.455,117.5256,4
6475,Booralaming,Western Australia,WA,-30.4589,117.4554,3
6475,Dukin,Western Australia,WA,-30.9404,117.4303,4
6475,Newcarlbeon,Western Australia,WA,-30.4589,117.4554,3
6475,Lake Margarette,Western Australia,WA,-30.4589,117.4554,3
6476,Gabbin,Western Australia,WA,-30.8011,117.6788,4
6477,Bencubbin,Western Australia,WA,-30.8161,117.8614,4
6477,Welbungin,Western Australia,WA,-30.8285,117.9886,4
6479,Lake Brown,Western Australia,WA,-30.95,118.3333,4
6479,Dandanning,Western Australia,WA,-30.7281,118.3019,3
6479,Barbalin,Western Australia,WA,-30.7281,118.3019,3
6479,Mukinbudin,Western Australia,WA,-30.9141,118.2078,4
6479,Karloning,Western Australia,WA,-30.7281,118.3019,3
6479,Wattoning,Western Australia,WA,-30.7281,118.3019,3
6479,Elachbutting,Western Australia,WA,-30.7281,118.3019,3
6479,Bonnie Rock,Western Australia,WA,-30.5408,118.3649,4
6479,Wilgoyne,Western Australia,WA,-30.7281,118.3019,3
6480,Nukarni,Western Australia,WA,-31.2833,118.2,4
6484,Ennuin,Western Australia,WA,-30.9847,119.1143,3
6484,Bullfinch,Western Australia,WA,-30.9842,119.1085,4
6484,Lake Deborah,Western Australia,WA,-30.9847,119.1143,3
6485,Korrelocking,Western Australia,WA,-31.2037,117.4709,4
6485,Wyalkatchem,Western Australia,WA,-31.1806,117.3836,4
6485,Cowcowing,Western Australia,WA,-30.9983,117.4497,4
6485,Nalkain,Western Australia,WA,-31.0504,117.4197,4
6485,Nembudding,Western Australia,WA,-31.1949,117.5737,4
6487,Yelbeni,Western Australia,WA,-31.1703,117.6607,4
6487,South Yelbeni,Western Australia,WA,-31.2399,117.7926,3
6487,North Yelbeni,Western Australia,WA,-31.2399,117.7926,3
6488,Trayning,Western Australia,WA,-31.1169,117.7935,4
6488,South Trayning,Western Australia,WA,-31.2328,117.7928,4
6488,North Trayning,Western Australia,WA,-31.1117,117.7921,3
6489,North Kununoppin,Western Australia,WA,-31.043,117.8218,3
6489,South Kununoppin,Western Australia,WA,-31.043,117.8218,3
6489,Kununoppin,Western Australia,WA,-31.1117,117.9179,4
6490,Elabbin,Western Australia,WA,-31.2312,118.1443,4
6490,Nungarin,Western Australia,WA,-31.1864,118.104,4
6490,Chandler,Western Australia,WA,-31.253,118.0448,3
6490,Burran Rock,Western Australia,WA,-31.2553,117.9989,4
6490,Kwelkan,Western Australia,WA,-31.1447,117.9956,4
6490,Talgomine,Western Australia,WA,-31.253,118.0448,3
6501,Muchea,Western Australia,WA,-31.5821,115.9716,4
6502,Bindoon,Western Australia,WA,-31.3866,116.0966,4
6502,Bindoon Training Area,Western Australia,WA,-31.3894,116.0789,3
6503,Cullalla,Western Australia,WA,-31.2044,115.7501,3
6503,Gingin,Western Australia,WA,-31.3472,115.9043,4
6503,Wanerie,Western Australia,WA,-31.1985,115.4117,4
6503,Coonabidgee,Western Australia,WA,-31.2044,115.7501,3
6503,Yeal,Western Australia,WA,-31.2044,115.7501,3
6503,Granville,Western Australia,WA,-31.2044,115.7501,3
6503,Orange Springs,Western Australia,WA,-31.2044,115.7501,3
6503,Cowalla,Western Australia,WA,-31.2044,115.7501,3
6503,Bambun,Western Australia,WA,-31.2044,115.7501,3
6503,Breera,Western Australia,WA,-31.2044,115.7501,3
6503,Moondah,Western Australia,WA,-31.2044,115.7501,3
6503,Moore River National Park,Western Australia,WA,-31.2044,115.7501,3
6503,Mindarra,Western Australia,WA,-31.2044,115.7501,3
6503,Boonanarring,Western Australia,WA,-31.2044,115.7501,3
6503,Red Gully,Western Australia,WA,-31.2044,115.7501,3
6503,Beermullah,Western Australia,WA,-31.2044,115.7501,3
6503,Ginginup,Western Australia,WA,-31.3178,115.9083,3
6503,Muckenburra,Western Australia,WA,-31.3347,115.7819,3
6503,Neergabby,Western Australia,WA,-31.2995,115.6374,3
6503,Lennard Brook,Western Australia,WA,-31.2044,115.7501,3
6504,Mooliabeenee,Western Australia,WA,-31.3303,116.0212,4
6505,Wannamal,Western Australia,WA,-31.1597,116.056,4
6506,Mogumber,Western Australia,WA,-31.0403,116.0425,4
6507,Dandaragan,Western Australia,WA,-30.6762,115.7029,4
6507,Cooljarloo,Western Australia,WA,-30.7317,115.5874,3
6507,Cataby,Western Australia,WA,-30.7317,115.5874,3
6507,Yathroo,Western Australia,WA,-30.7317,115.5874,3
6507,Mimegarra,Western Australia,WA,-30.7317,115.5874,3
6507,Regans Ford,Western Australia,WA,-30.7317,115.5874,3
6509,New Norcia,Western Australia,WA,-30.9706,116.2125,4
6509,Waddington,Western Australia,WA,-30.85,116.25,4
6509,Yarawindah,Western Australia,WA,-30.9083,116.2333,3
6509,Glentromie,Western Australia,WA,-30.9083,116.2333,3
6510,Gillingarra,Western Australia,WA,-30.9378,116.0495,4
6510,Barberton,Western Australia,WA,-30.7274,116.0258,4
6510,Moora,Western Australia,WA,-30.64,116.0036,4
6510,Berkshire Valley,Western Australia,WA,-30.55,116.1667,4
6510,Koojan,Western Australia,WA,-30.805,116.0283,4
6510,Walebing,Western Australia,WA,-30.6945,116.2148,4
6511,Cervantes,Western Australia,WA,-30.4961,115.0672,4
6512,Coomberdale,Western Australia,WA,-30.4633,116.0393,4
6512,Namban,Western Australia,WA,-30.3803,116.046,4
6513,Gunyidi,Western Australia,WA,-30.3585,116.02,3
6513,Watheroo,Western Australia,WA,-30.2976,116.0572,4
6514,Green Head,Western Australia,WA,-30.0677,114.968,4
6514,Leeman,Western Australia,WA,-29.9494,114.9821,4
6515,Waddy Forest,Western Australia,WA,-30.0217,115.7621,3
6515,Coorow,Western Australia,WA,-29.8819,116.0226,4
6515,Marchagee,Western Australia,WA,-30.0543,116.074,4
6515,Eganu,Western Australia,WA,-30.0217,115.7621,3
6516,Jurien Bay,Western Australia,WA,-30.3059,115.0383,4
6517,Winchester,Western Australia,WA,-29.7737,115.9273,4
6517,Carnamah,Western Australia,WA,-29.6887,115.8859,4
6518,Warradarge,Western Australia,WA,-29.8242,115.2604,3
6518,Eneabba,Western Australia,WA,-29.8196,115.2659,4
6519,Three Springs,Western Australia,WA,-29.5336,115.7605,4
6519,Arrino,Western Australia,WA,-29.4435,115.63,4
6519,Womarden,Western Australia,WA,-29.5254,115.5772,3
6519,Arrowsmith East,Western Australia,WA,-29.5254,115.5772,3
6519,Kadathinni,Western Australia,WA,-29.5254,115.5772,3
6519,Dudawa,Western Australia,WA,-29.3861,115.7353,4
6521,Nambung,Western Australia,WA,-30.3427,115.662,1
6521,Badgingarra,Western Australia,WA,-30.3427,115.662,1
6521,Boothendarra,Western Australia,WA,-30.3427,115.662,1
6521,Grey,Western Australia,WA,-30.3427,115.662,1
6521,Hill River,Western Australia,WA,-30.3427,115.662,1
6522,Yandanooka,Western Australia,WA,-29.3121,115.5686,4
6522,Bundanoon,Western Australia,WA,-29.1579,115.4843,3
6522,Mingenew,Western Australia,WA,-29.1908,115.4416,4
6522,Holmwood,Western Australia,WA,-29.1579,115.4843,3
6522,Yarragadee,Western Australia,WA,-29.1579,115.4843,3
6522,Lockier,Western Australia,WA,-29.1579,115.4843,3
6522,Nangetty,Western Australia,WA,-29.1579,115.4843,3
6522,Mooriary,Western Australia,WA,-29.1579,115.4843,3
6522,Mount Budd,Western Australia,WA,-29.1579,115.4843,3
6522,Ikewa,Western Australia,WA,-29.1579,115.4843,3
6525,Irwin,Western Australia,WA,-29.2204,115.0801,4
6525,Port Denison,Western Australia,WA,-29.2833,114.9167,4
6525,Dongara,Western Australia,WA,-29.2582,114.9328,4
6525,Allanooka,Western Australia,WA,-29.3265,115.0917,3
6525,Mount Adams,Western Australia,WA,-29.3265,115.0917,3
6525,Milo,Western Australia,WA,-29.3265,115.0917,3
6525,Bookara,Western Australia,WA,-29.3265,115.0917,3
6525,Yardarino,Western Australia,WA,-29.3265,115.0917,3
6525,Arrowsmith,Western Australia,WA,-29.3265,115.0917,3
6525,Mount Horner,Western Australia,WA,-29.3265,115.0917,3
6525,Springfield,Western Australia,WA,-29.3265,115.0917,3
6525,Bonniefield,Western Australia,WA,-29.2058,114.9392,3
6528,South Greenough,Western Australia,WA,-28.9269,114.8997,3
6528,Walkaway,Western Australia,WA,-28.9412,114.8031,4
6528,Mount Hill,Western Australia,WA,-28.9269,114.8997,3
6530,Tarcoola Beach,Western Australia,WA,-28.81,114.6224,4
6530,Strathalbyn,Western Australia,WA,-28.7517,114.6458,4
6530,Woorree,Western Australia,WA,-28.7668,114.6581,4
6530,Wandina,Western Australia,WA,-28.8194,114.6346,4
6530,Rangeway,Western Australia,WA,-28.7866,114.6306,4
6530,Waggrakine,Western Australia,WA,-28.7188,114.6392,4
6530,Karloo,Western Australia,WA,-28.791,114.6411,4
6530,Beresford,Western Australia,WA,-28.7575,114.6196,4
6530,Utakarra,Western Australia,WA,-28.7833,114.65,4
6530,Sunset Beach,Western Australia,WA,-28.7191,114.623,4
6530,Webberton,Western Australia,WA,-28.7524,114.6322,4
6530,West End,Western Australia,WA,-28.7817,114.5793,4
6530,Beachlands,Western Australia,WA,-28.7857,114.6019,4
6530,Spalding,Western Australia,WA,-28.7352,114.6365,4
6530,Houtman Abrolhos,Western Australia,WA,-28.5586,113.7584,4
6530,Bluff Point,Western Australia,WA,-28.7374,114.6228,4
6530,Meru,Western Australia,WA,-28.8033,114.6864,4
6530,Moresby,Western Australia,WA,-28.7352,114.6724,4
6530,Wonthella,Western Australia,WA,-28.7669,114.6334,4
6530,Mahomets Flats,Western Australia,WA,-28.792,114.6133,4
6530,Mount Tarcoola,Western Australia,WA,-28.7986,114.6253,4
6530,Geraldton,Western Australia,WA,-28.779,114.6146,4
6531,Geraldton Po,Western Australia,WA,-28.779,114.6146,3
6531,Geraldton,Western Australia,WA,-28.779,114.6146,4
6532,Meadow,Western Australia,WA,-32.4972,115.7533,4
6532,Ajana,Western Australia,WA,-27.9558,114.6302,4
6532,Wandana,Western Australia,WA,-28.3989,115.0313,3
6532,Drummond Cove,Western Australia,WA,-28.6738,114.6128,4
6532,Eradu South,Western Australia,WA,-28.3989,115.0313,3
6532,Rudds Gully,Western Australia,WA,-28.8355,114.6416,4
6532,Yuna,Western Australia,WA,-28.3333,115,4
6532,Yetna,Western Australia,WA,-28.6102,114.6989,4
6532,Coburn,Western Australia,WA,-28.5613,114.747,3
6532,Moonyoonooka,Western Australia,WA,-28.781,114.727,4
6532,Deepdale,Western Australia,WA,-28.7864,114.6951,4
6532,Tamala,Western Australia,WA,-31.7061,115.7201,4
6532,Sandsprings,Western Australia,WA,-28.3989,115.0313,3
6532,Glenfield,Western Australia,WA,-28.6925,114.6214,4
6532,Northern Gully,Western Australia,WA,-28.7138,114.9087,4
6532,Nabawa,Western Australia,WA,-28.4995,114.7968,4
6532,Rockwell,Western Australia,WA,-28.346,114.925,4
6532,Narngulu,Western Australia,WA,-28.8205,114.6925,4
6532,Nerren Nerren,Western Australia,WA,-28.5613,114.747,3
6532,Kojarena,Western Australia,WA,-28.7224,114.8656,4
6532,Durawah,Western Australia,WA,-28.3989,115.0313,3
6532,Oakajee,Western Australia,WA,-28.5697,114.631,4
6532,Nolba,Western Australia,WA,-28.3667,114.8667,4
6532,Georgina,Western Australia,WA,-28.8775,114.7536,4
6532,Narra Tarra,Western Australia,WA,-28.7,114.7167,4
6532,Bootenal,Western Australia,WA,-28.8558,114.7275,4
6532,Toolonga,Western Australia,WA,-28.5613,114.747,3
6532,Dindiloa,Western Australia,WA,-28.4307,114.7895,4
6532,Binnu,Western Australia,WA,-28.0424,114.678,4
6532,Naraling,Western Australia,WA,-28.4,114.8333,4
6532,Howatharra,Western Australia,WA,-28.5333,114.6167,4
6532,Cape Burney,Western Australia,WA,-28.8675,114.6489,4
6532,Coolcalalaya,Western Australia,WA,-28.3989,115.0313,3
6532,Greenough,Western Australia,WA,-28.95,114.7333,4
6532,Bringo,Western Australia,WA,-28.7491,114.8439,4
6532,Wicherina,Western Australia,WA,-28.7178,115.0144,4
6532,Mount Erin,Western Australia,WA,-28.3989,115.0313,3
6532,Nanson,Western Australia,WA,-28.5788,114.7545,4
6532,Wicherina South,Western Australia,WA,-28.3989,115.0313,3
6532,West Binnu,Western Australia,WA,-28.0424,114.678,4
6532,South Yuna,Western Australia,WA,-28.3989,115.0313,3
6532,North Eradu,Western Australia,WA,-28.3989,115.0313,3
6532,Carrarang,Western Australia,WA,-28.5613,114.747,3
6532,Valentine,Western Australia,WA,-28.3989,115.0313,3
6532,Dartmoor,Western Australia,WA,-28.3989,115.0313,3
6532,Ellendale,Western Australia,WA,-28.3989,115.0313,3
6532,East Chapman,Western Australia,WA,-28.3989,115.0313,3
6532,White Peak,Western Australia,WA,-28.6359,114.6434,3
6532,Eradu,Western Australia,WA,-28.3989,115.0313,3
6532,Hickety,Western Australia,WA,-28.3989,115.0313,3
6532,Minnenooka,Western Australia,WA,-28.8244,114.9003,3
6532,Hamelin Pool,Western Australia,WA,-28.3989,115.0313,3
6532,Marrah,Western Australia,WA,-28.3989,115.0313,3
6532,Eurardy,Western Australia,WA,-28.3989,115.0313,3
6532,Burma Road,Western Australia,WA,-28.3989,115.0313,3
6532,Tibradden,Western Australia,WA,-28.3989,115.0313,3
6532,East Yuna,Western Australia,WA,-28.3989,115.0313,3
6532,Buller,Western Australia,WA,-28.3989,115.0313,3
6532,East Nabawa,Western Australia,WA,-28.3989,115.0313,3
6535,Ogilvie,Western Australia,WA,-28.15,114.6333,4
6535,Horrocks,Western Australia,WA,-28.3861,114.43,4
6535,Isseka,Western Australia,WA,-28.4333,114.6333,4
6535,Yallabatharra,Western Australia,WA,-28.1311,114.3442,3
6535,Gregory,Western Australia,WA,-28.1865,114.2533,4
6535,Bowes,Western Australia,WA,-28.3833,114.6667,4
6535,Northampton,Western Australia,WA,-28.3499,114.6382,4
6535,Alma,Western Australia,WA,-28.0013,114.8555,3
6535,East Bowes,Western Australia,WA,-28.0013,114.8555,3
6535,Sandy Gully,Western Australia,WA,-28.0013,114.8555,3
6536,Zuytdorp,Western Australia,WA,-27.6034,114.5223,3
6536,Kalbarri,Western Australia,WA,-27.7105,114.165,4
6536,Kalbarri National Park,Western Australia,WA,-27.6034,114.5223,3
6537,Denham,Western Australia,WA,-25.9287,113.5355,4
6537,Monkey Mia,Western Australia,WA,-25.794,113.718,4
6537,Francois Peron National Park,Western Australia,WA,-32.8167,116.0667,3
6537,Dirk Hartog Island,Western Australia,WA,-32.8167,116.0667,3
6537,Nanga,Western Australia,WA,-32.8167,116.0667,3
6537,Shark Bay,Western Australia,WA,-32.8167,116.0667,3
6537,Useless Loop,Western Australia,WA,-32.8167,116.0667,3
6556,Beechina,Western Australia,WA,-31.85,116.3167,4
6556,The Lakes,Western Australia,WA,-31.8745,116.3359,4
6556,Chidlow,Western Australia,WA,-31.8609,116.2724,4
6556,Malmalling,Western Australia,WA,-31.8473,116.3095,3
6556,Gorrie,Western Australia,WA,-31.8473,116.3095,3
6558,Wooroloo,Western Australia,WA,-31.8038,116.3131,4
6560,Wundowie,Western Australia,WA,-31.7616,116.3799,4
6562,Copley,Western Australia,WA,-31.835,116.4467,3
6562,Bakers Hill,Western Australia,WA,-31.7469,116.4587,4
6562,Woottating,Western Australia,WA,-31.835,116.4467,3
6564,Clackline,Western Australia,WA,-31.7333,116.5333,4
6566,Carani,Western Australia,WA,-30.9925,116.4053,4
6566,Culham,Western Australia,WA,-31.4167,116.4667,4
6566,West Toodyay,Western Australia,WA,-31.5333,116.4167,4
6566,Coondle,Western Australia,WA,-31.4818,116.4276,4
6566,Nunile,Western Australia,WA,-31.4304,116.4453,3
6566,Toodyay,Western Australia,WA,-31.5498,116.4674,4
6566,Katrine,Western Australia,WA,-31.4304,116.4453,3
6566,Bejoording,Western Australia,WA,-31.4,116.5,4
6566,Hoddys Well,Western Australia,WA,-31.4304,116.4453,3
6566,Dumbarton,Western Australia,WA,-31.5682,116.523,3
6567,Julimar,Western Australia,WA,-31.4779,116.2483,4
6567,Dewars Pool,Western Australia,WA,-31.4833,116.4333,4
6567,Moondyne,Western Australia,WA,-31.4812,116.2698,3
6568,Wattening,Western Australia,WA,-31.3333,116.5167,4
6568,Wyening,Western Australia,WA,-31.1887,116.4857,4
6568,Bolgart,Western Australia,WA,-31.2667,116.5,4
6569,Old Plains,Western Australia,WA,-31.0885,116.4488,3
6569,Calingiri,Western Australia,WA,-31.0916,116.4478,4
6571,Yerecoin,Western Australia,WA,-30.675,116.3667,1
6572,Piawaning,Western Australia,WA,-30.841,116.3884,4
6574,Gabalong,Western Australia,WA,-30.7214,116.389,4
6574,Bindi Bindi,Western Australia,WA,-30.6352,116.3587,4
6575,Miling,Western Australia,WA,-30.4897,116.3624,4
6603,Konnongorring,Western Australia,WA,-31.0833,116.7833,4
6603,Lake Hinds,Western Australia,WA,-30.7635,116.846,3
6603,Wongan Hills,Western Australia,WA,-30.8929,116.7193,4
6603,Lake Ninan,Western Australia,WA,-30.7635,116.846,3
6603,Mocardy,Western Australia,WA,-30.7635,116.846,3
6605,Kondut,Western Australia,WA,-30.7109,116.7732,4
6606,West Ballidu,Western Australia,WA,-30.5917,116.7691,3
6606,East Ballidu,Western Australia,WA,-30.6833,116.9,4
6606,Ballidu,Western Australia,WA,-30.5984,116.7703,4
6608,Pithara,Western Australia,WA,-30.3919,116.6688,4
6608,Marne,Western Australia,WA,-30.2704,116.643,3
6608,East Damboring,Western Australia,WA,-30.2704,116.643,3
6609,Dalwallinu,Western Australia,WA,-30.2805,116.6601,4
6609,Xantippe,Western Australia,WA,-30.0333,117.0333,4
6609,Nugadong,Western Australia,WA,-30.2039,116.6636,4
6612,Wubin,Western Australia,WA,-30.1065,116.6332,4
6612,Miamoon,Western Australia,WA,-29.1266,117.3905,3
6612,Paynes Find,Western Australia,WA,-29.2592,117.6893,4
6612,Jibberding,Western Australia,WA,-29.1266,117.3905,3
6613,Buntine,Western Australia,WA,-29.987,116.5713,4
6614,Maya,Western Australia,WA,-29.8849,116.5044,4
6616,Latham,Western Australia,WA,-29.7587,116.4457,4
6620,Rothsay,Western Australia,WA,-29.2833,116.8833,4
6620,Perenjori,Western Australia,WA,-29.4361,116.2856,4
6623,Pintharuka,Western Australia,WA,-29.094,115.982,4
6623,Koolanooka,Western Australia,WA,-29.271,116.0747,4
6623,Morawa,Western Australia,WA,-29.2134,116.0079,4
6623,Bunjil,Western Australia,WA,-29.653,116.3664,4
6623,Bowgada,Western Australia,WA,-29.3349,116.1743,4
6623,Gutha,Western Australia,WA,-28.994,115.946,4
6625,Merkanooka,Western Australia,WA,-29.1963,116.1275,1
6627,Canna,Western Australia,WA,-28.9002,115.8621,4
6628,Tardun,Western Australia,WA,-28.7937,115.7478,4
6630,Nerramyne,Western Australia,WA,-27.2164,115.966,3
6630,Murchison,Western Australia,WA,-26.8949,115.9575,4
6630,Woolgorong,Western Australia,WA,-27.2164,115.966,3
6630,Mullewa,Western Australia,WA,-28.5395,115.5117,4
6630,Nunierra,Western Australia,WA,-27.2164,115.966,3
6630,Wongoondy,Western Australia,WA,-27.2164,115.966,3
6630,Devils Creek,Western Australia,WA,-27.2164,115.966,3
6630,West Casuarinas,Western Australia,WA,-27.2164,115.966,3
6631,Pindar,Western Australia,WA,-28.4804,115.793,4
6632,Tenindewa,Western Australia,WA,-28.6178,115.3625,4
6632,Ambania,Western Australia,WA,-28.6965,115.1016,4
6635,Yalgoo,Western Australia,WA,-28.3436,116.6825,4
6635,South Murchison,Western Australia,WA,-27.3163,116.5712,3
6638,Mount Magnet,Western Australia,WA,-28.0648,117.849,4
6638,Paynesville,Western Australia,WA,-28.3061,118.822,3
6638,Daggar Hills,Western Australia,WA,-28.3061,118.822,3
6638,Cooladar Hill,Western Australia,WA,-28.4825,118.1683,3
6639,Sandstone,Western Australia,WA,-27.9868,119.2973,4
6640,Cue,Western Australia,WA,-27.424,117.8974,4
6640,East Murchison,Western Australia,WA,-26.8712,117.4493,3
6640,Weld Range,Western Australia,WA,-26.8712,117.4493,3
6640,Reedy,Western Australia,WA,-27.15,118.2667,4
6640,Lake Austin,Western Australia,WA,-26.8712,117.4493,3
6642,Peak Hill,Western Australia,WA,-25.6375,118.7244,4
6642,Capricorn,Western Australia,WA,-25.1448,118.5342,3
6642,Meekatharra,Western Australia,WA,-26.5952,118.4939,4
6642,Angelo River,Western Australia,WA,-25.1448,118.5342,3
6642,Karalundi,Western Australia,WA,-26.5952,118.4939,3
6642,Kumarina,Western Australia,WA,-25.1448,118.5342,3
6646,Wiluna,Western Australia,WA,-26.5921,120.224,4
6646,Lake Carnegie,Western Australia,WA,-25.3077,122.2496,3
6646,Little Sandy Desert,Western Australia,WA,-25.3077,122.2496,3
6701,Lyndon,Western Australia,WA,-24.161,114.4806,3
6701,Kingsford,Western Australia,WA,-24.8639,113.6955,4
6701,Gilroyd,Western Australia,WA,-20.5828,119.1891,3
6701,Talisker,Western Australia,WA,-20.5828,119.1891,3
6701,Wooramel,Western Australia,WA,-24.161,114.4806,3
6701,Coral Bay,Western Australia,WA,-23.1408,113.7763,4
6701,Yandoo Creek,Western Australia,WA,-24.161,114.4806,3
6701,Woodleigh,Western Australia,WA,-20.5828,119.1891,3
6701,South Plantations,Western Australia,WA,-24.8423,113.7632,3
6701,Morgantown,Western Australia,WA,-24.8765,113.6589,4
6701,Brown Range,Western Australia,WA,-24.8916,113.6985,4
6701,Bernier Island,Western Australia,WA,-24.161,114.4806,3
6701,South Carnarvon,Western Australia,WA,-24.8569,113.7148,4
6701,East Carnarvon,Western Australia,WA,-24.864,113.6777,4
6701,Carbla,Western Australia,WA,-26.1976,114.3872,4
6701,Inggarda,Western Australia,WA,-24.161,114.4806,3
6701,North Plantations,Western Australia,WA,-24.8447,113.7088,4
6701,Kennedy Range,Western Australia,WA,-24.161,114.4806,3
6701,Ningaloo,Western Australia,WA,-22.8361,113.8012,4
6701,Babbage Island,Western Australia,WA,-24.161,114.4806,3
6701,Brockman,Western Australia,WA,-24.8813,113.6536,4
6701,Yalardy,Western Australia,WA,-20.5828,119.1891,3
6701,Massey Bay,Western Australia,WA,-24.161,114.4806,3
6701,Mauds Landing,Western Australia,WA,-24.161,114.4806,3
6701,Macleod,Western Australia,WA,-24.161,114.4806,3
6701,Carnarvon,Western Australia,WA,-24.8826,113.6571,4
6701,Greys Plain,Western Australia,WA,-24.883,113.7018,3
6701,Dorre Island,Western Australia,WA,-24.161,114.4806,3
6701,Minilya,Western Australia,WA,-24.161,114.4806,3
6705,Gascoyne Junction,Western Australia,WA,-25.0499,115.2091,4
6705,East Lyons River,Western Australia,WA,-24.7255,116.3552,3
6705,West Lyons River,Western Australia,WA,-24.7255,116.3552,3
6705,Gascoyne River,Western Australia,WA,-25.0499,115.2091,4
6707,Exmouth,Western Australia,WA,-21.9306,114.1209,4
6707,Learmonth,Western Australia,WA,-22.25,114.0833,4
6707,Cape Range National Park,Western Australia,WA,-22.2143,113.9896,3
6707,North West Cape,Western Australia,WA,-21.882,114.1325,4
6707,Exmouth Gulf,Western Australia,WA,-22.2143,113.9896,3
6710,Onslow,Western Australia,WA,-21.6376,115.1122,4
6710,Talandji,Western Australia,WA,-22.5027,116.2032,3
6710,Peedamulla,Western Australia,WA,-22.5027,116.2032,3
6710,Yannarie,Western Australia,WA,-22.5027,116.2032,3
6710,Cane,Western Australia,WA,-22.5027,116.2032,3
6711,Thevenard Island,Western Australia,WA,-20.9833,116.6917,1
6712,Barrow Island,Western Australia,WA,-20.9833,116.6917,1
6713,Dampier,Western Australia,WA,-20.6627,116.7126,4
6713,Dampier Archipelago,Western Australia,WA,-20.668,116.7191,3
6714,Mulataga,Western Australia,WA,-20.7395,116.8774,4
6714,Karratha,Western Australia,WA,-20.7377,116.8463,4
6714,Baynton,Western Australia,WA,-20.7516,116.8014,4
6714,Karratha Industrial Estate,Western Australia,WA,-20.7678,116.8708,3
6714,Millars Well,Western Australia,WA,-20.7417,116.8173,4
6714,Pegs Creek,Western Australia,WA,-20.7383,116.8328,4
6714,Balla Balla,Western Australia,WA,-21.0631,116.4802,3
6714,Bulgarra,Western Australia,WA,-20.7258,116.8567,4
6714,Antonymyre,Western Australia,WA,-21.0631,116.4802,3
6714,Cleaverville,Western Australia,WA,-21.0631,116.4802,3
6714,Stove Hill,Western Australia,WA,-20.7574,116.8495,3
6714,Nickol,Western Australia,WA,-20.7456,116.7954,4
6714,Mount Anketell,Western Australia,WA,-21.0631,116.4802,3
6714,Sherlock,Western Australia,WA,-21.0631,116.4802,3
6714,Burrup,Western Australia,WA,-20.5901,116.796,4
6714,Cooya Pooya,Western Australia,WA,-21.0631,116.4802,3
6714,Gap Ridge,Western Australia,WA,-20.7461,116.7856,3
6714,Mardie,Western Australia,WA,-21.0631,116.4802,3
6714,Maitland,Western Australia,WA,-21.0631,116.4802,3
6714,Gnoorea,Western Australia,WA,-21.0631,116.4802,3
6716,Pannawonica,Western Australia,WA,-21.6392,116.3222,4
6716,Millstream,Western Australia,WA,-21.6374,116.3258,3
6716,Hamersley Range,Western Australia,WA,-21.6374,116.3258,3
6716,Fortescue,Western Australia,WA,-21.6374,116.3258,3
6718,Roebourne,Western Australia,WA,-20.7723,117.1465,4
6718,Whim Creek,Western Australia,WA,-20.8386,117.8314,4
6720,Wickham,Western Australia,WA,-20.6747,117.1378,4
6720,Cossack,Western Australia,WA,-20.7,117.2,4
6720,Point Samson,Western Australia,WA,-20.6289,117.1927,4
6721,Mundabullangana,Western Australia,WA,-20.929,118.6739,3
6721,Wedgefield,Western Australia,WA,-20.3609,118.5958,4
6721,Port Hedland,Western Australia,WA,-20.3121,118.6106,4
6721,Strelley,Western Australia,WA,-21.1167,119.05,4
6721,Indee,Western Australia,WA,-20.929,118.6739,3
6721,Redbank,Western Australia,WA,-20.3452,118.6296,4
6721,Pardoo,Western Australia,WA,-20.1086,119.5798,3
6721,Wallareenya,Western Australia,WA,-20.929,118.6739,3
6722,Boodarie,Western Australia,WA,-20.3675,118.5586,4
6722,Finucane,Western Australia,WA,-20.2977,118.569,4
6722,De Grey,Western Australia,WA,-20.4091,118.6091,3
6722,Pippingarra,Western Australia,WA,-20.4091,118.6091,3
6722,South Hedland,Western Australia,WA,-20.4066,118.6007,4
6723,Goldsworthy,Western Australia,WA,-20.35,119.5333,4
6725,Roebuck,Western Australia,WA,-18.1713,122.5012,4
6725,Minyirr,Western Australia,WA,-17.9813,122.2097,4
6725,Dampier Peninsula,Western Australia,WA,-16.9324,122.8656,4
6725,Lagrange,Western Australia,WA,-18.7056,121.9933,3
6725,Broome,Western Australia,WA,-17.9554,122.2392,4
6725,Gingerah,Western Australia,WA,-18.6605,122.4748,3
6725,Bilingurr,Western Australia,WA,-17.9091,122.2292,4
6725,Waterbank,Western Australia,WA,-17.4547,122.6775,3
6725,Djugun,Western Australia,WA,-17.9539,122.2279,4
6725,Eighty Mile Beach,Western Australia,WA,-19.2407,121.6133,4
6726,Cable Beach,Western Australia,WA,-17.961,122.2127,4
6728,Camballin,Western Australia,WA,-18.209,123.9018,4
6728,Kimbolton,Western Australia,WA,-20.4159,118.7072,3
6728,King Leopold Ranges,Western Australia,WA,-17.1099,125.4806,4
6728,Geegully Creek,Western Australia,WA,-20.4159,118.7072,3
6728,Derby,Western Australia,WA,-17.3029,123.6286,4
6728,Willare,Western Australia,WA,-20.4159,118.7072,3
6728,Meda,Western Australia,WA,-20.4159,118.7072,3
6728,St George Ranges,Western Australia,WA,-18.982,125.0087,4
6731,Cockatoo Island,Western Australia,WA,-20.5828,119.1891,1
6733,Koolan Island,Western Australia,WA,-21.0454,118.4324,1
6740,Prince Regent River,Western Australia,WA,-15.9162,126.8148,1
6740,Kalumburu,Western Australia,WA,-14.2872,126.6345,1
6740,Mitchell Plateau,Western Australia,WA,-15.9162,126.8148,1
6740,Oombulgurri,Western Australia,WA,-15.9162,126.8148,1
6740,Wyndham,Western Australia,WA,-15.4869,128.1243,4
6740,Drysdale River,Western Australia,WA,-15.9162,126.8148,1
6743,Lake Argyle,Western Australia,WA,-16.1088,128.7443,1
6743,Kununurra,Western Australia,WA,-15.7739,128.739,1
6743,Gibb,Western Australia,WA,-32.0844,119.0982,1
6743,Warmun,Western Australia,WA,-17.0338,128.2236,1
6743,Cambridge Gulf,Western Australia,WA,-17.4122,129.6861,1
6743,Durack,Western Australia,WA,-17.4122,129.6861,1
6751,Rocklea,Western Australia,WA,-32.8696,118.4233,3
6751,Tom Price,Western Australia,WA,-22.6939,117.7931,4
6751,Chichester,Western Australia,WA,-22.6856,117.7906,3
6751,Wittenoom,Western Australia,WA,-22.2404,118.3355,4
6751,Mulga Downs,Western Australia,WA,-22.6856,117.7906,3
6751,Nanutarra,Western Australia,WA,-22.6856,117.7906,3
6751,Innawanga,Western Australia,WA,-22.6856,117.7906,3
6751,Mount Sheila,Western Australia,WA,-22.6856,117.7906,3
6751,Karijini,Western Australia,WA,-22.6856,117.7906,3
6751,Juna Downs,Western Australia,WA,-22.6856,117.7906,3
6753,Newman,Western Australia,WA,-23.3564,119.7355,4
6754,Paraburdoo,Western Australia,WA,-23.2042,117.6697,4
6758,Nullagine,Western Australia,WA,-21.8897,120.1114,4
6760,Marble Bar,Western Australia,WA,-21.1707,119.7444,4
6761,Shay Gap,Western Australia,WA,-21.1707,119.7444,1
6762,Telfer,Western Australia,WA,-21.2117,123.2617,4
6765,Mount Hardman,Western Australia,WA,-21.1707,119.7444,3
6765,Fitzroy Crossing,Western Australia,WA,-18.1971,125.5666,4
6770,Sturt Creek,Western Australia,WA,-18.8386,128.1783,3
6770,Mueller Ranges,Western Australia,WA,-18.3983,126.8562,4
6770,Mcbeath,Western Australia,WA,-19.3668,127.5678,3
6770,Purnululu,Western Australia,WA,-19.3668,127.5678,3
6770,Tanami,Western Australia,WA,-19.3668,127.5678,3
6770,Halls Creek,Western Australia,WA,-19.3668,127.5678,3
6770,Ord River,Western Australia,WA,-17.3857,128.9207,3
6798,Christmas Island,Western Australia,WA,-10.4854,105.6365,1
6799,Home Island Cocos (Keeling) Islands,Western Australia,WA,-12.1352,96.8628,1
6799,West Island Cocos (Keeling) Islands,Western Australia,WA,-12.1352,96.8628,1
6800,Perth,Western Australia,WA,-31.9522,115.8614,4
6803,Northbridge,Western Australia,WA,-31.9478,115.8588,4
6809,Perth,Western Australia,WA,-31.9522,115.8614,4
6817,Perth,Western Australia,WA,-31.9522,115.8614,4
6820,Perth,Western Australia,WA,-31.9522,115.8614,4
6827,Perth,Western Australia,WA,-31.9522,115.8614,4
6830,Perth,Western Australia,WA,-31.9522,115.8614,4
6831,Perth St Georges Tce,Western Australia,WA,-31.9333,115.8333,1
6832,Perth Adelaide Tce,Western Australia,WA,-31.9522,115.8614,1
6832,Perth East St Georges Tce,Western Australia,WA,-31.9333,115.8333,1
6837,Perth,Western Australia,WA,-31.9522,115.8614,4
6838,Perth,Western Australia,WA,-31.9522,115.8614,4
6839,Perth,Western Australia,WA,-31.9522,115.8614,4
6840,Perth,Western Australia,WA,-31.9522,115.8614,4
6841,Perth,Western Australia,WA,-31.9522,115.8614,4
6842,Perth,Western Australia,WA,-31.9522,115.8614,4
6843,Perth,Western Australia,WA,-31.9522,115.8614,4
6844,Perth,Western Australia,WA,-31.9522,115.8614,4
6845,Perth,Western Australia,WA,-31.9522,115.8614,4
6846,Perth,Western Australia,WA,-31.9522,115.8614,4
6847,Perth,Western Australia,WA,-31.9522,115.8614,4
6848,Perth,Western Australia,WA,-31.9522,115.8614,4
6849,Perth Bc,Western Australia,WA,-31.9333,115.8333,1
6850,Cloisters Square Po,Western Australia,WA,-31.952,115.8607,1
6850,Cloisters Square,Western Australia,WA,-31.9333,115.8333,1
6865,Northbridge,Western Australia,WA,-31.9478,115.8588,4
6872,West Perth,Western Australia,WA,-31.949,115.842,4
6892,East Perth,Western Australia,WA,-31.9587,115.8711,4
6900,Leederville,Western Australia,WA,-31.9363,115.8419,4
6901,West Leederville,Western Australia,WA,-31.9414,115.8312,4
6902,Leederville,Western Australia,WA,-31.9363,115.8419,4
6903,Leederville,Western Australia,WA,-31.9363,115.8419,4
6904,Subiaco,Western Australia,WA,-31.9485,115.8268,4
6904,Subiaco Po,Western Australia,WA,-31.95,115.8,3
6905,Northlands,Western Australia,WA,-31.9722,115.8,1
6905,Northlands Po,Western Australia,WA,-31.9486,115.8275,1
6906,North Perth,Western Australia,WA,-31.9272,115.8528,4
6907,Nedlands,Western Australia,WA,-31.9818,115.8073,4
6909,Nedlands,Western Australia,WA,-31.9818,115.8073,4
6910,Claremont,Western Australia,WA,-31.9796,115.7823,4
6911,Cottesloe,Western Australia,WA,-32,115.7667,4
6912,Mosman Park,Western Australia,WA,-32.0054,115.7638,4
6913,Wembley,Western Australia,WA,-31.9333,115.8,4
6914,Balcatta,Western Australia,WA,-31.875,115.8284,4
6915,Mount Hawthorn,Western Australia,WA,-31.92,115.8351,4
6916,Osborne Park Dc,Western Australia,WA,-31.9802,115.7757,3
6916,Osborne Park,Western Australia,WA,-31.9007,115.8108,4
6917,Osborne Park,Western Australia,WA,-31.9007,115.8108,4
6918,Innaloo,Western Australia,WA,-31.8927,115.7951,4
6919,Joondalup Dc,Western Australia,WA,-31.9802,115.7757,1
6920,North Beach,Western Australia,WA,-31.863,115.7562,4
6921,Karrinyup,Western Australia,WA,-31.8723,115.7768,4
6922,Scarborough,Western Australia,WA,-31.8958,115.7643,4
6923,Hillarys,Western Australia,WA,-31.807,115.7405,4
6924,Greenwood,Western Australia,WA,-31.8272,115.8025,4
6925,Walliston Dc,Western Australia,WA,-31.9167,115.8556,1
6926,Kalamunda,Western Australia,WA,-31.9868,116.0704,1
6929,Mount Lawley,Western Australia,WA,-31.9344,115.8716,4
6931,Maylands,Western Australia,WA,-31.931,115.8949,4
6932,Inglewood,Western Australia,WA,-31.9166,115.8798,4
6933,Bayswater,Western Australia,WA,-31.9169,115.9178,4
6934,Bassendean,Western Australia,WA,-31.9091,115.9437,4
6935,Guildford,Western Australia,WA,-31.9,115.9667,4
6936,Midland Dc,Western Australia,WA,-31.9111,115.9333,1
6937,Tuart Hill,Western Australia,WA,-31.8981,115.8349,4
6938,Tuart Hill,Western Australia,WA,-31.8981,115.8349,4
6939,Tuart Hill,Western Australia,WA,-31.8981,115.8349,4
6940,Tuart Hill,Western Australia,WA,-31.8981,115.8349,4
6941,Mirrabooka,Western Australia,WA,-31.8596,115.8659,4
6942,Bassendean Dc,Western Australia,WA,-31.75,115.8,1
6943,Morley,Western Australia,WA,-31.8877,115.9099,4
6944,Malaga,Western Australia,WA,-31.8526,115.881,4
6945,Malaga Dc,Western Australia,WA,-31.75,115.8,1
6946,Wanneroo,Western Australia,WA,-31.75,115.8,4
6947,Wangara Dc,Western Australia,WA,-31.75,115.8,1
6951,South Perth,Western Australia,WA,-31.9833,115.8667,4
6952,Como,Western Australia,WA,-31.9912,115.8634,4
6953,Applecross,Western Australia,WA,-32.0167,115.8333,4
6954,Booragoon,Western Australia,WA,-32.0395,115.8337,4
6955,Willetton,Western Australia,WA,-32.0525,115.8878,4
6956,Melville,Western Australia,WA,-32.0404,115.8012,4
6957,Palmyra,Western Australia,WA,-32.045,115.7859,4
6958,Royal Australian Navy Warships,Western Australia,WA,-32.05,115.7667,1
6959,Fremantle,Western Australia,WA,-32.05,115.7667,4
6960,Myaree Bc,Western Australia,WA,-32.2833,115.7167,1
6961,Palmyra Dc,Western Australia,WA,-32.2833,115.7167,1
6963,Hamilton Hill,Western Australia,WA,-32.0845,115.7795,4
6964,Success,Western Australia,WA,-32.1428,115.85,4
6965,Bibra Lake Dc,Western Australia,WA,-32.2833,115.7167,1
6966,Kwinana,Western Australia,WA,-32.2301,115.7813,4
6967,Rockingham Dc,Western Australia,WA,-32.2833,115.7167,1
6968,Rockingham,Western Australia,WA,-32.2768,115.7298,4
6969,Rockingham Beach,Western Australia,WA,-32.2833,115.7167,1
6970,Canning Vale Dc,Western Australia,WA,-31.9919,115.8645,1
6979,Victoria Park,Western Australia,WA,-31.9762,115.9052,4
6980,Cannington,Western Australia,WA,-32.0167,115.95,4
6981,East Victoria Park,Western Australia,WA,-31.9889,115.9039,4
6982,Bentley,Western Australia,WA,-32,115.9167,4
6983,Bentley Dc,Western Australia,WA,-32.0048,115.9411,1
6984,Belmont,Western Australia,WA,-31.9435,115.9255,4
6985,Cloverdale,Western Australia,WA,-31.9628,115.9443,4
6986,Welshpool Dc,Western Australia,WA,-32.0048,115.9411,1
6987,Cannington,Western Australia,WA,-32.0167,115.95,4
6988,Thornlie,Western Australia,WA,-32.06,115.955,4
6989,Maddington,Western Australia,WA,-32.05,115.9833,4
6990,Gosnells,Western Australia,WA,-32.081,116.0054,4
6991,Kelmscott,Western Australia,WA,-32.1243,116.0259,4
6992,Armadale,Western Australia,WA,-32.1461,116.0093,4
6997,Kelmscott Dc,Western Australia,WA,-32.1167,116.0056,1`;
