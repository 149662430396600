import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Icon from "../Icons/Icon";
import FlexCardWithIcon, { InnerContainer } from "./FlexCardWithIcon";
import { media } from "../../themes";

const StyledRecommendationCount = styled.span`
    font-size: ${props => props.theme.text.heading6.sizeRem};
    font-weight: normal;
    padding: 0 0.5em;
`;

const StyledRecommendationContainer = styled.div`
    flex-direction: row;
    flex-grow: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    ${media.mobile`
        flex-direction: column;
    `}
`;

const StyledTitle = styled.h6`
    font-size: ${props => props.theme.text.heading6.size};
    font-weight: normal;
    margin: 0;
    padding: 0;
    flex-grow: 6;
`;

class HealthCheck extends Component {
    static displayName = "Health Check Card";
    static propTypes = {
        theme: PropTypes.any,
        date: PropTypes.string.isRequired,
        installationName: PropTypes.string.isRequired,
        recommendationCount: PropTypes.number
    };

    render() {
        let colour = this.props.theme.colours.flat.darkGray.hex;

        let recommendation = null;
        if (this.props.recommendationCount > 0) {
            recommendation = (
                <StyledRecommendationContainer>
                    <Icon type={this.props.theme.icons.types.recommendation} colour={colour} />
                    <StyledRecommendationCount>{this.props.recommendationCount}</StyledRecommendationCount>
                </StyledRecommendationContainer>
            );
        }

        return (
            <FlexCardWithIcon
                iconType={this.props.theme.icons.types.healthCheck}
                iconBackground={this.props.theme.colours.gradientCSS.darkGreen}
            >
                <InnerContainer>
                    <StyledTitle>{this.props.date + " at " + this.props.installationName}</StyledTitle>
                    {recommendation}
                </InnerContainer>
            </FlexCardWithIcon>
        );
    }
}

export default withTheme(HealthCheck);
