import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { pathOr } from "ramda";
import styled, { ThemeContext } from "styled-components";
import { typography } from "styled-system";

// ------------------------------------------------------------------------

import { Checkbox } from "@sisuwellness/ui/src/components/Forms";
import Flex from "components/Flex";

import { CheckboxLabel } from "../styled";

// ------------------------------------------------------------------------

const CustomLink = styled(Link)`
    color: ${props => props.theme.colours.blackTints700.hex};
    ${typography}
`;

// ------------------------------------------------------------------------

const RegisterTNC = ({ consents, locale, onChange, ...rest }) => {
    const theme = useContext(ThemeContext);

    const isGlobalLocale = locale === "en";

    const defaultCheckboxState = {};
    consents.forEach((_, index) => (defaultCheckboxState[`checkbox-${index}`] = false));

    const [checkboxState, setCheckboxState] = useState(defaultCheckboxState);

    useEffect(() => {
        const tncState = Object.values(checkboxState).reduce((acc, current) => acc && current, true);
        onChange(tncState);
    }, [checkboxState]);

    const handleOnChange = event => {
        setCheckboxState({ ...checkboxState, [event.target.id]: event.target.checked });
    };

    return (
        <Flex fd="column" onChange={handleOnChange} gap="15px" {...rest}>
            {consents.map((item, index) => {
                let label = pathOr(null, ["configuration", "plaintext", "text"], item);

                if (!label) return <></>;

                const REGEX = /(Terms of Use and Privacy Policy)|(Privacy Policy)|(Terms and Conditions and Privacy Notice)|(Privacy Notice)/;
                const group = label.match(REGEX);

                label = label.replace(REGEX, "");

                let anchorComponent = <></>;

                if (group && group[1]) {
                    anchorComponent = (
                        <>
                            <CustomLink target="_blank" to={`/terms-of-use/${isGlobalLocale ? "" : locale}`}>
                                Terms of Use
                            </CustomLink>
                            {" and "}
                            <CustomLink target="_blank" to={`/privacy-policy/${isGlobalLocale ? "" : locale}`}>
                                Privacy Policy
                            </CustomLink>
                        </>
                    );
                }

                if (group && group[2]) {
                    anchorComponent = (
                        <CustomLink target="_blank" to={`/privacy-policy/${isGlobalLocale ? "" : locale}`}>
                            Privacy Policy
                        </CustomLink>
                    );
                }

                if (group && group[3]) {
                    anchorComponent = (
                        <>
                            <CustomLink target="_blank" to={`/terms-of-use/${isGlobalLocale ? "" : locale}`}>
                                Terms and Conditions
                            </CustomLink>
                            {" and "}
                            <CustomLink target="_blank" to={`/privacy-policy/${isGlobalLocale ? "" : locale}`}>
                                Privacy Notice
                            </CustomLink>
                        </>
                    );
                }

                if (group && group[4]) {
                    anchorComponent = (
                        <CustomLink target="_blank" to={`/privacy-policy/${isGlobalLocale ? "" : locale}`}>
                            Privacy Notice
                        </CustomLink>
                    );
                }

                return (
                    <Checkbox id={index} color={theme.colours.hpGreen.hex} key={index}>
                        <CheckboxLabel fontSize={["13px", "16px"]}>
                            {label}
                            {anchorComponent}
                        </CheckboxLabel>
                    </Checkbox>
                );
            })}
        </Flex>
    );
};

RegisterTNC.propTypes = {
    ...Flex.propTypes,
    consents: PropTypes.array,
    locale: PropTypes.string,
    onChange: PropTypes.func
};

export default RegisterTNC;
