import React from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import { Guidelines } from "@sisuwellness/utilities";
import { UnitConverter } from "@sisuwellness/utilities";

const BodyMassIndexAttribute = ({ t, theme, date, weight, bmi, settings, ...rest }) => {
    let guidelineStyle = null;
    let guideline = null;

    const getValueByUnit = (value, unit) => {
        return unit && unit === "imperial" ? UnitConverter.kgToPounds(value).toFixed(1) : value.toFixed(1);
    };

    if (bmi) {
        guideline = new Guidelines.BodyMassIndex.SiSU(bmi);
        guidelineStyle = theme.guidelines.bodyMassIndex[guideline.key];
    }

    return (
        <Attribute
            {...rest}
            t={t}
            colour={guidelineStyle ? guidelineStyle.flat.hex : null}
            gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
            guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
            guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
            title={t("Weight & BMI")}
            date={date}
            guidelineRatingName={"BMI"}
            primaryStatUnit={settings && settings.unitMeasurement === "imperial" ? "Lbs" : "Kg"}
            primaryStatValue={
                typeof weight == "number"
                    ? settings
                        ? getValueByUnit(weight, settings.unitMeasurement)
                        : weight.toFixed(1)
                    : "-"
            }
            icon={theme.icons.types.bmi}
            secondaryStatUnit={"BMI"}
            secondaryStatValue={bmi ? bmi.toFixed(1) : null}
        />
    );
};

BodyMassIndexAttribute.displayName = "Body Mass Index Attribute";
BodyMassIndexAttribute.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    bmi: PropTypes.number,
    weight: PropTypes.number,
    date: PropTypes.string,
    settings: PropTypes.object
};

export default withTheme(BodyMassIndexAttribute);
