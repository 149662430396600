import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// ---------------------------------------------------------------------------

import UserJourney from "@sisuwellness/web-components/components/UserJourneys/UserJourney";
import { UserJourney as UserJourneyModel } from "@sisuwellness/utilities/UserJourney";
import { setCitizen } from "@sisuwellness/connected-components/actions/auth";
import Response, { EventCodes } from "@sisuwellness/utilities/Response";
import { useHraStateProvider, useMessageBanner } from "components/ContextProviders";
import { modifyResposePromise } from "utilities/commonUtils";
import { MESSAGE_ERROR } from "constants/messages";
import { theme } from "@sisuwellness/web-components";
import PageLayout from "components/PageLayout";
import APIClient from "utilities/APIClient";
import Auth from "utilities/auth";

import routes from "../../constants/routes";
import HraLoadingView from "../HraLoadingView";

// ---------------------------------------------------------------------------

const HealthRiskAssessmentView = () => {
    const [state, setState] = useState({
        response: null,
        processingState: null,
        userJourneyModel: null,
        userJourneyPageId: null
    });

    const { citizen } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const { resetState } = useHraStateProvider();
    const { handleMessage } = useMessageBanner();

    var isMounted = true;
    const handleSetState = state => isMounted && setState(state);

    useEffect(() => {
        resetState(); // Reset HRA States
        modifyResposePromise(APIClient.userJourneys.getHealthRiskAssessment())
            .then(userJourneyConfiguration => {
                let next = null;

                if (userJourneyConfiguration) {
                    next = Auth.getUserUUID();
                }

                return Promise.all([Promise.resolve(userJourneyConfiguration), Promise.resolve(next)]);
            })
            .then(([userJourneyConfiguration, uuid]) => {
                let next = null;
                let journeyResponse = null;
                let userJourneyModel = null;

                if (uuid) {
                    journeyResponse = Response.createForHealthRiskAssessment(userJourneyConfiguration);
                    journeyResponse.userUUID = uuid;

                    userJourneyModel = new UserJourneyModel(userJourneyConfiguration);
                    journeyResponse.setUserJourney(userJourneyModel);

                    journeyResponse.addEvent(EventCodes.healthRiskAssessment.started);

                    if (citizen) {
                        journeyResponse.setPreviousAnswers(citizen.previousAnswersForHRA);
                        journeyResponse
                            .getUserJourney()
                            .getUserJourneyPages()
                            .forEach(ujp => {
                                if (ujp.getSurvey()) {
                                    ujp.getSurvey().evaluateSurveyState();
                                }
                            });

                        if (citizen.data.trackingHealthRiskAssessmentResponse !== null) {
                            journeyResponse.addEvent(EventCodes.healthRiskAssessment.resumed);
                        }
                    }

                    // save the response, track it then return the response id
                    next = APIClient.response.post("", journeyResponse.toJSON()).then(response => {
                        if (response.succeeded()) {
                            return response.body().then(body => {
                                return body.responseId;
                            });
                        }
                    });
                }

                return Promise.all([
                    Promise.resolve(journeyResponse),
                    Promise.resolve(userJourneyModel),
                    Promise.resolve(next)
                ]);
            })
            .then(([journeyResponse, userJourneyModel, newResponseId]) => {
                if (newResponseId) {
                    journeyResponse.responseId = newResponseId;
                }

                handleSetState(state => ({ ...state, userJourneyModel, response: journeyResponse }));
            })
            .catch(() => {
                handleSetState(state => ({ ...state, processingState: "complete" }));
                handleMessage(MESSAGE_ERROR, "Failed to start the assessment. Please try again later.");
            });

        return () => (isMounted = false);
    }, []);

    const handleComplete = () => {
        handleSetState(state => ({ ...state, processingState: "sending" }));
        state.response.addEvent(EventCodes.healthRiskAssessment.completed);
        modifyResposePromise(APIClient.response.finish(state.response))
            .then(() => {
                handleSetState(state => ({ ...state, processingState: "sent" }));
                return modifyResposePromise(APIClient.citizen.get(""));
            })
            .then(citizen => dispatch(setCitizen(citizen)))
            .catch(() => {
                handleSetState(state => ({ ...state, processingState: "complete" }));
                handleMessage(MESSAGE_ERROR, "Could not finish successfully. Please try again later.");
            });
    };

    const handleJourneyPageComplete = () => {
        // we do nothing with success and fail conditions... just be hopeful
        APIClient.response.update(state.response);
    };

    const hasCitizenBeenUpdated = () => {
        modifyResposePromise(APIClient.citizen.get(""))
            .then(latestCitizen => {
                let processingState = "slow";
                if (latestCitizen) {
                    // try-catch easier than checking hierarchy of citizen props
                    // assumption here is that the citizen structure will have this hierarchy if it exists
                    try {
                        if (latestCitizen.data.lifestyleScore.updatedAt !== citizen.data.lifestyleScore.updatedAt) {
                            processingState = "complete";
                        }
                    } catch (error) {
                        // do nothing
                    }
                }

                handleSetState({ ...state, processingState });

                return latestCitizen;
            })
            .then(citizen => {
                dispatch(setCitizen(citizen));
            });
    };

    const slowScreenTimeout = () => {
        handleSetState(state => ({ ...state, processingState: "slowTimeout" }));
    };

    const handleShowPage = userJourneyPageId => {
        handleSetState(state => ({ ...state, userJourneyPageId }));
    };

    let component = null;
    const greyBg = theme.portalColor.hpCardBackground.hex;

    if (state.processingState === "complete" || state.processingState === "slowTimeout") {
        component = <Redirect to={routes.hraResult} />;
    } else if (state.processingState === "sending") {
        component = (
            <PageLayout bg={greyBg}>
                <HraLoadingView
                    title={"Analysing your response"}
                    message={"Please wait while we are crunching some numbers"}
                />
            </PageLayout>
        );
    } else if (state.processingState === "sent") {
        component = (
            <PageLayout bg={greyBg}>
                <HraLoadingView
                    title={"Calculating your scores"}
                    message={"Please wait while I do some maths"}
                    minimumDisplayTime={5000}
                    onMinimumDisplayTimeReached={hasCitizenBeenUpdated}
                />
            </PageLayout>
        );
    } else if (state.processingState === "slow") {
        component = (
            <PageLayout bg={greyBg}>
                <HraLoadingView
                    title={"Sorry, I'm still calculating"}
                    message={"Not all your results may be visible just yet."}
                    minimumDisplayTime={5000}
                    onMinimumDisplayTimeReached={slowScreenTimeout}
                />
            </PageLayout>
        );
    } else if (state.userJourneyModel) {
        component = (
            <PageLayout bg={greyBg}>
                <UserJourney
                    model={state.userJourneyModel}
                    onComplete={handleComplete}
                    onPageResolved={handleJourneyPageComplete}
                    onShowPage={handleShowPage}
                />
            </PageLayout>
        );
    } else {
        component = <HraLoadingView height="100vh" />;
    }

    return component;
};

export default HealthRiskAssessmentView;
