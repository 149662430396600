import React, { useState } from "react";

import { Flex, Text } from "rebass";
import { TabNavigation } from "components/TabNavigation";

const NAVIGATION = {
    MUSCLE: "Muscle",
    FAT: "Fat",
    OTHER: "Other"
};

const CompositionTabs = props => {
    const [currentTab, setCurrentTab] = useState("MUSCLE");

    return (
        <Flex
            minHeight="330px"
            flexDirection="column"
            bg="hpCardBackground"
            p={["16px", "32px"]}
            {...props}
            data-testid="overview-tabs-bc"
        >
            <TabNavigation currentKey={currentTab} setCurrentKey={setCurrentTab} navigationKeyValuePairs={NAVIGATION} />
            <Text color="hpGrayishBlueText" fontSize="13px" fontWeight="light" mt="16px">
                {currentTab === "MUSCLE" && (
                    <>
                        <Text fontWeight="500">What is Skeletal Muscle?</Text>
                        <Text py="1em">
                            The 3 main types of muscle are smooth muscle, cardiac muscle, and skeletal muscle. The first
                            2 types are found within our internal organs and structures and we cannot control their
                            movement. Skeletal muscle is the muscle attached to our bones, allowing movement and the
                            muscle that is visible in people's appearance. This type of muscle can be increased by
                            resistance training. Achieving and maintaining a good level of skeletal muscle mass has many
                            benefits, including:
                        </Text>
                        <Text as="ul" px="20px">
                            <li>Increasing your metabolism and BMR </li>
                            <li>Helps to lose or maintain your weight (by reducing your fat mass)</li>
                            <li>Prevents injury</li>
                            <li>Improves balance and mobility</li>
                        </Text>
                    </>
                )}
                {currentTab === "FAT" && (
                    <>
                        <Text fontWeight="500">What is Body Fat?</Text>
                        <Text py="1em">
                            High levels of body fat, particularly around your midsection, have a negative impact on your
                            health. But it is important to maintain a healthy level of body fat for essential functions.
                            There are 3 main different types of fat:
                        </Text>
                        <Text as="ul" px="20px">
                            <li>
                                <Text as="span" fontWeight="normal">
                                    Essential Fat
                                </Text>{" "}
                                – This type of fat is used to protect vital organs, store vitamins and provide
                                insulation.
                            </li>
                            <li>
                                <Text as="span" fontWeight="normal">
                                    Subcutaneous Fat
                                </Text>{" "}
                                – This makes up the majority of our body fat and can be found under the skin. This is
                                the body’s method of storing excess energy consumed.
                            </li>
                            <li>
                                <Text as="span" fontWeight="normal">
                                    Visceral Fat
                                </Text>{" "}
                                – This is found in the abdomen amongst the major organs. If your visceral fat levels are
                                too high, it can lead to inflammation and high blood pressure, which increases the risk
                                of serious health problems.
                            </li>
                        </Text>
                    </>
                )}
                {currentTab === "OTHER" && (
                    <>
                        <Text fontWeight="500">What is Other?</Text>
                        <Text py="1em">
                            The remainder of your body composition is made up of minerals and extracellular water (there
                            is also water included in your muscle mass, which is known as ‘intracellular water’ because
                            it is inside your body’s cells, e.g. muscle cells). Minerals are primarily contained in bone
                            tissue and in the bloodstream. The percentage of ‘other’ shown represents all these
                            components, plus your cardiac and smooth muscle mass.
                        </Text>
                    </>
                )}
            </Text>
        </Flex>
    );
};

CompositionTabs.propTypes = {};

export default CompositionTabs;
