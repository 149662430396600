import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { StyledInput } from "../../Forms";

// ============================================================================

const StyledContainer = styled.div``;

// ============================================================================

/**
 * Returns a partially or fully formed AU mobile number, with guidance dashes
 *
 * @param value
 * @returns {string}
 */
const formatInputForAU = value => {
    value = value.replace(/\D/g, "");
    if (value.length >= 4) value = value.substring(0, 4) + "-" + value.substring(4);
    if (value.length >= 8) value = value.substring(0, 8) + "-" + value.substring(8);
    return value.substring(0, maxLengthMap.AU);
};

const validatorForAU = value => /^04\d{2}-\d{3}-\d{3}/.test(value);
const isCompleteForAU = value => value.length === maxLengthMap.AU;

const formatMap = { AU: formatInputForAU };
const validatorMap = { AU: validatorForAU };
const isCompleteMap = { AU: isCompleteForAU };
const maxLengthMap = { AU: 12 };

const formatInputFor = regionCode => formatMap[regionCode];
const validateInputFor = regionCode => validatorMap[regionCode];
const isCompleteFor = regionCode => isCompleteMap[regionCode];
const maxLengthFor = regionCode => maxLengthMap[regionCode];

const getRaw = value => value.replace(/-/g, "");
const getLastCharacter = value => value[value.length - 1];

// ============================================================================

const MobileInput = ({ autoFocus, centreText, id, inputWidth, onInputFocus, onIsValid, placeholder, regionCode }) => {
    const inputRef = useRef(null);
    const [value, setValue] = useState("");
    const [valid, setValid] = useState(false);
    const formatter = formatInputFor(regionCode);
    const validator = validateInputFor(regionCode);
    const isComplete = isCompleteFor(regionCode);
    const maxLength = maxLengthFor(regionCode);

    const hasErrors = isComplete(value) && !valid;

    const formatNewValue = newValue => {
        if (value.length > newValue.length && (getLastCharacter(value) === "-" || getLastCharacter(newValue) === "-")) {
            return formatter(newValue.substring(0, newValue.length - 1));
        } else {
            return formatter(newValue);
        }
    };

    const onFocus = () => {
        if (onInputFocus && inputRef.current) {
            const newValue = inputRef.current.value;

            inputRef.current.value = formatNewValue(newValue);
            onInputFocus(inputRef.current);
        }
    };

    useEffect(() => {
        if (autoFocus && onInputFocus) {
            onFocus();
        }
    }, [autoFocus, onInputFocus]);

    useEffect(() => {
        onIsValid(valid, getRaw(value));
    }, [onIsValid, valid]);

    useEffect(() => {
        if (inputRef.current) {
            // Update the cursor position after manual formatting
            inputRef.current.focus();
            inputRef.current.setSelectionRange(value.length, value.length);
        }
        if (value.length >= maxLength) setValue(value.substring(0, maxLength));
    }, [value, maxLength]);

    const onInput = e => {
        const newValue = e.target.value;
        const update = formatNewValue(newValue);

        setValue(update);
        setValid(validator(update));
    };

    return (
        <StyledContainer data-testid={"survey-question-mobile-input"}>
            <StyledInput
                autoComplete={"off"}
                autoFocus={autoFocus}
                centreText={centreText}
                data-testid={`input-${id}`}
                hasErrors={hasErrors}
                id={id}
                maxLength={maxLength}
                onChange={() => {}}
                onFocus={() => onFocus()}
                placeholder={placeholder}
                ref={inputRef}
                type={"text"}
                value={value}
                width={inputWidth}
                onInput={e => onInput(e)}
            />
        </StyledContainer>
    );
};

MobileInput.propTypes = {
    autoFocus: PropTypes.bool,
    centreText: PropTypes.bool,
    id: PropTypes.string.isRequired,
    inputWidth: PropTypes.string,
    onInputFocus: PropTypes.func,
    onIsValid: PropTypes.func,
    placeholder: PropTypes.string,
    regionCode: PropTypes.string.isRequired,
    touchKeyboard: PropTypes.bool
};

export default MobileInput;
