import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { QuestionChoice } from "./styled";

const Label = styled.span`
    font-size: 30px;
`;

const HelpText = styled.span`
    font-size: 20px;
`;

const Spacer = () => <span> </span>;

const Choice = ({ choice: { label, helpText }, isSelected = false, onSelect = () => {}, onDeselect = () => {} }) => {
    const onClick = useCallback(
        e => {
            e.preventDefault();
            if (isSelected) {
                onDeselect(label);
            } else {
                onSelect(label);
            }
        },
        [label, onSelect, onDeselect]
    );

    return (
        <QuestionChoice isSelected={isSelected} onClick={onClick}>
            <Label>{label}</Label>
            <Spacer />
            <HelpText>{helpText}</HelpText>
        </QuestionChoice>
    );
};

Choice.propTypes = {
    choice: PropTypes.object,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func
};

export default Choice;
