import React from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------

import { MediaQuery, TooltipV2, theme } from "@sisuwellness/web-components";
import Password from "@sisuwellness/utilities/Auth/Password";

// ----------------------------------------------------------------------------

import "./tooltip.css";
import { PORTAL_IMAGE } from "constants/imagePath";
import { RulesHeader, Rule, RuleText, RulesContainer, RuleImage } from "./styled-component";

// ----------------------------------------------------------------------------

const PasswordValidation = ({ children, input, rules, headerText }) => {
    const PasswordUtil = new Password();
    const owaspResponse = PasswordUtil.testPassword(input);
    return (
        <TooltipV2
            name={headerText}
            tooltip={
                <>
                    <RulesHeader>{headerText}</RulesHeader>
                    <RulesContainer>
                        {rules.map((rule, index) => {
                            return (
                                <Rule key={index}>
                                    <RuleImage
                                        src={`${PORTAL_IMAGE}/${
                                            rule.owaspErrMsg2
                                                ? owaspResponse.errors.includes(rule.owaspErrMsg) &&
                                                  owaspResponse.errors.includes(rule.owaspErrMsg2)
                                                    ? "check-pending"
                                                    : "check-complete"
                                                : owaspResponse.errors.includes(rule.owaspErrMsg)
                                                ? "check-pending"
                                                : "check-complete"
                                        }.svg`}
                                        alt="check-image"
                                    />
                                    <MediaQuery devices={["desktop"]}>
                                        <RuleText>{rule.displayText}</RuleText>
                                    </MediaQuery>
                                    <MediaQuery devices={["mobile", "tablet"]}>
                                        <RuleText>
                                            {rule.displayTextMobile ? rule.displayTextMobile : rule.displayText}
                                        </RuleText>
                                    </MediaQuery>
                                </Rule>
                            );
                        })}
                    </RulesContainer>
                </>
            }
            tooltipProps={{
                type: "info",
                place: "right",
                effect: "solid",
                backgroundColor: `${theme.colours.flat.white.hex}`,
                border: true,
                borderColor: `${theme.colours.flat.mediumGreen.hex}`,
                textColor: `${theme.colours.flat.primaryBlack.hex}`,
                className: "custom-tooltip",
                clickable: true
            }}
        >
            {children}
        </TooltipV2>
    );
};

// ----------------------------------------------------------------------------

PasswordValidation.displayName = "InputRegexValidation";

PasswordValidation.propTypes = {
    children: PropTypes.node.isRequired,
    input: PropTypes.string.isRequired,
    rules: PropTypes.array,
    headerText: PropTypes.string
};

PasswordValidation.defaultProps = {
    rules: [
        { displayText: "At least 8 characters long", owaspErrMsg: "The password must be at least 8 characters long." },
        {
            displayText: "One lowercase character",
            displayTextMobile: "1 lowercase character",
            owaspErrMsg: "The password must contain at least one lowercase letter."
        },
        {
            displayText: "One uppercase character",
            displayTextMobile: "1 uppercase character",
            owaspErrMsg: "The password must contain at least one uppercase letter."
        },
        {
            displayText: "One number, symbol, or whitespace character",
            displayTextMobile: "1 number, symbol, or whitespace character",
            owaspErrMsg: "The password must contain at least one special character.",
            owaspErrMsg2: "The password must contain at least one number."
        }
    ],
    headerText: "Password Rules"
};

// ----------------------------------------------------------------------------

export default PasswordValidation;
