import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Flex, Image } from "rebass";
import styled from "styled-components";
import Lottie from "react-lottie";

//-----------------------------------------------------------------------------

import FlexWithGap from "components/Flex";
import { PORTAL_IMAGE } from "constants/imagePath";
import UnderstandingImpact from "components/Templates/Premium/UnderstandingImpact";
import UpgradeSiSUBtn from "components/CommonComponents/Button/UpgradeSiSUBtn";
import { MediaQuery, theme } from "@sisuwellness/web-components";
import { useSelector } from "react-redux";

//-----------------------------------------------------------------------------

const LockedImage = styled(Flex)`
    position: relative;
    align-items: center;
    background-size: cover;
    justify-content: center;
`;

const StyledPicture = styled.picture`
    width: 100%;
    filter: blur(3px);
    -webkit-filter: blur(3px);
`;

const LockedState = ({ metricType = {}, ...rest }) => {
    let loopNumber = 1;
    const [isLoopPased, setIsLoopPaused] = useState(false);

    const { isCountryUK } = useSelector(state => state.deviceLocation);

    useEffect(() => {
        if (isCountryUK) return;
        const observer = new IntersectionObserver(entries =>
            entries.forEach(entry => setIsLoopPaused(!entry.isIntersecting))
        );
        observer.observe(document.querySelector(".lottie-wrapper"));

        return () => observer.disconnect();
    }, [isCountryUK]);

    return (
        <FlexWithGap fd="column" gap="32px" with="100%" {...rest}>
            <FlexWithGap fd={["column", "row"]} gap="32px" with="100%">
                <LockedImage width={["100%", "50%"]} {...metricType.LOCKED_IMAGES}>
                    <StyledPicture id="picture-element">
                        <source
                            media={`(max-width:${theme.mediaQuerySizes.tablet}px)`}
                            srcSet={`${PORTAL_IMAGE}/premium/${
                                isCountryUK
                                    ? "heartAge/uk-locked-heart-age-mobile.svg"
                                    : metricType.LOCKED_IMAGES.imageDesktop
                            }`}
                        />
                        <Image
                            width="100%"
                            alt="phone-mockup"
                            src={`${PORTAL_IMAGE}/premium/${
                                isCountryUK ? "heartAge/uk-locked-heart-age.svg" : metricType.LOCKED_IMAGES.imageDesktop
                            }`}
                        />
                    </StyledPicture>
                    <UpgradeSiSUBtn width="250px" position="absolute">
                        {metricType.CTA}
                    </UpgradeSiSUBtn>
                </LockedImage>
                {isCountryUK ? (
                    <>
                        <MediaQuery devices={["desktop", "tablet"]}>
                            <Flex bg="hpCardBackground" p="16px" justifyContent="center" flex={1}>
                                <Image
                                    alt="uk-heart-age-overview.svg"
                                    src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                />
                            </Flex>
                        </MediaQuery>
                        <MediaQuery devices={["mobile"]}>
                            <Flex bg="hpCardBackground" p="16px" justifyContent="center" flex={1}>
                                <Image
                                    alt="uk-heart-age-overview.svg"
                                    src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview-mobile.svg`}
                                />
                            </Flex>
                        </MediaQuery>
                    </>
                ) : (
                    <Flex
                        jc="center"
                        p="16px"
                        bg="hpCardBackground"
                        alignItems="flex-end"
                        className="lottie-wrapper"
                        width={["100%", "50%"]}
                        height={["324px", "324px", "346px"]}
                    >
                        <Lottie
                            height="100%"
                            isPaused={isLoopPased}
                            isClickToPauseDisabled={true}
                            options={{ loop: 2, autoplay: true, animationData: metricType.ANIMATION }}
                            eventListeners={[
                                {
                                    eventName: "loopComplete",
                                    callback: () => (loopNumber += 1)
                                },
                                {
                                    eventName: "enterFrame",
                                    callback: event => event.currentTime > 30 && loopNumber > 1 && setIsLoopPaused(true)
                                }
                            ]}
                        />
                    </Flex>
                )}
            </FlexWithGap>
            <UnderstandingImpact metricType={metricType.UNDERSTANDING_IMPACT} />
        </FlexWithGap>
    );
};

LockedState.propTypes = {
    metricType: PropTypes.object
};

export default LockedState;
