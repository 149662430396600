import BloodPressure from "./BloodPressure";
import BodyMassIndex from "./BodyMassIndex";
import HealthRiskAssessment from "./HealthRiskAssessment";
import BodyFat from "./BodyFat";
import HeartRate from "./HeartRate";
import AustralianDiabetesRisk, { AusDiabetesGuidelineDefinition } from "./AusDiabetesRisk/AusDiabetesRisk";
import NetPromoterScore from "./NetPromoterScore";
import WaistCircumference from "./WaistCircumference";
import TotalCholesterol from "./TotalCholesterol";
import FastingGlucose from "./FastingGlucose";
import NonFastingGlucose from "./NonFastingGlucose";
import HDLCholesterol from "./HDLCholesterol";
import { StressPss4 as StressTest } from "./Stress/SiSU";
import * as PSS4 from "./Stress/SiSU";
import * as Qrisk from "./Qrisk/SiSU";
import Alcohol from "./Alcohol/SiSU";
import Activity from "./Activity/SiSU";
import Weight from "./Weight";
import HRAIndex from "./HRAIndex";
import Sleep from "./Sleep";
import Height from "./Height";
import Smoking from "./Smoking";
import * as HeartAge from "./HeartAge";
import * as DailyEnergyUse from "./DailyEnergyUse";
import * as MuscleMass from "./MuscleMass";
import HRAStress from "./HRAStress";
import HRASleepCaffeine from "./HRASleepCaffeine";
import HRASleepTrouble from "./HRASleepTrouble";
import HRASleepMeal from "./HRASleepMeal";
import HRASuddenAnxiety from "./HRASuddenAnxiety";
import HRAWorkIssues from "./HRAWorkIssues";
import HRAActivity from "./HRAActivity";
import HRAAlcohol from "./HRAAlcohol";
import HRAFatFried from "./HRAFatFried";
import HRAFishAndLegume from "./HRAFishAndLegume";
import HRAFruitAndVeg from "./HRAFruitAndVeg";
import HRAProcessedMeat from "./HRAProcessedMeat";
import HRASubstantialBreakfast from "./HRASubstantialBreakfast";
import HRASweetConsumption from "./HRASweetConsumption";
import HRAThreeMeals from "./HRAThreeMeals";

export default {
    AusDiabetesGuidelineDefinition,
    AustralianDiabetesRisk,
    BloodPressure,
    BodyFat,
    BodyMassIndex,
    FastingGlucose,
    HDLCholesterol,
    HealthRiskAssessment,
    HeartRate,
    NetPromoterScore,
    NonFastingGlucose,
    TotalCholesterol,
    WaistCircumference,
    StressTest,
    PSS4,
    Qrisk,
    Alcohol,
    Activity,
    Weight,
    HRAIndex,
    Sleep,
    Height,
    Smoking,
    HeartAge,
    DailyEnergyUse,
    MuscleMass,
    HRAStress,
    HRASleepCaffeine,
    HRASleepTrouble,
    HRASleepMeal,
    HRASuddenAnxiety,
    HRAWorkIssues,
    HRAActivity,
    HRAAlcohol,
    HRAFatFried,
    HRAFishAndLegume,
    HRAFruitAndVeg,
    HRAProcessedMeat,
    HRASubstantialBreakfast,
    HRASweetConsumption,
    HRAThreeMeals
};
