/**
 * Get feature offering list
 *
 * @param {boolean} isCountryUk is country UK flag
 *
 * @return {Array<{ core: boolean, premium: boolean, featureName: String }>}
 */

// eslint-disable-next-line no-unused-vars
export default (isCountryUk = false) =>
    [
        {
            core: true,
            premium: true,
            featureName: "Existing Free Health Measures*"
        },
        {
            core: false,
            premium: true,
            featureName: "Heart Age"
        },
        {
            core: false,
            premium: true,
            featureName: "The 12-week Sustainable Weight Loss Program"
        },
        {
            core: false,
            premium: true,
            featureName: "The Better Sleep Program"
        },
        {
            core: false,
            premium: true,
            featureName: "More Programs & tools on the SiSU Health Mobile App"
        }
    ].filter(e => e);
