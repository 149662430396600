import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// ============================================================================

import BaseCard from "../../Cards";
import ActionButton from "../../Station/buttons/actionButton";
import ContentContainer from "../../WYSIWYG/Container";
import SiSUKeyboard from "../../Keyboards/SisuKeyboard";
import MobileInput from "../Components/MobileInput";
import { Back } from "../../Buttons";
import FullPageCard from "../Components/FullPageCard";

// ============================================================================

const Views = {
    CONSENT: "CONSENT",
    TERMS: "TERMS"
};

// ============================================================================

// Consent View
const StyledContainer = styled.div`
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    min-height: 100%;
    height: 100%;
`;

const StyledInnerContainer = styled(BaseCard)`
    position: relative;
    flex-grow: 5;
    flex-basis: 2em;
    margin-left: 1em;
    min-height: 100%;
    height: 785px;
    padding: 0;
`;

const InputContainer = styled.div`
    width: 60%;
    align-self: center;
`;

const StyledButtonContainer = styled.div`
    bottom: 0;
    width: 100%;
    height: 5em;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: #fff;
`;

const StyledActionButton = styled(ActionButton)`
    text-align: center !important;
    text-transform: none;
`;

const KeyboardContainer = styled.div`
    background: ${props => props.theme.colours.flat.lightGray.hex};
    position: absolute;
    left: 0;
    bottom: 0;

    height: 38%;
    width: 100%;

    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.9));
    display: flex;
`;

const TermsOfServiceButton = styled.a`
    position: absolute;
    padding: 1em;
    top: 0;
    right: 0;
    font-size: ${props => props.theme.text.heading6.sizeRem};
    color: ${props => props.theme.colours.flat.lightBlue.hex};
`;

const StyledTermsContainer = styled(StyledContainer)`
    flex-direction: column;
    width: 100%;
    padding: 2.5em;
`;

// contains WYSIWYG content
const StyledTermsDetails = styled.div`
    margin-top: 1em;
    width: 100%;
    height: 670px;
    overflow: scroll;

    h2 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading2.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    h3 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading3.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    h4 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading4.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    p {
        font-size: ${props => props.theme.text.paragraph.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }
`;

// ============================================================================

const createKeyboard = (inputNode, onNext) => {
    const keyboardProps = {
        disableAlphabets: true,
        disableSymbols: true,
        inputNode,
        nextEnabled: false,
        onNext
    };

    return (
        <KeyboardContainer data-testid={"keyboard-container"}>
            <SiSUKeyboard {...keyboardProps} />
        </KeyboardContainer>
    );
};

// ============================================================================

const MobileNumber = ({ schema, survey, onAccepted, onRejected, touchKeyboard }) => {
    const {
        acceptButtonLabel,
        rejectButtonLabel,
        content,
        regionCode,
        termsOfService,
        termsOfServiceButtonLabel
    } = schema;

    const questionGroup = survey.getQuestionGroups().length ? survey.getQuestionGroups()[0] : null;
    const questionModel = questionGroup ? questionGroup.firstQuestion : null;

    const [currentView, setCurrentView] = useState(Views.CONSENT);
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
    const [canAccept, setCanAccept] = useState(false);
    const [inputNode, setInputNode] = useState(null);
    const [keyboard, setKeyboard] = useState(null);

    const handleAccepted = () => {
        if (!canAccept) return;

        questionModel.addAnswer(mobilePhoneNumber);
        onAccepted();
    };

    useEffect(() => {
        if (!inputNode) return;

        const keyboard = createKeyboard(inputNode, () => handleAccepted());
        setKeyboard(keyboard);
    }, [inputNode]);

    const onInputFocus = inputRef => {
        if (inputRef === inputNode) return;
        setInputNode(inputRef);
    };

    const onIsValid = (valid, mobilePhoneNumber) => {
        if (valid) setMobilePhoneNumber(mobilePhoneNumber);
        setCanAccept(valid);
    };

    if (!questionModel) return null;

    // ======================================

    if (currentView === Views.TERMS) {
        return (
            <FullPageCard>
                <StyledTermsContainer>
                    <Back visible onClick={() => setCurrentView(Views.CONSENT)}>
                        &lt; Back
                    </Back>
                    <StyledTermsDetails dangerouslySetInnerHTML={{ __html: termsOfService }} />
                </StyledTermsContainer>
            </FullPageCard>
        );
    }

    // ======================================

    const input = (
        <MobileInput
            autoFocus={true}
            centreText={true}
            id={"survey-question-" + questionModel.getQuestionId()}
            inputWidth={"100%"}
            key={questionModel.getQuestionId()}
            model={questionModel}
            onInputFocus={inputRef => onInputFocus(inputRef)}
            onIsValid={(valid, mobilePhoneNumber) => onIsValid(valid, mobilePhoneNumber)}
            placeholder={"Please enter your mobile number"}
            regionCode={regionCode}
            touchKeyboard={touchKeyboard}
        />
    );

    return (
        <>
            <StyledContainer>
                <StyledInnerContainer flexDirection={"column"}>
                    <TermsOfServiceButton onClick={() => setCurrentView(Views.TERMS)}>
                        {termsOfServiceButtonLabel}
                    </TermsOfServiceButton>
                    <ContentContainer dangerouslySetInnerHTML={{ __html: content }} />
                    <InputContainer>{input}</InputContainer>

                    <StyledButtonContainer>
                        <StyledActionButton
                            styleType={"Secondary"}
                            label={rejectButtonLabel}
                            onClick={() => onRejected()}
                        />
                        <StyledActionButton
                            disabled={!canAccept}
                            label={acceptButtonLabel}
                            onClick={() => handleAccepted()}
                        />
                    </StyledButtonContainer>
                </StyledInnerContainer>
            </StyledContainer>
            {keyboard}
        </>
    );
};

MobileNumber.propTypes = {
    theme: PropTypes.object,
    schema: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
    onAccepted: PropTypes.func.isRequired,
    onRejected: PropTypes.func.isRequired,
    touchKeyboard: PropTypes.bool.isRequired
};

export default MobileNumber;
