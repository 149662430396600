import React, { useImperativeHandle, useState, useContext } from "react";
import PropTypes from "prop-types";

import AutoSuggest from "react-autosuggest";
import { ThemeContext } from "styled-components";

// ----------------------------------------------------------------------------

import { InputContainer, InputFieldTheme, LeftChild, RightChild, Container } from "./styled-components";
import { ErrorNotification } from "components/Forms";

// ----------------------------------------------------------------------------

const InputField = ({
    id,
    inputProps,
    leftChild,
    rightChild,
    customStyles,
    label,
    className,
    error,
    forwardRef,
    autoSuggest,
    fontFamily,
    handleOnSuggestionsFetch,
    handleGetSuggestionValue,
    handleRenderSuggestion,
    ...rest
}) => {
    const theme = useContext(ThemeContext);

    const [suggestions, setSuggestions] = useState([]);

    useImperativeHandle(forwardRef, () => {
        return forwardRef.current ? forwardRef.current.input : forwardRef.current;
    });

    const handleSuggestionsClear = () => setSuggestions([]);

    const onSuggestionsFetch = ({ value }) => handleOnSuggestionsFetch(value, setSuggestions);

    return (
        <Container width="100%" fontFamily={fontFamily ? fontFamily : "Open Sans"} {...rest}>
            <InputContainer label={label} error={error} className={className} customStyles={customStyles}>
                {leftChild && <LeftChild>{leftChild}</LeftChild>}
                <AutoSuggest
                    ref={forwardRef}
                    id={id ? id : "input-field"}
                    theme={InputFieldTheme(theme, suggestions ? suggestions.length : [])}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={autoSuggest ? onSuggestionsFetch : () => {}}
                    onSuggestionsClearRequested={autoSuggest ? handleSuggestionsClear : () => {}}
                    alwaysRenderSuggestions
                    getSuggestionValue={autoSuggest ? handleGetSuggestionValue : () => {}}
                    renderSuggestion={autoSuggest ? handleRenderSuggestion : () => {}}
                    inputProps={{ ...inputProps, autoComplete: "nope" }}
                />
                {rightChild && <RightChild>{rightChild}</RightChild>}
            </InputContainer>
            {<ErrorNotification boolExp={!!error}>{error}</ErrorNotification>}
        </Container>
    );
};

InputField.propTypes = {
    inputProps: PropTypes.shape({
        value: PropTypes.string.isRequired
    }),
    id: PropTypes.any,
    label: PropTypes.string,
    leftChild: PropTypes.any,
    rightChild: PropTypes.any,
    customStyles: PropTypes.any,
    className: PropTypes.string,
    fontFamily: PropTypes.string,
    theme: PropTypes.object,
    forwardRef: PropTypes.object,
    autoSuggest: PropTypes.bool.isRequired,
    handleOnSuggestionsFetch: PropTypes.func,
    handleGetSuggestionValue: PropTypes.func,
    handleRenderSuggestion: PropTypes.func,
    error: PropTypes.any
};

InputField.defaultProps = {
    autoSuggest: false
};

export default React.forwardRef((props, ref) => <InputField {...props} forwardRef={ref} />);
