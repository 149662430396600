import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import medicareCard from "../assets/medicare-card.jpeg";
import MedicareNumberInput from "../MedicareNumberInput";
import MediaQuery from "../../MediaQuery";
import { Flex, FlexBox, Box } from "../../Flex";
import Title from "../Title";
import Help from "../Help";

const Link = styled.div`
    font-size: ${props => props.theme.text.heading5.sizeRem};
    color: ${props => props.theme.colours.flat.lightBlue.hex};
    user-select: none;
    margin: ${props => props.margin};
`;

const CardImage = styled.img`
    width: 100%;
    max-width: 350px;
`;

const Disclaimer = styled(Help)`
    font-size: 1rem;
    font-family: "Proxima Nova Alt Light", Helvetica, Arial, sans-serif;
    margin-top: 0;
    margin-bottom: 1.2rem;
`;

const HelpText = styled(Help)`
    margin-bottom: 0;
`;

const MedicareNumber = ({
    title = "What is your Medicare number?",
    helpText = "So you can access your medication quickly at the best price",
    disclaimer = "By entering your Medicare card number you agree to the assignment of the Medicare benefit directly to the provider (bulk bill)",
    errors = [],
    onFocusChanged = () => {},
    onValuesChanged = () => {},
    onNoMedicare = () => {}
}) => {
    const input = (
        <MedicareNumberInput
            errors={errors}
            onValuesChanged={e => onValuesChanged(e)}
            onFocusChanged={e => onFocusChanged(e)}
        />
    );
    const link = (margin = "1rem 0") => (
        <Link margin={margin} onClick={() => onNoMedicare()}>
            {noMedicare}
        </Link>
    );

    const noMedicare = "No Medicare Number";
    return (
        <>
            <MediaQuery key={"desktop"} devices={["desktop"]}>
                <Flex fd="column" ai="center" jc="center">
                    <Title>{title}</Title>
                    <HelpText>{helpText}</HelpText>
                    {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
                    <Flex ai="center" jc="center">
                        <CardImage src={medicareCard} />
                        <FlexBox fd="column" ai="center">
                            <Box width={3 / 4} mb="1rem">
                                {input}
                            </Box>
                            {link()}
                        </FlexBox>
                    </Flex>
                </Flex>
            </MediaQuery>
            <MediaQuery key={"mobile"} devices={["mobile", "tablet"]}>
                <Flex fd="column" jc="flex-start" ai="center" mx="0.5rem" overflow={"auto"}>
                    <Title my="1rem">{title}</Title>
                    <Box>{input}</Box>
                    <Box>{link("1.5rem 0")}</Box>
                    <Box>
                        <CardImage src={medicareCard} />
                    </Box>
                </Flex>
            </MediaQuery>
        </>
    );
};

MedicareNumber.propTypes = {
    title: PropTypes.string,
    helpText: PropTypes.string,
    disclaimer: PropTypes.string,
    onFocusChanged: PropTypes.func,
    onValuesChanged: PropTypes.func,
    onNoMedicare: PropTypes.func,
    errors: PropTypes.array
};

export default MedicareNumber;
