import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField } from "../../Forms";
import PrimaryButton from "../../Buttons/primary";
import { media } from "../../../themes";
import Heading from "../../Heading";

const StyledFormAndErrorsContainer = styled.div`
    flex: 1 0 0;
    order: 2;
`;

const StyledFormBox = styled.div`
    margin: 2em;

    ${media.mobile`
        height: 60vh;
    `};
`;

const StyledPrimaryButton = styled.div`
    margin-top: 10px;

    button {
        width: 100%;
    }
`;

const SetupViewStrings = {
    labels: {
        submit: "Submit"
    }
};

const SetupForm = ({ handleSubmit, handleChangePassword, handleChangeConfirmPassword, isLoading }) => {
    return (
        <StyledFormAndErrorsContainer>
            <Heading renderAs="h4" textAlign="center" mt="1em">
                Create your password
            </Heading>
            <StyledFormBox>
                <form onSubmit={handleSubmit} data-testid="setup-form">
                    <TextField
                        name={"password"}
                        value={""}
                        label={"Password"}
                        fieldId={"password"}
                        className={"login-password"}
                        type={"password"}
                        autoFocus={true}
                        onChange={handleChangePassword}
                        required={true}
                    />
                    <TextField
                        name={"confirmPassword"}
                        value={""}
                        label={"Confirm Password"}
                        fieldId={"confirmPassword"}
                        className={"register-password"}
                        type={"password"}
                        onChange={handleChangeConfirmPassword}
                        required={true}
                    />
                    <StyledPrimaryButton>
                        <PrimaryButton
                            label={SetupViewStrings.labels.submit}
                            type={"submit"}
                            disabled={isLoading}
                            borderRadius={"5px"}
                        />
                    </StyledPrimaryButton>
                </form>
            </StyledFormBox>
        </StyledFormAndErrorsContainer>
    );
};

SetupForm.propTypes = {
    isLoading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleChangePassword: PropTypes.func.isRequired,
    handleChangeConfirmPassword: PropTypes.func.isRequired
};

export default SetupForm;
