import {
    BODY_FAT,
    BODY_MASS_INDEX,
    WEIGHT,
    HEIGHT,
    BLOOD_PRESSURE,
    HEART_RATE,
    DIABETES,
    STRESS_PSS4,
    ACTIVITY,
    ALCOHOL_RISK,
    SMOKING,
    HEART_AGE,
    TDEE,
    BODY_COMPOSITION,
    MUSCLE_MASS
} from "./trend-charts";
import React from "react";
import { Text } from "rebass";

// Note: New Metric support should follow same object structure
const FACTS = {
    [BODY_MASS_INDEX]: {
        title: "Body Mass Index (BMI)",
        data: [
            {
                heading: "What is BMI?",
                content: (
                    <p>
                        Body Mass Index (BMI) is an internationally recognised weight assessment measurement,
                        recommended by the World Health Organisation. BMI is not always a perfect measure for every
                        individual; however it is a good indicator of the health of adult population groups.
                    </p>
                )
            },
            {
                heading: "How is BMI calculated?",
                content: (
                    <>
                        <p>
                            The BMI calculation uses your height and weight measurements to determine if you are a
                            healthy weight for your height.
                        </p>
                        <p>
                            The BMI calculation is: Weight (kg) / height (m)<sup>2</sup>
                        </p>
                    </>
                )
            },
            {
                heading: "Does BMI mean the same thing for everyone?  ",
                content: (
                    <>
                        <p>
                            No, BMI is not a ‘one size fits all’ standard, it can be not applicable and has exceptions.
                        </p>
                        <p>
                            BMI is not a suitable measure for:
                            <br />
                            &nbsp;&nbsp;• People under 18 years of age
                            <br />
                            &nbsp;&nbsp;• Women who are pregnant
                        </p>
                        <p>
                            BMI exceptions are:
                            <br />
                            &nbsp;&nbsp;• The healthy weight range is lower for people with an Asian background
                            <br />
                            &nbsp;&nbsp;• The healthy weight range is higher for those of Polynesian origin
                            <br />
                            &nbsp;&nbsp;• The healthy weight range is higher for older people
                            <br />
                            &nbsp;&nbsp;• The healthy weight range is higher for elite athletes with higher than normal
                            levels of lean body tissue
                        </p>
                    </>
                )
            }
        ]
    },
    [BODY_FAT]: {
        title: "Body Fat Percentage",
        data: [
            {
                heading: "How does the SiSU Health Station measure body composition?",
                content: (
                    <>
                        <p>
                            Body composition on the SiSU Heath Station uses a method called Bio-electrical Impedance
                            (BIA). Along with your age, height and weight, the Body Composition result takes the BIA
                            output and provides a clinically validated result for Body Fat. High body fat as measured by
                            BIA can help identify an increased cardiometabolic risk, which determines your chances of
                            having a heart attack or stroke.
                        </p>
                        <strong>How is this done?</strong>
                        <p>
                            The SiSU Health Station's handles create a tiny high-frequency signal that passes though the
                            muscle and fat of your arms and chest. Because muscle and blood conduct electricity and fat
                            insulates electricity, the total electrical resistance will give an indication of the users
                            fat percentage. The unit meets IEC 60601-1 requirements including patient auxiliary current
                            and power isolation. It is specified for users having up to 50% body fat.
                        </p>
                    </>
                )
            },
            {
                heading: "Are there any limitations with the test?",
                content: (
                    <>
                        <p>
                            Body composition will not work above 50% body fat and/or BMI &gt; 50 It operates on a
                            prediction algorithm that will give varying results for different people. Hydration, time of
                            day, whether you have long or short arms for your height and even your ethnicity can impact
                            the results.
                        </p>
                        <p>
                            It is best to think of the body fat result as an advanced BMI, and like BMI there is still
                            limitations and should be used in conjunction with other indicators such as Blood Pressure
                            and Resting Heart Rate to determine health goals and if lifestyle change is needed.
                        </p>
                    </>
                )
            },
            {
                heading: "What exactly is body fat?",
                content: (
                    <>
                        <p>
                            People use the term “fat” mainly as a negative way of describing a body type, but there are
                            different types of fat in your body. High levels of fat do indeed have a negative impact on
                            your health and can contribute to Type 2 Diabetes, heart disease, high blood pressure or
                            cancer, but it is important to maintain a healthy level of body fat.
                        </p>
                        <p>Fat in our body is stored in three different ways: </p>
                        &nbsp;&nbsp;• Essential Fat – This type of fat is used to protect vital organs, store vitamins,
                        insulate us and build key cell messengers. Men typically have around 3% essential body fat,
                        compared with women who typically have around 12% essential body fat.
                        <br />
                        &nbsp;&nbsp;• Subcutaneous Fat – This makes up the majority of our body fat and can be found
                        under the skin. This is the body’s method of storing energy for later use.
                        <br />
                        &nbsp;&nbsp;• Visceral Fat – This is found in the abdomen amongst the major organs and ensures
                        that there is some distance between each organ. If your visceral fat levels are too high, it can
                        lead to inflammation and high blood pressure, which increases the risk of serious health
                        problems.
                        <br />
                        <p>
                            Through maintaining an overall healthy level of body fat, you can manage all three types of
                            body fat and reduce your risk of health problems.{" "}
                        </p>
                    </>
                )
            }
        ]
    },
    [WEIGHT]: {
        title: "Weight",
        data: [
            {
                heading: "What causes weight gain?",
                content: (
                    <>
                        <p>
                            Weight gain occurs gradually when you take in more energy that your body needs over time.
                            The average adult needs 8700 kJ each day, any extra energy you consume is stored as fat.
                        </p>
                        <p>Ongoing poor diet and lifestyle choices can develop obesity.</p>
                        <p>Obesity can also be developed from other factors, such as:</p>
                        &nbsp;&nbsp;• Family history
                        <br />
                        &nbsp;&nbsp;• Your environment
                        <br />
                        &nbsp;&nbsp;• Metabolism
                        <br />
                        &nbsp;&nbsp;• Certain medical conditions and medications
                        <br />
                        <p>
                            Source:{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.healthdirect.gov.au/obesity#complications"
                            >
                                Health Direct
                            </a>
                        </p>
                    </>
                )
            }
        ]
    },
    [HEIGHT]: {
        title: "Height",
        data: [
            {
                heading: "Is it normal to lose height?",
                content: (
                    <>
                        <p>
                            Losing a little height as you get older is normal. Estimates vary, some people lose more
                            than others and some have a higher rate of loss.
                        </p>
                        <p>Loss of height is caused by: </p>
                        &nbsp;&nbsp;• Compression of discs between spine’s vertebrae due to loss of bone density
                        (osteoporosis)
                        <br />
                        &nbsp;&nbsp;• Ageing spine is more curved
                        <br />
                        &nbsp;&nbsp;• Your muscles start losing mass
                        <br />
                        &nbsp;&nbsp;• Spaces between joints narrowing
                        <br />
                        &nbsp;&nbsp;• Gradual flattening of arches of the feet
                        <br />
                    </>
                )
            },
            {
                heading: "Is loss of height a sign of health problems?",
                content: (
                    <>
                        <p>It can be.</p>
                        <p>
                            Loss in height may signal osteoporsis and other skeletal conditions, or simply a marker for
                            poor nutrition.
                        </p>
                    </>
                )
            }
        ]
    },
    [BLOOD_PRESSURE]: {
        title: "Blood Pressure",
        data: [
            {
                heading: "What is blood pressure?",
                content: (
                    <>
                        <p>
                            Blood pressure is a measure of the force that your heart uses to pump blood around your
                            body.
                            <br />
                            Your Blood Pressure reading has two numbers, i.e. 120/80.
                        </p>
                        <p>
                            Systolic pressure: (top number) The level of pressure in your arteries during the
                            contraction of your heart muscles.
                            <br />
                            Diastolic pressure: (bottom number) The pressure of your blood in between heartbeats.
                        </p>
                    </>
                )
            },
            {
                heading: "What contributes to high blood pressure?",
                content: (
                    <>
                        &nbsp;&nbsp;• Smoking
                        <br />
                        &nbsp;&nbsp;• Physical inactivity
                        <br />
                        &nbsp;&nbsp;• Being overweight
                        <br />
                        &nbsp;&nbsp;• Excessive alcohol intake
                        <br />
                        &nbsp;&nbsp;• Excessive caffeine consumption
                        <br />
                        &nbsp;&nbsp;• Stress
                        <br />
                        &nbsp;&nbsp;• Family history
                        <br />
                        <p>
                            High Blood Pressure can weaken the walls of your arteries in your brain, and contribute to
                            the build-up of atherosclerosis (plaque build-up on artery walls), restricting the flow of
                            blood to your heart.
                        </p>
                    </>
                )
            },
            {
                heading: "Does blood pressure rise with age?",
                content: (
                    <>
                        <p>
                            As you grow older, your vascular system changes - this includes your heart and blood
                            vessels. In the blood vessels, there’s a reduction in elastic tissue in your arteries,
                            causing them to become stiffer and less compliant. As a result, an increased blood pressure
                            is a more common occurrence.{" "}
                        </p>
                        <p>
                            However, it is only one of many factors contribute to high blood pressure and there are many
                            ways to reduce your risk:
                        </p>
                        &nbsp;&nbsp;• Exercise most days of the week
                        <br />
                        &nbsp;&nbsp;• Consume a low sodium-diet
                        <br />
                        &nbsp;&nbsp;• Limit alcohol intake to no more than 1-2 drinks per day
                        <br />
                        &nbsp;&nbsp;• Make stress reduction a priority
                        <br />
                        &nbsp;&nbsp;• Get a good night’s sleep
                        <br />
                        &nbsp;&nbsp;• Monitor your weight
                        <br />
                        &nbsp;&nbsp;• Get regular health check-ups
                        <br />
                    </>
                )
            },
            {
                heading: "How often should I get my blood pressure checked?",
                content: (
                    <>
                        <p>
                            It is essential to check regularly to determine a reliable baseline.Factors such as
                            exercise, whether you have consumed a caffeinated beverage and stress can also impact your
                            Blood Pressure results. To obtain reliable information, aim to complete a SiSU Health Check
                            each week for three weeks to establish your baseline and every 1-2 weeks after that*
                        </p>
                        <p>
                            *Please note this advice is general in nature. Please remember to seek medical advice from
                            your GP.
                        </p>
                    </>
                )
            }
        ]
    },
    [HEART_RATE]: {
        title: "Heart Rate",
        data: [
            {
                heading: "What is heart rate?",
                content: (
                    <>
                        <p>
                            Heart rate or pulse is the number of times your heart beats per minute. Your resting heart
                            rate is an indication of how efficiently your heart is working.
                        </p>
                        <p>
                            Generally, a lower heart rate will indicate a higher level of fitness. An individual’s
                            resting heart rate varies between 60 – 100 beats per minute. If your heart rate is above 100
                            beats per minute, make sure you check in with your GP.
                        </p>
                        <p>
                            It is essential to know what you resting heart rate is, but it is not an indicator of your
                            resting Blood Pressure.
                        </p>
                    </>
                )
            },
            {
                heading: "What influences your heart rate?",
                content: (
                    <>
                        <p>Factors that can influence your heart rate in the short term:</p>
                        &nbsp;&nbsp;• Physical activity – while you are physically active your heart rate will go up
                        <br />
                        &nbsp;&nbsp;• Air temperature – hot weather can increase your heart rate
                        <br />
                        &nbsp;&nbsp;• Medications - specific prescription medications can increase your resting heart
                        rate.
                        <br />
                        &nbsp;&nbsp;• Emotions – stress or excitement can make your heart rate go up
                        <br />
                        <p>Factors that can influence your heart rate in the long term:</p>
                        &nbsp;&nbsp;• Age – your heart rate can change as you get older
                        <br />
                        &nbsp;&nbsp;• Fitness level – higher levels of cardiovascular fitness can lower your resting
                        heart rate
                        <br />
                    </>
                )
            },
            {
                heading: "What heart rate is a heart attack?",
                content: (
                    <>
                        <p>
                            During a heart attack, your heart muscle receives less blood because one or more arteries
                            that supply the muscle are blocked or spasming and unable to deliver a sufficient flow of
                            blood. Or, the cardiac demand (the amount of oxygen the heart needs) is higher than the
                            cardiac supply (the amount of oxygen the heart has) available.
                        </p>
                        <p>
                            A very high or very low heart rate may reveal your risk for heart attack. For most people, a
                            heart rate that’s consistently above 100 beats per minute or below 60 beats per minute for
                            nonathletes should prompt a visit to a doctor for a heart health evaluation.
                        </p>
                        <p>
                            Source:{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.healthline.com/health/heart-rate-during-heart-attack#heart-attack-risk-factors"
                            >
                                HealthLine
                            </a>
                        </p>
                    </>
                )
            }
        ]
    },
    [DIABETES]: {
        title: "Diabetes",
        data: [
            {
                heading: "What exactly is diabetes?",
                content: (
                    <>
                        <p>
                            Diabetes mellitus is a group of metabolic diseases characterized by high blood sugar
                            (glucose) levels that result from defects in insulin secretion, or its action, or both.
                        </p>
                        <p>
                            Normally, blood glucose levels are tightly controlled by insulin, a hormone produced by the
                            pancreas. Insulin lowers the blood glucose level. When the blood glucose elevates (for
                            example, after eating food), insulin is released from the pancreas to normalize the glucose
                            level by promoting the uptake of glucose into body cells. In patients with diabetes, the
                            absence of insufficient production of or lack of response to insulin causes hyperglycaemia.
                            Diabetes is a chronic medical condition, meaning that although it can be controlled, it
                            lasts a lifetime.
                        </p>
                        <p>
                            Source:{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.medicinenet.com/diabetes_mellitus/article.htm"
                            >
                                MedicineNet
                            </a>
                        </p>
                    </>
                )
            },
            {
                heading: "What is the diabetes score based on?",
                content: (
                    <>
                        <p>
                            The diabetes risk algorithm on the SiSU Health Station is based on the Australian Type 2
                            Diabetes Risk Assessment Tool (AUSDRISK), developed by the Baker IDI Heart and Diabetes
                            Institute on behalf of the Australian, state and territory governments.
                        </p>
                        <p>
                            It represents the most up to date information on the risk factors for the development of
                            diabetes.
                        </p>
                    </>
                )
            },
            {
                heading:
                    "What risk factors are being examined in the Australian Type 2 Diabetes Risk Assessment Tool (AUSDRISK) tool?",
                content: (
                    <>
                        <p>Factors that can influence your heart rate in the short term:</p>
                        <p>The AUSDRISK tool contains 10 questions which look at your:</p>
                        &nbsp;&nbsp;• Age
                        <br />
                        &nbsp;&nbsp;• Gender
                        <br />
                        &nbsp;&nbsp;• Your ethnicity/country of birth
                        <br />
                        &nbsp;&nbsp;• Family history of diabetes
                        <br />
                        &nbsp;&nbsp;• Previous blood sugar readings
                        <br />
                        &nbsp;&nbsp;• Blood Pressure medication
                        <br />
                        &nbsp;&nbsp;• Smoking habits
                        <br />
                        &nbsp;&nbsp;• Fruit and vegetable intake
                        <br />
                        &nbsp;&nbsp;• Physical activity
                        <br />
                        &nbsp;&nbsp;• Waist circumference
                        <br />
                    </>
                )
            },
            {
                heading: "How is the diabetes score determined?",
                content: (
                    <>
                        <p>
                            Each question is weighted differently, meaning that some risk factors will increase your
                            risk more than others.
                        </p>
                        <p>
                            For example, an age of 55 yrs+ or a waist circumference of higher than 100cm for women/
                            110cm for men, will give you 6-7 points and put you into the Intermediate risk category,
                            even without answering the other questions.
                        </p>
                    </>
                )
            }
        ]
    },
    [STRESS_PSS4]: {
        title: "Perceived Stress",
        data: [
            {
                heading: "How is Perceived Stress (PSS-4) calculated?",
                content: (
                    <>
                        <p>
                            Perceived Stress (PSS-4) questions are general in nature hence relatively free of content
                            specific to any sub-population group.
                        </p>
                        <p>
                            PSS-4 scores are obtained from the sum across all questions.
                            <br />
                            Lowest total score = 0
                            <br />
                            Highest total score = 16
                        </p>
                    </>
                )
            },
            {
                heading: "What are the common signs of stress?",
                content: (
                    <>
                        <p>
                            Stress is the body's reaction to any change that requires an adjustment or response. The
                            body reacts to these changes with physical, mental, and emotional responses. Stress is a
                            normal part of life. You can experience stress from your environment, your body, and your
                            thoughts. Even positive life changes such as a promotion, a mortgage, or the birth of a
                            child produce stress.
                        </p>
                        <p>
                            Chronic stress can wear down the body's natural defences, leading to a variety of physical
                            symptoms, including the following:
                        </p>
                        &nbsp;&nbsp;• Dizziness or a general feeling of "being out of it"
                        <br />
                        &nbsp;&nbsp;• General aches and pains
                        <br />
                        &nbsp;&nbsp;• Grinding teeth, clenched jaw
                        <br />
                        &nbsp;&nbsp;• Headaches
                        <br />
                        &nbsp;&nbsp;• Indigestion or acid reflux symptoms
                        <br />
                        &nbsp;&nbsp;• Increase in or loss of appetite
                        <br />
                        &nbsp;&nbsp;• Muscle tension in neck, face or shoulders
                        <br />
                        &nbsp;&nbsp;• Problems sleeping
                        <br />
                        &nbsp;&nbsp;• Racing heart
                        <br />
                        &nbsp;&nbsp;• Cold and sweaty palms
                        <br />
                        &nbsp;&nbsp;• Tiredness, exhaustion
                        <br />
                        &nbsp;&nbsp;• Trembling/shaking
                        <br />
                        &nbsp;&nbsp;• Weight gain or loss
                        <br />
                        &nbsp;&nbsp;• Upset stomach, diarrhea
                        <br />
                        &nbsp;&nbsp;• Sexual difficulties
                        <br />
                        <p>
                            Source:&nbsp;
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://my.clevelandclinic.org/health/articles/11874-stress"
                            >
                                Cleveland Clinic
                            </a>
                        </p>
                    </>
                )
            },
            {
                heading: "What is the impact of stress to health?",
                content: (
                    <>
                        <p>
                            Stress can affect the behaviour, body and feelings, if the stress level is greater than our
                            ability to cope.
                        </p>
                        <p>Chronic and severe stress can increase risk of mental and physical issues.</p>
                    </>
                )
            }
        ]
    },
    [ACTIVITY]: {
        title: "Physical Activity",
        data: [
            {
                heading: "What’s the recommended amount of activity per week? ",
                content: (
                    <>
                        <p>The recommended amount of exercise per week for adults is as follows: </p>
                        &nbsp;&nbsp;• Aim to be physically active every day.
                        <br />
                        &nbsp;&nbsp;• Do strengthening activities that work major muscles groups at least twice.
                        <br />
                        &nbsp;&nbsp;• At least 150 of moderate intensity or 75 minutes of vigorous intensity activity.
                        <br />
                        <p>
                            If you don’t manage to meet these guidelines every week, then don’t fret, focus on reaching
                            them next week!
                        </p>
                    </>
                )
            },
            {
                heading: "How is my activity level calculated?",
                content: (
                    <>
                        <p>
                            Your activity level is calculated using your Physical Activity Index (PAI), which is based
                            on questions around your past 7 days of activity and the nature of your job.
                        </p>
                        <p>
                            Your PAI score is designed to give you a brief, easy-to-understand idea of your current
                            activity levels, and highlight any improvements that you may need to make, in order to
                            reduce your risk of serious health conditions.
                        </p>
                    </>
                )
            }
        ]
    },
    [ALCOHOL_RISK]: {
        title: "Alcohol Intake",
        data: [
            {
                heading: "What are the risks of drinking too much alcohol?",
                content: (
                    <>
                        <p>Some of the short-term risks of alcohol misuse can be: </p>
                        &nbsp;&nbsp;• Accidents and injuries requiring hospital treatment.
                        <br />
                        &nbsp;&nbsp;• Violent behaviour and being a victim of violence.
                        <br />
                        &nbsp;&nbsp;• Alcohol poisoning.
                        <p>Persistent alcohol misuse increases your risk of the following serious health conditions:</p>
                        &nbsp;&nbsp;• Heart disease
                        <br />
                        &nbsp;&nbsp;• Liver disease
                        <br />
                        &nbsp;&nbsp;• Pancreatitis
                        <br />
                        &nbsp;&nbsp;• Numerous cancers, such as liver, bowel, mouth and breast cancer.
                        <br />
                        <p>
                            If you’re concerned about your drinking or someone else’s, a good first step is to see a
                            doctor.
                        </p>
                    </>
                )
            },
            {
                heading: "How is my score calculated?",
                content: (
                    <>
                        <p>
                            Your alcohol risk score is calculated using the alcohol screening tool, AUDIT-C. It is used
                            to identify hazardous drinkers or people who have active alcohol use disorders, as well as
                            being a useful tool to tracking your weekly drinking.
                        </p>
                        <p>
                            If you do obtain a score of 8 or higher, then please speak with your doctor as it’s likely
                            that your level of drinking could lead to harm.
                        </p>
                    </>
                )
            }
        ]
    },
    [SMOKING]: {
        title: "Smoking",
        data: [
            {
                heading: "How bad is smoking for you?",
                content: (
                    <>
                        <p>
                            Smoking leads to disease and disability and harms nearly every organ of the body. For every
                            person who dies because of smoking, at least 30 people live with a serious smoking-related
                            illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic
                            obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis.
                            Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the
                            immune system, including rheumatoid arthritis.
                        </p>
                        <p>
                            Second-hand smoke exposure contributes to approximately 41,000 deaths among non-smoking
                            adults and 400 deaths in infants each year. Second-hand smoke causes stroke, lung cancer,
                            and coronary heart disease in adults. Children who are exposed to second-hand smoke are at
                            increased risk for sudden infant death syndrome, acute respiratory infections, middle ear
                            disease, more severe asthma, respiratory symptoms, and slowed lung growth.
                        </p>
                        <p>
                            Source:{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.cdc.gov/tobacco/basic_information/health_effects/index.htm"
                            >
                                CDC
                            </a>
                        </p>
                    </>
                )
            },
            {
                heading: "How long can you live if you smoke?",
                content: (
                    <>
                        <p>
                            The evidence about smoking and longevity is pretty definitive: those who don’t smoke tend to
                            live significantly longer. On average, smokers' life expectancy is 10 years less than
                            non-smokers.
                        </p>
                        <p>
                            However, it has been proven that people who quit before they are 40 years old reduce their
                            risk of death by smoking related disease by 90%. For this to take effect, simply cutting
                            back won’t help, only completely quitting will.
                        </p>
                    </>
                )
            }
        ]
    },
    [HEART_AGE]: {
        title: "Heart Age",
        data: [
            {
                heading: "What should my Heart Age be?",
                content: (
                    <>
                        <p>
                            Heart Age is a way of understanding your risk of cardiovascular disease, such as stroke and
                            heart attack.{" "}
                        </p>
                        <p>
                            Having a Heart Age that is equal to or below your actual age is ideal. If your Heart Age is
                            older than you are, then you have a higher risk of heart disease compared to others your age
                            and gender.
                        </p>
                    </>
                )
            },
            {
                heading: "How is Heart Age calculated?",
                content: (
                    <>
                        <p>Your Heart Age is calculated from the following inputs:</p>
                        &nbsp;&nbsp;• Gender
                        <br />
                        &nbsp;&nbsp;• Age
                        <br />
                        &nbsp;&nbsp;• Body Mass Index
                        <br />
                        &nbsp;&nbsp;• Systolic blood pressure
                        <br />
                        &nbsp;&nbsp;• Treatment for hypertension
                        <br />
                        &nbsp;&nbsp;• Diabetes
                        <br />
                        &nbsp;&nbsp;• Smoking status
                        <p>
                            Each factor contributes various weightings to determine your risk or probability of
                            developing cardiovascular disease in the next ten years.
                        </p>
                        <p>
                            The actual calculation is based on a multivariate algorithm known as the Framingham Risk
                            Function. This is a predictive equation developed using data from the Framingham Heart
                            Study. Originating in 1948, the multi-generational, observational study looks at
                            characteristics that contribute to cardiovascular disease. It expanded to include diverse
                            populations to understand risk factors in different groups.
                        </p>
                        <p>
                            Source:{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.nhlbi.nih.gov/science/framingham-heart-study-fhs"
                            >
                                National Heart, Lung and Blood Institute
                            </a>
                        </p>
                    </>
                )
            },
            {
                heading: "How can I improve my Heart Age?",
                content: (
                    <>
                        <p>
                            Your Heart Age is an overall assessment of your risk factors. By optimising one or more
                            contributing factors you can improve your Heart Age, risk of cardiovascular disease and
                            overall health.
                        </p>
                        <p>Here are some ways that can boost your heart health and lower your heart age:</p>
                        <ol>
                            <li>
                                <p>
                                    Stop smoking
                                    <br /> Smokers are four times more likely to die of heart disease and three times
                                    more likely to die from sudden cardiac death.* Smoking affects the amount of oxygen
                                    available in the blood and damages blood vessel walls that supply blood to your
                                    body.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Know your blood pressure
                                    <br /> Blood pressure tells you how hard your heart is working. People with high
                                    blood pressure are often more at risk of developing heart problems.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Keep your weight in check
                                    <br /> If you’re overweight, your heart has to work harder. Having a healthy weight
                                    will reduce the strain on your heart and lower risk of serious health conditions,
                                    including heart disease, stroke, type 2 diabetes and certain cancers.
                                </p>
                            </li>
                        </ol>

                        <p>
                            Check out our{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://programs.sisuhealthgroup.com/course/heart-health"
                            >
                                Healthy Heart program
                            </a>{" "}
                            to improve your Heart Health knowledge and understanding of risk factors for cardiovascular
                            disease.
                        </p>
                        <p>
                            *Source:{" "}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.heartfoundation.org.au/heart-health-education/smoking-and-your-heart"
                            >
                                Heart Foundation
                            </a>
                        </p>
                    </>
                )
            },
            {
                heading: "Does having a high Heart Age mean I should start taking medication?",
                content: (
                    <>
                        <p>The Heart Age tool is not intended to be used as a diagnostic medical tool.</p>
                        <p>
                            It is important to always talk with your doctor before making any changes to your
                            medications.
                        </p>
                    </>
                )
            },
            {
                heading: "I am below 30 or over 74 years old, what do you mean Heart Age is indicative only?",
                content: (
                    <>
                        <p>You can still use Heart Age to see your calculation of heart disease risk. </p>
                        <p>
                            Please keep in mind that Heart Age is based on data from the Framingham Heart Study which
                            does not include people younger than 30 years and over 74 years, so it is difficult to
                            estimate risk outside the study’s age group.
                        </p>
                    </>
                )
            }
        ]
    },
    [TDEE]: {
        title: "Daily Energy Use",
        data: [
            {
                heading: "How is Daily Energy Use calculated?",
                content: (
                    <>
                        <p>
                            The Katch-Mcardle equation
                            <Text fontSize="13px" as="sup">
                                1
                            </Text>{" "}
                            is used to determine your Basal Metabolic Rate (BMR). This equation uses your most recent
                            weight measurement and lean body mass (derived from your most recent body fat result).
                        </p>
                        <p>
                            Your Daily Energy Use is determined by multiplying your BMR by an activity factor that is
                            most in line with your lifestyle (based on the information you have provided us on the
                            health station). SiSU uses Physical Activity Levels (PALs) developed by the Australian
                            Government National Health and Medical Research Council (NMHRC){" "}
                            <Text fontSize="13px" as="sup">
                                2 3
                            </Text>
                            , adapted from the latest global research on energy expenditure. PALs are an estimation of
                            your ‘average’ level of activity over a 24-hour period, based on your activity level at work
                            and additional exercise outside of work.
                        </p>
                        <p>
                            If you are lactating (breastfeeding), your result and corresponding reference ranges are
                            increased by 2000kJ, to account for the extra energy your body uses during lactation
                            <Text fontSize="13px" as="sup">
                                2
                            </Text>
                            .
                        </p>
                        <p>
                            Basal Metabolic Rate and Daily Energy Use results are based on predictive equations and
                            should be used as a guide only. The information provided by SiSU should not replace any
                            advice given by an accredited health professional, who is familiar with your medical
                            history.
                        </p>
                        <p>References:</p>
                        <ol>
                            <li>
                                Katch V. L, McArdle, W D. 2015, Essentials of exercise physiology: Physiology:
                                Nutrition, Energy, and Human Performance, Wolters Kluwer Health, Baltimore.
                            </li>
                            <li>
                                National Health and Medical Research Council, Nutrient Reference Values for Australia
                                and New Zealand, Dietary Energy
                            </li>
                            <li>National Health and Medical Research Council, Eat for Health, Nutrition Calculators</li>
                        </ol>
                    </>
                )
            },
            {
                heading: "What should my Daily Energy Use be?",
                content: (
                    <>
                        <p>
                            There is no set reference range for what is considered ‘healthy’ or ‘at risk’ for Daily
                            Energy Use and Basal Metabolic Rate (BMR), which is different from other health measurements
                            such as blood pressure or cholesterol.
                        </p>
                        <p>When interpreting your Daily Energy Use results, you should consider two main things:</p>
                        <ul>
                            <li>
                                Your activity level - Your Daily Energy Use is dependent on your BMR and your activity
                                levels. So, for example if you have a Daily Energy Use result that is ‘low’, it means
                                you are not doing enough physical activity.
                            </li>
                            <li>
                                Your weight goal - Your Daily Energy Use results can be used as a tool to assist you
                                achieving or maintaining a weight within the healthy weight range (see your BMI for more
                                information).
                            </li>
                        </ul>
                    </>
                )
            },
            {
                heading: "Can I optimise my metabolism?",
                content: (
                    <>
                        <p>
                            There are a range of factors that affect your Basal Metabolic Rate (BMR). Many of these
                            factors you don’t have control over, such as your age, sex, height and genetics, but you can
                            still make significant changes to optimise your BMR now and as you get older. You can
                            achieve that by doing regular cardiovascular exercise, increasing (and maintaining) your
                            muscle mass and achieving a healthy level of body fat.
                        </p>
                    </>
                )
            },
            {
                heading: "How accurate is Daily Energy Use?",
                content: (
                    <>
                        <p>
                            The Katch-McArdle formula uses your lean tissue mass from your actual body fat reading (from
                            your most recent health check) to determine your BMR, and thus calculate your Daily Energy
                            Use. Other BMR formulas try to ‘predict’ your lean body mass from other factors such as your
                            height, weight, age and gender.
                        </p>
                        <p>
                            Note: SiSU’s Daily Energy Use equation is not applicable for individuals 18 and below, due
                            to additional energy required for growth.
                        </p>
                    </>
                )
            },
            {
                heading: "What is the difference between Kilojoules and Calories?",
                content: (
                    <>
                        <p>
                            Kilojoules and Calories (also known as kilocalories) are two different ways of measuring
                            energy contained in food and energy our bodies use.
                        </p>
                        <p>
                            To convert kilojoules to calories, simply divide kilojoules by 4.2 (1 kilojoule = 0.24
                            calories)
                        </p>
                        <p>
                            It doesn’t really matter which measurement you use and it will likely be influenced by what
                            country you live in. Energy was first measured in calories, but is now officially measured
                            in kilojoules. Nearly all countries have now swapped over to using kilojoules, however the
                            UK and US still use calories (which heavily influences consumers in other parts of the world
                            through food and fitness websites and apps).
                        </p>
                    </>
                )
            }
        ]
    },
    [BODY_COMPOSITION]: {
        title: "What is body composition?",
        data: [
            {
                heading: "What is body composition?",
                content: (
                    <>
                        <p>Your body is made up of two types of mass:</p>
                        <ul>
                            <li>Fat mass which includes essential fat, subcutaneous fat and visceral fat.</li>
                            <li>
                                Non-fat mass (also called lean tissue) which includes bone, water, muscle, organs, and
                                other tissues. Muscle is metabolically active, meaning it burns calories for energy
                                (increasing your metabolism), while fat does not.
                            </li>
                        </ul>
                        A healthy body composition includes a lower percentage of body fat and a higher percentage of
                        muscle mass. Our body composition analysis will show you your body composition breakdown.
                    </>
                )
            },
            {
                heading: "What factors affect body composition?",
                content: (
                    <>
                        <p>Your body composition is influenced by factors you control:</p>
                        <ul>
                            <li>
                                <strong>Diet:</strong> How you eat contributes greatly to your body make up of muscle,
                                fat and water.
                            </li>
                            <li>
                                <strong>Activity:</strong> Being active can reduce body fat and resistance training at
                                least twice a week will help build muscle mass.
                            </li>
                        </ul>
                        <p>Your body composition can also be influenced by factors you can't control:</p>
                        <ul>
                            <li>
                                <strong>Age:</strong> People naturally lose muscle as they age,﻿ if they don't maintain
                                their muscle mass with sufficient resistance training. Muscle loss results in a slower
                                metabolism, reduced balance and mobility.
                            </li>
                            <li>
                                <strong>Genes:</strong> These play a role in whether you are naturally lean or have a
                                tendency to retain fat, including where you store it.
                            </li>
                            <li>
                                <strong>Hormones:</strong> These can influence water retention and body composition.
                            </li>
                            <li>
                                <strong>Sex:</strong> Women naturally have a higher level of body fat and lower level of
                                muscle mass compared to males and therefore have different healthy ranges.
                            </li>
                        </ul>
                    </>
                )
            },
            {
                heading: "How is body composition measured?",
                content: (
                    <>
                        Bioelectrical Impedance Analysis (BIA) is a method of quantifying body composition by
                        introducing an electrical current throughout the body. SiSU Health use this method to measure
                        body composition, which divides your weight into different components, such as Water, Fat Mass
                        and Lean Body Mass.
                        <p>
                            BIA measures impedance by sending low level alternating electrical currents through the body
                            at varying frequencies. Using these different impedance results in conjunction with a user’s
                            biometrics, the SiSU Health Station can output clinically validated results for Body Fat,
                            Skeletal Muscle Mass and Basal Metabolic Rate.
                        </p>
                        <p>
                            The SiSU Health Stations BIA measurement meets IEC 60601-1 requirements including patient
                            auxiliary current and power isolation and is specified for users having up to 50% body fat.
                        </p>
                    </>
                )
            }
        ]
    },
    [MUSCLE_MASS]: {
        data: [
            {
                heading: "What is resistance training?",
                content: (
                    <>
                        <p>
                            Resistance training uses opposing force to build strength and muscle mass. There are many
                            different ways you can strengthen & increase your muscle mass, which are all considered
                            resistance training. Joining a gym will give you a wide variety of equipment, but there are
                            plenty of things you can do at home.
                        </p>
                        <ul>
                            <li>
                                Free weights or medicine balls – you can use any heavy household item such as filled
                                water bottles or packets of rice
                            </li>
                            <li>
                                Your own body weight (eg push ups or tricep dips) – start with push ups against the wall
                                or on your knees
                            </li>
                            <li>
                                Resistance bands - these are relatively cheap to buy and can be used anywhere and will
                                come with instructions and exercise suggestions
                            </li>
                            <li>Weight machines - used for lifting heavier weights, found in most gyms</li>
                        </ul>
                    </>
                )
            },
            {
                heading: "My muscle mass is in the normal range, do I still need to do resistance training?",
                content: (
                    <>
                        <p>
                            Yes! Some people naturally have a good percentage of muscle mass, but it is recommended
                            everyone includes resistance training into their routine to gain important health benefits,
                            which are independent of the benefits of increasing muscle mass. These include:
                        </p>
                        <ul>
                            <li>Reducing injury</li>
                            <li>Improved flexibility, balance, mobility & posture</li>
                            <li>Weight management and lower body fat</li>
                            <li>Improved muscle strength & tone</li>
                            <li>Increased bone density</li>
                            <li>Improved sleep</li>
                            <li>Increased self-esteem & sense of wellbeing</li>
                        </ul>
                    </>
                )
            },
            {
                heading: "What does a low muscle mass result mean and what can I do about it?",
                content: (
                    <>
                        <p>Low muscle mass is associated with:</p>
                        <ul>
                            <li>Speeds up age-related muscle loss</li>
                            <li>Reduces physical ability</li>
                            <li>Increases risk of injury</li>
                            <li>Diabetes</li>
                            <li>Osteoporosis</li>
                        </ul>
                        To increase your skeletal muscle mass and gain the added benefits from resistance training, it
                        is recommended to complete resistance training at least twice per week.
                    </>
                )
            },
            {
                heading: "Is muscle mass important for women?",
                content: (
                    <>
                        Women are at higher risk of Osteoporosis than males, so muscle strength and resistance training
                        are both particularly important for women to lower disease risk. This will assist to reduce bone
                        density losses caused by ageing (which starts at 30 years of age!) and reduce risk of injury.
                    </>
                )
            },
            {
                heading: "What affect does diet have on muscle mass?",
                content: (
                    <>
                        <p>Key dietary considerations for building & maintaining muscle mass:</p>
                        <ul>
                            <li>
                                Eat some quality carbohydrates before and after resistance training so you have enough
                                energy to exercise (E.g. Banana).
                            </li>
                            <li>
                                Spread protein evenly though out the day (include some in each meal and snacks when
                                possible).
                            </li>
                            <li>Unless you are an athlete, you shouldn’t need additional expensive supplements.</li>
                            <li>
                                If you are looking to put on significant muscle mass you will need to consume more
                                energy (by eating more food or changing to more energy-dense foods) and complete
                                additional weight training.
                            </li>
                        </ul>
                    </>
                )
            }
        ]
    }
};

export default FACTS;
