import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledInput } from "./index";
import { DateValidator, Validation } from "@sisuwellness/utilities/Validation";
import { TypeConverter } from "@sisuwellness/utilities/Converters";
import { Box } from "rebass";

const StyledDateContainer = styled.div`
    display: flex;
    justify-content: centre;
`;

const InputContainer = styled(Box)`
    flex: 1 1 auto;
`;

const StyledNumberInput = styled(StyledInput)`
    text-align: center;
    max-width: 185px;
`;

export default class Date extends Component {
    static defaultProps = {
        autoFocus: true,
        dayHasErrors: false,
        monthHasErrors: false,
        yearHasErrors: false
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        autoFocus: PropTypes.bool,
        value: PropTypes.string,
        onValueChange: PropTypes.func.isRequired,

        dayInputRef: PropTypes.object.isRequired,
        monthInputRef: PropTypes.object.isRequired,
        yearInputRef: PropTypes.object.isRequired,

        onDayInputFocus: PropTypes.func,
        onMonthInputFocus: PropTypes.func,
        onYearInputFocus: PropTypes.func
    };

    constructor(props) {
        super(props);

        const state = {
            day: {
                value: "",
                hasErrors: false
            },

            month: {
                value: "",
                hasErrors: false
            },

            year: {
                value: "",
                hasErrors: false
            },

            value: ""
        };

        const dateValidator = new Validation();
        dateValidator.addValidator(new DateValidator());

        if (dateValidator.validate(props.value, true).length === 0) {
            let date = TypeConverter.stringToDate(props.value, "YYYY-MM-DD");

            state.day.value = date.format("DD");
            state.month.value = date.format("MM");
            state.year.value = date.format("YYYY");
            state.value = props.value;
        }

        this.dateValidator = dateValidator;
        this.state = state;

        this.onDayInputFocus = this.onDayInputFocus.bind(this);
        this.onMonthInputFocus = this.onMonthInputFocus.bind(this);
        this.onYearInputFocus = this.onYearInputFocus.bind(this);

        this.handleDayInputChange = this.handleDayInputChange.bind(this);
        this.handleMonthInputChange = this.handleMonthInputChange.bind(this);
        this.handleYearInputChange = this.handleYearInputChange.bind(this);
    }

    onDayInputFocus(e) {
        if (this.props.onDayInputFocus) {
            this.props.onDayInputFocus(e);
        }
    }

    onMonthInputFocus(e) {
        if (this.props.onMonthInputFocus) {
            this.props.onMonthInputFocus(e);
        }
    }

    onYearInputFocus(e) {
        if (this.props.onYearInputFocus) {
            this.props.onYearInputFocus(e);
        }
    }

    /**
     * Handles the day selection input change
     * @param event
     */
    handleDayInputChange(event) {
        const day = event.target.value;
        const state = this.state;

        if (isNaN(day)) {
            this.setState(state);
            return;
        }

        if (day.length > 2) {
            this.props.monthInputRef.current.focus();
            this.setState(state);
            return;
        }

        state.day.value = day;

        if (day.length === 0) {
            state.month.hasErrors = false;
            this.setState(state);
            return;
        }

        // validate the day with dummy year/month values
        const testDate = [2020, 12, day].join("-");
        if (this.dateValidator.validate(testDate, false).length === 0) {
            state.day.value = day;
            state.day.hasErrors = false;

            this.props.monthInputRef.current.focus();
        } else {
            state.day.hasErrors = true;
        }

        this.setState(state);
        this.onValueChange(day, null, null);
    }

    /**
     * Handles the month selection input change
     * @param event
     */
    handleMonthInputChange(event) {
        const month = event.target.value;
        const state = this.state;

        if (isNaN(month)) {
            this.setState(state);
            return;
        }

        if (month.length > 2) {
            this.props.yearInputRef.current.focus();
            this.setState(state);
            return;
        }

        state.month.value = month;

        if (month.length === 0) {
            state.month.hasErrors = false;
            this.setState(state);
            return;
        }

        // validate the month with dummy year/day values
        const testDate = [2020, month, 10].join("-");
        if (this.dateValidator.validate(testDate, false).length === 0) {
            state.month.value = month;
            state.month.hasErrors = false;

            this.props.yearInputRef.current.focus();
        } else {
            state.month.hasErrors = true;
        }

        this.setState(state);
        this.onValueChange(null, month, null);
    }

    /**
     * Handles the year selection input change
     * @param event
     */
    handleYearInputChange(event) {
        const year = event.target.value;
        const state = this.state;

        if (isNaN(year) || year.length > 4) {
            this.setState(state);
            return;
        }

        state.year.value = year;

        if (year.length === 0) {
            state.month.hasErrors = false;
            this.setState(state);
            return;
        }

        // validate the year with dummy month/day values
        const testDate = [year, 12, 10].join("-");
        if (this.dateValidator.validate(testDate, true).length === 0) {
            state.year.hasErrors = false;
            this.props.yearInputRef.current.blur();
        } else {
            state.year.hasErrors = true;
        }

        this.setState(state);
        this.onValueChange(null, null, year);
    }

    /**
     * Called on each input
     * If all values exist, trigger the onValueChange callback
     */
    onValueChange(day, month, year) {
        day = day || this.state.day.value;
        month = month || this.state.month.value;
        year = year || this.state.year.value;

        const valid =
            year.length === 4 &&
            month.length === 2 &&
            day.length === 2 &&
            !this.state.day.hasErrors &&
            !this.state.month.hasErrors &&
            !this.state.year.hasErrors;
        if (valid) {
            const dateString = [year, month, day].join("-");
            this.props.onValueChange(dateString);
            this.setState({ value: dateString });
        } else {
            this.props.onValueChange("");
        }
    }

    render() {
        return (
            <StyledDateContainer>
                <InputContainer key={this.props.id + "-day-container"} mr={"1em"}>
                    <StyledNumberInput
                        data-testid={"date-input-day"}
                        key={this.props.id + "-day"}
                        id={this.props.id + "-day"}
                        ref={this.props.dayInputRef}
                        placeholder={"DD"}
                        type={"text"}
                        hasErrors={this.state.day.hasErrors}
                        value={this.state.day.value}
                        onChange={() => {}}
                        onInput={e => this.handleDayInputChange(e)}
                        autoFocus={this.props.autoFocus}
                        onFocus={e => this.onDayInputFocus(e)}
                        autoComplete={"off"}
                    />
                </InputContainer>

                <InputContainer key={this.props.id + "-month-container"} mr={"1em"}>
                    <StyledNumberInput
                        data-testid={"date-input-month"}
                        key={this.props.id + "-month"}
                        id={this.props.id + "-month"}
                        ref={this.props.monthInputRef}
                        placeholder={"MM"}
                        type={"text"}
                        hasErrors={this.state.month.hasErrors}
                        value={this.state.month.value}
                        onChange={() => {}}
                        onInput={e => this.handleMonthInputChange(e)}
                        onFocus={e => this.onMonthInputFocus(e)}
                        autoComplete={"off"}
                    />
                </InputContainer>

                <InputContainer key={this.props.id + "-year-container"}>
                    <StyledNumberInput
                        data-testid={"date-input-year"}
                        key={this.props.id + "-year"}
                        id={this.props.id + "-year"}
                        ref={this.props.yearInputRef}
                        placeholder={"YYYY"}
                        type={"text"}
                        hasErrors={this.state.year.hasErrors}
                        value={this.state.year.value}
                        onChange={() => {}}
                        onInput={e => this.handleYearInputChange(e)}
                        onFocus={e => this.onYearInputFocus(e)}
                        autoComplete={"off"}
                    />
                </InputContainer>
            </StyledDateContainer>
        );
    }
}
