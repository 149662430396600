import React from "react";
import PropTypes from "prop-types";

import { Image, Text } from "rebass";
import styled from "styled-components";
import Lottie from "react-lottie";

// -----------------------------------------------------------------------

import Flex from "components/Flex";
import { ModalBody } from "components/NotificationModal";
import { PORTAL_IMAGE } from "constants/imagePath";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import animationData from "../../../assets/lottie/fireworks.json";
import { media } from "@sisuwellness/web-components/themes";

// -----------------------------------------------------------------------

const textSpecs = [
    "Heart Age results unlocked for all full station Health Checks",
    "Access to Heart and Stroke Risk calculators",
    "Access additional tips to support weight management",
    "Premium programs to keep you on track"
];

const StyledContainer = styled(Flex)`
    left: 50%;
    bottom: 0px;
    position: absolute;
    transform: translateX(-50%);

    ${media.mobile`
        left: 0px;
        transform: unset;
    `}
`;

export const CongratulationsModal = ({ close }) => {
    const spinnerOptions = {
        loop: false,
        autoplay: true,
        animationData
    };

    return (
        <ModalBody p={["32px 24px", "40px"]} position="relative" data-testid="sisu-plus-congratulations">
            <Flex fd="column" jc="space-between" gap={["16px", "24px"]} position="relative" zIndex="2">
                <Text fontSize={["20px", "28px"]} color="hpPrimaryPurple">
                    Congratulations, you’re a SiSU Plus member!
                </Text>
                <Flex fd="column" gap="8px">
                    {textSpecs.map((text, ix) => (
                        <Flex key={ix} gap="8px" ai="center">
                            <Image size="24px" src={`${PORTAL_IMAGE}/premium/green_tick.svg`} alt="green_tick.svg" />
                            <Text fontSize="15px" color="hpGrayishBlueText">
                                {text}
                            </Text>
                        </Flex>
                    ))}
                </Flex>

                <Flex ai="center" jc="center">
                    <PrimaryButton
                        mx="auto"
                        display="block"
                        onClick={close}
                        mt={["16px", "8px"]}
                        width={["100%", "290px"]}
                    >
                        Get started
                    </PrimaryButton>
                </Flex>
            </Flex>

            <StyledContainer size="100%">
                <Lottie options={spinnerOptions} isStopped={false} isPaused={false} />
            </StyledContainer>
        </ModalBody>
    );
};

CongratulationsModal.propTypes = {
    close: PropTypes.func
};
