import React from "react";
import PropTypes from "prop-types";

import { Flex, Image } from "rebass";
import { useHistory } from "react-router";

// ---------------------------------------------------------------------------------------------------------

import { HealthCardIcon, HealthCardRecommendation, HealthCardStatus } from "components/HealthCard/atomicComponents";
import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { OutcomeCardBtnSecondary } from "components/CommonComponents/Button";
import { getCtaText } from "utilities/premium";
import SummaryCard from "components/Cards/SummaryCard";
import { PORTAL_IMAGE } from "constants/imagePath";
import routes from "constants/routes";
import { Guidelines } from "@sisuwellness/utilities";
import { theme } from "@sisuwellness/web-components";
import { fixedNumber } from "utilities/commonUtils";

import muscleMassRecommendations from "./muscleMassRecommendations";
import { BlurContainer, StyledMetricCardContainer } from "../../styled";

// ---------------------------------------------------------------------------------------------------------

export const MuscleMassDashboardCard = ({ isPremium, value = {}, ...rest }) => {
    const history = useHistory();

    const { muscleMass, gender, age } = value;

    let muscleMassValue = muscleMass,
        rating = {},
        color = "";

    if (!isPremium) {
        // Dummy Value for locked premium card state
        muscleMassValue = 12;
    } else {
        rating = Guidelines.MuscleMass.buildSisuGuideline(muscleMass, gender, age);
        color = theme.guidelines.muscleMass[rating.key]?.sisuPortal.hex;
    }

    const handleOnClick = () => history.push(isPremium && !muscleMass ? routes.findHealthStations : routes.muscleMass);

    return (
        <SummaryCard
            height="286px"
            scale="small"
            flexBasis={["162px", "772px", "264px"]}
            cardHeader={
                <Flex width="100%" justifyContent="center">
                    <Heading mb="16px" fontSize="15px">
                        Skeletal Muscle
                    </Heading>
                </Flex>
            }
            cardFooter={
                <OutcomeCardBtnSecondary
                    width="auto"
                    px="16px"
                    minWidth="100px"
                    id="muscle-mass"
                    onClick={handleOnClick}
                    data-testid="muscle-mass-cta"
                    fontSize="14px"
                >
                    {getCtaText(muscleMassValue, isPremium)}
                </OutcomeCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" alignItems="center" isPremium={isPremium}>
                    <HealthCardStatus
                        width="114px"
                        height="26px"
                        indicator={{ bg: color, label: rating.label }}
                        fontProps={{ fontSize: 12, fontWeight: "400", mt: 0 }}
                    />
                    <Flex mt="16px" justifyContent="space-between" alignItems="center">
                        <HealthCardIcon icon="/images/healthHub/muscleMass.svg">
                            {muscleMassValue ? `${fixedNumber(muscleMassValue)}%` : "?"}
                        </HealthCardIcon>
                    </Flex>
                    {!isPremium && (
                        <BlurContainer mt="8px" top="19%" minHeight="150px" alignItems="center" justifyContent="center">
                            <Image size="120px" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} alt="premium-lock" />
                        </BlurContainer>
                    )}
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

const MuscleMassOutcomeCard = ({ isPremium, value = {}, showRecommendations = false, ...rest }) => {
    const history = useHistory();

    const { muscleMass, gender, age } = value;

    let muscleMassValue = muscleMass,
        rating = {},
        color = "";

    if (!isPremium) {
        // Dummy Value for locked premium card state
        muscleMassValue = 12;
    } else {
        rating = Guidelines.MuscleMass.buildSisuGuideline(muscleMass, gender, age);
        color = theme.guidelines.muscleMass[rating.key]?.sisuPortal.hex;
    }

    const handleOnClick = () => history.push(isPremium && !muscleMass ? routes.findHealthStations : routes.muscleMass);

    const recommendationText =
        muscleMassValue && isPremium
            ? muscleMassRecommendations[rating.key]
            : "Complete a full health check on a SiSU Health Station to calculate your full body composition.";

    return (
        <SummaryCard
            py="24px"
            height={showRecommendations ? "324px" : "260px"}
            cardHeader={
                <Flex mb="8px" justifyContent="space-between">
                    <Heading>Skeletal Muscle</Heading>
                </Flex>
            }
            cardFooter={
                <OutcomeCardBtnSecondary id="muscle-mass" onClick={handleOnClick} data-testid="muscle-mass-cta">
                    {getCtaText(muscleMassValue, isPremium)}
                </OutcomeCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" isPremium={isPremium}>
                    <Flex width="100%" justifyContent="space-between" alignItems="center">
                        <HealthCardIcon icon="/images/healthHub/muscleMass.svg">
                            {muscleMassValue ? `${fixedNumber(muscleMassValue)}%` : "?"}
                        </HealthCardIcon>
                        <HealthCardStatus indicator={{ bg: color, label: rating.label }} />
                    </Flex>
                    {!isPremium && (
                        <BlurContainer
                            mt="8px"
                            top="19%"
                            alignItems="center"
                            justifyContent="center"
                            minHeight={showRecommendations ? "183px" : "124px"}
                        >
                            <Image size="130px" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} alt="premium-lock" />
                        </BlurContainer>
                    )}
                    {showRecommendations && (
                        <HealthCardRecommendation
                            mt="16px"
                            fontSize="13px"
                            textAlign="left"
                            text={recommendationText}
                        />
                    )}
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

MuscleMassOutcomeCard.propTypes = {
    title: PropTypes.string,
    isPremium: PropTypes.bool,
    value: PropTypes.object,
    recommendation: PropTypes.string,
    indicator: PropTypes.shape({ label: PropTypes.string }),
    showRecommendations: PropTypes.bool
};
MuscleMassDashboardCard.propTypes = {
    title: PropTypes.string,
    isPremium: PropTypes.bool,
    value: PropTypes.object,
    indicator: PropTypes.shape({ label: PropTypes.string })
};

export default MuscleMassOutcomeCard;
