import React from "react";
import PropTypes from "prop-types";

import { Form, Formik } from "formik";
import InputField from "../../Components/InputField";
import { Text, Flex } from "rebass";
import { position } from "styled-system";
import styled from "styled-components";
import { FIELD_VALIDATION, validationSchema } from "../../utilities/validationSchemas";
import { isEmpty } from "ramda";
import MediaQuery from "../../../../MediaQuery";
import theme from "../../../../../themes";

const CustomText = styled(Text)`
    transform: translateY(-50%);
    ${position};
`;

const StyledSpan = styled.span`
    color: #877aa4;
`;

const MetricFormForWeight = ({ onSubmit, value, setErrors, isRequired }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            initialValues={{ weightInKg: `${value}`, isRequired }}
            validationSchema={validationSchema(FIELD_VALIDATION.METRIC.WEIGHT)}
        >
            {({ values, handleBlur, handleSubmit, errors, touched, handleChange }) => {
                setErrors(!touched.weightInKg && isEmpty(errors) && !values.weightInKg ? "error" : errors);
                return (
                    <Form onSubmit={handleSubmit} id="field-form" data-testid="form">
                        <InputField
                            width={["346px", "432px"]}
                            inputProps={{
                                maxLength: 7,
                                name: "weightInKg",
                                placeholder: "(kg)",
                                onBlur: handleBlur,
                                onChange: handleChange,
                                value: values.weightInKg
                            }}
                            error={errors.weightInKg}
                        >
                            <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                {values.weightInKg} {values.weightInKg && <StyledSpan>&nbsp;(kg)</StyledSpan>}
                            </CustomText>
                        </InputField>
                        <MediaQuery devices={["desktop"]}>
                            <Text color={theme.colours.blackTints300.hex} fontSize="13px" textAlign="left">
                                press ENTER ↵
                            </Text>
                        </MediaQuery>
                    </Form>
                );
            }}
        </Formik>
    );
};
MetricFormForWeight.propTypes = {
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any,
    isRequired: PropTypes.bool
};

const ImperialFormForWeight = ({ onSubmit, value, setErrors, isRequired }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            initialValues={{ weightInSt: `${value[0]}`, weightInLbs: `${value[1]}`, isRequired }}
            validationSchema={validationSchema(FIELD_VALIDATION.IMPERIAL.WEIGHT)}
        >
            {({ values, handleBlur, handleSubmit, handleChange, errors, touched }) => {
                setErrors(!touched.weightInSt && isEmpty(errors) && !values.weightInSt ? "error" : errors);
                return (
                    <Form onSubmit={handleSubmit} id="field-form" data-testid="form">
                        <Flex justifyContent="space-between" width={["346px", "432px"]}>
                            <InputField
                                width={["165px", "208px"]}
                                inputProps={{
                                    maxLength: 7,
                                    name: "weightInSt",
                                    placeholder: "(st)",
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                    value: values.weightInSt
                                }}
                                error={errors.weightInSt}
                            >
                                <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                    {values.weightInSt} {values.weightInSt && <StyledSpan>&nbsp;(st)</StyledSpan>}
                                </CustomText>
                            </InputField>

                            <InputField
                                width={["165px", "208px"]}
                                inputProps={{
                                    maxLength: 7,
                                    name: "weightInLbs",
                                    placeholder: "(lbs)",
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                    value: values.weightInLbs
                                }}
                                error={errors.weightInLbs}
                            >
                                <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                    {values.weightInLbs} {values.weightInLbs && <StyledSpan>&nbsp;(lbs)</StyledSpan>}
                                </CustomText>
                            </InputField>
                        </Flex>
                        <MediaQuery devices={["desktop"]}>
                            <Text color={theme.colours.blackTints300.hex} fontSize="13px" textAlign="right">
                                press ENTER ↵
                            </Text>
                        </MediaQuery>
                    </Form>
                );
            }}
        </Formik>
    );
};
ImperialFormForWeight.propTypes = {
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any,
    isRequired: PropTypes.bool
};

export { MetricFormForWeight, ImperialFormForWeight };
