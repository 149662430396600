import withAuthentication from "@sisuwellness/connected-components/components/withAuthentication";
import APIClient from "utilities/APIClient";
import AuthUtil from "utilities/auth";
import routes from "constants/routes";

export default withAuthentication({
    APIClient,
    AuthUtil,
    authRoles: [],
    authRoutes: {
        root: routes.root,
        login: routes.login
    },
    defaultRoute: routes.healthHub,
    extraRoutes: [routes.findHealthStations, routes.shaneWarneLegacyHeartTest, routes.swlhtEmbed, routes.notFound] // Extra routes to skip redirections for specified routes
});
