import React, { useState } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Flex, Image } from "rebass";
import { useHistory } from "react-router";
import { Form, Formik, Field } from "formik";
import { getFlagPath } from "utilities/flag";

// --------------------------------------------------------------------------

import routes from "constants/routes";
import PortalModal from "components/PortalModal";
import Spinner from "components/spinner";
import withDimensions from "components/HOC/withDimensions";
import InputField from "components/InputField";
import { isEmpty } from "lodash-es";
import { validateSchema } from "./formValidation";
import { Margin } from "components/styled-components";
import { PrimaryButton, SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import styled from "styled-components";

// --------------------------------------------------------------------------

export const FlagCode = styled.div`
    display: flex;
    align-items: center;
`;

const StyledImage = styled(Image)`
    width: 45px;
    height: 30px;
    object-fit: cover;
`;

const AddressCardForm = ({ dimension, onSubmit }) => {
    const history = useHistory();
    const [width] = dimension;
    const initialValue = useSelector(state => state.auth.citizen);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const defaultCountryCode = initialValue.userProfile.countryCode;
    const [countryCode, setCountryCode] = useState(defaultCountryCode);

    const availableCountries = initialValue.availableCountries || [];
    const availableCountriesOption = availableCountries.map(country => {
        return { value: country.alpha2, label: country.name };
    });

    const initialValues = {
        postalCode: initialValue.userProfile.postalCode || "",
        countryCode: initialValue.userProfile.countryCode
    };

    const Flag = (
        <FlagCode>
            <StyledImage src={getFlagPath(countryCode).src} alt={getFlagPath(countryCode).alt} />
        </FlagCode>
    );

    return (
        <Formik
            initialValues={{ ...initialValue.userProfile, ...initialValues }}
            validationSchema={validateSchema({ countryCode })}
            onSubmit={onSubmit}
        >
            {({ values, handleBlur, handleSubmit, setFieldValue, handleChange, errors, touched, isSubmitting }) => {
                const getCountryList = Object.values(availableCountriesOption).map(country => country.label);

                const getCurrentCountry = Object.values(availableCountriesOption)
                    .filter(country => country.value === values.countryCode)
                    .map(country => country.label)[0];

                const getCountryCode = item =>
                    Object.values(availableCountriesOption)
                        .filter(country => country.label === item)
                        .map(country => country.value)[0];
                if (values.postalCode === null) {
                    setFieldValue("postalCode", "");
                }

                const isButtonDisabled =
                    initialValue.userProfile.postalCode === values.postalCode &&
                    initialValue.userProfile.countryCode == values.countryCode &&
                    initialValue.userProfile.mobileNumber === values.mobileNumber;
                const backButton = () => {
                    if (isButtonDisabled) {
                        return history.replace(routes.myAccount.personalDetails);
                    } else {
                        return setIsModalOpen(true);
                    }
                };
                return (
                    <>
                        <Form id="address-form" onSubmit={handleSubmit} style={{ gridColumn: "span 2" }}>
                            <Margin width={["50%", "", "100%"]} mTop={["50px"]}>
                                <Field name="countryCode" onBlur={handleBlur}>
                                    {() => (
                                        <Spinner
                                            tag="countryCode"
                                            data-testid="select-country"
                                            data={getCountryList}
                                            fontFamily="GT Walsheim Pro"
                                            defaultValue={{
                                                countryCode: getCurrentCountry
                                            }}
                                            selectCallback={(_, item) => {
                                                setCountryCode(getCountryCode(item));
                                                setFieldValue("countryCode", getCountryCode(item));
                                                const { countryCode, postalCode } = initialValue.userProfile;
                                                const tempPostalCode =
                                                    getCountryCode(item) === countryCode ? postalCode : "";
                                                setFieldValue("postalCode", tempPostalCode);
                                            }}
                                        >
                                            Country
                                        </Spinner>
                                    )}
                                </Field>
                                {
                                    <Margin mTop={["44px"]}>
                                        <InputField
                                            label="Postcode"
                                            fontFamily="GT Walsheim Pro"
                                            inputProps={{
                                                name: "postalCode",
                                                onChange: handleChange,
                                                placeholder: "Postcode",
                                                onBlur: handleBlur,
                                                value: values.postalCode,
                                                errors: errors.postalCode,
                                                "data-testid": "postalCode"
                                            }}
                                            error={touched.postalCode ? errors.postalCode : ""}
                                        />
                                    </Margin>
                                }
                                <Margin mTop={["44px"]}>
                                    <InputField
                                        label="Mobile Number"
                                        fontFamily="GT Walsheim Pro"
                                        inputProps={{
                                            name: "mobileNumber",
                                            onChange: _event => {
                                                if (!Number.isFinite(parseInt(_event.target.value))) return;
                                                handleChange(_event);
                                            },
                                            value: String(values.mobileNumber ? values.mobileNumber : ""),
                                            onBlur: handleBlur
                                        }}
                                        leftChild={Flag}
                                        error={errors.mobileNumber}
                                    />
                                </Margin>
                            </Margin>
                            <Margin mTop={["60px"]}>
                                <Flex justifyContent="space-between" pr={["0px", "20px"]} pl={["0px", "20px"]}>
                                    <SecondaryButton
                                        width={width < 800 ? "138px" : undefined}
                                        type="button"
                                        onClick={backButton}
                                    >
                                        Back
                                    </SecondaryButton>
                                    <PrimaryButton
                                        width={width < 800 ? "138px" : ""}
                                        type="submit"
                                        disabled={!isEmpty(errors) || isButtonDisabled || isSubmitting}
                                    >
                                        Save
                                    </PrimaryButton>
                                </Flex>
                            </Margin>
                        </Form>
                        <PortalModal closeModal={() => setIsModalOpen(false)} modalIsOpen={isModalOpen} />
                    </>
                );
            }}
        </Formik>
    );
};

AddressCardForm.propTypes = {
    dimension: PropTypes.arrayOf(PropTypes.number).isRequired,
    onSubmit: PropTypes.func
};

export default withDimensions(AddressCardForm);
