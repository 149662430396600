import React from "react";
import PropTypes from "prop-types";

import { Flex, Image, Text } from "rebass";
import { useHistory } from "react-router";

// ---------------------------------------------------------------------------------------------------------

import { HealthCardRecommendation, HealthCardStatus } from "components/HealthCard/atomicComponents";
import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { OutcomeCardBtnSecondary } from "components/CommonComponents/Button";
import { getCtaText, getHeartAgeRecommendation } from "utilities/premium";
import SummaryCard from "components/Cards/SummaryCard";
import { theme } from "@sisuwellness/web-components";
import { PORTAL_IMAGE } from "constants/imagePath";
import routes from "constants/routes";

import HeartAgeIcon from "../../icon";
import { PositionedContainer, StyledText, BlurContainer, StyledMetricCardContainer } from "../../styled";

// ---------------------------------------------------------------------------------------------------------

const HeartAgeOutcomeCard = ({ title, indicator = {}, isPremium, value, ...rest }) => {
    const { label } = indicator;

    const isHeartAgeFinite = Number.isFinite(value);
    const history = useHistory();

    const rating = isPremium && label?.toLowerCase();
    const color = theme.guidelines.HeartAge[rating]?.sisuPortal?.hex;

    const handleOnClick = () =>
        history.push(isPremium && !isHeartAgeFinite ? routes.findHealthStations : routes.heartAge);

    return (
        <SummaryCard
            py="24px"
            height="324px"
            cardHeader={
                <Flex mb="24px" justifyContent="space-between">
                    <Heading>{title ?? "Heart Age"}</Heading>
                    {isPremium && (
                        <Heading fontSize="13px" fontWeight="bold" opacity="0.7">
                            Premium
                        </Heading>
                    )}
                </Flex>
            }
            cardFooter={
                <OutcomeCardBtnSecondary onClick={handleOnClick} data-testid="heartage-cta" id="heart-age">
                    {getCtaText(value, isPremium)}
                </OutcomeCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" isPremium={isPremium}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <PositionedContainer size="90px" color="hpPrimaryPurple" stroke={isHeartAgeFinite ? color : ""}>
                            <HeartAgeIcon />
                            <StyledText fontSize="20px" fontWeight="intermediate" textAlign="center">
                                <Text as="span">{isPremium ? value ?? "?" : "?"}</Text>
                                <Text as="span" fontFamily="openSans" fontSize="12px" mt="-4px">
                                    Years
                                </Text>
                            </StyledText>
                        </PositionedContainer>

                        {isHeartAgeFinite && (
                            <HealthCardStatus width="85px" height="40px" indicator={{ bg: color, label }} />
                        )}
                    </Flex>
                    {!isPremium && (
                        <BlurContainer top="64px" height="180px" alignItems="center" justifyContent="center">
                            <Image size="130px" alt="premium-lock" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} />
                        </BlurContainer>
                    )}
                    <HealthCardRecommendation
                        fontSize="13px"
                        textAlign="left"
                        text={getHeartAgeRecommendation(rating)}
                    />
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

HeartAgeOutcomeCard.propTypes = {
    title: PropTypes.string,
    isPremium: PropTypes.bool,
    value: PropTypes.number,
    indicator: PropTypes.shape({ label: PropTypes.string })
};

export default HeartAgeOutcomeCard;
