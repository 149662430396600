import { pathOr } from "ramda";

import { theme } from "@sisuwellness/web-components";
import {
    isMobileView,
    createLegends,
    calculateMinMax,
    createYGridValues,
    flattenPointValues,
    createPlottableDataPoints,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { BODY_FAT } from "constants/trend-charts";
import { fixedNumber } from "utilities/commonUtils";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: false,
    key: ["bodyFatPercent"],
    additionalKeys: ["age", "gender", "rating", "unit"]
};

/**
 * Update data with rating
 * @param {object} data
 * @return {{ rating: { key: string, label: string, ranges: object } }}
 */
const bodyFatRatingCalc = data => {
    if (!(data.age && data.gender && data.bodyFatPercent)) return { bodyFatPercent: null };
    const { guideline, currentValue } = calculatePointColor(BODY_FAT, data);

    return {
        ...data,
        unit: "%",
        bodyFatPercent: fixedNumber(currentValue),
        rating: { key: guideline?.key, label: guideline?.label, ranges: guideline?.ranges }
    };
};

const plot = {
    unit: () => "Body Fat (%)",

    color: ({ rating } = {}) => theme.guidelines.bodyFat[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = [], settings) => {
        const points = createPlottableDataPoints(data => bodyFatRatingCalc(data, settings))(
            healthChecks,
            METRIC_FIELDS.key,
            METRIC_FIELDS.additionalKeys
        );

        return points;
    },

    yRange: (points = []) => {
        const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(0, maximum, { parts: 5, rounded: METRIC_FIELDS.rounded }).map(num =>
            num.toFixed(1)
        );

        return {
            min: 0,
            yScaleRanges: ranges,
            max: Number(ranges[ranges.length - 1])
        };
    },

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.BODY_FAT.themeKey,
            "Body Fat"
        ),

    historyProps: (filteredPoints = [], latestHealthCheckData = {}) => {
        const { age, gender, bodyFatPercent } = latestHealthCheckData;
        const { rating } = bodyFatRatingCalc({ age, gender, bodyFatPercent });

        const meanAge = calculateMeanValue("age", filteredPoints[0]);
        const meanBodyFatPercent = calculateMeanValue("bodyFatPercent", filteredPoints[0]);

        const { rating: meanRating } = bodyFatRatingCalc({ age: meanAge, bodyFatPercent: meanBodyFatPercent, gender });

        return {
            unit: "%",
            value: bodyFatPercent ?? "-",
            meanValue: fixedNumber(meanBodyFatPercent),
            valueColor: theme.guidelines.bodyFat[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.bodyFat[(meanRating?.key)]?.sisuPortal?.hex
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
