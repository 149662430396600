import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Flex, Text, Box } from "rebass";

// ------------------------------------------------------------------------------------------------

import CardStack from "components/Cards/CardStack";
import { CHART_FILTERS } from "constants/trend-charts";
import { formatDate } from "utilities/commonUtils";

// ------------------------------------------------------------------------------------------------

const StyledCard = styled(Flex)`
    border-radius: 5px;
    background: #f9fbff;
    align-items: center;
`;

const StyledBox = styled(Box)`
    border: 0px;
`;

// ------------------------------------------------------------------------------------------------

const OutcomeHistory = ({ unit, value, filter, meanColor, valueColor, meanValue, lastMeasurementDate, ...rest }) => {
    const [{ width } = {}, setMeasurementWidth] = useState();

    const mapCards = [
        {
            value: formatDate(lastMeasurementDate, "DD/MM/YYYY"),
            title: "Last Measurement Date:"
        },
        {
            unit,
            value: value,
            showBar: true,
            color: valueColor,
            title: "Last Measurement:"
        },
        {
            unit,
            value: meanValue,
            showBar: true,
            color: meanColor,
            title: `${CHART_FILTERS[filter]} Average:`
        }
    ];

    return (
        <CardStack gap={["8px", "16px"]} {...rest}>
            {mapCards.map(({ title, value, showBar, color, unit }, ix) => (
                <StyledCard
                    px="16px"
                    key={ix}
                    flexGrow={1}
                    flexBasis={["346px", "240px"]}
                    minHeight={["56px", "94px", "77px"]}
                    justifyContent={["flex-start", "center"]}
                    data-testid="history-overview-block"
                >
                    <StyledCard justifyContent="center" flexWrap="wrap">
                        <Text mr="16px" color="blackTints700" fontSize={["13px", "16px"]} fontWeight="light">
                            {title}
                        </Text>
                        <div>
                            <Text fontSize={["17px", "20px"]} ref={setMeasurementWidth}>
                                {value} {unit}
                            </Text>
                            {showBar && <StyledBox as="hr" height="2px" width={width} bg={color ?? "black"} />}
                        </div>
                    </StyledCard>
                </StyledCard>
            ))}
        </CardStack>
    );
};

OutcomeHistory.propTypes = {
    filter: PropTypes.string,
    meanColor: PropTypes.string,
    valueColor: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.any,
    meanValue: PropTypes.any,
    lastMeasurementDate: PropTypes.string
};

export default OutcomeHistory;
