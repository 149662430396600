import React from "react";
import PropTypes from "prop-types";

import { Text, Box } from "rebass";

// ------------------------------------------------------------------------------------------------

// import { useUserHealthCheckData } from "views/BodyComposition";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import { SectionHeaders } from "views/HeartAge/commonElements";
import { pickAttribute } from "utilities/commonUtils";
import OutcomeHistory from "components/Templates/OutcomeHistory";
import { LineChart } from "components/Templates/TrendChart";
import mmProps from "./mmProps";
import { useUserHealthCheckData } from "views/MuscleMass";

// ------------------------------------------------------------------------------------------------

const MuscleMassHistory = props => {
    const { latestHealthCheck, healthChecks } = useUserHealthCheckData();
    const lastMeasurementDate = pickAttribute(["updatedAt"], latestHealthCheck);

    return (
        <Box>
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    Your history
                </Text>
            </SectionHeaders>

            <HeadingLabel fontSize="22px" mt="24px">
                Your Skeletal Muscle History
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px" mb="16px">
                An analytical overview of your health over time.
            </NormalLabel>

            <LineChart
                healthChecks={healthChecks}
                latestHealthCheckData={latestHealthCheck}
                {...mmProps}
                data-testid="history-muscle-mass"
            >
                {({ filter, historyProps }) => (
                    <OutcomeHistory
                        pb="24px"
                        filter={filter}
                        {...historyProps}
                        lastMeasurementDate={lastMeasurementDate}
                    />
                )}
            </LineChart>
        </Box>
    );
};

MuscleMassHistory.propTypes = { setWeightGoal: PropTypes.func };

export default MuscleMassHistory;
