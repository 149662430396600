import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Box } from "rebass";
import { toPairs } from "ramda";

import Flex from "components/Flex";
import { theme } from "@sisuwellness/web-components";
import { hexToRgba } from "utilities/commonUtils";

const StyledHorizontalRow = styled(Box)`
    width: 100%;
    height: 34px;
    border-radius: 5px;
`;

export const StyledNavigation = styled(Flex)`
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 15px;
    border-radius: 3px;
    color: ${({ theme }) => theme.colours.hpPrimaryPurple.hex};
    background: ${({ isActive }) => (isActive ? "white" : "transparent")};
    box-shadow: ${({ isActive }) => (isActive ? "0 1px 4px 0 rgba(55, 33, 104, 0.25)" : "unset")};
`;

export const TabNavigation = ({ navigationKeyValuePairs, currentKey, setCurrentKey, ...rest }) => {
    const handleOnTabClick = key => setCurrentKey(key);

    const handleNavigation = ([key, value], ix) => {
        const isActive = currentKey === key;
        return (
            <StyledNavigation
                key={ix}
                ai="center"
                jc="center"
                isActive={isActive}
                color="hpPrimaryPurple"
                onClick={() => handleOnTabClick(key)}
            >
                {value}
            </StyledNavigation>
        );
    };

    return (
        <StyledHorizontalRow
            py="3px"
            px="4px"
            data-testid="trend-filter"
            bg={hexToRgba(theme.colours.hpPurple200.hex, 0.5)}
            {...rest}
        >
            <Flex size="100%" gap="4px">
                {toPairs(navigationKeyValuePairs).map(handleNavigation)}
            </Flex>
        </StyledHorizontalRow>
    );
};

TabNavigation.propTypes = {
    ...StyledHorizontalRow.propTypes,
    navigationKeyValuePairs: PropTypes.object,
    currentKey: PropTypes.string,
    setCurrentKey: PropTypes.func
};
