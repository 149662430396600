import React from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------------------------

import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import Flex from "components/Flex";
import { theme } from "@sisuwellness/web-components";

// ----------------------------------------------------------------------------------------------

const Legend = props => (
    <Flex
        ai="center"
        jc="center"
        flexWrap="wrap"
        p="0px 24px 24px"
        fd={["column", "row"]}
        gap={["16px", "48px"]}
        {...props}
    >
        <Flex gap={["4px", "16px"]} ai="center">
            <svg height="12" width="50">
                <g fill="none" stroke={theme.colours.hpAmethystSmoke.hex}>
                    <path strokeWidth="3" d="M5 6 l50 0" />
                </g>
            </svg>
            <HeadingLabel fontSize="12px" fontWeight="500">
                No resistance training
            </HeadingLabel>
        </Flex>

        <Flex gap={["4px", "16px"]} ai="center">
            <svg height="12" width="60">
                <g fill="none" stroke={theme.colours.hpPrimaryPurple.hex}>
                    <path strokeDasharray="18" strokeWidth="1" d="M5 6 l50 0" />
                </g>
            </svg>
            <HeadingLabel fontSize="12px" fontWeight="500">
                1-2 sessions/week resistance training
            </HeadingLabel>
        </Flex>

        <Flex gap={["4px", "16px"]} ai="center">
            <svg height="12" width="50">
                <g fill="none" stroke={theme.colours.hpAmethystSmoke.hex}>
                    <path strokeDasharray="6" strokeWidth="2" d="M5 6 l50 0" />
                </g>
            </svg>
            <HeadingLabel fontSize="12px" fontWeight="500">
                2-4 sessions/week resistance training
            </HeadingLabel>
        </Flex>
    </Flex>
);

Legend.propTypes = {
    legends: PropTypes.array
};

export default Legend;
