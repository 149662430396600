import { pathOr } from "ramda";

import { theme } from "@sisuwellness/web-components";
import {
    isMobileView,
    createLegends,
    calculateMinMax,
    createYGridValues,
    flattenPointValues,
    createPlottableDataPoints,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { DIABETES } from "constants/trend-charts";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: true,
    key: ["ausdriskScoreValue"],
    additionalKeys: ["rating", "unit"]
};

/**
 * Update data with rating
 * @param {object} data
 * @return {{ rating: { key: string, label: string, ranges: object } }}
 */
const diabetesRatingCalc = data => {
    const { guideline, currentValue } = calculatePointColor(DIABETES, data);

    return {
        ...data,
        unit: "pts",
        ausdriskScoreValue: Number(currentValue),
        rating: { key: guideline?.key, label: guideline?.label, ranges: guideline?.ranges }
    };
};

const plot = {
    unit: () => "Diabetes (pts)",

    color: ({ rating } = {}) => theme.guidelines.AustralianDiabetesRisk[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = [], settings) => {
        const points = createPlottableDataPoints(data => diabetesRatingCalc(data, settings))(
            healthChecks,
            METRIC_FIELDS.key,
            METRIC_FIELDS.additionalKeys
        );

        return points;
    },

    yRange: (points = []) => {
        const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(0, maximum, { parts: 5, rounded: METRIC_FIELDS.rounded });

        return {
            min: 0,
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.DIABETES.themeKey,
            "Diabetes"
        ),

    historyProps: (filteredPoints = [], latestHealthCheckData = {}) => {
        const { ausdriskScoreValue } = latestHealthCheckData;
        const { rating } = diabetesRatingCalc({ ausdriskScoreValue });

        const meanAusdriskScoreValue = calculateMeanValue("ausdriskScoreValue", filteredPoints[0]);

        const { rating: meanRating } = diabetesRatingCalc({ ausdriskScoreValue: meanAusdriskScoreValue });

        return {
            unit: "pts",
            value: ausdriskScoreValue,
            meanValue: Math.round(meanAusdriskScoreValue),
            valueColor: theme.guidelines.AustralianDiabetesRisk[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.AustralianDiabetesRisk[(meanRating?.key)]?.sisuPortal?.hex
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
