import styled from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";
import { Box } from "rebass";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";

// ----------------------------------------------------------------------------

export const HeaderContainer = styled(Box)`
    margin: ${props => (props.loading ? "0 40px" : "0 auto")};
    display: flex;
    justify-content: space-between;
    ${props =>
        mediaQueryForCss({
            margin: ["", props.loading ? "0 16px" : "0 auto", "0 auto"]
        })}
`;

export const ItemsContainer = styled(Box)`
    margin-top: 23px;
    display: flex;

    img: {
        cursor: pointer;
    }
`;

export const Button = styled(PrimaryButton)`
    width: 140px;
    height: 32px;
    box-shadow: ${props => props.theme.portalShadows.card1};
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`;

export const MobileLogo = styled(Box)`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    img: {
        cursor: pointer;
    }
`;

export const MemberLogin = styled(PrimaryButton)`
    position: fixed;
    right: 10px;
    width: 140px;
    height: 32px;
    box-shadow: ${props => props.theme.portalShadows.card1};
    border-radius: 64px;
    font-size: ${props => props.theme.fontSize[14].px};
`;
