import { isNil, range } from "lodash";
import { and, compose, filter, flatten, map, mean, mergeRight, pathOr, pick, sort, toPairs } from "ramda";
import moment from "moment";
import { theme } from "@sisuwellness/web-components";
import { FILTER_ALL_TIME, FILTER_ONE_MONTH, FILTER_ONE_YEAR } from "constants/trend-charts";
import { formatDate } from "utilities/commonUtils";

export const CLOSEST_LOWER_LIMIT = 10;

/**
 * Down scale a number by a given numeric value
 * @param {number} num Number to down scale
 * @param {number} val Numeric value to down scale by
 * @return {number}
 */
export const downBy = (num, val = CLOSEST_LOWER_LIMIT) => {
    if (!Number.isFinite(num)) return 0;
    const downScaleVal = num - val;

    return downScaleVal < 0 ? num : downScaleVal;
};

/**
 * Create Plottable data points required for trend charts
 * @param  {...Function} evaluators Function to evaluate data
 */
export const createPlottableDataPoints = (...evaluators) =>
    /**
     *
     * @param {object[]} healthchecks
     * @param {string[]} keys
     * @param {string[]} additionalKeys
     * @return {Array<{ id: string, data: { x: Date, y: *, dateCreated: string  }[] }>}
     */
    (healthchecks, keys = [], additionalKeys = []) =>
        compose(
            data =>
                // Create point object for each key
                map(
                    key => ({
                        id: key,
                        data: compose(
                            map(data => ({ ...data, id: key, y: data[key], x: moment(data["dateCreated"]).toDate() })), // Create point data object
                            sort((a, b) => a.dateCreated?.localeCompare(b.dateCreated)), // sort by date created
                            filter(
                                data =>
                                    !isNil(data[key]) &&
                                    !Number.isNaN(data[key]) &&
                                    moment(data["dateCreated"]).isValid()
                            ), // filter null/undefined/NaN data
                            map(pick([key, "dateCreated", "unit", "analyticsHealthCheckId", ...additionalKeys])) // Extract fields provided by keys
                        )(data)
                    }),
                    keys
                ),
            // Map data according to the provided evaluators.
            map(data =>
                evaluators.length
                    ? mergeRight(compose(...evaluators)(data), {
                          dateCreated: data.dateCreated,
                          analyticsHealthCheckId: data.analyticsHealthCheckId
                      })
                    : data
            )
        )(healthchecks);

/**
 * Create an Array of Legends
 * @param {object} ranges key/value pair for metric ranges
 * @param {string} themeKey
 * @param {string} extraText text to append with label
 * @return {{ id: string, label: string, color: string }[]}
 */
export const createLegends = (ranges, themeKey, extraText = "") =>
    toPairs(ranges).map(([key, val]) => ({
        id: key,
        label: `${val?.label} ${extraText}`,
        color: theme.guidelines[themeKey]?.[key]?.sisuPortal?.hex
    }));

/**
 * Flatten Points object wrt to provided key
 * @param {Array<{ id: string, data: [] }>} points
 * @param {string} key
 * @return {[]}
 */
export const flattenPointValues = (points, key) =>
    compose(
        filter(el => !isNil(el)),
        map(data => data[key]),
        flatten,
        map(and(pick(["data"]), ({ data }) => data))
    )(points);

/**
 * Calculate min and max values
 *
 * @param {number[]} numArray
 * @return { minimun: number, maximum: number }
 */
export const calculateMinMax = numArray => ({ maximum: Math.max(...numArray), minimum: Math.min(...numArray) });

/**
 * Create evenly spaced array
 * @param {number} min
 * @param {number} max
 * @param {object} param
 * @param {number} param.parts
 * @param {boolean} param.rounded
 * @return {number[]}
 */
export const createYGridValues = (min = 0, max = 1, { parts = 1, rounded = false }) => {
    const steps = (max - min) / parts;

    const roundedSteps = rounded ? Math.ceil(steps) : steps;
    const spacedArray = range(rounded ? Math.floor(min) : min, rounded ? Math.ceil(max) : max, roundedSteps);

    const lastElement = spacedArray[spacedArray.length - 1];
    if (lastElement < max) spacedArray.push(lastElement + roundedSteps);

    return spacedArray;
};

/**
 *
 * @param {Array<{ id: string, data: [] }>} points
 * @param {string} currentFilter
 * @param {string} date
 * @return {Array<{ id: string, data: [] }>}
 */
export const createFilteredDatapoints = (points, currentFilter, date) =>
    map(({ id, data }) => ({
        id,
        data: filter(({ dateCreated }) => {
            switch (currentFilter) {
                case FILTER_ONE_MONTH:
                    return formatDate(date, "MM/YYYY") === formatDate(dateCreated, "MM/YYYY");

                case FILTER_ONE_YEAR:
                    return formatDate(date, "YYYY") === formatDate(dateCreated, "YYYY");

                default:
                    return true;
            }
        })(data)
    }))(points);

/**
 * Calculate Mean on given key
 * @param {string} key
 * @param {Array<{ id: string, data: [] }>} points
 * @return {number}
 */
export const calculateMeanValue = (key, points) =>
    compose(
        num => (Number.isNaN(num) ? 0 : num),
        mean,
        map(data => data[key]),
        pathOr([], ["data"])
    )(points);

/**
 *
 * @param {object} settings
 * @param {"imperial" | "metric"} settings.unitMeasurement
 * @return {boolean}
 */
export const isUnitImperial = ({ unitMeasurement } = {}) => unitMeasurement === "imperial";

/**
 * Check if screen width is a mobile screen width
 * @param {number} width
 * @return {boolean}
 */
export const isMobileView = width => width < theme.mediaQuerySizes.mobile;

/**
 * Calculates a number to fill x-axis values smartly
 * @param {string} filterType
 * @param {Array} dataPoints
 * @param {number} width veiwport
 * @return {number}u
 */
const calulateTickValues = (filterType, dataPoints = [], width) => {
    let tickNumber = 1;

    const uniqDates = dataPoints[0]?.data?.map(({ dateCreated }) => dateCreated);
    const differenceByTime = filterType === FILTER_ONE_MONTH ? "days" : "months";
    const sparseness = Math.abs(moment(uniqDates[0]).diff(uniqDates[uniqDates.length - 1], differenceByTime));

    const isMobileScreen = width < 600;
    const isTabletScreen = width >= 600 && width < 800;

    let divisionPoints = isTabletScreen ? 8 : isMobileScreen ? 4 : 12;
    if (filterType === FILTER_ONE_MONTH) divisionPoints = isTabletScreen ? 16 : isMobileScreen ? 12 : 20;
    if (filterType === FILTER_ALL_TIME) divisionPoints = isTabletScreen ? 8 : isMobileScreen ? 5 : 12;

    if (sparseness <= divisionPoints) return tickNumber;

    tickNumber = sparseness / divisionPoints;

    return Math.ceil(tickNumber);
};

/**
 * Map filter type
 * @param {string} filterType
 * @param {number} width viewport
 * @param {Array} data healthChecks array
 * @return {{ axisBottomProps: object, xScaleProps: object }}
 */
export const mapFilterTypeToTimeScale = (filterType, width, data) => {
    let axisBottomProps,
        xScaleProps = {};

    const tickNumber = calulateTickValues(filterType, data, width);

    switch (filterType) {
        case FILTER_ONE_MONTH: {
            axisBottomProps = {
                format: tick => moment(tick).format("DD"),
                tickValues: `every ${tickNumber} day`
            };

            xScaleProps = { precision: "hour" };
            break;
        }

        case FILTER_ONE_YEAR: {
            axisBottomProps = { format: tick => moment(tick).format("D'MMM"), tickValues: `every ${tickNumber} month` };
            xScaleProps = { precision: "hour" };
            break;
        }

        case FILTER_ALL_TIME:
        default: {
            axisBottomProps = {
                format: tick => moment(tick).format("MMM'YY"),
                tickValues: `every ${tickNumber} month`
            };

            xScaleProps = { precision: "hour" };
        }
    }

    return { axisBottomProps, xScaleProps };
};
