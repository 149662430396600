import React, { useState } from "react";
import PropTypes from "prop-types";

import { Flex } from "rebass";
import { Form, Field, Formik } from "formik";
import { range, reverse } from "ramda";
import { useSelector } from "react-redux";
import moment from "moment";
import { isEmpty, isEqual } from "lodash-es";
import { useHistory } from "react-router";
// --------------------------------------------------------------------

import PortalModal from "components/PortalModal";
import { PrimaryButton, SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { Margin } from "components/styled-components";
import InputField from "components/InputField";
import Spinner from "components/spinner";
import withDimensions from "components/HOC/withDimensions";
import { ErrorText } from "components/InputField/styled-components";
import routes from "constants/routes";

import { validateSchema } from "./formValidation";
import { responsiveSelect } from "./styled";

// --------------------------------------------------------------------

const ProfileForm = ({ dimension: [width], onSubmit }) => {
    const { userProfile } = useSelector(state => state.auth.citizen);
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const initialValues = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        gender: userProfile.gender,
        dateOfBirth: userProfile.dateOfBirth
    };

    const getDate = dob => moment(dob, "YYYY-MM-DD").date();
    const getMonth = dob => moment(dob, "YYYY-MM-DD").month() + 1;
    const getYear = dob => moment(dob, "YYYY-MM-DD").year();
    const date = (year, month, day) => moment(new Date(year, month - 1, day)).format("YYYY-MM-DD");

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={true}
            onSubmit={onSubmit}
            validationSchema={validateSchema()}
        >
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setTouched, isSubmitting }) => {
                const backButton = () => {
                    if (isEqual(values, initialValues)) {
                        return history.replace(routes.myAccount.personalDetails);
                    } else {
                        return setIsModalOpen(true);
                    }
                };
                return (
                    <>
                        <Form onSubmit={handleSubmit} style={{ gridColumn: "span 2" }}>
                            <Flex flexDirection="column" width={["100%", "50%"]}>
                                <Margin mTop={["44px"]}>
                                    <InputField
                                        label="First Name"
                                        fontFamily="GT Walsheim Pro"
                                        inputProps={{
                                            name: "firstName",
                                            placeholder: "First Name",
                                            onChange: handleChange,
                                            value: values.firstName,
                                            onBlur: handleBlur
                                        }}
                                        error={errors.firstName}
                                    />
                                </Margin>
                                <Margin mTop={["44px"]}>
                                    <InputField
                                        label="Last Name"
                                        fontFamily="GT Walsheim Pro"
                                        inputProps={{
                                            name: "lastName",
                                            placeholder: "Last Name",
                                            onChange: handleChange,
                                            value: values.lastName,
                                            onBlur: handleBlur
                                        }}
                                        error={errors.lastName}
                                    />
                                </Margin>
                                <Margin mTop={["44px"]}>
                                    <Field name="gender" onBlur={handleBlur}>
                                        {({ form: { touched, errors } }) => (
                                            <Spinner
                                                tag="gender"
                                                placeholder="Gender"
                                                fontFamily="GT Walsheim Pro"
                                                data-testid="select-gender"
                                                data={["Male", "Female"]}
                                                error={errors.gender}
                                                defaultValue={{ gender: values.gender }}
                                                selectCallback={(_, item) => {
                                                    setTouched({ ...touched, gender: true });
                                                    setFieldValue("gender", item);
                                                }}
                                            >
                                                Gender at Birth
                                            </Spinner>
                                        )}
                                    </Field>
                                </Margin>

                                <Margin mTop={["44px"]}>
                                    <Flex style={{ flex: "1" }}>
                                        <Margin mRight={["15px"]}>
                                            <Field name="day" onBlur={handleBlur}>
                                                {({ form: { touched } }) => {
                                                    return (
                                                        <Spinner
                                                            tag="day"
                                                            fontFamily="GT Walsheim Pro"
                                                            data={range(1, 32)}
                                                            error={Boolean(errors.dateOfBirth)}
                                                            defaultValue={{ day: getDate(values.dateOfBirth) }}
                                                            selectCallback={(_, item) => {
                                                                setTouched({ ...touched, day: true });
                                                                const dob = values.dateOfBirth;
                                                                const value = date(getYear(dob), getMonth(dob), item);
                                                                setFieldValue("dateOfBirth", value);
                                                            }}
                                                            customStyle={responsiveSelect}
                                                        >
                                                            Date of Birth
                                                        </Spinner>
                                                    );
                                                }}
                                            </Field>
                                        </Margin>
                                        <Margin mRight={["15px"]}>
                                            <Field name="month" onBlur={handleBlur}>
                                                {({ form: { touched } }) => {
                                                    return (
                                                        <Spinner
                                                            tag="month"
                                                            fontFamily="GT Walsheim Pro"
                                                            data={range(1, 13)}
                                                            error={Boolean(errors.dateOfBirth)}
                                                            defaultValue={{ month: getMonth(values.dateOfBirth) }}
                                                            selectCallback={(_, item) => {
                                                                setTouched({ ...touched, month: true });
                                                                const dob = values.dateOfBirth;
                                                                const value = date(getYear(dob), item, getDate(dob));
                                                                setFieldValue("dateOfBirth", value);
                                                            }}
                                                            customStyle={responsiveSelect}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Margin>
                                        <Margin mRight={["15px"]}>
                                            <Field name="year" onBlur={handleBlur}>
                                                {({ form: { touched } }) => {
                                                    return (
                                                        <Spinner
                                                            tag="year"
                                                            fontFamily="GT Walsheim Pro"
                                                            data={reverse(
                                                                range(
                                                                    new Date().getFullYear() - 99,
                                                                    new Date().getFullYear() + 1
                                                                )
                                                            )}
                                                            error={Boolean(errors.dateOfBirth)}
                                                            defaultValue={{ year: getYear(values.dateOfBirth) }}
                                                            selectCallback={(_, item) => {
                                                                setTouched({ ...touched, year: true });
                                                                const dob = values.dateOfBirth;
                                                                const value = date(item, getMonth(dob), getDate(dob));
                                                                setFieldValue("dateOfBirth", value);
                                                            }}
                                                            customStyle={responsiveSelect}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Margin>
                                    </Flex>
                                </Margin>
                                {errors.dateOfBirth && <ErrorText>{errors.dateOfBirth}</ErrorText>}
                            </Flex>
                            <Margin mTop={["60px", "", "40px"]}>
                                <Flex justifyContent="space-between" pr={["0px", "20px"]} pl={["0px", "20px"]}>
                                    <SecondaryButton
                                        width={width < 800 ? "138px" : undefined}
                                        type="button"
                                        onClick={backButton}
                                    >
                                        Back
                                    </SecondaryButton>
                                    <PrimaryButton
                                        width={width < 800 ? "138px" : ""}
                                        type="submit"
                                        disabled={!isEmpty(errors) || isEqual(values, initialValues) || isSubmitting}
                                    >
                                        Save
                                    </PrimaryButton>
                                </Flex>
                            </Margin>
                        </Form>
                        <PortalModal closeModal={() => setIsModalOpen(false)} modalIsOpen={isModalOpen} />
                    </>
                );
            }}
        </Formik>
    );
};

ProfileForm.propTypes = {
    dimension: PropTypes.array,
    onSubmit: PropTypes.func
};

export default withDimensions(ProfileForm);
