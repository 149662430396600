export default {
    en: {
        translation: {
            Guideline: "Guideline",
            "Weight & BMI": "Weight & BMI",
            "Guidelines.BodyMassIndex.low": "Low",
            "Guidelines.BodyMassIndex.normal": "Normal",
            "Guidelines.BodyMassIndex.elevated": "Elevated",
            "Guidelines.BodyMassIndex.high": "High",
            "Guidelines.BodyMassIndex.veryHigh": "Very High",
            "Guidelines.NationalHeartFoundationAustralia.optimal": "Optimal",
            "Guidelines.NationalHeartFoundationAustralia.normal": "Normal",
            "Guidelines.NationalHeartFoundationAustralia.highNormal": "High-normal",
            "Guidelines.NationalHeartFoundationAustralia.high": "High",
            "Guidelines.NationalHeartFoundationAustralia.veryHigh": "Very High",
            "Guidelines.NationalHeartFoundationAustralia.severe": "Severe",
            "Guidelines.BodyFat.low": "Low",
            "Guidelines.BodyFat.normal": "Normal",
            "Guidelines.BodyFat.high": "High",
            "Guidelines.BodyFat.veryHigh": "Very High"
        }
    },
    de: {
        translation: {
            Guideline: "Richtlinie",
            "Weight & BMI": "Weight & BMI",
            "Guidelines.BodyMassIndex.low": "Niedrig",
            "Guidelines.BodyMassIndex.normal": "Normal",
            "Guidelines.BodyMassIndex.elevated": "Erhöht",
            "Guidelines.BodyMassIndex.high": "Hoch",
            "Guidelines.BodyMassIndex.veryHigh": "Sehr Hoch",
            "Guidelines.NationalHeartFoundationAustralia.optimal": "Optimal",
            "Guidelines.NationalHeartFoundationAustralia.normal": "Normal",
            "Guidelines.NationalHeartFoundationAustralia.highNormal": "Hoch-Normal",
            "Guidelines.NationalHeartFoundationAustralia.high": "Hoch",
            "Guidelines.NationalHeartFoundationAustralia.veryHigh": "Sehr Hoch",
            "Guidelines.NationalHeartFoundationAustralia.severe": "Gefährlich Hoch",
            "Guidelines.BodyFat.low": "Niedrig",
            "Guidelines.BodyFat.normal": "Normal",
            "Guidelines.BodyFat.high": "Hoch",
            "Guidelines.BodyFat.veryHigh": "Sehr Hoch"
        }
    }
};
