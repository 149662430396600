import routes from "constants/routes";

export default {
    myAccount: {
        path: `${routes.myAccount.home}`,
        title: "My Account"
    },

    logOut: {
        path: `${routes.root}`,
        title: "Log Out"
    }
};
