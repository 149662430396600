import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router";
import { Flex, Image, Text } from "rebass";

// ----------------------------------------------------------------------------

import { HeadingLabel as Title } from "@sisuwellness/ui/src/components/Labels";
import { MetricCardBtnSecondary } from "components/CommonComponents/Button";
import SummaryCard from "components/Cards/SummaryCard";
import { PORTAL_IMAGE } from "constants/imagePath";
import routes from "constants/routes";
import { getCtaText } from "utilities/premium";

import { BlurContainer, StyledMetricCardContainer } from "../../styled";
import CompositionGraph from "./compositionGraph";

// ----------------------------------------------------------------------------

const BodyCompositionCard = ({ value, isPremium, ...rest }) => {
    const history = useHistory();

    const { muscleMass, bodyFatPercent } = value;

    let muscleMassValue = muscleMass,
        bodyFatValue = bodyFatPercent;

    if (!isPremium) {
        // Dummy Value for locked premium card state
        muscleMassValue = 12;
        bodyFatValue = 10;
    }

    const handleOnClick = useCallback(() => {
        history.push(isPremium && !muscleMassValue ? routes.findHealthStations : routes.bodyComposition);
    }, [isPremium, value]);

    return (
        <SummaryCard
            cardHeader={
                <Title fontSize="15px" fontWeight="normal" mb="8px">
                    Your Body Composition
                </Title>
            }
            cardFooter={
                <MetricCardBtnSecondary
                    id="body-composition"
                    onClick={handleOnClick}
                    data-testid="body-composition-cta"
                >
                    {getCtaText(muscleMassValue, isPremium)}
                </MetricCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" alignItems="center" isPremium={isPremium}>
                    <Flex width="100%" maxWidth="215px" justifyContent="space-between">
                        <CompositionGraph value={muscleMassValue} bodyFatPercent={bodyFatValue} />
                    </Flex>
                    {!isPremium && (
                        <BlurContainer height="168px" alignItems="center" justifyContent="center" mt="8px">
                            <Image size="130px" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} alt="premium-lock" />
                        </BlurContainer>
                    )}
                    <Text
                        mt="16px"
                        maxWidth="230px"
                        fontSize="13px"
                        textAlign="center"
                        fontWeight="light"
                        color="hpGrayishBlueText"
                    >
                        {isPremium && muscleMassValue
                            ? "Analyse your body composition breakdown to discover what you need to lose, gain, or maintain."
                            : "Complete a full health check on a SiSU Health Station to calculate your full body composition."}
                    </Text>
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

BodyCompositionCard.propTypes = {
    isPremium: PropTypes.bool,
    value: PropTypes.any
};

export default BodyCompositionCard;
