import React from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------------

import { getOptObject } from "utilities/commonUtils";

import Select from "../../Select";
import { Box } from "rebass";
import { ErrorNotification } from "../../Error";

// -----------------------------------------------------------------------------------

const GENDER_ARRAY = ["Male", "Female"];

export const GenderField = ({ values, errors, setFieldValue, handleBlur, touched, ...rest }) => (
    <Box width="100%" textAlign="left" {...rest}>
        <Select
            label="Gender"
            name="gender"
            placeholder="Gender"
            data-testid="select-gender"
            defaultValue={getOptObject(values.gender)}
            isError={!!(touched.gender ? errors.gender : "")}
            onBlur={() => handleBlur({ target: { name: "gender" } })}
            onChange={options => setFieldValue("gender", options.value)}
            options={GENDER_ARRAY.map(gender => getOptObject(gender))}
        />
        <ErrorNotification boolExp={!!(touched.gender && errors.gender)}>{errors.gender}</ErrorNotification>
    </Box>
);

GenderField.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    initialValues: PropTypes.object
};
