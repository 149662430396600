import React from "react";
import PropTypes from "prop-types";

import { values } from "ramda";
import { Text } from "rebass";
import styled from "styled-components";

// ------------------------------------------------------------------------

import { getFlagPath } from "utilities/flag";
import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";
import InputField from "components/InputField";

// ------------------------------------------------------------------------

export const FlagCode = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 40px;
        height: 30px;
        object-fit: cover;
    }
`;

export const MobileNumberField = ({
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    countryCode = COUNTRIES.AUSTRALIA,
    ...rest
}) => {
    const Flag = (
        <FlagCode>
            <img {...getFlagPath(countryCode)} />
        </FlagCode>
    );

    const handleOnChange = event => {
        const { value } = event.target;
        if (Number.isNaN(Number(value))) return;
        handleChange(event);
    };

    const inputValue = String(values.mobileNumber);

    const inputProps = {
        value: inputValue,
        onBlur: handleBlur,
        name: "mobileNumber",
        onChange: handleOnChange,
        "data-testid": "mobileNumber"
    };

    const error = touched.mobileNumber ? errors.mobileNumber : false;

    return (
        <InputField
            fontFamily="GT Walsheim Pro"
            leftChild={Flag}
            label="Mobile Number"
            inputProps={inputProps}
            error={error}
            {...rest}
        />
    );
};

MobileNumberField.propTypes = {
    ...Text.propTypes,
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    countryCode: PropTypes.oneOf(values(COUNTRIES))
};
