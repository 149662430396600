import React, { Component } from "react";
import PropTypes from "prop-types";
import { Errors, StyledInput } from "../../Forms";
import QuestionTitle from "./Title";
import Help from "./Help";
import styled from "styled-components";
import { NumberValidator, Validation } from "@sisuwellness/utilities/Validation";
import { TypeConverter } from "@sisuwellness/utilities/Converters";
import theme from "../../../themes";
import QuestionIcon from "./QuestionIcon";

const StyledContainer = styled.div``;

class NumberQuestion extends Component {
    static defaultProps = {
        touchKeyboard: false
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeTouchKeyboard = this.handleInputChangeTouchKeyboard.bind(this);
    }

    componentDidMount() {
        if (this.props.autoFocus && this.props.onInputFocus) {
            this.props.onInputFocus(this.inputRef.current);
        }
    }

    onInputFocus() {
        if (this.props.onInputFocus) {
            this.props.onInputFocus(this.inputRef.current);
        }
    }

    handleInputChangeTouchKeyboard(e) {
        let input = e.target.value;
        let value = this.enforceNumberPattern(input);
        if (value !== null) {
            this.props.onValueChange(value);
            this.setState(this.state);
        }
    }

    enforceNumberPattern(value) {
        let number = null;
        const regex = /^[-+]?[0-9]*[.]?[0-9]*$/;
        const match = value.match(regex);

        if (match && match.length === 1) {
            number = match[0];
        }

        return number;
    }

    handleInputChange(e) {
        let input = e.target.value;
        let value = this._validateAndCleanValue(input);
        if (value !== null) {
            this.props.onValueChange(value);
            this.setState(this.state);
        }
    }

    _validateAndCleanValue(value) {
        let cleanedValue = null;
        if (value === "") {
            cleanedValue = value;
        } else {
            let validation = new Validation();
            validation.addValidator(new NumberValidator({}));

            const errors = validation.validate(value);
            if (!errors.length) {
                cleanedValue = TypeConverter.stringToNumber(value);
            }
        }
        return cleanedValue;
    }

    getInput() {
        let input = null;
        if (this.props.touchKeyboard) {
            input = (
                <StyledInput
                    data-testid={"survey-number-input"}
                    autoComplete={"off"}
                    type={"text"}
                    id={this.props.id}
                    ref={this.inputRef}
                    value={this.props.value}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                    onChange={() => {}}
                    onFocus={e => this.onInputFocus(e)}
                    onInput={this.handleInputChangeTouchKeyboard}
                    centreText
                    width={"40%"}
                />
            );
        } else {
            input = (
                <StyledInput
                    data-testid={"survey-number-input"}
                    placeholder={this.props.placeholder}
                    id={this.props.id}
                    type={"number"}
                    value={this.props.value}
                    onChange={this.handleInputChange}
                    autoFocus={this.props.autoFocus}
                    autoComplete={"off"}
                    centreText
                    width={"40%"}
                />
            );
        }

        return input;
    }

    render() {
        let getIcon = this.props.model.getQuestionIcon();
        const hasErrors = this.props.errors && this.props.errors.length;

        let helpText = null;
        if (this.props.model.getHelpText()) {
            helpText = <Help>{this.props.model.getHelpText()}</Help>;
        }
        let input = this.getInput();
        let validationErrors = null;
        if (hasErrors) {
            validationErrors = <Errors rounded={true}>{this.props.errors}</Errors>;
        }

        return (
            <StyledContainer data-testid={"survey-question-number"}>
                {validationErrors}
                <QuestionIcon type={theme.icons.types[getIcon]} />
                <QuestionTitle hasErrors={hasErrors} htmlFor={this.props.id}>
                    {this.props.model.getDisplayText()}
                </QuestionTitle>
                {helpText}
                {input}
            </StyledContainer>
        );
    }
}

NumberQuestion.displayName = "Number Question";
NumberQuestion.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    value: PropTypes.any.isRequired,
    onValueChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    touchKeyboard: PropTypes.bool,
    onInputFocus: PropTypes.func,
    errors: PropTypes.any
};

export default NumberQuestion;
