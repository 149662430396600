export default class SiSU {
    /**
     * Constructor
     */
    constructor(answer) {
        this._answer = answer;
    }

    /**
     * Returns the given sleep trouble value
     * @returns {Array}
     */
    get() {
        return this._answer;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "SLEEP_TROUBLE";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_SLEEP_TROUBLE";
    }

    /**
     * Returns if the given sleep trouble answer is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return !this.isVeryHigh() && !this.isHigh();
    }

    /**
     * Returns if the given sleep trouble answer is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        const ans = ["very often", "2-3 nights /week", "less than 6 hours"];
        if (!this.isVeryHigh()) {
            if (this._answer[0] || this._answer[1] || this._answer[2]) {
                return this._answer.some(str => ans.includes(str.toLowerCase()));
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Returns if the given sleep trouble answer is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        if (this._answer[0] && this._answer[1]) {
            return this._answer[0].toLowerCase() === "very often" && this._answer[1].toLowerCase() === "very often";
        } else {
            return false;
        }
    }

    /**
     * Returns an object representing various data for each stress range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            normal: {
                key: "normal",
                label: "Normal",
                info: "Not very often"
            },
            high: {
                key: "high",
                label: "High",
                info: "Often"
            },
            veryHigh: {
                key: "veryHigh",
                label: "Very High",
                info: "Very often"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get rangeInfo() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.info;
            case this.isHigh():
                return SiSU.ranges.high.info;
            case this.isVeryHigh():
                return SiSU.ranges.veryHigh.info;
        }
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get key() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.key;
            case this.isHigh():
                return SiSU.ranges.high.key;
            case this.isVeryHigh():
                return SiSU.ranges.veryHigh.key;
        }
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    // eslint-disable-next-line
    get label() {
        switch (true) {
            case this.isNormal():
                return SiSU.ranges.normal.label;
            case this.isHigh():
                return SiSU.ranges.high.label;
            case this.isVeryHigh():
                return SiSU.ranges.veryHigh.label;
        }
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    // eslint-disable-next-line
    get riskLevel() {
        const totalRiskLevel = 3;
        switch (true) {
            case this.isNormal():
                return 1 / (totalRiskLevel + 1);
            case this.isHigh():
                return 2 / (totalRiskLevel + 1);
            case this.isVeryHigh():
                return 3 / (totalRiskLevel + 1);
        }
    }
}
