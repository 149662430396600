import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Image } from "rebass";
import Slider from "@material-ui/core/Slider";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

// --------------------------------------------------------------------------------------

import Flex from "components/Flex";
import { PORTAL_IMAGE } from "constants/imagePath";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { CircularCard, LabelBox, StyledSwtich, SwitchTabs } from "./styled-components";

// --------------------------------------------------------------------------------------

const theme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: { color: "#372168" },
            track: { color: "#372168" },
            rail: { color: "#d7d3e1" }
        }
    }
});

// --------------------------------------------------------------------------------------

export const MetricSliders = ({
    heading,
    icon,
    units,
    actualReading,
    setReadingValue,
    minValue,
    maxValue,
    defaultValue,
    ...rest
}) => {
    const [instantaneousReading, setInstantaneousReading] = useState(actualReading);
    useEffect(() => setInstantaneousReading(actualReading), [actualReading]);

    const handleSliderChange = (_, newValue) => {
        setInstantaneousReading(newValue);
        setReadingValue(newValue);
    };

    return (
        <Flex width="314px" gap="16px" {...rest}>
            <CircularCard size="65px">
                <Image size="45px" src={`${PORTAL_IMAGE}/premium/${icon}`} alt={icon} />
            </CircularCard>
            <Flex width="100%" fd="column">
                <Flex jc="space-between">
                    <HeadingLabel fontSize={["17px", "", "20px"]}>{heading}</HeadingLabel>
                    <LabelBox color="hpDarkPurple" data-testid="slider-value">
                        {Math.round(instantaneousReading)} {units}
                    </LabelBox>
                </Flex>
                <HeadingLabel fontSize={["11px", "", "13px"]} mb="16px">
                    Your current reading: {Math.round(defaultValue)} {units}
                </HeadingLabel>
                <ThemeProvider theme={theme}>
                    <Slider
                        min={minValue}
                        max={maxValue}
                        data-testid="slider"
                        value={instantaneousReading}
                        onChange={handleSliderChange}
                    />
                </ThemeProvider>
            </Flex>
        </Flex>
    );
};

MetricSliders.propTypes = {
    icon: PropTypes.string,
    units: PropTypes.string,
    heading: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    actualReading: PropTypes.number,
    setReadingValue: PropTypes.func,
    defaultValue: PropTypes.number
};

// --------------------------------------------------------------------------------------

export const WeightSlider = ({ weight, onChange, defaultValue }) => (
    <MetricSliders
        units="kgs"
        minValue={30}
        maxValue={250}
        heading="Weight"
        icon="weight.svg"
        actualReading={weight}
        setReadingValue={onChange}
        defaultValue={defaultValue}
        data-testid="slider-weight"
    />
);

WeightSlider.propTypes = {
    weight: PropTypes.number,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number
};

// --------------------------------------------------------------------------------------

export const BloodPressureSlider = ({ systolic, onChange, defaultValue }) => (
    <MetricSliders
        units="mmHg"
        minValue={70}
        maxValue={260}
        heading="Systolic BP"
        icon="bloodPressure.svg"
        actualReading={systolic}
        setReadingValue={onChange}
        defaultValue={defaultValue}
        data-testid="slider-bp"
    />
);

BloodPressureSlider.propTypes = {
    systolic: PropTypes.number,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number
};

// --------------------------------------------------------------------------------------

export const SmokingSlider = ({ isSmoker, onChange, defaultValue }) => {
    const [activeTab, setActiveTab] = useState(isSmoker ? "YES" : "NO");
    useEffect(() => setActiveTab(isSmoker ? "YES" : "NO"), [isSmoker]);

    const isActive = key => activeTab === key;
    const handleOnClick = key => {
        setActiveTab(key);
        onChange(key === "YES");
    };

    return (
        <Flex width="314px" gap="16px" data-testid="slider-smoke">
            <CircularCard size="65px">
                <Image size="45px" src={`${PORTAL_IMAGE}/premium/smoking.svg`} alt="smoking.svg" />
            </CircularCard>
            <Flex width="100%" fd="column">
                <HeadingLabel fontSize={["17px", "", "20px"]}>Smoking</HeadingLabel>
                <Flex jc="space-between">
                    <HeadingLabel fontSize={["11px", "", "13px"]} mb="16px">
                        Current status: {defaultValue ? "Yes" : "No"}
                    </HeadingLabel>
                    <StyledSwtich data-testid="switch-tabs">
                        <SwitchTabs isActive={isActive("YES")} onClick={() => handleOnClick("YES")}>
                            Yes
                        </SwitchTabs>
                        <SwitchTabs isActive={isActive("NO")} onClick={() => handleOnClick("NO")}>
                            No
                        </SwitchTabs>
                    </StyledSwtich>
                </Flex>
            </Flex>
        </Flex>
    );
};

SmokingSlider.propTypes = {
    isSmoker: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.bool
};
