export const METRIC_TEXTSPECS = {
    HEART_AGE: {
        heading: "Heart Age",
        icon: "heart-age.svg",
        bg: "hpLightBlue"
    },
    TEN_YEAR_CVD_RISK: {
        heading: "Heart and Stroke Risk",
        icon: "heart-stroke.svg",
        bg: "hpPurplishPink",
        text: "Your risk of developing cardiovascular disease in the next 10 years is:"
    }
};
