import { HRA_EMOTIONAL_SCORE, HRA_LIFESTYLE_SCORE, HRA_SOCIAL_SCORE } from "./trend-charts";

const getStaticRecommendations = type => {
    switch (type) {
        case HRA_SOCIAL_SCORE:
            return {
                text: "This takes into account factors like the quality of your relationships and social interactions."
            };

        case HRA_EMOTIONAL_SCORE:
            return {
                text:
                    "This takes into account factors like the quality of your relationships and how you deal with pressure."
            };

        case HRA_LIFESTYLE_SCORE:
            return {
                text: "This takes into account factors like your activity and eating habits."
            };

        default:
            return null;
    }
};

export default getStaticRecommendations;
