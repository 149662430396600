import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Flex, Box, FlexBox } from "../../Flex";
import Title from "../Title";
import { StyledChoice } from "../../Forms/Choice";
import { media } from "../../../themes";

const Button = styled(StyledChoice)`
    padding-left: 6rem;
    padding-right: 6rem;
    ${media.tablet`
        width: 90%;
    `}
`;

const AcceptButton = styled(Button)`
    background: ${props => props.theme.colours.flat.blue.hex};
    color: #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border: 3px solid ${props => props.theme.colours.flat.blue.hex};
`;

const List = styled.ul`
    box-sizing: border-box;
    text-align: start;
`;

const Item = styled.li`
    font-size: ${props => props.theme.text.heading5.sizeRem};
    margin: 0.5rem 0;
`;

export const MedAckButtonLabels = {
    ack: "ACCEPT",
    back: "ADD MEDICARE"
};

const MedicareAcknowledgment = ({
    title = "I acknowledge that by not providing my Medicare number:",
    label = "I acknowledge",
    info = [],
    onAck = () => {},
    onBack = () => {}
}) => {
    return (
        <Flex fd="column" jc="space-between" my="2rem" overflow="auto">
            <Title my={"2rem"}>{title}</Title>
            <Box my="2rem" mx="2rem" flex="1">
                <List>
                    {info.map((item, index) => (
                        <Item key={index}>{item}</Item>
                    ))}
                </List>
            </Box>
            <FlexBox ai="center" jc="center" flexWrap={"wrap"} mt="2rem">
                <Button onClick={() => onBack()}>{MedAckButtonLabels.back}</Button>
                <AcceptButton onClick={() => onAck(label)}>{MedAckButtonLabels.ack}</AcceptButton>
            </FlexBox>
        </Flex>
    );
};

MedicareAcknowledgment.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    info: PropTypes.node,
    onAck: PropTypes.func,
    onBack: PropTypes.func
};

export default withTheme(MedicareAcknowledgment);
