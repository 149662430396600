import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import theme, { generateGlobalTypographyStyle } from "@sisuwellness/web-components/themes";

import AccountView from "./views/Account";
import { Box } from "rebass";
import HealthCheckView from "./views/HealthCheck";
import HealthChecksView from "./views/HealthChecks";
import HealthHubView from "./views/HealthHub";
import HealthRiskAssessmentView from "./views/HealthRiskAssessment/HealthRiskAssessment";
import HealthRiskOnboardingView from "./views/HealthRiskAssessment/HealthRiskOnboarding";
import HealthStationsView from "./views/HealthStations";
import IdentifyView from "./views/Identify";
import ImproveLaunchView from "./views/Improve/ImproveLaunch";
import ImproveView from "./views/Improve/Improve.js";
import LoginView from "./views/Login";
import NeedsResult from "./views/Public/Needs/Result";
import NotFoundView from "./views/NotFound";
import PrintGlobalStyle from "components/PrintGlobalStyle";
import PrintHeader from "components/PrintHeader";
import PrivacyPolicyView from "./views/PrivacyPolicy";
import PrivateRoute from "components/PrivateRoute";
import PulseView from "./views/Pulse";
import RecommendationsView from "./views/Recommendations";
import RegisterView from "./views/Register";
import SetupView from "./views/Setup";
import TermsOfUseView from "./views/TermsOfUse";
import TrendView from "./views/Trend";
import UserJourneyView from "./views/Operations/UserJourneyView";
import UserJourneysList from "./views/Operations/UserJourneysList";
import SignupView from "./views/Signup";
import { createGlobalStyle } from "styled-components";
import routes from "constants/routes";
import styled from "styled-components";
import ContextProviders from "components/ContextProviders";
import HraResult from "views/HraResult";
import HeartAgeView from "views/HeartAge";
import BodyCompositionView from "views/BodyComposition";
import MuscleMassView from "views/MuscleMass";
import AuthPassthrough from "components/AuthPassthrough";
import CorporateSubscriptionSuccessfulPopup from "components/CorporateSubscriptionSuccessfulPopup";
import DeviceLocationResolution from "components/DeviceLocationResolution";
import useRudderStack from "hooks/useRudderStack";
import SwlhtView from "./views/HealthStations/swlht";
import SwlhtEmbedView from "./views/HealthStations/swlht-embed";

const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
    }

    body {
        padding: 0;
        margin: 0;
        background-size: 100% 100%;
        background: white;

        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    ${generateGlobalTypographyStyle(theme)}
`;

const RouteWrapper = styled(Box)`
    min-height: calc(100vh - 20px);
`;

const App = () => {
    const rudderStack = useRudderStack();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        rudderStack.pageEvent(pathname);
    }, [pathname]);

    return (
        <ContextProviders>
            <GlobalStyle />
            <PrintGlobalStyle />
            <PrintHeader />
            <AuthPassthrough />
            <DeviceLocationResolution />
            <CorporateSubscriptionSuccessfulPopup />
            <RouteWrapper>
                <Switch>
                    <Route exact path={routes.root} component={IdentifyView} />
                    <Route exact path={routes.login} component={LoginView} />
                    <Route path={routes.registerToken} component={RegisterView} />
                    <Route path={routes.setupToken} component={SetupView} />
                    <Route exact path={routes.findHealthStations} component={HealthStationsView} />
                    <Route exact path={routes.shaneWarneLegacyHeartTest} component={SwlhtView} />
                    <Route exact path={routes.swlhtEmbed} component={SwlhtEmbedView} />
                    <PrivateRoute exact path={routes.healthRiskOnboarding} viewComponent={HealthRiskOnboardingView} />
                    <PrivateRoute exact path={routes.healthRiskAssessment} viewComponent={HealthRiskAssessmentView} />
                    <PrivateRoute exact path={routes.healthHub} viewComponent={HealthHubView} />
                    <PrivateRoute exact path={routes.healthChecks} viewComponent={HealthChecksView} />
                    <PrivateRoute exact path={routes.healthChecksDetail} viewComponent={HealthCheckView} />
                    <PrivateRoute exact path={routes.improve} viewComponent={ImproveView} />
                    <PrivateRoute exact path={routes.improveLaunch} viewComponent={ImproveLaunchView} />
                    <PrivateRoute exact path={routes.userJourneys} viewComponent={UserJourneysList} />
                    <PrivateRoute exact path={routes.hraResult} viewComponent={HraResult} />
                    <PrivateRoute path={routes.userJourneyDetail} viewComponent={UserJourneyView} />
                    <PrivateRoute path={routes.pulse} viewComponent={PulseView} />
                    <PrivateRoute path={routes.recommendations} viewComponent={RecommendationsView} />
                    <PrivateRoute exact path={routes.myAccount.home} viewComponent={AccountView} />
                    <PrivateRoute path={routes.myAccountDetail} viewComponent={AccountView} />
                    <PrivateRoute path={routes.trendDetail} viewComponent={TrendView} />
                    <PrivateRoute path={routes.heartAge} viewComponent={HeartAgeView} />
                    <PrivateRoute path={routes.bodyComposition} viewComponent={BodyCompositionView} />
                    <PrivateRoute path={routes.muscleMass} viewComponent={MuscleMassView} />
                    <Route path={routes.termOfUse} component={TermsOfUseView} />
                    <Route path={routes.privacyPolicy} component={PrivacyPolicyView} />
                    <Route path={routes.surveyNeedsResultDetail} component={NeedsResult} />
                    <Route path={routes.signup} component={SignupView} />
                    <Route path={routes.notFound} component={NotFoundView} />
                </Switch>
            </RouteWrapper>
        </ContextProviders>
    );
};

App.displayName = "App";

export default App;
