import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Heading from "../Heading";
import FlexBase from "./FlexBase";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    text-align: left;
    padding: 1.75rem 1.25rem;
`;

const WelcomeCard = ({ firstName = null, text, ...rest }) => {
    return (
        <FlexBase {...rest}>
            <Container data-testid="welcome-card">
                <Heading renderAs="h4" mb={"0.5em"}>
                    Welcome{firstName && `, ${firstName}`}
                </Heading>
                {text}
            </Container>
        </FlexBase>
    );
};

WelcomeCard.propTypes = {
    firstName: PropTypes.string,
    tall: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired
};

export default WelcomeCard;
