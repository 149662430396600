import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Keyboard, { Style } from "./base/Keyboard";
import KeyboardButton from "./base/KeyboardButton";
import "./base/Keyboard.css";
import theme from "../../themes";

export default class SisuNumpadKeyboard extends PureComponent {
    static propTypes = {
        inputNode: PropTypes.any,
        showNextButton: PropTypes.bool,
        showBackButton: PropTypes.bool,
        onNext: PropTypes.func,
        onBack: PropTypes.func,
        className: PropTypes.string
    };

    static defaultProps = {
        showNextButton: true,
        showBackButton: true
    };

    onNext(e) {
        this.props.onNext ? this.props.onNext(e) : null;
    }

    onBack(e) {
        this.props.onBack ? this.props.onBack(e) : null;
    }

    getLeftButtons() {
        return this.props.showBackButton
            ? [
                  <KeyboardButton
                      onClick={() => this.onBack(this.props.inputNode.value)}
                      value="Back"
                      classes="numpad-next"
                      key="BackButton"
                      backgroundColor={theme.colours.flat.darkBlue.hex}
                      color={theme.colours.flat.white.hex}
                      minWidth={"100px"}
                  />
              ]
            : [];
    }

    getRightButtons() {
        return this.props.showNextButton
            ? [
                  <KeyboardButton
                      onClick={() => this.onNext(this.props.inputNode.value)}
                      value="Next"
                      classes="numpad-next"
                      key="NextButton"
                      backgroundColor={theme.colours.flat.blue.hex}
                      color={theme.colours.flat.white.hex}
                      minWidth={"200px"}
                  />
              ]
            : [];
    }

    render() {
        return (
            <Keyboard
                inputNode={this.props.inputNode}
                isNumeric={true}
                style={Style.sisu}
                leftButtons={this.getLeftButtons()}
                rightButtons={this.getRightButtons()}
                className={this.props.className}
            />
        );
    }
}
