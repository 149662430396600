import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";

class TotalCholesterol extends Component {
    render() {
        const { t, cholesterol, theme, date, ...rest } = this.props;

        let guideline = null;
        let guidelineStyle = null;

        if (cholesterol) {
            guideline = new Guidelines.TotalCholesterol.SiSU(cholesterol);
            guidelineStyle = theme.guidelines.SiSUTotalCholesterol[guideline.key];
        }

        return (
            <Attribute
                {...rest}
                t={t}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={t("Cholesterol")}
                date={date}
                primaryStatValue={cholesterol}
                primaryStatUnit={"mmol/L"}
                icon={theme.icons.types.cholesterol}
            />
        );
    }
}

TotalCholesterol.displayName = "Total Cholesterol Attribute";
TotalCholesterol.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    cholesterol: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(TotalCholesterol);
