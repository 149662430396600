import React from "react";
import PropTypes from "prop-types";

import { Flex, Box } from "rebass";
import { withTheme } from "styled-components";
import { Redirect } from "react-router-dom";

import moment from "moment";

// ------------------------------------------------------------------------

import APIClient from "utilities/APIClient";
import LoadingView from "components/LoadingView";

import HealthCheckMetrics from "./Metrics/healthCheckMetrics";

// ------------------------------------------------------------------------

import { pathOr, compose } from "ramda";
import routes from "constants/routes";
import { useAsyncData } from "@sisuwellness/utilities/hooks";
import { connect } from "react-redux";
import PageLayout from "components/PageLayout";

import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import BreadCrumb from "views/BreadCrumbs";
import { MediaQuery } from "@sisuwellness/web-components";
import AdSlotView from "components/AdSlotView";
import { AD_SLOT_CODES } from "@sisuwellness/utilities/CampaignAd";
import { PremiumFeature } from "components/PremiumFeatures";

const HealthCheckView = ({ match, campaignId, settings }) => {
    const healthCheckId = pathOr(null, ["params", "healthCheckId"], match);

    const [{ isLoading, isStarted, data: healthCheck }] = useAsyncData(
        () => APIClient.healthChecks.getResource(healthCheckId, true),
        null
    );

    if (!isStarted || isLoading) {
        return <LoadingView />;
    }

    if (!healthCheck && !isLoading) {
        return <Redirect to={routes.notFound} />;
    }

    const date = moment(pathOr(null, ["dateCreated"], healthCheck));

    return (
        <PageLayout>
            <BreadCrumb extra={date.format("DD MMMM YYYY")} />
            <Flex justifyContent="space-between" flexDirection={["column", "row"]}>
                <Heading fontSize="34px" fontWeight="500" mb={["16px", "24px"]}>
                    {date.format("DD MMMM YYYY")} - Health Check
                </Heading>
                <Heading fontSize="16px" fontWeight="500" mb={["40px", "24px"]} alignSelf={["flex-start", "flex-end"]}>
                    Taken at {healthCheck.stationInstallationName}
                </Heading>
            </Flex>
            <PremiumFeature forceRender>
                <HealthCheckMetrics healthCheck={healthCheck} campaignId={campaignId} settings={settings} />
            </PremiumFeature>
            <Box my="10px">
                <MediaQuery devices={["tablet", "mobile"]}>
                    {/* TODO: Change ad slot views */}
                    <AdSlotView
                        adSlotCode={AD_SLOT_CODES.PORTAL_HEALTH_HUB_FOOTER}
                        campaignId={campaignId}
                        criteria={{}}
                    />
                </MediaQuery>
            </Box>
        </PageLayout>
    );
};

HealthCheckView.propTypes = {
    theme: PropTypes.any.isRequired,
    match: PropTypes.object.isRequired,
    campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    settings: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth: { citizen = {} } = {}, settings }) => ({
    campaignId: pathOr(null, ["latestUserCampaignActivity", "campaignId"], citizen),
    settings
});

export default compose(
    withTheme,
    connect(mapStateToProps)
)(HealthCheckView);
