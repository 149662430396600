import moment from "moment";
import * as R from "ramda";
import { isBoolean, isNaN, toArray } from "lodash-es";
import { BodyMassIndex } from "@sisuwellness/utilities/HealthCalculators";
import chroma from "chroma-js";

import {
    NO_NETWORK_ERROR_MESSAGE,
    REQUEST_FAILED_ERROR_MESSAGE,
    INVALID_EMAIL_ADDRESS_ERROR_MESSAGE,
    INVALID_ACCESS_ERROR_MESSAGE
} from "constants/messages";
import { HealthChecksFetchStatus } from "constants/";

/**
 * Returns age from give DoB
 * @param {string} dob Date of birth
 * @returns {number} age for given DoB
 */
export const calculateAge = dob => Math.floor(moment().diff(dob, "years", true));

/**
 * Returns formatted string for given date
 * @param {string} date moment date string
 * @param {string} fmtString format type string
 * @returns {string} formatted string
 */
export const formatDate = (date, fmtString) => moment(date).format(fmtString);

/**
 * Returns difference between date1 and date2
 * @param {string} date1 moment date string
 * @param {string} date2 moment date string
 */
export const dateDiff = (date1, date2) => moment(date1).diff(date2);

/**
 * Returns a curried equivalent of the provided function
 */
export const pickAttribute = R.curry((key, data) => R.pathOr(null, key, data));

/**
 * Returns a, object with flattened keys
 * @param {object} key from HRA data
 */
export const mapHRAMetrics = ({
    heartRate,
    bodyMassIndex,
    recommendations,
    stressTestPss4Score,
    bloodPressureSystolic,
    bloodPressureDiastolic,
    australianDiabetesRiskScore,
    ...rest
}) => {
    return {
        ...rest,
        hr: heartRate,
        bmi: bodyMassIndex,
        sys: bloodPressureSystolic,
        dia: bloodPressureDiastolic,
        pss4Score: stressTestPss4Score,
        recommendations: toArray(recommendations),
        ausdriskScoreValue: australianDiabetesRiskScore
    };
};

/**
 * Returns age from give DoB
 * @param {string} dob Date of birth
 * @returns {date} date in YYYY-MM-DD format
 */
export const isoToDate = dob => moment(dob).format("YYYY/MM/DD");

/**
 * Returns age from give DoB
 * @param {date} date Current date string
 * @returns {date} Coordinated Universal Time (UTC)
 */
export const treatAsUTC = date => {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
};

//Total MilliSeconds in a Day
export const millisecondsPerDay = 24 * 60 * 60 * 1000;

/**
 * Returns age from give DoB
 * @param {date} start Start date
 * @param {date} end Target end date
 * @returns {Number} difference between 2 date
 */
export const timeDifference = (start, end) => (treatAsUTC(start) - end) / millisecondsPerDay;

/**
 * Returns age from give DoB
 * @param {date} date Current date
 * @param {Number} days No. of days to be added
 * @returns {date} date + days
 */
export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

/**
 * Returns consolidated object of latest health-check data and citizen data
 * (Health-Check Biased i.e health-check data overides citizen data and not vice-versa)
 * @param {Array} citizenData contains citizen data
 * @param {Array} healthCheckData array of past health check data
 * @returns {object}
 */
export const consolidateMetricData = (citizenData, healthCheckData) => {
    let data = mapHRAMetrics(pickAttribute(["data"], citizenData) || {});
    const height = data["height"] && Number(data["height"]);
    const weight = data["weight"] && Number(data["weight"]);

    // TODO: revisit, not sure why we just didn't merge
    // get values for these keys from health check data as these are not in citizen data
    const extraKeys = {
        isSmoker: null,
        isOnBpMedication: null,
        updatedAt: pickAttribute(["updatedAt"], citizenData),
        bmi:
            data["bmi"] ||
            (Number.isFinite(height) && Number.isFinite(weight) && BodyMassIndex.getBMI(height, weight)) ||
            null
    };
    const mappedCitizenData = { ...data, ...extraKeys };

    // return citizen data if health check data is null
    if (!healthCheckData || R.isEmpty(healthCheckData)) return mappedCitizenData;

    const tempData = { ...mappedCitizenData };

    // Traverse through all keys available in citizen data
    Object.keys(mappedCitizenData).forEach(key => {
        const value = mappedCitizenData[key];

        // only overwrite whose value is defined or keys are not present in the citizen data
        if (!value && !isBoolean(value) && value !== 0 && !Object.keys(extraKeys).includes(key)) return;

        // map latest recommendation with consolidation of health check and citizen recommendations
        // (Health-Check Biased i.e health-check recommendations overide citizen recommendations and not vice-versa)
        if (key === "recommendations") {
            tempData[key] = getLatestRecommendations(R.pluck("recommendations", healthCheckData), value);
            return;
        }

        // Get first health check object that contains the "key" whose value is not null.
        const healthCheckMetric = R.find(
            R.propSatisfies(x => x || isBoolean(x) || (x === 0 && !data[key]), key),
            healthCheckData
        );
        if (healthCheckMetric) {
            tempData[key] = healthCheckMetric[key];

            // set age and gender from the same bucket of bodyFatPercent
            if (key === "bodyFatPercent") {
                tempData["age"] = healthCheckMetric["age"];
                tempData["gender"] = healthCheckMetric["gender"];
            }
        }
    });

    // set default age if age is not present
    if (!tempData["age"]) tempData["age"] = calculateAge(pickAttribute(["userProfile", "dateOfBirth"], citizenData));
    return tempData;
};

/**
 * Returns latest recommendations for Portal Dashboard
 * @param {Array} healthCheckRecommendations array of all health check recommendations
 * @param {Array} citizenRecommendations array of HRA recommendations
 * @returns {Array}
 */
export const getLatestRecommendations = (healthCheckRecommendations, citizenRecommendations) => {
    if (!healthCheckRecommendations) return citizenRecommendations || [];
    // All recommendations from all health checks
    const latestRecommendations = healthCheckRecommendations;

    // All types of recommendations from HRA
    let typesRequired = toArray(citizenRecommendations);

    const constructRecommendations = [];

    // Map through array of latest recommendations
    latestRecommendations.forEach(lRecommendation => {
        if (!lRecommendation) return;
        // Map through different types of recommendations
        lRecommendation.forEach(recommendation => {
            // Only show recommedations that are available for Dashboard
            if (!typesRequired.length) return;

            // fn for type of risk
            const risk = R.propEq("risk", recommendation.risk);

            // Construct recommedation array which are latest for each type
            if (!R.filter(risk, constructRecommendations).length) {
                constructRecommendations.push(recommendation);

                // Reduce the size of the required recommedations array
                typesRequired = R.reject(risk, typesRequired);
            }
        });
    });
    // If no latest recommendation found show default HRA recommendation
    return Object.values(R.concat(typesRequired, constructRecommendations));
};

/**
 * Convert valid color to rgba string
 * @param {string} color  css color name or hex code
 * @param {string} alpha
 * @return {string}
 */
export const hexToRgba = (color, alpha = 1) => {
    if (!chroma.valid(color)) return color;
    const rgb = chroma(color).rgb();

    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
};

/**
 * Returns fixed number
 * @param {Number} number
 * @param {Number} fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 * @returns {Number}
 */
export const fixedNumber = (number, fractionDigits = 1) => {
    // FIXME: gives 0 for "", null, undefined - (fix if required)
    const numberTypeValue = Number(number);
    if (isNaN(numberTypeValue) || !Number.isFinite(numberTypeValue)) return number;
    return Number(numberTypeValue.toFixed(fractionDigits));
};

/**
 * Scrolls with smooth transition if supported
 * @param {*} xOffset
 * @param {*} yOffset
 */
export const scrollTo = (xOffset = 0, yOffset = 0) => {
    try {
        window.scrollTo({
            left: xOffset,
            behavior: "smooth",
            top: yOffset
        });
    } catch (error) {
        window.scrollTo(xOffset, yOffset);
    }
};

/**
 *
 * @param {object} param
 * @param {Function} param.fn
 * @param {number} param.interval
 * @param {number} param.maxAttempts Default 60
 * @param {(result: any) => Promise<boolean> | boolean } param.validate
 * @return {Promise}
 */
export const poll = async ({ fn, interval, validate, maxAttempts = 60 }) => {
    let attempts = 0;
    const executePoll = async (resolve, reject) => {
        const result = await fn();
        attempts++;

        const validateResult = validate(result);
        const extractResultIfPromise = validateResult instanceof Promise ? await validateResult : validateResult;

        if (extractResultIfPromise) return resolve(result);
        else if (attempts === maxAttempts) return reject(new Error("Exceeded maximum attempts"));
        else setTimeout(executePoll, interval, resolve, reject);
    };
    return new Promise(executePoll);
};

export const actualAgeRecordedAtTheTimeOfLastHealthCheck = healthChecks =>
    pickAttribute(["age"], R.find(R.propSatisfies(age => Number.isFinite(age), "age"), healthChecks));

/**
 * Modifies the respose promise to resolve only on success else reject
 * @param {Promise<Response>} promise
 * @return
 */
export const modifyResposePromise = promise =>
    promise.then(res => (res.succeeded() ? res.body() : Promise.reject(res)));

/**
 * Returns specific error message based on the param object (which is either An Error object or A Response object)
 * @param {Error | Response} error
 * @return {string}
 */
export const getErrorMessage = error => {
    if (error.message) return error.message;
    else if (error.isNetworkFailure()) return NO_NETWORK_ERROR_MESSAGE;
    else if (error.isNotAuthorised()) return INVALID_ACCESS_ERROR_MESSAGE;
    else if (error.isBadRequest()) return INVALID_EMAIL_ADDRESS_ERROR_MESSAGE;
    else return REQUEST_FAILED_ERROR_MESSAGE;
};

/**
 * Returns object structure required for select component options
 * @param {string} value
 * @param {string} label
 * @return {{ value: any, label: string } | null}
 */
export const getOptObject = (value, label) => (value ? { value, label: label || value } : null);

/**
 * Check if Health Check Fetch status is finalised
 *
 * @param {string} status
 * @return {boolean}
 */
export const isHealthChecksFetchStatusInProgress = status => status === HealthChecksFetchStatus.inProgress;

export { moment };
