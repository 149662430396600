import { pathOr } from "ramda";

import { theme } from "@sisuwellness/web-components";
import {
    isMobileView,
    createLegends,
    calculateMeanValue,
    createPlottableDataPoints
} from "components/Templates/TrendChart/Line/utils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { ACTIVITY } from "constants/trend-charts";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const MAP_ACTIVITY_RATING = ["Activity", "Moderately Active", "Moderately Inactive", "Inactive"];

const MAP_TICK_VALUES = ["Active", "Mod. Active", "Mod. Inactive", "Inactive"];

const METRIC_FIELDS = {
    rounded: true,
    key: ["activityRating"],
    additionalKeys: ["rating", "unit", "activityString"]
};

/**
 * Update data with rating
 * @param {object} data
 * @return {{ rating: { key: string, label: string, ranges: object } }}
 */
const activityRatingCalc = data => {
    if (!data.activityRating) return { activityRating: null };
    const { guideline, currentValue } = calculatePointColor(ACTIVITY, data);

    return {
        ...data,
        activityString: currentValue,
        // Map numbers for corresponding activity rating
        activityRating: MAP_ACTIVITY_RATING.indexOf(currentValue) + 1,
        rating: { key: guideline?.key, label: guideline?.label, ranges: guideline?.ranges }
    };
};

const plot = {
    unit: () => "Activity Rating",

    keyForValueString: () => "activityString",

    color: ({ rating } = {}) => theme.guidelines.Activity[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = []) => {
        const points = createPlottableDataPoints(activityRatingCalc)(
            healthChecks,
            METRIC_FIELDS.key,
            METRIC_FIELDS.additionalKeys
        );

        return points;
    },

    yRange: () => ({
        min: 0,
        max: 4,
        yScaleRanges: [1, 2, 3, 4]
    }),

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.ACTIVITY.themeKey
        ).reverse(),

    historyProps: (filteredPoints = [], latestHealthCheckData = {}) => {
        const { activityRating } = latestHealthCheckData;
        const { rating } = activityRatingCalc({ activityRating });

        const meanActivityRating = Math.round(calculateMeanValue("activityRating", filteredPoints[0]) - 1);

        const { rating: meanRating } = activityRatingCalc({
            activityRating: MAP_ACTIVITY_RATING[meanActivityRating]
        });

        return {
            value: activityRating,
            valueColor: theme.guidelines.Activity[(rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.Activity[(meanRating?.key)]?.sisuPortal?.hex,
            meanValue: meanActivityRating < 1 ? "-" : MAP_ACTIVITY_RATING[meanActivityRating]
        };
    },

    axisLeftProps: { format: tick => MAP_TICK_VALUES[tick - 1] },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 80 : 120 })
};

export default { plottingUtility: plot };
