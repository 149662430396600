import { pathOr } from "ramda";

import { theme } from "@sisuwellness/web-components";
import {
    isMobileView,
    createLegends,
    isUnitImperial,
    calculateMinMax,
    createYGridValues,
    flattenPointValues,
    createPlottableDataPoints,
    calculateMeanValue,
    downBy
} from "components/Templates/TrendChart/Line/utils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { WEIGHT } from "constants/trend-charts";
import { UnitConverter } from "@sisuwellness/utilities";
import { fixedNumber } from "utilities/commonUtils";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: true,
    key: ["weight"],
    additionalKeys: ["height", "rating", "weightInString"]
};

/**
 * Update data wrt to unit preference
 * @param {object} data
 * @param {object} settings
 * @return {{ rating: { key: string, label: string, ranges: object }, weight: number, weightInString: string }}
 */
const weightWithUnitPreference = (data, settings) => {
    if (!(data.height && data.weight)) return { weight: null };
    const { key, rating, guideline } = calculatePointColor(WEIGHT, data);

    const { stones, pounds } = UnitConverter.kgToStLbs(data.weight);
    const weightInStString = `${stones} st ${fixedNumber(pounds)} lbs`;
    const weightInKgString = `${fixedNumber(data.weight)} kgs`;

    const convertToOnlyStones = stones + pounds / 14;

    return {
        ...data,
        rating: { key, label: rating, ranges: guideline?.ranges },
        weightInString: isUnitImperial(settings) ? weightInStString : weightInKgString,
        weight: isUnitImperial(settings) ? fixedNumber(convertToOnlyStones) : data.weight
    };
};

const plot = {
    // Lookup key for weight value in string
    keyForValueString: () => "weightInString",

    unit: settings => (isUnitImperial(settings) ? "Stones (st)" : "Kilograms (kgs)"),

    color: ({ rating } = {}) => theme.guidelines.weight[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

    dataPoints: (healthChecks = [], settings) => {
        const points = createPlottableDataPoints(data => weightWithUnitPreference(data, settings))(
            healthChecks,
            METRIC_FIELDS.key,
            METRIC_FIELDS.additionalKeys
        );
        return points;
    },

    yRange: (points = []) => {
        const { maximum, minimum } = calculateMinMax(flattenPointValues(points, "y"));

        const ranges = createYGridValues(downBy(minimum), maximum, { parts: 5, rounded: METRIC_FIELDS.rounded });

        return {
            min: ranges[0],
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: (points = []) =>
        createLegends(
            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
            METRIC_INDICATOR.WEIGHT.themeKey,
            "Weight"
        ),

    historyProps: (filteredPoints = [], latestHealthCheckData = {}, settings) => {
        const { weight, height } = latestHealthCheckData;
        const datum = weightWithUnitPreference({ weight, height }, settings);

        let meanWeight = calculateMeanValue("weight", filteredPoints[0]);
        const meanHeight = calculateMeanValue("height", filteredPoints[0]);

        // filtered points have weight according to unit preference
        if (isUnitImperial(settings)) meanWeight = UnitConverter.stLbsToKg(meanWeight, 0);

        const meanDatum = weightWithUnitPreference({ weight: meanWeight, height: meanHeight }, settings);

        return {
            value: datum.weightInString,
            meanValue: meanDatum.weightInString ?? "0",
            valueColor: theme.guidelines.weight[(datum.rating?.key)]?.sisuPortal?.hex,
            meanColor: theme.guidelines.weight[(meanDatum.rating?.key)]?.sisuPortal?.hex
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
