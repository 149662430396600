/**
 * Muscle Mass Recommendations
 */
export default {
    low:
        "A low muscle mass can affect your strength, weight management and more. Consider doing resistance-based exercise at least twice a week.",
    normal:
        "Congratulations on a healthy muscle mass percentage! Resistance training twice a week will help you maintain this and give you extra health benefits.",
    high:
        "Congratulations on a healthy muscle mass percentage! Resistance training twice a week will help you maintain this and give you extra health benefits.",
    veryHigh:
        "Congratulations on a very high muscle mass percentage! Resistance training twice a week will help you maintain this and give you extra health benefits."
};
