import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";

class NonFastingGlucose extends Component {
    render() {
        const { t, glucose, date, theme, ...rest } = this.props;

        let guideline = null;
        let guidelineStyle = null;

        if (glucose) {
            guideline = new Guidelines.NonFastingGlucose.SiSU(glucose);
            guidelineStyle = theme.guidelines.SiSUNonFastingGlucose[guideline.key];
        }

        return (
            <Attribute
                {...rest}
                t={t}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={t("Non-fasting Glucose")}
                date={date}
                primaryStatValue={glucose}
                primaryStatUnit={"mmol/L"}
                icon={theme.icons.types.glucose}
            />
        );
    }
}

NonFastingGlucose.displayName = "Non-fasting Glucose Attribute";
NonFastingGlucose.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    glucose: PropTypes.number,
    date: PropTypes.string
};

export default withTheme(NonFastingGlucose);
