import React from "react";
import PropTypes from "prop-types";

import { HEART_AGE } from "constants/trend-charts";
import { Link } from "rebass";
import styled from "styled-components";

const StyledRecommendationUrl = styled.span`
    text-decoration: underline;
`;

const StyledExternalLink = styled.a`
    color: inherit;
    text-decoration: none;

    :active {
        text-decoration: none;
    }

    :visited {
        text-decoration: none;
    }

    width: 100%;
`;

/**
 * @param {object} recommendation
 * @return {string}
 */
function getRecommendationText(recommendation) {
    return recommendation.textPortal || recommendation.text || "";
}

function createSlicedTextParts(text, indexOfSearchText, lengthOfSearchText) {
    const leftText = text.slice(0, indexOfSearchText);
    const rightText = text.slice(indexOfSearchText + lengthOfSearchText, text.length);

    return [leftText, rightText];
}

function generateLinkableRecommendation(recommendation) {
    let recommendationText = getRecommendationText(recommendation);
    const indexOfUrl = recommendationText.indexOf(recommendation.url);

    if (indexOfUrl !== -1) {
        // slice up the text before and after the URL
        const [textBeforeUrl, textAfterUrl] = createSlicedTextParts(
            recommendationText,
            indexOfUrl,
            recommendation.url.length
        );

        // recomposite with the styled URL
        return (
            <div data-testid={`recommendation-styled-url-${recommendation.risk}`}>
                {textBeforeUrl}
                <StyledRecommendationUrl>{recommendation.url}</StyledRecommendationUrl>
                {textAfterUrl}
            </div>
        );
    }

    return recommendationText;
}

function interpolateLinkToHeartAgeRecommendation(recommendation, initialRecommendationText) {
    const text = getRecommendationText(recommendation);
    if (recommendation.risk === HEART_AGE && recommendation.rating === "elevated") {
        const indexOfHyperlink = text.search(/healthy heart program/i);
        if (indexOfHyperlink !== -1) {
            const [textBeforeUrl, textAfterUrl] = createSlicedTextParts(text, indexOfHyperlink, 21);
            return (
                <span>
                    {textBeforeUrl}
                    <Link href={recommendation.url || "#"} color="hpBlue">
                        {text.substr(indexOfHyperlink, 21)}
                    </Link>
                    {textAfterUrl}
                </span>
            );
        }
    }
    return initialRecommendationText;
}

/**
 * Renders a recommendation, given recommendation data and a function for generating the inner recommendation component
 *
 * @param {Object} recommendation
 * @param {Number} index
 * @param {Object} customStyleProps
 * @param {function(index: Number, risk: String, title: String, priority: Number, text: String|JSX)} generateRecommendationComponent
 * @return {*}
 */
export default function InterpolatedRecommendation({ recommendation, index, children }) {
    let recommendationText = interpolateLinkToHeartAgeRecommendation(
        recommendation,
        recommendation.renderAsHTML
            ? getRecommendationText(recommendation)
            : generateLinkableRecommendation(recommendation)
    );

    const RecommendationComponent = React.cloneElement(children, {
        index,
        ...recommendation,
        text: recommendationText
    });

    return recommendation.url ? (
        <StyledExternalLink
            href={recommendation.url}
            target={"_blank"}
            data-testid={`recommendation-link-${recommendation.risk}`}
        >
            {RecommendationComponent}
        </StyledExternalLink>
    ) : (
        RecommendationComponent
    );
}

InterpolatedRecommendation.propTypes = {
    children: PropTypes.any,
    recommendation: PropTypes.object,
    index: PropTypes.number.isRequired
};
