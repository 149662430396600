import React from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------------

import InputField from "components/InputField";

// -----------------------------------------------------------------------------------

export const NameField = ({ values, touched, errors, handleChange, handleBlur }) => {
    return (
        <>
            <InputField
                label="First Name"
                fontFamily="GT Walsheim Pro"
                inputProps={{
                    name: "firstName",
                    placeholder: "First Name",
                    "data-testid": "firstName",
                    onChange: handleChange,
                    value: values.firstName,
                    onBlur: handleBlur
                }}
                error={touched.firstName ? errors.firstName : ""}
            />
            <InputField
                label="Surname"
                fontFamily="GT Walsheim Pro"
                inputProps={{
                    name: "lastName",
                    placeholder: "Last Name",
                    "data-testid": "lastName",
                    onChange: handleChange,
                    value: values.lastName,
                    onBlur: handleBlur
                }}
                error={touched.lastName ? errors.lastName : ""}
            />
        </>
    );
};

NameField.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
};
