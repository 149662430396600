import { BLOOD_PRESSURE, BODY_MASS_INDEX, SMOKING } from "constants/trend-charts";
import { theme } from "@sisuwellness/web-components";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import { PORTAL_IMAGE } from "constants/imagePath";
import { fixedNumber } from "utilities/commonUtils";

/**
 * Get metric details specifically for heart age page
 * @param {string} type
 * @param {object} datum
 * @return {{} | { icon: string, title: string, value: string | number, unit: string, label: string, color: string, recommendation: string}}
 */
export const getMetricDetails = (type, datum) => {
    const { themeKey } = METRIC_INDICATOR[type] || {};

    const themeColor = theme.guidelines[themeKey];

    const { key = "" } = calculatePointColor(type, datum) || {};

    switch (type) {
        case BODY_MASS_INDEX: {
            const title = "BMI";
            let metric = {};

            const { bmi } = datum;

            switch (key) {
                case "low":
                    metric = {
                        label: "Low",
                        color: themeColor.low.sisuPortal.hex,
                        recommendation:
                            "Being underweight can increase your risk of some diseases, lead to malnutrition, and reduce your immune function, so aim for a normal BMI to optimise your health."
                    };
                    break;

                case "normal":
                    metric = {
                        label: "Normal",
                        color: themeColor.normal.sisuPortal.hex,
                        recommendation:
                            "Great job on a healthy BMI! Maintain your BMI for lower risk of serious health conditions, including heart disease, stroke, type 2 diabetes and certain cancers."
                    };
                    break;

                case "high":
                case "elevated":
                    metric = {
                        label: "Intermediate",
                        color: themeColor.elevated.sisuPortal.hex,
                        recommendation:
                            "Reducing your weight (and therefore BMI) will reduce your Heart and Stroke Risk and your Heart Age."
                    };
                    break;

                case "veryHigh":
                    metric = {
                        label: "High",
                        color: themeColor.high.sisuPortal.hex,
                        recommendation:
                            "It is important your reduce your weight (and therefore BMI) soon, to reduce your risk of Heart and Stroke Risk and your Heart Age."
                    };
                    break;
            }

            return {
                ...metric,
                title,
                unit: "BMI",
                value: fixedNumber(bmi),
                icon: `${PORTAL_IMAGE}/healthHub/bmi.svg`
            };
        }

        case BLOOD_PRESSURE: {
            const title = "Blood Pressure";
            let metric = {};

            const { sys, dia } = datum;

            switch (key) {
                case "optimal":
                    metric = {
                        label: "Optimal",
                        color: themeColor.optimal.sisuPortal.hex,
                        recommendation:
                            "Your blood pressure range is optimal! Blood pressure changes throughout your life, so it is important to monitor your blood pressure regularly to ensure it stays below 120/80 mmHg."
                    };
                    break;

                case "normal":
                    metric = {
                        label: "Normal",
                        color: themeColor.normal.sisuPortal.hex,
                        recommendation:
                            "Your blood pressure range is normal. Blood pressure changes throughout your life, so it is important to monitor your blood pressure regularly to ensure it stays below 120/80 mmHg."
                    };
                    break;

                case "mildHypertension":
                case "highNormal":
                    metric = {
                        label: "High Normal",
                        color: themeColor.highNormal.sisuPortal.hex,
                        recommendation:
                            "The ideal blood pressure range is below 120/80 mmHg. Consider what lifestyle changes, such as cutting back on caffeine and alcohol, you can make to reduce your blood pressure."
                    };
                    break;

                case "severeHypertension":
                case "moderateHypertension":
                    metric = {
                        label: "High",
                        color: themeColor.moderateHypertension.sisuPortal.hex,
                        recommendation:
                            "High blood pressure increases the risk of heart attack or stroke. Most people with high blood pressure do not show symptoms. Discuss your reading with your doctor or pharmacist as soon as possible."
                    };
                    break;
            }
            return {
                ...metric,
                title,
                unit: "mmHg",
                value: `${sys}/${dia}`,
                icon: `${PORTAL_IMAGE}/healthHub/bloodpressure.svg`
            };
        }

        case SMOKING: {
            const title = "Smoking";
            let metric = {};

            const { isSmoker } = datum;

            switch (key) {
                case "normal":
                    metric = {
                        label: "Great",
                        color: themeColor.normal.sisuPortal.hex,
                        recommendation:
                            "Congratulations on not smoking, this greatly reduces your risk of heart attack and stroke. Smoking is a huge influence on your Heart Age."
                    };
                    break;

                case "high":
                    metric = {
                        label: "Risk",
                        color: themeColor.high.sisuPortal.hex,
                        recommendation:
                            "Stopping smoking is one of the single biggest changes for improving your health. This will significantly reduce your risk of heart attack and stroke. The sooner you can stop, the better."
                    };
                    break;
            }
            return {
                ...metric,
                title,
                value: isSmoker ? "Yes" : "Not Smoking",
                icon: `${PORTAL_IMAGE}/healthHub/smoking.svg`
            };
        }

        case "DIABETIC": {
            const title = "Diabetes";
            let metric = {};

            const { hasDiabetes } = datum;

            const isDiabetic = hasDiabetes === "diabetic";

            if (isDiabetic)
                metric = {
                    label: "High Risk",
                    color: theme.colours.hpPrimaryRed.hex,
                    recommendation:
                        "Unfortunately, having diabetes increases your chance of developing cardiovascular disease and other health problems, such as high cholesterol."
                };
            else
                metric = {
                    label: "Normal",
                    color: theme.colours.hpGreen.hex,
                    recommendation:
                        "Not having diabetes means you are less likely to develop conditions that would lead to cardiovascular disease."
                };

            return {
                ...metric,
                title,
                value: isDiabetic ? "Diagnosed" : "No",
                icon: `${PORTAL_IMAGE}/premium/diabetic.svg`
            };
        }

        default:
            return {};
    }
};
